import { PlusOutlined } from "@ant-design/icons";
import type {
	IContact,
	IContactLinking,
	IDependant,
	IPartner,
	TContactLinkingType,
	TMaritalStatus,
} from "@bothive_core/database";
import { Button, Divider, Empty, Form, InputNumber, Select, Switch } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDebounce } from "use-debounce";
import utc from "dayjs/plugin/utc";

import { trpc } from "../../../../../../../../trpc";
import FieldLabel from "../../../../../../../../shared/components/field/default";
import SideLabel from "../../../../../../../../shared/components/field/side_label";
import CreateModal from "../../../../../../overview/components/CreateModal";
import { useSetFocus } from "@/shared/hooks";
import ChildForm from "./ChildForm";
import PartnerForm from "./PartnerForm";
import RelativeForm from "./RelativeForm";
import { dependentType } from "../config";
import DateFormItem from "@/shared/components/input/DateFormItem";

const dependentTypes = ["child", "dependent", "partner", "other"];

dayjs.extend(utc);

export interface IContactLinkingSubmit {
	linkedContactId: string;
	type: TContactLinkingType;
	flowProxy: boolean;
	data: any;
}

interface IFormComponent {
	contact: IContact;
	formId?: string;
	linkedContact?: IContactLinking;
	hideLinkedContact?: boolean;
	onSubmit: ({ linkedContactId, type, data }: IContactLinkingSubmit) => void;
}

// TODO: better matching between id and id fieldlabel => form.item, now relation and disability won't work

export default function FormComponent({
	linkedContact,
	contact,
	onSubmit,
	hideLinkedContact = false,
	formId = "linkContactForm",
}: IFormComponent) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const inputRef = useRef(null);
	const [query, setQuery] = React.useState("");
	const [debouncedSearch] = useDebounce(query, 500);
	const [selectNameValue, setSelectNameValue] = React.useState("");
	const [createdContact, setCreatedContact] = React.useState<any | null>(null);
	const [showCreateContactModal, setShowCreateContactModal] = React.useState(false);
	const [selectedContactLinkId, setSelectedContactLinkId] = React.useState<string | null>(null);
	const { data: contactList, isLoading } = trpc.contact.getAllContacts.useQuery({
		limit: 100,
		q: debouncedSearch,
		showLinked: true,
	});
	const { data: linkedContactData } = trpc.contact.getContactById.useQuery(
		{ id: linkedContact?.linkedContactId.toString() || "" },
		{ enabled: !!linkedContact?.contactId }
	);

	// Form state
	const hasIncome = Form.useWatch<IDependant["income"]>("income", form);
	const linkType = Form.useWatch<IContactLinking["type"]>("type", form);
	const maritalStatus = Form.useWatch<TMaritalStatus>("maritalStatus", form);

	useSetFocus({ ref: inputRef, dependencies: [inputRef] });
	useEffect(() => {
		form.resetFields();

		if (linkedContact) {
			form.setFieldsValue({ ...linkedContact, ...(linkedContact?.data || {}) });
		}
	}, [linkedContact]);

	const handleGeneratedContact = (contact: any) => {
		setCreatedContact(contact);
		setSelectedContactLinkId(contact._id);
	};

	const handleSubmit = ({ type, linkedContactId, flowProxy, ...data }: IContactLinkingSubmit) => {
		const _linkedContactId = selectedContactLinkId || linkedContactId || linkedContact?._id;

		if (!_linkedContactId) return;

		onSubmit({ type, linkedContactId: _linkedContactId, flowProxy, data });
	};

	const filteredContacts = contactList?.data
		.filter((item) => item?._id !== contact._id && item.fullName)
		.map((contact) => ({ value: contact?._id, label: contact.fullName }));

	return (
		<>
			<Form
				form={form}
				id={formId}
				layout="vertical"
				className="frsf-form"
				style={{ display: "grid", gap: "1em" }}
				initialValues={
					linkedContact
						? {
								weddingDate:
									(linkedContact.data as IPartner)?.weddingDate &&
									dayjs((linkedContact.data as IPartner).weddingDate)
										.utc()
										.valueOf(),
								divorceDate:
									(linkedContact.data as IPartner)?.divorceDate &&
									dayjs((linkedContact.data as IPartner).divorceDate)
										.utc()
										.valueOf(),
						  }
						: { type: linkType }
				}
				onFinish={handleSubmit}
			>
				<FieldLabel
					id="relation"
					label="CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.RELATIONSHIP"
					style={hideLinkedContact ? { display: "none" } : {}}
				>
					<Form.Item
						style={{ width: "100%", textAlign: "left" }}
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: "CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.RELATIONSHIP.ERROR_REQUIRED",
								}),
							},
						]}
					>
						<Select
							id="relation"
							ref={inputRef}
							showSearch
							allowClear={true}
							loading={isLoading}
							className="loading_select"
							options={filteredContacts}
							value={selectedContactLinkId || linkedContact?.linkedContactId}
							disabled={Boolean(linkedContact)}
							onSearch={(value) => setQuery(value)}
							onChange={(value) => setSelectedContactLinkId(value.toString())}
							placeholder={
								createdContact?.firstName ||
								`${linkedContactData?.firstName || ""} ${linkedContactData?.lastName || ""}` ||
								""
							}
							notFoundContent={
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={
										<p>
											<FormattedMessage id="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.RELATIONSHIP.EMPTY" />
										</p>
									}
								/>
							}
							filterOption={false}
							defaultActiveFirstOption={false}
							dropdownRender={(menu) => (
								<>
									{menu}
									<Divider style={{ margin: "8px 0" }} />
									<Button
										type="text"
										icon={<PlusOutlined />}
										onClick={() => setShowCreateContactModal(true)}
									>
										{" "}
										<FormattedMessage id="CONTACTS.DETAIL.ATTRIBUTES.LINKING.SELECT.ADD" />
									</Button>
								</>
							)}
							onInputKeyDown={(e) => {
								// @ts-ignore
								setSelectNameValue(e.target.value);
							}} // todo figure out why this is one step behind
						/>
					</Form.Item>
				</FieldLabel>
				<FieldLabel id="type" label="CONTACTS.OVERVIEW.ADD.MODAL.TYPE">
					<Form.Item name="type" style={{ width: "100%", textAlign: "left" }}>
						<Select autoFocus={true}>
							{Object.keys(dependentType).map((key) => (
								<Select.Option key={key} value={key}>
									<FormattedMessage id={dependentType[key]} />
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</FieldLabel>
				{linkType === "child" && <ChildForm form={form} />}
				{linkType === "partner" && <PartnerForm form={form} />}
				{linkType === "dependent" && (
					<>
						<FieldLabel
							id="dependentSince"
							label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.DEPENDENT_SINCE.LABEL"
							optional
						>
							<Form.Item name="dependentSince" style={{ width: "100%", textAlign: "left" }}>
								<DateFormItem
									style={{ width: "100%" }}
									disabledDate={(current) => current && current > dayjs().endOf("day")}
								/>
							</Form.Item>
						</FieldLabel>
						<SideLabel
							id="hasReducedSelfReliance"
							label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.HAS_REDUCED_SELF_RELIANCE.LABEL"
							style={{ width: "100%" }}
						>
							<Form.Item name="hasReducedSelfReliance" valuePropName="checked">
								<Switch />
							</Form.Item>
						</SideLabel>
					</>
				)}
				{["child", "dependent"].includes(linkType) && (
					<>
						<SideLabel
							id="income"
							label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.ADDITIONAL_INCOME"
							style={{ width: "100%" }}
						>
							<Form.Item name="income" valuePropName="checked">
								<Switch />
							</Form.Item>
						</SideLabel>
						{hasIncome && (
							<FieldLabel
								id="incomeAmount"
								label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.ADDITIONAL_INCOME_AMOUNT.LABEL"
								optional={true}
							>
								<Form.Item name="incomeAmount" style={{ width: "100%" }}>
									<InputNumber
										prefix="€"
										placeholder="1250"
										style={{ width: "100%", textAlign: "left" }}
									/>
								</Form.Item>
							</FieldLabel>
						)}
					</>
				)}
				{dependentTypes.includes(linkType) && !["separated", "defactoSeparated"].includes(maritalStatus) && (
					<RelativeForm contact={contact} />
				)}
			</Form>
			<CreateModal
				open={showCreateContactModal}
				setShowModal={setShowCreateContactModal}
				setCreatedContact={handleGeneratedContact}
				contactType="linkedContact"
				initialName={selectNameValue}
				isCreatingLinkedContact={true}
			/>
		</>
	);
}
