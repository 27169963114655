import get from "lodash.get";

export default {
	cleanUrl(item) {
		const stripedUrl = item.url.replace(/(https:|http:)\/\//, "");
		const cleanUrl = stripedUrl.substring(stripedUrl.indexOf("/") + 1);

		return cleanUrl || stripedUrl;
	},
	randomUuid(length = 12) {
		const values = "abcdefghijklmonpqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-_+";

		let token = "";

		for (let i = 0; i < length; i++) {
			token += values.substr(Math.floor(Math.random() * values.length), 1);
		}

		return token;
	},
	capitalizeFirstLetter(string) {
		if (!string) {
			return string;
		}

		return string.charAt(0).toUpperCase() + string.slice(1);
	},
	formatNamedList(item) {
		let response = item ? item[0] : "";

		if (item?.length > 1) {
			const list = item.join(", ");
			const pos = list.lastIndexOf(",");

			response = `${list.substring(0, pos)} en ${list.substring(pos + 1)}`;
		}

		return response;
	},
	isEmpty: (string) => !string || string === "" || /^(\s+$)/.test(string),
	getValueLength(value) {
		if (typeof value === "string") {
			return stripTemplate(value).length;
		}

		if (value[0]) {
			if (value[0].type && value[0].children) {
				return value.reduce((prev, curr) => {
					if (curr.type === "text") {
						return (
							prev +
							curr.children.reduce((prev, curr) => (curr.type ? prev + 5 : prev + curr.text.length), 0)
						);
					}

					return prev + 5;
				}, 0);
			}
		}

		return 1;
	},
	stringifyComplexString({ text, team }) {
		if (!text) {
			return "";
		}

		if (typeof text === "string") {
			return text;
		}

		const mergedString = text.reduce((prev, curr) => {
			const value = curr.children.reduce(
				(prev, child) => `${prev}${child.text !== undefined ? child.text : child.value}`,
				""
			);

			return `${prev}${value}`;
		}, "");

		return replaceTemplates({
			string: mergedString,
			payload: { organization: team },
		});
	},
	stripTemplate,
	formatPayload,
	replaceTemplates,
	isUrl(value) {
		if (!value) {
			return false;
		}

		const pattern = new RegExp("^(http|https|file):\\/\\/?"); //eslint-disable-line max-len

		return pattern.test(value);
	},
	isEmail(value) {
		if (!value) {
			return false;
		}

		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
	},
	formatForwardingSubject(subject) {
		const prefix = "FWD: ";

		if (!subject) {
			return "";
		}

		if (subject.startsWith(prefix)) {
			return subject;
		}

		return `${prefix}${subject}`;
	},
	formatForwardingContent(content) {
		const prefix = "<br /><br /><br />---------- Forwarded message ---------<br /><br />";

		return `${prefix}${content}`;
	},
};

function stripTemplate(string) {
	if (!string) {
		return "";
	}

	return string.replace(/{{(organization|user).[a-zA-Z0-9\-_.[\]]*}}/g, (_, key) => key);
}
function replaceTemplates({ string, payload }) {
	if (!string) {
		return "";
	}

	const _payload = formatPayload(payload);

	return string.replace(/{{(organization|user).([a-zA-Z0-9\-_.[\]]*)}}/g, (
		value,
		key,
		subKey //eslint-disable-line no-useless-escape
	) => get(_payload[key], subKey, value));
}

function formatPayload(payload) {
	const formatted = JSON.parse(JSON.stringify(payload));

	if (formatted.organization) {
		formatted.organization.locations = formatted.organization.locations.map((location) => ({
			...location,
			companyNumber: formatCompanyNumber(location.companyNumber),
		}));

		if (formatted.organization?.billing?.address?.companyNumber) {
			formatted.organization.billing.address.companyNumber = formatCompanyNumber(
				formatted.organization.billing?.address?.companyNumber
			);
		}
	}

	return formatted;
}
function formatCompanyNumber(companyNumber) {
	let number = companyNumber.replace(/\./g, "");

	return `${number.slice(0, 4)}.${number.slice(4, 7)}.${number.slice(7)}`;
}
