export default {
	systemlabels: [
		"ARCHIVED",
		"DRAFT",
		"IMPORTANT",
		"INBOX",
		"NEWSLETTERS",
		"NOTIFICATIONS",
		"PROMOTIONS",
		"SOCIAL_MEDIA",
		"SPAM",
		"TRASH",
		"DELEGATED",
		"SENT",
		"PINNED",
	],
};
