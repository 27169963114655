import { Component } from "../../../../components/atoms";
import { Button, Result } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

export default function () {
	const intl = useIntl();
	const history = useHistory();

	return (
		<section className="t-section">
			<Component.ADashboardCard>
				<Result
					style={{ margin: "auto" }}
					status="warning"
					title={intl.formatMessage({
						id: "CONTACT_DETAILS.NOT_FOUND.TITLE",
						defaultMessage: "Contact not found",
					})}
					subTitle={intl.formatMessage({
						id: "CONTACT_DETAILS.NOT_FOUND.DESCRIPTION",
						defaultMessage: "It seems like this contact does not exists inside your organization.",
					})}
					extra={
						<Button onClick={() => history.goBack()} type="primary">
							<FormattedMessage id="CONTACT_DETAILS.NOT_FOUND.RETURN" defaultMessage="Go back" />
						</Button>
					}
				/>
			</Component.ADashboardCard>
		</section>
	);
}
