import { CloseOutlined, FullscreenExitOutlined, FullscreenOutlined, MinusOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { ReactNode, useEffect } from "react";
import { FormattedMessage, MessageDescriptor, defineMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import { useRecoilState } from "recoil";
import { composeWindowState } from "../state";
import { WindowState } from "../types";

interface IActionButtons {
	state: WindowState;
	config: Record<
		string,
		{
			tooltip: MessageDescriptor;
			ariaLabel: MessageDescriptor;
			icon: ReactNode;
		}
	>;
	onClick: (state: WindowState) => void;
	className?: string;
}
function ActionButtons({ state, config, onClick, className = "" }: IActionButtons) {
	const intl = useIntl();
	const mouseEnterDelay = 1;
	const componentConfig = config[state];

	return (
		<Tooltip mouseEnterDelay={mouseEnterDelay} title={intl.formatMessage(componentConfig.tooltip)}>
			<Button
				aria-label={intl.formatMessage(componentConfig.ariaLabel)}
				icon={componentConfig.icon}
				onClick={() => onClick(state)}
				className={`inbox-composer-header-controls-button ${className}`}
				size="middle"
				type="link"
			/>
		</Tooltip>
	);
}

export default function Header({ onClose }: { onClose: () => void }) {
	const queryParam = "composerState";

	const intl = useIntl();
	const mouseEnterDelay = 1;
	const history = useHistory();
	const location = useLocation();
	const [windowState, setWindowState] = useRecoilState(composeWindowState);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		let stateFromQueryParams = searchParams.get(queryParam) as string | null;

		if (!stateFromQueryParams || !windowState.includes(stateFromQueryParams)) stateFromQueryParams = "default";

		setWindowState(stateFromQueryParams as WindowState);
	}, [location.search]);

	const handleWindowState = (state: WindowState) => {
		const searchParams = new URLSearchParams(location.search);

		if (searchParams.get(queryParam)) {
			if (state === "fullscreen") {
				// If window state query param is already set replace url to not spam history
				history.replace(`${location.pathname}?${queryParam}=${state}`);
			} else {
				// If not fullscreen state remove url from browser history
				history.goBack();
			}
		} else if (state === "fullscreen") {
			history.push(`${location.pathname}?${queryParam}=${state}`);
		}

		setWindowState(state);
	};

	return (
		<header className="inbox-composer-header">
			<h1 className="header-md">
				<FormattedMessage
					id="inbox.compose.header.title.default"
					defaultMessage="Compose email"
					description="Compose editor title for when composer is used as external window"
				/>
			</h1>
			<div className="inbox-composer-header-controls">
				<ActionButtons
					state={windowState === "minimized" ? "maximized" : "minimized"}
					config={{
						minimized: {
							tooltip: defineMessage({
								id: "inbox.compose.header.minimized.tooltip",
								defaultMessage: "Minimized",
							}),
							ariaLabel: defineMessage({
								id: "inbox.compose.header.minimized.aria_label",
								defaultMessage: "Minimized compose window",
							}),
							icon: <MinusOutlined className="inbox-composer-header-controls-icon_minimized" />,
						},
						maximized: {
							tooltip: defineMessage({
								id: "inbox.compose.header.maximized.tooltip",
								defaultMessage: "Maximized",
							}),
							ariaLabel: defineMessage({
								id: "inbox.compose.header.maximized.aria_label",
								defaultMessage: "Maximized compose window",
							}),
							icon: <MinusOutlined className="inbox-composer-header-controls-icon_maximized" />,
						},
					}}
					onClick={handleWindowState}
				/>
				<ActionButtons
					state={windowState === "fullscreen" ? "default" : "fullscreen"}
					config={{
						fullscreen: {
							tooltip: defineMessage({
								id: "inbox.compose.header.fullscreen.tooltip",
								defaultMessage: "Fullscreen",
							}),
							ariaLabel: defineMessage({
								id: "inbox.compose.header.fullscreen.aria_label",
								defaultMessage: "Make compose window fullscreen",
							}),
							icon: <FullscreenOutlined />,
						},
						default: {
							tooltip: defineMessage({
								id: "inbox.compose.header.shrink.tooltip",
								defaultMessage: "Shrink",
							}),
							ariaLabel: defineMessage({
								id: "inbox.compose.header.shrink.aria_label",
								defaultMessage: "Shrink compose window",
							}),
							icon: <FullscreenExitOutlined />,
						},
					}}
					onClick={handleWindowState}
				/>
				<Tooltip
					mouseEnterDelay={mouseEnterDelay}
					title={intl.formatMessage({
						id: "inbox.compose.header.close.tooltip",
						defaultMessage: "Close",
					})}
				>
					<Button
						aria-label={intl.formatMessage({
							id: "inbox.compose.header.close.aria_label",
							defaultMessage: "Close compose window",
						})}
						className="inbox-composer-header-controls-button"
						onClick={() => onClose()}
						icon={<CloseOutlined />}
						size="middle"
						type="link"
					/>
				</Tooltip>
			</div>
		</header>
	);
}
