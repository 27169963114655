import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Popup } from "..";

export default function OPopupDefaultControl({
	addLabel,
	cancelLabel,
	children,
	onClick,
	onClose,
	onCancel,
	loading,
	disabled,
	...props
}) {
	return (
		<Popup.OPopupGeneral onClose={onClose} {...props}>
			{children}
			<div className="o-popup--button">
				<Button type="text" onClick={onCancel || onClose}>
					<FormattedMessage id={cancelLabel} />
				</Button>

				<Button htmlType="submit" type="primary" onClick={onClick} disabled={disabled} loading={loading}>
					<FormattedMessage id={addLabel} />
				</Button>
			</div>
		</Popup.OPopupGeneral>
	);
}

OPopupDefaultControl.defaultProps = {
	disabled: true,
	children: undefined,
	cancelLabel: "POPUP.BUTTON.CANCEL",
	className: "",
	saving: false,
	onClick: undefined,
	onCancel: undefined,
};
