import { CheckCircleOutlined, DownOutlined, ExclamationCircleOutlined, NumberOutlined } from "@ant-design/icons";
import { Button, Divider, List, Tooltip } from "antd";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ListItem from "./ListItem";
import clsx from "clsx";
import "../style.scss";
import { trpc } from "@/trpc";

export default function Group({
	flowReminderSettingId,
	dateLabel,
	onlyShowFailed,
	stats,
}: {
	dateLabel: string;
	onlyShowFailed: boolean;
	flowReminderSettingId: string;
	stats: { total?: number; failed?: number; success?: number };
}) {
	const intl = useIntl();
	const [collapsed, setCollapsed] = useState(true);

	const {
		data: items,
		fetchNextPage,
		isInitialLoading,
		isFetchingNextPage,
	} = trpc.flowReminders.getFlowReminderHistoryByDay.useInfiniteQuery(
		{
			flowReminderSettingId,
			failedOnly: onlyShowFailed,
			day: new Date(dateLabel),
		},
		{
			getNextPageParam: (data): { ids: string[]; executeAt: Date } | null => {
				if (!data || !data?.data?.length) return null;

				const latest = data?.data?.[data?.data?.length - 1];

				return {
					ids: [latest._id],
					executeAt: latest.executeAt,
				};
			},
			keepPreviousData: true,
			enabled: !collapsed,
		}
	);

	const label = (() => {
		const dateFormatter = new Intl.DateTimeFormat(intl.locale, { dateStyle: "full" });
		const currentValue = new Date(dateLabel);
		const today = new Date();
		const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

		// today
		if (currentValue.toDateString() === today.toDateString()) {
			return intl.formatMessage({
				id: "FLOW.DETAIL.REMINDERS.HISTORY_LIST.TIME_SEPARATOR.TODAY",
				defaultMessage: "Today",
			});
		}

		// yesterday
		if (
			currentValue.getFullYear() === yesterday.getFullYear() &&
			currentValue.getMonth() === yesterday.getMonth() &&
			currentValue.getDate() === yesterday.getDate()
		) {
			return intl.formatMessage({
				id: "FLOW.DETAIL.REMINDERS.HISTORY_LIST.TIME_SEPARATOR.YESTERDAY",
				defaultMessage: "Yesterday",
			});
		}

		return dateFormatter.format(currentValue);
	})();

	const itemsFlat = useMemo(() => {
		return items?.pages.flatMap((page) => page.data) || [];
	}, [items?.pages]);
	const hasMore = (itemsFlat?.length || 0) < (items?.pages[0]?.total || 0);

	return (
		<article
			tabIndex={0}
			onKeyDown={(event) => event.key === "Enter" && setCollapsed((prev) => !prev)}
			className="flow-reminder-history__group"
		>
			<header
				onClick={() => setCollapsed((prev) => !prev)}
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					cursor: "pointer",
					padding: "2em",
				}}
			>
				<div style={{ display: "flex", gap: ".5em", alignItems: "center" }}>
					<p style={{ fontWeight: 600, textTransform: "uppercase" }}>
						<FormattedMessage id={label} />
					</p>
					<DownOutlined style={{ ...(collapsed && { transform: "rotate(-90deg)" }) }} />
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						color: "gray",
					}}
				>
					<Tooltip
						title={intl.formatMessage(
							{
								id: "FLOW.DETAIL.REMINDERS.STATUS.COUNT.GENERAL",
								defaultMessage: "{count} reminders executed on this day",
							},
							{ count: stats.total }
						)}
					>
						<p className="flow-reminder-history__group-summary-item">
							<NumberOutlined />
							{stats.total}
						</p>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip
						title={intl.formatMessage(
							{
								id: "FLOW.DETAIL.REMINDERS.STATUS.COUNT.SUCCESS",
								defaultMessage: "{count} reminders succeeded",
							},
							{ count: stats.success }
						)}
					>
						<p className="flow-reminder-history__group-summary-item">
							<CheckCircleOutlined />
							{stats.success}
						</p>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip
						title={intl.formatMessage(
							{
								id: "FLOW.DETAIL.REMINDERS.STATUS.COUNT.FAILED",
								defaultMessage: "{count} reminders failed",
							},
							{ count: stats.failed }
						)}
					>
						<p
							data-count={stats.failed}
							className={clsx(
								"flow-reminder-history__group-summary-item",
								stats.failed && "flow-reminder-history__group-summary-item--error"
							)}
						>
							<ExclamationCircleOutlined />
							{stats.failed}
						</p>
					</Tooltip>
				</div>
			</header>
			<div style={{ padding: "0 2em", display: collapsed ? "none" : "block" }}>
				<Divider className="flow-reminder-history__group-header-divider" />
				<div style={{ padding: "2em 0", display: "grid" }}>
					<List loading={isInitialLoading}>
						{!!itemsFlat.length &&
							itemsFlat?.map((reminderQueueItem) => (
								<ListItem key={reminderQueueItem._id} reminderQueueItem={reminderQueueItem} />
							))}
					</List>
					{hasMore && (
						<Button
							type="text"
							loading={isFetchingNextPage}
							style={{ marginTop: "2em", justifySelf: "center" }}
							onClick={() => fetchNextPage()}
						>
							<FormattedMessage
								id="FLOW.DETAIL.REMINDERS.HISTORY.LIST.ITEMS.LOAD_MORE"
								defaultMessage="Load more data"
							/>
						</Button>
					)}
				</div>
			</div>
		</article>
	);
}
