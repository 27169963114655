import { MessageDescriptor, defineMessage, defineMessages } from "react-intl";

import { DateFormats, MappingOperations } from "./types";

export type ImportMappingFieldOption = {
	label: MessageDescriptor;
	options: {
		value: string;
		label: MessageDescriptor;
		config?: {
			type: "TEXT_INPUT" | "SELECT";
			operation: MappingOperations;
			label: MessageDescriptor;
			defaultValue: string;
			options?: {
				value: string;
				label: string | MessageDescriptor;
			}[];
		};
	}[];
};

export const fieldTranslations = defineMessages({
	firstName: {
		id: "contact.import.field.first_name",
		defaultMessage: "First name",
	},
	lastName: {
		id: "contact.import.field.last_name",
		defaultMessage: "Last name",
	},
	middleName: {
		id: "contact.import.field.middle_name",
		defaultMessage: "Middle name",
	},
	fullName: {
		id: "contact.import.field.fullname",
		defaultMessage: "Full name",
	},
	salutation: {
		id: "contact.import.field.salutation",
		defaultMessage: "Salutation",
	},
	email: {
		id: "contact.import.field.email",
		defaultMessage: "Email",
	},
	phone: {
		id: "contact.import.field.phone",
		defaultMessage: "Phone",
	},
	nationalRegistrationNumber: {
		id: "contact.import.field.national_registration_number",
		defaultMessage: "National registration number",
	},
	birthday: {
		id: "contact.import.field.birthday",
		defaultMessage: "Birthday",
	},
	sex: {
		id: "contact.import.field.sex",
		defaultMessage: "Sex",
	},
	uniqueIdentifier: {
		id: "contact.import.field.unique_identifier",
		defaultMessage: "Unique identifier",
	},
	ibanReimbursement: {
		id: "contact.import.field.iban_reimbursement",
		defaultMessage: "IBAN reimbursement",
	},
	accountManagers: {
		id: "contact.import.field.account_manager",
		defaultMessage: "Account managers",
	},
	"eid.cardNumber": {
		id: "contact.import.field.card_number",
		defaultMessage: "Card number",
	},
	"eid.expirationData": {
		id: "contact.import.field.expiration_date",
		defaultMessage: "Expiration date",
	},
	"address.[0].streetName": {
		id: "contact.import.field.street_name",
		defaultMessage: "Street name",
	},
	"address.[0].streetNumber": {
		id: "contact.import.field.street_number",
		defaultMessage: "Street number",
	},
	"address.[0].city": {
		id: "contact.import.field.city",
		defaultMessage: "City",
	},
	"address.[0].bus": {
		id: "contact.import.field.bus",
		defaultMessage: "Bus",
	},
	"address.[0].postalCode": {
		id: "contact.import.field.postal_code",
		defaultMessage: "Postal code",
	},
	"address.[0].country": {
		id: "contact.import.field.country",
		defaultMessage: "Country",
	},
	"linkedToContact.email": {
		id: "contact.import.field.linked_to_contact.email",
		defaultMessage: "Email (existing contact)",
	},
	"linkedToContact.phone": {
		id: "contact.import.field.linked_to_contact.phone",
		defaultMessage: "Phone (existing contact)",
	},
	"linkedToContact.nationalRegistrationNumber": {
		id: "contact.import.field.linked_to_contact.national_registration_number",
		defaultMessage: "National registration number (existing contact)",
	},
	"linkedToContact.uniqueIdentifier": {
		id: "contact.import.field.linked_to_contact.unique_identifier",
		defaultMessage: "Unique identifier (existing contact)",
	},
	"linkData.flowProxy": {
		id: "contact.import.field.link_data.flowProxy",
		defaultMessage: "Flow proxy",
	},
});

export const configTranslations = defineMessages({
	dateFormat: {
		id: "contact.import.field_config.date_format",
		defaultMessage: "Date format",
	},
});

export const dateFormatOptions: { value: DateFormats; label: string | MessageDescriptor }[] = [
	{
		value: "utc",
		label: defineMessage({
			id: "contact.import.date_format_option.iso_date",
			defaultMessage: "ISO standard date",
		}),
	},
	{
		value: "MM/DD/YYYY",
		label: "MM/DD/YYYY",
	},
	{
		value: "DD/MM/YYYY",
		label: "DD/MM/YYYY",
	},
];

export function toFields(type: "customer" | "linkedContact") {
	const baseOptions: Record<string, ImportMappingFieldOption> = {
		generalInfo: {
			label: defineMessage({
				id: "contact.import.field.general_info.title",
				defaultMessage: "General information",
			}),
			options: [
				{
					value: "firstName",
					label: fieldTranslations.firstName,
				},
				{
					value: "lastName",
					label: fieldTranslations.lastName,
				},
				{
					value: "middleName",
					label: fieldTranslations.middleName,
				},
				{
					value: "fullName",
					label: fieldTranslations.fullName,
				},
				{
					value: "salutation",
					label: fieldTranslations.salutation,
				},
				{
					value: "email",
					label: fieldTranslations.email,
				},
				{
					value: "phone",
					label: fieldTranslations.phone,
				},
				{
					value: "accountManagers",
					label: fieldTranslations.accountManagers,
				},
				{
					value: "nationalRegistrationNumber",
					label: fieldTranslations.nationalRegistrationNumber,
				},
				{
					value: "birthday",
					label: fieldTranslations.birthday,
					config: {
						type: "SELECT",
						operation: "FORMAT_DATE",
						defaultValue: "DD/MM/YYYY",
						options: dateFormatOptions,
						label: configTranslations.dateFormat,
					},
				},
				{
					value: "sex",
					label: fieldTranslations.sex,
				},
				{
					value: "uniqueIdentifier",
					label: fieldTranslations.uniqueIdentifier,
				},
				{
					value: "ibanReimbursement",
					label: fieldTranslations.ibanReimbursement,
				},
			],
		},
		eid: {
			label: defineMessage({
				id: "contact.import.field.eid.title",
				defaultMessage: "EID",
			}),
			options: [
				{
					value: "eid.cardNumber",
					label: fieldTranslations["eid.cardNumber"],
				},
				{
					value: "eid.expirationData",
					label: fieldTranslations["eid.expirationData"],
					config: {
						type: "SELECT",
						operation: "FORMAT_DATE",
						defaultValue: "DD/MM/YYYY",
						options: dateFormatOptions,
						label: configTranslations.dateFormat,
					},
				},
			],
		},
		address: {
			label: defineMessage({
				id: "contact.import.field.address.title",
				defaultMessage: "Address",
			}),
			options: [
				{
					value: "address.[0].streetName",
					label: fieldTranslations["address.[0].streetName"],
				},
				{
					value: "address.[0].streetNumber",
					label: fieldTranslations["address.[0].streetNumber"],
				},
				{
					value: "address.[0].city",
					label: fieldTranslations["address.[0].city"],
				},
				{
					value: "address.[0].bus",
					label: fieldTranslations["address.[0].bus"],
				},
				{
					value: "address.[0].postalCode",
					label: fieldTranslations["address.[0].postalCode"],
				},
				{
					value: "address.[0].country",
					label: fieldTranslations["address.[0].country"],
				},
			],
		},
	};

	if (type === "linkedContact") {
		baseOptions.linkedContact = {
			label: defineMessage({
				id: "contact.import.field.linked_contact.title",
				defaultMessage: "Existing contact",
			}),
			options: [
				{
					value: "linkedToContact.email",
					label: fieldTranslations["linkedToContact.email"],
				},
				{
					value: "linkedToContact.phone",
					label: fieldTranslations["linkedToContact.phone"],
				},
				{
					value: "linkedToContact.nationalRegistrationNumber",
					label: fieldTranslations["linkedToContact.nationalRegistrationNumber"],
				},
				{
					value: "linkedToContact.uniqueIdentifier",
					label: fieldTranslations["linkedToContact.uniqueIdentifier"],
				},
			],
		};
		baseOptions.linkedData = {
			label: defineMessage({
				id: "contact.import.field.linked_data.title",
				defaultMessage: "Linked data",
			}),
			options: [
				{
					value: "linkData.flowProxy",
					label: fieldTranslations["linkData.flowProxy"],
				},
			],
		};
	}

	return baseOptions;
}
