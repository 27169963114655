import React, { ReactNode } from "react";

export default function ShadowView({ children, className }: { children: ReactNode; className?: string }) {
	const attachShadow = (host) => {
		if (!host) return;

		const shadowRoot = host.attachShadow({ mode: "open" });

		[].slice.call(host.children).forEach((child) => {
			shadowRoot.appendChild(child);
		});
	};

	return (
		<div ref={attachShadow} className={className} style={{ all: "initial" }}>
			{children}
		</div>
	);
}
