import config from "../../config";

export function getBillingWarning({ total, limit }) {
	if (total >= limit) {
		return "FLOWS.REPORTS.STATUS_TYPES.MAX.LIMIT";
	}

	const warningThreshold = limit * config.banner.warningThreshold;

	if (total >= warningThreshold) {
		return "FLOWS.REPORTS.STATUS_TYPES.MAX.WARNING";
	}

	return undefined;
}

export function getIsDefaultError({ formValues, initialValues }) {
	if (initialValues?.isDefault && formValues?.isDefault === false) {
		return "FLOWS.REPORTS.STATUS_TYPES.MODAL.DEFAULT.WARNING_BANNER.NEED_ONE";
	}

	if (!initialValues?.isDefault && formValues?.isDefault) {
		return "FLOWS.REPORTS.STATUS_TYPES.MODAL.DEFAULT.WARNING_BANNER.REPLACE";
	}

	return undefined;
}
