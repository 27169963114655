import "./style.scss";
import type { FC } from "react";
import { Skeleton } from "antd";
import { AnimatePresence, motion } from "framer-motion";

interface ConversationLoadingProps {}

const ConversationLoading: FC<ConversationLoadingProps> = ({}) => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ delay: 0.5 }} // Show animation after 500 ms of loading
			className="cl-loading"
		>
			<Skeleton className="cl-loading-header" active paragraph={{ rows: 1 }} />
		</motion.div>
	);
};
export default ConversationLoading;
