import { CopyOutlined } from "@ant-design/icons";
import { Button, Input, Skeleton, Tooltip } from "antd";
import React from "react";
import { useIntl } from "react-intl";

import ShowSuccessBanner from "@/shared/components/banner/success";
import { clipboardHelpers } from "@/shared/helpers";

import style from "./style.module.scss";

export default function FlowLink({ link, isLoading }) {
	const intl = useIntl();

	const handleCopyFlowLink = () => {
		clipboardHelpers.copyToClipboard(link);

		ShowSuccessBanner({
			title: intl.formatMessage({
				id: "FLOWS.OVERVIEW.TABLE.BUTTON.COPY_FLOW_LINK.SUCCESS",
				defaultMessage: "Flow link copied to clipboard",
			}),
		});
	};

	if (!link || isLoading) {
		return <Skeleton active paragraph={false} />;
	}

	return (
		<Input.Group compact className={style.flow_link_group}>
			<Input
				readOnly
				name="link"
				className={style.flow_link_input}
				defaultValue={link}
				onClick={handleCopyFlowLink}
			/>
			<Tooltip title={intl.formatMessage({ id: "FLOWS.REPORT.SETTINGS.UNIQUE_LINK.TOOLTIP" })}>
				<Button onClick={handleCopyFlowLink} icon={<CopyOutlined />} type="primary" />
			</Tooltip>
		</Input.Group>
	);
}
