import Tag from "@/shared/components/antdWrappers/Tag";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Avatar, Button, List, notification } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router";
import { Component } from "../../../../components/atoms";
import { Section } from "../../../../components/organisms";
import ShowFailedBanner from "../../../../shared/components/banner/failed";
import { trpc } from "../../../../trpc";
import Loading from "./Loading";
import NotFound from "./NotFound";
import AccountManagerSelectModal from "./components/AccountManagerSelectModal";
import LabelSelectModal from "./components/LabelSelectModal";

export default function ContactDetail() {
	const history = useHistory();
	const params: { contactId: string; team: string } = useParams();
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();

	const [showAccountManagerSelectModal, setShowAccountManagerSelectModal] = useState(false);
	const [showLabelSelectModal, setShowLabelSelectModal] = useState(false);

	const removeLabelFromContactMutation = trpc.contact.removeLabelFromContact.useMutation();
	const removeAccountManagerMutation = trpc.contact.removeAccountManagerFromContact.useMutation();

	// @ts-ignore
	const { data: contact, isLoading: isContactLoading } = trpc.contact.getContactById.useQuery({
		id: params?.contactId,
	});
	const { data: contactLabels } = trpc.contactLabel.all.useQuery({
		limit: 0, // allow unlimited labels as there is no pagination
	});

	const { data: accountManagers } = trpc.member.getMembers.useQuery();

	const assignedAccountManagers = accountManagers?.filter((accountManager: { userId: string }) =>
		// @ts-ignore
		contact?.accountManagers?.includes(accountManager.userId)
	);

	const assignedLabels = contactLabels?.data?.filter((label) => contact?.labels?.includes(label._id));

	const restoreContactMutation = trpc.contact.restoreContact.useMutation({
		onSuccess: () => {
			notification.success({
				message: intl.formatMessage({
					id: "CONTACTS.DETAIL.STATE.DELETED.RESTORE.SUCCESS",
					defaultMessage: "Contact restored successfully.",
				}),
				placement: "bottomRight",
			});
			trpcUtils.contact.getContactById.invalidate({ id: params?.contactId });
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "CONTACTS.DETAIL.STATE.DELETED.RESTORE.ERROR",
					defaultMessage: "Something went wrong while executing restore.",
				}),
				placement: "bottomRight",
			});
		},
	});

	const unarchiveContactMutation = trpc.contact.dearchiveContact.useMutation({
		onSuccess: () => {
			notification.success({
				message: intl.formatMessage({
					id: "CONTACTS.DETAIL.STATE.ARCHIVED.UNARCHIVE.SUCCESS",
					defaultMessage: "Contact unarchived successfully.",
				}),
				placement: "bottomRight",
			});
			trpcUtils.contact.getContactById.invalidate({ id: params?.contactId });
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "CONTACTS.DETAIL.STATE.ARCHIVED.UNARCHIVE.ERROR",
					defaultMessage: "Something went wrong while executing unarchive.",
				}),
				placement: "bottomRight",
			});
		},
	});

	if (isContactLoading) return <Loading />;

	if (!contact) return <NotFound />;

	return (
		<>
			{contact.archivedAt && !contact.deletedAt && (
				<div style={{ padding: "2em 2em 0 2em", width: "100%", textAlign: "left" }}>
					<Alert
						message={intl.formatMessage({
							id: "CONTACTS.DETAIL.STATE.ARCHIVED",
							defaultMessage: "This contact is currently archived",
						})}
						type="warning"
						action={[
							<Button
								loading={unarchiveContactMutation.isLoading}
								type="text"
								size="small"
								onClick={() => {
									unarchiveContactMutation.mutate({
										contactId: params.contactId,
									});
								}}
							>
								<FormattedMessage
									id="CONTACTS.OVERVIEW.BULK_ACTION.UNARCHIVE.BUTTON.TITLE"
									defaultMessage="Unarchive"
								/>
							</Button>,
						]}
					/>
				</div>
			)}
			{contact.deletedAt && (
				<div style={{ padding: "2em 2em 0 2em", width: "100%", textAlign: "left" }}>
					<Alert
						message={intl.formatMessage({
							id: "CONTACTS.DETAIL.STATE.DELETED",
							defaultMessage: "This contact is currently deleted",
						})}
						type="error"
						action={[
							<Button
								loading={restoreContactMutation.isLoading}
								type="text"
								size="small"
								onClick={() => {
									restoreContactMutation.mutate({
										contactId: params.contactId,
									});
								}}
							>
								<FormattedMessage
									id="CONTACTS.OVERVIEW.BULK_ACTION.RESTORE.BUTTON.TITLE"
									defaultMessage="Restore"
								/>
							</Button>,
						]}
					/>
				</div>
			)}
			<div className="t-section t-section--contact-detail">
				<Section.OContact
					// @ts-ignore
					contact={contact}
					className="contact-detail--contact-info"
					onSendMessage={() => history.push(`/${params?.team}/inbox`)}
					onMetaClick={() => history.push(`/${params?.team}/contact/id/${params?.contactId}/attributes`)}
				/>
				<Component.ADashboardCard
					className="contact-detail--team-info"
					style={{ maxHeight: "unset" }}
					childStyle={{ display: "flex", flexDirection: "column", textAlign: "left" }}
				>
					<div style={{ width: "100%" }} className="t-gap--bottom">
						<p className="t-gap--bottom-sm">
							<FormattedMessage id="CONTACTS.DETAIL.ACCOUNT_MANAGER.TITLE" />
						</p>
						<List style={{ textAlign: "left" }}>
							{!!assignedAccountManagers?.length &&
								assignedAccountManagers.map(
									(accountManager: {
										userId: string;
										avatar: string;
										fullName?: string;
										name?: string;
									}) => (
										<List.Item key={accountManager.userId}>
											<List.Item.Meta
												avatar={
													<Avatar src={accountManager.avatar} size="small" shape="square" />
												}
												title={
													<p style={{ fontWeight: "500" }}>
														{accountManager?.fullName || accountManager?.name}
													</p>
												}
											/>
											<Button
												type="text"
												danger
												onClick={() => {
													removeAccountManagerMutation.mutate(
														{
															contactId: contact?._id || "",
															accountManagerId: accountManager?.userId || "",
														},
														{
															onSuccess: () => {
																trpcUtils.contact.getContactById.invalidate();
															},
															onError: () => {
																ShowFailedBanner({
																	title: intl.formatMessage({
																		id: "CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.REMOVE.FAILED",
																		defaultMessage:
																			"Failed to remove account manager",
																	}),
																});
															},
														}
													);
												}}
												icon={<DeleteOutlined />}
											/>
										</List.Item>
									)
								)}
						</List>
						<Button
							icon={<PlusOutlined />}
							style={{ textAlign: "left", padding: 0 }}
							type="text"
							onClick={() => setShowAccountManagerSelectModal(true)}
						>
							{""} <FormattedMessage id="CONTACTS.DETAIL.ADD.ACCOUNT_MANAGER" />
						</Button>
					</div>
					<div>
						<p className="t-gap--bottom-sm">
							<FormattedMessage id="CONTACTS.DETAIL.LABELS.TITLE" />
						</p>
						<div style={{ textAlign: "left", display: "flex", flexWrap: "wrap" }}>
							{assignedLabels?.map((label) => (
								<Tag
									color={label.color}
									style={{
										marginBottom: 8,
										display: "flex",
										width: "min-content",
									}}
									key={label._id}
									title={label.name}
									closable
									onClose={() => {
										removeLabelFromContactMutation.mutate(
											{ contactId: params?.contactId, labelId: label._id },
											{
												onSuccess: () => {
													trpcUtils.contact.getContactById.invalidate();
												},
												onError: () => {
													ShowFailedBanner({
														title: intl.formatMessage({
															id: "CONTACTS.DETAIL.MODAL.LABELS.REMOVE.FAILED",
															defaultMessage: "Failed to remove labels",
														}),
													});
												},
											}
										);
									}}
								>
									<p
										style={{
											maxWidth: "20ch",
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
										}}
									>
										{label.name}
									</p>
								</Tag>
							))}
						</div>
						<Button
							icon={<PlusOutlined />}
							style={{ textAlign: "left", padding: 0 }}
							type="text"
							onClick={() => setShowLabelSelectModal(true)}
						>
							{""} <FormattedMessage id="CONTACTS.DETAIL.MODAL.LABELS.ADD" />
						</Button>
					</div>
				</Component.ADashboardCard>
				<AccountManagerSelectModal
					isOpen={showAccountManagerSelectModal}
					setIsOpen={setShowAccountManagerSelectModal}
				/>
				<LabelSelectModal isOpen={showLabelSelectModal} setIsOpen={setShowLabelSelectModal} />
			</div>
		</>
	);
}
