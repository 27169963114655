import dompurify from "dompurify"; //prevent xss
import { decode } from "html-entities";
import React from "react";

import { emailHelpers } from "../../../helpers";
import { MarkdownText } from "../../../shared/components/text/markdown";
import { Component } from "../../atoms";

function dumpurifyHook(node) {
	//Open all links in new tab
	if ("href" in node) {
		node.setAttribute("target", "_blank");
		node.setAttribute("rel", "noopener noreferrer");
	}
}

function MEmail({ html, text, showThread, className }) {
	if (!html) {
		return (
			<div className="m-data-preview--email">
				{/* use paragrah (slate) for clickable links */}
				<MarkdownText value={decode(text)} className="data-preview--text" />
			</div>
		);
	}

	const htmlObject = showThread ? html : emailHelpers.removeThreadingEmail({ html });

	dompurify.addHook("beforeSanitizeAttributes", function (node) {
		dumpurifyHook(node);
	});
	dompurify.addHook("afterSanitizeAttributes", function (node) {
		dumpurifyHook(node);
	});

	return (
		<div className={`m-data-preview--email ${className}`}>
			<Component.AShadowDom>
				<div
					style={{ width: "100%", maxWidth: "100%", overflowY: "auto" }}
					dangerouslySetInnerHTML={{ __html: dompurify.sanitize(htmlObject) }}
				/>
			</Component.AShadowDom>
		</div>
	);
}

MEmail.defaultProps = {
	html: undefined,
	text: "",
	className: "",
};

export default React.memo(MEmail); //Improves UI scroll enormously
