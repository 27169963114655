import { actions as dashboardActions } from "@/store/dashboard";
import { trpc } from "@/trpc";
import { IOpeningHours } from "@bothive_core/database";
import isEqual from "lodash.isequal";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import OfficeHours from "./components/OfficeHours";
import RelatedSettings from "./components/RelatedSettings";
import config from "./config";

export default function OpeningHours() {
	const routeParameters = useParams();
	const dispatch = useDispatch();
	const intl = useIntl();
	const { data: organization } = trpc.organization?.getOrganization?.useQuery();
	const { allowEditing } = useSelector(
		(state) => ({
			allowEditing: state.dashboard.permissions.userPermissions.editSettings,
		}),
		isEqual
	);
	useEffect(() => {
		// @ts-ignore
		const breadcrumbs = config.breadcrumbs(routeParameters);

		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					...config,
					breadcrumbs,
				},
				intl,
			})
		);
	}, []);
	return (
		<section className="organizationContainer">
			<OfficeHours
				allowEditing={allowEditing}
				openingHours={organization?.openingHours as { default: IOpeningHours }}
			/>
			<h2 className="t-gap--top" style={{ marginBottom: "10px" }}>
				<FormattedMessage
					id="ORGANIZATION_SETTINGS.GENERAL.SUGGESTIONS.TITLE"
					defaultMessage="Settings you might find useful"
				/>
			</h2>
			<hr />
			<RelatedSettings slug={organization?.slug || ""} />
		</section>
	);
}
