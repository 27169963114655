import axios from "axios";
import { Crop } from "react-image-crop";

import { apiConfig, fileConfig } from "../../config";

export type IFileErrorCode = "INVALID_OBJECT" | "INVALID_TYPE" | "FILE_SIZE_TO_BIG";
class FileError extends Error {
	code: IFileErrorCode = "INVALID_OBJECT";

	constructor({ message, code }: { message: string; code: IFileErrorCode }) {
		super(message);

		this.code = code;

		Error.captureStackTrace(this, this.constructor);
	}
}

export function dataURLtoFile(dataurl: string, filename: string) {
	let arr = dataurl.split(","),
		mime = arr[0]?.match(/:(.*?);/)?.[1],
		bstr = Buffer.from(arr[1], "base64").toString("binary"),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}

interface IImageUploadWithPreview {
	file: File;
	config?: {
		size: number;
	};
}
export interface IImageUploadResult {
	url: string | ArrayBuffer | null;
	name: string;
	type: string;
}

export function imageUploadWithPreview({ file, config }: IImageUploadWithPreview): Promise<IImageUploadResult> {
	const _config = { size: 10000000, ...(config || {}) };

	return new Promise((resolve, reject) => {
		// @ts-ignore
		if (file === undefined || file === null || file === []) return null;

		if (file.size > _config?.size) {
			reject(new FileError({ message: "File is to large", code: "FILE_SIZE_TO_BIG" }));
		}

		if (!fileConfig.fileTypes.image.includes(file.type.replace(/[a-zA-Z0-9]+\//, "").toLowerCase())) {
			reject(new FileError({ message: "File type is not supported", code: "INVALID_TYPE" }));
		}

		const reader = new FileReader();

		reader.addEventListener(
			"load",
			() => {
				resolve({
					url: reader.result,
					name: file.name,
					type: file.type,
				});
			},
			true
		);

		reader.readAsDataURL(file);
	});
}

export function preventFileDragOver({ selector }: { selector: string }) {
	const element = document.querySelector(selector);

	if (!element) return;

	element.addEventListener("dragover", (ev) => ev.preventDefault());
}
interface IAllowFileDrop {
	selector: string;
	callback: (value: FileList | undefined) => void;
}
export function allowFileDrop({ selector, callback }: IAllowFileDrop) {
	const element = document.querySelector(selector);

	if (!element) return;

	element.addEventListener("drop", (event) => {
		event.preventDefault();

		const evt = event as DragEvent;

		if (!evt) return;

		callback(evt.dataTransfer?.files);
	});
}

interface IImageToCanvas {
	src: string;
	minHeight?: number;
	minWidth?: number;
}
export function imageToCanvas({ src, minHeight = 0, minWidth = 0 }: IImageToCanvas): Promise<string | undefined> {
	return new Promise((resolve, reject) => {
		if (!src) return;

		try {
			const image = new Image();
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");

			if (!context) return;

			image.src = src;

			image.onload = function () {
				const maxSize = Math.max(image.width, image.height);
				const canvasWidth = Math.max(maxSize, minWidth);
				const canvasHeight = Math.max(maxSize, minHeight);

				const widthOffset = (canvasWidth - image.width) / 2;
				const heightOffset = (canvasHeight - image.height) / 2;

				canvas.width = canvasWidth;
				canvas.height = canvasHeight;
				context.fillStyle = "#FFFFFF";
				context.fillRect(0, 0, canvasWidth, canvasHeight);
				context.drawImage(image, widthOffset, heightOffset, image.width, image.height);

				resolve(canvas.toDataURL());
			};
		} catch (error) {
			reject(error);
		}
	});
}

interface ICropImage {
	image: HTMLImageElement;
	crop: Crop;
	fileName: string;
	fileType: string;
}
export function cropImage({ image, crop, fileName, fileType }: ICropImage): Promise<File> {
	return new Promise((resolve) => {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;

		if (!crop?.width || !crop?.height) return;

		canvas.width = crop.width;
		canvas.height = crop.height;

		const ctx = canvas.getContext("2d");

		if (!ctx) return;

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		canvas.toBlob((blob) => {
			if (!blob) return;

			resolve(new File([blob], fileName, { type: fileType }));
		}, fileType);
	});
}

export function createPreviewFromFile(file: File): Promise<string> {
	return new Promise((resolve) => {
		const reader = new FileReader();

		reader.addEventListener("load", () => resolve(reader.result as string), true);
		reader.readAsDataURL(file);
	});
}

export async function uploadImage(file: File): Promise<{ url: string }> {
	const body = new FormData();

	body.append("file", file);

	if (file.size > fileConfig.imageSize) {
		throw new FileError({ message: "File is to large", code: "FILE_SIZE_TO_BIG" });
	}

	if (!fileConfig.fileTypes.image.includes(file.type.replace(/[a-zA-Z0-9]+\//, "").toLowerCase())) {
		throw new FileError({ message: "File type is not supported", code: "INVALID_TYPE" });
	}

	try {
		const result = await axios.post(`${apiConfig.fileUrl}/v1/image`, body);

		return result.data.data;
	} catch (error) {
		throw new FileError({ message: "Upload failed", code: "INVALID_OBJECT" });
	}
}

export function downloadFileFromUrl({ url, fileName }: { url: string; fileName: string }) {
	window.open(url, fileName);
}

export function downloadZip({ zip, zipName }: { zip: string; zipName: string }) {
	const url = window.URL.createObjectURL(new Blob([zip], { type: "application/zip" }));
	const link = document.createElement("a");

	link.href = url;
	link.setAttribute("download", zipName);
	link.setAttribute("charset", "utf-16");
	document.body.appendChild(link);
	link.click();

	if (document.contains(link)) {
		// don't remove link if it already removed from body
		document.body.removeChild(link);
	}
}
export async function downloadFile({ file, fileName, type }: { file: Blob; fileName: string; type: string }) {
	let blob: Blob;

	if (type === "xlsx") {
		blob = new Blob([file], { type: "data:application/vnd.ms-excel;base64" });
	} else {
		blob = new Blob(["\ufeff", file], { type: "text/plain;charset=utf-16" });
	}

	const url = window.URL.createObjectURL(blob);
	const link = document.createElement("a");

	link.href = url;
	link.setAttribute("download", fileName);

	document.body.appendChild(link);
	link.click();

	if (document.contains(link)) document.body.removeChild(link);
}

export default {
	dataURLtoFile,
	imageUploadWithPreview,
	preventFileDragOver,
	allowFileDrop,
	imageToCanvas,
	cropImage,
	createPreviewFromFile,
	uploadImage,
	downloadFileFromUrl,
	downloadFile,
	downloadZip,
};
