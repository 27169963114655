import settingsConfig from "@/modules/settings/config";

export default {
	title: "ORGANIZATION_SETTINGS.GENERAL.BREADCRUMB.TITLE",
	tabTitle: "ORGANIZATION_SETTINGS.GENERAL.BREADCRUMB.TITLE",
	breadcrumbs: ({ team }) => [
		{
			to: settingsConfig.routes.root.replace(":team", team),
			name: "settings.advanced.breadcrumb.settings",
		},
		{
			to: settingsConfig.routes.organization.general.replace(":team", team),
			name: "ORGANIZATION_SETTINGS.GENERAL.BREADCRUMB.TITLE",
		},
	],
	experimental: {
		id: "experimentalFeatures",
		title: "SETTINGS.ADVANCED.EXPERIMENTAL_FEATURES.ACCORDION.TITLE",
		tooltip: "SETTINGS.ADVANCED.EXPERIMENTAL_FEATURES.TOOLTIP",
		form: {
			isEnabled: {
				label: "SETTINGS.ADVANCED.EXPERIMENTAL_FEATURES.TOGGLE.LABEL",
				help: "SETTINGS.ADVANCED.EXPERIMENTAL_FEATURES.TOGGLE.HELP",
			},
		},
		submit: "SETTINGS.ADVANCED.EXPERIMENTAL_FEATURES.SUBMIT",
		list: [
			// EXAMPLE
			// {
			// 	label: "SETTINGS.ADVANCED.EXPERIMENTAL_FEATURES.LIST.INBOX_INTEGRATIONS.LABEL",
			// 	help: "SETTINGS.ADVANCED.EXPERIMENTAL_FEATURES.LIST.INBOX_INTEGRATIONS.HELP",
			// },
		],
	},
	dangerZone: {
		id: "dangerZone",
		title: "SETTINGS.ADVANCED.DANGER_ZONE.ACCORDION_TITLE",
		section: {
			title: "SETTINGS.ADVANCED.DANGER_ZONE.TITLE",
			description: "SETTINGS.ADVANCED.DANGER_ZONE.DESCRIPTION",
			buttonLabel: "SETTINGS.ADVANCED.DANGER_ZONE.BUTTON",
		},
	},
};
