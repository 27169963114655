import "./style.scss";

import React from "react";
import { FormattedMessage } from "react-intl";

export default function HeaderDescription({ title, description, className }) {
	return (
		<header className={`header-description ${className}`}>
			<h1>
				<FormattedMessage id={title} />
			</h1>
			{description && (
				<p>
					<FormattedMessage id={description} />
				</p>
			)}
		</header>
	);
}
