import { Alert, Button, Modal, Typography } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";

import { automationStatusSelector } from "../state";
import style from "./style.module.scss";

export default function ConfirmationModal({ rule, visible, onSubmit, onClose }) {
	const intl = useIntl();
	const routerParams = useParams();
	const reportId = routerParams.reportId;
	const ruleStatus = useRecoilValueLoadable(automationStatusSelector(reportId));

	if (!rule?.ui?.requireConfirm) {
		return null;
	}

	const modalConfig = rule.ui.confirm;
	const hasBeenSentSuccessful = ruleStatus.contents[rule._id]?.successHistory?.includes(true);

	return (
		<Modal
			open={visible}
			onCancel={onClose}
			onOk={onSubmit}
			title={modalConfig.title[intl.locale]}
			transitionName="" // to disable animation
			footer={
				<footer className={style.confirmation_modal_footer}>
					<Button key="cancel" type="text" onClick={onClose}>
						<FormattedMessage id="FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.CONFIRMATION_MODAL.CANCEL" />
					</Button>
					<Button key="submit" type="primary" onClick={onSubmit}>
						{modalConfig.submitLabel[intl.locale]}
					</Button>
				</footer>
			}
		>
			{hasBeenSentSuccessful && (
				<Alert
					message={modalConfig.duplicateWarning[intl.locale]}
					className="t-gap--bottom"
					type="warning"
					showIcon
				/>
			)}
			<Typography.Text className={style.confirmation_modal_content}>
				{modalConfig.description[intl.locale]}
			</Typography.Text>
		</Modal>
	);
}
