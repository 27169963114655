import { Alert, Skeleton } from "antd";
import isEqual from "lodash.isequal";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE } from "recoil";

import { automationSelector } from "@/flows/report_detail/meta_aside/automation/state";
import ImportContacts from "@/modules/contacts/import";
import DeleteCollapse from "@/shared/components/deleteCollapse";
import { actions as dashboardActions } from "@/store/dashboard";
import { trpc } from "@/trpc";
import { deleteConnection } from "../common/helpers";
import IntegrationConnection from "./IntegrationConnection";
import config from "./config";
import { Layout } from "@/components/templates";
import AdminPulseWebhook from "./components/webhook";
import AdminPulseTasks from "./components/tasks";
import AdminPulseAuditLog from "./components/auditLogs";

export default function AdminPulse() {
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const trpcUtils = trpc.useUtils();
	const [showImportModal, setShowImportModal] = useState(false);
	const [checkCanAddIntegration, setCheckCanAddIntegration] = useState(false);
	const routeParameters = useParams() as { connectionId: string; team: "string" };
	const deleteConnectionMutation = trpc.integration.deleteConnection.useMutation();

	const invalidateRules = useRecoilRefresher_UNSTABLE(automationSelector);

	const { data: connections, isLoading: connectionLoading } = trpc.integration?.getConnections?.useQuery({
		query: { populate: true, category: "5c38a182092c99228ead1a32" }, // accountancy category
	});

	const { maxIntegrations, allowEditingIntegrations } = useSelector(
		(state) => ({
			maxIntegrations: state.dashboard.permissions.integrations,
			allowEditingIntegrations: state.dashboard.permissions.userPermissions.editIntegrations,
		}),
		isEqual
	);

	const connection = useMemo(() => {
		return connections?.data?.find((connection) => connection._id === routeParameters.connectionId);
	}, [connections]);

	useEffect(() => {
		const breadcrumbs = config.breadcrumbs(routeParameters);

		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					...config,
					breadcrumbs,
				},
				intl,
			})
		);
	}, []);

	useEffect(() => {
		if (!connections?.data?.length) return setCheckCanAddIntegration(true);

		setCheckCanAddIntegration(connections?.data?.length < maxIntegrations);
	}, [connections, maxIntegrations]);

	if (connectionLoading) return <Layout.TLoadScreen className="sub-page" />;

	if (!connection && !connectionLoading) {
		return (
			<section
				className="organizationContainer"
				style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "2em" }}
			>
				{!checkCanAddIntegration && (
					<Alert
						message={intl.formatMessage({
							id: "settings.integrations.adminpulse.notification.limit_reached",
							defaultMessage: "You have no integrations left in your current plan.",
						})}
						type="warning"
						showIcon
						style={{ width: "60%", margin: "0 auto", textAlign: "center" }}
					/>
				)}
				<IntegrationConnection
					allowEditing={allowEditingIntegrations}
					canAddIntegration={checkCanAddIntegration}
				/>
			</section>
		);
	}

	return (
		<section
			className="organizationContainer"
			style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "2em" }}
		>
			<AdminPulseWebhook />
			<AdminPulseTasks />
			<AdminPulseAuditLog />
			<ImportContacts
				open={showImportModal}
				integrationId={connection?.integrationId}
				onClose={() => setShowImportModal(false)}
			/>
			{!connectionLoading && allowEditingIntegrations && (
				<DeleteCollapse
					headerTitle={intl.formatMessage({
						id: "SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.ACCORDION_TITLE",
						defaultMessage: "Delete integration",
					})}
					panelTitle={intl.formatMessage({
						id: "SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.TITLE",
						defaultMessage: "Delete integration with Admin Pulse",
					})}
					modalDescription={intl.formatMessage({
						id: "SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.DESCRIPTION",
						defaultMessage:
							"Are you sure that you want to stop the integration with Admin Pulse? All current functionalities will not be available to your users anymore when you delete this integration.",
					})}
					buttonText={intl.formatMessage({
						id: "SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.BUTTON",
						defaultMessage: "Delete integration",
					})}
					deleteAction={() => {
						deleteConnection(
							connection?._id,
							intl,
							trpcUtils,
							deleteConnectionMutation,
							history,
							routeParameters.team
						);

						invalidateRules();
					}}
				/>
			)}
		</section>
	);
}
