import { atom, selector } from "recoil";

import config from "./config";

// You might need to use the hook instead of using this atom directly
export const flowMeta = atom({
	key: "foFlowMeta",
	default: {
		limit: config.pagination.limit,
		compact: true,
		offset: 0,
		total: 0,
		totalEnabled: 0,
		totalEnabledPremium: 0,
		q: "",
	},
});

export const flowListState = atom({
	key: "foFlowListState",
	default: [],
});

export const flowTablePageState = atom({
	key: "froFlowTablePage",
	default: 1,
});

// Helper selectors -> gets shouldn't be used
export const addFlow = selector({
	key: "frsAddFlow",
	get: ({ get }) => get(flowListState),
	set: ({ get, set }, flow) => {
		const meta = get(flowMeta);
		const list = get(flowListState);

		set(flowMeta, { ...meta, total: meta.total + 1 });
		set(flowListState, [...list, flow]);
	},
});
export const updateFlowList = selector({
	key: "frsUpdateFlowList",
	get: () => [],
	set: ({ get, set }, { flowId, payload }) => {
		const meta = get(flowMeta);
		const list = get(flowListState);
		const mutatedList = list.map((flow) => (flow._id === flowId ? { ...flow, ...payload } : flow));

		const updatedFlowPrevState = list.find((flow) => flow._id === flowId);

		if (updatedFlowPrevState?.isEnabled !== payload.isEnabled) {
			set(flowMeta, {
				...meta,
				totalEnabled: updatedFlowPrevState?.isEnabled ? meta.totalEnabled + 1 : meta.totalEnabled - 1,
				totalEnabledPremium:
					updatedFlowPrevState?.isEnabled && !updatedFlowPrevState?.template.freemium
						? meta.totalEnabledPremium - 1
						: meta.totalEnabledPremium + 1,
			});
		}

		set(flowListState, mutatedList);
	},
});
export const removeFlow = selector({
	key: "frsRemoveFlow",
	get: ({ get }) => get(flowListState),
	set: ({ get, set }, flowId) => {
		const meta = get(flowMeta);
		const list = get(flowListState);

		const deletedFlow = list.find((flow) => flow._id === flowId);

		set(flowMeta, {
			...meta,
			total: meta.total - 1,
			totalEnabled: deletedFlow?.isEnabled ? meta.totalEnabled - 1 : meta.totalEnabled,
			totalEnabledPremium:
				deletedFlow?.isEnabled && !deletedFlow.template.freemium
					? meta.totalEnabledPremium - 1
					: meta.totalEnabledPremium,
		});
		set(
			flowListState,
			list.filter((status) => status.id !== flowId)
		);
	},
});
