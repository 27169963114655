export default {
	progressState: {
		completed: "COMPLETED",
		inProgress: "IN_PROGRESS",
		inviteSend: "INVITE_SEND",
		created: "CREATED",
		pendingSend: "PENDING_SEND",
	},
	templateTypes: {
		firstSend: "firstSend",
		reminding: "reminding",
	},
	reportType: {
		csv: "csv",
		excel: "excel",
		json: "json",
		pdf: "pdf",
	},
};
