const baseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:8980"; //eslint-disable-line no-undef

const endpoints = {
	api: "/api/v1",
};

export default {
	baseUrl,
	endpoints,
	template: {
		getAll: `${baseUrl}${endpoints.api}/flow/template`,
		getConfig: `${baseUrl}${endpoints.api}/flow/template/:templateId/configuration`,
	},
};
