import actionTypes from "./permissions.actionTypes";

const initialState = {
	domains: 3,
	flows: 0,
	editIntents: false,
	viewIntents: false,
	integrations: 0,
	locations: 1,
	languages: 1,
	triggers: 0,
	users: 3,
	clientsOverview: false,
	userPermissions: {
		editChatbot: false,
		editFlows: false,
		editQuestions: false,
		editBilling: false,
		editSettings: false,
		editIntegrations: false,
		readAllContactConversations: false,
		manageUsers: false,
		superAdmin: false,
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.SET_TEAM_PERMISSIONS:
			return {
				...state,
				...action.data,
			};
		case actionTypes.SET_USER_PERMISSIONS:
			return {
				...state,
				userPermissions: {
					...state.userPermissions,
					...action.data,
				},
			};
		default:
			return state;
	}
}
