import React from "react";
import { FormattedMessage } from "react-intl";

export default function MDefaultHeader({ id, title, className }) {
	return (
		<header id={id} className={`m-header m-header--default ${className}`}>
			<h2>
				<FormattedMessage id={title} />
			</h2>
		</header>
	);
}

MDefaultHeader.defaultProps = {
	id: "",
	className: "",
};
