import "./style.scss";

import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";

import AddFlowSelectDetail from "@/flows/overview/create/detail";
import AddFlowOverview from "@/flows/overview/create/overview";
import { isAddFlowModalVisibleState, selectedTemplateIdState } from "@/flows/overview/create/state";
import PopupFullscreen from "@/shared/components/popup/full_screen";

export default function AddFlow() {
	const history = useHistory();

	const [isModalVisible, setIsModalVisible] = useRecoilState(isAddFlowModalVisibleState);
	const [selectedTemplateId, setSelectedTemplateId] = useRecoilState(selectedTemplateIdState);

	const handleSelectTemplate = (templateId) => {
		setSelectedTemplateId(templateId);
	};

	const handleClose = () => {
		setIsModalVisible(false);
		setSelectedTemplateId(undefined);
		history.push({ search: "" });
	};

	if (!isModalVisible) {
		return null;
	}

	return (
		<PopupFullscreen onClose={handleClose}>
			{selectedTemplateId ? (
				<AddFlowSelectDetail />
			) : (
				<AddFlowOverview handleSelectTemplate={handleSelectTemplate} />
			)}
		</PopupFullscreen>
	);
}
