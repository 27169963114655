export function getWarningBannerConfig({ isLocked, doesFlowExist }) {
	if (isLocked) {
		return {
			title: "FLOWS.OVERVIEW.ADD_FLOW.WARNING.LOCKED.TITLE",
			description: "FLOWS.OVERVIEW.ADD_FLOW.WARNING.LOCKED.DESCRIPTION",
		};
	}

	if (doesFlowExist.contents) {
		return {
			title: "FLOWS.OVERVIEW.ADD_FLOW.WARNING.EXISTS.TITLE",
			description: "FLOWS.OVERVIEW.ADD_FLOW.WARNING.EXISTS.DESCRIPTION",
		};
	}

	return undefined;
}
