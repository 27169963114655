import "./style.scss";

import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, List, Tooltip, Typography, notification } from "antd";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { flowState } from "@/flows/common/state";
import { fileHelpers } from "@/shared/helpers";
import { ReportRouterOutput } from "@/trpc";
import UploadFiles from "./UploadFiles";
import { downloadAttachments } from "./query";
import { isReportUploadOpenState } from "./state";
import { getFieldLabels } from "./utils";

export default function FilesOverview({ report }: { report?: ReportRouterOutput["getById"] }) {
	const intl = useIntl();
	const { flowId } = useParams<{ flowId: string }>();
	const [isDownloading, setIsDownloading] = useState(false);

	const flow = useRecoilValueLoadable(flowState({ id: flowId }));
	const language = useSelector((state) => state.dashboard.header.lang);
	const setIsReportUploadOpen = useSetRecoilState(isReportUploadOpenState);

	const fileLabels = useMemo(() => {
		if (!flow?.contents?.template?.fields?.length) return [];

		return getFieldLabels(flow?.contents?.template?.fields);
	}, [flow?.contents?.template?.fields]);
	const files = useMemo(() => {
		if (!fileLabels?.length) return [];

		return fileLabels
			.map((label) => {
				const items = report?.data?.[label.key];

				if (!items?.length || !Array.isArray(items)) return [];

				return items.map((item, index) => ({
					...(item as any), // Quick fix because Landbot can give string values
					labelName: `${label.name[language]}${!index ? "" : ` ${index}`}`,
				}));
			})
			.flat()
			.filter(Boolean) as { _id: string; labelName: string; url: string; previewUrl: string }[];
	}, [report, fileLabels]);

	const handleDownloadAttachments = async () => {
		const notificationKey = "downloadStarted";

		try {
			if (!files?.length || !report) return;

			setIsDownloading(true);

			notification.info({
				key: notificationKey,
				message: intl.formatMessage({
					id: "flows.report_detail.files.download_attachments.button.banner.started",
					defaultMessage: "Download has started, this can take a while",
				}),
				placement: "bottomRight",
				duration: 8,
			});

			const result = await downloadAttachments({ reportId: report._id });

			notification.destroy(notificationKey);

			if (result.status === 204) {
				setIsDownloading(false);
				notification.destroy(notificationKey);
				return notification.error({
					message: intl.formatMessage({
						id: "flows.report_detail.files.download_attachments.button.banner.no_result.title",
						defaultMessage: "No attachments found",
					}),
					placement: "bottomRight",
					duration: 8,
				});
			}

			const contact = report?.contact || report?.data;
			const fileName = intl.formatMessage(
				{
					id: "flows.report_detail.files.download_attachments.file_name",
					defaultMessage: "{flowName}_{name}_attachments",
				},
				{
					name: contact.fullName || [contact.firstName, contact.lastName].join("_"),
					flowName: flow?.contents?.name || flow?.contents?.template?.name?.[intl.locale],
				}
			);

			fileHelpers.downloadZip({ zip: result.data, zipName: `${fileName}.zip` });

			setIsDownloading(false);
			notification.success({
				message: intl.formatMessage({
					id: "flows.report_detail.files.download_attachments.button.banner.succeeded.title",
					defaultMessage: "Attachments downloaded",
				}),
				placement: "bottomRight",
				duration: 8,
			});
		} catch (err) {
			const error = err as { response: { status: number } };

			setIsDownloading(false);
			notification.destroy(notificationKey);
			console.error("Failed to download attachments", err);

			if (error.response?.status === 404) {
				return notification.error({
					message: intl.formatMessage({
						id: "flows.report_detail.files.download_attachments.button.banner.error_404.title",
						defaultMessage: "No attachments found",
					}),
					description: intl.formatMessage({
						id: "flows.report_detail.files.download_attachments.button.banner.error_404.description",
						defaultMessage: "Please try again later or contact customer support",
					}),
					placement: "bottomRight",
					duration: 8,
				});
			}

			notification.error({
				message: intl.formatMessage({
					id: "flows.report_detail.files.download_attachments.button.banner.generic_error.title",
					defaultMessage: "Failed to download attachments",
				}),
				description: intl.formatMessage({
					id: "flows.report_detail.files.download_attachments.button.banner.generic_error.description",
					defaultMessage: "Please try again later or contact customer support",
				}),
				placement: "bottomRight",
				duration: 8,
			});
		}
	};

	if (!fileLabels?.length) return null;

	return (
		<section className="report_detail-meta-files_group">
			<header className="files_group-header">
				<p>
					<FormattedMessage
						id="flows.report_detail.files.title"
						defaultMessage="Files {total, plural, =0 { } other {(#)}}"
						values={{ total: files?.length }}
					/>
				</p>
				<div className="files_group-header-wrapper">
					<Tooltip
						title={
							files.length
								? intl.formatMessage({
										id: "flows.report_detail.files.download.tooltip",
										defaultMessage: "Download all files",
								  })
								: intl.formatMessage({
										id: "flows.report_detail.files.download.tooltip.disabled",
										defaultMessage: "When your rapport contains files, you can download them here",
								  })
						}
					>
						<Button
							type="text"
							size="small"
							loading={isDownloading}
							disabled={!files.length}
							icon={<DownloadOutlined />}
							onClick={handleDownloadAttachments}
						/>
					</Tooltip>
					<Tooltip
						title={intl.formatMessage({
							id: "flows.report_detail.files.add_files.tooltip",
							defaultMessage: "Upload new files to the report",
						})}
					>
						<Button
							type="text"
							size="small"
							icon={<PlusOutlined />}
							onClick={() => setIsReportUploadOpen(true)}
						/>
					</Tooltip>
				</div>
			</header>
			<List
				size="small"
				rowKey="_id"
				dataSource={files}
				locale={{
					emptyText: intl.formatMessage({
						id: "flows.report_detail.files.empty",
						defaultMessage: "Report has no files",
					}),
				}}
				pagination={files?.length > 5 && { pageSize: 5, size: "small" }}
				renderItem={(item) => (
					<List.Item className="files_group-list-item">
						<Typography.Link ellipsis href={item.url} target="_blank">
							{item.labelName}
						</Typography.Link>
					</List.Item>
				)}
			/>
			<UploadFiles report={report} />
		</section>
	);
}
