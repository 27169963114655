import "./style.scss";
import ContactConversationFilters from "./components/ContactConversationFilters";
import { routesConfig } from "../../../../config";
import ContactConversationList from "./components/ContactConversationList";
import { useState } from "react";
import { storageHelpers } from "@/shared/helpers";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import type { IOrganization } from "@bothive_core/database";

export default function Conversations() {
	const organization: IOrganization = useSelector((state) => state.content.team.team);
	const params = useParams<{ contactId: string; team: string }>();

	const [showFilters, setShowFilters] = useState<boolean>(
		storageHelpers.getContactState()?.conversations?.showFilters || false
	);

	function handleShowFilterChange() {
		setShowFilters((prev) => {
			const newValue = !prev;

			storageHelpers.updateContactState({ conversations: { showFilters: newValue } });
			return newValue;
		});
	}

	if (!organization.permissions.readContactConversations) {
		return (
			<Redirect
				to={routesConfig.dashboard.contact.detail.root
					.replace(":team", params.team)
					.replace(":contactId", params.contactId)}
			/>
		);
	}

	return (
		<main className="cc-contact-conversations">
			<ContactConversationFilters showFilters={showFilters} />
			<ContactConversationList showFilters={showFilters} onChangeShowFilters={handleShowFilterChange} />
		</main>
	);
}
