export default {
	removeThreadingEmail({ html }) {
		// create new empty html object
		const htmlParser = new DOMParser();
		const htmlObject = htmlParser.parseFromString(html, "text/html");

		// add email html to object
		htmlObject.innerHTML = html;
		const htmlBody = htmlObject.getElementsByTagName("BODY")[0];

		if (html.includes("---------- Forwarded message ----------")) {
			return htmlBody;
		}

		if (!htmlBody) {
			// make array of all html children, this way we scope our selecting as threading is always top level
			const htmlChildren = [...htmlObject.children];

			const divsInHtmlChildren = htmlChildren.filter((item) => item.nodeName === "DIV");

			// there should be 1 top level div, which is the threading, if so we remove it
			if (divsInHtmlChildren.length === 1) {
				htmlObject.removeChild(divsInHtmlChildren[0]);

				return htmlObject;
			}
		}

		// Gmail separates threads in body by class name
		const gmailQuote = htmlBody.getElementsByClassName("gmail_quote")[0];

		if (gmailQuote) {
			gmailQuote.parentNode.removeChild(gmailQuote);

			return htmlBody;
		}

		// Outlook starts thread with a div that has a special id
		const outlookQuote = htmlBody.querySelector("#appendonsend");

		if (outlookQuote) {
			let node = outlookQuote.nextSibling;

			while (node) {
				node.parentNode.removeChild(node);
				node = outlookQuote.nextSibling;
			}

			outlookQuote.parentNode.removeChild(outlookQuote);

			return htmlBody;
		}

		// Standard quote stripping
		const stripedNewLineChildren = [...htmlBody.children].filter((item) => item.nodeName !== "BR");
		const lastChild = stripedNewLineChildren[stripedNewLineChildren.length - 1];

		if (!lastChild?.children) {
			return html;
		}

		const childBlock = [...lastChild.children].filter((item) => item.nodeName !== "BR");
		const lastBlock = childBlock[childBlock.length - 1];

		if (lastBlock?.nodeName === "BLOCKQUOTE") {
			const parentNode = lastBlock.parentNode;

			parentNode.removeChild(lastBlock);

			return parentNode.parentNode;
		}

		if (lastBlock) {
			const threadQuote = lastBlock.getElementsByTagName("BLOCKQUOTE")[0];

			if (threadQuote) {
				const parentNode = threadQuote.parentNode;

				parentNode.removeChild(threadQuote);

				return lastBlock.parentNode.parentNode;
			}
		}

		return html;
	},
	containsThread({ html }) {
		if (!html) {
			return false;
		}

		// create new empty html object
		const htmlParser = new DOMParser();
		const htmlObject = htmlParser.parseFromString(html, "text/html");

		// add email html to object
		htmlObject.innerHTML = html;
		const htmlBody = htmlObject.getElementsByTagName("BODY")[0];

		if (html.includes("---------- Forwarded message ----------")) {
			return false;
		}

		if (!htmlBody) {
			// make array of all html children, this way we scope our selecting as threading is always top level
			const divsInHtmlChildren = [...htmlObject.children].filter((item) => item.nodeName === "DIV");

			// there should be 1 top level div, which is the threading, if so we remove it
			if (divsInHtmlChildren.length === 1) {
				return false;
			}
		}

		if (
			htmlBody.querySelector("#appendonsend") ||
			htmlBody.getElementsByClassName("gmail_quote")[0] ||
			htmlBody.getElementsByTagName("BLOCKQUOTE")[0]
		) {
			return true;
		}

		return false;
	},
};
