import { Component } from "../../../../components/atoms";
import { Skeleton } from "antd";

export default function Loading() {
	return (
		<section
			className="t-section"
			style={{
				display: "grid",
				gridTemplateRows: "35em",
				gridTemplateColumns: "2fr 5fr 3fr",
				gap: "0.5em",
			}}
		>
			<Component.ADashboardCard childStyle={{ display: "block" }}>
				<Skeleton active></Skeleton>
			</Component.ADashboardCard>
			<Component.ADashboardCard childStyle={{ display: "block" }}>
				<Skeleton active></Skeleton>
			</Component.ADashboardCard>
			<Component.ADashboardCard style={{ marginLeft: "1.5em" }} childStyle={{ display: "block" }}>
				<Skeleton active></Skeleton>
			</Component.ADashboardCard>
		</section>
	);
}
