const day = 86400000;

export default {
	teamUuid: "organizationId",
	organizationId: "organizationId",
	teamSlug: "organizationSlug",
	cookieConcent: "cookieConcent",
	sessionId: "sessionId",
	token: "token",
	userUuid: "userUuid",
	userId: "userId",
	language: "lang",
	inbox: "inbox",

	expireTime: {
		year: 365 * day,
		demo: day / 2,
		token: 90 * day, //3months valid
		day,
	},
};
