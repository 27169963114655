import "./style.scss";

import type { IAdminPulseTaskLink } from "@bothive_core/database";
import { Form, FormInstance, Select, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useMemo } from "react";

import { trpc } from "../../../../../../../trpc";
import { flowActionsOptionI18n, flowStatusI18n } from "../config";

interface IWebhookRulesForm {
	form?: FormInstance;
	connectionId: string;
	values?: Partial<IAdminPulseTaskLink>;
	onSubmit: (values: Omit<IAdminPulseTaskLink, "_id">) => void;
}
export default function TaskForm({ connectionId, form, values, onSubmit }: IWebhookRulesForm) {
	const intl = useIntl();

	const { data: tasks, isLoading: isTasksLoading } = trpc.integrations.crm.adminPulse.getTaskTemplates.useQuery({
		connectionId,
	});
	const { data: templates, isLoading: isTemplateLoading } = trpc.flowTemplate.getTemplates.useQuery(
		{ comingSoon: false },
		{ refetchOnWindowFocus: false }
	);

	const selectedTaskId = Form.useWatch("taskId", form);
	const selectedStatus = Form.useWatch("status", form);
	const selectedTask = tasks?.find((task) => task.id === selectedTaskId);
	const flowTemplateOptions = useMemo(
		() =>
			templates?.data
				?.filter((item) => item._id === "65567c114e7254410e97e96f") // Only support pb24 for now
				?.sort((a, b) => {
					const aOrder = a.order || Number.MAX_VALUE;
					const bOrder = b.order || Number.MAX_VALUE;

					if (aOrder < bOrder) return -1;
					if (aOrder > bOrder) return 1;

					return 0;
				})
				?.map((item) => ({ value: item._id, label: item.name[intl.locale] })),
		[templates]
	);

	useEffect(() => {
		form?.setFieldValue("subTaskId", selectedTask?.subtaskTemplates?.[0]?.id);
	}, [selectedTask]);

	function selectFilter(inputValue: string, option?: { label: string; value: string }) {
		const label = option?.label?.toLowerCase() || "";

		return label.includes(inputValue.toLowerCase());
	}

	const handleSubmit = (data) => {
		const subTask = selectedTask?.subtaskTemplates.find((item) => item.id === data.subTaskId);

		if (!selectedTask || !subTask) return;

		const link: Omit<IAdminPulseTaskLink, "_id"> = {
			task: {
				id: data.taskId,
				name: selectedTask.name,
				subTaskId: data.subTaskId,
				subTaskName: subTask.name,
			},
			flowAction: data.flowAction,
			flowTemplateId: data.flowTemplateId,
			status: data.status,
		};

		onSubmit(link);
	};

	return (
		<Form
			form={form}
			preserve={false}
			layout="vertical"
			onFinish={handleSubmit}
			id="adminPulseLinkForm"
			requiredMark="optional"
			className="admin_pulse-tasks-form"
			initialValues={values || { status: "done" }}
		>
			<Form.Item
				name="flowTemplateId"
				label={intl.formatMessage({
					id: "settings.integrations.admin_pulse.tasks.form.flow_template.label",
					defaultMessage: "Link flow",
				})}
				className="admin_pulse-tasks-form-flow"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: "settings.integrations.admin_pulse.tasks.form.flow_template.validation.required",
							defaultMessage: "Don't forget to pick a flow template.",
							description: "Validation message user is required to select a flow template",
						}),
					},
				]}
			>
				<Select
					showSearch
					size="large"
					allowClear={true}
					loading={isTemplateLoading}
					filterOption={selectFilter}
					notFoundContent={
						<div className="admin_pulse-tasks-form-not_found">
							<Typography.Text type="secondary">
								<FormattedMessage
									id="settings.integrations.admin_pulse.tasks.form.flow_template.empty_message"
									defaultMessage="Can't seem to find any flow templates."
									description="Empty message when we couldn't find any flow templates"
								/>
							</Typography.Text>
						</div>
					}
					options={flowTemplateOptions}
				/>
			</Form.Item>

			<Form.Item
				name="taskId"
				label={intl.formatMessage({
					id: "settings.integrations.admin_pulse.tasks.form.task_id.label",
					defaultMessage: "To task template",
				})}
				className="admin_pulse-tasks-form-task"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: "settings.integrations.admin_pulse.tasks.form.task_id.validation.required",
							defaultMessage: "Don't forget to pick a AdminPulse template.",
							description: "Validation message user is required to select a AdminPulse template.",
						}),
					},
				]}
			>
				<Select
					showSearch
					size="large"
					allowClear={true}
					loading={isTasksLoading}
					notFoundContent={
						<div className="admin_pulse-tasks-form-not_found">
							<Typography.Text type="secondary">
								<FormattedMessage
									id="settings.integrations.admin_pulse.tasks.form.task_id.empty_message"
									defaultMessage="Can't seem to find any AdminPulse templates."
									description="Empty message when we couldn't find any AdminPulse templates"
								/>
							</Typography.Text>
						</div>
					}
					filterOption={selectFilter}
					options={tasks?.map((item) => ({ value: item.id, label: item.name }))}
				/>
			</Form.Item>
			<Form.Item
				name="subTaskId"
				label={intl.formatMessage({
					id: "settings.integrations.admin_pulse.tasks.form.sub_task.label",
					defaultMessage: "Sub task",
				})}
				className="admin_pulse-tasks-form-subTask"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: "settings.integrations.admin_pulse.tasks.form.sub_task.validation.required",
							defaultMessage: "Don't forget to pick a AdminPulse sub template.",
							description: "Validation message user is required to select a AdminPulse sub template.",
						}),
					},
				]}
			>
				<Select
					showSearch
					size="large"
					allowClear={true}
					loading={isTasksLoading}
					disabled={!selectedTask}
					notFoundContent={
						<div className="admin_pulse-tasks-form-not_found">
							<Typography.Text type="secondary">
								<FormattedMessage
									id="settings.integrations.admin_pulse.tasks.form.sub_task.empty_message"
									defaultMessage="Can't seem to find any sub templates."
									description="Empty message when we couldn't find any sub templates"
								/>
							</Typography.Text>
						</div>
					}
					filterOption={selectFilter}
					options={selectedTask?.subtaskTemplates.map((item) => ({ value: item.id, label: item.name }))}
				/>
			</Form.Item>

			<Form.Item
				name="flowAction"
				label={intl.formatMessage(
					{
						id: "settings.integrations.admin_pulse.tasks.form.flow_action.label",
						defaultMessage: "When one of the following actions occur on a flow report",
					},
					{ selectedStatus }
				)}
				className="admin_pulse-tasks-form-action"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: "settings.integrations.admin_pulse.tasks.form.flow_action.validation.required",
							defaultMessage: "Don't forget to pick a action.",
							description: "Validation message user is required to select a action",
						}),
					},
				]}
			>
				<Select
					size="large"
					mode="multiple"
					allowClear={true}
					options={Object.keys(flowActionsOptionI18n).map((key) => ({
						value: key,
						label: intl.formatMessage(flowActionsOptionI18n[key]),
					}))}
				/>
			</Form.Item>

			<Form.Item
				name="status"
				label={intl.formatMessage({
					id: "settings.integrations.admin_pulse.tasks.form.status.label",
					defaultMessage: "Move sub task status to",
				})}
				className="admin_pulse-tasks-form-status"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: "settings.integrations.admin_pulse.tasks.form.status.validation.required",
							defaultMessage: "Don't forget to pick the status to where we need to move the status to.",
							description:
								"Validation message user is required to select the status to where we need to move the task to",
						}),
					},
				]}
			>
				<Select
					size="large"
					options={Object.keys(flowStatusI18n).map((key) => ({
						value: key,
						label: intl.formatMessage(flowStatusI18n[key]),
					}))}
				/>
			</Form.Item>
		</Form>
	);
}
