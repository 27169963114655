import axios from "axios";

import { apiConfig } from "@/flows/common/config";

export default {
	deleteReport: async ({ flowId, reportId }) => {
		const { data, status } = await axios.delete(
			apiConfig.report.delete.replace(":id", flowId).replace(":reportId", reportId)
		);

		if (status !== 204) {
			throw new Error();
		}

		return data;
	},
};
