import "../style.scss";

import { Button, Form, Input } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

import helper from "@/flows/common/helper";
import { flowState } from "@/flows/common/state";
import { updateFlow } from "@/flows/flow_detail/settings/flow/query";
import { widgetPreviewSelector } from "@/flows/flow_detail/settings/widget/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import FieldLabel from "@/shared/components/field/default";
import ImageUpload from "@/shared/components/upload/imageUpload";

import style from "./style.module.scss";

export default function WidgetSettingsForm({ isDisabled }) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const { flowId } = useParams<{ flowId: string }>();
	const [isFlowUpdating, setIsFlowUpdating] = useState(false);
	const [flow, setFlowState] = useRecoilState(flowState({ id: flowId }));
	const setWidgetSettings = useSetRecoilState(widgetPreviewSelector);

	const locale = "nl"; // temp because we don't support multi language flow widget yet

	const handleFlowUpdate = async () => {
		try {
			setIsFlowUpdating(true);

			const result = await updateFlow({
				id: flowId,
				payload: {
					widgetSettings: {
						...flow.widgetSettings,
						...form.getFieldsValue(),
					},
				},
			});

			if (!result?.success) throw new Error();

			setFlowState({ ...flow, ...result.data });
			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT.SETTINGS.FORM.SUCCEEDED.TITLE",
					defaultMessage: "Flow changed",
				}),
			});
		} catch (error) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT.SETTINGS.FORM.FAILED.TITLE",
					defaultMessage: "Flow could not be updated",
				}),
				description: intl.formatMessage({
					id: "FLOWS.REPORT.SETTINGS.FORM.FAILED.DESCRIPTION",
					defaultMessage: "Please try again later or contact customer support",
				}),
			});
		}

		setIsFlowUpdating(false);
	};

	return (
		<Form
			form={form}
			id="updateFlow"
			disabled={isDisabled}
			className={style.flow_settings_form}
			initialValues={helper.getFlowDescription({ flow, language: locale })}
			onValuesChange={(_, allValues) => setWidgetSettings(allValues)}
		>
			<div className={style.flow_settings_wrapper}>
				<FieldLabel
					name="logo"
					label="FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.TITLE"
					help="FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.HELP"
					className={style.flow_settings_logo}
				>
					<Form.Item name="logo">
						<ImageUpload id={`flow_${flowId}_icon`} crop={{ aspect: 1 / 1 }} disabled={isDisabled} />
					</Form.Item>
				</FieldLabel>
				<div className={style.flow_settings_field_wrapper}>
					<FieldLabel label="FLOWS.REPORT.SETTINGS.WIDGET.FORM.NAME.LABEL">
						<Form.Item
							name="name"
							rules={[
								{
									required: true,
									message: (
										// eslint-disable-next-line max-len
										<FormattedMessage id="FLOWS.REPORT.SETTINGS.WIDGET.FORM.NAME.LABEL.REQUIRED" />
									),
								},
							]}
							className="form-item"
						>
							<Input placeholder={flow.template?.name?.[intl.locale]} maxLength={64} />
						</Form.Item>
					</FieldLabel>
					<FieldLabel className="t-gap--top-sm" label="FLOWS.REPORT.SETTINGS.WIDGET.FORM.DESCRIPTION.LABEL">
						<Form.Item
							name="description"
							rules={[
								{
									required: true,
									message: (
										// eslint-disable-next-line max-len
										<FormattedMessage id="FLOWS.REPORT.SETTINGS.WIDGET.FORM.DESCRIPTION.LABEL.REQUIRED" />
									),
								},
							]}
							className="form-item"
						>
							<Input.TextArea
								className="text-area"
								placeholder={flow.template?.description?.[intl.locale]}
								maxLength={256}
								rows={4}
							/>
						</Form.Item>
					</FieldLabel>
				</div>
			</div>
			<Form.Item>
				<Button
					type="primary"
					htmlType="submit"
					className="save-button"
					loading={isFlowUpdating}
					onClick={handleFlowUpdate}
				>
					<FormattedMessage id="FLOWS.REPORT.SETTINGS.WIDGET.FORM.BUTTON.SAVE" />
				</Button>
			</Form.Item>
		</Form>
	);
}
