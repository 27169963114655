import config from "@/flows/report_detail/template/config";

export function formatBreadcrumbs({ flow, report, language, routerParameters }) {
	let flowName = "...";

	if (flow?.id === routerParameters?.flowId) {
		flowName = flow?.name || flow?.template?.name?.[language];
	}

	return config.breadcrumbs({
		...routerParameters,
		reportName: report?.contact?.username,
		flowName,
	});
}
