import axios from "axios";
import qs from "qs";

import config from "./config";

export const getFlowReports = async ({
	flowId,
	limit,
	offset,
	q,
	status,
	progress,
	contactLabel,
	accountManager,
	sort,
	compact = false,
}) => {
	const query = qs.stringify({
		q,
		flowId,
		limit,
		offset,
		status,
		progress,
		compact,
		contactLabel,
		accountManager,
		sort,
	});

	const { data } = await axios.get(`${config.api.getAll.replace(":id", flowId)}?${query}`);

	if (!data.success) throw new Error();

	return data;
};
