import type { IMember } from "@bothive_core/database";
import { Divider, Modal, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { trpc } from "../../../../../trpc";
import { useParams } from "react-router";
import AssigneeList from "./AssigneeList";
import ShowFailedBanner from "../../../../../shared/components/banner/failed";

export default function AccountManagerSelectModal({
	isOpen,
	setIsOpen,
}: {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}) {
	const intl = useIntl();
	const params: { contactId: string } = useParams();
	const trpcUtils = trpc.useUtils();

	const { data: accountManagers } = trpc.member.getMembers.useQuery();

	const { userId } = useSelector((state) => ({
		// @ts-ignore
		userId: state.profile.account.user._id,
	}));

	let assignedContactManagers: IMember[] = [];
	let unassignedContactManagers: IMember[] = [];

	const { data: contact } = trpc.contact.getContactById.useQuery({ id: params?.contactId });

	if (contact && accountManagers?.length) {
		assignedContactManagers = (contact?.accountManagers as any[])?.reduce<IMember[]>((prev, accountManagerId) => {
			const member = accountManagers?.find((accountManager) => accountManager.userId === accountManagerId);

			return member ? [...prev, member] : prev;
		}, []);

		unassignedContactManagers = accountManagers?.filter(
			(accountManager: IMember) => !assignedContactManagers?.includes(accountManager)
		);
	}

	const addAccountManagerMutation = trpc.contact.addAccountManagerToContact.useMutation();
	const removeAccountManagerMutation = trpc.contact.removeAccountManagerFromContact.useMutation();

	return (
		<Modal
			title={intl.formatMessage({ id: "CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.TITLE" })}
			bodyStyle={{ padding: "8px" }}
			open={isOpen}
			destroyOnClose // destroyOnClose is necessary to keep focus in search input after open/close/open
			footer={null}
			onCancel={() => setIsOpen(false)}
		>
			<AssigneeList
				userId={userId}
				items={unassignedContactManagers}
				onClick={(value) => {
					if (!contact) return;

					addAccountManagerMutation.mutate(
						{
							contactId: contact._id,
							accountManagerId: value,
						},
						{
							onSuccess: () => {
								trpcUtils.contact.getContactById.invalidate();
							},
							onError: () => {
								ShowFailedBanner({
									title: intl.formatMessage({
										id: "CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.ADD.FAILED",
										defaultMessage: "Failed to add account manager",
									}),
								});
							},
						}
					);
				}}
				isDestructive={undefined}
			/>
			{assignedContactManagers.length > 0 && (
				<>
					<Divider />
					<Typography.Text type="secondary" className="t-gap--left-sm">
						<FormattedMessage id="ASSIGNEE_POPUP.ASSIGNEES.TITLE" />
					</Typography.Text>
					<AssigneeList
						items={assignedContactManagers}
						userId={userId}
						isDestructive={true}
						onClick={(value) => {
							removeAccountManagerMutation.mutate(
								{
									contactId: contact?._id || "",
									accountManagerId: value,
								},
								{
									onSuccess: () => {
										trpcUtils.contact.getContactById.invalidate();
									},
									onError: () => {
										ShowFailedBanner({
											title: intl.formatMessage({
												id: "CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.REMOVE.FAILED",
												defaultMessage: "Failed to remove account manager",
											}),
										});
									},
								}
							);
						}}
					/>
				</>
			)}
		</Modal>
	);
}
