import { ReactNode } from "react";
import "./style.scss";
import { Empty, EmptyProps } from "antd";

interface ConversationEmptyProps extends EmptyProps {
	action?: ReactNode;
}

const ConversationEmpty = (props: ConversationEmptyProps) => {
	return (
		<div className="ce-empty">
			<Empty {...props} imageStyle={{ height: "160px" }}>
				{props.action}
			</Empty>
		</div>
	);
};

export default ConversationEmpty;
