import { trpc } from "@/trpc";
import { StarFilled } from "@ant-design/icons";
import { Avatar, Badge, Select, SelectProps, Space, Spin, Tooltip, Typography } from "antd";
import { forwardRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDebounce } from "use-debounce";

const recipientTypeMap = defineMessages({
	member: {
		id: "recipient.type.member.tag",
		defaultMessage: "Member",
		color: "var(--primary)",
	},
	contact: {
		id: "recipient.type.contact.tag",
		defaultMessage: "Contact",
		color: "var(--accent)",
	},
});

const RecipientSelect = forwardRef(function RecipientSelect(
	props: SelectProps & { fromChannelIds: string[] },
	ref: any
) {
	const [recipientSearch, setRecipientSearch] = useState<string | null>(null);
	const [debouncedSearch] = useDebounce(recipientSearch, 150);
	const intl = useIntl();

	const { data: recipients, isLoading: isContactsLoading } = trpc.contact.getRecipientsByChannel.useQuery(
		{ channelIds: props.fromChannelIds, q: debouncedSearch || undefined },
		{
			enabled: Boolean(props.fromChannelIds.length),
		}
	);

	const handleChange = (values: string[], option) => {
		if (!props.onChange) return;

		props.onChange(
			values.map((value) => value.trim()),
			option
		);
	};

	return (
		<Select
			mode="tags"
			ref={ref}
			{...props}
			autoClearSearchValue
			filterOption={false}
			onChange={handleChange}
			optionLabelProp="value"
			tokenSeparators={[","]}
			loading={isContactsLoading}
			onSelect={() => setRecipientSearch(null)}
			onSearch={(value) => setRecipientSearch(value)}
			notFoundContent={isContactsLoading || debouncedSearch ? <Spin size="small" /> : null}
		>
			{!!recipientSearch?.length &&
				recipients?.map((recipient) => (
					<Select.Option
						key={`${recipient._id}_${recipient.email}`}
						value={recipient.email}
						label={recipient.fullName}
					>
						<Space>
							{recipient.avatar && <Avatar src={recipient.avatar} shape="square" />}
							<div
								style={{
									display: "grid",
								}}
							>
								<Space>
									<Typography.Text strong>{recipient.fullName}</Typography.Text>
									{["member", "contact"].includes(recipient.type) && (
										<Badge
											size="small"
											count={intl.formatMessage(recipientTypeMap[recipient.type])}
											color={recipientTypeMap[recipient.type]?.color}
										/>
									)}
								</Space>
								<Space>
									<Typography.Text type="secondary">{recipient.email}</Typography.Text>
									{recipient.isPrimary && (
										<Tooltip
											title={intl.formatMessage({
												id: "recipient.contact.type.primary.tooltip",
												defaultMessage: "This is the contact's primary email",
											})}
										>
											<StarFilled />
										</Tooltip>
									)}
								</Space>
							</div>
						</Space>
					</Select.Option>
				))}
		</Select>
	);
});

export default RecipientSelect;
