import { BellOutlined, CalendarOutlined, CopyOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Tooltip } from "antd";
import { routesConfig } from "@/flows/common/config";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import { typesConfig } from "@/flows/common/config";
import { flowState, selectedFlowIdState } from "@/flows/common/state";
import { clipboardHelper } from "@/helpers";
import ShowSuccessBanner from "@/shared/components/banner/success";

import style from "./style.module.scss";
import type { IFlow } from "@bothive_core/database";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import { useHistory, useParams } from "react-router-dom";

interface IShowSendModalArgs {
	flowId?: string;
	visible: boolean;
	defaultProgress?: string[];
	templateType?: string;
	flow: IFlow;
}

const reminderOptions = ["automated_reminder", "manual_reminder"] as const;
type TReminderOptions = (typeof reminderOptions)[number];

interface ActionButtonsProps {
	showSendModal: (props: IShowSendModalArgs) => void;
}

export default function ActionButtons({ showSendModal }: ActionButtonsProps) {
	const intl = useIntl();
	const flowId = useRecoilValue(selectedFlowIdState);
	const flow = useRecoilValueLoadable(flowState({ id: flowId }));
	const routerParameters = useParams<{ team: string; flowId: string }>();
	const history = useHistory();

	const handleCopyFlowLink = () => {
		ShowSuccessBanner({
			title: intl.formatMessage({
				id: "flows.overview.table.button.copy_flow_link.success",
				defaultMessage: "Flow link copied to clipboard",
			}),
		});

		clipboardHelper.copyToClipboard(flow.contents.link);
	};

	function handleReminders(key: TReminderOptions) {
		if (key === "manual_reminder") {
			showSendModal({
				flowId,
				visible: true,
				defaultProgress: [
					typesConfig.progressState.created,
					typesConfig.progressState.inviteSend,
					typesConfig.progressState.inProgress,
				],
				templateType: typesConfig.templateTypes.reminding,
				flow: flow.contents,
			});
		}

		if (key === "automated_reminder") {
			history.push(
				routesConfig.reminders
					.replace(":team", routerParameters.team)
					.replace(":flowId", routerParameters.flowId)
			);
		}
	}

	const menuProps = {
		items: [
			{
				label: intl.formatMessage({
					id: "FLOW_DETAIL.REPORT.BUTTON.MANUAL_REMIND",
					defaultMessage: "Remind contacts",
				}),
				key: "manual_reminder",
			},
			{
				label: intl.formatMessage({
					id: "FLOW_DETAIL.REPORT.BUTTON.AUTOMATED_REMINDERS",
					defaultMessage: "Remind automated",
				}),
				key: "automated_reminder",
			},
		] satisfies { [key: string]: any; key: TReminderOptions }[],
		onClick: (event: any) => {
			handleReminders(event.key);
		},
	};

	return (
		<div className={style.action_buttons}>
			<Tooltip
				title={
					flow?.contents?.template?.deactivated
						? intl.formatMessage({
								id: "FLOWS.DEACTIVATED.TOOLTIP",
								defaultMessage: "This flow has been deactivated and can not be sent to clients anymore",
						  })
						: null
				}
			>
				<Button
					type="text"
					icon={<CopyOutlined className="t-gap--right-xs" />}
					onClick={handleCopyFlowLink}
					disabled={flow?.contents?.template?.deactivated}
				>
					<FormattedMessage id="FLOW_DETAIL.REPORT.BUTTON.COPY_LINK" />
				</Button>
			</Tooltip>
			<Tooltip
				title={
					flow?.contents?.template?.deactivated
						? intl.formatMessage({
								id: "FLOWS.DEACTIVATED.TOOLTIP",
								defaultMessage: "This flow has been deactivated and can not be sent to clients anymore",
						  })
						: null
				}
			>
				<Button
					type="text"
					icon={<SendOutlined className="t-gap--right-xs" />}
					onClick={() =>
						showSendModal({
							visible: true,
							flow: flow.contents,
						})
					}
					disabled={flow?.contents?.template?.deactivated}
				>
					<FormattedMessage id="FLOW_DETAIL.REPORT.BUTTON.SEND" />
				</Button>
			</Tooltip>
			{flow?.contents?.template?.settings?.remindersEnabled ? (
				<Dropdown menu={menuProps}>
					<Tooltip
						title={
							flow?.contents?.template?.deactivated
								? intl.formatMessage({
										id: "FLOWS.DEACTIVATED.TOOLTIP",
										defaultMessage:
											"This flow has been deactivated and can not be sent to clients anymore",
								  })
								: null
						}
					>
						<Button type="text" style={{ cursor: "default" }} icon={<BellOutlined />}>
							{" "}
							<Space>
								<FormattedMessage
									id="FLOW_DETAIL.REPORT.BUTTON.REMINDER_OPTIONS"
									defaultMessage="Reminder options"
								/>
								<ArrowDownIcon />
							</Space>
						</Button>
					</Tooltip>
				</Dropdown>
			) : (
				<Tooltip
					title={
						flow?.contents?.template?.deactivated
							? intl.formatMessage({
									id: "FLOWS.DEACTIVATED.TOOLTIP",
									defaultMessage:
										"This flow has been deactivated and can not be sent to clients anymore",
							  })
							: null
					}
				>
					<Button
						type="text"
						icon={<BellOutlined className="t-gap--right-xs" />}
						onClick={() =>
							showSendModal({
								flowId,
								visible: true,
								defaultProgress: [
									typesConfig.progressState.created,
									typesConfig.progressState.inviteSend,
									typesConfig.progressState.inProgress,
								],
								templateType: typesConfig.templateTypes.reminding,
								flow: flow.contents,
							})
						}
						disabled={flow?.contents?.template?.deactivated}
					>
						<FormattedMessage
							id="FLOW_DETAIL.REPORT.BUTTON.MANUAL_REMIND"
							defaultMessage="Remind contacts"
						/>
					</Button>
				</Tooltip>
			)}
		</div>
	);
}
