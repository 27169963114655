import "./style.scss";

import settingsConfig from "@/modules/settings/config";
import DashboardCard from "@/shared/components/card/dashboard";
import { advancedData, brandingData, channelData, contactsData, organizationData } from "./config";
import { actions as dashboardActions } from "@/store/dashboard/index";
import { trpc } from "@/trpc";
import { Avatar, Button, List, Tooltip } from "antd";
import { useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ProgressGraph from "../integrations/overview/components/ProgressGraph";
import MembersOverview from "../members/components/MembersOverview";
import SettingsCardItem from "./components/settingsCardItem";

export default function OverviewSettings({ className }) {
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const routeParameters = useParams<{ team: string }>();
	const team = trpc.organization.getOrganization.useQuery();
	const connections = trpc.integration?.getConnections?.useQuery({
		query: { populate: true, category: "5c38a182092c99228ead1a32" }, // accountancy category
	});

	const state = useSelector((state) => ({
		permissions: state.dashboard.permissions,
		userPermissions: state.dashboard.permissions.userPermissions,
	}));

	useEffect(() => {
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					title: intl.formatMessage({ id: "settings.page.title", defaultMessage: "Settings" }),
					tabTitle: intl.formatMessage({ id: "settings.page.title", defaultMessage: "Settings" }),
				},
				intl,
			})
		);
	}, []);

	const goToChannels = () => {
		history.push(settingsConfig.routes.inbox.channels.overview.replace(":team", routeParameters.team));
	};
	const goToIntegrations = () => {
		history.push(settingsConfig.routes.integrations.overview.replace(":team", routeParameters.team));
	};
	const goToMembers = () => {
		history.push(settingsConfig.routes.members.overview.replace(":team", routeParameters.team));
	};

	const integrationGraphMessage = useMemo(() => {
		if (connections.isLoading) {
			return intl.formatMessage({
				id: "settings.integrations.overview.info.permissions.loading",
				defaultMessage: "Fetching limits...",
			});
		}

		if (state.permissions.integrations < Number.MAX_SAFE_INTEGER) {
			return intl.formatMessage(
				{
					id: "settings.integrations.overview.info.progress_label",
					defaultMessage:
						"You have {value, plural, =0 {no integrations} one {# integration} other {# integrations}} left in your current plan.",
				},
				{ value: Math.max(state.permissions.integrations - (connections?.data?.total || 0), 0) }
			);
		}

		return intl.formatMessage({
			id: "settings.integrations.overview.info.progress_label_unlimited",
			defaultMessage: "You have unlimited integrations in your current plan.",
		});
	}, [connections]);

	return (
		<main className={`page-settings-overview ${className}`}>
			<DashboardCard
				title={intl.formatMessage({
					id: "settings_overview.organization.title",
					defaultMessage: "Company settings",
				})}
				className="settings-overview--organization-settings"
			>
				<List
					dataSource={organizationData()}
					className="settings-overview--list t-gap--top"
					renderItem={({ title, description, link, icon }) => (
						<SettingsCardItem title={title} description={description} link={link} icon={icon} />
					)}
				/>
			</DashboardCard>
			<DashboardCard
				title={intl.formatMessage({
					id: "settings_overview.branding.title",
					defaultMessage: "Branding",
				})}
				className="settings-overview--branding"
			>
				<List
					dataSource={brandingData()}
					className="settings-overview--list t-gap--top"
					renderItem={({ title, description, link, icon }) => (
						<SettingsCardItem title={title} description={description} link={link} icon={icon} />
					)}
				/>
			</DashboardCard>
			<DashboardCard
				title={intl.formatMessage({
					id: "settings_overview.contacts.title",
					defaultMessage: "Contacts",
				})}
				className="settings-overview--contacts"
			>
				<List
					dataSource={contactsData()}
					className="settings-overview--list t-gap--top dashboard-card-body"
					renderItem={({ title, description, link, icon }) => (
						<SettingsCardItem
							title={title}
							description={description}
							link={link}
							icon={icon}
							formattedMessageValues={{ agent: team?.data?.virtualAssistant?.name }}
						/>
					)}
				/>
			</DashboardCard>
			<DashboardCard
				title={intl.formatMessage({
					id: "settings_overview.members.title",
					defaultMessage: "Members",
				})}
				description={intl.formatMessage({
					id: "settings_overview.members.description",
					defaultMessage: "Manage the users who have access to the platform and their permissions",
				})}
				className="settings-overview--members"
			>
				<MembersOverview onClick={goToMembers} className="dashboard-card-body" />
			</DashboardCard>
			<DashboardCard
				title={intl.formatMessage({
					id: "settings_overview.channels.title",
					defaultMessage: "Channels",
				})}
				description={intl.formatMessage({
					id: "settings_overview.channels.description",
					defaultMessage: "Connect chat and email channels with your inbox.",
				})}
				className="settings-overview--channels"
			>
				<div className="settings-overview--cluster-content dashboard-card-body">
					<List
						dataSource={channelData()}
						className="settings--cluster-content"
						grid={{
							md: 2,
						}}
						renderItem={({ tooltip, src }) => (
							<Tooltip title={tooltip} placement="bottom">
								<Avatar
									shape="square"
									src={src}
									className="settings--cluster-content--image-size settings-overview--channels-images"
								/>
							</Tooltip>
						)}
					/>
					<Button type="default" size="large" onClick={goToChannels}>
						<FormattedMessage
							id="settings_overview.channels.footer.button"
							defaultMessage="Manage channels"
						/>
					</Button>
				</div>
			</DashboardCard>
			<DashboardCard
				title={intl.formatMessage({
					id: "settings_overview.integrations.title",
					defaultMessage: "Integrations",
				})}
				className="settings-overview--integrations"
				description={intl.formatMessage({
					id: "settings_overview.integrations.description",
					defaultMessage:
						"Through integrations you can extend the functionalities of Bothive. By integrating on different platforms you can synchronize Bothive with your crm and extend Bothive knowledge.",
				})}
				descriptionValues={{ botName: team?.data?.virtualAssistant?.name }}
			>
				<div className="settings-overview--integrations-body dashboard-card-body">
					<ProgressGraph
						max={state.permissions.integrations || 0}
						value={connections?.data?.total || 0}
						label={integrationGraphMessage}
					/>
					<Button type="default" size="large" onClick={goToIntegrations}>
						<FormattedMessage
							id="settings_overview.integrations.footer.button"
							defaultMessage="Manage integrations"
						/>
					</Button>
				</div>
			</DashboardCard>
			<DashboardCard
				title={intl.formatMessage({
					id: "settings_overview.advanced.title",
					defaultMessage: "Advanced",
				})}
				className="settings-overview--advanced"
			>
				<List
					className="settings-overview--list t-gap--top"
					grid={{
						gutter: 16,
						xs: 1,
						sm: 1,
						md: 3,
						lg: 3,
						xl: 3,
						xxl: 3,
					}}
					dataSource={advancedData()}
					renderItem={({ title, description, link, icon }) => (
						<SettingsCardItem title={title} description={description} link={link} icon={icon} />
					)}
				/>
			</DashboardCard>
			<div className="settings-overview--spacer" />
		</main>
	);
}
