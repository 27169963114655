import { Alert, Button, Form, Modal, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { selectedFlowIdState } from "@/flows";
import { initChangeStatus } from "@/flows/flow_detail/reports/bulk_actions/query";
import {
	allReportsSelectedState,
	isBulkStatusChangingState,
	selectedReportIdsState,
	totalReportsSelectedState,
} from "@/flows/flow_detail/reports/bulk_actions/state";
import {
	reportsList,
	searchFilterState,
	selectedProgressState,
	selectedStatusTypeState,
	selectedContactLabelState,
} from "@/flows/flow_detail/reports/state";
import { useStatusTypes } from "@/flows/flow_detail/status_types/hook";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";

import style from "../../style.module.scss";

export default function ChangeStatusModal({ showChangeStatusModal, setShowChangeStatusModal }) {
	const intl = useIntl();
	const [statusTypes] = useStatusTypes();

	const totalReportsSelected = useRecoilValue(totalReportsSelectedState);
	const selectedReportIds = useRecoilValue(selectedReportIdsState);
	const flowId = useRecoilValue(selectedFlowIdState);
	const query = useRecoilValue(searchFilterState);

	const progress = useRecoilValue(selectedProgressState);
	const statusType = useRecoilValue(selectedStatusTypeState);
	const allReportsSelected = useRecoilValue(allReportsSelectedState);
	const selectedContactLabel = useRecoilValue(selectedContactLabelState);
	const [isBulkStatusChanging, setIsBulkStatusChanging] = useRecoilState(isBulkStatusChangingState);
	const setReportList = useSetRecoilState(reportsList);

	const handleConfirm = async ({ newStatusId }) => {
		setIsBulkStatusChanging(true);

		try {
			const result = await initChangeStatus({
				flowId,
				reportIds: allReportsSelected ? undefined : selectedReportIds,
				q: query,
				progress,
				statusId: statusType,
				contactLabel: selectedContactLabel,
				newStatusId,
			});

			if (!result || !result?.data?.success) {
				throw new Error();
			}

			// Mutate existing reportlist of the current table page, with the updated status type
			setReportList((prevData) => {
				return prevData.map((report) => {
					if (selectedReportIds.includes(report._id)) {
						const status = statusTypes?.contents?.find((status) => status?._id === newStatusId);

						return { ...report, status };
					}

					return report;
				});
			});

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.SUCCESS.TITLE",
					defaultMessage: "Bulk report status change started.",
				}),
				duration: 6,
			});
		} catch (error) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.FAILED.TITLE",
					defaultMessage: "Bulk report status change error.",
				}),
				description: intl.formatMessage({
					id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.FAILED.DESCRIPTION",
					defaultMessage: "The bulk report status change failed",
				}),
			});
		}

		setShowChangeStatusModal(false);
		setIsBulkStatusChanging(false);
	};

	return (
		<Modal
			title={intl.formatMessage({ id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.TITLE" })}
			centered
			open={showChangeStatusModal}
			onCancel={() => setShowChangeStatusModal(false)}
			mask={true}
			maskClosable={true}
			footer={null}
		>
			<Form layout="vertical" id="bulkChangeStatus" onFinish={handleConfirm} requiredMark={false}>
				<Form.Item>
					<Alert
						message={intl.formatMessage(
							{
								id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.INFO_MESSAGE",
							},
							{
								totalReportsSelected,
							}
						)}
						type="info"
						showIcon
					/>
				</Form.Item>
				<Form.Item
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.STATUS_TYPE.REQUIRED_MSG",
							}),
						},
					]}
					name="newStatusId"
					label={intl.formatMessage({
						id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.STATUS_TYPE.LABEL",
					})}
				>
					<Select
						size="large"
						placeholder={intl.formatMessage({
							id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.STATUS_TYPE.PLACEHOLDER",
						})}
					>
						{statusTypes?.contents?.map(({ _id, name }) => (
							<Select.Option key={_id} value={_id}>
								{name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item className={style.form_item_no_margin}>
					<div className={style.form_footer_wrapper}>
						<Button type="primary" htmlType="submit" form="bulkChangeStatus" loading={isBulkStatusChanging}>
							{" "}
							<FormattedMessage id="FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.CONFIRM" />
						</Button>
					</div>
				</Form.Item>
			</Form>
		</Modal>
	);
}
