import "./style.scss";

import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Panel } from "../../../../../../shared/components/collapse";
import { Card, Modal } from "./components";
import { ContactRouterOutput } from "@/trpc";

interface IBankAccounts {
	onChange: (value: Partial<ContactRouterOutput["getContactById"]>) => Promise<Boolean>;
	contact: ContactRouterOutput["getContactById"];
	panelProps?: any;
}

function BankAccounts({ contact, onChange, ...panelProps }: IBankAccounts) {
	const intl = useIntl();
	const selectedAccountIndex = useRef<string | undefined>(undefined);
	const [showModal, setShowModal] = useState<boolean>(false);

	const handleCardClick = (id: string) => {
		selectedAccountIndex.current = id;
		setShowModal(true);
	};

	const handleClose = () => {
		selectedAccountIndex.current = undefined;
		setShowModal(false);
	};

	const handleCreate = (payload: Required<ContactRouterOutput["getContactById"]>["bankAccounts"][0]) => {
		const bankAccounts = contact?.bankAccounts || [];

		if (payload.isRefundAccount) {
			bankAccounts.forEach((account) => {
				account.isRefundAccount = false;
			});
		}

		bankAccounts.push(payload);

		onChange({ bankAccounts }).then(() => handleClose());
	};

	const handleUpdate = ({
		id,
		payload,
	}: {
		id: string;
		payload: Required<ContactRouterOutput["getContactById"]>["bankAccounts"][0];
	}) => {
		const bankAccounts = contact?.bankAccounts || [];

		if (payload.isRefundAccount) {
			bankAccounts.forEach((account) => {
				account.isRefundAccount = false;
			});
		}

		bankAccounts[id] = payload;

		onChange({ bankAccounts }).then(() => handleClose());
	};
	const handleDelete = (id: string) => {
		const bankAccounts = contact?.bankAccounts?.filter((_, index) => `${index}` !== id);

		onChange({ bankAccounts }).then(() => handleClose());
	};

	return (
		<>
			<Panel
				key="bankAccounts"
				title={intl.formatMessage({
					id: "contacts.detail.attributes.bank_accounts.title",
					defaultMessage: "Bank accounts and crypto",
				})}
				{...panelProps}
			>
				<div className="bank_accounts-content">
					{contact?.bankAccounts?.map((account, index) => {
						if (account.isExpired) return;
						return <Card key={`bac_${index}`} id={`${index}`} value={account} onClick={handleCardClick} />;
					})}
					<Button
						style={{ width: "fit-content" }}
						type="text"
						onClick={() => setShowModal(true)}
						size="large"
						icon={<PlusOutlined />}
					>
						{" "}
						<FormattedMessage
							id="contacts.detail.attributes.bank_accounts.add_button"
							defaultMessage="Add bank account"
						/>
					</Button>
				</div>
			</Panel>
			<Modal
				isOpen={showModal}
				id={selectedAccountIndex.current}
				value={selectedAccountIndex.current && contact?.bankAccounts?.[selectedAccountIndex.current]}
				onSubmit={handleCreate}
				onUpdate={handleUpdate}
				onDelete={handleDelete}
				onClose={handleClose}
			/>
		</>
	);
}

export default BankAccounts;
