import "./style.scss";

import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { actions as contentActions } from "@/store/content";
import { actions as dashboardActions } from "@/store/dashboard";
import BillingSettings from "./billing";
import config from "./config";
import DeveloperSettings from "./developer";
import UserNotifications from "./user_notifications";

/**
 * TODO
 * move all components in to settings module with ant design components
 * ? should components be in each page? because they link to that other page
 */

export default function NotificationSettings({ className }) {
	const intl = useIntl();
	const dispatch = useDispatch();
	const routeParameters = useParams();
	const state = useSelector((state) => ({
		notificationSettings: state.content.team.team.contact,
	}));

	useEffect(() => {
		const breadcrumbs = config.header.breadcrumbs(routeParameters);

		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					...config.header,
					breadcrumbs,
				},
				intl,
			})
		);
	}, []);

	const handleChange = (payload) => {
		const value = {
			contact: { ...state.contact, ...payload },
		};

		dispatch(contentActions.form.updateTeam({ value }));
	};

	return (
		<main className={`page-settings--content page-settings-notifications ${className}`}>
			<BillingSettings />
			<DeveloperSettings notificationSettings={state.notificationSettings} onChange={handleChange} />
			<UserNotifications />
		</main>
	);
}
