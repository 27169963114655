import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";

import { queryStringHelpers } from "../../shared/helpers";
import config from "./config";
import { getFlows } from "./query";
import { flowListState, flowMeta } from "./state";

export function useFlowList({ limit, offset } = { limit: config.pagination.limit, offset: 0 }) {
	const routerParameters = useParams();

	const [hookState, setHookState] = useState("hasValue");
	const [hookError, setHookError] = useState(undefined);
	const [flowMetaData, setFlowMetaData] = useRecoilState(flowMeta);
	const [flowList, setFlowList] = useRecoilState(flowListState);

	useEffect(() => {
		fetchFlowList({ limit, offset });
	}, [routerParameters?.team]);

	const fetchFlowList = async ({ limit, offset, q } = { limit: config.pagination.limit }) => {
		try {
			const queryParameters = { limit, offset, q, team: routerParameters?.team };

			if (!queryStringHelpers.isQueryParametersChanged({ queryParameters, metaData: flowMetaData })) {
				return;
			}

			setHookState("loading");
			setHookError(undefined);

			const result = await getFlows({ ...flowMetaData, limit, offset, q });

			setHookState("hasValue");
			setFlowList(result.data);
			setFlowMetaData({ ...flowMetaData, ...result.meta, team: routerParameters?.team });
		} catch (error) {
			setHookState("hasError");
			setHookError(error);
		}
	};

	return [
		{
			state: hookState,
			contents: hookError || flowList,
		},
		fetchFlowList,
	];
}
