import { Typography } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, useParams } from "react-router-dom";
import { IIntegration } from "@bothive_core/database";

import { routesConfig } from "@/config";
import { ConnectChannel } from "@/shared/components/walk_through";
import ConnectChannelModal from "../../../modules/settings/channels/connectChannel";
import { trpc } from "../../../trpc";

export default function NoChannel() {
	const queryParameters: { team: string } = useParams();
	const [openConnectModal, setOpenConnectModal] = useState(false);
	const [selectedProvider, setSelectedProvider] = useState<IIntegration>();
	const footerLink = routesConfig.dashboard.settings.inbox.channels.overview.replace(":team", queryParameters.team);
	const trpcUtils = trpc.useUtils();

	const handleConnectChannelClose = () => {
		// @ts-ignore
		trpcUtils.inbox.inbox.getUserInbox.invalidate();
		setOpenConnectModal(false);
	};

	const handleChannelSelect = (provider: IIntegration) => {
		setOpenConnectModal(true);
		setSelectedProvider(provider);
	};

	return (
		<>
			<div className="t-gap--top">
				<header className="compose-connect-channel--header t-gap--bottom">
					<Typography.Title level={3}>
						<FormattedMessage
							id="COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.TITLE"
							defaultMessage="Connect your email channel"
						/>
					</Typography.Title>
					<p>
						<FormattedMessage
							id="COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.DESCRIPTION"
							defaultMessage="Connect email channels with your inbox."
						/>
					</p>
				</header>
				<ConnectChannel
					onClick={handleChannelSelect}
					className="compose-connect-channel--connections t-gap--bottom"
				/>
				<footer className="compose-connect-channel--footer">
					<p>
						<FormattedMessage
							id="COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.FOOTER"
							defaultMessage="You can manage your channels in <link>settings / channels</link>"
							values={{
								link: (children) => (
									<NavLink to={footerLink} className="a-link">
										{children}
									</NavLink>
								),
							}}
						/>
					</p>
				</footer>
			</div>
			<ConnectChannelModal
				step="addChannel"
				open={openConnectModal}
				provider={selectedProvider}
				onClose={handleConnectChannelClose}
			/>
		</>
	);
}
