import { combineReducers } from "redux";

import { actions as flowActions, reducers as flowReducer } from "./flow";
import { actions as reportActions, reducers as reportReducer } from "./report";
import { actions as statusTypesActions, reducers as statusTypesReducer } from "./status_types";

const actions = {
	flow: flowActions,
	report: reportActions,
	statusTypes: statusTypesActions,
};

const reducers = combineReducers({
	flow: flowReducer,
	report: reportReducer,
	statusTypes: statusTypesReducer,
});

export { actions, reducers };
