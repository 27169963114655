import "./style.scss";

import { EditOutlined } from "@ant-design/icons";
import type { IAdminPulseConfig } from "@bothive_core/database";
import { Button, Skeleton, Table } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import DashboardCard from "../../../../../../shared/components/card/dashboard";
import SkeletonTable from "../../../../../../shared/components/table/skeleton";
import { trpc } from "../../../../../../trpc";
import { flowActionsI18n, flowStatusI18n } from "./config";
import CreateTaskLinkModal from "./modals/createTaskLinkModal";
import EditTaskLinkModal from "./modals/editTaskLinkModal";

export default function AdminPulseTasks() {
	const intl = useIntl();
	const [showCreateModal, setShowCreateModal] = useState(false);
	const { connectionId } = useParams() as { connectionId: string };
	const [selectedLinkId, setSelectedLinkId] = useState<string | undefined>();

	const allowEditingIntegrations = useSelector(
		(state) => state.dashboard.permissions.userPermissions.editIntegrations,
		shallowEqual
	);

	const { data: connection, isLoading } = trpc.integration.getConnection.useQuery({ id: connectionId });

	const connectionConfig = connection?.config as IAdminPulseConfig;
	const { data: templates, isLoading: isTemplateLoading } = trpc.flowTemplate.getTemplates.useQuery(
		{ ids: connectionConfig?.taskLink?.map((item) => item.flowTemplateId) },
		{ enabled: Boolean(connectionConfig?.taskLink?.length) }
	);

	return (
		<DashboardCard
			title={intl.formatMessage({
				id: "settings.integrations.admin_pulse.tasks.title",
				defaultMessage: "Link AdminPulse tasks",
			})}
		>
			<Skeleton active loading={isLoading} style={{ paddingTop: "2em" }}>
				<header className="admin_pulse-tasks-header">
					<p className="admin_pulse-tasks-header-description">
						<FormattedMessage
							id="settings.integrations.admin_pulse.tasks.description"
							defaultMessage="Task Linking in AdminPulse is your secret sauce! Connect tasks in AdminPulse with flow actions in Bothive. If one of these actions happen, we update the status of that task in AdminPulse. It's like connecting the dots but for your tasks."
							description="Describe to the user what task linking is: with tasks linking they can link flow action with tasks in AdminPulse. If we execute an action we will updated the status of the linked task in AdminPulse."
						/>
					</p>
					<Button
						size="large"
						type="primary"
						onClick={() => setShowCreateModal(true)}
						disabled={!allowEditingIntegrations}
					>
						<FormattedMessage
							id="settings.integrations.admin_pulse.tasks.button.enable"
							defaultMessage="Add new link"
						/>
					</Button>
				</header>
			</Skeleton>
			{connectionConfig?.taskLink?.length > 0 && (
				<SkeletonTable
					pagination={
						connectionConfig.taskLink.length > 5 && {
							defaultPageSize: 5,
							showSizeChanger: true,
							showQuickJumper: false,
							pageSizeOptions: ["5", "10", "20", "50"],
						}
					}
					isLoading={isLoading}
					dataSource={connectionConfig?.taskLink || []}
					onRow={(_, index) => ({
						onClick: () => {
							setSelectedLinkId(index);
						},
					})}
				>
					<Table.Column
						ellipsis
						key="taskName"
						dataIndex={["task", "name"]}
						title={intl.formatMessage({
							id: "settings.integrations.admin_pulse.tasks.table.task_name.label",
							defaultMessage: "Task name",
						})}
					/>
					<Table.Column
						ellipsis
						key="subTaskName"
						dataIndex={["task", "subTaskName"]}
						title={intl.formatMessage({
							id: "settings.integrations.admin_pulse.tasks.table.sub_task_name.label",
							defaultMessage: "Sub task name",
						})}
					/>
					<Table.Column
						ellipsis
						key="flowTemplateId"
						dataIndex="flowTemplateId"
						title={intl.formatMessage({
							id: "settings.integrations.admin_pulse.tasks.table.template_name.label",
							defaultMessage: "Flow template",
						})}
						render={(flowTemplateId) => {
							const template = templates?.data?.find((item) => item._id === flowTemplateId);

							return (
								<Skeleton paragraph={{ rows: 1 }} loading={isTemplateLoading} active>
									{template?.name?.[intl.locale]}
								</Skeleton>
							);
						}}
					/>
					<Table.Column
						ellipsis
						key="flowAction"
						dataIndex="flowAction"
						title={intl.formatMessage({
							id: "settings.integrations.admin_pulse.tasks.table.flow_action.label",
							defaultMessage: "On action",
						})}
						render={(actions) => {
							if (!actions) return "";

							return new Intl.ListFormat(intl.locale, {
								style: "short",
								type: "disjunction",
							}).format(actions.map((field) => intl.formatMessage(flowActionsI18n[field]).toLowerCase()));
						}}
					/>
					<Table.Column
						ellipsis
						key="status"
						dataIndex="status"
						title={intl.formatMessage({
							id: "settings.integrations.admin_pulse.tasks.table.status.label",
							defaultMessage: "Move to status",
						})}
						render={(key) => <FormattedMessage {...flowStatusI18n[key]} />}
					/>
					<Table.Column
						key="edit"
						width="6em"
						title={null}
						dataIndex="_id"
						render={(id) => (
							<Button icon={<EditOutlined />} type="text" onClick={() => setSelectedLinkId(id)} />
						)}
					/>
				</SkeletonTable>
			)}
			{connection && (
				<>
					<CreateTaskLinkModal
						isOpen={showCreateModal}
						connectionId={connection._id}
						onClose={() => setShowCreateModal(false)}
					/>
					<EditTaskLinkModal
						connectionId={connection._id}
						isOpen={selectedLinkId !== undefined}
						onClose={() => setSelectedLinkId(undefined)}
						taskLink={selectedLinkId !== undefined && connectionConfig.taskLink[selectedLinkId]}
					/>
				</>
			)}
		</DashboardCard>
	);
}
