import { percentageValidator } from "@/helpers/validators.helpers";
import FieldLabel from "../../../../../../../../shared/components/field/default";
import { Form, InputNumber, Typography } from "antd";
import { IContact } from "@bothive_core/database";
import { useIntl } from "react-intl";

export default function CoOwnershipForm({ contact }: { contact: IContact }) {
	const intl = useIntl();

	return (
		<>
			<FieldLabel
				id="ownershipPercentage"
				label="CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_PERCENTAGE.LABEL"
				help="CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_PERCENTAGE.HELP"
				labelValue={{
					name: contact.fullName,
					bold: (content) => <Typography.Text strong>{content}</Typography.Text>,
				}}
				optional={true}
			>
				<Form.Item
					name="ownershipPercentage"
					className="foreign_property_modal-form_item"
					rules={[
						{
							validator: (_, value) => percentageValidator(value, intl),
						},
					]}
				>
					<InputNumber prefix="%" placeholder="100" max={100} />
				</Form.Item>
			</FieldLabel>
		</>
	);
}
