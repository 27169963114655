import "./style.scss";

import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { routesConfig } from "@/flows/common/config";
import FlowDetailBody from "@/flows/overview/create/detail/body";
import FlowDetailHeader from "@/flows/overview/create/detail/header";
import { createFlow } from "@/flows/overview/create/query";
import {
	isAddFlowModalVisibleState,
	selectedFlowTemplate,
	selectedTemplateIdState,
} from "@/flows/overview/create/state";
import { addFlow, flowMeta } from "@/flows/overview/state";
import ShowFailedBanner from "@/shared/components/banner/failed";

import WarningBanner from "./warning_banner";

export default function AddFlowSelectDetail() {
	const intl = useIntl();
	const params = useParams();
	const history = useHistory();
	const [isCreating, setIsCreating] = useState(false);
	const flowPermissions = useSelector((state) => state.dashboard.permissions.flows);

	const flowMetaData = useRecoilValue(flowMeta);
	const addNewFlowToState = useSetRecoilState(addFlow);
	const selectedTemplate = useRecoilValue(selectedFlowTemplate);
	const setIsModalVisible = useSetRecoilState(isAddFlowModalVisibleState);
	const setSelectedTemplateId = useSetRecoilState(selectedTemplateIdState);
	const isLocked = flowPermissions <= (flowMetaData.totalEnabledPremium || 0);

	if (!selectedTemplate) {
		setIsModalVisible(false);

		return ShowFailedBanner({
			title: intl.formatMessage({
				id: "FLOWS.OVERVIEW.ADD_FLOW.BANNER.FAILED",
				defaultMessage: "Flow cannot be created at the moment. Try again later.",
			}),
		});
	}

	const handleAddFlow = async () => {
		setIsCreating(true);

		try {
			const newFlow = await createFlow({ templateId: selectedTemplate._id });
			const url = routesConfig.reports.replace(":team", params.team).replace(":flowId", newFlow.data._id);

			setIsModalVisible(false);
			addNewFlowToState(newFlow.data);
			setSelectedTemplateId(undefined);
			history.push(url);
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.OVERVIEW.ADD_FLOW.BANNER.FAILED",
					defaultMessage: "Flow cannot be created at the moment. Try again later.",
				}),
			});
		}

		setIsCreating(false);
	};

	return (
		<div className="layout--modal-flow-template-detail">
			<FlowDetailHeader
				title={selectedTemplate?.name?.[intl.locale]}
				description={selectedTemplate?.description?.[intl.locale]}
				src={selectedTemplate?.logo}
				freemium={selectedTemplate?.freemium}
				onClick={handleAddFlow}
				isLoading={isCreating}
				disabled={isLocked}
				onReturn={() => setSelectedTemplateId(undefined)}
				className="layout-header"
			/>
			<WarningBanner isLocked={isLocked && !selectedTemplate?.freemium} template={selectedTemplate} />
			<FlowDetailBody />
		</div>
	);
}
