import { FC, ReactNode, useEffect, useRef } from "react";

interface ShadowViewProps {
	className?: string;
	children: ReactNode;
}

const ShadowView: FC<ShadowViewProps> = ({ className, children }) => {
	const shadowContainer = useRef(null);

	const attachShadow = (host: HTMLElement | null) => {
		if (!host) {
			return;
		}

		const shadowRoot = host.attachShadow({ mode: "open" });

		[].slice.call(host.children).forEach((child) => {
			shadowRoot.appendChild(child);
		});
	};

	useEffect(() => {
		if (shadowContainer) {
			attachShadow(shadowContainer.current);
		}
	}, [shadowContainer]);

	return (
		<div ref={shadowContainer} className={className}>
			{children}
		</div>
	);
};
export default ShadowView;
