import { hideWarning, setWarning } from "../ui/ui.action";
import actionTypes from "./errorHandling.actionTypes";

const warningBannerId = "errorHandling";

export const throwNewError = ({ error, info }) => (dispatch) => {
	dispatch({
		type: actionTypes.THROW_NEW_ERROR,
		data: {
			error,
			info,
		},
	});
	dispatch(setWarning({ id: warningBannerId, message: "WARNING.SUPPORT.ERROR" }));
};

export const errorResolved = () => (dispatch) => {
	dispatch({ type: actionTypes.ERROR_RESOLVED });
	dispatch(hideWarning(warningBannerId));
};

export const throwInboxError = () => (dispatch) => {
	dispatch({ type: actionTypes.THROW_INBOX_ERROR });
};

export const inboxErrorResolved = () => (dispatch) => {
	dispatch({ type: actionTypes.INBOX_ERROR_RESOLVED });
};
