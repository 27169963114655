import React from "react";
import { FormattedMessage } from "react-intl";

import { Banner } from "..";
import { Link } from "../../atoms";

export default function MCookieConcent({ url, className, onClose }) {
	return (
		<Banner.MBannerDefault onClose={onClose} timing={20_000} className={`m-banner m-banner--concent ${className}`}>
			<p>
				<FormattedMessage id="BANNER.COOKIE_CONCENT.BODY" />
			</p>
			<Link.ASaveUrl url={url} className="banner-button white">
				<FormattedMessage id="BANNER.COOKIE_CONCENT.LINK" />
			</Link.ASaveUrl>
		</Banner.MBannerDefault>
	);
}

MCookieConcent.defaultProps = {
	url: "",
	className: "",
	onClose: undefined,
};
