import "./style.scss";

import { KeyboardEventHandler, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { keyEventsHelpers } from "@/shared/helpers";
import { trpc } from "@/trpc";
import { ConnectChannel, WindowHeader } from "./components";
import ComposeController from "./controller";
import {
	attachmentsState,
	composeWindowState,
	composeState,
	composeWindowReplyTypeState,
	openState,
	composeNewDraft,
} from "./state";

function ComposeWindow() {
	const [isOpen, setIsOpen] = useRecoilState(openState);
	const [composeDraftMessage, setComposeDraftMessage] = useRecoilState(composeState);
	const [attachments, setAttachments] = useRecoilState(attachmentsState);
	const [windowState, setWindowState] = useRecoilState(composeWindowState);
	const startNewDraft = useSetRecoilState(composeNewDraft);
	const [composeWindowReplyType, setIsComposeWindowForwardState] = useRecoilState(composeWindowReplyTypeState);

	const channelsQuery = trpc.channel.getInboxChannels.useQuery(
		{ type: "email", limit: 0 },
		{ refetchOnWindowFocus: false, staleTime: Infinity }
	);

	function handleClose() {
		setWindowState("default");
		setIsComposeWindowForwardState(undefined);
		setComposeDraftMessage(undefined);
		setAttachments([]);
		setIsOpen(false);
	}

	const handleKeyDown: KeyboardEventHandler<HTMLElement> = (event) => {
		if (event.key === "Escape") handleClose();
	};

	//Keyboard shortcuts
	useEffect(() => {
		// Should only execute when state.selectedId is changed else selected id won't be updated in handleKeyUp
		window.addEventListener("keyup", handleKeyUp);

		return () => {
			window.removeEventListener("keyup", handleKeyUp);
		};
	}, []);

	function handleKeyUp(event) {
		const functionMap = {
			"inbox.new_message": () => startNewDraft(undefined),
		};

		keyEventsHelpers.handleShortcutPress({ event, functionMap });
	}

	if (!isOpen) return null;

	return (
		<dialog open data-window-state={windowState} onKeyDown={handleKeyDown} className="inbox-composer">
			<WindowHeader onClose={handleClose} />
			<ComposeController
				key={composeDraftMessage?.key}
				initialValue={composeDraftMessage}
				replyType={composeWindowReplyType}
				attachments={attachments}
				onClose={handleClose}
			/>
			{!channelsQuery.data?.data.length && !channelsQuery.isLoading && <ConnectChannel />}
		</dialog>
	);
}

export default ComposeWindow;
