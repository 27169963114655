import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";

export default function OSectionInformation({
	description,
	buttonLabel,
	formattedMessageValues,
	hideButton,
	loading,
	className,
	onClick,
}) {
	return (
		<div className={`o-section o-section--information ${className}`}>
			<div className="section-body">
				<p style={{ maxWidth: "75ch" }}>
					<FormattedMessage id={description} values={formattedMessageValues} />
				</p>
			</div>
			{!hideButton && (
				<Button loading={loading} onClick={onClick}>
					<FormattedMessage id={buttonLabel} />
				</Button>
			)}
		</div>
	);
}
