const baseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:8980"; //eslint-disable-line no-undef

const endpoints = {
	api: "/api/v1",
};

export default {
	api: {
		baseUrl,
		endpoints,
		getAllTemplates: `${baseUrl}${endpoints.api}/flow/template`,
		create: `${baseUrl}${endpoints.api}/flows`,
	},
};
