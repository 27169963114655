import "./style.scss";

import { Form, Input } from "antd";
import { useIntl } from "react-intl";

import FieldLabel from "@/shared/components/field/default";
import CountryPicker from "@/shared/components/select/country_picker";
import { stringHelpers } from "@/shared/helpers";

export default function AddressForm() {
	const intl = useIntl();
	const validate = ({
		value,
		checkEmpty,
		isEmptyMessage,
		checkSymbols = false,
		checkNumbers = false,
	}: {
		value: string;
		checkEmpty?: boolean;
		isEmptyMessage?: string;
		checkSymbols?: boolean;
		checkNumbers?: boolean;
	}): Promise<void> => {
		if (!checkEmpty) checkEmpty = true;

		if (checkEmpty && !value?.length) {
			return Promise.reject(new Error(isEmptyMessage));
		}

		if (checkSymbols && stringHelpers.hasSymbols(value)) {
			const message = intl.formatMessage({
				id: "organization_settings.general.locations.form.error.hasSymbols",
				defaultMessage: "Please remove any special symbols",
			});
			return Promise.reject(new Error(message));
		}
		if (checkNumbers && stringHelpers.hasNumbers(value)) {
			const message = intl.formatMessage({
				id: "organization_settings.general.locations.form.error.hasNumbers",
				defaultMessage: "Please remove any numbers",
			});
			return Promise.reject(new Error(message));
		}

		return Promise.resolve();
	};

	return (
		<div className="location_settings-address_form">
			<FieldLabel
				label={intl.formatMessage({
					id: "organization_settings.general.locations.form.country.label",
					defaultMessage: "Country",
				})}
				className="address_form-country"
			>
				<Form.Item
					name="country"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "organization_settings.general.locations.form.country.error.required",
								defaultMessage: "Please fill in a country",
							}),
						},
					]}
				>
					<CountryPicker />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				label={intl.formatMessage({
					id: "organization_settings.general.locations.form.postal_code.label",
					defaultMessage: "Postal code",
				})}
				className="address_form-postal_code"
			>
				<Form.Item
					name="postalCode"
					rules={[
						{
							required: true,
							validator: (_, value: string) =>
								validate({
									value,
									isEmptyMessage: intl.formatMessage({
										id: "organization_settings.general.locations.form.postal_code.error.required",
										defaultMessage: "Please fill in a postal code",
									}),
									checkSymbols: true,
								}),
						},
					]}
				>
					<Input maxLength={10} />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				label={intl.formatMessage({
					id: "organization_settings.general.locations.form.city",
					defaultMessage: "City",
				})}
				className="address_form-city"
			>
				<Form.Item
					name="city"
					rules={[
						{
							required: true,
							validator: (_, value: string) =>
								validate({
									value,
									isEmptyMessage: intl.formatMessage({
										id: "organization_settings.general.locations.form.city.error.required",
										defaultMessage: "Please fill in a city",
									}),
									checkSymbols: true,
									checkNumbers: true,
								}),
						},
					]}
				>
					<Input maxLength={30} />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				label={intl.formatMessage({
					id: "organization_settings.general.locations.form.province.label",
					defaultMessage: "Province",
				})}
				className="address_form-province"
				optional
			>
				<Form.Item name="province">
					<Input maxLength={30} />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				className="address_form-street_name"
				label={intl.formatMessage({
					id: "organization_settings.general.locations.form.street_name.label",
					defaultMessage: "Street name",
				})}
			>
				<Form.Item
					name="streetName"
					rules={[
						{
							required: true,
							validator: (_, value: string) =>
								validate({
									value,
									isEmptyMessage: intl.formatMessage({
										id: "organization_settings.general.locations.form.street_name.error.required",
										defaultMessage: "Please fill in a street name",
									}),
									checkSymbols: true,
								}),
						},
					]}
				>
					<Input maxLength={30} />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				label={intl.formatMessage({
					id: "organization_settings.general.locations.form.street_number.label",
					defaultMessage: "Street number",
				})}
				className="address_form-street_number"
			>
				<Form.Item
					name="streetNumber"
					rules={[
						{
							required: true,
							validator: (_, value: string) =>
								validate({
									value,
									isEmptyMessage: intl.formatMessage({
										id: "organization_settings.general.locations.form.street_number.error.required",
										defaultMessage: "Please fill in a street number",
									}),
									checkSymbols: true,
								}),
						},
					]}
				>
					<Input maxLength={8} />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				label={intl.formatMessage({
					id: "organization_settings.general.locations.form.bus.label",
					defaultMessage: "Bus",
				})}
				className="address_form-bus"
				optional
			>
				<Form.Item
					name="bus"
					rules={[
						{
							required: true,
							validator: (_, value) => {
								if (/[^a-zA-Z0-9_\-\. ]+/i.test(value)) {
									const message = intl.formatMessage({
										id: "organization_settings.general.locations.form.bus.error.special_symbol",
										defaultMessage: "Only a-z,0-9,-,_ and . are supported in bus number",
										description: "Bus number should not contain any special characters",
									});

									return Promise.reject(new Error(message));
								}

								return Promise.resolve();
							},
						},
					]}
				>
					<Input maxLength={4} />
				</Form.Item>
			</FieldLabel>
		</div>
	);
}
