import { defaultContext } from "@tanstack/react-query";
import { createTRPCReact } from "@trpc/react-query";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import type { DashboardRouter } from "@bothive/server/src/modules/trpc";

// INPUT
export type MessageRouterInput = inferRouterInputs<DashboardRouter>["inbox"]["message"];
export type MemberRouterInput = inferRouterInputs<DashboardRouter>["member"];

// OUTPUT
export type InboxRouterOutput = inferRouterOutputs<DashboardRouter>["inbox"]["inbox"];
export type InboxLabelRouterOutput = inferRouterOutputs<DashboardRouter>["inbox"]["inboxLabel"];
export type MessageRouterOutput = inferRouterOutputs<DashboardRouter>["inbox"]["message"];
export type ConversationRouterOutput = inferRouterOutputs<DashboardRouter>["inbox"]["conversation"];
export type ChannelRouterOutput = inferRouterOutputs<DashboardRouter>["channel"];
export type MemberRouterOutput = inferRouterOutputs<DashboardRouter>["member"];
export type EmailSignatureRouterOutput = inferRouterOutputs<DashboardRouter>["emailSignature"];
export type ContactRouterOutput = inferRouterOutputs<DashboardRouter>["contact"];
export type ContactLabelRouterOutput = inferRouterOutputs<DashboardRouter>["contactLabel"];
export type IntegrationsRouterOutput = inferRouterOutputs<DashboardRouter>["integrations"];
export type FlowRemindersRouterOutput = inferRouterOutputs<DashboardRouter>["flowReminders"];
export type ReportRouterOutput = inferRouterOutputs<DashboardRouter>["report"];
export type FlowRouterOutput = inferRouterOutputs<DashboardRouter>["flow"];
export type ApiKeyManagerRouterOutput = inferRouterOutputs<DashboardRouter>["apiKeyManager"];

export const trpc = createTRPCReact<DashboardRouter>({ reactQueryContext: defaultContext });
