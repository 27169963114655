export default {
	TEAM_IS_FETCHING: "TEAM_IS_FETCHING",
	FETCHING_TEAM_FAILED: "FETCHING_TEAM_FAILED",
	FETCHING_TEAM_SUCCEEDED: "FETCHING_TEAM_SUCCEEDED",

	FETCH_TEAM: "FETCH_TEAM",
	TEAM_CHANGED: "TEAM_CHANGED",
	TEAM_ADDED: "TEAM_ADDED",
	CREATE_TEAM: "CREATE_TEAM",
	UPDATE_TEAM: "UPDATE_TEAM",
	UPDATE_TEAM_LOCAL: "UPDATE_TEAM_LOCAL",
	UPDATE_BILLING_INFORMATION: "UPDATE_BILLING_INFORMATION",
	DELETE_TEAM: "DELETE_TEAM",

	CLEAR_TEAM: "CLEAR_TEAM",

	TEAM_IS_ALREADY_FETCHED: "TEAM_IS_ALREADY_FETCHED",
};
