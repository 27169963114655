import { Form, Select } from "antd";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { trpc } from "@/trpc";
import { defaultValue } from "@/config";
import { updateReportInList } from "@/flows/flow_detail/reports/state";
import { reportState } from "@/flows/report_detail/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import SelectLoading from "@/shared/components/select/loading";
import UserPreview from "@/shared/components/user/user_preview";

import query from "./query";
import style from "./style.module.scss";
import { useEffect } from "react";

export default function AssigneeSection({ className }) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const queryParams = useParams<{ flowId: string; reportId: string }>();
	const flowId = queryParams.flowId;
	const reportId = queryParams.reportId;
	const updateReportList = useSetRecoilState(updateReportInList);
	const setReport = useSetRecoilState(reportState({ flowId, reportId }));
	const report = useRecoilValueLoadable(reportState({ flowId, reportId }));
	const { data: teamMembers, isLoading: isFetchingTeamMembers } = trpc.member.getAll.useQuery({ limit: 0 });

	const membersList = useMemo(
		() => [
			...(teamMembers?.data || []),
			{
				...defaultValue.unAssigned,
				name: intl.formatMessage({ id: defaultValue.unAssigned.label }),
			},
		],
		[teamMembers?.data]
	);

	const handleUpdate = async (assigneeId) => {
		const assignedMember = membersList.find((member) => member.userId === assigneeId);

		try {
			setReport({ ...report.contents, assignee: assigneeId });

			const assignee = defaultValue.unAssigned.id === assigneeId ? undefined : assigneeId;

			await query.updateReportAssignee({ flowId, reportId, assignee });

			updateReportList({
				reportId,
				payload: {
					assignee: { avatar: assignedMember.avatar, username: assignedMember.name },
				},
			});
		} catch (_) {
			setReport(report.contents);
			ShowFailedBanner({
				title: assignedMember?.name
					? intl.formatMessage(
							{
								id: "FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.BANNER.ASSIGN_USER_FAILED",
								defaultMessage: "{username} could not be assigned to report. Please try again later.",
							},
							{
								username: assignedMember?.name,
							}
					  )
					: intl.formatMessage(
							{
								id: "FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.BANNER.UNASSIGN_FAILED",
								defaultMessage: "Could not unassign {username} from report. Please try again later.",
							},
							{
								username: assignedMember?.name,
							}
					  ),
			});
		}
	};

	useEffect(() => {
		if (!report.contents) return;

		form.setFieldsValue({ assignee: report.contents?.assignee || defaultValue.unAssigned.id });
	}, [report.contents]);

	return (
		<section className={`${style.assignee_section} ${className}`}>
			<Form layout="vertical" form={form}>
				<Form.Item
					name="assignee"
					label={intl.formatMessage({ id: "FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.LABEL" })}
					className={style.assignee_dropdown_label}
				>
					<SelectLoading
						isLoading={isFetchingTeamMembers}
						className={style.assignee_dropdown}
						placeholder="FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.PLACEHOLDER"
						onChange={handleUpdate}
						allowClear={false}
						showSearch
					>
						{membersList.map((member) => (
							<Select.Option
								key={member.userId}
								value={member.userId}
								className={style.assignee_section_dropdown}
							>
								<UserPreview
									id={member._id}
									src={member.avatar}
									username={member.name}
									loading={isFetchingTeamMembers}
								/>
							</Select.Option>
						))}
					</SelectLoading>
				</Form.Item>
			</Form>
		</section>
	);
}

AssigneeSection.defaultProps = {
	className: "",
};
