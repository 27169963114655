import { ContactRouterOutput, trpc } from "@/trpc";
import { FolderAddOutlined } from "@ant-design/icons";

import { Button, notification, Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilState } from "recoil";
import { selectedAllArchivedContactsState, selectedArchivedContactsState } from "../state";

interface UnarchiveProps {
	filters: {
		q?: string;
		contactType?: string;
		labelId?: string;
		accountManagerId?: string;
	};
	includesDeleted: boolean;
	contacts?: ContactRouterOutput["getAllContacts"];
}

export default function Unarchive({ filters, contacts, includesDeleted }: UnarchiveProps) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const [selectedContacts, setSelectedContacts] = useRecoilState(selectedArchivedContactsState);
	const [selectedAll, setSelectedAll] = useRecoilState(selectedAllArchivedContactsState);

	const { data: deletedContacts } = trpc.contact.getAllContacts.useQuery(
		{
			limit: 0,
			...filters,
			archived: true,
			deleted: includesDeleted,
		},
		{ enabled: includesDeleted }
	);

	const bulkMutateArchiveMutation = trpc.contact.bulkMutateArchive.useMutation({
		onSuccess: (result) => {
			notification.success({
				message: intl.formatMessage(
					{
						id: "CONTACT.OVERVIEW.BULK_ACTION.UNARCHIVE.SUCCESS",
						defaultMessage: "{count, plural, one {# contact} other {# contacts}} unarchived successfully.",
					},
					{ count: result.nModified }
				),
				placement: "bottomRight",
			});

			trpcUtils.contact.getAllContacts.invalidate();
			setSelectedAll(false);
			setSelectedContacts([]);
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "CONTACT.OVERVIEW.BULK_ACTION.UNARCHIVE.ERROR",
					defaultMessage: "Something went wrong while executing bulk unarchive.",
				}),
				placement: "bottomRight",
			});
		},
	});

	function handleArchiveContacts() {
		bulkMutateArchiveMutation.mutate({
			action: "unarchive",
			contactIds: selectedContacts,
			mutateAll: selectedAll,
			filters,
		});
	}
	const onlyDeletedContactsCount = selectedAll
		? // @ts-ignore
		  deletedContacts?.data?.filter((contact) => contact.deletedAt && !contact.archivedAt)
		: contacts?.data?.filter(
				// @ts-ignore
				(contact) => selectedContacts.includes(contact._id) && contact.deletedAt && !contact.archivedAt
		  );

	return (
		<Tooltip
			title={
				!!onlyDeletedContactsCount?.length &&
				intl.formatMessage(
					{
						id: "CONTACTS.OVERVIEW.BULK_ACTION.UNARCHIVE.BUTTON.DISABLED.TOOLTIP",
						defaultMessage:
							"You have {count, plural, one {# deleted contact} other {# deleted contacts}} that are not archived inside your selection, you can't perform an unarchive action on contacts that are only deleted",
					},
					{
						count: onlyDeletedContactsCount?.length,
					}
				)
			}
		>
			<Button
				loading={bulkMutateArchiveMutation.isLoading}
				disabled={!!onlyDeletedContactsCount?.length}
				onClick={() => handleArchiveContacts()}
				type="text"
				icon={<FolderAddOutlined />}
			>
				{" "}
				<FormattedMessage
					id="CONTACTS.OVERVIEW.BULK_ACTION.UNARCHIVE.BUTTON.TITLE"
					defaultMessage="Unarchive"
				/>
			</Button>
		</Tooltip>
	);
}
