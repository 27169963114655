import axios from "axios";

import { queryStringHelpers } from "@/helpers";

import config from "./config";

export const getStatusTypes = async ({ flowId, limit, offset }) => {
	const query = queryStringHelpers.createQueryString({
		flowId,
		limit,
		offset,
	});
	const url = config.api.getAll.replace(":flowId", flowId);
	const { data } = await axios.get(`${url}${query}`);

	if (!data.success) {
		throw new Error();
	}

	return data;
};

export const reorderStatusType = async ({ flowId, statusType }) => {
	const url = config.api.reOrder.replace(":flowId", flowId);
	const payload = { id: statusType._id, order: statusType.order };

	const { data } = await axios.put(url, payload);

	if (!data.success) {
		throw new Error("Failed to update status order type");
	}

	return data;
};
