import "./style.scss";
import type { FC, KeyboardEventHandler, MouseEventHandler, ReactNode } from "react";

interface SideNavSubItemProps {
	title: ReactNode;
	actionIcon?: ReactNode;
	onClickAction?: Function;
}

const SideNavSubItem: FC<SideNavSubItemProps> = ({ title, actionIcon, onClickAction }) => {
	const handleOnClick: MouseEventHandler = (event) => {
		event.stopPropagation();
		if (onClickAction) {
			onClickAction();
		}
	};

	const handleKeypress: KeyboardEventHandler<HTMLButtonElement> = (event) => {
		event.stopPropagation();
		if (event.key === "Enter") {
			if (onClickAction) {
				onClickAction();
			}
		}
	};

	return (
		<div className="sn-submenu-item">
			{title}
			{actionIcon && (
				<button className="sn-action-icon-btn" onKeyDown={handleKeypress} onClick={handleOnClick}>
					{actionIcon}
				</button>
			)}
		</div>
	);
};
export default SideNavSubItem;
