import debounce from "./debounce.helpers";

let windowObjectReference: Window | null = null,
	previousUrl: string;

export function receiveMessage(event: any, callback: <Type>(data: Type) => void) {
	if (!event?.data?.code) return;

	window.removeEventListener("message", (event) => receiveMessage(event, callback));
	debounce(() => callback(event.data));
}

interface IOpenSignInWindow {
	url: string;
	name: string;
	blank?: boolean;
	callback: <Type>(data: Type, error?: { message: string }) => void;
}
export function openSignInWindow({ url, name, blank = false, callback }: IOpenSignInWindow) {
	window.removeEventListener("message", (event) => receiveMessage(event, callback));

	const _name = blank ? "_blank" : name;

	if (previousUrl !== url) {
		windowObjectReference = window.open(url, _name);
		windowObjectReference?.focus();
	} else {
		windowObjectReference = window.open(url, _name);
		windowObjectReference?.focus();
	}

	if (!windowObjectReference || windowObjectReference.closed || typeof windowObjectReference.closed === "undefined") {
		callback(undefined, { message: "blocked" });
		return;
	}

	window.addEventListener("message", (event) => receiveMessage(event, callback), false);
	previousUrl = url;
}
