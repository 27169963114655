import "./style.scss";

import { Form, Input, Tooltip, Switch } from "antd";
import FieldLabel from "@/shared/components/field/default";
import FieldSideLabel from "@/shared/components/field/side_label";
import { FormattedMessage, useIntl } from "react-intl";
import { debounceHelpers, stringHelpers } from "@/shared/helpers";
import { PHONE_REGEX } from "@/helpers/validators.helpers";

export default function ContactForm({
	disableSwitch,
	onIsPrimaryChange,
}: {
	disableSwitch?: boolean;
	onIsPrimaryChange?: (value: boolean) => void;
}) {
	const intl = useIntl();

	return (
		<div className="location_settings-contact_form">
			<div className="location_settings-contact_form-sub_header">
				<p>
					<FormattedMessage
						id="organization_settings.general.locations.contact_info.title"
						defaultMessage="Contact information"
					/>
				</p>
				<hr />
			</div>
			<FieldLabel
				label={intl.formatMessage({
					id: "organization_settings.general.locations.contact_info.form.email.label",
					defaultMessage: "Email",
				})}
			>
				<Form.Item
					name="email"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "organization_settings.general.locations.contact_info.form.email.error",
								defaultMessage: "Email is required",
							}),
						},
						{
							type: "email",
							message: intl.formatMessage({
								id: "organization_settings.general.locations.contact_info.form.email.invalid",
								defaultMessage: "Email looks to be incomplete",
							}),
						},
					]}
				>
					<Input maxLength={320} />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				label={intl.formatMessage({
					id: "organization_settings.general.locations.contact_info.form.phone.label",
					defaultMessage: "Phone",
				})}
				optional
			>
				<Form.Item
					name="phone"
					rules={[
						{
							pattern: PHONE_REGEX,
							message: intl.formatMessage({
								id: "organization_settings.general.locations.contact_info.form.phone.max_length",
								defaultMessage: "This looks like a invalid phone number",
							}),
						},
					]}
				>
					<Input />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				label={intl.formatMessage({
					id: "organization_settings.general.locations.contact_info.form.enterprise_number.label",
					defaultMessage: "Enterprise number",
				})}
			>
				<Form.Item
					name="enterpriseNumber"
					rules={[
						{
							required: true,
							validator: (_, value: string) => {
								if (value.length === 0) {
									const message = intl.formatMessage({
										id: "organization_settings.general.locations.contact_info.form.enterprise_number.error.required",
										defaultMessage: "Enterprise number is required",
									});
									return Promise.reject(new Error(message));
								}

								return new Promise((resolve, reject) =>
									debounceHelpers(async () => {
										const isEnterpriseNumberValid = await stringHelpers.isEnterpriseNumberValid(
											value
										);

										if (!isEnterpriseNumberValid.valid) {
											const message = intl.formatMessage({
												id: "organization_settings.general.locations.contact_info.form.enterprise_number.error.notvalid",
												defaultMessage: "Enterprise number is not valid",
											});

											return reject(new Error(message));
										}

										return resolve(true);
									})
								);
							},
						},
					]}
				>
					<Input
						placeholder={intl.formatMessage({
							id: "organization_settings.general.locations.contact_info.form.enterprise_number.placeholder",
							defaultMessage: "Ex. BE0665684769",
						})}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldSideLabel
				label={intl.formatMessage({
					id: "organization_settings.general.locations.contact_info.form.is_primary.label",
					defaultMessage: "Mark as primary address",
				})}
			>
				<Form.Item name="isPrimary" valuePropName="checked">
					<Tooltip
						title={
							disableSwitch
								? intl.formatMessage({
										id: "organization_settings.general.locations.contact_info.form.is_primary.tooltip",
										defaultMessage: "Can't be changed as this is the primary address",
										description: "Show tooltip a primary address can not be unmarked",
								  })
								: ""
						}
					>
						<Form.Item name="isPrimary" valuePropName="checked">
							<Switch disabled={disableSwitch} onChange={onIsPrimaryChange} />
						</Form.Item>
					</Tooltip>
				</Form.Item>
			</FieldSideLabel>
		</div>
	);
}
