import { TDependantAmount, TDependantAmountSubType } from "@bothive_core/database";

export const tagConfig = {
	child: { label: "CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.CHILD", color: "gold" },
	dependent: { label: "CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.DEPENDENT", color: "blue" },
	partner: { label: "CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.PARTNER", color: "orange" },
	other: { label: "CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.OTHER", color: "red" },
	married: { label: "MARRIED", color: "magenta" },
	cohabiting: { label: "LEGALLY_COHABITING", color: "cyan" },
	widower: { label: "WIDOWER_WIDOW", color: "geekblue" },
	separated: { label: "DIVORCED", color: "volcano" },
	defactoSeparated: { label: "ACTUALLY_DIVORCED", color: "purple" },
};

export const dependentType = {
	child: "CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.CHILD",
	dependent: "CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.DEPENDENT",
	partner: "CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.PARTNER",
	other: "CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.OTHER",
} as const;

export const amountDependent: Record<TDependantAmount, string> = {
	full: "FULLYCHARGED",
	co: "CO-PARENTSHIP",
};

export const amountDependentSubtype: Record<TDependantAmountSubType, string> = {
	fiscally_at_my_expense: "FISCALLYATMYEXPENSE",
	fiscally_dependent_on_other_parent: "FISCALLYDEPENDENTONOTHERPARENT",
};

export const maritalStatusTypes = {
	married: "MARRIED",
	cohabiting: "LEGALLY_COHABITING",
	widower: "WIDOWER_WIDOW",
	separated: "DIVORCED",
	defactoSeparated: "ACTUALLY_DIVORCED",
} as const;

export const matrimonialPropertySystemTypes = {
	statutorySystem: "STATUTORY_SYSTEM",
	separationOfGoods: "SEPARATION_OF_GOODS",
	communityProperty: "OVERALL_COMMUNITY",
	other: "OTHER",
} as const;
