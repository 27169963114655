const baseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:8980"; //eslint-disable-line no-undef
const fileUrl = process.env.REACT_APP_FILE_SERVER_HOST || "http://localhost:4449"; //eslint-disable-line no-undef
const mapsKey = process.env.REACT_MAPS_KEY || "AIzaSyAxYyLp4kNCNgvME7wCw6vWWkYXS-5ClsE"; //eslint-disable-line no-undef
const trpcUrl = process.env.REACT_APP_TRPC_URL || "http://localhost:8980/trpc"; //eslint-disable-line no-undef
const trpcWsUrl = process.env.REACT_APP_TRPC_WS_URL || "ws://localhost:8980/trpc"; //eslint-disable-line no-undef
const version = "/v1";

const endpoints = {
	api: "/api/v1",
	auth: "/auth/v1",
	serve: "/serve",
};

export default {
	baseUrl,
	fileUrl,
	endpoints,
	trpcUrl,
	trpcWsUrl,
	analytics: {
		bulk: `${baseUrl}${endpoints.api}/analytics/bulk`,
	},
	external: {
		previewWidget: "https://preview.bothive.be?website=:url&apiKey=:apiKey",
		avatar: "https://ui-avatars.com/api/?name=:name&background=D4D6DB&color=293249&size=224", //eslint-disable-line max-len
	},
	apiKey: {
		getKeys: `${baseUrl}${endpoints.api}/api_keys`,
		getById: `${baseUrl}${endpoints.api}/api_keys/:id`,
		getPermissions: `${baseUrl}${endpoints.api}/api_keys/permissions`,

		create: `${baseUrl}${endpoints.api}/api_keys`,
		update: `${baseUrl}${endpoints.api}/api_keys/:id`,
		delete: `${baseUrl}${endpoints.api}/api_keys/:id`,
	},
	auth: {
		login: `${baseUrl}${endpoints.auth}/login`,
		logout: `${baseUrl}${endpoints.auth}/logout`,
		office: (credentials) =>
			`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${
				credentials.clientId
			}&response_type=code&redirect_uri=${
				credentials.redirectUri
			}&response_mode=query&scope=${credentials.scope.join(",")}&state=${window.origin}`, //eslint-disable-line max-len
	},
	connections: {
		getAll: `${baseUrl}${endpoints.api}/connection`,
	},
	contact: {
		labels: {
			getByName: `${baseUrl}${endpoints.api}/contacts/labels/name/:name`,
		},
	},
	file: {
		file: `${fileUrl}/v1/file`,
		image: `${fileUrl}/v1/image`,
		conversation: `${baseUrl}${endpoints.api}/file/team/:teamUuid/conversation/:clientUuid?type=:type`,

		delete: `${fileUrl}/v1/file/:fileId`,
	},
	flow: {
		getFlows: `${baseUrl}${endpoints.api}/flows`,
		getById: `${baseUrl}${endpoints.api}/flows/:id`,
		create: `${baseUrl}${endpoints.api}/flows`,
		update: `${baseUrl}${endpoints.api}/flows/:id`,
		delete: `${baseUrl}${endpoints.api}/flows/:id`,

		sendFlow: `${baseUrl}${endpoints.api}/flows/:id/send`,

		report: {
			getAll: `${baseUrl}${endpoints.api}/flows/:id/reports`,
			getTemplates: `${baseUrl}${endpoints.api}/flows/:id/reports/template`,
			getById: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId`,
			download: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId/download`,
			update: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId`,
			assign: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId/assign`,
			delete: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId`,
			attachments: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId/attachments`,
			attachmentsZipped: `${fileUrl}${version}${endpoints.serve}/flow/reports/:reportId/attachments/zipped`,
		},
		statusTypes: {
			getAll: `${baseUrl}${endpoints.api}/flows/:flowId/status_types`,
			create: `${baseUrl}${endpoints.api}/flows/:flowId/status_types`,
			update: `${baseUrl}${endpoints.api}/flows/:flowId/status_types/:statusTypeId`,
			copy: `${baseUrl}${endpoints.api}/flows/:flowId/status_types/copy`,
			delete: `${baseUrl}${endpoints.api}/flows/:flowId/status_types/:statusTypeId`,
		},
		template: {
			getAll: `${baseUrl}${endpoints.api}/flow/template`,
		},
	},
	inbox: {
		signatures: {
			getAll: `${baseUrl}${endpoints.api}/inbox/:inboxId/signatures`,
			getTemplates: `${baseUrl}${endpoints.api}/inbox/:inboxId/signatures/templates`,
			getById: `${baseUrl}${endpoints.api}/inbox/:inboxId/signatures/:id`,
			create: `${baseUrl}${endpoints.api}/inbox/:inboxId/signatures`,
			update: `${baseUrl}${endpoints.api}/inbox/:inboxId/signatures/:id`,
			delete: `${baseUrl}${endpoints.api}/inbox/:inboxId/signatures/:id`,
		},
	},
	integrations: {
		getAll: `${baseUrl}${endpoints.api}/integration`,
		getById: `${baseUrl}${endpoints.api}/integration/:id`,
		action: `${baseUrl}${endpoints.api}/integration/:id/action/:action`,
	},
	maps: {
		location: ({ streetName, streetNumber, postalCode, city, primary }) => {
			const address = `${streetName}+${streetNumber},+${postalCode}+${city}`;
			const markerColor = primary ? "f8c945" : "002140";

			return `https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=15&markers=size:tiny%7Ccolor:0x${markerColor}%7C${address}&size=160x160&maptype=roadmap&key=${mapsKey}`; //eslint-disable-line max-len
		},
	},
	me: {
		membership: {
			getAll: `${baseUrl}${endpoints.api}/me/memberships`,
		},
		accounts: {
			create: `${baseUrl}${endpoints.api}/me/accounts`,
		},
		notification: {
			settings: {
				get: `${baseUrl}${endpoints.api}/me/notifications/settings`,
				update: `${baseUrl}${endpoints.api}/me/notifications/settings`,
			},
		},
	},
	members: {
		getAll: `${baseUrl}${endpoints.api}/members`,
		getById: `${baseUrl}${endpoints.api}/members/:memberId`,
		create: `${baseUrl}${endpoints.api}/members`,
		update: `${baseUrl}${endpoints.api}/members/:memberId`,
		delete: `${baseUrl}${endpoints.api}/members/:memberId`,
		resendInvite: `${baseUrl}${endpoints.api}/members/resend-invite`,
	},
	signature: {
		getTemplates: `${baseUrl}${endpoints.api}/signatures/templates`,
	},
	support: {
		supportMail: `${baseUrl}${endpoints.api}/support/mail`,
	},
	team: {
		create: `${baseUrl}${endpoints.api}/team/create`,
		acceptTermsAndConditions: `${baseUrl}${endpoints.api}/team/:uuid/terms-and-conditions/accept`,
		getByUuid: `${baseUrl}${endpoints.api}/team/uuid/:uuid`,
		delete: `${baseUrl}${endpoints.api}/team/:uuid`,
		getBySlug: `${baseUrl}${endpoints.api}/team/:slug`,
		getInbox: `${baseUrl}${endpoints.api}/team/:uuid/inbox`,
		getInboxDetails: `${baseUrl}${endpoints.api}/team/:uuid/inbox/:type`,
		getConversationDetail: `${baseUrl}${endpoints.api}/team/:uuid/conversation/:conversationId`,
		getUsersMembership: `${baseUrl}${endpoints.api}/team/:id/membership`,
	},
	user: {
		user: `${baseUrl}${endpoints.api}/user`,
		update: `${baseUrl}${endpoints.api}/user`,
		updatePassword: `${baseUrl}${endpoints.api}/user/password`,

		getIntegrations: `${baseUrl}${endpoints.api}/user/integrations`,

		validatePassword: `${baseUrl}${endpoints.api}/user/password/validate`,
		verifyEmail: `${baseUrl}${endpoints.api}/user/verify/email/:email`,
		resetPassword: `${baseUrl}${endpoints.api}/user/password/reset`,
		signUp: `${baseUrl}${endpoints.api}/user`,
	},
} as const;
