import { Select, SelectProps, Spin } from "antd";
import { useState } from "react";
import { useIntl } from "react-intl";

import { timingConfig } from "@/config";
import { debounce } from "@/helpers";

import { trpc } from "../../../../../../../../trpc";
import { stringHelpers } from "@bothive/helpers";

interface IContactSelect extends SelectProps {
	debounceTimeout?: number;
	onChange: (value: string[]) => void;
}
function ContactSelect({ onChange, debounceTimeout = timingConfig.debounce, ...props }: IContactSelect) {
	const intl = useIntl();
	const [search, setSearch] = useState<string>("");

	const { isLoading, data: contacts } = trpc.contact.getAllContacts.useQuery({
		sort: { field: "firstName", order: "asc" },
		limit: 30,
		q: search,
	});

	const handleChange = (value) => {
		onChange(value.map(({ key }) => key));
	};

	const handleSearch = (value: string) => {
		debounce(() => setSearch(value), debounceTimeout);
	};

	return (
		<Select
			{...props}
			labelInValue
			showArrow
			mode="multiple"
			maxTagCount={1}
			filterOption={false}
			onChange={handleChange}
			onSearch={handleSearch}
			notFoundContent={isLoading && <Spin size="small" />}
			placeholder={intl.formatMessage({ id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.CONTACT.PLACEHOLDER" })}
			options={contacts?.data?.map((contact) => {
				const emails = new Intl.ListFormat(intl.locale, { style: "short", type: "conjunction" }).format(
					contact.email.map((email) => email.value)
				);
				const name =
					contact.firstName && contact.lastName
						? `${contact.firstName} ${contact.lastName}`
						: contact.fullName;

				return {
					label: (
						<p style={{ display: "flex", alignItems: "center", gap: ".5em" }}>
							{name}
							<span
								style={{
									fontSize: ".9em",
									maxWidth: "30ch",
									overflowX: "hidden",
									textOverflow: "ellipsis",
									display: "inline-block",
								}}
							>
								(
								{!stringHelpers.isEmptyString(emails)
									? emails
									: intl.formatMessage({
											id: "FLOWS.SEND.CONTACT_SELECTION.NO_EMAIL",
											defaultMessage: "Email missing",
									  })}
								)
							</span>
						</p>
					),
					value: contact._id,
				};
			})}
			className="filter_value"
		/>
	);
}

export default ContactSelect;
