import { FormattedMessage, useIntl } from "react-intl";
import { Form, Switch, FormInstance } from "antd";
import FieldSideLabel from "@/shared/components/field/side_label";

interface IMemberPermissions {
	form: FormInstance;
}
export default function MemberPermissions({ form }: IMemberPermissions) {
	const intl = useIntl();
	const disabledSwitches = Form.useWatch<boolean>("superAdmin", form);

	if (Form.useWatch<boolean>("superAdmin", form) === true) {
		form.setFieldsValue({
			editFlows: true,
			editSettings: true,
			editIntegrations: true,
			manageUsers: true,
			readAllContactConversations: true,
		});
	}

	return (
		<>
			<h4 style={{ padding: "2% 0" }}>
				<FormattedMessage id="settings.users_popup.permissions" defaultMessage="User permissions" />
			</h4>
			<FieldSideLabel
				id="editFlows"
				label={intl.formatMessage({
					id: "settings.users_popup.edit_flows.title",
					defaultMessage: "Edit flows",
				})}
				help={intl.formatMessage({
					id: "settings.users_popup.edit_flows.help",
					defaultMessage: "Create, edit and remove flows",
				})}
			>
				<Form.Item name="editFlows" valuePropName="checked">
					<Switch disabled={disabledSwitches} />
				</Form.Item>
			</FieldSideLabel>
			<FieldSideLabel
				id="editSettings"
				label={intl.formatMessage({
					id: "settings.users_popup.edit_settings.title",
					defaultMessage: "Edit team settings",
				})}
				help={intl.formatMessage({
					id: "settings.users_popup.edit_settings.help",
					defaultMessage: "Allows user to make changes to the setting page.",
				})}
			>
				<Form.Item name="editSettings" valuePropName="checked">
					<Switch disabled={disabledSwitches} />
				</Form.Item>
			</FieldSideLabel>
			<FieldSideLabel
				id="editIntegrations"
				label={intl.formatMessage({
					id: "settings.users_popup.edit_integrations.title",
					defaultMessage: "Add or edit integrations",
				})}
				help={intl.formatMessage({
					id: "settings.users_popup.edit_integrations.help",
					defaultMessage: "Create, edit and remove triggers triggers.",
				})}
			>
				<Form.Item name="editIntegrations" valuePropName="checked">
					<Switch disabled={disabledSwitches} />
				</Form.Item>
			</FieldSideLabel>
			<FieldSideLabel
				id="manageUsers"
				label={intl.formatMessage({
					id: "settings.users_popup.manage_users.title",
					defaultMessage: "Manage users",
				})}
				help={intl.formatMessage({
					id: "settings.users_popup.manage_users.help",
					defaultMessage: "Allows user to add, edit or remove members from team.",
				})}
			>
				<Form.Item name="manageUsers" valuePropName="checked">
					<Switch disabled={disabledSwitches} />
				</Form.Item>
			</FieldSideLabel>
			<FieldSideLabel
				id="manageUsers"
				label={intl.formatMessage({
					id: "settings.users_popup.read_all_contact_conversations.title",
					defaultMessage: "Read all contact conversations",
					description:
						"Label of an input switch that allows member to read all the conversations of any member with a specific contact",
				})}
				help={intl.formatMessage({
					id: "settings.users_popup.read_all_contact_conversations.help",
					defaultMessage:
						"Allows user to read all the conversations that any member of the organization has had with a specific contact.",
					description:
						"Help text for a label of an input switch that allows member to read all the conversations of any member with a specific contact",
				})}
			>
				<Form.Item name="readAllContactConversations" valuePropName="checked">
					<Switch disabled={disabledSwitches} />
				</Form.Item>
			</FieldSideLabel>
			<FieldSideLabel
				id="superAdmin"
				label={intl.formatMessage({
					id: "settings.users_popup.super_admin.title",
					defaultMessage: "Super admin",
				})}
				help={intl.formatMessage({
					id: "settings.users_popup.super_admin.help",
					defaultMessage: "Super admin has all the rights. Super admin can't be removed.",
				})}
				className="permissions-super-admin"
			>
				<Form.Item name="superAdmin" valuePropName="checked">
					<Switch />
				</Form.Item>
			</FieldSideLabel>
		</>
	);
}
