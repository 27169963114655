import "./style.scss";

import { Menu, Tooltip } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import { AsideCollapsedState } from "@/flows";
import Icon from "@/shared/components/icon";
import { useWindowWidth } from "@/shared/hooks";

import helpers from "./helpers";

export default function Aside({ items }) {
	const location = useLocation();

	const [asideCollapsedState, setAsideCollapsedState] = useRecoilState(AsideCollapsedState);

	const width = useWindowWidth();

	useEffect(() => {
		if (width < 1440 && !asideCollapsedState) {
			return setAsideCollapsedState(true);
		}

		if (width >= 1440 && asideCollapsedState) {
			return setAsideCollapsedState(false);
		}
	}, [width]);

	const selectedKey = helpers.findActiveAsideItem({
		items,
		path: location.pathname,
	});

	return (
		<Menu
			selectedKeys={[selectedKey?.id]} // find active tab by using current route
			mode="inline"
			className={`aside ${asideCollapsedState ? "aside-collapsed" : ""}`}
			inlineIndent="17"
		>
			{items?.map((item) => (
				<Menu.Item key={item.id} className="aside-button">
					<Tooltip
						placement="right"
						title={asideCollapsedState ? <FormattedMessage id={item.label} /> : null}
					>
						<NavLink to={item.to} exact={true} className="route">
							{typeof item.icon === "string" ? <Icon type={item.icon} className="small" /> : item.icon}
							{!asideCollapsedState && (
								<p className="t-gap--left-sm">
									<FormattedMessage id={item.label} />
								</p>
							)}
						</NavLink>
					</Tooltip>
				</Menu.Item>
			))}
		</Menu>
	);
}
