import ShowFailedBanner from "@/shared/components/banner/failed";
import { Button, Modal } from "antd";
import { Dispatch, SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import ShowSuccessBanner from "@/shared/components/banner/success";
import { trpc } from "@/trpc";
import { generatorHelpers } from "../../../../helpers";
import InboxLabelForm from "./inboxLabelForm";

interface CreateModalArgs {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function CreateModal({ isOpen, setIsOpen }: CreateModalArgs) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();

	function cleanUpSuccessfulMutation() {
		trpcUtils.inbox.inboxLabel.getAll.invalidate();
		setIsOpen(false);
	}

	const createInboxLabelMutation = trpc.inbox.inboxLabel.create.useMutation({
		onSuccess: () => {
			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "inbox.label.modal.create.result.success",
					defaultMessage: "Inbox label created successfully.",
				}),
			});

			cleanUpSuccessfulMutation();
		},
		onError: () => {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "inbox.label.modal.create.result.error",
					defaultMessage: "Failed to create inbox label.",
				}),
			});
		},
	});

	return (
		<Modal
			width={700}
			title={intl.formatMessage({
				id: "inbox.label.modal.title",
				defaultMessage: "Add inbox label",
			})}
			open={isOpen || false}
			destroyOnClose
			onCancel={() => {
				setIsOpen(false);
			}}
			footer={[
				<Button
					key="submit"
					type="primary"
					form="createInboxLabel"
					htmlType="submit"
					loading={createInboxLabelMutation.isLoading}
				>
					<FormattedMessage id="inbox.label.modal.create.actions.add.button.title" defaultMessage="Add" />
				</Button>,
			]}
		>
			<InboxLabelForm
				onFinish={(values) => createInboxLabelMutation.mutate(values)}
				initialValues={{
					color: `#${generatorHelpers.randomColor()}`,
				}}
			/>
		</Modal>
	);
}
