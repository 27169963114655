const replyActions = ["forward", "reply", "reply_all"] as const;
export type ReplyAction = (typeof replyActions)[number];

const config: { id: ReplyAction; label: string }[] = [
	{
		id: "reply",
		label: "INBOX.CONVERSATION.REPLY_FOOTER.REPLY",
	},
	{
		id: "reply_all",
		label: "INBOX.CONVERSATION.REPLY_FOOTER.REPLY_ALL",
	},
	{
		id: "forward",
		label: "INBOX.CONVERSATION.REPLY_FOOTER.FORWARD",
	},
];

export default config;
