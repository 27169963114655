import React, { useCallback } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";

import { typesConfig } from "../../../config";

export default function AListItemMeta({ label, value, type, className }) {
	const createBody = useCallback(() => {
		const actions = {
			[typesConfig.cellTypes.date]: value ? <FormattedDate value={value} /> : "-",
			[typesConfig.cellTypes.number]: value ? <FormattedNumber value={value} /> : "-",
			[typesConfig.cellTypes.string]: value || "-",
		};

		return actions[type];
	}, [type, value]);

	return (
		<div className={`a-list-item--meta ${className}`}>
			<p className="semi-bold">
				<FormattedMessage id={label} />
			</p>
			<p>{createBody()}</p>
		</div>
	);
}

AListItemMeta.defaultProps = {
	value: "",
	type: "string",
	className: "",
};
