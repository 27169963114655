import axios from "axios";

import { apiConfig } from "../config";
import type { IFlowPopulated } from "@bothive_core/database";

export const getFlowById = async ({ id }) => {
	const { data } = await axios.get<{ data: IFlowPopulated }>(apiConfig.flow.getById.replace(":id", id));

	return data;
};
