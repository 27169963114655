import { routesConfig } from "@/config";
import { Avatar, Result, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { useDebounce } from "use-debounce";

import { websiteIcon } from "../../../assets/images/icon";
import { Image } from "../../../components/atoms";
import ErrorState from "../../../flows/common/components/error";
import FirstVisit from "@/modules/contacts/overview/components/firstVisit";
import Tag from "@/shared/components/antdWrappers/Tag";
import SkeletonTable from "../../../shared/components/table/skeleton";
import { actions as dashboardActions } from "../../../store/dashboard";
import { trpc } from "../../../trpc";
import BulkActions from "./BulkActions/BulkActions";
import CreateModal from "./components/CreateModal";
import ContactOverviewHeader from "./components/Header";
import ContactImport from "../import";
import { selectedAllContactsState, selectedContactsState } from "./state";
import AvatarGroup from "@/shared/components/antdWrappers/AvatarGroup";

export default function ContactsOverview() {
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [search, setSearch] = useState<string | undefined>(undefined);
	const [labelId, setLabelId] = useState<string | undefined>(undefined);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
	const [contactType, setContactType] = useState<string | undefined>(undefined);
	const [selectedAll, setSelectedAll] = useRecoilState(selectedAllContactsState);
	const [selectedContacts, setSelectedContacts] = useRecoilState(selectedContactsState);
	const [accountManagerId, setAccountManagerId] = useState<string | undefined>(undefined);
	const [sort, setSort] = useState<{ field: string; order: string } | undefined>(undefined);
	const [showImportModal, setShowImportModal] = useState<{ show: boolean; integrationId?: string }>({
		show: false,
	});

	const team = useSelector(({ content }: { content: { team: any } }) => content.team.team);
	const { data: accountManagers } = trpc.member.getAll.useQuery({ limit: 0 });

	const [debouncedSearch] = useDebounce(search, 500);
	const { isLoading, isError, data } = trpc.contact.getAllContacts.useQuery({
		sort,
		labelId,
		contactType,
		q: debouncedSearch,
		limit: pagination.pageSize,
		offset: (pagination.current - 1) * pagination.pageSize,
		accountManagerId,
		archived: false,
	});

	useEffect(() => {
		setSelectedContacts([]);
		setSelectedAll(false);
		setPagination({ ...pagination, current: 1 });
	}, [debouncedSearch, contactType, labelId, accountManagerId]);

	useEffect(() => {
		const selectedContactsFilteredOnNewData = data?.data?.filter((contact) =>
			selectedContacts.includes(contact._id)
		);
		if (
			selectedContactsFilteredOnNewData &&
			selectedContactsFilteredOnNewData?.length !== selectedContacts.length
		) {
			setSelectedContacts(selectedContactsFilteredOnNewData.map(({ _id }) => _id));
		}

		// If on last page shift to new last page
		if (
			pagination.current - 1 > 0 &&
			data?.data?.length === 0 &&
			Math.ceil((data?.total || 0) / pagination.pageSize) === pagination.current - 1
		) {
			setPagination({
				current: pagination.current - 1,
				pageSize: pagination.pageSize,
			});
		}
	}, [data?.data]);

	const { data: contactLabels } = trpc.contactLabel.all.useQuery({ limit: 0 });

	useEffect(() => {
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					id: "contactsOverview",
					title: "CONTACT.OVERVIEW.PAGE.TITLE",
					tabTitle: "CONTACT.OVERVIEW.PAGE.TITLE",
				},
				intl,
			})
		);
	}, []);

	const handleTableChange = ({ sorter, pagination }) => {
		if (sorter?.columnKey && sorter?.order) {
			setSort({ field: sorter.columnKey, order: sorter.order });
		}
		setPagination({ current: pagination.current, pageSize: pagination.pageSize });
	};

	const handleSearch = (value: string) => {
		setSearch(value === "" ? undefined : value);
	};

	const handleContactSelect = ({ _id }: { _id: string }) => {
		if (!_id) return;

		setSelectedContacts((prevValues) => {
			if (prevValues.includes(_id)) {
				return prevValues.filter((value) => value !== _id);
			}

			return [...prevValues, _id];
		});
	};

	const handleContactSelectAll = ({ selected, contacts }: { selected: boolean; contacts: { _id: string }[] }) => {
		// remove all shown contacts from selectedContacts but keep the ones from other pages
		if (!selected) {
			setSelectedContacts((prevValues) => {
				return [
					...new Set(
						prevValues.filter(
							(value) => !data?.data?.map((contact: { _id?: string }) => contact?._id).includes(value)
						)
					),
				];
			});

			return;
		}

		// add all shown contacts to selectedContacts
		setSelectedContacts((prevValues) => [
			...new Set([
				...prevValues,
				...contacts.filter((item) => item?._id).map((contact: { _id: string }) => contact?._id),
			]),
		]);
	};

	useEffect(() => {
		if (selectedAll && selectedContacts.length === 1) {
			setSelectedAll(false);
			const pageSelection = data?.data.map((item) => item._id);
			const deselectItemIndex = pageSelection?.indexOf(selectedContacts[selectedContacts.length - 1]);

			if (typeof deselectItemIndex !== "undefined" && deselectItemIndex > -1) {
				pageSelection?.splice(deselectItemIndex, 1);
			}

			setSelectedContacts(pageSelection || []);
		}
	}, [selectedContacts, data?.total]);

	const handleShowImportModal = ({ show, integrationId }: { show: boolean; integrationId: string }) => {
		setShowImportModal({ show, integrationId });
	};

	if (!sort && !search && !labelId && !accountManagerId && !contactType && data?.data?.length === 0) {
		return (
			<main className="t-section" style={{ display: "flex", justifyContent: "center" }}>
				<FirstVisit setShowImportModal={handleShowImportModal} setShowCreateModal={setShowCreateModal} />
				<ContactImport
					open={showImportModal.show}
					integrationId={showImportModal?.integrationId}
					onClose={() => setShowImportModal({ show: false })}
				/>
				<CreateModal open={showCreateModal} setShowModal={setShowCreateModal} isCreatingLinkedContact={false} />
			</main>
		);
	}

	return (
		<>
			<div className="t-section t-section--detail-view t-section--contact-overview">
				<ContactOverviewHeader
					contactAmount={data?.total || 0}
					handleSearch={handleSearch}
					setContactType={(contactType) => setContactType(contactType)}
					setLabelId={(labelId) => setLabelId(labelId)}
					labelId={labelId || ""}
					setAccountManagerId={(accountManager) => setAccountManagerId(accountManager)}
					setCreateContactModal={setShowCreateModal}
				/>
				{(!!selectedContacts.length || selectedAll) && (
					<BulkActions
						contactQueryData={data}
						filters={{
							q: debouncedSearch,
							contactType,
							labelId,
							accountManagerId,
						}}
					/>
				)}
				{isError && <ErrorState title={intl.formatMessage({ id: "CONTACTS.OVERVIEW.ERROR.TITLE" })} />}
				{!isError && (
					<SkeletonTable
						rowKey="_id"
						className="t-gap--top fro-table"
						rowClassName="t-section--contact-overview--table-row"
						dataSource={data?.data}
						isLoading={isLoading}
						locale={{
							emptyText: <Result title={intl.formatMessage({ id: "CONTACTS.OVERVIEW.EMPTY.TITLE" })} />,
						}}
						onChange={(pagination: any, _: any, sorter: any) => handleTableChange({ pagination, sorter })}
						onRow={(contact: { _id: string }) => ({
							onClick: (event: any) => {
								// If click happens in a column with these classes it should be ignored to prevent accidental clicks
								try {
									if (
										typeof event.target === "string" &&
										(event.target?.className?.includes("ant-table-selection-column") ||
											event.target?.className?.includes("ant-checkbox-wrapper"))
									) {
										return;
									}

									if (!contact._id) return;
								} catch (_) {
									// event.target.className.includes can sometimes not be a function
								}

								history.push(
									routesConfig.dashboard.contact.detail.root
										.replace(":team", team?.slug)
										.replace(":contactId", contact._id)
								);
							},
						})}
						rowSelection={
							!!data?.data?.length && {
								hideSelectAll: selectedAll,
								onSelect: (contact: { _id: string }) => handleContactSelect(contact),
								onSelectAll: (selected: boolean, contacts: { _id: string }[]) =>
									handleContactSelectAll({ selected, contacts }),
								selectedRowKeys: selectedAll ? data?.data.map((item) => item._id) : selectedContacts,
							}
						}
						pagination={{
							current: pagination.current,
							pageSize: pagination.pageSize,
							total: data?.total,
							showSizeChanger: true,
							defaultPageSize: 20,
							pageSizeOptions: ["20", "50", "100", "200"],
						}}
					>
						<Table.Column
							key="firstName"
							title={<FormattedMessage id="FLOWS.REPORT.OVERVIEW.TABLE.FIRSTNAME" />}
							width="128px"
							ellipsis={{ showTitle: false }}
							render={({ firstName, fullName, lastName, avatar }) => {
								if (!firstName && !lastName && !fullName) {
									return <FormattedMessage id="FALLBACK.CONTACT.NAME.SHORT" />;
								}

								const name = firstName || fullName.split(" ")[0];

								return (
									<Tooltip placement="top" title={name}>
										<div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
											<Image.ADefault src={avatar || websiteIcon} className="small" />
											<p
												style={{
													overflow: "hidden",
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
												}}
											>
												{name}
											</p>
										</div>
									</Tooltip>
								);
							}}
							sorter={true}
						/>
						<Table.Column
							key="lastName"
							title={<FormattedMessage id="FLOWS.REPORT.OVERVIEW.TABLE.LASTNAME" />}
							width="128px"
							ellipsis={{ showTitle: false }}
							render={({ lastName, fullName }) => {
								if (!lastName && !fullName) return "";

								const name = lastName || fullName.split(" ").slice(1).join(" ");

								return (
									<Tooltip placement="top" title={name}>
										{name}
									</Tooltip>
								);
							}}
							sorter={true}
						/>
						<Table.Column
							key="type"
							title={<FormattedMessage id="CONTACTS.OVERVIEW.TABLE.TYPE" />}
							width="128px"
							dataIndex="type"
							sorter={true}
							render={(type) => {
								const generateTypeDescription = (type?: string) => {
									switch (type) {
										case "customer":
											return (
												<FormattedMessage
													id="contact.overview.select.type.customer"
													defaultMessage="customer"
												/>
											);
										case "prospect":
											return (
												<FormattedMessage
													id="contact.overview.select.type.prospect"
													defaultMessage="prospect"
												/>
											);
										case "other":
											return (
												<FormattedMessage
													id="contact.overview.select.type.other"
													defaultMessage="other"
												/>
											);
										case "linkedContact":
											return (
												<FormattedMessage
													id="contact.overview.select.type.linked_contact"
													defaultMessage="linked contacts"
												/>
											);
										case "anonymous":
											return (
												<FormattedMessage
													id="contact.overview.select.type.anonymous"
													defaultMessage="Anonymous"
												/>
											);
										default:
											return undefined;
									}
								};

								return <p>{generateTypeDescription(type)}</p>;
							}}
						/>
						<Table.Column
							key="labels"
							title={<FormattedMessage id="CONTACTS.OVERVIEW.TABLE.LABELS" />}
							width="128px"
							dataIndex="labels"
							render={(labels) => {
								const assignedLabels = contactLabels?.data?.filter((label) =>
									labels?.includes(label._id)
								);
								const MAX_LABELS = 2;

								const renderLabels = assignedLabels?.slice(0, MAX_LABELS);
								const extraLabels = assignedLabels?.slice(MAX_LABELS).map((label) => label.name);

								return (
									<div
										style={{
											display: "flex",
											gap: "0.5em",
											flexWrap: "wrap",
										}}
									>
										{renderLabels?.map((label) => (
											<Tooltip title={label.name}>
												<Tag
													key={label._id}
													color={label.color}
													style={{
														marginRight: 0,
														maxWidth: "100px",
														overflow: "hidden",
														textOverflow: "ellipsis",
														whiteSpace: "nowrap",
													}}
												>
													{label.name}
												</Tag>
											</Tooltip>
										))}
										{!!extraLabels?.length && (
											<Tooltip title={extraLabels?.length ? extraLabels?.join(", ") : ""}>
												<Tag
													intent="count"
													style={{
														marginRight: 0,
													}}
												>
													{extraLabels?.length}
												</Tag>
											</Tooltip>
										)}
									</div>
								);
							}}
						/>
						<Table.Column
							key="accountManagers"
							title={<FormattedMessage id="CONTACTS.OVERVIEW.TABLE.ACCOUNT_MANAGERS" />}
							width="128px"
							dataIndex="accountManagers"
							render={(accountManagerIds) => {
								const assignedAccountManagers = accountManagers?.data.filter((accountManager) =>
									accountManagerIds?.includes(accountManager.userId)
								);

								return (
									<div style={{ display: "flex", gap: "0.5em", flexWrap: "wrap" }}>
										{!!assignedAccountManagers?.length && (
											<AvatarGroup maxCount={3}>
												{assignedAccountManagers?.map((manager) => (
													<Tooltip title={manager?.name || manager?.name} placement="top">
														<Avatar src={manager.avatar} size="default" shape="square" />
													</Tooltip>
												))}
											</AvatarGroup>
										)}
									</div>
								);
							}}
						/>
						<Table.Column
							key="createdAt"
							title={<FormattedMessage id="CONTACTS.OVERVIEW.TABLE.CREATED_AT" />}
							width="128px"
							dataIndex="createdAt"
							sorter={true}
							render={(createdAt) => <FormattedDate value={createdAt} />}
						/>
						<Table.Column
							key="updatedAt"
							title={<FormattedMessage id="CONTACTS.OVERVIEW.TABLE.UPDATED_AT" />}
							width="128px"
							dataIndex="updatedAt"
							sorter={true}
							render={(updatedAt) => <FormattedDate value={updatedAt} />}
						/>
					</SkeletonTable>
				)}
			</div>
			<CreateModal open={showCreateModal} setShowModal={setShowCreateModal} isCreatingLinkedContact={false} />
		</>
	);
}
