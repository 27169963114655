import actionTypes from "./form.actionTypes";

const initialState = {
	form: {},
	isSaving: false,
	savingFailed: false,
	savingSucceeded: false,

	isDeleting: false,
	deletingFailed: false,
	deletingSucceeded: false,
};

export default function (state = initialState, action) {
	const updateState = {
		[actionTypes.IS_SAVING]: () => ({
			...state,
			isSaving: true,
			savingFailed: false,
			savingSucceeded: false,
		}),
		[actionTypes.SAVING_FAILED]: () => ({
			...state,
			isSaving: false,
			savingFailed: true,
			savingSucceeded: false,
		}),
		[actionTypes.SAVING_SUCCEEDED]: () => ({
			...state,
			isSaving: false,
			savingFailed: false,
			savingSucceeded: true,
		}),
		[actionTypes.IS_DELETING]: () => ({
			...state,
			isDeleting: true,
			deletingFailed: false,
			deletingSucceeded: false,
		}),
		[actionTypes.DELETING_FAILED]: () => ({
			...state,
			isDeleting: false,
			deletingFailed: true,
			deletingSucceeded: false,
		}),
		[actionTypes.DELETING_SUCCEEDED]: () => ({
			...state,
			isDeleting: false,
			deletingFailed: false,
			deletingSucceeded: true,
		}),
		[actionTypes.INIT_FORM]: () => ({
			...state,
			isSaving: false,
			savingFailed: false,
			savingSucceeded: false,
			isDeleting: false,
			deletingFailed: false,
			deletingSucceeded: false,
		}),
		[actionTypes.UPDATE_FORM]: () => ({
			...state,
			form: {
				...state.form,
				...action.data,
			},
		}),
		[actionTypes.RECEIVED_COUPON_DATA]: () => ({
			...state,
			form: {
				...state.form,
				couponDetails: action.data,
			},
		}),
	};

	return updateState[action.type] ? updateState[action.type]() : state;
}
