import React from "react";

import { keyEvents } from "../../../helpers";

export default function ADefault({ id, label, checked, disabled, onClick, onChange, className, ...props }) {
	const cName = `a-checkbox ${checked ? "active" : ""} ${className}`;

	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			onChange(event);
		}
	};
	const handleOnChange = () => {
		if (onChange) {
			onChange(!checked);
		}
	};

	return (
		<label
			role="checkbox" //eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
			tabIndex="0"
			aria-labelledby={label}
			aria-checked={checked}
			onKeyUp={handleKeyUp}
			onClick={onClick}
			className={cName}
			htmlFor={id}
		>
			<input
				id={id}
				type="checkBox"
				{...props}
				onChange={handleOnChange}
				checked={checked}
				aria-disabled={disabled}
			/>
			<div className="a-checkbox--label" aria-checked={checked}></div>
		</label>
	);
}

ADefault.defaultProps = {
	checked: false,
	disabled: false,
	label: "",
	className: "",
	onChange: () => undefined,
	onClick: () => undefined,
};
