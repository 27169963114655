import { Alert, Form, Input, Modal, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useRecoilValue } from "recoil";

import FieldSideLabel from "@/shared/components/field/side_label";

import { statusTypesList } from "../../state";
import { getBillingWarning, getIsDefaultError } from "./helper";

export default function StatusTypesModalTemplate({ title, isVisible, initialValues, onCancel, footer, handleSubmit }) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [doneWarningMessage, setDoneWarningMessage] = useState(undefined);
	const statusTypes = useRecoilValue(statusTypesList);
	const maxStatusTypes = useSelector((state) => state.dashboard.permissions.statusTypes);
	const limitWarning = getBillingWarning({ total: statusTypes?.length, limit: maxStatusTypes });

	const doneStatusType = statusTypes?.find(({ isDone }) => isDone);

	const handleValueChange = (_, formValues) => {
		const warningMessage = getIsDefaultError({ formValues, initialValues });

		if (doneWarningMessage !== warningMessage) {
			setDoneWarningMessage(doneWarningMessage);
		}
	};

	useEffect(() => {
		// Reset form each time new status is selected
		form.setFieldsValue(initialValues);

		if (!isVisible) {
			form.resetFields();
		}

		return () => {
			form.resetFields();
		};
	}, [initialValues, isVisible]);

	return (
		<Modal
			title={<FormattedMessage id={title} />}
			destroyOnClose
			open={isVisible}
			transitionName="" // to disable animation
			onCancel={onCancel}
			footer={footer}
		>
			{limitWarning && (
				<Alert
					showIcon
					type="warning"
					className="t-gap--bottom-sm"
					message={intl.formatMessage(
						{ id: limitWarning },
						{
							currentStatusTypes: statusTypes?.length,
							maxStatusTypes,
						}
					)}
				/>
			)}
			<Form
				form={form}
				layout="vertical"
				id="StatusTypeForm"
				requiredMark="optional"
				onFinish={(values) => handleSubmit(values)}
				onValuesChange={handleValueChange}
			>
				<Form.Item
					name="name"
					label={intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.NAME" })}
					rules={[
						{
							required: true,
							message: intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.NAME.REQUIRED" }),
						},
					]}
				>
					{/* eslint-disable-next-line jsx-a11y/no-autofocus */}
					<Input autoFocus />
				</Form.Item>
				<Form.Item
					name="description"
					label={intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.DESCRIPTION" })}
				>
					<Input.TextArea
						placeholder={intl.formatMessage({
							id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.DESCRIPTION.PLACEHOLDER",
						})}
					/>
				</Form.Item>
				<FieldSideLabel
					label={intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.DEFAULT" })}
					help={intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.DEFAULT.DESCRIPTION" })}
				>
					<Form.Item name="isDefault" valuePropName="checked">
						<Switch />
					</Form.Item>
				</FieldSideLabel>
				{doneWarningMessage && (
					<Alert
						showIcon
						type="warning"
						className="t-gap--top-sm"
						message={intl.formatMessage(
							{ id: doneWarningMessage },
							{
								default: doneStatusType.name,
								bold: (content) => <Typography.Text strong>{content}</Typography.Text>,
							}
						)}
					/>
				)}
				<FieldSideLabel
					label={intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.DONE" })}
					help={intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.DONE.DESCRIPTION" })}
					className="t-gap--top-sm"
				>
					<Form.Item name="isDone" valuePropName="checked">
						<Switch />
					</Form.Item>
				</FieldSideLabel>
			</Form>
		</Modal>
	);
}
