import axios from "axios";

import { apiConfig } from "@/config";
import { queryStringHelpers } from "@/helpers";
import config from "./config";

export default {
	fetchApiKeys: async () => {
		const query = queryStringHelpers.createQueryString({
			permissions: [config.permissions.widget],
		});
		const result = await axios.get(`${apiConfig.apiKey.getKeys}${query}`);

		if (result.status !== 200 || !result.data.success) {
			throw new Error();
		}

		return result.data;
	},
};
