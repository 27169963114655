import "./style.scss";

import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Panel } from "../../../../../../shared/components/collapse";
import { Card, Modal } from "./components";
import { ContactRouterOutput } from "@/trpc";

export type IProperty = Required<ContactRouterOutput["getContactById"]>["properties"][0];

interface IPropertiesParams {
	onChange: (value: Partial<ContactRouterOutput["getContactById"]>) => Promise<Boolean>;
	contact: ContactRouterOutput["getContactById"];
	panelProps?: any;
}

function Properties({ contact, onChange, ...panelProps }: IPropertiesParams) {
	const intl = useIntl();
	const selectedPropertyIndex = useRef<string | undefined>(undefined);
	const [showModal, setShowModal] = useState<boolean>(false);

	const handleCardClick = (id: string) => {
		selectedPropertyIndex.current = id;
		setShowModal(true);
	};

	const handleClose = () => {
		selectedPropertyIndex.current = undefined;
		setShowModal(false);
	};

	const handleCreate = (payload: IProperty) => {
		const properties = contact.properties || [];
		properties.push(payload);
		onChange({ properties }).then(() => handleClose());
	};

	const handleUpdate = ({ id, payload }: { id: string; payload: IProperty }) => {
		const properties = contact.properties || [];
		properties[id] = payload;
		onChange({ properties }).then(() => handleClose());
	};

	const handleDelete = (id: string) => {
		const properties = contact.properties?.filter((_, index) => `${index}` !== id);
		onChange({ properties }).then(() => handleClose());
	};

	return (
		<>
			<Panel
				key="properties"
				title={intl.formatMessage({ id: "CONTACTS.DETAIL.ATTRIBUTES.PROPERTIES.TITLE" })}
				{...panelProps}
			>
				<div className="foreign_properties-content">
					{contact.properties?.map((property, index) => {
						if (property.isDeleted) return;

						return <Card key={`bac_${index}`} id={`${index}`} value={property} onClick={handleCardClick} />;
					})}
					<Button
						style={{ width: "fit-content" }}
						type="text"
						onClick={() => setShowModal(true)}
						size="large"
						icon={<PlusOutlined />}
					>
						{" "}
						<FormattedMessage id="CONTACTS.DETAIL.ATTRIBUTES.PROPERTIES.ADD_BUTTON" />
					</Button>
				</div>
			</Panel>
			<Modal
				isOpen={showModal}
				contact={contact}
				id={selectedPropertyIndex.current}
				value={selectedPropertyIndex.current && contact.properties?.[selectedPropertyIndex.current]}
				onSubmit={handleCreate}
				onUpdate={handleUpdate}
				onDelete={handleDelete}
				onClose={handleClose}
			/>
		</>
	);
}

export default Properties;
