export default {
	sections: {
		settings: {
			id: "settings",
			title: "SETTINGS.NOTIFICATIONS.SETTINGS.CARD.TITLE",
			description: "SETTINGS.NOTIFICATIONS.SETTINGS.CARD.DESCRIPTION",
			tooltip: "SETTINGS.NOTIFICATIONS.SETTINGS.CARD.TOOLTIP",
			includeIntegrations: false,

			fieldConfig: {
				integration: {
					label: "SETTINGS.NOTIFICATIONS.SETTINGS.INTEGRATION.LABEL",
				},
			},
		},
		flow: {
			id: "flow",
			title: "SETTINGS.NOTIFICATIONS.FLOW.CARD.TITLE",
			description: "SETTINGS.NOTIFICATIONS.FLOW.CARD.DESCRIPTION",
			tooltip: "SETTINGS.NOTIFICATIONS.FLOW.CARD.TOOLTIP",
			includeIntegrations: true,

			fieldConfig: {
				assign: {
					label: "SETTINGS.NOTIFICATIONS.FLOW.FORM.ASSIGN.LABEL",
				},
				reportActions: {
					label: "SETTINGS.NOTIFICATIONS.FLOW.FORM.REPORT_ACTIONS.LABEL",
				},
			},
		},
		inbox: {
			id: "inbox",
			title: "SETTINGS.NOTIFICATIONS.INBOX.CARD.TITLE",
			description: "SETTINGS.NOTIFICATIONS.INBOX.CARD.DESCRIPTION",
			tooltip: "SETTINGS.NOTIFICATIONS.FLOW.CARD.TOOLTIP",
			includeIntegrations: false,

			fieldConfig: {
				assign: {
					label: "SETTINGS.NOTIFICATIONS.INBOX.FORM.ASSIGN.LABEL",
				},
				participant: {
					label: "SETTINGS.NOTIFICATIONS.INBOX.FORM.PARTICIPANT.LABEL",
				},
				mentioned: {
					label: "SETTINGS.NOTIFICATIONS.INBOX.FORM.MENTIONED.LABEL",
				},
				// newConversation: {
				// 	label: "SETTINGS.NOTIFICATIONS.INBOX.FORM.NEW_CONVERSATION.LABEL",
				// },
				// accountManagedConversation: {
				// 	label: "SETTINGS.NOTIFICATIONS.INBOX.FORM.ACCOUNT_MANAGED_CONVERSATION.LABEL",
				// },
			},
		},
		contacts: {
			id: "contacts",
			title: "settings.notifications.contacts.card.title",
			description: "settings.notifications.contacts.card.description",
			tooltip: "SETTINGS.NOTIFICATIONS.FLOW.CARD.TOOLTIP",
			includeIntegrations: false,

			fieldConfig: {
				synchronization: {
					label: "settings.notifications.contacts.form.synchronization.label",
				},
			},
		},
	},
	banner: {
		update: {
			success: "BANNER.SUCCEEDED.UPDATE_NOTIFICATION_SETTINGS",
			failed: "BANNER.FAILED.UPDATE_NOTIFICATION_SETTINGS",
		},
	},
};
