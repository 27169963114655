import DashboardCard from "@/shared/components/card/dashboard";
import FieldComponent from "./FieldComponent";
import { useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { IOpeningHours } from "@bothive_core/database";
import { trpc } from "@/trpc";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import "../style.scss";

interface IOfficeHours {
	allowEditing: boolean;
	openingHours: { default: IOpeningHours };
}

type DayType =
	| "weekdays"
	| "weekends"
	| "monday"
	| "tuesday"
	| "wednesday"
	| "thursday"
	| "friday"
	| "saturday"
	| "sunday";

interface OpeningHoursItem {
	dayType: DayType;
	time: moment.Moment[] | null;
	time2: moment.Moment[] | null;
}

export default function OfficeHours({ allowEditing, openingHours }: IOfficeHours) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const updateOpeningHoursMutation = trpc.organization.updateOpeningHours.useMutation();
	const initialOpeningHours = useMemo(() => {
		if (!openingHours?.default) return [];

		return Object.entries(openingHours.default).map((value) => {
			if (value[1] === null) {
				return {
					dayType: value[0] as DayType,
					time: null,
					time2: null,
				};
			}

			let time: moment.Moment[] = [];
			let time2: moment.Moment[] = [];

			if (value[1].length >= 2) {
				time.push(moment().startOf("day").add(value[1][0], "minutes"));
				time.push(moment().startOf("day").add(value[1][1], "minutes"));
			}

			if (value[1].length >= 4) {
				time2.push(moment().startOf("day").add(value[1][2], "minutes"));
				time2.push(moment().startOf("day").add(value[1][3], "minutes"));
			}

			return {
				dayType: value[0] as DayType,
				time: time,
				time2: time2,
			};
		});
	}, [openingHours]);

	useEffect(() => {
		form.setFieldValue("openingHours", initialOpeningHours);
	}, [initialOpeningHours]);

	const updateOpeningHours = (data) => {
		const openingHours = reformatOpeningHours(data.openingHours);
		updateOpeningHoursMutation.mutate(openingHours, {
			onSuccess: () => {
				ShowSuccessBanner({
					title: intl.formatMessage({
						id: "organization_settings.opening_hours.update_banner.success",
						defaultMessage: "The opening hours have been successfully saved.",
					}),
				});
			},
			onError: () => {
				ShowFailedBanner({
					title: intl.formatMessage({
						id: "organization_settings.opening_hours.update_banner.failed",
						defaultMessage: "The changes couldn't be saved. Please try again later",
					}),
				});
			},
		});
	};

	const options = [
		{
			value: "everyday",
			label: {
				id: "component.openings_hours.day_options.everyday",
				defaultMessage: "Everyday",
			},
		},
		{
			value: "weekdays",
			label: {
				id: "component.openings_hours.day_options.weekdays",
				defaultMessage: "Weekdays",
			},
		},
		{
			value: "weekends",
			label: {
				id: "component.openings_hours.day_options.weekends",
				defaultMessage: "Weekends",
			},
		},
		{
			value: "monday",
			label: {
				id: "component.openings_hours.day_options.monday",
				defaultMessage: "Monday",
			},
		},
		{
			value: "tuesday",
			label: {
				id: "component.openings_hours.day_options.tuesday",
				defaultMessage: "Tuesday",
			},
		},
		{
			value: "wednesday",
			label: {
				id: "component.openings_hours.day_options.wednesday",
				defaultMessage: "Wednesday",
			},
		},
		{
			value: "thursday",
			label: {
				id: "component.openings_hours.day_options.thursday",
				defaultMessage: "Thursday",
			},
		},
		{
			value: "friday",
			label: {
				id: "component.openings_hours.day_options.friday",
				defaultMessage: "Friday",
			},
		},
		{
			value: "saturday",
			label: {
				id: "component.openings_hours.day_options.saturday",
				defaultMessage: "Saturday",
			},
		},
		{
			value: "sunday",
			label: {
				id: "component.openings_hours.day_options.sunday",
				defaultMessage: "Sunday",
			},
		},
	];

	const openingHoursList = Form.useWatch("openingHours", form);
	const reformatOpeningHours = (data: OpeningHoursItem[]) => {
		const openingHours: { default: Partial<Record<DayType, number[]>> } = { default: {} };

		data.forEach((item) => {
			const dayType = item.dayType;
			const time = item.time;
			const time2 = item.time2;

			if (!openingHours.default[dayType]) {
				openingHours.default[dayType] = [];
			}
			if (time && time.length === 2) {
				openingHours?.default[dayType]?.push(time[0].diff(moment().startOf("day"), "minutes"));
				openingHours?.default[dayType]?.push(time[1].diff(moment().startOf("day"), "minutes"));
			}
			if (time2 && time2.length === 2) {
				openingHours?.default[dayType]?.push(time2[0].diff(moment().startOf("day"), "minutes"));
				openingHours?.default[dayType]?.push(time2[1].diff(moment().startOf("day"), "minutes"));
			}
		});

		return openingHours;
	};

	function generateForm() {
		if (!allowEditing && !openingHours?.default) {
			return (
				<p className="officehours-fallback">
					<FormattedMessage
						id="organization_settings.opening_hours.missing_permissions"
						defaultMessage="Your organization does not have any openinghours at this time. Please request someone with the necessary permissions to add them."
					/>
				</p>
			);
		}

		return (
			<Form
				form={form}
				id="officehours"
				name="officehours"
				layout="horizontal"
				autoComplete="off"
				disabled={!allowEditing}
				className="form t-gap--top"
				//Object met key socials
				onFinish={(form) => updateOpeningHours(form)}
				initialValues={{ openingHours: initialOpeningHours, dayType: "everyday" }}
			>
				<Form.List name="openingHours">
					{(fields, { add, remove }) => (
						<>
							{fields.map((field) => {
								return (
									<FieldComponent
										form={form}
										key={field.key}
										field={field}
										remove={remove}
										openingList={openingHoursList}
										options={options}
										allowEditing={allowEditing}
									/>
								);
							})}
							{fields.length < options.length && (
								<div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
									{allowEditing && (
										<Button
											type="text"
											onClick={add}
											icon={<PlusOutlined />}
											style={{ display: "flex", alignItems: "center" }}
										>
											<span style={{ textAlign: "left" }}>
												<FormattedMessage
													id="component.openings_hours.add_new_time"
													defaultMessage="Add new time"
												/>
											</span>
										</Button>
									)}
								</div>
							)}
						</>
					)}
				</Form.List>
				{allowEditing && (
					<Button
						key="submit"
						type="primary"
						htmlType="submit"
						style={{ display: "flex", width: "fit-content", alignSelf: "flex-end" }}
						className="t-gap--top saveButton"
						form="officehours"
						loading={updateOpeningHoursMutation.isLoading}
					>
						<FormattedMessage
							id="organization_settings.opening_hours.basic_form.submit"
							defaultMessage="Save"
						/>
					</Button>
				)}
			</Form>
		);
	}

	return (
		<DashboardCard
			className="t-gap--top"
			title={intl.formatMessage({
				id: "organization_settings.opening_hours.basic.title",
				defaultMessage: "Office hours",
			})}
			description={intl.formatMessage({
				id: "organization_settings.opening_hours.basic.description",
				defaultMessage: "What are your normal office hours during the week?",
			})}
		>
			{generateForm()}
		</DashboardCard>
	);
}
