import { TextNode, type Klass, type LexicalNode } from "lexical";

import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";

import { ImageNode } from "../image_node/imageNode";
import { KeywordNode } from "../keywordNode";
import { ExtendedTextNode } from "../extendedTextNode";

export const TableCellNodes: Array<Klass<LexicalNode>> = [
	ExtendedTextNode,
	TextNode,
	HeadingNode,
	ListNode,
	ListItemNode,
	QuoteNode,
	CodeNode,
	CodeHighlightNode,
	AutoLinkNode,
	LinkNode,
	KeywordNode,
	ImageNode,
];
