import "./style.scss";

import { CloseCircleOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import type { IAdminPulseConfig } from "@bothive_core/database";
import { Button, Modal, Skeleton, Table, Tooltip, notification } from "antd";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import DashboardCard from "../../../../../../shared/components/card/dashboard";
import SkeletonTable from "../../../../../../shared/components/table/skeleton";
import { trpc } from "../../../../../../trpc";
import { contactTypesI18n, crmTypesI18n } from "./config";
import AddFiltersModal from "./modals/addFilters";
import EditFiltersModal from "./modals/editFilters";
import EnableWebhookModal from "./modals/enableWebhook";
import Tag from "@/shared/components/antdWrappers/Tag";

export default function AdminPulseWebhook() {
	const intl = useIntl();
	const trpcContext = trpc.useContext();
	const [showEnableModal, setShowEnableModal] = useState(false);
	const [showAddFilterModal, setShowAddFilterModal] = useState(false);
	const [selectedFilterIndex, setSelectedFilterIndex] = useState<number>();
	const routeParameters = useParams() as { connectionId: string; team: "string" };

	const allowEditingIntegrations = useSelector(
		(state) => state.dashboard.permissions.userPermissions.editIntegrations,
		shallowEqual
	);

	const { data, isLoading } = trpc.integration.getConnection.useQuery({ id: routeParameters.connectionId });
	const disableWebhook = trpc.integrations.crm.disableWebhook.useMutation();

	const labelIds = useMemo(() => {
		if (!data?.config?.webhook?.contactFilters) return [];
		return data?.config?.webhook?.contactFilters?.reduce(
			(prev, curr) => (curr.labelId ? [...prev, curr.labelId] : prev),
			[]
		);
	}, [data]);

	const { data: contactLabels } = trpc.contactLabel.getBatch.useQuery(
		{ idList: labelIds },
		{ enabled: Boolean(labelIds.length) }
	);

	const connectionConfig = data?.config as IAdminPulseConfig;

	const handleDisableWebhook = () => {
		Modal.confirm({
			width: 512,
			title: intl.formatMessage({
				id: "settings.integrations.admin_pulse.webhook.disable_model.title",
				defaultMessage: "Disable webhook",
				description:
					"Title of modal that is shown to the user where they can disable the admin pulse webhook model",
			}),
			icon: <CloseCircleOutlined style={{ color: "#DE5050" }} />,
			content: intl.formatMessage({
				id: "settings.integrations.admin_pulse.webhook.disable_model.description",
				defaultMessage:
					"You're about to delete the Admin Pulse webhook, it's important to know that once you proceed, we'll stop syncing any of your contacts.",
				description:
					"Description in confirmation modal where user needs to confirm they want to delete the admin pulse webhook. Explain that from this point on we will not sync any of their contacts",
			}),
			okText: intl.formatMessage({
				id: "settings.integrations.admin_pulse.webhook.disable_model.button.submit",
				defaultMessage: "Disable webhook",
			}),
			okButtonProps: { danger: true, loading: disableWebhook.isLoading },
			cancelText: intl.formatMessage({
				id: "settings.integrations.admin_pulse.webhook.disable_model.button.cancel",
				defaultMessage: "Cancel",
			}),
			onOk() {
				disableWebhook
					.mutateAsync({ connectionId: routeParameters.connectionId })
					.then(() => {
						trpcContext.integration.getConnection.invalidate({ id: routeParameters.connectionId });
						notification.success({
							message: intl.formatMessage({
								id: "settings.integrations.admin_pulse.webhook.disable_model.succeeded",
								defaultMessage:
									"The webhook has been successfully disabled. You can turn it back on whenever you're ready.",
								description: "Notification about Admin pulse webhook has been successfully disabled",
							}),
							placement: "bottomRight",
						});
					})
					.catch(() => {
						notification.error({
							message: intl.formatMessage({
								id: "settings.integrations.admin_pulse.webhook.disable_model.failed",
								defaultMessage:
									"It seems like the webhook can't be disabled right now. Please give it another shot later. We'll have this sorted out for you soon!",
								description: "Error webhook can not be disabled, user should try again later",
							}),
							placement: "bottomRight",
							duration: 8,
						});
					});
			},
		});
	};

	const handleWebhookButton = () => {
		if (connectionConfig?.webhook?.isEnabled) return handleDisableWebhook();

		return setShowEnableModal(true);
	};

	return (
		<DashboardCard
			title={intl.formatMessage({
				id: "settings.integrations.admin_pulse.webhook.title",
				defaultMessage: "Webhook configuration",
			})}
		>
			<Skeleton active loading={isLoading} style={{ paddingTop: "2em" }}>
				<header className="admin_pulse-webhook-header">
					<p className="admin_pulse-webhook-header-description">
						<FormattedMessage
							id="settings.integrations.admin_pulse.webhook.description"
							defaultMessage="Supercharge your Admin Pulse experience with webhooks, you keep Bothive in sync with your Admin Pulse contacts in real-time. No more manually importing or updating your contacts - it's all automatic, making your workflow smoother and more efficient."
							description="Describe to the user they can enable the admin pulse webhooks in this section. The webhooks ensures Admin pulse relations and changes are immediately synced into Bothive."
						/>
					</p>
					<Tooltip
						title={intl.formatMessage({
							id: "settings.integrations.admin_pulse.webhook.button.no_access",
							defaultMessage: "Missing edit integrations permissions",
							description: "Tooltip user sees when they don't 'edit integration permissions'",
						})}
						trigger={!allowEditingIntegrations ? ["hover"] : []}
						placement="bottom"
					>
						<Button
							size="large"
							onClick={handleWebhookButton}
							loading={disableWebhook.isLoading}
							disabled={!allowEditingIntegrations}
							danger={connectionConfig?.webhook?.isEnabled}
						>
							{!connectionConfig?.webhook?.isEnabled && (
								<FormattedMessage
									id="settings.integrations.admin_pulse.webhook.button.enable"
									defaultMessage="Enable webhook"
								/>
							)}
							{connectionConfig?.webhook?.isEnabled && (
								<FormattedMessage
									id="settings.integrations.admin_pulse.webhook.button.disable"
									defaultMessage="Disable webhook"
								/>
							)}
						</Button>
					</Tooltip>
				</header>
			</Skeleton>
			{connectionConfig?.webhook?.isEnabled && (
				<div className="admin_pulse-webhook-table_wrapper">
					<SkeletonTable
						className="t-gap--top-sm"
						pagination={
							(connectionConfig?.webhook?.contactFilters?.length || 0) > 5 && {
								defaultPageSize: 5,
								showSizeChanger: true,
								showQuickJumper: false,
								pageSizeOptions: ["5", "10", "20", "50"],
							}
						}
						isLoading={isLoading}
						dataSource={connectionConfig?.webhook?.contactFilters || []}
						onRow={(_, index) => ({ onClick: () => setSelectedFilterIndex(index) })}
						locale={{
							emptyText: intl.formatMessage({
								id: "settings.integrations.admin_pulse.webhook.table.empty_text",
								defaultMessage:
									"Oh, it's looking a bit empty here! When no webhook filters are defined. Don't worry; you can easily fill it up with your filters.",
								description: "Empty table message when no webhook filters are defined.",
							}),
						}}
					>
						<Table.Column
							key="crmType"
							dataIndex="crmType"
							title={intl.formatMessage({
								id: "settings.integrations.admin_pulse.webhook.table.crm_type.label",
								defaultMessage: "CRM type",
							})}
							ellipsis={true}
							render={(value) =>
								crmTypesI18n[value] ? <FormattedMessage {...crmTypesI18n[value]} /> : "-"
							}
						/>
						<Table.Column
							key="type"
							dataIndex="type"
							ellipsis={true}
							title={intl.formatMessage({
								id: "settings.integrations.admin_pulse.webhook.table.type.label",
								defaultMessage: "Type",
							})}
							render={(value) =>
								contactTypesI18n[value] ? <FormattedMessage {...contactTypesI18n[value]} /> : "-"
							}
						/>
						<Table.Column
							key="rules"
							dataIndex="rules"
							title={intl.formatMessage({
								id: "settings.integrations.admin_pulse.webhook.table.rules.label",
								defaultMessage: "Rules set",
							})}
							render={(value: []) =>
								value?.length ||
								intl.formatMessage({
									id: "settings.integrations.admin_pulse.webhook.table.rules.empty_value",
									defaultMessage: "None",
								})
							}
						/>
						<Table.Column
							key="labelId"
							dataIndex="labelId"
							title={intl.formatMessage({
								id: "settings.integrations.admin_pulse.webhook.table.label.label",
								defaultMessage: "Label",
							})}
							render={(labelId) => {
								if (!contactLabels?.length) return "";

								const label = contactLabels.find((label) => label._id === labelId);

								if (!label) return "";

								return (
									<Tag
										title={label.name}
										color={label.color}
										style={{
											maxWidth: "170px",
											textOverflow: "ellipsis",
											overflowX: "hidden",
											whiteSpace: "nowrap",
											margin: "auto 0 auto",
										}}
									>
										{label.name}
									</Tag>
								);
							}}
						/>

						<Table.Column
							key="edit"
							width="8em"
							title={null}
							dataIndex="_id"
							render={(id) => (
								<Button
									icon={<EditOutlined />}
									type="text"
									onClick={() => setSelectedFilterIndex(id)}
								/>
							)}
						/>
					</SkeletonTable>
					<Button
						size="small"
						type="text"
						icon={<PlusOutlined className="t-gap--right-xs" />}
						onClick={() => setShowAddFilterModal(true)}
					>
						<FormattedMessage
							id="settings.integrations.admin_pulse.webhook.table.add_filter"
							defaultMessage="Add filter"
						/>
					</Button>
				</div>
			)}

			<EnableWebhookModal
				isOpen={showEnableModal}
				connectionId={routeParameters.connectionId}
				onClose={() => setShowEnableModal(false)}
			/>
			<EditFiltersModal
				filterIndex={selectedFilterIndex}
				isOpen={selectedFilterIndex !== undefined}
				connectionId={routeParameters.connectionId}
				onClose={() => setSelectedFilterIndex(undefined)}
				contactFilters={connectionConfig?.webhook?.contactFilters || []}
			/>
			<AddFiltersModal
				isOpen={showAddFilterModal}
				connectionId={routeParameters.connectionId}
				onClose={() => setShowAddFilterModal(false)}
				contactFilters={connectionConfig?.webhook?.contactFilters || []}
			/>
		</DashboardCard>
	);
}
