import "./style.scss";

import { Button, Form, Input, Select, Switch, Tooltip, Typography } from "antd";
import { DeleteOutlined, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useState } from "react";
import type { IContact } from "@bothive_core/database";

import { trpc } from "../../../../../../trpc";
import FieldLabel from "@/shared/components/field/default";
import ShowSuccessBanner from "@/shared/components/banner/success";
import ShowFailedBanner from "@/shared/components/banner/failed";
import { ExistingContactDisplay } from "../../../../components/existingContactDisplay";

interface IContactPhoneNumbers {
	phoneNumbers?: {
		name: string;
		value: string;
		isPrimary: boolean;
	}[];
	className: string;
}

export default function ContactPhoneNumbers({ phoneNumbers, className }: IContactPhoneNumbers) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const params: { contactId: string } = useParams();
	const [conflictContact, setConflictContact] = useState<IContact | undefined>(undefined);

	const updatePhoneNumberMutation = trpc.contact.updatePhone.useMutation();
	const trpcUtils = trpc.useUtils();

	const selectOptions = [
		{
			label: intl.formatMessage({
				id: "contacts.detail.attributes.phone.label.standard",
				defaultMessage: "Standard",
			}),
			value: "STANDARD",
		},
		{
			label: intl.formatMessage({
				id: "contacts.detail.attributes.phone.label.mobile",
				defaultMessage: "Mobile",
			}),
			value: "MOBILE",
		},
		{
			label: intl.formatMessage({ id: "contacts.detail.attributes.phone.label.home", defaultMessage: "Home" }),
			value: "HOME",
		},
		{
			label: intl.formatMessage({
				id: "contacts.detail.attributes.phone.label.private",
				defaultMessage: "Private",
			}),
			value: "PRIVATE",
		},
		{
			label: intl.formatMessage({
				id: "contacts.detail.attributes.phone.label.whatsapp",
				defaultMessage: "WhatsApp",
			}),
			value: "WHATSAPP",
		},
		{
			label: intl.formatMessage({
				id: "contacts.detail.attributes.phone.label.company",
				defaultMessage: "Company",
			}),
			value: "COMPANY",
		},
		{
			label: intl.formatMessage({ id: "contacts.detail.attributes.phone.label.other", defaultMessage: "Other" }),
			value: "OTHER",
		},
	];

	const handleValuesChange = (newValue, oldValue) => {
		// If address is set to primary, set old primary address to false
		if (!newValue.phoneNumbers || !Array.isArray(newValue.phoneNumbers)) return;

		const newPrimaryIndex = newValue.phoneNumbers.findIndex((value) => value?.isPrimary);

		// if primary is not changed -> exit
		if (newPrimaryIndex === -1) return;

		const previousPrimaryIndex = oldValue.phoneNumbers.findIndex(
			(value, index) => value?.isPrimary && newPrimaryIndex !== index
		);

		// if primary is not changed against old values -> exit
		if (newPrimaryIndex === previousPrimaryIndex || previousPrimaryIndex === -1) return;

		oldValue.phoneNumbers[previousPrimaryIndex].isPrimary = false;

		form.setFieldsValue(oldValue);
	};

	const handleSubmit = ({ phoneNumbers }) => {
		updatePhoneNumberMutation.mutate(
			{ contactId: params.contactId, phones: phoneNumbers },
			{
				onSuccess: () => {
					trpcUtils.contact.getContactById.invalidate();
					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "contacts.detail.attributes.phone.update.success.title",
							defaultMessage: "Phone number successfully updated",
						}),
					});
				},
				onError: (error) => {
					if (error?.data?.code === "CONFLICT") {
						const details = error?.shape?.message
							? (JSON.parse(error?.shape?.message) as { conflictContact: IContact })
							: null;

						if (details?.conflictContact) setConflictContact(details.conflictContact);

						ShowFailedBanner({
							title: intl.formatMessage({
								id: "contacts.detail.attributes.phone.update.failed.title",
								defaultMessage: "Phone number failed to update",
							}),
							description: intl.formatMessage({
								id: "contacts.detail.attributes.phone.update.failed.description",
								defaultMessage: "The content of this field needs to be unique in this organization",
							}),
						});
						return;
					}
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "contacts.detail.attributes.phone.update.failed.title",
							defaultMessage: "Phone number failed to update",
						}),
					});
				},
			}
		);
	};

	return (
		<Form
			form={form}
			preserve={false}
			layout="vertical"
			id="contactPhoneNumberForm"
			initialValues={{ phoneNumbers }}
			onValuesChange={handleValuesChange}
			onFinish={handleSubmit}
			className={`contact_phone_number ${className || ""}`}
		>
			<Form.List name="phoneNumbers">
				{(fields, { add, remove }, { errors }) => (
					<>
						{fields.map((field, index) => {
							const isPrimaryField = form.getFieldValue(["phoneNumbers", field.name, "isPrimary"]);
							const hasConflict = conflictContact?.phone?.find(
								(phone) => phone.value === form.getFieldValue(["phoneNumbers", field.name, "value"])
							);

							return (
								<>
									{hasConflict && conflictContact && (
										<div className="contact_phone_number-form_row-error">
											<Typography.Text type="danger">
												<FormattedMessage
													id="contacts.detail.attributes.phone.error.duplicate"
													defaultMessage="Phone number is already added to the following contact in your organization"
													description="Error message for phone number that already exists with preview of the contact that has the same phone number"
												/>
											</Typography.Text>
											<ExistingContactDisplay
												contact={conflictContact}
												className="t-gap--bottom"
											/>
										</div>
									)}
									<Form.Item key={field.key} className="contact_phone_number-form_row">
										<FieldLabel
											id={`phoneNumbers_${index}_name`}
											label={intl.formatMessage({
												id: "contacts.detail.attributes.phone.label.title",
												defaultMessage: "Label",
											})}
											style={{ width: "fit-content", marginRight: 16 }}
										>
											<Form.Item {...field} name={[field.name, "name"]}>
												<Select
													placement="bottomLeft"
													style={{ width: 254, textAlign: "left" }}
													options={selectOptions}
												/>
											</Form.Item>
										</FieldLabel>
										<FieldLabel
											id={`phoneNumbers_${index}_value`}
											label={intl.formatMessage({
												id: "contacts.detail.attributes.phone.number.label",
												defaultMessage: "Number",
											})}
											className="contact_phone_number-form_row-email"
										>
											<Form.Item
												{...field}
												name={[field.name, "value"]}
												validateTrigger={["onChange", "onBlur"]}
												rules={[
													{
														validator: async (_, phoneNumber) => {
															if (!phoneNumber.match(/^[0-9 -+]+$/)) {
																const errorLabel = intl.formatMessage({
																	id: "contacts.detail.attributes.phone.number.invalid_error",
																	defaultMessage: "Please enter a valid phone number",
																});

																return Promise.reject(new Error(errorLabel));
															}
														},
													},
												]}
											>
												<Input
													placeholder="0491 23 45 56"
													status={hasConflict ? "error" : undefined}
													prefix={hasConflict ? <WarningOutlined /> : undefined}
												/>
											</Form.Item>
										</FieldLabel>
										<FieldLabel
											label={intl.formatMessage({
												id: "contacts.detail.attributes.phone.primary.label",
												defaultMessage: "Primary",
											})}
											style={{ width: "100px", marginLeft: "32px" }}
										>
											<Tooltip
												title={
													isPrimaryField &&
													intl.formatMessage({
														id: "contacts.detail.attributes.phone.primary.tooltip",
														defaultMessage: "Make another number primary",
													})
												}
											>
												<Form.Item
													{...field}
													name={[field.name, "isPrimary"]}
													valuePropName="checked"
												>
													<Switch disabled={isPrimaryField} />
												</Form.Item>
											</Tooltip>
										</FieldLabel>
										<Form.Item>
											<Tooltip
												title={
													isPrimaryField &&
													intl.formatMessage({
														id: "contacts.detail.attributes.phone.delete_button.tooltip",
														defaultMessage: "Primary phone number cannot be deleted.",
													})
												}
											>
												<Button
													type="text"
													disabled={isPrimaryField}
													icon={<DeleteOutlined />}
													onClick={() => remove(field.name)}
													className="contact_phone_number-form_row-delete"
												/>
											</Tooltip>
										</Form.Item>
									</Form.Item>
								</>
							);
						})}
						<Form.Item>
							<Button
								style={{ width: "fit-content" }}
								className="t-gap--top-sm"
								type="text"
								icon={<PlusOutlined />}
								onClick={() =>
									add({
										name: "STANDARD",
										value: "",
										isPrimary: !form.getFieldValue("phoneNumbers")?.length,
									})
								}
							>
								{""}{" "}
								<FormattedMessage
									id="contacts.detail.attributes.phone.add.number"
									defaultMessage="Add phone number"
								/>
							</Button>
							<Form.ErrorList errors={errors} />
						</Form.Item>
					</>
				)}
			</Form.List>
			<Form.Item style={{ width: "fit-content", alignSelf: "flex-end" }}>
				<Button
					key="submit"
					type="primary"
					htmlType="submit"
					className="t-gap--top"
					style={{ width: "fit-content", alignSelf: "flex-end" }}
				>
					<FormattedMessage id="contacts.detail.attributes.phone.submit" defaultMessage="Save" />
				</Button>
			</Form.Item>
		</Form>
	);
}
