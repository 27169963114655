import { Avatar, Dropdown } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import { actions as profileActions } from "../../../store/profile";
import { routesConfig } from "../../../config";

const mapStateToProps = (state) => ({
	user: state.profile.account.user,
});

class OProfileHeader extends React.Component {
	constructor(props) {
		super(props);
		this.bindHandles();
	}

	bindHandles() {
		this.handleMenuClick = this.handleMenuClick.bind(this);
	}
	shouldComponentUpdate(nextProps) {
		const propsKeys = ["title", "user", "breadcrumbs"];

		if (propsKeys.find((key) => nextProps[key] !== this.props[key])) {
			return true;
		}

		return false;
	}

	handleMenuClick({ key }) {
		const actions = {
			logout: () => this.props.logoutUser({ redirect: true }),
		};

		actions[key] && actions[key]();
	}

	render() {
		return (
			<header className={`o-header ${this.props.className}`}>
				{!this.props.breadcrumbs?.length && this.props.title && (
					<h1>
						<FormattedMessage id={this.props.title} />
					</h1>
				)}
				<Dropdown
					menu={{
						onClick: this.handleMenuClick,
						items: [
							{
								key: "settings",
								label: (
									<NavLink to={routesConfig.profile.overview.replace(":slug", this.props.user.slug)}>
										<FormattedMessage
											id="PROFILE.POPUP.SETTINGS"
											defaultMessage="Profile Settings"
										/>
									</NavLink>
								),
							},
							{
								key: "logout",
								label: (
									<div className="o-header--dashboard-notification_item danger">
										<FormattedMessage id="profile.popup.logout" defaultMessage="Logout" />
									</div>
								),
							},
						],
					}}
				>
					<Avatar shape="square" src={this.props.user.avatar} />
				</Dropdown>
			</header>
		);
	}
}

OProfileHeader.defaultProps = {
	breadcrumbs: [],
	className: "",
};

export default withRouter(
	connect(mapStateToProps, {
		logoutUser: profileActions.account.logoutUser,
		changeAvailability: profileActions.account.changeAvailability,
	})(OProfileHeader)
);
