import "./style.scss";

import { Modal, Typography } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { keyMappingConfig } from "../config";
import { actions as dashboardActions } from "../store/dashboard";
import helpers from "./helpers";
import ShortcutRow from "./shortcut_row";

function Shortcut({ isVisible, onClose }) {
	const intl = useIntl();
	const dispatch = useDispatch();
	const mapping = keyMappingConfig.defaultMapping;
	const groups = helpers.shortcutMappingToConfigGroups(mapping);

	useEffect(() => {
		document.addEventListener("keyup", toggleShortcuts);

		return () => {
			document.removeEventListener("keyup", toggleShortcuts);
		};
	}, [isVisible]);

	const toggleShortcuts = () => {
		if (!isVisible) {
			return;
		}

		dispatch(dashboardActions.ui.toggleShortcuts());
	};

	return (
		<Modal
			title={intl.formatMessage({ id: "INBOX.SHORTCUTS.TITLE" })}
			open={isVisible}
			onCancel={onClose}
			width="640px"
			footer={null}
			className="inbox-shortcuts"
		>
			{groups.map((group) => (
				<section key={group.id} className="inbox-shortcuts-group t-gap--bottom">
					<Typography.Text strong>
						<FormattedMessage id={group.title} />
					</Typography.Text>
					<div className="inbox-shortcuts-group--list">
						{group.items.map((item) => (
							<ShortcutRow keys={item.keys} label={item.label} key={item.id} />
						))}
					</div>
				</section>
			))}
		</Modal>
	);
}

export default React.memo(Shortcut);
