import actionTypes from "./report.actionTypes";

const initialState = {
	list: [],
	selected: {},
	currentPage: null,

	isFetching: false,
	fetchingFailed: false,
	fetchingSucceeded: false,

	filter: {
		status: undefined,
		limit: 20,
		offset: 0,
		total: 0,
	},

	cancellation: undefined,

	attachmentsAreFetching: false,
	attachmentsCount: null,
};

export default function (state = initialState, action) {
	const updateState = {
		[actionTypes.FLOW_REPORT_IS_FETCHING]: () => ({
			...state,
			isFetching: true,
			fetchingFailed: false,
			fetchingSucceeded: false,
		}),
		[actionTypes.FETCH_FLOW_REPORT_FAILED]: () => ({
			...state,
			isFetching: false,
			fetchingFailed: true,
			fetchingSucceeded: false,
		}),
		[actionTypes.FETCH_FLOW_REPORT_SUCCEEDED]: () => ({
			...state,
			isFetching: false,
			fetchingFailed: false,
			fetchingSucceeded: true,
		}),
		[actionTypes.RESET_FLOW_REPORTS]: () => initialState,
		[actionTypes.SET_FLOW_REPORTS_FILTER]: () => ({
			...state,
			filter: {
				...state.filter,
				...action.data,
			},
		}),
		[actionTypes.FETCH_FLOW_REPORTS]: () => ({
			...state,
			list: action.data.data || [],
			cancellation: undefined,
			filter: {
				...state.filter,
				...action.data.meta,
				limit: action.data.meta.limit || state.filter.limit,
			},
		}),
		[actionTypes.FETCH_NEXT_FLOW_REPORTS]: () => ({
			...state,
			list: action.data.data,
			filter: {
				...state.filter,
				...action.data.meta,
				limit: action.data.meta.limit || state.filter.limit,
			},
		}),

		[actionTypes.FETCH_REPORT_DETAIL]: () => ({
			...state,
			selected: {
				...state.selected,
				[action.data.id]: action.data,
			},
		}),
		// [actionTypes.FETCH_NEXT_REPORT_DETAIL]: () => ({
		// 	...state,
		// 	selected: undefined,
		// }),
		// [actionTypes.FETCH_PREVIOUS_REPORT_DETAIL]: () => ({
		// 	...state,
		// 	selected: undefined,
		// }),

		[actionTypes.UPDATE_REPORT]: () => ({
			...state,
			list: state.list.map((report) => {
				if (report.id === action.data.reportId) {
					return {
						...report,
						status: action.data.payload.status,
						updatedAt: action.data.payload.updatedAt,
					};
				}

				return report;
			}),
			selected: {
				...state.selected,
				[action.data.reportId]: {
					...state.selected[action.data.reportId],
					...action.data.payload,
				},
			},
		}),
		[actionTypes.UPDATE_REPORT_LOCALLY]: () => ({
			...state,
			selected: {
				...state.selected,
				[action.data.reportId]: {
					...state.selected[action.data.reportId],
					...action.data.payload,
				},
			},
		}),
		[actionTypes.ASSIGN_TO_REPORT]: () => ({
			...state,
			list: state.list.map((report) => {
				if (report.id === action.data.reportId) {
					return {
						...report,
						assignee: action.data.member && {
							id: action.data.member.userId,
							username: action.data.member.name,
							avatar: action.data.member.avatar,
						},
					};
				}

				return report;
			}),
			selected: {
				...state.selected,
				[action.data.reportId]: {
					...state.selected[action.data.reportId],
					assignee: action.data.member?.userId,
				},
			},
		}),
		[actionTypes.DELETE_REPORT]: () => {
			delete state.selected[action.data];

			return {
				...state,
				list: state.list.filter((report) => report.id !== action.data),
				selected: state.selected,
			};
		},
		[actionTypes.TEAM_CHANGED]: () => initialState,
		[actionTypes.FETCH_TEAM]: () => initialState,
		[actionTypes.SET_REPORT_CANCELLATION]: () => ({
			...state,
			cancellation: action.data,
		}),
		[actionTypes.FLOW_REPORT_ATTACHMENTS_ARE_FETCHING]: () => ({
			...state,
			attachmentsAreFetching: action.data,
		}),
		[actionTypes.SET_CURRENT_PAGE]: () => ({
			...state,
			currentPage: action.data,
		}),
		[actionTypes.FLOW_REPORT_ATTACHMENTS_COUNT]: () => ({
			...state,
			attachmentsCount: action.data,
		}),
	};

	return updateState[action.type] ? updateState[action.type]() : state;
}
