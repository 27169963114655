import { Button, Form, Modal, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect } from "react";
import type { IAdminPulseContactFilter } from "@bothive_core/database";

import { trpc } from "../../../../../../../trpc";
import WebhookRulesForm from "./webhookRulesForm";

interface IEnableWebhook {
	connectionId: string;
	isOpen: boolean;
	onClose: () => void;
}
export default function EnableWebhookModal({ connectionId, isOpen, onClose }: IEnableWebhook) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const trpcContext = trpc.useContext();
	const enableWebhook = trpc.integrations.crm.enableWebhook.useMutation();

	const handleSubmit = async () => {
		try {
			const contactFilter = form.getFieldsValue();

			await enableWebhook.mutateAsync({ connectionId, data: { contactFilters: [contactFilter] } });

			onClose();
			trpcContext.integration.getConnection.invalidate({ id: connectionId });
			notification.success({
				message: intl.formatMessage({
					id: "settings.integrations.admin_pulse.webhook.enable_model.succeeded",
					defaultMessage: "The webhook has been created",
					description: "Notification about Admin pulse webhook, it has been enabled",
				}),
				placement: "bottomRight",
			});
		} catch (_) {
			notification.error({
				message: intl.formatMessage({
					id: "settings.integrations.admin_pulse.webhook.enable_model.failed",
					defaultMessage:
						"It seems like the webhook can't be enabled right now. Please give it another shot later. We'll have this sorted out for you soon!",
					description: "Error notification webhook can not be enabled, user should try again later",
				}),
				placement: "bottomRight",
				duration: 8,
			});
		}
	};

	const defaultValue: IAdminPulseContactFilter = {
		type: "2",
		crmType: "3",
		rules: [],
	};

	useEffect(() => form.setFieldsValue({}), []);

	return (
		<Modal
			width={640}
			open={isOpen}
			onCancel={onClose}
			title={intl.formatMessage({
				id: "settings.integrations.admin_pulse.webhook.enable_model.title",
				defaultMessage: "Enable webhook",
				description:
					"Title of modal that is shown to the user where they can enable the admin pulse webhook model",
			})}
			className="admin_pulse-webhook-enable_webhook"
			destroyOnClose
			footer={
				<footer className="admin_pulse-webhook-enable_webhook-footer">
					<Button type="text" onClick={onClose}>
						<FormattedMessage
							id="settings.integrations.admin_pulse.webhook.enable_model.button.cancel"
							defaultMessage="Cancel"
						/>
					</Button>
					<Button type="primary" htmlType="submit" form="webhookRulesForm" loading={enableWebhook.isLoading}>
						<FormattedMessage
							id="settings.integrations.admin_pulse.webhook.enable_model.button.submit"
							defaultMessage="Enable webhook"
						/>
					</Button>
				</footer>
			}
		>
			<p className="admin_pulse-webhook-enable_webhook-description">
				<FormattedMessage
					id="settings.integrations.admin_pulse.webhook.disable_model.description"
					defaultMessage={`Ready to supercharge your Admin Pulse experience?
					Enable the Admin Pulse webhook model, it's your key to seamless data synchronization right at your fingertips.`}
					description="Description of modal that is shown to the user where they can enable the admin pulse webhook model and configure syncing rules"
				/>
			</p>
			<br />
			<p>
				<FormattedMessage
					id="settings.integrations.admin_pulse.webhook.disable_model.rules.description"
					defaultMessage="The webhook will only sync contacts that meet your filters. Say goodbye to the noise and keep things focused on what truly matters to you!"
					description="Explain syncing rules, only contacts that match with the filters will be synced through the webhook"
				/>
			</p>
			<br />
			<WebhookRulesForm form={form} values={defaultValue} onSubmit={handleSubmit} />
		</Modal>
	);
}
