export enum EMessageTypes {
	email = "email",
	internal_message = "internal_message",
}

export enum EPayloadTypes {
	entity = "entity",
	html = "html",
}

export enum ERecipientTypes {
	to = "to",
	from = "from",
	cc = "cc",
	bcc = "bcc",
	replyTo = "replyTo",
}
