import { Button, Form, Input, Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

export default function AddDomain({
	visible,
	onClose,
	onSubmit,
}: {
	visible: boolean;
	onClose: () => void;
	onSubmit: (values: { name: string }) => void;
}) {
	const intl = useIntl();

	const validateURL = async (_, value) => {
		try {
			new URL(value);
			return Promise.resolve();
		} catch (_) {
			return Promise.reject(
				new Error(
					intl.formatMessage({
						id: "settings.api_key_modal.add_domain.form.domain_name.not_valid_url",
						defaultMessage: "Please fill in a valid URL.",
					})
				)
			);
		}
	};

	return (
		<Modal
			open={visible}
			onCancel={onClose}
			onOk={() => onSubmit}
			destroyOnClose
			title={
				<FormattedMessage id="settings.api_key_modal.add_domain.header.title" defaultMessage="Add new domain" />
			}
			transitionName="" // to disable animation
			footer={[
				<Button key="submit" type="primary" form="addDomain" htmlType="submit">
					<FormattedMessage
						id="settings.api_key_modal.add_domain.button.add_domain"
						defaultMessage="Add domain"
					/>
				</Button>,
			]}
		>
			<Form id="addDomain" onFinish={onSubmit} requiredMark="optional" autoComplete="off" layout="vertical">
				<Form.Item
					name="name"
					rules={[
						{
							required: true,
							validator: validateURL,
						},
					]}
				>
					<Input
						placeholder={intl.formatMessage({
							id: "settings.api_key_modal.add_domain.form.domain_name.placeholder",
							defaultMessage: "e.g. https://www.bothive.be, http://getbothive.com, https://...",
						})}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}
