/* eslint-disable simple-import-sort/imports */
import { Button } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import DashboardCard from "@/shared/components/card/dashboard";

import CopyModal from "./copy_modal";
import style from "./style.module.scss";

export default function CopyStatusTypes({ className }) {
	const [showCopyModal, setShowCopyModal] = useState(false);
	const hasEditPermissions = useSelector((state) => state.dashboard.permissions.userPermissions.editFlows);

	if (!hasEditPermissions) return null;

	return (
		<>
			<DashboardCard
				title="FLOWS.REPORTS.STATUS_TYPES.COPY.TITLE"
				className={`${style.copy_status_types} ${className}`}
			>
				<div className={style.copy_status_types_body}>
					<p>
						<FormattedMessage id="FLOWS.REPORTS.STATUS_TYPES.COPY.DESCRIPTION" />
					</p>
					<Button onClick={() => setShowCopyModal(true)}>
						<FormattedMessage id="FLOWS.REPORTS.STATUS_TYPES.COPY.BUTTON" />
					</Button>
				</div>
			</DashboardCard>
			<CopyModal isVisible={showCopyModal} onClose={() => setShowCopyModal(false)} />
		</>
	);
}

CopyStatusTypes.defaultProps = {
	className: "",
};
