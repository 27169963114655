import { contrastTextByBackground } from "@/helpers/color.helpers";
import { CloseOutlined } from "@ant-design/icons";
import { Tag as AntdTag, TagProps } from "antd";
import { CSSProperties, ReactNode } from "react";

type CustomTagProps = {
	color?: string;
	children: ReactNode;
	style?: CSSProperties;
	intent?: "default" | "count";
	extraTags?: string[];
} & TagProps;

export default function Tag({ color, children, style, extraTags, intent = "default", ...props }: CustomTagProps) {
	const textColor = contrastTextByBackground({
		backgroundColor: color || "gray",
		lightTextColor: "#fafcfc", // $matte--black
		darkTextColor: "#141414", // $white--light
	});

	if (intent === "count")
		return (
			<AntdTag
				{...props}
				color={color ? color : "#002140"}
				style={{
					...style,
				}}
			>
				+{children}
			</AntdTag>
		);

	return (
		<AntdTag
			{...props}
			closeIcon={
				props.onClose && (
					<CloseOutlined
						style={{
							color: textColor,
						}}
					/>
				)
			}
			color={color}
			style={{
				...style,
				color: textColor,
			}}
		>
			{children}
		</AntdTag>
	);
}
