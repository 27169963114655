import "./style.scss";

import { Skeleton } from "antd";
import React from "react";

import DashboardCard from "@/shared/components/card/dashboard";

import WidgetSettingsForm from "./form";

export default function WidgetSettings({ isLoading, isDisabled }) {
	return (
		<DashboardCard
			title="FLOWS.REPORT.SETTINGS.WIDGET.HEADER.TITLE"
			description="FLOWS.REPORT.SETTINGS.WIDGET.HEADER.DESCRIPTION"
			className="frsw-form t-gap--right"
		>
			{isLoading && <Skeleton active paragraph={{ rows: 3 }} />}
			{!isLoading && <WidgetSettingsForm isDisabled={isDisabled} />}
		</DashboardCard>
	);
}
