import { Button, Dropdown, Input } from "antd";
import { useState } from "react";
import { ChromePicker } from "react-color";

interface IColorPicker {
	id?: string;
	value?: string;
	onChange?: (value: string) => void;
	isValidHex?: (value: boolean) => void;
}

const ColorPicker = ({ id, onChange, value, isValidHex }: IColorPicker) => {
	const [color, setColor] = useState(value || "#FFFFFF");
	const [dropdownVisible, setDropdownVisible] = useState(false);

	const isValidColor = (str) => str.match(/^#[a-f0-9]{6}$/i) !== null;

	const handleChange = (value) => {
		setColor(value.hex);

		onChange && onChange(value.hex);
		isValidHex && isValidHex(isValidColor(value.hex));
	};

	const handleInputChange = (value) => {
		setColor(value);

		onChange && onChange(value);
		isValidHex && isValidHex(isValidColor(value));
	};

	const picker = () => (
		<ChromePicker color={color} onChangeComplete={handleChange} enableAlpha={false} disableAlpha />
	);

	return (
		<Input
			id={id}
			value={color || ""}
			onChange={(e) => handleInputChange(e.target.value)}
			onClick={() => setDropdownVisible(true)}
			prefix={
				<Dropdown open={dropdownVisible} onOpenChange={setDropdownVisible} trigger={["click"]} overlay={picker}>
					<Button style={{ background: color }}> </Button>
				</Dropdown>
			}
			style={{ width: "auto", paddingLeft: "5.6px" }}
		/>
	);
};

export default ColorPicker;
