import axios from "axios";

import { queryStringHelpers } from "@/shared/helpers";

import { apiConfig } from "../common/config";

export const getFlows = async ({ templateId, limit, offset, q, compact = false }) => {
	const query = queryStringHelpers.createQueryString({
		templateId,
		limit,
		offset,
		q,
		compact,
	});

	const { data } = await axios.get(`${apiConfig.flow.getAll}${query}`);

	if (!data.success) throw new Error();

	return data;
};
