import { keyMappingConfig } from "../config";

interface IMappingItem {
	id: keyMappingConfig.keyMappingEvents;
	label: string;
}

interface IMappingGroup {
	id: string;
	title: string;
	items: IMappingItem[];
}

const groups: IMappingGroup[] = [
	{
		id: "inbox",
		title: "INBOX.SHORTCUTS.INBOX.TITLE",
		items: [
			{
				id: "inbox.previous_conversation",
				label: "INBOX.SHORTCUTS.INBOX.PREVIOUS_CONVERSATION.LABEL",
			},
			{
				id: "inbox.next_conversation",
				label: "INBOX.SHORTCUTS.INBOX.NEXT_CONVERSATION.LABEL",
			},
			{
				id: "inbox.left_status_folder",
				label: "INBOX.SHORTCUTS.INBOX.left_status_folder.LABEL",
			},
			{
				id: "inbox.right_status_folder",
				label: "INBOX.SHORTCUTS.INBOX.right_status_folder.LABEL",
			},
			{
				id: "inbox.new_message",
				label: "INBOX.SHORTCUTS.INBOX.NEW_MESSAGE.LABEL",
			},
			{
				id: "inbox.search.focus",
				label: "INBOX.SHORTCUTS.INBOX.inbox.search.focus.LABEL",
			},
		],
	},
	{
		id: "conversation",
		title: "INBOX.SHORTCUTS.CONVERSATION.TITLE",
		items: [
			{
				id: "conversation.reply_all",
				label: "INBOX.SHORTCUTS.CONVERSATION.REPLY_ALL.LABEL",
			},
			{
				id: "conversation.reply",
				label: "INBOX.SHORTCUTS.CONVERSATION.REPLY.LABEL",
			},
			{
				id: "conversation.forward",
				label: "INBOX.SHORTCUTS.CONVERSATION.FORWARD.LABEL",
			},
			{
				id: "conversation.internal_reply",
				label: "INBOX.SHORTCUTS.CONVERSATION.internal_reply.LABEL",
			},
			{
				id: "conversation.close",
				label: "INBOX.SHORTCUTS.CONVERSATION.CLOSE.LABEL",
			},
			{
				id: "conversation.toggle_read",
				label: "INBOX.SHORTCUTS.CONVERSATION.MARK_AS_UNREAD.LABEL",
			},
			{
				id: "conversation.open_label_menu",
				label: "INBOX.SHORTCUTS.CONVERSATION.OPEN_LABEL.LABEL",
			},
			{
				id: "conversation.open_assign_menu",
				label: "INBOX.SHORTCUTS.CONVERSATION.OPEN_ASSIGN.LABEL",
			},
			{
				id: "conversation.toggle_self_assign",
				label: "INBOX.SHORTCUTS.CONVERSATION.toggle_self_assign.LABEL",
			},
			{
				id: "conversation.open_participants_menu",
				label: "INBOX.SHORTCUTS.CONVERSATION.OPEN_PARTICIPANTS.LABEL",
			},
			{
				id: "conversation.delete",
				label: "INBOX.SHORTCUTS.CONVERSATION.DELETE.LABEL",
			},
		],
	},
	{
		id: "messaging",
		title: "INBOX.SHORTCUTS.MESSAGING.TITLE",
		items: [
			{
				id: "messaging.mark.bold",
				label: "INBOX.SHORTCUTS.MESSAGING.BOLD.LABEL",
			},
			{
				id: "messaging.mark.italic",
				label: "INBOX.SHORTCUTS.MESSAGING.ITALIC.LABEL",
			},
			{
				id: "messaging.mark.underlined",
				label: "INBOX.SHORTCUTS.MESSAGING.UNDERLINED.LABEL",
			},
		],
	},
	{
		id: "global",
		title: "INBOX.SHORTCUTS.GLOBAL.TITLE",
		items: [
			{
				id: "global.close_modal",
				label: "INBOX.SHORTCUTS.GLOBAL.CLOSE_MODAL.LABEL",
			},
			{
				id: "global.show_shortcuts",
				label: "INBOX.SHORTCUTS.GLOBAL.SHORTCUTS.LABEL",
			},
		],
	},
];

export default {
	groups,
};
