import { InboxFolder } from "../hooks/useInboxNavigation.hooks";

const foldersWithCount: InboxFolder[] = [
	"personal_conversations",
	"assigned_to_me",
	"mentioned",
	"shared_with_me",
	"drafts",
	"outbox",
];

export default {
	foldersWithCount,
};
