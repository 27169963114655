import { Alert, AlertProps, Button, Form, Input, Modal } from "antd";
import { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import helper from "./helper";
import style from "./style.module.scss";

interface DeleteModalProps {
	title: string | ReactNode;
	description: string | ReactNode;
	deleteLabel: string;
	placeholder?: string;
	banner?: ReactNode;
	additionalInfo?: ReactNode;
	alternativeAction?: ReactNode;
	isVisible?: boolean;
	isDeleting?: boolean;
	descriptionType?: AlertProps["type"];
	showDescriptionIcon?: boolean;
	onClose: () => void;
	onDelete: () => void;
}

export default function DeleteModal({
	title,
	description,
	deleteLabel,
	alternativeAction,
	banner,
	isVisible,
	isDeleting,
	onClose,
	onDelete,
	descriptionType,
	additionalInfo,
	placeholder = "POPUP.PLACEHOLDER.DESTRUCTIVE",
	showDescriptionIcon = false,
}: DeleteModalProps) {
	const intl = useIntl();

	return (
		<Modal
			open={isVisible}
			title={intl.formatMessage({ id: title as string })}
			destroyOnClose
			onOk={onDelete}
			onCancel={onClose}
			footer={null}
		>
			{banner}
			{typeof description === "string" ? (
				descriptionType ? (
					<Alert message={description} type={descriptionType} showIcon={showDescriptionIcon} />
				) : (
					<FormattedMessage id={description} />
				)
			) : (
				description
			)}
			{additionalInfo}
			{alternativeAction}
			<Form>
				<Form.Item
					name="confirm"
					rules={[
						() => ({
							validator(_, value) {
								return helper.deleteValueValidator({ value, intl });
							},
						}),
					]}
					className="t-gap--top-sm"
				>
					<Input placeholder={placeholder && intl.formatMessage({ id: placeholder })} />
				</Form.Item>
				<footer className={style.delete_modal_footer}>
					<Form.Item shouldUpdate className={style.delete_modal_submit}>
						{(form) => {
							const hasErrors =
								!form.isFieldsTouched() || form.getFieldsError().some(({ errors }) => errors.length);

							return (
								<Button
									danger
									type="primary"
									htmlType="submit"
									loading={isDeleting}
									disabled={hasErrors}
									onClick={onDelete}
									className={style.delete_modal_submit_button}
								>
									<FormattedMessage id={deleteLabel} />
								</Button>
							);
						}}
					</Form.Item>
					<Button type="text" onClick={onClose}>
						<FormattedMessage id="POPUP.BUTTON.CANCEL" defaultMessage="Cancel" />
					</Button>
				</footer>
			</Form>
		</Modal>
	);
}
