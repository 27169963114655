import { websiteIcon } from "../assets/images/icon";

export default {
	language: "nl-BE",
	support: {
		request: {
			bug: "5c38a182092c99228eaa7d7c",
			featureRequest: "5c38a182092c99228eaa7d7d",
			hulp: "5c38a182092c99228eaa7d7e",
			integratieRequest: "5c38a182092c99228eaa7d3a",
			newQuestion: "5c38a182092c99228eaa7d7f",
		},
		default: {
			importantType: "5c38a182092c99228eaa7d79",
		},
	},
	inbox: {
		siteUserAvatar: websiteIcon,
	},
	skipValue: "skip",
	unAssigned: {
		id: "unAssigned",
		userId: "unAssigned",
		avatar: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/avatar/unknown_avatar.svg",
		label: "ASSIGNMENT.UNASSIGNED.LABEL",
	},
};
