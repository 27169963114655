import { Avatar } from "antd";
import { GroupProps } from "antd/lib/avatar";

export default function AvatarGroup(props: GroupProps) {
	return (
		<Avatar.Group
			maxStyle={{
				color: "white",
				borderRadius: "var(--rounded-xs)",
				backgroundColor: "var(--primary)",
			}}
			{...props}
		>
			{props.children}
		</Avatar.Group>
	);
}
