import "./style.scss";

import { Alert, Button, Form, Input, Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect } from "react";

import {
	searchFilterState,
	selectedProgressState,
	selectedContactLabelState,
	selectedStatusTypeState,
} from "@/flows/flow_detail/reports/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import { trpc } from "../../../../../../trpc";
import { selectedFlowIdState } from "../../../../../common/state";
import { useReportList } from "@/flows/flow_detail/reports/hook";
import {
	allReportsSelectedState,
	isBulkDeletingState,
	selectedReportIdsState,
	totalReportsSelectedState,
} from "../../state";

export default function ChangeStatusModal({ showBulkDeleteModal, setShowBulkDeleteModal }) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const query = useRecoilValue(searchFilterState);
	const flowId = useRecoilValue(selectedFlowIdState);
	const progress = useRecoilValue(selectedProgressState);
	const statusType = useRecoilValue(selectedStatusTypeState);
	const selectedReportIds = useRecoilValue(selectedReportIdsState);
	const allReportsSelected = useRecoilValue(allReportsSelectedState);
	const totalReportsSelected = useRecoilValue(totalReportsSelectedState);
	const selectedContactLabel = useRecoilValue(selectedContactLabelState);
	const [isBulkDeleting, setIsBulkDeleting] = useRecoilState(isBulkDeletingState);
	const [_, fetchReportsList] = useReportList();

	const deleteReports = trpc.report.deleteReports.useMutation();

	const handleConfirm = async () => {
		setIsBulkDeleting(true);

		try {
			if (!flowId) return;

			await deleteReports.mutateAsync({
				flowId,
				q: query,
				progress,
				statusId: statusType,
				contactLabel: selectedContactLabel,
				reportIds: allReportsSelected ? undefined : selectedReportIds,
			});

			fetchReportsList();
			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "flows.report.overview.bulk_action.modal.delete_reports.success.title",
					defaultMessage: "Reports has been deleted.",
				}),
				duration: 6,
			});
		} catch (error) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "flows.report.overview.bulk_action.modal.delete_reports.failed.title",
					defaultMessage: "Bulk delete failed",
				}),
				description: intl.formatMessage({
					id: "flows.report.overview.bulk_action.modal.delete_reports.failed.description",
					defaultMessage: "Currently we cannot delete these reports, try again later.",
				}),
			});
		}

		setShowBulkDeleteModal(false);
		setIsBulkDeleting(false);
	};

	const compareValue = intl.formatMessage({
		id: "flows.report.overview.bulk_action.modal.delete_reports.value",
		defaultMessage: "DELETE",
	});

	useEffect(() => {
		form.setFieldsValue({ isDeleted: "" });
	}, [showBulkDeleteModal]);

	return (
		<Modal
			mask={true}
			footer={null}
			maskClosable={true}
			open={showBulkDeleteModal}
			onCancel={() => setShowBulkDeleteModal(false)}
			title={intl.formatMessage({
				id: "flows.report.overview.bulk_action.modal.delete_reports.title",
				defaultMessage: "Delete selected reports",
			})}
		>
			<Form
				form={form}
				preserve={false}
				layout="vertical"
				id="bulkChangeStatus"
				onFinish={handleConfirm}
				requiredMark={false}
				initialValues={{ isDeleted: "" }}
			>
				<Form.Item>
					<Alert
						message={intl.formatMessage(
							{
								id: "flows.report.overview.bulk_action.modal.delete_reports.info_message",
								defaultMessage:
									"You're about to delete {totalReportsSelected, plural, one {1 report} other {# reports}}. This action is irreversible and will delete all report data forever.",
							},
							{ totalReportsSelected }
						)}
						type="warning"
						showIcon
					/>
				</Form.Item>
				<Form.Item
					name="isDeleted"
					rules={[
						{
							validator: async (_, value: string) => {
								if (value !== compareValue) {
									const message = intl.formatMessage(
										{
											id: "flows.report.overview.bulk_action.modal.delete_reports.invalid",
											defaultMessage: "Type '{{value}}' in capital case to delete reports",
										},
										{ value: compareValue }
									);

									return Promise.reject(new Error(message));
								}
							},
						},
					]}
					help={intl.formatMessage(
						{
							id: "flows.report.overview.bulk_action.modal.delete_reports.help",
							defaultMessage: "Type {value} in capital case to delete reports",
						},
						{ value: compareValue }
					)}
				>
					<Input
						size="large"
						placeholder={intl.formatMessage(
							{
								id: "flows.report.overview.bulk_action.modal.delete_reports.placeholder",
								defaultMessage: "Type {value}",
							},
							{ value: compareValue }
						)}
					/>
				</Form.Item>

				<div className="delete_reports-footer">
					<Button type="text">
						<FormattedMessage
							id="flows.report.overview.bulk_action.modal.delete_reports.cancel"
							defaultMessage="Cancel"
						/>
					</Button>

					<Form.Item shouldUpdate className="delete_reports-footer-submit">
						{() => {
							const isDisabled =
								!form.isFieldsTouched(true) ||
								!!form.getFieldsError().filter(({ errors }) => errors.length).length;

							return (
								<Button
									type="primary"
									danger
									htmlType="submit"
									loading={isBulkDeleting}
									disabled={isDisabled}
								>
									{" "}
									<FormattedMessage
										id="flows.report.overview.bulk_action.modal.delete_reports.confirm"
										defaultMessage="Delete {totalReportsSelected, plural, one {1 report} other {# reports}}"
										values={{ totalReportsSelected }}
									/>
								</Button>
							);
						}}
					</Form.Item>
				</div>
			</Form>
		</Modal>
	);
}
