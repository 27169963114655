// Use this function before sending html to add our styling to the HTML
// Only styles that are included in the html will be added

const styleMap = {
	"html_editor-paragraph": `{
		margin-block-start: 0px;
		margin-block-end: 0px;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
	}`,
	"html_editor-text-bold": `{
		font-weight: bold;
	}`,
	"html_editor-text-italic": `{
		font-style: italic;
	}`,
	"html_editor-text-underline": `{
		text-decoration: underline;
	}`,
	"html_editor-text-strike_through": `{
		text-decoration: line-through;
	}`,
	"html_editor-text-underline_strike_through": `{
		text-decoration: underline line-through;
	}`,
	"html_editor-link": `{
		color: #6ca4d9;
		text-decoration: underline;
	
		&:hover {
			text-decoration: underline;
		}
	}`,
	"html_editor-h1": `{
		font-size: 2.2rem;
	}`,
	"html_editor-h2": `{
		font-size: 1.8rem;
	}`,
	"html_editor-h3": `{
		font-size: 1.6rem;
		font-weight: normal;
		text-decoration: underline;
	}`,
	"html_editor-ol": `{
		margin: 0.4rem 0.8rem;
		padding-inline-start: 1rem;
	}`,
	"html_editor-ul": `{
		margin: 0.4rem 0.8rem;
		padding-inline-start: 1rem;
	}`,
	"html_editor-nested_list_item": `{
		list-style: none;
		margin: 0rem 0rem;
	}`,
	"html_editor-quote": `{
		margin-top: 1em;
		margin-left: 1em;
		padding-left: 1em;
		border-left: 2px solid #f8c945;
	}`,
	"html_editor-table": `{
		border-collapse: collapse;
		border-spacing: 0;
		max-width: 100%;
		overflow-y: scroll;
		table-layout: fixed;
		width: calc(100% - 25px);
		margin: 1em 0;
		position: relative;
	
		&:nth-child(1) {
			margin-top: 0;
		}
		&:nth-last-child(1) {
			margin-bottom: 0;
		}
	}`,
	"html_editor-table-cell": `{
		border: 1px solid #bbb;
		min-width: 75px;
		vertical-align: top;
		text-align: start;
		padding: 6px 8px;
		position: relative;
		outline: none;
	}`,
	"html_editor-table-cell_header": `{
		background-color: #f2f3f5;
		text-align: start;
	}`,
	"html_editor-code": `{
		display: block;
		background-color: #f8f8f8;
		font-family: Menlo, Consolas, Monaco, monospace;
		margin: 0.5em 0;
		padding: 0.5em;
		padding-left: 52px;
	
		border-radius: 1em;
		line-height: 1.53;
		font-size: 1.4rem;
		tab-size: 4;
		/* white-space: pre; */
		overflow-x: auto;
		position: relative;
	
		&:before {
			content: attr(data-gutter);
			position: absolute;
			top: 0;
			left: 0;
	
			padding: 0.5em;
			background-color: #eee;
			border-right: 1px solid #ccc;
			color: #777;
			white-space: pre-wrap;
			text-align: right;
			min-width: 25px;
		}
	}`,
	"html_editor-token_comment": `{
		color: slategray;
	}`,
	"html_editor-token_punctuation": `{
		color: #999;
	}`,
	"html_editor-token_property": `{
		color: #905;
	}`,
	"html_editor-token_selector": `{
		color: #690;
	}`,
	"html_editor-token_operator": `{
		color: #9a6e3a;
	}`,
	"html_editor-token_attr": `{
		color: #07a;
	}`,
	"html_editor-token_variable": `{
		color: #e90;
	}`,
	"html_editor-token_function": `{
		color: #dd4a68;
	}`,
};

export function applyHtmlStyle({ content }: { content: string }) {
	let styles = ``;

	Object.keys(styleMap).forEach((key) => {
		if (content.includes(key)) {
			styles = `${styles}
			.${key}${styleMap[key]}`;
		}
	});

	return `<style>${styles}</style>${content}`;
}
