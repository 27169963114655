import { timingConfig } from "../config";

let interval, startTimer;

function customTimeoutWatch(callback, timeout) {
	interval = setInterval(() => {
		const duration = new Date() - startTimer;

		if (duration > timeout) {
			callback();
			clearInterval(interval);
			startTimer = undefined;
		}
	}, timingConfig.second);
}

export default {
	startTimer: () => (startTimer = new Date()),
	endTimeout(callback) {
		const duration = new Date() - startTimer;

		if (duration > timingConfig.savingTimeout) {
			callback();
		}

		setTimeout(() => callback(), timingConfig.savingTimeout - duration);
		startTimer = undefined;
	},
	watchTimeout(callback) {
		customTimeoutWatch(callback, timingConfig.serverTimeout);
	},
	customTimeoutWatch,
	clearTimer() {
		clearInterval(interval);
		startTimer = undefined;
	},
};
