import DashboardCard from "@/shared/components/card/dashboard";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { routesConfig } from "@/config/index.js";
import { Button } from "antd";

import "../../style.scss";

export default function RelatedSettings({ slug }: { slug: string }) {
	const intl = useIntl();
	const history = useHistory();

	return (
		<div className="settings_page-suggestions t-gap--top">
			<DashboardCard
				className="settings_page-suggestions-options"
				title={intl.formatMessage({
					id: "settings.contact_import.suggestions.contact_labels.title",
					defaultMessage: "Contact labels",
				})}
			>
				<div className="cardContent">
					<p>
						<FormattedMessage
							id="settings.contact_import.suggestions.contact_labels.description"
							defaultMessage="Segment/group your contacts by contact labels."
						/>
					</p>
					<Button type="default" onClick={() => history.push(`/${slug}/settings/contact/labels`)}>
						<FormattedMessage
							id="settings.contact_import.suggestions.contact_labels.button"
							defaultMessage="Manage labels"
						/>
					</Button>
				</div>
			</DashboardCard>
			<DashboardCard
				className="settings_page-suggestions-options"
				title={intl.formatMessage({
					id: "settings.contact_import.suggestions.integrations.title",
					defaultMessage: "Integrations",
				})}
			>
				<div className="cardContent">
					<p>
						<FormattedMessage
							id="settings.contact_import.suggestions.integrations.description"
							defaultMessage="Integrate with various CRM systems to quickly synchronize contacts."
						/>
					</p>
					<Button type="default" onClick={() => history.push(`/${slug}/settings/integration`)}>
						<FormattedMessage
							id="settings.contact_import.suggestions.integrations.button"
							defaultMessage="Manage integrations"
						/>
					</Button>
				</div>
			</DashboardCard>
		</div>
	);
}
