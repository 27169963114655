import "./style.scss";
import "react-image-crop/dist/ReactCrop.css";

import { Modal, Row, Button } from "antd";
import { FormattedMessage } from "react-intl";
import ReactCrop, { Crop } from "react-image-crop";
import { useState, useRef, useEffect } from "react";

import { debounce } from "@/helpers";
import { cropImage, IImageUploadResult } from "../../../helpers/file.helpers";

interface ICropModal {
	value: IImageUploadResult;
	open: boolean;
	setModal: React.Dispatch<React.SetStateAction<boolean>>;
	onClose: () => void;
	crop?: Partial<Crop>;
	onChange?: ({ file, crop }: { file: File; crop: Crop }) => void;
	onSubmit: (value: File) => void;
	className?: string;
}

export default function CropModal({ value, open, setModal, onChange, onSubmit, onClose, crop, className }: ICropModal) {
	const [_value, setValue] = useState<string>(value?.url as string);
	const imageRef = useRef<HTMLImageElement>();
	const [_crop, setCrop] = useState<Crop>({
		unit: "%",
		width: 60,
		height: 60,
		aspect: 4 / 4,
		x: 20,
		y: 20,
		...crop,
	});

	const handleImageLoad = (image: HTMLImageElement) => {
		imageRef.current = image;
	};

	const handleChange = (crop: Crop) => {
		setCrop(crop);

		if (!onChange) return;

		debounce(async () => {
			if (!imageRef.current) return;
			const file = await cropImage({ crop, image: imageRef.current, fileType: value.type, fileName: value.name });

			onChange({ file, crop });
		});
	};

	const handleSave = async () => {
		if (!imageRef.current) return;

		const file = await cropImage({
			image: imageRef.current,
			fileType: value.type,
			fileName: value.name,
			crop: _crop,
		});

		onSubmit(file);
	};

	useEffect(() => {
		if (!value) return;

		setValue(value.url as string);
	}, [value]);
	useEffect(() => {
		if (!crop) return;

		let height = crop.height || _crop.height;

		if (crop.aspect && !crop.height) {
			// Calculated new crop height based on new aspect ratio
			height = (crop.width || _crop.width) / crop.aspect;
		}

		setCrop({ ..._crop, ...crop, height });
	}, [crop]);

	return (
		<Modal
			maskClosable={false}
			open={open}
			forceRender
			destroyOnClose
			onOk={handleSave}
			onCancel={onClose}
			closeIcon={<div />}
			className={className}
			footer={
				<Row justify="space-between">
					<Button
						type="text"
						htmlType="button"
						onClick={() => {
							setModal(false);
						}}
					>
						<FormattedMessage id="settings.branding.popup.button.cancel" defaultMessage="Cancel" />
					</Button>
					<Button type="primary" htmlType="submit" form="addMember" onClick={handleSave}>
						<FormattedMessage id="settings.branding.popup.button.submit" defaultMessage="Save" />
					</Button>
				</Row>
			}
		>
			<ReactCrop
				crop={_crop}
				src={_value}
				ruleOfThirds={true}
				onChange={handleChange}
				onImageLoaded={handleImageLoad}
				className="upload-image_crop"
			/>
		</Modal>
	);
}

CropModal.defaultProps = {
	crop: {},
	className: "",
};
