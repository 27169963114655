import { ReconciliationOutlined } from "@ant-design/icons";
import { Result } from "antd";
import React from "react";

import DashboardCard from "@/shared/components/card/dashboard";

export default function EmptyStateReports({ title }) {
	return (
		<DashboardCard>
			<Result icon={<ReconciliationOutlined />} title={title} />
		</DashboardCard>
	);
}
