import Tag from "@/shared/components/antdWrappers/Tag";
import { ContactLabelRouterOutput } from "@/trpc";
import { Table, Empty, Typography, Button } from "antd";
import { Fragment, useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { IContactSyncRule } from "@bothive_core/database";

import { fieldTranslations } from "../../CsvImport/config";
import RuleModal from "./RuleModal";

interface RulesTableProps {
	isLoading: boolean;
	fileKeys: string[];
	rules: IContactSyncRule[];
	onChange: (rules: IContactSyncRule[]) => void;
	contactLabels?: ContactLabelRouterOutput["all"]["data"];
}

export default function RulesTable({ rules, fileKeys, isLoading, contactLabels, onChange }: RulesTableProps) {
	const intl = useIntl();
	const [showRuleModal, setShowRuleModal] = useState<boolean>(false);
	const [selectedRuleId, setSelectedRuleId] = useState<number | undefined>();

	const valueI18n = defineMessages({
		EQUAL_TO: {
			id: "contact.import.rules_table.rule.equal_to",
			defaultMessage: "Is equal to <bold>{input}</bold>",
		},
		LESS_THAN: {
			id: "contact.import.rules_table.rule.less_than",
			defaultMessage: "is less then <bold>{input}</bold>",
		},
		IS_DEFINED: {
			id: "contact.import.rules_table.rule.is_defined",
			defaultMessage: "is defined",
		},
		GREATER_THAN: {
			id: "contact.import.rules_table.rule.greater_than",
			defaultMessage: "is greater then <bold>{input}</bold>",
		},
		LESS_THAN_OR_EQUAL: {
			id: "contact.import.rules_table.rule.greater_than_or_equal",
			defaultMessage: "less then or equal to <bold>{input}</bold>",
		},
		GREATER_THAN_OR_EQUAL: {
			id: "contact.import.rules_table.rule.less_than_or_equal",
			defaultMessage: "greater then or equal to <bold>{input}</bold>",
		},
	});

	const handleRowClick = (ruleId: number) => {
		setSelectedRuleId(ruleId);
		setShowRuleModal(true);
	};

	return (
		<div>
			<Button
				type="primary"
				onClick={() => {
					setSelectedRuleId(undefined);
					setShowRuleModal(true);
				}}
			>
				<FormattedMessage id="contact.import.rules_table.labels.add_rule" defaultMessage="Add rule" />
			</Button>
			{rules.length > 0 && (
				<Table
					pagination={false}
					dataSource={rules || []}
					rowClassName="sk-table-row"
					className="t-gap--top-sm ics-contact-sync-modal__rules-table"
					onRow={(rule) => ({ onClick: () => handleRowClick(rule?.id) })}
					locale={{
						emptyText: (
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								imageStyle={{ height: 40 }}
								description={intl.formatMessage({
									id: "contact.import.rules_table.empty",
									defaultMessage: "No rules set yet",
								})}
							/>
						),
					}}
				>
					<Table.Column
						key="name"
						dataIndex={[]}
						title={intl.formatMessage({
							id: "contact.import.rules_table.empty",
							defaultMessage: "Condition",
						})}
						ellipsis={true}
						render={(value: IContactSyncRule) => {
							return value.conditions.map((condition, index) => {
								const inputValue =
									condition.input === "CURRENT_DATE"
										? intl.formatMessage({
												id: "contact.import.rules_table.eid_expiration_date.fixed_value",
												defaultMessage: "day of import",
										  })
										: condition.input;

								return (
									<Fragment key={index}>
										<FormattedMessage
											id="contact.import.rules_table.rule"
											defaultMessage="{type, select, if {Als} or {of} and {en} other {}} <bold>{field}</bold> {value}"
											values={{
												type: condition.type,
												field: intl.formatMessage(fieldTranslations[condition.field]),
												//@ts-ignore
												value: intl.formatMessage(valueI18n[condition.value], {
													input: inputValue,
													bold: (content) => (
														<Typography.Text strong>{content}</Typography.Text>
													),
												}),
												bold: (content) => <Typography.Text strong>{content}</Typography.Text>,
											}}
										/>
										{index < value.conditions.length - 1 && " "}
									</Fragment>
								);
							});
						}}
					/>
					<Table.Column
						key="label"
						dataIndex={[]}
						title={<FormattedMessage id="contact.import.rules_table.label" defaultMessage="Label" />}
						render={(value: IContactSyncRule & { label: string }) => {
							const label = contactLabels?.find(
								(label) => label._id === value.labelId || label._id === value.label
							);

							if (!label) return;

							return (
								<FormattedMessage
									id="contact.import.rules_table.label.content"
									defaultMessage="Add <label>{labelName}</label>"
									values={{
										labelName: label.name,
										label: (content) => <Tag color={label.color}>{content}</Tag>,
									}}
								/>
							);
						}}
					/>
				</Table>
			)}
			<RuleModal
				fileKeys={fileKeys}
				rules={rules || []}
				ruleId={selectedRuleId}
				visible={showRuleModal}
				isLabelLoading={isLoading}
				onChange={(value) => onChange(value as IContactSyncRule[])}
				contactLabels={contactLabels || []}
				onClose={() => {
					setSelectedRuleId(undefined);
					setShowRuleModal(false);
				}}
			/>
		</div>
	);
}
