import { Input as AntInput } from "antd";
import React from "react";
import { FieldControl } from "react-reactive-form";

import { templateConfig } from "../../../config";
import { formHelper } from "../../../helpers";
import { Input as AInput, Dropdown, Toggle } from "../../atoms"; //eslint-disable-line no-unused-vars
import { Input } from "../../molecules"; //eslint-disable-line no-unused-vars

export default function TAbstractForm({ fields, items, disabled, multiLanguage, onCopy, className }) {
	function renderField(field) {
		let rendered;

		switch (field.template) {
			case templateConfig.templates.checkBox:
				rendered = formHelper.generateControlRenderedSideField((props) => (
					<Toggle.AToggleDefault {...props} checked={Boolean(props.value)} />
				));
				break;

			case templateConfig.templates.dropdown:
				rendered = formHelper.generateControlRenderedField((props) => <Dropdown.ADefault {...props} />);
				break;
			case templateConfig.templates.password:
				rendered = formHelper.generateControlRenderedField(
					(props) => <AntInput.Password {...props} size="large" />,
					{
						maxLength: undefined,
					}
				);
				break;
			case templateConfig.templates.tagDropdown:
				rendered = formHelper.generateControlRenderedField((props) => (
					<Input.MTagDropdownInput
						{...props}
						items={items[field.key]}
						value={props.value ? props.value?.map((items) => (items._id ? items._id : items)) || [] : []}
					/>
				));
				break;
			case templateConfig.templates.tagInput:
				rendered = formHelper.generateControlRenderedField((props) => <Input.MTagInput {...props} />);
				break;
			case templateConfig.templates.textArea:
				rendered = formHelper.generateControlRenderedField((props) => {
					const value = typeof props.value === "object" ? props.value[multiLanguage] || "" : props.value;

					return (
						<AInput.ATextArea
							{...props}
							value={value}
							className={`a-input--field-bordered ${props.className}`}
						/>
					);
				});
				break;
			case templateConfig.templates.textField:
				rendered = formHelper.generateControlRenderedField((props) => {
					const value = typeof props.value === "object" ? props.value[multiLanguage] || "" : props.value;

					return (
						<AInput.ATextField
							{...props}
							value={value}
							className={`a-input--field-bordered ${props.className}`}
						/>
					);
				});
				break;
			default:
				break;
		}

		return (
			<FieldControl
				key={field.id}
				name={field.id}
				render={rendered}
				meta={{
					disabled,
					...field,
					multiLanguage,
					items: items && items[field.key],
				}}
			/>
		);
	}
	return <div className={className}>{fields.map((field) => renderField(field))}</div>;
}

TAbstractForm.defaultProps = {
	items: undefined,
	disabled: false,
	multiLanguage: "",
	className: "",
	onCopy: undefined,
};
