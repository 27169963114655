import lodashMerge from "lodash.merge";

import { pageConfig, typesConfig } from "../../../config";
import actionTypes from "./import.actionTypes";

/**
 * Remap a dot.string to an object structure and add a value to the last key
 */
function mapKeyStringToObject(keyString, value) {
	let initObject = {};

	let container = initObject;

	keyString.split(".").forEach((key, index, values) => {
		container = container[key] = index === values.length - 1 ? value : {};
	});

	return initObject;
}

function remapField({ keyString, value }) {
	if (keyString.includes(".")) {
		return mapKeyStringToObject(keyString, value);
	}

	return { [keyString]: value };
}

function remapImportCsvToContact(json, mapping) {
	// Iterate over each contact in the provided JSON.
	const remappedContacts = json.map((content, index) => {
		// For each contact, iterate over each element in the mapping
		let contact = {};

		const mapped = mapping.reduce((prev, curr) => {
			// Custom Option
			if (curr.value === typesConfig.specialIds.custom) {
				return {
					...prev,
					attributes: {
						...prev.attributes,
						[curr.value_custom]: content[curr.field],
					},
				};
			}

			// Remap Object Structures
			const remapObjectFields = remapField({
				keyString: curr.value,
				value: content[curr.field],
			});

			contact = lodashMerge(contact, remapObjectFields);

			return {
				key: `${pageConfig.modal.importContact.indexPrefix}${index}`,
				...prev,
				...contact,
			};
		}, {});

		// TODO: Adding objects to array until properly implemented in the frontend
		// This results in a limited possibility of only one element in each array. Should be fixed!
		if (mapped.address) mapped.address = [mapped.address];
		if (mapped.dependents) mapped.dependents = [mapped.dependents];
		if (mapped.foreignRealEstate) mapped.foreignRealEstate = [mapped.foreignRealEstate];

		return mapped;
	});

	return remappedContacts;
}

export const importMappedFields = ({ json, mapping }) => (dispatch) => {
	const remap = remapImportCsvToContact(json, mapping);

	dispatch({
		type: actionTypes.IMPORT_MAP_FIELDS,
		data: remap,
	});
};

export const resetMappedFields = () => (dispatch) => {
	dispatch({ type: actionTypes.RESET_MAPPED_FIELDS });
};
