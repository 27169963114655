export default {
	apiPermissions: {
		widget: "widget.load",
	},
	authenticationTypes: {
		default: "default",
		oauth2: "OAuth2",
	},
	bannerStatus: {
		error: "error",
		info: "info",
		success: "success",
	},
	cellTypes: {
		boolean: "boolean",
		checkbox: "checkbox",
		clipboard: "clipboard",
		customBoolean: "customBoolean",
		date: "date",
		description: "description",
		icon: "icon",
		button: "button",
		iconButton: "iconButton",
		imageDescription: "imageDescription",
		mrkdwn: "mrkdwn",
		number: "number",
		prefixIcon: "prefixIcon",
		price: "price",
		profile: "profile",
		state: "state",
		string: "string",
		tag: "tag",
		tooltip: "tooltip",
		translatedString: "translatedString",
	},
	channelScopes: {
		calendar: "scopes.calendar",
		email: "scopes.email",
	},
	channelTypes: {
		default: "default",
		email: "email",
		messenger: "messenger",
		whatsApp: "whatsApp",
	},
	conversationStatus: {
		archived: "archived",
		closed: "closed",
		open: "open",
		trashed: "trashed",
		spam: "spam",
		unseen: "unseen",
		seen: "seen",
	},
	conversationTypes: {
		default: "default",
		email: "email",
		facebook: "facebook",
		whatsApp: "whatsApp",
	},
	contactTypes: {
		contact: "contact",
		emailContact: "emailContact",
		linkedContact: "linkedContact",
		visitor: "visitor",
	},
	direction: {
		top: "top",
		bottom: "bottom",
		center: "center",
		left: "left",
		leftStart: "leftStart",
		right: "right",
		horizontal: "horizontal",
		vertical: "vertical",
	},
	downloadTypes: {
		csv: "csv",
		excel: "excel",
		json: "json",
		audio: "audio",
		compression: "compression",
		data: "data",
		image: "image",
		presentation: "presentation",
		pdf: "pdf",
		documents: "documents",
		spreadsheet: "spreadsheet",
		video: "video",
	},
	editor: {
		address: "address",
		code: "code",
		link: "link",
		image: "image",
		paragraph: "paragraph",
		quote: "quote",
		mention: "mention",
		template: "template",
		text: "text",
		orderedList: "ordered_list",
		unorderedList: "unordered_list",
		listItem: "list_item",

		table: {
			cell: "cell",
			row: "row",
			table: "table",
		},

		heading: {
			heading1: "h1",
			heading2: "h2",
			heading3: "h3",
			heading4: "h4",
			heading5: "h5",
			heading6: "h6",
		},
	},
	editorStyle: {
		bold: "bold",
		italic: "italic",
		strikeThrough: "strikeThrough",
		underlined: "underlined",
	},
	fileExtensions: {
		csv: "csv",
		excel: "xlsx",
		json: "json",
		pdf: "pdf",
	},
	fields: {
		string: "string",
		number: "number",
		alphaNumeric: "alphaNumeric",
		date: "date",
		time: "time",
		email: "email",
		phone: "phone",
		vat: "vat",
		iban: "iban",
		bic: "bic",
		postal: "postal",
		country: "country",
		url: "url",
		object: "object",
		textArray: "textArray",
		urlArray: "urlArray",
		emailArray: "emailArray",
		objectArray: "objectArray",
		fileArray: "fileArray",

		boolean: "boolean",
		enum: "enum",
	},
	imageType: {
		image: "image",
		video: "video",
	},
	inboxTypes: {
		channels: "channels",
		default: "default",
		labels: "labels",
		members: "members",
	},
	inboxSystemLabels: {
		archived: "ARCHIVED",
		draft: "DRAFT",
		inbox: "INBOX",
		outbox: "OUTBOX",
		spam: "SPAM",
		trash: "TRASH",
		//core
		assigned: "assigned",
		closed: "closed",
		shared: "shared",
		//status
		delegated: "DELEGATED",
		mentioned: "MENTIONED",
		sent: "SENT",
		pinned: "PINNED",
	},
	inboxMainFilters: {
		assigned: "assigned",
		shared: "shared",
	},
	integrationCategories: {
		accountancy: "5c38a182092c99228ead1a32",
		cms: "5c38a182092c99228ead1a33",
		communication: "5c38a182092c99228ead1a34",
		emailMarketing: "5c38a182092c99228ead1a25",
		invoicing: "5c38a182092c99228ead1a27",
	},
	integrationTypes: {
		adminPulse: "5cdc7149e0f7a731c8b449ca",
		billit: "5cdc7149e0f7a634c8c1f5b4",
		cm: "5cdc7149e0f9a13988c5e27d",
		exact: "5cdc7149e0f7a634c8c1f8a2",
		fidManager: "5cdc7149e0f7a634c8c2f2b6",
		liantis: "61827608ad122c9a4cd8063c",
		mailchimp: "5cdc7149e0f9a13988c1f7b6",
		messenger: "5cdc7149e0f9a13988c6f63a",
		octopus: "5cdc7149e0f7a634c5c226ac",
		silverfin: "5cdc7149e0f9a13988c2f6b5",
		slack: "5cdc7149e0f7a634c3a1f3b7",
		teams: "5f85b8a9edb267e464d81792",
		twinntax: "622875fb5dd6be4f1b87efc6",
		twilio: "5cdc1139e0f9a13948a9a34e",
		yuki: "5cdc7149e0f9a13988c2f5a6",
		organization: "organization",
		user: "user",
	},
	messageTypes: {
		inbound: "inbound",
		outbound: "outbound",
	},
	messageNotificationTypes: {
		assignment: "assignment",
		close: "close",
		liveChat: "livechat",
	},
	notificationTypes: {
		assignConversation: "assignConversation",
		conversationStarted: "conversationStarted",
		liveChat: "liveChat",
		mention: "mention",
		flowAssign: "flowAssign",
		flowReport: "flowReport",
	},
	notificationOptions: [
		{ id: "default", value: "NOTIFICATION_OPTIONS.DEFAULT" },
		{ id: "email", value: "NOTIFICATION_OPTIONS.EMAIL" },
	],
	keyCodes: {
		left: 37,
		up: 38,
		right: 39,
		down: 40,
	},
	languages: ["nl-BE", "en-GB"],
	localizedLanguage: {
		en: "en-GB",
		nl: "nl-BE",
	},
	lockTypes: {
		customizeFont: "customizeFont",
		domains: "domains",
		editIntents: "editIntents",
		viewIntents: "viewIntents",
		flows: "flows",
		integrations: "integrations",
		languages: "languages",
		locations: "locations",
		liveChat: "liveChat",
		triggers: "triggers",
		users: "users",
	},
	operator: {
		equals: "OPERATOR_EQUALS",
		notEquals: "OPERATOR_NOT_EQUALS",
		contains: "OPERATOR_CONTAINS",
		startsWith: "OPERATOR_STARTS_WITH",
		// endsWith: "OPERATOR_ENDS_WITH",
		// doesNotStartsWith: "OPERATOR_DOES_NOT_STARTS_WITH",
		// doesNotEndsWith: "OPERATOR_DOES_NOT_ENDS_WITH",
		// lessThan: "OPERATOR_LESS_THAN",
		// lessThanOrEqual: "OPERATOR_LESS_THAN_OR_EQUAL",
		// biggerThan: "OPERATOR_BIGGER_THAN",
		// biggerThanOrEqual: "OPERATOR_BIGGER_THAN_OR_EQUAL",
		// regex: "OPERATOR_REGEX",
	},
	payloadTypes: {
		audio: "audio",
		assignment: "assignment",
		buttons: "buttons",
		close: "close",
		default: "default",
		email: "email",
		file: "file",
		html: "html",
		image: "image",
		inlineButtons: "inlineButtons",
		invoice: "invoice",
		internal: "internal",
		openingHours: "openingHours",
		upload: "upload",
	},
	recipientType: {
		bcc: "bcc",
		cc: "cc",
		from: "from",
		to: "to",
		replyTo: "replyTo",
	},
	reportType: {
		csv: "csv",
		excel: "excel",
		json: "json",
		pdf: "pdf",
	},
	scopes: {
		calendar: "",
		inbox: "",
	},
	socialMediaButtons: {
		google: "google",
		office365: "office365",
		skip: "skip",
	},
	spacing: {
		right: "right",
		left: "left",
		center: "center",
		spaceBetween: "spaceBetween",
	},
	sender: {
		agent: "agent",
		contact: "contact",
		user: "user",
	},
	sessionState: {
		completed: "COMPLETED",
		inProgress: "IN_PROGRESS",
		inviteSend: "INVITE_SEND",
		created: "CREATED",
		pendingSend: "PENDING_SEND",
	},
	supportTypes: {
		pricing: "pricing",
	},
	statusTypes: {
		begin: "start",
		failed: "failed",
		pending: "pending",
		end: "stop",
		success: "success",
	},
	specialIds: {
		custom: "custom",
		address: "address",
		dependants: "dependants",
	},
	templateOptionsHeader: {
		bold: "bold",
		italic: "italic",
		underlined: "underlined",
		organization: "organization",
		bot: "bot",
		link: "link",
		unorderedList: "unorderedList",
	},
	valueTypes: {
		default: "default",
		email: "email",
		url: "url",
	},
	triggerRule: {
		flow: {
			manual: "flow.manual",
		},
	},
	variable: {
		hostName: "VARIABLE_HOST_NAME",
		path: "VARIABLE_PATH",
		url: "VARIABLE_URL",
		utmCampaign: "VARIABLE_UTM_CAMPAIGN",
		utmContent: "VARIABLE_UTM_CONTENT",
		utmMedium: "VARIABLE_UTM_MEDIUM",
		utmSource: "VARIABLE_UTM_SOURCE",
		utmTerm: "VARIABLE_UTM_TERM",
		queryString: "VARIABLE_QUERY_STRING",
	},
	viewStates: {
		desktop: "desktop",
		mobile: "mobile",
		tablet: "tablet",
	},
	windowState: {
		default: "default",
		fullscreen: "fullscreen",
		minimized: "minimized",
	},
	userType: {
		default: "default",
		powerUser: "powerUser",
	},
	templateTypes: {
		firstSend: "firstSend",
		reminding: "reminding",
	},
};
