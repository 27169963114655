import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Typography } from "antd";

import { defaultValue } from "../../../config";
import { Image } from "../../atoms";

export default function MContactInfo({ firstName, lastName, fullName, avatar, className, onClick, ...contact }) {
	const primaryEmail = contact.email?.find((item) => item?.isPrimary)?.value;
	const primaryPhone = contact.phone?.find((item) => item?.isPrimary)?.value;

	const username = [firstName, lastName].filter(Boolean).join(" ");

	return (
		<article className={`m-article m-article--contact-info ${className}`}>
			<Image.AProfile src={avatar || defaultValue.inbox.siteUserAvatar} />
			<div className="article-body">
				<h3>{fullName || username}</h3>
				<Typography.Text copyable>
					{primaryEmail || primaryPhone || contact.nationalRegistrationNumber}
				</Typography.Text>
			</div>
			<Button type="primary" onClick={onClick} block className="t-gap--top">
				<FormattedMessage id="CONTACT_DETAILS.CONTACT_INFO.BUTTON.SEND_MESSAGE" />
			</Button>
		</article>
	);
}
