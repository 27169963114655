export default {
	isRowClickAllowed({ target }) {
		// match both on current target and parent because user can click on the icon inside the column
		if (
			target.parentElement?.className?.includes?.("style_sortable_handle") ||
			target.className?.includes?.("style_sortable_handle")
		) {
			return false;
		}

		return true;
	},
};
