import "./style.scss";

import { Menu } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import helpers from "./helpers";

export default function Aside({ items }) {
	const location = useLocation();
	const selectedKey = helpers.findActiveRouteConfig({ asideConfig: items, path: location.pathname });

	return (
		<Menu
			selectedKeys={[selectedKey?.id]} // find active tab by using current route
			mode="inline"
			inlineIndent={17}
			className="settings-aside"
		>
			<div>
				{items?.map((group) => (
					<Menu.ItemGroup
						key={group.title}
						title={<FormattedMessage id={group.title} />}
						className="aside-group"
					>
						{group.items.map((item) => (
							<Menu.Item key={item.id} className="aside-button">
								<NavLink to={item.to} exact={true} className="route">
									<FormattedMessage id={item.label} />
								</NavLink>
							</Menu.Item>
						))}
					</Menu.ItemGroup>
				))}
			</div>
		</Menu>
	);
}
