export default {
	title: "SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.TITLE",
	description: "SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.DESCRIPTION",
	permissions: {
		widget: "widget.load",
	},
	fieldConfig: {
		apiKey: {
			label: "SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.FORM.API_KEY.LABEL",
			help: "SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.FORM.API_KEY.HELP",
			error: "SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.FORM.API_KEY.ERROR",
		},
	},
	banner: {
		developerEmail: {
			success: "BANNER.SUCCEEDED.CONTACT_WEB_DEVELOPER",
			fail: "BANNER.FAILED.CONTACT_WEB_DEVELOPER",
		},
	},
	submit: "SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.SUBMIT_BUTTON",
};
