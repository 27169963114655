import "./style.scss";
import { DownloadOutlined, ExclamationCircleOutlined, SendOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import React from "react";
import { Switch, Select } from "antd";
import { ASaveEmail, ASaveUrl } from "@/components/atoms/atoms.link";
import { typesConfig } from "@/config";
import { stringHelpers } from "@/helpers";
import { useIntl } from "react-intl";

const countriesList = require("world_countries_lists/data/countries/_combined/countries.json");

function formatValue({ value, type, config, language }) {
	if (stringHelpers.isEmpty(value)) return "";

	try {
		const formatActions = {
			[typesConfig.fields.number]: formatNumber,
			[typesConfig.fields.date]: formatDate,
			[typesConfig.fields.alphaNumeric]: formatAlphaNumeric,
			[typesConfig.fields.phone]: formatPhone,
			[typesConfig.fields.postal]: formatAlphaNumeric,
			[typesConfig.fields.vat]: formatVat,
			[typesConfig.fields.iban]: formatIban,
			[typesConfig.fields.bic]: formatAlphaNumeric,

			[typesConfig.fields.boolean]: formatBoolean,
			[typesConfig.fields.country]: formatCountry,
			[typesConfig.fields.enum]: formatEnum,
		};

		return formatActions[type] ? formatActions[type](value, language, config) : value;
	} catch (err) {
		return value;
	}
}

/**
 * Remove all characters except numbers.
 */
function filterNumbers(inputString) {
	if (!value) return "";
	return inputString.replace(/\D/g, "");
}

/**
 * Remove all special characters except alphanumeric (letters & numbers) characters
 */
function filterAlphaNumeric(inputString) {
	if (!value) return "";
	return inputString.replace(/[^a-zA-Z0-9]+/g, "");
}

/**
 * Split strings into specific character groups in a given notation form
 */
function splitString({ string, charGroups, notation }) {
	if (!string) return "";

	const parts = charGroups.reduce((prev, charGroup) => `${prev}(.{${charGroup}})`, "");
	const pattern = `^${parts}(.*)$`;
	const regex = new RegExp(pattern, "g");

	return string.replace(regex, notation);
}

function formatNumber(value) {
	if (!value) return "";
	// Remove everything except numbers, dots, comma's
	return value.replace(/[^\d,.]/g, "");
}

function formatPhone(value) {
	if (value.length < 9) return value;

	const replacePlusWithTwoZeros = value.replace(/\+/g, "00");
	const sanitizeValue = filterNumbers(replacePlusWithTwoZeros);
	const splitAndAddSpaces = splitString({
		string: sanitizeValue,
		charGroups: [4, 3],
		notation: "$1 $2 $3",
	});

	return splitAndAddSpaces;
}

function formatVat(value) {
	if (value.length < 9) return value;

	const sanitizedValue = filterAlphaNumeric(value);
	const splitAndAddDots = splitString({
		string: sanitizedValue,
		charGroups: [4, 3],
		notation: "$1.$2.$3",
	});

	return splitAndAddDots.toUpperCase(value);
}

function formatAlphaNumeric(value) {
	const sanitizedValue = filterAlphaNumeric(value);

	return sanitizedValue.toUpperCase();
}

function formatIban(value) {
	if (value.length < 10) return value;

	const sanitizedValue = filterAlphaNumeric(value);
	const splitAndAddSpaces = splitString({
		string: sanitizedValue,
		charGroups: [4, 4],
		notation: "$1 $2 $3",
	});

	return splitAndAddSpaces.toUpperCase();
}

function formatDate(value) {
	if (value.length < 10) return value;

	return format(new Date(value), "dd-MM-yyyy");
}

function checkIfRequired(required, fieldValue) {
	if (!required) return false;

	if (stringHelpers.isEmpty(fieldValue) && fieldValue !== 0) return true;

	return false;
}

function formatBoolean(value) {
	const intl = useIntl();

	return value
		? intl.formatMessage({ id: "boolean.yes", defaultMessage: "Ja" })
		: intl.formatMessage({ id: "boolean.no", defaultMessage: "Nee" });
}
function formatCountry(value, language) {
	const country = countriesList.find((item) => item.alpha2 === value);

	return country[language.slice(0, 2)];
}
function formatEnum(value, language, config) {
	const item = config.data((item) => item.value === value);

	return item[language];
}

// COMPONENTS
const FieldEditor = ({ value, type, meta, config, onChange, isEditing, isRequired }) => {
	const intl = useIntl();
	const required = checkIfRequired(isRequired, value);
	const placeholder = required && intl.formatMessage({ id: "FLOW_DETAIL.FIELDS.REQUIRED.HOVER" });

	const formatAndChange = (event) => {
		const formattedValue = formatValue({
			value: event.target.value,
			language: intl.locale,
			config,
			type,
		});

		onChange({ value: formattedValue, key: meta.location });
		event.target.value = formattedValue;
	};

	return (
		<div data-is-editing={isEditing} data-is-required={required} className="data-preview-row--value">
			{(() => {
				switch (type) {
					case "country": {
						if (!isEditing) {
							const country = countriesList?.find((country) => country.alpha2 === value);

							return (
								<p onClick={onChange} className="data-preview-clickable">
									{country?.[intl.locale]}
								</p>
							);
						}
						return (
							<Select
								showSearch
								defaultValue={value}
								autoComplete="doNotShow"
								className="m-input--select"
								data-is-editing={isEditing}
								data-is-required={required}
								popupMatchSelectWidth={false}
								filterOption={(input, option) =>
									`${option?.label || ""} ${option?.value || ""}`
										.toLowerCase()
										.includes(input.toLowerCase())
								}
							>
								{countriesList.map((country) => (
									<Select.Option
										key={country.alpha2}
										value={country.alpha2}
										label={country[intl.locale]}
									>
										{country[intl.locale]}
									</Select.Option>
								))}
							</Select>
						);
					}
					case "enum": {
						if (!isEditing) {
							const option = config.options?.find((item) => item.value === value);

							return (
								<p onClick={onChange} className="data-preview-clickable">
									{option?.label?.[intl.locale]}
								</p>
							);
						}
						return (
							<Select
								defaultValue={value}
								className="m-input--select"
								data-is-editing={isEditing}
								data-is-required={required}
								popupMatchSelectWidth={false}
								onChange={(value) => onChange({ value, key: meta.location })}
								options={config.options?.map((item) => ({
									value: item.value,
									label: item.label[intl.locale],
								}))}
							/>
						);
					}
					case "boolean": {
						if (!isEditing)
							return (
								<p onClick={onChange} className="data-preview-clickable">
									{formatBoolean(value)}
								</p>
							);

						return (
							<Switch
								defaultChecked={value}
								onChange={(value) => onChange({ value, key: meta.location })}
								checkedChildren={intl.formatMessage({ id: "boolean.yes", defaultMessage: "Ja" })}
								unCheckedChildren={intl.formatMessage({ id: "boolean.no", defaultMessage: "Nee" })}
							/>
						);
					}
					default:
						return (
							<input
								type="text"
								className="m-input--field"
								placeholder={placeholder}
								data-is-editing={isEditing}
								data-is-required={required}
								defaultValue={formatValue({ value, type, language: intl.locale })}
								onChange={(event) => formatAndChange(event)}
								onClick={onChange}
							/>
						);
				}
			})()}
			{required && !isEditing && (
				<span
					className="m-input--field--icon"
					title={intl.formatMessage({ id: "FLOW_DETAIL.FIELDS.REQUIRED.HOVER" })}
				>
					<ExclamationCircleOutlined />
				</span>
			)}
		</div>
	);
};

const FileLink = ({ value, meta, type, onChange, isEditing, isRequired }) => {
	if (!value) return;

	if (typeof value === "string" && !stringHelpers.isUrl(value)) {
		return FieldEditor({ value, meta, type, onChange, isEditing, isRequired });
	}

	let fileName, url;

	if (typeof value !== "string") {
		switch (type) {
			case typesConfig.fields.fileArray:
				url = value.url;
				fileName = value.name;
				break;
			default:
				url = value;
				// Get everything after the last "/" of the string
				fileName = value && value.match(/[^/]+$/g).pop();
		}
	} else {
		url = value;
		// Get everything after the last "/" of the string
		fileName = value && value.match(/[^/]+$/g).pop();
	}

	// Get the last number found in a string (used to find the "#index" in the location string)
	const locationValue = meta.location.match(/(\d+)(?!.*\d)/g);
	const index = Number(locationValue) + 1;

	return (
		<div className="data-preview-row--value">
			<ASaveUrl url={url} className="m-input--field-url">
				{type === typesConfig.fields.url || `#${index}`} {fileName.replace(/^.*__/, "")} <DownloadOutlined />
			</ASaveUrl>
		</div>
	);
};

const MailTo = ({ value, type, meta, onChange, isEditing, isRequired }) => {
	const body = "Powered by Bothive";
	const mailUrl = `${value}?body=${body}`;

	if (isEditing) {
		return FieldEditor({
			value,
			type,
			meta,
			onChange,
			isEditing,
			isRequired,
		});
	}

	return (
		<div data-is-editing={isEditing} className="data-preview-row--value">
			<ASaveEmail url={mailUrl} className="m-input--field-url">
				{value} <SendOutlined />
			</ASaveEmail>
		</div>
	);
};

// renderId prevents react to cache the items when mapping. Otherwise the view will not update while switching report.
export default function CSVViewer({ renderId, items, isEditing, direction, className, onChange }) {
	const isEmail = (type) => type === typesConfig.fields.email || type === typesConfig.fields.emailArray;
	const showEditor = (type) => !isEmail(type) && !isUrl(type);
	const isUrl = (type) =>
		type === typesConfig.fields.url ||
		type === typesConfig.fields.urlArray ||
		type === typesConfig.fields.fileArray;

	return (
		<div data-direction={direction} className={`m-data-preview--csv ${className}`}>
			{items?.map(({ key, value, type, meta, isRequired, ...config }, index) => {
				return (
					<div key={`${key}_${index}_${renderId}`} className="data-preview-row">
						<div style={{ "--data-preview-level": meta.level }} className="data-preview-row--key">
						<p>{key}</p>
						</div>
						{isUrl(type) && FileLink({ value, meta, type, onChange, isEditing, isRequired })}
						{isEmail(type) && MailTo({ value, type, meta, onChange, isEditing, isRequired })}
						{showEditor(type) && FieldEditor({ value, type, config, meta, onChange, isEditing, isRequired })}
					</div>
				)
			})}
		</div>
	);
}
