const progressTypes = {
	created: "CREATED",
	invite_send: "INVITE_SEND",
	in_progress: "IN_PROGRESS",
	completed: "COMPLETED",
};

export default {
	reportProgress: {
		progressTypes,
		progressTypesArray: Object.values(progressTypes),
	},
};
