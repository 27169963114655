import Tag from "@/shared/components/antdWrappers/Tag";
import { trpc } from "@/trpc";
import { SearchOutlined, TagsOutlined } from "@ant-design/icons";
import type { IContactLabel } from "@bothive_core/database";

import { Button, Checkbox, Dropdown, Empty, Form, Input, List, notification, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilValue } from "recoil";
import { selectedAllContactsState, selectedContactsState } from "../state";

interface ContactLabelsProps {
	filters: {
		q?: string;
		contactType?: string;
		labelId?: string;
		accountManagerId?: string;
	};
}

export default function ContactLabels({ filters }: ContactLabelsProps) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const [labelForm] = Form.useForm();
	const searchValue = Form.useWatch<string>("search", labelForm);
	const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
	const selectedContacts = useRecoilValue(selectedContactsState);
	const selectedAll = useRecoilValue(selectedAllContactsState);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement | null>(null);
	const { data: contactLabels, isLoading } = trpc.contactLabel.all.useQuery({ limit: 0 });
	const [displayedLabels, setDisplayedLabels] = useState<IContactLabel[]>([]);

	const bulkMutateContactLabelsMutation = trpc.contact.bulkMutateContactLabels.useMutation({
		onSuccess: (result) => {
			notification.success({
				message: intl.formatMessage(
					{
						id: "CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.SUCCESS",
						defaultMessage:
							"Successfully updated contact labels of {count, plural, one {# contact} other {# contacts}}.",
					},
					{ count: result.nModified }
				),
				placement: "bottomRight",
			});

			trpcUtils.contact.getAllContacts.invalidate();
			setIsOpen(false);
			labelForm.resetFields();
			setSelectedLabels([]);
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.ERROR",
					defaultMessage: "Something went wrong while updating contact labels.",
				}),
				placement: "bottomRight",
			});
		},
	});

	// Close logic
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleClickOutside = (event: MouseEvent) => {
		if (
			!dropdownRef?.current?.contains(event.target as Node) &&
			!document.querySelector("#cts-bulk-actions__contact-labels-dropdown--cl")?.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (!searchValue || searchValue === "") {
			setDisplayedLabels(contactLabels?.data || []);
			return;
		}
		setDisplayedLabels(
			() =>
				contactLabels?.data?.filter((label) =>
					label.name.toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase().trim())
				) || []
		);
	}, [searchValue]);

	useEffect(() => {
		if (displayedLabels.length || searchValue) return;
		setDisplayedLabels(contactLabels?.data || []);
	}, [contactLabels?.data]);

	return (
		<div ref={dropdownRef}>
			<Dropdown
				open={isOpen}
				autoFocus
				destroyPopupOnHide
				trigger={["click"]}
				dropdownRender={() => (
					<Space
						id="cts-bulk-actions__contact-labels-dropdown--cl"
						className="cts-bulk-actions__dropdown-space"
					>
						<Form className="cts-bulk-actions__multi-select-form" form={labelForm}>
							<header>
								<h1 className="t-gap--bottom-xs">
									<FormattedMessage
										id="CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.TITLE"
										defaultMessage="Label as"
									/>
								</h1>
								<Form.Item name="search" noStyle>
									<Input
										placeholder={intl.formatMessage({
											id: "CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.SEARCH_PLACEHOLDER",
											defaultMessage: "Search for contact label",
										})}
										allowClear
										prefix={<SearchOutlined />}
									/>
								</Form.Item>
								<p className="cts-bulk-actions__multi-select-count">
									<FormattedMessage
										id="CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ITEM_SELECTED"
										defaultMessage="{count, plural, =0 {No labels} one {# label} other {# labels}} selected"
										values={{ count: selectedLabels.length }}
									/>
								</p>
							</header>
							<Form.Item noStyle name="labels">
								<List
									loading={isLoading}
									locale={{
										emptyText: (
											<Empty
												image={Empty.PRESENTED_IMAGE_SIMPLE}
												description={intl.formatMessage({
													id: "CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.NOT_FOUND",
													defaultMessage: "No contact labels found",
												})}
											/>
										),
									}}
									size="small"
									bordered={false}
									style={{ maxHeight: "20em", height: "100%", overflow: "auto" }}
									dataSource={displayedLabels}
									renderItem={(item) => (
										<List.Item key={item._id}>
											<Checkbox
												key={item._id}
												checked={selectedLabels.includes(item._id)}
												id={item._id}
												onChange={(event) => {
													if (event.target.checked) {
														setSelectedLabels((prev) => [
															...prev,
															event.target.id as string,
														]);
														return;
													}

													setSelectedLabels((prev) =>
														prev.filter(
															(id) => id !== (event.target.id as unknown as string)
														)
													);
												}}
											>
												<Tag
													title={item.name}
													color={item.color}
													style={{
														maxWidth: "20em",
														textOverflow: "ellipsis",
														overflowX: "hidden",
														whiteSpace: "nowrap",
														display: "block",
													}}
												>
													{item.name}
												</Tag>
											</Checkbox>
										</List.Item>
									)}
								/>
							</Form.Item>
							<div className="cts-bulk-actions__multi-select-actions">
								<Button
									loading={
										bulkMutateContactLabelsMutation.variables?.action === "delete" &&
										bulkMutateContactLabelsMutation.isLoading
									}
									danger
									disabled={
										!selectedLabels?.length ||
										(bulkMutateContactLabelsMutation.isLoading &&
											bulkMutateContactLabelsMutation.variables?.action === "add")
									}
									onClick={() => {
										bulkMutateContactLabelsMutation.mutate({
											action: "delete",
											labelIds: selectedLabels,
											contactIds: selectedContacts,
											mutateAll: selectedAll,
											filters,
										});
									}}
									className="t-gap--right-sm"
									type="primary"
								>
									<FormattedMessage
										id="CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ACTIONS.DELETE"
										defaultMessage="Delete"
									/>
								</Button>
								<Button
									loading={
										bulkMutateContactLabelsMutation.variables?.action === "add" &&
										bulkMutateContactLabelsMutation.isLoading
									}
									disabled={
										!selectedLabels?.length ||
										(bulkMutateContactLabelsMutation.isLoading &&
											bulkMutateContactLabelsMutation.variables?.action === "delete")
									}
									onClick={() => {
										bulkMutateContactLabelsMutation.mutate({
											action: "add",
											labelIds: selectedLabels,
											contactIds: selectedContacts,
											mutateAll: selectedAll,
											filters,
										});
									}}
									type="primary"
								>
									<FormattedMessage
										id="CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ACTIONS.ADD"
										defaultMessage="Add"
									/>
								</Button>
							</div>
						</Form>
					</Space>
				)}
			>
				<Button
					onClick={() => setIsOpen((prev) => !prev)}
					type={isOpen ? "primary" : "text"}
					icon={<TagsOutlined />}
				>
					{" "}
					<FormattedMessage
						id="CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.BUTTON.TITLE"
						defaultMessage="Labels"
					/>
				</Button>
			</Dropdown>
		</div>
	);
}
