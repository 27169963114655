import { $getListDepth, $isListItemNode, $isListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
	$getSelection,
	$isElementNode,
	$isRangeSelection,
	INDENT_CONTENT_COMMAND,
	COMMAND_PRIORITY_HIGH,
	KEY_TAB_COMMAND,
	COMMAND_PRIORITY_EDITOR,
	OUTDENT_CONTENT_COMMAND,
} from "lexical";
import { useEffect } from "react";

function getElementNodesInSelection(selection) {
	const nodesInSelection = selection.getNodes();

	if (!nodesInSelection.length) {
		return new Set([selection.anchor.getNode().getParentOrThrow(), selection.focus.getNode().getParentOrThrow()]);
	}

	return new Set(nodesInSelection.map((n) => ($isElementNode(n) ? n : n.getParentOrThrow())));
}

function isIndentPermitted(maxDepth) {
	const selection = $getSelection();

	if (!$isRangeSelection(selection)) return false;

	const elementNodesInSelection = getElementNodesInSelection(selection);

	let totalDepth = 0;

	elementNodesInSelection.forEach((elementNode: any) => {
		if ($isListNode(elementNode)) {
			totalDepth = Math.max($getListDepth(elementNode) + 1, totalDepth);
			return;
		}

		if ($isListItemNode(elementNode)) {
			const parent = elementNode.getParent();
			if (!$isListNode(parent)) {
				throw new Error("ListMaxIndentLevelPlugin: A ListItemNode must have a ListNode for a parent.");
			}

			totalDepth = Math.max($getListDepth(parent) + 1, totalDepth);
		}
	});

	return totalDepth <= maxDepth;
}

export default function ListMaxIndentLevelPlugin({ maxDepth }) {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		return mergeRegister(
			editor.registerCommand(
				INDENT_CONTENT_COMMAND,
				() => !isIndentPermitted(maxDepth ?? 7),
				COMMAND_PRIORITY_HIGH
			),
			editor.registerCommand<KeyboardEvent>(
				KEY_TAB_COMMAND,
				(event) => {
					const selection = $getSelection();
					if (!$isRangeSelection(selection)) {
						return false;
					}
					event.preventDefault();
					return editor.dispatchCommand(
						event.shiftKey ? OUTDENT_CONTENT_COMMAND : INDENT_CONTENT_COMMAND,
						undefined
					);
				},
				COMMAND_PRIORITY_EDITOR
			)
		);
	}, [editor, maxDepth]);

	return null;
}
