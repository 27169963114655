import "./style.scss";

import React from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";

import { stringHelpers } from "@/shared/helpers";

export default function FieldLabel({
	id = "",
	label = "",
	labelValue = {},
	defaultLabelMessage = "",
	optional = false,
	error = false,
	help = "",
	value = "",
	maxLength = 0,
	className = "",
	children,
	style = {},
}) {
	const { formatMessage } = useIntl();

	let maxLengthText,
		optionalText,
		helpText = <p></p>;

	if (optional) {
		optionalText = (
			<span className="form--optional">
				<FormattedMessage id="FORM_ELEMENT.LABEL.OPTIONAL" />
			</span>
		);
	}

	if (help && help !== "") {
		helpText = (
			<p className="form--help-label">
				<FormattedMessage id={help} values={labelValue} />
			</p>
		);
	}

	if (maxLength && maxLength !== 0) {
		maxLengthText = (
			<p className="form--help-label">
				<FormattedNumber value={stringHelpers.getValueLength(value)} />/
				<FormattedNumber value={maxLength} />
			</p>
		);
	}

	if (error && error !== "") {
		const injectedLabel = label ? label : "FORM_ELEMENT.ERROR.THIS_FIELD";

		helpText = (
			<p className="form--help-label error small">
				<FormattedMessage id={error} values={{ ...labelValue, label: formatMessage({ id: injectedLabel }) }} />
			</p>
		);
	}

	return (
		<div className={`form-element ${className}`} style={style}>
			<label htmlFor={id} className="form--label">
				{label && <FormattedMessage id={label} values={labelValue} defaultMessage={defaultLabelMessage} />}
				{optionalText}
			</label>
			<div className="form--wrapper">{children}</div>
			{(!!help || !!maxLength) && (
				<div className="form--wrapper">
					{helpText}
					{maxLengthText}
				</div>
			)}
		</div>
	);
}
