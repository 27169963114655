import { Button, Form, Modal, notification } from "antd";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import type { IAdminPulseTaskLink } from "@bothive_core/database";

import { trpc } from "../../../../../../../trpc";
import TaskForm from "./taskForm";

interface ICreateTaskLinkModal {
	isOpen: boolean;
	connectionId: string;
	onClose: () => void;
}
export default function CreateTaskLinkModal({ connectionId, isOpen, onClose }: ICreateTaskLinkModal) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const trpcContext = trpc.useContext();
	const addTaskLink = trpc.integrations.crm.adminPulse.addTaskLink.useMutation();

	const handleSubmit = async (link: Omit<IAdminPulseTaskLink, "_id">) => {
		try {
			await addTaskLink.mutateAsync({ connectionId, link });

			onClose();
			trpcContext.integration.getConnection.invalidate({ id: connectionId });
			notification.success({
				message: intl.formatMessage({
					id: "settings.integrations.admin_pulse.tasks.create_new_link.succeeded",
					defaultMessage: "New task link has been created",
					description: "Notification about new task link has been created",
				}),
				placement: "bottomRight",
			});
		} catch (_) {
			notification.error({
				message: intl.formatMessage({
					id: "settings.integrations.admin_pulse.tasks.create_new_link.failed",
					defaultMessage:
						"It seems like the task link can't be created right now. Please give it another shot later. We'll have this sorted out for you soon!",
					description:
						"Error notification about task link that can not be create, user should try again later",
				}),
				placement: "bottomRight",
				duration: 8,
			});
		}
	};

	useEffect(() => {
		form.setFieldsValue({});
	}, []);

	return (
		<Modal
			width={720}
			open={isOpen}
			onCancel={onClose}
			title={intl.formatMessage({
				id: "settings.integrations.admin_pulse.tasks.create_new_link.title",
				defaultMessage: "Create new task link",
				description: "Title of modal where user creates new AdminPulse task link",
			})}
			className="admin_pulse-tasks-create_modal"
			destroyOnClose
			footer={
				<footer className="admin_pulse-webhook-create_modal-footer">
					<Button type="text" onClick={onClose}>
						<FormattedMessage
							id="settings.integrations.admin_pulse.tasks.create_new_link.button.cancel"
							defaultMessage="Cancel"
						/>
					</Button>
					<Button type="primary" htmlType="submit" form="adminPulseLinkForm" loading={addTaskLink.isLoading}>
						<FormattedMessage
							id="settings.integrations.admin_pulse.tasks.create_new_link.button.submit"
							defaultMessage="Create new link"
						/>
					</Button>
				</footer>
			}
		>
			<TaskForm connectionId={connectionId} form={form} onSubmit={handleSubmit} />
		</Modal>
	);
}
