export default {
	REQUEST_DESKTOP_NOTIFICATION_PERMISSION: "REQUEST_DESKTOP_NOTIFICATION_PERMISSION",
	SEND_DESKTOP_NOTIFICATION: "SEND_DESKTOP_NOTIFICATION",

	DESKTOP_NOTIFICATION_DECLINED: "DESKTOP_NOTIFICATION_DECLINED",

	START_BROWSER_TAB_NOTIFICATION: "START_BROWSER_TAB_NOTIFICATION",
	STOP_BROWSER_TAB_NOTIFICATION: "STOP_BROWSER_TAB_NOTIFICATION",

	START_NEW_MESSAGE_NOTIFICATIONS: "START_NEW_MESSAGE_NOTIFICATIONS",
};
