import "./style.scss";

import { Button, Tag, Skeleton, Typography } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Image } from "@/components/atoms";
import { keyEvents } from "@/helpers";

export default function TemplateCard({
	_id,
	title,
	description,
	logo,
	tags,
	label,
	disabled = false,
	comingSoon = false,
	freemium = false,
	loading = false,
	onClick,
	className,
}) {
	const intl = useIntl();
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			handleClick();
		}
	};
	const handleClick = () => {
		if (onClick && !comingSoon && !disabled) {
			onClick(_id);
		}
	};

	return (
		<article
			role="button" //eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
			tabIndex="0"
			data-disabled={disabled || comingSoon}
			onClick={handleClick}
			onKeyUp={handleKeyUp}
			className={`card card-template ${className}`}
		>
			<div className="card-header">
				{tags?.map((tag) => (
					<Tag key={tag.label} color={tag.color} className="a-chip--tag card-chip">
						<FormattedMessage id={tag.label} />
					</Tag>
				))}
				{freemium && (
					<Tag color="blue" className="card-chip">
						<FormattedMessage
							id="shared_components.card.flow_template.freemium_tag"
							defaultMessage="Free"
							description="Tag on flow card that indicates if a flow is free when user wants to create a new flow"
						/>
					</Tag>
				)}
			</div>
			{loading ? (
				<div className="card-content">
					<Skeleton.Avatar active shape="square" size="large" />
					<Skeleton active />
				</div>
			) : (
				<div className="card-content">
					<Image.ADefault src={logo} className="card-image" />
					<div className="card-body">
						<p className="card-title">{title}</p>
						<Typography.Paragraph ellipsis={{ rows: 3 }} className="card-description">
							{description}
						</Typography.Paragraph>
					</div>
				</div>
			)}
			<div className="card-footer">
				{label && !comingSoon && !disabled && (
					<Button size="small" className="card-button">
						<FormattedMessage id={label} />
					</Button>
				)}
				{comingSoon && (
					<Tag className="a-chip--tag card-chip">
						<FormattedMessage id="FLOW_TEMPLATE_MODAL.CARD.CHIP.COMING_SOON" />
					</Tag>
				)}
			</div>
		</article>
	);
}
