import "./style.scss";

import { IdcardOutlined, LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import type { IContactImport, IContactSyncRule, ILinkedContactImport } from "@bothive_core/database";
import { Button, Card, Dropdown, Form, Result, Segmented, Space, Spin, Tooltip } from "antd";
import lodashSet from "lodash.set";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import ContactLabels from "../../../../shared/components/ContactLabelsSelector";
import { trpc } from "../../../../trpc";
import Analytics from "../components/Analytics";
import EditorTable from "../components/EditorTable";
import RulesTable from "../components/LabelRules";
import { StepParams, TableFilters } from "../types";
import { importConfigState, importDataState, importMappingState } from "./state";
import { getKeyFromMapping, reverseMapping } from "./utils";

export default function ReviewImport({ onBack, onClose }: StepParams) {
	const intl = useIntl();
	const history = useHistory();
	const params = useParams<{ team: string }>();
	const [form] = Form.useForm<{ label: string }>();
	const [contactLabel, setContactLabel] = useState();
	const importConfig = useRecoilValue(importConfigState);
	const [rules, setRules] = useState<IContactSyncRule[]>([]);
	const [tableFilters, setFilter] = useState<TableFilters>("ALL");
	const [importData, setImportData] = useRecoilState(importDataState);
	const [importMapping, setImportMapping] = useRecoilState(importMappingState);

	const trpcContext = trpc.useUtils();
	const splitNames = trpc.contact.splitNames.useMutation();
	const analyzeImport = trpc.contact.analyzeImport.useMutation();
	const importContacts = trpc.contact.importContacts.useMutation();
	const importLinkedContacts = trpc.contact.importLinkedContacts.useMutation();
	const { data: contactLabels, isLoading: isLabelLoading } = trpc.contactLabel.all.useQuery({ limit: 0 });

	const columnData = useMemo(
		() =>
			Object.entries(importMapping || {}).map(([from, value]) => ({
				key: value.to,
				title: from,
				dataIndex: from,
			})),
		[importMapping]
	);
	const tableData = useMemo(() => {
		if (!analyzeImport.data) return importData;

		const reverseMap = reverseMapping(importMapping);

		// Map analytics on to table rows
		return importData.map((data) => {
			const errors = {
				hasError: false,
				noIdentifier:
					analyzeImport.data.errors.noIdentifier &&
					!data[reverseMap.email] &&
					!data[reverseMap.phone] &&
					!data[reverseMap.uniqueIdentifier] &&
					!data[reverseMap.nationalRegistrationNumber],
				invalidEmail: reverseMap.email && analyzeImport.data.errors.invalidEmail.has(data[reverseMap.email]),
				invalidPhone: reverseMap.phone && analyzeImport.data.errors.invalidPhone.has(data[reverseMap.phone]),
				invalidNationalRegistrationNumber:
					reverseMap.nationalRegistrationNumber &&
					analyzeImport.data.errors.invalidNationalRegistrationNumber.has(
						data[reverseMap.nationalRegistrationNumber]
					),
				missingName:
					analyzeImport.data.errors.missingName &&
					!data[reverseMap.firstName] &&
					!data[reverseMap.lastName] &&
					!data[reverseMap.fullName],
				noLinkedContactFound: analyzeImport.data.errors.noLinkedContactFound,
				invalidLinkedContactIdentifier: analyzeImport.data.errors.invalidLinkedContactIdentifier,
				missingLinkedContacts:
					analyzeImport.data.errors.missingLinkedContacts &&
					(analyzeImport.data.errors.missingLinkedContacts.has(data[reverseMap["linkedToContact.email"]]) ||
						analyzeImport.data.errors.missingLinkedContacts.has(
							data[reverseMap["linkedToContact.phone"]]
						) ||
						analyzeImport.data.errors.missingLinkedContacts.has(
							data[reverseMap["linkedToContact.uniqueIdentifier"]]
						) ||
						analyzeImport.data.errors.missingLinkedContacts.has(
							data[reverseMap["linkedToContact.nationalRegistrationNumber"]]
						)),
			};
			const warnings = {
				hasWarnings: false,
				duplicateEmail:
					reverseMap.email && analyzeImport.data.warnings.duplicateEmail.has(data[reverseMap.email]),
				duplicatePhone:
					reverseMap.phone && analyzeImport.data.warnings.duplicatePhone.has(data[reverseMap.phone]),
				duplicateId:
					reverseMap.uniqueIdentifier &&
					analyzeImport.data.warnings.duplicateId.has(data[reverseMap.uniqueIdentifier]),
				duplicateNationalRegistrationNumber:
					reverseMap.nationalRegistrationNumber &&
					analyzeImport.data.warnings.duplicateNationalRegistrationNumber.has(
						data[reverseMap.nationalRegistrationNumber]
					),
				unknownAccountManagers: false,
			};

			if (analyzeImport.data.warnings.unknownAccountManagers.size) {
				const splitAM = data[reverseMap.accountManagers].split(/ , | ,|, |,/);

				warnings.unknownAccountManagers = !!splitAM.find((email) =>
					analyzeImport.data.warnings.unknownAccountManagers.has(email.trim())
				);
			}

			errors.hasError = !!Object.values(errors).find(Boolean);
			warnings.hasWarnings = !!Object.values(warnings).find(Boolean);

			return { ...data, errors, warnings, id: data.id as string };
		});
	}, [analyzeImport.data, importData, importMapping]);
	const filteredTableData = useMemo(() => {
		if (tableFilters === "ALL") return tableData;

		return tableData.filter((item) => {
			if (tableFilters === "ERRORS") return item.errors?.hasError;
			if (tableFilters === "WARNINGS") return item.warnings?.hasWarnings;

			return true;
		});
	}, [tableData, tableFilters]);

	const isSplitNameDisabled = useMemo(() => {
		const fullNameMapKey = getKeyFromMapping({ key: "fullName", mapping: importMapping });

		return !fullNameMapKey;
	}, [importMapping]);
	const isSwitchNamesDisabled = useMemo(() => {
		const fNameMapKey = getKeyFromMapping({ key: "firstName", mapping: importMapping });
		const lNameMapKey = getKeyFromMapping({ key: "lastName", mapping: importMapping });

		return !fNameMapKey || !lNameMapKey;
	}, [importMapping]);

	useEffect(() => {
		analyzeContacts();
	}, []);

	const analyzeContacts = () => {
		const fNameKey = getKeyFromMapping({ key: "firstName", mapping: importMapping });
		const lNameKey = getKeyFromMapping({ key: "lastName", mapping: importMapping });
		const fullNameKey = getKeyFromMapping({ key: "fullName", mapping: importMapping });
		const emailKey = getKeyFromMapping({ key: "email", mapping: importMapping });
		const phoneKey = getKeyFromMapping({ key: "phone", mapping: importMapping });
		const nrnKey = getKeyFromMapping({ key: "nationalRegistrationNumber", mapping: importMapping });
		const idKey = getKeyFromMapping({ key: "uniqueIdentifier", mapping: importMapping });
		const accountManagersKey = getKeyFromMapping({ key: "accountManagers", mapping: importMapping });
		let linkedContactEmailKey, linkedContactPhoneKey, linkedContactIdKey, linkedContactNrnKey;

		if (importConfig.type === "linkedContact") {
			linkedContactEmailKey = getKeyFromMapping({ key: "linkedToContact.email", mapping: importMapping });
			linkedContactPhoneKey = getKeyFromMapping({ key: "linkedToContact.phone", mapping: importMapping });
			linkedContactIdKey = getKeyFromMapping({ key: "linkedToContact.uniqueIdentifier", mapping: importMapping });
			linkedContactNrnKey = getKeyFromMapping({
				key: "linkedToContact.nationalRegistrationNumber",
				mapping: importMapping,
			});
		}

		const data = importData.map((item) => {
			const mapping: {
				firstName?: string;
				lastName?: string;
				fullName?: string;
				email?: string;
				phone?: string;
				uniqueIdentifier?: string;
				accountManagers?: string[];
				nationalRegistrationNumber?: string;
				linkedToContact?: {
					email?: string;
					phone?: string;
					nationalRegistrationNumber?: string;
					uniqueIdentifier?: string;
				};
			} = {};

			if (fNameKey) mapping.firstName = item[fNameKey];
			if (lNameKey) mapping.lastName = item[lNameKey];
			if (fullNameKey) mapping.fullName = item[fullNameKey];
			if (emailKey) mapping.email = item[emailKey];
			if (phoneKey) mapping.phone = item[phoneKey];
			if (nrnKey) mapping.nationalRegistrationNumber = item[nrnKey];
			if (idKey) mapping.uniqueIdentifier = item[idKey];
			if (accountManagersKey) mapping.accountManagers = item[accountManagersKey].split(/ , | ,|, |,/);
			if (importConfig.type === "linkedContact") {
				mapping.linkedToContact = {};
				if (linkedContactEmailKey) mapping.linkedToContact.email = item[linkedContactEmailKey];
				if (linkedContactPhoneKey) mapping.linkedToContact.phone = item[linkedContactPhoneKey];
				if (linkedContactIdKey) mapping.linkedToContact.uniqueIdentifier = item[linkedContactIdKey];
				if (linkedContactNrnKey) mapping.linkedToContact.nationalRegistrationNumber = item[linkedContactNrnKey];
			}

			return mapping;
		});

		if (!data?.length) return;

		analyzeImport
			.mutateAsync({ type: importConfig.type, data })
			.catch((error) => console.error("Import analyze failed", error));
	};

	const handleSplitNames = async ({ order }) => {
		let fNameMapKey = getKeyFromMapping({ key: "firstName", mapping: importMapping });
		let lNameMapKey = getKeyFromMapping({ key: "lastName", mapping: importMapping });
		const nameMapKey = getKeyFromMapping({ key: "fullName", mapping: importMapping });

		if (!nameMapKey) return;

		const list = importData
			.map((item) => ({
				id: item.id,
				fullName: item[nameMapKey],
			}))
			.filter((item) => item.fullName?.length);

		const result = await splitNames.mutateAsync({ order, list });

		if (!fNameMapKey) {
			// if fname is not set, set it
			setImportMapping((prev) => ({ ...prev, firstName: { to: "firstName" } }));
			fNameMapKey = "firstName";
		}
		if (!lNameMapKey) {
			// if lname is not set, set it
			setImportMapping((prev) => ({ ...prev, lastName: { to: "lastName" } }));
			lNameMapKey = "lastName";
		}

		setImportData((prev) =>
			prev.map((data) => {
				const split = result.find((item) => item.id === data.id);

				if (!split) return data;

				return { ...data, [`${fNameMapKey}`]: split.firstName, [`${lNameMapKey}`]: split.lastName };
			})
		);
	};

	const handleSwitchNames = () => {
		const fNameMapKey = getKeyFromMapping({ key: "firstName", mapping: importMapping });
		const lNameMapKey = getKeyFromMapping({ key: "lastName", mapping: importMapping });

		if (!fNameMapKey || !lNameMapKey) return;

		setImportData((prev) =>
			prev.map((item) => ({
				...item,
				[fNameMapKey]: item[lNameMapKey],
				[lNameMapKey]: item[fNameMapKey],
			}))
		);
	};

	const handleUpload = async () => {
		try {
			type FormattedData = (IContactImport | ILinkedContactImport)[];

			const { label } = form.getFieldsValue();
			const formattedData = importData.map((data) =>
				Object.entries(importMapping).reduce((prev, [from, mapping]) => {
					let value = data[from];

					switch (true) {
						case mapping.to === "accountManagers":
							value = value.split(/ , | ,|, |,/);
							break;
						case value === "Invalid Date":
							// Remove invalid dates
							return prev;
						case mapping.to.includes("."):
							return lodashSet(prev, mapping.to, value);
					}

					return { ...prev, [mapping.to]: value };
				}, {})
			) as FormattedData;

			// mapping to support other components
			const formatRules = rules.map((rule) => ({
				conditions: rule.conditions.map((item) => ({
					field: item.field,
					type: item.type,
					action: item.value,
					value: item.input,
				})),
				label: rule.labelId || rule.label,
			}));

			if (importConfig.type === "customer") {
				await importContacts.mutateAsync({
					label,
					rules: formatRules,
					data: formattedData,
					allowCreate: importConfig.allowCreate,
					allowUpdate: importConfig.allowUpdate,
				});
			} else {
				await importLinkedContacts.mutateAsync({
					label,
					rules: formatRules,
					allowCreate: importConfig.allowCreate,
					allowUpdate: importConfig.allowUpdate,
					linkedContactType: importConfig.linkedContactType,
					data: formattedData as ILinkedContactImport[],
				});
			}

			trpcContext.contact.getAllContacts.invalidate();
		} catch (error) {
			console.error("Import failed", error);
		}
	};

	const handleFinish = () => {
		history.push(`/${params.team}/contact`);
		trpcContext.contact.getAllContacts.invalidate();
		onClose();
	};

	if (analyzeImport.isError) {
		return (
			<Result
				status="error"
				className="contact_import-content-status"
				title={intl.formatMessage({
					id: "contact.import.csv.review_import.analyze_failed.title",
					defaultMessage: "Import could not be analyzed",
				})}
				subTitle={intl.formatMessage({
					id: "contact.import.csv.review_import.analyze_failed.description",
					defaultMessage:
						"We could not analyze your file. Please review your file for corrupted or missing fields and try again",
				})}
				extra={
					<Button type="primary" onClick={() => onBack("GET_DATA")}>
						<FormattedMessage
							id="contact.import.csv.review_import.analyze_failed.button"
							defaultMessage="Try again"
						/>
					</Button>
				}
			/>
		);
	}
	if (analyzeImport.isLoading) {
		return (
			<div className="contact_import-content-loader">
				<Spin
					size="large"
					tip={intl.formatMessage({
						id: "contact.import.csv.review_import.loading",
						defaultMessage: "We are analyzing your import, this can take a moment",
					})}
				>
					<div />
				</Spin>
			</div>
		);
	}
	if (importContacts.isError || importLinkedContacts.isError) {
		return (
			<Result
				status="error"
				className="contact_import-content-status"
				title={intl.formatMessage({
					id: "contact.import.csv.review_import.import_failed.title",
					defaultMessage: "Import failed",
				})}
				subTitle={intl.formatMessage({
					id: "contact.import.csv.review_import.import_failed.description",
					defaultMessage:
						"The import failed. Please try again later. If the problem persists, please contact support.",
				})}
				extra={
					<Button type="primary" onClick={() => onBack("GET_DATA")}>
						<FormattedMessage
							id="contact.import.csv.review_import.import_failed.button"
							defaultMessage="Try again"
						/>
					</Button>
				}
			/>
		);
	}
	if (importContacts.isLoading || importLinkedContacts.isLoading) {
		return (
			<Result
				icon={<LoadingOutlined />}
				className="contact_import-content-status"
				title={intl.formatMessage({
					id: "contact.import.csv.review_import.is_importing.title",
					defaultMessage: "Import started",
				})}
				subTitle={intl.formatMessage({
					id: "contact.import.csv.review_import.is_importing.description",
					defaultMessage: "We're currently importing your contacts, this process may take a few moments.",
				})}
			/>
		);
	}
	if (importContacts.isSuccess || importLinkedContacts.isSuccess) {
		return (
			<Result
				status="success"
				className="contact_import-content-status"
				title={intl.formatMessage({
					id: "contact.import.csv.review_import.import_succeeded.title",
					defaultMessage: "Your contacts are imported",
				})}
				subTitle={intl.formatMessage({
					id: "contact.import.csv.review_import.import_succeeded.description",
					defaultMessage: "All your contacts have been imported, you can view them under your contacts",
				})}
				extra={
					<Button type="primary" onClick={handleFinish}>
						<FormattedMessage
							id="contact.import.csv.review_import.import_succeeded.button"
							defaultMessage="Close import"
						/>
					</Button>
				}
			/>
		);
	}

	const totalNewContacts =
		importData?.length - (analyzeImport.data?.errors?.total || 0) - (analyzeImport.data?.totalUpdatedContacts || 0);
	const totalValidContacts = totalNewContacts + (analyzeImport.data?.totalUpdatedContacts || 0);

	return (
		<Form
			form={form}
			preserve={false}
			layout="vertical"
			onFinish={handleUpload}
			initialValues={importConfig}
			className="contact_import-content_wrapper contact_import-review"
		>
			<Card
				title={intl.formatMessage({
					id: "contact.import.csv.review_import.title",
					defaultMessage: "Review import",
				})}
				className="contact_import-content-card"
				extra={
					<Button type="primary" onClick={analyzeContacts}>
						<FormattedMessage
							id="contact.import.csv.review_import.re_analyze"
							defaultMessage="Re-analyze contacts"
						/>
					</Button>
				}
			>
				<Analytics
					total={importData?.length}
					analytics={analyzeImport.data}
					isLoading={analyzeImport.isLoading}
					allowCreate={importConfig.allowCreate}
					allowUpdate={importConfig.allowUpdate}
				/>
			</Card>
			<Card
				title={intl.formatMessage({
					id: "contact.import.csv.review_import.analytics.toolbar.title",
					defaultMessage: "Import preview",
				})}
				className="contact_import-content-card"
				extra={
					<Segmented
						value={tableFilters}
						onChange={(value) => setFilter(value as TableFilters)}
						options={[
							{
								label: intl.formatMessage({
									id: "contact.import.csv.review_import.filters.show_all",
									defaultMessage: "Show all",
								}),
								value: "ALL",
							},
							{
								label: intl.formatMessage({
									id: "contact.import.csv.review_import.filters.warnings",
									defaultMessage: "Only show warnings",
								}),
								value: "WARNINGS",
							},
							{
								label: intl.formatMessage({
									id: "contact.import.csv.review_import.filters.errors",
									defaultMessage: "Only show errors",
								}),
								value: "ERRORS",
							},
						]}
					/>
				}
			>
				<header className="contact_import-review-toolbar-actions">
					<Tooltip
						title={intl.formatMessage({
							id: "contact.import.csv.review_import.analytics.toolbar.tooltip",
							defaultMessage: "Split names only work if full name is mapped",
						})}
						trigger={isSplitNameDisabled ? ["hover"] : []}
					>
						<Dropdown
							menu={{
								items: [
									{
										type: "group",
										label: intl.formatMessage({
											id: "contact.import.csv.review_import.analytics.toolbar.split_name.title",
											defaultMessage: "In which order are the names set",
										}),
										children: [
											{
												key: "fl",
												label: intl.formatMessage({
													id: "contact.import.csv.review_import.analytics.toolbar.split_name.option.fl",
													defaultMessage: "<First name> <last name>",
												}),
											},
											{
												key: "lf",
												label: intl.formatMessage({
													id: "contact.import.csv.review_import.analytics.toolbar.split_name.option.lf",
													defaultMessage: "<Last name> <first name>",
												}),
											},
										],
									},
								],
								onClick: (event: any & { key: string }) => handleSplitNames({ order: event.key }),
							}}
							disabled={isSplitNameDisabled}
						>
							<Button
								type="text"
								loading={splitNames.isLoading}
								icon={<IdcardOutlined className="t-gap--right-xs" />}
							>
								{" "}
								<FormattedMessage
									id="contact.import.csv.review_import.analytics.toolbar.split_name"
									defaultMessage="Split names"
								/>
							</Button>
						</Dropdown>
					</Tooltip>
					<Tooltip
						title={intl.formatMessage({
							id: "contact.import.csv.review_import.analytics.switch_names.tooltip",
							defaultMessage: "Switch names only work if both first name and last name are mapped",
						})}
						trigger={isSwitchNamesDisabled ? ["hover"] : []}
					>
						<Button
							type="text"
							onClick={handleSwitchNames}
							disabled={isSwitchNamesDisabled}
							icon={<SyncOutlined className="t-gap--right-xs" />}
						>
							<FormattedMessage
								id="contact.import.csv.review_import.analytics.toolbar.switch_name"
								defaultMessage="Switch names"
							/>
						</Button>
					</Tooltip>
				</header>
				<EditorTable
					columns={columnData}
					dataSource={filteredTableData}
					onChange={({ id, data }) =>
						setImportData((prev) => prev.map((item) => (item.id === id ? data : item)))
					}
				/>
			</Card>
			<Card
				title={intl.formatMessage({
					id: "contact.import.csv.review_import.analytics.labels.title",
					defaultMessage: "Add labels",
				})}
				className="contact_import-content-card"
			>
				<Space direction="vertical" size="large" className="contact_import-review">
					<Form.Item
						id="label"
						name="label"
						label={intl.formatMessage({
							id: "contact.import.csv.review_import.analytics.labels.general.label",
							defaultMessage: "Add label to contacts",
						})}
						help={intl.formatMessage({
							id: "contact.import.csv.review_import.analytics.labels.general.help",
							defaultMessage:
								"Hint: with labels you can find and edit all contacts from a specific import",
						})}
					>
						<ContactLabels
							onChange={contactLabel}
							isLabelLoading={isLabelLoading}
							contactLabels={contactLabels?.data}
							onClear={() => setContactLabel(undefined)}
							showAddButton
						/>
					</Form.Item>
					<RulesTable
						rules={rules}
						onChange={setRules}
						isLoading={isLabelLoading}
						contactLabels={contactLabels?.data}
						fileKeys={Object.values(importMapping).map((item) => item.to)}
					/>
					<footer className="contact_import-content-footer">
						<Button type="text" onClick={() => onBack()}>
							<FormattedMessage id="contact.import.csv.review_import.footer.back" defaultMessage="Back" />
						</Button>
						<Form.Item shouldUpdate className="contact_import-content-footer-submit">
							{() => {
								const isDisabled =
									!!form.getFieldsError().filter(({ errors }) => errors.length).length ||
									!totalValidContacts;

								return (
									<Tooltip
										title={intl.formatMessage({
											id: "contact.import.csv.review_import.footer.import.tooltip",
											defaultMessage: "Edit and re-analyze your contacts",
										})}
										trigger={isDisabled ? ["hover"] : []}
									>
										<Button
											type="primary"
											htmlType="submit"
											disabled={isDisabled}
											loading={
												importContacts.isLoading ||
												importLinkedContacts.isLoading ||
												splitNames.isLoading
											}
										>
											<FormattedMessage
												id="contact.import.csv.review_import.footer.import"
												defaultMessage="Import contacts"
											/>
										</Button>
									</Tooltip>
								);
							}}
						</Form.Item>
					</footer>
				</Space>
			</Card>
		</Form>
	);
}
