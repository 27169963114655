export default {
	adminPulse: {
		// Values calculated from backend remapping at fetch contacts, this are all fields where the value is not an object
		contactImportKeys: [
			"type",
			"id",
			"name",
			"email",
			"language",
			"firstName",
			"uniqueIdentifier",
			"phone",
			"nationalRegistrationNumber",
			"eid.expirationData",
			"idCardNumber",
			"idCardNumberExpiryDate",
		],
	},
	twinntax: {
		// Values calculated from backend remapping at fetch contacts, this are all fields where the value is not an object
		contactImportKeys: [
			"id",
			"uniqueIdentifier",
			"type",
			"fullName",
			"firstName",
			"lastName",
			"avatar",
			"nationalRegistrationNumber",
			// "hasDisability", type boolean not supported yet
		],
	},
};
