import React from "react";
import { FormattedMessage } from "react-intl";

export default function ANotFoundSmall({ label, className }) {
	return (
		<section className={`a-fallback ${className}`}>
			<p>
				<FormattedMessage id={label} />
			</p>
		</section>
	);
}
