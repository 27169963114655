import { Alert, AlertProps, Button, Form, Input, Modal } from "antd";
import { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import helper from "./helper";
import style from "./style.module.scss";

interface ArchiveModalProps {
	title: string;
	description: string;
	placeholder?: string;
	archiveLabel: string;
	isVisible: boolean;
	isExecuting: boolean;
	children: ReactNode;
	descriptionType?: AlertProps["type"];
	showDescriptionIcon?: boolean;
	onClose: () => void;
	onDelete: () => void;
}

export default function ArchiveModal({
	title,
	description,
	archiveLabel,
	placeholder,
	children,
	isVisible,
	isExecuting,
	onClose,
	onDelete,
	descriptionType,
	showDescriptionIcon = false,
}: ArchiveModalProps) {
	const intl = useIntl();

	return (
		<Modal open={isVisible} title={title} destroyOnClose onOk={onDelete} onCancel={onClose} footer={null}>
			{children}
			{descriptionType ? (
				<Alert message={description} type={descriptionType} showIcon={showDescriptionIcon} />
			) : (
				<p>{description}</p>
			)}
			<Form>
				<Form.Item
					name="confirm"
					rules={[
						() => ({
							validator(_, value) {
								return helper.archiveValueValidator({ value, intl });
							},
						}),
					]}
					className="t-gap--top-sm"
				>
					<Input placeholder={placeholder && placeholder} />
				</Form.Item>
				<footer className={style.delete_modal_footer}>
					<Form.Item shouldUpdate className={style.delete_modal_submit}>
						{(form) => {
							const hasErrors =
								!form.isFieldsTouched() || form.getFieldsError().some(({ errors }) => errors.length);

							return (
								<Button
									danger
									type="primary"
									htmlType="submit"
									loading={isExecuting}
									disabled={hasErrors}
									onClick={onDelete}
									className={style.delete_modal_submit_button}
								>
									{archiveLabel}
								</Button>
							);
						}}
					</Form.Item>
					<Button type="text" onClick={onClose}>
						<FormattedMessage id="POPUP.BUTTON.CANCEL" defaultMessage="Cancel" />
					</Button>
				</footer>
			</Form>
		</Modal>
	);
}
