import dayjs from "dayjs";
import { DatePicker, Form, FormInstance } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { atom, useRecoilValue } from "recoil";

export const SendFirstAfterDaysState = atom<number>({
	key: "sendFirstAfterDaysState",
	default: 7,
});

export default function StopForm({ form }: { form: FormInstance }) {
	const intl = useIntl();

	const [minDate, setMinDate] = useState(dayjs().startOf("day"));
	const firstReminderInterval = useRecoilValue(SendFirstAfterDaysState);
	const sendAfterDays = form.getFieldValue("sendFirstAfterDays");

	useEffect(() => {
		const newMinDate = dayjs().startOf("day").add(sendAfterDays, "days");

		setMinDate(newMinDate);
	}, [form, firstReminderInterval]);

	return (
		<div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
			<p>
				<FormattedMessage
					id="FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_1.CUTOFF_DATE.1"
					defaultMessage="Stop sending reminders after"
				/>
			</p>
			<Form.Item
				name={["cutoffDate"]}
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_1.CUTOFF_DATE.REQUIRED",
							defaultMessage: "Please select a cutoff date",
						}),
					},
					({ getFieldValue }) => ({
						validator(_, value) {
							const minDate = dayjs().startOf("day").add(firstReminderInterval, "days");

							if (value && value.isBefore(minDate, "day")) {
								return Promise.reject(
									intl.formatMessage(
										{
											id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_1.CUTOFF_DATE.MIN_DATE",
											defaultMessage:
												"Cutoff date must be at least {firstReminderInterval} days after the first reminder is sent.",
										},
										{ firstReminderInterval: firstReminderInterval }
									)
								);
							}
							return Promise.resolve();
						},
					}),
				]}
				style={{ marginBottom: 0 }}
			>
				<DatePicker
					format="DD/MM/YYYY"
					allowClear={false}
					disabledDate={(date) => {
						if (date.isBefore(dayjs().startOf("day"))) {
							return true;
						}

						if (minDate && date.isBefore(minDate, "day")) {
							return true;
						}

						return false;
					}}
					style={{ width: "135px" }}
				/>
			</Form.Item>
		</div>
	);
}
