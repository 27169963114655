import React from "react";
import { FormattedMessage } from "react-intl";

import { AccordionHeader } from "../../molecules";

export default function ODefault({ id, label, description, expanded, className, onClick, children }) {
	const handleClick = () => {
		onClick(id);
	};

	return (
		<article className={`o-accordion ${expanded ? "active" : ""} ${className}`}>
			<AccordionHeader.MDefault id={id} label={label} expanded={expanded} onClick={handleClick} />
			{expanded && description && (
				<p className="accordion-description">
					<FormattedMessage id={description} />
				</p>
			)}
			{expanded && children}
		</article>
	);
}

ODefault.defaultProps = {
	description: undefined,
	expanded: false,
	className: "",
	onClick: () => undefined,
};
