export default {
	salesPage: process.env.REACT_APP_SALES_PAGE_BASE_URL || "https://www.bothive.be", //eslint-disable-line no-undef
	root: "/",
	auth2: {
		root: "/auth",
		success: "/auth/success",
	},
	billing: {
		root: "/:team/billing/plan",
		payment: "/:team/billing/plan/payment",
		pricing: "/:team/billing/plan/pricing",
	},
	dashboard: {
		root: "/:team",
		billing: "/:team/billing",
		botDefaultResponse: "/:team/chatbot/default-response",
		triggers: "/:team/chatbot/triggers",
		triggersDetail: "/:team/chatbot/triggers/:triggerId",
		contact: {
			overview: "/:team/contact",
			archive: "/:team/contact/archive",
			import: { report: "/:team/contact/import/id/:contactImportId" },
			detail: {
				root: "/:team/contact/id/:contactId",
				settings: "/:team/contact/id/:contactId/settings",
				attributes: "/:team/contact/id/:contactId/attributes",
				conversations: "/:team/contact/id/:contactId/conversations",
				analytics: "/:team/contact/id/:contactId/analytics",
				reports: "/:team/contact/id/:contactId/reports",
				files: "/:team/contact/id/:contactId/files",
				logs: "/:team/contact/id/:contactId/logs",
			},
		},
		flow: {
			root: "/:team/flows",
			overview: "/:team/flows",
			detail: "/:team/flows/:flowId",
			reports: "/:team/flows/:flowId/reports",
			reportDetail: "/:team/flows/:flowId/reports?report_id=:reportId",
			configuration: "/:team/flows/:flowId/configuration",
			statusTypes: "/:team/flows/:flowId/status_types",
			settings: "/:team/flows/:flowId/settings",
		},
		intents: {
			intents: "/:team/question-settings/intents",
			intentsOverview: "/:team/question-settings/intents/:categoryId",
			intentsDetail: "/:team/question-settings/intents/:categoryId/:intentId",
		},
		inbox: {
			root: "/:team/inbox",
			folder: "/:team/inbox/folder/:folder/:conversationId?",
			labelStatus: "/:team/inbox/label/:label/status/:status/:conversationId?",
			status: "/:team/inbox/folder/:folder/status/:status/:conversationId?",
		},
		documentation: "/:team/documentation",
		notifications: "/:team/notifications",
		settings: {
			root: "/:team/settings",
			apiKey: {
				overview: "/:team/settings/api_key",
				detail: "/:team/settings/api_key/:apiKeyId",
			},
			agentSettings: "/:team/settings/virtuele_assistent",
			branding: {
				main: "/:team/settings/branding",
				widget: "/:team/settings/widget",
			},
			contact: {
				overview: "/:team/settings/contact",
				labels: "/:team/settings/contact/labels",
			},
			members: {
				overview: "/:team/settings/members",
			},
			organization: {
				general: "/:team/settings/organization/general",
				information: "/:team/settings/organization/information",
				openingHours: "/:team/settings/organization/opening_hours",
			},
			intents: {
				overview: "/:team/settings/intents",
			},
			integrations: {
				overview: "/:team/settings/integration",
				detail: "/:team/settings/integration/:slug",
				adminPulseIntegration: "/:team/settings/integration/admin_pulse/:connectionId?",
				billitIntegration: "/:team/settings/integration/billit/:connectionId?",
				cmIntegration: "/:team/settings/integration/cm/:connectionId?",
				exactIntegration: "/:team/settings/integration/exact/:connectionId?",
				liantisIntegration: "/:team/settings/integration/liantis/:connectionId?",
				fidManagerIntegration: "/:team/settings/integration/fid_manager/:connectionId?",
				mailchimpIntegration: "/:team/settings/integration/mailchimp/:connectionId?",
				messengerIntegration: "/:team/settings/integration/facebook_messenger/:connectionId?",
				octopusIntegration: "/:team/settings/integration/octopus/:connectionId?",
				silverfinIntegration: "/:team/settings/integration/silverfin/:connectionId?",
				slackIntegration: "/:team/settings/integration/slack/:connectionId?",
				twilioIntegration: "/:team/settings/integration/twilio/:connectionId?",
				yukiIntegration: "/:team/settings/integration/yuki/:connectionId?",
				teamsIntegration: "/:team/settings/integration/teams/:connectionId?",
				twinntaxIntegration: "/:team/settings/integration/twinntax/:connectionId?",
			},
			inbox: {
				channels: {
					overview: "/:team/settings/inbox/channels",
					detail: "/:team/settings/inbox/channels/:channelId",
				},
			},
			triggers: {
				overview: "/:team/settings/triggers",
				detail: "/:team/settings/triggers/:triggerId",
			},
		},
		support: "/:team/support",
		supportRequestParameter: "/:team/support?requestType=:request",
	},
	portal: {
		root: "/portal",
		deactivatedTeam: "/portal/:team/deactivated",
		login: "/portal/login",
		resetPassword: "/portal/reset-password",
		signUp: {
			root: "/portal/signup",
			welcomeUser: "/portal/signup/welcome",
			initialization: "/portal/signup/initialization",
		},
		createTeam: {
			root: "/portal/create_team",
			platform: "/portal/create_team/platform",
			companyInfo: "/portal/create_team/company-info",
			termsAndConditions: "/portal/create_team/terms-and-conditions",
		},
	},
	profile: {
		root: "/profile",
		overview: "/profile/:slug",
		teams: "/profile/:slug/teams",
		advancedSettings: "/profile/:slug/advanced-settings",
	},
};
