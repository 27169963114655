import * as OAccordion from "./organisms.accordion";
import * as OAside from "./organisms.aside";
import * as OHandler from "./organisms.handler";
import * as OHeader from "./organisms.header";
import * as OPopup from "./organisms.popup";
import * as OSection from "./organisms.section";

export const Accordion = OAccordion;
export const Aside = OAside;
export const Handler = OHandler;
export const Header = OHeader;
export const Popup = OPopup;
export const Section = OSection;
