import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Layout } from "@/components/templates";
import { routesConfig } from "@/config";
import settingsConfig from "@/modules/settings/config";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import Collapse from "@/shared/components/collapse";
import { actions as dashboardActions } from "@/store/dashboard";
import { ApiKeyManagerRouterOutput, trpc } from "@/trpc";
import { EPermissionKey } from "@bothive_core/database";
import Destructive from "./destructive";
import Domains from "./domains";
import MainForm from "./main_form";
import Permissions from "./permissions";
import WarningBanner from "./warning_banner";

export default function ApiKeyDetail({ className }: { className?: string }) {
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const trpcUtils = trpc.useUtils();
	const routeParameters = useParams<{ team: string; apiKeyId: string }>();

	const { data: apiKey, isLoading: isApiLoading } = trpc.apiKeyManager?.getById?.useQuery({
		id: routeParameters.apiKeyId,
	});

	const { data: permissions, isLoading: isPermissionsLoading } = trpc.apiKeyManager?.getPermissions?.useQuery();

	const updateApiKeyMutation = trpc.apiKeyManager.updateApiKey.useMutation();

	const updateApiKey = (apiKey: ApiKeyManagerRouterOutput["getById"]) => {
		if (!apiKey) return;
		updateApiKeyMutation.mutate(apiKey, {
			onSuccess: () => {
				trpcUtils.apiKeyManager.invalidate();
				ShowSuccessBanner({
					title: intl.formatMessage({
						id: "banner.succeeded.update_api_key",
						defaultMessage: "API key is successfully updated.",
					}),
				});
			},
			onError: () => {
				ShowFailedBanner({
					title: intl.formatMessage({
						id: "banner.failed.update_api_key",
						defaultMessage: "API key could not be updated. Please try again later.",
					}),
				});
			},
		});
	};

	const state = useSelector((state) => ({
		language: state.dashboard.header.lang.slice(0, 2),
		allowViewing: state.dashboard.permissions.userPermissions.editSettings,
	}));

	useEffect(() => {
		if (typeof apiKey?._id !== "string") return;

		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					id: "apiKey",
					newKey: "new",
					title: intl.formatMessage({
						id: "settings.api_key_detail.page.title",
						defaultMessage: "API key detail",
					}),
					tabTitle: intl.formatMessage({
						id: "settings.api_key_detail.page.title",
						defaultMessage: "API key detail",
					}),
					breadcrumbs: [
						{
							to: settingsConfig.routes.root.replace(":team", routeParameters.team),
							name: intl.formatMessage({
								id: "settings.api_key_detail.header.breadcrumb.settings",
								defaultMessage: "Settings",
							}),
						},
						{
							to: settingsConfig.routes.apiKey.overview.replace(":team", routeParameters.team),
							name: intl.formatMessage({
								id: "settings.api_key_detail.header.breadcrumb.overview",
								defaultMessage: "API keys",
							}),
						},
						{
							to: settingsConfig.routes.apiKey.detail
								.replace(":apiKeyId", apiKey._id)
								.replace(":team", routeParameters.team),
							name: apiKey?.name || "...",
						},
					],
				},
				intl,
			})
		);
	}, [apiKey?.name]);

	const handleFormChange = (payload) => {
		updateApiKey(payload);
	};
	const handlePermissionChange = (permissions: EPermissionKey[]) => {
		if (!apiKey) return;
		updateApiKey({ ...apiKey, permissions });
	};
	const handleChangeDomain = (domains: string[]) => {
		if (!apiKey) return;
		updateApiKey({ ...apiKey, domains });
	};

	if (!state.allowViewing) {
		const url = routesConfig.dashboard.settings.root.replace(":team", routeParameters.team);

		history.push(url);
		return null;
	}

	if (isApiLoading || isPermissionsLoading) {
		return <Layout.TLoadScreen className="sub-page" />;
	}

	if (!isApiLoading && !apiKey) {
		history.push(routesConfig.dashboard.settings.apiKey.overview.replace(":team", routeParameters.team));
		return null;
	}

	function showApi() {
		if (apiKey && permissions) {
			return (
				<>
					<WarningBanner apiKey={apiKey} permissions={permissions} />
					<MainForm apiKey={apiKey} onChange={handleFormChange} className="t-gap--top t-gap--bottom" />
					<Collapse expandIconPosition="end">
						<Permissions
							apiKey={apiKey}
							permissions={permissions}
							language={state.language}
							onChange={handlePermissionChange}
						/>
						<Domains apiKey={apiKey} onChange={handleChangeDomain} />
						<Destructive apiKey={apiKey} />
					</Collapse>
				</>
			);
		}
	}

	return <section className={`page-settings--content page-api-key-overview ${className}`}>{showApi()}</section>;
}
