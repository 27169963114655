import "./style.scss";

import { List } from "antd";
import React from "react";
import { useIntl } from "react-intl";

import { Buttons, Component } from "@/components/atoms";

export default function LabelList({ items, onClick, isDestructive }) {
	const intl = useIntl();

	let actions = () => [<Buttons.AIconButton key="add" icon="add" className="success" iconClass="small success" />];
	const handleClick = (_id) => {
		if (!onClick || isDestructive) {
			return;
		}

		onClick(_id);
	};

	if (isDestructive) {
		actions = (_id) => [
			<Buttons.AIconButton
				key="delete"
				icon="delete"
				className="error"
				iconClass="error"
				onClick={() => onClick(_id)}
			/>,
		];
	}

	return (
		<List
			itemLayout="horizontal"
			dataSource={items}
			className="contact-labels-list"
			locale={{
				emptyText: intl.formatMessage({ id: "INBOX_LABEL_POPUP.FALLBACK.TITLE" }),
			}}
			renderItem={(label) => (
				<List.Item
					key={label._id}
					data-state={isDestructive ? "destructive" : "default"}
					actions={actions(label._id)}
					className="contact-labels-list-item"
					onClick={() => handleClick(label._id)}
				>
					<div className="list-item--content">
						<Component.AIcon
							type="tag"
							style={{ color: label.color }}
							className="small fill t-gap--right-sm"
						/>
						<p>{label.name}</p>
					</div>
				</List.Item>
			)}
		/>
	);
}
