import DashboardCard from "@/shared/components/card/dashboard";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import EmptyRemindersIcon from "../../../../assets/images/icon/empty_reminders.svg";

export default function Empty({ onCreate }: { onCreate: () => void }) {
	return (
		<main style={{ padding: "2em", height: "100%", width: "100%" }}>
			<DashboardCard
				style={{ minHeight: "80%", display: "grid", placeItems: "center", justifyContent: "unset" }}
				bodyStyle={{ display: "grid", placeItems: "center", justifyContent: "unset" }}
			>
				<div>
					<img src={EmptyRemindersIcon} style={{ height: "255px" }} />
					<h1 className="t-gap--top-lg">
						<FormattedMessage
							id="FLOW.DETAIL.REMINDERS.EMPTY.TITLE"
							defaultMessage="Automatic flow reminders"
						/>
					</h1>
					<p style={{ fontSize: "1.5em", maxWidth: "60ch" }}>
						<FormattedMessage
							id="FLOW.DETAIL.REMINDERS.EMPTY.DESCRIPTION"
							defaultMessage="Automatically send reminders to your contacts who have not yet completed their reports."
						/>
					</p>
					<Button className="t-gap--top t-gap--bottom" onClick={() => onCreate()}>
						<FormattedMessage
							id="FLOW.DETAIL.REMINDERS.EMPTY.BUTTON.TITLE"
							defaultMessage="Setup reminders"
						/>
					</Button>
				</div>
			</DashboardCard>
		</main>
	);
}
