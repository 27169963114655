import React from "react";
import { Route, Switch } from "react-router-dom";

import { Handler } from "../components/organisms";
import IntegrationContactSplashScreen from "../modules/contacts/integrationContactSplashScreen";

export default () => (
	<Handler.OErrorFallback>
		<Switch>
			<Route path="/contact/:integrationId/:externalId" component={IntegrationContactSplashScreen} />
		</Switch>
	</Handler.OErrorFallback>
);
