import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

import { Component } from "..";

export default function AListItemAside({ label, icon, to, isExternal, IsFolded, onClick, className }) {
	if (isExternal) {
		return (
			<a
				href={to}
				className={`a-aside-list-item ${className}`}
				data-folded={IsFolded}
				target="_blank"
				rel="noopener noreferrer"
			>
				{typeof icon === "string" ? (
					<Component.AIcon type={icon} className="small white aside-list-item-icon" />
				) : (
					React.cloneElement(icon, { className: "main-aside-menu__icon aside-list-item-icon" })
				)}
				<span className="a-aside--text t-gap--left-sm">
					<FormattedMessage id={label} default="link" />
				</span>
			</a>
		);
	}

	return (
		<NavLink
			to={to}
			className={`a-aside-list-item ${className}`}
			onClick={onClick}
			activeClassName="active"
			data-folded={IsFolded}
			exact={to.split("/").length === 2 || to.includes("/profile")}
		>
			{typeof icon === "string" ? (
				<Component.AIcon type={icon} className="small white aside-list-item-icon" />
			) : (
				React.cloneElement(icon, { className: "main-aside-menu__icon aside-list-item-icon" })
			)}
			<p className="a-aside--text t-gap--left-sm">
				<FormattedMessage id={label} default="link" />
			</p>
		</NavLink>
	);
}

AListItemAside.defaultProps = {
	icon: "",
	IsFolded: false,
	className: "",
	onClick: () => undefined,
};
