import "./style.scss";

import { Collapse, Skeleton } from "antd";
import React from "react";
import { useIntl } from "react-intl";

export default function CollapsePanel({ title, className, isLoading, ...collapseProps }) {
	const intl = useIntl();

	const generateTitle = () => {
		if (isLoading) {
			return <Skeleton active paragraph={{ rows: 0 }} />;
		}

		return intl.formatMessage({ id: `${title}` });
	};

	return (
		<Collapse.Panel
			{...collapseProps} // Get all functional props from parent collapse component
			bordered="false"
			header={generateTitle()}
			className={`shared-panel ${className || ""}`}
		/>
	);
}
