import React from "react";
import { FormattedMessage } from "react-intl";

import { keyEvents } from "../../../helpers";

export default function MIntegrationSmallCard({ id, logo, icon: Icon, label, selected, disabled, className, onClick }) {
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event) && onClick) {
			handleClick();
		}
	};
	const handleClick = () => {
		if (disabled) {
			return;
		}

		onClick(id);
	};

	return (
		<article
			role="button" //eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
			tabIndex="0"
			onClick={handleClick}
			onKeyUp={handleKeyUp}
			data-disabled={disabled}
			className={`m-card m-card--integration-small ${selected ? "selected" : ""} ${className}`}
		>
			{logo ? (
				<img src={logo} alt="" className="a-image integration-image" />
			) : (
				<div
					className="integration-image"
					style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
				>
					<Icon style={{ fontSize: 50 }} />
				</div>
			)}

			<p>
				<FormattedMessage id={label} />
			</p>
		</article>
	);
}

MIntegrationSmallCard.defaultProps = {
	logo: "",
	icon: null,
	selected: false,
	disabled: false,
	className: "",
	onClick: () => undefined,
};
