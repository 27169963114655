import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Button, Modal, Row, Alert } from "antd";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import "../../../style.scss";
import AddressForm from "./form/AddressForm";
import ContactForm from "./form/ContactForm";
import { trpc } from "@/trpc";
import { IOrganization } from "@bothive_core/database";

interface IAddModalLocation {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	allowEditing: boolean;
	organization?: IOrganization;
}
export default function AddModal({ visible, setVisible, allowEditing, organization }: IAddModalLocation) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const [form] = Form.useForm();
	const [showAlert, setShowAlert] = useState(false);
	const createLocationMutation = trpc.organization.createLocation.useMutation();

	const primaryAddress = organization?.address?.find((address) => address.isPrimary);

	const createLocation = (address) => {
		createLocationMutation.mutate(address, {
			onSuccess: () => {
				trpcUtils.organization.getOrganization.invalidate();
				setVisible(false);
				form.resetFields();
				setShowAlert(false);
				ShowSuccessBanner({
					title: intl.formatMessage({
						id: "organization_settings.general.locations.add_modal.created.succeeded",
						defaultMessage: "The address has been successfully saved.",
					}),
				});
			},
			onError: () => {
				ShowFailedBanner({
					title: intl.formatMessage({
						id: "organization_settings.general.locations.add_modal.created.failed",
						defaultMessage: "The address couldn't be saved. Please try again later",
					}),
				});
			},
		});
	};

	return (
		<>
			<Modal
				destroyOnClose
				forceRender
				title={intl.formatMessage({
					id: "organization_settings.general.locations.add_modal.title",
					defaultMessage: "Add Location",
				})}
				open={visible}
				footer={
					<Row justify="space-between">
						<Button type="text" htmlType="button" onClick={() => setVisible(false)}>
							<FormattedMessage
								id="organization_settings.general.locations.add_modal.button.cancel"
								defaultMessage="Cancel"
							/>
						</Button>
						<Button
							type="primary"
							htmlType="submit"
							form="addForm"
							loading={createLocationMutation.isLoading}
						>
							<FormattedMessage
								id="organization_settings.general.locations.add_modal.button.create"
								defaultMessage="Create location"
							/>
						</Button>
					</Row>
				}
				width={650}
				centered
				onCancel={() => {
					form.resetFields();
					setVisible(false);
				}}
			>
				{primaryAddress && showAlert && (
					<Alert
						message={intl.formatMessage({
							id: "organization_settings.general.locations.alert.title",
							defaultMessage: "Primary address",
							description:
								"Alert banner to notify to user that marking this location as primary will change current primary address to normal",
						})}
						description={intl.formatMessage({
							id: "organization_settings.general.locations.alert.description",
							defaultMessage:
								"Are you sure you want to set this address as primary? This will remove the primary status from the current primary address.",
							description:
								"Alert banner to notify to user that marking this location as primary will change current primary address to normal",
						})}
						type="warning"
						showIcon
						className="t-gap--bottom"
					/>
				)}
				<Form
					id="addForm"
					form={form}
					disabled={!allowEditing}
					initialValues={{ isPrimary: false }}
					onFinish={createLocation}
					preserve={false}
				>
					<AddressForm />
					<ContactForm onIsPrimaryChange={(value) => setShowAlert(value)} />
				</Form>
			</Modal>
		</>
	);
}
