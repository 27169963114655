import "./style.scss";

import { RightOutlined } from "@ant-design/icons";
import { Avatar, Card, Empty, Space, Table, Typography } from "antd";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import SkeletonTable from "@/shared/components/table/skeleton";
import { icon } from "../../../assets/images";
import { FlowRouterOutput, trpc } from "../../../trpc";

export default function FlowsCard() {
	const intl = useIntl();
	const history = useHistory();
	const trpcUtils = trpc.useUtils();
	const { team } = useParams<{ team: string }>();

	const { data: reports, isLoading } = trpc.flow.getAllReports.useQuery({ progress: "COMPLETED" });

	const handleRowClick = (report: FlowRouterOutput["getAllReports"]["data"][number]) => {
		history.push(`/${team}/flows/${report.flowId}/reports/${report._id}`);
	};

	return (
		<Card
			title={intl.formatMessage({
				id: "dashboard.flows.title",
				defaultMessage: "Latest completed reports",
			})}
			className="dashboard-flow"
		>
			{!reports?.total && !isLoading && (
				<Empty
					description={intl.formatMessage({
						id: "dashboard.flows.empty.description",
						defaultMessage: "You're up-to-date with all your completed reports",
					})}
					image={icon.notificationsFallback}
					className="dashboard-flow-empty"
				/>
			)}
			{!!reports?.total && (
				<SkeletonTable
					rowKey="_id"
					showHeader={false}
					className="dashboard-flow-table"
					isLoading={isLoading}
					dataSource={reports?.data}
					pagination={false}
					onRow={(report) => ({ onClick: (event) => handleRowClick(report) })}
				>
					<Table.Column
						dataIndex="flow"
						render={(flow) => (
							<Space direction="horizontal">
								<Avatar src={flow.logo} shape="square" className="dashboard-flow-table-avatar" />
								<Typography.Text>{flow.name}</Typography.Text>
							</Space>
						)}
					/>
					<Table.Column
						dataIndex="contact"
						render={(contact) => (
							<Space direction="horizontal">
								<Avatar src={contact.avatar} shape="square" />
								<Typography.Text>{contact.fullName || contact.email}</Typography.Text>
							</Space>
						)}
					/>
					<Table.Column
						dataIndex="status"
						render={(status) => status && <Typography.Text>{status.name}</Typography.Text>}
					/>
					<Table.Column
						width="128px"
						dataIndex="lastFilledInAt"
						render={(lastFilledInAt) => (
							<FormattedDate value={lastFilledInAt} day="2-digit" month="2-digit" year="numeric" />
						)}
					/>
					<Table.Column dataIndex="goToReport" render={() => <RightOutlined />} width="64px" />
				</SkeletonTable>
			)}
		</Card>
	);
}
