export default {
	createUsername({ contact, reverse }) {
		if (contact.username && !reverse) {
			return contact.username;
		}
		if (contact.fullName) {
			return contact.fullName;
		}

		if (contact?.firstName || contact?.lastName) {
			let username = [contact?.firstName, contact?.lastName];

			if (reverse) {
				username = username.reverse();
			}

			return username.filter((item) => item).join(" ");
		}

		return contact.email;
	},
};
