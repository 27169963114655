export default {
	setDefaultFieldValues: ({ rules, form }) => {
		if (!rules?.length) return;

		for (const item of rules) {
			if (
				// eslint-disable-next-line eqeqeq
				form.getFieldValue(item.condition?.field) == item.condition?.value
			) {
				for (const dependent of item.dependents) {
					form.setFieldsValue({ [dependent.field]: dependent.value });
				}
			}
		}
	},
	hideOrShowFields: ({ rules, form, setFieldData, section }) => {
		const handleFindAndSetHiddenProperty = ({ fields, dependent, hidden }) => {
			const editedFields = JSON.parse(JSON.stringify([...fields]));
			const index = editedFields.findIndex((item) => item.id === section.id);

			const prevSectionFields = editedFields[index].fields;
			const dependentFieldIndex = prevSectionFields.findIndex((item) => item.id === dependent.field);

			if (dependent.isHidden) {
				editedFields[index].fields[dependentFieldIndex].isHidden = hidden;
			}

			return editedFields;
		};

		if (!rules?.length) return;

		for (const item of rules) {
			// eslint-disable-next-line eqeqeq
			if (form.getFieldValue(item.condition?.field) == item.condition?.value) {
				for (const dependent of item.dependents) {
					setFieldData((prevState) =>
						handleFindAndSetHiddenProperty({ fields: prevState, dependent, hidden: true })
					);
				}
			}

			// eslint-disable-next-line eqeqeq
			if (form.getFieldValue(item.condition?.field) != item.condition?.value) {
				for (const dependent of item.dependents) {
					setFieldData((prevState) =>
						handleFindAndSetHiddenProperty({ fields: prevState, dependent, hidden: false })
					);
				}
			}
		}
	},
};
