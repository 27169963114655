import "./style.scss";

import { Button, Typography } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import assignImage from "../../../assets/images/mdImages/assign.png";
import backgroundImage from "../../../assets/images/mdImages/inbox.png";
import inboxZeroImage from "../../../assets/images/mdImages/inbox_zero.png";
import shareImage from "../../../assets/images/mdImages/share.png";
import { actions as dashboardActions } from "../../../store/dashboard";
import config from "../config/header.config";

export default () => {
	const intl = useIntl();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(dashboardActions.ui.foldAside());
		dispatch(dashboardActions.ui.changePageTitle({ config: config.header, intl }));

		return () => {
			dispatch(dashboardActions.ui.unfoldAside());
		};
	}, []);

	return (
		<section className="inbox_coming_soon">
			<header>
				<h1 className="inbox_coming_soon-title">
					<FormattedMessage id="INBOX.COMING_SOON.TITLE" defaultMessage="Nu in Publieke beta" />
				</h1>
				<p className="inbox_coming_soon-description">
					<FormattedMessage
						id="INBOX.COMING_SOON.DESCRIPTION"
						defaultMessage="De inbox voor de moderne accountant"
					/>
				</p>
			</header>
			<ul className="inbox_coming_soon-list">
				<li>
					<img src={assignImage} className="inbox_coming_soon-list-icon" />
					<Typography.Text>
						<FormattedMessage id="INBOX.COMING_SOON.FEATURES.ASSIGN" defaultMessage="Wijs teamleden toe" />
					</Typography.Text>
				</li>
				<li>
					<img src={shareImage} className="inbox_coming_soon-list-icon" />
					<Typography.Text>
						<FormattedMessage
							id="INBOX.COMING_SOON.FEATURES.TEAM_INBOX"
							defaultMessage="Deel inbox met je team"
						/>
					</Typography.Text>
				</li>
				<li>
					<img src={inboxZeroImage} className="inbox_coming_soon-list-icon" />
					<Typography.Text>
						<FormattedMessage
							id="INBOX.COMING_SOON.FEATURES.INBOX_ZERO"
							defaultMessage="Gebouwd voor inbox zero"
						/>
					</Typography.Text>
				</li>
			</ul>
			<Button size="large" href="mailto:astrid@bothive.be">
				<FormattedMessage id="INBOX.COMING_SOON.BUTTON" defaultMessage="Vraag je toegang aan" />
			</Button>
			<img src={backgroundImage} className="inbox_coming_soon-background" />
		</section>
	);
};
