import React from "react";

export default function TCardList({ items, children, className }) {
	return (
		<ul className={`t-list t-list--cards ${className}`}>
			{items.map((item) => (
				<li className="list--item" key={item.id}>
					{React.cloneElement(children, item)}
				</li>
			))}
		</ul>
	);
}
