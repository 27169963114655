import { atom, atomFamily } from "recoil";

import { getFlowById } from "../query";

export const selectedFlowIdState = atom<string>({
	key: "foSelectedFlowId",
	default: "",
});

export const flowState = atomFamily({
	key: "foFlowState",
	default: async ({ id }: { id: string }) => {
		const result = await getFlowById({ id });

		return result?.data;
	},
});

export const AsideCollapsedState = atom<boolean>({
	key: "asideCollapsedState",
	default: false,
});
