import "./style.scss";
import { FC, ReactNode } from "react";
import { Badge, Typography } from "antd";

interface SideNavItemProps {
	title: ReactNode;
	count?: number;
}

const SideNavItem: FC<SideNavItemProps> = ({ title, count }) => {
	return (
		<div className="sn-menu-item">
			<Typography.Paragraph className="truncate" ellipsis style={{ marginBottom: 0 }}>
				{title}
			</Typography.Paragraph>
			<Badge className="sn-folder-count" overflowCount={999} count={count || 0} />
		</div>
	);
};

export default SideNavItem;
