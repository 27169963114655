import { typesConfig } from "../../../config";
import settingsConfig from "../../../modules/settings/config";

export default {
	defaultLimit: 20,
	header: {
		id: "label",
		title: "CONTACT_SETTINGS.LABELS.TITLE",
		tabTitle: "CONTACT_SETTINGS.LABELS.TITLE",
		breadcrumbs: ({ team }) => [
			{
				to: settingsConfig.routes.root.replace(":team", team),
				name: "CONTACT_SETTINGS.LABELS.BREADCRUMB.SETTINGS",
			},
			{
				to: settingsConfig.routes.contact.labels.replace(":team", team),
				name: "CONTACT_SETTINGS.LABELS.BREADCRUMB.TITLE",
			},
		],
	},
	table: {
		fallbackMessage: "CONTACT_SETTINGS.LABELS.TABLE.FALLBACK",
		heading: [
			{
				id: "label",
				key: "label",
				label: "CONTACT_SETTINGS.LABELS.TABLE.NAME",
				cellType: typesConfig.cellTypes.tag,
				width: "100%",
			},
			{
				id: "action",
				key: "action",
				cellType: typesConfig.cellTypes.icon,
				width: "64px",
			},
		],
		addButton: "CONTACT_SETTINGS.LABELS.TABLE.ADD_LABEL",
	},
};
