import { FormattedMessage } from "react-intl";
import { Button, Result } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { routesConfig, typesConfig } from "@/config";
import { useSelector } from "react-redux";

export default function FlowSendingResult({
	handleClose,
	showClose,
	showReports,
	flowId,
}: {
	handleClose: () => void;
	showClose?: boolean;
	showReports?: boolean;
	flowId: string;
}) {
	// @ts-ignore
	const team = useSelector((state) => state.content.team.team);

	const history = useHistory();
	const url = routesConfig.dashboard.flow.reports.replace(":team", team.slug).replace(":flowId", flowId);

	return (
		<Result
			status="success"
			title={
				<FormattedMessage
					id="FLOWS.SEND.RESULT.TITLE"
					defaultMessage="Your flow is being sent to your selected contacts"
				/>
			}
			subTitle={
				<FormattedMessage
					id="FLOWS.SEND.RESULT.DESCRIPTION"
					defaultMessage="We're sending your flow to all selected contacts. This can take up to 30 minutes. You can view the status per report in reports overview under 'Progression'. You will also receive a notification when all emails are sent."
				/>
			}
			extra={
				showClose ? (
					<Button type="primary" key="handleClose" onClick={() => handleClose()}>
						<FormattedMessage id="FLOWS.SEND.RESULT.CLOSE" defaultMessage="Close" />
					</Button>
				) : showReports ? (
					<Button
						type="primary"
						key="handleClose"
						onClick={() => {
							history.push(`${url}?progress=${typesConfig.sessionState.inviteSend}`);

							handleClose();
						}}
					>
						<FormattedMessage id="FLOWS.SEND.RESULT.REPORTS" defaultMessage="Go to reports" />
					</Button>
				) : null
			}
		/>
	);
}
