import { PlusOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";

import { selectedFlowIdState } from "@/flows/common/state";
import { reportViewState } from "@/flows/report_detail/state";
import settingsConfig from "@/modules/settings/config";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import { StatusButton } from "@/shared/components/button";

import ConfirmationModal from "./confirmation_modal";
import helper from "./helper";
import query from "./query";
import { automationSelector, automationStatusSelector } from "./state";
import style from "./style.module.scss";

export default function Automation({ isReportInProgress }) {
	const intl = useIntl();
	const history = useHistory();
	const routeParameters = useParams();
	const reportId = routeParameters.reportId;
	const flowId = useRecoilValue(selectedFlowIdState);
	const reportViewType = useRecoilValue(reportViewState);
	const rules = useRecoilValueLoadable(automationSelector);
	const [ruleStatus, setRuleStatus] = useRecoilState(automationStatusSelector(reportId));

	const [showConfirm, setShowConfirm] = useState(false);
	const [clickedRule, setClickedRule] = useState(undefined);

	useEffect(() => {
		handleAutomationHistory();
	}, []);

	const handleAutomationHistory = async () => {
		const data = await query.automationHistory({ reportId });

		setRuleStatus(helper.getAutomationStatusFromHistory(data));
	};

	const handleRuleClick = (ruleId) => {
		const rule = rules.contents.data.find((rule) => rule._id === ruleId);

		if (!rule.ui?.requireConfirm) {
			executeRule(rule);
			return;
		}

		setShowConfirm(true);
		setClickedRule(rule);
	};

	const handleCloseConfirm = () => {
		setShowConfirm(false);
		setClickedRule(undefined);
	};

	const handleConfirm = () => {
		executeRule(clickedRule);
		handleCloseConfirm();
	};

	const executeRule = async (rule) => {
		let errorMessage = "FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.BANNER.FAILED";
		const ruleId = rule._id; // save rule inside function to let users execute multiple rules and still update the correct rule status

		try {
			changeRuleStatus({ ruleId, status: { isLoading: true } });

			const result = await query.executeRule({
				ruleId,
				flowId,
				reportId,
				formatType: reportViewType,
			});

			if (!result.success) {
				errorMessage = result.meta?.banner || errorMessage;
				throw new Error(errorMessage);
			}

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: result.meta?.banner || "FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.BANNER.SUCCEEDED",
					defaultMessage: result.meta?.banner || "Automation has been executed.",
				}),
			});
			changeRuleStatus({ ruleId, status: { isLoading: false, isSuccessful: true } });
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: errorMessage,
					defaultMessage: errorMessage,
				}),
			});
			changeRuleStatus({ ruleId, status: { isLoading: false, isSuccessful: false } });
		}
	};

	function changeRuleStatus({ ruleId, status }) {
		setRuleStatus({
			...ruleStatus,
			[ruleId]: {
				...(ruleStatus[ruleId] || []),
				ruleId,
				...status,
			},
		});
	}

	const addIntegration = () => {
		const url = settingsConfig.routes.integrations.overview.replace(":team", routeParameters.team);

		history.push(url);
	};

	return (
		<section className={style.automation_group}>
			<header className={style.automation_group_header}>
				<p>
					<FormattedMessage id="FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.TITLE" />
				</p>
			</header>
			<div className={style.automation_group_content}>
				{rules.state === "loading" && <Skeleton.Button active size="large" block />}
				{rules.state === "hasValue" &&
					rules.contents.data.map((rule) => (
						<StatusButton
							key={rule._id}
							isLoading={ruleStatus[rule._id]?.isLoading}
							isSuccessful={ruleStatus[rule._id]?.isSuccessful}
							tooltip={{
								success: "FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.TOOLTIP.SUCCEEDED",
								failure: "FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.TOOLTIP.FAILED",
							}}
							icon={<img src={rule.icon} alt="" className={style.automation_button_icon} />}
							onClick={() => handleRuleClick(rule._id)}
							disabled={!isReportInProgress}
						>
							{rule.name[intl.locale]}
						</StatusButton>
					))}
				<StatusButton icon={<PlusOutlined className="t-gap--right-xs" />} onClick={addIntegration}>
					<FormattedMessage id="FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.FOOTER.ADD_BUTTON" />
				</StatusButton>
			</div>
			<ConfirmationModal
				rule={clickedRule}
				visible={showConfirm}
				onSubmit={handleConfirm}
				onClose={handleCloseConfirm}
			/>
		</section>
	);
}
