export default {
	title: "SETTINGS.NOTIFICATIONS.TECHNICAL.TITLE",
	description: "SETTINGS.NOTIFICATIONS.TECHNICAL.DESCRIPTION",
	fieldConfig: {
		developer: {
			label: "SETTINGS.NOTIFICATIONS.TECHNICAL.FORM.DEVELOPER.LABEL",
			placeholder: "SETTINGS.NOTIFICATIONS.TECHNICAL.FORM.DEVELOPER.PLACEHOLDER",
			emailError: "SETTINGS.NOTIFICATIONS.TECHNICAL.FORM.DEVELOPER.EMAIL_ERROR",
			button: "SETTINGS.NOTIFICATIONS.TECHNICAL.FORM.DEVELOPER.BUTTON",
		},
	},
};
