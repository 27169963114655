import { Button, Form, Modal, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { trpc } from "@/trpc";
import { notification } from "antd";
import config from "./config";
import query from "./query";

export default function DeveloperEmailOptions({ developerEmail, visible, onClose }) {
	const intl = useIntl();

	const [apiKeys, setApiKeys] = useState([]);
	const [isFetching, setIsFetching] = useState(false);
	const sendKeyToWebDeveloper = trpc.apiKeyManager.sendKeyToWebDeveloper.useMutation();

	useEffect(() => {
		setIsFetching(true);
		query
			.fetchApiKeys()
			.then((result) => {
				setIsFetching(false);
				setApiKeys(result.data);
			})
			.catch(() => setIsFetching(false)); //TODO show error fetching keys failed
	}, []);

	const handleSubmit = async ({ apiKey }) => {
		try {
			if (!apiKey || !developerEmail) throw new Error();

			await sendKeyToWebDeveloper.mutateAsync({ email: developerEmail, apiKey });

			notification.success({
				message: intl.formatMessage({ id: config.banner.developerEmail.success }),
				placement: "bottomRight",
			});
			onClose();
		} catch (_) {
			notification.success({
				message: intl.formatMessage({ id: config.banner.developerEmail.fail }),
				placement: "bottomRight",
			});
		}
	};

	return (
		<Modal
			open={visible}
			onCancel={onClose}
			onOk={handleSubmit}
			title={<FormattedMessage id={config.title} />}
			transitionName="" // to disable animation
			footer={[
				<Button key="submit" type="primary" form="developerEmailOptions" htmlType="submit">
					<FormattedMessage id={config.submit} />
				</Button>,
			]}
		>
			<Typography.Text>
				<FormattedMessage id={config.description} />
			</Typography.Text>
			<Form
				id="developerEmailOptions"
				onFinish={handleSubmit}
				requiredMark="optional"
				className="t-gap--top-sm"
				autoComplete="off"
				layout="vertical"
			>
				<Form.Item
					name="apiKey"
					label={intl.formatMessage({ id: config.fieldConfig.apiKey.label })}
					help={intl.formatMessage({ id: config.fieldConfig.apiKey.help })}
					rules={[{ required: true, error: intl.formatMessage({ id: config.fieldConfig.apiKey.error }) }]}
				>
					<Select notFoundContent={null} loading={isFetching} showArrow>
						{apiKeys.map((apiKey) => (
							<Select.Option key={apiKey.key} value={apiKey.key}>
								{apiKey.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
}
