import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

import { ListItem } from "../../atoms";

export default function MMetaInfo({ meta, items, onClick, buttonLabel, className }) {
	return (
		<article className={`m-article m-article--meta-info ${className}`}>
			<div className="article-body">
				{items.map((item) => (
					<ListItem.AListItemMeta key={item.key} label={item.label} type={item.type} value={meta[item.key]} />
				))}
			</div>
			<footer className="article-footer">
				<Button type="default" onClick={onClick}>
					<FormattedMessage id={buttonLabel} />
				</Button>
			</footer>
		</article>
	);
}

MMetaInfo.defaultProps = {
	meta: {},
	items: [],
	buttonLabel: "see all",
	className: "",
	onClick: undefined,
};
