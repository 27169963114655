import { defineMessages } from "react-intl";

export const contactTypesI18n = defineMessages({
	0: {
		id: "settings.integrations.admin_pulse.webhook.form.crm_type.option.company",
		defaultMessage: "Company",
	},
	1: {
		id: "settings.integrations.admin_pulse.webhook.form.crm_type.option.sole_proprietor",
		defaultMessage: "Sole proprietor",
	},
	2: {
		id: "settings.integrations.admin_pulse.webhook.form.crm_type.option.person",
		defaultMessage: "Person",
	},
});

export const crmTypesI18n = defineMessages({
	0: {
		id: "settings.integrations.admin_pulse.webhook.form.crm_type.option.other",
		defaultMessage: "Other",
	},
	2: {
		id: "settings.integrations.admin_pulse.webhook.form.crm_type.option.prospect",
		defaultMessage: "Prospect",
	},
	3: {
		id: "settings.integrations.admin_pulse.webhook.form.crm_type.option.customer",
		defaultMessage: "Customer",
	},
	4: {
		id: "settings.integrations.admin_pulse.webhook.form.crm_type.option.own_company",
		defaultMessage: "Own company",
	},
});
