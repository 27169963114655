import StatusButton from "@/shared/components/button/status_button";
import { trpc } from "@/trpc";
import { SaveOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

export default function SaveToContactButton() {
	const { reportId } = useParams<{ reportId: string }>();
	const intl = useIntl();
	const { data: report } = trpc.report.getById.useQuery({ reportId }, { enabled: !!reportId });
	const seedContactMutation = trpc.report.saveReportToContact.useMutation({
		onError: () => {
			notification.error({
				message: intl.formatMessage({ id: "REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.ERROR.MESSAGE" }),
				placement: "bottomRight",
			});
		},
		onSuccess: () => {
			notification.success({
				message: intl.formatMessage({ id: "REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.SUCCESS.MESSAGE" }),
				placement: "bottomRight",
			});
		},
	});

	return (
		<StatusButton
			onClick={() => seedContactMutation.mutate({ reportId })}
			icon={<SaveOutlined className="t-gap--right-xs" />}
			disabled={!["IN_PROGRESS", "COMPLETED"].includes(report?.progress || "")}
			isLoading={seedContactMutation.isLoading}
		>
			<FormattedMessage id="FLOWS.REPORT_DETAIL.ACTIONS.SAVE_TO_CONTACT" />
		</StatusButton>
	);
}
