import "./style.scss";

import { useIntl } from "react-intl";
import { Form, Input, Button, Select, FormListFieldData, FormInstance } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

interface IPlatform {
	value: string;
	label: string;
}

interface ISocialSettings {
	field: FormListFieldData;
	form: FormInstance<any>;
	isEnabled?: boolean;
	onRemove: (index: number | number[]) => void;
}

export default function SocialsRow({ field, form, onRemove, isEnabled = true }: ISocialSettings) {
	const intl = useIntl();

	const platformRegex = {
		facebook: /^https?:\/\/(www\.)?facebook\.com\/.*/i,
		linkedin: /^https?:\/\/([a-z]{2}\.)?linkedin\.com\/.*/i,
		instagram: /^https?:\/\/(www\.)?instagram\.com\/.*/i,
		twitter: /^https?:\/\/(www\.)?twitter\.com\/.*/i,
		youtube: /^https?:\/\/(www\.)?youtube\.com\/.*/i,
	};

	let platformOption: IPlatform[] = [
		{
			value: "facebook",
			label: intl.formatMessage({
				id: "organization_settings.general.social_media.form.field_labels.facebook.field.placeholder",
				defaultMessage: "Facebook",
			}),
		},
		{
			value: "linkedin",
			label: intl.formatMessage({
				id: "organization_settings.general.social_media.form.field_labels.linkedin.field.placeholder",
				defaultMessage: "Linkedin",
			}),
		},
		{
			value: "instagram",
			label: intl.formatMessage({
				id: "organization_settings.general.social_media.form.field_labels.instagram.field.placeholder",
				defaultMessage: "Instagram",
			}),
		},
		{
			value: "twitter",
			label: intl.formatMessage({
				id: "organization_settings.general.social_media.form.field_labels.twitter.field.placeholder",
				defaultMessage: "Twitter",
			}),
		},
		{
			value: "youtube",
			label: intl.formatMessage({
				id: "organization_settings.general.social_media.form.field_labels.youtube.field.placeholder",
				defaultMessage: "Youtube",
			}),
		},
	];

	const placeholder = {
		facebook: "https://www.facebook.com/bothivechatbots",
		linkedin: "https://be.linkedin.com/company/bothive-chatbots",
		instagram: "https://www.instagram.com/bothiveHQ/",
		twitter: "https://twitter.com/BothiveHQ",
		youtube: "https://www.youtube.com/channel/UCA4zAo0t7MKrUYIqbLgTliQ",
	};

	const formValue = Form.useWatch(["socialMedia"], form);
	const selectedPlatform = form.getFieldValue(["socialMedia", field.name, "platform"]);

	const selectedPlatforms = formValue
		? formValue.map((item) => item && item.platform).filter((item) => item !== selectedPlatform)
		: [];
	const filteredOptions = platformOption.filter(({ value }) => !selectedPlatforms.includes(value));

	return (
		<div className="settings-organization-social_settings-row" key={`${field.name}div`}>
			<Form.Item
				{...field}
				name={[field.name, "platform"]}
				key={`field${field.name}`}
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: "settings.organization.socials.platform.required",
							defaultMessage: "Platform is required.",
						}),
					},
				]}
			>
				<Select
					style={{ width: 130, textAlign: "left" }}
					placeholder={intl.formatMessage({
						id: "organization_settings.general.social_media.form.add_fields.placeholder.label",
						defaultMessage: "Platform",
					})}
					options={filteredOptions}
					getPopupContainer={(trigger) => trigger.parentNode}
					onChange={() => form.validateFields()}
				/>
			</Form.Item>
			<Form.Item
				{...field}
				name={[field.name, "url"]}
				key={field.name + "url"}
				rules={[
					({ getFieldValue }) => ({
						validator(_, value) {
							if (!value?.length)
								return Promise.reject(
									new Error(
										intl.formatMessage({
											id: "settings.organization.socials.url.required",
											defaultMessage: "URL is required.",
										})
									)
								);
							const platform = getFieldValue(["socialMedia", field.name, "platform"]);
							if (platform && !platformRegex[platform].test(value)) {
								const message = intl.formatMessage(
									{
										id: "settings.organization.socials.url.validation",
										defaultMessage: "`Please enter a valid {platform} URL. Example {example}`",
									},
									{ platform, example: placeholder[selectedPlatform] }
								);
								return Promise.reject(new Error(message));
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<Input placeholder={selectedPlatform && placeholder[selectedPlatform]} />
			</Form.Item>
			{isEnabled && <Button type="text" icon={<DeleteOutlined />} onClick={() => onRemove(field.name)} />}
		</div>
	);
}
