import "./style.scss";

import { Form, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import FieldLabel from "../../../../../../../shared/components/field/default";
import BankAccountItems from "./formItems/BankAccountItems";
import { CryptoWalletItems } from "./formItems/CryptoWalletItems";
import { useSetFocus } from "@/shared/hooks";
import { useRef } from "react";

interface BankAccountsFormProps {
	initialValues: any;
	handleSubmit: (values: any) => void;
}

export default function BankAccountsForm({ initialValues, handleSubmit }: BankAccountsFormProps) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const selectRef = useRef(null);

	useSetFocus({ ref: selectRef, dependencies: [selectRef] });

	return (
		<Form
			form={form}
			id="bankAccountForm"
			layout="vertical"
			className="frsf-form bank_account_modal-form"
			onFinish={handleSubmit}
			initialValues={initialValues}
		>
			<FieldLabel id="type" label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.LABEL">
				<Form.Item
					name="type"
					className="bank_account_modal-form_item"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.ERROR_REQUIRED",
							}),
						},
					]}
				>
					<Select ref={selectRef}>
						<Select.Option value="currentAccount">
							<FormattedMessage id="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.CURRENT_ACCOUNT" />
						</Select.Option>
						<Select.Option value="privateAccount">
							<FormattedMessage id="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.PRIVATE_ACCOUNT" />
						</Select.Option>
						<Select.Option value="savingsAccount">
							<FormattedMessage id="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.SAVINGS_ACCOUNT" />
						</Select.Option>
						<Select.Option value="cryptoWallet">
							<FormattedMessage id="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.CRYPTO_WALLET" />
						</Select.Option>
						<Select.Option value="other">
							<FormattedMessage id="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.OTHER" />
						</Select.Option>
					</Select>
				</Form.Item>
			</FieldLabel>
			{Form.useWatch("type", form) === "cryptoWallet" ? <CryptoWalletItems /> : <BankAccountItems form={form} />}
		</Form>
	);
}
