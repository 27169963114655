import { ApiKeyManagerRouterOutput, trpc } from "@/trpc";

export default {
	formatDataSource({ apiKey }: { apiKey: ApiKeyManagerRouterOutput["getById"] | null | undefined }) {
		if (!apiKey?.domains?.length) {
			return [];
		}

		return apiKey.domains?.map((domain) => ({
			domain,
			deleteKey: domain,
		}));
	},
};
