import "./style.scss";

interface IAsidePreview {
	brandColor: string;
	textColor: string;
	icon: string;
}

export default function AsidePreview({ brandColor, textColor, icon }: IAsidePreview) {
	return (
		<div
			className="setup-aside_preview setup_branding-aside_preview"
			style={{ background: brandColor }}
			// Old gradient background
			// style={{ background: `linear-gradient(134deg, ${brandColor} 0%,${accentColor} 100%)` }}
		>
			<div className="aside_preview-navigation">
				<div className="aside_preview-navigation-header">
					<div className="aside_preview-navigation-header-title" />
					<div className="aside_preview-navigation-header-description" />
					<div className="aside_preview-navigation-header-description" />
					<div className="aside_preview-navigation-header-description small" />
				</div>

				<div className="aside_preview-navigation-sections" />
				<div className="aside_preview-navigation-sections" />
				<div className="aside_preview-navigation-sections" />
				<div className="aside_preview-navigation-sections" />
			</div>
			<div className="aside_preview-content">
				<div className="aside_preview-content-header">
					<img src={icon} className="aside_preview-content-header-icon" />
					<div className="aside_preview-content-header-title" />
				</div>

				<div className="aside_preview-content-chat">
					<div data-state="incoming" className="aside_preview-content-bubble">
						<div className="bubble-text" />
						<div className="bubble-text" />
						<div className="bubble-text small" />
					</div>
					<div
						data-state="outgoing"
						className="aside_preview-content-bubble"
						style={{ background: brandColor }}
					>
						<div className="bubble-text" style={{ background: textColor }} />
						<div className="bubble-text small" style={{ background: textColor }} />
					</div>
					<div data-state="incoming" style={{ width: "124px" }} className="aside_preview-content-bubble">
						<div className="bubble-text" />
					</div>
				</div>
			</div>
		</div>
	);
}
