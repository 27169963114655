import React from "react";
import { injectIntl } from "react-intl";

import { Buttons } from "../../atoms";

function OFullScreen({ className, children, onClose, intl }) {
	return (
		<div role="dialog" aria-labelledby="dialog" className={`o-popup-fullscreen ${className}`}>
			<div className="popup-header">
				<Buttons.AIconButton
					icon="close"
					label={intl.formatMessage({ id: "POPUP.BUTTON.CLOSE" })}
					iconClass="small"
					onClick={onClose}
				/>
			</div>
			<div className="popup-content">{children}</div>
		</div>
	);
}

OFullScreen.defaultProps = {
	children: undefined,
	className: "",
};

export default injectIntl(OFullScreen);
