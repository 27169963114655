import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { trpc } from "../../../../../../trpc";
import AddAddressModal from "./components/AddAddressModal";
import LocationCard from "./components/LocationCard";
import { Panel } from "../../../../../../shared/components/collapse";
import ShowSuccessBanner from "../../../../../../shared/components/banner/success";
import DeleteModal from "../../../../../../shared/components/modal/delete_modal";
import ShowFailedBanner from "../../../../../../shared/components/banner/failed";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import arrayHelper from "../../../../../../helpers/array.helpers";

export default function ContactAddresses(panelProps: any) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const params: { contactId: string } = useParams();

	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const [showUpdateModal, setShowUpdateModal] = useState<any | boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

	const { data: contact } = trpc.contact.getContactById.useQuery({ id: params?.contactId });

	const deleteAddressMutation = trpc.contact.deleteAddress.useMutation();

	const isThereAPrimaryAddress = useMemo(() => !!contact?.address?.find((address) => address.primary), [contact]);
	const modalAddress = useMemo(
		() => contact?.address?.find((address) => address._id === showUpdateModal),
		[contact, showUpdateModal]
	);

	return (
		<>
			<Panel
				key="addresses"
				title={intl.formatMessage({ id: "CONTACTS.DETAIL.ADDRESSES.TITLE" })}
				{...panelProps}
			>
				<div className="t-list--horizontal">
					{arrayHelper
						.sortByBoolean({
							item: contact?.address || [],
							key: "primary",
							direction: -1,
						})
						?.map((address, index) => (
							<LocationCard
								key={address._id || `${index}`}
								_id={address._id || ""}
								{...address}
								onClick={(contactId: string) => setShowUpdateModal(contactId)}
							/>
						))}
					<Button
						style={{ width: "fit-content" }}
						type="text"
						onClick={() => {
							setShowCreateModal(true);
						}}
						size="large"
						icon={<PlusOutlined />}
					>
						{" "}
						<FormattedMessage id="FORM_ELEMENT.FORM_GROUP.ADD_LOCATION" />
					</Button>
				</div>
			</Panel>
			<AddAddressModal
				showCreateModal={showCreateModal}
				showUpdateModal={showUpdateModal}
				setShowUpdateModal={setShowUpdateModal}
				setShowCreateModal={setShowCreateModal}
				address={modalAddress}
				isThereAPrimaryAddress={isThereAPrimaryAddress}
				setShowDeleteModal={setShowDeleteModal}
			/>
			<DeleteModal
				title={intl.formatMessage({
					id: "CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.TITLE",
					defaultMessage: "Delete address",
				})}
				deleteLabel={intl.formatMessage({
					id: "CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.BUTTON",
					defaultMessage: "Delete",
				})}
				description={intl.formatMessage({
					id: "CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.DESCRIPTION",
					defaultMessage: "Are you sure you want to delete this address?",
				})}
				isVisible={showDeleteModal}
				onDelete={() => {
					if (!modalAddress?._id) return;

					deleteAddressMutation.mutate(
						{
							contactId: params.contactId,
							addressId: modalAddress._id,
						},
						{
							onSuccess: () => {
								ShowSuccessBanner({
									title: intl.formatMessage({
										id: "CONTACTS.DETAIL.ATTRIBUTES.DELETE.ADDRESS.SUCCESS",
										defaultMessage: "Address successfully deleted",
									}),
								});

								trpcUtils.contact.getContactById.invalidate();

								setShowDeleteModal(false);
								setShowUpdateModal(false);
							},
							onError: () => {
								ShowFailedBanner({
									title: intl.formatMessage({
										id: "CONTACTS.DETAIL.ATTRIBUTES.DELETE.ADDRESS.FAILED",
										defaultMessage: "Address failed to delete",
									}),
								});

								setShowDeleteModal(false);
								setShowUpdateModal(false);
							},
						}
					);
				}}
				onClose={() => setShowDeleteModal(false)}
			/>
		</>
	);
}
