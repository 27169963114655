import { Steps } from "antd";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

export default function SetupSteps({ currentStep }: { currentStep: number }) {
	const intl = useIntl();
	const history = useHistory();
	const routerParameters = useParams<{ slug: string }>();

	const handleChange = (stepIndex: number) => {
		const routes = [
			`/${routerParameters.slug}/setup/members`,
			`/${routerParameters.slug}/setup/branding`,
			`/${routerParameters.slug}/setup/flow`,
		];

		history.push(routes[stepIndex]);
	};

	return (
		<Steps
			size="small"
			current={currentStep}
			onChange={handleChange}
			items={[
				{
					title: intl.formatMessage({
						id: "setup.steps.members",
						defaultMessage: "Invite members",
						description: "setup page navigation title",
					}),
				},
				{
					title: intl.formatMessage({
						id: "setup.steps.branding",
						defaultMessage: "Branding",
						description: "setup page navigation title",
					}),
				},
				{
					title: intl.formatMessage({
						id: "setup.steps.flow",
						defaultMessage: "Flows",
						description: "setup page navigation title",
					}),
				},
			]}
		/>
	);
}
