import { Button, Col, Row, Form, Input, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import FieldLabel from "@/shared/components/field/default";
import { useIntl } from "react-intl";
import { addConnection } from "../common/helpers";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { trpc } from "@/trpc";
import { stringHelpers } from "@/shared/helpers";

export default function IntegrationConnection({
	allowEditing,
	canAddIntegration,
}: {
	allowEditing: boolean;
	canAddIntegration: boolean;
}) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const history = useHistory();
	const location = useLocation();
	const trpcUtils = trpc.useUtils();
	const addConnectionMutation = trpc.integration.addConnection.useMutation();
	const { connectionId: integrationId }: any = useParams();

	return (
		<Row className="integrationModal">
			<Col className="info" xs={24} xl={9}>
				<section className="info-section">
					<h2 className="info-title">
						<FormattedMessage
							id="SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.TITLE"
							defaultMessage="Fid-Manager"
						/>
					</h2>
					<p>
						<FormattedMessage
							id="SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.DESCRIPTION"
							defaultMessage="Connect with Fid-Manager to easily sync your contacts with Bothive and sync flow's with FID-manager"
						/>
					</p>
					<ul className="info-features">
						<li>
							<FormattedMessage
								id="SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FEATURES.SYNC_CONTACTS"
								defaultMessage="Synchronize contacts"
							/>
						</li>
					</ul>
				</section>
			</Col>
			<Col className="login" xs={24} xl={15}>
				<div className="logo-container">
					<img
						src="https://storage.googleapis.com/bothive_assets_8a0s1s3e/integration_icon/fid_manager.png"
						alt="Fid-Manager logo"
						className="logo"
					/>
				</div>
				<Tooltip
					title={
						!allowEditing
							? intl.formatMessage({
									id: "settings.integrations.fidmanager.tooltip.missing_integrations_permissions",
									defaultMessage: "Missing edit integrations permissions",
							  })
							: !canAddIntegration
							? intl.formatMessage({
									id: "settings.integrations.fidmanager.tooltip.limit_reached",
									defaultMessage: "Maximum integrations limit reached",
							  })
							: null
					}
					placement="bottom"
				>
					<Form
						id="login"
						form={form}
						className="login-form"
						disabled={!allowEditing || !canAddIntegration}
						onFinish={(values) =>
							addConnection(
								intl,
								trpcUtils,
								integrationId,
								history,
								location,
								addConnectionMutation,
								values
							)
						}
					>
						<FieldLabel
							label={intl.formatMessage({
								id: "SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.BASE_URL.LABEL",
								defaultMessage: "Base url",
							})}
						>
							<Form.Item
								name="baseUrl"
								className="form-input"
								rules={[
									{
										required: true,
										validator: (_, value: string) => {
											const emptyMessage = intl.formatMessage({
												id: "settings.integrations.fid_manager.login.form.error.required",
												defaultMessage: "URL is required",
											});

											const noUrlMessage = intl.formatMessage({
												id: "settings.integrations.fid_manager.login.form.error.no_url",
												defaultMessage: "Please fill in a valid URL",
											});

											if (!value?.length) return Promise.reject(new Error(emptyMessage));

											if (!stringHelpers.isUrl(value))
												return Promise.reject(new Error(noUrlMessage));

											return Promise.resolve();
										},
									},
								]}
							>
								<Input
									size="large"
									placeholder={intl.formatMessage({
										id: "SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.BASE_URL.PLACEHOLDER",
										defaultMessage: "https://company-name.fid-manager.be/nl",
									})}
								/>
							</Form.Item>
						</FieldLabel>
						<FieldLabel
							className="t-gap--top-sm"
							label={intl.formatMessage({
								id: "SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.LABEL",
								defaultMessage: "Login",
							})}
						>
							<Form.Item
								name="username"
								className="form-input"
								rules={[
									{
										required: true,
										validator: (_, value: string) => {
											const emptyMessage = intl.formatMessage({
												id: "SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.REQUIRED",
												defaultMessage: "Login is required",
											});

											const symbolsMessage = intl.formatMessage({
												id: "settings.integrations.fid_manager.login.form.error.has_symbols",
												defaultMessage: "Please remove any special symbols",
											});

											if (!value?.length) return Promise.reject(new Error(emptyMessage));

											if (stringHelpers.hasSymbols(value))
												return Promise.reject(new Error(symbolsMessage));

											return Promise.resolve();
										},
									},
								]}
							>
								<Input
									size="large"
									placeholder={intl.formatMessage({
										id: "SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.PLACEHOLDER",
										defaultMessage: "a000a000-000a-0000-a0aa-aaa00a00a0000",
									})}
								/>
							</Form.Item>
						</FieldLabel>
						<FieldLabel
							className="t-gap--top-sm"
							label={intl.formatMessage({
								id: "SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.PASSWORD.LABEL",
								defaultMessage: "Password",
							})}
						>
							<Form.Item
								name="password"
								className="form-input"
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id: "SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.PASSWORD.REQUIRED",
											defaultMessage: "Password is required",
										}),
									},
								]}
							>
								<Input.Password size="large" />
							</Form.Item>
						</FieldLabel>
						<Button
							type="primary"
							htmlType="submit"
							form="login"
							size="large"
							className="form-button fidmanager t-gap--top-sm"
							loading={addConnectionMutation.isLoading}
						>
							<FormattedMessage
								id="SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.SUBMIT"
								defaultMessage="Connect with Fid-Manager"
							/>
						</Button>
					</Form>
				</Tooltip>
			</Col>
		</Row>
	);
}
