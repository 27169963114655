import "./style.scss";

import { IContact } from "@bothive_core/database";
import { Avatar, Button, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";

import { contactTypesI18n } from "../config";

export function ExistingContactDisplay({ contact, className = "" }: { contact: IContact; className?: string }) {
	const { team } = useParams<{ team: string }>();

	return (
		<div className={`existing_contact ${className}`}>
			<div className="existing_contact-left">
				<Avatar src={contact.avatar} shape="square" size={34} />
				<div className="existing_contact-list">
					<Typography.Text>{contact.fullName}</Typography.Text>
					<Typography.Text type="secondary">
						<FormattedMessage {...contactTypesI18n[contact.type]} />
					</Typography.Text>
				</div>
			</div>
			<Link
				to={`/${team}/contact/id/${contact._id}`}
				component={(props) => (
					<Button type="text" {...props}>
						<FormattedMessage
							id="contact.existing_contact_display.button.go_to_contact"
							defaultMessage="Go to contact"
							description="Button show in card of a existing contact that redirects the user to this contact detail page"
						/>
					</Button>
				)}
			/>
		</div>
	);
}
