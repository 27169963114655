import { Avatar, Tag } from "antd";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { EyeOutlined } from "@ant-design/icons";

import { keyEvents } from "@/helpers";
import { apiConfig } from "@/config";
import { Component } from "@/components/atoms";

const countriesList = require("world_countries_lists/data/countries/_combined/countries.json");

export default function LocationCard({
	_id,
	streetNumber,
	streetName,
	city,
	country,
	postalCode,
	primary,
	deprecated = false,
	className,
	onClick,
	disabled,
	allowEditing,
}: {
	_id: string;
	streetNumber: string;
	streetName: string;
	city: string;
	country: (typeof countriesList)[number]["alpha2"];
	postalCode: string;
	primary?: boolean;
	deprecated?: boolean;
	className?: string;
	onClick?: (id: string) => void;
	disabled?: boolean;
	allowEditing?: boolean;
}) {
	const intl = useIntl();
	const handleClick = useCallback(() => {
		onClick && onClick(_id);
	}, [_id, onClick]);
	const handleKeyUp = useCallback(
		(event: any) => {
			if (keyEvents.enterOrSpacePressed(event)) {
				handleClick();
			}
		},
		[handleClick]
	);
	const addressUrl = useMemo(
		() =>
			apiConfig.maps.location({
				streetName,
				streetNumber,
				postalCode,
				// @ts-ignore
				country,
				city,
				primary,
			}),
		[streetName, streetNumber, postalCode, country, city, primary]
	);
	const state = deprecated ? "deprecated" : primary ? "primary" : "";
	const fullCountryName = countriesList.find((countryItem) => countryItem.alpha2 === country)?.[intl.locale];

	return (
		<article
			role="button" //eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
			data-state={state}
			onClick={handleClick}
			onKeyUp={handleKeyUp}
			className={`m-card m-card--location ${disabled && "disabled"} ${className}`}
		>
			<Avatar src={addressUrl} shape="square" size={64} style={{ minWidth: "64px" }} />
			<div className="card--content">
				{primary && !deprecated && (
					<Tag color="gold" className="t-gap--bottom-xs">
						<FormattedMessage id="MOLECULES.LOCATION_CARD.PRIMARY_ADDRESS" />
					</Tag>
				)}
				<p className="semi-bold">
					{streetName} {streetNumber}
				</p>
				<p className="font-size-sm">
					{postalCode} {city}, {fullCountryName || country}
				</p>
			</div>
			{allowEditing ? (
				<Component.AIcon type="edit" className="card--edit-icon" />
			) : (
				<EyeOutlined className="card--edit-icon" style={{ fontSize: "2em" }} />
			)}
		</article>
	);
}
