import "./style.scss";

import NavigationBar from "@/flows/send/components/Navigation";
import { Alert, Button, Checkbox, Table, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import ContactFilter from "@/flows/send/components/ContactSelection/components/ContactFilter";
import React, { useMemo } from "react";
import { selectedQuery } from "@/flows/send/components/ContactSelection/interface";
import { trpc } from "@/trpc";
import SkeletonTable from "@/shared/components/table/skeleton";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { IContact, IFlowPopulated, TProgress } from "@bothive_core/database";

export default function ContactSelection({
	setCurrentStep,
	setSelectedContacts,
	flow,
	reportSelection = [],
	query,
	setQuery,
	defaultProgress,
	sendToCompletedReports,
	setSendToCompletedReports,
}: {
	setCurrentStep: (value: number) => void;
	setSelectedContacts: (value: Partial<IContact>[]) => void;
	flow?: IFlowPopulated;
	reportSelection?: string[];
	query: selectedQuery | undefined;
	setQuery: (value: selectedQuery) => void;
	defaultProgress?: TProgress[];
	sendToCompletedReports: boolean;
	setSendToCompletedReports: (value: boolean) => void;
}) {
	const intl = useIntl();

	const validateEmail = (email: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

	const generateQuery = () => {
		switch (query?.type) {
			case "label":
				return {
					labelIds: query.value,
				};
			case "contact":
				return {
					contactIds: query.value,
				};
			case "accountManager":
				return {
					accountManagerIds: query.value,
				};
		}
	};

	const generatedQuery = useMemo(() => generateQuery(), [query]);

	const { data: contacts, isLoading: isContactLoading } = trpc.contact.getAllContacts.useQuery(
		{
			limit: 0,
			...generatedQuery,
		},
		{
			enabled: generatedQuery !== undefined,
		}
	);

	const { data: progressContacts, isLoading: isProgressContactLoading } =
		trpc.contact.getContactsByFlowProgress.useQuery(
			{
				flowId: flow?.id,
				// @ts-ignore
				progress: query?.value || [],
			},
			{
				enabled: query?.type === "progress",
			}
		);

	const { data: reportContacts, isLoading: isReportContactLoading } = trpc.contact.getContactsByReportIds.useQuery(
		{
			reportIds: reportSelection,
		},
		{
			enabled: !!reportSelection.length,
		}
	);

	const inValidContacts = useMemo(() => {
		const contactData = generatedQuery
			? contacts?.data
			: reportSelection.length
			? reportContacts
			: progressContacts;

		return (
			contactData?.filter(
				(contact) => !contact?.email?.find((email) => email.isPrimary && validateEmail(email.value))
			) || []
		);
	}, [contacts, progressContacts, reportSelection, reportContacts]);

	const validContacts = useMemo(() => {
		const contactData = contacts?.data || progressContacts || reportContacts || [];

		return (
			contactData?.filter((contact) =>
				contact?.email?.find((email) => email.isPrimary && validateEmail(email.value))
			) || []
		);
	}, [contacts, progressContacts, reportSelection, reportContacts]);

	return (
		<div style={{ width: "100%", display: "flex", gap: "2em", flexDirection: "column", maxWidth: "722px" }}>
			<div style={{ display: "flex", flexDirection: "column", gap: "2em", paddingBottom: "12em" }}>
				<header className="m-header m-header--description">
					<h1>
						<FormattedMessage id="FLOWS.SEND.CONTACT_SELECTION.TITLE" defaultMessage="Contact selection" />
					</h1>
					<p>
						<FormattedMessage
							id="FLOWS.SEND.CONTACT_SELECTION.DESCRIPTION"
							defaultMessage="Select contacts by filters"
						/>
					</p>
				</header>
				{reportSelection?.length <= 0 && (
					<ContactFilter setQuery={setQuery} defaultProgress={defaultProgress} />
				)}
				<Checkbox
					value={sendToCompletedReports}
					onChange={() => setSendToCompletedReports(!sendToCompletedReports)}
				>
					<FormattedMessage
						id="FLOWS.SEND.CONTACT_SELECTION.SEND_TO_COMPLETED_REPORTS"
						defaultMessage="Also send to contacts that already completed this flow"
					/>
				</Checkbox>
				{inValidContacts?.length > 0 && (
					<Alert
						message={intl.formatMessage(
							{
								id: "FLOWS.SEND.CONTACT_SELECTION.ALERT",
								defaultMessage:
									"{count, plural, =1 {# contact} other {# contacts}} will not receive your message as they do not have an email address.",
							},
							{
								count: inValidContacts?.length || 0,
							}
						)}
						type="warning"
						showIcon
						style={{ textAlign: "left" }}
					/>
				)}
				{(contacts?.data?.length || progressContacts?.length || reportContacts?.length) && (
					<SkeletonTable
						rowKey="id"
						dataSource={contacts?.data || progressContacts || reportContacts || []}
						loading={
							generatedQuery !== undefined
								? isContactLoading
								: reportContacts
								? isReportContactLoading
								: isProgressContactLoading
						}
						rowClassName="layout-import--table-row"
						pagination={{
							showSizeChanger: true,
							showQuickJumper: true,
							defaultPageSize: 20,
							pageSizeOptions: ["20", "50", "100"],
						}}
					>
						<Table.Column
							key="username"
							dataIndex={[]}
							title={
								<FormattedMessage
									id="FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.NAME"
									defaultMessage="Name"
								/>
							}
							render={(item) => (
								<Typography.Text>
									{item?.username || `${item?.firstName || ""} ${item?.lastName || ""}`}
								</Typography.Text>
							)}
						/>
						<Table.Column
							key="email"
							dataIndex={[]}
							title={
								<FormattedMessage
									id="FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.EMAIL"
									defaultMessage="Email"
								/>
							}
							render={(item) => (
								<Typography.Text>{item?.email.find((email) => email.isPrimary)?.value}</Typography.Text>
							)}
						/>
						<Table.Column
							key=""
							dataIndex={[]}
							title={
								<FormattedMessage
									id="FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.CAN_BE_SENT"
									defaultMessage="Can be sent"
								/>
							}
							sorter={(a: IContact, b: IContact) => {
								const aPrimary = a?.email?.find(
									(email) => email?.isPrimary && validateEmail(email?.value)
								);
								const bPrimary = b?.email?.find(
									(email) => email.isPrimary && validateEmail(email?.value)
								);

								if (aPrimary && !bPrimary) return -1;
								else if (!aPrimary && bPrimary) return 1;

								return 0;
							}}
							render={(item) => {
								if (
									item?.email.find(
										(email) =>
											email.isPrimary &&
											/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email?.value)
									)
								) {
									return (
										<div style={{ display: "flex", gap: "0.5em", alignItems: "center" }}>
											<CheckCircleTwoTone twoToneColor="#52c41a" />
											<Typography.Text>
												<FormattedMessage
													id="FLOWS.SEND.CONTACT_SELECTION.TABLE.CAN_BE_SENT"
													defaultMessage="Can be sent"
												/>
											</Typography.Text>
										</div>
									);
								} else {
									return (
										<div style={{ display: "flex", gap: "0.5em", alignItems: "center" }}>
											<CloseCircleTwoTone twoToneColor="#de5050" />
											<Typography.Text>
												<FormattedMessage
													id="FLOWS.SEND.CONTACT_SELECTION.TABLE.CANNOT_BE_SENT"
													defaultMessage="Email missing or invalid"
												/>
											</Typography.Text>
										</div>
									);
								}
							}}
						/>
					</SkeletonTable>
				)}
			</div>
			<NavigationBar>
				<Button
					type="primary"
					style={{ margin: "1em" }}
					onClick={() => {
						setSelectedContacts(validContacts);

						setCurrentStep(1);
					}}
					disabled={validContacts.length === 0}
				>
					<FormattedMessage id="FLOWS.SEND.CONTACT_SELECTION.BUTTONS.NEXT" defaultMessage="Next" />
				</Button>
			</NavigationBar>
		</div>
	);
}
