import { useParams } from "react-router";
import React, { useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Alert, Button, Form, InputRef, Modal, Switch, Tooltip } from "antd";
import { trpc } from "../../../../../../../trpc";
import ShowSuccessBanner from "../../../../../../../shared/components/banner/success";
import ShowFailedBanner from "../../../../../../../shared/components/banner/failed";
import FieldSideLabel from "../../../../../../../shared/components/field/side_label";
import { useSetFocus } from "../../../../../../../shared/hooks";
import AddressForm from "@/shared/components/form/contacts/AddressForm";

export default function AddAddressModal({
	showCreateModal,
	setShowCreateModal,
	showUpdateModal,
	setShowUpdateModal,
	address,
	isThereAPrimaryAddress,
	setShowDeleteModal,
}: {
	showCreateModal: boolean;
	setShowCreateModal: (value: boolean) => void;
	showUpdateModal: any;
	setShowUpdateModal: (value: any) => void;
	address: any;
	isThereAPrimaryAddress: boolean;
	setShowDeleteModal: (value: boolean) => void;
}) {
	const [showPrimaryWarning, setShowPrimaryWarning] = React.useState<boolean>(false);
	const [isSwitchChecked, setIsSwitchChecked] = React.useState<boolean | undefined>(address?.primary);

	const intl = useIntl();
	const [form] = Form.useForm();
	const params: { contactId: string } = useParams();
	const trpcUtils = trpc.useUtils();

	const addAddressMutation = trpc.contact.addAddress.useMutation();
	const updateAddressMutation = trpc.contact.updateAddress.useMutation();

	const inputRef = useRef<InputRef>(null);

	useEffect(() => {
		form.resetFields();

		form.setFieldsValue({
			...address,
		});
	}, [address, showCreateModal]);

	useSetFocus({ ref: inputRef, dependencies: [inputRef, showCreateModal] });

	const addAddress = (values: any) => {
		addAddressMutation.mutate(
			{ contactId: params.contactId, address: { ...values, primary: isSwitchChecked } },
			{
				onSuccess: () => {
					trpcUtils.contact.getContactById.invalidate();

					setShowCreateModal(false);
					setShowPrimaryWarning(false);
					setIsSwitchChecked(false);

					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.SUCCESS",
							defaultMessage: "Address successfully added",
						}),
					});
				},
				onError: (error) => {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.FAILED",
							defaultMessage: "Address failed to add",
						}),
					});
				},
			}
		);
	};

	const updateAddress = (values: any) => {
		if (!address) return;

		updateAddressMutation.mutate(
			{
				contactId: params.contactId,
				addressId: address._id,
				address: { ...values, primary: isSwitchChecked || address?.primary },
			},
			{
				onSuccess: () => {
					trpcUtils.contact.getContactById.invalidate();

					setShowUpdateModal(false);
					setShowPrimaryWarning(false);

					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.UPDATE.ADDRESS.SUCCESS",
							defaultMessage: "Address successfully updated",
						}),
					});
				},
				onError: () => {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.UPDATE.ADDRESS.FAILED",
							defaultMessage: "Address failed to update",
						}),
						intl,
					});
				},
			}
		);
	};

	const modalButtons = () => {
		if (showUpdateModal) {
			return [
				<Button danger key="delete" type="primary" onClick={() => setShowDeleteModal(true)}>
					<FormattedMessage id="SETTINGS.INBOX.LABELS.MODAL.UPDATE.DELETE" />
				</Button>,
				<Button key="submit" type="primary" form="addAddressForm" htmlType="submit" className="t-gap--left-sm">
					<FormattedMessage id="FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT" />
				</Button>,
			];
		}

		if (showCreateModal) {
			return [
				<Button key="submit" type="primary" form="addAddressForm" htmlType="submit">
					<FormattedMessage id="FLOWS.REPORTS.STATUS_TYPES.MODAL.ADD" />
				</Button>,
			];
		}
	};

	const generateModalTitle = () => {
		if (showUpdateModal) {
			return intl.formatMessage({ id: "CONTACT.DETAIL.ATTRIBUTES.UPDATE.ADDRESS" });
		}

		if (showCreateModal) {
			return intl.formatMessage({ id: "CONTACT.DETAIL.ATTRIBUTES.ADD.ADDRESS" });
		}
	};

	return (
		<Modal
			title={generateModalTitle()}
			open={!!showCreateModal || !!showUpdateModal}
			onCancel={() => {
				setIsSwitchChecked(undefined);
				setShowCreateModal(false);
				setShowUpdateModal(false);
			}}
			width={700}
			transitionName="" // to disable animation
			footer={modalButtons()}
		>
			{showPrimaryWarning && (
				<Alert
					message={intl.formatMessage({
						id: "CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PRIMARY.CAUTION.TITLE",
					})}
					description={intl.formatMessage({
						id: "CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PRIMARY.CAUTION.DESCRIPTION",
					})}
					type="warning"
					showIcon
					className="t-gap--bottom"
				/>
			)}
			<Form
				form={form}
				layout="vertical"
				className="frsf-form"
				id="addAddressForm"
				onFinish={(values) => {
					if (!address) {
						addAddress(values);
					}

					updateAddress(values);
				}}
			>
				<AddressForm inputRef={inputRef} />
				<FieldSideLabel
					label="CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PRIMARY"
					id="primary"
					help={undefined}
					error={undefined}
					optional={undefined}
					type={undefined}
					className="t-gap--top-sm"
					bodyClassName={undefined}
					intl={undefined}
				>
					<Form.Item name="primary" style={{ width: "100%", textAlign: "right" }}>
						<Tooltip
							title={
								isSwitchChecked || address?.primary
									? intl.formatMessage({ id: "CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.PRIMARY.TOOLTIP" })
									: undefined
							}
						>
							<Switch
								checked={isSwitchChecked || address?.primary}
								onChange={(value) => {
									if (value) {
										setIsSwitchChecked(true);
									} else {
										setIsSwitchChecked(false);
									}

									if (value && isThereAPrimaryAddress) {
										setShowPrimaryWarning(true);
									} else {
										setShowPrimaryWarning(false);
									}
								}}
							/>
						</Tooltip>
					</Form.Item>
				</FieldSideLabel>
			</Form>
		</Modal>
	);
}
