import { ContactRouterOutput, trpc } from "@/trpc";
import { DownOutlined, FolderOpenOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Dropdown, notification, Space, Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilState } from "recoil";
import { selectedAllArchivedContactsState, selectedArchivedContactsState } from "../state";

interface RestoreProps {
	filters: {
		q?: string;
		contactType?: string;
		labelId?: string;
		accountManagerId?: string;
	};
	contacts?: ContactRouterOutput["getAllContacts"];
}

const restoreOptions = ["restore_contact", "restore_contact_as_archived"] as const;
type TRestoreOptions = (typeof restoreOptions)[number];

export default function Restore({ filters, contacts }: RestoreProps) {
	const [selectedContacts, setSelectedContacts] = useRecoilState(selectedArchivedContactsState);
	const [selectedAll, setSelectedAll] = useRecoilState(selectedAllArchivedContactsState);
	const trpcUtils = trpc.useUtils();
	const intl = useIntl();

	const bulkRestoreContactsMutation = trpc.contact.bulkRestoreContacts.useMutation({
		onSuccess: (result) => {
			notification.success({
				message: intl.formatMessage(
					{
						id: "CONTACT.OVERVIEW.BULK_ACTION.RESTORE.SUCCESS",
						defaultMessage: "{count, plural, one {# contact} other {# contacts}} restored successfully.",
					},
					// @ts-ignore for some reason it complains in this component and not in the others
					{ count: result.nModified }
				),
				placement: "bottomRight",
			});

			trpcUtils.contact.getAllContacts.invalidate();
			setSelectedAll(false);
			setSelectedContacts([]);
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "CONTACT.OVERVIEW.BULK_ACTION.RESTORE.ERROR",
					defaultMessage: "Something went wrong while executing bulk restore.",
				}),
				placement: "bottomRight",
			});
		},
	});

	const handleRestore = (event: { key: TRestoreOptions }) => {
		bulkRestoreContactsMutation.mutate({
			contactIds: selectedContacts,
			restoreAll: selectedAll,
			restoreAsArchived: event.key === "restore_contact_as_archived",
			filters,
		});
	};

	const { data: allArchivedContacts } = trpc.contact.getAllContacts.useQuery(
		{
			limit: 0,
			...filters,
			archived: true,
		},
		{ enabled: selectedAll }
	);

	// Enable restore only when there is no contact without deletedAt
	const preventFromRestoreAmount = selectedAll
		? // @ts-ignore
		  allArchivedContacts?.data?.filter((contact) => !contact.deletedAt)?.length
		: // @ts-ignore
		  contacts?.data?.filter((contact) => selectedContacts.includes(contact._id) && !contact.deletedAt)?.length;

	const menuProps = {
		items: [
			{
				label: intl.formatMessage({
					id: "CONTACTS.OVERVIEW.BULK_ACTION.RESTORE.BUTTON.TITLE",
					defaultMessage: "Restore",
				}),
				key: "restore_contact",
				icon: <UndoOutlined />,
			},
			{
				label: intl.formatMessage({
					id: "CONTACTS.OVERVIEW.BULK_ACTION.RESTORE_AS_ARCHIVED.BUTTON.TITLE",
					defaultMessage: "Restore as archived",
				}),
				key: "restore_contact_as_archived",
				icon: <FolderOpenOutlined />,
			},
		] satisfies { [key: string]: any; key: TRestoreOptions }[],
		onClick: (event: any) => {
			handleRestore({ key: event.key as TRestoreOptions });
		},
	};

	return (
		<Dropdown disabled={!!preventFromRestoreAmount} menu={menuProps}>
			<Tooltip
				title={
					!!preventFromRestoreAmount &&
					intl.formatMessage(
						{
							id: "CONTACTS.OVERVIEW.BULK_ACTION.RESTORE.BUTTON.DISABLED.TOOLTIP",
							defaultMessage:
								"You have {count, plural, one {# archived contact} other {# archived contacts}} that are not deleted inside your selection, you can't perform an restore action on contacts that are only archived",
						},
						{
							count: preventFromRestoreAmount,
						}
					)
				}
			>
				<Button
					disabled={!!preventFromRestoreAmount}
					type="text"
					icon={<UndoOutlined />}
					loading={bulkRestoreContactsMutation.isLoading}
				>
					{" "}
					<Space>
						<FormattedMessage
							id="CONTACT.OVERVIEW.BULK_ACTION.RESTORE.BUTTON.TITLE"
							defaultMessage="Restore"
						/>
						<DownOutlined />
					</Space>
				</Button>
			</Tooltip>
		</Dropdown>
	);
}
