import { trpc } from "@/trpc";
import { DatePicker, Form, Input, Row, Switch, Col } from "antd";
import qs from "qs";
import type { Dayjs } from "dayjs";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import { stringHelpers } from "@bothive/helpers";
import { useHistory } from "react-router-dom";
import { debounce } from "@/helpers";
import InboxLabelSelect from "@/modules/inbox/components/InboxLabelSelect";

const INPUT_DEBOUNCE = 500;
const ALLOWED_SEARCH_PARAMETERS = ["q", "inboxLabelIds", "fromDate", "until", "hasAttachments"] as const;

interface SearchQuery {
	q?: string;
	inboxLabelIds?: string[];
	hasAttachments?: boolean;
	fromDate?: string;
	until?: string;
}

export function getSearchQueryParameters(): Partial<SearchQuery> {
	const params = qs.parse<SearchQuery>(location.search, { ignoreQueryPrefix: true });

	if (!params) return {};

	return Object.keys(params).reduce((prev, key) => {
		let value: string | number | string[] | Date | boolean | dayjs.Dayjs = decodeURI(params[key]);

		const arrayKeys = ["inboxLabelIds"];

		if (!ALLOWED_SEARCH_PARAMETERS.includes(key)) return prev;

		if (key === "fromDate" || key === "until") value = dayjs.unix(Number(value));
		if (arrayKeys.includes(key))
			value = (value as string).split(",").filter((value) => !stringHelpers.isEmptyString(value));
		if (value === "true") value = true; // qs doesn't parse boolean

		return { ...prev, [key]: value };
	}, {});
}

interface ContactConversationFiltersProps {
	showFilters: boolean;
}

export default function ContactConversationFilters({ showFilters }: ContactConversationFiltersProps) {
	const intl = useIntl();
	const [form] = Form.useForm<SearchQuery>();
	const history = useHistory();

	const setSearch = (queryParams: SearchQuery) => {
		const filterQueryParams = Object.keys(queryParams).reduce((prev, key) => {
			let value = queryParams[key];

			if (value === true) value = "true"; // qs doesn't stringify boolean
			if (key === "fromDate" || key === "until") value = value?.unix();
			if (!value || stringHelpers.isEmptyString(value)) return prev;
			if (Array.isArray(value) && !value.length) return prev;

			return { ...prev, [key]: encodeURI(value) };
		}, {});

		let pathname = location.pathname;

		if (!Object.keys(filterQueryParams).length) {
			history.replace({ pathname, search: undefined });
			return;
		}

		const search = qs.stringify(filterQueryParams);

		history.replace({ pathname, search });
	};

	const untilDate = Form.useWatch<Dayjs>("until", form);
	const fromDate = Form.useWatch<Dayjs>("fromDate", form);

	const inboxLabelQuery = trpc.inbox.inboxLabel.getAll.useQuery({ limit: 0 });

	function handleValuesChanged(changedValues: Partial<SearchQuery>, allValues: SearchQuery) {
		// Only debounce search
		if (changedValues.q && Object.keys(changedValues).length === 1) {
			debounce(() => setSearch({ ...getSearchQueryParameters(), q: changedValues.q }), INPUT_DEBOUNCE);
			return;
		}

		setSearch(allValues);
	}

	if (!showFilters) return null;

	return (
		<header
			style={{
				textAlign: "left",
				padding: "var(--spacer-md) var(--spacer-md) 0",
			}}
		>
			<Form
				layout="vertical"
				onValuesChange={handleValuesChanged}
				initialValues={getSearchQueryParameters()}
				form={form}
			>
				<Row>
					<Col flex={1}>
						<Form.Item
							name="q"
							label={intl.formatMessage({
								id: "contact.conversations.filter.form.q.label",
								defaultMessage: "Search",
							})}
						>
							<Input
								size="large"
								allowClear
								placeholder={intl.formatMessage({
									id: "contact.conversations.filter.form.q",
									defaultMessage: "Search for subject, content, e-mail address, ...",
								})}
							></Input>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={11}>
						<Form.Item
							name="inboxLabelIds"
							label={intl.formatMessage({
								id: "contact.conversations.filter.form.inboxLabels.label",
								defaultMessage: "Filter on inbox label(s)",
							})}
						>
							<InboxLabelSelect
								size="large"
								allowClear
								mode="multiple"
								placeholder={intl.formatMessage({
									id: "contact.conversations.filter.form.inboxLabels",
									defaultMessage: "Filter on inbox label(s)",
								})}
								inboxLabels={inboxLabelQuery.data?.data}
								isLabelLoading={inboxLabelQuery.isLoading}
							/>
						</Form.Item>
					</Col>
					<Col span={5}>
						<Form.Item
							name="fromDate"
							label={intl.formatMessage({
								id: "contact.conversations.filter.form.from_date.label",
								defaultMessage: "From",
							})}
						>
							<DatePicker
								style={{ width: "100%" }}
								size="large"
								format="DD-MM-YYYY"
								disabledDate={(currentDate) => (untilDate ? currentDate > untilDate : false)}
							/>
						</Form.Item>
					</Col>
					<Col span={5}>
						<Form.Item
							name="until"
							label={intl.formatMessage({
								id: "contact.conversations.filter.form.until.label",
								defaultMessage: "Until",
							})}
						>
							<DatePicker
								style={{ width: "100%" }}
								size="large"
								format="DD-MM-YYYY"
								disabledDate={(currentDate) => (fromDate ? currentDate < fromDate : false)}
							/>
						</Form.Item>
					</Col>
					<Col span={3}>
						<Form.Item
							name="hasAttachments"
							label={intl.formatMessage({
								id: "contact.conversations.filter.form.has_attachments.label",
								defaultMessage: "Has attachments",
							})}
							valuePropName="checked"
						>
							<Switch />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</header>
	);
}
