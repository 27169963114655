import { Alert, Button, Form, Modal, Select, Switch } from "antd";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { selectedFlowIdState } from "@/flows/common/state";
import { getBillingWarning } from "@/flows/flow_detail/status_types/status_modal/template/helper";
import { useFlowList } from "@/flows/overview/hook";
import { debounce } from "@/helpers";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import FieldSideLabel from "@/shared/components/field/side_label";

import config from "../../config";
import { statusTypesList, statusTypesMeta } from "../../state";
import { cleanFlowList } from "./helper";
import { copyStatusTypes } from "./query";

export default function CopyStatusTypesModal({ isVisible, onClose }) {
	const intl = useIntl();
	const [flows, fetchFlowList] = useFlowList();
	const [isCopying, setIsCopying] = useState(false);
	const flowId = useRecoilValue(selectedFlowIdState);
	const [statusTypes, setStatusTypes] = useRecoilState(statusTypesList);
	const setStatusTypeMetaData = useSetRecoilState(statusTypesMeta);
	const maxStatusTypes = useSelector((state) => state.dashboard.permissions.statusTypes);
	const limitWarning = getBillingWarning({ total: statusTypes?.length, limit: maxStatusTypes });

	const flowList = useMemo(
		() =>
			cleanFlowList({
				flows: flows.contents,
				language: intl.locale,
				flowId,
			}),
		[flowId, flows.contents]
	);

	const handleSearch = async (searchValue) => {
		debounce(() => {
			fetchFlowList({ q: searchValue });
		});
	};

	const handleSubmit = async ({ flow, overwrite }) => {
		try {
			setIsCopying(true);

			const result = await copyStatusTypes({ flowId, payload: { flow, overwrite } });

			let newStatusTypes = result.data;

			if (!overwrite) {
				newStatusTypes = [...result.data, ...statusTypes];
			}

			setStatusTypes(newStatusTypes);
			setStatusTypeMetaData({
				offset: 0,
				total: result.data.length,
				limit: config.pagination.limit,
			});
			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORTS.STATUS_TYPES.COPY.SUCCEEDED",
					defaultMessage: "Status types have been copied.",
				}),
			});
			onClose();
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORTS.STATUS_TYPES.COPY.FAILED",
					defaultMessage: "Failed to copy status types, try again later.",
				}),
			});
		}

		setIsCopying(false);
	};

	return (
		<Modal
			title={intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.TITLE" })}
			destroyOnClose
			open={isVisible}
			transitionName="" // to disable animation
			onCancel={onClose}
			footer={[
				<Button
					key="submit"
					type="primary"
					form="CopyStatusTypesModal"
					htmlType="submit"
					loading={isCopying}
					disabled={statusTypes?.length >= maxStatusTypes}
				>
					<FormattedMessage id="FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY" />
				</Button>,
			]}
		>
			{limitWarning && (
				<Alert
					showIcon
					type="warning"
					className="t-gap--bottom-sm"
					message={intl.formatMessage(
						{ id: limitWarning },
						{
							currentStatusTypes: statusTypes?.length,
							maxStatusTypes,
						}
					)}
				/>
			)}
			<Form
				layout="vertical"
				requiredMark="optional"
				id="CopyStatusTypesModal"
				onFinish={(values) => handleSubmit(values)}
			>
				<Form.Item
					label={intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.FLOWS" })}
					name="flow"
					rules={[
						{
							required: true,
							message: intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.FLOWS.REQUIRED" }),
						},
					]}
				>
					<Select
						showSearch
						filterOption={false}
						loading={flows?.state === "loading"}
						onSearch={handleSearch}
						notFoundContent={intl.formatMessage({
							id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.FLOWS.SEARCH_NOT_FOUND",
						})}
					>
						{flows?.state === "hasValue" &&
							flowList.map((flow) => (
								<Select.Option key={flow._id} value={flow._id}>
									{flow?.template?.name?.[intl.locale] || flow?.template?.name?.nl}
								</Select.Option>
							))}
					</Select>
				</Form.Item>

				<FieldSideLabel
					label={intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.OVERWRITE" })}
					help={intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.OVERWRITE.HELP" })}
				>
					<Form.Item name="overwrite" valuePropName="checked">
						<Switch />
					</Form.Item>
				</FieldSideLabel>
			</Form>
		</Modal>
	);
}
