import axios from "axios";
import qs from "qs";

import config from "@/flows/flow_detail/reports/config";

export const initReportBulkDownload = async ({
	flowId,
	downloadAttachments,
	reportType,
	reportIds,
	statusId,
	contactLabel,
	q,
	progress,
}) => {
	const body = {
		downloadAttachments,
		contactLabel,
		reportType,
		reportIds,
		statusId,
		q,
		progress,
	};

	return await axios.post(`${config.api.baseUrl}/api/v1/flows/${flowId}/reports/bulk_download`, body);
};

export const initChangeStatus = async ({ flowId, newStatusId, reportIds, statusId, q, progress, contactLabel }) => {
	const body = {
		newStatusId,
		statusId,
		q,
		progress,
		reportIds,
		contactLabel,
	};

	return await axios.post(`${config.api.baseUrl}/api/v1/flows/${flowId}/reports/bulk_status_change`, body);
};

export const initSendToIntegration = async ({
	reportType,
	duplicates,
	reportIds,
	statusId,
	progress,
	contactLabel,
	ruleId,
	flowId,
	q,
}) => {
	const body = {
		reportType,
		duplicates,
		contactLabel,
		reportIds,
		statusId,
		progress,
		ruleId,
		q,
	};

	return await axios.post(`${config.api.baseUrl}/api/v1/flows/${flowId}/reports/bulk_integration_sending`, body);
};
