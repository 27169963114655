import React, { Suspense } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { NavLink } from "react-router-dom";
import { Aside } from "../../components/organisms";
import { Layout } from "../../components/templates";
import { routesConfig } from "../../config";
import { keyEvents, localDbHelpers, storageHelpers } from "../../helpers";
import DashboardHeader from "../../shared/components/header/dashboardHeader";
import Shortcuts from "../../shortcuts";
import { actions as contentActions } from "../../store/content";
import { actions as dashboardActions } from "../../store/dashboard";
import InitDashboard from "./pages.dashboard.init";

// TODO to many subscriptions will trigger unnecessary rerenders
const mapStateToProps = (state) => ({
	team: state.content.team.team,
	user: state.profile.account.user,
	showShortcut: state.dashboard.ui.showShortcut,
	teamIsFetching: state.content.team.isFetching,
	fetchingFailed: state.content.team.fetchingFailed,
	title: state.dashboard.ui.title || [],
	breadcrumbs: state.dashboard.ui.breadcrumbs || [],
	hideHeader: state.dashboard.ui.config?.hideHeader || false,
	fetchingSucceeded: state.content.team.fetchingSucceeded,
});

class DashboardTemplate extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		localDbHelpers.init();
		this.props.checkIfNetworkIsOnline();

		if (this.props.match.params.team) {
			const slug =
				this.props.match.params.team === routesConfig.profile.root.replace("/", "")
					? storageHelpers.getTeamSlug()
					: this.props.match.params.team;

			this.props.fetchTeam(slug);
		}

		this.bindHandlers();
		window.addEventListener("keyup", this.handleKeyUp);
	}

	componentWillUnmount() {
		window.removeEventListener("keyup", this.handleKeyUp);
	}

	bindHandlers() {
		this.handleKeyUp = this.handleKeyUp.bind(this);
	}

	handleKeyUp(event) {
		if (keyEvents.isInsideEditor({ event }) || keyEvents.isMetaPressed({ event })) {
			return;
		}

		const func = {
			"global.show_shortcuts": this.props.toggleShortcuts,
		};

		const keyEvent = keyEvents.mapShortcutKeysInbox({ event });

		if (func[keyEvent]) {
			event.preventDefault();
			func[keyEvent]();
		}
	}

	componentDidUpdate() {
		if (this.props.teamIsFetching) return;

		if (!this.props.teamIsFetching && !this.props.team) return;

		if (this.props.fetchingFailed && !this.props.team) {
			// If team couldn't be fetched go back to users profile
			this.props.history.push(routesConfig.profile.teams.replace(":slug", this.props.user.slug));
		}
	}

	render() {
		let header;

		if (!this.props.user || !this.props.team || this.props.teamIsFetching) {
			return <Layout.TLoadScreen />;
		}

		if (this.props.user && !this.props.hideHeader) {
			header = (
				<DashboardHeader
					title={this.props.title && this.props.intl.formatMessage({ id: this.props.title })}
					breadcrumbs={this.props.breadcrumbs?.map((item) => ({
						key: item.to,
						value: (
							<NavLink to={item.to}>
								<FormattedMessage id={item.name} />
							</NavLink>
						),
					}))}
				/>
			);
		}

		return (
			<div className="t-layout--template">
				<Aside.OAsideDashboard />
				<InitDashboard />
				<div className="t-layout--dashboard-template-content">
					{header}
					{this.props.children}
				</div>
				{/* don't show a fallback this will break the layout because all these components are absolute positioned */}
				<Suspense fallback={<div />}>
					<Shortcuts isVisible={this.props.showShortcut} onClose={this.props.toggleShortcuts} />
				</Suspense>
			</div>
		);
	}
}

export default withRouter(
	connect(mapStateToProps, {
		fetchTeam: contentActions.team.fetchTeam,
		toggleShortcuts: dashboardActions.ui.toggleShortcuts,
		checkIfNetworkIsOnline: dashboardActions.ui.checkIfNetworkIsOnline,
	})(injectIntl(DashboardTemplate))
);
