import React from "react";

import { logo } from "../../../assets/images";

export default function ALogo({ type, className }) {
	return (
		<img
			src={type === "white" ? logo.logoWhite : logo.logo}
			alt="logo"
			className={`a-image a-image--logo ${className}`}
		/>
	);
}
