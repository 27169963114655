import "./style.scss";

import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { $generateNodesFromDOM } from "@lexical/html";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import {
	$createParagraphNode,
	$createRangeSelection,
	$insertNodes,
	$setSelection,
	LexicalEditor,
	TextNode,
} from "lexical";
import { MutableRefObject, memo, useMemo } from "react";

import {
	$isSignatureNode,
	$isThreadNode,
	TableNode as CustomTableNode,
	ExtendedTextNode,
	ImageNode,
	KeywordNode,
	SignatureNode,
	ThreadNode,
} from "../nodes";
import { TOnImageUpload } from "../nodes/image_node/imageNode";
import { htmlTheme } from "../theme";
import Editor from "./editor";
import { OptionTools } from "./types";

interface HtmlEditorComposerProps {
	value?: string;
	channelId?: string;
	className?: string;
	tools?: OptionTools[];
	editorRef?: MutableRefObject<LexicalEditor | undefined>;
	onChange?: (value: string) => void;
	onImageUpload?: TOnImageUpload;
	onImageDelete?: (fileId: string) => void;
	onFileUpload?: (params: { file: File }) => void;
}

// TODO
// - Table resize
// - Extend html table: merge actions

function HtmlEditor({
	value,
	channelId,
	className,
	editorRef,
	tools = [],
	onFileUpload,
	onImageUpload,
	onImageDelete,
	onChange,
}: HtmlEditorComposerProps) {
	const editorConfig = useMemo(
		() => ({
			namespace: "htmlEditor",
			theme: htmlTheme,
			editorState: (editor) => {
				const parser = new DOMParser();
				const dom = parser.parseFromString(value || "", "text/html");
				const nodes = $generateNodesFromDOM(editor, dom);

				try {
					$insertNodes(nodes);
				} catch (_) {
					try {
						const topNode = $createParagraphNode();

						$insertNodes([topNode, ...nodes]);
					} catch (error) {
						console.error("Html editor error", error);
					}
				}

				if (nodes.findIndex((node) => $isThreadNode(node) || $isSignatureNode(node)) !== -1) {
					// If thread or signature node is found move cursor to the first position
					$setSelection($createRangeSelection());
				}
			},

			// Handling of errors during update
			onError(error) {
				throw error;
			},
			nodes: [
				ExtendedTextNode,
				{
					replace: TextNode,
					with: (node: TextNode) => new ExtendedTextNode(node.__text, node.__key),
				},
				// Node order is important
				SignatureNode,
				ThreadNode,
				TextNode,
				HeadingNode,
				ListNode,
				ListItemNode,
				QuoteNode,
				CodeNode,
				CodeHighlightNode,
				ImageNode,
				CustomTableNode,
				TableNode,
				TableCellNode,
				TableRowNode,
				AutoLinkNode,
				LinkNode,
				KeywordNode,
				HorizontalRuleNode,
			],
		}),
		[value]
	);

	return (
		<LexicalComposer initialConfig={editorConfig}>
			<Editor
				value={value}
				tools={tools}
				channelId={channelId}
				editorRef={editorRef}
				onChange={onChange}
				onFileUpload={onFileUpload}
				onImageUpload={onImageUpload}
				onImageDelete={onImageDelete}
				className={className}
			/>
		</LexicalComposer>
	);
}

export default memo(HtmlEditor);
