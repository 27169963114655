import { ReportRouterOutput } from "@/trpc";
import { Alert } from "antd";
import { FormattedDate, FormattedMessage } from "react-intl";
import "./style.scss";
import { IQueue } from "@bothive_core/database";

export default function ReminderStats({ report }: { report: ReportRouterOutput["getById"] }) {
	const succeeded = report.flowReminderQueueHistory.filter((item) => item.status === "SUCCESS") || [];

	if (succeeded.length == 0) return null;

	const lastSuccessFull = succeeded?.reduce((prev: IQueue, current: IQueue) => {
		if (current.index > prev.index || (current.index === prev.index && current.executeAt > prev.executeAt)) {
			return current;
		}
		return prev;
	});

	return (
		<div style={{ textAlign: "left", padding: "0 2em", margin: "2em 0" }}>
			<p className="t-gap--bottom-sm" style={{ fontWeight: 600 }}>
				<FormattedMessage
					id="flows.report_detail.meta_aside.report_info.reminder_history.label"
					defaultMessage="Reminders:"
				/>
			</p>
			<div className="report-detail__reminders-detail" style={{ textAlign: "left" }}>
				<Alert
					type="info"
					style={{ gridColumn: "1 / 3" }}
					message={
						<>
							<p>
								<FormattedMessage
									id="flows.report_detail.meta_aside.report_info.reminder_history.total_send"
									defaultMessage="Reminder {index} of {total} sent"
									description="This is the first part of a bigger: Reminder 1 of 5 sent on 30 march 2023 at 10:00 to email@bothive.be."
									values={{
										index: lastSuccessFull?.index,
										total: report.flowReminderSetting?.settings.total || 0,
									}}
								/>
							</p>
							<p>
								<FormattedMessage
									id="flows.report_detail.meta_aside.report_info.reminder_history.send_on"
									defaultMessage="on {executeAt}"
									description="This is the second part of a bigger: Reminder 1 of 5 sent on 30 march 2023 at 10:00 to email@bothive.be."
									values={{
										executeAt: (
											<FormattedDate
												value={lastSuccessFull.executeAt}
												day="numeric"
												month="long"
												year="numeric"
												hour="2-digit"
												minute="2-digit"
												formatMatcher="basic"
											/>
										),
									}}
								/>
							</p>
							<p>
								<FormattedMessage
									id="flows.report_detail.meta_aside.report_info.reminder_history.send_to"
									defaultMessage="to {email}."
									description="This is the last part of a bigger: Reminder 1 of 5 sent on 30 march 2023 at 10:00 to email@bothive.be."
									values={{ email: lastSuccessFull.email }}
								/>
							</p>
						</>
					}
				/>
			</div>
		</div>
	);
}
