import debounce from "./debounce.helpers";

let windowObjectReference = undefined,
	previousUrl = undefined;

function receiveMessage(event, callback) {
	if (!event?.data?.code) {
		return;
	}

	window.removeEventListener("message", receiveMessage);
	debounce(() => callback(event.data));
}

export default {
	receiveMessage,
	openSignInWindow({ url, name, blank = false, callback }) {
		window.removeEventListener("message", receiveMessage);

		const _name = blank ? "_blank" : name;

		if (!windowObjectReference || windowObjectReference?.closed) {
			windowObjectReference = window.open(url, _name);
		} else if (previousUrl !== url) {
			windowObjectReference = window.open(url, _name);
			windowObjectReference.focus();
		} else {
			windowObjectReference.focus();
		}

		if (
			!windowObjectReference ||
			windowObjectReference.closed ||
			typeof windowObjectReference.closed === "undefined"
		) {
			callback(undefined, { error: "blocked" });
			return;
		}

		window.addEventListener("message", (event) => receiveMessage(event, callback), false);
		previousUrl = url;
	},
};
