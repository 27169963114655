import { useSelector, useDispatch } from "react-redux";
import isEqual from "lodash.isequal";
import "./style.scss";
import CompanySettings from "./components/CompanySettings";
import LocationSettings from "./components/LocationSettings";
import SocialSettings from "./components/SocialSettings";
import RelatedSettings from "./components/RelatedSettings";
import { actions as dashboardActions } from "@/store/dashboard";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import config from "./config";
import { trpc } from "@/trpc";

export default function OrganizationSettings() {
	const routeParameters = useParams();
	const dispatch = useDispatch();
	const intl = useIntl();
	const { isLoading, data } = trpc.organization.getOrganization.useQuery();

	const { allowEditing } = useSelector(
		(state) => ({
			// team: state.content.team.team,
			allowEditing: state.dashboard.permissions.userPermissions.editSettings,
		}),
		isEqual
	);

	useEffect(() => {
		// @ts-ignore
		const breadcrumbs = config.breadcrumbs(routeParameters);

		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					...config,
					breadcrumbs,
				},
				intl,
			})
		);
	}, []);

	return (
		<section className="organizationContainer">
			<CompanySettings allowEditing={allowEditing} organization={data} isLoading={isLoading} />
			<LocationSettings
				allowEditing={allowEditing}
				organization={data}
				isLoading={isLoading}
				className="t-gap--top"
			/>
			<SocialSettings
				allowEditing={allowEditing}
				organization={data}
				isLoading={isLoading}
				className="t-gap--top"
			/>
			<h2 className="t-gap--top" style={{ marginBottom: "10px" }}>
				<FormattedMessage
					id="ORGANIZATION_SETTINGS.GENERAL.SUGGESTIONS.TITLE"
					defaultMessage="Settings you might find useful"
				/>
			</h2>
			<hr />
			<RelatedSettings slug={data?.slug} />
		</section>
	);
}
