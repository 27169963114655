import { actions as dashboardActions } from "@/store/dashboard";
import { trpc } from "@/trpc";
import type { IUser } from "@bothive_core/database";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import DashboardHeader from "../../shared/components/header/dashboardHeader";
import ConversationLoading from "./ConversationView/ConversationContent/components/ConversationLoading";
import SideNav from "./SideNav";
import InboxSearch from "./inboxSearch";
import { locateConversationFolder, locateConversationStatus } from "./utils/locator.utils";

// Page used to load in correct conversation with the correct folders selected for the user
export default function PermaLink() {
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const params: { conversationId: string; team: string } = useParams();

	const user: IUser = useSelector((state: any) => state.profile.account.user, shallowEqual);

	const {
		data: conversation,
		isLoading,
		isError,
	} = trpc.inbox.conversation.getById.useQuery(
		{ conversationId: params.conversationId },
		{ enabled: Boolean(params.conversationId) }
	);

	useEffect(() => {
		dispatch(dashboardActions.ui.foldAside());

		return () => dispatch(dashboardActions.ui.unfoldAside());
	}, []);

	if (!isLoading && !isError && conversation) {
		const participant = conversation?.participants.find((participant) => participant.userId === user._id);

		if (!participant) return history.goBack();

		const status = locateConversationStatus(participant?.labels || []);
		const folder = locateConversationFolder({
			userId: user._id,
			participantLabels: participant?.labels || [],
			assignedToUserId: conversation?.assignedTo as string | undefined,
		});

		history.replace({
			pathname: `/${params.team}/inbox/folder/${folder}/status/${status}/${params.conversationId}`,
		});
		return null;
	}

	if (isError || (!isLoading && !conversation)) {
		history.goBack();
		return null;
	}

	return (
		<main className="inbox_layout">
			<DashboardHeader
				title={intl.formatMessage({ id: "inbox.page.title", defaultMessage: "Inbox (BETA)" })}
				className="inbox_layout-header"
			>
				<InboxSearch />
			</DashboardHeader>
			<div className="inbox_container">
				<SideNav />
				<ConversationLoading />
			</div>
		</main>
	);
}
