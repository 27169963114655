import { Button, Form, Input, Tooltip, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import FieldLabel from "@/shared/components/field/default";
import { storageHelpers } from "@/shared/helpers";
import ShowFailedBanner from "../../../shared/components/banner/failed";
import ShowSuccessBanner from "../../../shared/components/banner/success";
import { actions as profileActions } from "../../../store/profile/account";
import { trpc } from "../../../trpc";
import { userState } from "../state";
import { saveUserTokens } from "../utils";

export default function Login() {
	const intl = useIntl();
	const history = useHistory();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const setUser = useSetRecoilState(userState);
	const userEmail = Form.useWatch("email", form);
	const login = trpc.authentication.login.useMutation();
	const requestPasswordReset = trpc.user.requestPasswordReset.useMutation();

	const handleLogin = (body: { email: string; password: string }) => {
		login.mutate(body, {
			onSuccess: ({ token, user }) => {
				saveUserTokens({ token, user });
				dispatch(profileActions.setLoggedInUser({ token, user })); // Deprecated

				if (!user.verified) {
					setUser(user);
					return history.push(`/portal/verify/${location.search}`);
				}

				const organizationSlug = storageHelpers.getOrganizationSlug();

				if (organizationSlug) return history.push(`/${organizationSlug}`);

				return history.push(`/profile/${user.slug}`);
			},
			onError: (error) => {
				if (error?.data?.code === "NOT_FOUND") {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "portal.login.failed.account_deleted",
							defaultMessage:
								"Your user has been deleted, please contact us if you want to restore your account",
						}),
						intl,
					});
					return;
				}
				if (error?.data?.code === "PRECONDITION_FAILED") {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "portal.login.failed.force_reset",
							defaultMessage: "You are forced to reset your password before you can login",
						}),
						intl,
					});
					return;
				}

				ShowFailedBanner({
					title: intl.formatMessage({
						id: "portal.login.failed.not_found",
						defaultMessage: "Your email or password is not correct",
						description: "Show error banner when users credentials are not correct",
					}),
					intl,
				});
				return;
			},
		});
	};

	const handlePasswordReset = () => {
		requestPasswordReset.mutate(
			{ email: userEmail },
			{
				onSuccess: () =>
					ShowSuccessBanner({
						title: intl.formatMessage(
							{
								id: "portal.login.request_reset.succeeded",
								defaultMessage: "We have sent you a email to {email}, to reset your password",
							},
							{
								email: userEmail,
							}
						),
						intl,
					}),
				onError: () =>
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "portal.login.request_reset.failed",
							defaultMessage:
								"Something went wrong, we couldn't send you a email to reset your password.",
							description: "Error when server crashes on requesting password reset email",
						}),
						intl,
					}),
			}
		);
	};

	return (
		<section className="portal_body">
			<article className="portal_body-article">
				<header className="portal_body-header">
					<Typography.Title>
						<FormattedMessage id="portal.login.title" defaultMessage="Welcome to Bothive" />
					</Typography.Title>
					<p>
						<FormattedMessage
							id="portal.login.description"
							defaultMessage="Don't have an account yet? <link>Sign up</link>"
							values={{
								link: (content) => (
									<NavLink to="/portal/sign_up" className="portal_body-header-link">
										{content}
									</NavLink>
								),
							}}
						/>
					</p>
				</header>
				<Form id="loginForm" onFinish={handleLogin} form={form} className="portal_body-form">
					<FieldLabel
						id="email"
						label={intl.formatMessage({ id: "portal.login.field.email.label", defaultMessage: "E-mail" })}
					>
						<Form.Item
							name="email"
							className="portal_body-form-field"
							required
							rules={[
								{
									type: "email",
									message: intl.formatMessage({
										id: "portal.login.field.email.invalid_email",
										defaultMessage: "Hmm… that email doesn't look valid",
									}),
								},
								{
									required: true,
									message: intl.formatMessage({
										id: "portal.login.field.email.error_is_required",
										defaultMessage: "Enter a e-mail address",
									}),
								},
							]}
						>
							<Input id="email" type="email" size="large" />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						id="password"
						label={intl.formatMessage({
							id: "portal.login.field.password.label",
							defaultMessage: "Password",
						})}
					>
						<Form.Item
							name="password"
							className="portal_body-form-field"
							required
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "portal.login.field.password.error_is_required",
										defaultMessage: "Enter your password",
									}),
								},
							]}
						>
							<Input.Password id="password" type="password" size="large" maxLength={256} />
						</Form.Item>
					</FieldLabel>
					<Form.Item shouldUpdate className="portal_body-form-field-offset_button">
						{() => {
							const isEmailValid =
								!form.getFieldsError(["email"])[0].errors.length && userEmail?.length > 0;

							return (
								<Tooltip
									title={
										!isEmailValid &&
										intl.formatMessage({
											id: "portal.login.forgot_password.tooltip",
											defaultMessage:
												"Enter your email here above before you can request to reset your password",
											description:
												"Forgot password button is placed underneath the form and is disabled until email field is correctly filled-in, show this tooltip when user hovers over disabled button and explain to user they first need to fill-in the email field before they can reset their password",
										})
									}
								>
									<Button
										type="text"
										disabled={!isEmailValid}
										onClick={handlePasswordReset}
										loading={requestPasswordReset.isLoading}
									>
										<FormattedMessage
											id="portal.login.forgot_password.button"
											defaultMessage="Forgot password"
										/>
									</Button>
								</Tooltip>
							);
						}}
					</Form.Item>
					<Form.Item shouldUpdate className="portal_body-form-field-submit_wrapper">
						{() => {
							const isDisabled =
								!form.isFieldsTouched(true) ||
								!!form.getFieldsError().filter(({ errors }) => errors.length).length;

							return (
								<Button
									size="large"
									type="primary"
									form="loginForm"
									htmlType="submit"
									disabled={isDisabled}
									loading={login.isLoading}
									className="portal_body-form-field-submit_button"
								>
									<FormattedMessage id="portal.login.submit" defaultMessage="Sign in" />
								</Button>
							);
						}}
					</Form.Item>
				</Form>
			</article>
		</section>
	);
}
