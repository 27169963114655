import "./style.scss";

import { Collapse, Button, Form, Input, Select } from "antd";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FieldGroup } from "react-reactive-form";

import DashboardCard from "@/shared/components/card/dashboard";
import ImageUpload from "@/shared/components/upload/imageUpload";
import { Component } from "../../components/atoms";
import { Fallback } from "../../components/molecules";
import { Accordion, Section, Popup } from "../../components/organisms";
import { DestructivePanel } from "../../shared/components/collapse";
import { Layout, Form as TForm, Wrapper } from "../../components/templates";
import { formConfig, pageConfig, routesConfig, templateConfig } from "../../config";
import { queryStringHelpers } from "../../helpers";
import { actions as contentActions } from "../../store/content";
import { actions as dashboardActions } from "../../store/dashboard";
import { actions as profileActions } from "../../store/profile";

const mapStateToProps = (state) => ({
	user: state.profile.account.user,
	isSaving: state.content.form.isSaving,
	savingFailed: state.content.form.savingFailed,
	savingSucceeded: state.content.form.savingSucceeded,
	isFetching: state.profile.account.isFetching,
	fetchingFailed: state.profile.account.fetchingFailed,
});

const styleForm = Object.keys(templateConfig.profile.overview).reduce(
	(prev, curr) => ({
		...prev,
		[curr]: formConfig.generateFormBuilder(templateConfig.profile.overview[curr]),
	}),
	{}
);

const deleteForm = Object.keys(templateConfig.profile.advancedSettings).reduce(
	(prev, curr) => ({
		...prev,
		[curr]: formConfig.generateFormBuilder(templateConfig.profile.advancedSettings[curr]),
	}),
	{}
);

class DashboardProfile extends React.Component {
	constructor(props) {
		super(props);

		const params = queryStringHelpers.getQueryString(props.history);

		this.state = {
			activeAccordionId: params && params.ot ? params.ot : "",
			showDeletePopup: false,
			unSavedChanges: false,
		};
		this.bindHandles();
	}

	componentDidMount() {
		this.props.changePageTitle({ config: pageConfig.profile.overview, intl: this.props.intl });
		this.props.initForm();
	}

	componentDidUpdate() {
		if (this.props?.user?.slug && this.props.match.params.slug !== this.props.user.slug) {
			const route = routesConfig.profile.overview.replace(":slug", this.props.user.slug);

			this.props.history.push(route);
		}
	}

	static getDerivedStateFromProps(props) {
		return props.savingSucceeded ? { unSavedChanges: false } : null;
	}

	bindHandles() {
		this.handleAccordionChange = this.handleAccordionChange.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);
		this.handleDeleteUser = this.handleDeleteUser.bind(this);
		this.handleToggleDeletePopUp = this.handleToggleDeletePopUp.bind(this);
	}

	handleDeleteUser(event) {
		event.preventDefault();
		this.props.deleteUser();
	}

	handleToggleDeletePopUp() {
		this.setState((prev) => ({ showDeletePopup: !prev.showDeletePopup }));
	}

	handleAccordionChange(activeAccordionId) {
		this.setState({ activeAccordionId, unSavedChanges: false });
	}

	handleChangePassword(value) {
		this.props.changeUserPassword(value);
		styleForm[pageConfig.profile.overview.accordion.security.id].reset();
	}

	render() {
		if (this.props.isFetching) return <Layout.TLoadScreen />;
		if (!this.props.user) return <Fallback.MEmpty {...pageConfig.profile.overview.fallback} />;

		const formConfig = {
			control: styleForm[pageConfig.profile.overview.accordion.security.id],
			loading: this.props.isSaving,
			className: "t-gap--top",
		};

		return (
			<main className="t-section">
				<Wrapper.TAccordion expanded={this.state.activeAccordionId} onChange={this.handleAccordionChange}>
					<DashboardCard
						title={this.props.intl.formatMessage({
							id: "profile.general.accordion_title",
							defaultMessage: "General",
							description: "Card title where can change their general info like name and email",
						})}
					>
						<Form
							layout="vertical"
							requiredMark="optional"
							id="virtualAssistantForm"
							initialValues={this.props.user}
							onFinish={this.props.updateUser}
							className="profile_overview-general-form"
						>
							<Form.Item
								required
								name="avatar"
								className="profile_overview-general-form-avatar"
								label={this.props.intl.formatMessage({
									id: "profile.general.form.avatar.label",
									defaultMessage: "Avatar",
								})}
								extra={this.props.intl.formatMessage({
									id: "profile.general.form.avatar.help",
									defaultMessage: "Maximum avatar size is 10MB",
								})}
								rules={[
									{
										required: true,
										message: this.props.intl.formatMessage({
											id: "profile.general.form.avatar.required",
											defaultMessage: "Avatar is required",
										}),
									},
								]}
							>
								<ImageUpload id="avatar" crop={{ aspect: 1 / 1 }} />
							</Form.Item>
							<Form.Item
								required
								name="firstName"
								className="profile_overview-general-form-first_name"
								label={this.props.intl.formatMessage({
									id: "profile.general.form.first_name.label",
									defaultMessage: "First name",
								})}
								rules={[
									{
										required: true,
										message: this.props.intl.formatMessage({
											id: "profile.general.form.first_name.required",
											defaultMessage: "Your first name is required",
										}),
									},
								]}
							>
								<Input size="large" maxLength={48} />
							</Form.Item>
							<Form.Item
								name="lastName"
								required
								className="profile_overview-general-form-last_name"
								label={this.props.intl.formatMessage({
									id: "profile.general.form.last_name.label",
									defaultMessage: "Last name",
								})}
								rules={[
									{
										required: true,
										message: this.props.intl.formatMessage({
											id: "profile.general.form.last_name.required",
											defaultMessage: "Your last name is required",
										}),
									},
								]}
							>
								<Input size="large" maxLength={48} />
							</Form.Item>
							<Form.Item
								required
								name="email"
								className="profile_overview-general-form-email"
								label={this.props.intl.formatMessage({
									id: "profile.general.form.email.label",
									defaultMessage: "E-mail",
								})}
								rules={[
									{
										type: "email",
										message: this.props.intl.formatMessage({
											id: "profile.general.form.email.invalid_email",
											defaultMessage: "Hmm… that email doesn't look valid",
										}),
									},
									{
										required: true,
										message: this.props.intl.formatMessage({
											id: "profile.general.form.email.error_is_required",
											defaultMessage: "Enter a e-mail address",
										}),
									},
								]}
							>
								<Input id="email" type="email" size="large" disabled />
							</Form.Item>
							<Form.Item
								name="phone"
								className="profile_overview-general-form-phone"
								label={this.props.intl.formatMessage({
									id: "profile.general.form.phone_number.label",
									defaultMessage: "Phonenumber",
								})}
							>
								<Input id="phone" type="phone" size="large" />
							</Form.Item>

							<Form.Item
								required
								name="language"
								label={this.props.intl.formatMessage({
									id: "profile.general.form.language.label",
									defaultMessage: "Language",
								})}
								help={this.props.intl.formatMessage({
									id: "profile.general.form.language.help",
									defaultMessage:
										"Languages in Beta are functional, but not everything is fully translated yet.",
								})}
								className="profile_overview-general-form-language"
							>
								<Select
									size="large"
									options={[
										{
											value: "nl-BE",
											label: this.props.intl.formatMessage({
												id: "profile.language.nl_be",
												defaultMessage: "Nederlands",
											}),
										},
										{
											value: "en-GB",
											label: this.props.intl.formatMessage({
												id: "profile.language.en_gb",
												defaultMessage: "English (BETA)",
											}),
										},
									]}
								/>
							</Form.Item>

							<footer className="profile_overview-general-form-footer">
								<Form.Item>
									<Button size="large" type="primary" htmlType="submit" loading={this.props.isSaving}>
										<FormattedMessage id="profile.general.form.submit" defaultMessage="Save" />
									</Button>
								</Form.Item>
							</footer>
						</Form>
					</DashboardCard>

					<Accordion.OBasic {...pageConfig.profile.overview.accordion.security}>
						<TForm.TFormTemplate {...formConfig} onSubmit={this.handleChangePassword}>
							<TForm.TAbstractForm
								fields={templateConfig.profile.overview.security}
								className="o-form--wrapper-vertical"
							/>
						</TForm.TFormTemplate>
					</Accordion.OBasic>
					<Collapse expandIconPosition="end" bordered={false}>
						<DestructivePanel
							key="dangerZone"
							title={this.props.intl.formatMessage({
								id: "profile.danger_zone.accordion_title",
								defaultMessage: "Delete your profile",
							})}
						>
							<Section.ODestructive
								title={this.props.intl.formatMessage({
									id: "profile.danger_zone.title",
									defaultMessage: "Delete your profile",
								})}
								description={this.props.intl.formatMessage({
									id: "profile.danger_zone.description",
									defaultMessage:
										"Are you sure you want to delete your profile? All your data will be permanently lost and your chatbot will be deactivated immediately.",
								})}
								buttonLabel={this.props.intl.formatMessage({
									id: "profile.danger_zone.button",
									defaultMessage: "Cancel your profile",
								})}
								onClick={this.handleToggleDeletePopUp}
							/>
						</DestructivePanel>
					</Collapse>
				</Wrapper.TAccordion>
				{this.state.showDeletePopup && (
					<Component.AOverlay>
						<FieldGroup
							control={deleteForm.delete}
							render={({ invalid }) => (
								<form className="o-popup--form" onSubmit={this.handleDeleteUser} noValidate>
									<Popup.OPopupDestructiveControl
										title={pageConfig.profile.overview.popup.destructive.title}
										addLabel={pageConfig.profile.overview.popup.destructive.button}
										disabled={invalid}
										onClose={this.handleToggleDeletePopUp}
										className="o-popup--delete"
									>
										<p className="t-gap--bottom-sm">
											<FormattedMessage
												id={pageConfig.profile.overview.popup.destructive.description}
											/>
										</p>
										<TForm.TAbstractForm
											fields={templateConfig.profile.overview.delete}
											className="o-form--vertical-explanation"
										/>
									</Popup.OPopupDestructiveControl>
								</form>
							)}
						/>
					</Component.AOverlay>
				)}
			</main>
		);
	}
}

export default withRouter(
	connect(mapStateToProps, {
		changePageTitle: dashboardActions.ui.changePageTitle,
		initForm: contentActions.form.initForm,
		updateUser: profileActions.account.updateUser,
		changeUserPassword: profileActions.account.changeUserPassword,
		deleteUser: profileActions.account.deleteUser,
	})(injectIntl(DashboardProfile))
);
