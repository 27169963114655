import { Button, Form, Modal, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import type { IAdminPulseConfig } from "@bothive_core/database";
import { useEffect, useMemo, useState } from "react";

import { trpc } from "../../../../../../../trpc";
import WebhookRulesForm from "./webhookRulesForm";

interface IEditFiltersModal {
	isOpen: boolean;
	filterIndex?: number;
	connectionId: string;
	contactFilters: IAdminPulseConfig["webhook"]["contactFilters"];
	onClose: () => void;
}
export default function EditFiltersModal({
	connectionId,
	filterIndex,
	contactFilters,
	isOpen,
	onClose,
}: IEditFiltersModal) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const trpcContext = trpc.useContext();
	const [isDeleting, setIsDeleting] = useState(false);
	const updateWebhookFilters = trpc.integration.updateWebhookFilters.useMutation();
	const _value = useMemo(() => contactFilters?.find((_, index) => index === filterIndex), [filterIndex]);

	const handleUpdate = async () => {
		try {
			const updatedFilters =
				contactFilters?.map((item, index) => (index === filterIndex ? form.getFieldsValue() : item)) || [];

			await updateWebhookFilters.mutateAsync({ id: connectionId, contactFilters: updatedFilters });

			onClose();
			trpcContext.integration.getConnection.invalidate({ id: connectionId });
			notification.success({
				message: intl.formatMessage({
					id: "settings.integrations.admin_pulse.webhook.edit_filters.succeeded",
					defaultMessage: "The webhook filter has been updated",
					description: "Notification about Admin pulse webhook filter has been updated",
				}),
				placement: "bottomRight",
			});
		} catch (_) {
			notification.error({
				message: intl.formatMessage({
					id: "settings.integrations.admin_pulse.webhook.edit_filters.update.failed",
					defaultMessage:
						"It seems like the webhook filter can't be updated right now. Please give it another shot later. We'll have this sorted out for you soon!",
					description:
						"Error notification about webhook filter that can not be updated, user should try again later",
				}),
				placement: "bottomRight",
				duration: 8,
			});
		}
	};
	const handleDelete = async () => {
		setIsDeleting(true);
		try {
			const updatedFilters = contactFilters?.filter((_, index) => index !== filterIndex) || [];

			await updateWebhookFilters.mutateAsync({ id: connectionId, contactFilters: updatedFilters });

			onClose();
			trpcContext.integration.getConnection.invalidate({ id: connectionId });
			notification.success({
				message: intl.formatMessage({
					id: "settings.integrations.admin_pulse.webhook.edit_filters.delete.succeeded",
					defaultMessage: "The webhook filter has been deleted",
					description: "Notification about Admin pulse webhook filter has been deleted",
				}),
				placement: "bottomRight",
			});
		} catch (_) {
			notification.error({
				message: intl.formatMessage({
					id: "settings.integrations.admin_pulse.webhook.edit_filters.delete.failed",
					defaultMessage:
						"It seems like the webhook filter can't be deleted right now. Please give it another shot later. We'll have this sorted out for you soon!",
					description:
						"Error notification about webhook filter that can not be deleted, user should try again later",
				}),
				placement: "bottomRight",
				duration: 8,
			});
		}
		setIsDeleting(false);
	};

	useEffect(() => {
		form.setFieldsValue(_value);
	}, [_value]);

	return (
		<Modal
			width={720}
			open={isOpen}
			onCancel={onClose}
			title={intl.formatMessage({
				id: "settings.integrations.admin_pulse.webhook.edit_filters.title",
				defaultMessage: "Webhook filter",
				description: "Title of modal that is shown to the user where they update the selected webhook filter",
			})}
			className="admin_pulse-webhook-edit_filters"
			destroyOnClose
			footer={
				<footer className="admin_pulse-webhook-edit_filters-footer">
					<Button type="text" onClick={onClose}>
						<FormattedMessage
							id="settings.integrations.admin_pulse.webhook.edit_filters.button.cancel"
							defaultMessage="Cancel"
						/>
					</Button>
					<div className="admin_pulse-webhook-edit_filters-footer-wrapper">
						<Button
							danger
							type="primary"
							loading={isDeleting}
							onClick={handleDelete}
							disabled={updateWebhookFilters.isLoading}
						>
							<FormattedMessage
								id="settings.integrations.admin_pulse.webhook.edit_filters.button.delete"
								defaultMessage="Delete"
							/>
						</Button>
						<Button
							type="primary"
							htmlType="submit"
							disabled={isDeleting}
							form="webhookRulesForm"
							loading={updateWebhookFilters.isLoading}
						>
							<FormattedMessage
								id="settings.integrations.admin_pulse.webhook.edit_filters.button.submit"
								defaultMessage="Update filter"
							/>
						</Button>
					</div>
				</footer>
			}
		>
			<WebhookRulesForm form={form} values={_value} onSubmit={handleUpdate} />
		</Modal>
	);
}
