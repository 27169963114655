export const ACCEPTABLE_IMAGE_TYPES = ["image/", "image/heic", "image/heif", "image/gif", "image/webp"];
// https://learn.microsoft.com/en-us/azure/communication-services/concepts/email/email-attachment-allowed-mime-types
export const ACCEPTABLE_MIME_TYPES_OUTLOOK = [
	"video/3gpp",
	"video/3gpp2",
	"application/x-7z-compressed",
	"audio/aac",
	"video/x-msvideo",
	"image/bmp",
	"text/csv",
	"application/msword",
	"application/vnd.ms-word.document.macroEnabled.12",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"application/vnd.ms-fontobject",
	"application/epub+zip",
	"image/gif",
	"application/gzip",
	"image/vnd.microsoft.icon",
	"text/calendar",
	"image/jpeg",
	"application/json",
	"audio/midi",
	"audio/mpeg",
	"video/mp4",
	"video/mpeg",
	"audio/ogg",
	"video/ogg",
	"application/ogg",
	"application/onenote",
	"audio/opus",
	"font/otf",
	"application/pdf",
	"image/png",
	"application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
	"application/vnd.openxmlformats-officedocument.presentationml.slideshow",
	"application/vnd.ms-powerpoint",
	"application/vnd.ms-powerpoint.presentation.macroEnabled.12",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	"application/vnd.ms-publisher",
	"application/x-rar-compressed",
	"application/vnd.ms-outlook",
	"application/rtf",
	"image/svg+xml",
	"application/x-tar",
	"image/tiff",
	"font/ttf",
	"text/plain",
	"application/vnd.visio",
	"audio/wav",
	"audio/webm",
	"video/webm",
	"image/webp",
	"audio/x-ms-wma",
	"video/x-ms-wmv",
	"font/woff",
	"font/woff2",
	"application/vnd.ms-excel",
	"application/vnd.ms-excel.sheet.binary.macroEnabled.12",
	"application/vnd.ms-excel.sheet.macroEnabled.12",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"application/xml, text/xml",
	"application/zip",
];
export const ACCEPTABLE_MIME_TYPES = [
	...ACCEPTABLE_MIME_TYPES_OUTLOOK,
	"x-conference/",
	"x-music/",
	"image/",
	"video/",
	"x-world/",
	"windows/",
	"application/",
	"audio/",
	"text/",
	"font/",
];
