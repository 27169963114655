import { timingConfig } from "../config";

let timer;

export default (callback, duration = timingConfig.debounce) => {
	clearTimeout(timer);

	timer = setTimeout(() => {
		callback();
		clearTimeout(timer);
	}, duration);
};
