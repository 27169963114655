/*eslint-disable max-len*/
export default {
	// NUMBER FORMATTING
	"NUMBER.ORDINAL.ST": "ste",
	"NUMBER.ORDINAL.ND": "de",
	"NUMBER.ORDINAL.RD": "de",
	"NUMBER.ORDINAL.TH": "de",

	"ACCORDION.DETAIL_TOGGLE.OFF": "Uitgeschakeld",
	"ACCORDION.DETAIL_TOGGLE.ON": "Ingeschakeld",

	"ASSIGNMENT.UNASSIGNED.LABEL": "Niet toegewezen",

	"CHAT_INTERNAL_WRAPPER.CHIP.INTERNAL": "Intern",

	"UTILITY.TIME": "{hour}u {minute}m {seconds}s",

	"BUTTON.DOWNLOAD.LABEL": "Download",

	//DASHBOARD
	"dashboard.conversation.empty.description": "Je bent up-to-date met al je conversaties voor vandaag",
	"dashboard.conversation.folder_options.all_conversations.label": "Alle conversaties",
	"dashboard.conversation.folder_options.assigned_to_me.label": "Toegewezen aan mij",
	"dashboard.conversation.folder_options.delegated.label": "Gedelegeerd",
	"dashboard.conversation.folder_options.drafts.label": "Concepten",
	"dashboard.conversation.folder_options.mentions.label": "Vermeldingen",
	"dashboard.conversation.folder_options.my_conversations.label": "Mijn conversaties",
	"dashboard.conversation.folder_options.send.label": "Verzonden",
	"dashboard.conversation.folder_options.shared_with_me.label": "Gedeeld met mij",
	"dashboard.conversations.all_conversations.fetched": "Alle conversaties opgehaald 📭",
	"dashboard.conversations.title": "Open conversaties in",
	"dashboard.flows.empty.description": "Je bent up-to-date met al je voltooide rapporten",
	"dashboard.flows.title": "Laatst voltooide rapporten",
	"dashboard.notification.empty.description": "Je bent up-to-date 🎉 goed gedaan, tijd voor wat koffie",
	"dashboard.notification.header.mark_all_as_read": "Alles als gelezen markeren",
	"dashboard.notification.list.end_of_list": "Alle meldingen zijn opgehaald 🔔",
	"dashboard.notification.title": "Ongelezen meldingen",
	"dashboard.welcome.contacts": "Contacten",
	"dashboard.welcome.flows": "Flows",
	"dashboard.welcome.inbox": "Inbox",
	"dashboard.welcome.title": "Welkom {firstName} 👋",
	"welcome.page.title": "Bothive",

	//BREADCRUMBS
	"BREADCRUMB.SETTINGS": "Instellingen",

	//ASIDE
	"ASIDE.DROPDOWN.MY_TEAMS": "Mijn teams",
	"ASIDE.DROPDOWN.ADD_TEAM": "Nieuw team aanmaken",
	"ASIDE.LABELS.DASHBOARD": "Dashboard",
	"ASIDE.LABELS.BOT": "Chatbot",
	"ASIDE.LABELS.BILLING": "Abonnement",
	"ASIDE.LABELS.QUESTION": "Vragen",
	"ASIDE.LABELS.FLOWS": "Flows",
	"ASIDE.LABELS.INBOX": "Inbox (bèta)",
	"ASIDE.LABELS.CONTACT": "Contacten (bèta)",
	"ASIDE.LABELS.DOCUMENTATION": "Documentatie",
	"ASIDE.LABELS.SUPPORT": "Klantendienst",
	"ASIDE.LABELS.SETTINGS": "Instellingen",
	"ASIDE.LABELS.LOGOUT": "Uitloggen",

	"ASIDE.PROFILE.LABELS.PROFILE": "Profiel",
	"ASIDE.PROFILE.LABELS.TEAMS": "Teams",
	"ASIDE.PROFILE.LABELS.ADVANCED_SETTINGS": "Instellingen",

	"ASIDE.INBOX.MOBILE.BACK_TO_CONVERSATIONS_LIST": "Terug naar alle conversaties",

	"ASIDE.INBOX.MOBILE.BACK_TO_CONVERSATIONS_LIST": "Terug naar alle conversaties",

	"ASIDE.INBOX.CHATBOT.TITLE": "Chatbot",

	"ASIDE.INBOX.SHOW_ALL.LABEL": "Toon alles",
	"ASIDE.INBOX.SHOW_LESS.LABEL": "Toon minder",

	"STATUS.INBOX.ALL.LABEL": "alles",
	"STATUS.INBOX.OPEN.LABEL": "open",
	"STATUS.INBOX.CLOSED.LABEL": "afgewerkt",
	"STATUS.INBOX.SNOOZED.LABEL": "snoozed",
	"STATUS.INBOX.SPAM.LABEL": "ongewenst",
	"STATUS.INBOX.TRASH.LABEL": "prullenmand",

	"admin_pulse.tasks.flow_actions.options.completed": "Rapport is voltooid",
	"admin_pulse.tasks.flow_actions.options.reviewed": "Rapport is afgewerkt",
	"admin_pulse.tasks.flow_actions.options.send": "Flow is verzonden",
	"admin_pulse.tasks.status.options.done": "Klaar",
	"admin_pulse.tasks.status.options.inProgress": "In uitvoering",
	"admin_pulse.tasks.status.options.todo": "Te doen",

	"INBOX.CONVERSATION_LIST.SUB_HEADER.EMPTY_TRASH": "Leegmaken",

	"INBOX.CONVERSATION_LIST.FALLBACK.INBOX": "Je inbox is leeg 🎉",
	"INBOX.CONVERSATION_LIST.FALLBACK.SEARCH": "Er zijn geen conversaties gevonden",
	"INBOX.CONVERSATION_LIST.FALLBACK.LABEL": "Geen conversaties in {label}",
	"INBOX.CONVERSATION_LIST.FALLBACK.CHANNEL": "Channel bevat geen conversaties",
	"INBOX.CONVERSATION_LIST.FALLBACK.MEMBER_INBOX": "{memberName}'s inbox is leeg",

	"INBOX.CONVERSATION_LIST.LIST_ITEM.MESSAGE_NOT_SEND": "Bericht verzenden mislukt",

	"INBOX.CONVERSATION.EMPTY_STATE.LABEL": "Selecteer een conversatie om van start te gaan.",

	"INBOX.CONVERSATION.INBOX_INTRO.CONNECT_CHANNEL.TITLE": "Verbind je e-mailkanalen",
	"INBOX.CONVERSATION.INBOX_INTRO.CONNECT_CHANNEL.DESCRIPTION":
		"Verbind minstens één van je huidige e-mailkanalen om je e-mails via de Bothive inbox te kunnen ontvangen en versturen.",

	"INBOX.CONVERSATION.INBOX_INTRO.CONNECT_CHANNEL.FOOTER": "Je kan je kanalen beheren via {link}",
	"INBOX.CONVERSATION.INBOX_INTRO.CONNECT_CHANNEL.FOOTER.LINK": "instellingen / kanalen",

	//ASSIGNEE_POPUP
	"ASSIGNEE_POPUP.HEADER.SUB_TITLE": "Teamleden",
	"ASSIGNEE_POPUP.HEADER.TITLE": "Wijs leden toe",
	"ASSIGNEE_POPUP.FALLBACK.TITLE": "Geen teamleden gevonden.",
	"ASSIGNEE_POPUP.SEARCH.PLACEHOLDER": "Zoek op naam ...",
	"ASSIGNEE_POPUP.ASSIGNEES.TITLE": "Toegewezen leden",
	"ASSIGNEE_POPUP.USERNAME.ME": "Mezelf",
	"ASSIGNEE_POPUP.NOT_ASSIGNED": "Niet toegewezen",

	"ASSIGNEE_POPUP.ERROR.TITLE": "Fout opgetreden bij het toewijzen",
	"ASSIGNEE_POPUP.ERROR.DESCRIPTION": "De conversatie kon niet worden toegewezen.",

	// ASSIGNEE_BUTTON
	"ASSIGNEE_BUTTON.TITLE": "Wijs toe",
	"ASSIGNEE_BUTTON.LABEL": "Toewijzen",

	// PARTICIPANT_POPUP
	"PARTICIPANT_POPUP.HEADER.TITLE": "Deelnemers",
	"PARTICIPANT_POPUP.HEADER.ADD.TITLE": "Deelnemers toevoegen",
	"PARTICIPANT_POPUP.SCOPE.SINGLE.TITLE": "Enkel zichtbaar voor {name}.",
	"PARTICIPANT_POPUP.SCOPE.MULTIPLE.TITLE": "Zichbaar voor {name} & {otherCount} andere.",

	"PARTICIPANT_POPUP.STATUS.READ": "Gelezen",
	"PARTICIPANT_POPUP.STATUS.UN_READ": "Ongelezen",
	"PARTICIPANT_POPUP.STATUS.CLOSED": "Afgewerkt",

	"PARTICIPANT_POPUP.ACTION.ADD.ERROR.TITLE": "Fout opgetreden bij het toevoegen van deelnemer.",
	"PARTICIPANT_POPUP.ACTION.ADD.ERROR.DESCRIPTION": "Deelnemer kon niet worden toegevoegd.",

	"PARTICIPANT_POPUP.ACTION.DELETE.ERROR.TITLE": "Fout opgetreden bij het verwijderen van deelnemer.",
	"PARTICIPANT_POPUP.ACTION.DELETE.ERROR.DESCRIPTION": "Deelnemer kon niet worden verwijdered.",

	"PARTICIPANT_POPUP.PARTICIPANT.ACTION.DELETE.OWNER.NOT_ALLOWED": "Ontvanger verwijderen niet mogelijk.",
	"PARTICIPANT_POPUP.PARTICIPANT.ACTION.DELETE.ASSIGNEE.NOT_ALLOWED":
		"Toegewezen deelnemer verwijderen niet mogelijk.",

	// PARTICIPANT_BUTTON
	"PARTICIPANT_BUTTON.TITLE": "Voeg toe",
	"PARTICIPANT_BUTTON.LABEL": "Deelnemers",

	//TEAM_MEMBER_POPUP

	"TEAM_MEMBER_POPUP.NOT_FOUND.TITLE": "Geen teamleden gevonden.",
	"TEAM_MEMBER_POPUP.ERROR.TITLE": "Fout bij het ophalen van teamleden.",

	//AUTH_REDIRECT
	"AUTH_REDIRECT.FALLBACK.TITLE": "Er lijkt iets te zijn misgelopen",
	"AUTH_REDIRECT.FALLBACK.DESCRIPTION":
		"Er is iets misgelopen met jouw authenticatie. Dit issue staat los van jouw e-mail provider.\nSluit deze pagina en probeer opnieuw.",

	//BANNER
	//BANNER ATTACHMENT
	"BANNER.FAILED.UPLOAD_ATTACHMENT.FILE_SIZE": "Je bestand is te groot ... Het maximale formaat is 10MB.",
	"BANNER.FAILED.UPLOAD_ATTACHMENT": "Het uploaden van de bijlage is mislukt ... Probeer het later opnieuw!",
	//BANNER API_KEY
	"BANNER.SUCCEEDED.CREATE_API_KEY": "API-key is succesvol aangemaakt",
	"BANNER.FAILED.CREATE_API_KEY": "Kon de API-key niet aanmaken. Probeer later opnieuw.",
	"BANNER.SUCCEEDED.UPDATE_API_KEY": "API-key is succesvol geüpdatet.",
	"BANNER.FAILED.UPDATE_API_KEY": "Kon de API-key niet updaten. Probeer later opnieuw.",
	"BANNER.SUCCEEDED.DELETE_API_KEY": "De API-key is succesvol verwijderd.",
	"BANNER.FAILED.DELETE_API_KEY": "Kon de API-key niet verwijderen. Probeer later opnieuw.",
	//BANNER BOT
	"BANNER.SUCCEEDED.CHANGE_BOT": "Je aanpassingen zijn succesvol opgeslagen",
	"BANNER.FAILED.CHANGE_BOT":
		"Er liep iets verkeerd bij het opslaan van jouw aanpassingen. Probeer het later opnieuw.",
	//BANNER CANCEL_SUBSCRIPTION
	"BANNER.FAILED.CANCEL_SUBSCRIPTION": "Opzeggen van abonnement is niet gelukt. Probeer het later opnieuw.",
	"BANNER.SUCCEEDED.CANCEL_SUBSCRIPTION": "Je abonnement is succesvol opgezegd.",
	//BANNER COPIED_TO_CLIPBOARD
	"BANNER.SUCCEEDED.COPIED_TO_CLIPBOARD": "Succesvol gekopieerd naar clipboard.",
	//BANNER CONTACT_WEB_DEVELOPER
	"BANNER.SUCCEEDED.CONTACT_WEB_DEVELOPER": "E-mail is succesvol verzonden.",
	"BANNER.FAILED.CONTACT_WEB_DEVELOPER": "E-mail verzenden mislukt. Probeer het later opnieuw.",
	//BANNER COOKIE_CONCENT
	"BANNER.COOKIE_CONCENT.BODY":
		"Door in te loggen op de website ga je akkoord met het gebruik van cookies en onze algemene voorwaarden.",
	"BANNER.COOKIE_CONCENT.LINK": "Lees onze algemene voorwaarden",
	//BANNER DELETE_CONTACT
	"BANNER.SUCCEEDED.DELETE_CONTACT": "Contact is succesvol verwijderd.",
	"BANNER.FAILED.DELETE_CONTACT": "Contact kon niet worden verwijderd. Probeer het later opnieuw.",
	//BANNER DELETE_CONTACT_BATCH
	"BANNER.SUCCEEDED.DELETE_CONTACT_BATCH": "Contacten zijn succesvol verwijderd.",
	"BANNER.FAILED.DELETE_CONTACT_BATCH": "Contact konden niet verwijderd worden. Probeer het later opnieuw.",
	//BANNER DELETE_ALL_CONTACTS
	"BANNER.SUCCEEDED.DELETE_ALL_CONTACTS": "Alle contacten zijn succesvol verwijderd.",
	"BANNER.FAILED.DELETE_ALL_CONTACTS": "Contacten konden niet verwijderd worden. Probeer het later opnieuw.",
	//BANNER UPDATE_NOTIFICATION_SETTINGS
	"BANNER.SUCCEEDED.UPDATE_NOTIFICATION_SETTINGS": "Je notificatie-instellingen zijn succesvol opgeslagen!",
	"BANNER.FAILED.UPDATE_NOTIFICATION_SETTINGS":
		"Het is niet gelukt om je notificatie-instellingen te updaten. Probeer het later opnieuw.",
	//BANNER FILE
	"BANNER.FAILED.FILE_IS_TO_LARGE": "Upload mislukt. Afbeelding mag niet groter zijn dan 10MB.",
	"BANNER.FAILED.FILE_TYPE_NOT_SUPPORTED":
		"Upload mislukt. Bestandstype wordt niet ondersteund. Enkel {types} worden ondersteund.",
	"BANNER.FAILED.ATTACHMENT_IS_TO_LARGE": "Upload mislukt. Bijlage mag niet groter zijn dan 5MB.",
	"BANNER.FAILED.ATTACHMENT_LIMIT_REACHED": "Upload mislukt. Je kan maar 20MB aan bijlage doorsturen.",
	"BANNER.FAILED.ATTACHMENTS_AMOUNT_REACHED":
		"Je kan maar maximum {amount} bijlages uploaden. {freeAmount, plural, =0 {Je hebt geen ruimte meer.} one {Je hebt nog ruimte voor # bijlage.} other {Je hebt nog ruimte voor # bijlages.}}",
	"BANNER.FAILED.ATTACHMENT_DELETE": "Er liep iets mis bij het verwijderen van de bijlage.",
	//BANNER INBOX
	"BANNER.FAILED.INBOX.AS_UNSEEN": "Kon de conversatie niet markeren als ongezien, probeer later opnieuw.",
	"BANNER.FAILED.INBOX.AS_SEEN": "Kon de conversatie niet markeren als gezien, probeer later opnieuw.",
	"BANNER.FAILED.INBOX.AS_SPAM": "Kon de conversatie niet markeren als ongewenste, probeer later opnieuw.",
	"BANNER.FAILED.INBOX.UN_SPAM": "Kon de conversatie niet uit de ongewenste folder halen, probeer later opnieuw.",
	"BANNER.FAILED.INBOX.MARK_AS_ARCHIVED": "Kon de conversatie niet archiveren, probeer later opnieuw.",
	"BANNER.FAILED.INBOX.UN_ARCHIVE": "Kon de conversatie niet terug plaatsen naar inbox, probeer later opnieuw.",
	"BANNER.FAILED.INBOX.CLOSE_CONVERSATION": "Kon de conversatie niet afsluiten, probeer later opnieuw.",
	"BANNER.FAILED.INBOX.OPEN_CONVERSATION": "Kon de conversatie niet openen, probeer later opnieuw.",
	"BANNER.FAILED.INBOX.TRASH_CONVERSATION": "Kon de conversatie niet verwijderen, probeer later opnieuw.",
	"BANNER.FAILED.INBOX.UN_TRASH_CONVERSATION": "Kon de conversatie niet terug plaatsen, probeer later opnieuw.",
	"BANNER.SUCCEEDED.INBOX.NEW_EMAIL.SEND": "E-mail is verzonden",
	"BANNER.SUCCEEDED.INBOX.DRAFT_EMAIL.SEND": "E-mail is verzonden",
	"BANNER.SUCCEEDED.INBOX.DRAFT_SAVED": "Concept aanpassingen opgeslaan",

	"BANNER.FAILED.INBOX.ADD_LABEL": "Label could not be added to conversation. Please try again later.",
	"BANNER.FAILED.INBOX.DELETE_LABEL": "Label could not be removed from conversation. Please try again later.",

	"BANNER.SUCCESS.EMPTY_CONVERSATIONS": "Conversaties zijn verwijderd",
	"BANNER.FAILED.EMPTY_CONVERSATIONS":
		"Onze virtuele vuilnisophaling kan jouw prullenmand momenteel niet legen. Probeer het later opnieuw.",
	//BANNER INTENT
	"BANNER.FAILED.INTENT_UPDATED": "Intent update is mislukt. Probeer het later opnieuw.",
	"BANNER.SUCCEEDED.INTENT_UPDATED": "Intent update is geslaagd.",
	"BANNER.FAILED.INTENT_RESET": "Intent reset is mislukt. Probeer het later opnieuw.",
	"BANNER.SUCCEEDED.INTENT_RESET": "Intent reset is geslaagd.",
	//BANNER DOWNLOAD_CONVERSATION_ATTACHMENT
	"BANNER.FAILED.DOWNLOAD_CONVERSATION_ATTACHMENT":
		"Het downloaden van de bijlage is niet gelukt. Probeer later opnieuw.",
	"BANNER.PROCESSING.DOWNLOAD_CONVERSATION_ATTACHMENT": "Downloaden van bijlage",
	"BANNER.FAILED.DOWNLOAD_ALL_CONVERSATION_ATTACHMENTS":
		"Het downloaden van alle bijlages is niet gelukt. Probeer later opnieuw.",
	"BANNER.PROCESSING.DOWNLOAD_ALL_CONVERSATION_ATTACHMENTS": "Downloaden alle bijlages",
	//BANNER PAYMENT
	"BANNER.FAILED.PAYMENT":
		"Je team kon niet worden aangemaakt. Als dit probleem zich herhaalt, probeer opnieuw door de pagina te herladen.",
	//BANNER QUESTION_SETTINGS
	"BANNER.SUCCEEDED.QUESTION_SETTINGS_UPDATE": "Je aanpassingen zijn succesvol opgeslagen",
	"BANNER.FAILED.QUESTION_SETTINGS_UPDATE":
		"Er liep iets verkeerd bij het opslaan van jouw aanpassingen. Probeer het later opnieuw.",
	//BANNER RESET_PASSWORD
	"BANNER.SUCCEEDED.RESEND_RESET_PASSWORD_MAIL": "Er is een e-mail verstuurd om je wachtwoord te veranderen.",
	"BANNER.FAILED.RESEND_RESET_PASSWORD_MAIL": "De e-mail om je wachtwoord te veranderen kon niet worden verstuurd!",
	"BANNER.FAILED.RESET_PASSWORD": "Wachtwoord kon niet aangepast worden.",
	//BANNER SETTINGS
	"BANNER.SUCCEEDED.SETTINGS_UPDATE": "Je aanpassingen zijn succesvol opgeslagen",
	"BANNER.FAILED.SETTINGS_UPDATE":
		"Er liep iets verkeerd bij het opslaan van jouw aanpassingen. Probeer het later opnieuw.",
	"BANNER.SUCCEEDED.SETTINGS_COPIED": "Succesvol gekopieerd naar clipboard.",
	"BANNER.FAILED.SETTINGS_COPIED": "Kon niet gekopieerd worden naar clipboard, probeer later opnieuw",
	"BANNER.SUCCEEDED.SETTINGS_ADD_USER": "Gebruiker is uitgenodigd.",
	"BANNER.FAILED.SETTINGS_ADD_USER": "Gebruiker kon niet worden uitgenodigd.",
	"BANNER.FAILED.USER_LIMITED_REACHED":
		"Er kon geen nieuwe gebruiker uitgenodigd worden. Je hebt jouw team limiet van gebruikersaccounts bereikt.",
	"BANNER.FAILED.DUPLICATED_USER_ADDED":
		"De gebruiker is al toegevoegd aan dit team. Een gebruiker kan niet meerdere keren aan hetzelfde team worden toegevoegd.",
	//BANNER MEMBER
	"BANNER.SUCCEEDED.MEMBER_UPDATED": "Gebruiker is succesvol aangepast.",
	"BANNER.FAILED.MEMBER_UPDATED": "Gebruiker kon niet worden aangepast!",
	"BANNER.SUCCEEDED.MEMBER_REMOVED": "Gebruiker is succesvol verwijderd.",
	"BANNER.FAILED.MEMBER_REMOVED": "Gebruiker kon niet worden verwijderd!",

	"BANNER.SUCCEEDED.RESEND_INVITE": "Uitnodiging is opnieuw verstuurd naar gebruiker.",
	//BANNER TEAM_GEDEACTIVEERD
	"BANNER.FAILED.TEAM_GEDEACTIVEERD": "Team gedeactiveerd!",
	// BANNER.FAILED.UPDATE_TEAM
	"BANNER.SUCCEEDED.UPDATE_TEAM": "Team bijwerken voltooid.",
	"BANNER.FAILED.UPDATE_TEAM": "Team kon niet worden bijgewerkt. Probeer het later opnieuw.",
	//BANNER NOTIFICATION
	"BANNER.FAILED.TRIGGER_ADDED": "Toevoegen trigger is niet gelukt.",
	"BANNER.FAILED.DUPLICATE_CONDITIONS":
		"Toevoegen trigger is niet gelukt. Je hebt al een trigger met deze condities. Sla op als draft of verander de condities.",

	"BANNER.FAILED.CREATE.DUPLICATE":
		"Toevoegen trigger is niet gelukt. Je hebt al een trigger met deze condities. Sla op als draft of verander de condities.",
	"BANNER.FAILED.CREATE.LIMITED_REACHED":
		"Toevoegen trigger is niet gelukt. Je hebt de limiet van je abbonnement bereikt. Upgrade of sla op als draft om deze trigger te kunnen bewaren.",
	"BANNER.FAILED.UPDATE.DUPLICATE":
		"Update trigger is niet gelukt. Je hebt al een trigger met deze condities. Sla op als draft of verander de condities.",
	"BANNER.FAILED.UPDATE.LIMITED_REACHED":
		"Update trigger is niet gelukt. Je hebt de limiet van je abbonnement bereikt. Upgrade of sla op als draft om deze trigger te kunnen bewaren.",

	"BANNER.FAILED.TRIGGER_REMOVED": "Trigger verwijderen is niet gelukt.",
	"BANNER.FAILED.TRIGGER_UPDATED": "Trigger updaten is niet gelukt.",
	"BANNER.SUCCEEDED.TRIGGER_ADDED": "Je trigger is succesvol aangemaakt",
	"BANNER.SUCCEEDED.TRIGGER_REMOVED": "Je trigger is verwijderd.",
	"BANNER.SUCCEEDED.TRIGGER_UPDATED": "Je trigger is aangepast.",
	//BANNER USER
	"BANNER.SUCCEEDED.USER_CHANGED": "Je profielinstellingen zijn succesvol opgeslagen!",
	"BANNER.FAILED.USER_CHANGED": "Er is iets fout gelopen!",
	"BANNER.FAILED.OWNER_CANT_BE_DELETED":
		"Je bent eigenaar van een team. Je gebruiker kan pas verwijderd worden nadat het team verwijderd is.",
	//BANNER USER_ACCOUNT
	//BANNER UPDATE_CHANNEL
	"BANNER.SUCCEEDED.UPDATE_CHANNEL": "De instellingen van je kanaal zijn succesvol gewijzigd!",
	"BANNER.FAILED.CHANNEL_ALREADY_CONNECTED": "Je hebt dit kanaal al gekoppeld aan dit team.",
	"BANNER.FAILED.UPDATE_CHANNEL": "Het is niet gelukt om je kanaal te updaten. Probeer het later opnieuw.",
	//BANNER DELETE_CHANNEL
	"BANNER.SUCCEEDED.DELETE_CHANNEL": "Je hebt je kanaal succesvol verwijderd",
	"BANNER.FAILED.DELETE_CHANNEL": "Het is niet gelukt om je kanaal te verwijderen. Probeer het later opnieuw.",
	//BANNER CONNECT_USER_WITH_INTEGRATION
	//BANNER LIANTIS
	"BANNER.SUCCEEDED.LIANTIS": "Rapport succesvol doorgestuurd naar Liantis",
	"BANNER.FAILED.LIANTIS":
		"Rapport kon niet worden doorgestuurd naar Liantis, probeer later opnieuw of neem contact op met onze klantendienst.",

	//BANNER FLOW REPORT FID
	"BANNER.SUCCEEDED.FID": "Rapport succesvol doorgestuurd naar het FID platform",
	"BANNER.DUPLICATE.FID": "Rapport is al aanwezig in het FID platform",
	"BANNER.FAILED.FID":
		"Rapport kon niet worden doorgestuurd naar het FID platform, probeer later opnieuw of neem contact op met onze klantendienst.",

	//BANNER FLOW REPORT ADMIN_PULSE
	"BANNER.SUCCEEDED.ADMIN_PULSE": "Rapport succesvol doorgestuurd naar AdminPulse",
	"BANNER.FAILED.ADMIN_PULSE":
		"Rapport kon niet worden doorgestuurd naar AdminPulse, probeer later opnieuw of neem contact op met onze klantendienst.",

	//VERIFY_ACCOUNT
	"BANNER.SUCCEEDED.REQUEST_NEW_VERIFY_CODE": "Nieuwe verificatiecode verzonden.",
	"BANNER.FAILED.REQUEST_NEW_VERIFY_CODE": "Nieuwe verificatiecode kon niet verzonden worden!",
	"BANNER.FAILED.VERIFY_ACCOUNT": "De verificatiecode kon niet geverifieerd worden!",
	//BANNER FLOW
	"BANNER.FAILED.DOWNLOAD_REPORT": "Rapport kon niet gedownload. Probeer later opnieuw.",
	"BANNER.SUCCEEDED.DOWNLOAD_REPORT": "Rapport is gedownload",
	"BANNER.PROCESSING.REPORT_IS_DOWNLOADING": "Bezig {type}-versie van rapport te downloaden.",
	"BANNER.FAILED.CREATE_FLOW": "De flow kon niet aangemaakt worden. Probeer later opnieuw.",
	"BANNER.SUCCEEDED.CREATE_FLOW": "Je flow is succesvol toegevoegd!",
	"BANNER.FAILED.UPDATE_FLOW": "De flow kon niet geüpdate worden. Probeer later opnieuw.",
	"BANNER.SUCCEEDED.UPDATE_FLOW": "Je flow is succesvol geüpdate.",
	"BANNER.FAILED.DELETE_FLOW": "De flow kon niet verwijderd worden. Probeer later opnieuw.",
	"BANNER.SUCCEEDED.DELETE_FLOW": "Je flow is succesvol verwijderd",
	"BANNER.FAILED.DOWNLOAD_REPORT_ATTACHMENTS":
		"Rapport bijlages kunnen niet worden gedownload, probeer later opnieuw of neem contact op met onze klantendienst.",
	"BANNER.PROCESSING.DOWNLOAD_REPORT_ATTACHMENTS": "Bezig met rapport bijlages te downloaden",
	"BANNER.SUCCEEDED.DOWNLOAD_NO_REPORT_ATTACHMENTS": "Rapport bevat geen bijlages",
	"BANNER.SUCCEEDED.DOWNLOAD_REPORT_ATTACHMENTS": "Rapport bijlages gedownload",

	//BANNER LABEL
	"BANNER.SUCCEEDED.CREATE_LABEL": "Label is succesvol aangemaakt!",
	"BANNER.FAILED.CREATE_LABEL":
		"Het label kon niet aangemaakt worden. Probeer het later opnieuw of contacteer onze klantendienst.",
	"BANNER.SUCCEEDED.UPDATE_LABEL": "Label is succesvol geüpdatet.",
	"BANNER.FAILED.UPDATE_LABEL":
		"Het label kon niet geüpdatet worden. Probeer het later opnieuw of contacteer onze klantendienst.",
	"BANNER.SUCCEEDED.DELETE_LABEL": "Label is verwijderd",
	"BANNER.FAILED.DELETE_LABEL":
		"Het label kon niet verwijderd woren. Probeer het later opnieuw of contacteer onze klantendienst.",

	//BANNER REPORT
	"BANNER.FAILED.TO_ASSIGN_USER": "Kon niemand toewijzen aan het rapport. Probeer later opnieuw.",
	"BANNER.FAILED.UPDATE_REPORT": "Het rapport kon niet geüpdate worden. Probeer later opnieuw.",
	"BANNER.SUCCEEDED.UPDATE_REPORT": "Het rapport is succesvol geüpdate.",
	"BANNER.FAILED.DELETE_REPORT": "Het rapport kon niet verwijderd worden. Probeer later opnieuw.",
	"BANNER.SUCCEEDED.DELETE_REPORT": "Het rapport is succesvol verwijderd.",

	//BANNER SIGNATURE
	"BANNER.SUCCEEDED.CREATE_SIGNATURE": "Handtekening is succesvol aangemaakt",
	"BANNER.FAILED.CREATE_SIGNATURE": "Kon de handtekening niet aanmaken. Probeer later opnieuw.",
	"BANNER.SUCCEEDED.UPDATE_SIGNATURE": "Handtekening is succesvol geüpdate.",
	"BANNER.FAILED.UPDATE_SIGNATURE": "Kon de handtekening niet updaten. Probeer later opnieuw.",
	"BANNER.SUCCEEDED.DELETE_SIGNATURE": "De handtekening is succesvol verwijderd.",
	"BANNER.FAILED.DELETE_SIGNATURE": "Kon de handtekening niet verwijderen. Probeer later opnieuw.",
	//BANNER STATUS_TYPE
	"BANNER.SUCCEEDED.CREATE_STATUS_TYPE": "Status type is succesvol aangemaakt",
	"BANNER.FAILED.CREATE_STATUS_TYPE": "Kon het statustype niet aanmaken. Probeer later opnieuw.",
	"BANNER.SUCCEEDED.UPDATE_STATUS_TYPE": "Status type is succesvol geüpdate.",
	"BANNER.SUCCEEDED.COPY_STATUS_TYPE": "Status types zijn succesvol gekopieerd.",
	"BANNER.FAILED.UPDATE_STATUS_TYPE": "Kon het statustype niet updaten. Probeer later opnieuw.",
	"BANNER.FAILED.COPY_STATUS_TYPE": "Kon de statustypes niet kopiëren. Probeer later opnieuw",
	"BANNER.SUCCEEDED.DELETE_STATUS_TYPE": "Het statustype is succesvol verwijderd.",
	"BANNER.FAILED.DELETE_STATUS_TYPE": "Kon het statustype niet verwijderen. Probeer later opnieuw.",

	//BOT
	"SETTINGS.WIDGET.SETTINGS.PAGE.TITLE": "Chatbot",
	"SETTINGS.WIDGET.SETTINGS.BREADCRUMB.SETTINGS": "Instellingen",
	"SETTINGS.WIDGET.SETTINGS.BREADCRUMB.WIDGET": "Widget",

	//SETTINGS.WIDGET.MOCKUP_ASIDE
	"SETTINGS.WIDGET.MOCKUP_ASIDE.HEADER.TITLE": "Widget voorbeeld",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.HEADER.DESCRIPTION":
		"Hier kan je zien hoe de widget er zal uitzien voor je websitebezoekers. Dit is geen functionele widget, maar een voorbeeld van de exacte weergave op je website.",

	"SETTINGS.WIDGET.MOCKUP_ASIDE.FOOTER.LINK": "Preview de widget op je website",

	//SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.HEADER.TITLE": "Preview widget",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.FORM.URL.LABEL":
		"Voer de website in waarop je jouw widget wil bekijken:",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.FORM.URL.PLACEHOLDER": "https://www.bothive.be",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.FORM.URL.REQUIRED":
		"Het lijkt er op dat je een url bent vergeten",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.FORM.API_KEY.LABEL": "API-key",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.FORM.API_KEY.HELP":
		"Je hebt een API-key nodig met de 'widget.load' permission.",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.FOOTER.PREVIEW": "Preview widget",

	//BOT DEFAULT RESPONSES
	"BOT_DEFAULT_RESPONSE.PAGE.TITLE": "Chatbot - Teksten en foutmeldingen",
	"BOT_DEFAULT_RESPONSE.HEADER.BREADCRUMB.CHATBOT": "Chatbot",
	"BOT_DEFAULT_RESPONSE.HEADER.BREADCRUMB.DEFAULT_RESPONSES": "Teksten en foutmeldingen",

	"BOT_DEFAULT_RESPONSE.FALLBACK.TITLE": "Er lijkt iets te zijn misgelopen",
	"BOT_DEFAULT_RESPONSE.FALLBACK.DESCRIPTION":
		"Sophia gebruikt teksten en foutmeldingen om de gebruiker te verwelkomen, acties te vertalen naar tekst of een defect te signaleren. Deze antwoorden kan je aanpassen naar eigen voorkeur.In de website widget kunnen visuele elementen toegevoegd worden aan bepaalde antwoorden. Op andere platformen zoals Facebook Messenger is dat niet mogelijk gezien deze buiten onze bevoegdheid vallen.",

	"BOT_DEFAULT_RESPONSE.TITLE.TITLE": "Header tekst",
	"BOT_DEFAULT_RESPONSE.TITLE.DESCRIPTION":
		"Maak duidelijk waarvoor de chatbot kan gebruikt worden. De headertekst is meestal een vraag die je websitebezoeker stimuleert om een conversatie te starten.",
	"BOT_DEFAULT_RESPONSE.TITLE.PLACEHOLDER": "Hulp nodig?",

	"BOT_DEFAULT_RESPONSE.GREETING_TITLE.TITLE": "Welkomsttitel",
	"BOT_DEFAULT_RESPONSE.GREETING_TITLE.DESCRIPTION": "Begroet de gebruiker en laat de chatbot zichzelf voorstellen.",
	"BOT_DEFAULT_RESPONSE.GREETING_TITLE.PLACEHOLDER": "Hallo ik ben \\{\\{organization.chatbot.name\\}\\}",

	"BOT_DEFAULT_RESPONSE.GREETING_MESSAGE.TITLE": "Welkomstbericht",
	"BOT_DEFAULT_RESPONSE.GREETING_MESSAGE.DESCRIPTION":
		"Vertel welke vragen de chatbot kan oplossen en geef verdere gebruiksinstructies.",
	"BOT_DEFAULT_RESPONSE.GREETING_MESSAGE.PLACEHOLDER":
		"ik ben een chatbot speciaal ontworpen om je te helpen bij vragen over \\{\\{organization.name\\}\\}",

	"BOT_DEFAULT_RESPONSE.OFFLINE_MESSAGE.TITLE": "Live chat is niet beschikbaar",
	"BOT_DEFAULT_RESPONSE.OFFLINE_MESSAGE.DESCRIPTION":
		"Stel een mededeling in die de chatbot toont buiten de kantooruren of wanneer niemand van je team online is. Let op: deze foutmelding zal enkel verschijnen als je live chat hebt ingeschakeld.",
	"BOT_DEFAULT_RESPONSE.OFFLINE_MESSAGE.PLACEHOLDER":
		"Een collega komt onmiddellijk met jou chatten. Even geduld ...",

	"BOT_DEFAULT_RESPONSE.LIVE_CHAT_REQUESTED.TITLE": "Live chat is geactiveerd",
	"BOT_DEFAULT_RESPONSE.LIVE_CHAT_REQUESTED.DESCRIPTION":
		"Stel een mededeling in die de chatbot toont wanneer de live chat actief is. Let op: deze foutmelding zal enkel verschijnen als je live chat hebt ingeschakeld.",

	"BOT_DEFAULT_RESPONSE.CLOSED_LIVE_CHAT.TITLE": "Live chat is afgesloten",
	"BOT_DEFAULT_RESPONSE.CLOSED_LIVE_CHAT.DESCRIPTION":
		"Stel een mededeling in die de chatbot toont wanneer de live chat is afgesloten. Let op: deze foutmelding zal enkel verschijnen als je live chat hebt ingeschakeld.",
	"BOT_DEFAULT_RESPONSE.CLOSED_LIVE_CHAT.PLACEHOLDER":
		"Live chat is afgesloten. Je kan weer met al je vragen bij mij terecht! Je kan de live chat opnieuw inschakelen door het aan mij te vragen.",

	//VIRTUAL_ASSISTANT_SETTINGS
	"VIRTUAL_ASSISTANT_SETTINGS.TITLE": "Virtuele assistent",
	"VIRTUAL_ASSISTANT_SETTINGS.BREADCRUMB.SETTINGS": "Instellingen",
	"VIRTUAL_ASSISTANT_SETTINGS.BREADCRUMB.TITLE": "Virtuele assistent",

	//VIRTUAL_ASSISTANT_RESPONSES
	"VIRTUAL_ASSISTANT_RESPONSES.TITLE": "Standaard antwoorden",
	"VIRTUAL_ASSISTANT_RESPONSES.BREADCRUMB.SETTINGS": "Instellingen",
	"VIRTUAL_ASSISTANT_RESPONSES.BREADCRUMB.RESPONSES": "Standaard antwoorden",
	//VIRTUAL_ASSISTANT_RESPONSES.FORM
	"VIRTUAL_ASSISTANT_RESPONSES.TITLE.LABEL": "Headertekst",
	"VIRTUAL_ASSISTANT_RESPONSES.TITLE.DESCRIPTION":
		"Stimuleer je websitebezoeker een conversatie te starten door een begeleidende tekst in de header van de chat widget te plaatsen.",
	"VIRTUAL_ASSISTANT_RESPONSES.FORM.TITLE.PLACEHOLDER": "Hulp nodig?",

	"VIRTUAL_ASSISTANT_RESPONSES.GREETING_TITLE.LABEL": "Welkomsttitel",
	"VIRTUAL_ASSISTANT_RESPONSES.GREETING_TITLE.DESCRIPTION": "Begroet de gebruiker en introduceer de chatbot.",
	"VIRTUAL_ASSISTANT_RESPONSES.FORM.GREETING_TITLE.FORM.PLACEHOLDER":
		"Hallo ik ben \\{\\{organization.chatbot.name\\}\\}",

	"VIRTUAL_ASSISTANT_RESPONSES.GREETING_MESSAGE.LABEL": "Welkomstbericht",
	"VIRTUAL_ASSISTANT_RESPONSES.GREETING_MESSAGE.DESCRIPTION":
		"Verduidelijk welke vragen de chatbot kan oplossen en geef verdere gebruiksinstructies.",
	"VIRTUAL_ASSISTANT_RESPONSES.FORM.GREETING_MESSAGE.FORM.PLACEHOLDER":
		"ik ben een chatbot speciaal ontworpen om je te helpen bij vragen over \\{\\{organization.name\\}\\}",

	"VIRTUAL_ASSISTANT_RESPONSES.OFFLINE_MESSAGE.LABEL": "Live chat is niet beschikbaar",
	"VIRTUAL_ASSISTANT_RESPONSES.OFFLINE_MESSAGE.DESCRIPTION":
		"Stel een mededeling in die de chatbot toont buiten de kantooruren of wanneer niemand van je team online is. Let op: deze foutmelding zal enkel verschijnen als je live chat hebt ingeschakeld.",
	"VIRTUAL_ASSISTANT_RESPONSES.FORM.OFFLINE_MESSAGE.FORM.PLACEHOLDER":
		"Een collega komt onmiddellijk met jou chatten. Even geduld ...",

	"VIRTUAL_ASSISTANT_RESPONSES.LIVE_CHAT_REQUESTED.LABEL": "Live chat is geactiveerd",
	"VIRTUAL_ASSISTANT_RESPONSES.LIVE_CHAT_REQUESTED.DESCRIPTION":
		"Stel een mededeling in die de chatbot toont wanneer de live chat actief is. Let op: deze foutmelding zal enkel verschijnen als je live chat hebt ingeschakeld.",

	"VIRTUAL_ASSISTANT_RESPONSES.CLOSED_LIVE_CHAT.LABEL": "Live chat is afgesloten",
	"VIRTUAL_ASSISTANT_RESPONSES.CLOSED_LIVE_CHAT.DESCRIPTION":
		"Stel een mededeling in die de chatbot toont wanneer de live chat is afgesloten. Let op: deze foutmelding zal enkel verschijnen als je live chat hebt ingeschakeld.",
	"VIRTUAL_ASSISTANT_RESPONSES.FORM.CLOSED_LIVE_CHAT.FORM.PLACEHOLDER":
		"Live chat is afgesloten. Je kan weer met al je vragen bij mij terecht! Je kan de live chat opnieuw inschakelen door het aan mij te vragen.",

	"VIRTUAL_ASSISTANT_RESPONSES.SUGGESTIONS.TITLE": "Gerelateerde instellingen",
	//BOT GREETING
	"BOT.GREETING.TITLE": "Standaard antwoorden",
	"BOT.GREETING.DESCRIPTION":
		"Bepaal zelf hoe Bothive gebruikers verwelkomt en defecten signaleert. Je kan alle antwoorden wijzigen, aanvullen met eigen informatie en styling toepassen op de teksten in de website widget. Let op: deze instellingen gelden niet voor domeinen buiten Bothive.",
	"BOT.GREETING.BUTTON": "Ga naar Standaard antwoorden",

	//BOT STYLING
	"SETTINGS.WIDGET.SETTINGS.STYLING.ACCORDION_TITLE": "Opmaak",
	"SETTINGS.WIDGET.SETTINGS.STYLING.ACCORDION_DESCRIPTION":
		"Wijzig de kleuren van de chatbot in naar de huisstijl van je kantoor of je persoonlijke lievelingskleuren.",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BRAND_COLOR.LABEL": "Hoofdkleur",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BRAND_COLOR.PLACEHOLDER": "#08112B",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BRAND_COLOR.HELP":
		"Primaire kleur van de widget (header, iconen en tekstballonnen van de gesprekspartner).",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BRAND_COLOR_LIGHT.LABEL": "Accentkleur",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BRAND_COLOR_LIGHT.PLACEHOLDER": "#54628A",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BRAND_COLOR_LIGHT.HELP":
		"Accentkleur complementair aan de primaire kleur (schakeringen en andere accenten).",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BOT_BUBBLE_COLOR.LABEL": "Tekstballon",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BOT_BUBBLE_COLOR.PLACEHOLDER": "#E6E7E7",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BOT_BUBBLE_COLOR.HELP": "Kleur van de tekstbalonnen van de chatbot.",
	"SETTINGS.WIDGET.SETTINGS.STYLING.CHOOSE_FROM_THEME": "Of selecteer een thema",
	"SETTINGS.WIDGET.SETTINGS.STYLING.FONT": "Lettertype",
	"SETTINGS.WIDGET.SETTINGS.STYLING.ADD_CUSTOM_FONT": "Upload je eigen lettertype.",
	"SETTINGS.WIDGET.SETTINGS.STYLING.ADD_CUSTOM_FONT.LOCK": "Upgrade je abonnement om eigen lettertypes te uploaden.",

	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.ACCORDION_TITLE": "Geavanceerde instellingen (bèta)",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.HIDE_WIDGET.LABEL": "Verberg de widget op je website.",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.HIDE_GDPR_BANNER.LABEL": "Verberg de GDPR-banner in de widget.",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.HIDE_GDPR_BANNER.HELP":
		"Let op: wanneer je deze optie uitschakelt, moet je deze banner op een andere locatie op je website plaatsen.",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.HIDE_WIDGET_WHEN_OFFLINE.LABEL":
		"Verberg de widget buiten je openingsuren.",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.HIDE_WIDGET_WHEN_OFFLINE.HELP":
		"Let op: wanneer je deze optie uitschakelt, gaat je 24/7-beschikbaarheid voordeel verloren. Je riskeert leads te mislopen die je kantoor buiten de werkuren willen bereiken.",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.HIDE_WIDGET_WHEN_LANGUAGE_IS_NOT_SUPPORTED.LABEL":
		"Verberg de widget wanneer de taal op jouw website niet ondersteund wordt.",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.SHOW_BRANDING": "Toon Bothive branding",

	"SETTINGS.WIDGET.SETTINGS.SUGGESTIONS.TITLE": "Gerelateerde instellingen",

	//BOT THEME
	"SETTINGS.WIDGET.SETTINGS.THEME.DEFAULT": "Standaard",
	"SETTINGS.WIDGET.SETTINGS.THEME.BUSINESS": "Zakelijk",
	"SETTINGS.WIDGET.SETTINGS.THEME.PRUNE": "Herfst",
	"SETTINGS.WIDGET.SETTINGS.THEME.SUNNY": "Mandarijn",
	"SETTINGS.WIDGET.SETTINGS.THEME.LAVENDER": "Lavendel",
	"SETTINGS.WIDGET.SETTINGS.THEME.TUSCAN": "Toscaans",

	//BOT LIVE CHAT
	"SETTINGS.WIDGET.SETTINGS.LIVE_CHAT_SETTINGS.ACCORDION_TITLE": "Live chat",
	"SETTINGS.WIDGET.SETTINGS.LIVE_CHAT.DESCRIPTION":
		"De live chat zal enkel beschikbaar zijn tijdens de openingsuren van jouw kantoor. Wanneer iemand live wil chatten buiten de openingsuren of wanneer de live chat is uitgeschakeld, zal de vraag via mail worden verstuurd.",
	"SETTINGS.WIDGET.SETTINGS.LIVE_CHAT.ALLOW_LIVE_CHAT": "Activeer live chat",
	"SETTINGS.WIDGET.SETTINGS.LIVE_CHAT.LOCK.MESSAGE": "Verhoog jouw plan om live chat aan te schakelen.",
	//BOT TRIGGER
	"BOT.SETTINGS.TRIGGERS.ACCORDION_TITLE": "Triggers (bèta)",
	"BOT.SETTINGS.TRIGGERS.DESCRIPTION":
		"Stel triggers in om interactie met je chatbot te stimuleren. Je kan een unieke trigger instellen voor elke pagina op je website.",
	"BOT.SETTINGS.TRIGGERS.BUTTON": "Ga naar triggers",

	//BUTTON_ICON
	"BUTTON_ICON.COPY.LABEL": "Kopieer {label}",
	"BUTTON_ICON.SHOW_PASSWORD": "Toon wachtwoord",
	"BUTTON_ICON.HIDE_PASSWORD": "Verberg wachtwoord",

	//COMPONENT.EMAIL_EDITOR
	"COMPONENT.EMAIL_EDITOR.HEADER.TO.LABEL": "Naar:",
	"COMPONENT.EMAIL_EDITOR.HEADER.TO.PLACEHOLDER": "Wie moet de e-mail ontvangen?",

	"COMPONENT.EMAIL_EDITOR.HEADER.FROM.LABEL": "Van:",
	"COMPONENT.EMAIL_EDITOR.HEADER.FROM.PLACEHOLDER": "Vanuit welk e-mail adres verstuur je de e-mail?",
	"COMPONENT.EMAIL_EDITOR.HEADER.FROM.FALLBACK.TITLE": "Geen kanaal gevonden",
	"COMPONENT.EMAIL_EDITOR.HEADER.FROM.FALLBACK.DESCRIPTION":
		"Ga naar instellingen > Voeg minstens één e-mailkanaal toe om je e-mail te kunnen versturen.",
	"COMPONENT.EMAIL_EDITOR.HEADER.FROM.FALLBACK.BUTTON": "Kanaal toevoegen",

	"COMPONENT.EMAIL_EDITOR.HEADER.BUTTON.CC": "Cc",
	"COMPONENT.EMAIL_EDITOR.HEADER.BUTTON.BCC": "Bcc",
	"COMPONENT.EMAIL_EDITOR.HEADER.CC.LABEL": "Cc:",
	"COMPONENT.EMAIL_EDITOR.HEADER.CC.PLACEHOLDER": "Naar wie wil je een kopie van deze e-mail versturen?",

	"COMPONENT.EMAIL_EDITOR.HEADER.BCC.LABEL": "Bcc:",
	"COMPONENT.EMAIL_EDITOR.HEADER.BCC.PLACEHOLDER": "Naar wie wil je een verborgen kopie van deze e-mail sturen?",

	"COMPONENT.EMAIL_EDITOR.HEADER.SUBJECT.LABEL": "Onderwerp:",
	"COMPONENT.EMAIL_EDITOR.HEADER.SUBJECT.PLACEHOLDER": "Waar gaat jouw mail over?",

	"COMPONENT.EMAIL_EDITOR.BODY.PLACEHOLDER": "Typ jouw bericht",

	"COMPONENT.EMAIL_EDITOR.FOOTER.BUTTON.FORMATTING": "Toggle tekst formattering menu",
	"COMPONENT.EMAIL_EDITOR.FOOTER.ADD_ATTACHMENT": "Bijlage toevoegen",
	"COMPONENT.EMAIL_EDITOR.DROP_ZONE.ADD_ATTACHMENT": "Bijlage toevoegen",
	"COMPONENT.EMAIL_EDITOR.FOOTER.BUTTON.SEND": "Versturen",
	"COMPONENT.EMAIL_EDITOR.FOOTER.BUTTON.DELETE_CONCEPT": "Verwijder concept",
	"COMPONENT.EMAIL_EDITOR.RULE.CHANNEL.REQUIRED": "Geef ten minste één kanaal op.",
	"COMPONENT.EMAIL_EDITOR.RULE.TO.REQUIRED": "Geef ten minste één ontvanger op.",

	"COMPONENT.SORT_TABLE.SORT.TOOLTIP": "Rij verslepen",

	"COMPOSE_EMAIL_MODEL.HEADER.TITLE": "E-mail opstellen",
	"COMPOSE_EMAIL_MODEL.HEADER.BUTTON.MINIMIZE": "Minimaliseren",
	"COMPOSE_EMAIL_MODEL.HEADER.BUTTON.MAXIMIZE": "Maximaliseren",
	"COMPOSE_EMAIL_MODEL.HEADER.BUTTON.COLLAPSE": "Verkleinen",
	"COMPOSE_EMAIL_MODEL.HEADER.BUTTON.ENLARGE": "Vergroten",
	"COMPOSE_EMAIL_MODEL.HEADER.BUTTON.CLOSE": "Sluiten",
	"COMPOSE_EMAIL_MODEL.VALIDATION.MAX_RECIPIENTS": "Maximum {max} ontvangers mogelijk.",
	"COMPOSE_EMAIL_MODEL.VALIDATION.EMAIL": "{address} is geen geldig e-mailadres.",
	"COMPOSE_EMAIL_MODEL.FOOTER.UPLOADING_ATTACHMENT": "Bijlages uploaden...",
	"COMPOSE_EMAIL_MODEL.FOOTER.LOADING_SIGNATURE": "E-mailhandtekeningen ophalen",
	"COMPOSE_EMAIL_MODEL.CONFIRM.ARE_YOU_SURE_TO_CLOSE_COMPOSE": "Wil je deze e-mail opslaan als concept?",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.TITLE": "Nog geen e-mailkanalen verbonden",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.DESCRIPTION":
		"Verbind minstens één van je huidige e-mailkanalen om je e-mails via de Bothive inbox te kunnen ontvangen en versturen.",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.FOOTER.LINK": "instellingen / kanalen",
	"COMPOSE_EMAIL_MODEL.FOOTER.SIGNATURE.SELECT.NO_SIGNATURE": "Geen handtekening",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.FOOTER": "Je kan je kanalen beheren via <link>instellingen / kanalen</link",

	//CONTACT
	"CONTACT.OVERVIEW.PAGE.TITLE": "Contacten",

	"CONTACT.FILTER.SEARCH.PLACEHOLDER": "Zoek op naam, e-mail of referentie ID (bv. Sophia of sophia@bothive.be)",
	"CONTACT.FILTER.LABEL.PLACEHOLDER": "Filter op labels",
	"CONTACT.FILTER.DETAIL.TOTAL_RESULTS":
		"{value, plural, =0 {Geen contacten gevonden} one {# contact gevonden} other {# contacten gevonden}}",
	"CONTACT.FILTER.BUTTON.NEW_CLIENT": "Nieuw contact",

	"CONTACT.OVERVIEW.TABLE_OPTIONS.DELETE_ALL_CONTACTS": "Verwijder ALLE contacten",
	"CONTACT.OVERVIEW.BUTTON.DISABLED.TOOLTIP.DELETE_ALL_CONTACTS":
		"Tijdens het zoeken op naam, of filteren op label, werkt deze functionaliteit (nog) niet.",
	"CONTACT.OVERVIEW.TABLE_OPTIONS.DELETE_SELECTED_CONTACTS":
		"Verwijder {value, plural, one {# contact} other {# contacten}}",

	"CONTACT.OVERVIEW.TABLE.HEADER.USER": "Gebruiker",
	"CONTACT.OVERVIEW.TABLE.HEADER.E_MAIL": "E-mail",
	"CONTACT.OVERVIEW.TABLE.HEADER.LOCATION": "Locatie",

	// CONTACT BULKACTIONS
	"CONTACT.OVERVIEW.BULK_ACTION.ACTION.ARCHIVE": "ARCHIVEREN",
	"CONTACT.OVERVIEW.BULK_ACTION.ACTION.DELETE": "VERWIJDEREN",

	"CONTACT.OVERVIEW.BULK_ACTION.BUTTON.SELECTION_TOTAL":
		"Er {selection, plural, =0 {is momenteel geen enkel contact} one {is momenteel # contact} other {zijn momenteel # contacten}} geselecteerd.",
	"CONTACT.OVERVIEW.BULK_ACTION.BUTTON.SELECT_ALL":
		"Selecteer {total, plural, one {# contact} other {alle contacten (#)}} ",
	"CONTACT.OVERVIEW.BULK_ACTION.BUTTON.DESELECT_ALL": "Selectie ongedaan maken",

	"CONTACT.OVERVIEW.BULK_ACTION.DELETE.BUTTON.TITLE": "Verwijderen",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.BUTTON.TITLE": "Labels",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.TITLE": "Labelen als",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.NOT_FOUND": "Geen contactlabels gevonden",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ACTIONS.DELETE": "Verwijderen",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ACTIONS.ADD": "Toevoegen",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.ERROR":
		"Er liep iets mis tijdens het aanpassen van de labels van de geselecteerd contacten.",

	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.BUTTON.TITLE": "Archiveren",
	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.TITLE": "Archiveer contacten",
	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.BUTTON": "Archiveer contacten",

	"CONTACTS.OVERVIEW.BULK_ACTION.UNARCHIVE.BUTTON.TITLE": "Dearchiveren",
	"CONTACTS.OVERVIEW.BULK_ACTION.UNARCHIVE.BUTTON.DISABLED.TOOLTIP":
		"Je hebt {count, plural, one {# verwijderd contact} other {# verwijderde contacten}} die niet zijn gearchiveerd in je selectie, je kunt geen dearchiveer actie uitvoeren op contacten die enkel zijn verwijderd",

	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.BUTTON.TITLE": "Dossierbeheerders",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.TITLE": "Beheer door",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.NOT_FOUND": "Geen dossierbeheerders gevonden",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ACTIONS.DELETE": "Verwijderen",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ACTIONS.ADD": "Toevoegen",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.ERROR":
		"Er liep iets mis tijdens het aanpassen van de dossierbeheerders van de geselecteerd contacten.",

	//CONTACT_SETTINGS.ASIDE
	"CONTACT_SETTINGS.ASIDE.OVERVIEW": "Overzicht",
	"CONTACT_SETTINGS.ASIDE.LABELS": "Labels",

	//CONTACT_SETTINGS.OVERVIEW
	"CONTACT_SETTINGS.OVERVIEW.TITLE": "Contacteninstellingen",
	"CONTACT_SETTINGS.OVERVIEW.BREADCRUMB.SETTINGS": "Instellingen",
	"CONTACT_SETTINGS.OVERVIEW.BREADCRUMB.TITLE": "Contacteninstellingen",
	"CONTACT_SETTINGS.OVERVIEW.SYNC.TITLE": "Importeer je contacten",
	"CONTACT_SETTINGS.OVERVIEW.SYNC.DESCRIPTION": "Kies hoe je je contacten wil importeren.",

	"CONTACT_SETTINGS.OVERVIEW.SUGGESTIONS.TITLE": "Gerelateerde instellingen",

	"CONTACT_SETTINGS.OVERVIEW.SUGGESTIONS.INTEGRATIONS.TITLE": "Integraties",
	"CONTACT_SETTINGS.OVERVIEW.SUGGESTIONS.INTEGRATIONS.DESCRIPTION":
		"Koppel met verschillende CRM-systemen om snel contacten te synchroniseren.",
	"CONTACT_SETTINGS.OVERVIEW.SUGGESTIONS.INTEGRATIONS.BUTTON": "Integraties beheren",

	"settings.contact_import.suggestions.integrations.title": "Integraties",
	"settings.contact_import.suggestions.integrations.description":
		"Koppel met verschillende CRM-systemen om snel contacten te synchroniseren.",
	"settings.contact_import.suggestions.integrations.button": "Integraties beheren",

	//CONTACT_SETTINGS.LABELS
	"CONTACT_SETTINGS.LABELS.TITLE": "Contact labels",
	"CONTACT_SETTINGS.LABELS.BREADCRUMB.SETTINGS": "Instellingen",
	"CONTACT_SETTINGS.LABELS.BREADCRUMB.TITLE": "Contact labels",
	"CONTACT_SETTINGS.LABELS.TABLE.FALLBACK": "Je hebt nog geen labels ingesteld.",
	"CONTACT_SETTINGS.LABELS.TABLE.NAME": "Label naam",
	"CONTACT_SETTINGS.LABELS.TABLE.ADD_LABEL": "Voeg een nieuw label toe",

	//"CONTACT.DETAILS
	"CONTACT_DETAILS.PAGE.TITLE": "Contact details",
	"CONTACT_DETAILS.HEADER.BREADCRUMB.OVERVIEW": "Contacten",
	"CONTACT_DETAILS.HEADER.BREADCRUMB.VISITOR": "Bezoeker",
	"CONTACT_DETAILS.NOT_FOUND.TITLE": "Contact niet gevonden",
	"CONTACT_DETAILS.NOT_FOUND.DESCRIPTION": "Het lijkt er op dat dit contact niet bestaat binnen jouw organisatie.",
	"CONTACT_DETAILS.NOT_FOUND.RETURN": "Terug",

	//CONTACT_IMPORT
	"CONTACT_IMPORT_REPORT.HEADER.BREADCRUMB.OVERVIEW": "Contacten",
	"CONTACT_IMPORT_REPORT.HEADER.BREADCRUMB.IMPORTS": "Imports",
	"CONTACT_IMPORT_REPORT.PAGE.TITLE": "Contact import rapport",

	//CONTACT_TYPE
	"CONTACT_DETAILS.CONTACT_TYPE.CONTACT": "Contact",
	"CONTACT_DETAILS.CONTACT_TYPE.EMAIL_CONTACT": "E-mail contact",
	"CONTACT_DETAILS.CONTACT_TYPE.LINKED_CONTACT": "Gelinkt contact",
	"CONTACT_DETAILS.CONTACT_TYPE.VISITOR": "Bezoeker",
	//CONTACT.DETAILS.ASIDE
	"CONTACT_DETAILS.ASIDE.BACK_TO_OVERVIEW.LABEL": "Terug naar overzicht",
	"CONTACT_DETAILS.ASIDE.OVERVIEW.LABEL": "Overzicht",
	"CONTACT_DETAILS.ASIDE.DETAILS.LABEL": "Contactinfo",
	"CONTACT_DETAILS.ASIDE.CONVERSATIONS.LABEL": "Conversaties",
	"CONTACT_DETAILS.ASIDE.REPORTS.LABEL": "Rapporten",
	"CONTACT_DETAILS.ASIDE.FILES.LABEL": "Documenten",
	"CONTACT_DETAILS.ASIDE.ANALYTICS.LABEL": "Analytics",
	"CONTACT_DETAILS.ASIDE.LOGS.LABEL": "Logs",
	"CONTACT_DETAILS.ASIDE.SETTINGS.LABEL": "Instellingen",
	//CONTACT_DETAILS.CONTACT_INFO
	"CONTACT_DETAILS.CONTACT_INFO.BUTTON.SEND_MESSAGE": "Verstuur een bericht",
	"CONTACT_DETAILS.CONTACT_INFO.META.PHONE.LABEL": "Telefoonnummers",
	"CONTACT_DETAILS.CONTACT_INFO.META.BIRTHDAY.LABEL": "Geboortedatum",
	"CONTACT_DETAILS.CONTACT_INFO.META.STREET.LABEL": "Straat",
	"CONTACT_DETAILS.CONTACT_INFO.META.CITY.LABEL": "Stad",
	"CONTACT_DETAILS.CONTACT_INFO.META.POSTAL_CODE.LABEL": "Postcode",
	"CONTACT_DETAILS.CONTACT_INFO.META.REFERENCE_ID.LABEL": "Referentie ID",
	"CONTACT_DETAILS.CONTACT_INFO.META.BUTTON.SEE_ALL": "Bekijk meer informatie",

	//CONTACT_DETAILS.SETTINGS
	"CONTACT_DETAILS.SETTINGS.CONTACT_TYPE.TITLE": "Contacttype",
	"CONTACT_DETAILS.SETTINGS.CONTACT_TYPE.DESCRIPTION":
		"Met contact types kan je jouw contacten segmenteren. Bepaalde segmenten of subdoelgroepen geven meer mogelijkheden dan andere. Maak bijvoorbeeld een onderscheid tussen jouw e-mailcontacten, jouw websitebezoekers of jouw klantenbestand.",

	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.ACCORDION_TITLE": "Contact verwijderen",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.TITLE": "Verwijder dit contact",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.DESCRIPTION":
		"Ben je er zeker van dat je dit contact wil verwijderen? Alle documenten, rapporten en gegevens die gelinkt zijn aan dit contact zullen definitief verwijderd worden na 90 dagen.",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.BUTTON": "Contact verwijderen",

	// CONTACT_DETAILS.UNIFIED_INBOX
	"CONTACT_DETAILS.UNIFIED_INBOX.FILTER.CHANNELS.PLACEHOLDER": "Toon alle beschikbare kanalen",
	"CONTACT_DETAILS.UNIFIED_INBOX.FAILED.TITLE": "Inladen is mislukt",
	"CONTACT_DETAILS.UNIFIED_INBOX.FAILED.DESCRIPTION":
		"De conversaties van {username} konden niet worden ingeladen.\nProbeer het later opnieuw of contacteer onze klantendienst.",
	"CONTACT_DETAILS.UNIFIED_INBOX.EMPTY": "Hier zal je alle conversaties tussen je team en {username} terugvinden.",
	"CONTACTS_LABEL_TABLE.NAME.TITLE": "Naam",
	//CONTACTS_LABEL_MODAL
	"CONTACTS_LABEL_MODAL.CREATE.TITLE": "Label aanmaken",
	"CONTACTS_LABEL_MODAL.CREATE.BUTTON.CREATE": "Label aanmaken",
	"CONTACTS_LABEL_MODAL.CREATE.BUTTON.CANCEL": "Annuleren",

	"CONTACTS_LABEL_MODAL.FORM.NAME.LABEL": "Labelnaam",
	"CONTACTS_LABEL_MODAL.FORM.NAME.PLACEHOLDER": "Personenbelasting",
	"CONTACTS_LABEL_MODAL.FORM.DESCRIPTION.LABEL": "Labelomschrijving",
	"CONTACTS_LABEL_MODAL.FORM.DESCRIPTION.PLACEHOLDER":
		"Hang dit label aan contacten die de personenbelastings flow moeten ontvangen",
	"CONTACTS_LABEL_MODAL.FORM.DESCRIPTION.HELP":
		"Leg dit label uit of schrijf een beschrijving aan uw collega's wanneer een contactpersoon dit label moet hebben.",
	"CONTACTS_LABEL_MODAL.FORM.COLOR.LABEL": "Labelkleur",
	"CONTACTS_LABEL_MODAL.FORM.COLOR.PLACEHOLDER": "#F8C945",

	"CONTACTS_LABEL_MODAL.EDIT.TITLE": "Label bewerken",
	"CONTACTS_LABEL_MODAL.EDIT.BUTTON.SAVE": "Opslaam",
	"CONTACTS_LABEL_MODAL.EDIT.BUTTON.CANCEL": "Annuleren",
	"CONTACTS_LABEL_MODAL.EDIT.BUTTON.DELETE": "Verwijderen",

	"contacts_label_modal.update.title": "Label updaten",
	"contacts_label_modal.update.footer.delete": "Verwijderen",
	"contacts_label_modal.update.footer.submit": "Aanpassen",

	"contacts_label_modal.delete_label.title": "Label verwijderen",
	"contacts_label_modal.delete_label.body":
		"Verwijderde labels verdwijnen definitief bij alle contacten en andere instellingen.",

	"contacts_label_modal.update.footer.cancel": "Annuleren",
	"contacts_label_modal.update.footer.delete": "Verwijderen",
	//CONTACT_IMPORT
	CONTACTS_IMPORT_TABLE_HEADER_TITLE_NAME: "Naam",
	CONTACTS_IMPORT_TABLE_HEADER_TITLE_EMAIL: "E-mailadres",

	//DASHBOARD
	"DASHBOARD.PAGE.TITLE": "Dashboard",

	"DASHBOARD.SESSIONS.SECTION_TITLE": "Sessies",
	"DASHBOARD.SESSIONS_KEY.SECTION_TITLE": "Sessies",
	"DASHBOARD.MESSAGES_PER_SESSIONS.SECTION_TITLE": "Berichten per sessie",
	"DASHBOARD.MESSAGES_PER_SESSIONS_KEY.SECTION_TITLE": "Berichten/sessies",
	"DASHBOARD.ERROR_RATE.SECTION_TITLE": "Beantwoorde vragen",
	"DASHBOARD.AVERAGE_MESSAGE_DURATION.SECTION_TITLE": "Gemiddelde duur sessie",
	"DASHBOARD.AVERAGE_SESSION_TIME.SECTION_TITLE": "Gemiddelde tijd per sessie",
	"DASHBOARD.VISITORS.UNIQUE_SITE_VISITORS":
		"Unieke {value, plural, =0 {websitebezoekers} one {websitebezoeker} other {websitebezoekers}}",
	"DASHBOARD.VISITORS.UNIQUE_CHAT_USERS":
		"Unieke chatbot {value, plural, =0 {gebruikers} one {gebruiker} other {gebruikers}}",
	"DASHBOARD.MOST_ASKED_QUESTION.SECTION_TITLE": "Meest gestelde vragen",
	"DASHBOARD.MOST_ASKED_QUESTION.FALLBACK": "Er is te weinig data om de meest gestelde vragen te analyseren.",
	"DASHBOARD.MOST_ASKED_QUESTION.QUESTION": "Gestelde vragen",
	"DASHBOARD.MOST_ASKED_QUESTION.COUNT": "Aantal",
	"DASHBOARD.AVERAGE_CONVERSATION_BY_TIME_OF_DAY.SECTION_TITLE": "Gemiddelde sessies per uur",
	"DASHBOARD.SUMMARY.SECTION_TITLE": "Samenvatting",
	"DASHBOARD.SUMMARY.USER_RATE": "Unieke gebruikers",
	"DASHBOARD.SUMMARY.RETURN_RATE": "Terugkerende gebruikers",
	"DASHBOARD.SUMMARY.FAIL_RATE": "Fail rate",
	"DASHBOARD.SUMMARY.TOTAL_TIME": "Totale tijd",
	"DASHBOARD.SUMMARY.PERCENTAGE": "{value} %",

	//FAILED_EMAIL
	"FAILED_EMAIL.HEADER.TITLE":
		"E-mail kon niet verzonden worden. als dit probleem blijft, neem contact op met onze customer success.",
	"FAILED_EMAIL.HEADER.MENU.DELETE_EMAIL": "E-mail verwijderen",

	//DRAFT_EMAIL
	"DRAFT_EMAIL.DRAFT_HEADER.TITLE": "E-mail concept",
	"DRAFT_EMAIL.DRAFT_HEADER.FAILED.TITLE": "Gefaalde E-mail",
	"DRAFT_EMAIL.DRAFT_HEADER.MENU.DELETE_DRAFT": "Draft verwijderen",
	"DRAFT_EMAIL.FOOTER.BUTTON.UPDATE": "Update concept",
	"DRAFT_EMAIL.FOOTER.BUTTON.SEND": "Concept versturen",

	//EMAIL_PREVIEW
	"EMAIL_PREVIEW.MENU.FORWARD.LABEL": "E-mail doorsturen",
	"EMAIL_PREVIEW.MENU.COLLAPSE.LABEL": "Verberg e-mail",
	"EMAIL_PREVIEW.MENU.EXPAND.LABEL": "Toon e-mail",
	"EMAIL_PREVIEW.MENU.SHOW_THREAD.LABEL": "Toon volledige e-mail",
	"EMAIL_PREVIEW.MENU.HIDE_THREAD.LABEL": "Verberg volledige e-mail",

	"EMAIL_PREVIEW.ATTACHMENTS.HEADER.TITLE": "{attachments} bijlage",
	"EMAIL_PREVIEW.ATTACHMENTS.HEADER.BUTTON": "downloaden",

	"EMAIL_PREVIEW.ACTIONS.SHOW_THREAD": "Toon volledige e-mail",
	"EMAIL_PREVIEW.ACTIONS.HIDE_THREAD": "Verberg volledige e-mail",

	//FALLBACK
	"FALLBACK.ANALYTICS.NOT_ENOUGH_DATA": "Onvoldoende gegevens beschikbaar",
	"FALLBACK.DROPDOWN.NO_ITEMS_FOUND": "Geen items gevonden",
	"FALLBACK.ERROR_HANDLER.TITLE": "Er is iets misgelopen waardoor de pagina niet getoond kan worden.",
	"FALLBACK.NOT_FOUND.DEFAULT": "Er kon geen data gevonden worden",
	"FALLBACK.TABLE.NO_DATA": "Er kon geen data gevonden worden",
	"FALLBACK.NO_TEAM.TITLE": "Er lijkt iets te zijn misgelopen.",
	"FALLBACK.NO_TEAM.DESCRIPTION":
		"Jouw team kon niet gevonden worden. Probeer uit te loggen en opnieuw in te loggen.",
	"FALLBACK.LOCK.CONTACT_SALE": "Praat met ons team",
	"FALLBACK.CONTACT.NAME": "Onbekend contact",
	"FALLBACK.CONTACT.NAME.SHORT": "Onbekend",

	//FILTER
	"FILTER.FILES.FORM.TYPE_DROPDOWN.LABEL":
		"{value, plural, =0 {Geen documenten gevonden} one {# document gevonden} other {# documenten gevonden}}",
	"FILTER.FILES.FOOTER.TOTAL_RESULTS":
		"{value, plural, =0 {Geen documenten gevonden} one {# document gevonden} other {# documenten gevonden}}",

	"FILTER.FILES.FORM.FILE_TYPES.LABEL": "Bestandstype",
	"FILTER.FILES.FORM.SEARCH.LABEL": "Bestandsnaam",
	"FILTER.FILES.FORM.SEARCH.PLACEHOLDER": "Zoek op bestandsnaam en extensie",
	"FILTER.FILES.FORM.FILE_TYPES.PLACEHOLDER": "Bestandstype",

	//FLOWS OVERVIEW
	"FLOWS_OVERVIEW.PAGE.TITLE": "Flows",
	"FLOWS_OVERVIEW.SEARCH.PLACEHOLDER": "Zoek op flow",
	"FLOWS_OVERVIEW.SEARCH.DETAIL.TOTAL_RESULTS":
		"{value, plural, =0 {Er konden geen flows gevonden worden} one {# flow gevonden} other {# flows gevonden}}",
	"FLOWS_OVERVIEW.SEARCH.BUTTON.CREATE_FLOW": "Flow toevoegen",

	"FLOWS_OVERVIEW.FIRST_TIME.TITLE": "Maak jouw eerste flow aan",
	"FLOWS_OVERVIEW.FIRST_TIME.DESCRIPTION":
		"Flows geven jou de mogelijkheid om administratieve processen te digitaliseren. Gebruikers doorlopen zelfstandig een automatisch stappenplan in een gebruiksvriendelijke en interactieve chat omgeving.\nMet onze templates maak je snel een eigen flow aan die je meteen kan doorsturen naar jouw klanten. Deze feature zorgt voor minder repetitief werk en meer productiviteit.",
	"FLOWS_OVERVIEW.FIRST_TIME.BUTTON_LABEL": "Bekijk al onze templates",

	"FLOWS_OVERVIEW.LOCK.TITLE": "Flows: de automatische formulieren van de toekomst",
	"FLOWS_OVERVIEW.LOCK.DESCRIPTION":
		"Met flows kan je jouw administratieve processen digitaliseren. Gebruikers doorlopen zelfstandig een automatisch stappenplan in een gebruiksvriendelijke en interactieve chatomgeving.",
	"FLOWS_OVERVIEW.LOCK.UPGRADE_BUTTON": "Upgrade jouw plan",

	"FLOWS_OVERVIEW.NO_FLOWS_FOUND.TITLE": "Er konden geen flows gevonden",
	"FLOWS_OVERVIEW.NO_FLOWS_FOUND.DESCRIPTION": "Je hebt nog geen flows aangemaakt die voldoen aan jouw zoekopdracht.",

	"FLOWS_OVERVIEW.TABLE.HEADER.FLOW": "Flow",
	"FLOWS_OVERVIEW.TABLE.HEADER.LINK": "Link",
	"FLOWS_OVERVIEW.TABLE.ROW.LINK.BUTTON": "Kopieer link",
	"FLOWS_OVERVIEW.TABLE.HEADER.STATUS": "Status",
	"FLOWS_OVERVIEW.TABLE.FALLBACK": "Er konden geen flows gevonden worden",

	//FLOW_DELETE_MODAL
	"FLOW_DELETE_MODAL.HEADER.TITLE": "Flow verwijderen",
	"FLOW_DELETE_MODAL.HEADER.DESCRIPTION":
		"Ben je zeker dat je deze flow wil verwijderen? Deze actie kan niet ongedaan worden gemaakt. Alle links zullen gedeactiveerd worden.",
	"FLOW_DELETE_MODAL.DELETE.BUTTON_LABEL": "Verwijderen flow",

	//FLOW_TEMPLATE_MODAL
	"FLOW_TEMPLATE_MODAL.HEADER.TITLE": "Flow template",
	"FLOW_TEMPLATE_MODAL.HEADER.DESCRIPTION":
		"Kies een template om de flow toe te voegen. Wil je een nieuwe flow aanvragen? Laat een berichtje achter in onze klantendienst of mail naar support@bothive.be.",
	"FLOW_TEMPLATE_MODAL.CARD.BUTTON.SELECT": "Kies",
	"FLOW_TEMPLATE_MODAL.CARD.CHIP.COMING_SOON": "Binnenkort",

	"FLOW_TEMPLATE_MODAL.WARNING.TITLE": "Upgrade jouw plan.",
	"FLOW_TEMPLATE_MODAL.WARNING.DESCRIPTION":
		"Upgrade je plan om een nieuwe flow toe te voegen, wil je hier meer informatie of een demo over neem contact op met ons support team.",

	"FLOW_TEMPLATE_MODAL.FALLBACK.DESCRIPTION":
		"Er zijn nog geen flows beschikbaar voor jouw of de chatbot. Contacteer ons support team voor meer informatie of om vroegtijdig toegang krijgen tot nieuwe flows.",
	"FLOW_TEMPLATE_MODAL.FALLBACK.BUTTON":
		"Contacteer ons support team voor mogelijke verbeteringen of suggesties via support@bothive.be.",

	//FLOW_TEMPLATE_DETAIL_MODAL
	"flow_detail_template_modal.overview": "Terug naar overzicht",
	"flow_detail_template_modal.button": "Flow toevoegen",
	"FLOW_DETAIL_TEMPLATE_MODAL.FALLBACK.DESCRIPTION":
		"Contacteer ons support team voor mogelijke verbeteringen of suggesties",
	"FLOW_DETAIL_TEMPLATE_MODAL.FALLBACK.BUTTON": "support@bothive.be.",
	"FLOW_DETAIL_TEMPLATE.MODAL.EXISTS.TITLE": "{name} is al aangemaakt.",
	"FLOW_DETAIL_TEMPLATE.MODAL.EXISTS.DESCRIPTION": "Wil je nog een template voor deze flow aanmaken?",

	//FLOW_DETAIL
	"FLOW_DETAIL.PAGE.TITLE": "Flow",

	"FLOW_DETAIL.HEADER.BREADCRUMB.FLOWS": "Flows",

	"FLOW_DETAIL.SETTINGS.CUSTOMIZE.HEADER.TITLE": "Personaliseer flow",
	"FLOW_DETAIL.SETTINGS.CUSTOMIZE.FORM.NAME.LABEL": "Naam",
	"FLOW_DETAIL.SETTINGS.CUSTOMIZE.FORM.DESCRIPTION.LABEL": "Beschrijving",
	"FLOW_DETAIL.SETTINGS.FORM.IS_ENABLED.LABEL": "Flow inschakelen",
	"FLOW_DETAIL.SETTINGS.FORM.IS_ENABLED.HELP":
		"Als je de flow uitschakelt, is die niet meer beschikbaar voor jouw gebruikers.",

	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.HEADER.TITLE": "Flow widget instellingen",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.HEADER.DESCRIPTION":
		"Personaliseer de flowtegels die op je website getoond worden.",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.LOGO.TITLE": "Logo",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.LOGO.LABEL": "Upload logo",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.LOGO.HELP": "Maximale grootte: 10MB",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.NAME.LABEL": "Naam",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.DESCRIPTION.LABEL": "Beschrijving",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.HIDE_FLOW.LABEL": "Verberg flow in de widget",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.HIDE_FLOW.HELP":
		"Als je deze instelling inschakelt, zal deze flow niet zichtbaar zijn op jouw website of andere plaatsen waar je de flow widget hebt toegevoegd.",

	"FLOW_DETAIL.SETTINGS.FORM.UNIQUE_LINK.LABEL": "Deelbare link naar de flow",
	"FLOW_DETAIL.SETTINGS.FORM.UNIQUE_LINK.HELP": "Deel deze link met klanten waarvan je gegevens wil ophalen.",

	"FLOW_DETAIL.ASIDE.BACK_TO_OVERVIEW.LABEL": "Terug naar overzicht",
	"FLOW_DETAIL.ASIDE.REPORT.LABEL": "Rapporten",
	"FLOW_DETAIL.ASIDE.CONFIGURATION.LABEL": "Personalisatie",
	"FLOW_DETAIL.ASIDE.STATUS_TYPES.LABEL": "Statussen",
	"FLOW_DETAIL.ASIDE.SETTINGS.LABEL": "Flow instellingen",

	"FLOW_DETAIL.REPORT.TABLE.HEADER.CONTACT": "Contact",
	"FLOW_DETAIL.REPORT.TABLE.HEADER.ASSIGNEE": "Toegewezen aan",
	"FLOW_DETAIL.REPORT.TABLE.HEADER.STATUS": "Status",
	"FLOW_DETAIL.REPORT.TABLE.HEADER.PROGRESS": "Progressie",
	"FLOW_DETAIL.REPORT.FALLBACK.TITLE": "Rapporten",
	"FLOW_DETAIL.REPORT.FALLBACK.OR": "of",
	"FLOW_DETAIL.REPORT.FALLBACK.SEND": "Verstuur flow naar contacten",
	"FLOW_DETAIL.REPORT.FALLBACK.DESCRIPTION":
		"Hier vind je de rapporten terug van alle ingevulde flows. Op dit moment doorliep nog niemand de flow.\nDeel deze link met klanten waarvan je gegevens wil ophalen.",
	"FLOW_DETAIL.REPORT.FALLBACK.INPUT_LABEL": "Deel deze link met klanten waarvan je gegevens wil ophalen.",
	"FLOW_DETAIL.REPORT.TABLE.HEADER.CREATED_AT": "Aangemaakt",
	"FLOW_DETAIL.REPORT.TABLE.HEADER.LAST_UPDATED": "Laatst Geüpdatet",
	"FLOW_DETAIL.REPORT.FILTER.CONTROL.STATUS.LABEL": "Status",
	"FLOW_DETAIL.REPORT.FILTER.CONTROL.STATUS.PLACEHOLDER": "Alle open statussen",
	"FLOW_DETAIL.REPORT.FILTER.CONTROL.PROGRESS.LABEL": "Progressie",
	"FLOW_DETAIL.REPORT.FILTER.CONTROL.PROGRESS.PLACEHOLDER": "Alle progressie niveaus",
	"FLOW_DETAIL.REPORT.FILTER.SEARCH.LABEL": "Zoek op contact",
	"FLOW_DETAIL.REPORT.FILTER.SEARCH.PLACEHOLDER":
		"Zoek op de naam van het contact of het e-mailadres. Bijvoorbeeld: Sophia of sophia@bothive.be",
	"FLOW_DETAIL.REPORT.FILTER.DETAIL.TOTAL_RESULTS":
		"{value, plural, =0 {Geen rapporten gevonden} one {# rapport gevonden} other {# rapporten gevonden}}",

	"FLOW_DETAIL.REPORT.BUTTON.COPY_LINK": "Kopieer link",
	"FLOW_DETAIL.REPORT.BUTTON.SEND": "Verstuur flow",
	"FLOW_DETAIL.REPORT.BUTTON.REMIND": "Herinner contacten",

	"FLOW_DETAIL.STATUS_TYPE.WALK_THROUGH.TITLE": "Werk vlotter samen met statussen",
	"FLOW_DETAIL.STATUS_TYPE.WALK_THROUGH.DESCRIPTION":
		"Stel statussen in om efficiënter te werken binnen je team. Specifieke statustypes tonen de vooruitgang van taken. Stel automatische regels in om bepaalde acties te triggeren.",

	"FLOW_DETAIL.STATUS_TYPE.TABLE.HEADER.TITLE": "Statustype",
	"FLOW_DETAIL.STATUS_TYPE.TABLE.HEADER.IS_DEFAULT": "Standaard",
	"FLOW_DETAIL.STATUS_TYPE.TABLE.HEADER.IS_DONE": "Voltooid",
	"FLOW_DETAIL.STATUS_TYPE.BUTTON.CREATE_STATUS_TYPE": "Nieuw statustype",

	"STATUS_TYPE_MODAL.HEADER.TITLE.CREATE": "Nieuw statustype",
	"STATUS_TYPE_MODAL.HEADER.TITLE.EDIT": "Bewerk statustype",
	"STATUS_TYPE_MODAL.FORM.NAME.LABEL": "Naam",
	"STATUS_TYPE_MODAL.FORM.NAME.PLACEHOLDER": "Review",
	"STATUS_TYPE_MODAL.FORM.DESCRIPTION.LABEL": "Beschrijving",
	"STATUS_TYPE_MODAL.FORM.DESCRIPTION.PLACEHOLDER": "Wanneer gebruikt je team dit statustype?",
	"STATUS_TYPE_MODAL.FORM.IS_DEFAULT.LABEL": "Standaard",
	"STATUS_TYPE_MODAL.FORM.IS_DEFAULT.HELP": "Koppel dit statustype automatisch aan nieuwe rapporten. ",
	"STATUS_TYPE_MODAL.FORM.ORDER.LABEL": "Rangorde",
	"STATUS_TYPE_MODAL.FORM.ORDER.HELP":
		"Definieer de rangorde van dit statustype met een cijfer van 0 tot -. Bijvoorbeeld '1' staat voor de eerste plaats. Alle flows met deze rangorde komen bovenaan de lijst.",
	"STATUS_TYPE_MODAL.FORM.WARNING_BANNER.DEFAULT_IS_ON":
		"Je kan maximaal één statustype instellen als standaard. Schakel dit statustype uit als standaard om het te vervangen door een ander statustype.",
	"STATUS_TYPE_MODAL.FORM.WARNING_BANNER.DEFAULT_REPLACE":
		"Je kan maximaal één statustype instellen als standaard. Als je dit statustype inschakelt als standaard vervangt het {default} als standaard statustype.",
	"STATUS_TYPE_MODAL.FORM.IS_DONE.LABEL": "Voltooid",
	"STATUS_TYPE_MODAL.FORM.IS_DONE.HELP":
		"Rapporten met dit statustype worden gezien als afgewerkt. Deze rapporten worden dus niet meer standaard in het overzicht getoond.",
	"STATUS_TYPE_MODAL.BUTTON.SUBMIT": "Opslaan",
	"STATUS_TYPE_MODAL.BUTTON.CANCEL": "Annuleren",
	"STATUS_TYPE_MODAL.BUTTON.DELETE": "Verwijderen",

	"STATUS_TYPE_COPY.BUTTONLABEL": "Kopieer van andere flows",
	"STATUS_TYPE_COPY.HEADER.TITLE": "Kopieer statustypes van andere flows",
	"STATUS_TYPE_COPY.BUTTON_SUBMIT": "Voeg toe",
	"STATUS_TYPE_COPY.BUTTON_CANCEL": "Annuleren",
	"STATUS_TYPE_COPY.FORM.OVERRIDE_LABEL": "Overschrijven",
	"STATUS_TYPE_COPY.FORM.DROPDOWN_LABEL": "Flows",
	"STATUS_TYPE_COPY.FORM.OVERRIDE.HELP": "Overschrijf de reeds aangemaakte statustypes van deze flow.",

	"STATUS_TYPE.MAX.WARNING.DESCRIPTION":
		"Je gebruikte reeds {currentStatusTypes} van de {maxStatusTypes} beschikbare statustypes bereikt, verhoog je plan om nog meer status types te kunnen toevoegen.",
	"STATUS_TYPE.MAX.LIMIT.DESCRIPTION":
		"Je hebt je limiet van {maxStatusTypes} statustypes bereikt, verhoog je plan om meer status types te kunnen toevoegen.",

	"FLOW_DETAIL.CONFIGURATION.TITLE": "Personaliseer jouw flow",
	"FLOW_DETAIL.CONFIGURATION.DESCRIPTION":
		"Wil je meer gegevens ophalen of vind je de flow net te uitgebreid? Binnenkort kan je onze standaard flows bewerken naar jouw voorkeur. Neem contact op met ons support team om als eerste toegang te krijgen van deze functie!",
	"FLOW_DETAIL.CONFIGURATION.FORM.SUBMIT": "Opslaan",

	"FLOW_DETAIL.SETTINGS.DANGER_ZONE.ACCORDION_TITLE": "Flow verwijderen",
	"FLOW_DETAIL.SETTINGS.DANGER_ZONE.TITLE": "Verwijder de flow",
	"FLOW_DETAIL.SETTINGS.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je deze flow wil verwijderen? Deze actie kan niet ongedaan worden gemaakt. Als je deze flow verwijdert, is de unieke link niet meer beschikbaar en worden alle rapporten verwijderd.",
	"FLOW_DETAIL.SETTINGS.DANGER_ZONE.BUTTON": "Verwijder flow",
	"FLOW_DETAIL.FIELDS.REQUIRED.HOVER": "Verplicht veld",

	//FORM_ELEMENT
	"FORM_ELEMENT.LABEL.OPTIONAL": "(optioneel)",
	"FORM_ELEMENT.ERROR.THIS_FIELD": "Dit veld",
	"FORM_ELEMENT.ERROR.REQUIRED": "{label} is verplicht",
	"FORM_ELEMENT.ERROR.MIN_LENGTH": "{label} is niet lang genoeg",
	"FORM_ELEMENT.ERROR.MAX_LENGTH": "{label} heeft te veel karakters",
	"FORM_ELEMENT.ERROR.TRIGGERS_NOT_COMPLETE": "Niet alle velden zijn ingevuld",
	"FORM_ELEMENT.ERROR.ARRAY_LENGTH": "Je hebt niet genoeg items geselecteerd",
	"FORM_ELEMENT.ERROR.EMAIL": "Dit is geen correct e-mailadres",
	"FORM_ELEMENT.ERROR.UNIQUE_CONTACT_LABEL_EMAIL": "Er bestaat al een label met deze naam in jouw team",
	"FORM_ELEMENT.ERROR.UNIQUE_USER_EMAIL": "Er is al een gebruiker geregistreerd met dit e-mailadres",
	"FORM_ELEMENT.ERROR.UNIQUE_TEAM_EMAIL": "Er is al een team geregistreerd met dit e-mailadres",
	"FORM_ELEMENT.ERROR.NOT_A_NUMBER": "{label} verwacht een number en accepteerd alleen maar karakters 0-9",
	"FORM_ELEMENT.ERROR.PASSWORD_VALIDATOR": "Wachtwoord is niet correct",
	"FORM_ELEMENT.ERROR.PASSWORD_COMPARE": "Wachtwoorden komen niet overeen",
	"FORM_ELEMENT.ERROR.HAS_UPPER_CASE": "{label} moet minimaal 1 hoofdletter bevatten",
	"FORM_ELEMENT.ERROR.OPENING_HOURS_INVALID":
		"De openingsuren kloppen niet, controleer of er geen overlappende openingsuren zijn...",
	"FORM_ELEMENT.ERROR.UPLOAD_FAILED": "Uploaden is mislukt",
	"FORM_ELEMENT.ERROR.COMPANY_NUMBER": "BTW heeft niet het correcte formaat",
	"FORM_ELEMENT.ERROR.NAME": "{label} bestaat reeds",
	"FORM_ELEMENT.ERROR.CONFLICTED_LABEL":
		"Het lijkt erop dat dit label al bestaat! Wij voorzagen een aantal standaard labels in de inbox. Je hoeft deze dus niet aan te maken.",
	"FORM_ELEMENT.ERROR.FID_USERNAME_INVALID": "Ongeldige FID gebruikersnaam.",

	"FORM_ELEMENT.BUTTON.DEFAULT": "Opslaan",
	"FORM_ELEMENT.BUTTON.ADD_FIELD": "Veld toevoegen",
	"FORM_ELEMENT.BUTTON.SEND": "Versturen",
	"FORM_ELEMENT.BUTTON.NEW_ROW": "Nieuwe rij toevoegen",
	"FORM_ELEMENT.PROMPT.UNSAVED_CHANGES": "Ben je zeker dat je weg wilt navigeren zonder op te slaan?",
	"FORM_ELEMENT.SEGMENT.CHOOSE_A_IMAGE": "Kies een afbeelding",
	"FORM_ELEMENT.SEGMENT.UPLOAD_IMAGE": "Upload afbeelding",
	"FORM_ELEMENT.SEGMENT.CHOOSE_A_FILE": "Kies een bestand",
	"FORM_ELEMENT.SEGMENT.FILE_UPLOADED":
		"{value, plural, =0 {# bestanden} one {# bestand} other {# bestanden}} geüpload",
	"FORM_ELEMENT.INCL_EXCL.INCLUDE_TITLE": "Selecteer welke sectoren je accepteert",
	"FORM_ELEMENT.INCL_EXCL.EXCLUDE_TITLE": "Selecteer welke sectoren je weigert",
	"FORM_ELEMENT.ERROR.INVALID_COUPON": "Deze promotiecode is niet geldig",
	"FORM_ELEMENT.ERROR.VALUE_DELETE": "De invoer is niet gelijk aan VERWIJDEREN",
	"FORM_ELEMENT.ERROR.VALUE_ARCHIVE": "De invoer is niet gelijk aan ARCHIVEREN",
	"FORM_ELEMENT.ERROR.CANCEL_SUBSCRIPTION": "De invoer is niet gelijk aan CANCEL SUBSCRIPTION",
	"FORM_ELEMENT.ERROR.DOWNGRADE": "De invoer is niet gelijk aan DOWNGRADE",
	"FORM_ELEMENT.LANGUAGE.EN": "Engelstalige versie",
	"FORM_ELEMENT.LANGUAGE.NL": "Nederlandstalige versie",
	"FORM_ELEMENT.MAP_FIELD.CUSTOM.LABEL": "Custom",
	"FORM_ELEMENT.TIME_DROPDOWN.HOUR": "Uren",
	"FORM_ELEMENT.TIME_DROPDOWN.MINUTE": "Minuten",

	"FORM_ELEMENT.FORM_GROUP.ADD_LOCATION": "Adres toevoegen",
	"FORM_ELEMENT.FORM_GROUP.LINK_CONTACT": "Link contact",
	"FORM_ELEMENT.RULE_FORM.BUTTON.ADD_COMPONENT": "Voeg een component toe",

	//GRAPH
	"GRAPH.HOUR_SHORT_HAND.H": "{hour} u",
	"GRAPH.HOUR_SHORT_HAND.MO": "Ma",
	"GRAPH.HOUR_SHORT_HAND.TU": "Di",
	"GRAPH.HOUR_SHORT_HAND.WE": "Wo",
	"GRAPH.HOUR_SHORT_HAND.TH": "Do",
	"GRAPH.HOUR_SHORT_HAND.FR": "Vr",
	"GRAPH.HOUR_SHORT_HAND.SA": "Za",
	"GRAPH.HOUR_SHORT_HAND.SU": "Zo",

	//IMPORT_CONTACTS_MODAL.CONTACT_LIST
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.HEADER.TITLE": "Contactinfo aanvullen",
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.HEADER.DESCRIPTION":
		"Hieronder vind je een overzicht van de contacten die niet geïmporteerd kunnen worden omdat er data ontbreekt. Vul de ontbrekende info aan om de contacten te kunnen importeren.",
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.TABLE.USERNAME": "Naam",
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.TABLE.EMAIL": "E-mail",
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.TABLE.FOOTNOTE":
		"Het maximaal aantal pagina’s is een suggestie en zal wijzigen wanneer het aantal contacten de huidige limiet overschrijdt.",
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.FOOTER.BACK.LABEL": "Wijzig importopties",
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.FOOTER.IMPORT.LABEL":
		"Verdergaan met {value, plural, =0 {alle contacten} one {# contact} other {# contacten}}",

	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.BANNER.FAILED":
		"Er is iets misgelopen bij het importeren van je contacten probeer later nog eens opnieuw.",
	//IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS
	"IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.OPTIONS.CSV.LABEL": "CSV-bestand",
	"IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.OPTIONS.ADD_IMPORT_OPTIONS.LABEL": "Import opties toevoegen",

	"IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.INFO_BANNER.TITLE": " Nieuwe importmogelijkheden",
	"IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.INFO_BANNER.DESCRIPTION":
		"Je kan nu contacten importeren vanuit AdminPulse of FID-Manager. Ga naar Integraties om je CRM-systeem te koppelen aan het Bothive platform.",

	// INBOX
	"inbox.page.title": "Inbox (BETA)",
	"inbox.inbox_labels.select.hidden.label": "Verborgen label",
	"inbox.inbox_labels.select.hidden.label.tooltip": "Privélabel van een andere gebruiker.",
	"INBOX.PAGE.TITLE": "Inbox (BETA)",

	// Coming soon
	"INBOX.COMING_SOON.TITLE": "Nu in Publieke beta",
	"INBOX.COMING_SOON.DESCRIPTION":
		"Werk e-mails sneller af door efficiënt samen te werken in de gedeelde team inbox.",
	"INBOX.COMING_SOON.FEATURES.ASSIGN": "Wijs e-mails aan teamleden toe",
	"INBOX.COMING_SOON.FEATURES.TEAM_INBOX": "Creëer rond elke e-mail een interne chatconversatie",
	"INBOX.COMING_SOON.FEATURES.INBOX_ZERO": "Bereik inbox zero door optimaal samen te werken",

	//INBOX ASIDE
	"INBOX.ASIDE.BUTTON.COMPOSE": "Nieuwe e-mail",
	"INBOX.ASIDE.CHANNEL.TITLE": "Kanalen",
	"INBOX.ASIDE.CHANNEL.EMPTY.DESCRIPTION": "Geen kanalen gevonden",
	"INBOX.ASIDE.CHANNEL.EMPTY.BUTTON": "Connecteer een kanaal",
	"INBOX.ASIDE.CHANNEL.RECONNECT.TITLE": "Verbind dit kanaal opnieuw",
	"INBOX.ASIDE.LABELS.TITLE": "Labels",
	"INBOX.ASIDE.LABELS.EMPTY.DESCRIPTION": "Geen labels gevonden",
	"INBOX.ASIDE.LABELS.EMPTY.BUTTON": "Label toevoegen",
	"INBOX.ASIDE.LABELS.BUTTON.SHOW_LESS": "Toon minder labels",
	"INBOX.ASIDE.LABELS.BUTTON.ADD_LABEL": "Label toevoegen",
	"INBOX.ASIDE.LABELS.BUTTON.SHOW_MORE": "Toon meer labels",
	"INBOX.ASIDE.SITE_VISITOR": "Gesprekspartner",
	//INBOX.CONVERSATION_ASIDE
	"INBOX.CONVERSATION_ASIDE.CHIP.EMAIL": "E-mail",
	"INBOX.CONVERSATION_ASIDE.CHIP.MESSENGER": "Messenger",
	"INBOX.CONVERSATION_ASIDE.CHIP.DEFAULT": "Bericht",
	"INBOX.CONVERSATION_ASIDE.CHIP.WHATSAPP": "WhatsApp",

	"INBOX.CONVERSATION_ASIDE.PAYLOAD.BUTTONS": "Opties",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.FILE": "Bestand preview",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.INLINEBUTTONS": "Opties",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.IMAGE": "Afbeelding",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.INVOICE": "Factuur",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.OPENINGHOURS": "Openingsuren",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.UPLOAD": "Upload bestand",

	"INBOX.CONVERSATION_ASIDE.PAYLOAD.NOT_SUPPORTED": "Bericht is niet ondersteund",

	"INBOX.CONVERSATION_ASIDE.SUB_HEADER.EMPTY_TRASH": "Leegmaken",
	"INBOX.CONVERSATION_ASIDE.CONFIRM_DELETE_MODAL.EMPTY_TRASH.TITLE": "Prullenmand leegmaken",
	"INBOX.CONVERSATION_ASIDE.CONFIRM_DELETE_MODAL.EMPTY_TRASH.BUTTON_LABEL": "Prullenmand leegmaken",
	"INBOX.CONVERSATION_ASIDE.CONFIRM_DELETE_MODAL.EMPTY_TRASH.DESCRIPTION":
		"Ben je zeker dat je alle conversaties in de prullenmand permanent wil verwijderen?",
	"INBOX.CONVERSATION_ASIDE.CONFIRM_DELETE_MODAL.EMPTY_TRASH.EXTRA_DESCRIPTION":
		"Let op: dit zal ook de conversaties verwijderen voor alle toegewezen of vermelde teamleden. Enkel als hun e-mailadres in de header van de e-mail (aan, van, cc, bcc) staat, kunnen ze de conversatie recupereren.",
	"INBOX.CONVERSATION_ASIDE.CONFIRM_DELETE_MODAL.EMPTY_TRASH.PLACEHOLDER":
		"Typ {keyword} en klik daarna op de Verwijder knop",
	"INBOX.CONVERSATION_ASIDE.CONFIRM_DELETE_MODAL.EMPTY_TRASH.ERROR": "De invoer is niet gelijk aan {keyword}",

	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.UNSEEN": "Markeer als ongezien",
	"conversation.detail.actions.read.button.title": "Markeer als gezien",
	"conversation.detail.actions.unread.button.title": "Markeer als ongezien",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.SEEN": "Markeer als gezien",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.CLOSE": "Sluit conversatie",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.OPEN": "Open conversatie",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.UN_ARCHIVE": "Conversatie terug plaatsen",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.ARCHIVED": "Archiveren",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.SPAM": "Markeren als ongewenst",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.UN_SPAM": "Geen ongewenste e-mail",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.DELETE": "Verwijderen",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.UN_TRASH": "Conversatie terugplaatsen",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.DELETE_DRAFT": "Verwijder concept",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.UN_TRASH_DRAFT": "Plaats concept terug",

	//INBOX.CONVERSATION

	"INBOX.CONVERSATION.HEADER.BUTTONS.LABELS": "Label toevoegen",
	"INBOX.CONVERSATION.HEADER.BUTTONS.ASSIGN_USER": "Teamlid toewijzen",
	"INBOX.CONVERSATION.HEADER.BUTTONS.CLOSE": "Markeren als afgewerkt",
	"INBOX.CONVERSATION.HEADER.BUTTONS.SNOOZE": "Snooze conversatie",
	"INBOX.CONVERSATION.HEADER.BUTTONS.OPEN": "Open conversatie",
	"INBOX.CONVERSATION.HEADER.BUTTONS.SPAM": "Markeren als ongewenst",
	"INBOX.CONVERSATION.HEADER.BUTTONS.UN_SPAM": "Geen ongewenste e-mail",
	"INBOX.CONVERSATION.HEADER.BUTTONS.DELETE": "Verwijderen",
	"INBOX.CONVERSATION.HEADER.BUTTONS.UN_TRASH": "Conversatie terug plaatsen",

	"INBOX.CONVERSATION.FOOTER.EDITOR.label": "Alleen zichtbaar voor je team",
	"INBOX.CONVERSATION.FOOTER.EDITOR.PLACEHOLDER": "Schrijf een intern bericht.",

	"INBOX.CONVERSATION.REPLY_FOOTER.REPLY": "Conversatie beantwoorden",
	"INBOX.CONVERSATION.REPLY_FOOTER.REPLY_ALL": "Allen beantwoorden",
	"INBOX.CONVERSATION.REPLY_FOOTER.FORWARD": "Doorsturen",
	"INBOX.CONVERSATION.REPLY_FOOTER.ASSIGN": "Teamlid toewijzen",

	"INBOX.CONVERSATION.LIST.FAILED_MESSAGE.TOOLTIP": "Conversatie bevat een gefaald bericht",
	"INBOX.CONVERSATION.LIST.DRAFT_MESSAGE.TOOLTIP": "Conversatie bevat een concept",

	//INBOX.SHORTCUTS
	"INBOX.SHORTCUTS.TITLE": "Sneltoetsen",

	"INBOX.SHORTCUTS.INBOX.TITLE": "Inbox",
	"INBOX.SHORTCUTS.INBOX.NEW_MESSAGE.LABEL": "Nieuwe e-mail",
	"INBOX.SHORTCUTS.INBOX.NEXT_CONVERSATION.LABEL": "Volgende conversatie",
	"INBOX.SHORTCUTS.INBOX.PREVIOUS_CONVERSATION.LABEL": "Vorige conversatie",
	"INBOX.SHORTCUTS.INBOX.left_status_folder.LABEL": "Ga naar de linker statusfolder",
	"INBOX.SHORTCUTS.INBOX.right_status_folder.LABEL": "Ga naar de rechter statusfolder",
	"INBOX.SHORTCUTS.INBOX.inbox.search.focus.LABEL": "Zoeken",

	"INBOX.SHORTCUTS.CONVERSATION.TITLE": "Conversaties",
	"INBOX.SHORTCUTS.CONVERSATION.REPLY.LABEL": "Conversatie beantwoorden",
	"INBOX.SHORTCUTS.CONVERSATION.REPLY_ALL.LABEL": "Allen beantwoorden",
	"INBOX.SHORTCUTS.CONVERSATION.FORWARD.LABEL": "Conversatie doorsturen",
	"INBOX.SHORTCUTS.CONVERSATION.internal_reply.LABEL": "Beantwoord in interne chat",
	"INBOX.SHORTCUTS.CONVERSATION.CLOSE.LABEL": "Conversatie afsluiten/heropenen",
	"INBOX.SHORTCUTS.CONVERSATION.MARK_AS_UNREAD.LABEL": "Markeren als gelezen/ongelezen",
	"INBOX.SHORTCUTS.CONVERSATION.OPEN_ASSIGN.LABEL": "Toewijs menu openen",
	"INBOX.SHORTCUTS.CONVERSATION.OPEN_PARTICIPANTS.LABEL": "Deelnemers menu openen",
	"INBOX.SHORTCUTS.CONVERSATION.OPEN_LABEL.LABEL": "Labels menu openen",
	"INBOX.SHORTCUTS.CONVERSATION.toggle_self_assign.LABEL": "Jezelf toewijzen",
	"INBOX.SHORTCUTS.CONVERSATION.DELETE.LABEL": "Conversatie verwijderen",

	"INBOX.SHORTCUTS.MESSAGING.TITLE": "Berichten",
	"INBOX.SHORTCUTS.MESSAGING.BOLD.LABEL": "Vette tekst",
	"INBOX.SHORTCUTS.MESSAGING.ITALIC.LABEL": "Cursieve tekst",
	"INBOX.SHORTCUTS.MESSAGING.UNDERLINED.LABEL": "Onderstreepte tekst",

	"INBOX.SHORTCUTS.GLOBAL.TITLE": "Algemeen",
	"INBOX.SHORTCUTS.GLOBAL.CLOSE_MODAL.LABEL": "Menu of pop-up sluiten",
	"INBOX.SHORTCUTS.GLOBAL.SHORTCUTS.LABEL": "Toon sneltoetsen",

	// INBOX CHAT
	"INBOX.CHAT.NO_CHAT": "Geen conversatie geselecteerd",
	"INBOX.CHAT.SITE_VISITOR": "Gesprekspartner",

	"INBOX.PAYLOAD.DOWNLOAD_FILE": "Download het bestand",

	//INBOX_LABEL_POPUP
	"INBOX_LABEL_POPUP.HEADER.TITLE": "Labels",
	"INBOX_LABEL_POPUP.FALLBACK.TITLE": "Geen labels gevonden.",
	"INBOX_LABEL_POPUP.SEARCH.PLACEHOLDER": "Zoek op labelnaam...",
	"INBOX_LABEL_POPUP.FOOTER.ADD_LABEL": "Nieuw label aanmaken",
	"INBOX_LABEL_POPUP.ASSIGNED_HEADER.TITLE": "Toegewezen labels",
	"INBOX_LABEL_POPUP.FALLBACK.ERROR.TITLE": "Fout bij het ophalen van labels.",

	"INBOX_LABEL_POPUP.ERROR.TITLE": "Fout opgetreden bij markering",
	"INBOX_LABEL_POPUP.ERROR.DESCRIPTION": "Er liep iets mis tijdens het toekennen van een label aan de conversatie.",

	//INBOX_SETTINGS
	"SETTINGS.INBOX.CHANNELS.ROOT": "Instellingen",
	"SETTINGS.INBOX.CHANNELS.TITLE": "Kanalen",

	"SETTINGS.INBOX.CHANNELS.CONNECTED.TITLE": "Geconnecteerde kanalen",
	"SETTINGS.INBOX.CHANNELS.CONNECTED.DESCRIPTION": "Verbonden met e-mail {channelName}",
	"SETTINGS.INBOX.CHANNELS.CONNECTED.BUTTON_LABEL": "Wijzigen",

	"SETTINGS.INBOX.CHANNELS.CONNECTIONS.TITLE": "Connecteer een nieuw kanaal",
	"SETTINGS.INBOX.CHANNELS.CONNECTIONS.DESCRIPTION": "Verbind de Bothive Inbox met je gebruikte e-mail tool(s).",
	"SETTINGS.INBOX.CHANNELS.FALLBACK": "Er konden geen kanalen gevonden worden.",

	"SETTINGS.INBOX.CHANNELS.LOCK.TITLE": "Connecteer je e-mail kanalen",
	"SETTINGS.INBOX.CHANNELS.LOCK.DESCRIPTION":
		"Connecteer je Gmail en/of Outlook inbox om de team inbox volledig te gebruiken.",
	"SETTINGS.INBOX.CHANNELS.LOCK.BUTTON.CONTACT_SUPPORT": "Contacteer ons",

	// CONNECT_CHANNELS
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.HEADER.TITLE": "Instellingen kanaal",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.HEADER.DESCRIPTION": "Personaliseer je kanaal.",

	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.NAME.LABEL": "Naam kanaal",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.NAME.ERROR.IS_REQUIRED": "Een channel naam is verplicht",

	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.AUTO_CLOSE.LABEL": "Automatisch afwerken",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.AUTO_CLOSE.HELP":
		"Markeer je bekeken e-mails automatisch als afgewerkt zodat je een overzichtelijke inbox behoudt.",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.IS_PRIVATE.LABEL": "Privékanaal",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.IS_PRIVATE.HELP":
		"Privékanalen zijn enkel zichtbaar voor jou of eigenaars van het team.",

	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.SUBMIT": "Kanaal aanmaken",

	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.IS_PUBLIC_WARNING.TITLE": "Waarschuwing",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.IS_PUBLIC_WARNING.DESCRIPTION":
		"Je kanaal staat nu publiek, waardoor iedereen van je team het aan hun inbox kan toevoegen. Dat betekent dat ze via dit kanaal berichten kunnen ontvangen, versturen, aanpassen en verwijderen.",

	"SETTINGS.INBOX.CONNECT_CHANNELS.SYNCING.HEADER.TITLE": "De synchronisatie kan enkele minuten duren",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SYNCING.HEADER.DESCRIPTION":
		"De synchronisatie kan enkele minuten duren. Bekijk intussen de intro tot de inbox om wegwijs te geraken in deze tool. Je kan ook gerust dit venster sluiten en verder werken in het platform.",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SYNCING.BUTTON.CLOSE": "Scherm sluiten",
	"SETTINGS.INBOX.CONNECT_CHANNELS.ERROR.BLOCKED_POPUP":
		"Bij het koppelen van je kanaal wordt er een pop-up venster geopend. Hiervoor moet je eerst toestemming geven in de instellingen van je browser.",

	//INBOX_SETTINGS_PROFILE.CHANNEL_DETAIL
	"INBOX_SETTINGS_PROFILE.CHANNEL_DETAIL.DETAILS.FORM.NAME.LABEL": "Naam",
	"INBOX_SETTINGS_PROFILE.CHANNEL_DETAIL.DETAILS.FORM.ORGANIZATION.LABEL": "Koppel met team",
	"INBOX_SETTINGS_PROFILE.CHANNEL_DETAIL.DETAILS.FORM.IS_PRIVATE.LABEL": "Privékanaal",
	"INBOX_SETTINGS_PROFILE.CHANNEL_DETAIL.DETAILS.FORM.IS_PRIVATE.HELP":
		"Privékanalen zijn enkel zichtbaar voor jou of eigenaars van het team.",

	//INTEGRATION
	"SETTINGS.INTEGRATIONS.OVERVIEW.PAGE.TITLE": "Integraties overzicht",
	"SETTINGS.INTEGRATIONS.OVERVIEW.HEADER.BREADCRUMB.SETTINGS": "Instellingen",
	"SETTINGS.INTEGRATIONS.OVERVIEW.HEADER.BREADCRUMB.PAGE_TITLE": "Integraties overzicht",

	"SETTINGS.INTEGRATIONS.OVERVIEW.FALLBACK.TITLE": "Er lijkt iets te zijn misgelopen.",
	"SETTINGS.INTEGRATIONS.OVERVIEW.FALLBACK.DESCRIPTION":
		"Integraties overzicht kan niet getoond worden. Als dit blijft gebeuren contacteer onze klantendienst.",

	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.TITLE": "Integraties",
	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.DESCRIPTION":
		"Breid het Bothive platform uit met integraties. Synchroniseer met je CRM-systeem en connecteer met andere platformen zoals WhatsApp, Messenger en Outlook.\n\nVind je de gewenste integratie niet in het huidige aanbod? Meld het via onze klantendienst, dan voegen we de koppeling zo snel mogelijk voor je toe. ",
	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.PROGRESS_LABEL":
		"Je hebt {value, plural, =0 {geen integraties} one {# integratie} other {# integraties}} over in jouw huidig plan.",
	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.PROGRESS_LABEL_UNLIMITED":
		"Je hebt ongelimiteerde integraties in jouw huidig plan.",

	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.NO_INTEGRATIONS":
		"Met je huidige abonnement heb je geen toegang tot integraties.\n\nContacteer ons via de de klantendienst om je abonnement te upgraden.",
	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.NO_INTEGRATIONS_NO_UPGRADE":
		"Met je huidige abonnement heb je geen toegang tot integraties.\n\nContacteer ons via de de klantendienst om je abonnement te upgraden.",
	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.UPGRADE_BUTTON": "Upgrade abonnement",
	"SETTINGS.INTEGRATIONS.OVERVIEW.ACTIVE.TITLE": "Actieve integraties",
	"SETTINGS.INTEGRATIONS.OVERVIEW.ACTIVE.FALLBACK": "Je hebt nog geen integraties gemaakt.",
	"SETTINGS.INTEGRATIONS.OVERVIEW.ALL_INTEGRATIONS.TITLE": "Alle integraties",
	"SETTINGS.INTEGRATIONS.OVERVIEW.ALL_INTEGRATIONS.FALLBACK":
		"Er zijn geen integraties meer beschikbaar. Contacteer ons als je jouw integratie niet gevonden hebt en wij voegen het voor je toe.",

	"SETTINGS.INTEGRATIONS.OVERVIEW.REQUEST_INTEGRATION.TITLE": "Integraties aanvragen",
	"SETTINGS.INTEGRATIONS.OVERVIEW.REQUEST_INTEGRATION.DESCRIPTION":
		"Op zoek naar een andere integratie? Meld het via onze klantendienst, dan voegen wij deze zo snel mogelijk voor je toe.\n\nTip: Als je een contactpersoon hebt bij de betreffende integratie, vermeld dan zeker de gegevens van deze persoon of iemand van hun development team.",
	"SETTINGS.INTEGRATIONS.OVERVIEW.REQUEST_INTEGRATION.BUTTON": "Integratie aanvragen",

	//	SETTINGS.INTEGRATIONS.CONTROLLER
	"SETTINGS.INTEGRATIONS.CONTROLLER.SECTION.BACK_BUTTON": "Terug naar integraties",

	// SETTINGS.INTEGRATIONS.ADMIN_PULSE
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.PAGE.TITLE": "AdminPulse integratie",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.BREADCRUMB.PAGE_TITLE": "AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.TITLE": "AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.DESCRIPTION":
		"Koppel met Admin Pulse om je contacten synchronizeren met Bothive en de flows te synchronizeren met Admin Pulse.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.FEATURES.SYNC_CONTACTS": "Synchronizeren contacten",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.OAUTH2.DESCRIPTION": "Koppel met AdminPulse via de AdminPulse website.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.OAUTH2.BUTTON": "Log in met AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.SYNC_CONTACTS.TITLE": "Importeer Admin Pulse klanten als contacten",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.SYNC_CONTACTS.DESCRIPTION":
		"Automatisch importeer jouw AdminPulse klanten in jouw Bothive team om beide contacten met elkaar te linken, contact detectie te verbeteren en data synchronisatie te optimaliseren.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.SYNC_CONTACTS.BUTTON_LABEL": "Importeer contacten",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.TITLE": "Verwijder integratie met AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je de integratie met AdminPulse wil stopzetten? Alle functionaliteit zullen niet meer beschikbaar zijn voor jouw klanten vanaf je het stopzet.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.BUTTON": "Verwijder integratie",
	"settings.integrations.admin_pulse.tasks.button.enable": "Nieuwe link toevoegen",
	"settings.integrations.admin_pulse.tasks.create_new_link.button.cancel": "Annuleren",
	"settings.integrations.admin_pulse.tasks.create_new_link.button.submit": "Nieuwe link maken",
	"settings.integrations.admin_pulse.tasks.create_new_link.failed":
		"Het lijkt erop dat de taaklink nu niet kan worden gemaakt. Probeer het later opnieuw. We zorgen ervoor dat dit snel voor je wordt opgelost!",
	"settings.integrations.admin_pulse.tasks.create_new_link.succeeded": "Nieuwe taaklink is aangemaakt",
	"settings.integrations.admin_pulse.tasks.create_new_link.title": "Nieuwe taaklink maken",
	"settings.integrations.admin_pulse.tasks.delete_linking_model.button.cancel": "Annuleren",
	"settings.integrations.admin_pulse.tasks.delete_linking_model.button.delete": "Verwijderen",
	"settings.integrations.admin_pulse.tasks.delete_linking_model.description":
		"Het verwijderen van deze link is een eenrichtingsstraat! Deze taken worden niet meer automatisch bijgewerkt. Klaar om door te gaan met verwijderen? 🗑️",
	"settings.integrations.admin_pulse.tasks.delete_linking_model.failed":
		"Het lijkt erop dat de link nu niet kan worden verwijderd. Probeer het later opnieuw. We zorgen ervoor dat dit snel voor je wordt opgelost!",
	"settings.integrations.admin_pulse.tasks.delete_linking_model.succeeded": "De taaklink is verwijderd.",
	"settings.integrations.admin_pulse.tasks.delete_linking_model.title": "Taaklink verwijderen",
	"settings.integrations.admin_pulse.tasks.description":
		"Taakkoppeling in AdminPulse is jouw geheime saus! Verbind taken in AdminPulse met stroomacties in Bothive. Als een van deze acties plaatsvindt, werken we de status van die taak in AdminPulse bij. Het is alsof je de puntjes verbindt, maar dan voor jouw taken.",
	"settings.integrations.admin_pulse.tasks.edit_task_modal.button.cancel": "Annuleren",
	"settings.integrations.admin_pulse.tasks.edit_task_modal.button.delete": "Verwijderen",
	"settings.integrations.admin_pulse.tasks.edit_task_modal.button.submit": "Taaklink bijwerken",
	"settings.integrations.admin_pulse.tasks.edit_task_modal.succeeded": "Link is bijgewerkt",
	"settings.integrations.admin_pulse.tasks.edit_task_modal.title": "Taaklink",
	"settings.integrations.admin_pulse.tasks.edit_task_modal.update.failed":
		"Het lijkt erop dat de taaklink nu niet kan worden bijgewerkt. Probeer het later opnieuw. We zorgen ervoor dat dit snel voor je wordt opgelost!",
	"settings.integrations.admin_pulse.tasks.form.flow_action.label":
		"Wanneer een van de volgende acties plaatsvindt in een stroomrapport",
	"settings.integrations.admin_pulse.tasks.form.flow_action.validation.required":
		"Vergeet niet om een actie te kiezen.",
	"settings.integrations.admin_pulse.tasks.form.flow_template.empty_message": "Kan geen stroomtemplates vinden.",
	"settings.integrations.admin_pulse.tasks.form.flow_template.label": "Linkstroom",
	"settings.integrations.admin_pulse.tasks.form.flow_template.validation.required":
		"Vergeet niet om een stroomtemplate te kiezen.",
	"settings.integrations.admin_pulse.tasks.form.status.label": "Verplaats de status van de subtaak naar",
	"settings.integrations.admin_pulse.tasks.form.status.validation.required":
		"Vergeet niet om de status te kiezen waarnaar we de status moeten verplaatsen.",
	"settings.integrations.admin_pulse.tasks.form.sub_task.empty_message": "Kan geen subtemplates vinden.",
	"settings.integrations.admin_pulse.tasks.form.sub_task.label": "Subtaak",
	"settings.integrations.admin_pulse.tasks.form.sub_task.validation.required":
		"Vergeet niet om een AdminPulse subtemplate te kiezen.",
	"settings.integrations.admin_pulse.tasks.form.task_id.empty_message": "Kan geen AdminPulse-templates vinden.",
	"settings.integrations.admin_pulse.tasks.form.task_id.label": "Naar taaksjabloon",
	"settings.integrations.admin_pulse.tasks.form.task_id.validation.required":
		"Vergeet niet om een AdminPulse-sjabloon te kiezen.",
	"settings.integrations.admin_pulse.tasks.table.flow_action.label": "Bij actie",
	"settings.integrations.admin_pulse.tasks.table.status.label": "Verplaats naar status",
	"settings.integrations.admin_pulse.tasks.table.sub_task_name.label": "Subtaaknaam",
	"settings.integrations.admin_pulse.tasks.table.task_name.label": "Taaknaam",
	"settings.integrations.admin_pulse.tasks.table.template_name.label": "Stroomsjabloon",
	"settings.integrations.admin_pulse.tasks.title": "Koppel AdminPulse-taken",
	"settings.integrations.admin_pulse.webhook.add_filters.button.cancel": "Annuleren",
	"settings.integrations.admin_pulse.webhook.add_filters.button.submit": "Filter toevoegen",
	"settings.integrations.admin_pulse.webhook.add_filters.succeeded": "Nieuw webhookfilter is toegevoegd",
	"settings.integrations.admin_pulse.webhook.add_filters.title": "Webhookfilter",
	"settings.integrations.admin_pulse.webhook.add_filters.update.failed":
		"Het lijkt erop dat de webhookfilter nu niet kan worden toegevoegd. Probeer het later opnieuw. We zorgen ervoor dat dit snel voor je wordt opgelost!",
	"settings.integrations.admin_pulse.webhook.button.disable": "Webhook uitschakelen",
	"settings.integrations.admin_pulse.webhook.button.enable": "Webhook inschakelen",
	"settings.integrations.admin_pulse.webhook.button.no_access": "Missende machtigingen voor integratiebewerkingen",
	"settings.integrations.admin_pulse.webhook.description":
		"Geef je Admin Pulse-ervaring een boost met webhooks. Houd Bothive in realtime gesynchroniseerd met je Admin Pulse-contacten. Geen handmatige import of bijwerken van je contacten meer - het is allemaal automatisch, waardoor je workflow soepeler en efficiënter verloopt.",
	"settings.integrations.admin_pulse.webhook.disable_model.button.cancel": "Annuleren",
	"settings.integrations.admin_pulse.webhook.disable_model.button.submit": "Webhook uitschakelen",
	"settings.integrations.admin_pulse.webhook.disable_model.description":
		"Klaar om je Admin Pulse-ervaring te verbeteren? Schakel de Admin Pulse-webhook in, het is de sleutel tot naadloze gegevenssynchronisatie.",
	"settings.integrations.admin_pulse.webhook.disable_model.failed":
		"Het lijkt erop dat de webhook nu niet kan worden uitgeschakeld. Probeer het later opnieuw. We zorgen ervoor dat dit snel voor je wordt opgelost!",
	"settings.integrations.admin_pulse.webhook.disable_model.rules.description":
		"De webhook synchroniseert alleen contacten die aan je filters voldoen. Zeg vaarwel tegen ruis en houd de focus op wat echt belangrijk voor je is!",
	"settings.integrations.admin_pulse.webhook.disable_model.succeeded":
		"De webhook is succesvol uitgeschakeld. Je kunt het weer inschakelen wanneer je er klaar voor bent.",
	"settings.integrations.admin_pulse.webhook.disable_model.title": "Webhook uitschakelen",
	"settings.integrations.admin_pulse.webhook.edit_filters.button.cancel": "Annuleren",
	"settings.integrations.admin_pulse.webhook.edit_filters.button.delete": "Verwijderen",
	"settings.integrations.admin_pulse.webhook.edit_filters.button.submit": "Filter bijwerken",
	"settings.integrations.admin_pulse.webhook.edit_filters.delete.failed":
		"Het lijkt erop dat de webhookfilter nu niet kan worden verwijderd. Probeer het later opnieuw. We zorgen ervoor dat dit snel voor je wordt opgelost!",
	"settings.integrations.admin_pulse.webhook.edit_filters.delete.succeeded": "De webhookfilter is verwijderd",
	"settings.integrations.admin_pulse.webhook.edit_filters.succeeded": "De webhookfilter is bijgewerkt",
	"settings.integrations.admin_pulse.webhook.edit_filters.title": "Webhook-filter",
	"settings.integrations.admin_pulse.webhook.edit_filters.update.failed":
		"Het lijkt erop dat de webhook-filter nu niet kan worden bijgewerkt. Probeer het later opnieuw. We zorgen ervoor dat dit snel voor je wordt opgelost!",
	"settings.integrations.admin_pulse.webhook.enable_model.button.cancel": "Annuleren",
	"settings.integrations.admin_pulse.webhook.enable_model.button.submit": "Webhook inschakelen",
	"settings.integrations.admin_pulse.webhook.enable_model.failed":
		"Het lijkt erop dat de webhook nu niet kan worden ingeschakeld. Probeer het later opnieuw. We zorgen ervoor dat dit snel voor je wordt opgelost!",
	"settings.integrations.admin_pulse.webhook.enable_model.succeeded": "De webhook is succesvol gemaakt",
	"settings.integrations.admin_pulse.webhook.enable_model.title": "Webhook inschakelen",
	"settings.integrations.admin_pulse.webhook.form.crm_type.label": "Selecteer het CRM-type",
	"settings.integrations.admin_pulse.webhook.form.crm_type.option.company": "Bedrijf",
	"settings.integrations.admin_pulse.webhook.form.crm_type.option.customer": "Klant",
	"settings.integrations.admin_pulse.webhook.form.crm_type.option.other": "Anders",
	"settings.integrations.admin_pulse.webhook.form.crm_type.option.own_company": "Eenmanszaak",
	"settings.integrations.admin_pulse.webhook.form.crm_type.option.person": "Persoon",
	"settings.integrations.admin_pulse.webhook.form.crm_type.option.prospect": "Prospect",
	"settings.integrations.admin_pulse.webhook.form.crm_type.option.sole_proprietor": "Zelfstandige ondernemer",
	"settings.integrations.admin_pulse.webhook.form.label.help":
		"Dit label wordt toegevoegd aan contacten die overeenkomen met je regels.",
	"settings.integrations.admin_pulse.webhook.form.label.label": "Label",
	"settings.integrations.admin_pulse.webhook.form.rules.help":
		"Regels verfijnen Admin Pulse-filters en helpen bij het moeiteloos labelen van specifieke contacten.",
	"settings.integrations.admin_pulse.webhook.form.rules.invalid.empty": "Je hebt lege velden in je regels",
	"settings.integrations.admin_pulse.webhook.form.rules.label": "Regels",
	"settings.integrations.admin_pulse.webhook.form.type.label": "Selecteer het contacttype",
	"settings.integrations.admin_pulse.webhook.table.add_filter": "Filter toevoegen",
	"settings.integrations.admin_pulse.webhook.table.crm_type.label": "CRM-type",
	"settings.integrations.admin_pulse.webhook.table.empty_text":
		"Oh, het ziet er een beetje leeg uit hier! Maak je geen zorgen; je kunt het eenvoudig vullen met jouw filters.",
	"settings.integrations.admin_pulse.webhook.table.label.label": "Label",
	"settings.integrations.admin_pulse.webhook.table.rules.empty_value": "Geen",
	"settings.integrations.admin_pulse.webhook.table.rules.label": "Regels",
	"settings.integrations.admin_pulse.webhook.table.type.label": "Type",
	"settings.integrations.admin_pulse.webhook.title": "Webhook-configuratie",
	"settings.integrations.overview.info.progress_label":
		"Je hebt {value, plural, =0 {geen integraties} one {# integratie} other {# integraties}} over in je huidige abonnement.",
	"settings.integrations.overview.info.progress_label_unlimited":
		"Je hebt een onbeperkt aantal integraties in je huidige abonnement.",
	// SETTINGS.INTEGRATIONS.BILLIT
	"SETTINGS.INTEGRATIONS.BILLIT.PAGE.TITLE": "Billit integratie",
	"SETTINGS.INTEGRATIONS.BILLIT.BREADCRUMB.PAGE_TITLE": "Billit",
	"SETTINGS.INTEGRATIONS.BILLIT.LOGIN.TITLE": "Billit",
	"SETTINGS.INTEGRATIONS.BILLIT.LOGIN.DESCRIPTION":
		"Connecteer Billit zodat jouw klant ook vanuit Bothive alle gewenste facturen kan opladen.",
	"SETTINGS.INTEGRATIONS.BILLIT.LOGIN.FEATURES.UPLOAD_INVOICES": "Facturen inladen",
	"SETTINGS.INTEGRATIONS.BILLIT.LOGIN.OAUTH2.DESCRIPTION": "Koppel met Billit via de Exact website.",
	"SETTINGS.INTEGRATIONS.BILLIT.LOGIN.OAUTH2.BUTTON": "Log in met Billit",
	"SETTINGS.INTEGRATIONS.BILLIT.FORM_SECTION.TITLE": "Bothive is gekoppeld met Billit",
	"SETTINGS.INTEGRATIONS.BILLIT.FORM_SECTION.DESCRIPTION":
		"Door deze integratie maak je Bothive krachtiger. Ze geeft gepersonaliseerde antwoorden op basis van de gegevens die opgeslagen werden in Billit.",
	"SETTINGS.INTEGRATIONS.BILLIT.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.BILLIT.DANGER_ZONE.TITLE": "Verwijder integratie met Billit",
	"SETTINGS.INTEGRATIONS.BILLIT.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je de integratie met Billit wil stopzetten? Alle functionaliteit zullen niet meer beschikbaar zijn voor jouw klanten vanaf je het stopzet.",
	"SETTINGS.INTEGRATIONS.BILLIT.DANGER_ZONE.BUTTON": "Verwijder integratie",

	// SETTINGS.INTEGRATIONS.EXACT
	"SETTINGS.INTEGRATIONS.EXACT.PAGE.TITLE": "Exact integratie",
	"SETTINGS.INTEGRATIONS.EXACT.BREADCRUMB.PAGE_TITLE": "Exact",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.TITLE": "Exact",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.DESCRIPTION":
		"Koppel met Exact en link alle opgeslagen klantendata ook aan Bothive. Jouw klant kan:",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.FEATURES.GET_BALANCE": "De voorlopige balans opvragen;",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.FEATURES.SEE_OPEN_INVOICES": "Alle openstaande rekeningen bekijken;",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.FEATURES.SEE_OPEN_DEBTOR":
		"Al hun klanten opvragen die nog rekeningen moeten betalen.",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.FEATURES.SEE_PROFIT": "Hun winst- en verliescijfers bekijken.",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.OAUTH2.DESCRIPTION": "Koppel met Exact via de Exact website.",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.OAUTH2.BUTTON": "Log in met Exact",

	"SETTINGS.INTEGRATIONS.EXACT.FORM_SECTION.TITLE": "Bothive is gekoppeld met Exact",
	"SETTINGS.INTEGRATIONS.EXACT.FORM_SECTION.DESCRIPTION":
		"Door deze integratie maak je Bothive krachtiger. Ze geeft gepersonaliseerde antwoorden op basis van de gegevens die opgeslagen werden in Exact.",

	"SETTINGS.INTEGRATIONS.EXACT.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.EXACT.DANGER_ZONE.TITLE": "Verwijder integratie met Exact",
	"SETTINGS.INTEGRATIONS.EXACT.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je de integratie met Exact wil stopzetten? Alle functionaliteit zullen niet meer beschikbaar zijn voor jouw klanten vanaf je het stopzet.",
	"SETTINGS.INTEGRATIONS.EXACT.DANGER_ZONE.BUTTON": "Verwijder integratie",

	// SETTINGS.INTEGRATIONS.LIANTIS
	"SETTINGS.INTEGRATIONS.LIANTIS.PAGE.TITLE": "Liantis integratie",
	"SETTINGS.INTEGRATIONS.LIANTIS.BREADCRUMB.PAGE_TITLE": "Liantis",
	"SETTINGS.INTEGRATIONS.LIANTIS.LOGIN.TITLE": "Liantis",

	"SETTINGS.INTEGRATIONS.LIANTIS.FORM_SECTION.TITLE": "Bothive is verbonden met Liantis",
	"SETTINGS.INTEGRATIONS.LIANTIS.FORM_SECTION.DESCRIPTION": "Stuur de onboardings flow automatisch door naar Liantis",

	"SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.TITLE": "Connecteer met Liantis",
	"SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.DESCRIPTION":
		"Connecteer Bothive met Liantis om flow data te kunnen versturen",
	"SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.BUTTON": "Log in met Liantis",

	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.TITLE": "Verwijder koppeling met Liantis",
	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je de koppeling met Liantis wil stopzetten? De functionaliteiten van deze integratie zijn niet langer beschikbaar vanaf je deze verwijdert.",
	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.BUTTON": "Verwijder integratie",

	// SETTINGS.INTEGRATIONS.FID_MANAGER
	"SETTINGS.INTEGRATIONS.FID_MANAGER.PAGE.TITLE": "Fid-Manager Integratie",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.BREADCRUMB.PAGE_TITLE": "Fid-Manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.TITLE": "Fid-Manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.DESCRIPTION": "Synchroniseer jouw contacten uit FID-manager met Bothive.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FEATURES.SYNC_CONTACTS": "Synchronizeren contacten",

	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.BASE_URL.LABEL": "Base url",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.BASE_URL.REQUIRED": "Base url is verplicht",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.BASE_URL.PLACEHOLDER": "https://bedrijfsname.fid-manager.be/nl",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.LABEL": "Login",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.REQUIRED": "Login is verplicht",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.PLACEHOLDER": "a000a000-000a-0000-a0aa-aaa00a00a0000",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.PASSWORD.LABEL": "Wachtwoord",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.PASSWORD.REQUIRED": "Wachtwoord is verplicht",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.SUBMIT": "Koppel met Fid-Manager",

	"SETTINGS.INTEGRATIONS.FID_MANAGER.FORM_SECTION.TITLE": "Bothive is gekoppeld met Fid-Manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.FORM_SECTION.DESCRIPTION":
		"Door met Fid-Manager te connecteren kan Bothive automatisch antwoorden van flows in jouw Fid-Manager invoeren, contacten synchroniseren en updaten.",

	"SETTINGS.INTEGRATIONS.FID_MANAGER.SYNC_CONTACTS.TITLE": "Importeer Fid-Manager klanten als contacten",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.SYNC_CONTACTS.DESCRIPTION":
		"Automatisch importeer jouw Fid-Manager klanten in jouw Bothive team om beide contacten met elkaar te linken, contact detectie te verbeteren en data synchronisatie te optimaliseren.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.SYNC_CONTACTS.BUTTON_LABEL": "Importeer contacten",

	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.TITLE": "Verwijder integratie met Fid-Manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je de integratie met Fid-Manager wil stopzetten? Alle functionaliteit zullen niet meer beschikbaar zijn vanaf je het stopzet.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.BUTTON": "Verwijder integratie",

	// SETTINGS.INTEGRATIONS.MESSENGER
	"SETTINGS.INTEGRATIONS.MESSENGER.PAGE.TITLE": "Messenger Integratie",
	"SETTINGS.INTEGRATIONS.MESSENGER.BREADCRUMB.PAGE_TITLE": "Messenger",

	"SETTINGS.INTEGRATIONS.MESSENGER.CONNECT.TITLE": "Koppel met jouw Facebookpagina",
	"SETTINGS.INTEGRATIONS.MESSENGER.CONNECT.DESCRIPTION":
		"Connecteer Bothive met jouw Facebook account om Messenger te linken met de gedeelde inbox en alle andere Bothive functionaliteiten. Wij plaatsen nooit zonder toestemming berichten op jouw facebookprofiel.",
	"SETTINGS.INTEGRATIONS.MESSENGER.CONNECT.BUTTON": "Log in met Facebook",

	"SETTINGS.INTEGRATIONS.MESSENGER.PAGE_OVERVIEW.TITLE": "Jouw Facebookpagina's",
	"SETTINGS.INTEGRATIONS.MESSENGER.PAGE_OVERVIEW.DESCRIPTION":
		"Selecteer de Facebookpagina die je wil connecteren met Bothive.",
	"SETTINGS.INTEGRATIONS.MESSENGER.PAGE_OVERVIEW.FALLBACK": "Je bent geen administrator van een Facebookpagina.",

	"INTEGRATION.PLATFORM_LIST.CONNECTED": "gekoppeld",
	"INTEGRATION.PLATFORM_LIST.CONNECT": "Connecteren",
	"INTEGRATION.PLATFORM_LIST.DISCONNECT": "Uitschakelen",

	// SETTINGS.INTEGRATIONS.MAILCHIMP
	"SETTINGS.INTEGRATIONS.MAILCHIMP.PAGE.TITLE": "Mailchimp Integratie",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.BREADCRUMB.PAGE_TITLE": "Mailchimp",

	"SETTINGS.INTEGRATIONS.MAILCHIMP.LOGIN.TITLE": "Mailchimp",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.LOGIN.DESCRIPTION":
		"Connecteer Bothive met Mailchimp om automatisch nieuwe gesprekspartners toe te voegen aan jouw mailinglijst.",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.LOGIN.FORM.API_KEY.TITLE": "API-key",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.LOGIN.FORM.API_KEY.PLACEHOLDER": "af0000afda00000af000afc000000000-us4",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.LOGIN.FORM.SUBMIT": "Koppel met Mailchimp",

	"SETTINGS.INTEGRATIONS.MAILCHIMP.FORM_SECTION.TITLE": "Bothive is gekoppeld met Mailchimp",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.FORM_SECTION.DESCRIPTION":
		"Wanneer Bothive een e-mail adres herkent of vraagt, zal deze automatisch toegevoegd worden aan jouw mailing lijst in Mailchimp.",

	"SETTINGS.INTEGRATIONS.MAILCHIMP.SETTINGS.LIST.TITLE": "Mailing lijst",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.SETTINGS.LIST.HELP":
		"Selecteer the mailing lijst waar je nieuwe gesprekspartners aan wil toevoegen.",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.SETTINGS.DUBBEL_OPT_IN.LABEL":
		"Voeg enkel gebruikers toe aan je mailing lijst na dubbele opt-in",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.SETTINGS.DUBBEL_OPT_IN.HELP":
		"Dit is een verplicht optie om GDPR conform te zijn. Als je deze afzet, dan bij jij verantwoordelijk om de gebruikers toestemming te krijgen.",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.SETTINGS.TAGS.TITLE": "Tags",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.SETTINGS.TAGS.HELP": "Druk op enter om een nieuwe tag toe te voegen.",

	"SETTINGS.INTEGRATIONS.MAILCHIMP.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.DANGER_ZONE.TITLE": "Verwijder integratie met Mailchimp",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je de integratie met Mailchimp wil stopzetten? Alle functionaliteit zal niet meer beschikbaar zijn voor jouw klanten vanaf je het stopzet.",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.DANGER_ZONE.BUTTON": "Verwijder integratie",

	// SETTINGS.INTEGRATIONS.OCTOPUS
	"SETTINGS.INTEGRATIONS.OCTOPUS.PAGE.TITLE": "Octopus integratie",
	"SETTINGS.INTEGRATIONS.OCTOPUS.BREADCRUMB.PAGE_TITLE": "Octopus",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.TITLE": "Octopus",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.DESCRIPTION":
		"Koppel met Octopus en link alle opgeslagen klantendata ook aan Bothive. Jouw klant kan:",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.FEATURES.GET_BALANCE": "De voorlopige balans opvragen;",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.FEATURES.SEE_OPEN_INVOICES": "Alle openstaande rekeningen bekijken;",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.FEATURES.SEE_OPEN_DEBTOR":
		"Al hun klanten opvragen die nog rekeningen moeten betalen.",

	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.FORM.NAME": "Naam",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.FORM.PASSWORD": "Wachtwoord",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.FORM.SUBMIT": "Koppel met Octopus",

	"SETTINGS.INTEGRATIONS.OCTOPUS.FORM_SECTION.TITLE": "Bothive is gekoppeld met Octopus",
	"SETTINGS.INTEGRATIONS.OCTOPUS.FORM_SECTION.DESCRIPTION":
		"Door deze integratie maak je Bothive krachtiger. Ze geeft gepersonaliseerde antwoorden op basis van de gegevens die opgeslagen werden in Octopus.",

	"SETTINGS.INTEGRATIONS.OCTOPUS.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.OCTOPUS.DANGER_ZONE.TITLE": "Verwijder integratie met Octopus",
	"SETTINGS.INTEGRATIONS.OCTOPUS.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je de integratie met Octopus wil stopzetten? Alle functionaliteit zullen niet meer beschikbaar zijn voor jouw klanten vanaf je het stopzet.",
	"SETTINGS.INTEGRATIONS.OCTOPUS.DANGER_ZONE.BUTTON": "Verwijder integratie",

	// SETTINGS.INTEGRATIONS.SILVERFIN
	"SETTINGS.INTEGRATIONS.SILVERFIN.PAGE.TITLE": "Silverfin integratie",
	"SETTINGS.INTEGRATIONS.SILVERFIN.BREADCRUMB.PAGE_TITLE": "Silverfin",

	"SETTINGS.INTEGRATIONS.SILVERFIN.LOGIN.TITLE": "Koppel met jouw silverfin account",
	"SETTINGS.INTEGRATIONS.SILVERFIN.LOGIN.DESCRIPTION":
		"Door met Silverfin te connecteren geef je Bothive de mogelijkheid om de klant hun dossier in te kijken.",
	"SETTINGS.INTEGRATIONS.SILVERFIN.LOGIN.BUTTON": "Log in met Silverfin",

	"SETTINGS.INTEGRATIONS.SILVERFIN.CONNECTED.TITLE": "Je bent gekoppeld met Silverfin",
	"SETTINGS.INTEGRATIONS.SILVERFIN.CONNECTED.DESCRIPTION":
		"Door met Silverfin te connecteren geef je de chatbot de mogelijkheid om de klant hun dossier in te kijken.",

	"SETTINGS.INTEGRATIONS.SILVERFIN.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.SILVERFIN.DANGER_ZONE.TITLE": "Verwijder integratie met Silverfin",
	"SETTINGS.INTEGRATIONS.SILVERFIN.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je de integratie met Silverfin wil stopzetten? Alle functionaliteit zal niet meer beschikbaar zijn voor jouw klanten vanaf je het stopzet.",
	"SETTINGS.INTEGRATIONS.SILVERFIN.DANGER_ZONE.BUTTON": "Verwijder integratie",

	// SETTINGS.INTEGRATIONS.SLACK
	"SETTINGS.INTEGRATIONS.SLACK.PAGE.TITLE": "Slack integration",
	"SETTINGS.INTEGRATIONS.SLACK.BREADCRUMB.PAGE_TITLE": "Slack",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.TITLE": "Nodig Bothive uit in jouw Slack workspace",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.DESCRIPTION":
		"Door Bothive uit te nodigen in jouw Slack workspace, kan Bothive jou en jouw workspace op de hoogte stellen wanneer er iets belangrijks gebeurt.",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.FEATURES.LIVE_CHAT": "Nieuw live chatverzoek notificatie",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.FEATURES.ASSIGNED_TO_CONVERSATION": "Toegewezen aan conversatie",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.FEATURES.FLOW_RAPPORT": "Nieuw flow rapport",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.FEATURES.EMAIL_RECEIVED": "E-mail notificatie",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.BUTTON": "Log in met Slack",

	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.OAUTH2.DESCRIPTION": "Koppel met Slack via de Slack website.",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.OAUTH2.BUTTON": "Log in met Slack",

	"SETTINGS.INTEGRATIONS.SLACK.CONNECTED.TITLE": "Bothive is lid geworden van jouw Slack workspace",
	"SETTINGS.INTEGRATIONS.SLACK.CONNECTED.DESCRIPTION":
		"Door Bothive uit te nodigen in jouw Slack workspace, kan Bothive jou en jouw workspace op de hoogte stellen wanneer er iets belangrijks gebeurt, zoals een nieuw live chatverzoek, een nieuwe afspraak, ontvangen e-mail, ...",

	"SETTINGS.INTEGRATIONS.SLACK.CHANNELS.DEFAULT_VALUE": "--Niet gekoppeld--",
	"SETTINGS.INTEGRATIONS.SLACK.CHANNELS.INBOX.LABEL": "Inbox notificaties",
	"SETTINGS.INTEGRATIONS.SLACK.CHANNELS.INBOX.HELP":
		"Alle notificatie gerelateerd aan de team inbox bijvoorbeeld live chat aanvraag of een nieuwe e-mail zullen in dit kanaal gepost worden.",

	"SETTINGS.INTEGRATIONS.SLACK.CHANNELS.FLOW_REPORT.LABEL": "Flow notificaties",
	"SETTINGS.INTEGRATIONS.SLACK.CHANNELS.FLOW_REPORT.HELP":
		"Telkens een nieuw rapport aangemaakt is zal er in dit kanaal gepost worden.",

	"SETTINGS.INTEGRATIONS.SLACK.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.SLACK.DANGER_ZONE.TITLE": "Verwijder integratie met Slack",
	"SETTINGS.INTEGRATIONS.SLACK.DANGER_ZONE.DESCRIPTION":
		"Weet je zeker dat Bothive je Slack workspace moet verlaten? Bothive zal geen meldingen of andere belangrijke berichten meer kunnen verzenden in je Slack channels.",
	"SETTINGS.INTEGRATIONS.SLACK.DANGER_ZONE.BUTTON": "Verwijder integratie",

	// SETTINGS.INTEGRATIONS.TEAMS
	"SETTINGS.INTEGRATIONS.TEAMS.PAGE.TITLE": "Teams integration",
	"SETTINGS.INTEGRATIONS.TEAMS.BREADCRUMB.PAGE_TITLE": "Teams",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.TITLE": "Nodig Bothive uit in jouw Teams workspace",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.DESCRIPTION":
		"Door Bothive uit te nodigen in jouw Teams workspace, kan Bothive jou en jouw workspace op de hoogte stellen wanneer er iets belangrijks gebeurt.",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.FEATURES.LIVE_CHAT": "Nieuw live chatverzoek notificatie",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.FEATURES.ASSIGNED_TO_CONVERSATION": "Toegewezen aan conversatie",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.FEATURES.FLOW_RAPPORT": "Nieuw flow rapport",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.FEATURES.EMAIL_RECEIVED": "E-mail notificatie",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.BUTTON": "Log in met Teams",

	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.OAUTH2.DESCRIPTION": "Koppel met Teams via de Teams website.",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.OAUTH2.BUTTON": "Log in met Teams",

	"SETTINGS.INTEGRATIONS.TEAMS.CONNECTED.TITLE": "Bothive is lid geworden van jouw Teams workspace",
	"SETTINGS.INTEGRATIONS.TEAMS.CONNECTED.DESCRIPTION":
		"Door Bothive uit te nodigen in jouw Teams workspace, kan Bothive jou en jouw workspace op de hoogte stellen wanneer er iets belangrijks gebeurt, zoals een nieuw live chatverzoek, een nieuwe afspraak, ontvangen e-mail, ...",

	"SETTINGS.INTEGRATIONS.TEAMS.CHANNELS.DEFAULT_VALUE": "--Niet gekoppeld--",
	"SETTINGS.INTEGRATIONS.TEAMS.CHANNELS.INBOX.LABEL": "Inbox notificaties",
	"SETTINGS.INTEGRATIONS.TEAMS.CHANNELS.INBOX.HELP":
		"Alle notificatie gerelateerd aan de team inbox bijvoorbeeld live chat aanvraag of een nieuwe e-mail zullen in dit kanaal gepost worden.",

	"SETTINGS.INTEGRATIONS.TEAMS.CHANNELS.FLOW_REPORT.LABEL": "Flow notificaties",
	"SETTINGS.INTEGRATIONS.TEAMS.CHANNELS.FLOW_REPORT.HELP":
		"Telkens een nieuw rapport aangemaakt is zal er in dit kanaal gepost worden.",

	"SETTINGS.INTEGRATIONS.TEAMS.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.TEAMS.DANGER_ZONE.TITLE": "Verwijder integratie met Teams",
	"SETTINGS.INTEGRATIONS.TEAMS.DANGER_ZONE.DESCRIPTION":
		"Weet je zeker dat Bothive je Teams workspace moet verlaten? Bothive zal geen meldingen of andere belangrijke berichten meer kunnen verzenden in je Teams channels.",
	"SETTINGS.INTEGRATIONS.TEAMS.DANGER_ZONE.BUTTON": "Verwijder integratie",

	// SETTINGS.INTEGRATIONS.TWINNTAX
	"SETTINGS.INTEGRATIONS.TWINNTAX.PAGE.TITLE": "Twinntax integratie",
	"SETTINGS.INTEGRATIONS.TWINNTAX.BREADCRUMB.PAGE_TITLE": "Twinntax",
	"SETTINGS.INTEGRATIONS.TWINNTAX.LOGIN.TITLE": "Twinntax",

	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM_SECTION.TITLE": "Bothive is verbonden met Twinntax",
	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM_SECTION.DESCRIPTION":
		"Bij de start van de PB-flow zullen MyMinfin documentent vanuit Twinntax gesynchorniseerd worden naar in de flow van de klant op basis van hun rijksregisternummer.",

	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM.APIKEY": "Api key",
	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM.APIKEY.REQUIRED": "Gelieve een api key in te voeren",
	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM.SUBMIT": "Connecteer met Twinntax",
	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM.HELP":
		"U kan een token aanmaken in jouw Twinntax dashboard onder Instellingen -> API Instellingen -> Creëer een API sleutel",

	"SETTINGS.INTEGRATIONS.TWINNTAX.CONNECT.TITLE": "Connecteer met Twinntax",
	"SETTINGS.INTEGRATIONS.TWINNTAX.CONNECT.DESCRIPTION":
		"Connecteer Bothive met Twinntax om MyMinfin-bestanden van je klanten te synchroniseren met Bothive",

	"SETTINGS.INTEGRATIONS.TWINNTAX.CONNECT.BUTTON": "Log in met Twinntax",

	"SETTINGS.INTEGRATIONS.TWINNTAX.SYNC_CONTACTS.TITLE": "Importeer Twinntax klanten als contacten",
	"SETTINGS.INTEGRATIONS.TWINNTAX.SYNC_CONTACTS.DESCRIPTION":
		"Automatisch importeer jouw Twinntax klanten in jouw Bothive team om beide contacten met elkaar te linken, contact detectie te verbeteren en data synchronisatie te optimaliseren.",
	"SETTINGS.INTEGRATIONS.TWINNTAX.SYNC_CONTACTS.BUTTON_LABEL": "Importeer contacten",

	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.TITLE": "Verwijder koppeling met Twinntax",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je de koppeling met Twinntax wil stopzetten? De functionaliteiten van deze integratie zijn niet langer beschikbaar vanaf je deze verwijdert.",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.BUTTON": "Verwijder integratie",

	// SETTINGS.INTEGRATIONS.TWILIO
	"SETTINGS.INTEGRATIONS.TWILIO.PAGE.TITLE": "Twilio integratie",
	"SETTINGS.INTEGRATIONS.TWILIO.BREADCRUMB.PAGE_TITLE": "Twilio",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.TITLE": "Koppel met Twilio business",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.DESCRIPTION":
		"Connecteer jouw Twilio account met Bothive om onze inbox en alle functionaliteiten te gebruiken met WhatsApp.",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FOOTER.LABEL": "Mogelijk gemaakt door",

	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.SID.LABEL": "Account SID",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.SID.PLACEHOLDER": "ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.SID.HELP":
		"Jouw Account SID kan terug gevonden worden op www.twilio.com/console.",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.AUTH_TOKEN.LABEL": "Auth token",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.AUTH_TOKEN.HELP":
		"Jouw Auth Token kan terug gevonden worden op www.twilio.com/console",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.WHATSAPP_NUMBER.LABEL": "Whatsapp nummer",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.WHATSAPP_NUMBER.PLACEHOLDER": "+32460000000",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.SUBMIT": "Koppel met Twilio",

	"SETTINGS.INTEGRATIONS.TWILIO.DETAIL.ACCOUNT_ID.LABEL": "Account id:",
	"SETTINGS.INTEGRATIONS.TWILIO.DETAIL.PHONE_NUMBER.LABEL": "WhatsApp nummer:",

	"SETTINGS.INTEGRATIONS.TWILIO.FORM_SECTION.TITLE": "Bothive is gekoppeld met Twilio",

	"SETTINGS.INTEGRATIONS.TWILIO.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.TWILIO.DANGER_ZONE.TITLE": "Verwijder integratie met Twilio",
	"SETTINGS.INTEGRATIONS.TWILIO.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je de integratie met Twilio wil stopzetten? Alle functionaliteit zullen niet meer beschikbaar zijn voor jouw klanten vanaf je het stopzet.",
	"SETTINGS.INTEGRATIONS.TWILIO.DANGER_ZONE.BUTTON": "Verwijder integratie",
	// SETTINGS.INTEGRATIONS.WHATS_APP
	"SETTINGS.INTEGRATIONS.CM.PAGE.TITLE": "WhatsApp integratie",
	"SETTINGS.INTEGRATIONS.CM.BREADCRUMB.PAGE_TITLE": "WhatsApp",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.TITLE": "Koppel met WatsApp Business",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.DESCRIPTION":
		"Connecteer Bothive met jouw WhatsApp Business account om WhatsApp te linken met de gedeelde inbox en alle andere Bothive functionaliteiten.\n\nContacteer ons om een WhatsApp Business account te krijgen.",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FOOTER.LABEL": "Mogelijk gemaakt door",

	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.API_KEY": "Api-key",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.API_KEY.PLACEHOLDER": "00000000-0000-0000-0000-000000000000",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.API_KEY.HELP": "De api-key kan terug gevonden worden in de gateway app.",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.CHANNEL_ID": "Channel ID",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.CHANNEL_ID.PLACEHOLDER": "00000000-0000-0000-0000-000000000000",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.CHANNEL_ID.HELP": "Channel id kan terug gevonden worden in de url van CM.",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.ACCOUNT_ID": "Account ID",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.ACCOUNT_ID.PLACEHOLDER": "00000000-0000-0000-0000-000000000000",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.ACCOUNT_ID.HELP": "Account id kan terug gevonden worden in de url van CM.",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.SUBMIT": "Connecteer WhatsApp",

	"SETTINGS.INTEGRATIONS.CM.DETAIL.ACCOUNT_ID.LABEL": "Account id:",
	"SETTINGS.INTEGRATIONS.CM.DETAIL.PHONE_NUMBER.LABEL": "gsm-nummer:",
	"SETTINGS.INTEGRATIONS.CM.DETAIL.QR_CODE.BUTTON_DOWNLOAD": "Download QR code",

	"SETTINGS.INTEGRATIONS.CM.FORM_SECTION.TITLE": "Bothive is gekoppeld met WhatsApp business",

	"SETTINGS.INTEGRATIONS.CM.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.CM.DANGER_ZONE.TITLE": "Verwijder integratie met WhatsApp business",
	"SETTINGS.INTEGRATIONS.CM.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je de integratie met WhatsApp business wil stopzetten? Alle functionaliteit zullen niet meer beschikbaar zijn voor jouw klanten vanaf je het stopzet.\n\n\rContacteer ons en wij zetten de volledige WhatsApp business integratie stop.",

	// SETTINGS.INTEGRATIONS.YUKI
	"SETTINGS.INTEGRATIONS.YUKI.PAGE.TITLE": "Yuki Integratie",
	"SETTINGS.INTEGRATIONS.YUKI.BREADCRUMB.PAGE_TITLE": "Yuki",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.TITLE": "Yuki",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.DESCRIPTION":
		"Koppel met Yuki en link alle opgeslagen klantendata ook aan Bothive. Jouw klant kan:",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.FEATURES.GET_BALANCE": "De voorlopige balans opvragen;",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.FEATURES.SEE_OPEN_INVOICES": "Alle openstaande rekeningen bekijken;",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.FEATURES.SEE_OPEN_DEBTOR":
		"Al hun klanten opvragen die nog rekeningen moeten betalen.",

	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.FORM.NAME": "Naam",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.FORM.PASSWORD": "Wachtwoord",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.FORM.SUBMIT": "Koppel met Yuki",

	"SETTINGS.INTEGRATIONS.YUKI.FORM_SECTION.TITLE": "Bothive is gekoppeld met Yuki",
	"SETTINGS.INTEGRATIONS.YUKI.FORM_SECTION.DESCRIPTION":
		"Door met Yuki te connecteren kunnen klanten snel en gemakkelijk enkele simpele vragen over hun dossier stellen aan Bothive. Zoals openstaande facturen, welke klanten nog moeten betalen en hun huidige Plan jouw meetingbalans.",

	"SETTINGS.INTEGRATIONS.YUKI.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.YUKI.DANGER_ZONE.TITLE": "Verwijder integratie met Yuki",
	"SETTINGS.INTEGRATIONS.YUKI.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je de integratie met Yuki wil stopzetten? Alle functionaliteit zullen niet meer beschikbaar zijn voor jouw klanten vanaf je het stopzet.",
	"SETTINGS.INTEGRATIONS.YUKI.DANGER_ZONE.BUTTON": "Verwijder integratie",

	//INTEGRATION_NOTIFICATIONS
	"SETTINGS.INTEGRATIONS.NOTIFICATION.SUCCESS": "{integrationName} is verwijderd!",
	"SETTINGS.INTEGRATIONS.NOTIFICATION.FAILED":
		"{integrationName} kan niet verwijderd worden, probeer het later opnieuw.",

	//INTEGRATION_CATEGORIES
	"SETTINGS.INTENT.CATEGORIES.PAGE.TITLE": "Intent categorieën",
	"SETTINGS.INTENT.CATEGORIES.HEADER.BREADCRUMB.PAGE_TITLE": "Intent categorieën",
	"SETTINGS.INTENT.CATEGORIES.HEADER.BREADCRUMB.SETTINGS": "Instellingen",
	"SETTINGS.INTENT.CATEGORIES.TABLE.CATEGORY_NAME": "Categorie",

	//INTENT CUSTOMIZE
	"SETTINGS.INTENT.CATEGORIES.FALLBACK.TITLE": "Intents overzicht",
	"SETTINGS.INTENT.CATEGORIES.FALLBACK.DESCRIPTION":
		"Intents bekijken is momenteel niet beschikbaar in jouw huidig plan, upgrade naar een hoger plan om gebruik te maken van deze functionaliteit.",

	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.NO_INTENTS_FOUND": "Er konden geen intents gevonden worden.",
	"SETTINGS.INTENT.CATEGORIES.NO_INTENTS.SUGGEST_NEW_INTENT": "Stel een nieuwe intent voor",

	"SETTINGS.INTENT.CATEGORY_DETAIL.SEARCH.PLACEHOLDER": "Zoek op intent naam",
	"SETTINGS.INTENT.CATEGORY_DETAIL.FILTER_DROPDOWN.NO_FILTER": "Geen filter",
	"SETTINGS.INTENT.CATEGORY_DETAIL.FILTER_DROPDOWN.EDITED_INTENTS_ONLY": "Bewerkte intents",
	"SETTINGS.INTENT.CATEGORY_DETAIL.FILTER_DROPDOWN.UNEDITED_INTENTS_ONLY": "Onbewerkte intents",
	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.FALLBACK": "Er konden geen intents gevonden worden",
	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.INTENT_NAME": "Intent naam",
	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.TYPE": "Type",
	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.TYPE_STATIC": "Statisch",
	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.TYPE_DYNAMIC": "Dynamisch",
	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.IS_EDITED": "Is aangepast",

	// Setting suggestionss
	"SETTINGS.SUGGESTIONS.CONTACT_LABElS.TITLE": "Contact labels",
	"SETTINGS.SUGGESTIONS.CONTACT_LABElS.DESCRIPTION": "Voeg labels toe aan specifieke groepen van contacten.",
	"SETTINGS.SUGGESTIONS.CONTACT_LABElS.BUTTON": "Labels beheren",

	"settings.contact_import.suggestions.contact_labels.title": "Contact labels",
	"settings.contact_import.suggestions.contact_labels.description":
		"Voeg labels toe aan specifieke groepen van contacten.",
	"settings.contact_import.suggestions.contact_labels.button": "Labels beheren",
	"settings.contact_import.breadcrumb.settings": "Instellingen",
	"settings.contact_import.breadcrumb.title": "Contactimport",
	"settings.contact_import.list.default.csv": "Handmatige CSV-import",
	"settings.contact_import.list.description": "Kies de gegevensbron van waaruit je je contacten wilt importeren",
	"settings.contact_import.list.title": "Importopties",
	"settings.contact_import.related_settings.title": "Gerelateerde instellingen",
	"SETTINGS.SUGGESTIONS.OPENING_HOURS.TITLE": "Openingsuren",
	"SETTINGS.SUGGESTIONS.OPENING_HOURS.DESCRIPTION":
		"Voeg openingsuren toe zodat je (potentiële) klanten juist kan informeren.",
	"SETTINGS.SUGGESTIONS.OPENING_HOURS.BUTTON": "Openingsuren wijzigen",

	"SETTINGS.SUGGESTIONS.BRANDING.TITLE": "Huisstijl",
	"SETTINGS.SUGGESTIONS.BRANDING.DESCRIPTION":
		"Laad het logo van je onderneming op en kies een icoon om uitgaande communicatie te personaliseren.",
	"SETTINGS.SUGGESTIONS.BRANDING.BUTTON": "Huisstijl wijzigen",

	"SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.TITLE": "Bedrijfsinstellingen",
	"SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.DESCRIPTION": "Vul je algemene bedrijfsinstellingen in zoals adres.",
	"SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.BUTTON": "Bedrijfsinstellingen bewerken",
	//INTENT DETAIL
	"SETTINGS.INTENT.DETAIL.PAGE.TITLE": "Details (BETA)",

	"INTENT_DETAIL.VIEW.FALLBACK.TITLE": "Intents overzicht",
	"INTENT_DETAIL.VIEW.FALLBACK.DESCRIPTION":
		"Intents bekijken is momenteel niet beschikbaar in jouw huidig plan, Overweeg een ander abonnement om intents te bekijken en ze aan te passen naar de stijl van je kantoor.",

	"SETTINGS.INTENT.DETAIL.FALLBACK.VIEW.TITLE": "Er lijkt iets te zijn misgelopen.",
	"SETTINGS.INTENT.DETAIL.FALLBACK.VIEW.DESCRIPTION":
		"De intent details kunnen niet getoond worden. Als dit blijft gebeuren contacteer onze klantendienst.",

	"SETTINGS.INTENT.DETAIL.FALLBACK.EDIT.TITLE": "Intents aanpassen niet mogelijk",
	"SETTINGS.INTENT.DETAIL.FALLBACK.EDIT.DESCRIPTION": "Je kan geen intents bewerken in je huidig plan.",
	"SETTINGS.INTENT.DETAIL.FALLBACK.EDIT.CTA.DESCRIPTION":
		"Overweeg een ander abonnement om intents te bekijken en ze aan te passen naar de stijl van je kantoor.",
	"SETTINGS.INTENT.DETAIL.FALLBACK.EDIT.CTA.BUTTON": "Upgrade plan",

	"SETTINGS.INTENT.DETAIL.WARNING.INTENT_IS_FF":
		"Dit is een dynamisch intent. Dit wil zeggen dat het antwoord afhankelijk zal zijn van de vraag die gesteld wordt. Dit is meestal veel specifieker dan het voorbeeld antwoord of gebaseerd op de tijd of locatie van de gebruiker. Door het antwoord aan te passen zal de intent statisch worden.",
	"SETTINGS.INTENT.DETAIL.WARNING.INTENT_WITH_PAYLOAD":
		"Opgelet!\nSommige vragen kan je niet rechtstreeks vanuit het Dashboard bewerken. Indien er geen antwoord staat geregistreerd, contacteer onze klantendienst of de verantwoordelijke van jouw project om de gewenste wijzigingen toe te passen.",
	"SETTINGS.INTENT.DETAIL.PREVIEW.TITLE": "{name} - Huidig antwoord:",
	"SETTINGS.INTENT.DETAIL.CUSTOMIZE_ANSWER.TITLE": "{name} - Personaliseer antwoord:",
	"INTENT_DETAIL.BUTTON.RESET_ANSWER": "Reset antwoord",
	"INTENT_DETAIL.BUTTON.SAVE_ANSWER": "Opslaan",

	"SETTINGS.INTENT.DETAIL.POPUP.TITLE": "Bevestigen aanpassing",
	"SETTINGS.INTENT.DETAIL.POPUP.MESSAGE":
		"Ben je zeker dat je het standaard antwoord wil overschrijven? Let op: hierdoor ben je zelf verantwoordelijk om de aangepaste antwoorden up-to-date te houden.",
	"SETTINGS.INTENT.DETAIL.POPUP.ADD_BUTTON": "Antwoord aanpassen",

	//LINK_POPUP
	"LINK_POPUP.HEADER.TITLE": "Voeg link toe",
	"LINK_POPUP.FORM.TEXT.LABEL": "Text",
	"LINK_POPUP.FORM.LINK.LABEL": "Link",

	//Members
	"ORGANIZATION_SETTINGS.MEMBERS.TABLE.PROFILE": "Gebruiker",
	"ORGANIZATION_SETTINGS.MEMBERS.TABLE.LAST_LOGIN": "Laatste login",
	"ORGANIZATION_SETTINGS.MEMBERS.TABLE.SUPER_ADMIN": "Super admin",

	"ORGANIZATION_SETTINGS.MEMBERS.TABLE.LOCK_MESSAGE": "Upgrade jouw plan om meer Gebruikers toe te voegen.",
	"ORGANIZATION_SETTINGS.MEMBERS.TABLE.BUTTON": "Voeg een gebruiker toe",

	//NOTIFICATION_POPUP
	"NOTIFICATION_POPUP.BUTTON.OPEN":
		"{totalUnread, plural, =0 {Notificaties} one {# ongelezen notificatie} other {# ongelezen notificaties}}",
	"NOTIFICATION_POPUP.TITLE.NOTIFICATIONS": "Notificaties",
	"NOTIFICATION_POPUP.EMPTY.TITLE": "Je hebt momenteel geen meldingen",
	"NOTIFICATION_POPUP.EMPTY.DESCRIPTION": "Je bent helemaal up-to-date 🎉 Goed gewerkt! Tijd voor een tas koffie.",
	"NOTIFICATION_POPUP.TITLE.BUTTON.VIEW_ALL": "Bekijk alle {total}",
	"NOTIFICATION_POPUP.TITLE.BUTTON.CLOSE": "Sluiten",

	//NOTIFICATION
	"NOTIFICATIONS.PAGE.TITLE": "Notificaties",
	"NOTIFICATIONS.HEADER.MARK_ALL_AS_SEEN": "Markeer alles als afgewerkt",
	"NOTIFICATION.EMPTY.TITLE": "Je hebt momenteel geen meldingen",
	"NOTIFICATION.EMPTY.DESCRIPTION": "Je bent helemaal up-to-date 🎉 Goed gewerkt! Tijd voor een tas koffie.",
	"NOTIFICATIONS.TABLE.HEADER.NOTIFICATIONS": "Notificaties",

	//NOTIFICATION_CARD
	"NOTIFICATION_CARD.HEADER.CHIP.ANNOUNCEMENT": "Nieuws",
	"NOTIFICATION_CARD.HEADER.CHIP.CONVERSATION_STARTED": "Nieuw conversatie",
	"NOTIFICATION_CARD.HEADER.CHIP.ASSIGNED_TO_CONVERSATION": "Conversatie toegewezen",
	"NOTIFICATION_CARD.HEADER.CHIP.ADDED_AS_PARTIIPANT": "Deelnemer",
	"NOTIFICATION_CARD.HEADER.CHIP.LIVE_CHAT": "Live chat",
	"NOTIFICATION_CARD.HEADER.CHIP.MENTIONED": "Vermelding",
	"NOTIFICATION_CARD.HEADER.CHIP.MESSAGE_ERROR": "Message error",
	"NOTIFICATION_CARD.HEADER.CHIP.ASSIGNED_TO_FLOW": "Flow toegewezen",
	"NOTIFICATION_CARD.HEADER.CHIP.NEW_FLOW_REPORT": "Flow rapport",
	"NOTIFICATION_CARD.HEADER.CHIP.FLOW_REMINDER_FAILED": "Flow reminder fout",
	"NOTIFICATION_CARD.HEADER.CHIP.SYNCHRONIZATION": "Synchronizatie",
	"NOTIFICATION_CARD.HEADER.CHIP.REPORT.BULK_DOWNLOAD": "Bulk download rapporten",

	"NOTIFICATION_CARD.FOOTER.MARK_AS_DONE": "Markeer als afgewerkt",

	//ORGANIZATION_SETTINGS.BRANDING
	"ORGANIZATION_SETTINGS.BRANDING.TITLE": "Huisstijl",
	"ORGANIZATION_SETTINGS.BRANDING.BREADCRUMB.SETTINGS": "Instellingen",
	"ORGANIZATION_SETTINGS.BRANDING.BREADCRUMB.TITLE": "Huisstijl",

	"ORGANIZATION_SETTINGS.BRANDING.LOGO.TITLE": "Logo en icoon",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.DESCRIPTION":
		"Laad het logo van je onderneming op en kies een icoon om uitgaande communicatie te personaliseren.",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.FORM.LOGO.TITLE": "Logo",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.FORM.LOGO.LABEL": "Laad jouw logo op",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.FORM.LOGO.HELP": "Maximale grootte: 10MB",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.FORM.ICON.TITLE": "Icoon",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.FORM.ICON.LABEL": "Laad jouw icoon op",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.FORM.ICON.HELP": "Maximale grootte: 10MB",

	"ORGANIZATION_SETTINGS.BRANDING.COLOR.TITLE": "Kleurenschema",
	"ORGANIZATION_SETTINGS.BRANDING.COLOR.DESCRIPTION":
		"Wijzig de kleuren voor de uitgaande communicatie naar de huisstijl van je kantoor.",
	"ORGANIZATION_SETTINGS.BRANDING.COLOR.FORM.BRAND_COLOR.LABEL": "Hoofdkleur",
	"ORGANIZATION_SETTINGS.BRANDING.COLOR.FORM.BRAND_COLOR.PLACEHOLDER": "#F8C945",
	"ORGANIZATION_SETTINGS.BRANDING.COLOR.FORM.ACCENT_COLOR.LABEL": "Accentkleur",
	"ORGANIZATION_SETTINGS.BRANDING.COLOR.FORM.ACCENT_COLOR.PLACEHOLDER": "#002140",
	"ORGANIZATION_SETTINGS.BRANDING.COLOR.FORM.TEXT_COLOR.LABEL": "Tekstkleur",
	"ORGANIZATION_SETTINGS.BRANDING.COLOR.FORM.TEXT_COLOR.PLACEHOLDER": "#002140",

	"ORGANIZATION_SETTINGS.BRANDING.FONT.TITLE": "Lettertype",
	"ORGANIZATION_SETTINGS.BRANDING.FONT.DESCRIPTION": "Kies het lettertype voor de chatbot en flow widget.",
	"ORGANIZATION_SETTINGS.BRANDING.FONT.FORM.PRIMARY_FONT.LABEL": "Lettertype",

	"ORGANIZATION_SETTINGS.BRANDING.FONT.ADD_CUSTOM": "Upload je eigen lettertype.",
	"ORGANIZATION_SETTINGS.BRANDING.FONT.CUSTOM.LOCK": "Upgrade je abonnement om eigen lettertypes te uploaden.",

	"ORGANIZATION_SETTINGS.MODAL.APPLY_TO_WIDGET.TITLE": "Huisstijl toepassen op widget",
	"ORGANIZATION_SETTINGS.MODAL.APPLY_TO_WIDGET.DESCRIPTION":
		"Wil je de huidige huistijl voor de widget overscrhijven met je nieuwe huisstijl?",
	"ORGANIZATION_SETTINGS.MODAL.APPLY_TO_WIDGET.DECLINE": "Nee",
	"ORGANIZATION_SETTINGS.MODAL.APPLY_TO_WIDGET.ACCEPT": "Ja, ook toepassen op de widget",

	//ORGANIZATION_SETTINGS.GENERAL
	"ORGANIZATION_SETTINGS.GENERAL.TITLE": "Bedrijf instellingen",
	"ORGANIZATION_SETTINGS.GENERAL.BREADCRUMB.SETTINGS": "Instellingen",
	"ORGANIZATION_SETTINGS.GENERAL.BREADCRUMB.TITLE": "Bedrijf instellingen",

	"ORGANIZATION_SETTINGS.GENERAL.BASIC.TITLE": "Bedrijfsinstellingen",
	"ORGANIZATION_SETTINGS.GENERAL.BASIC.FORM.NAME.LABEL": "Bedrijfsnaam",
	"ORGANIZATION_SETTINGS.GENERAL.BASIC.FORM.PHONE.LABEL": "Telefoonnummer",
	"ORGANIZATION_SETTINGS.GENERAL.BASIC.FORM.PHONE.HELP":
		"Dit telefoonnummer kan gebruikt worden om je bedrijf te contacteren.",
	"ORGANIZATION_SETTINGS.GENERAL.BASIC.FORM.SUPPORT_EMAIL.LABEL": "E-mailadres",
	"ORGANIZATION_SETTINGS.GENERAL.BASIC.FORM.SUPPORT_EMAIL.HELP":
		"Dit e-mailadres gebruiken we standaard om contact op te nemen met je bedrijf.",
	"ORGANIZATION_SETTINGS.GENERAL.BASIC.FORM.SUCCESS": "Bedrijfsinstellingen zijn succesvol opgeslagen.",

	"ORGANIZATION_SETTINGS.GENERAL.LOCATIONS.TITLE": "Locatie",
	"ORGANIZATION_SETTINGS.GENERAL.LOCATIONS.TABLE.ADD_BUTTON": "Locatie toevoegen",

	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.TITLE": "Sociale media",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.ADD_FIELDS": "Sociale media link toevoegen",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.ADD_FIELDS.PLACEHOLDER.LABEL": "Platform",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.ADD_FIELDS.PLACEHOLDER.VALUE":
		"https://www.facebook.com/bothivechatbots",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.FIELD_LABELS.FACEBOOK.FIELD.PLACEHOLDER":
		"https://www.facebook.com/bothivechatbots",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.FIELD_LABELS.LINKEDIN.FIELD.PLACEHOLDER":
		"https://be.linkedin.com/company/bothive-chatbots",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.FIELD_LABELS.INSTAGRAM.FIELD.PLACEHOLDER":
		"https://www.instagram.com/bothiveHQ/",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.FIELD_LABELS.TWITTER.FIELD.PLACEHOLDER":
		"https://twitter.com/BothiveHQ",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.FIELD_LABELS.YOUTUBE.FIELD.PLACEHOLDER":
		"https://www.youtube.com/channel/UCA4zAo0t7MKrUYIqbLgTliQ",

	"ORGANIZATION_SETTINGS.GENERAL.SUGGESTIONS.TITLE": "Gerelateerde instellingen",

	//ORGANIZATION_SETTINGS.OPENING_HOURS
	"ORGANIZATION_SETTINGS.OPENING_HOURS.TITLE": "Openingsuren",
	"ORGANIZATION_SETTINGS.OPENING_HOURS.BREADCRUMB.SETTINGS": "Instellingen",
	"ORGANIZATION_SETTINGS.OPENING_HOURS.BREADCRUMB.TITLE": "Openingsuren",

	"ORGANIZATION_SETTINGS.OPENING_HOURS.BASIC.TITLE": "Kantoor uren",
	"ORGANIZATION_SETTINGS.OPENING_HOURS.BASIC.DESCRIPTION": "Voeg de openingstijden van je kantoor toe.",

	"ORGANIZATION_SETTINGS.OPENING_HOURS.SUGGESTIONS.TITLE": "Gerelateerde instellingen",
	"ORGANIZATION_SETTINGS.OPENING_HOURS.SUGGESTIONS.GENERAL.TITLE": "Bedrijfsvragen",
	"ORGANIZATION_SETTINGS.OPENING_HOURS.SUGGESTIONS.GENERAL.DESCRIPTION":
		"Vul meer gedetailleerde bedrijfsinformatie aan zodat Sophia (potentiële) klanten juist kan informeren.",
	"ORGANIZATION_SETTINGS.OPENING_HOURS.SUGGESTIONS.GENERAL.BUTTON": "Wijzig bedrijfsvragen",

	//ORGANIZATION_INFORMATION
	"ORGANIZATION_INFORMATION.PAGE.TITLE": "Bedrijfsvragen (BETA)",
	"ORGANIZATION_INFORMATION.BREADCRUMB.SETTINGS": "Instellingen",
	"ORGANIZATION_INFORMATION.BREADCRUMB.TITLE": "Bedrijfsvragen",

	"ORGANIZATION_INFORMATION.HERO.TITLE": "Maak {agent} slimmer",
	"ORGANIZATION_INFORMATION.HERO.DESCRIPTION":
		"Vul specifieke bedrijfsinformatie aan zodat {agent} (potentiële) klanten persoonlijk en snel kan helpen.",

	"ORGANIZATION_INFORMATION.REQUEST_MORE_SETTINGS.TITLE": "Stel nieuwe vragen voor",
	"ORGANIZATION_INFORMATION.REQUEST_MORE_SETTINGS.DESCRIPTION":
		"Weet jij een nieuwe vraag of instelling die {agent} nog krachtiger kan maken? Geef je voorstel door via de klantendienst en wij doen de rest!",
	"ORGANIZATION_INFORMATION.REQUEST_MORE_SETTINGS.BUTTON": "Nieuwe instelling voorstellen",

	//POPUP
	"POPUP.BUTTON.CANCEL": "Annuleren",
	"POPUP.BUTTON.CLOSE": "Sluiten",
	"POPUP.BUTTON.DELETE": "Verwijderen",
	"POPUP.BUTTON.INVITE_USER": "Uitnodigen",
	"POPUP.BUTTON.SAVE": "Opslaan",
	"POPUP.PLACEHOLDER.DESTRUCTIVE": "Typ VERWIJDEREN",
	"POPUP.PLACEHOLDER.ARCHIVE": "Typ ARCHIVEREN",
	"POPUP.PLACEHOLDER.DESTRUCTIVE.CHANNEL": "Typ VERWIJDEREN",

	//POPUP ADD USER
	"POPUP.ADD_USER.TITLE": "Voeg een gebruiker toe",
	"POPUP.ADD_USER.INVITE_USER_HELP": "Als de gebruiker geen e-mail ontvangen heeft, bekijk de spam folder.",

	//POPUP EDIT USER
	"POPUP.EDIT_USER.TITLE": "Bewerk gebruiker",

	//POPUP DESTRUCTIVE_SETTINGS
	"settings.advanced.delete_organization_modal.title": "Verwijder je organisatie",
	"settings.advanced.delete_organization_modal.delete_button": "Verwijder organisatie",
	"settings.advanced.delete_organization_modal.description":
		"Ben je zeker dat je je organisatie wil verwijderen? Deze actie is onomkeerbaar! Je abonnement wordt onmiddellijk stopgezet en je flows worden gedeactiveerd.",
	"settings.advanced.delete_organization_modal.failed":
		"Je organisatie kon niet verwijderd worden, contacteer onze customer support",
	//POPUP DESTRUCTIVE_PROFILE
	"POPUP.DESTRUCTIVE_PROFILE.TITLE": "Verwijder profiel",
	"POPUP.DESTRUCTIVE_PROFILE.DELETE_BUTTON": "Verwijder",
	"POPUP.DESTRUCTIVE_PROFILE.DESCRIPTION":
		"Ben je zeker dat je jouw profiel wil verwijderen? Deze actie kan niet ongedaan gemaakt worden.",

	//POPUP DESTRUCTIVE_PROFILE
	"POPUP.DEACTIVATE_TEAM.TITLE": "Verwijder je team",
	"POPUP.DEACTIVATE_TEAM.BUTTON_LABEL": "Verwijder team",
	"POPUP.DEACTIVATE_TEAM.DESCRIPTION":
		"Ben je zeker dat je je team wil verwijderen? Deze actie is onomkeerbaar! Je abonnement wordt onmiddellijk stopgezet en de chatbot wordt gedeactiveerd.",

	//POPUP TERMS_AND_CONDITIONS
	"POPUP.TERMS_AND_CONDITIONS_UPDATE.TITLE": "Gebruiksvoorwaarden",
	"POPUP.TERMS_AND_CONDITIONS_UPDATE.LOG_OUT": "Log uit",
	"POPUP.TERMS_AND_CONDITIONS_UPDATE.ACCEPT": "Accepteer de voorwaarden",

	//PROFILE
	"PROFILE.PAGE.TITLE": "Mijn profiel",
	"PROFILE.FALLBACK.TITLE": "Profiel informatie kon niet gevonden worden",
	"PROFILE.FALLBACK.DESCRIPTION":
		"Log uit en probeer opnieuw. Als dit probleem blijft gebeuren contacteer onze klantendienst.",
	"PROFILE.POPUP.AVAILABLE": "Beschikbaar",
	"PROFILE.POPUP.INBOX": "Mijn Inbox",
	"profile.popup.settings": "Mijn profiel",
	"profile.popup.actions.shortcuts": "Shortcuts",
	"profile.popup.logout": "Uitloggen",

	//PROFILE.INBOX_SETTINGS
	"PROFILE.SETTINGS.INBOX.CHANNELS.NO_CHANNELS.TITLE": "Connecteer je e-mail account",
	"PROFILE.SETTINGS.INBOX.CHANNELS.NO_CHANNELS.DESCRIPTION":
		"Verbind je e-mail accounts met je Bothive profiel om alle communicatie in één inbox te verzamelen. Slimme regels en automatisering zorgen voor een nog snellere verwerking van je ongelezen berichten.",
	"PROFILE.SETTINGS.INBOX.CHANNELS.NO_CHANNELS.BUTTON.CONNECT": "Koppel een e-mailaccount",

	//PROFILE_TEAMS
	"PROFILE_TEAMS.PAGE.TITLE": "Mijn teams",
	"PROFILE_TEAMS.SEARCH.PLACEHOLDER": "Zoek op teamnaam",
	"PROFILE_TEAMS.TABLE.HEADER.TEAM_NAME": "Teamnaam",
	"PROFILE_TEAMS.TABLE.FALLBACK": "Er konden geen teams gevonden worden",

	"PROFILE_TEAMS.FALLBACK.TITLE": "Teams",
	"PROFILE_TEAMS.FALLBACK.DESCRIPTION":
		"Je bent nog geen lid van een team. Vraag aan een administrator van een Bothive team om je uit te nodigen of maak je eigen team aan.",
	"PROFILE_TEAMS.FALLBACK.LABEL": "Maak een team aan",

	//GENERAL
	"profile.general.accordion_title": "Algemeen",
	"profile.general.form.avatar.label": "Profielfoto",
	"profile.general.form.avatar.help": "Maximale grootte: 10MB",
	"profile.general.form.first_name.label": "Voornaam",
	"profile.general.form.last_name.label": "Naam",
	"profile.general.form.email.label": "E-mail",
	"profile.general.form.phone_number.label": "Telefoonnummer",

	//SECURITY
	"PROFILE.SECURITY.ACCORDION_TITLE": "Wachtwoord",
	"PROFILE.SECURITY.OLD_PASSWORD": "Oud wachtwoord",
	"PROFILE.SECURITY.NEW_PASSWORD": "Nieuw wachtwoord",
	//DANGER_ZONE
	"profile.danger_zone.accordion_title": "Gebruiker verwijderen",
	"profile.danger_zone.title": "Verwijder je gebruiker",
	"profile.danger_zone.description":
		"Ben je zeker dat je je gebruiker wil verwijderen? Al je gegevens zullen verloren gaan en je gebruiker wordt verwijderd uit alle teams.",
	"profile.danger_zone.button": "Verwijder gebruiker",
	"profile.danger_zone.accordion_title": "Verwijder je profiel",
	"profile.danger_zone.description":
		"Ben je zeker dat je je gebruiker wil verwijderen? Al je gegevens zullen verloren gaan en je gebruiker wordt verwijderd uit alle teams.",
	"profile.danger_zone.title": "Verwijder je profiel",

	//QUESTION
	"QUESTION_SETTINGS.PAGE.TITLE": "Vragen",

	"QUESTION_SETTINGS.STANDARD_QUESTIONS.TITLE": "Antwoorden bewerken (bèta)",
	"QUESTION_SETTINGS.STANDARD_QUESTIONS.DESCRIPTION":
		"Pas de antwoorden van Bothive aan naar de stijl van je kantoor. Let op: je bent zelf verantwoordelijk voor de aangepaste antwoorden en het up-to-date houden van de informatie. Op dit moment is het nog niet mogelijk de volledige dataset te bewerken of intents toe te voegen. Vraag je chatbotbeheerder om andere intents aan te passen of nieuwe toe te voegen.",
	"QUESTION_SETTINGS.STANDARD_QUESTIONS.BUTTON": "Antwoorden bewerken",

	"QUESTION.ASK_QUESTION.TITLE": "Stel een nieuwe vraag voor",
	"QUESTION.ASK_QUESTION.DESCRIPTION":
		"Wil je een nieuwe vraag toevoegen aan de chatbot? Druk op de knop en wij doen de rest!",
	"QUESTION.ASK_QUESTION.BUTTON": "Nieuwe vraag voorstellen",

	//REPORT_DETAIL
	"REPORT_DETAIL_ASIDE.FALLBACK.EMPTY.TITLE": "{username} is nog niet gestart met het invullen van deze flow.",
	"REPORT_DETAIL_ASIDE.ACTIONS.TITLE": "Acties:",
	"REPORT_DETAIL_ASIDE.ACTIONS.DOWNLOAD_ATTACHMENTS": "Download Bijlages ({count})",
	"REPORT_DETAIL_ASIDE.INTEGRATIONS.TITLE": "Integraties:",
	"REPORT_DETAIL_ASIDE.INTEGRATIONS.ADD": "Voeg toe ...",

	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.TITLE": "Rapportgegevens opslaan bij contact",
	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.DESCRIPTION":
		"Wil je deze rapportgegevens opslaan bij dit contact? Dit zal ervoor zorgen dat de huidige gegevens overschreven worden met de nieuwe. Wanneer dit contact nieuwe flows invult, zullen de gekende gegevens automatisch vooraf in de flow worden aangevuld.",
	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.SAVE_DECISION": "Deze pop-up niet meer tonen en mijn keuze onthouden.",
	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.SAVE": "Opslaan bij contact",
	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.DONT_SAVE": "Gegevens niet opslaan bij contact",
	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.ERROR.MESSAGE":
		"Er liep iets mis bij het opslaan van de rapport data in het contact.",
	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.SUCCESS.MESSAGE":
		"Rapportgegevens werden succesvol aan dit contact toegevoegd.",
	//SETTINGS

	//SETTINGS ASIDE
	"SETTINGS.ASIDE.OVERVIEW.TITLE": "Overzicht",
	"SETTINGS.ASIDE.INBOX.TITLE": "Inbox",
	"SETTINGS.ASIDE.FLOWS.TITLE": "Flows",
	"SETTINGS.ASIDE.CONTACTS.TITLE": "Contacten",
	"SETTINGS.ASIDE.VIRTUAL_ASSISTANT.TITLE": "Chatbot",
	"SETTINGS.ASIDE.TEAM.TITLE": "Team",
	"SETTINGS.ASIDE.ADVANCED.TITLE": "Geavanceerd",

	"SETTINGS_OVERVIEW.PAGE.TITLE": "Instellingen",
	//SETTINGS_OVERVIEW.ORGANIZATION
	"SETTINGS_OVERVIEW.ORGANIZATION.TITLE": "Bedrijfsvragen",

	"SETTINGS_OVERVIEW.ORGANIZATION.GENERAL.TITLE": "Algemeen",
	"SETTINGS_OVERVIEW.ORGANIZATION.GENERAL.DESCRIPTION":
		"Vul de informatie van je organisatie aan zodat de chatbot de gegevens correct kan weergeven.",
	"SETTINGS_OVERVIEW.ORGANIZATION.OPENING_HOURS.TITLE": "Openingsuren",
	"SETTINGS_OVERVIEW.ORGANIZATION.OPENING_HOURS.DESCRIPTION":
		"Voeg de momenten waarop de fysieke locatie(s) open zijn toe.",
	//SETTINGS_OVERVIEW.BRANDING
	"SETTINGS_OVERVIEW.BRANDING.TITLE": "Huisstijl",

	"SETTINGS_OVERVIEW.BRANDING.STYLING.TITLE": "Huisstijl",
	"SETTINGS_OVERVIEW.BRANDING.STYLING.DESCRIPTION":
		"Laad het logo van je onderneming op en kies een icoon om uitgaande communicatie te personaliseren.",
	"SETTINGS_OVERVIEW.BRANDING.WIDGET.TITLE": "Widget",
	"SETTINGS_OVERVIEW.BRANDING.WIDGET.DESCRIPTION": "Personaliseer de widget naar de huisstijl van je onderneming.",
	//SETTINGS_OVERVIEW.CONTACTS.TITLE
	"SETTINGS_OVERVIEW.CONTACTS.TITLE": "Contacten",
	"SETTINGS_OVERVIEW.CONTACTS.CONTACTS.TITLE": "Contacten",
	"SETTINGS_OVERVIEW.CONTACTS.CONTACTS.DESCRIPTION": "Wijzig contactinstellingen en import opties.",
	"SETTINGS_OVERVIEW.CONTACTS.LABELS.TITLE": "Contact labels",
	"SETTINGS_OVERVIEW.CONTACTS.LABELS.DESCRIPTION": "Beheer je contact labels",
	//SETTINGS_OVERVIEW.VIRTUAL_ASSISTANT
	"SETTINGS_OVERVIEW.VIRTUAL_ASSISTANT.TITLE": "Virtuele assistent",

	"SETTINGS_OVERVIEW.VIRTUAL_ASSISTANT.AGENT.TITLE": "Chatbot",
	"SETTINGS_OVERVIEW.VIRTUAL_ASSISTANT.AGENT.DESCRIPTION":
		"Personaliseer de chatbot naar de huisstijl van je onderneming.",
	"SETTINGS_OVERVIEW.VIRTUAL_ASSISTANT.RESPONSES.TITLE": "Antwoorden",
	"SETTINGS_OVERVIEW.VIRTUAL_ASSISTANT.RESPONSES.DESCRIPTION":
		"Personaliseer de standaard antwoorden van de chatbot, zoals foutmeldingen en welkomstteksten.",
	//SETTINGS_OVERVIEW.MEMBERS
	"SETTINGS_OVERVIEW.MEMBERS.TITLE": "Gebruikers",
	"SETTINGS_OVERVIEW.MEMBERS.DESCRIPTION":
		"Stel in welke gebruikers toegang hebben tot het platform en wijzig hun bevoegdheden.",
	//SETTINGS.NOTIFICATIONS.PAGE.TITLE
	"SETTINGS.NOTIFICATIONS.PAGE.TITLE": "Notificatie-instellingen",
	"SETTINGS.NOTIFICATIONS.BREADCRUMB.SETTINGS": "Instellingen",
	"SETTINGS.NOTIFICATIONS.BREADCRUMB.TITLE": "Notificatie-instellingen",

	"SETTINGS.NOTIFICATIONS.BILLING.TITLE": "Facturatie e-mails",
	"SETTINGS.NOTIFICATIONS.BILLING.FORM.EMAIL.LABEL":
		"Vul het e-mailadres in waarop je facturen en herinneringen wil ontvangen.",
	"SETTINGS.NOTIFICATIONS.BILLING.FORM.EMAIL.PLACEHOLDER": "facturen@bothive.be",
	"SETTINGS.NOTIFICATIONS.BILLING.FORM.EMAIL.EMAIL_ERROR": "Vul een geldig e-mailadres in.",

	"SETTINGS.NOTIFICATIONS.TECHNICAL.TITLE": "Technische notificaties",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DESCRIPTION":
		"Vul het e-mailadres in voor technische meldingen zoals wijzigingen in de API's of SDK's.",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.FORM.DEVELOPER.LABEL": "E-mail developer",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.FORM.DEVELOPER.PLACEHOLDER": "developer@bothive.be",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.FORM.DEVELOPER.EMAIL_ERROR": "Vul een geldig e-mailadres in.",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.FORM.DEVELOPER.BUTTON": "Verstuur e-mail",

	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.TITLE": "Contacteer jouw web developer",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.DESCRIPTION":
		"Wij brengen jouw webontwikkelaar graag op de hoogte van je nieuwe chat applicatie. Voer hier het contact e-mailadres in van jouw webontwikkelaar en wij e-mailen de instructies door om de widget op jouw website te installeren.",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.FORM.EMAIL_WEB_DEVELOPER.LABEL": "E-mail webontwikkelaar",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.FORM.API_KEY.LABEL": "API-key",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.FORM.API_KEY.HELP":
		"Je hebt een API-key nodig met de 'widget.load' permission",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.FORM.API_KEY.ERROR":
		"Je bent vergeten een API-key te selecteren",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.SUBMIT_BUTTON": "Verstuur e-mail",

	//----------------------//
	// NOTIFICATION_OPTIONS //
	//----------------------//
	"NOTIFICATION_OPTIONS.DEFAULT": "Bothive platform",
	"NOTIFICATION_OPTIONS.EMAIL": "E-mail",
	//PROFILE_NOTIFICATION_SETTINGS
	"SETTINGS.NOTIFICATIONS.FLOW.CARD.TITLE": "Flows",
	"SETTINGS.NOTIFICATIONS.FLOW.CARD.TOOLTIP": "Deze instellingen zijn voor jou persoonlijk",
	"SETTINGS.NOTIFICATIONS.FLOW.CARD.DESCRIPTION":
		"Selecteer de kanalen waarop je notificaties wil ontvangen van flowrapporten.",
	"SETTINGS.NOTIFICATIONS.FLOW.FORM.ASSIGN.LABEL":
		"Ontvang een notificatie wanneer je wordt toegewezen aan een rapport.",
	"SETTINGS.NOTIFICATIONS.FLOW.FORM.REPORT_ACTIONS.LABEL":
		"Ontvang een notificatie wanneer een actie gebeurt op een rapport.",
	"SETTINGS.NOTIFICATIONS.INBOX.CARD.TITLE": "Inbox",
	"SETTINGS.NOTIFICATIONS.INBOX.CARD.TOOLTIP": "Deze instellingen zijn voor jou persoonlijk",
	"SETTINGS.NOTIFICATIONS.INBOX.CARD.DESCRIPTION":
		"Selecteer de kanalen waarop je notificaties wil ontvangen van de inbox.",
	"SETTINGS.NOTIFICATIONS.INBOX.FORM.ASSIGN.LABEL":
		"Ontvang een notificatie wanneer je wordt toegewezen aan een conversatie.",
	"SETTINGS.NOTIFICATIONS.INBOX.FORM.PARTICIPANT.LABEL":
		"Ontvang een notificatie wanneer je wordt toegevoegd word aan een conversatie als deelnemer.",
	"SETTINGS.NOTIFICATIONS.INBOX.FORM.LIVE_CHAT.LABEL": "Ontvang een notificatie wanneer een live chat gestart wordt.",
	"SETTINGS.NOTIFICATIONS.INBOX.FORM.MENTIONED.LABEL":
		"Ontvang notificatie wanneer je gementioned wordt in een conversatie",
	"SETTINGS.NOTIFICATIONS.INBOX.FORM.NEW_CONVERSATION.LABEL":
		"Ontvang een notificatie wanneer een conversatie wordt gestart.",
	"SETTINGS.NOTIFICATIONS.INBOX.FORM.ACCOUNT_MANAGED_CONVERSATION.LABEL":
		"Ontvang een notificatie wanneer een contact waarvan jij accountbeheerder bent een conversatie start.",

	// SETTINGS
	"SETTINGS.NOTIFICATIONS.SETTINGS.CARD.TITLE": "Instellingen",
	"SETTINGS.NOTIFICATIONS.SETTINGS.CARD.DESCRIPTION":
		"Selecteer de kanalen waarop je notificaties wil ontvangen voor bepaalde instellingen.",
	"SETTINGS.NOTIFICATIONS.SETTINGS.CARD.TOOLTIP": "Deze instellingen zijn voor jou persoonlijk",
	"SETTINGS.NOTIFICATIONS.SETTINGS.INTEGRATION.LABEL":
		"Ontvang een notificatie wanneer een integratie connectie opnieuw geconnecteerd moet worden.",

	//SETTINGS_OVERVIEW.KNOWLEDGE_CENTER
	"SETTINGS_OVERVIEW.KNOWLEDGE_CENTER.TITLE": "Kenniscenter",

	"SETTINGS_OVERVIEW.KNOWLEDGE_CENTER.QUESTIONS.TITLE": "Bedrijfsvragen",
	"SETTINGS_OVERVIEW.KNOWLEDGE_CENTER.QUESTIONS.DESCRIPTION":
		"Specifieke vragen over jullie bedrijf die {agent} kan beantwoorden.",
	"SETTINGS_OVERVIEW.KNOWLEDGE_CENTER.INTENTS.TITLE": "Intents",
	"SETTINGS_OVERVIEW.KNOWLEDGE_CENTER.INTENTS.DESCRIPTION": "Alle mogelijke vragen die {agent} kan beantwoorden.",
	//SETTINGS_OVERVIEW.CHANNELS
	"SETTINGS_OVERVIEW.CHANNELS.TITLE": "Kanalen",
	"SETTINGS_OVERVIEW.CHANNELS.DESCRIPTION": "Verbind je eigen chatkanalen en e-mailadressen met de Bothive inbox.",
	"SETTINGS_OVERVIEW.CHANNELS.GOOGLE.TOOLTIP": "Connecteer met Gmail",
	"SETTINGS_OVERVIEW.CHANNELS.OFFICE.TOOLTIP": "Connecteer met Outlook",
	"SETTINGS_OVERVIEW.CHANNELS.FOOTER.BUTTON": "Beheer kanalen",
	//SETTINGS_OVERVIEW.INTEGRATONS
	"SETTINGS_OVERVIEW.INTEGRATIONS.TITLE": "Integraties",
	"SETTINGS_OVERVIEW.INTEGRATIONS.GRAPH.UNLIMITED": "Ongelimiteerd",
	"SETTINGS_OVERVIEW.INTEGRATIONS.DESCRIPTION":
		"Koppel de Bothive tools met boekhoudpakketten, CRM-systemen of andere software om data te importeren.",

	"SETTINGS_OVERVIEW.INTEGRATIONS.FOOTER.BUTTON": "Beheer integraties",
	//SETTINGS_OVERVIEW.ADVANCED
	"SETTINGS_OVERVIEW.ADVANCED.TITLE": "Geavanceerd",

	"SETTINGS_OVERVIEW.ADVANCED.CONTACTS.TITLE": "Contacten (bèta)",
	"SETTINGS_OVERVIEW.ADVANCED.CONTACTS.DESCRIPTION": "Wijzig contactinstellingen zoals labels of speciale velden.",
	"SETTINGS_OVERVIEW.ADVANCED.WIDGET.TITLE": "Widget",
	"SETTINGS_OVERVIEW.ADVANCED.WIDGET.DESCRIPTION": "Geavanceerde widget instellingen",
	"SETTINGS_OVERVIEW.ADVANCED.TRIGGERS.TITLE": "Triggers",
	"SETTINGS_OVERVIEW.ADVANCED.TRIGGERS.DESCRIPTION": "Wijzig de chatbot triggers.",
	"SETTINGS_OVERVIEW.ADVANCED.CONTACT_INFO.TITLE": "Contact info",
	"SETTINGS_OVERVIEW.ADVANCED.CONTACT_INFO.DESCRIPTION":
		"Stel hier in hoe wij jou mogen contacteren en op welke email addressen.",
	"SETTINGS_OVERVIEW.ADVANCED.NOTIFICATION_SETTINGS.TITLE": "Notificatie-instellingen",
	"SETTINGS_OVERVIEW.ADVANCED.NOTIFICATION_SETTINGS.DESCRIPTION":
		"Stel in welke meldingen van de Bothive app jij en je teamleden ontvangen.",
	"SETTINGS_OVERVIEW.ADVANCED.API_KEY.TITLE": "API-key",
	"SETTINGS_OVERVIEW.ADVANCED.API_KEY.DESCRIPTION":
		"Gebruik API-keys om je website developers toegang te geven tot je Bothive team en de opgeslagen data.",
	"SETTINGS_OVERVIEW.ADVANCED.DESTRUCTIVE.TITLE": "Gevaarlijke opties",
	"SETTINGS_OVERVIEW.ADVANCED.DESTRUCTIVE.DESCRIPTION": "Verwijder of deactiveer je organisatie.",

	//SETTINGS DEPRECATED
	"SETTINGS.PAGE.TITLE": "Instellingen",
	"settings.page.title": "Instellingen",
	"SETTINGS.ASIDE.OVERVIEW": "Overzicht",
	"SETTINGS.ASIDE.ORGANIZATION": "Bedrijfinstellingen",
	"SETTINGS.ASIDE.OPENING_HOURS": "Openingsuren",
	"SETTINGS.ASIDE.BRANDING": "Huisstijl",
	"SETTINGS.ASIDE.INBOX.SETTINGS": "Inbox instellingen",
	"SETTINGS.ASIDE.INBOX.CHANNELS": "Kanalen",
	"SETTINGS.ASIDE.INBOX.LABELS": "Inbox labels",
	"SETTINGS.ASIDE.CANNED_RESPONSES": "Canned responses",
	"SETTINGS.ASIDE.EMAIL_SIGNATURE": "E-mailhandtekening",
	"SETTINGS.ASIDE.FLOW_SETTINGS": "Flow instellingen",
	"SETTINGS.ASIDE.VIRTUAL_ASSISTANT": "Virtuele assistent",
	"SETTINGS.ASIDE.ORGANIZATION_QUESTIONS": "Bedrijfsvragen",
	"SETTINGS.ASIDE.DEFAULT_ANSWERS": "Standaard antwoorden",
	"SETTINGS.ASIDE.INTENTS": "Intents",
	"SETTINGS.ASIDE.WIDGET": "Widget",
	"SETTINGS.ASIDE.INTEGRATIONS": "Integraties",
	"SETTINGS.ASIDE.CONTACT_IMPORT": "Contacten importeren",
	"SETTINGS.ASIDE.CONTACT_LABELS": "Contact labels",
	"SETTINGS.ASIDE.USERS": "Gebruikers",
	"SETTINGS.ASIDE.TRIGGERS": "Triggers",
	"SETTINGS.ASIDE.NOTIFICATION_SETTINGS": "Notificatie-instellingen",
	"SETTINGS.ASIDE.API_KEY": "Api key",
	"SETTINGS.ASIDE.ADVANCED": "Geavanceerd",

	//SETTINGS GENERAL
	"SETTINGS.GENERAL.COMPANY_NAME": "Bedrijfsnaam",
	"SETTINGS.GENERAL.COMPANY_NUMBER": "Btw-nummer",
	"SETTINGS.GENERAL.COMPANY_NUMBER_PLACEHOLDER": "Vb. BE0665684769",
	"SETTINGS.GENERAL.PHONE": "Telefoonnummer",
	"SETTINGS.GENERAL.PHONE_HELP": "Dit telefoonnummer kan gebruikt worden om contact op te nemen met je bedrijf.",
	"SETTINGS.GENERAL.SUPPORT_EMAIL": "E-mailadres",
	"SETTINGS.GENERAL.SUPPORT_EMAIL_HELP":
		"Dit e-mailadres gebruiken wij standaard om contact op te nemen met je bedrijf.",
	"SETTINGS.GENERAL.SUPPORT_EMAIL_HELP_SIGN_UP":
		"Dit e-mailadres gebruiken wij standaard om contact op te nemen met jouw bedrijf.",
	//SETTINGS BRANDING
	"SETTINGS.BRANDING.LOGO.TITLE": "Logo",
	"SETTINGS.BRANDING.LOGO.LABEL": "Upload je logo",
	"SETTINGS.BRANDING.LOGO.HELP": "Maximale grootte: 10MB",
	"SETTINGS.BRANDING.ICON.TITLE": "Icoon",
	"SETTINGS.BRANDING.ICON.LABEL": "Upload je icoon",
	"SETTINGS.BRANDING.ICON.HELP": "Maximale grootte: 10MB",

	"SETTINGS.BRANDING.BRAND_COLOR.LABEL": "Hoofdkleur",
	"SETTINGS.BRANDING.BRAND_COLOR.PLACEHOLDER": "#F8C945",

	"SETTINGS.BRANDING.ACCENT_COLOR.LABEL": "Accentkleur",
	"SETTINGS.BRANDING.ACCENT_COLOR.PLACEHOLDER": "#002140",

	"SETTINGS.BRANDING.TEXT_COLOR.LABEL": "Tekstkleur",
	"SETTINGS.BRANDING.TEXT_COLOR.PLACEHOLDER": "#002140",
	//SETTINGS LOCATIONS
	"SETTINGS.LOCATIONS.POPUP.NEW_LOCATION_TITLE": "Voeg nieuwe locatie toe",
	"SETTINGS.LOCATIONS.POPUP.EDIT_LOCATION_TITLE": "Bewerk locatie",
	"SETTINGS.LOCATIONS.POPUP.CANCEL_BUTTON": "Annuleren",
	"SETTINGS.LOCATIONS.POPUP.REMOVE_LOCATION": "Verwijder",
	"SETTINGS.LOCATIONS.POPUP.UPDATE_LOCATION": "Opslaan",
	"SETTINGS.LOCATIONS.POPUP.ADD_NEW_LOCATION": "Aanmaken",

	"SETTINGS.LOCATIONS.POPUP.SUB_HEADER.CONTACT_INFO": "Contact informatie",

	"SETTINGS.LOCATIONS.COUNTRY": "Land",
	"SETTINGS.LOCATIONS.CITY": "Stad",
	"SETTINGS.LOCATIONS.POSTALCODE": "Postcode",
	"SETTINGS.LOCATIONS.STREET": "Straat",
	"SETTINGS.LOCATIONS.NUMBER": "Huisnr.",
	"SETTINGS.LOCATIONS.COMPANY_NUMBER": "Btw-nummer",
	"SETTINGS.LOCATIONS.EMAIL": "E-mail",
	"SETTINGS.LOCATIONS.TELEPHONE_NUMBER": "Telefoonnummer",
	"SETTINGS.LOCATIONS.COMPANY_NUMBER_PLACEHOLDER": "Vb. BE0665684769",
	"SETTINGS.LOCATIONS.ADD_NEW_LOCATION": "Nieuwe locatie toevoegen",
	//SETTINGS USERS
	"SETTINGS.USERS_POPUP.EMAIL.LABEL": "E-mail",
	"SETTINGS.USERS_POPUP.EMAIL.HELP": "Geen e-mail ontvangen? Bekijk de spamfolder of contacteer onze klantendienst.",

	"settings.users_popup.error.cannot_delete_owner":
		"Een eigenaar van een organisatie kan niet verwijderd worden. Als je dit toch wenst, dan kan je onze klantendienst contacteren.",
	"settings.users_popup.permissions": "Gebruikersrechten",
	"settings.users_popup.edit_flows.title": "Flows aanpassen en toevoegen",
	"settings.users_popup.edit_flows.help": "Sta de gebruiker toe flows aan te maken, te bewerken en te verwijderen.",
	"settings.users_popup.edit_settings.title": "Teaminstellingen aanpassen",
	"settings.users_popup.edit_settings.help": "Sta de gebruiker toe teaminstellingen te wijzigen.",
	"settings.users_popup.edit_integrations.title": "Integraties toevoegen en aanpassen",
	"settings.users_popup.edit_integrations.help":
		"Sta de gebruiker toe integraties aan te maken, te bewerken of te verwijderen.",
	"settings.users_popup.manage_users.title": "Gebruikers beheren",
	"settings.users_popup.manage_users.help":
		"Sta de gebruiker toe andere gebruikers en teams toe te voegen te bewerken of te verwijderen.",
	"settings.users_popup.super_admin.title": "Super admin",
	"settings.users_popup.super_admin.help":
		"Geef de gebruiker toestemming tot alle bewerkingsrechten. Een super admin kan niet verwijderd worden uit het team.",
	"settings.users_popup.cancel": "Annuleren",
	"settings.users_popup.delete": "Verwijderen",
	"settings.users_popup.resend_invite": "Opnieuw uitnodigen",
	"settings.users_popup.read_all_contact_conversations.help":
		"Stelt de gebruiker in staat om alle gesprekken te lezen die een lid van de organisatie heeft gehad met een specifiek contact.",
	"settings.users_popup.read_all_contact_conversations.title": "Lees alle contactconversaties",

	//SETTINGS DANGER ZONE
	"settings.advanced.page.title": "Geavanceerd",
	"settings.advanced.page.tab_title": "Geavanceerd",
	"settings.advanced.breadcrumb.settings": "Instellingen",
	"settings.advanced.breadcrumb.advanced": "Geavanceerd",

	//EXPERIMENTAL FEATURES
	"settings.advanced.experimental_features.accordion.title": "Experimentele functies",
	"settings.advanced.experimental_features.tooltip":
		"Deze experimentele features zullen enkel voor jou persoonlijk aangezet worden.",
	"settings.advanced.experimental_features.toggle.label": "Ik wil de nieuwste features uitproberen.",
	"settings.advanced.experimental_features.toggle.help":
		"Let op: deze features zijn in testversie en kunnen kleine fouten of storingen bevatten.",
	"settings.advanced.experimental_features.submit": "Opslaan",

	//SETTINGS API_KEY
	"API_KEY_OVERVIEW.PAGE.TITLE": "API-key overzicht",
	"API_KEY_OVERVIEW.HEADER.BREADCRUMB.SETTINGS": "Instellingen",
	"API_KEY_OVERVIEW.HEADER.BREADCRUMB.TITLE": "API-key overzicht",

	"API_KEY_OVERVIEW.LOCK.TITLE": "Verbeter je website snel en efficiënt met API-keys",
	"API_KEY_OVERVIEW.LOCK.DESCRIPTION":
		"Met API-keys kan je website developers toegang geven tot je Bothive team en de opgeslagen data. Je hebt de mogelijkheid om deze toegang te beperken tot de noodzakelijke gegevens.",
	"API_KEY_OVERVIEW.LOCK.BUTTON.CONTACT_SUPPORT": "Contacteer ons",

	"API_KEY_OVERVIEW.NO_KEYS_FOUND.TITLE": "Verbeter je website snel en efficiënt met API-keys",
	"API_KEY_OVERVIEW.NO_KEYS_FOUND.DESCRIPTION":
		"Met API-keys kan je website developers toegang geven tot je Bothive team en de opgeslagen data. Je hebt de mogelijkheid om deze toegang te beperken tot de noodzakelijke gegevens.",
	"API_KEY_OVERVIEW.NO_KEYS_FOUND.BUTTON.CREATE_API_KEY": "API-key aanmaken",

	"FILTER.API_KEY.BUTTON.CREATE_API_KEY": "API-key aanmaken",
	"filter.api_key.button.create_api_key.tooltip": "Je kan geen API-key meer aanmaken, upgrade je abonnement.",
	"settings.api_key.overview.no_keys.disabled": "Je kan geen API-key meer aanmaken, upgrade je abonnement.",

	"API_KEY_OVERVIEW.TABLE.HEADER.DESCRIPTION": "Beschrijving",
	"API_KEY_OVERVIEW.TABLE.HEADER.API_KEY": "API-key",
	"API_KEY_OVERVIEW.TABLE.HEADER.PERMISSIONS": "Toestemmingen",
	"API_KEY_OVERVIEW.TABLE.HEADER.CREATED_AT": "Aangemaakt op",
	"API_KEY_OVERVIEW.TABLE.HEADER.IS_ENABLED": "Status",
	"API_KEY_OVERVIEW.TABLE.ROW.API_KEY.BUTTON": "Kopieer API-key",

	"API_KEY_CREATE_MODAL.HEADER.TITLE": "API-key aanmaken",
	"API_KEY_CREATE_MODAL.DELETE.ADD": "API-key aanmaken",
	"API_KEY_CREATE_MODAL.DELETE.CANCEL": "Annuleren",
	//SETTTINGS API_KEY_DETAIL
	"SETTINGS.API_KEY_DETAIL.PAGE.TITLE": "API-key detail",
	"SETTINGS.API_KEY_DETAIL.HEADER.BREADCRUMB.SETTINGS": "Instellingen",
	"SETTINGS.API_KEY_DETAIL.HEADER.BREADCRUMB.OVERVIEW": "API keys",

	"SETTINGS.API_KEY_DETAIL.FORM.NAME.LABEL": "Naam",
	"SETTINGS.API_KEY_DETAIL.FORM.NAME.HELP": "Geef je API-keys een herkenbare naam om ze snel terug te vinden.",
	"SETTINGS.API_KEY_DETAIL.FORM.NAME.REQUIRED": "Je API-key heeft een naam nodig zodat je deze makkelijk herkent",
	"SETTINGS.API_KEY_DETAIL.FORM.DESCRIPTION.LABEL": "Beschrijving",
	"SETTINGS.API_KEY_DETAIL.FORM.DESCRIPTION.HELP":
		"Voeg een beschrijving toe en leg de doelstellingen van deze API vast.",
	"SETTINGS.API_KEY_DETAIL.FORM.API_KEY.LABEL": "Api-key",
	"SETTINGS.API_KEY_DETAIL.FORM.IS_ENABLED.LABEL": "Is ingeschakeled",
	"SETTINGS.API_KEY_DETAIL.FORM.IS_ENABLED.HELP":
		"Als je de API-key uitschakelt, wordt deze onmiddellijk inactief en verliezen de applicaties die er gebruik van maken de toegang tot jouw team.",

	"SETTINGS.API_KEY_DETAIL.PERMISSIONS.CONFLICT.BANNER":
		"Je hebt één/meerdere rechten toegevoegd die niet samen compatibel zijn. Als je die rechten nodig hebt, maak dan meerdere API-sleutels aan om je veiligheid te vergroten.",

	"SETTINGS.API_KEY_DETAIL.PERMISSIONS.TITLE": "API-key rechten",
	"SETTINGS.API_KEY_DETAIL.PERMISSIONS.DESCRIPTION":
		"Bescherm je data door de toegang van de API-key te beperken. We raden aan om de API-key enkel de rechten te geven die nodig zijn om het doel te bereiken.",
	"SETTINGS.API_KEY_DETAIL.PERMISSIONS.SELECT_DOMAIN.PLACEHOLDER": "Kies API-key rechten",
	"SETTINGS.API_KEY_DETAIL.PERMISSIONS.TABLE.PERMISSION": "Rechten",
	"SETTINGS.API_KEY_DETAIL.PERMISSIONS.WARNING.DOMAIN_LOCK.DESCRIPTION":
		"Om deze API-key te activeren, moet je een vertrouwd domein invoeren.",

	"SETTINGS.API_KEY_DETAIL.DOMAIN.TITLE": "Vertrouwde domeinen",
	"SETTINGS.API_KEY_DETAIL.DOMAIN.DESCRIPTION":
		"Om ongewenste toegang te vermijden, vereisen sommige API-keys bescherming. Voeg hier vertrouwde domeinen toe om de API-key te activeren.",
	"SETTINGS.API_KEY_DETAIL.DOMAIN.TABLE.DOMAIN": "Domein naam",
	"SETTINGS.API_KEY_DETAIL.DOMAIN.BUTTON.ADD_DOMAIN": "Voeg domein toe",

	"SETTINGS.API_KEY_DETAIL.DANGER_ZONE.ACCORDION_TITLE": "Verwijder API-key",
	"SETTINGS.API_KEY_DETAIL.DANGER_ZONE.TITLE": "Verwijder deze API-key",
	"SETTINGS.API_KEY_DETAIL.DANGER_ZONE.DESCRIPTION":
		"Ben je zeker dat je deze API-key wil verwijderen? Deze actie zal onmiddellijk uitgevoerd worden en kan niet ongedaan gemaakt worden.",
	"SETTINGS.API_KEY_DETAIL.DANGER_ZONE.BUTTON": "Verwijder API-key",

	//SETTINGS EMAIL SIGNATURES
	"SETTINGS.INBOX.EMAIL_SIGNATURE.TITLE": "E-mailhandtekening",
	"SETTINGS.INBOX.EMAIL_SIGNATURE.BREADCRUMB.SETTINGS": "Instellingen",
	"SETTINGS.INBOX.EMAIL_SIGNATURE.BREADCRUMB.SIGNATURES": "Handtekeningen",

	"SETTINGS.INBOX.EMAIL_SIGNATURE.HEADER.BUTTON.CREATE": "Handtekeningen toevoegen",

	"SETTINGS.INBOX.EMAIL_SIGNATURE.TABLE.HEADER.NAME": "Naam",
	"SETTINGS.INBOX.EMAIL_SIGNATURE.TABLE.HEADER.PREVIEW": "Handtekening",
	"SETTINGS.INBOX.EMAIL_SIGNATURE.TABLE.HEADER.IS_DEFAULT": "Is standaard",

	//SETTINGS EMAIL SIGNATURES DETAIL
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.TITLE": "Handtekeningen",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BREADCRUMB.SETTINGS": "Instellingen",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BREADCRUMB.OVERVIEW": "Handtekeningen",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BREADCRUMB.CREATE": "Nieuwe handtekening",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.NAME.LABEL": "Naam",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.NAME.ERROR": "Je bent de naam vergeten in te vullen.",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.CONTENT.LABEL": "Handtekening",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.CONTENT.PLACEHOLDER": "Met vriendelijke groeten",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.CONTENT.ERROR": "Je hebt geen handtekening ingevuld.",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.IS_CHANNEL.LABEL": "Kanaal",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.IS_CHANNEL.HELP":
		"Koppel het aan een kanaal om enkel voor dat channel beschikbaar te zijn.",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.IS_PRIMARY.LABEL": "Is standaard",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.IS_PRIMARY.HELP": "Selecteer deze handtekening standaard.",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.DELETE": "Verwijderen",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.SUBMIT": "Opslaan",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BANNER.CREATE.SUCCESS": "E-mailhandtekening is aangemaakt.",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BANNER.CREATE.FAILED":
		"E-mailhandtekening kon niet aangemaakt worden, probleem later opnieuw.",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BANNER.UPDATE.SUCCESS": "E-mailhandtekening is aangepast.",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BANNER.UPDATE.FAILED":
		"E-mailhandtekening kon niet aangepast worden, probleem later opnieuw.",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BANNER.DELETE.SUCCESS": "E-mailhandtekening is verwijderd.",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BANNER.DELETE.FAILED":
		"E-mailhandtekening kon niet verwijderd worden, probleem later opnieuw.",

	// SETTINGS INBOX LABELS
	"SETTINGS.INBOX.LABELS.TITLE": "Inbox labels",
	"SETTINGS.INBOX.LABELS.BREADCRUMB.SETTINGS": "Instellingen",
	"SETTINGS.INBOX.LABELS.BREADCRUMB.INBOX.LABELS": "Inbox labels",
	"SETTINGS.INBOX.LABELS.TABLE.NAME": "Naam",
	"SETTINGS.INBOX.LABELS.TABLE.COLOR": "Kleur",
	"SETTINGS.INBOX.LABELS.TABLE.CHANNEL_NAME": "Kanaal",
	"SETTINGS.INBOX.LABELS.ADD": "Nieuw label toevoegen",
	"SETTINGS.INBOX.LABELS.EMPTY": "Nog geen labels toegevoegd",

	"SETTINGS.INBOX.LABELS.NO_INBOX.TITLE": "Je hebt geen inbox in dit team",
	"SETTINGS.INBOX.LABELS.NO_INBOX.DESCRIPTION":
		"Je hebt geen inbox in dit team, dit kan komen door verschillende redenen. Je bent geen lid van dit team, er is iets misgelopen op onze server of je hebt geen rechten om een inbox aan te maken in dit team.",

	"SETTINGS.INBOX.LABELS.MODAL.ADD.FORM.TITLE": "Nieuw label toevoegen",
	"SETTINGS.INBOX.LABELS.MODAL.ADD.FORM.NAME": "Naam",
	"SETTINGS.INBOX.LABELS.MODAL.ADD.FORM.NAME.REQUIRED": "Gelieve een naam in te vullen",
	"SETTINGS.INBOX.LABELS.MODAL.ADD.FORM.COLOR": "Labelkleur",
	"SETTINGS.INBOX.LABELS.MODAL.ADD.FORM.COLOR.REQUIRED": "Gelieve een labelkleur te kiezen",
	"SETTINGS.INBOX.LABELS.MODAL.ADD.CONFIRM": "Toevoegen",
	"SETTINGS.INBOX.LABELS.MODAL.ADD.RETURN": "Terug",

	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.TITLE": "Label aanpassen",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.NAME": "Naam",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.NAME.REQUIRED": "Gelieve een naam in te vullen",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.COLOR": "Labelkleur",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.COLOR.REQUIRED": "Gelieve een labelkleur te kiezen",

	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.CHANNEL": "Kanaal",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.CHANNEL.HELP":
		"Labels die je koppelt aan een kanaal zijn alleen zichtbaar in de inboxen van dat kanaal.",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.OPTION.PUBLIC": "Publiek voor heel je team",

	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.DELETE": "Verwijderen",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.CONFIRM": "Aanpassen",

	"SETTINGS.API_KEY_MODAL.DELETE_API_KEY.HEADER.TITLE": "Verwijderd API-key",
	"SETTINGS.API_KEY_MODAL.DELETE_API_KEY.HEADER.DESCRIPTION":
		"Ben je zeker dat je deze API-key wil verwijderen? deze actie kan niet ongedaan worden gemaakt",

	"SETTINGS.API_KEY_MODAL.DELETE_API_KEY.FORM.DELETE.PLACEHOLDER":
		"Typ {keyword} en klik daarna op de Verwijder knop",
	"SETTINGS.API_KEY_MODAL.DELETE_API_KEY.FORM.DELETE.ERROR": "De invoer is niet gelijk aan {keyword}",

	"SETTINGS.API_KEY_MODAL.DELETE_API_KEY.DELETE.BUTTON_LABEL": "Verwijder API-KEY",

	"SETTINGS.API_KEY_MODAL.ADD_DOMAIN.HEADER.TITLE": "Nieuw domein toevoegen",
	"SETTINGS.API_KEY_MODAL.ADD_DOMAIN.FORM.DOMAIN_NAME.PLACEHOLDER":
		"Vb. https://www.bothive.be, http://getbothive.com, https://...",
	"SETTINGS.API_KEY_MODAL.ADD_DOMAIN.FORM.DOMAIN_NAME.ERROR_MESSAGE": "Vergeet de domain naam niet",
	"SETTINGS.API_KEY_MODAL.ADD_DOMAIN.BUTTON.ADD_DOMAIN": "Voeg domain toe",
	"SETTINGS.API_KEY_MODAL.ADD_DOMAIN.BUTTON.CANCEL": "Annuleren",

	//STYLE_INPUT
	"STYLE_INPUT.BOT.BOT_NAME": "Naam",

	"STYLE_INPUT.MARKUP.BOLD": "Vet",
	"STYLE_INPUT.MARKUP.ITALIC": "Schuin",
	"STYLE_INPUT.MARKUP.UNDERLINED": "Onderlijned",

	//TABLE_CELL
	"TABLE_CELL.BOOLEAN.ACTIVE": "Actief",
	"TABLE_CELL.BOOLEAN.INACTIVE": "Inactief",
	"TABLE_CELL.FALLBACK.DEFAULT": "Er kon niet genoeg data gevonden worden",

	//TEMPLATE
	"TEMPLATE.ORGANIZATION.COMPANY_NAME": "Bedrijfsnaam",
	"TEMPLATE.ORGANIZATION.COMPANY_PHONE": "Telefoonnummer",
	"TEMPLATE.ORGANIZATION.COMPANY_EMAIL": "E-mail",
	"TEMPLATE.ORGANIZATION.COMPANY_LOGO": "Logo",
	"TEMPLATE.ORGANIZATION.COMPANY_ICON": "Icoon",
	"TEMPLATE.ORGANIZATION.COMPANY_LOCATION_COUNTRY": "Land",
	"TEMPLATE.ORGANIZATION.COMPANY_LOCATION_CITY": "Stad",
	"TEMPLATE.ORGANIZATION.COMPANY_LOCATION_POSTAL_CODE": "Postcode",
	"TEMPLATE.ORGANIZATION.COMPANY_LOCATION_STREET": "Straatnaam",
	"TEMPLATE.ORGANIZATION.COMPANY_LOCATION_NUMBER": "Straatnummer",
	"TEMPLATE.ORGANIZATION.COMPANY_LOCATION_COMPANY_NUMBER": "Ondernemingsnummer",
	"TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_FACEBOOK": "Facebook",
	"TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_LINKEDIN": "Linkedin",
	"TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_INSTAGRAM": "Instagram",
	"TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_TWITTER": "Twitter",
	"TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_YOUTUBE": "Youtube",

	"TEMPLATE.USER.FIRST_NAME": "Voornaam",
	"TEMPLATE.USER.LAST_NAME": "Familienaam",
	"TEMPLATE.USER.USERNAME": "Naam",
	"TEMPLATE.USER.AVATAR": "Avatar",
	"TEMPLATE.USER.EMAIL": "E-mail",
	"TEMPLATE.USER.PHONE_NUMBER": "Telefoonnummer",

	//WARNING
	"WARNING.SUPPORT.ERROR":
		"Er is iets misgelopen, deze pagina kan niet correct worden weergegeven. Als dit probleem zich blijft voordoen, contacteer support@bothive.be.",
	"WARNING.SUPPORT.IE_NOT_SUPPORTED":
		"Ons dashboard ondersteunt deze browser niet meer. Je kan de website wel blijven gebruiken maar voor de beste gebruikservaring raden we aan om een moderne browser zoals Chrome, Firefox of Safari te gebruiken. De chatbot widget op jullie website wordt echter wel nog ondersteund in alle browsers zodat jouw klanten je overal kunnen bereiken.",
	"WARNING.SUPPORT.BROWSER_IS_OFFLINE": "Er is geen internet connectie",

	//WIDGET_BUBBLE
	"WIDGET_BUBBLE.SENDER.SEND_BY": "Nieuw bericht van {name}",

	//WIDGET_MOCKUP
	"WIDGET_MOCKUP.FOOTER.POWERED_BY.LABEL": "Ontwikkeld door Bothive",
	"WIDGET_MOCKUP.FOOTER.SEND.PLACEHOLDER": "Stel mij een vraag.",

	//MOLECULES
	"MOLECULES.LOCATION_CARD.PRIMARY_ADDRESS": "Primair adres",

	"MOLECULES.UNLIMITED": "Ongelimiteerd",
	"MOLECULES.HOURS_RANGE.2_TIME_SLOTS": "{label} van {timeSlot1} tot {timeSlot2}",
	"MOLECULES.HOURS_RANGE.4_TIME_SLOTS": "{label} van {timeSlot1} tot {timeSlot2} en van {timeSlot1} tot {timeSlot2}",

	"MOLECULES.DETAIL_TOGGLE.OFF": "Uitgeschakeld",
	"MOLECULES.DETAIL_TOGGLE.ON": "Ingeschakeld",

	"MOLECULES.DATA_PREVIEW.PDF.TEMPLATE_FAILED": "Template kon niet ingeladen worden",

	"MOLECULES.EMAIL_HEADER.TO": "Aan:",
	"MOLECULES.EMAIL_HEADER.FROM": "Van:",
	"MOLECULES.EMAIL_HEADER.SUBJECT": "Onderwerp:",
	"MOLECULES.EMAIL_HEADER.CC": "Cc:",
	"MOLECULES.EMAIL_HEADER.BCC": "Bcc:",
	"MOLECULES.EMAIL_HEADER.REPLY_TO": "Antwoord naar:",
	"MOLECULES.EMAIL_HEADER.ATTACHMENTS": "Ga naar {count, plural, one {# bijlage} other {# bijlages}}",
	"MOLECULES.EMAIL_FOOTER.ATTACHMENTS": "{count, plural, one {# bijlage} other {# bijlages}}",

	"MOLECULES.EMAIL_HEADER.TITLE": "Conversatie beantwoorden",
	"MOLECULES.EMAIL_HEADER.OPTIONS.CLOSE_REPLY": "Sluit beantwoorden",

	"MOLECULES.EMAIL_FOOTER.ADD_SIGNATURE": "Voeg handtekening toe",

	"MOLECULES.FOOTER.CANCEL": "Annuleren",
	"MOLECULES.FOOTER.DELETE": "Verwijderen",
	"MOLECULES.FOOTER.SAVE": "Opslaan",

	"MOLECULES.INDICATOR.IS_TYPING": "{username} is aan het typen",

	//MODEL
	"MODEL.CONTACT.REFERENCE_ID": "Referentie user id",
	"MODEL.CONTACT.FIRST_NAME": "Voornaam",
	"MODEL.CONTACT.LAST_NAME": "Familienaam",
	"MODEL.CONTACT.USERNAME": "Volledige naam",
	"MODEL.CONTACT.GENDER": "Gender",
	"MODEL.CONTACT.AVATAR": "Avatar",
	"MODEL.CONTACT.BIRTHDAY": "Verjaardag",
	"MODEL.CONTACT.BIRTH_COUNTRY": "Geboorteland",
	"MODEL.CONTACT.BIRTH_PLACE": "Geboorteplaats",
	"MODEL.CONTACT.EMAIL": "E-mail",
	"MODEL.CONTACT.PHONE": "Telefoon",
	"MODEL.CONTACT.LANGUAGE": "Taal",
	"MODEL.CONTACT.NATIONAL_INSURANCE_NUMBER": "Rijksregisternummer",
	"MODEL.CONTACT.COMPANY_NUMBER": "Ondernemingsnummer",
	"MODEL.CONTACT.SOCIAL_SECURITY_SECRETARIAT": "Sociaal Secretariaat",
	"MODEL.CONTACT.SOCIAL_SECURITY_CASE_NUMBER": "Sociaal Secretariaat Dossiernummer",
	"MODEL.CONTACT.SOCIAL_SECURITY_CONTACT": "Sociaal Secretariaat contact",
	"MODEL.CONTACT.LABELS": "Labels",
	"MODEL.CONTACT.TIME_ZONE": "Tijdzone",
	"MODEL.CONTACT.CREATED_AT": "Contact sinds",

	"MODEL.CONTACT.ADDRESS.STREET_NUMBER": "Adres: Huisnummer",
	"MODEL.CONTACT.ADDRESS.STREET_NAME": "Adres: Straatnaam",
	"MODEL.CONTACT.ADDRESS.BUS": "Adres: Bus",
	"MODEL.CONTACT.ADDRESS.CITY": "Adres: Stad",
	"MODEL.CONTACT.ADDRESS.PROVINCE": "Adres: Provincie",
	"MODEL.CONTACT.ADDRESS.COUNTRY": "Land",
	"MODEL.CONTACT.ADDRESS.POSTAL_CODE": "Adres: Post code",
	"MODEL.CONTACT.ADDRESS.PRIMARY": "Adres: Dommicilie",

	"MODEL.CONTACT.ATTRIBUTES.BANK.ACCOUNT_NUMBER": "Bank: Account nummer",
	"MODEL.CONTACT.ATTRIBUTES.BANK.IBAN": "Bank: IBAN",
	"MODEL.CONTACT.ATTRIBUTES.BANK.COUNTRY": "Bank: Land",
	"MODEL.CONTACT.ATTRIBUTES.BANK.BIC": "Bank: BIC",
	"MODEL.CONTACT.ATTRIBUTES.BANK.OPENED_AT": "Bank: Rekening geopend op",
	"MODEL.CONTACT.ATTRIBUTES.BANK.CLOSED_AT": "Bank: Account gesloten op",
	"MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.NAME": "Bank: Naam",
	"MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.STREET_AND_NUMBER": "Bank: Straat & nummer",
	"MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.CITY": "Bank: Stad",
	"MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.PROVINCE": "Bank: Provencie",
	"MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.COUNTRY": "Bank: Land",
	"MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.POSTAL": "Bank: Post code",
	"MODEL.CONTACT.ATTRIBUTES.BANK.REPORTED_TO_GOVERNMENT_YES_NO": "Bank: Geregistreerd bij de overheid",
	"MODEL.CONTACT.ATTRIBUTES.BANK.RECEIVED_INTERESTS": "Bank: Interesten verkregen in euro",

	"MODEL.CONTACT.ATTRIBUTES.OWNER_OF_FOREIGN_LEGAL_CONSTRUCTIONS":
		"Eigenaar van buitenlandse juridische constructies",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_INCOME_RECIEVED":
		"Buitenlandse juridische constructies: Inkomen gekregen",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_INCOME_AMOUNT.DIVIDENDS":
		"Buitenlandse juridische constructies: Inkomen hoeveelheid: Dividenten",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_INCOME_AMOUNT.INTERESTS":
		"Buitenlandse juridische constructies: Inkomen hoeveelheid: Interesten",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_INCOME_AMOUNT.OTHER":
		"Buitenlandse juridische constructies: Inkomen hoeveelheid: Andere",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_ALREADY_WITHHOLDED_TAX":
		"Buitenlandse juridische constructies: Belastingen al weerhouden",

	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.NAME": "Buitenlands vasthoed: Naam",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.RENTED_OUT": "Buitenlands vasthoed: Verhuurd",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.YEARLY_RENTABLE_INCOME":
		"Buitenlands vasthoed: Jaarlijks inkomen verhuur",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.TAX_PAYED_ABROAD":
		"Buitenlands vasthoed: Belasting betaald in het buitenland",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.PURCHASED_AT": "Buitenlands vasthoed: Gekocht op",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.SOLD_AT": "Buitenlands vasthoed: Verkocht op",

	"MODEL.CONTACT.ATTRIBUTES.HAS_DISABILITY": "Persoonlijk: Heeft een handicap",
	"MODEL.CONTACT.ATTRIBUTES.IBAN_FOR_TAX_RETURNS": "Persoonlijk: IBAN voor belastingaangiften",
	"MODEL.CONTACT.ATTRIBUTES.MARITAL_STATUS": "Persoonlijk: Burgelijke staat",
	"MODEL.CONTACT.ATTRIBUTES.MATRIMONIAL_PROPERTY_SYSTEM": "Persoonlijk: Huwelijksvermogenssysteem",
	"MODEL.CONTACT.ATTRIBUTES.PARTNER.FIRST_NAME": "Persoonlijk: Partner: Voornaam",
	"MODEL.CONTACT.ATTRIBUTES.PARTNER.LAST_NAME": "Persoonlijk: Partner: Familienaam",
	"MODEL.CONTACT.ATTRIBUTES.BIRTH_PLACE": "Persoonlijk: Geboorteplaats",
	"MODEL.CONTACT.ATTRIBUTES.BIRTH_COUNTRY": "Persoonlijk: Geboorteland",

	"MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.TYPE": "Naaste: Type",
	"MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.NAME": "Naaste: Naam",
	"MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.HAS_DISABILITY": "Naaste: Heeft handicap",
	"MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.BIRTHDAY": "Naaste: Verjaardag",
	"MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.CHILDCARE_CERTIFICATE": "Naaste: Certificaat kinderopvang",
	"MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.DEPENDENCY_TYPE": "Naaste: Type afhankelijkheid",

	//DATE
	"DATE_PICKER.STATIC_RANGE.THIS_WEEK": "Deze week",
	"DATE_PICKER.STATIC_RANGE.LAST_WEEK": "Vorige week",
	"DATE_PICKER.STATIC_RANGE.THIS_MONTH": "Deze maand",
	"DATE_PICKER.STATIC_RANGE.LAST_MONTH": "Vorige maand",
	"DATE_PICKER.STATIC_RANGE.LAST_6_MONTHS": "Laatste 6 maanden",
	"DATE_PICKER.STATIC_RANGE.THIS_YEAR": "Dit jaar",
	"DATE_PICKER.STATIC_RANGE.LAST_2_YEARS": "Laatste 2 jaar",
	"DATE_PICKER.STATIC_RANGE.LAST_5_YEARS": "Laatste 5 jaar",

	//REPORT PROGRESS
	CREATED: "Aangemaakt",
	COMPLETE: "Voltooid",
	COMPLETED: "Voltooid",
	IN_PROGRESS: "Bezig",
	INVITE_SEND: "Uitgestuurd",
	PENDING_SEND: "Poging uitsturen mislukt",

	//FLOW OVERVIEW SEND FLOW
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.HEADER.TITLE": 'Verstuur flow "{flowName}" naar je contacten',
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.FOOTER.SEND": "Verstuur",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.LABEL": "Label",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.LABEL.PLACEHOLDER": "Selecteer contacten op basis van label",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.CONTACT": "Contact",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.CONTACT.PLACEHOLDER": "Selecteer contacten op basis van e-mail",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.ACCOUNT_MANAGER": "Dossierbeheerders",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.ACCOUNT_MANAGER.PLACEHOLDER":
		"Selecteer contacten op basis van dossierbeheerder",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.PROGRESS": "Progressie",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.PROGRESS.PLACEHOLDER":
		"Selecteer contacten op basis van hun rapport progressie",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.ALL": "Seleceer alle contacten in uw organisatie.",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.FILTER.LABEL": "Filter",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.FILTER.REQUIRED_ERROR": "Je hebt de filter nog niet volledig ingevuld",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.LABEL": "Kanaal",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.REQUIRED_ERROR": "Je hebt nog geen kanaal geselecteerd",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.RECONNECT.TOOLTIP": "Verbind dit kanaal opnieuw",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.LABEL": "E-mail",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.ERROR_EMPTY": "Je hebt nog geen bericht ingegeven",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.ERROR_NO_LINK_VARIABLE":
		"Je hebt de variable '{{flow_link}}' nog niet toegevoegd. Deze variabele plaats een unieke link in je email zonder deze variable zal de email niet werken.",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SIGNATURE.LABEL": "Handtekening",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SIGNATURE.NOSIGNATURES":
		"Om een e-mailhandtekening aan je bericht toe te voegen, moet je deze eerst instellen. Ga naar instellingen > e-mailhandtekening.",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.PLACEHOLDER":
		"Schrijf een bericht naar uw contacten. Gebruik het variabel {{ flow_link }} in uw mail. Dit is de gepersonaliseerde link die uw contacten kunnen gebruiken om de flow in te vullen. U kan dit (en andere) variabelen gebruiken door invoegen + template te klikken",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.NO_INBOX.TITLE": "Deze functionaliteit is niet beschikbaar",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.NO_INBOX.DESCRIPTION":
		"Om flows naar uw contacten te sturen moet u geconnecteerd zijn met de Bothive inbox.",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.NO_INBOX.FOOTER.CANCEL": "Annuleren",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.NO_INBOX.FOOTER.REQUEST_DEMO": "Bothive inbox demonstratie inboeken",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2022.TITLE": "Personenbelasting",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2022.DESCRIPTION":
		"U kan deze template gebruiken om uw klanten een persoonlijke flow link mail te versturen.",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2024.TITLE": "Personenbelasting",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2024.DESCRIPTION":
		"U kan deze template gebruiken om uw klanten een persoonlijke flow link mail te versturen.",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.DEFAULT.TITLE": "Algemene template",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.DEFAULT.DESCRIPTION":
		"U kan deze template gebruiken om uw klanten een persoonlijke flow link mail te versturen.",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.FOOTER.COPY_LINK.TOOLTIP": "Klik hier om de flow link te kopiëren",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.FOOTER.COPY_LINK": "Kopieer flow link",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.FOOTER.SEND.DISABLED": "Gelieve alle nodige velden in te vullen",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SUBJECT.LABEL": "Onderwerp",

	"FLOWS_OVERVIEW.TABLE.HEADER.SEND_FLOW": "Versturen",
	"FLOWS_OVERVIEW.TABLE.ROW.SEND_FLOW": "Flow versturen",

	"FLOWS_OVERVIEW.SEND_FLOW.BANNER.SUCCESS": "Flow e-mail succesvol verstuurd",
	"FLOWS_OVERVIEW.SEND_FLOW.BANNER.FAILED": "Flow e-mail kon niet verstuurd worden",

	"FLOWS_OVERVIEW.SEND_FLOW.SUCCESS.TITLE": "Uw flow wordt uitgestuurd.",
	"FLOWS_OVERVIEW.SEND_FLOW.SUCCESS.DESCRIPTION":
		"De flow wordt momenteel uitgestuurd naar je geselecteerde klanten. Dat kan tot 30 minuten duren. Je kan de status van het uitsturen per klant terugvinden bij de rapporten onder ‘Progressie’. Je ontvangt ook een notificatie wanneer alle flows uitgestuurd zijn.",
	"FLOWS_OVERVIEW.SEND_FLOW.SUCCESS.BUTTON": "Ga naar rapporten",

	"FLOWS.OVERVIEW.TABLE.FLOW": "Flow",
	"FLOWS.OVERVIEW.TABLE.FLOW.FETCH_FAILED.TITLE": "Flows konden niet opgehaald worden",
	"FLOWS.OVERVIEW.TABLE.FLOW.FETCH_FAILED.BUTTON": "Opnieuw proberen",
	"FLOWS.OVERVIEW.TABLE.QUERY.NOT_FOUND": "Geen flows gevonden",
	"FLOWS.OVERVIEW.TABLE.STATUS": "Status",
	"FLOWS.OVERVIEW.TABLE.STATE.ACTIVE": "Actief",
	"FLOWS.OVERVIEW.TABLE.STATE.INACTIVE": "Inactief",
	"FLOWS.OVERVIEW.TABLE.BUTTON.COPY_FLOW_LINK": "Kopieer link",
	"FLOWS.OVERVIEW.TABLE.BUTTON.SEND_FLOWS": "Verstuur flow",

	"FLOWS.OVERVIEW.SEARCH.PLACEHOLDER": "Zoek op flow",
	"FLOWS.OVERVIEW.SEARCH.TOTAL_FOUND":
		"{total, plural, =0 {Geen flows gevonden} one {# flow gevonden} other {# flows gevonden}} {totalEnabled, plural, =0 { } one {waarvan # actief} other {waarvan # actief}}",
	"FLOWS.OVERVIEW.ADD_FLOW.BUTTON": "Flow toevoegen",

	"FLOWS.OVERVIEW.FIRST_TIME.TITLE": "Maak jouw eerste flow aan",
	"FLOWS.OVERVIEW.FIRST_TIME.DESCRIPTION":
		"Flows geven jou de mogelijkheid om administratieve processen te digitaliseren. Gebruikers doorlopen zelfstandig een automatisch stappenplan in een gebruiksvriendelijke en interactieve chat omgeving.\nMet onze templates maak je snel een eigen flow aan die je meteen kan doorsturen naar jouw klanten. Deze feature zorgt voor minder repetitief werk en meer productiviteit.",
	"FLOWS.OVERVIEW.FIRST_TIME.BUTTON_LABEL": "Bekijk al onze templates",

	"FLOWS.OVERVIEW.LOCK.TITLE": "Flows: de automatische formulieren van de toekomst",
	"FLOWS.OVERVIEW.LOCK.DESCRIPTION":
		"Met flows kan je jouw administratieve processen digitaliseren. Gebruikers doorlopen zelfstandig een automatisch stappenplan in een gebruiksvriendelijke en interactieve chatomgeving.",
	"FLOWS.OVERVIEW.LOCK.UPGRADE_BUTTON": "Upgrade jouw plan",

	"FLOWS.OVERVIEW.ADD_FLOW.TITLE": "Flow template",
	"FLOWS.OVERVIEW.ADD_FLOW.FETCH_FAILED.TITLE": "Flows konden niet opgehaald worden",
	"FLOWS.OVERVIEW.ADD_FLOW.DESCRIPTION":
		"Kies een template om de flow toe te voegen. Wil je een nieuwe flow aanvragen? Laat een berichtje achter in onze klantendienst of mail naar support@bothive.be.",

	"FLOWS.OVERVIEW.ADD_FLOW.FALLBACK.DESCRIPTION":
		"Contacteer ons support team voor mogelijke verbeteringen of suggesties",
	"FLOWS.OVERVIEW.ADD_FLOW.FALLBACK.BUTTON": "support@bothive.be.",

	"FLOWS.OVERVIEW.ADD_FLOW.WARNING.PLAN.TITLE": "Upgrade jouw plan.",
	"FLOWS.OVERVIEW.ADD_FLOW.WARNING.PLAN.DESCRIPTION":
		"Upgrade je plan om een nieuwe flow toe te voegen, wil je hier meer informatie of een demo over neem contact op met ons support team.",

	"FLOWS.OVERVIEW.ADD_FLOW.WARNING.EXISTS.TITLE": "{name} is al aangemaakt",
	"FLOWS.OVERVIEW.ADD_FLOW.WARNING.EXISTS.DESCRIPTION": "Wil je nog een template voor deze flow aanmaken?",

	"FLOWS.OVERVIEW.ADD_FLOW.WARNING.LOCKED.TITLE": "Upgrade jouw plan.",
	"FLOWS.OVERVIEW.ADD_FLOW.WARNING.LOCKED.DESCRIPTION":
		"Upgrade je plan om een nieuwe flow toe te voegen, wil je hier meer informatie of een demo over neem contact op met ons support team.",

	"FLOWS.OVERVIEW.ADD_FLOW.BANNER.FAILED": "Flow kan momenteel niet aangemaakt worden. Probeer later opnieuw.",

	"FLOWS.REPORT.OVERVIEW.TABLE.FETCH_FAILED.TITLE": "Rapporten konden niet opgehaald worden",
	"FLOWS.REPORT.OVERVIEW.TABLE.EMPTY.TITLE":
		"Hier vind je de rapporten terug van alle ingevulde flows. Op dit moment doorliep nog niemand de flow.",
	"FLOWS.REPORT.OVERVIEW.TABLE.FETCH_FAILED.BUTTON": "Opnieuw proberen",
	"FLOWS.REPORT.OVERVIEW.TABLE.QUERY.NOT_FOUND": "Geen rapporten gevonden",
	"FLOWS.REPORT.OVERVIEW.TABLE.FIRSTNAME": "Voornaam",
	"FLOWS.REPORT.OVERVIEW.TABLE.LASTNAME": "Familienaam",
	"FLOWS.REPORT.OVERVIEW.TABLE.ASSIGNEE": "Toegewezen aan",
	"FLOWS.REPORT.OVERVIEW.TABLE.STATUS": "Status",
	"FLOWS.REPORT.OVERVIEW.TABLE.PROGRESS": "Progressie",
	"FLOWS.REPORT.OVERVIEW.TABLE.LABELS": "Contact labels",
	"FLOWS.REPORT.OVERVIEW.TABLE.CREATED_AT": "Aangemaakt op",
	"FLOWS.REPORT.OVERVIEW.TABLE.LAST_UPDATED": "Laatst gewijzigd",

	"FLOWS.REPORT.OVERVIEW.FETCH_FAILED.TITLE": "Status types konden niet opgehaald worden",
	"FLOWS.REPORT.OVERVIEW.FILTER.STATUS.LABEL": "Interne status",
	"FLOWS.REPORT.OVERVIEW.FILTER.STATUS.PLACEHOLDER": "Alle open statussen",
	"FLOWS.REPORT.OVERVIEW.FILTER.PROGRESS.LABEL": "Progressie gebruiker",
	"FLOWS.REPORT.OVERVIEW.FILTER.PROGRESS.PLACEHOLDER": "Alle progressie niveaus",
	"flows.report.overview.filter.account_manager.label": "Filter op dossierbeheerder",
	"flows.report.overview.filter.account_manager.placeholder": "Toon reporten van iedereen",
	"FLOWS.REPORT.OVERVIEW.LABELS.TITLE": "Filter op contact label",
	"FLOWS.REPORT.OVERVIEW.LABELS.PLACEHOLDER": "Filter op contact label",
	"FLOWS.REPORT.OVERVIEW.FILTER.SEARCH.LABEL": "Zoek op contact",
	"FLOWS.REPORT.OVERVIEW.FILTER.SEARCH.PLACEHOLDER":
		"Zoek op de naam van het contact of het e-mailadres. Bijvoorbeeld: Sophia of sophia@bothive.be",
	"FLOWS.REPORT.OVERVIEW.LABELS.LABEL": "Filter op contact label",
	"FLOWS.REPORT.OVERVIEW.LABELS.PLACEHOLDER": "Filter op label",
	"FLOWS.REPORT.OVERVIEW.FILTER.DETAIL.TOTAL_RESULTS":
		"{total, plural, =0 {Geen rapporten gevonden} one {# rapport gevonden} other {# rapporten gevonden}}",

	//--------------//
	// BULK ACTIONS //
	//--------------//
	// DOWNLOAD REPORT
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SELECTION_TOTAL":
		"Er {selection, plural, =0 {is momenteel geen enkel rapport} one {is momenteel # rapport} other {zijn momenteel # rapporten}} geselecteerd.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SELECT_ALL":
		" Selecteer {total, plural, one {# rapport} other {alle rapporten (#)}}",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.DESELECT_ALL": "Selectie ongedaan maken",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.DOWNLOAD_REPORTS": "Download rapporten",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.TITLE": "Download rapporten",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.CONFIRM": "Bevestig",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.INFO_MESSAGE":
		"Je staat op het punt om {totalReportsSelected, plural, =0 {geen rapporten} one {# {reportType} rapport} other {# {reportType} rapporten}} te downloaden {withAttachments, plural, =0 {exclusief} one {inclusief} other {}} bijlages. Je krijgt een notificatie en  een e-mail wanneer de bestanden klaar zijn om te downloaden.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.REPORT_TYPE.LABEL": "Rapport type",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.REPORT_TYPE.HELP": "Gewenst bestandstype.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.INCLUDE_ATTACHMENTS.LABEL": "Inclusief bijlages",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.INCLUDE_ATTACHMENTS.HELP":
		"Download ook alle bijlages per rapport.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.SUCCESS.TITLE": "Bulk download is gestart.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.SUCCESS.DESCRIPTION":
		"Wanneer de bestanden verwerkt zijn ontvangt u een notificatie en email met download link.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.FAILED.TITLE": "Bulk download error.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.FAILED.DESCRIPTION":
		"Het starten van de bulk download actie is mislukt.",
	// CHANGE STATUS
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.CHANGE_STATUS": "Verander Status",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.TITLE": "Status type aanpassen",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.CONFIRM": "Bevestig",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.INFO_MESSAGE":
		"Je staat op het punt het status type aan te passen voor {totalReportsSelected, plural, =0 {0 rapporten} one {# rapport} other {# rapporten}}. Je krijgt een notificatie wanneer deze actie uitgevoerd is.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.STATUS_TYPE.LABEL": "Status type",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.STATUS_TYPE.PLACEHOLDER": "Selecteer status type...",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.STATUS_TYPE.REQUIRED_MSG":
		"Selecteer eerst een nieuw status type.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.SUCCESS.TITLE": "Bulk rapport status aanpassen is gestart.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.SUCCESS.DESCRIPTION":
		"Wanneer de actie compleet is krijgt u een notificatie in uw dashboard.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.FAILED.TITLE": "Bulk status type aanpassen error.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.FAILED.DESCRIPTION":
		"De uitvoering van de bulk status type aanpassen actie is mislukt.",
	// SEND TO INTEGRATION
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SEND_TO_INTEGRATION": "Versturen naar integratie",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SEND_TO_INTEGRATION.DISABLED": "Geen integraties beschikbaar",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.TITLE": "Versturen naar integratie",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.RULE.LABEL": "Integratie selecteren",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.RULE.REQUIRED": "Gelieve een integratie te selecteren",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.REPORT_TYPE.LABEL": "Rapport type",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.REPORT_TYPE.HELP":
		"Het bestandstype dat gebruikt zal worden, wordt niet door elke automatisatie ondersteund",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.DUPLICATES.LABEL": "Rapporten dubbel versturen",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.DUPLICATES.HELP":
		"Als deze rapporten reeds verzonden werden, zullen deze opnieuw verstuurd worden.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.INFO_MESSAGE":
		"Je staat op het punt om de integratie uit te voeren voor {totalReportsSelected, plural, =0 {0 rapporten} one {# rapport} other {# rapporten}}. Deze actie is niet onomkeerbaar.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.SUCCESS.TITLE":
		"Bulk versturen naar integratie is gestart.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.SUCCESS.DESCRIPTION":
		"Wanneer de actie compleet is krijgt u een notificatie in uw dashboard.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.FAILED.TITLE":
		"Bulk versturen naar integratie error.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.FAILED.DESCRIPTION":
		"De uitvoering van het bulk versturen naar integratie is mislukt.",

	// PARTNER PUSH
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.PARTNER_PUSH": "Partner Duw",

	"FLOWS.REPORT.TEMPLATE.ASIDE.BACK_TO_OVERVIEW.LABEL": "Terug naar overzicht",
	"FLOWS.REPORT.TEMPLATE.ASIDE.BACK_TO_REPORT_OVERVIEW.LABEL": "Terug naar overzicht",
	"FLOWS.REPORT.TEMPLATE.ASIDE.REPORT.LABEL": "Rapporten",
	"FLOWS.REPORT.TEMPLATE.ASIDE.CONFIGURATION.LABEL": "Personalisatie",
	"FLOWS.REPORT.TEMPLATE.ASIDE.STATUS_TYPES.LABEL": "Status types",
	"FLOWS.REPORT.TEMPLATE.ASIDE.SETTINGS.LABEL": "Flow instellingen",

	"FLOWS.REPORT_DETAIL.TEMPLATE.PAGE.TITLE": "Rapport detail",
	"FLOWS.REPORT_DETAIL.TEMPLATE.BREADCRUMB.FLOWS": "Flows",

	"FLOWS.REPORT_DETAIL.HEADER.PREVIEW_STATE.LABEL": "Bekijk als:",

	"FLOWS.REPORT_DETAIL.REPORT_LIST.FETCH_FAILED.TITLE": "Rapporten konden niet opgehaald worden",
	"FLOWS.REPORT_DETAIL.REPORT_LIST.FALLBACK.DESCRIPTION": "Er zijn geen rapporten gevonden",
	"FLOWS.REPORT_DETAIL.REPORT_LIST.END_OF_LIST": "Alle rapporten zijn opgehaald 🙌",

	"FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.LABEL": "Toegewezen aan",
	"FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.PLACEHOLDER": "Wijs een teamlid toe",
	"FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.BANNER.ASSIGN_USER_FAILED":
		"{username} kon niet toegewezen worden. Probeer later nog eens opnieuw.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.BANNER.UNASSIGN_FAILED":
		"Kon toewijzen niet aanpassen. Probeer later nog eens opnieuw.",

	"FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.MARK_AS_COMPLETED": "Markeer als afgewerkt",
	"FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.MARKED_AS_COMPLETED": "Gemarkeerd als afgewerkt",

	"FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.LABEL": "Status",
	"FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.PLACEHOLDER": "Kies een rapport status",
	"FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.BANNER.FAILED":
		"Status type kon niet aangepast worden. Probeer later nog eens opnieuw.",

	"FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.AUTHOR.LABEL": "Ingevuld door:",
	"FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.PROGRESS.LABEL": "Progressie:",
	"FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.CREATED_AT.LABEL": "Aangemaakt op:",
	"FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.UPDATED_AT.LABEL": "Laatst bewerkt door mij:",
	"FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.LAST_FILLED_IN_AT.LABEL": "Gewijzigd door contact:",
	"FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.LAST_SYNCED_TO_CONTACT_AT.LABEL": "Laatste overdracht naar contact:",

	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.TITLE": "Automatisaties",
	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.FOOTER.ADD_BUTTON": "Automatisatie toevoegen",

	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.CONFIRMATION_MODAL.CANCEL": "Annuleren",
	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.BANNER.SUCCEEDED": "Automatisatie is uitgevoerd.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.BANNER.FAILED":
		"Automatisatie kon niet uitgevoerd worden. Probeer later nog eens opnieuw.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.TOOLTIP.SUCCEEDED": "Rapport verstuurd.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.TOOLTIP.FAILED": "Rapport versturen mislukt",

	"FLOWS.REPORTS.STATUS_TYPES.ADD.BUTTON": "Nieuw status type toevoegen",
	"FLOWS.REPORTS.STATUS_TYPES.TABLE.STATUS_TYPE": "Status type",
	"FLOWS.REPORTS.STATUS_TYPES.TABLE.DEFAULT": "Standaard",
	"FLOWS.REPORTS.STATUS_TYPES.TABLE.IS_DONE": "Voltooid",
	"FLOWS.REPORTS.STATUS_TYPES.TABLE.EMPTY_TEXT": "Geen status types gevonden.",
	"FLOWS.REPORTS.STATUS_TYPES.REORDER_FAILED": "Sorting is tijdelijk niet beschikbaar. probeer later opnieuw.",

	"FLOWS.REPORTS.STATUS_TYPES.COPY.TITLE": "Kopieer status types",
	"FLOWS.REPORTS.STATUS_TYPES.COPY.DESCRIPTION":
		"Kopieer snel de status types van een andere flow in deze om je workflow te behouden.",
	"FLOWS.REPORTS.STATUS_TYPES.COPY.BUTTON": "Kopieer status types",

	"FLOWS.REPORTS.STATUS_TYPES.MODAL.ADD": "Toevoegen",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.UPDATE": "Aanpassen",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.ADD.TITLE": "Status type toevoegen",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.ADD.FAILED": "Status type kon niet toegevoegd worden",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT": "Aanpassen",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT.TITLE": "Status type aanpassen",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT.IS_DEFAULT.TOOLTIP":
		"Status types die standaard worden toegekend aan rapporten kunnen niet verwijderd worden",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT.FAILED": "Kon statustype niet aanpassen",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT.DUPLICATE_NAME": "De status naam bestaat al in deze flow.",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE": "Verwijderen",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE.FAILED": "Kon statustype niet verwijderen",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE.SUCCEEDED": "Status type is verwijderd",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.NAME": "Naam",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.NAME.REQUIRED": "Gelieve het statustype een naam te geven",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DESCRIPTION": "Omschrijving",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DESCRIPTION.PLACEHOLDER": "Wanneer gebruik je dit statustype?",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DEFAULT": "Standaard",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DEFAULT.DESCRIPTION": "Koppel dit statustype automatisch aan nieuwe rapporten",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DEFAULT.WARNING_BANNER.NEED_ONE":
		"Je hebt altijd 1 standaard status type nodig, Zet een ander status type als om deze uit te kunnen zetten.",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DONE.WARNING_BANNER.REPLACE":
		"Je kan maximaal één statustype instellen als standaard. Als je dit statustype inschakelt als standaard vervangt <bold>{default}</bold> als standaard statustype.",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DONE": "Voltooid",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DONE.DESCRIPTION":
		"Rapporten met dit statustype worden gezien als afgewerkt. Deze rapporten worden dus niet meer standaard in het overzicht getoond.",

	"FLOWS.REPORTS.STATUS_TYPES.MAX.WARNING":
		"Je gebruikte reeds {currentStatusTypes} van je {maxStatusTypes} beschikbare statustypes, verhoog je plan om nog meer types te kunnen gebruiken.",
	"FLOWS.REPORTS.STATUS_TYPES.MAX.LIMIT":
		"Je hebt je limiet van {maxStatusTypes} statustypes bereikt, verhoog je plan om meer types te kunnen gebruiken.",

	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY": "Kopieer",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.BUTTON": "Kopieer status types",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.TITLE": "Kopieer status types van andere flow",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.FLOWS": "Flows",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.FLOWS.REQUIRED": "Gelieve een flow te selecteren",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.FLOWS.SEARCH_NOT_FOUND": "Geen flows gevonden",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.OVERWRITE": "Overschrijven",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.OVERWRITE.HELP": "Overschrijf de reeds bestaande status types van deze flow",
	"FLOWS.REPORTS.STATUS_TYPES.COPY.SUCCEEDED": "Status types zijn succesvol gekopieerd",
	"FLOWS.REPORTS.STATUS_TYPES.COPY.FAILED": "Kopieeren is mislukt, probeer later opnieuw",

	"FLOWS.REPORT.STATUS_TYPES.TABLE.STATUS_TYPE": "Status type",
	"FLOWS.REPORT.STATUS_TYPES.TABLE.DEFAULT": "Default",
	"FLOWS.REPORT.STATUS_TYPES.TABLE.IS_DONE": "Completed",

	"FLOWS.REPORT.FAILED.TITLE": "Flow niet gevonden",
	"FLOWS.REPORT.FAILED.BUTTON": "Ga naar flow overzicht",

	"FLOWS.REPORT.SETTINGS.LOADING": "Flow instellingen aan het ophalen...",
	"FLOWS.REPORT.SETTINGS.CUSTOMIZE.HEADER.TITLE": "Flow beheer",
	"FLOWS.REPORT.SETTINGS.CUSTOMIZE.HEADER.DESCRIPTION":
		"Beheer de flow instellingen en personaliseer de flow naam en omschrijving.",
	"FLOWS.REPORT.SETTINGS.CUSTOMIZE.FORM.NAME.LABEL": "Naam",
	"FLOWS.REPORT.SETTINGS.CUSTOMIZE.FORM.NAME.LABEL.REQUIRED": "Gelieve een flow naam in te vullen",
	"FLOWS.REPORT.SETTINGS.CUSTOMIZE.FORM.DESCRIPTION.LABEL": "Beschrijving",
	"FLOWS.REPORT.SETTINGS.CUSTOMIZE.FORM.DESCRIPTION.LABEL.REQUIRED": "Gelieve een flow beschrijving in te vullen",
	"FLOWS.REPORT.SETTINGS.FORM.IS_ENABLED.LABEL": "Flow inschakelen",
	"FLOWS.REPORT.SETTINGS.FORM.IS_ENABLED.HELP":
		"Als je de flow uitschakelt, is die niet meer beschikbaar voor jouw gebruikers.",
	"FLOWS.REPORT.SETTINGS.FORM.SHOW_FLOW.LABEL": "Flow weergeven op je website",
	"FLOWS.REPORT.SETTINGS.FORM.SHOW_FLOW.HELP":
		"Als je deze instelling uitschakelt, zal deze flow niet zichtbaar zijn op jouw website of andere plaatsen waar je de flow widget hebt toegevoegd.",

	"FLOWS.REPORT.SETTINGS.UNIQUE_LINK.LABEL": "Deelbare link naar de flow",
	"FLOWS.REPORT.SETTINGS.UNIQUE_LINK.TOOLTIP": "Kopieer link",
	"FLOWS.REPORT.SETTINGS.UNIQUE_LINK.HELP": "Deel deze link met klanten waarvan je gegevens wil ophalen.",

	"FLOWS.REPORT.SETTINGS.FORM.SUCCEEDED.TITLE": "Flow aangepast",
	"FLOWS.REPORT.SETTINGS.FORM.FAILED.TITLE": "Flow kon niet geüpdatet worden",
	"FLOWS.REPORT.SETTINGS.FORM.FAILED.DESCRIPTION": "Probeer later opnieuw of contacteer onze customer support",

	"FLOWS.REPORT.SETTINGS.WIDGET.HEADER.TITLE": "Flow widget instellingen",
	"FLOWS.REPORT.SETTINGS.WIDGET.HEADER.DESCRIPTION": "Personaliseer de flow tegels die op je website getoond worden.",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.TITLE": "Logo",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.CUSTOMIZE": "Logo aanpassen",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.LABEL": "Upload logo",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.HELP": "Maximale grootte: 10MB",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.ERROR.FILE_SIZE": "Gelieve een bestand van maximaal 10MB te selecteren",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.ERROR.FILETYPE": "Gelieve een PNG of JPG bestand te selecteren",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.NAME.LABEL": "Naam",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.NAME.LABEL.REQUIRED": "Gelieve een naam in te vullen",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.DESCRIPTION.LABEL": "Beschrijving",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.DESCRIPTION.LABEL.REQUIRED": "Gelieve een beschrijving in te vullen",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.BUTTON.SAVE": "Opslaan",

	"FLOWS.REPORT.SETTINGS.WIDGET.PREVIEW.TITLE": "Widget voorbeeld",
	"FLOWS.REPORT.SETTINGS.WIDGET.PREVIEW.DESCRIPTION":
		"Dit is een voorbeeld hoe de flow tegel eruit zal zien op je website.",

	"FLOWS.REPORTS.CONFIGURATION.FALLBACK.TITLE": "Personaliseer jouw flow",
	"FLOWS.REPORTS.CONFIGURATION.FALLBACK.DESCRIPTION":
		"Wil je meer gegevens ophalen of vind je de flow net te uitgebreid? Binnenkort kan je onze standaard flows bewerken naar jouw voorkeur. Neem contact op met ons support team om als eerste toegang te krijgen van deze functie!",
	"FLOWS.REPORTS.CONFIGURATION.FORM.SUBMIT": "Opslaan",
	"flows.report.overview.bulk_action.button.delete": "Verwijderen",
	"flows.report.overview.bulk_action.modal.delete_reports.cancel": "Annuleren",
	"flows.report.overview.bulk_action.modal.delete_reports.confirm":
		"Verwijder {totalReportsSelected, plural, one {1 rapport} other {# rapporten}}",
	"flows.report.overview.bulk_action.modal.delete_reports.failed.description":
		"Momenteel kunnen we deze rapporten niet verwijderen, probeer het later opnieuw.",
	"flows.report.overview.bulk_action.modal.delete_reports.failed.title": "Bulk verwijderen mislukt",
	"flows.report.overview.bulk_action.modal.delete_reports.help":
		"Typ {value} in hoofdletters om rapporten te verwijderen",
	"flows.report.overview.bulk_action.modal.delete_reports.info_message":
		"Je staat op het punt {totalReportsSelected, plural, one {1 rapport} other {# rapporten}} te verwijderen. Deze actie is onomkeerbaar en zal alle rapportgegevens permanent verwijderen.",

	"FLOW.REPORTS.CONFIGURATION.UPDATE.SUCCESS.TITLE": "Flow aangepast",
	"FLOW.REPORTS.CONFIGURATION.UPDATE.FAILED.TITLE": "Flow kon niet geüpdatet worden",
	"FLOW.REPORTS.CONFIGURATION.UPDATE.FAILED.DESCRIPTION": "Probeer later opnieuw of contacteer onze customer support",
	"FLOW.REPORTS.CONFIGURATION.FETCH_FAILED.TITLE": "Configuratie kon niet opgehaald worden",
	"FLOW.REPORTS.CONFIGURATION.FETCH_FAILED.BUTTON": "Opnieuw proberen",

	"FLOWS.DETAIL.SETTINGS.DELETE_SUCCEEDED.TITLE": "Je flow is succesvol verwijderd",
	"FLOWS.DETAIL.SETTINGS.DELETE_FAILED.TITLE": "De flow kon niet verwijderd worden. Probeer later opnieuw.",

	"FLOWS.REPORT_DETAIL.FETCH_FAILED.TITLE": "Flow kon niet opgehaald worden",

	"FLOWS.REPORT_DETAIL.PDF.FAILED.TITLE": "Flow pdf kon niet gegenereerd worden",
	"FLOWS.REPORT_DETAIL.PDF.FAILED.DESCRIPTION": "Probeer later opnieuw of contacteer onze customer support",

	"FLOWS.REPORT_DETAIL.EMPTY_REPORT.TITLE": "{username} is nog niet begonnen met het rapport in te vullen.",
	"FLOWS.REPORT_DETAIL.VIEWER.FOOTER.CANCEL": "Annuleren",
	"FLOWS.REPORT_DETAIL.VIEWER.FOOTER.SAVE": "Opslaan",

	"FLOWS.REPORT_DETAIL.ACTIONS.TITLE": "Acties",
	"FLOWS.REPORT_DETAIL.ACTIONS.EDIT": "Bewerken",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOADEN": "Downloaden",
	"FLOWS.REPORT_DETAIL.ACTIONS.SAVE_TO_CONTACT": "Opslaan in contact",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS": "Bijlagen downloaden",
	"FLOWS.REPORT_DETAIL.ACTIONS.REMINDER": "Reminder sturen",
	"FLOWS.REPORT_DETAIL.ACTIONS.DELETE": "Verwijderen",

	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.SUCCEEDED.TITLE": "Rapport gedownload",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.FAILED.TITLE": "Rapport kon niet gedownload worden",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.FAILED.DESCRIPTION":
		"Probeer later opnieuw of contacteer onze customer support",

	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.SUCCEEDED.TITLE": "Bijlages gedownload",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.FAILED.TITLE":
		"Bijlages konden niet gedownload worden",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.FAILED.DESCRIPTION":
		"Probeer later opnieuw of contacteer onze customer support",

	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.NO_RESULT.TITLE": "Geen bijlages gevonden",

	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.TITLE": "Rapport verwijderen",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.DESCRIPTION":
		"Ben je er zeker van dat je dit rapport wil verwijderen? Deze actie kan niet ongedaan gemaakt worden.",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.LABEL.BUTTON_LABEL": "Rapport verwijderen",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.SENT.WARNING.TITLE": "Flow reeds uitgestuurd",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.SENT.WARNING.DESCRIPTION":
		"Wanneer u dit rapport verwijdert, zal de klant het rapport niet meer kunnen invullen",

	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.SUCCEEDED.TITLE": "Rapport verwijderd",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.FAILED.TITLE": "Rapport kon niet verwijderd worden",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.FAILED.DESCRIPTION": "Probeer later opnieuw of contacteer onze customer support",

	"FLOWS.REPORT_DETAIL.LIST.FILTER.TITLE":
		"{total, plural, =0 {Geen actieve filters} one {# actieve filter} other {# actieve filters}}",
	"FLOWS.REPORT_DETAIL.LIST.FILTER.SEARCH.LABEL": "Zoek op contact",
	"FLOWS.REPORT_DETAIL.LIST.FILTER.SEARCH.PLACEHOLDER": "Zoek op de naam van het contact of het e-mailadres.",
	"FLOWS.REPORT_DETAIL.LIST.FILTER.STATUS.LABEL": "Status",
	"FLOWS.REPORT_DETAIL.LIST.FILTER.STATUS.PLACEHOLDER": "Alle open statussen",
	"FLOWS.REPORT_DETAIL.LIST.FILTER.PROGRESS.LABEL": "Progressie",
	"FLOWS.REPORT_DETAIL.LIST.FILTER.PROGRESS.PLACEHOLDER": "Alle progressie niveaus",
	"flows.report_detail.list.filter.account_manager.placeholder": "Toon reporten van iedereen",
	"flows.report_detail.list.filter.account_manager.label": "Filter op dossierbeheerder",

	"flows.report_detail.list.filter.labels.title": "Filter op contact label",
	"flows.report_detail.list.filter.labels.placeholder": "Filter op contact label",
	
	"FLOWS.REPORT_DETAIL.UPDATE.SUCCESS.TITLE": "Rapport geüpdatet",
	"FLOWS.REPORT_DETAIL.UPDATE.FAILED.TITLE": "Rapport kon niet geüpdatet worden",
	"FLOWS.REPORT_DETAIL.UPDATE.FAILED.DESCRIPTION": "Probeer later opnieuw of contacteer onze customer support",

	"FLOWS.SEND.INPUT.WARNING.TITLE":
		"Opgelet: de link werkt enkel wanneer je de e-mail langs deze weg naar je klanten verstuurt.",

	"CONTACTS.OVERVIEW.ERROR.TITLE": "Contacten konden niet opgehaald worden",
	"CONTACTS.OVERVIEW.EMPTY.TITLE": "Geen contacten gevonden",
	"CONTACTS.OVERVIEW.ASIDE.OVERVIEW": "Contacten overzicht",
	"CONTACTS.OVERVIEW.ASIDE.ARCHIVE": "Contacten archief",

	"CONTACTS.OVERVIEW.SELECT.TYPE.TITLE": "Filter op contacttype",
	"CONTACTS.OVERVIEW.SELECT.TYPE.PLACEHOLDER": "Filter op contacttype",
	"contact.overview.select.type.customer": "Klant",
	"contact.overview.select.type.prospect": "Prospect",
	"contact.overview.select.type.other": "Andere",
	"contact.overview.select.type.anonymous": "Anoniem contact",
	"contact.overview.select.type.linked_contact": "Gelinkt contact",

	"contact.type.customer": "Klant",
	"contact.type.prospect": "Prospect",
	"contact.type.other": "Andere",
	"contact.type.anonymous": "Anoniem contact",
	"contact.type.linked_contact": "Gelinkt contact",
	"contact.type.address_book_contact": "Adres boek",

	"contact.conversation.participant_status.non_participant": "Je bent geen deelnemer van deze conversatie.",
	"contact.conversation.participant_status.participant": "Je bent een deelnemer van deze conversatie.",
	"contact.conversation.thread.switch.all": "Alle berichten",
	"contact.conversation.thread.switch.all.disabled.tooltip": "Deze conversatie bevat slechts één e-mail.",
	"contact.conversation.thread.switch.latest_email": "Nieuwste E-mail",
	"contact.conversation_list.all_conversations.fetched": "Alle conversaties opgehaald 📭",
	"contact.conversation_list.all_conversations.fetched.description":
		"Bericht dat de gebruiker het einde van de lijst met conversaties heeft bereikt",
	"contact.conversation_list.conversation.assigned_to.label": "Toegewezen aan",
	"contact.conversation_list.conversation.attachments.label": "{count, plural, one {Bijlage} other {Bijlagen}}",
	"contact.conversation_list.conversation.channel.label": "{count, plural, one {Kanaal} other {Kanalen}}",
	"contact.conversation_list.conversation.labels.label": "{count, plural, one {Label} other {Labels}}",
	"contact.conversation_list.conversation.participants.label": "{count, plural, one {Deelnemer} other {Deelnemers}}",
	"contact.conversation_list.empty_list.label": "Geen conversaties gevonden",
	"inbox.conversation_list.search.relevancy_type.most_recent.title": "recent",
	"inbox.conversation_list.search.relevancy_type.most_relevant.title": "relevant",
	"inbox.conversation_list.search.relevancy_type.title": "Zoek op meest",
	"contact.conversation_list.filters.btn.hide": "Verberg filters",
	"contact.conversation_list.filters.btn.show": "Toon filters",
	"contact.conversation_list.item.open_in_inbox.btn.disabled.tooltip":
		"Je bent geen deelnemer, je kunt deze conversatie niet zien in je inbox.",
	"contact.conversation_list.item.open_in_inbox.btn.tooltip": "Open conversatie in inbox.",
	"contact.conversation_list.results.count":
		"{count, plural, one {<b>#</b> conversatie} other {<b>#</b> conversaties}} gevonden",
	"contact.conversation_list.separator.search": "Zoekmodus (meest relevant eerst)",
	"contact.conversation_list.separator.search.description":
		"Tekst die de gebruiker ziet als titel vóór alle conversaties. Wanneer niet wordt gezocht, zal dit bijvoorbeeld 'vandaag' of 'morgen' zijn. Bij zoeken geven we aan dat het is gesorteerd op relevantie.",
	"contact.conversation_list.forbidden.organization_channel_conflict.title": "Geen toegang!",
	"contact.conversation_list.forbidden.organization_channel_conflict.sub_title":
		"U hebt geen toegang tot de gesprekken vanwege privacyredenen. Één of meerdere e-mailadressen van dit contact komen overeen met verbonden kanalen binnen deze organisatie. Voor verdere hulp kunt u contact opnemen met onze supportafdeling.",

	"contact.conversations.filter.form.from_date.label": "Vanaf datum",
	"contact.conversations.filter.form.has_attachments.label": "Heeft bijlagen",
	"contact.conversations.filter.form.inboxLabels": "Met labels",
	"contact.conversations.filter.form.inboxLabels.label": "Met labels",
	"contact.conversations.filter.form.q": "Zoek naar onderwerp, inhoud, e-mailadres, ...",
	"contact.conversations.filter.form.q.label": "Zoeken",
	"contact.conversations.filter.form.until.label": "Tot en met",

	"contact.existing_contact_display.button.go_to_contact": "Ga naar contact",
	"contact.import.admin_pulse.filter_import.details.allow_create.label": "Wil je nieuwe contacten toevoegen?",
	"contact.import.admin_pulse.filter_import.details.allow_update.label": "Wil je bestaande contacten bijwerken?",
	"contact.import.admin_pulse.filter_import.details.title": "Importeer details",
	"contact.import.admin_pulse.filter_import.details.toggle.no": "Nee",
	"contact.import.admin_pulse.filter_import.details.toggle.yes": "Ja",
	"contact.import.admin_pulse.filter_import.fetch_failed.description":
		"We konden geen contacten ophalen uit Admin Pulse. Probeer het later opnieuw. Als het probleem aanhoudt, neem dan contact op met de ondersteuning.",
	"contact.import.admin_pulse.filter_import.fetch_failed.title": "Het ophalen van je contacten is mislukt",
	"contact.import.admin_pulse.filter_import.footer.cancel": "Import annuleren",
	"contact.import.admin_pulse.filter_import.footer.next": "Volgende",
	"contact.import.admin_pulse.filter_import.header.crm_type.label": "Selecteer CRM-type",
	"contact.import.admin_pulse.filter_import.header.crm_type.option.customer": "Klant",
	"contact.import.admin_pulse.filter_import.header.crm_type.option.other": "Overig",
	"contact.import.admin_pulse.filter_import.header.crm_type.option.own_company": "Eigen bedrijf",
	"contact.import.admin_pulse.filter_import.header.crm_type.option.prospect": "Prospect",
	"contact.import.admin_pulse.filter_import.header.crm_type.placeholder": "Selecteer CRM-type",
	"contact.import.admin_pulse.filter_import.header.search.label": "Zoeken op contact",
	"contact.import.admin_pulse.filter_import.header.search.placeholder": "Zoeken op naam of e-mail",
	"contact.import.admin_pulse.filter_import.header.title": "Filter contacten",
	"contact.import.admin_pulse.filter_import.header.type.label": "Filter op contacttype",
	"contact.import.admin_pulse.filter_import.header.type.option.company": "Bedrijf",
	"contact.import.admin_pulse.filter_import.header.type.option.person": "Persoon",
	"contact.import.admin_pulse.filter_import.header.type.option.sole_proprietor": "Eenmanszaak",
	"contact.import.admin_pulse.filter_import.header.type.placeholder": "Selecteer contacttype",
	"contact.import.admin_pulse.filter_import.info":
		"We tonen slechts een voorbeeld van de eerste 100 contacten die worden geïmporteerd. In de volgende stap kun je alle geïmporteerde contacten bekijken en verfijnen welke geïmporteerd moeten worden.",
	"contact.import.admin_pulse.filter_import.preview.title": "Importvoorbeeld",
	"contact.import.admin_pulse.filter_import.stats.total": "Gevonden contacten",
	"contact.import.admin_pulse.filter_import.table.column.email": "E-mail",
	"contact.import.admin_pulse.filter_import.table.column.name": "Naam",
	"contact.import.admin_pulse.filter_import.table.column.phone": "Telefoon",
	"contact.import.admin_pulse.filter_import.table.empty_text": "Geen contacten gevonden",
	"contact.import.admin_pulse.review_import.analyze.in_progress.complete": "Contactanalyse is voltooid",
	"contact.import.admin_pulse.review_import.analyze.in_progress.label": "Contactanalyse is bezig",
	"contact.import.admin_pulse.review_import.analyze_failed.button": "Opnieuw proberen",
	"contact.import.admin_pulse.review_import.analyze_failed.description":
		"We konden je import niet analyseren. Probeer het later opnieuw met andere instellingen.",
	"contact.import.admin_pulse.review_import.analyze_failed.title": "Import kon niet worden geanalyseerd",
	"contact.import.admin_pulse.review_import.footer.back": "Terug",
	"contact.import.admin_pulse.review_import.footer.import": "Contacten importeren",
	"contact.import.admin_pulse.review_import.import_failed.button": "Opnieuw proberen",
	"contact.import.admin_pulse.review_import.import_failed.description":
		"De import is mislukt. Probeer het later opnieuw met andere instellingen. Als het probleem aanhoudt, neem dan contact op met de ondersteuning.",
	"contact.import.admin_pulse.review_import.import_failed.title": "Import mislukt",
	"contact.import.admin_pulse.review_import.is_importing.button": "Venster sluiten",
	"contact.import.admin_pulse.review_import.is_importing.description":
		"De import is bezig in de achtergrond. Je kunt dit venster sluiten en doorgaan met werken.",
	"contact.import.admin_pulse.review_import.is_importing.title": "Import gestart",
	"contact.import.admin_pulse.review_import.labels.general.help":
		"Tip: met labels kun je alle contacten van een specifieke import vinden en bewerken",
	"contact.import.admin_pulse.review_import.labels.general.label": "Label toevoegen aan contacten",
	"contact.import.admin_pulse.review_import.labels.title": "Labels toevoegen",
	"contact.import.admin_pulse.review_import.loading": "We analyseren je import, dit kan even duren",
	"contact.import.admin_pulse.review_import.table.column.email": "E-mail",
	"contact.import.admin_pulse.review_import.table.column.first_name": "Voornaam",
	"contact.import.admin_pulse.review_import.table.column.last_name": "Achternaam",
	"contact.import.admin_pulse.review_import.table.column.phone": "Telefoon",
	"contact.import.admin_pulse.review_import.title": "Overzicht import",
	"contact.import.admin_pulse.review_import.toolbar.title": "Importvoorbeeld",

	"contact.import.admin_pulse.review_import.filters.show_all": "Toon alles",
	"contact.import.admin_pulse.review_import.filters.warnings": "Toon Waarschuwingen",
	"contact.import.admin_pulse.review_import.filters.errors": "Toon fouten",

	"contact.import.analytics.analytics.error.description": "Contacten met fouten worden niet geïmporteerd",
	"contact.import.analytics.analytics.error.title": "Import fouten",
	"contact.import.analytics.analytics.warning.description":
		"Bij dubbels wordt slechts één contact geïmporteerd; beide kunnen worden overgeslagen.",
	"contact.import.analytics.analytics.warning.title": "Import waarschuwingen",
	"contact.import.analytics.error.invalid_email": "Import bevat één of meer ongeldige e-mailadressen.",
	"contact.import.analytics.error.invalid_linked_contact_identifier":
		"Import bevat één of meer contacten waar de bestaande contactidentificatie (e-mail, telefoon, rijksregisternummer) ongeldig is.",
	"contact.import.analytics.error.invalid_nation_registration_number":
		"Import bevat één of meer ongeldige nationale registratienummers.",
	"contact.import.analytics.error.invalid_phone": "Import bevat één of meer ongeldige telefoonnummers.",
	"contact.import.analytics.error.missing_linked_contacts":
		"Import bevat enkele contacten die niet overeenkomen met een bestaand contact.",
	"contact.import.analytics.error.missing_name": "Import bevat één of meer contacten zonder naam.",
	"contact.import.analytics.error.no_identifier":
		"Import bevat geen contacten met een geldige identificatie (e-mail, telefoon, rijksregisternummer of unieke identificatie).",
	"contact.import.analytics.error.no_linked_contact_found":
		"Import bevat geen contacten die overeenkomen met bestaande contacten.",
	"contact.import.analytics.stats.description":
		"Dit zijn geschatte cijfers; de werkelijke import kan variëren op basis van eventuele waarschuwingen of fouten.",
	"contact.import.analytics.stats.errors": "Fouten",
	"contact.import.analytics.stats.new_contacts": "Nieuwe contacten",
	"contact.import.analytics.stats.update_contacts": "Contacten bijwerken",
	"contact.import.analytics.stats.warnings": "Waarschuwingen",
	"contact.import.analytics.warning.duplicate_email":
		"Import bevat twee of meerdere contacten met dezelfde e-mailadressen.",
	"contact.import.analytics.warning.duplicate_id": "Import bevat twee of meerdere contacten met dezelfde ids.",
	"contact.import.analytics.warning.duplicate_nation_registration_number":
		"Import bevat twee of meerdere contacten met hetzelfde rijksregisternummer.",
	"contact.import.analytics.warning.duplicate_phone":
		"Import bevat twee of meerdere contacten met hetzelfde telefoonnummer.",
	"contact.import.analytics.warning.unknown_account_manager":
		"Import bevat één of meer e-mails van accountmanagers die niet aan een lid zijn toegevoegd. Voeg deze leden eerst toe, anders worden ze overgeslagen bij de import.",
	"contact.import.csv.file_upload.choose_file.description": "Selecteer een CSV-bestand om te importeren",
	"contact.import.csv.file_upload.choose_file.title": "Kies bestand",
	"contact.import.csv.file_upload.details.allow_create.label": "Wil je nieuwe contacten toevoegen?",
	"contact.import.csv.file_upload.details.allow_update.label": "Wil je bestaande contacten bijwerken?",
	"contact.import.csv.file_upload.details.delimiter.help":
		"Wijzig deze waarde als de kolommen niet correct worden weergegeven in de volgende stap",
	"contact.import.csv.file_upload.details.delimiter.label": "Scheidingsteken",
	"contact.import.csv.file_upload.details.linked_contact_type.help": "Welk type gelinkte contacten importeer je?",
	"contact.import.csv.file_upload.details.linked_contact_type.label": "Type gelinkte contacten",
	"contact.import.csv.file_upload.details.title": "Importeer details",
	"contact.import.csv.file_upload.details.toggle.no": "Nee",
	"contact.import.csv.file_upload.details.toggle.yes": "Ja",
	"contact.import.csv.file_upload.error.failed":
		"Het CSV-bestand kan niet worden geüpload. Controleer je bestand op fouten en probeer het later opnieuw.",
	"contact.import.csv.file_upload.error.max_columns":
		"Het door jou geüploade CSV-bestand mag maximaal {maxColumns} kolommen bevatten.",
	"contact.import.csv.file_upload.error.max_rows":
		"Het door jou geüploade CSV-bestand mag maximaal {maxRows} rijen bevatten.",
	"contact.import.csv.file_upload.footer.cancel": "Import annuleren",
	"contact.import.csv.file_upload.footer.next": "Volgende",
	"contact.import.csv.file_upload.preview_file.label": "Geselecteerd bestand",
	"contact.import.csv.file_upload.title": "Upload importbestand",
	"contact.import.csv.file_upload.what_to_import.option.contacts.help":
		"Contacten worden geïmporteerd met behulp van e-mail, telefoon, rijksregisternummer of unieke identificatie. <link>Bekijk voorbeeldbestand</link>",
	"contact.import.csv.file_upload.what_to_import.option.contacts.label": "Contacten",
	"contact.import.csv.file_upload.what_to_import.option.linked_contacts.help":
		"Gelinkte contacten worden geïmporteerd en gekoppeld aan een bestaand contact met behulp van e-mail, telefoon, rijksregisternummer of unieke identificatie. <link>Bekijk voorbeeldbestand</link>",
	"contact.import.csv.file_upload.what_to_import.option.linked_contacts.label": "Gelinkte contacten",
	"contact.import.csv.map_fields.footer.back": "Terug",
	"contact.import.csv.map_fields.footer.next": "Volgende",
	"contact.import.csv.map_fields.header.enabled": "Importeren",
	"contact.import.csv.map_fields.header.from": "Van",
	"contact.import.csv.map_fields.header.to": "Naar",
	"contact.import.csv.map_fields.title": "Velden mappen",
	"contact.import.csv.map_fields.warning.identifier":
		"Je moet ten minste één geldige identificatie (e-mail, telefoon, rijksregisternummer of unieke identificatie) toewijzen om uw contacten te importeren.",
	"contact.import.csv.map_fields.warning.linked_contact":
		"Je moet ten minste één geldige identificatie (e-mail, telefoon, rijksregisternummer of unieke identificatie) van het bestaande contact toewijzen.",
	"contact.import.csv.map_fields.warning.name": "Je moet een naam toewijzen.",
	"contact.import.csv.map_fields.warning.title":
		"We missen enkele veldtoewijzingen om de contactimport voort te zetten",
	"contact.import.csv.review_import.analytics.labels.general.help":
		"Hint: met labels kan je alle contacten van een specifieke import vinden en bewerken",
	"contact.import.csv.review_import.analytics.labels.general.label": "Label aan contacten toevoegen",
	"contact.import.csv.review_import.analytics.labels.title": "Labels toevoegen",
	"contact.import.csv.review_import.analytics.switch_names.tooltip":
		"Let op: namen omwisselen werkt alleen als zowel de voornaam als de achternaam zijn toegewezen",
	"contact.import.csv.review_import.analytics.toolbar.split_name": "Namen splitsen",
	"contact.import.csv.review_import.analytics.toolbar.split_name.option.fl": "<Voornaam> <achternaam>",
	"contact.import.csv.review_import.analytics.toolbar.split_name.option.lf": "<Achternaam> <voornaam>",
	"contact.import.csv.review_import.analytics.toolbar.split_name.title": "In welke volgorde zijn de namen ingesteld",
	"contact.import.csv.review_import.analytics.toolbar.switch_name": "Namen omwisselen",
	"contact.import.csv.review_import.analytics.toolbar.title": "Importvoorbeeld",
	"contact.import.csv.review_import.analytics.toolbar.tooltip":
		"Namen splitsen werkt alleen als de volledige naam is toegewezen",
	"contact.import.csv.review_import.analyze_failed.button": "Probeer opnieuw",
	"contact.import.csv.review_import.analyze_failed.description":
		"We konden uw bestand niet analyseren. Controleer uw bestand op beschadigde of ontbrekende velden en probeer het opnieuw",
	"contact.import.csv.review_import.analyze_failed.title": "Import kon niet worden geanalyseerd",
	"contact.import.csv.review_import.footer.back": "Terug",
	"contact.import.csv.review_import.footer.import": "Contacten importeren",
	"contact.import.csv.review_import.footer.import.tooltip": "Bewerk en analyseer uw contacten opnieuw",
	"contact.import.csv.review_import.import_failed.button": "Probeer opnieuw",
	"contact.import.csv.review_import.import_failed.description":
		"De import is mislukt. Probeer het later opnieuw. Als het probleem aanhoudt, neem dan contact op met de ondersteuning.",
	"contact.import.csv.review_import.import_failed.title": "Import mislukt",
	"contact.import.csv.review_import.import_succeeded.button": "Import sluiten",
	"contact.import.csv.review_import.import_succeeded.description":
		"Al je contacten zijn geïmporteerd. Je kunt ze bekijken onder uw contacten",
	"contact.import.csv.review_import.import_succeeded.title": "Je contacten zijn geïmporteerd",
	"contact.import.csv.review_import.is_importing.description":
		"We zijn momenteel bezig met het importeren van uw contacten, dit proces kan enkele momenten duren.",
	"contact.import.csv.review_import.is_importing.title": "Import gestart",
	"contact.import.csv.review_import.loading": "We analyseren uw import, dit kan even duren",
	"contact.import.csv.review_import.re_analyze": "Contacten opnieuw analyseren",
	"contact.import.csv.review_import.title": "Import beoordelen",
	"contact.import.editor_table.row_footer.cancel": "Annuleren",
	"contact.import.editor_table.row_footer.save": "Opslaan",
	"contact.import.field.email": "E-mail",
	"contact.import.field.phone": "Telefoon",
	"contact.import.field.unique_identifier": "Unieke identificatie",
	"contact.import.field.national_registration_number": "Rijksregisternummer",
	"contact.import.field.account_manager": "Accountmanagers",
	"contact.import.field.address.title": "Adres",
	"contact.import.field.birthday": "Geboortedatum",
	"contact.import.field.bus": "Bus",
	"contact.import.field.card_number": "Kaartnummer",
	"contact.import.field.city": "Stad",
	"contact.import.field.country": "Land",
	"contact.import.field.eid.title": "EID",
	"contact.import.field.expiration_date": "Vervaldatum",
	"contact.import.field.first_name": "Voornaam",
	"contact.import.field.fullname": "Volledige naam",
	"contact.import.field.general_info.title": "Algemene informatie",
	"contact.import.field.iban_reimbursement": "IBAN nummer",
	"contact.import.field.last_name": "Achternaam",
	"contact.import.field.linked_contact.title": "Bestaand contact",
	"contact.import.field.middle_name": "Tweede naam",
	"contact.import.field.postal_code": "Postcode",
	"contact.import.field.salutation": "Aanhef",
	"contact.import.field.sex": "Geslacht",
	"contact.import.field.street_name": "Straatnaam",
	"contact.import.field.street_number": "Huisnummer",
	"contact.import.field.linked_to_contact.email": "E-mail (bestaand contact)",
	"contact.import.field.linked_to_contact.phone": "Telefoon (bestaand contact)",
	"contact.import.field.linked_to_contact.unique_identifier": "Unieke identificatie (bestaand contact)",
	"contact.import.field.linked_to_contact.national_registration_number": "Rijksregisternummer (bestaand contact)",
	"contact.import.rule_import_modal.add": "Regel toevoegen",
	"contact.import.rule_import_modal.condition.error.empty": {},
	"contact.import.rule_import_modal.condition.error.empty_fields": {},
	"contact.import.rule_import_modal.delete": "Regel verwijderen",
	"contact.import.rule_import_modal.description":
		"Als een contact overeenkomt met je regel, wordt het gekozen label aan dit contact toegevoegd.",
	"contact.import.rule_import_modal.label.error.required": "Voer een label in",
	"contact.import.rule_import_modal.label.help":
		"Hint: met labels kan je alle contacten van een specifieke import vinden en bewerken",
	"contact.import.rule_import_modal.label.title": "Label",
	"contact.import.rule_import_modal.title": "Regel toevoegen",
	"contact.import.rules_table.eid_expiration_date.fixed_value": "dag van import",
	"contact.import.rules_table.empty": "Voorwaarde",
	"contact.import.rules_table.label": "Label",
	"contact.import.rules_table.label.content": "Voeg <label>{labelName}</label> toe",
	"contact.import.rules_table.labels.add_rule": "Regel toevoegen",
	"contact.import.rules_table.rule":
		"{type, select, if {Als} or {of} and {en} other {}} <bold>{field}</bold> {value}",
	"contact.import.rules_table.rule.equal_to": "Is gelijk aan <bold>{input}</bold>",
	"contact.import.rules_table.rule.greater_than": "is groter dan <bold>{input}</bold>",
	"contact.import.rules_table.rule.greater_than_or_equal": "kleiner dan of gelijk aan <bold>{input}</bold>",
	"contact.import.rules_table.rule.is_defined": "is gedefinieerd",
	"contact.import.rules_table.rule.less_than": "is minder dan <bold>{input}</bold>",
	"contact.import.rules_table.rule.less_than_or_equal": "groter dan of gelijk aan <bold>{input}</bold>",
	"contact.overview.bulk_action.delete.button.force_delete": "Effectief verwijderen",
	"contact.overview.bulk_action.delete.button.force_delete.tooltip":
		"Verwijder {count, plural, one {# contact} other {# contacten}} onmiddellijk.",
	"contact.overview.bulk_action.delete.button.soft_delete": "Zachte verwijdering",
	"contact.overview.bulk_action.delete.button.soft_delete.tooltip":
		"Je hebt {count, plural, one {# contact} other {# contacten}} die al verwijderd zijn binnen je selectie. Je kunt geen verwijderactie uitvoeren op contacten die al verwijderd zijn.",
	"contact.overview.bulk_action.force_delete.value": "EFFECTIEF VERWIJDEREN",
	"contact.overview.bulk_action.force_delete_modal.cancel": "Annuleren",
	"contact.overview.bulk_action.force_delete_modal.confirm":
		"Verwijder {total, plural, one {1 contact} other {# contacten}}",
	"contact.overview.bulk_action.force_delete_modal.description":
		"<bold>We raden aan om zachte verwijdering te gebruiken</bold>, dit geeft je 90 dagen voordat je gegevens effectief zullen worden verwijderd.",
	"contact.overview.bulk_action.force_delete_modal.explain.contacts":
		"We zullen de contacten en alle gekoppelde gegevens verwijderen",
	"contact.overview.bulk_action.force_delete_modal.explain.files":
		"We zullen alle bestanden verwijderen van {total, plural, one {dit contact} other {deze contacten}}",
	"contact.overview.bulk_action.force_delete_modal.explain.linked_contact":
		"We zullen alle gekoppelde contacten verwijderen van {total, plural, one {dit contact} other {deze contacten}}",
	"contact.overview.bulk_action.force_delete_modal.explain.reports":
		"We zullen alle rapporten verwijderen van {total, plural, one {dit contact} other {deze contacten}}",
	"contact.overview.bulk_action.force_delete_modal.title": "Contact effectief verwijderen",
	"contact.overview.select.group.hidden": "Verborgen types",
	"contact.overview.select.hidden_option.tooltip": "Deze contacten zijn standaard verborgen in het overzicht",
	"contact.overview.select.type.anonymous": "Anoniem",

	"contact.overview.bulk_action.force_delete_modal.info_message":
		"Je staat op het punt om {total, plural, one {1 contact} other {# contacts}} te verwijderen. Deze actie is onomkeerbaar en zal alle gegevens voor altijd verwijderen.",

	"contact.overview.select.type.customer": "klant",
	"contact.overview.select.type.linked_contact": "gekoppelde contacten",
	"contact.overview.select.type.other": "andere",
	"contact.overview.select.type.prospect": "prospect",
	"contact.type.address_book_contact": "Adresboek",
	"contact.type.anonymous": "Anoniem",
	"contact.type.anonymous.title": "Anoniem",
	"contact.type.customer": "klant",
	"contact.type.customer.title": "Klant",
	"contact.type.linked_contact": "gekoppelde contacten",
	"contact.type.linked_contact.title": "Gekoppeld contact",
	"contact.type.other": "andere",
	"contact.type.other.title": "Andere",
	"contact.type.prospect": "prospect",
	"contact.type.prospect.title": "Prospect",

	"contact_import.steps.csv.get_data": "Bestand importeren",
	"contact_import.steps.csv.import_preview": "Import voorbeeld",
	"contact_import.steps.csv.map_files": "Velden mappen",
	"contact_import.steps.integration.preview_import": "Voorbeeld",
	"contact_import.steps.integration.analyze": "Analyseren",
	"contact_import.title": "Contact importeren",
	"contact_label_selector.add_label": "Label toevoegen",
	"contact_label_selector.empty": "Geen labels gevonden",
	"contact_linking.type.child": "kind",
	"contact_linking.type.dependent": "afhankelijke",
	"contact_linking.type.guardian": "voogd",
	"contact_linking.type.lawyer": "advocaat",
	"contact_linking.type.notary": "notaris",
	"contact_linking.type.other": "andere",
	"contact_linking.type.parent": "ouder",
	"contact_linking.type.partner": "partner",
	"contact_linking.type.sibling": "broer/zus",

	"contacts.detail.PROPERTIES_MODAL.form.building_type.error_required": "Selecteer alsjeblieft een gebouwtype",
	"contacts.detail.PROPERTIES_MODAL.form.building_type.label": "Gebouwtype",
	"contacts.detail.PROPERTIES_MODAL.form.cadastral_income.label": "Kadastraal inkomen",
	"contacts.detail.PROPERTIES_MODAL.form.has_re_mvp_split.label":
		"Verdeling tussen onroerend goed en roerende huur in contract",
	"contacts.detail.PROPERTIES_MODAL.form.is_co_ownership.label": "Is mede-eigendom",
	"contacts.detail.PROPERTIES_MODAL.form.is_rented_out.label": "Is verhuurd",
	"contacts.detail.PROPERTIES_MODAL.form.is_sold.label": "Is verkocht",
	"contacts.detail.PROPERTIES_MODAL.form.name.help": "Geef het pand een unieke naam om het te identificeren",
	"contacts.detail.PROPERTIES_MODAL.form.name.label": "Naam",
	"contacts.detail.PROPERTIES_MODAL.form.name.placeholder": "Vakantiehuis Spanje",
	"contacts.detail.PROPERTIES_MODAL.form.original_value.help": "Voer de oorspronkelijke waarde van dit pand in.",
	"contacts.detail.PROPERTIES_MODAL.form.original_value.label": "Oorspronkelijke waarde",
	"contacts.detail.PROPERTIES_MODAL.form.ownership_type.error_required": "Selecteer alsjeblieft een eigendomstype",
	"contacts.detail.PROPERTIES_MODAL.form.ownership_type.label": "Eigendomstype",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_movable_property.label": "Percentage roerend goed",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_private_rented.label": "Percentage privé verhuurd",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_professionally_rented.label": "Percentage professioneel verhuurd",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_real_estate.label": "Percentage onroerend goed",
	"contacts.detail.PROPERTIES_MODAL.form.possible_rent_amount.help": "Voer het mogelijke huurbedrag van dit pand in.",
	"contacts.detail.PROPERTIES_MODAL.form.possible_rent_amount.label": "Mogelijk huurbedrag",
	"contacts.detail.PROPERTIES_MODAL.form.property_value.help": "Voer de waarde van dit pand in.",
	"contacts.detail.PROPERTIES_MODAL.form.property_value.label": "Pandwaarde",
	"contacts.detail.PROPERTIES_MODAL.form.purchase_date.label": "Aankoopdatum",
	"contacts.detail.PROPERTIES_MODAL.form.renovation_costs.label": "Renovatiekosten",
	"contacts.detail.PROPERTIES_MODAL.form.rent_amount.help": "Voer het huurbedrag van dit pand in.",
	"contacts.detail.PROPERTIES_MODAL.form.rent_amount.label": "Huurbedrag",
	"contacts.detail.PROPERTIES_MODAL.form.rent_type.error_required": "Selecteer alsjeblieft een huurtype",
	"contacts.detail.PROPERTIES_MODAL.form.rent_type.help": "Huurtype van dit pand.",
	"contacts.detail.PROPERTIES_MODAL.form.rent_type.label": "Huurtype",
	"contacts.detail.PROPERTIES_MODAL.form.rented_with_furniture.label": "Verhuurd met meubels",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.error": "Verkoopdatum kan niet voor de aankoopdatum liggen",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.help": "Voer de verkoopdatum van dit pand in.",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.label": "Verkoopdatum",
	"contacts.detail.PROPERTIES_MODAL.form.type.error_required": "Selecteer alsjeblieft een eigendomstype",
	"contacts.detail.PROPERTIES_MODAL.form.type.label": "Eigendomstype",
	"contacts.detail.attributes.detail.error.duplicate":
		"Deze unique identifier is al toegevoegd aan het volgende contact in jouw organisatie.",
	"contacts.detail.attributes.email_addresses.error.duplicate":
		"Dit e-mailadres is al toegevoegd aan het volgende contact in jouw organisatie.",
	"contacts.detail.attributes.phone.error.duplicate":
		"Dit telefoonnummer is al toegevoegd aan het volgende contact in jouw organisatie.",

	"contacts_label_modal.system_label.warning":
		"Dit is een systeemlabel dat door ons is aangemaakt. Het kan niet worden gewijzigd.",
	"CONTACTS.OVERVIEW.SELECT.LABELS.TITLE": "Filter op label",
	"CONTACTS.OVERVIEW.SELECT.LABELS.PLACEHOLDER": "Filter op label",

	"CONTACTS.OVERVIEW.SELECT.ACCOUNT_MANAGER.TITLE": "Filter op dossierbeheerder",
	"CONTACTS.OVERVIEW.SELECT.ACCOUNT_MANAGER.PLACEHOLDER": "Filter op dossierbeheerder",
	"CONTACTS.OVERVIEW.SELECT.ACCOUNT_MANAGER.NOT_FOUND": "Geen teamleden gevonden",

	"CONTACT.OVERVIEW.SEARCH.TITLE": "Zoek op contact",
	"CONTACT.OVERVIEW.SEARCH.PLACEHOLDER":
		"Zoek op naam, e-mailadres, telefoonnummer, rijksregisternummer of unieke referentie",

	"CONTACTS.OVERVIEW.SHOW_ARCHIVED.TITLE": "Toon gearchiveerde contacten",
	"CONTACT.OVERVIEW.SHOW_DELETED.TITLE": "Toon verwijderde contacten",

	"CONTACTS.OVERVIEW.ADD.MODAL.TITLE": "Nieuw contact toevoegen",
	"CONTACTS.OVERVIEW.ADD.MODAL.TYPE": "Contacttype",
	"CONTACTS.OVERVIEW.ADD.MODAL.TYPE.REQUIRED": "Gelieve een contacttype te selecteren",
	"CONTACTS.OVERVIEW.ADD.MODAL.FIRSTNAME": "Voornaam",
	"CONTACTS.OVERVIEW.ADD.MODAL.FIRSTNAME.REQUIRED": "Gelieve een voornaam in te vullen",
	"CONTACTS.OVERVIEW.ADD.MODAL.LASTNAME": "Familienaam",
	"CONTACTS.OVERVIEW.ADD.MODAL.LASTNAME.REQUIRED": "Gelieve een familienaam in te vullen",
	"CONTACTS.OVERVIEW.ADD.MODAL.EMAIL": "E-mailadres",
	"CONTACTS.OVERVIEW.ADD.MODAL.EMAIL.REQUIRED": "Gelieve een geldige e-mailadres in te vullen",
	"CONTACTS.OVERVIEW.ADD.MODAL.NATIONALREGISTRATIONNUMBER": "Rijksregisternummer",
	"CONTACTS.OVERVIEW.ADD.MODAL.NATIONALREGISTRATIONNUMBER.REQUIRED": "Gelieve een rijksregisternummer in te vullen",
	"CONTACTS.OVERVIEW.ADD.MODAL.LABELS": "Label",
	"CONTACTS.OVERVIEW.ADD.MODAL.LABELS.EMPTY": "Geen labels gevonden",
	"CONTACTS.OVERVIEW.ADD.MODAL.LABELS.EMPTY.BUTTON": "Klik hier om een label aan te maken",

	"contacts_import.validate.form.full_name.label": "Naam",
	"contacts_import.validate.form.full_name.error_required": "Naam is verplicht",

	"CONTACT.OVERVIEW.ADD.MODAL.SUCCESS": "Contact succesvol toegevoegd",
	"CONTACT.OVERVIEW.ADD.MODAL.FAILED": "Contact kon niet toegevoegd worden",
	"CONTACT.OVERVIEW.ADD.MODAL.FAILED.DESCRIPTION": "Verifieer of er reeds een contact bestaat met dit e-mailadres",
	"CONTACT.OVERVIEW.ADD.MODAL.FAILED.DUPLICATE": "Contact met dit {field} bestaat al",
	"CONTACT.OVERVIEW.ADD.MODAL.FAILED.DUPLICATE.DESCRIPTION": "Gelieve een ander {field} in te vullen",

	"CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.TITLE": "Contacten verwijderen",
	"CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.OPTION.ARCHIVE":
		"Archiveer {count, plural, one {contact} other {contacten}} in plaats van verwijderen",
	"CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.BUTTON": "Verwijderen",

	"CONTACTS.OVERVIEW.DELETE.ALL.MODAL.TITLE": "Contacten verwijderen",
	"CONTACTS.OVERVIEW.DELETE.ALL.MODAL.BUTTON": "Alle contacten verwijderen",

	"CONTACTS.DETAIL.STATE.DELETED": "Dit contact is momenteel verwijderd.",
	"CONTACTS.DETAIL.STATE.ARCHIVED": "Dit contact is momenteel geärchiveerd.",

	"CONTACTS.DETAIL.STATE.ARCHIVED.UNARCHIVE.SUCCESS": "Contact succesvol gedearchiveerd.",
	"CONTACTS.DETAIL.STATE.ARCHIVED.UNARCHIVE.ERROR": "Kon contact niet dearchiveren.",
	"CONTACTS.DETAIL.STATE.DELETED.RESTORE.SUCCESS": "Contact succesvol hersteld.",
	"CONTACTS.DETAIL.STATE.DELETED.RESTORE.ERROR": "Kon contact niet herstellen.",

	"CONTACTS.DETAIL.ACCOUNT_MANAGER.TITLE": "Dossierbeheerders",
	"CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.TITLE": "Dossierbeheerder toevoegen",
	"CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.ADD.FAILED": "Dossierbeheerder kon niet toegevoegd worden",
	"CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.REMOVE.FAILED": "Dossierbeheerder kon niet verwijderd worden",
	"CONTACTS.DETAIL.ADD.ACCOUNT_MANAGER": "Dossierbeheerder toevoegen",

	"CONTACTS.DETAIL.LABELS.TITLE": "Labels",
	"CONTACTS.DETAIL.MODAL.LABELS.TITLE": "Labels toevoegen",
	"CONTACTS.DETAIL.MODAL.LABELS.DIVIDER": "Toegevoegde labels",
	"CONTACTS.DETAIL.MODAL.LABELS.ADD.FAILED": "Label kon niet toegevoegd worden",
	"CONTACTS.DETAIL.MODAL.LABELS.REMOVE.FAILED": "Label kon niet verwijderd worden",
	"CONTACTS.DETAIL.MODAL.LABELS.ADD": "Label toevoegen",

	"CONTACTS.DETAIL.ATTRIBUTES.MIDDLENAME": "Tussenvoegsel",
	"CONTACTS.DETAIL.ATTRIBUTES.SALUTATION": "Aanhef",
	"CONTACTS.DETAIL.ATTRIBUTES.SALUTATION.PLACEHOLDER": "Mevrouw",
	"CONTACTS.DETAIL.ATTRIBUTES.SEX": "Geslacht",
	"CONTACTS.DETAIL.ATTRIBUTES.SEX.PLACEHOLDER": "Vrouw",
	"CONTACTS.DETAIL.ATTRIBUTES.NATIONALREGISTRATIONNUMBER": "Rijksregisternummer",
	"CONTACTS.DETAIL.ATTRIBUTES.BIRTHDATE": "Geboortedatum",
	"CONTACTS.DETAIL.ATTRIBUTES.LANGUAGE": "Taal",

	"LANGUAGE.ENGLISH": "Engels",
	"LANGUAGE.DUTCH": "Nederlands",
	"LANGUAGE.FRENCH": "Frans",

	"BUTTON.SAVE": "Opslaan",

	"CONTACTS.DETAIL.ATTRIBUTES.SAVE.SUCCESS": "Contact succesvol opgeslagen",
	"CONTACTS.DETAIL.ATTRIBUTES.SAVE.FAILED": "Contactinfo kon niet bijgewerkt worden",
	"CONTACTS.DETAIL.ATTRIBUTES.SAVE.FAILED.DUPLICATE.DESCRIPTION":
		"De inhoud van dit veld moet uniek zijn in deze organisatie",

	"CONTACTS.DETAIL.ADDRESSES.TITLE": "Adressen",
	"CONTACT.DETAIL.ATTRIBUTES.ADD.ADDRESS": "Adres toevoegen",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.STREETNAME": "Straatnaam",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.STREETNAME.REQUIRED": "Gelieve een straatnaam in te vullen",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.STREETNUMBER": "Huisnummer",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.STREETNUMBER.REQUIRED": "Gelieve een huisnummer in te vullen",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.CITY": "Stad",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.CITY.REQUIRED": "Gelieve een stad in te vullen",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.BUS": "Bus",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PROVINCE": "Provincie",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PROVINCE.REQUIRED": "Gelieve een provincie in te vullen",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.COUNTRY": "Land",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.COUNTRY.REQUIRED": "Gelieve een land aan te duiden",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.POSTALCODE": "Postcode",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.POSTALCODE.REQUIRED": "Gelieve een postcode in te vullen",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PRIMARY": "Aanduiden als primair adres",

	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PRIMARY.CAUTION.TITLE": "Primair adres",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PRIMARY.CAUTION.DESCRIPTION":
		"Opgelet, u had reeds een primair adres ingesteld voor dit contact, wanneer u dit adres primair maakt zal het andere niet primair worden",

	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.SUCCESS": "Adres succesvol toegevoegd",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.FAILED": "Adres kon niet toegevoegd worden",

	"CONTACT.DETAIL.ATTRIBUTES.UPDATE.ADDRESS": "Adres aanpassen",

	"CONTACTS.DETAIL.ATTRIBUTES.UPDATE.ADDRESS.SUCCESS": "Adres succesvol opgeslagen",
	"CONTACTS.DETAIL.ATTRIBUTES.UPDATE.ADDRESS.FAILED": "Adres kon niet opgeslagen worden",
	"CONTACTS.DETAIL.ATTRIBUTES.DELETE.ADDRESS.SUCCESS": "Adres succesvol verwijderd",
	"CONTACTS.DETAIL.ATTRIBUTES.DELETE.ADDRESS.FAILED": "Adres kon niet verwijderd worden",

	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.TITLE": "Contact archiveren",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.DESCRIPTION":
		"Als je dit contact archiveert, zal je het niet meer kunnen terugvinden. Je kan gearchiveerde contacten steeds zichtbaar maken in het contactmenu en ze van daaruit terug dearchiveren.",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.BUTTON": "Contact archiveren",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.SUCCESS": "Contact succesvol gearchiveerd",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.FAILED": "Contact kon niet gearchiveerd worden",

	"CONTACTS.DETAIL.SETTINGS.ARCHIVED.TITLE": "Contact gearchiveerd",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVED.DESCRIPTION":
		"Dit contact is gearchiveerd. Zet het contact terug door te op ‘Contact dearchiveren’ te klikken.",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVED.BUTTON": "Contact dearchiveren",
	"CONTACTS.DETAIL.SETTINGS.DEARCHIVE.SUCCESS": "Contact succesvol gedearchiveerd",
	"CONTACTS.DETAIL.SETTINGS.DEARCHIVE.FAILED": "Contact kon niet gearchiveerd worden",

	"CONTACTS.DETAIL.ATTRIBUTES.CONTACT_LINKING": "Gekoppelde contacten",

	"CONTACTS.DETAIL.ATTRIBUTES.CONTACT_LINKING.ADD.BUTTON": "Nieuw contact koppelen",

	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.TITLE": " E-mailadressen",

	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.LABEL.TITLE": "Label",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADDRESS.TITLE": "Adres",

	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESS.LABEL.STANDARD": "Standaard",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESS.LABEL.WORK": "Werk",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESS.LABEL.BILLING": "Facturatie",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESS.LABEL.PRIVATE": "Privé",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESS.LABEL.OTHER": "Andere",

	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADD.ADDRESS": "E-mailadres toevoegen",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADD.ADDRESS.PRIMARY.LABEL": "Primair",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADD.ADDRESS.PRIMARY.TOOLTIP":
		"Maak een ander e-mailadres primair om deze ongedaan te maken",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.UPDATE.SUCCESS.TITLE": "E-mailadres succesvol opgeslagen",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.UPDATE.FAILED.TITLE": "E-mailadres kon niet opgeslagen worden",

	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.FORM.ERROR": "Gelieve ten minste 1 geldig e-mailadres in te geven",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADDRESS.INVALID_ERROR": "Gelieve een geldig e-mailadres in te geven",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADDRESS.PRIMARY.ERROR.TITLE":
		"{email} kan niet worden verwijderd omdat het werd aangeduid als primair e-mailadres van dit contact.",

	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADD.DELETE_BUTTON.TOOLTIP":
		"Primair e-mailadres kan niet verwijderd worden.",

	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.TITLE": "Telefoonnummers",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.TITLE": "Label",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.NUMBER.LABEL": "Telefoonnummer",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.NUMBER.INVALID_ERROR": "Gelieve een geldig telefoon nummer in te geven",

	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.STANDARD": "Standaard",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.MOBILE": "Mobiel",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.HOME": "Vast nummer",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.PRIVATE": "Privé",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.WHATSAPP": "WhatsApp",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.COMPANY": "Bedrijf",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.OTHER": "Andere",

	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.ADD": "Telefoonnummer toevoegen",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.UPDATE.SUCCESS.TITLE": "Telefoon nummer succesvol opgeslagen",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.UPDATE.FAILED.TITLE": "Telefoon nummer kon niet opgeslagen worden",

	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.PRIMARY.LABEL": "Primair",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.PRIMARY.TOOLTIP":
		"Telefoon nummer kan niet verwijderd worden omdat dit primair is",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.ERROR.TITLE": "Gelieve een geldig telefoonnummer in te geven",

	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.DELETE_BUTTON.TOOLTIP": "Primair telefoonnummer kan niet verwijderd worden.",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.TITLE": "Contact linken",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.SAVE": "Opslaan",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.VIEW_CONTACT": "Bekijk contact",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.SUCCESS": "Contact succesvol gelinkt",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.FAILED": "Contact kon niet gelinkt worden",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.LINK_TYPE": "Link type",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.RELATIONSHIP": "Relatie",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.RELATIONSHIP.ERROR_REQUIRED": "Relatie is verplicht",

	"CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.CHILD": "Kind ten laste",
	"CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.PARTNER": "Partner",
	"CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.DEPENDENT": "Andere persoon ten laste",
	"CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.OTHER": "Andere",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD": "Type",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.RELATIVE.FLOW_PROXY.LABEL": "Flow volmacht",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.RELATIVE.FLOW_PROXY.HELP":
		"{name} mag een flow invullen in naam van dit gelinkt contact",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.NAME": "Naam",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.NAME.REQUIRED": "Gelieve een naam in te geven",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.HASDAYCARECERTIFICATE": "Dagopvang certificaat",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.HASDAYCARECERTIFICATE.REQUIRED":
		"Gelieve aan te geven of er een dagopvang certificaat is",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.AMOUNTDEPENDENT": "Hoeveelheid afhankelijk",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.AMOUNTDEPENDENT.REQUIRED":
		"Gelieve hoeveelheid afhankelijkheid in te geven",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.AMOUNTDEPENDENTSUBTYPE": "Afhankelijkheid subtype",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.AMOUNTDEPENDENTSUBTYPE.REQUIRED":
		"Gelieve afhankelijkheid subtype in te geven",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.HASDISABILITY": "Beperking",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.HASDISABILITY.REQUIRED":
		"Gelieve aan te geven of er een beperking is",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.ADDITIONAL_INCOME": "Inkomen verworven",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.ADDITIONAL_INCOME.REQUIRED":
		"Gelieve aan te geven of er inkomen verworven is",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.ADDITIONAL_INCOME_AMOUNT.LABEL": "Inkomen",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.HAS_REDUCED_SELF_RELIANCE.LABEL":
		"Heeft een verminderde zelfredzaamheid",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.DEPENDENT_SINCE.LABEL": "Ten laste sinds",

	FISCALLYATMYEXPENSE: "Fiscaal ten laste van mij",
	FISCALLYDEPENDENTONOTHERPARENT: "Fiscaal ten laste van andere ouder",

	FULLYCHARGED: "Volledig ten laste",
	CHARGEDPARTLY: "Gedeeltelijk ten laste",
	"CO-PARENTSHIP": "Co-ouderschap",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.MARITAL_STATUS": "Burgerlijke staat",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.MATRIMONIAL_PROPERTY_SYSTEM": "Huwelijksvermogensstelsel",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.WEDDING_DATE": "Datum getrouwd",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.WEDDING_DATE.REQUIRED": "Gelieve trouwdatum in te geven",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.DIVORCE_DATE": "Datum gescheiden",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.DIVORCE_DATE.REQUIRED": "Gelieve scheidingsdatum in te geven",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.DIVORCE_DATE.ERROR":
		"Scheidingsdatum kan niet eerder vallen dan trouwdatum",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.WIDOW_DATE": "Datum weduwe/weduwnaar geworden",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.WIDOW_DATE.REQUIRED":
		"Datum wanneer je weduwe/weduwnaar werd is verplicht",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.COHABITATION_DATE": "Datum wettelijk samenwonend",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.COHABITATION_DATE.REQUIRED":
		"Datum wettelijk samenwonend is verplicht",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.END_COHABITATION_DATE": "Einddatum wettelijk samenwonend",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.END_COHABITATION_DATE.ERROR":
		"Einddatum kan niet eerder vallen dan start datum wettelijk samenwonend",

	MARRIED_WITHOUT_BEING_LEGALLY_COHABITING: "Ongehuwd zonder wettelijk samenwonend te zijn",
	MARRIED: "Gehuwd",
	LEGALLY_COHABITING: "Wettelijk samenwonend",
	WIDOWER_WIDOW: "Weduwnaar/weduwe",
	DIVORCED: "Gescheiden",
	ACTUALLY_DIVORCED: "Feitelijk gescheiden",
	OTHER: "Andere",

	STATUTORY_SYSTEM: "Wettelijk stelsel",
	SEPARATION_OF_GOODS: "Scheiding van goederen",
	OVERALL_COMMUNITY: "Algehele gemeenschap",
	OTHER: "Andere",

	"CONTACT.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.TITLE": "Bewerk relatie",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.SUCCESS": "De relatie is succesvol bijgewerkt",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.ERROR":
		"Er is een fout opgetreden bij het bijwerken van de relatie",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.SUCCESS": "De relatie is succesvol verwijderd",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.ERROR":
		"Er is een fout opgetreden bij het verwijderen van de relatie",

	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.TITLE": "Verwijder adres",
	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.DESCRIPTION": "Weet u zeker dat u dit adres wilt verwijderen?",
	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.PLACEHOLDER": "typ VERWIJDEREN en klik daarna op ‘Verwijder adres’",
	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.BUTTON": "Verwijder adres",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.TITLE": "Verwijder deze relatie",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.DESCRIPTION": "Weet u zeker dat u deze relatie wilt verwijderen?",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.BUTTON": "Verwijder relatie",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.RELATIONSHIP.EMPTY": "Geen contacten gevonden",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.RELATIONSHIP.EMPTY.BUTTON": "Maak nieuw contact aan",

	"CONTACTS.OVERVIEW.ADD_CONTACT": "Voeg contact toe",
	"CONTACTS.OVERVIEW.IMPORT_CONTACT": "Importeer contacten",

	"CONTACTS.DETAIL.ATTRIBUTES.TYPE.TITLE": "Contacttype",
	"CONTACTS.DETAIL.ATTRIBUTES.TYPE.DESCRIPTION": "Selecteer het type dat je aan dit contact wil toekennen. ",

	"CONTACTS.DETAIL.ATTRIBUTES.BASE_VALUES.TITLE": "Persoonsgegevens",

	"CONTACTS.DETAIL.ATTRIBUTES.TYPE.SUCCESS": "Het contacttype is succesvol bijgewerkt",
	"CONTACTS.DETAIL.ATTRIBUTES.TYPE.ERROR": "Er is een fout opgetreden bij het bijwerken van het contacttype",

	"contact.type.customer.title": "Klant",
	"contacts.type.customer.description": "Contacten die tot je klantenbestand behoren",
	"contact.type.prospect.title": "Prospect",
	"contacts.type.prospect.description": "Contacten die al in contact kwamen met je kantoor",
	"contact.type.other.title": "Andere",
	"contacts.type.other.description": "Contacten van een ander type waarop je wil kunnen filteren",
	"contact.type.linked_contact.title": "Gelinkt contact",
	"contacts.type.linked_contact.description":
		"Contact die je kan linken aan andere contacten maar niet tot je klantenbestand behoord",

	"CONTACTS.DETAIL.ATTRIBUTES.DETAIL.TITLE": "Detail",
	"CONTACTS.DETAIL.ATTRIBUTES.DETAIL.UNIQUE_IDENTIFIER.TITLE": "Unieke referentie",
	"CONTACTS.DETAIL.ATTRIBUTES.DETAIL.UNIQUE_IDENTIFIER.DESCRIPTION":
		"De unieke referentie waarmee je het contact wilt identificeren",
	"CONTACTS.DETAIL.ATTRIBUTES.DETAIL.UNIQUE_IDENTIFIER.PLACEHOLDER": "REF-0001",
	"CONTACTS.DETAIL.ATTRIBUTES.DETAIL.DISABLED.TITLE": "Beperking",
	"CONTACTS.DETAIL.ATTRIBUTES.DETAIL.DISABLED.DESCRIPTION": "Dit contact heeft een beperking van meer dan 66%",

	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.CREATE_TITLE": "Rekeningnummer toevoegen ",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.UPDATE_TITLE": "Rekening detail",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FOOTER.DELETE": "Verwijderen",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FOOTER.SAVE": "Opslaan",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.LABEL": "Type rekening",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.ERROR_REQUIRED": "Type rekening is verplicht",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.CURRENT_ACCOUNT": "Zichtrekening",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.PRIVATE_ACCOUNT": "Privérekening",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.SAVINGS_ACCOUNT": "Spaarrekening",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.CRYPTO_WALLET": "Custodial crypto wallet",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.OTHER": "Andere",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IBAN.LABEL": "IBAN",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IBAN.PLACEHOLDER": "BE68 5390 0754 7034",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IBAN.ERROR_REQUIRED": "IBAN-nummer is verplicht",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IBAN.ERROR_INVALID":
		"Gelieve een geldig IBAN-nummer in te vullen. Bijvoorbeeld: (BE68 5390 0754 7034)",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BIC.LABEL": "BIC",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BIC.PLACEHOLDER": "AAAABBCCDD",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BIC.ERROR_REQUIRED": "BIC is verplicht",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BANK_NAME.LABEL": "Naam bank",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BANK_NAME.HELP": "Naam van de bank waarbij de rekening werd geopend. .",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BANK_NAME.ERROR_REQUIRED": "Naam bank is verplicht",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.COUNTRY.LABEL": "Land",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.COUNTRY.LABEL.REQUIRED": "Gelieve een land te selecteren",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.COUNTRY.HELP": "Land van de bank waarbij de rekening werd geopend.",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_OPENED.LABEL": "Datum geopend",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IS_CLOSED.LABEL": "Is gesloten",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_CLOSED.LABEL": "Datum gesloten",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IS_DECLARED.LABEL": " Rekening werd aangegeven bij het CAP/NBB",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.HAS_INCOME_OF_CHILDREN.LABEL": "Bevat inkomen van kinderen",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_SINCE_CHILD_INCOME.LABEL": "Bevat inkomen van kinderen sinds",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_SINCE_CHILD_INCOME.HELP":
		"Sinds welk jaar bevat deze rekening inkomen van je kinderen",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_SINCE_CHILD_INCOME.ERROR_INVALID":
		"Dit is geen geldig jaartal (1900 - {currentYear})",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_NAME": "Naam institutie",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_NAME.ERROR_REQUIRED": "Gelieve een naam in te vullen",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_NAME.PLACEHOLDER": "v.b. Binance",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_COUNTRY": "Land institutie",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_COUNTRY.ERROR_REQUIRED":
		"Gelieve een land te selecteren",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_COUNTRY.HELP":
		"Selecteer het land van de institutie waar de wallet gehouden wordt.",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.IS_DECLARED": "Aangegeven aan CAP/NBB",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.IS_DECLARED.ERROR_REQUIRED": "Gelieve een optie te selecteren",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.IS_CLOSED.LABEL": "Is gesloten",

	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.TITLE": "Verwijder bankrekening",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.DESCRIPTION":
		"Ben je zeker dat je deze bankrekening wil verwijderen?",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.BUTTON": "Verwijder bankrekening",
	"CONTACTS.DETAIL.BANK_ACCOUNTS.CARD.NO_DETAILS": "Geen details bekend",

	"CONTACTS.DETAIL.ATTRIBUTES.PROPERTIES.TITLE": "Vastgoed",
	"CONTACTS.DETAIL.PROPERTIES_CARD.NO_PRECISE_LOCATION": "Geen precieze locatie beschikbaar",
	"CONTACTS.DETAIL.ATTRIBUTES.PROPERTIES.ADD_BUTTON": "Vastgoed toevoegen",
	"CONTACTS.DETAIL.PROPERTIES_CARD.CHIP.SOLD": "Verkocht",
	"CONTACTS.DETAIL.PROPERTIES_CARD.CHIP.IS_RENTED": "Verhuurd",
	"CONTACTS.DETAIL.PROPERTIES_CARD.CHIP.IS_CO_OWNERSHIP": "Co-eigendom",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.CREATE_TITLE": "Vastgoed toevoegen",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.UPDATE_TITLE": "Vastgoed eigendom",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FOOTER.DELETE": "Verwijderen",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FOOTER.SAVE": "Opslaan",
	"contacts.detail.PROPERTIES_MODAL.form.type.label": "Type eigendom",
	"contacts.detail.PROPERTIES_MODAL.form.type.error_required": "Type eigendom is verplicht",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.TYPE.OPTIONS.FAMILY_HOME": "Gezins woning",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.TYPE.OPTIONS.SECOND_HOME": "Tweede woning",
	"contacts.detail.PROPERTIES_MODAL.form.building_type.label": "Type woonst",
	"contacts.detail.PROPERTIES_MODAL.form.building_type.error_required": "Type woonst is verplicht",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.HOME": "Huis/villa",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.FAMILY_HOME": "Gezins woning",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.SECOND_HOME": "Tweede woning",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.APARTMENT": "Appartement",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.INDUSTRIAL_BUILDING": "Industrieel gebouw",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.AGRICULTURE": "Landbouw",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.TRADING_HOUSE": "Handelshuis",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.TERRAIN": "Terrein",
	"contacts.detail.PROPERTIES_MODAL.form.ownership_type.label": "Type eigendom",
	"contacts.detail.PROPERTIES_MODAL.form.ownership_type.error_required": "Type eigendom is verplicht",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_TYPE.OPTIONS.FULL_OWNERSHIP": "Volle eigendom",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_TYPE.OPTIONS.BARE_PROPERTY": "Naakte eigendom",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_TYPE.OPTIONS.USUFRUCT": "Vruchtgebruik",
	"contacts.detail.PROPERTIES_MODAL.form.is_co_ownership.label": "Eigendom heeft meerdere eigenaars",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_PERCENTAGE.LABEL": "Voor hoeveel procent eigennaar",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_PERCENTAGE.HELP":
		"Het percentage dat aantoont voor welk deel <bold>{name}</bold> de eigendom bezit.",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_PERCENTAGE.ERROR_MIN": "Percentage kan niet minder zijn dan 0",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_PERCENTAGE.ERROR_MAX": "Percentage kan niet groter zijn dan 100",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NAME.LABEL": "Straatnaam",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NAME.PLACEHOLDER": "Coupure Rechts",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NAME.ERROR_REQUIRED": "Straatnaam is verplicht",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NUMBER.LABEL": "Huisnummer",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NUMBER.PLACEHOLDER": "620",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NUMBER.ERROR_REQUIRED": "Huisnummer is verplicht",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.BUS.LABEL": "Bus",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.CITY.LABEL": "Stad",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.CITY.PLACEHOLDER": "Stad",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.CITY.ERROR_REQUIRED": "Stad is verplicht",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.POSTAL_CODE.LABEL": "Postcode",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.POSTAL_CODE.PLACEHOLDER": "9000",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.POSTAL_CODE.ERROR_REQUIRED": "Postcode is verplicht",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.PROVINCE.LABEL": "Provincie",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.PROVINCE.PLACEHOLDER": "Oost-vlaanderen",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.COUNTRY.LABEL": "Land",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.COUNTRY.PLACEHOLDER": "België",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.COUNTRY.ERROR_REQUIRED": "Land is verplicht",
	"contacts.detail.PROPERTIES_MODAL.form.cadastral_income.label": "Kadastraal inkomen",
	"contacts.detail.PROPERTIES_MODAL.form.property_value.label": "Huidige waarde",
	"contacts.detail.PROPERTIES_MODAL.form.property_value.help":
		"De huidige waarde van dit eigendom inclusief renovatie..",
	"contacts.detail.PROPERTIES_MODAL.form.original_value.label": "Originele waarde",
	"contacts.detail.PROPERTIES_MODAL.form.original_value.help":
		"De originele waarde van dit eigendom voor de renovatie.",
	"contacts.detail.PROPERTIES_MODAL.form.renovation_costs.label": "Renovatiekosten",
	"contacts.detail.PROPERTIES_MODAL.form.is_rented_out.label": "Eigendom wordt verhuurd",
	"contacts.detail.PROPERTIES_MODAL.form.possible_rent_amount.label": "Waarde indien verhuurd",
	"contacts.detail.PROPERTIES_MODAL.form.possible_rent_amount.help":
		"Wat zijn de totale huur inkomsten mochten alle kamers/appartementen/... verhuurd zijn.",

	"contacts.detail.PROPERTIES_MODAL.form.rent_amount.label": "Huurinkomsten",
	"contacts.detail.PROPERTIES_MODAL.form.rent_amount.help": "De jaarlijkse huurinkomsten van dit eigendom.",
	"contacts.detail.PROPERTIES_MODAL.form.rent_type.label": "Type verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.rent_type.error_required": "Type verhuur is verplicht",
	"contacts.detail.PROPERTIES_MODAL.form.rent_type.help":
		"Het type verhuur van dit eigendom (privé of professioneel).",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.RENT_TYPE.OPTIONS.PRIVATE": "Privé verhuur",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.RENT_TYPE.OPTIONS.PROFESSIONAL": "Professioneel verhuur",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.RENT_TYPE.OPTIONS.MIXED": "Zowel professioneel als privé verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.has_re_mvp_split.label":
		"Opsplitsing tussen onroerende en roerende huur in het huurcontract",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_private_rented.label": "Percentage privé verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_professionally_rented.label": "Percentage professioneel verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_movable_property.label": "Percentage roerend goed verhuur",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.PERCENTAGE_REAL_ESTATE.LABEL": "Percentage onroerend goed verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.purchase_date.label": "Aankoopdatum",
	"contacts.detail.PROPERTIES_MODAL.form.is_sold.label": "Eigendom is reeds verkocht",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.label": "Verkoopdatum",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.help": "Datum waarop dit eigendom is verkocht.",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.error": "De verkoopdatum kan niet voor de aankoopdatum liggen.",

	"CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.TITLE": "Verwijder buitenlands vastgoed",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.DESCRIPTION":
		"Ben je er zeker van dat je dit buitenlands vastgoed wil verwijderen?",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.BUTTON": "Verwijder buitenlands vastgoed",

	"CONTACTS.DETAIL.ATTRIBUTES.INSURANCES.TITLE": "Verzekeringen",
	"CONTACTS.DETAIL.ATTRIBUTES.INSURANCES.ADD_BUTTON": "Verzekering toevoegen",
	"CONTACTS.DETAIL.INSURANCES_MODAL.CREATE_TITLE": "Verzekering toevoegen",
	"CONTACTS.DETAIL.INSURANCES_MODAL.UPDATE_TITLE": "Verzekering",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.TYPE.LABEL": "Type verzekering",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.TYPE.ERROR_REQUIRED": "Type verzekering is verplicht",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.TYPE.OPTION.LIFE_INSURANCE": "Buitenlandse levensverzekering",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.INSTITUTION_NAME.LABEL": "Naam verzekeringsmaatschappij",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.INSTITUTION_NAME.ERROR_REQUIRED":
		"Naam verzekeringsmaatschappij is verplicht",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.ADDRESS.COUNTRY.LABEL": "Land verzekeringsmaatschappij",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.ADDRESS.COUNTRY.PLACEHOLDER": "Zwitserland",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.ADDRESS.COUNTRY.ERROR_REQUIRED":
		"Land verzekeringsmaatschappij is verplicht",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FOOTER.SAVE": "Opslaan",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FOOTER.DELETE": "Verwijderen",
	"CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.TITLE": "Verwijder verzekering",
	"CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.BUTTON": "Verwijder verzekering",
	"CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.DESCRIPTION":
		"Ben je er zeker van dat je deze verzekering wil verwijderen?",

	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.TITLE": "Laadpaal",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.HAS_CHARGING_STATION.LABEL": "Bezit een laadpaal",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.GREEN_POWER.LABEL": "Maakt volledig gebruik van groene stroom",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INSPECTED.LABEL": "Is gekeurd",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INTELLIGENT.LABEL": "Is intelligent",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.LOCATED_AT_DOMICILE.LABEL": "Locatie is bij domicilie",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INSTALLED.LABEL": "Geinstalleerd door installateur",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INSTALLATION_YEAR.LABEL": "Jaar van installatie",

	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INSTALLATION_YEAR.LABEL.ERROR_REQUIRED":
		"Jaar van installatie is verplicht",

	"CONTACTS.IMPORT.STEPS.IMPORT_OPTIONS": "Importopties",
	"CONTACTS.IMPORT.STEPS.OVERVIEW": "Overzicht contacten",
	"CONTACTS.IMPORT.STEPS.MAP": "Map velden",
	"CONTACTS.IMPORT.STEPS.VALIDATE": "Contactinfo aanvullen",
	"CONTACTS.IMPORT.STEPS.SETTINGS": "Contacten labelen",
	"CONTACTS.IMPORT.STEPS.IMPORT": "Importeren",

	"contacts.import.import_options.title": "Importopties",

	"CONTACTS.IMPORT.OVERVIEW.TITLE": "Overzicht contacten",
	"CONTACTS.IMPORT.OVERVIEW.DESCRIPTION": "Hieronder vind je een overzicht van de contacten die je kan importeren. ",
	"CONTACTS.IMPORT.OVERVIEW.WARN_DUPLICATES":
		"Let op: het is mogelijk dat de uiteindelijke import minder contacten bevat omdat dubbele contacten automatisch in Bothive worden samengevoegd tot één contact.",

	"CONTACTS.IMPORT.FETCH.FAILED": "Er is een fout opgetreden bij het ophalen van de contacten",

	"CONTACTS.IMPORT.ADMIN_PULSE.OWN_COMPANY": "Eigen bedrijf",

	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.CRMTYPE": "Filter op CRM-type",
	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.CRMTYPE.PLACEHOLDER": "Selecteer een CRM type",

	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.TYPE.COMPANY": "Bedrijf",
	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.TYPE.PERSON": "Persoon",
	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.TYPE.SOLE_PROPRIETOR": "Eenmanszaak",

	"CONTACTS.IMPORT.VALIDATE.NEXT.BUTTON": "Opslaan en naar volgende",

	"CONTACTS.IMPORT.VALIDATE.TITLE": "Contactinfo aanvullen",
	"CONTACTS.IMPORT.VALIDATE.DESCRIPTION":
		"{value, plural, =0 {Alle contacten kunnen geïmporteerd worden} one {# contact is niet volledig en kan niet geïmporteerd worden} other {# contacten kunnen niet geïmporteerd worden}}. Je kan de data van de contacten hier aanpassen.",

	"CONTACTS.IMPORT.VALIDATE.ONLY_NATIONALNUMBER": "Alleen rijksregisternummer",
	"CONTACTS.IMPORT.VALIDATE.ONLY_PHONENUMBER": "Alleen telefoonnummer",
	"CONTACTS.IMPORT.VALIDATE.ONLY_EMAIL": "Alleen e-mailadres",
	"CONTACTS.IMPORT.VALIDATE.NO_DATA": "Geen data",

	"CONTACTS.OVERVIEW.ADD.MODAL.PHONE": "Telefoonnummer",
	"CONTACTS.OVERVIEW.ADD.MODAL.PHONE.REQUIRED": "Gelieve een telefoonnummer in te geven",

	"CONTACTS.OVERVIEW.VALIDATE.LIST.TITLE":
		"{value, plural, =0 {Geen incomplete contacten} one {# onvolledig contact gevonden} other {# onvolledige contacten gevonden}}",

	"CONTACTS.IMPORT.RETURN": "Vorige",

	"CONTACTS.IMPORT.VALIDATE.SAVE": "Contact opslaan",
	"CONTACTS.IMPORT.VALIDATE.SKIP":
		"Verdergaan en {value, plural, =0 {importeren} one {# contact} other {# contacten}} overslaan",

	"CONTACTS.IMPORT.SETTINGS.TITLE": "Contacten labelen",
	"CONTACTS.IMPORT.SETTINGS.DESCRIPTION": "Label contacten om ze makkelijker te kunnen filteren en bewerken. ",
	"CONTACTS.IMPORT.SETTINGS.RULES.DESCRIPTION":
		"Voeg op basis van bepaalde regels een specifiek label toe aan een groep van geïmporteerde contacten. ",

	"CONTACTS.IMPORT.SETTINGS.LABEL.TITLE": "Voeg een label toe aan alle geïmporteerde contacten.",
	"CONTACTS.IMPORT.SETTINGS.LABEL.HELP": "Maak een label aan voor de volledige groep van geïmporteerde contacten.",

	"CONTACTS.IMPORT.SETTINGS.IMPORT.BUTTON.UPDATE_CONTACTS": "Update contacten",
	"CONTACTS.IMPORT.SETTINGS.IMPORT.BUTTON":
		"{value, plural, one {# contact importeren} other {# contacten importeren}}",

	"CONTACTS.IMPORT.SETTINGS.LABEL.EMPTY.TITLE": "Geen labels gevonden",
	"CONTACTS.IMPORT.SETTINGS.LABEL.ADD.TITLE": "Maak een label aan",

	"CONTACTS.IMPORT.IMPORT.ERROR.TITLE": "Er is een fout opgetreden bij het importeren van de contacten",
	"CONTACTS.IMPORT.IMPORT.ERROR.DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze support indien het probleem zich blijft voordoen",
	"CONTACTS.IMPORT.IMPORT.ERROR.BUTTON": "Importeren afsluiten",

	"CONTACTS.IMPORT.IMPORT.PROGRESS": "{amount} van {total} contacten geïmporteerd",
	"CONTACTS.IMPORT.IMPORT.PROGRESS.PERCENT": "{percent}% geïmporteerd",

	"CONTACTS.IMPORT.INTEGRATION_LIST.ERROR": "Er is een fout opgetreden bij het ophalen van de integraties",

	"CONTACTS.CSV.OVERVIEW.TITLE": "Csv importeren",
	"CONTACTS.CSV.OVERVIEW.DESCRIPTION": "Importeer contacten uit een csv bestand",
	"CONTACTS.CSV.OVERVIEW.CHOOSE_FILE": "Kies een bestand",
	"CONTACTS.CSV.OVERVIEW.CHOOSE_FILE.DESCRIPTION": "Kies een csv bestand om te importeren",
	"CONTACTS.CSV.OVERVIEW.DELIMITER": "Scheidingsteken",
	"CONTACTS.CSV.OVERVIEW.DELIMITER.BUTTON": "Scheidingsteken toepassen",
	"CONTACTS.CSV.OVERVIEW.DELIMITER.HELP": "Wijzig deze waarde als de CSV kolommen niet correct gedetecteerd worden.",

	"CONTACTS.CSV.MAP.TITLE": "Map velden",
	"CONTACTS.CSV.MAP.DESCRIPTION": "Map de kolommen van je csv bestand naar de velden van je contacten",

	"CONTACTS.CSV.MAP.SELECT": "Importeren",
	"CONTACTS.CSV.MAP.FROM": "Van",
	"CONTACTS.CSV.MAP.TO": "Naar",

	"CONTACTS.CSV.MAP.WARNING.TITLE": "Verplichte velden",
	"CONTACTS.CSV.MAP.WARNING.DESCRIPTION": "Gelieve de volgende velden te mappen om verder te gaan:",

	"CONTACTS.SETTINGS.ADD.RULE.MODAL.TITLE": "Voorwaarde voor label toevoegen",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.DESCRIPTION":
		"Je contact zal een specifiek label krijgen wanneer het voldoet aan je ingestelde voorwaarde.",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.LABEL": "Label",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.BUTTON": "Opslaan",
	"CONTACTS.SETTINGS.DELETE.RULE.MODAL.BUTTON": "Verwijderen",

	"CONTACTS.SETTINGS.ADD.RULE.MODAL.IS_DEFINED": "Gedefinieerd",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.EQUAL_TO": "Gelijk aan",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.GREATER_THAN": "Groter dan",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.LESS_THAN": "Minder dan",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.GREATER_THAN_OR_EQUAL": "Groter dan of gelijk aan",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.LESS_THAN_OR_EQUAL": "Minder dan of gelijk",

	"CONTACTS.SETTINGS.ADD.RULE.MODAL.IF": "Als",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.IS": "Is",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.AND": "En",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.OR": "Of",
	"CONTACTS.SETTINGS.RULE.MODAL.CONDITION.ERROR.EMPTY": "Er zijn nog geen regels toegevoegd",
	"CONTACTS.SETTINGS.RULE.MODAL.CONDITION.ERROR.EMPTY_FIELDS": "Er zijn nog lege velden in je regel",

	"CONTACTS.SETTINGS.ADD.RULE.MODAL.ADD_AND": "Voeg ‘En’ voorwaarde toe",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.ADD_OR": "Voeg ‘Of’ voorwaarde toe",

	"CONTACTS.SETTINGS.RULE.TABLE.CONDITION": "Conditie",
	"CONTACTS.SETTINGS.RULE.TABLE.LABEL": "Label",
	"CONTACTS.SETTINGS.RULE.ADD.BUTTON": "Specifiek label toevoegen",

	"CONTACTS.SETTINGS.RULE.TABLE.CONTENT.RULE":
		"{type, select, if {Als} or {of} and {en} other {}} <bold>{field}</bold> {value}",

	"CONTACTS.SETTINGS.RULE.TABLE.CONTENT.RULE.EQUAL_TO": "gelijk is aan <bold>{input}</bold>",
	"CONTACTS.SETTINGS.RULE.TABLE.CONTENT.RULE.LESS_THAN": "minder is dan <bold>{input}</bold>",
	"CONTACTS.SETTINGS.RULE.TABLE.CONTENT.RULE.IS_DEFINED": "gedefinieerd is",
	"CONTACTS.SETTINGS.RULE.TABLE.CONTENT.RULE.GREATER_THAN": "groter dan <bold>{input}</bold>",
	"CONTACTS.SETTINGS.RULE.TABLE.CONTENT.RULE.GREATER_THAN_OR_EQUAL": "groter dan of gelijk aan <bold>{input}</bold>",
	"CONTACTS.SETTINGS.RULE.TABLE.CONTENT.RULE.LESS_THAN_OR_EQUAL": "minder dan of gelijk aan <bold>{input}</bold>",

	"CONTACTS.SETTINGS.RULE.TABLE.CONTENT.LABEL": "voeg <label>{labelName}</label>toe",

	"CONTACTS.SETTINGS.RULE.MODAL.LABEL.TITLE": "Voeg label toe",
	"CONTACTS.SETTINGS.RULE.MODAL.LABEL.HELP":
		"Selecteer of maak een label dat je wil toevoegen aan de contacten die aan je ingestelde voorwaarde voldoen.",
	"CONTACTS.SETTINGS.RULE.MODAL.LABEL.REQUIRED": "Gelieve een veld en een label te selecteren",

	"CONTACTS.OVERVIEW.TABLE.ACCOUNT_MANAGERS": "Dossierbeheerders",
	"CONTACTS.OVERVIEW.TABLE.LABELS": "Labels",
	"CONTACTS.OVERVIEW.TABLE.TYPE": "Type",
	"CONTACTS.OVERVIEW.TABLE.CREATED_AT": "Aangemaakt op",
	"CONTACTS.OVERVIEW.TABLE.UPDATED_AT": "Aangepast op",
	"CONTACTS.OVERVIEW.TABLE.ARCHIVED_AT": "Gearchiveerd op",
	"CONTACTS.OVERVIEW.TABLE.DELETED_AT": "Verwijderd op",
	"CONTACTS.OVERVIEW.TABLE.HARD_DELETE.MESSAGE": "{days, plural, =0 {Vandaag} one {{days} dag} other {{days} dagen}}",
	"CONTACTS.OVERVIEW.TABLE.HARD_DELETE.MESSAGE.TOOLTIP":
		"{days, plural, =0 {Vandaag wordt dit contact definitief verwijderd} one {Binnen {days} dag wordt dit contact definitief verwijderd} other {Binnen {days} dagen wordt dit contact definitief verwijderd}}",

	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.PRIMARY.TOOLTIP": "Kan niet aangepast worden omdat dit het primaire adres is",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.DISABLED":
		"Contact linken is niet mogelijk omdat dit contact gearchiveerd werd",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.SELECT.ADD": "Contact aanmaken",

	"CONTACTS.SETTINGS.LABELS.HEX.ERROR": "Kleurencode foutief, gebruik een geldige hex waarde",

	"CONTACTS.SETTINGS.LABELS.NAME.REQUIRED": "Gelieve een naam in te vullen",
	"CONTACTS.SETTINGS.NAME.DUPLICATE": "Een label met deze naam bestaat reeds, gelieve een andere naam te kiezen",

	"CONTACTS.SETTINGS.RULE.ADD.LABEL.SUCCESS": "Label succesvol toegevoegd",
	"CONTACTS.SETTINGS.RULE.ADD.LABEL.FAILED":
		"Er is een fout opgetreden bij het toevoegen van het label, probeer later opnieuw",
	"CONTACTS.SETTINGS.RULE.ADD.LABEL.FAILED.CONFLICT":
		"Er bestaat reeds een label met deze naam, gelieve een andere naam te kiezen",
	"CONTACTS.SETTINGS.RULE.LABEL.ADD.BUTTON": "Nieuw label toevoegen",

	"CONTACTS.OVERVIEW.INTRO.TITLE": "Importeer je contacten",
	"CONTACTS.OVERVIEW.INTRO.DESCRIPTION": "Kies hoe je je contacten wil importeren.",
	"CONTACTS.OVERVIEW.INTRO.FOOTER_BUTTON": "Contact manueel aanmaken",
	"CONTACTS.IMPORT.DONE.CLOSE": "Importeren afsluiten",

	// GENERAL

	"MODEL.CONTACT.GENERAL_INFORMATION": "Algemene informatie",
	"MODEL.CONTACT.FIRST_NAME": "Voornaam",
	"MODEL.CONTACT.LAST_NAME": "Familienaam",
	"MODEL.CONTACT.MIDDLE_NAME": "Tussenvoegsel",
	"MODEL.CONTACT.FULLNAME": "Volledige naam",
	"MODEL.CONTACT.SALUTATION": "Aanhef",
	"MODEL.CONTACT.EMAIL": "E-mail",
	"MODEL.CONTACT.PHONE": "Telefoon",
	"MODEL.CONTACT.NATIONAL_REGISTRATION_NUMBER": "Rijksregisternummer",
	"MODEL.CONTACT.BIRTHDAY": "Geboortedatum",
	"MODEL.CONTACT.LANGUAGE": "Taal",
	"MODEL.CONTACT.SEX": "Geslacht",
	"MODEL.CONTACT.UNIQUE_IDENTIFIER": "Unieke identificatie",
	"MODEL.CONTACT.IBAN_REIMBURSEMENT": "IBAN terugbetaling",
	"MODEL.CONTACT.HAS_DISABILITY": "Heeft een handicap",

	// ADDRESS

	"MODEL.CONTACT.ADDRESS": "Adres",
	"MODEL.CONTACT.ADDRESS.STREET_NAME": "Straatnaam",
	"MODEL.CONTACT.ADDRESS.STREET_NUMBER": "Huisnummer",
	"MODEL.CONTACT.ADDRESS.CITY": "Stad",
	"MODEL.CONTACT.ADDRESS.BUS": "Bus",
	"MODEL.CONTACT.ADDRESS.PROVINCE": "Provincie",
	"MODEL.CONTACT.ADDRESS.POSTAL_CODE": "Postcode",

	// EID
	"MODEL.CONTACT.EID": "EID",
	"MODEL.CONTACT.EID.CARD_NUMBER": "Kaartnummer",
	"MODEL.CONTACT.EID.EXPIRATION_DATE": "Vervaldatum",

	// CHARGING STATION
	"MODEL.CONTACT.CHARGING_STATION": "Laadpaal",
	"MODEL.CONTACT.CHARGING_STATION.HAS_CHARGING_STATION": "Bezit een laadpaal",
	"MODEL.CONTACT.CHARGING_STATION.IS_CONNECTED_TO_NETWORK": "Is aangesloten op het netwerk",
	"MODEL.CONTACT.CHARGING_STATION.IS_INTELLIGENT": "Is een intelligente laadpaal",
	"MODEL.CONTACT.CHARGING_STATION.IS_USING_GREEN_POWER": "Gebruikt groene stroom",
	"MODEL.CONTACT.CHARGING_STATION.IS_EXAMINED": "Is gekeurd",

	// CRYPTO
	"MODEL.CONTACT.CRYPTO": "Cryptomunten",
	"MODEL.CONTACT.CRYPTO.ADDRESS": "Crypto adres",
	"MODEL.CONTACT.CRYPTO.HAS_CRYPTO": "Bezit cryptomunten",
	"MODEL.CONTACT.CRYPTO.COUNTRY_WALLET": "Land wallet",

	// BANK
	"MODEL.CONTACT.BANK": "Bank",
	"MODEL.CONTACT.BANK_ACCOUNTS.BANK_NAME": "Banknaam",
	"MODEL.CONTACT.BANK_ACCOUNTS.TYPE": "Type rekening",
	"MODEL.CONTACT.BANK_ACCOUNTS.IBAN": "IBAN",
	"MODEL.CONTACT.BANK_ACCOUNTS.INSTITUTION_NAME": "Instituutnaam",
	"MODEL.CONTACT.BANK_ACCOUNTS.INSTITUTION_ADDRESS_COUNTRY": "Land instituut",
	"MODEL.CONTACT.BANK_ACCOUNTS.BIC_CODE": "BIC code",

	// FOREIGN PROPERTIES
	"MODEL.CONTACT.FOREIGN_PROPERTIES": "Buitenlands vastgoed ",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.TYPE": "Type",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.BUILDING_TYPE": "Type gebouw",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.STREET_NUMBER": "Huisnummer",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.STREET_NAME": "Straatnaam",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.CITY": "Stad",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.BUS": "Bus",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.PROVINCE": "Provincie",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.COUNTRY": "Land",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.POSTAL_CODE": "Postcode",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.OWNERSHIP_TYPE": "Eigendomstype",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.IS_CO_OWNERSHIP": "Is mede-eigendom",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.CO_OWNER_NAMES": "Naam mede-eigenaar",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.PROPERTY_VALUE": "Waarde eigendom",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ORIGINAL_VALUE": "Oorspronkelijke waarde",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.RENOVATION_COSTS": "Renovatiekosten",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.IS_RENTED_OUT": "Is verhuurd",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.POSSIBLE_RENT_AMOUNT": "Mogelijke huurprijs",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.RENT_AMOUNT": "Huurprijs",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.PURCHASE_DATE": "Aankoopdatum",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.IS_SOLD": "Is verkocht",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.SOLD_DATE": "Verkoopdatum",

	// CONTACT LINKING
	"MODEL.CONTACT.CONTACT_LINKING": "Contact koppeling",
	"MODEL.CONTACT.CONTACT_LINKING.GENERAL": "Algemeen",
	"MODEL.CONTACT.CONTACT_LINKING.FIRST_NAME": "Voornaam",
	"MODEL.CONTACT.CONTACT_LINKING.LAST_NAME": "Familienaam",
	"MODEL.CONTACT.CONTACT_LINKING.TYPE": "Type",

	// CONTACT LINKING CHILD
	"MODEL.CONTACT.CONTACT_LINKING_CHILD": "Contact koppeling kind",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.AMOUNT_DEPENDENT": "Hoeveelheid afhankelijk",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.AMOUNT_DEPENDENT_SUBTYPE": "Hoeveelheid afhankelijk subtype",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.HAS_DISABILITY": "Heeft een handicap",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.HAS_DAYCARE_CERTIFICATE": "Heeft een attest dagopvang",

	// CONTACT LINKING PARTNER
	"MODEL.CONTACT.CONTACT_LINKING_PARTNER": "Contact koppeling partner",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.INCOME": "Inkomsten",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.MARITAL_STATUS": "Burgerlijke staat",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.MATRIMONIAL_PROPERTY_SYSTEM": "Eigendomsstelsel",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.WEDDING_DATE": "Huwelijksdatum",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.DIVORCE_DATE": "Scheidingsdatum",

	"CONTACTS.FORM.NATIONALREGISTRATIONNUMBER.HELP":
		"Vul een rijksregisternummer in om dubbele contacten te voorkomen.",

	"SETTINGS.CONTACTS.LABELS.EMPTY": "Geen labels gevonden",

	"CONTACTS.IMPORT.VALIDATE.NO_INCOMPLETE_CONTACTS": "Alle contactinfo vervolledigd",
	"CONTACTS.IMPORT.VALIDATE.NO_INCOMPLETE.CONTACTS.DESCRIPTION":
		"Er zijn geen contacten waarvan er nog informatie ontbreekt. Je kan verdergaan met importeren. ",

	"CONTACTS.IMPORT.CSV.OVERVIEW.FILE_FAULTY.TITLE": "Bestand aangepast",
	"CONTACTS.IMPORT.CSV.OVERVIEW.FILE_FAULTY.DESCRIPTION":
		"Sommige data uit dit csv bestand werd verwijded omdat dit incompatibel was met de import.",

	"CONTACTS.IMPORT.VALIDATE.BASIC_INFO.COLLAPSE.TITLE":
		"{value, plural, =0 {Basisgegevens} one {Basisgegevens, {value} veld ontbreekt} other {Basisgegevens, {value} velden ontbreken}}",
	"CONTACTS.IMPORT.VALIDATE.ADDRESSES.COLLAPSE.TITLE":
		"{value, plural, =0 {Adressen} one {Adressen, {value} veld ontbreekt} other {Adressen, {value} velden ontbreken}}",
	"CONTACTS.IMPORT.VALIDATE.BANK_ACCOUNTS.COLLAPSE.TITLE":
		"{value, plural, =0 {Bankrekeningnummer} one {Bankrekeningnummer, {value} veld ontbreekt} other {Bankrekeningnummer, {value} velden ontbreken}}",

	"CONTACTS.IMPORT.SETTINGS.TYPE": "Type",
	"CONTACTS.IMPORT.SETTINGS.ID": "Id",
	"CONTACTS.IMPORT.SETTINGS.LASTNAME": "Familienaam",
	"CONTACTS.IMPORT.SETTINGS.FIRSTNAME": "Voornaam",
	"CONTACTS.IMPORT.SETTINGS.FULL_NAME": "Volledige naam",
	"CONTACTS.IMPORT.SETTINGS.UNIQUEIDENTIFIER": "Unieke identificatie",
	"CONTACTS.IMPORT.SETTINGS.EMAIL": "E-mail",
	"CONTACTS.IMPORT.SETTINGS.PHONE": "Telefoon",
	"CONTACTS.IMPORT.SETTINGS.AVATAR": "Avatar",
	"CONTACTS.IMPORT.SETTINGS.NATIONALREGISTRATIONNUMBER": "Rijksregisternummer",
	"CONTACTS.IMPORT.SETTINGS.HAS_DISABILITY": "Heeft beperking",
	"CONTACTS.IMPORT.SETTINGS.LANGUAGE": "Taal",
	"CONTACTS.IMPORT.SETTINGS.EID.EXPIRATION_DATE": "Eid vervaldatum",
	"CONTACTS.IMPORT.SETTINGS.EID.CARD_NUMBER": "Eid kaartnummer",
	"CONTACTS.IMPORT.SETTINGS.BIRTHDAY": "Geboortedatum",

	"REDIRECT.AUTH_SUCCEEDED.TITLE": "Koppeling verwerken",
	"REDIRECT.AUTH_SUCCEEDED.DESCRIPTION":
		"We zijn bezig met je koppeling te verwerken als dit langer dan enkele seconden duurt herlaad deze pagina.",
	"REDIRECT.AUTH_SUCCEEDED.BUTTON": "Herlaad deze pagina",

	"settings.member.delete.not_authorized": "U bent niet bevoegd om dit lid te verwijderen",
	"settings.member.delete.owner_deleted": "Eigenaren kunnen zichzelf niet verwijderen uit een organisatie",
	"settings.member.delete.something_went_wrong": "Je kunt dit lid tijdelijk niet verwijderen",
	"settings.member.invite.duplicate_member": "Lid is al uitgenodigd in je organisatie",
	"settings.member.invite.not_authorized": "Je bent niet bevoegd om nieuwe leden uit te nodigen",
	"settings.member.invite.plan_limit_reached":
		"Je hebt het aantal leden bereikt dat je aan je abonnement kunt toevoegen, upgrade je abonnement om nieuwe leden uit te nodigen",
	"settings.member.invite.something_went_wrong": "Je kunt tijdelijk geen nieuwe leden toevoegen",
	"settings.member.resend_invite.not_authorized": "Je bent niet bevoegd om leden uit te nodigen",
	"settings.member.resend_invite.something_went_wrong":
		"Je kunt de ledenuitnodiging tijdelijk niet opnieuw versturen",
	"settings.member.update.not_authorized": "Je bent niet gemachtigd om dit lid bij te werken",
	"settings.member.update.something_went_wrong": "Je kunt dit lid tijdelijk niet updaten",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SEND_TO_EMAIL": "E-mail verzenden",
	"FLOWS.SEND.COMPOSE.BUTTONS.NEXT": "Volgende",
	"FLOWS.SEND.COMPOSE.CHANNEL": "Kanaal",
	"FLOWS.SEND.COMPOSE.EMAIL": "E-mail",
	"FLOWS.SEND.COMPOSE.PREVIOUS": "Vorige",
	"FLOWS.SEND.COMPOSE.SUBJECT": "Onderwerp",
	"FLOWS.SEND.COMPOSE.SUBJECT.REQUIRED": "Gelieve een onderwerp in te vullen.",
	"FLOWS.SEND.COMPOSE.TITLE": "Bericht samenstellen",
	"FLOWS.SEND.COMPOSE.DESCRIPTION": "Stel een bericht samen om naar je contacten te verzenden.",
	"FLOWS.SEND.CONTACT_SELECTION.BUTTONS.NEXT": "Volgende",
	"FLOWS.SEND.CONTACT_SELECTION.BUTTONS.SEND": "{value, plural, one {E-mail verzenden} other {E-mails verzenden}}",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.CAN_BE_SENT": "Verzenden mogelijk",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.EMAIL": "E-mail",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.NAME": "Naam",
	"FLOWS.SEND.CONTACT_SELECTION.NO_EMAIL": "E-mailadres ontbreekt",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.CANNOT_BE_SENT": "E-mailadres ontbreekt of is ongeldig.",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.CAN_BE_SENT": "Verzenden mogelijk",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.EMAIL": "E-mail",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.NAME": "Naam",
	"FLOWS.SEND.CONTACT_SELECTION.TITLE": "Contacten selecteren",
	"FLOWS.SEND.CONTACT_SELECTION.DESCRIPTION": "Selecteer je contacten door ze te filteren.",
	"FLOWS.SEND.CONTACT_SELECTION.SEND_TO_COMPLETED_REPORTS":
		"Verzend dit bericht ook naar contacten die de flow al vervolledigd hebben.",
	"FLOWS.SEND.CONTACT_SELECTION.ALERT":
		"{count, plural, =1 {# contact zal} other {# contacten zullen}} je bericht niet ontvangen omdat hun e-mailadres ontbreekt.",
	"FLOWS.SEND.OVERVIEW.CHANNEL": "Kanaal:",
	"FLOWS.SEND.OVERVIEW.EMAIL": "E-mail:",
	"FLOWS.SEND.OVERVIEW.FLOW": "Flow:",
	"FLOWS.SEND.OVERVIEW.SUBJECT": "Onderwerp:",
	"FLOWS.SEND.OVERVIEW.TITLE": "Overzicht",
	"FLOWS.SEND.OVERVIEW.DESCRIPTION": "Kijk je bericht en je gekozen contacten na vooraleer je de e-mail verzendt.",
	"FLOWS.SEND.OVERVIEW.SEND_FLOW_FAILED.TITLE": "Flow kan niet worden verzonden.",
	"FLOWS.SEND.OVERVIEW.SEND_FLOW_FAILED.DESCRIPTION":
		"Er is iets misgegaan tijdens het verzenden van de Flow, probeer het opnieuw of neem contact op met onze klantenservice.",
	"FLOWS.SEND.RESULT.CLOSE": "Sluiten",
	"FLOWS.SEND.RESULT.DESCRIPTION":
		"Je flow wordt momenteel naar alle geselecteerde contacten verstuurd. Dit kan tot 30 minuten duren. Je kan de status per rapport opvolgen in het rapportoverzicht onder ‘Progressie’. Je ontvangt een melding wanneer alle e-mails verzonden zijn.",
	"FLOWS.SEND.RESULT.REPORTS": "Ga naar rapporten",
	"FLOWS.SEND.RESULT.TITLE": "Je flow wordt momenteel naar alle geselecteerde contacten verstuurd.",
	"FLOWS.SEND.STEP.COMPOSE_MESSAGE": "Bericht samenstellen",
	"FLOWS.SEND.STEP.CONTACT_SELECT": "Contacten selecteren",
	"FLOWS.SEND.STEP.OVERVIEW": "Overzicht",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.EMPTY": "Er zijn geen kanalen aan je gebruiker gekoppeld.",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.EMPTY.ADD": "Kanaal toevoegen",
	"INBOX.COMING_SOON.DESCRIPTION":
		"Werk e-mails sneller af door efficiënt samen te werken in de gedeelde team inbox.",
	"INBOX.COMING_SOON.FEATURES.ASSIGN": "Wijs e-mails aan teamleden toe",
	"INBOX.COMING_SOON.FEATURES.INBOX_ZERO": "Creëer rond elke e-mail een interne chatconversatie",
	"INBOX.COMING_SOON.FEATURES.TEAM_INBOX": "Bereik inbox zero door optimaal samen te werken",
	"INBOX.COMING_SOON.TITLE": "Nu in Publieke beta",
	"POPUP.BUTTON.DELETE": "Verwijderen",
	"settings.member.delete.not_authorized": "Je hebt niet de rechten om dit teamlid te verwijderen.",
	"settings.member.delete.owner_deleted": "Eigenaars van een team kunnen zichzelf niet uit het team verwijderen.",
	"settings.member.delete.something_went_wrong": "Je kan dit teamlid tijdelijk niet verwijderen.",
	"settings.member.invite.duplicate_member": "Dit teamlid maakt al deel uit van je team.",
	"settings.member.invite.not_authorized": "Je hebt niet de rechten om nieuwe teamleden uit te nodigen.",
	"settings.member.invite.plan_limit_reached":
		"Je hebt het maximaal aantal leden bereikt dat je aan je team kan toevoegen. Upgrade je lidmaatschap om nieuwe leden uit te nodigen.",
	"settings.member.invite.something_went_wrong": "Je kan tijdelijk geen nieuwe teamleden toevoegen.",
	"settings.member.resend_invite.not_authorized": "Je hebt niet de rechten om teamleden uit te nodigen.",
	"settings.member.resend_invite.something_went_wrong": "Je kan de uitnodiging tijdelijk niet opnieuw verzenden.",
	"settings.member.update.not_authorized": "Je hebt niet de rechten om dit teamlid te bewerken.",
	"settings.member.update.something_went_wrong": "Je kan dit teamlid tijdelijk niet bewerken.",

	"settings.notifications.contacts.card.title": "Contacten",
	"settings.notifications.contacts.card.description": "Ontvang een melding bij acties rond contacten.",
	"settings.notifications.contacts.form.synchronization.label": "Contact import",
	"import_contacts_modal.import_options.header.title": "Importeer contacten",
	"import_contacts_modal.import_options.header.description": "Kies hoe je je contacten wil importeren.",
	//////////////////////////////////////
	/////// new copy from savannah ///////
	//////////////////////////////////////
	"BANNER.FAILED.CREATE_LABEL":
		"Het label kon niet aangemaakt worden. Probeer het later opnieuw of contacteer onze klantendienst.",
	"BANNER.FAILED.DELETE_LABEL":
		"Het label kon niet verwijderd woren. Probeer het later opnieuw of contacteer onze klantendienst.",
	"BANNER.FAILED.UPDATE_LABEL":
		"Het label kon niet geüpdatet worden. Probeer het later opnieuw of contacteer onze klantendienst.",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.DESCRIPTION":
		"Verbind minstens één van je huidige e-mailkanalen om je e-mails via de Bothive inbox te kunnen ontvangen en versturen.",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.FOOTER": "Je kan je kanalen beheren via <link>instellingen / kanalen</link.",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.TITLE": "Nog geen e-mailkanalen verbonden.",
	"CONTACT.OVERVIEW.ADD.MODAL.FAILED": "Contact kon niet worden toegevoegd.",
	"CONTACT.OVERVIEW.ADD.MODAL.FAILED.DESCRIPTION": "Verifieer of er reeds een contact bestaat met dit e-mailadres.",
	"CONTACT.OVERVIEW.ADD.MODAL.SUCCESS": "Contact succesvol toegevoegd.",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.BUTTON.TITLE": "Accountmanagers",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.ERROR":
		"Er is iets fout gegaan bij het bijwerken van de accountmanagers.",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ACTIONS.ADD": "Toevoegen",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ACTIONS.DELETE": "Verwijderen",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ITEM_SELECTED":
		"{count, plural, =0 {Geen accountmanagers} one {1 accountmanager} other {# accountmanagers}} geselecteerd.",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.NOT_FOUND": "Geen contactlabels gevonden.",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.SEARCH_PLACEHOLDER": "Zoek op accountmanager",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.TITLE": "Beheerd door",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.SUCCESS":
		"Accountmanagers van {count, plural, one {1 contact} other {# contacten}} succesvol bijgewerkt.",
	"CONTACT.OVERVIEW.BULK_ACTION.ACTION.ARCHIVE": "ARCHIVEREN",
	"CONTACT.OVERVIEW.BULK_ACTION.ACTION.DELETE": "VERWIJDEREN",
	"CONTACT.OVERVIEW.BULK_ACTION.ARCHIVE.ERROR": "Er is iets fout gegaan bij het archiveren in bulk.",
	"CONTACT.OVERVIEW.BULK_ACTION.ARCHIVE.SUCCESS":
		"{count, plural, one {1 contact} other {# contacten}} succesvol bijgewerkt.",
	"CONTACT.OVERVIEW.BULK_ACTION.BUTTON.SELECTION_TOTAL":
		"{selection, plural, =0 {Geen contacten} one {1 contact} other {# contacten}} geselecteerd.",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.BUTTON.TITLE": "Labels",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.ERROR":
		"Er is iets fout gegaan bij het bijwerken van de contactlabels.",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ACTIONS.ADD": "Toevoegen",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ACTIONS.DELETE": "Verwijderen",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ITEM_SELECTED":
		"{count, plural, =0 {Geen labels} one {1 label} other {# labels}} geselecteerd.",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.NOT_FOUND": "Geen contactlabels gevonden.",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.SEARCH_PLACEHOLDER": "Zoek op contactlabel",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.TITLE": "Label als",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.SUCCESS":
		"Contactlabels van {count, plural, one {1 contact} other {# contacten}} succesvol bijgewerkt.",
	"CONTACT.OVERVIEW.BULK_ACTION.DELETE.BUTTON.TITLE": "Verwijderen",
	"CONTACT.OVERVIEW.BULK_ACTION.SOFT_DELETE.ERROR": "Er is iets fout gegaan bij het verwijderen in bulk.",
	"CONTACT.OVERVIEW.BULK_ACTION.SOFT_DELETE.SUCCESS":
		"{count, plural, one {1 contact} other {# contacten}} succesvol verwijderd.",
	"CONTACT.OVERVIEW.BULK_ACTION.FORCE_DELETE.ERROR": "Er is iets fout gegaan bij het verwijderen in bulk.",
	"CONTACT.OVERVIEW.BULK_ACTION.FORCE_DELETE.SUCCESS":
		"{count, plural, one {1 contact} other {# contacten}} succesvol verwijderd.",
	"CONTACT.OVERVIEW.BULK_ACTION.RESTORE.BUTTON.TITLE": "Herstellen",
	"CONTACT.OVERVIEW.BULK_ACTION.RESTORE.ERROR": "Er is iets fout gegaan bij het herstellen in bulk.",
	"CONTACT.OVERVIEW.BULK_ACTION.RESTORE.SUCCESS":
		"{count, plural, one {1 contact} other {# contacten}} succesvol hersteld.",
	"CONTACT.OVERVIEW.BULK_ACTION.UNARCHIVE.ERROR": "Er is iets fout gegaan bij het dearchiveren in bulk.",
	"CONTACT.OVERVIEW.BULK_ACTION.UNARCHIVE.SUCCESS":
		"{count, plural, one {1 contact} other {# contacten}} succesvol gedearchiveerd.",
	"CONTACT.OVERVIEW.SELECT.TYPE.CUSTOMER": "Klant",
	"CONTACT.OVERVIEW.SELECT.TYPE.OTHER": "Prospect",
	"CONTACT.OVERVIEW.SELECT.TYPE.PROSPECT": "Andere",
	"CONTACT.OVERVIEW.SHOW_DELETED.TITLE": "Toon verwijderde contacten",
	"CONTACTS.CSV.MAP.INFO.DUPLICATE_EMAIL.TITLE":
		"Houd er rekening mee dat elk geïmporteerd contact een uniek e-mailadres moet hebben. Contacten met hetzelfde e-mailadres worden niet opgenomen in de import.",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.FAILED": "Adres kon niet worden toegevoegd.",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.SUCCESS": "Adres succesvol toegevoegd.",
	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.BUTTON": "Adres verwijderen",
	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.DESCRIPTION": "Weet je zeker dat je dit adres wilt verwijderen?",
	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.TITLE": "Verwijder adres",
	"CONTACTS.DETAIL.ATTRIBUTES.DELETE.ADDRESS.FAILED": "Adres kon niet worden verwijderd",
	"CONTACTS.DETAIL.ATTRIBUTES.DELETE.ADDRESS.SUCCESS": "Adres succesvol verwijderd",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.BUTTON": "Verwijder relatie",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.DESCRIPTION": "Weet je zeker dat je deze relatie wil verwijderen?",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.ERROR":
		"Er is iets fout gegaan bij het verwijderen van de relatie. Probeer het later opnieuw.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.SUCCESS": "Relatie succesvol verwijderd.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.TITLE": "Verwijder deze relatie",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.COHABITATION_DATE.REQUIRED":
		"Datum wettelijk samenwonend is verplicht.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.DIVORCE_DATE.REQUIRED": "Scheidingsdatum is verplicht.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.WEDDING_DATE.REQUIRED": "Trouwdatum is verplicht.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.WIDOW_DATE.REQUIRED":
		"Datum waarop je weduwe/weduwnaar werd is verplicht.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.ERROR":
		"Er is iets fout gegaan bij het bijwerken van de relatie. Probeer het later opnieuw.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.SUCCESS": "Relatie succesvol bijgewerkt.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.FAILED": "Contact kon niet worden gelinkt.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.SUCCESS": "Contact succesvol gelinkt.",
	"CONTACTS.DETAIL.ATTRIBUTES.SAVE.FAILED": "Contactinfo kon niet worden bijgewerkt.",
	"CONTACTS.DETAIL.ATTRIBUTES.SAVE.FAILED.DUPLICATE.DESCRIPTION":
		"De inhoud van dit veld moet uniek zijn binnen deze organisatie.",
	"CONTACTS.DETAIL.ATTRIBUTES.SAVE.SUCCESS": "Contact succesvol opgeslagen.",
	"CONTACTS.DETAIL.ATTRIBUTES.TYPE.ERROR": "Er is iets fout gegaan bij het bijwerken van het contacttype.",
	"CONTACTS.DETAIL.ATTRIBUTES.TYPE.SUCCESS": "Het contacttype is succesvol bijgewerkt.",
	"CONTACTS.DETAIL.ATTRIBUTES.UPDATE.ADDRESS.FAILED": "Adres kon niet worden opgeslagen.",
	"CONTACTS.DETAIL.ATTRIBUTES.UPDATE.ADDRESS.SUCCESS": "Adres succesvol opgeslagen.",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.BUTTON": "Bankrekeningnummer verwijderen",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.DESCRIPTION":
		"Ben je er zeker van dat je dit bankrekeningnummer wil verwijderen?",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.TITLE": "Bankrekeningnummer verwijderen",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IS_REFUND_ACCOUNT.LABEL": "Terugbetalingen op dit rekeningnummer",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.BUTTON": "Buitenlands vastgoed verwijderen",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.DESCRIPTION":
		"Ben je er zeker van dat je dit buitenlands vastgoed wil verwijderen?",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.TITLE": "Buitenlands vastgoed verwijderen",
	"CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.BUTTON": "Verwijder verzekering",
	"CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.DESCRIPTION":
		"Ben je er zeker van dat je deze verzekering wil verwijderen?",
	"CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.TITLE": "Verzekering verwijderen",
	"CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.ADD.FAILED": "Dossierbeheerder kon niet worden toegevoegd.",
	"CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.REMOVE.FAILED": "Dossierbeheerder kon niet verwijderd worden.",
	"CONTACTS.DETAIL.MODAL.LABELS.ADD.FAILED": "Label kon niet worden toegevoegd.",
	"CONTACTS.DETAIL.MODAL.LABELS.REMOVE.FAILED": "Label kon niet verwijderd worden.",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.FAILED": "Contact kon niet gearchiveerd worden.",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.SUCCESS": "Contact succesvol gearchiveerd.",
	"CONTACTS.DETAIL.SETTINGS.DEARCHIVE.FAILED": "Contact kon niet gearchiveerd worden.",
	"CONTACTS.DETAIL.SETTINGS.DEARCHIVE.SUCCESS": "Contact succesvol gedearchiveerd.",
	"CONTACTS.DETAIL.STATE.ARCHIVED": "Dit is een gearchiveerd contact.",
	"CONTACTS.DETAIL.STATE.ARCHIVED.UNARCHIVE.ERROR": "Er is iets fout gegaan bij het dearchiveren.",
	"CONTACTS.DETAIL.STATE.ARCHIVED.UNARCHIVE.SUCCESS": "Contact succesvol gedearchiveerd.",
	"CONTACTS.DETAIL.STATE.DELETED": "Dit is een verwijderd contact.",
	"CONTACTS.DETAIL.STATE.DELETED.RESTORE.ERROR": "Er is iets fout gegaan bij het herstellen van dit contact.",
	"CONTACTS.DETAIL.STATE.DELETED.RESTORE.SUCCESS": "Contact succesvol hersteld.",
	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.CRMTYPE": "Selecteer CRM-type",
	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.CRMTYPE.PLACEHOLDER": "Selecteer CRM-type",
	"CONTACTS.IMPORT.AUTO_SYNC.DESCRIPTION":
		"Voer deze synchronisatie dagelijks uit om je Bothive contacten up-to-date te houden.",
	"CONTACTS.IMPORT.AUTO_SYNC.ENABLE.TITLE": "Voer dagelijkse import uit",
	"CONTACTS.IMPORT.AUTO_SYNC.ERROR": "Synchronisatie mislukt.",
	"contacts.import.auto_sync.sync_only_existing_contacts":
		"Synchroniseer alleen contacten die al aanwezig zijn in Bothive",
	"CONTACTS.IMPORT.AUTO_SYNC.ERROR_DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantenservice.",
	"CONTACTS.IMPORT.AUTO_SYNC.NAME.TITLE": "Importnaam",
	"CONTACTS.IMPORT.AUTO_SYNC.TITLE": "Auto synchronisatie",
	"CONTACTS.IMPORT.IMPORT.LONG_RUNNING":
		"De contactimport wordt uitgevoerd op de achtergrond. Je kan dit venster sluiten en verder werken.",
	"CONTACTS.IMPORT.OVERVIEW.WARN_DUPLICATES":
		"Let op: het is mogelijk dat de uiteindelijke import minder contacten bevat omdat dubbele contacten automatisch in Bothive worden samengevoegd tot één contact.",
	"CONTACTS.IMPORT.SETTINGS.EXISTING.CONTACT_SYNC.CONTEXT":
		"Let op: je stelde eerder al automatische labeling in voor een groep van geïmporteerde contacten met dezelfde filters. Bekijk je huidige labelinstellingen hieronder. Wanneer je nieuwe regels instelt, zullen je huidige labelinstellingen automatisch met de nieuwe overschreven worden.",
	"CONTACTS.IMPORT.VALIDATE.SKIP.TOOLTIP.ALL_INCOMPLETE":
		"Alle geselecteerde contacten bevatten ontbrekende informatie.",
	"CONTACTS.INTEGRATION.IMPORT.ADMIN_PULSE.OWN_COMPANY": "Eigen bedrijf",
	"CONTACTS.INTEGRATION.IMPORT.ADMIN_PULSE.SELECT.TYPE.COMPANY": "Bedrijf",
	"CONTACTS.INTEGRATION.IMPORT.ADMIN_PULSE.SELECT.TYPE.PERSON": "Persoon",
	"CONTACTS.INTEGRATION.IMPORT.ADMIN_PULSE.SELECT.TYPE.SOLE_PROPRIETOR": "Eenmanszaak",
	"CONTACTS.INTEGRATION.IMPORT.SETTINGS.LABEL.TITLE": "Voeg contactlabel toe",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_ARCHIVED": "Een contact met dit {fields} werd gearchiveerd.",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_ARCHIVED.UNARCHIVE.ERROR":
		"Er is iets fout gegaan bij het dearchiveren.",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_ARCHIVED.UNARCHIVE.SUCCESS": "Contact succesvol gedearchiveerd.",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_DELETED": "Een contact met dit {fields} werd verwijderd.",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_DELETED.RESTORE.ERROR": "Er is iets fout gegaan bij het herstellen.",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_DELETED.RESTORE.SUCCESS": "Contact succesvol hersteld.",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.VIEW_CONTACT": "Contact bekijken",
	"CONTACTS.OVERVIEW.ARCHIVE.BULK_ACTION.DELETE.BUTTON.DISABLED.TOOLTIP":
		"{count, plural, one { 1 contact} other {# contacten}} binnen je selectie is/zijn reeds verwijderd. Je kan geen contacten verwijderen die je eerder al hebt verwijderd.",
	"CONTACTS.OVERVIEW.ARCHIVED.EMPTY.TITLE": "Geen gearchiveerde contacten gevonden.",
	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.BUTTON.TITLE": "Archiveren",
	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.BUTTON": "Archiveer contacten",
	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.DESCRIPTION":
		"Wanneer je een contact archiveert, is het contact niet meer zichtbaar in de zoekresultaten en kan je er geen automatiseringen meer op uitvoeren. Nieuwe data wordt wel nog steeds bij het gearchiveerd contact opgeslagen.",
	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.TITLE": "Archiveer contacten",
	"CONTACTS.OVERVIEW.BULK_ACTION.RESTORE.BUTTON.DISABLED.TOOLTIP":
		"{count, plural, Éen contact other {# contacten}} binnen je selectie is/zijn gearchiveerd en niet verwijderd. Je kan enkel verwijderde contacten herstellen.",
	"CONTACTS.OVERVIEW.BULK_ACTION.RESTORE.BUTTON.TITLE": "Herstellen",
	"CONTACTS.OVERVIEW.BULK_ACTION.RESTORE_AS_ARCHIVED.BUTTON.TITLE": "Herstellen als gearchiveerd",
	"CONTACTS.OVERVIEW.BULK_ACTION.UNARCHIVE.BUTTON.DISABLED.TOOLTIP":
		"{count, plural, Éen contact other {# contacten}} binnen je selectie is/zijn verwijderd en niet gearchiveerd. Je kan enkel gearchiveerde contacten dearchiveren.",
	"CONTACTS.OVERVIEW.BULK_ACTION.UNARCHIVE.BUTTON.TITLE": "Dearchiveren",
	"CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.DESCRIPTION":
		"Verwijderde contacten blijven zichtbaar bij gearchiveerde contacten, rapporten, gekoppelde contacten en bestanden. Ze worden pas na een periode van 90 dagen definitief verwijderd.",
	"CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.OPTION.ARCHIVE":
		"Archiveer {count, plural, one {1 contact} other { # contacten}} in plaats van verwijderen",
	"CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.SIDE_EFFECTS.DESCRIPTION":
		"Let op: wanneer je een contact in Bothive verwijdert, maar het niet in je gekoppeld CRM-systeem verwijdert, zal het contact terug in Bothive geïmporteerd worden wanneer je er in je CRM-systeem aanpassingen aan doet.",
	"CONTACTS.OVERVIEW.IMPORT_CONTACT": "Contacten importeren",
	"CONTACTS.OVERVIEW.TABLE.ARCHIVED_AT": "Gearchiveerd op:",
	"CONTACTS.OVERVIEW.TABLE.DELETED_AT": "Verwijderd op:",
	"CONTACTS.OVERVIEW.TABLE.HARD_DELETE.MESSAGE":
		"{days, plural, =0 {Today} vandaag {{days} dag} other {{days} dagen}}",
	"CONTACTS.OVERVIEW.TABLE.HARD_DELETE.MESSAGE.TOOLTIP":
		"{days, plural, =0 {Dit contact wordt vandaag definitief verwijderd.} one {Dit contact wordt over één dag definitief verwijderd.} other {Dit contact wordt over {days} dagen definitief verwijderd.}",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.INPUT.COMPARISON.PLACEHOLDER`": "Selecteer een regel",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.INPUT.FIELD.PLACEHOLDER`": "Nog geen regels ingesteld.",
	"CONTACTS.SETTINGS.RULE.TABLE.EMPTY": "Nog geen regels ingesteld.",
	"CONTACT_DETAILS.NOT_FOUND.DESCRIPTION": "Het lijkt erop dat dit contact niet bestaat binnen je organisatie.",
	"CONTACT_DETAILS.NOT_FOUND.RETURN": "Terug",
	"CONTACT_DETAILS.NOT_FOUND.TITLE": "Geen contact gevonden.",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.ACCORDION_TITLE": "Contact verwijderen",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.BUTTON": "Contact verwijderen",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.DESCRIPTION":
		"Ben je er zeker van dat je dit contact wil verwijderen? Alle documenten, rapporten en gegevens die gelinkt zijn aan dit contact zullen definitief verwijderd worden na 90 dagen.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED": "Er is iets fout gegaan bij het opslaan van je herinnering.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantenservice.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_TO_CREATE":
		"Er is iets fout gegaan bij het aanmaken van je herinnering.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_TO_CREATE_DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantenservice.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_TO_UPDATE":
		"Er is iets fout gegaan bij het bijwerken van je herinnering.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_TO_UPDATE_DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantenservice.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FOOTER.RETURN": "Terug",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP2.MESSAGE": "Het bericht waarmee je je klant wil herinneren",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_1.CUTOFF_DATE.1": "Beëindig herinneringen automatisch na",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_1.CUTOFF_DATE.MIN_DATE":
		"De laatste herinnering moet minstens {firstReminderInterval} dagen na de eerste herinnering verzonden worden.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_1.CUTOFF_DATE.REQUIRED":
		"Selecteer een datum voor de laatste herinnering.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_1.SEND_FIRST_AFTER_DAYS.NUMBER": "Voer een afgerond cijfer in.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_2.BUTTON.SUBMIT": "Volgende",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_2.CHANNEL": "Het e-mailadres waarmee je de herinnering wil verzenden",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_2.DESCRIPTION":
		"Maak het bericht op dat je als herinnering wil verzenden.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_2.TITLE": "Herinneringen personenbelasting flow aanslagjaar 2024",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.SUCCESSFULLY_CREATED": "Herinnering succesvol aangemaakt.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.SUCCESSFULLY_UPDATED": "Herinnering succesvol bijgewerkt.",
	"FLOW.DETAIL.REMINDERS.EMPTY.BUTTON.TITLE": "Herinneringen instellen",
	"FLOW.DETAIL.REMINDERS.EMPTY.DESCRIPTION":
		"Verzend automatisch herinneringen tot alle gegevens worden aangeleverd.",
	"FLOW.DETAIL.REMINDERS.EMPTY.TITLE": "Automatische herinneringen",
	"FLOW.DETAIL.REMINDERS.HISTORY.FILTERS.SHOW_FAILED.LABEL": "Toon alleen gefaalde herinneringen",
	"FLOW.DETAIL.REMINDERS.HISTORY.LIST.EMPTY": "Nog geen herinneringen verzonden.",
	"FLOW.DETAIL.REMINDERS.HISTORY.LIST.FETCHED_ALL": "Alle verzonden herinneringen opgehaald",
	"FLOW.DETAIL.REMINDERS.HISTORY.LIST.ITEM.REMINDER_COUNT": "{count} herinnering",
	"FLOW.DETAIL.REMINDERS.HISTORY.LIST.ITEMS.LOAD_MORE": "Meer herinneringen ophalen",
	"FLOW.DETAIL.REMINDERS.HISTORY.LIST.LOAD_MORE": "Meer dagen ophalen",
	"FLOW.DETAIL.REMINDERS.HISTORY.TITLE": "Verzonden herinneringen",
	"FLOW.DETAIL.REMINDERS.HISTORY_LIST.TIME_SEPARATOR.TODAY": "Vandaag",
	"FLOW.DETAIL.REMINDERS.HISTORY_LIST.TIME_SEPARATOR.YESTERDAY": "Gisteren",
	"FLOW.DETAIL.REMINDERS.SETTINGS.ACTIONS.STOP.ERROR":
		"Er is iets fout gegaan bij het beëindigen van de herinneringen.",
	"FLOW.DETAIL.REMINDERS.SETTINGS.ACTIONS.STOP.SUCCESS": "Je herinneringen werden succesvol beëindigd.",
	"FLOW.DETAIL.REMINDERS.SETTINGS.BUTTON.EDIT": "Bewerken",
	"FLOW.DETAIL.REMINDERS.SETTINGS.BUTTON.STOP": "Herinneringen beëindigen",
	"FLOW.DETAIL.REMINDERS.SETTINGS.STATUS":
		"Verzend {total, plural, =0 {<b>geen</b> herinneringen} één {<b>#</b> herinnering} other {in totaal <b>#</b> herinneringen}} met telkens {days, plural, =0 {<b>no</b> dagen} één {<b>#</b> dag} other {<b>#</b> dagen}} tussen elke herinnering. De herinneringen worden automatisch beëindigd wanneer alle gegevens werden aangeleverd, of wanneer de datum van de laatste herinnering is verstreken.",
	"FLOW.DETAIL.REMINDERS.SETTINGS.STOP_MODAL.BUTTON.STOP.TITLE": "Herinneringen beëindigen",
	"FLOW.DETAIL.REMINDERS.SETTINGS.STOP_MODAL.DESCRIPTION":
		"Wanneer je de herinneringen beëindigt, zal geen enkel contact nog een herinnering ontvangen.",
	"FLOW.DETAIL.REMINDERS.SETTINGS.STOP_MODAL.TITLE": "Herinneringen beëindigen",
	"FLOW.DETAIL.REMINDERS.SETTINGS.TABLE.COLUMN.TEMPLATE.DESCRIPTION": "Naam",
	"FLOW.DETAIL.REMINDERS.SETTINGS.TABLE.COLUMN.TEMPLATE.SUBJECT": "Onderwerp",
	"FLOW.DETAIL.REMINDERS.SETTINGS.TABLE.DEFAULT.TEMPLATE": "Standaard e-mail template",
	"FLOW.DETAIL.REMINDERS.SETTINGS.TITLE": "Instellingen",
	"FLOW.DETAIL.REMINDERS.STATUS.COUNT.FAILED": "Er is iets fout gegaan bij het verzenden van {count} herinneringen.",
	"FLOW.DETAIL.REMINDERS.STATUS.COUNT.GENERAL": "Er werden vandaag {count} herinneringen verzonden.",
	"FLOW.DETAIL.REMINDERS.STATUS.COUNT.SUCCESS": "{count} herinneringen werden succesvol verzonden.",
	"FLOW.REPORTS.CONFIGURATION.UPDATE.FAILED.DESCRIPTION": "Probeer later opnieuw of contacteer onze customer support",
	"FLOW.REPORTS.CONFIGURATION.UPDATE.FAILED.TITLE": "Flow kon niet bijgewerkt worden.",
	"FLOW.REPORTS.CONFIGURATION.UPDATE.SUCCESS.TITLE": "Flow succesvol bijgewerkt.",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.DESCRIPTION":
		"Ben je er zeker van dat je dit rapport wil verwijderen? Deze actie kan niet ongedaan gemaakt worden.",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.FAILED.DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantendienst.",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.FAILED.TITLE": "Rapport kon niet worden verwijderd.",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.LABEL.BUTTON_LABEL": "Rapport verwijderen",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.SUCCEEDED.TITLE": "Rapport verwijderd",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.TITLE": "Rapport verwijderen",
	"FLOWS.DEACTIVATED.TOOLTIP": "Deze flow werd gedeactiveerd en kan niet meer naar contacten verzonden worden.",
	"FLOWS.DETAIL.SETTINGS.DELETE_FAILED.TITLE": "De flow kon niet worden verwijderd. Probeer het later opnieuw.",
	"FLOWS.DETAIL.SETTINGS.DELETE_SUCCEEDED.TITLE": "Flow succesvol verwijderd.",
	"FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.COMPOSE": "E-mail inhoud",
	"FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.COMPOSE.ERROR_EMPTY": "Bericht inhoud is verplicht.",
	"FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.COMPOSE.SUBJECT": "Onderwerp",
	"FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.COMPOSE.SUBJECT.REQUIRED": "Onderwerp is verplicht.",
	"FLOWS.OVERVIEW.ADD_FLOW.BANNER.FAILED": "Flow kan momenteel niet worden aangemaakt. Probeer het later opnieuw.",
	"FLOWS.OVERVIEW.ADD_FLOW.FETCH_FAILED.TITLE": "Flows konden niet worden opgehaald.",
	"FLOWS.OVERVIEW.TABLE.BUTTON.COPY_FLOW_LINK.SUCCESS": "Link gekopieerd naar klembord.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.FAILED.DESCRIPTION":
		"Er is iets fout gegaan bij het in bulk aanpassen van het status type.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.FAILED.TITLE": "Bulk status type aanpassen mislukt.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.SUCCESS.TITLE": "Bulk rapport status aanpassen is gestart.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.FAILED.DESCRIPTION":
		"Er is iets fout gegaan bij het downloaden in bulk.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.FAILED.TITLE": "Bulk download mislukt.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.SUCCESS.DESCRIPTION":
		"Wanneer de bestanden verwerkt zijn, ontvang je een notificatie en een e-mail met de link om ze te downloaden.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.SUCCESS.TITLE": "Bulk download is gestart.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.FAILED.DESCRIPTION":
		"Het in bulk versturen naar de integratie is mislukt.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.FAILED.TITLE":
		"Bulk versturen naar integratie mislukt.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.SUCCESS.DESCRIPTION":
		"Wanneer de actie compleet is ontvang je een notificatie in je dashboard.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.SUCCESS.TITLE":
		"Bulk versturen naar integratie gestart.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SEND_TO_EMAIL": "E-mail verzenden",
	"FLOWS.REPORT.SETTINGS.FORM.FAILED.DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantendienst.",
	"FLOWS.REPORT.SETTINGS.FORM.FAILED.TITLE": "Flow kon niet worden aangepast.",
	"FLOWS.REPORT.SETTINGS.FORM.SUCCEEDED.TITLE": "Flow aangepast.",
	"FLOWS.REPORT.TEMPLATE.ASIDE.REMINDERS.LABEL": "Herinneringen",
	"FLOWS.REPORTS.STATUS_TYPES.COPY.FAILED":
		"Er is iets fout gegaan bij het kopiëren van de status types, probeer het later opnieuw.",
	"FLOWS.REPORTS.STATUS_TYPES.COPY.SUCCEEDED": "Status types succesvol gekopieerd.",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE.FAILED": "Status type kon niet verwijderd worden.",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE.SUCCEEDED": "Status type succesvol verwijderd",
	"FLOWS.REPORTS.STATUS_TYPES.REORDER_FAILED": "Sorteren is tijdelijk niet beschikbaar. probeer het later opnieuw.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.FAILED.DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantendienst.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.FAILED.TITLE":
		"Bijlagen konden niet worden gedownload.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.NO_RESULT.TITLE": "Geen bijlagen gevonden.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.SUCCEEDED.TITLE": "Bijlagen succesvol gedownload.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.FAILED.DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantendienst.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.FAILED.TITLE": "Rapport kon niet worden gedownload.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.SUCCEEDED.TITLE": "Rapport succesvol gedownload.",
	"FLOWS.REPORT_DETAIL.ACTIONS.EDIT_TOOLTIP.DISABLED":
		"Je kan het rapport enkel bewerken of downloaden wanneer je contact gestart is met het aanleveren van gegevens.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.BANNER.ASSIGN_USER_FAILED":
		"{username} kon niet worden toegewezen. Probeer het later opnieuw.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.BANNER.UNASSIGN_FAILED":
		"Er is iets fout gegaan bij het aanpassen van je toewijzing. Probeer het later opnieuw.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.BANNER.FAILED":
		"Status type kon niet worden aangepast. Probeer het later opnieuw.",
	"FLOWS.REPORT_DETAIL.REPORT_LIST.FETCH_FAILED.TITLE": "Rapporten konden niet worden opgehaald.",
	"FLOWS.REPORT_DETAIL.UPDATE.FAILED.DESCRIPTION": "Probeer later opnieuw of neem contact op met onze klantendienst.",
	"FLOWS.REPORT_DETAIL.UPDATE.FAILED.TITLE": "Rapport kon niet worden aangepast.",
	"FLOWS.REPORT_DETAIL.UPDATE.SUCCESS.TITLE": "Rapport aangepast.",
	"flows.report_detail.files.empty": "Rapport heeft geen bestanden",
	"flows.report_detail.files.title": "Bestanden {total, plural, =0 { } other {(#)}}",
	"flows.report_detail.files.download.tooltip": "Download alle bestanden",
	"flows.report_detail.files.download.tooltip.disabled":
		"Wanneer je rapport bestanden bevat, kan je deze hier downloaden",
	"flows.report_detail.files.add_files.tooltip": "Upload nieuwe bestanden naar het rapport",
	"flows.report_detail.files.download_attachments.button.banner.started": "Download is begonnen, dit kan even duren",
	"flows.report_detail.files.download_attachments.button.banner.no_result.title": "Geen bestanden gevonden",
	"flows.report_detail.files.download_attachments.file_name": "{name}_bestanden",
	"flows.report_detail.files.download_attachments.button.banner.succeeded.title": "Bestanden downloaden",
	"flows.report_detail.files.download_attachments.button.banner.error_404.title": "Geen bestanden gevonden",
	"flows.report_detail.files.download_attachments.button.banner.error_404.description":
		"Probeer later opnieuw of neem contact met ons op",
	"flows.report_detail.files.download_attachments.button.banner.generic_error.title":
		"Bestanden konden niet gedownload worden",
	"flows.report_detail.files.download_attachments.button.banner.generic_error.description":
		"Probeer later opnieuw of neem contact met ons op",

	"flows.report_detail.files.upload_modal.failed":
		"We kunnen dit bestand tijdelijk niet uploaden, probeer het later opnieuw",
	"flows.report_detail.files.upload_modal.title": "Upload bestanden",
	"flows.report_detail.files.upload_modal.label.label": "Bestandslabel",
	"flows.report_detail.files.upload_modal.label.required_error": "Selecteer welk bestand u wilt uploaden",
	"flows.report_detail.files.upload_modal.file.label": "Bestanden",
	"flows.report_detail.files.upload_modal.label.required_error": "Selecteer welk bestand u wilt uploaden",
	"flows.report_detail.files.upload_modal.footer.cancel": "Annuleren",
	"flows.report_detail.files.upload_modal.footer.submit": "Bestand opslaan",

	"FLOWS.SEND.COMPOSE.BUTTONS.NEXT": "Volgende",
	"FLOWS.SEND.COMPOSE.CHANNEL": "Kanaal",
	"FLOWS.SEND.COMPOSE.DESCRIPTION": "Stel een bericht samen om naar je contacten te verzenden.",
	"FLOWS.SEND.COMPOSE.EMAIL": "E-mail",
	"FLOWS.SEND.COMPOSE.PREVIOUS": "Vorige",
	"FLOWS.SEND.COMPOSE.SUBJECT": "Onderwerp",
	"FLOWS.SEND.COMPOSE.SUBJECT.REQUIRED": "Onderwerp is verplicht.",
	"FLOWS.SEND.COMPOSE.TITLE": "Bericht samenstellen",
	"FLOWS.SEND.CONTACT_SELECTION.ALERT":
		"{count, plural, =1 {# contact zal} other {# contacten zullen}} je bericht niet ontvangen omdat hun e-mailadres ontbreekt.",
	"FLOWS.SEND.CONTACT_SELECTION.BUTTONS.NEXT": "Volgende",
	"FLOWS.SEND.CONTACT_SELECTION.BUTTONS.SEND": "{value, plural, one {E-mail verzenden} other {E-mails verzenden}}",
	"FLOWS.SEND.CONTACT_SELECTION.DESCRIPTION": "Selecteer je contacten door ze te filteren.",
	"FLOWS.SEND.CONTACT_SELECTION.NO_EMAIL": "E-mailadres ontbreekt.",
	"FLOWS.SEND.CONTACT_SELECTION.SEND_TO_COMPLETED_REPORTS":
		"Verzend dit bericht ook naar contacten die de flow al vervolledigd hebben",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.CANNOT_BE_SENT": "E-mailadres ontbreekt of is ongeldig.",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.CAN_BE_SENT": "Verzenden mogelijk",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.CAN_BE_SENT": "Verzenden mogelijk",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.EMAIL": "E-mailadres",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.NAME": "Naam",
	"FLOWS.SEND.CONTACT_SELECTION.TITLE": "Contacten selecteren",
	"FLOWS.SEND.OVERVIEW.CHANNEL": "Kanaal:",
	"FLOWS.SEND.OVERVIEW.DESCRIPTION":
		"Kijk je bericht en je geselecteerde contacten na vooraleer je de e-mail verzendt.",
	"FLOWS.SEND.OVERVIEW.EMAIL": "E-mail:",
	"FLOWS.SEND.OVERVIEW.FLOW": "Flow:",
	"FLOWS.SEND.OVERVIEW.SEND_FLOW_FAILED.DESCRIPTION":
		"Er is iets misgegaan bij het verzenden van de flow. Probeer het later opnieuw of neem contact op met onze klantendienst.",
	"FLOWS.SEND.OVERVIEW.SEND_FLOW_FAILED.TITLE": "Flow kan niet worden verzonden.",
	"FLOWS.SEND.OVERVIEW.SUBJECT": "Onderwerp:",
	"FLOWS.SEND.OVERVIEW.TITLE": "Overzicht",
	"FLOWS.SEND.RESULT.CLOSE": "Sluiten",
	"FLOWS.SEND.RESULT.DESCRIPTION":
		"Je flow wordt momenteel naar alle geselecteerde contacten verstuurd. Dit kan tot 30 minuten duren. Je kan de status per rapport opvolgen in het rapportoverzicht onder ‘Progressie’. Je ontvangt een melding wanneer alle e-mails verzonden zijn.",
	"FLOWS.SEND.RESULT.REPORTS": "Ga naar rapporten",
	"FLOWS.SEND.RESULT.TITLE": "Je flow wordt momenteel naar alle geselecteerde contacten verstuurd.",
	"FLOWS.SEND.STEP.COMPOSE_MESSAGE": "Bericht samenstellen",
	"FLOWS.SEND.STEP.CONTACT_SELECT": "Contacten selecteren",
	"FLOWS.SEND.STEP.OVERVIEW": "Overzicht",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.EMPTY": "Er zijn geen e-mailadressen aan je gebruiker gekoppeld.",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.EMPTY.ADD": "E-mailadres toevoegen",
	"FLOW_DELETE_MODAL.DELETE.BUTTON_LABEL": "Flow verwijderen",
	"FLOW_DELETE_MODAL.HEADER.DESCRIPTION":
		"Ben je er zeker van dat je deze flow wil verwijderen? Deze actie kan niet ongedaan gemaakt worden. Alle links naar de flow worden gedeactiveerd.",
	"FLOW_DELETE_MODAL.HEADER.TITLE": "Flow verwijderen",
	"FLOW_DETAIL.REPORT.BUTTON.AUTOMATED_REMINDERS": "Automatische herinneringen",
	"FLOW_DETAIL.REPORT.BUTTON.MANUAL_REMIND": "Manuele herinneringen per contact",
	"FLOW_DETAIL.REPORT.BUTTON.REMINDER_OPTIONS": "Opties voor herinneringen",
	"IMPORT_CONTACTS_MODAL.INTEGRATIONLIST.REFETCH": "Opnieuw ophalen",
	"INBOX.COMING_SOON.DESCRIPTION":
		"Werk e-mails sneller af door efficiënt samen te werken in de gedeelde team inbox.",
	"INBOX.COMING_SOON.FEATURES.ASSIGN": "Wijs e-mails aan teamleden toe.",
	"INBOX.COMING_SOON.FEATURES.INBOX_ZERO": "Creëer rond elke e-mail een interne chatconversatie.",
	"INBOX.COMING_SOON.FEATURES.TEAM_INBOX": "Bereik inbox zero door optimaal samen te werken.",
	"INBOX.COMING_SOON.TITLE": "Nu in Publieke beta",
	"INTEGRATION.CONTACTS.IMPORT.OPTIONS.TABLE.CONDITION": "Status",
	"INTEGRATION.CONTACTS.IMPORT.OPTIONS.TABLE.EDIT": "Bewerken",
	"INTEGRATION.CONTACTS.IMPORT.OPTIONS.TABLE.LAST_SYNC": "Laatst gesynchroniseerd op:",
	"INTEGRATION.CONTACTS.IMPORT.OPTIONS.TABLE.LAST_SYNC.NOT_EXECUTED_YET": "Nog geen synchronisatie uitgevoerd.",
	"INTEGRATION.CONTACTS.IMPORT.OPTIONS.TABLE.UPCOMING_SYNC": "Volgende synchronisatie op:",
	"INTEGRATIONS.CONTACTS.IMPORT.OPTIONS.TABLE.STATE.ACTIVE": "Actief",
	"INTEGRATIONS.CONTACTS.IMPORT.OPTIONS.TABLE.STATE.INACTIVE": "Inactief",
	"ORGANIZATION_SETTINGS.GENERAL.SUGGESTIONS.TITLE": "Gerelateerde instellingen",
	"POPUP.BUTTON.DELETE": "Verwijderen",
	"POPUP.PLACEHOLDER.ARCHIVE": "Typ ARCHIVEREN en klik op ARCHIVEREN.",
	"RYNL+m": "Label",
	"SETTINGS.ADVANCED.EXPERIMENTAL_FEATURES.SUBMIT": "Opslaan",
	"SETTINGS.CONTACTS.LABELS.EMPTY": "Geen labels gevonden.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.BUTTON": "Verwijder integratie",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.DESCRIPTION":
		"Ben je er zeker van dat je de koppeling met AdminPulse wil verwijderen? De functionaliteiten van deze integratie zullen niet meer beschikbaar zijn vanaf het moment waarop je ze verwijdert.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.TITLE": "Verwijder integratie met AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.FORM_SECTION.DESCRIPTION":
		"Door met AdminPulse te koppelen importeer je beschikbare contactgegevens in Bothive en andersom.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.FEATURES.SYNC_CONTACTS": "Contacten importeren en synchroniseren",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.OAUTH2.BUTTON": "Log in met AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.OAUTH2.DESCRIPTION": "Koppel met AdminPulse via de AdminPulse website.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.TITLE": "AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.SYNC_CONTACTS.BUTTON_LABEL": "Contacten importeren",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.SYNC_CONTACTS.DESCRIPTION":
		"Importeer automatisch je contactdata vanuit AdminPulse naar Bothive en andersom.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.SYNC_CONTACTS.TITLE": "Importeer AdminPulse contacten naar Bothive",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.ADD": "Toevoegen",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.ADD.SUCCESS": "Importoptie succesvol aangemaakt.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.ADD_RULE.BUTTON": "Regel toevoegen",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.DELETE": "Verwijderen",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.DELETE.ERROR":
		"Er is iets fout gegaan bij het verwijderen van de importoptie.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.DELETE.SUCCESS": "Importoptie succesvol verwijderd.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.FILTERS.TITLE": "Filters",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.FILTERS.TITLE.TOOLTIP":
		"Hier maak je de filters die je aan een selectie van deze contacten wil toevoegen.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.IMPORT_RULES.TITLE": "Importregels",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.IMPORT_RULES.TITLE.TOOLTIP":
		"Hier maak je de labels en de regels aan die je aan een selectie van deze contacten wil toevoegen.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.IS_ACTIVE": "Is actief",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.LABEL_RULES_TABLE.TITLE": "Labelregels",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.LABEL_TO_APPLY": "Label toevoegen",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.NAME": "Naam importoptie",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.NAME.REQUIRED": "Naam is verplicht.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.SYNC_FILES":
		"Synchroniseer ook de bijhorende bestanden van deze contacten",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.TITLE": "Importoptie toevoegen",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.UPDATE": "Bijwerken",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.UPDATE.ERROR":
		"Er is iets fout gegaan bij het opslaan van deze importoptie.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.UPDATE.SUCCESS": "Importoptie succesvol bijgewerkt.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.EDIT.MODAL.TITLE": "Importoptie bewerken",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.TITLE": "Importeer contacten automatisch",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.BUTTON": "Verwijder integratie",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.DESCRIPTION":
		"Ben je er zeker van dat je de koppeling met FID-Manager wil verwijderen? De functionaliteiten van deze integratie zullen niet meer beschikbaar zijn vanaf het moment waarop je ze verwijdert.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.TITLE": "Verwijder integratie met FID-Manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.FORM_SECTION.DESCRIPTION":
		"Koppel met FID-Manager om contactdata aan te vullen in Bothive en andersom. De contactinfo wordt automatisch met beide platformen gesynchroniseerd om steeds up-to-date te blijven.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.FORM_SECTION.TITLE": "Bothive is gekoppeld met FID-Manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.DESCRIPTION": "Koppel je FID-Manager contacten met Bothive.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FEATURES.SYNC_CONTACTS": "Contacten importeren en synchroniseren",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.BASE_URL.LABEL": "URL",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.BASE_URL.PLACEHOLDER": "https://bedrijfsnaam.fid-manager.be/nl",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.BASE_URL.REQUIRED": "Voer je bedrijfsspecifieke URL in.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.LABEL": "Login",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.PLACEHOLDER": "a000a000-000a-0000-a0aa-aaa00a00a0000",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.REQUIRED": "Voer je FID-Manager login in.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.PASSWORD.LABEL": "Wachtwoord",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.PASSWORD.REQUIRED": "Voer je FID-Manager wachtwoord in.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.SUBMIT": "Koppel met FID-Manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.TITLE": "FID-Manager",
	"SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.BUTTON": "Log in met Liantis",
	"SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.DESCRIPTION":
		"Koppel Bothive met Liantis om elke KBO-oprichting te automatiseren.",
	"SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.TITLE": "Koppel met Liantis",
	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.BUTTON": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.DESCRIPTION":
		"Ben je er zeker van dat je de koppeling met Liantis wil stopzetten? De functionaliteiten van deze integratie zullen niet meer beschikbaar zijn vanaf het moment waarop je ze verwijdert.",
	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.LIANTIS.FORM_SECTION.DESCRIPTION":
		"Stuur het rapport uit de onboarding flow met één klik door naar Liantis en automatiseer elke KBO-oprichting.",
	"SETTINGS.INTEGRATIONS.LIANTIS.FORM_SECTION.TITLE": "Bothive is gekoppeld met Liantis",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.BUTTON": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.DESCRIPTION":
		"Ben je er zeker van dat je de koppeling met TwinnTax wil stopzetten? De functionaliteiten van deze integratie zijn niet langer beschikbaar vanaf het moment waarop je ze verwijdert.",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM_SECTION.TITLE": "Bothive is gekoppeld met Twinntax",
	"SETTINGS.INTEGRATIONS.TWINNTAX.SYNC_CONTACTS.BUTTON_LABEL": "Importeer contacten",
	"SETTINGS.INTEGRATIONS.TWINNTAX.SYNC_CONTACTS.DESCRIPTION":
		"Importeer je TwinnTax contactdata in Bothive om de personenbelasting flow vooraf aan te vullen met gekende MyMinfin data per contact.",
	"SETTINGS.INTEGRATIONS.TWINNTAX.SYNC_CONTACTS.TITLE": "TwinnTax contactdata importeren",
	"SETTINGS.MEMBERS.INVITE.TITLE": "Gebruiker uitnodigen",
	"SETTINGS.NOTIFICATIONS.BILLING.FORM.EMAIL.EMAIL_ERROR": "Vul een geldig e-mailadres in.",
	"SETTINGS.ORGANIZATION.MEMBER.ADD.FAILED":
		"Er is iets fout gegaan bij het uitnodigen van deze gebruiker. Probeer het later opnieuw.",
	"SETTINGS.ORGANIZATION.MEMBER.ADD.LIMIT":
		"Je hebt het maximaal aantal gebruikers in je licentie bereikt. Verhoog je licentie om meer gebruikers aan je team toe te voegen.",
	"SETTINGS.ORGANIZATION.MEMBER.ADD.SUCCESS": "Uitnodiging succesvol verstuurd.",
	"SETTINGS.ORGANIZATION.MEMBER.EXISTS": "Deze gebruiker maakt al deel uit van je team.",
	"SETTINGS.ORGANIZATION.SOCIALS.UPDATE.FAILED":
		"Er is iets fout gegaan bij opslaan van je aanpassingen. Probeer het later opnieuw.",
	"SETTINGS.ORGANIZATION.SOCIALS.UPDATE.SUCCESS": "Je social media werd succesvol opgeslagen.",
	"SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.BUTTON": "Bedrijfsgegevens beheren",
	"SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.DESCRIPTION": "Beheer en bewerk je algemene bedrijfsinstellingen.",
	"SETTINGS.SUGGESTIONS.OPENING_HOURS.BUTTON": "Openingsuren beheren",
	"SETTINGS.SUGGESTIONS.OPENING_HOURS.DESCRIPTION": "Beheer en je bewerk je openingsuren.",
	"SETTINGS.USERS_POPUP.EMAIL.HELP": "Geen e-mail ontvangen? Bekijk je spamfolder of contacteer onze klantendienst.",
	"banner.failed.settings_update":
		"Er is iets fout gegaan bij het opslaan van jouw aanpassingen. Probeer het later opnieuw.",
	"component.openings_hours.add_new_time": "Openingstijd toevoegen",
	"component.openings_hours.and": "en van",
	"component.openings_hours.closed": "Gesloten",
	"component.openings_hours.from": "Van",
	"contact.import.settings.date.restriction":
		"Op dit moment zijn de enige ondersteunde formaten voor datums: <list></list>. Andere formaten worden niet toegelaten.",
	"contact.type.anonymous.title": "Anonieme gebruiker",
	"contact.type.customer.title": "Klant",
	"contact.type.linked_contact.title": "Gelinkt contact",
	"contact.type.other.title": "Andere",
	"contact.type.prospect.title": "Prospect",
	"contacts.archived_overview.bulk_action.export.failed.title":
		"Er is iets fout gegaan bij het importeren van je contacten.",
	"contacts.archived_overview.bulk_action.export.filename": "Gearchiveerde contacten",
	"contacts.archived_overview.bulk_action.export.form.type.help": "Gewenst bestandstype voor de export",
	"contacts.archived_overview.bulk_action.export.form.type.label": "Bestandstype",
	"contacts.archived_overview.bulk_action.export.info_banner":
		"Het importeren van je contacten kan even duren. Sluit je browser niet af vooraleer alle contacten geïmporteerd zijn.",
	"contacts.archived_overview.bulk_action.export.modal.footer.cancel": "Annuleren",
	"contacts.archived_overview.bulk_action.export.modal.footer.submit": "Importeren",
	"contacts.archived_overview.bulk_action.export.modal.title": "Contacten importeren",
	"contacts.detail.attributes.bank_accounts.add_button": "Bankrekeningnummer toevoegen",
	"contacts.detail.attributes.bank_accounts.title": "Bankrekeningnummers en cryptomunten",
	"contacts.detail.attributes.email_address.label.billing": "Facturatie",
	"contacts.detail.attributes.email_address.label.other": "Andere",
	"contacts.detail.attributes.email_address.label.private": "Privé",
	"contacts.detail.attributes.email_address.label.standard": "Standaard",
	"contacts.detail.attributes.email_address.label.work": "Werk",
	"contacts.detail.attributes.email_addresses.add.address": "E-mailadres toevoegen",
	"contacts.detail.attributes.email_addresses.add.address.primary.label": "Primair",
	"contacts.detail.attributes.email_addresses.add.address.primary.tooltip":
		"Maak eerst een ander e-mailadres primair.",
	"contacts.detail.attributes.email_addresses.add.delete_button.tooltip":
		"Een primair e-mailadres kan niet worden verwijderd.",
	"contacts.detail.attributes.email_addresses.address.invalid_error": "Voer een geldig e-mailadres in.",
	"contacts.detail.attributes.email_addresses.address.title": "Adres",
	"contacts.detail.attributes.email_addresses.form.error": "Voer minstens 1 geldig e-mailadres in.",
	"contacts.detail.attributes.email_addresses.label.title": "Label",
	"contacts.detail.attributes.email_addresses.submit": "Opslaan",
	"contacts.detail.attributes.email_addresses.update.failed.description": "Dit adres werd eerder al toegevoegd.",
	"contacts.detail.attributes.email_addresses.update.failed.title": "E-mailadres kon niet opgeslagen worden.",
	"contacts.detail.attributes.email_addresses.update.success.title": "E-mailadres succesvol opgeslagen.",
	"contacts.detail.attributes.phone.add.number": "Telefoonnummer toevoegen",
	"contacts.detail.attributes.phone.delete_button.tooltip": "Een primair telefoonnummer kan niet worden verwijderd.",
	"contacts.detail.attributes.phone.label.company": "Bedrijf",
	"contacts.detail.attributes.phone.label.home": "Vast nummer",
	"contacts.detail.attributes.phone.label.mobile": "Mobiel",
	"contacts.detail.attributes.phone.label.other": "Andere",
	"contacts.detail.attributes.phone.label.private": "Privé",
	"contacts.detail.attributes.phone.label.standard": "Standaard",
	"contacts.detail.attributes.phone.label.title": "Label",
	"contacts.detail.attributes.phone.label.whatsapp": "WhatsApp",
	"contacts.detail.attributes.phone.number.invalid_error": "Voer een geldig telefoonnummer in.",
	"contacts.detail.attributes.phone.number.label": "Telefoonnummer",
	"contacts.detail.attributes.phone.primary.label": "Primair",
	"contacts.detail.attributes.phone.primary.tooltip": "Maak eerst een ander telefoonnummer primair.",
	"contacts.detail.attributes.phone.submit": "Opslaan",
	"contacts.detail.attributes.phone.update.failed.description": "Dit telefoonnummer werd eerder al toegevoegd.",
	"contacts.detail.attributes.phone.update.failed.title":
		"Er is iets fout gegaan bij het opslaan van dit telefoonnummer.",
	"contacts.detail.attributes.phone.update.success.title": "Telefoonnummer succesvol opgeslagen.",
	"contacts.detail.attributes.save.failed": "Er is iets fout gegaan bij het bijwerken van de contactinfo.",
	"contacts.detail.attributes.save.failed.duplicate.description":
		"De inhoud van dit veld moet uniek zijn in deze organisatie",
	"contacts.detail.attributes.save.failed.duplicate.title": "Er is iets fout gegaan bij het opslaan van het contact.",
	"contacts.detail.attributes.save.success": "Contact succesvol opgeslagen.",
	"contacts.detail.PROPERTIES_MODAL.form.building_type.error_required": "Type woonst is verplicht.",
	"contacts.detail.PROPERTIES_MODAL.form.building_type.label": "Type woonst",
	"contacts.detail.PROPERTIES_MODAL.form.cadastral_income.label": "Kadastraal inkomen",
	"contacts.detail.PROPERTIES_MODAL.form.has_re_mvp_split.label":
		"Opsplitsing tussen onroerende en roerende huur in het huurcontract",
	"contacts.detail.PROPERTIES_MODAL.form.is_co_ownership.label": "Eigendom heeft meerdere eigenaars",
	"contacts.detail.PROPERTIES_MODAL.form.is_rented_out.label": "Eigendom wordt verhuurd",
	"contacts.detail.PROPERTIES_MODAL.form.is_sold.label": "Eigendom is reeds verkocht",
	"contacts.detail.PROPERTIES_MODAL.form.name.help":
		"Geef het vastgoed een unieke naam waaraan je het kan herkennen.",
	"contacts.detail.PROPERTIES_MODAL.form.name.label": "Naam",
	"contacts.detail.PROPERTIES_MODAL.form.name.placeholder": "Villa in Spanje",
	"contacts.detail.PROPERTIES_MODAL.form.original_value.help":
		"De originele waarde van dit eigendom voor de renovatie.",
	"contacts.detail.PROPERTIES_MODAL.form.original_value.label": "Originele waarde",
	"contacts.detail.PROPERTIES_MODAL.form.ownership_type.error_required": "Type eigendom is verplicht.",
	"contacts.detail.PROPERTIES_MODAL.form.ownership_type.label": "Type eigendom",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_movable_property.label": "Percentage roerend goed verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_private_rented.label": "Percentage privéverhuur",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_professionally_rented.label": "Percentage professioneel verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_real_estate.label": "Percentage onroerend goed verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.possible_rent_amount.help":
		"De totale huurinkomsten mochten alle kamers/appartementen/... verhuurd zijn",
	"contacts.detail.PROPERTIES_MODAL.form.possible_rent_amount.label": "Waarde indien verhuurd",
	"contacts.detail.PROPERTIES_MODAL.form.property_value.help":
		"De huidige waarde van dit eigendom inclusief renovatie",
	"contacts.detail.PROPERTIES_MODAL.form.property_value.label": "Huidige waarde",
	"contacts.detail.PROPERTIES_MODAL.form.purchase_date.label": "Aankoopdatum",
	"contacts.detail.PROPERTIES_MODAL.form.renovation_costs.label": "Renovatiekosten",
	"contacts.detail.PROPERTIES_MODAL.form.rent_amount.help": "De jaarlijkse huurinkomsten van dit eigendom",
	"contacts.detail.PROPERTIES_MODAL.form.rent_amount.label": "Huurinkomsten",
	"contacts.detail.PROPERTIES_MODAL.form.rent_type.error_required": "Type verhuur is verplicht.",
	"contacts.detail.PROPERTIES_MODAL.form.rent_type.help":
		"Het type verhuur van dit eigendom (privé of professioneel)",
	"contacts.detail.PROPERTIES_MODAL.form.rent_type.label": "Type verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.rented_with_furniture.label": "Wordt verhuurd met meubilair",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.error": "De verkoopdatum kan niet voor de aankoopdatum liggen.",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.help": "Datum waarop dit eigendom is verkocht",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.label": "Verkoopdatum",
	"contacts.detail.PROPERTIES_MODAL.form.type.error_required": "Type eigendom is verplicht.",
	"contacts.detail.PROPERTIES_MODAL.form.type.label": "Type eigendom",
	"contacts.detail.settings.restore.button": "Contact herstellen",
	"contacts.detail.settings.restore.description":
		"Dit contact is verwijderd en zal definitief worden verwijderd binnen <bold>{days, plural, =0 {Today} one {{days} day} other {{days} days}}</bold> als je dit contact niet herstelt voor deze datum. Als dit contact niet wordt verwijderd uit externe bronnen, is het mogelijk dat het opnieuw wordt toegevoegd wanneer het verandert in die bron nadat het definitief is verwijderd uit Bothive",
	"contacts.detail.settings.restore.extra.files": "Alle rapporten van dit contact",
	"contacts.detail.settings.restore.extra.linked_contacts": "Gelinkte contacten",
	"contacts.detail.settings.restore.extra.reports": "Rapporten",
	"contacts.detail.settings.restore.extra.title":
		"Als je dit contact verwijdert, zal deze data automatisch mee verwijderd worden:",
	"contacts.detail.settings.restore.failed":
		"Er is iets fout gegaan bij het herstellen van {fullName}. Probeer het later opnieuw.",
	"contacts.detail.settings.restore.succeeded": "{fullName} werd succesvol hersteld.",
	"contacts.detail.settings.restore.title": "Contact herstellen",
	"contacts.import.validate.csv_toolbar.split_name": "Namen splitsen",
	"contacts.import.validate.csv_toolbar.switch_name": "Namen omwisselen",
	"contacts.import.validate.csv_toolbar.title": "CSV-import hulpmiddelen",
	"contacts.import.validate.csv_toolbar.tooltip": "Deze actie uitvoeren op alle onvolledige contacten",
	"contacts.import.validate.skip.tooltip.return": "Als je teruggaat gaan je ingevulde gegevens verloren.",
	"contacts.import.validate.spit_name.cancel": "Annuleren",
	"contacts.import.validate.spit_name.modal.order.help":
		"Namen splitsen is een geautomatiseerd hulpmiddel dat helpt bij het splitsen van de contactnamen met een nauwkeurigheid van 85%.",
	"contacts.import.validate.spit_name.modal.order.label":
		"In welke volgorde staan de namen van de contacten geordend?",
	"contacts.import.validate.spit_name.modal.order.option.first_last_name": "<voornaam> <familienaam>",
	"contacts.import.validate.spit_name.modal.order.option.last_first_name": "<familienaam> <voornaam>",
	"contacts.import.validate.spit_name.submit": "Namen splitsen",
	"contacts.import.validate.split_name_modal.failed": "Er is iets fout gegaan bij het splitsen van de contactnamen.",
	"contacts.import.validate.split_name_modal.title": "Namen splitsen",
	"contacts.overview.bulk_action.download.failed.title":
		"Er is iets fout gegaan bij het importeren van je contacten.",
	"contacts.overview.bulk_action.download.filename": "Contacten",
	"contacts.overview.bulk_action.download.form.type.help": "Kies het gewenst bestandstype voor de export.",
	"contacts.overview.bulk_action.download.form.type.label": "Bestandstype",
	"contacts.overview.bulk_action.download.info_banner":
		"Het exporteren van je contacten kan even duren. Sluit dit venster niet tot de import voltooid is.",
	"contacts.overview.bulk_action.download.modal.title": "Contacten exporteren",
	"contacts.overview.bulk_action.export.button.title": "Exporteren",
	"contacts.overview.bulk_action.export.modal.footer.cancel": "Annuleren",
	"contacts.overview.bulk_action.export.modal.footer.submit_button": "Exporteren",
	"contacts.overview.select.type.anonymous.description": "Contact dat je anoniem heeft gecontacteerd",
	"contacts.overview.select.type.customer.description": "Contacten uit je klantenbestand",
	"contacts.overview.select.type.linked_contact.description":
		"Contacten die aan je contacten gelinkt zijn, maar die niet tot je klantenbestand behoren",
	"contacts.overview.select.type.other.description": "Contacten van een ander type waarop je wil kunnen filteren",
	"contacts.overview.select.type.prospect.description": "Contacten die al met je kantoor in contact kwamen",
	"contacts.settings.add.rule.modal.eid_expiration_date.fixed_value": "de dag van de import",
	"contacts.settings.add.rule.modal.eid_expiration_date.fixed_value.tooltip":
		"Opgelet: bij het aanduiden van de vervaldatum van het eID, is het enkel mogelijk om ‘minder dan’ of ‘is gelijk aan’ regels te selecteren.",
	"contacts_import.validate.form.full_name.error_required": "Vul de volledige naam in.",
	"contacts_import.validate.form.full_name.label": "Naam",
	"contacts_label_modal.delete_label.body":
		"Ben je er zeker van dat je dit label wil verwijderen? Verwijderde labels verdwijnen definitief bij alle bijhorende contacten en instellingen.",
	"contacts_label_modal.delete_label.title": "Label verwijderen",
	"contacts_label_modal.update.footer.cancel": "Annuleren",
	"contacts_label_modal.update.footer.delete": "Verwijderen",
	"contacts_label_modal.update.footer.submit": "Aanpassen",
	"contacts_label_modal.update.title": "Label aanpassen",
	"flow.detail.reminders.banner.stopped.button.restart.title": "Herinneringen herstarten",
	"flow.detail.reminders.banner.stopped.title":
		"De automatische herinneringen voor deze flow werden reeds beëindigd.",
	"flow.detail.reminders.create_strategy.allow_reenroll.1":
		"Pas de ingestelde herinneringen automatisch toe op nieuwe rapporten.",
	"flow.detail.reminders.create_strategy.step_1.button.submit": "Volgende",
	"flow.detail.reminders.create_strategy.step_1.description": "Stel automatische herinneringen voor {flowName} in.",
	"flow.detail.reminders.create_strategy.step_1.interval.1": "Verstuur elke",
	"flow.detail.reminders.create_strategy.step_1.interval.1_explanation":
		"tot alle gegevens worden aangeleverd of de ingestelde einddatum van de laatste herinnering verstreken is.",
	"flow.detail.reminders.create_strategy.step_1.interval.2": "dagen een herinnering met een totaal van",
	"flow.detail.reminders.create_strategy.step_1.interval.3": "herinneringen",
	"flow.detail.reminders.create_strategy.step_1.interval.max": "Voer een termijn van maximum 365 dagen in.",
	"flow.detail.reminders.create_strategy.step_1.interval.required": "Interval is verplicht.",
	"flow.detail.reminders.create_strategy.step_1.send_first_after_days.1":
		"Verzend de eerste herinnering wanneer je na",
	"flow.detail.reminders.create_strategy.step_1.send_first_after_days.2": "dagen nog geen gegevens hebt ontvangen.",
	"flow.detail.reminders.create_strategy.step_1.send_first_after_days.max":
		"Voer een termijn van maximum 365 dagen in.",
	"flow.detail.reminders.create_strategy.step_1.send_first_after_days.number": "Voer een afgerond cijfer in.",
	"flow.detail.reminders.create_strategy.step_1.send_first_after_days.required":
		"Voer het aantal dagen in waarna je de eerste herinnering wil verzenden.",
	"flow.detail.reminders.create_strategy.step_1.stop": "Herinneringen beëindigen",
	"flow.detail.reminders.create_strategy.step_1.time_of_day.1": "Verzend de herinneringen steeds om",
	"flow.detail.reminders.create_strategy.step_1.time_of_day.2": "uur.",
	"flow.detail.reminders.create_strategy.step_1.time_of_day.required": "Selecteer een datum of tijdstip.",
	"flow.detail.reminders.create_strategy.step_1.title": "Herinneringen personenbelasting flow aanslagjaar 2024",
	"flow.detail.reminders.create_strategy.step_1.step": "Frequentie instellen",
	"flow.detail.reminders.create_strategy.step_1.total.max": "Voer een termijn van maximum 365 dagen in.",
	"flow.detail.reminders.create_strategy.step_1.total.required": "Voer de hoeveelheid in.",
	"flow.detail.reminders.create_strategy.step_1.when": "Aantal en frequentie van je herinneringen",
	"flow.detail.reminders.create_strategy.step_2.title": "Herinnering beschrijven",
	"flow_detail_template_modal.button": "Flow toevoegen",
	"flow_detail_template_modal.button.create": "Flow toevoegen",
	"flow_detail_template_modal.button.create.tooltip":
		"Je hebt het maximaal aantal flows in je licentie bereikt. Verhoog je licentie om meer flows aan je account toe te voegen.",
	"flow_detail_template_modal.overview": "Terug naar overzicht",
	"flows.detail.banner.fetch_failed": "Er is iets fout gegaan bij het ophalen van de flows.",
	"flows.overview.banner.fetch_failed": "Er is iets fout gegaan bij het ophalen van de flows.",
	"flows.overview.table.button.copy_flow_link.success": "Link gekopieerd naar klembord.",
	"flows.overview.table.error_state.fetch_failed.button": "Opnieuw proberen",
	"flows.overview.table.error_state.fetch_failed.title": "Er is iets fout gegaan bij het ophalen van de flows.",
	"flows.report_detail.meta_aside.report_info.reminder_history.label": "Herinneringen:",
	"flows.report_detail.meta_aside.report_info.reminder_history.send_on": "Op {executeAt}",
	"flows.report_detail.meta_aside.report_info.reminder_history.send_to": "Naar {email)",
	"flows.report_detail.meta_aside.report_info.reminder_history.total_send":
		"{index} van de {total} herinneringen verzonden",
	"image_upload.choose_other_image": "Nieuwe afbeelding selecteren",
	"image_upload.error.file_size.description": "Het bestand dat je wil opladen is groter dan {size}.",
	"image_upload.error.file_size.title": "Bestand is te groot.",
	"image_upload.error.invalid_type.description":
		"Het bestandstype dat je wil opladen wordt niet ondersteund. We ondersteunen enkel volgende types: {types}",
	"image_upload.error.invalid_type.title": "Bestandstype wordt niet ondersteund.",
	"image_upload.error.unexpected.description":
		"Er is iets fout gegaan bij het opladen van je bestand. Probeer het later opnieuw.",
	"image_upload.error.unexpected.title": "Er ging iets mis. Probeer het later opnieuw.",
	"image_upload.pick_a_image": "Selecteer een afbeelding",
	"image_upload.upload_failed": "Er is iets fout gegaan bij het opladen van je bestand. Probeer het later opnieuw.",
	"import_contacts_modal.contact_list.import.failed.description":
		"Je bestand bevat niet het juiste formaat of werd beschadigd. Neem contact op met onze klantendienst als dit probleem aanhoudt.",
	"import_contacts_modal.contact_list.import.failed.title": "We kunnen je bestand niet uitlezen.",
	"organization.settings.branding.colors.accent_color.invalid_color": "Accentkleur is geen hexadecimale kleur.",
	"organization.settings.branding.colors.accent_color.is_required": "Accentkleur is verplicht.",
	"organization.settings.branding.colors.brand_color.invalid_color": "Merkkleur is geen hexadecimale kleur.",
	"organization.settings.branding.colors.brand_color.is_required": "Merkkleur is verplicht.",
	"organization.settings.branding.colors.text_color.invalid_color": "Tekstkleur is geen geldige hexadecimale kleur.",
	"organization.settings.branding.colors.text_color.is_required": "Tekstkleur is verplicht.",
	"organization_settings.branding.breadcrumb.page_title": "Bedrijfskleuren",
	"organization_settings.branding.breadcrumb.settings": "Instellingen",
	"organization_settings.branding.color.description":
		"Wijzig de kleuren van alle uitgaande communicatie naar de vaste kleuren van de onderneming.",
	"organization_settings.branding.color.form.accent_color.label": "Accentkleur",
	"organization_settings.branding.color.form.brand_color.label": "Hoofdkleur",
	"organization_settings.branding.color.form.submit": "Opslaan",
	"organization_settings.branding.color.form.text_color.label": "Tekstkleur",
	"organization_settings.branding.color.title": "Kleurenschema",
	"organization_settings.branding.logo.description":
		"Laad het logo van de onderneming op en kies een pictogram om uitgaande communicatie te personaliseren.",
	"organization_settings.branding.logo.form.icon.help": "Maximale grootte: 10MB",
	"organization_settings.branding.logo.form.icon.label": "Laad je pictogram op",
	"organization_settings.branding.logo.form.icon.required_error": "Pictogram is verplicht.",
	"organization_settings.branding.logo.form.logo.help": "Maximale grootte: 10MB",
	"organization_settings.branding.logo.form.logo.label": "Laad je logo op",
	"organization_settings.branding.logo.form.logo.required_error": "Logo is verplicht.",
	"organization_settings.branding.logo.form.submit": "Opslaan",
	"organization_settings.branding.logo.title": "Logo en pictogram",
	"organization_settings.branding.missing_logo_icon_permissions":
		"Je onderneming heeft op dit moment geen logo en pictogram. Vraag iemand met de nodige rechten om deze op te laden.",
	"organization_settings.branding.save.failed":
		"Er is iets fout gegaan bij het opslaan van je instellingen. Probeer het later opnieuw.",
	"organization_settings.branding.save.success": "Instellingen werden succesvol opgeslagen.",
	"organization_settings.branding.tab_title": "Huisstijl instellingen",
	"organization_settings.branding.title": "Huisstijl instellingen",
	"organization_settings.general.basic.form.success": "Bedrijfsinstellingen werden succesvol opgeslagen.",
	"organization_settings.general.form.email.error_required": "E-mailadres is verplicht.",
	"organization_settings.general.form.email.help":
		"Dit is het e-mailadres waarmee externen contact met je kunnen opnemen.",
	"organization_settings.general.form.email.invalid_error": "Voer een volledig e-mailadres in.",
	"organization_settings.general.form.email.label": "E-mailadres",
	"organization_settings.general.form.name.error_required": "Bedrijfsnaam is verplicht.",
	"organization_settings.general.form.name.label": "Bedrijfsnaam",
	"organization_settings.general.form.phone.help":
		"Dit is het telefoonnummer waarmee externen contact met je kunnen opnemen.",
	"organization_settings.general.form.phone.label": "Telefoonnummer",
	"organization_settings.general.form.phone.validation": "Voer een geldig telefoonnummer in.",
	"organization_settings.general.form.save": "Opslaan",
	"organization_settings.general.locations.add_modal.button.cancel": "Annuleren",
	"organization_settings.general.locations.add_modal.button.create": "Locatie toevoegen",
	"organization_settings.general.locations.add_modal.created.failed":
		"Er is iets fout gegaan bij het opslaan van dit adres. Probeer het later opnieuw.",
	"organization_settings.general.locations.add_modal.created.succeeded": "Adres werd succesvol opgeslagen.",
	"organization_settings.general.locations.add_modal.title": "Locatie toevoegen",
	"organization_settings.general.locations.alert.description":
		"Weet je zeker dat je dit adres als primair wil instellen? Hierdoor wordt de primaire status van je ander adres verwijderd.",
	"organization_settings.general.locations.alert.title": "Primair adres",
	"organization_settings.general.locations.contact_info.form.email.error": "E-mailadres is verplicht.",
	"organization_settings.general.locations.contact_info.form.email.invalid": "Voer een volledig e-mailadres in.",
	"organization_settings.general.locations.contact_info.form.email.label": "E-mailadres",
	"organization_settings.general.locations.contact_info.form.enterprise_number.error.notvalid":
		"Ondernemingsnummer is niet geldig.",
	"organization_settings.general.locations.contact_info.form.enterprise_number.error.required":
		"Ondernemingsnummer is verplicht.",
	"organization_settings.general.locations.contact_info.form.enterprise_number.label": "Ondernemingsnummer",
	"organization_settings.general.locations.contact_info.form.enterprise_number.placeholder":
		"Bijvoorbeeld: BE0665684769",
	"organization_settings.general.locations.contact_info.form.is_primary.label": "Markeer als primair adres",
	"organization_settings.general.locations.contact_info.form.is_primary.tooltip":
		"Dit adres staat al gemarkeerd als primair adres.",
	"organization_settings.general.locations.contact_info.form.phone.label": "Telefoonnummer",
	"organization_settings.general.locations.contact_info.form.phone.max_length": "Voer een geldig telefoonnummer in.",
	"organization_settings.general.locations.contact_info.title": "Contactinformatie",
	"organization_settings.general.locations.delete_button.label": "Verwijderen",
	"organization_settings.general.locations.delete_button.tooltip":
		"Het is niet mogelijk om een primair adres te verwijderen.",
	"organization_settings.general.locations.delete_modal.delete_button": "Verwijderen",
	"organization_settings.general.locations.delete_modal.description":
		"Ben je er zeker van dat je dit adres wil verwijderen?",
	"organization_settings.general.locations.delete_modal.title": "Adres verwijderen",
	"organization_settings.general.locations.edit_modal.delete.failed":
		"Er is iets fout gegaan bij het verwijderen van dit adres. Probeer het later opnieuw.",
	"organization_settings.general.locations.edit_modal.delete.succeeded": "Adres werd succesvol verwijderd.",
	"organization_settings.general.locations.edit_modal.delete_primary.failed":
		"Het is niet mogelijk om een primair adres te verwijderen.",
	"organization_settings.general.locations.edit_modal.title": "Locatie bewerken",
	"organization_settings.general.locations.edit_modal.update.failed":
		"Er is iets fout gegaan bij het wijzigen van je adres. Probeer het later opnieuw.",
	"organization_settings.general.locations.edit_modal.update.succeeded": "Adres werd succesvol opgeslagen.",
	"organization_settings.general.locations.form.bus.error.special_symbol":
		"Het is alleen mogelijk om letters, cijfers, koppeltekens, underscores en punten te gebruiken bij het busnummer.",
	"organization_settings.general.locations.form.bus.label": "Bus",
	"organization_settings.general.locations.form.city": "Gemeente",
	"organization_settings.general.locations.form.city.error.required": "Gemeente is verplicht.",
	"organization_settings.general.locations.form.country.error.required": "Land is verplicht.",
	"organization_settings.general.locations.form.country.label": "Land",
	"organization_settings.general.locations.form.error.hasNumbers": "Verwijder alle cijfers.",
	"organization_settings.general.locations.form.error.hasSymbols": "Verwijder alle cijfers en symbolen.",
	"organization_settings.general.locations.form.postal_code.error.required": "Postcode is verplicht.",
	"organization_settings.general.locations.form.postal_code.label": "Postcode",
	"organization_settings.general.locations.form.province.label": "Provincie",
	"organization_settings.general.locations.form.street_name.error.required": "Straatnaam is verplicht.",
	"organization_settings.general.locations.form.street_name.label": "Straatnaam",
	"organization_settings.general.locations.form.street_number.error.required": "Huisnummer is verplicht.",
	"organization_settings.general.locations.form.street_number.label": "Huisnummer",
	"organization_settings.general.locations.missing_locations_permissions":
		"Je onderneming heeft op dit moment geen locatie(s). Vraag iemand met de nodige rechten om de locatie(s) toe te voegen.",
	"organization_settings.general.locations.table.add_button": "Locatie toevoegen",
	"organization_settings.general.locations.title": "Locatie",
	"organization_settings.general.locations.update_button.label": "Bijwerken",
	"organization_settings.general.locations.view_modal.title": "Locatie bekijken",
	"organization_settings.general.social_media.form.add_fields.placeholder.label": "Platform",
	"organization_settings.general.social_media.form.field_labels.facebook.field.placeholder": "Facebook",
	"organization_settings.general.social_media.form.field_labels.instagram.field.placeholder": "Instagram",
	"organization_settings.general.social_media.form.field_labels.linkedin.field.placeholder": "LinkedIn",
	"organization_settings.general.social_media.form.field_labels.twitter.field.placeholder": "Twitter",
	"organization_settings.general.social_media.form.field_labels.youtube.field.placeholder": "Youtube",
	"organization_settings.general.socials.missing_socials_permissions":
		"Je onderneming heeft op dit moment geen sociale media kanalen ingesteld. Vraag iemand met de nodige rechten om de kanalen toe te voegen.",
	"organization_settings.general.title": "Bedrijfsinstellingen",
	"organization_settings.members.breadcrumbs.self": "Gebruikers",
	"organization_settings.members.breadcrumbs.settings": "Instellingen",
	"organization_settings.members.breadcrumbs.tab_title": "Gebruikers",
	"organization_settings.members.breadcrumbs.title": "Gebruikers",
	"organization_settings.members.table.button": "Gebruiker toevoegen",
	"organization_settings.members.table.button.tooltip": "Verhoog je licentie om meer gebruikers toe te voegen.",
	"organization_settings.members.table.profile": "Gebruiker",
	"organization_settings.opening_hours.basic.description": "Voeg de openingstijden van je kantoor toe.",
	"organization_settings.opening_hours.basic.title": "Kantooruren",
	"organization_settings.opening_hours.basic_form.submit": "Opslaan",
	"organization_settings.opening_hours.missing_permissions":
		"Je onderneming heeft op dit moment geen openingsuren ingesteld. Vraag iemand met de nodige rechten om de openingsuren in te stellen.",
	"organization_settings.opening_hours.update_banner.failed":
		"Er ging iets mis bij het opslaan van je wijzigingen. Probeer het later opnieuw.",
	"organization_settings.opening_hours.update_banner.success": "Openingsuren succesvol gewijzigd.",
	"portal.accept_invite.accept_failed": "Je kan momenteel geen uitnodigen accepteren. Probeer het later opnieuw.",
	"portal.accept_invite.accept_invite": "Uitnodiging accepteren.",
	"portal.accept_invite.decline_failed": "Er ging iets mis. Probeer het later opnieuw.",
	"portal.accept_invite.decline_invite": "Afwijzen",
	"portal.accept_invite.description":
		"Je bent uitgenodigd als teamlid van <bold>{organizationName}</bold>. Accepteer deze uitnodiging om toegang te krijgen tot het account van <bold>{organizationName}</bold>.",
	"portal.accept_invite.not_found.description":
		"We konden geen uitnodiging vinden. Het is mogelijk dat je de uitnodiging eerder al accepteerde, of dat je een link gebruikt die niet meer geldig is.",
	"portal.accept_invite.not_found.title": "Geen uitnodigingen gevonden.",
	"portal.accept_invite.title": "Uitnodiging accepteren",
	"portal.login.description": "Nog geen account? <link>Maak een account aan.</link>",
	"portal.login.failed.account_deleted":
		"Deze gebruiker werd verwijderd. Contacteer onze klantendienst als je de gebruiker wil herstellen.",
	"portal.login.failed.force_reset": "Stel je wachtwoord opnieuw in om te kunnen inloggen.",
	"portal.login.failed.not_found": "Je e-mailadres of wachtwoord is niet correct.",
	"portal.login.field.email.error_is_required": "Voer je e-mailadres in.",
	"portal.login.field.email.invalid_email": "Ongeldig e-mailadres.",
	"portal.login.field.email.label": "E-mailadres",
	"portal.login.field.password.error_is_required": "Voer je wachtwoord in.",
	"portal.login.field.password.label": "Wachtwoord",
	"portal.login.forgot_password.button": "Wachtwoord vergeten",
	"portal.login.forgot_password.tooltip": "Voer hierboven je e-mailadres in om je wachtwoord te herstellen.",
	"portal.login.request_reset.failed":
		"Er is iets fout gegaan bij het verzenden van de e-mail om je wachtwoord te herstellen.",
	"portal.login.request_reset.succeeded":
		"We hebben een e-mail naar {email} gestuurd om je wachtwoord te herstellen.",
	"portal.login.submit": "Log in",
	"portal.login.title": "Welkom bij Bothive",
	"portal.organization.accept_tos.error_is_required":
		"Je kan enkel doorgaan wanneer je de algemene voorwaarden accepteert.",
	"portal.organization.accept_tos.label": "Ik heb de <link>algemene voorwaarden</link> gelezen en goedgekeurd.",
	"portal.organization.company_size.error_is_required": "Hoeveel werknemers zijn er in de onderneming?",
	"portal.organization.company_size.label": "Aantal werknemers",
	"portal.organization.description": "Geef een beschrijving aan de onderneming.",
	"portal.organization.email.error_is_required": "Voer een e-mailadres in.",
	"portal.organization.email.help": "Dit is het e-mailadres dat we gebruiken om contact met je op te nemen.",
	"portal.organization.email.invalid_email": "Ongeldig e-mailadres.",
	"portal.organization.email.label": "E-mailadres",
	"portal.organization.enterprise_number.error.invalid_format":
		"Ondernemingsnummer bevat niet het correcte formaat. Voorbeeld: BE0728.495.140",
	"portal.organization.enterprise_number.error.required": "Ondernemingsnummer is verplicht.",
	"portal.organization.enterprise_number.label": "Ondernemingsnummer",
	"portal.organization.failed.bad_request":
		"Er is iets fout gegaan bij het aanmaken van je onderneming. Probeer het later opnieuw.",
	"portal.organization.failed.invalid_vat":
		"Er is iets fout gegaan bij het verifiëren van je ondernemingsnummer. Contacteer onze klantendienst als dit probleem aanhoudt.",
	"portal.organization.name.error_is_required": "Bedrijfsnaam is verplicht.",
	"portal.organization.name.label": "Bedrijfsnaam",
	"portal.organization.name.to_long": "De bedrijfsnaam mag maximum 46 karakters lang zijn.",
	"portal.organization.phone.label": "Telefoonnummer",
	"portal.organization.phone.validation": "Voer een geldig telefoonnummer in.",
	"portal.organization.submit": "Maak je onderneming aan",
	"portal.organization.title": "Jouw onderneming.",
	"portal.reset_password.button": "Wachtwoord herstellen",
	"portal.reset_password.description": "Wil je je wachtwoord niet herstellen? <link>Log hier in.</link>",
	"portal.reset_password.error_banner.invalid_token.description":
		"De URL om je wachtwoord te herstellen is niet meer geldig. Dat kan het geval zijn wanneer je de URL eerder al gebruikte, of wanneer langer dan 30 minuten geleden verstuurd werd.",
	"portal.reset_password.error_banner.invalid_token.title": "Ongeldige URL.",
	"portal.reset_password.failed.error": "Er ging iets mis. Probeer het later opnieuw.",
	"portal.reset_password.new_password.error_is_required": "Voer een nieuw wachtwoord in.",
	"portal.reset_password.new_password.label": "Nieuw wachtwoord",
	"portal.reset_password.new_password.min_length": "Je nieuw wachtwoord moet uit minstens vijf karakters bestaan.",
	"portal.reset_password.repeat_password.error_is_required": "Bevestig nieuw wachtwoord",
	"portal.reset_password.repeat_password.label": "Herhaal je gekozen wachtwoord",
	"portal.reset_password.repeat_password.min_length": "Je nieuw wachtwoord moet uit minstens vijf karakters bestaan.",
	"portal.reset_password.repeat_password.no_capital_case":
		"Je nieuw wachtwoord moet minstens één hoofdletter bevatten.",
	"portal.reset_password.repeat_password.not_equal": "Wachtwoorden zijn niet gelijk.",
	"portal.reset_password.successful.description":
		"Je wordt binnen <bold>{seconds, plural, =1 {# second} other {# seconden}}</bold> naar het login scherm geleid.",
	"portal.reset_password.successful.title": "Wachtwoord succesvol hersteld.",
	"portal.reset_password.title": "Wachtwoord herstellen",
	"portal.sign_up.description": "Heb je al een account? <link>Log hier in.</link>",
	"portal.sign_up.email.error_is_required": "Voer een e-mailadres in.",
	"portal.sign_up.email.invalid_email": "Ongeldig e-mailadres.",
	"portal.sign_up.email.label": "E-mailadres",
	"portal.sign_up.failed.bad_request":
		"Er is iets fout gegaan bij het aanmaken van je account. Probeer het later opnieuw.",
	"portal.sign_up.failed.user_exists": "Er bestaat al een gebruiker met dit e-mailadres.",
	"portal.sign_up.firstName.error_is_required": "Voornaam is verplicht",
	"portal.sign_up.firstName.label": "Voornaam",
	"portal.sign_up.firstName.to_long": "De voornaam kan maximum 46 karakters lang zijn.",
	"portal.sign_up.invite_failed.bad_request":
		"Er is iets fout gegaan bij het voltooien van je uitnodiging. Probeer het later opnieuw.",
	"portal.sign_up.invite_failed.not_found":
		"Er is iets fout gegaan bij het voltooien van je uitnodiging. Neem contact op met onze klantendienst.",
	"portal.sign_up.lastName.error_is_required": "Familienaam is verplicht.",
	"portal.sign_up.lastName.label": "Familienaam",
	"portal.sign_up.lastName.to_long": "De familienaam kan maximum 46 karakters lang zijn.",
	"portal.sign_up.password.error_is_required": "Voer je wachtwoord in.",
	"portal.sign_up.password.label": "Wachtwoord",
	"portal.sign_up.password.min_length": "Je nieuw wachtwoord moet uit minstens vijf karakters bestaan.",
	"portal.sign_up.password.no_capital_case": "Je nieuw wachtwoord moet minstens één hoofdletter bevatten.",
	"portal.sign_up.phone.label": "Telefoonnummer",
	"portal.sign_up.phone.validation": "Voer een geldig telefoonnummer in.",
	"portal.sign_up.submit": "Maak account aan",
	"portal.sign_up.title": "Welkom bij Bothive",
	"portal.sign_up_completed.button.create_organization": "Team aanmaken",
	"portal.sign_up_completed.button.join_an_organization": "Aansluiten bij bestaand team",
	"portal.sign_up_completed.button_split.or": "Of",
	"portal.sign_up_completed.description":
		"Bothive is een nieuwe, snelle en gestructureerde manier om te communiceren met je <bold>collega's</bold> en <bold>klanten</bold>.",
	"portal.sign_up_completed.join_organization_instruction.description":
		"Om lid te worden van een team, vraag je de beheerder van je bedrijf om je uit te nodigen met je e-mailadres: {email}.",
	"portal.sign_up_completed.join_organization_instruction.title": "Aansluiten bij bestaand team",
	"portal.sign_up_completed.title": "Aan de slag met Bothive",
	"portal.verify_user.description":
		"We hebben een e-mail gestuurd naar <bold>{email}</bold> met een code die <bold>30 minuten</bold> geldig is om je account te verifiëren.",
	"portal.verify_user.failed.not_found":
		"We konden je account niet verifiëren. Het is mogelijk dat je code verlopen is.",
	"portal.verify_user.request_new_code.failed":
		"Er is iets fout gegaan bij het aanvragen van een nieuwe verificatiecode. Probeer het later opnieuw.",
	"portal.verify_user.request_new_code.success": "Verificatiecode verzonden naar {email}.",
	"portal.verify_user.resend_code": "Verificatiecode opnieuw verzenden",
	"portal.verify_user.title": "Verifieer je e-mailadres",
	"settings.advanced.breadcrumb.advanced": "Geavanceerd",
	"settings.advanced.breadcrumb.settings": "Instellingen",
	"settings.advanced.danger_zone.accordion_title": "Gevaarlijke instellingen",
	"settings.advanced.danger_zone.button": "Team verwijderen",
	"settings.advanced.danger_zone.description":
		"Ben je er zeker van dat je je team wil verwijderen? Je licentie wordt onmiddellijk gecanceld en je chatbot wordt gedeactiveerd.",
	"settings.advanced.danger_zone.title": "Team verwijderen",
	"settings.advanced.delete_organization_modal.delete_button": "Bedrijf verwijderen",
	"settings.advanced.delete_organization_modal.description":
		"Ben je zeker dat je je organisatie wil verwijderen? Deze actie is onomkeerbaar. Je abonnement wordt onmiddellijk stopgezet en je flows worden gedeactiveerd.",
	"settings.advanced.delete_organization_modal.failed":
		"Er is iets fout gegaan bij het verwijderen van je organisatie. Neem contact op met onze klantendienst.",
	"settings.advanced.delete_organization_modal.title": "Organisatie verwijderen",
	"settings.advanced.experimental_features.accordion.title": "Experimentele functies",
	"settings.advanced.experimental_features.toggle.help":
		"Opgelet: deze functies zijn in testfase en kunnen kleine fouten of storingen bevatten.",
	"settings.advanced.experimental_features.toggle.label": "Ik wil de nieuwste functies uitproberen.",
	"settings.advanced.experimental_features.tooltip":
		"Deze experimentele functies zullen enkel voor jou persoonlijk geactiveerd worden.",
	"settings.advanced.page.tab_title": "Geavanceerde instellingen",
	"settings.advanced.page.title": "Geavanceerde instellingen",
	"settings.branding.popup.button.cancel": "Annuleren",
	"settings.branding.popup.button.submit": "Opslaan",
	"settings.integrations.integration.add.failed":
		"Er is iets fout gegaan bij het toevoegen van deze integratie. Probeer het later opnieuw.",

	"settings.integrations.integration.add.limit_reached":
		"Je hebt het maximaal aantal integraties in je licentie bereikt. Upgrade je abonnement om meer integraties aan je account toe te voegen.",
	"settings.integrations.integration.add.success": "Integratie succesvol toegevoegd.",
	"settings.integrations.integration.delete.failed":
		"Er is iets fout gegaan bij het verwijderen van deze integratie. Probeer het later opnieuw.",
	"settings.integrations.integration.delete.success": "Integratie succesvol verwijderd.",
	"settings.integrations.overview.active.title": "Actieve integraties",
	"settings.integrations.overview.all_integrations.fallback":
		"Geen integraties beschikbaar. Neem contact op met onze klantendienst om gewenste integraties te bespreken.",
	"settings.integrations.overview.all_integrations.title": "Alle integraties",
	"settings.integrations.overview.info.description":
		"Breid het Bothive platform uit met integraties. Synchroniseer met je CRM-systeem en connecteer met andere platformen zoals WhatsApp, Messenger en Outlook.",
	"": "",
	"Vind je de gewenste integratie niet in het huidige aanbod? Meld het via onze klantendienst, dan voegen we de koppeling zo snel mogelijk voor je toe.":
		"Op zoek naar een andere integratie? Neem contact op met onze klantendienst om gewenste integraties te bespreken.",
	"settings.integrations.overview.info.title": "Integraties",
	"settings.integrations.overview.request_integration.button": "Integratie aanvragen",
	"settings.integrations.overview.request_integration.description":
		"Op zoek naar een andere integratie? Neem contact op met onze klantendienst om gewenste integraties te bespreken.",
	"Tip: Als je een contactpersoon hebt bij de betreffende integratie, vermeld dan zeker de gegevens van deze persoon of iemand van hun development team.":
		"",
	"settings.integrations.overview.request_integration.title": "Integratie aanvragen",
	"settings.integrations.twinntax.connect.description":
		"Koppel Bothive met TwinnTax om de personenbelasting flow vooraf aan per contact aan te vullen met gekende gegevens uit MyMinfin.",
	"settings.integrations.twinntax.connect.title": "Koppel met TwinnTax",
	"settings.integrations.twinntax.form.api_key.help":
		"Je kan een API-sleutel aanmaken in je TwinnTax dashboard onder Instellingen -> API-instellingen -> Creëer een API-sleutel.",
	"settings.integrations.twinntax.form.api_key.label": "API-sleutel",
	"settings.integrations.twinntax.form.api_key.required": "API-sleutel is verplicht.",
	"settings.integrations.twinntax.form.submit": "Koppel met TwinnTax",
	"settings.members.popup.cancel_button": "Annuleren",
	"settings.members.popup.invite_button": "Uitnodigen",
	"settings.organization.edit_member.delete_modal.button": "Gebruiker verwijderen",
	"settings.organization.edit_member.delete_modal.description":
		"Je staat op het punt om {username} uit je organisatie te verwijderen. Weet je zeker dat je wil doorgaan?",
	"settings.organization.edit_member.delete_modal.title": "{username} verwijderen",
	"settings.organization.edit_member.edit.delete": "Verwijderen",
	"settings.organization.edit_member.edit.downgrade_power_admin_failed":
		"Je kan de rechten van deze gebruiker niet verwijderen omdat er binnen je organisatie minstens één gebruiker moet zijn die alle gebruiksrechten heeft.",
	"settings.organization.edit_member.edit.failed":
		"Er is iets fout gegaan bij het bewerken van deze gebruiker. Probeer het later opnieuw.",
	"settings.organization.edit_member.edit.success": "Gebruiker succesvol bewerkt.",
	"settings.organization.edit_member.edit.title": "Gebruiker bewerken",
	"settings.organization.edit_member.edit.update": "Aanpassen",
	"settings.organization.edit_member.form.email.email_error": "E-mailadres incompleet.",
	"settings.organization.edit_member.form.email.help":
		"Geen e-mail ontvangen? Bekijk je spamfolder of contacteer onze klantendienst.",
	"settings.organization.edit_member.form.email.label": "E-mail",
	"settings.organization.edit_member.remove.failed":
		"Er is iets fout gegaan bij het verwijderen van deze gebruiker. Probeer het later opnieuw.",
	"settings.organization.edit_member.remove.last_member":
		"Het is niet mogelijk om de enige gebruiker binnen je organisatie te verwijderen.",
	"settings.organization.edit_member.remove.success": "Gebruiker succesvol verwijderd.",
	"settings.organization.edit_member.remove.super_admin": "Het is niet mogelijk om een super admin te verwijderen.",
	"settings.organization.edit_member.resend_invite.failed":
		"Er is een fout opgetreden bij het versturen van de uitnodiging. Probeer het later opnieuw.",
	"settings.organization.edit_member.resend_invite.success": "Uitnodiging verzonden",
	"settings.organization.socials.add_field": "Veld toevoegen",
	"settings.organization.socials.platform.required": "Platform is verplicht.",
	"settings.organization.socials.submit": "Opslaan",
	"settings.organization.socials.url.invalid": "Voer een geldige URL in.",
	"settings.organization.socials.url.required": "URL is verplicht.",
	"settings.organization.socials.url.validation": "Voer een geldige {platform} URL in. Voorbeeld: {example}",
	"settings.users_popup.edit_flows.help": "Sta de gebruiker toe flows aan te maken, te bewerken en te verwijderen.",
	"settings.users_popup.edit_flows.title": "Flows aanpassen en toevoegen",
	"settings.users_popup.edit_integrations.help":
		"Sta de gebruiker toe integraties aan te maken, te bewerken en te verwijderen.",
	"settings.users_popup.edit_integrations.title": "Integraties aanpassen en toevoegen",
	"settings.users_popup.edit_settings.help": "Sta de gebruiker toe teaminstellingen te wijzigen.",
	"settings.users_popup.edit_settings.title": "Teaminstellingen aanpassen",
	"settings.users_popup.manage_users.help":
		"Sta de gebruiker toe andere gebruikers en teams toe te voegen, te bewerken en te verwijderen.",
	"settings.users_popup.manage_users.title": "Gebruikers beheren",
	"settings.users_popup.permissions": "Gebruikersrechten",
	"settings.users_popup.resend_invite": "Opnieuw uitnodigen",
	"settings.users_popup.super_admin.help":
		"Geef de gebruiker toestemming tot alle bewerkingsrechten. Een super admin kan niet worden verwijderd uit het team.",
	"settings.users_popup.super_admin.title": "Super admin",
	"settings.virtual_assistant.description":
		"Geef je chatbot meer persoonlijkheid door de naam en avatar te wijzigen. Dit is het eerste wat je bezoekers te zien krijgen wanneer ze communiceren met je chatbot.",
	"settings.virtual_assistant.form.avatar.help": "De maximale grootte van de afbeelding is 10 MB.",
	"settings.virtual_assistant.form.avatar.label": "Avatar",
	"settings.virtual_assistant.form.avatar.required": "Avatar is verplicht.",
	"settings.virtual_assistant.form.footer.save": "Opslaan",
	"settings.virtual_assistant.form.name.label": "Naam",
	"settings.virtual_assistant.form.name.required": "Naam is verplicht.",
	"settings.virtual_assistant.suggestions.title": "Instellingen die je mogelijk handig vindt",
	"settings.virtual_assistant.title": "Virtuele assistent",
	"settings.virtual_assistant.update.failed":
		"Er is iets fout gegaan bij het bijwerken van je virtuele assistent. Probeer het later opnieuw.",
	"settings.virtual_assistant.update.succeeded": "Virtuele assistent succesvol bijgewerkt.",
	"setup.branding.aside.help": "Personenbelasting flow aanslagjaar 2024",
	"setup.branding.aside.title": "Voorbeeld van hoe we je huisstijl zullen gebruiken",
	"setup.branding.button.invite": "Huisstijl opslaan",
	"setup.branding.button.skip": "Overslaan",
	"setup.branding.colors.accent_color.invalid_color": "Accentkleur is geen geldige hexadecimale kleur.",
	"setup.branding.colors.accent_color.is_required": "Accentkleur is verplicht.",
	"setup.branding.colors.accent_color.label": "Accentkleur",
	"setup.branding.colors.brand_color.invalid_color": "Merkkleur is geen geldige hexadecimale kleur.",
	"setup.branding.colors.brand_color.is_required": "Merkkleur is verplicht.",
	"setup.branding.colors.brand_color.label": "Merkkleur",
	"setup.branding.colors.text_color.invalid_color": "Tekstkleur is geen geldige hexadecimale kleur.",
	"setup.branding.colors.text_color.is_required": "Tekstkleur is vereist.",
	"setup.branding.colors.text_color.label": "Tekstkleur",
	"setup.branding.colors.title": "Bedrijfskleuren",
	"setup.branding.description":
		"Geef de widget de kleuren van je huisstijl om een uniforme ervaring aan je bezoekers te bieden.",
	"setup.branding.icon_and_logo.icon.help": "De maximale grootte van het pictogram is 10 MB.",
	"setup.branding.icon_and_logo.icon.is_required": "Pictogram is verplicht.",
	"setup.branding.icon_and_logo.icon.label": "Pictogram",
	"setup.branding.icon_and_logo.icon.overlay": "Selecteer een pictogram",
	"setup.branding.icon_and_logo.icon.placeholder": "Selecteer een pictogram",
	"setup.branding.icon_and_logo.logo.help": "De maximale grootte van het logo is 10 MB.",
	"setup.branding.icon_and_logo.logo.is_required": "Logo is verplicht.",
	"setup.branding.icon_and_logo.logo.label": "Logo",
	"setup.branding.icon_and_logo.logo.overlay": "Selecteer een logo",
	"setup.branding.icon_and_logo.logo.placeholder": "Selecteer een logo",
	"setup.branding.icon_and_logo.title": "Logo en pictogram",
	"setup.branding.title": "Branding",
	"setup.branding.update.failed":
		"Er is iets fout gegaan bij het bijwerken van je huisstijl. Probeer het later opnieuw.",
	"setup.flow_setup.aside.help":
		"Dit is een voorbeeld van hoe je webontwikkelaar de flows op je website kan implementeren.",
	"setup.flow_setup.aside.title": "Plaats flows meteen op je website",
	"setup.flow_setup.button.go_to_organization": "Setup voltooien",
	"setup.flow_setup.button.send_mail": "Stuur een e-mail naar je webontwikkelaar en voltooi de setup",
	"setup.flow_setup.code.copied_to_clipboard": "Code gekopieerd naar het klembord.",
	"setup.flow_setup.description":
		"Voer het e-mailadres van je webontwikkelaar in, zodat we alle benodigde informatie kunnen sturen om de flow widget op je website te plaatsen.",
	"setup.flow_setup.form.web_developer.invalid_email": "Ongeldig e-mailadres.",
	"setup.flow_setup.form.web_developer.is_required": "Het e-mailadres van je webontwikkelaar is verplicht.",
	"setup.flow_setup.form.web_developer.label": "E-mail webontwikkelaar",
	"setup.flow_setup.self_setup.description":
		"Voeg de onderstaande code toe in de <head> en <body>-tag en pas de style-attribuut aan zodat deze binnen de website past.",
	"setup.flow_setup.self_setup.title": "Of je kan het zelf doen.",
	"setup.flow_setup.send_email.failed":
		"Er ging iets mis bij het versturen van de instructies naar je webontwikkelaar, probeer het later opnieuw.",
	"setup.flow_setup.send_email.success": "E-mail is verzonden naar je webontwikkelaar.",
	"setup.flow_setup.title": "Flow widget",
	"setup.flows.aside.help":
		"Dit is een voorbeeld van hoe je webontwikkelaar de flows op je website kan implementeren.",
	"setup.flows.aside.title": "Plaats flows meteen op je website",
	"setup.flows.button.next_step": "Volgende stap",
	"setup.flows.button.create":
		"{value, plural, =0 {Selecteer hierboven een flow} =1 {Activeer # flow} other {Activeer # flows}}",
	"setup.flows.button.go_to_organization": "Overslaan en voltooien",
	"setup.flows.create_flows.failed":
		"Er is iets fout gegaan bij het activeren van de flows. Probeer het later opnieuw.",
	"setup.flows.description":
		"Bij Bothive krijg je een reeks gratis flows die je naar je klanten kan sturen. Klik op degene die je wil activeren.",
	"setup.flows.title": "Activeer je eerste flows",
	"setup.invite_members.add_member.button": "Gebruiker toevoegen",
	"setup.invite_members.add_member.input.aria_label": "Voeg e-mailadressen toe van collega's die je wil uitnodigen.",
	"setup.invite_members.add_member.input.help": "{value} / {total} leden die je aan je organisatie kunt toevoegen.",
	"setup.invite_members.add_member.upgrade_banner": "Je kan leden uitnodigen wanneer je je licentie verhoogt.",
	"setup.invite_members.aside.help": "Dit is een voorbeeld van hoe je je collega's kan toewijzen aan flow rapporten.",
	"setup.invite_members.aside.image.assign": "Toewijzen",
	"setup.invite_members.aside.title": "Bothive is gebouwd voor jou en je team.",
	"setup.invite_members.button.invite": "Uitnodigingen versturen",
	"setup.invite_members.button.skip": "Overslaan",
	"setup.invite_members.description": "Nodig je collega's uit in je organisatie.",
	"setup.invite_members.invite.failed":
		"Er is iets fout gegaan bij het uitnodigen van nieuwe gebruikers. Probeer het later opnieuw.",
	"setup.invite_members.list.title": "Uitnodigingslijst",
	"setup.invite_members.title": "Gebruikers uitnodigen",
	"setup.steps.branding": "Branding",
	"setup.steps.flow": "Flows",
	"setup.steps.members": "Gebruikers uitnodigen",
	"shared_components.card.flow_template.freemium_tag": "Gratis",
	"support.banner.email_send.failed":
		"Er is iets fout gegaan bij het verzenden van je bericht. Probeer het later opnieuw.",
	"support.banner.email_send.succeeded": "Bedankt voor je bericht. We nemen zo snel mogelijk contact met je op.",
	"support.form.button.send": "Versturen",
	"support.form.content.required": "Inhoud is verplicht.",
	"support.form.description.required": "Beschrijving is verplicht.",
	"support.form.priority.critical": "Zo snel mogelijk",
	"support.form.priority.high": "Hoog",
	"support.form.priority.low": "Laag",
	"support.form.priority.normal": "Normaal",
	"support.form.question.bug": "Probleem melden",
	"support.form.question.feature_request": "Functie aanvragen",
	"support.form.question.help": "Hulp",
	"support.form.question.installation_help": "Hulp bij installatie",
	"support.form.question.integration_request": "Integratie aanvragen",
	"support.form.question.new_question": "Nieuwe vraag",
	"support.form.question.pricing": "Licenties",
	"support.form.request.required": "Selecteer een optie",
	"support.page.breadcrumb.title": "Klantenservice",
	"support.page.tab_title": "Klantenservice",
	"support.page.title": "Klantenservice",
	"virtual_assistant_settings.breadcrumb.settings": "Instellingen",
	"virtual_assistant_settings.breadcrumb.title": "Virtuele assistent",
	"virtual_assistant_settings.tab_title": "Virtuele assistent",
	"virtual_assistant_settings.title": "Virtuele assistent",
	"settings.integrations.adminpulse.notification.limit_reached":
		"Je hebt geen integraties meer beschikbaar in je huidige licentie.",
	"settings.integrations.twinntax.notification.limit_reached":
		"Je hebt geen integraties meer beschikbaar in je huidige licentie.",
	"settings.integrations.fidmanager.notification.limit_reached":
		"Je hebt geen integraties meer beschikbaar in je huidige licentie.",
	"settings.integrations.liantis.notification.limit_reached":
		"Je hebt geen integraties meer beschikbaar in je huidige licentie.",
	"settings.integrations.adminpulse.tooltip.missing_integrations_permissions":
		"Je hebt niet de nodige rechten om de integratie bij te werken.",
	"settings.integrations.adminpulse.tooltip.limit_reached":
		"Het maximaal aantal integraties van deze licentie is bereikt.",
	"settings.integrations.fidmanager.tooltip.missing_integrations_permissions":
		"Je hebt niet de nodige rechten om de integratie bij te werken.",
	"settings.integrations.fidmanager.tooltip.limit_reached":
		"Het maximaal aantal integraties van deze licentie is bereikt.",
	"settings.integrations.liantis.tooltip.missing_integrations_permissions":
		"Je hebt niet de nodige rechten om de integratie bij te werken.",
	"settings.integrations.liantis.tooltip.limit_reached":
		"Het maximaal aantal integraties van deze licentie is bereikt.",
	"settings.integrations.overview.info.no_integrations_no_upgrade":
		"Je hebt geen integraties in je huidige licentie. Neem contact op met onze klantendienst om je licentie te verhogen.",
	"settings.integrations.overview.info.no_integrations":
		"Je hebt geen integraties in je huidige licentie. Neem contact op met onze klantendienst om je licentie te verhogen.",
	"settings.integrations.overview.info.permissions.loading": "Ophalen limieten...",
	"settings.integrations.fid_manager.login.form.error.required": "URL is verplicht.",
	"settings.integrations.fid_manager.login.form.error.no_url": "Vul een geldige URL in.",
	"settings.integrations.fid_manager.login.form.error.has_symbols": "Verwijder speciale tekens en symbolen.",
	"settings_overview.integrations.no_integrations_upgrade":
		"Je hebt geen integraties in je huidige licentie. Neem contact op met onze klantendienst om je licentie te verhogen.",

	"notification.list_item.mark_as_read.tooltip": "Markeer als gelezen",
	"notification.list_item.mark_as_unread.tooltip": "Markeer als ongelezen",
	"BANNER.FAILED.CREATE_LABEL": "Er ging iets mis bij het aanmaken van het label. Probeer het later opnieuw..",
	"BANNER.FAILED.DELETE_LABEL": "Er ging iets mis bij het verwijderen van het label. Probeer het later opnieuw..",
	"BANNER.FAILED.UPDATE_LABEL": "Er ging iets mis bij het wijzigen van het label. Probeer het later opnieuw..",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.DESCRIPTION":
		"Verbind minstens één van je huidige e-mailkanalen om je e-mails via de Bothive inbox te kunnen ontvangen en versturen.",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.FOOTER": "Beheer je kanalen via <link>instellingen / kanalen</link.",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.TITLE": "Nog geen e-mailkanalen verbonden.",
	"CONTACT.OVERVIEW.ADD.MODAL.FAILED":
		"Er ging iets mis bij het toevoegen van dit contact. Probeer het later opnieuw..",
	"CONTACT.OVERVIEW.ADD.MODAL.FAILED.DESCRIPTION": "Ga na of er al een contact bestaat met dit e-mailadres.",
	"CONTACT.OVERVIEW.ADD.MODAL.SUCCESS": "Contact succesvol toegevoegd.",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.BUTTON.TITLE": "Accountmanagers",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.ERROR":
		"Er ging iets mis bij het bijwerken van de dossierbeheerder.",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ACTIONS.ADD": "Toevoegen",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ACTIONS.DELETE": "Verwijderen",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ITEM_SELECTED":
		"{count, plural, =0 {Geen dossierbeheerder} one {1 dossierbeheerder} other {# dossierbeheerders}} geselecteerd.",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.NOT_FOUND": "Geen contactlabels gevonden.",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.SEARCH_PLACEHOLDER": "Zoek op dossierbeheerder",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.TITLE": "Beheerd door",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.SUCCESS":
		"De dossierbeheerder van {count, plural, one {1 contact} other {# contacten}} werd succesvol bijgewerkt.",
	"CONTACT.OVERVIEW.BULK_ACTION.ACTION.ARCHIVE": "ARCHIVEREN",
	"CONTACT.OVERVIEW.BULK_ACTION.ACTION.DELETE": "VERWIJDEREN",
	"CONTACT.OVERVIEW.BULK_ACTION.ARCHIVE.ERROR":
		"Er ging iets mis bij het archiveren in bulk. Probeer het later opnieuw..",
	"CONTACT.OVERVIEW.BULK_ACTION.ARCHIVE.SUCCESS":
		"{count, plural, one {1 contact} other {# contacten}} succesvol bijgewerkt.",
	"CONTACT.OVERVIEW.BULK_ACTION.BUTTON.SELECTION_TOTAL":
		"{selection, plural, =0 {Geen contact} one {1 contact} other {# contacten}} geselecteerd.",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.BUTTON.TITLE": "Labels",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.ERROR":
		"Er ging iets mis bij het bijwerken van de contactlabels. Probeer het later opnieuw..",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ACTIONS.ADD": "Toevoegen",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ACTIONS.DELETE": "Verwijderen",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ITEM_SELECTED":
		"{count, plural, =0 {Geen label} one {1 label} other {# labels}} geselecteerd.",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.NOT_FOUND": "Geen contactlabels gevonden.",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.SEARCH_PLACEHOLDER": "Zoek op contactlabel",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.TITLE": "Label als",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.SUCCESS":
		"Contactlabels van {count, plural, one {1 contact} other {# contacten}} werden succesvol bijgewerkt.",
	"CONTACT.OVERVIEW.BULK_ACTION.DELETE.BUTTON.TITLE": "Verwijderen",
	"CONTACT.OVERVIEW.BULK_ACTION.FORCE_DELETE.ERROR":
		"Er ging iets mis bij het verwijderen van meerdere contacten. Probeer het later opnieuw..",
	"CONTACT.OVERVIEW.BULK_ACTION.FORCE_DELETE.SUCCESS":
		"{count, plural, one {1 contact} other {# contacten}} succesvol verwijderd.",
	"CONTACT.OVERVIEW.BULK_ACTION.SOFT_DELETE.ERROR":
		"Er ging iets mis bij het verwijderen van meerdere contacten. Probeer het later opnieuw..",
	"CONTACT.OVERVIEW.BULK_ACTION.SOFT_DELETE.SUCCESS":
		"{count, plural, one {1 contact} other {# contacten}} succesvol verwijderd.",
	"CONTACT.OVERVIEW.BULK_ACTION.DELETE.ERROR":
		"Er ging iets mis bij het verwijderen van meerdere contacten. Probeer het later opnieuw..",
	"CONTACT.OVERVIEW.BULK_ACTION.DELETE.SUCCESS":
		"{count, plural, one {1 contact} other {# contacten}} succesvol verwijderd.",
	"CONTACT.OVERVIEW.BULK_ACTION.RESTORE.BUTTON.TITLE": "Herstellen",
	"CONTACT.OVERVIEW.BULK_ACTION.RESTORE.ERROR":
		"Er ging iets mis bij het herstellen van meerdere contacten. Probeer het later opnieuw..",
	"CONTACT.OVERVIEW.BULK_ACTION.RESTORE.SUCCESS":
		"{count, plural, one {1 contact} other {# contacten}} succesvol hersteld.",
	"CONTACT.OVERVIEW.BULK_ACTION.UNARCHIVE.ERROR":
		"Er ging iets mis bij het dearchiveren van meerdere contacten. Probeer het later opnieuw..",
	"CONTACT.OVERVIEW.BULK_ACTION.UNARCHIVE.SUCCESS":
		"{count, plural, one {1 contact} other {# contacten}} succesvol gedearchiveerd.",
	"CONTACT.OVERVIEW.SELECT.TYPE.CUSTOMER": "Klant",
	"CONTACT.OVERVIEW.SELECT.TYPE.OTHER": "Prospect",
	"CONTACT.OVERVIEW.SELECT.TYPE.PROSPECT": "Andere",
	"CONTACT.OVERVIEW.SHOW_DELETED.TITLE": "Toon verwijderde contacten",
	"CONTACTS.CSV.MAP.INFO.DUPLICATE_EMAIL.TITLE":
		"Houd er rekening mee dat elk geïmporteerd contact een uniek e-mailadres moet hebben. Contacten met hetzelfde e-mailadres worden niet opgenomen in de import.",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.FAILED":
		"Er ging iets mis bij het toevoegen van het adres. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.SUCCESS": "Adres succesvol toegevoegd.",
	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.BUTTON": "Adres verwijderen",
	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.DESCRIPTION": "Weet je zeker dat je dit adres wilt verwijderen?",
	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.TITLE": "Adres verwijderen",
	"CONTACTS.DETAIL.ATTRIBUTES.DELETE.ADDRESS.FAILED":
		"Er ging iets mis bij het verwijderen van het adres. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.ATTRIBUTES.DELETE.ADDRESS.SUCCESS": "Adres succesvol verwijderd.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.BUTTON": "Relatie verwijderen",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.DESCRIPTION": "Weet je zeker dat je deze relatie wil verwijderen?",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.ERROR":
		"Er ging iets mis bij het verwijderen van de relatie. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.SUCCESS": "Relatie succesvol verwijderd.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.TITLE": "Relatie verwijderen",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.COHABITATION_DATE.REQUIRED":
		"Datum wettelijk samenwonend is verplicht.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.DIVORCE_DATE.REQUIRED": "Scheidingsdatum is verplicht.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.WIDOW_DATE.REQUIRED":
		"Datum waarop je weduwe/weduwnaar werd is verplicht.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.ERROR":
		"Er ging iets mis bij het bijwerken van de relatie. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.SUCCESS": "Relatie succesvol bijgewerkt.",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.FAILED":
		"Er ging iets mis bij het linken van het contact. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.SUCCESS": "Contact succesvol gelinkt.",
	"CONTACTS.DETAIL.ATTRIBUTES.SAVE.FAILED":
		"Er ging iets mis bij het bijwerken van de contactinfo. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.ATTRIBUTES.SAVE.FAILED.DUPLICATE.DESCRIPTION":
		"De inhoud van dit veld moet uniek zijn binnen deze organisatie.",
	"CONTACTS.DETAIL.ATTRIBUTES.SAVE.SUCCESS": "Contact succesvol opgeslagen.",
	"CONTACTS.DETAIL.ATTRIBUTES.TYPE.ERROR":
		"Er ging iets mis bij het bijwerken van het contacttype. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.ATTRIBUTES.TYPE.SUCCESS": "Contacttype succesvol bijgewerkt.",
	"CONTACTS.DETAIL.ATTRIBUTES.UPDATE.ADDRESS.FAILED":
		"Er ging iets mis bij het opslaan van het adres. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.ATTRIBUTES.UPDATE.ADDRESS.SUCCESS": "Adres succesvol opgeslagen.",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.BUTTON": "Bankrekeningnummer verwijderen",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.DESCRIPTION":
		"Ben je er zeker van dat je dit bankrekeningnummer wil verwijderen?",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.TITLE": "Bankrekeningnummer verwijderen",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IS_REFUND_ACCOUNT.LABEL": "Rekeningnummer voor terugbetalingen",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.ERROR_INVALID": "Voer een geldig rekeningnummer in.",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.BUTTON": "Buitenlands vastgoed verwijderen",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.DESCRIPTION":
		"Ben je er zeker van dat je dit buitenlands vastgoed wil verwijderen?",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.TITLE": "Buitenlands vastgoed verwijderen",
	"CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.BUTTON": "Verzekering verwijderen",
	"CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.DESCRIPTION":
		"Ben je er zeker van dat je deze verzekering wil verwijderen?",
	"CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.TITLE": "Verzekering verwijderen",
	"CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.ADD.FAILED":
		"Er ging iets mis bij het toevoegen van deze dossierbeheerder. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.REMOVE.FAILED":
		"Er ging iets mis bij het verwijderen van deze dossierbeheerder. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.MODAL.LABELS.ADD.FAILED":
		"Er ging iets mis bij het toevoegen van dit label. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.MODAL.LABELS.REMOVE.FAILED":
		"Er ging iets mis bij het verwijderen van dit label. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.FAILED":
		"Er ging iets mis bij het archiveren van dit contact. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.SUCCESS": "Contact succesvol gearchiveerd.",
	"CONTACTS.DETAIL.SETTINGS.DEARCHIVE.FAILED":
		"Er ging iets mis bij het dearchiveren van dit contact. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.SETTINGS.DEARCHIVE.SUCCESS": "Contact succesvol gedearchiveerd.",
	"CONTACTS.DETAIL.STATE.ARCHIVED": "Dit contact werd gearchiveerd.",
	"CONTACTS.DETAIL.STATE.ARCHIVED.UNARCHIVE.ERROR":
		"Er ging iets mis bij het dearchiveren van dit contact. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.STATE.ARCHIVED.UNARCHIVE.SUCCESS": "Contact succesvol gedearchiveerd.",
	"CONTACTS.DETAIL.STATE.DELETED": "Dit contact werd verwijderd.",
	"CONTACTS.DETAIL.STATE.DELETED.RESTORE.ERROR":
		"Er ging iets mis bij het herstellen van dit contact. Probeer het later opnieuw..",
	"CONTACTS.DETAIL.STATE.DELETED.RESTORE.SUCCESS": "Contact succesvol hersteld.",
	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.CRMTYPE": "Selecteer een CRM-type",
	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.CRMTYPE.PLACEHOLDER": "Selecteer een CRM-type",
	"CONTACTS.IMPORT.AUTO_SYNC.DESCRIPTION":
		"Voer deze synchronisatie dagelijks uit om je contacten in Bothive up-to-date te houden.",
	"CONTACTS.IMPORT.AUTO_SYNC.ENABLE.TITLE": "Dagelijkse synchronisatie uitvoeren",
	"CONTACTS.IMPORT.AUTO_SYNC.ERROR": "Er ging iets mis bij het synchroniseren van je contacten.",
	"CONTACTS.IMPORT.AUTO_SYNC.ERROR_DESCRIPTION": "Probeer het later opnieuw..",
	"CONTACTS.IMPORT.AUTO_SYNC.NAME.TITLE": "Importnaam",
	"CONTACTS.IMPORT.AUTO_SYNC.SYNC_ONLY_EXISTING_CONTACTS":
		"Synchroniseer alleen de contactinformatie van de contacten in Bothive",
	"CONTACTS.IMPORT.AUTO_SYNC.TITLE": "Automatisch synchroniseren",

	"CONTACTS.IMPORT.SETTINGS.email": "E-mail (bestaand contact)",
	"CONTACTS.IMPORT.SETTINGS.phone": "Telefoon (bestaand contact)",
	"CONTACTS.IMPORT.SETTINGS.unique_identifier": "Unieke identificatie (bestaand contact)",
	"CONTACTS.IMPORT.SETTINGS.national_registration_number": "Rijksregisternummer (bestaand contact)",

	"CONTACTS.IMPORT.IMPORT.LONG_RUNNING":
		"De contactimport wordt uitgevoerd op de achtergrond. Je kan dit venster sluiten en verder werken.",
	"CONTACTS.IMPORT.OVERVIEW.WARN_DUPLICATES":
		"Let op: het is mogelijk dat de uiteindelijke import minder contacten bevat omdat dubbele contacten automatisch in Bothive worden samengevoegd tot één contact.",
	"CONTACTS.IMPORT.SETTINGS.EXISTING.CONTACT_SYNC.CONTEXT":
		"Let op: je stelde eerder al automatische labeling in voor een groep van geïmporteerde contacten met dezelfde filters. Bekijk je huidige labelinstellingen hieronder. Wanneer je nieuwe regels instelt, zullen je huidige labelinstellingen automatisch met de nieuwe overschreven worden.",
	"CONTACTS.IMPORT.SETTINGS.IMPORT.BUTTON.UPDATE_CONTACTS": "Contacten updaten",
	"CONTACTS.IMPORT.VALIDATE.SKIP.TOOLTIP.ALL_INCOMPLETE":
		"Alle geselecteerde contacten bevatten ontbrekende informatie.",
	"CONTACTS.INTEGRATION.IMPORT.ADMIN_PULSE.OWN_COMPANY": "Eigen bedrijf",
	"CONTACTS.INTEGRATION.IMPORT.ADMIN_PULSE.SELECT.TYPE.COMPANY": "Bedrijf",
	"CONTACTS.INTEGRATION.IMPORT.ADMIN_PULSE.SELECT.TYPE.PERSON": "Persoon",
	"CONTACTS.INTEGRATION.IMPORT.ADMIN_PULSE.SELECT.TYPE.SOLE_PROPRIETOR": "Eenmanszaak",
	"CONTACTS.INTEGRATION.IMPORT.SETTINGS.LABEL.TITLE": "Contactlabel toevoegen",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_ARCHIVED": "Een contact met dit {fields} werd gearchiveerd.",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_ARCHIVED.UNARCHIVE.ERROR":
		"Er ging iets mis bij het archiveren van dit contact. Probeer het later opnieuw..",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_ARCHIVED.UNARCHIVE.SUCCESS": "Contact succesvol gedearchiveerd.",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_DELETED": "Een contact met dit {fields} werd verwijderd.",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_DELETED.RESTORE.ERROR":
		"Er ging iets mis bij het herstellen van dit contact. Probeer het later opnieuw..",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_DELETED.RESTORE.SUCCESS": "Contact succesvol hersteld.",
	"CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.VIEW_CONTACT": "Contact bekijken",
	"CONTACTS.OVERVIEW.ADD.MODAL.EMAIL.DUPLICATE": "Er is al een ander contact gekoppeld aan dit e-mailadres.",
	"contact.overview.bulk_action.delete.button.soft_delete.tooltip":
		"{count, plural, one { 1 contact binnen je selectie is} other {# contacten binnen je selectie zijn}} al verwijderd. Het is niet mogelijk om contacten te verwijderen die je eerder al hebt verwijderd.",
	"CONTACTS.OVERVIEW.ARCHIVED.EMPTY.TITLE": "Geen gearchiveerde contacten gevonden.",
	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.BUTTON.TITLE": "Archiveren",
	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.BUTTON": "Archiveer contacten",
	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.DESCRIPTION":
		"Wanneer je een contact archiveert, is het contact niet meer zichtbaar in de zoekresultaten en kan je er geen automatiseringen meer op uitvoeren. Nieuwe data wordt wel nog steeds bij het gearchiveerd contact opgeslagen.",
	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.TITLE": "Archiveer contacten",
	"CONTACTS.OVERVIEW.BULK_ACTION.RESTORE.BUTTON.DISABLED.TOOLTIP":
		"{count, plural, Éen contact other {# contacten}} binnen je selectie is/zijn gearchiveerd en niet verwijderd. Je kan enkel verwijderde contacten herstellen.",
	"CONTACTS.OVERVIEW.BULK_ACTION.RESTORE.BUTTON.TITLE": "Herstellen",
	"CONTACTS.OVERVIEW.BULK_ACTION.RESTORE_AS_ARCHIVED.BUTTON.TITLE": "Herstellen als gearchiveerd",
	"CONTACTS.OVERVIEW.BULK_ACTION.UNARCHIVE.BUTTON.DISABLED.TOOLTIP":
		"{count, plural, Éen contact other {# contacten}} binnen je selectie is/zijn verwijderd en niet gearchiveerd. Je kan enkel gearchiveerde contacten dearchiveren.",
	"CONTACTS.OVERVIEW.BULK_ACTION.UNARCHIVE.BUTTON.TITLE": "Dearchiveren",
	"CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.DESCRIPTION":
		"Verwijderde contacten blijven zichtbaar bij gearchiveerde contacten, rapporten, gekoppelde contacten en bestanden. Ze worden pas na een periode van 90 dagen definitief verwijderd.",
	"CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.OPTION.ARCHIVE":
		"Archiveer {count, plural, one {1 contact} other { # contacten}} in plaats van verwijderen",
	"CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.SIDE_EFFECTS.DESCRIPTION":
		"Let op: wanneer je een contact in Bothive verwijdert, maar het niet in je gekoppeld CRM-systeem verwijdert, zal het contact terug in Bothive geïmporteerd worden wanneer je er in je CRM-systeem aanpassingen aan doet.",
	"CONTACTS.OVERVIEW.IMPORT_CONTACT": "Contacten importeren",
	"CONTACTS.OVERVIEW.TABLE.ARCHIVED_AT": "Gearchiveerd op:",
	"CONTACTS.OVERVIEW.TABLE.DELETED_AT": "Verwijderd op:",
	"CONTACTS.OVERVIEW.TABLE.HARD_DELETE.MESSAGE":
		"{days, plural, =0 {Today} vandaag {{days} dag} other {{days} dagen}}",
	"CONTACTS.OVERVIEW.TABLE.HARD_DELETE.MESSAGE.TOOLTIP":
		"{days, plural, =0 {Dit contact wordt vandaag definitief verwijderd.} one {Dit contact wordt over één dag definitief verwijderd.} other {Dit contact wordt over {days} dagen definitief verwijderd.}",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.INPUT.COMPARISON.PLACEHOLDER`": "Selecteer een regel",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.INPUT.FIELD.PLACEHOLDER`": "Nog geen regels ingesteld.",
	"CONTACTS.SETTINGS.RULE.TABLE.EMPTY": "Nog geen regels ingesteld.",
	"CONTACT_DETAILS.NOT_FOUND.DESCRIPTION": "Het lijkt erop dat dit contact niet bestaat binnen je organisatie.",
	"CONTACT_DETAILS.NOT_FOUND.RETURN": "Terug",
	"CONTACT_DETAILS.NOT_FOUND.TITLE": "Geen contact gevonden.",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.ACCORDION_TITLE": "Contact verwijderen",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.BUTTON": "Contact verwijderen",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.DESCRIPTION":
		"Ben je er zeker van dat je dit contact wil verwijderen? Alle documenten, rapporten en gegevens die gelinkt zijn aan dit contact zullen definitief verwijderd worden na 90 dagen.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED": "Er ging iets mis bij het opslaan van je herinnering.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantenservice.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_TO_CREATE": "Er ging iets mis bij het aanmaken van je herinnering.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_TO_CREATE_DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantenservice.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_TO_UPDATE": "Er ging iets mis bij het bijwerken van je herinnering.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_TO_UPDATE_DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantenservice.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FOOTER.RETURN": "Terug",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP2.MESSAGE": "Het bericht waarmee je je klant wil herinneren",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_1.CUTOFF_DATE.1": "Beëindig herinneringen automatisch na",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_1.CUTOFF_DATE.MIN_DATE":
		"De laatste herinnering moet minstens {firstReminderInterval} dagen na de eerste herinnering verzonden worden.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_1.CUTOFF_DATE.REQUIRED":
		"Selecteer een datum voor de laatste herinnering.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_1.SEND_FIRST_AFTER_DAYS.NUMBER": "Voer een afgerond cijfer in.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_2.BUTTON.SUBMIT": "Volgende",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_2.CHANNEL": "Het e-mailadres waarmee je de herinnering wil verzenden",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_2.DESCRIPTION":
		"Maak het bericht op dat je als herinnering wil verzenden.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_2.TITLE": "Herinneringen personenbelasting flow aanslagjaar 2024",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.SUCCESSFULLY_CREATED": "Herinnering succesvol aangemaakt.",
	"FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.SUCCESSFULLY_UPDATED": "Herinnering succesvol bijgewerkt.",
	"FLOW.DETAIL.REMINDERS.EMPTY.BUTTON.TITLE": "Herinneringen instellen",
	"FLOW.DETAIL.REMINDERS.EMPTY.DESCRIPTION":
		"Verzend automatisch herinneringen tot alle gegevens worden aangeleverd.",
	"FLOW.DETAIL.REMINDERS.EMPTY.TITLE": "Automatische herinneringen",
	"FLOW.DETAIL.REMINDERS.HISTORY.FILTERS.SHOW_FAILED.LABEL": "Toon alleen gefaalde herinneringen",
	"FLOW.DETAIL.REMINDERS.HISTORY.LIST.EMPTY": "Nog geen herinneringen verzonden.",
	"FLOW.DETAIL.REMINDERS.HISTORY.LIST.FETCHED_ALL": "Alle verzonden herinneringen opgehaald",
	"FLOW.DETAIL.REMINDERS.HISTORY.LIST.ITEM.REMINDER_COUNT": "{count} herinnering",
	"FLOW.DETAIL.REMINDERS.HISTORY.LIST.ITEMS.LOAD_MORE": "Meer herinneringen ophalen",
	"FLOW.DETAIL.REMINDERS.HISTORY.LIST.LOAD_MORE": "Meer dagen ophalen",
	"FLOW.DETAIL.REMINDERS.HISTORY.TITLE": "Verzonden herinneringen",
	"FLOW.DETAIL.REMINDERS.HISTORY_LIST.TIME_SEPARATOR.TODAY": "Vandaag",
	"FLOW.DETAIL.REMINDERS.HISTORY_LIST.TIME_SEPARATOR.YESTERDAY": "Gisteren",
	"FLOW.DETAIL.REMINDERS.SETTINGS.ACTIONS.STOP.ERROR": "Er ging iets mis bij het beëindigen van de herinneringen.",
	"FLOW.DETAIL.REMINDERS.SETTINGS.ACTIONS.STOP.SUCCESS": "Je herinneringen werden succesvol beëindigd.",
	"FLOW.DETAIL.REMINDERS.SETTINGS.BUTTON.EDIT": "Bewerken",
	"FLOW.DETAIL.REMINDERS.SETTINGS.BUTTON.STOP": "Herinneringen beëindigen",
	"FLOW.DETAIL.REMINDERS.SETTINGS.STATUS":
		"Verzend {total, plural, =0 {<b>geen</b> herinneringen} één {<b>#</b> herinnering} other {in totaal <b>#</b> herinneringen}} met telkens {days, plural, =0 {<b>no</b> dagen} één {<b>#</b> dag} other {<b>#</b> dagen}} tussen elke herinnering. De herinneringen worden automatisch beëindigd wanneer alle gegevens werden aangeleverd, of wanneer de datum van de laatste herinnering is verstreken.",
	"FLOW.DETAIL.REMINDERS.SETTINGS.STOP_MODAL.BUTTON.STOP.TITLE": "Herinneringen beëindigen",
	"FLOW.DETAIL.REMINDERS.SETTINGS.STOP_MODAL.DESCRIPTION":
		"Wanneer je de herinneringen beëindigt, zal geen enkel contact nog een herinnering ontvangen.",
	"FLOW.DETAIL.REMINDERS.SETTINGS.STOP_MODAL.TITLE": "Herinneringen beëindigen",
	"FLOW.DETAIL.REMINDERS.SETTINGS.TABLE.COLUMN.TEMPLATE.DESCRIPTION": "Naam",
	"FLOW.DETAIL.REMINDERS.SETTINGS.TABLE.COLUMN.TEMPLATE.SUBJECT": "Onderwerp",
	"FLOW.DETAIL.REMINDERS.SETTINGS.TABLE.DEFAULT.TEMPLATE": "Standaard e-mail template",
	"FLOW.DETAIL.REMINDERS.SETTINGS.TITLE": "Instellingen",
	"FLOW.DETAIL.REMINDERS.STATUS.COUNT.FAILED": "Er ging iets mis bij het verzenden van {count} herinneringen.",
	"FLOW.DETAIL.REMINDERS.STATUS.COUNT.GENERAL": "Er werden vandaag {count} herinneringen verzonden.",
	"FLOW.DETAIL.REMINDERS.STATUS.COUNT.SUCCESS": "{count} herinneringen werden succesvol verzonden.",
	"FLOW.REPORTS.CONFIGURATION.UPDATE.FAILED.DESCRIPTION": "Probeer later opnieuw of contacteer onze customer support",
	"FLOW.REPORTS.CONFIGURATION.UPDATE.FAILED.TITLE": "Flow kon niet bijgewerkt worden.",
	"FLOW.REPORTS.CONFIGURATION.UPDATE.SUCCESS.TITLE": "Flow succesvol bijgewerkt.",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.DESCRIPTION":
		"Ben je er zeker van dat je dit rapport wil verwijderen? Deze actie kan niet ongedaan gemaakt worden.",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.FAILED.DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantendienst.",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.FAILED.TITLE": "Rapport kon niet worden verwijderd.",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.LABEL.BUTTON_LABEL": "Rapport verwijderen",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.SUCCEEDED.TITLE": "Rapport verwijderd",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.TITLE": "Rapport verwijderen",
	"FLOWS.DEACTIVATED.TOOLTIP": "Deze flow werd gedeactiveerd en kan niet meer naar contacten verzonden worden.",
	"FLOWS.DETAIL.SETTINGS.DELETE_FAILED.TITLE": "De flow kon niet worden verwijderd. Probeer het later opnieuw.",
	"FLOWS.DETAIL.SETTINGS.DELETE_SUCCEEDED.TITLE": "Flow succesvol verwijderd.",
	"FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.COMPOSE": "E-mail inhoud",
	"FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.COMPOSE.ERROR_EMPTY": "Bericht inhoud is verplicht.",
	"FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.COMPOSE.SUBJECT": "Onderwerp",
	"FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.COMPOSE.SUBJECT.REQUIRED": "Onderwerp is verplicht.",
	"FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.SIGNATURE": "Verzenden met handtekening",
	"FLOWS.OVERVIEW.ADD_FLOW.BANNER.FAILED":
		"Er ging iets mis bij het aanmaken van deze flow. Probeer het later opnieuw.",
	"FLOWS.OVERVIEW.ADD_FLOW.FETCH_FAILED.TITLE": "Flows konden niet worden opgehaald.",
	"FLOWS.OVERVIEW.TABLE.BUTTON.COPY_FLOW_LINK.SUCCESS": "Link gekopieerd naar klembord.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.FAILED.DESCRIPTION":
		"Er ging iets mis bij het in bulk aanpassen van het status type.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.FAILED.TITLE": "Bulk status type aanpassen mislukt.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.SUCCESS.TITLE": "Bulk rapport status aanpassen is gestart.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.FAILED.DESCRIPTION":
		"Er ging iets mis bij het downloaden in bulk.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.FAILED.TITLE": "Bulk download mislukt.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.SUCCESS.DESCRIPTION":
		"Wanneer de bestanden verwerkt zijn, ontvang je een notificatie en een e-mail met de link om ze te downloaden.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.SUCCESS.TITLE": "Bulk download is gestart.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.FAILED.DESCRIPTION":
		"Het in bulk versturen naar de integratie is mislukt.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.FAILED.TITLE":
		"Bulk versturen naar integratie mislukt.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.SUCCESS.DESCRIPTION":
		"Wanneer de actie compleet is ontvang je een notificatie in je dashboard.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.SUCCESS.TITLE":
		"Bulk versturen naar integratie gestart.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SEND_TO_EMAIL": "E-mail verzenden",
	"FLOWS.REPORT.SETTINGS.FORM.FAILED.DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantendienst.",
	"FLOWS.REPORT.SETTINGS.FORM.FAILED.TITLE": "Flow kon niet worden aangepast.",
	"FLOWS.REPORT.SETTINGS.FORM.SUCCEEDED.TITLE": "Flow aangepast.",
	"FLOWS.REPORT.TEMPLATE.ASIDE.REMINDERS.LABEL": "Herinneringen",
	"FLOWS.REPORTS.STATUS_TYPES.COPY.FAILED":
		"Er ging iets mis bij het kopiëren van de status types, probeer het later opnieuw.",
	"FLOWS.REPORTS.STATUS_TYPES.COPY.SUCCEEDED": "Status types succesvol gekopieerd.",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE.FAILED": "Status type kon niet verwijderd worden.",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE.SUCCEEDED": "Status type succesvol verwijderd",
	"FLOWS.REPORTS.STATUS_TYPES.REORDER_FAILED": "Sorteren is tijdelijk niet beschikbaar. probeer het later opnieuw.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.FAILED.DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantendienst.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.FAILED.TITLE":
		"Bijlagen konden niet worden gedownload.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.NO_RESULT.TITLE": "Geen bijlagen gevonden.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.SUCCEEDED.TITLE": "Bijlagen succesvol gedownload.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.FAILED.DESCRIPTION":
		"Probeer het later opnieuw of neem contact op met onze klantendienst.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.FAILED.TITLE": "Rapport kon niet worden gedownload.",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.SUCCEEDED.TITLE": "Rapport succesvol gedownload.",
	"FLOWS.REPORT_DETAIL.ACTIONS.EDIT_TOOLTIP.DISABLED":
		"Je kan het rapport enkel bewerken of downloaden wanneer je contact gestart is met het aanleveren van gegevens.",
	"FLOWS.REPORT_DETAIL.ACTIONS.OPEN_FLOW": "Open flow als contact",
	"FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.BANNER.ASSIGN_USER_FAILED":
		"{username} kon niet worden toegewezen. Probeer het later opnieuw.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.BANNER.UNASSIGN_FAILED":
		"Er ging iets mis bij het aanpassen van je toewijzing. Probeer het later opnieuw.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.BANNER.FAILED":
		"Status type kon niet worden aangepast. Probeer het later opnieuw.",
	"FLOWS.REPORT_DETAIL.PDF.FAILED.TITLE": "Flow pdf kon niet gegenereerd worden",
	"FLOWS.REPORT_DETAIL.REPORT_LIST.FETCH_FAILED.TITLE": "Rapporten konden niet worden opgehaald.",
	"FLOWS.REPORT_DETAIL.UPDATE.FAILED.DESCRIPTION": "Probeer later opnieuw of neem contact op met onze klantendienst.",
	"FLOWS.REPORT_DETAIL.UPDATE.FAILED.TITLE": "Rapport kon niet worden aangepast.",
	"FLOWS.REPORT_DETAIL.UPDATE.SUCCESS.TITLE": "Rapport aangepast.",
	"FLOWS.SEND.COMPOSE.BUTTONS.NEXT": "Volgende",
	"FLOWS.SEND.COMPOSE.CHANNEL": "Kanaal",
	"FLOWS.SEND.COMPOSE.DESCRIPTION": "Stel een bericht samen om naar je contacten te verzenden.",
	"FLOWS.SEND.COMPOSE.EMAIL": "E-mail",
	"FLOWS.SEND.COMPOSE.PREVIOUS": "Vorige",
	"FLOWS.SEND.COMPOSE.SUBJECT": "Onderwerp",
	"FLOWS.SEND.COMPOSE.SUBJECT.REQUIRED": "Onderwerp is verplicht.",
	"FLOWS.SEND.COMPOSE.TITLE": "Bericht samenstellen",
	"FLOWS.SEND.CONTACT_SELECTION.ALERT":
		"{count, plural, =1 {# contact zal} other {# contacten zullen}} je bericht niet ontvangen omdat hun e-mailadres ontbreekt.",
	"FLOWS.SEND.CONTACT_SELECTION.BUTTONS.NEXT": "Volgende",
	"FLOWS.SEND.CONTACT_SELECTION.BUTTONS.SEND": "{value, plural, one {E-mail verzenden} other {E-mails verzenden}}",
	"FLOWS.SEND.CONTACT_SELECTION.DESCRIPTION": "Selecteer je contacten door ze te filteren.",
	"FLOWS.SEND.CONTACT_SELECTION.NO_EMAIL": "E-mailadres ontbreekt.",
	"FLOWS.SEND.CONTACT_SELECTION.SEND_TO_COMPLETED_REPORTS":
		"Verzend dit bericht ook naar contacten die de flow al vervolledigd hebben",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.CANNOT_BE_SENT": "E-mailadres ontbreekt of is ongeldig.",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.CAN_BE_SENT": "Verzenden mogelijk",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.CAN_BE_SENT": "Verzenden mogelijk",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.EMAIL": "E-mailadres",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.NAME": "Naam",
	"FLOWS.SEND.CONTACT_SELECTION.TITLE": "Contacten selecteren",
	"FLOWS.SEND.OVERVIEW.CHANNEL": "Kanaal:",
	"FLOWS.SEND.OVERVIEW.DESCRIPTION":
		"Kijk je bericht en je geselecteerde contacten na vooraleer je de e-mail verzendt.",
	"FLOWS.SEND.OVERVIEW.EMAIL": "E-mail:",
	"FLOWS.SEND.OVERVIEW.FLOW": "Flow:",
	"FLOWS.SEND.OVERVIEW.SEND_FLOW_FAILED.DESCRIPTION":
		"Er is iets misgegaan bij het verzenden van de flow. Probeer het later opnieuw of neem contact op met onze klantendienst.",
	"FLOWS.SEND.OVERVIEW.SEND_FLOW_FAILED.TITLE": "Flow kan niet worden verzonden.",
	"FLOWS.SEND.OVERVIEW.SUBJECT": "Onderwerp:",
	"FLOWS.SEND.OVERVIEW.TITLE": "Overzicht",
	"FLOWS.SEND.RESULT.CLOSE": "Sluiten",
	"FLOWS.SEND.RESULT.DESCRIPTION":
		"Je flow wordt momenteel naar alle geselecteerde contacten verstuurd. Dit kan tot 30 minuten duren. Je kan de status per rapport opvolgen in het rapportoverzicht onder ‘Progressie’. Je ontvangt een melding wanneer alle e-mails verzonden zijn.",
	"FLOWS.SEND.RESULT.REPORTS": "Ga naar rapporten",
	"FLOWS.SEND.RESULT.TITLE": "Je flow wordt momenteel naar alle geselecteerde contacten verstuurd.",
	"FLOWS.SEND.STEP.COMPOSE_MESSAGE": "Bericht samenstellen",
	"FLOWS.SEND.STEP.CONTACT_SELECT": "Contacten selecteren",
	"FLOWS.SEND.STEP.OVERVIEW": "Overzicht",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.EMPTY": "Er zijn geen e-mailadressen aan je gebruiker gekoppeld.",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.EMPTY.ADD": "E-mailadres toevoegen",
	"FLOW_DELETE_MODAL.DELETE.BUTTON_LABEL": "Flow verwijderen",
	"FLOW_DELETE_MODAL.HEADER.DESCRIPTION":
		"Ben je er zeker van dat je deze flow wil verwijderen? Deze actie kan niet ongedaan gemaakt worden. Alle links naar de flow worden gedeactiveerd.",
	"FLOW_DELETE_MODAL.HEADER.TITLE": "Flow verwijderen",
	"FLOW_DETAIL.REPORT.BUTTON.AUTOMATED_REMINDERS": "Automatische herinneringen",
	"FLOW_DETAIL.REPORT.BUTTON.MANUAL_REMIND": "Manuele herinneringen per contact",
	"FLOW_DETAIL.REPORT.BUTTON.REMINDER_OPTIONS": "Opties voor herinneringen",
	"IMPORT_CONTACTS_MODAL.INTEGRATIONLIST.REFETCH": "Opnieuw ophalen",
	"INBOX.COMING_SOON.DESCRIPTION":
		"Werk e-mails sneller af door efficiënt samen te werken in de gedeelde team inbox.",
	"INBOX.COMING_SOON.FEATURES.ASSIGN": "Wijs e-mails aan teamleden toe.",
	"INBOX.COMING_SOON.FEATURES.INBOX_ZERO": "Creëer rond elke e-mail een interne chatconversatie.",
	"INBOX.COMING_SOON.FEATURES.TEAM_INBOX": "Bereik inbox zero door optimaal samen te werken.",
	"INBOX.COMING_SOON.TITLE": "Bothive inbox",
	"INBOX.CONVERSATION.EMPTY_STATE.LABEL": "Selecteer een conversatie om van start te gaan.",
	"INBOX.CONVERSATION.LIST.HAS_ATTACHMENTS.TOOLTIP": "Bevat {count, plural, one {1 bijlage} other {# bijlagen}}.",
	"INBOX.CONVERSATION.NOT_FOUND_STATE.BUTTON.RELOAD": "Opnieuw zoeken",
	"INBOX.CONVERSATION.NOT_FOUND_STATE.LABEL": "Geen conversaties gevonden.",
	"INBOX.MESSAGES.NOT_FOUND_STATE.LABEL": "Geen bericht gevonden.",
	"INTEGRATION.CONTACTS.IMPORT.OPTIONS.TABLE.CONDITION": "Status",
	"INTEGRATION.CONTACTS.IMPORT.OPTIONS.TABLE.EDIT": "Bewerken",
	"INTEGRATION.CONTACTS.IMPORT.OPTIONS.TABLE.LAST_SYNC": "Laatst gesynchroniseerd op:",
	"INTEGRATION.CONTACTS.IMPORT.OPTIONS.TABLE.LAST_SYNC.NOT_EXECUTED_YET": "Nog geen synchronisatie uitgevoerd.",
	"INTEGRATION.CONTACTS.IMPORT.OPTIONS.TABLE.UPCOMING_SYNC": "Volgende synchronisatie op:",
	"INTEGRATIONS.CONTACTS.IMPORT.OPTIONS.TABLE.STATE.ACTIVE": "Actief",
	"INTEGRATIONS.CONTACTS.IMPORT.OPTIONS.TABLE.STATE.INACTIVE": "Inactief",
	"ORGANIZATION_SETTINGS.GENERAL.SUGGESTIONS.TITLE": "Gerelateerde instellingen",
	"POPUP.BUTTON.CANCEL": "Annuleren",
	"POPUP.PLACEHOLDER.ARCHIVE": "Typ ARCHIVEREN en klik op ARCHIVEREN.",
	"PROFILE.POPUP.LOGOUT": "Uitloggen",
	"PROFILE.POPUP.SETTINGS": "Profielinstellingen",
	"PROFILE_TEAMS.TABLE.EMPTY_SEARCH": "Geen teams gevonden.",
	"PROFILE_TEAMS.TABLE.HEADER.TEAM_NAME": "Teamnaam",
	"PROFILE_TEAMS.TABLE.NO_TEAMS": "Je maakt nog geen deel uit van een team.",
	"SETTINGS.ADVANCED.EXPERIMENTAL_FEATURES.SUBMIT": "Opslaan",
	"SETTINGS.API_KEY_DETAIL.FORM.API_KEY.LABEL": "API-sleutel",
	"SETTINGS.API_KEY_MODAL.DELETE_API_KEY.FORM.DELETE.PLACEHOLDER": "Typ {keyword} en klik daarna op verwijderen.",
	"SETTINGS.CONTACTS.LABELS.EMPTY": "Geen labels gevonden.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.ACCORDION_TITLE": "Koppeling verwijderen",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.BUTTON": "Koppeling verwijderen",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.DESCRIPTION":
		"Ben je er zeker van dat je de koppeling met AdminPulse wil verwijderen? De functionaliteiten van deze integratie zullen niet meer beschikbaar zijn vanaf het moment waarop je ze verwijdert.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.TITLE": "Verwijder integratie met AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.FORM_SECTION.DESCRIPTION":
		"Door met AdminPulse te koppelen importeer je beschikbare contactgegevens in Bothive en andersom.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.FEATURES.SYNC_CONTACTS": "Contacten importeren en synchroniseren",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.OAUTH2.BUTTON": "Log in met AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.OAUTH2.DESCRIPTION": "Koppel met AdminPulse via de AdminPulse website.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.TITLE": "AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.SYNC_CONTACTS.BUTTON_LABEL": "Contacten importeren",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.SYNC_CONTACTS.DESCRIPTION":
		"Importeer automatisch je contactdata vanuit AdminPulse naar Bothive en andersom.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.SYNC_CONTACTS.TITLE": "Importeer AdminPulse contacten naar Bothive",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.ADD": "Toevoegen",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.ADD.SUCCESS": "Importoptie succesvol aangemaakt.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.ADD_RULE.BUTTON": "Regel toevoegen",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.DELETE": "Verwijderen",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.DELETE.ERROR":
		"Er ging iets mis bij het verwijderen van de importoptie.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.DELETE.SUCCESS": "Importoptie succesvol verwijderd.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.FILTERS.TITLE": "Filters",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.FILTERS.TITLE.TOOLTIP":
		"Hier maak je de filters die je aan een selectie van deze contacten wil toevoegen.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.IMPORT_RULES.TITLE": "Importregels",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.IMPORT_RULES.TITLE.TOOLTIP":
		"Hier maak je de labels en de regels aan die je aan een selectie van deze contacten wil toevoegen.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.IS_ACTIVE": "Is actief",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.LABEL_RULES_TABLE.TITLE": "Labelregels",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.LABEL_TO_APPLY": "Label toevoegen",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.NAME": "Naam importoptie",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.NAME.REQUIRED": "Naam is verplicht.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.SYNC_FILES":
		"Synchroniseer ook de bijhorende bestanden van deze contacten",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.TITLE": "Importoptie toevoegen",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.UPDATE": "Bijwerken",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.UPDATE.ERROR":
		"Er ging iets mis bij het opslaan van deze importoptie.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.ADD.MODAL.UPDATE.SUCCESS": "Importoptie succesvol bijgewerkt.",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.EDIT.MODAL.TITLE": "Importoptie bewerken",
	"SETTINGS.INTEGRATIONS.AUTO_CONTACT_SYNC.TITLE": "Importeer contacten automatisch",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.BUTTON": "Koppeling verwijderen",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.DESCRIPTION":
		"Ben je er zeker van dat je de koppeling met FID-Manager wil verwijderen? De functionaliteiten van deze integratie zullen niet meer beschikbaar zijn vanaf het moment waarop je ze verwijdert.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.TITLE": "Koppeling met FID-Manager verwijderen",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.FORM_SECTION.DESCRIPTION":
		"Koppel met FID-Manager om contactdata aan te vullen in Bothive en andersom. De contactinfo wordt automatisch met beide platformen gesynchroniseerd om steeds up-to-date te blijven.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.FORM_SECTION.TITLE": "Bothive is gekoppeld met FID-Manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.DESCRIPTION": "Koppel je FID-Manager contacten met Bothive.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FEATURES.SYNC_CONTACTS": "Contacten importeren en synchroniseren",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.BASE_URL.LABEL": "URL",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.BASE_URL.PLACEHOLDER": "https://bedrijfsnaam.fid-manager.be/nl",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.LABEL": "Login",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.PLACEHOLDER": "a000a000-000a-0000-a0aa-aaa00a00a0000",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.REQUIRED": "Voer je FID-Manager login in.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.PASSWORD.LABEL": "Wachtwoord",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.PASSWORD.REQUIRED": "Voer je FID-Manager wachtwoord in.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.SUBMIT": "Koppel met FID-Manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.TITLE": "FID-Manager",
	"SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.BUTTON": "Log in met Liantis",
	"SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.DESCRIPTION":
		"Koppel Bothive met Liantis om elke KBO-oprichting te automatiseren.",
	"SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.TITLE": "Koppel met Liantis",
	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.BUTTON": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.DESCRIPTION":
		"Ben je er zeker van dat je de koppeling met Liantis wil stopzetten? De functionaliteiten van deze integratie zullen niet meer beschikbaar zijn vanaf het moment waarop je ze verwijdert.",
	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.LIANTIS.FORM_SECTION.DESCRIPTION":
		"Stuur het rapport uit de onboarding flow met één klik door naar Liantis en automatiseer elke KBO-oprichting.",
	"SETTINGS.INTEGRATIONS.LIANTIS.FORM_SECTION.TITLE": "Bothive is gekoppeld met Liantis",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.ACCORDION_TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.BUTTON": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.DESCRIPTION":
		"Ben je er zeker van dat je de koppeling met TwinnTax wil stopzetten? De functionaliteiten van deze integratie zijn niet langer beschikbaar vanaf het moment waarop je ze verwijdert.",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.TITLE": "Integratie verwijderen",
	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM_SECTION.TITLE": "Bothive is gekoppeld met Twinntax",
	"SETTINGS.INTEGRATIONS.TWINNTAX.SYNC_CONTACTS.BUTTON_LABEL": "Importeer contacten",
	"SETTINGS.INTEGRATIONS.TWINNTAX.SYNC_CONTACTS.DESCRIPTION":
		"Importeer je TwinnTax contactdata in Bothive om de personenbelasting flow vooraf aan te vullen met gekende MyMinfin data per contact.",
	"SETTINGS.INTEGRATIONS.TWINNTAX.SYNC_CONTACTS.TITLE": "TwinnTax contactdata importeren",
	"SETTINGS.MEMBERS.INVITE.TITLE": "Gebruiker uitnodigen",
	"SETTINGS.NOTIFICATIONS.BILLING.FORM.EMAIL.EMAIL_ERROR": "Vul een geldig e-mailadres in.",
	"SETTINGS.ORGANIZATION.MEMBER.ADD.FAILED":
		"Er ging iets mis bij het uitnodigen van deze gebruiker. Probeer het later opnieuw.",
	"SETTINGS.ORGANIZATION.MEMBER.ADD.LIMIT":
		"Je hebt het maximaal aantal gebruikers in je licentie bereikt. Upgrade je abonnement om meer gebruikers aan je team toe te voegen.",
	"SETTINGS.ORGANIZATION.MEMBER.ADD.SUCCESS": "Uitnodiging succesvol verstuurd.",
	"SETTINGS.ORGANIZATION.MEMBER.EXISTS": "Deze gebruiker maakt al deel uit van je team.",
	"SETTINGS.ORGANIZATION.SOCIALS.UPDATE.FAILED":
		"Er ging iets mis bij opslaan van je aanpassingen. Probeer het later opnieuw.",
	"SETTINGS.ORGANIZATION.SOCIALS.UPDATE.SUCCESS": "Je social media werd succesvol opgeslagen.",
	"SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.BUTTON": "Bedrijfsgegevens beheren",
	"SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.DESCRIPTION": "Beheer en bewerk je algemene bedrijfsinstellingen.",
	"SETTINGS.SUGGESTIONS.OPENING_HOURS.BUTTON": "Openingsuren beheren",
	"SETTINGS.SUGGESTIONS.OPENING_HOURS.DESCRIPTION": "Beheer en je bewerk je openingsuren.",
	"SETTINGS.USERS_POPUP.EMAIL.HELP": "Geen e-mail ontvangen? Bekijk je spamfolder of contacteer onze klantendienst.",
	"api_key_create_modal.delete.add": "API-sleutel aanmaken",
	"api_key_create_modal.header.title": "API-sleutel aanmaken",
	"api_key_overview.header.breadcrumb.settings": "Instellingen",
	"api_key_overview.header.breadcrumb.title": "Overzicht van je API-sleutels",
	"api_key_overview.lock.button.contact_support": "Licentie verhogen",
	"api_key_overview.lock.description":
		"API-sleutels geven je de mogelijkheid om je Bothive team te koppelen aan externe platformen.",
	"api_key_overview.lock.title": "Versterk account met API-sleutels",
	"api_key_overview.no_keys_found.button.create_api_key": "Maak een API-sleutel aan",
	"api_key_overview.no_keys_found.description":
		"API-sleutels geven je de mogelijkheid om je Bothive team te koppelen aan externe platformen.",
	"api_key_overview.no_keys_found.title": "Versterk account met API-sleutels",
	"api_key_overview.page.title": "API-sleuteloverzicht",
	"api_key_overview.table.header.api_key": "API-sleutel",
	"api_key_overview.table.header.created_at": "Aangemaakt op",
	"api_key_overview.table.header.description": "Omschrijving",
	"api_key_overview.table.header.is_enabled": "Is ingeschakeld",
	"api_key_overview.table.header.permissions": "Machtigingen",
	"banner.failed.create_api_key": "Er ging iets mis bij het aanmaken van de API-sleutel. Probeer het later opnieuw..",
	"banner.failed.delete_api_key":
		"Er ging iets mis bij het verwijderen van de API-sleutel. Probeer het later opnieuw..",
	"banner.failed.settings_update":
		"Er ging iets mis bij het opslaan van je aanpassingen. Probeer het later opnieuw..",
	"banner.failed.update_api_key": "Er ging iets mis bij het bewerken van je API-sleutel. Probeer het later opnieuw.",
	"banner.succeeded.copy_key": "Gekopieerd naar het klembord.",
	"banner.succeeded.create_api_key": "API-sleutel succesvol aangemaakt.",
	"banner.succeeded.delete_api_key": "API-sleutel succesvol verwijderd.",
	"banner.succeeded.update_api_key": "API-sleutel succesvol bijgewerkt.",
	"boolean.no": "Nee",
	"boolean.yes": "Ja",
	"channel.status.connected.tooltip": "Kanaal verbonden",
	"channel.status.deleting.tooltip": "Kanaal wordt verwijderd.",
	"channel.status.failed.tooltip": "Er ging iets mis bij het synchroniseren. Probeer het later opnieuw.",
	"channel.status.in_progress.tooltip": "Bezig met synchroniseren.",
	"channel.status.pending.tooltip": "Bezig met synchroniseren.",
	"channel.status.success.tooltip": "Synchronisatie voltooid.",
	"channel.status.connected.tooltip": "Kanaal gekoppeld",
	"channels.connect_channel.add_channel.description": "Selecteer het e-mailkanaal dat je aan Bothive wil koppelen.",
	"channels.connect_channel.add_channel.error.blocked_popup":
		"Er wordt een pop-up scherm geopend om in te loggen. Geef ons hiervoor toestemming in de instellingen van je browser.",
	"channels.connect_channel.add_channel.error.generic_error":
		"Er ging iets mis bij het koppelen met {providerName}. Probeer het later opnieuw.",
	"channels.connect_channel.add_channel.title": "Kanalen toevoegen",
	"channels.connect_channel.setup_channel.create":
		"Er ging iets mis bij het instellen van deze kanalen. Probeer het later opnieuw.",
	"channels.connect_channel.setup_channel.error.channel_already_connected":
		"Je hebt dit kanaal al aan Bothive gekoppeld in dit of een ander team.",
	"channels.connect_channel.setup_channel.form.auto_close.help":
		"Markeer automatisch je bekeken e-mails als voltooid zodat je een overzichtelijke inbox behoudt.",
	"channels.connect_channel.setup_channel.form.auto_close.label": "Automatisch sluiten",
	"channels.connect_channel.setup_channel.form.name.error.required": "Kanaalnaam is verplicht.",
	"channels.connect_channel.setup_channel.form.name.label": "Naam van het kanaal",
	"channels.connect_channel.setup_channel.form.submit": "Kanaal aanmaken",
	"channels.connect_channel.setup_channel.title": "Kanalen instellen",
	"channels.connect_channel.steps.add_channel": "Kanaal toevoegen",
	"channels.connect_channel.complete.close_screen": "Sluiten",
	"channels.connect_channel.complete.description":
		"Jouw kanaal is verbonden met jouw organisatie. Je kan het nu gebruiken om flows naar jouw contacten te sturen.",
	"channels.connect_channel.complete.title": "Kanaal verbonden",
	"channels.connect_channel.steps.setup_channel": "Kanaal instellen",
	"channels.connect_channel.steps.sync": "Synchroniseren",
	"channels.connect_channel.syncing.close_screen": "Synchronisatiescherm sluiten",
	"channels.connect_channel.syncing.description":
		"Je kanaal wordt momenteel met de Bothive inbox gesynchroniseerd. Je kan de inbox al beginnen gebruiken na enkele minuten, volledige synchronisatie kan tot enkele dagen duren. Je kan de inbox gebruiken terwijl we je oude mails verwerken.",
	"channels.connect_channel.syncing.title": "Bezig met synchroniseren. 🚀",
	"component.openings_hours.add_new_time": "Openingstijd toevoegen",
	"component.openings_hours.and": "en van",
	"component.openings_hours.from": "Van",
	"contact.import.settings.date.restriction":
		"Op dit moment zijn de enige ondersteunde formaten voor datums: <list></list>. Andere formaten worden niet toegelaten.",
	"contact.type.anonymous.title": "Anonieme gebruiker",
	"contact.type.customer.title": "Klant",
	"contact.type.linked_contact.title": "Gelinkt contact",
	"contact.type.other.title": "Andere",
	"contact.type.prospect.title": "Prospect",
	"contacts.archived_overview.bulk_action.export.failed.title":
		"Er ging iets mis bij het importeren van je contacten. Probeer het later opnieuw.",
	"contacts.archived_overview.bulk_action.export.filename": "Gearchiveerde contacten",
	"contacts.archived_overview.bulk_action.export.form.type.help": "Gewenst bestandstype voor de export",
	"contacts.archived_overview.bulk_action.export.form.type.label": "Bestandstype",
	"contacts.archived_overview.bulk_action.export.info_banner":
		"Het importeren van je contacten kan even duren. Sluit je browser niet af vooraleer alle contacten geïmporteerd zijn.",
	"contacts.archived_overview.bulk_action.export.modal.footer.cancel": "Annuleren",
	"contacts.archived_overview.bulk_action.export.modal.footer.submit": "Importeren",
	"contacts.archived_overview.bulk_action.export.modal.title": "Contacten importeren",
	"contacts.detail.attributes.bank_accounts.add_button": "Bankrekeningnummer toevoegen",
	"contacts.detail.attributes.bank_accounts.title": "Bankrekeningnummers en cryptomunten",
	"contacts.detail.attributes.email_address.label.billing": "Facturatie",
	"contacts.detail.attributes.email_address.label.other": "Andere",
	"contacts.detail.attributes.email_address.label.private": "Privé",
	"contacts.detail.attributes.email_address.label.standard": "Standaard",
	"contacts.detail.attributes.email_address.label.work": "Werk",
	"contacts.detail.attributes.email_addresses.add.address": "E-mailadres toevoegen",
	"contacts.detail.attributes.email_addresses.add.address.primary.label": "Primair",
	"contacts.detail.attributes.email_addresses.add.address.primary.tooltip":
		"Maak eerst een ander e-mailadres primair.",
	"contacts.detail.attributes.email_addresses.add.delete_button.tooltip":
		"Een primair e-mailadres kan niet worden verwijderd.",
	"contacts.detail.attributes.email_addresses.address.invalid_error": "Voer een geldig e-mailadres in.",
	"contacts.detail.attributes.email_addresses.address.title": "Adres",
	"contacts.detail.attributes.email_addresses.form.error": "Voer minstens 1 geldig e-mailadres in.",
	"contacts.detail.attributes.email_addresses.label.title": "Label",
	"contacts.detail.attributes.email_addresses.submit": "Opslaan",
	"contacts.detail.attributes.email_addresses.update.failed.description": "Dit adres werd eerder al toegevoegd.",
	"contacts.detail.attributes.email_addresses.update.failed.title":
		"Er ging iets mis bij het opslaan van het e-mailadres. Probeer het later opnieuw.",
	"contacts.detail.attributes.email_addresses.update.success.title": "E-mailadres succesvol opgeslagen.",
	"contacts.detail.attributes.phone.add.number": "Telefoonnummer toevoegen",
	"contacts.detail.attributes.phone.delete_button.tooltip": "Een primair telefoonnummer kan niet worden verwijderd.",
	"contacts.detail.attributes.phone.label.company": "Bedrijf",
	"contacts.detail.attributes.phone.label.home": "Vast nummer",
	"contacts.detail.attributes.phone.label.mobile": "Mobiel",
	"contacts.detail.attributes.phone.label.other": "Andere",
	"contacts.detail.attributes.phone.label.private": "Privé",
	"contacts.detail.attributes.phone.label.standard": "Standaard",
	"contacts.detail.attributes.phone.label.title": "Label",
	"contacts.detail.attributes.phone.label.whatsapp": "WhatsApp",
	"contacts.detail.attributes.phone.number.invalid_error": "Voer een geldig telefoonnummer in.",
	"contacts.detail.attributes.phone.number.label": "Telefoonnummer",
	"contacts.detail.attributes.phone.primary.label": "Primair",
	"contacts.detail.attributes.phone.primary.tooltip": "Maak eerst een ander telefoonnummer primair.",
	"contacts.detail.attributes.phone.submit": "Opslaan",
	"contacts.detail.attributes.phone.update.failed.description": "Dit telefoonnummer werd eerder al toegevoegd.",
	"contacts.detail.attributes.phone.update.failed.title":
		"Er ging iets mis bij het opslaan van dit telefoonnummer. Probeer het later opnieuw",
	"contacts.detail.attributes.phone.update.success.title": "Telefoonnummer succesvol opgeslagen.",
	"contacts.detail.attributes.save.failed":
		"Er ging iets mis bij het bijwerken van de contactinfo. Probeer het later opnieuw.",
	"contacts.detail.attributes.save.failed.duplicate.description":
		"De inhoud van dit veld moet uniek zijn in dit team.",
	"contacts.detail.attributes.save.failed.duplicate.title":
		"Er ging iets mis bij het opslaan van het contact. Probeer het later opnieuw.",
	"contacts.detail.attributes.save.success": "Contact succesvol opgeslagen.",

	"contacts.detail.attributes.external_links.title": "Externe links",
	"contacts.detail.attributes.external_links.admin_pulse.label": "Admin Pulse",

	"contacts.detail.PROPERTIES_MODAL.form.building_type.error_required": "Type woonst is verplicht.",
	"contacts.detail.PROPERTIES_MODAL.form.building_type.label": "Type woonst",
	"contacts.detail.PROPERTIES_MODAL.form.cadastral_income.label": "Kadastraal inkomen",
	"contacts.detail.PROPERTIES_MODAL.form.has_re_mvp_split.label":
		"Opsplitsing tussen onroerende en roerende huur in het huurcontract",
	"contacts.detail.PROPERTIES_MODAL.form.is_co_ownership.label": "Eigendom heeft meerdere eigenaars",
	"contacts.detail.PROPERTIES_MODAL.form.is_rented_out.label": "Eigendom wordt verhuurd",
	"contacts.detail.PROPERTIES_MODAL.form.is_sold.label": "Eigendom is reeds verkocht",
	"contacts.detail.PROPERTIES_MODAL.form.name.help":
		"Geef het vastgoed een unieke naam waaraan je het kan herkennen.",
	"contacts.detail.PROPERTIES_MODAL.form.name.label": "Naam",
	"contacts.detail.PROPERTIES_MODAL.form.name.placeholder": "Villa in Spanje",
	"contacts.detail.PROPERTIES_MODAL.form.original_value.help":
		"De originele waarde van dit eigendom voor de renovatie.",
	"contacts.detail.PROPERTIES_MODAL.form.original_value.label": "Originele waarde",
	"contacts.detail.PROPERTIES_MODAL.form.ownership_type.error_required": "Type eigendom is verplicht.",
	"contacts.detail.PROPERTIES_MODAL.form.ownership_type.label": "Type eigendom",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_movable_property.label": "Percentage roerend goed verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_private_rented.label": "Percentage privéverhuur",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_professionally_rented.label": "Percentage professioneel verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.percentage_real_estate.label": "Percentage onroerend goed verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.possible_rent_amount.help":
		"De totale huurinkomsten mochten alle kamers/appartementen/... verhuurd zijn",
	"contacts.detail.PROPERTIES_MODAL.form.possible_rent_amount.label": "Waarde indien verhuurd",
	"contacts.detail.PROPERTIES_MODAL.form.property_value.help":
		"De huidige waarde van dit eigendom inclusief renovatie",
	"contacts.detail.PROPERTIES_MODAL.form.property_value.label": "Huidige waarde",
	"contacts.detail.PROPERTIES_MODAL.form.purchase_date.label": "Aankoopdatum",
	"contacts.detail.PROPERTIES_MODAL.form.renovation_costs.label": "Renovatiekosten",
	"contacts.detail.PROPERTIES_MODAL.form.rent_amount.help": "De jaarlijkse huurinkomsten van dit eigendom",
	"contacts.detail.PROPERTIES_MODAL.form.rent_amount.label": "Huurinkomsten",
	"contacts.detail.PROPERTIES_MODAL.form.rent_type.error_required": "Type verhuur is verplicht.",
	"contacts.detail.PROPERTIES_MODAL.form.rent_type.help":
		"Het type verhuur van dit eigendom (privé of professioneel)",
	"contacts.detail.PROPERTIES_MODAL.form.rent_type.label": "Type verhuur",
	"contacts.detail.PROPERTIES_MODAL.form.rented_with_furniture.label": "Wordt verhuurd met meubilair",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.error": "De verkoopdatum kan niet voor de aankoopdatum liggen.",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.help": "Datum waarop dit eigendom is verkocht",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.label": "Verkoopdatum",
	"contacts.detail.PROPERTIES_MODAL.form.type.error_required": "Type eigendom is verplicht.",
	"contacts.detail.PROPERTIES_MODAL.form.type.label": "Type eigendom",
	"contacts.detail.settings.restore.button": "Contact herstellen",
	"contacts.detail.settings.restore.description":
		"Dit contact is verwijderd en zal definitief worden verwijderd binnen <bold>{days, plural, =0 {Today} one {{days} day} other {{days} days}}</bold> als je dit contact niet herstelt voor deze datum. Als dit contact niet wordt verwijderd uit externe bronnen, is het mogelijk dat het opnieuw wordt toegevoegd wanneer het verandert in die bron nadat het definitief is verwijderd uit Bothive",
	"contacts.detail.settings.restore.extra.files": "Alle rapporten van dit contact",
	"contacts.detail.settings.restore.extra.linked_contacts": "Gelinkte contacten",
	"contacts.detail.settings.restore.extra.reports": "Rapporten",
	"contacts.detail.settings.restore.extra.title":
		"Als je dit contact verwijdert, zal deze data automatisch mee verwijderd worden:",
	"contacts.detail.settings.restore.failed":
		"Er ging iets mis bij het herstellen van {fullName}. Probeer het later opnieuw.",
	"contacts.detail.settings.restore.succeeded": "{fullName} werd succesvol hersteld.",
	"contacts.detail.settings.restore.title": "Contact herstellen",
	"contacts.import.auto_sync.sync_only_existing_contacts":
		"Synchroniseer alleen contacten die al aanwezig zijn in Bothive",
	"contacts.import.import_options.title": "Importopties",
	"contacts.import.validate.csv_toolbar.split_name": "Namen splitsen",
	"contacts.import.validate.csv_toolbar.switch_name": "Namen omwisselen",
	"contacts.import.validate.csv_toolbar.title": "CSV-import hulpmiddelen",
	"contacts.import.validate.csv_toolbar.tooltip": "Deze actie uitvoeren op alle onvolledige contacten",
	"contacts.import.validate.skip.tooltip.return": "Als je teruggaat gaan je ingevulde gegevens verloren.",
	"contacts.import.validate.spit_name.cancel": "Annuleren",
	"contacts.import.validate.spit_name.modal.order.help":
		"Namen splitsen is een geautomatiseerd hulpmiddel dat helpt bij het splitsen van de contactnamen met een nauwkeurigheid van 85%.",
	"contacts.import.validate.spit_name.modal.order.label":
		"In welke volgorde staan de namen van de contacten geordend?",
	"contacts.import.validate.spit_name.modal.order.option.first_last_name": "<voornaam> <familienaam>",
	"contacts.import.validate.spit_name.modal.order.option.last_first_name": "<familienaam> <voornaam>",
	"contacts.import.validate.spit_name.submit": "Namen splitsen",
	"contacts.import.validate.split_name_modal.failed":
		"Er ging iets mis bij het splitsen van de contactnamen. Probeer het later opnieuw.",
	"contacts.import.validate.split_name_modal.title": "Namen splitsen",
	"contacts.overview.bulk_action.download.failed.title":
		"Er ging iets mis bij het importeren van je contacten. Probeer het later opnieuw.",
	"contacts.overview.bulk_action.download.filename": "Contacten",
	"contacts.overview.bulk_action.download.form.type.help": "Kies het gewenst bestandstype voor de export.",
	"contacts.overview.bulk_action.download.form.type.label": "Bestandstype",
	"contacts.overview.bulk_action.download.info_banner":
		"Het exporteren van je contacten kan even duren. Sluit dit venster niet tot de import voltooid is.",
	"contacts.overview.bulk_action.download.modal.title": "Contacten exporteren",
	"contacts.overview.bulk_action.export.button.title": "Exporteren",
	"contacts.overview.bulk_action.export.modal.footer.cancel": "Annuleren",
	"contacts.overview.bulk_action.export.modal.footer.submit_button": "Exporteren",
	"contacts.overview.select.type.anonymous.description": "Contact dat je anoniem heeft gecontacteerd",
	"contacts.overview.select.type.customer.description": "Contacten uit je klantenbestand",
	"contacts.overview.select.type.linked_contact.description":
		"Contacten die aan je contacten gelinkt zijn, maar die niet tot je klantenbestand behoren",
	"contacts.overview.select.type.other.description": "Contacten van een ander type waarop je wil kunnen filteren",
	"contacts.overview.select.type.prospect.description": "Contacten die al met je kantoor in contact kwamen",
	"contacts.settings.add.rule.modal.eid_expiration_date.fixed_value": "de dag van de import",
	"contacts.settings.add.rule.modal.eid_expiration_date.fixed_value.tooltip":
		"Opgelet: bij het aanduiden van de vervaldatum van het eID, is het enkel mogelijk om ‘minder dan’ of ‘is gelijk aan’ regels te selecteren.",
	"contacts.settings.labels.name.validation_rule.too_long": "De naam mag maximum 56 karakters lang zijn.",
	"contacts_import.validate.form.full_name.error_required": "Vul de volledige naam in.",
	"contacts_import.validate.form.full_name.label": "Naam",
	"contacts_label_modal.delete_label.body":
		"Ben je er zeker van dat je dit label wil verwijderen? Verwijderde labels verdwijnen definitief bij alle bijhorende contacten en instellingen.",
	"contacts_label_modal.delete_label.title": "Label verwijderen",
	"contacts_label_modal.update.footer.cancel": "Annuleren",
	"contacts_label_modal.update.footer.delete": "Verwijderen",
	"contacts_label_modal.update.footer.submit": "Aanpassen",
	"contacts_label_modal.update.title": "Label aanpassen",
	"conversation.compose.draft.create.failed": "Concept kon niet worden aangemaakt. Probeer het later opnieuw.",
	"conversation.compose.draft.discard.failed": "Concept kon niet worden verwijderd. Probeer het later opnieuw.",
	"conversation.compose.draft.send.failed": "Concept kon niet worden verzonden. Probeer het later opnieuw.",
	"conversation.compose.draft.sent.succeeded": "Bericht verzonden.",
	"conversation.compose.draft.update.failed": "Concept kon niet worden bijgewerkt. Probeer het later opnieuw.",
	"conversation.detail.actions.assignee.label": "Toewijzen",
	"conversation.detail.actions.assignee.title": "Toewijzen",
	"conversation.detail.actions.labels.add_label": "Label toevoegen",
	"conversation.detail.actions.participants.add": "Toevoegen",
	"conversation.detail.actions.participants.label": "Deelnemers",
	"conversation.detail.actions.read.failed":
		"Er ging iets mis bij het wijzigen van de status naar gelezen. Probeer het later opnieuw.",
	"conversation.detail.actions.unread.failed":
		"Er ging iets mis bij het wijzigen van de status naar ongelezen. Probeer het later opnieuw.",
	"conversation.detail.messages.internal.send.failed":
		"Intern chatbericht kon niet worden verzonden. Probeer het later opnieuw.",
	"conversation.subject.empty": "(Geen onderwerp)",
	"editor.signature_node.dropdown.label": "Handtekening wijzigen",
	"editor.signature_node.no_signature.description":
		"Je hebt (voor dit kanaal) nog geen handtekeningen toegevoegd. Je kan je handtekeningen beheren in de instellingen van je inbox.",
	"editor.signature_node.no_signature.redirect_button": "Handtekening toevoegen",
	"editor.table_menu.add_cell_header": "Celkop toevoegen",
	"editor.table_menu.add_column_header": "Kolomkop toevoegen",
	"editor.table_menu.add_row_header": "Rijkop toevoegen",
	"editor.table_menu.clear_cell": "Cel wissen",
	"editor.table_menu.delete_column": "Kolom verwijderen",
	"editor.table_menu.delete_row": "Rij verwijderen",
	"editor.table_menu.delete_table": "Tabel verwijderen",
	"editor.table_menu.insert_column_left": "Voeg {selectedColumns, plural, =1 {# kolom} other {# kolommen}} links in",
	"editor.table_menu.insert_column_right":
		"Voeg {selectedColumns, plural, =1 {# kolom} other {# kolommen}} rechts in",
	"editor.table_menu.insert_row_above": "Voeg {selectedRows, plural, =1 {# rij} other {# rijen}} hierboven in",
	"editor.table_menu.insert_row_below": "Voeg {selectedRows, plural, =1 {# rij} other {# rijen}} hieronder in",
	"editor.table_menu.merge_cells": "Cellen samenvoegen",
	"editor.table_menu.remove_cell_header": "Celkop verwijderen",
	"editor.table_menu.remove_column_header": "Kolomkop verwijderen",
	"editor.table_menu.remove_row_header": "Rijkop verwijderen",
	"editor.table_menu.remove_sorting": "Sortering verwijderen",
	"editor.table_menu.sort_ascending": "Sorteer oplopend",
	"editor.table_menu.sort_descending": "Sorteer aflopend",
	"editor.table_menu.un_merge_cells": "Cellen splitsen",
	"file_upload.upload_error.file_size_error": "Het is niet mogelijk om bijlagen groter dan 10 MB op te laden.",
	"file_upload.error.duplicate.message": "Dit bestand is al geüpload.",
	"file_upload.error.limit.message": "Je mag {plural,limit, one {# bestand} other {# bestanden}} uploaden.",
	"file_upload.error.size.message": "Maximale bestandsgrootte is 25 MB.",
	"file_upload.error.type.message": "Bestandstype is niet toegestaan.",
	"file_upload.segment.label": "Klik of sleep bestand(en) naar dit gebied om te uploaden.",
	"file_upload.upload.failed": "We kunnen dit bestand tijdelijk niet uploaden. Probeer het later opnieuw.",

	"filter.api_key.button.create_api_key": "API-sleutel aanmaken",
	"filter.api_key.button.create_api_key.tooltip": "Upgrade je abonnement om meer API-sleutels aan te maken.",
	"flow_actions.completed": "voltooid",
	"flow_actions.reviewed": "afgewerkt",
	"flow_actions.send": "verzonden",
	"flow.detail.reminders.banner.stopped.button.restart.title": "Herinneringen herstarten",
	"flow.detail.reminders.banner.stopped.title":
		"De automatische herinneringen voor deze flow werden reeds beëindigd.",
	"flow.detail.reminders.create_strategy.allow_reenroll.1":
		"Pas de ingestelde herinneringen automatisch toe op nieuwe rapporten.",
	"flow.detail.reminders.create_strategy.step_1.button.submit": "Volgende",
	"flow.detail.reminders.create_strategy.step_1.description": "Stel automatische herinneringen voor {flowName} in.",
	"flow.detail.reminders.create_strategy.step_1.interval.1": "Verstuur elke",
	"flow.detail.reminders.create_strategy.step_1.interval.1_explanation":
		"tot alle gegevens worden aangeleverd of de ingestelde einddatum van de laatste herinnering verstreken is.",
	"flow.detail.reminders.create_strategy.step_1.interval.2": "dagen een herinnering met een totaal van",
	"flow.detail.reminders.create_strategy.step_1.interval.3": "herinneringen",
	"flow.detail.reminders.create_strategy.step_1.interval.max": "Voer een termijn van maximum 365 dagen in.",
	"flow.detail.reminders.create_strategy.step_1.interval.required": "Interval is verplicht.",
	"flow.detail.reminders.create_strategy.step_1.send_first_after_days.1":
		"Verzend de eerste herinnering wanneer je na",
	"flow.detail.reminders.create_strategy.step_1.send_first_after_days.2": "dagen nog geen gegevens hebt ontvangen.",
	"flow.detail.reminders.create_strategy.step_1.send_first_after_days.max":
		"Voer een termijn van maximum 365 dagen in.",
	"flow.detail.reminders.create_strategy.step_1.send_first_after_days.number": "Voer een afgerond cijfer in.",
	"flow.detail.reminders.create_strategy.step_1.send_first_after_days.required":
		"Voer het aantal dagen in waarna je de eerste herinnering wil verzenden.",
	"flow.detail.reminders.create_strategy.step_1.step": "Frequentie instellen",
	"flow.detail.reminders.create_strategy.step_1.stop": "Herinneringen beëindigen",
	"flow.detail.reminders.create_strategy.step_1.time_of_day.1": "Verzend de herinneringen steeds om",
	"flow.detail.reminders.create_strategy.step_1.time_of_day.2": "uur.",
	"flow.detail.reminders.create_strategy.step_1.time_of_day.required": "Selecteer een datum of tijdstip.",
	"flow.detail.reminders.create_strategy.step_1.title": "Herinneringen personenbelasting flow aanslagjaar 2024",
	"flow.detail.reminders.create_strategy.step_1.total.max": "Voer een termijn van maximum 365 dagen in.",
	"flow.detail.reminders.create_strategy.step_1.total.required": "Voer de hoeveelheid in.",
	"flow.detail.reminders.create_strategy.step_1.when": "Aantal en frequentie van je herinneringen",
	"flow.detail.reminders.create_strategy.step_2.title": "Herinnering beschrijven",
	"flow_detail_template_modal.button.create": "Flow toevoegen",
	"flow_detail_template_modal.button.create.tooltip":
		"Je hebt het maximaal aantal flows in je licentie bereikt. Upgrade je abonnement om meer flows aan je account toe te voegen.",
	"flow_detail_template_modal.overview": "Terug naar overzicht",
	"flows.detail.banner.fetch_failed": "Er ging iets mis bij het ophalen van de flows. Probeer het later opnieuw.",
	"flows.overview.banner.fetch_failed": "Er ging iets mis bij het ophalen van de flows. Probeer het later opnieuw.",
	"flows.overview.table.button.copy_flow_link.success": "Link gekopieerd naar klembord.",
	"flows.overview.table.error_state.fetch_failed.button": "Opnieuw proberen",
	"flows.overview.table.error_state.fetch_failed.title":
		"Er ging iets mis bij het ophalen van de flows. Probeer het later opnieuw.",
	"flows.report_detail.meta_aside.report_info.reminder_history.label": "Herinneringen:",
	"flows.report_detail.meta_aside.report_info.reminder_history.send_on": "Op {executeAt}",
	"flows.report_detail.meta_aside.report_info.reminder_history.send_to": "Naar {email)",
	"flows.report_detail.meta_aside.report_info.reminder_history.total_send":
		"{index} van de {total} herinneringen verzonden",
	"html_editor.toolbar.clear_formatting": "Opmaak verwijderen",
	"html_editor.toolbar.formatting.bold": "Vetgedrukte opmaak",
	"html_editor.toolbar.formatting.insert_link": "Link invoegen",
	"html_editor.toolbar.formatting.italics": "Cursieve opmaak",
	"html_editor.toolbar.formatting.strikethrough": "Doorhalen",
	"html_editor.toolbar.formatting.title": "Opmaak",
	"html_editor.toolbar.formatting.underline": "Onderstrepen",
	"html_editor.toolbar.horizontal_rule": "Horizontale lijn",
	"html_editor.toolbar.insert.title": "Invoegen",
	"html_editor.toolbar.paragraph_styles.bullet_list": "Opsommingstekenlijst",
	"html_editor.toolbar.paragraph_styles.code_block": "Codeblok",
	"html_editor.toolbar.paragraph_styles.heading_1": "Kop 1",
	"html_editor.toolbar.paragraph_styles.heading_2": "Kop 2",
	"html_editor.toolbar.paragraph_styles.heading_3": "Kop 3",
	"html_editor.toolbar.paragraph_styles.normal": "Normaal",
	"html_editor.toolbar.paragraph_styles.numbered_list": "Opsommingstekenlijst",
	"html_editor.toolbar.paragraph_styles.quote": "Citaat",
	"html_editor.toolbar.signature": "Handtekening",
	"html_editor.toolbar.subscript": "Subscript",
	"html_editor.toolbar.superscript": "Superscript",
	"html_editor.toolbar.table": "Tabel",
	"html_editor.toolbar.text_align.center_align": "Midden uitlijnen",
	"html_editor.toolbar.text_align.left_align": "Links uitlijnen",
	"html_editor.toolbar.text_align.right_align": "Rechts uitlijnen",
	"image_upload.choose_other_image": "Selecteer een andere afbeelding",
	"image_upload.error.file_size.description":
		"Het bestand dat je wil opladen is groter dan {size}. Kies een kleiner bestand.",
	"image_upload.error.file_size.title": "Bestand is te groot.",
	"image_upload.error.invalid_type.description":
		"Het bestandstype dat je wil opladen wordt niet ondersteund. We ondersteunen enkel volgende types: {types}",
	"image_upload.error.invalid_type.title": "Bestandstype wordt niet ondersteund.",
	"image_upload.error.unexpected.description":
		"Er ging iets mis bij het opladen van je bestand. Probeer het later opnieuw.",
	"image_upload.error.unexpected.title": "Er ging iets mis. Probeer het later opnieuw.",
	"image_upload.pick_a_image": "Selecteer een afbeelding",
	"image_upload.upload_failed": "Er ging iets mis bij het opladen van je bestand. Probeer het later opnieuw.",
	"import_contacts_modal.contact_list.import.failed.description":
		"Je bestand bevat niet het juiste formaat of werd beschadigd. Neem contact op met onze klantendienst als dit probleem aanhoudt.",
	"import_contacts_modal.contact_list.import.failed.title": "We kunnen je bestand niet uitlezen.",
	"inbox.action.button.refresh_whole_inbox.tooltip": "Inbox herladen",
	"inbox.compose.attachments.upload_error.attachment_limit":
		"Je kan maximaal {amount} bijlagen uploaden. {freeAmount, plural, =0 {Je kan geen extra bijlagen meer uploaden .} one {Je kan nog één extra bijlage uploaden.} other {Je kan # extra bijlagen uploaden.}}",
	"inbox.compose.attachments.upload_error.attachment_size_limit":
		"Upload mislukt. Je kan slechts 20 MB aan bijlagen uploaden.",
	"inbox.compose.attachments.upload_error.file_size": "Upload mislukt. Bijlage mag niet groter zijn dan 20MB.",
	"inbox.compose.attachments.upload_error.file_size_error":
		"Het is niet mogelijk om bijlagen groter dan 20 MB op te laden.",
	"inbox.compose.attachments.upload_error.general_error":
		"Er ging iets mis bij het opladen van je bijlage. Probeer het later opnieuw.",
	"inbox.compose.attachments.upload_error.no_draft_yet":
		"Uploaden mislukt. Probeer het opnieuw als de concept update is gelukt",

	"inbox.compose.button.bcc": "Bcc",
	"inbox.compose.button.cc": "Cc",
	"inbox.compose.connect_channel.description": "Koppel e-mailkanalen aan je inbox.",
	"inbox.compose.connect_channel.footer": "Je kan je kanalen beheren in <link>instellingen / kanalen</link>",
	"inbox.compose.connect_channel.title": "Koppel je e-mailkanaal",
	"inbox.compose.fields.bcc.label": "Bcc:",
	"inbox.compose.fields.bcc.placeholder": "Wie moet een blinde kopie van dit bericht ontvangen",
	"inbox.compose.fields.cc.label": "Cc:",
	"inbox.compose.fields.cc.placeholder": "Wie moet een kopie van dit bericht ontvangen",
	"inbox.compose.fields.email_validation.error": "{address} is geen geldig e-mailadres.",
	"inbox.compose.fields.from.error_required": "Vanuit welk kanaal wil je dit bericht verzenden",
	"inbox.compose.fields.from.fallback":
		"We konden geen kanalen vinden. Ga naar <link>instellingen > kanalen</link> om een kanaal te koppelen.",
	"inbox.compose.fields.from.label": "Van:",
	"inbox.compose.fields.from.placeholder": "Vanuit welk kanaal wil je dit bericht verzenden",
	"inbox.compose.fields.recipients_validation.max_error": "Maximum {max} ontvangers mogelijk.",
	"inbox.compose.fields.subject.label": "Onderwerp:",
	"inbox.compose.fields.subject.placeholder": "Waar gaat dit bericht over",
	"inbox.compose.fields.to.error_required": "Wie moet dit bericht ontvangen",
	"inbox.compose.fields.to.label": "Naar:",
	"inbox.compose.fields.to.placeholder": "Wie moet dit bericht ontvangen",
	"inbox.compose.footer.add_attachments.tooltip": "Bijlage toevoegen",
	"inbox.compose.footer.add_attachments.disabled.tooltip": "We zijn bezig met je concept klaar te zetten...",

	"inbox.compose.footer.add_signature.label": "Handtekening toevoegen",
	"inbox.compose.footer.button.discard_draft.label": "Concept verwijderen",
	"inbox.compose.footer.button.send.label": "Verzenden",
	"inbox.compose.footer.button.status.saved_updates": "Concept bijwerken",
	"inbox.compose.footer.button.status.update_succeeded": "Concept opgeslagen",
	"inbox.compose.footer.uploading_attachments": "Bijlage uploaden",
	"inbox.compose.header.close.aria_label": "Berichtvenster sluiten",
	"inbox.compose.header.close.tooltip": "Sluiten",
	"inbox.compose.header.fullscreen.aria_label": "Berichtvenster maximaliseren",
	"inbox.compose.header.fullscreen.tooltip": "Maximaliseren",
	"inbox.compose.header.maximized.aria_label": "Gemaximaliseerd berichtvenster",
	"inbox.compose.header.maximized.tooltip": "Gemaximaliseerd",
	"inbox.compose.header.minimized.aria_label": "Geminimaliseerd berichtvenster",
	"inbox.compose.header.minimized.tooltip": "Geminimaliseerd",
	"inbox.compose.header.shrink.aria_label": "Berichtvenster minimaliseren",
	"inbox.compose.header.shrink.tooltip": "Minimaliseren",
	"inbox.compose.header.title.default": "Nieuwe e-mail",
	"inbox.compose.header.title.draft": "Concept",
	"inbox.compose.header.title.failed_message": "Bericht mislukt",
	"inbox.conversation.assign_self_failed.message": "Toewijzing aanpassen mislukt.",
	"inbox.conversation.attachment.corrupt.label": "Mislukte bijlage",
	"inbox.conversation.attachment.delete_attachment.aria_label": "Bestand verwijderen",
	"inbox.conversation.attachment.download_attachment.aria_label": "Bestand downloaden",
	"inbox.conversation.attachment.corrupt.label": "Bijlage is corrupt",
	"inbox.conversation.list.banner.fetch_updates": "Conversaties en updates ophalen…",
	"inbox.conversation.list.item.channel.deleted.tooltip": "{channelAddress} (BEZIG MET VERWIJDEREN)",
	"inbox.list.item.channel.deleted.tooltip": "{channelAddress} (BEZIG MET VERWIJDEREN)",
	"inbox.conversation.list.separator.search": "Zoekmodus (meest relevant eerst)",
	"inbox.conversation.list.time_separator.last_week": "Afgelopen week",
	"inbox.conversation.list.time_separator.older": "Oudere conversaties",
	"inbox.conversation.list.time_separator.today": "Vandaag",
	"inbox.conversation.list.time_separator.yesterday": "Gisteren",
	"inbox.conversation.message.audit.add_inbox_label": "{actor} <b>voegde</b> {mutatedEntities} label <b>toe</b> ",
	"inbox.conversation.message.audit.add_participant":
		"{actor} <b>voegde</b> {mutatedEntities} als <b>{mutatedEntitiesCount, plural, =1 {deelnemer} other {deelnemers}}</b> toe",
	"inbox.conversation.message.audit.assign": "{actor} <b>wees</b> {mutatedEntities} <b>toe</b>",
	"inbox.conversation.message.audit.mark_participant_label":
		"{actor} <b>verplaatste</b> de conversatie <b>naar</b> {mutatedEntities}",
	"inbox.conversation.message.audit.remove_inbox_label": "{actor} <b>verwijderde</b> {mutatedEntities} label",
	"inbox.conversation.message.audit.remove_participant":
		"{actor} <b>verwijderde</b> {mutatedEntities} als <b>{mutatedEntitiesCount, plural, =1 {deelnemer} other {deelnemers}}</b>",
	"inbox.conversation.message.audit.swap_assignment":
		"{actor} <b>wijzigde toegewezen</b> gebruiker <b>van</b> {from} <b>naar</b> {to}",
	"inbox.conversation.message.audit.unassign": "{actor} <b>verwijderde</b> {mutatedEntities} als <b>toegewezen</b>",
	"inbox.conversation.message.audit.unmarked_participant_label":
		"{actor} <b>verwijderde</b> conversatie <b>uit</b> {mutatedEntities}",
	"inbox.conversation.update.failed.description":
		"Conversaties kon niet worden bijgewerkt. Probeer het opnieuw of contacteer onze klantendienst.",
	"inbox.conversation.update.failed.title": "Conversatie kon niet worden bijgewerkt",
	"inbox.conversation_list.all_conversations.fetched": "Alle conversaties werden opgehaald. 📭",
	"inbox.conversation_list.empty_list.label": "Geen conversaties gevonden in {label}",
	"inbox.label.actions.add.button.title": "Label toevoegen",
	"inbox.label.actions.filter.type.input.label": "Filter op type",
	"inbox.label.actions.filter.type.input.placeholder": "Filter op type",
	"inbox.label.actions.search.input.label": "Zoeken op naam",
	"inbox.label.actions.search.input.placeholder": "Zoeken op labelnaam",
	"inbox.label.form.field.channel.label": "Kanaal",
	"inbox.label.form.field.channel.placeholder": "Kies een kanaal",
	"inbox.label.form.field.channel.tooltip.disabled":
		"Het is niet mogelijk om het label te wijzigen wanneer het is gemaakt als label van een gekoppeld kanaal.",
	"inbox.label.form.field.channel.validation_rule": "Kanaal is verplicht.",
	"inbox.label.form.field.color.label": "Kleur",
	"inbox.label.form.field.description.help": "Geef dit label een beschrijving van maximaal 256 tekens.",
	"inbox.label.form.field.description.label": "Beschrijving",
	"inbox.label.form.field.description.placeholder": "Dit label wordt toegepast op conversaties over klant Jhon.",
	"inbox.label.form.field.name.label": "Naam",
	"inbox.label.form.field.name.placeholder": "Geef het label een naam.",
	"inbox.label.form.field.name.validation_rule": "Naam is verplicht.",
	"inbox.label.form.field.name.validation_rule.too_long": "Geef het label een naam van maximaal 56 tekens.",
	"inbox.label.form.field.type.label": "Type",
	"inbox.label.form.field.type.placeholder": "Type is verplicht.",
	"inbox.label.form.field.type.tooltip.disabled":
		"Het is niet mogelijk om het labeltype te wijzigen wanneer het is gemaakt als label van een gekoppeld kanaal.",
	"inbox.label.form.field.type.validation_rule": "Geef dit label een type.",
	"inbox.label.modal.create.actions.add.button.title": "Toevoegen",
	"inbox.label.modal.create.result.error":
		"Er ging iets mis bij het aanmaken van dit label. Probeer het later opnieuw.",
	"inbox.label.modal.create.result.success": "Label succesvol aangemaakt.",
	"inbox.label.modal.delete.action.consequence":
		"Als je dit label verwijdert, zal het verwijderd worden uit alle kanalen en conversaties waaraan het is gekoppeld. Deze actie is onomkeerbaar.",
	"inbox.label.modal.delete.action.delete.button.title": "Verwijderen",
	"inbox.label.modal.delete.title": "Label verwijderen",
	"inbox.label.modal.title": "Label toevoegen",
	"inbox.label.modal.update.action.delete.result.error":
		"Er ging iets mis bij het verwijderen van dit label. Probeer het later opnieuw.",
	"inbox.label.modal.update.action.delete.result.success": "Label succesvol verwijderd.",
	"inbox.label.modal.update.action.update.result.error":
		"Er ging iets mis bij het bijwerken van dit label. Probeer het later opnieuw.",
	"inbox.label.modal.update.action.update.result.success": "Label succesvol bijgewerkt.",
	"inbox.label.modal.update.actions.delete.button.title": "Verwijderen",
	"inbox.label.modal.update.actions.save_changes.button.disabled":
		"Zorg ervoor dat je je kanaal opnieuw verbindt vooraleer je wijzigingen aanbrengt.",
	"inbox.label.modal.update.actions.save_changes.button.title": "Wijzigingen opslaan",
	"inbox.label.modal.update.title": "Label bewerken",
	"inbox.label.table.header.channel": "Kanaal",
	"inbox.label.table.header.description": "Beschrijving",
	"inbox.label.table.header.name": "Naam",
	"inbox.label.table.header.type": "Type",
	"inbox.label.type.user.label": "Privé",
	"inbox.label.type.user.description": "Alleen zichtbaar voor jezelf",
	"inbox.label.type.organization.label": "Organisatie",
	"inbox.label.type.organization.description": "Toegankelijk voor iedereen binnen je organisatie",
	"inbox.label.type.channel.label": "Kanaal",
	"inbox.label.type.channel.description":
		"Toegankelijk voor iedereen die dit kanaal gebruikt en synchorniseerd met het kanaal",
	"inbox.message.actions.dropdown.forward.title": "Doorsturen",
	"inbox.message.actions.dropdown.reply.title": "Beantwoorden",
	"inbox.message.actions.dropdown.reply_all.title": "Allen beantwoorden",
	"inbox.message.content.hide_thread": "Berichtgeschiedenis verbergen",
	"inbox.message.content.show_thread": "Berichtgeschiedenis weergeven",
	"inbox.message.footer.attachments.download_all_button.label": "Download alle bijlagen",
	"inbox.message.footer.attachments.title": "{count, plural, one {# bijlage} other {# bijlagen}}",
	"inbox.message.header.bcc.label": "Bcc:",
	"inbox.message.header.cc.label": "Cc:",
	"inbox.message.header.collapse_button.aria_label.collapse": "E-mail ingeklapt",
	"inbox.message.header.collapse_button.aria_label.expanded": "E-mail uitgeklapt",
	"inbox.message.header.collapse_button.tooltip.collapse": "E-mail inklappen",
	"inbox.message.header.collapse_button.tooltip.expanded": "E-mail uitklappen",
	"inbox.message.header.from.label": "Van:",
	"inbox.message.header.go_to_attachments": "Ga naar {count, plural, one {# bijlage} other {# bijlagen}}",
	"inbox.message.header.replyTo.label": "Antwoorden aan:",
	"inbox.message.header.subject": "Onderwerp:",
	"inbox.message.header.to.label": "Aan:",
	"inbox.page.title": "Inbox (BETA)",
	"inbox.participants.add_modal.error.description":
		"Er ging iets mis bij het toevoegen van deze deelnemer. Probeer het later opnieuw.",
	"inbox.participants.add_modal.error.title":
		"Er ging iets mis bij het toevoegen van deze deelnemer. Probeer het later opnieuw.",
	"inbox.participants.add_modal.footer.add": "Toevoegen",
	"inbox.participants.add_modal.footer.cancel": "Annuleren",
	"inbox.participants.add_modal.search.placeholder": "Zoeken op naam",
	"inbox.participants.add_modal.sub_title": "Teamleden",
	"inbox.participants.add_modal.title": "Deelnemers toevoegen",
	"inbox.participants.list.fallback.empty": "Geen teamleden gevonden.",
	"inbox.participants.list.fallback.error":
		"Er ging iets mis bij het ophalen van de teamleden. Probeer het later opnieuw.",
	"inbox.search.advanced.footer.button.reset": "Reset",
	"inbox.search.advanced.footer.button.search": "Zoeken",
	"inbox.search.advanced.form.from.label": "Van",
	"inbox.search.advanced.form.from_date.label": "Vanaf datum",
	"inbox.search.advanced.form.has_attachments.label": "Heeft bijlagen",
	"inbox.search.advanced.form.search_within_folders.label": "Aanwezig binnen mappen",
	"inbox.search.advanced.form.search_within_labels.label": "Met labels",
	"inbox.search.advanced.form.subject.label": "Onderwerp",
	"inbox.search.advanced.form.to.label": "Naar",
	"inbox.search.advanced.form.until.label": "Tot en met",
	"inbox.search.advanced.search_within.options.assigned_by_me": "Toegewezen door mij",
	"inbox.search.advanced.search_within.options.assigned_to_me": "Toegewezen aan mij",
	"inbox.search.advanced.search_within.options.closed": "Gesloten",
	"inbox.search.advanced.search_within.options.drafts": "Concepten",
	"inbox.search.advanced.search_within.options.mentioned": "Vermeldingen",
	"inbox.search.advanced.search_within.options.open": "Open",
	"inbox.search.advanced.search_within.options.personal_conversations": "Mijn conversaties",
	"inbox.search.advanced.search_within.options.sent": "Verzonden",
	"inbox.search.advanced.search_within.options.shared_with_me": "Gedeeld met mij",
	"inbox.search.advanced.search_within.options.snoozed": "Uitgesteld",
	"inbox.search.advanced.search_within.options.spam": "Ongewenste",
	"inbox.search.advanced.search_within.options.trash": "Prullenbak",
	"inbox.search.placeholder": "Druk op S om alle conversaties te doorzoeken",
	"inbox.search.suggestions.all": "Alle zoekresultaten voor",
	"inbox.search.suggestions.from": "Van",
	"inbox.search.suggestions.subject": "Onderwerp",
	"inbox.side_navigation.button.report_bug": "Probleem melden",
	"inbox.side_navigation.channel_sync.status.in_progress":
		"{count ,plural, =1 {Kanaal synchroniseren} other {Kanalen synchroniseren}}…",
	"inbox.side_navigation.compose_button.label": "Opstellen",
	"inbox.side_navigation.folders.drafts.label": "Concepten",
	"inbox.side_navigation.folders.title": "Mappen",
	"inbox.side_navigation.labels.add_more": "label toevoegen",
	"inbox.side_navigation.labels.show_less": "Toon minder",
	"inbox.side_navigation.labels.show_more": "Toon meer",
	"inbox.side_navigation.personal.all_conversations.label": "Alle conversaties",
	"inbox.side_navigation.personal.assigned_to_me.label": "Toegewezen aan mij",
	"inbox.side_navigation.personal.delegated.label": "Gedelegeerd",
	"inbox.side_navigation.personal.mentions.label": "Vermeldingen",
	"inbox.side_navigation.personal.my_conversations.label": "Mijn conversaties",
	"inbox.side_navigation.personal.outbox.label": "Postvak uit",
	"inbox.side_navigation.personal.send.label": "Verzonden",
	"inbox.side_navigation.personal.shared_with_me.label": "Gedeeld met mij",
	"inbox.side_navigation.personal.title": "Persoonlijk",
	"inbox.side_navigation.settings_button.label": "Inbox instellingen",
	"inbox.side_navigation.warning.channel_disabled.label": "{channelName} opnieuw koppelen",
	"inbox.status_header.search.from.label": "<bold>Van:</bold> {value}",
	"inbox.status_header.search.fromDate.label": "<bold>Vanaf datum:</bold> {value}",
	"inbox.status_header.search.hasAttachments.label": "Heeft bijlagen",
	"inbox.status_header.search.searchWithinFolders.label": "<bold>Aanwezig binnen mappen:</bold> {value}",
	"inbox.status_header.search.searchWithinLabels.label": "<bold>Met labels:</bold> {value}",
	"inbox.status_header.search.subject.label": "<bold>Onderwerp:</bold> {value}",
	"inbox.status_header.search.q.label": "<bold>Zoek alle met:</bold> {value}",
	"inbox.status_header.search.to.label": "<bold>Naar:</bold> {value}",
	"inbox.status_header.search.until.label": "<bold>Tot:</bold> {value}",
	"internal_editor.send": "Verstuur intern bericht",
	"internal_editor.placeholder": "Stuur een intern chatbericht...",
	"internal_editor.toolbar.bold.aria_label": "Ongedefinieerd",
	"internal_editor.toolbar.bold.aria_label.active": "Vetgedrukte opmaak verwijderen",
	"internal_editor.toolbar.code.aria_label": "Ongedefinieerd",
	"internal_editor.toolbar.code.aria_label.active": "Code-opmaak verwijderen",
	"internal_editor.toolbar.italic.aria_label": "Ongedefinieerd",
	"internal_editor.toolbar.italic.aria_label.active": "Cursieve opmaak verwijderen",
	"editor.toolbar.link.tooltip": "Link invoegen",
	"editor.toolbar.link.aria_label": "Link invoegen",
	"editor.toolbar.link.editor_modal.edit_button.aria_label": "Link bewerken",
	"editor.toolbar.link.editor_modal.save_button.aria_label": "Link opslaan",
	"internal_editor.toolbar.ordered_list.aria_label": "Ongedefinieerd",
	"internal_editor.toolbar.ordered_list.aria_label.active": "Geordend lijstitem verwijderen",
	"internal_editor.toolbar.strike_through.aria_label": "ongedefinieerd",
	"internal_editor.toolbar.strike_through.aria_label.active": "Doorhalen opmaak verwijderen",
	"internal_editor.toolbar.unordered_list.aria_label": "Ongedefinieerd",
	"internal_editor.toolbar.unordered_list.aria_label.active": "Ongeordend lijstitem verwijderen",
	"keyword.delete": "VERWIJDEREN",
	"notification.empty.description": "Je bent up-to-date! 🎉 Goed gedaan, tijd voor een kop koffie.",
	"notification.header.button.mark_all_as_read": "Alles als gelezen markeren",
	"notification.list_item.chip.added_as_partiipant": "Deelnemer",
	"notification.list_item.chip.announcement": "Aankondiging",
	"notification.list_item.chip.assigned_to_conversation": "Toewijzing conversaties",
	"notification.list_item.chip.assigned_to_flow": "Toewijzing flows",
	"notification.list_item.chip.conversation_started": "Nieuwe conversatie",
	"notification.list_item.chip.flow_reminder_failed": "Er ging iets mis bij de herinneringsmails van deze flow.",
	"notification.list_item.chip.flow_sending": "Flow verzenden",
	"notification.list_item.chip.integration_error": "Fout",
	"notification.list_item.chip.integration_refresh": "Vernieuwen",
	"notification.list_item.chip.mentioned": "Vermeldingen",
	"notification.list_item.chip.message_error": "Fout",
	"notification.list_item.chip.new_flow_report": "Flow rapport",
	"notification.list_item.chip.report.bulk_download": "Bulk download rapporten",
	"notification.list_item.chip.synchronization": "Synchronisatie",
	"notification.list_item.chip.contact_import": "Contactimport",
	"notification.list_item.chip.contact_webhook_sync": "Contact Webhook",
	"notification.mark_all_as_read.failed": "Er ging iets mis. Probeer het later opnieuw.",
	"notification.mark_all_as_read.succeeded": "Alle meldingen zijn gemarkeerd als gelezen.",
	"notification.title": "Meldingen",
	"notifications.list.end_of_list": "Alle meldingen zijn opgehaald. 🔔",
	"notifications.list_item.download_button": "Downloaden",
	"notifications.list_item.report_download.button.tooltip": "Download is verlopen.",
	"notifications.list_item.report_download.info": "Download verloopt na 1 dag.",
	"organization.settings.branding.colors.accent_color.invalid_color": "Accentkleur is geen hexadecimale kleur.",
	"organization.settings.branding.colors.accent_color.is_required": "Accentkleur is verplicht.",
	"organization.settings.branding.colors.brand_color.invalid_color": "Merkkleur is geen hexadecimale kleur.",
	"organization.settings.branding.colors.brand_color.is_required": "Merkkleur is verplicht.",
	"organization.settings.branding.colors.text_color.invalid_color": "Tekstkleur is geen geldige hexadecimale kleur.",
	"organization.settings.branding.colors.text_color.is_required": "Tekstkleur is verplicht.",
	"organization_settings.branding.breadcrumb.page_title": "Bedrijfskleuren",
	"organization_settings.branding.breadcrumb.settings": "Instellingen",
	"organization_settings.branding.color.description":
		"Wijzig de kleuren van alle uitgaande communicatie naar de vaste kleuren van de onderneming.",
	"organization_settings.branding.color.form.accent_color.label": "Accentkleur",
	"organization_settings.branding.color.form.brand_color.label": "Hoofdkleur",
	"organization_settings.branding.color.form.submit": "Opslaan",
	"organization_settings.branding.color.form.text_color.label": "Tekstkleur",
	"organization_settings.branding.color.title": "Kleurenschema",
	"organization_settings.branding.logo.description":
		"Laad het logo van de onderneming op en kies een pictogram om uitgaande communicatie te personaliseren.",
	"organization_settings.branding.logo.form.icon.help": "Maximale grootte: 10MB",
	"organization_settings.branding.logo.form.icon.label": "Laad je pictogram op",
	"organization_settings.branding.logo.form.icon.required_error": "Pictogram is verplicht.",
	"organization_settings.branding.logo.form.logo.help": "Maximale grootte: 10MB",
	"organization_settings.branding.logo.form.logo.label": "Laad je logo op",
	"organization_settings.branding.logo.form.logo.required_error": "Logo is verplicht.",
	"organization_settings.branding.logo.form.submit": "Opslaan",
	"organization_settings.branding.logo.title": "Logo en pictogram",
	"organization_settings.branding.missing_logo_icon_permissions":
		"Je onderneming heeft op dit moment geen logo en pictogram. Vraag iemand met de nodige rechten om deze op te laden.",
	"organization_settings.branding.save.failed":
		"Er ging iets mis bij het opslaan van je instellingen. Probeer het later opnieuw.",
	"organization_settings.branding.save.success": "Instellingen werden succesvol opgeslagen.",
	"organization_settings.branding.tab_title": "Huisstijl instellingen",
	"organization_settings.branding.title": "Huisstijl instellingen",
	"organization_settings.general.basic.form.success": "Bedrijfsinstellingen werden succesvol opgeslagen.",
	"organization_settings.general.form.email.error_required": "E-mailadres is verplicht.",
	"organization_settings.general.form.email.help":
		"Dit is het e-mailadres waarmee externen contact met je kunnen opnemen.",
	"organization_settings.general.form.email.invalid_error": "Voer een volledig e-mailadres in.",
	"organization_settings.general.form.email.label": "E-mailadres",
	"organization_settings.general.form.name.error_required": "Bedrijfsnaam is verplicht.",
	"organization_settings.general.form.name.label": "Bedrijfsnaam",
	"organization_settings.general.form.phone.help":
		"Dit is het telefoonnummer waarmee externen contact met je kunnen opnemen.",
	"organization_settings.general.form.phone.label": "Telefoonnummer",
	"organization_settings.general.form.phone.validation": "Voer een geldig telefoonnummer in.",
	"organization_settings.general.form.save": "Opslaan",
	"organization_settings.general.locations.add_modal.button.cancel": "Annuleren",
	"organization_settings.general.locations.add_modal.button.create": "Locatie toevoegen",
	"organization_settings.general.locations.add_modal.created.failed":
		"Er ging iets mis bij het opslaan van dit adres. Probeer het later opnieuw.",
	"organization_settings.general.locations.add_modal.created.succeeded": "Adres werd succesvol opgeslagen.",
	"organization_settings.general.locations.add_modal.title": "Locatie toevoegen",
	"organization_settings.general.locations.alert.description":
		"Weet je zeker dat je dit adres als primair wil instellen? Hierdoor wordt de primaire status van je ander adres verwijderd.",
	"organization_settings.general.locations.alert.title": "Primair adres",
	"organization_settings.general.locations.contact_info.form.email.error": "E-mailadres is verplicht.",
	"organization_settings.general.locations.contact_info.form.email.invalid": "Voer een volledig e-mailadres in.",
	"organization_settings.general.locations.contact_info.form.email.label": "E-mailadres",
	"organization_settings.general.locations.contact_info.form.enterprise_number.error.notvalid":
		"Ondernemingsnummer is niet geldig.",
	"organization_settings.general.locations.contact_info.form.enterprise_number.error.required":
		"Ondernemingsnummer is verplicht.",
	"organization_settings.general.locations.contact_info.form.enterprise_number.label": "Ondernemingsnummer",
	"organization_settings.general.locations.contact_info.form.enterprise_number.placeholder":
		"Bijvoorbeeld: BE0665684769",
	"organization_settings.general.locations.contact_info.form.is_primary.label": "Markeer als primair adres",
	"organization_settings.general.locations.contact_info.form.is_primary.tooltip":
		"Dit adres staat al gemarkeerd als primair adres.",
	"organization_settings.general.locations.contact_info.form.phone.label": "Telefoonnummer",
	"organization_settings.general.locations.contact_info.form.phone.max_length": "Voer een geldig telefoonnummer in.",
	"organization_settings.general.locations.contact_info.title": "Contactinformatie",
	"organization_settings.general.locations.delete_button.label": "Verwijderen",
	"organization_settings.general.locations.delete_button.tooltip":
		"Het is niet mogelijk om een primair adres te verwijderen.",
	"organization_settings.general.locations.delete_modal.delete_button": "Verwijderen",
	"organization_settings.general.locations.delete_modal.description":
		"Ben je er zeker van dat je dit adres wil verwijderen?",
	"organization_settings.general.locations.delete_modal.title": "Adres verwijderen",
	"organization_settings.general.locations.edit_modal.delete.failed":
		"Er ging iets mis bij het verwijderen van dit adres. Probeer het later opnieuw.",
	"organization_settings.general.locations.edit_modal.delete.succeeded": "Adres werd succesvol verwijderd.",
	"organization_settings.general.locations.edit_modal.delete_primary.failed":
		"Het is niet mogelijk om een primair adres te verwijderen.",
	"organization_settings.general.locations.edit_modal.title": "Locatie bewerken",
	"organization_settings.general.locations.edit_modal.update.failed":
		"Er ging iets mis bij het wijzigen van je adres. Probeer het later opnieuw.",
	"organization_settings.general.locations.edit_modal.update.succeeded": "Adres werd succesvol opgeslagen.",
	"organization_settings.general.locations.form.bus.error.special_symbol":
		"Het is alleen mogelijk om letters, cijfers, koppeltekens, underscores en punten te gebruiken bij het busnummer.",
	"organization_settings.general.locations.form.bus.label": "Bus",
	"organization_settings.general.locations.form.city": "Gemeente",
	"organization_settings.general.locations.form.city.error.required": "Gemeente is verplicht.",
	"organization_settings.general.locations.form.country.error.required": "Land is verplicht.",
	"organization_settings.general.locations.form.country.label": "Land",
	"organization_settings.general.locations.form.error.hasNumbers": "Verwijder alle cijfers.",
	"organization_settings.general.locations.form.error.hasSymbols": "Verwijder alle cijfers en symbolen.",
	"organization_settings.general.locations.form.postal_code.error.required": "Postcode is verplicht.",
	"organization_settings.general.locations.form.postal_code.label": "Postcode",
	"organization_settings.general.locations.form.province.label": "Provincie",
	"organization_settings.general.locations.form.street_name.error.required": "Straatnaam is verplicht.",
	"organization_settings.general.locations.form.street_name.label": "Straatnaam",
	"organization_settings.general.locations.form.street_number.error.required": "Huisnummer is verplicht.",
	"organization_settings.general.locations.form.street_number.label": "Huisnummer",
	"organization_settings.general.locations.missing_locations_permissions":
		"Je onderneming heeft op dit moment geen locatie(s). Vraag iemand met de nodige rechten om de locatie(s) toe te voegen.",
	"organization_settings.general.locations.table.add_button": "Locatie toevoegen",
	"organization_settings.general.locations.title": "Locatie",
	"organization_settings.general.locations.update_button.label": "Bijwerken",
	"organization_settings.general.locations.view_modal.title": "Locatie bekijken",
	"organization_settings.general.social_media.form.add_fields.placeholder.label": "Platform",
	"organization_settings.general.social_media.form.field_labels.facebook.field.placeholder": "Facebook",
	"organization_settings.general.social_media.form.field_labels.instagram.field.placeholder": "Instagram",
	"organization_settings.general.social_media.form.field_labels.linkedin.field.placeholder": "LinkedIn",
	"organization_settings.general.social_media.form.field_labels.twitter.field.placeholder": "Twitter",
	"organization_settings.general.social_media.form.field_labels.youtube.field.placeholder": "Youtube",
	"organization_settings.general.socials.missing_socials_permissions":
		"Je onderneming heeft op dit moment geen sociale media kanalen ingesteld. Vraag iemand met de nodige rechten om de kanalen toe te voegen.",
	"organization_settings.general.title": "Bedrijfsinstellingen",
	"organization_settings.members.breadcrumbs.self": "Gebruikers",
	"organization_settings.members.breadcrumbs.settings": "Instellingen",
	"organization_settings.members.breadcrumbs.tab_title": "Gebruikers",
	"organization_settings.members.breadcrumbs.title": "Gebruikers",
	"organization_settings.members.table.button": "Gebruiker toevoegen",
	"organization_settings.members.table.button.tooltip": "Upgrade je abonnement om meer gebruikers toe te voegen.",
	"organization_settings.members.table.profile": "Gebruiker",
	"organization_settings.opening_hours.basic.description": "Voeg de openingstijden van je kantoor toe.",
	"organization_settings.opening_hours.basic.title": "Kantooruren",
	"organization_settings.opening_hours.basic_form.submit": "Opslaan",
	"organization_settings.opening_hours.missing_permissions":
		"Je onderneming heeft op dit moment geen openingsuren ingesteld. Vraag iemand met de nodige rechten om de openingsuren in te stellen.",
	"organization_settings.opening_hours.update_banner.failed":
		"Er ging iets mis bij het opslaan van je wijzigingen. Probeer het later opnieuw.",
	"organization_settings.opening_hours.update_banner.success": "Openingsuren succesvol gewijzigd.",
	"participant_popup.participant.action.add.permissions.not_allowed":
		"Je hebt niet de nodige rechten om deelnemers toe te voegen.",
	"participant_popup.participant.action.delete.permissions.not_allowed":
		"Je hebt niet de nodige rechten om deelnemers te verwijderen.",
	"portal.accept_invite.accept_failed": "Je kan momenteel geen uitnodigen accepteren. Probeer het later opnieuw.",
	"portal.accept_invite.accept_invite": "Uitnodiging accepteren.",
	"portal.accept_invite.decline_failed": "Er ging iets mis. Probeer het later opnieuw.",
	"portal.accept_invite.decline_invite": "Afwijzen",
	"portal.accept_invite.description":
		"Je bent uitgenodigd als teamlid van <bold>{organizationName}</bold>. Accepteer deze uitnodiging om toegang te krijgen tot het account van <bold>{organizationName}</bold>.",
	"portal.accept_invite.not_found.description":
		"We konden geen uitnodiging vinden. Het is mogelijk dat je de uitnodiging eerder al accepteerde, of dat je een link gebruikt die niet meer geldig is.",
	"portal.accept_invite.not_found.title": "Geen uitnodigingen gevonden.",
	"portal.accept_invite.title": "Uitnodiging accepteren",
	"portal.login.description": "Nog geen account? <link>Maak een account aan.</link>",
	"portal.login.failed.account_deleted":
		"Deze gebruiker werd verwijderd. Contacteer onze klantendienst als je de gebruiker wil herstellen.",
	"portal.login.failed.force_reset": "Stel je wachtwoord opnieuw in om te kunnen inloggen.",
	"portal.login.failed.not_found": "Je e-mailadres of wachtwoord is niet correct.",
	"portal.login.field.email.error_is_required": "Voer je e-mailadres in.",
	"portal.login.field.email.invalid_email": "Ongeldig e-mailadres.",
	"portal.login.field.email.label": "E-mailadres",
	"portal.login.field.password.error_is_required": "Voer je wachtwoord in.",
	"portal.login.field.password.label": "Wachtwoord",
	"portal.login.forgot_password.button": "Wachtwoord vergeten",
	"portal.login.forgot_password.tooltip": "Voer hierboven je e-mailadres in om je wachtwoord te herstellen.",
	"portal.login.request_reset.failed":
		"Er ging iets mis bij het verzenden van de e-mail om je wachtwoord te herstellen.",
	"portal.login.request_reset.succeeded":
		"We hebben een e-mail naar {email} gestuurd om je wachtwoord te herstellen.",
	"portal.login.submit": "Log in",
	"portal.login.title": "Welkom bij Bothive",
	"portal.organization.accept_tos.error_is_required":
		"Je kan enkel doorgaan wanneer je de algemene voorwaarden accepteert.",
	"portal.organization.accept_tos.label": "Ik heb de <link>algemene voorwaarden</link> gelezen en goedgekeurd.",
	"portal.organization.company_size.error_is_required": "Hoeveel werknemers zijn er in de onderneming?",
	"portal.organization.company_size.label": "Aantal werknemers",
	"portal.organization.description": "Geef een beschrijving aan de onderneming.",
	"portal.organization.email.error_is_required": "Voer een e-mailadres in.",
	"portal.organization.email.help": "Dit is het e-mailadres dat we gebruiken om contact met je op te nemen.",
	"portal.organization.email.invalid_email": "Ongeldig e-mailadres.",
	"portal.organization.email.label": "E-mailadres",
	"portal.organization.enterprise_number.error.invalid_format":
		"Ondernemingsnummer bevat niet het correcte formaat. Voorbeeld: BE0728.495.140",
	"portal.organization.enterprise_number.error.required": "Ondernemingsnummer is verplicht.",
	"portal.organization.enterprise_number.label": "Ondernemingsnummer",
	"portal.organization.failed.bad_request":
		"Er ging iets mis bij het aanmaken van je onderneming. Probeer het later opnieuw.",
	"portal.organization.failed.invalid_vat":
		"Er ging iets mis bij het verifiëren van je ondernemingsnummer. Contacteer onze klantendienst als dit probleem aanhoudt.",
	"portal.organization.name.error_is_required": "Bedrijfsnaam is verplicht.",
	"portal.organization.name.label": "Bedrijfsnaam",
	"portal.organization.name.to_long": "De bedrijfsnaam mag maximum 46 karakters lang zijn.",
	"portal.organization.phone.label": "Telefoonnummer",
	"portal.organization.phone.validation": "Voer een geldig telefoonnummer in.",
	"portal.organization.submit": "Maak je onderneming aan",
	"portal.organization.title": "Jouw onderneming.",
	"portal.reset_password.button": "Wachtwoord herstellen",
	"portal.reset_password.description": "Wil je je wachtwoord niet herstellen? <link>Log hier in.</link>",
	"portal.reset_password.error_banner.invalid_token.description":
		"De URL om je wachtwoord te herstellen is niet meer geldig. Dat kan het geval zijn wanneer je de URL eerder al gebruikte, of wanneer langer dan 30 minuten geleden verstuurd werd.",
	"portal.reset_password.error_banner.invalid_token.title": "Ongeldige URL.",
	"portal.reset_password.failed.error": "Er ging iets mis. Probeer het later opnieuw.",
	"portal.reset_password.new_password.error_is_required": "Voer een nieuw wachtwoord in.",
	"portal.reset_password.new_password.label": "Nieuw wachtwoord",
	"portal.reset_password.new_password.min_length": "Je nieuw wachtwoord moet uit minstens vijf karakters bestaan.",
	"portal.reset_password.repeat_password.error_is_required": "Bevestig nieuw wachtwoord",
	"portal.reset_password.repeat_password.label": "Herhaal je gekozen wachtwoord",
	"portal.reset_password.repeat_password.min_length": "Je nieuw wachtwoord moet uit minstens vijf karakters bestaan.",
	"portal.reset_password.repeat_password.no_capital_case":
		"Je nieuw wachtwoord moet minstens één hoofdletter bevatten.",
	"portal.reset_password.repeat_password.not_equal": "Wachtwoorden zijn niet gelijk.",
	"portal.reset_password.successful.description":
		"Je wordt binnen <bold>{seconds, plural, =1 {# second} other {# seconden}}</bold> naar het login scherm geleid.",
	"portal.reset_password.successful.title": "Wachtwoord succesvol hersteld.",
	"portal.reset_password.title": "Wachtwoord herstellen",
	"portal.sign_up.description": "Heb je al een account? <link>Log hier in.</link>",
	"portal.sign_up.email.error_is_required": "Voer een e-mailadres in.",
	"portal.sign_up.email.invalid_email": "Ongeldig e-mailadres.",
	"portal.sign_up.email.label": "E-mailadres",
	"portal.sign_up.failed.bad_request": "Er ging iets mis bij het aanmaken van je account. Probeer het later opnieuw.",
	"portal.sign_up.failed.user_exists": "Er bestaat al een gebruiker met dit e-mailadres.",
	"portal.sign_up.firstName.error_is_required": "Voornaam is verplicht",
	"portal.sign_up.firstName.label": "Voornaam",
	"portal.sign_up.firstName.to_long": "De voornaam mag maximum 46 karakters lang zijn.",
	"portal.sign_up.invite_failed.bad_request":
		"Er ging iets mis bij het voltooien van je uitnodiging. Probeer het later opnieuw.",
	"portal.sign_up.invite_failed.not_found":
		"Er ging iets mis bij het voltooien van je uitnodiging. Neem contact op met onze klantendienst.",
	"portal.sign_up.lastName.error_is_required": "Familienaam is verplicht.",
	"portal.sign_up.lastName.label": "Familienaam",
	"portal.sign_up.lastName.to_long": "De familienaam mag maximum 46 karakters lang zijn.",
	"portal.sign_up.password.error_is_required": "Voer je wachtwoord in.",
	"portal.sign_up.password.label": "Wachtwoord",
	"portal.sign_up.password.min_length": "Je nieuw wachtwoord moet uit minstens vijf karakters bestaan.",
	"portal.sign_up.password.no_capital_case": "Je nieuw wachtwoord moet minstens één hoofdletter bevatten.",
	"portal.sign_up.phone.label": "Telefoonnummer",
	"portal.sign_up.phone.validation": "Voer een geldig telefoonnummer in.",
	"portal.sign_up.submit": "Maak account aan",
	"portal.sign_up.title": "Welkom bij Bothive",
	"portal.sign_up_completed.button.create_organization": "Team aanmaken",
	"portal.sign_up_completed.button.join_an_organization": "Aansluiten bij bestaand team",
	"portal.sign_up_completed.button_split.or": "Of",
	"portal.sign_up_completed.description":
		"Bothive is een nieuwe, snelle en gestructureerde manier om te communiceren met je <bold>collega's</bold> en <bold>klanten</bold>.",
	"portal.sign_up_completed.join_organization_instruction.description":
		"Om lid te worden van een team, vraag je de beheerder van je bedrijf om je uit te nodigen met je e-mailadres: {email}.",
	"portal.sign_up_completed.join_organization_instruction.title": "Aansluiten bij bestaand team",
	"portal.sign_up_completed.title": "Aan de slag met Bothive",
	"portal.verify_user.description":
		"We hebben een e-mail gestuurd naar <bold>{email}</bold> met een code die <bold>30 minuten</bold> geldig is om je account te verifiëren.",
	"portal.verify_user.failed.not_found":
		"We konden je account niet verifiëren. Het is mogelijk dat je code verlopen is.",
	"portal.verify_user.request_new_code.failed":
		"Er ging iets mis bij het aanvragen van een nieuwe verificatiecode. Probeer het later opnieuw.",
	"portal.verify_user.request_new_code.success": "Verificatiecode verzonden naar {email}.",
	"portal.verify_user.resend_code": "Verificatiecode opnieuw verzenden",
	"portal.verify_user.title": "Verifieer je e-mailadres",
	"profile.general.accordion_title": "Algemeen",
	"profile.general.form.avatar.help": "Maximale grootte: 10MB",
	"profile.general.form.avatar.label": "Profielfoto",
	"profile.general.form.avatar.required": "Profielfoto is verplicht.",
	"profile.general.form.email.error_is_required": "E-mailadres is verplicht.",
	"profile.general.form.email.invalid_email": "Voer een geldig e-mailadres in.",
	"profile.general.form.email.label": "E-mailadres",
	"profile.general.form.first_name.label": "Voornaam",
	"profile.general.form.first_name.required": "Voornaam is verplicht",
	"profile.general.form.last_name.label": "Familienaam",
	"profile.general.form.last_name.required": "Familienaam is verplicht.",
	"profile.general.form.phone_number.label": "Telefoonnummer",
	"profile.general.form.submit": "Opslaan",
	"profile.popup.actions.shortcuts": "Sneltoetsen",
	"profile.popup.logout": "Uitloggen",
	"recipient.contact.type.primary.tooltip": "Dit is het primaire e-mailadres van dit contact.",
	"recipient.type.contact.tag": "Contact",
	"recipient.type.member.tag": "Teamlid",
	"settings.advanced.breadcrumb.advanced": "Geavanceerd",
	"settings.advanced.breadcrumb.settings": "Instellingen",
	"settings.advanced.danger_zone.accordion_title": "Gevaarlijke instellingen",
	"settings.advanced.danger_zone.button": "Team verwijderen",
	"settings.advanced.danger_zone.description":
		"Ben je er zeker van dat je je team wil verwijderen? Je licentie wordt onmiddellijk geannuleerd en je chatbot wordt gedeactiveerd.",
	"settings.advanced.danger_zone.title": "Team verwijderen",
	"settings.advanced.delete_organization_modal.delete_button": "Organisatie verwijderen",
	"settings.advanced.delete_organization_modal.description":
		"Ben je zeker dat je je organisatie wil verwijderen? Deze actie is onomkeerbaar. Je abonnement wordt onmiddellijk stopgezet en je flows worden gedeactiveerd.",
	"settings.advanced.delete_organization_modal.failed":
		"Er ging iets mis bij het verwijderen van je organisatie. Neem contact op met onze klantendienst.",
	"settings.advanced.delete_organization_modal.title": "Organisatie verwijderen",
	"settings.advanced.experimental_features.accordion.title": "Experimentele functies",
	"settings.advanced.experimental_features.toggle.help":
		"Opgelet: deze functies zijn in testfase en kunnen kleine fouten of storingen bevatten.",
	"settings.advanced.experimental_features.toggle.label": "Ik wil de nieuwste functies uitproberen.",
	"settings.advanced.experimental_features.tooltip":
		"Deze experimentele functies zullen enkel voor jou persoonlijk geactiveerd worden.",
	"settings.advanced.page.tab_title": "Geavanceerde instellingen",
	"settings.advanced.page.title": "Geavanceerde instellingen",
	"settings.api_key.overview.no_keys.disabled": "Je kan geen API-sleutels meer aanmaken. Upgrade je abonnement.",
	"settings.api_key_detail.domain.button.add_domain": "Domein toevoegen",
	"settings.api_key_detail.domain.description":
		"Bescherm je API-sleutel tegen ongewenste toegang door de API-sleutel te beperken tot een specifieke reeks van vertrouwde domeinen. Sommige machtigingen vereisen dat je vertrouwde domeinen aan de API-sleutel toevoegt voordat deze zal werken.",
	"settings.api_key_detail.domain.table.domain": "Domeinnaam",
	"settings.api_key_detail.domain.title": "Vertrouwde domeinen",
	"settings.api_key_detail.form.description.help":
		"Een beschrijving kan jou en je team helpen om de doelstellingen van deze API-sleutel beter te volgen en in te grijpen bij misbruik.",
	"settings.api_key_detail.form.description.label": "Beschrijving",
	"settings.api_key_detail.form.is_enabled.help":
		"Het uitschakelen van deze API-sleutel zal verdere toegang van gekoppelde platformen meteen voorkomen.",
	"settings.api_key_detail.form.is_enabled.label": "Is ingeschakeld",
	"settings.api_key_detail.form.name.help": "Geef je API-sleutel een relevante naam voor betere herkenning.",
	"settings.api_key_detail.form.name.label": "Naam",
	"settings.api_key_detail.form.name.required": "API-sleutel naam is verplicht.",
	"settings.api_key_detail.header.breadcrumb.overview": "API-sleutels",
	"settings.api_key_detail.header.breadcrumb.settings": "Instellingen",
	"settings.api_key_detail.page.title": "API-sleutel details",
	"settings.api_key_detail.permissions.conflict.banner":
		"Je hebt één of meer machtigingen toegevoegd die niet compatibel zijn met elkaar. Als je deze machtigingen beiden nodig hebt, maak dan meerdere API-sleutels aan om de beveiliging te verbeteren.",
	"settings.api_key_detail.permissions.description":
		"Bescherm je gegevens door de gegevenstoegang van de API-sleutel te beperken. We raden aan om de API-sleutel het minimum aan machtigingen te geven die nodig zijn om het doel te bereiken.",
	"settings.api_key_detail.permissions.select_domain.placeholder": "Selecteer een machtiging",
	"settings.api_key_detail.permissions.table.permission": "Machtiging",
	"settings.api_key_detail.permissions.title": "Machtigingen",
	"settings.api_key_detail.permissions.warning.domain_lock.description":
		"Er wordt vereist om een vertrouwd domein toe te voegen vooraleer deze API-sleutel zal werken.",
	"settings.api_key_modal.add_domain.button.add_domain": "Domein toevoegen",
	"settings.api_key_modal.add_domain.form.domain_name.not_valid_url": "Vul een geldige URL in.",
	"settings.api_key_modal.add_domain.form.domain_name.placeholder":
		"Bijvoorbeeld: https://www.bothive.be, http://getbothive.com, https://...",
	"settings.api_key_modal.add_domain.header.title": "Nieuw domein toevoegen",
	"settings.branding.popup.button.cancel": "Annuleren",
	"settings.branding.popup.button.submit": "Opslaan",
	"settings.channel.breadcrumb.settings": "Instellingen",
	"settings.channel.header.add.button.label": "Kanaal toevoegen",
	"settings.channel.header.search_field.placeholder": "Zoeken op kanaalnaam of e-mailadres",
	"settings.channel.table.empty": "Geen kanalen gevonden",
	"settings.channel.table.is_private.label": "Is privé",
	"settings.channel.table.name.label": "Kanaalnaam",
	"settings.channel.table.sync_status.label": "Status",
	"settings.channel.title": "Kanalen",
	"settings.channel_detail.alert.delete.description":
		"Je kanaal wordt momenteel verwijderd, dit kan even duren. Je wordt automatisch doorgestuurd wanneer deze actie is voltooid, of je kan deze pagina verlaten.",
	"settings.channel_detail.alert.reconnect.button": "Kanaal opnieuw verbinden",
	"settings.channel_detail.alert.reconnect.description":
		"Je kanaal is niet langer up-to-date. Verbind het kanaal opnieuw om het te blijven gebruiken.",
	"settings.channel_detail.alert.reconnect.title": "Kanalen opnieuw verbinden",
	"settings.channel_detail.alert.non_inbox_channel.description":
		"Dit kanaal was gekoppeld vóór de lancering van de Bothive Inbox. Als je dit kanaal wilt gebruiken voor de Inbox, dien je het eerst te verwijderen en vervolgens opnieuw te koppelen.",
	"settings.channel_detail.analytics.connected": "Verbonden op",
	"settings.channel_detail.analytics.integration": "Verbonden kanaal",
	"settings.channel_detail.analytics.is_syncing": "Status",
	"settings.channel_detail.analytics.last_synced": "Laatst gesynchroniseerd op",
	"settings.channel_detail.analytics.title": "Kanaalinformatie",
	"settings.channel_detail.analytics.not_applicable": "N.v.t",
	"settings.channel_detail.breadcrumb.channels_overview": "Kanalen",
	"settings.channel_detail.breadcrumb.settings": "Instellingen",
	"settings.channel_detail.delete.notification.failed": "Kon dit kanaal niet verwijderen. Probeer het later opnieuw.",
	"settings.channel_detail.delete.notification.success": "Kanaal succesvol verwijderd.",
	"settings.channel_detail.delete_card.content.button": "Kanaal verwijderen.",
	"settings.channel_detail.delete_card.description":
		"Wanneer je je kanaal verwijdert, worden al je conversaties, berichten en bijlagen verwijderd uit Bothive. conversaties waar andere kanalen op zijn aangesloten worden behouden, maar jij en je kanaal worden als deelnemer verwijderd.",
	"settings.channel_detail.delete_card.title": "Verwijder kanaal",
	"settings.channel_detail.delete_modal.additional_info":
		"Let op: het kan even duren vooraleer alle gegevens van je kanaal verwijderd zijn. De duur is afhankelijk van de omvang van je kanaal. Bedankt voor je geduld.",
	"settings.channel_detail.delete_modal.button": "Kanaal verwijderen",
	"settings.channel_detail.delete_modal.description": "Wanneer je je kanaal verwijdert, verlies je al je gegevens.",
	"settings.channel_detail.delete_modal.title": "Kanaal verwijderen",
	"settings.channel_detail.form.name.error.required": "Kanaal is verplicht.",
	"settings.channel_detail.form.name.label": "Kanaalnaam",
	"settings.channel_detail.form.submit": "Kanaal bijwerken",
	"settings.channel_detail.form.title": "Kanaalinstellingen",
	"settings.channel_detail.reconnect.notification.failed":
		"Er ging iets mis bij het opnieuw verbinden van je kanaal. Probeer het later opnieuw..",
	"settings.channel_detail.title": "{channelName} - Kanaalgegevens",
	"settings.channel_detail.update.notification.failed":
		"Er ging iets mis bij het bijwerken van dit kanaal. Probeer het later opnieuw.",
	"settings.channel_detail.update.notification.success": "Kanaal succesvol bijgewerkt.",
	"settings.email_signature.breadcrumb.settings": "Instellingen",
	"settings.email_signature.create.failed":
		"Er ging iets mis bij het opslaan van je e-mailhandtekening. Probeer het later opnieuw.",
	"settings.email_signature.create.succeeded": "E-mailhandtekening succesvol opgeslagen.",
	"settings.email_signature.delete.failed": "Er ging iets mis bij het verwijderen van je handtekening.",
	"settings.email_signature.delete.succeeded": "Je e-mailhandtekening is succesvol verwijderd",
	"settings.email_signature.header.add.button.label": "E-mailhandtekening toevoegen",
	"settings.email_signature.modal.button.cancel": "Annuleren",
	"settings.email_signature.modal.button.delete": "Verwijderen",
	"settings.email_signature.modal.create.button.submit": "Aanmaken",
	"settings.email_signature.modal.create.title": "E-mailhandtekening aanmaken",
	"settings.email_signature.modal.form.channel.label": "Kanaal",
	"settings.email_signature.modal.form.channel.placeholder": "Koppel de e-mailhandtekening aan één van je kanalen",
	"settings.email_signature.modal.form.content.label": "Inhoud",
	"settings.email_signature.modal.form.content.error.required": "Handtekening inhoud is verplicht",
	"settings.email_signature.modal.form.content_editor.tab.wysiwyg": "Opgemaakte tekst",
	"settings.email_signature.modal.form.is_primary.help":
		"De primaire handtekening wordt standaard ingesteld op basis van je geselecteerde kanaal.",
	"settings.email_signature.modal.form.is_primary.label": "Is primair",
	"settings.email_signature.modal.form.name.error.max_length":
		"De naam van de handtekening mag niet langer zijn dan 48 tekens.",
	"settings.email_signature.modal.form.name.error.required": "Handtekeningnaam is verplicht.",
	"settings.email_signature.modal.form.name.label": "Naam",
	"settings.email_signature.modal.update.button.submit": "Bewerken",
	"settings.email_signature.modal.update.title": "E-mailhandtekening bewerken",
	"settings.email_signature.table.content.label": "Voorbeeld",
	"settings.email_signature.table.empty": "Geen e-mailhandtekeningen gevonden",
	"settings.email_signature.table.is_primary.label": "Is primair",
	"settings.email_signature.table.name.label": "Handtekeningnaam",
	"settings.email_signature.title": "E-mailhandtekeningen",
	"settings.email_signature.update.failed":
		"Er ging iets mis bij het bijwerken van je handtekening. Probeer het later opnieuw.",
	"settings.email_signature.update.succeeded": "Je e-mailhandtekening is succesvol bijgewerkt",
	"settings.inbox.label.title": "Inboxlabels",
	"settings.integrations.adminpulse.missing_integrations_permissions":
		"Je hebt niet de nodige rechten om deze integratie te bewerken.",
	"settings.integrations.adminpulse.notification.limit_reached":
		"Je hebt geen integraties meer beschikbaar in je huidige licentie. Upgrade je abonnement voor meer integraties.",
	"settings.integrations.adminpulse.tooltip.limit_reached":
		"Het maximaal aantal integraties van dit abonnement is bereikt.",
	"settings.integrations.adminpulse.tooltip.missing_integrations_permissions":
		"Je hebt niet de nodige rechten om de integratie bij te werken.",
	"settings.integrations.fid_manager.login.form.error.has_symbols": "Verwijder speciale tekens en symbolen.",
	"settings.integrations.fid_manager.login.form.error.no_url": "Vul een geldige URL in.",
	"settings.integrations.fid_manager.login.form.error.required": "URL is verplicht.",
	"settings.integrations.fidmanager.notification.limit_reached":
		"Je hebt geen integraties meer beschikbaar in je huidige licentie. Upgrade je abonnement voor meer integraties.",
	"settings.integrations.fidmanager.tooltip.limit_reached":
		"Het maximaal aantal integraties van dit abonnement is bereikt.",
	"settings.integrations.fidmanager.tooltip.missing_integrations_permissions":
		"Je hebt niet de nodige rechten om de integratie bij te werken.",
	"settings.integrations.integration.add.failed":
		"Er ging iets mis bij het toevoegen van deze integratie. Probeer het later opnieuw.",
	"settings.integrations.integration.add.success": "Integratie succesvol toegevoegd.",
	"settings.integrations.integration.delete.failed":
		"Er ging iets mis bij het verwijderen van deze integratie. Probeer het later opnieuw.",
	"settings.integrations.integration.delete.success": "Integratie succesvol verwijderd.",
	"settings.integrations.liantis.notification.limit_reached":
		"Je hebt geen integraties meer beschikbaar in je huidige licentie. Upgrade je abonnement voor meer integraties.",
	"settings.integrations.liantis.tooltip.limit_reached":
		"Het maximaal aantal integraties van dit abonnement is bereikt.",
	"settings.integrations.liantis.tooltip.missing_integrations_permissions":
		"Je hebt niet de nodige rechten om de integratie bij te werken.",
	"settings.integrations.overview.active.title": "Actieve integraties",
	"settings.integrations.overview.all_integrations.fallback":
		"Geen integraties beschikbaar. Neem contact op met onze klantendienst om gewenste integraties te bespreken.",
	"settings.integrations.overview.all_integrations.title": "Alle integraties",
	"settings.integrations.overview.info.description":
		"Breid het Bothive platform uit met integraties. Synchroniseer met je CRM-systeem en connecteer met andere platformen zoals WhatsApp, Messenger en Outlook.",
	"settings.integrations.overview.info.permissions.loading": "Machtigingen ophalen…",
	"settings.integrations.overview.info.title": "Integraties",
	"settings.integrations.overview.info.upgrade_button": "Upgraden",
	"settings.integrations.overview.request_integration.button": "Integratie aanvragen",
	"settings.integrations.overview.request_integration.description":
		"Op zoek naar een andere integratie? Neem contact op met onze klantendienst om gewenste integraties te bespreken.",
	"settings.integrations.overview.request_integration.title": "Integratie aanvragen",
	"settings.integrations.twinntax.connect.description":
		"Koppel Bothive met TwinnTax om de personenbelasting flow vooraf aan per contact aan te vullen met gekende gegevens uit MyMinfin.",
	"settings.integrations.twinntax.connect.title": "Koppel met TwinnTax",
	"settings.integrations.twinntax.form.api_key.help":
		"Je kan een API-sleutel aanmaken in je TwinnTax dashboard onder Instellingen -> API-instellingen -> Creëer een API-sleutel.",
	"settings.integrations.twinntax.form.api_key.label": "API-sleutel",
	"settings.integrations.twinntax.form.api_key.required": "API-sleutel is verplicht.",
	"settings.integrations.twinntax.form.submit": "Koppel met TwinnTax",
	"settings.integrations.twinntax.notification.limit_reached":
		"Je hebt geen integraties meer beschikbaar in je huidige licentie. Upgrade je abonnement voor meer integraties.",
	"settings.integrations.twinntax.tooltip.limit_reached":
		"Het maximaal aantal integraties van dit abonnement is bereikt.",
	"settings.integrations.twinntax.tooltip.missing_integrations_permissions":
		"Je hebt niet de nodige rechten om de integratie bij te werken.",
	"settings.members.popup.cancel_button": "Annuleren",
	"settings.members.popup.invite_button": "Uitnodigen",
	"settings.organization.edit_member.delete_modal.button": "Gebruiker verwijderen",
	"settings.organization.edit_member.delete_modal.description":
		"Je staat op het punt om {username} uit je organisatie te verwijderen. Weet je zeker dat je wil doorgaan?",
	"settings.organization.edit_member.delete_modal.title": "{username} verwijderen",
	"settings.organization.edit_member.edit.delete": "Verwijderen",
	"settings.organization.edit_member.edit.downgrade_power_admin_failed":
		"Je kan de rechten van deze gebruiker niet verwijderen omdat er binnen je organisatie minstens één gebruiker moet zijn die alle gebruiksrechten heeft.",
	"settings.organization.edit_member.edit.failed":
		"Er ging iets mis bij het bewerken van deze gebruiker. Probeer het later opnieuw.",
	"settings.organization.edit_member.edit.success": "Gebruiker succesvol bewerkt.",
	"settings.organization.edit_member.edit.title": "Gebruiker bewerken",
	"settings.organization.edit_member.edit.update": "Aanpassen",
	"settings.organization.edit_member.form.email.email_error": "E-mailadres incompleet.",
	"settings.organization.edit_member.form.email.help":
		"Geen e-mail ontvangen? Bekijk je spamfolder of contacteer onze klantendienst.",
	"settings.organization.edit_member.form.email.label": "E-mail",
	"settings.organization.edit_member.remove.failed":
		"Er ging iets mis bij het verwijderen van deze gebruiker. Probeer het later opnieuw.",
	"settings.organization.edit_member.remove.last_member":
		"Het is niet mogelijk om de enige gebruiker binnen je organisatie te verwijderen.",
	"settings.organization.edit_member.remove.success": "Gebruiker succesvol verwijderd.",
	"settings.organization.edit_member.remove.super_admin": "Het is niet mogelijk om een super admin te verwijderen.",
	"settings.organization.edit_member.resend_invite.failed":
		"Er is een fout opgetreden bij het versturen van de uitnodiging. Probeer het later opnieuw.",
	"settings.organization.edit_member.resend_invite.success": "Uitnodiging verzonden",
	"settings.organization.socials.add_field": "Veld toevoegen",
	"settings.organization.socials.platform.required": "Platform is verplicht.",
	"settings.organization.socials.submit": "Opslaan",
	"settings.organization.socials.url.required": "URL is verplicht.",
	"settings.organization.socials.url.validation": "Voer een geldige {platform} URL in. Voorbeeld: {example}",
	"settings.page.title": "Instellingen",
	"settings.users_popup.edit_flows.help": "Sta de gebruiker toe flows aan te maken, te bewerken en te verwijderen.",
	"settings.users_popup.edit_flows.title": "Flows aanpassen en toevoegen",
	"settings.users_popup.edit_integrations.help":
		"Sta de gebruiker toe integraties aan te maken, te bewerken en te verwijderen.",
	"settings.users_popup.edit_integrations.title": "Integraties aanpassen en toevoegen",
	"settings.users_popup.edit_settings.help": "Sta de gebruiker toe teaminstellingen te wijzigen.",
	"settings.users_popup.edit_settings.title": "Teaminstellingen aanpassen",
	"settings.users_popup.manage_users.help":
		"Sta de gebruiker toe andere gebruikers en teams toe te voegen, te bewerken en te verwijderen.",
	"settings.users_popup.manage_users.title": "Gebruikers beheren",
	"settings.users_popup.permissions": "Gebruikersrechten",
	"settings.users_popup.resend_invite": "Opnieuw uitnodigen",
	"settings.users_popup.super_admin.help":
		"Geef de gebruiker toestemming tot alle bewerkingsrechten. Een super admin kan niet worden verwijderd uit het team.",
	"settings.users_popup.super_admin.title": "Super admin",
	"settings.virtual_assistant.description":
		"Geef je chatbot meer persoonlijkheid door de naam en avatar te wijzigen. Dit is het eerste wat je bezoekers te zien krijgen wanneer ze communiceren met je chatbot.",
	"settings.virtual_assistant.form.avatar.help": "De maximale grootte van de afbeelding is 10 MB.",
	"settings.virtual_assistant.form.avatar.label": "Avatar",
	"settings.virtual_assistant.form.avatar.required": "Avatar is verplicht.",
	"settings.virtual_assistant.form.footer.save": "Opslaan",
	"settings.virtual_assistant.form.name.label": "Naam",
	"settings.virtual_assistant.form.name.required": "Naam is verplicht.",
	"settings.virtual_assistant.suggestions.title": "Instellingen die je mogelijk handig vindt",
	"settings.virtual_assistant.title": "Virtuele assistent",
	"settings.virtual_assistant.update.failed":
		"Er ging iets mis bij het bijwerken van je virtuele assistent. Probeer het later opnieuw.",
	"settings.virtual_assistant.update.succeeded": "Virtuele assistent succesvol bijgewerkt.",
	"settings_overview.advanced.api_key.description":
		"De API-sleutel is een unieke code die wordt gebruikt om toegang te krijgen tot de Bothive API en een klant te identificeren.",
	"settings_overview.advanced.api_key.title": "API-sleutel",
	"settings_overview.advanced.destructive.description": "Onomkeerbare opties zoals het deactiveren van je team.",
	"settings_overview.advanced.destructive.title": "Gevaarlijke opties",
	"settings_overview.advanced.notification_settings.description": "Stel in hoe we je op de hoogte kunnen houden.",
	"settings_overview.advanced.notification_settings.title": "Meldingsinstellingen",
	"settings_overview.advanced.title": "Geavanceerd",
	"settings_overview.branding.styling.description": "Personaliseer de huisstijl van je organisatie.",
	"settings_overview.branding.styling.title": "Styling",
	"settings_overview.branding.title": "Branding",
	"settings_overview.channels.description": "Verbind chat- en e-mailkanalen met je inbox.",
	"settings_overview.channels.footer.button": "Kanalen beheren",
	"settings_overview.channels.google.tooltip": "Verbind met Gmail",
	"settings_overview.channels.office.tooltip": "Verbind met Outlook",
	"settings_overview.channels.title": "Kanalen",
	"settings_overview.contacts.contacts.description": "Contactinstellingen en importopties.",
	"settings_overview.contacts.contacts.title": "Contacten",
	"settings_overview.contacts.labels.description": "Beheer de contactlabels van je organisatie.",
	"settings_overview.contacts.labels.title": "Contactlabels",
	"settings_overview.contacts.title": "Contacten",
	"settings_overview.integrations.description":
		"Breid de mogelijkheden van Bothive uit met integraties. Door te integreren met verschillende externe platformen kun je Bothive synchroniseren met je CRM-systeem om je contacten te importeren.",
	"settings_overview.integrations.footer.button": "Integraties beheren",
	"settings_overview.integrations.title": "Integraties",
	"settings_overview.members.description":
		"Beheer de teamleden die toegang hebben tot het platform en hun machtigingen.",
	"settings_overview.members.footer.button": "Leden beheren",
	"settings_overview.members.title": "Leden",
	"settings_overview.organization.general.description":
		"Basisgegevens van je bedrijf, zoals naam, telefoonnummer, locaties, ...",
	"settings_overview.organization.general.title": "Algemeen",
	"settings_overview.organization.opening_hours.description": "Op welke tijden ben je open of beschikbaar.",
	"settings_overview.organization.opening_hours.title": "Openingstijden",
	"settings_overview.organization.title": "Bedrijfsinstellingen",
	"setup.branding.aside.help": "Personenbelasting flow aanslagjaar 2024",
	"setup.branding.aside.title": "Voorbeeld van hoe we je huisstijl zullen gebruiken",
	"setup.branding.button.invite": "Huisstijl opslaan",
	"setup.branding.button.skip": "Overslaan",
	"setup.branding.colors.accent_color.invalid_color": "Accentkleur is geen geldige hexadecimale kleur.",
	"setup.branding.colors.accent_color.is_required": "Accentkleur is verplicht.",
	"setup.branding.colors.accent_color.label": "Accentkleur",
	"setup.branding.colors.brand_color.invalid_color": "Merkkleur is geen geldige hexadecimale kleur.",
	"setup.branding.colors.brand_color.is_required": "Merkkleur is verplicht.",
	"setup.branding.colors.brand_color.label": "Merkkleur",
	"setup.branding.colors.text_color.invalid_color": "Tekstkleur is geen geldige hexadecimale kleur.",
	"setup.branding.colors.text_color.is_required": "Tekstkleur is vereist.",
	"setup.branding.colors.text_color.label": "Tekstkleur",
	"setup.branding.colors.title": "Bedrijfskleuren",
	"setup.branding.description":
		"Geef de widget de kleuren van je huisstijl om een uniforme ervaring aan je bezoekers te bieden.",
	"setup.branding.icon_and_logo.icon.help": "De maximale grootte van het pictogram is 10 MB.",
	"setup.branding.icon_and_logo.icon.is_required": "Pictogram is verplicht.",
	"setup.branding.icon_and_logo.icon.label": "Pictogram",
	"setup.branding.icon_and_logo.icon.overlay": "Selecteer een pictogram",
	"setup.branding.icon_and_logo.icon.placeholder": "Selecteer een pictogram",
	"setup.branding.icon_and_logo.logo.help": "De maximale grootte van het logo is 10 MB.",
	"setup.branding.icon_and_logo.logo.is_required": "Logo is verplicht.",
	"setup.branding.icon_and_logo.logo.label": "Logo",
	"setup.branding.icon_and_logo.logo.overlay": "Selecteer een logo",
	"setup.branding.icon_and_logo.logo.placeholder": "Selecteer een logo",
	"setup.branding.icon_and_logo.title": "Logo en pictogram",
	"setup.branding.title": "Branding",
	"setup.branding.update.failed": "Er ging iets mis bij het bijwerken van je huisstijl. Probeer het later opnieuw.",
	"setup.flow_setup.aside.help":
		"Dit is een voorbeeld van hoe je webontwikkelaar de flows op je website kan implementeren.",
	"setup.flow_setup.aside.title": "Plaats flows meteen op je website",
	"setup.flow_setup.button.go_to_organization": "Setup voltooien",
	"setup.flow_setup.button.send_mail": "Stuur een e-mail naar je webontwikkelaar en voltooi de setup",
	"setup.flow_setup.code.copied_to_clipboard": "Code gekopieerd naar het klembord.",
	"setup.flow_setup.description":
		"Voer het e-mailadres van je webontwikkelaar in, zodat we alle benodigde informatie kunnen sturen om de flow widget op je website te plaatsen.",
	"setup.flow_setup.form.web_developer.invalid_email": "Ongeldig e-mailadres.",
	"setup.flow_setup.form.web_developer.is_required": "Het e-mailadres van je webontwikkelaar is verplicht.",
	"setup.flow_setup.form.web_developer.label": "E-mail webontwikkelaar",
	"setup.flow_setup.self_setup.description":
		"Voeg de onderstaande code toe in de <head> en <body>-tag en pas de style-attribuut aan zodat deze binnen de website past.",
	"setup.flow_setup.self_setup.title": "Of je kan het zelf doen.",
	"setup.flow_setup.send_email.failed":
		"Er ging iets mis bij het versturen van de instructies naar je webontwikkelaar, probeer het later opnieuw.",
	"setup.flow_setup.send_email.success": "E-mail is verzonden naar je webontwikkelaar.",
	"setup.flow_setup.title": "Flow widget",
	"setup.flows.aside.help":
		"Dit is een voorbeeld van hoe je webontwikkelaar de flows op je website kan implementeren.",
	"setup.flows.aside.title": "Plaats flows meteen op je website",
	"setup.flows.button.create":
		"{value, plural, =0 {Selecteer hierboven een flow} =1 {Activeer # flow} other {Activeer # flows}}",
	"setup.flows.button.go_to_organization": "Overslaan en voltooien",
	"setup.flows.button.next_step": "Volgende stap",
	"setup.flows.create_flows.failed": "Er ging iets mis bij het activeren van de flows. Probeer het later opnieuw.",
	"setup.flows.description":
		"Bij Bothive krijg je een reeks gratis flows die je naar je klanten kan sturen. Klik op degene die je wil activeren.",
	"setup.flows.title": "Activeer je eerste flows",
	"setup.invite_members.add_member.button": "Gebruiker toevoegen",
	"setup.invite_members.add_member.input.aria_label": "Voeg e-mailadressen toe van collega's die je wil uitnodigen.",
	"setup.invite_members.add_member.input.help": "{value} / {total} leden die je aan je organisatie kunt toevoegen.",
	"setup.invite_members.add_member.upgrade_banner": "Upgrade je abonnement om meer leden toe te voegen.",
	"setup.invite_members.aside.help": "Dit is een voorbeeld van hoe je je collega's kan toewijzen aan flow rapporten.",
	"setup.invite_members.aside.image.assign": "Toewijzen",
	"setup.invite_members.aside.title": "Bothive is gebouwd voor jou en je team.",
	"setup.invite_members.button.invite": "Uitnodigingen versturen",
	"setup.invite_members.button.skip": "Overslaan",
	"setup.invite_members.description": "Nodig je collega's uit in je organisatie.",
	"setup.invite_members.invite.failed":
		"Er ging iets mis bij het uitnodigen van nieuwe gebruikers. Probeer het later opnieuw.",
	"setup.invite_members.list.title": "Uitnodigingslijst",
	"setup.invite_members.title": "Gebruikers uitnodigen",
	"setup.steps.branding": "Branding",
	"setup.steps.flow": "Flows",
	"setup.steps.members": "Gebruikers uitnodigen",
	"shared_components.card.flow_template.freemium_tag": "Gratis",
	"support.banner.email_send.failed": "Er ging iets mis bij het verzenden van je bericht. Probeer het later opnieuw.",
	"support.banner.email_send.succeeded": "Bedankt voor je bericht. We nemen zo snel mogelijk contact met je op.",
	"support.form.button.send": "Versturen",
	"support.form.content.required": "Inhoud is verplicht.",
	"support.form.description.required": "Beschrijving is verplicht.",
	"support.form.priority.critical": "Zo snel mogelijk",
	"support.form.priority.high": "Hoog",
	"support.form.priority.low": "Laag",
	"support.form.priority.normal": "Normaal",
	"support.form.question.bug": "Probleem melden",
	"support.form.question.feature_request": "Functie aanvragen",
	"support.form.question.help": "Hulp",
	"support.form.question.installation_help": "Hulp bij installatie",
	"support.form.question.integration_request": "Integratie aanvragen",
	"support.form.question.new_question": "Nieuwe vraag",
	"support.form.question.pricing": "Licenties",
	"support.form.request.required": "Selecteer een optie",
	"support.page.breadcrumb.title": "Klantenservice",
	"support.page.tab_title": "Klantenservice",
	"support.page.title": "Klantenservice",
	"virtual_assistant_settings.breadcrumb.settings": "Instellingen",
	"virtual_assistant_settings.breadcrumb.title": "Virtuele assistent",
	"virtual_assistant_settings.tab_title": "Virtuele assistent",
	"virtual_assistant_settings.title": "Virtuele assistent",
	"inbox.status_header.search.clear": "Verwijderen",

	"editor.toolbar.clear_formatting": "Opmaak wissen",
	"editor.toolbar.code.aria_label": "Opmaken als code",
	"editor.toolbar.code.aria_label.active": "Opmaak verwijderen",
	"editor.toolbar.code.tooltip": "Opmaken als code",
	"editor.toolbar.code.tooltip.active": "Opmaak verwijderen",
	"editor.toolbar.formatting.bold.aria_label": "Vet",
	"editor.toolbar.formatting.bold.aria_label.active": "Opmaak verwijderen",
	"editor.toolbar.formatting.bold.tooltip": "Vet",
	"editor.toolbar.formatting.bold.tooltip.active": "Vet verwijderen",
	"editor.toolbar.formatting.insert_link.aria_label": "Opmaken als link",
	"editor.toolbar.formatting.insert_link.tooltip": "Opmaken als link",
	"editor.toolbar.formatting.italic.aria_label": "Cursief",
	"editor.toolbar.formatting.italic.tooltip": "Cursief",
	"editor.toolbar.formatting.strikethrough.aria_label": "Doorhalen",
	"editor.toolbar.formatting.strikethrough.tooltip": "Doorhalen",
	"editor.toolbar.formatting.title": "Opmaak",
	"editor.toolbar.formatting.underline.aria_label": "Onderstrepen",
	"editor.toolbar.formatting.underline.aria_label.active": "Opmaak verwijderen",
	"editor.toolbar.formatting.underline.tooltip.active": "Opmaak verwijderen",
	"editor.toolbar.formatting.underline.tooltip": "Onderstrepen",
	"editor.toolbar.horizontal_rule": "Horizontale regel",
	"editor.toolbar.insert.title": "Invoegen",
	"editor.toolbar.italic.aria_label": "Cursief maken",
	"editor.toolbar.italic.aria_label.active": "Opmaak formaat verwijderen",
	"editor.toolbar.italic.tooltip": "Cursief maken",
	"editor.toolbar.italic.tooltip.active": "Opmaak formaat verwijderen",
	"editor.toolbar.paragraph_styles.bullet_list": "Opsommingslijst",
	"editor.toolbar.paragraph_styles.bullet_list.aria_label": "Opsommingslijst",
	"editor.toolbar.paragraph_styles.bullet_list.tooltip": "Lijst met opsommingstekens",
	"editor.toolbar.paragraph_styles.bullet_list.tooltip.active": "Lijst met opsommingstekens verwijderen",
	"editor.toolbar.paragraph_styles.code_block": "Codeblok",
	"editor.toolbar.paragraph_styles.heading_1": "Kop 1",
	"editor.toolbar.paragraph_styles.heading_2": "Kop 2",
	"editor.toolbar.paragraph_styles.heading_3": "Kop 3",
	"editor.toolbar.paragraph_styles.normal": "Standaard",
	"editor.toolbar.paragraph_styles.numbered_list": "Genummerde lijst",
	"editor.toolbar.paragraph_styles.numbered_list.aria_label": "paragraaf opmaken in Genummerde lijst",
	"editor.toolbar.paragraph_styles.numbered_list.aria_label.active": "Ongeordend lijstitem verwijderen",
	"editor.toolbar.paragraph_styles.numbered_list.tooltip": "Nummerlijst",
	"editor.toolbar.paragraph_styles.numbered_list.tooltip.active": "Genummerde lijst verwijderen",
	"editor.toolbar.paragraph_styles.quote": "Citaat",
	"editor.toolbar.signature": "Handtekening",
	"editor.toolbar.subscript": "Onderschrift",
	"editor.toolbar.superscript": "Superscript",
	"editor.toolbar.table": "Tabel",
	"editor.toolbar.text_align.centr_align.aria_label": "Tekst centreren",
	"editor.toolbar.text_align.center_align.tooltip": "Tekst centreren",
	"editor.toolbar.text_align.left_align.tooltip": "Tekst links uitlijnen",
	"editor.toolbar.text_align.left_align.aria_label": "Tekst links uitlijnen",
	"editor.toolbar.text_align.right_align.aria_label": "Tekst rechts uitlijnen",
	"editor.toolbar.text_align.right_align.tooltip": "Tekst rechts uitlijnen",

	"inbox.message.header.draft.btn.edit": "Concept bewerken",
	"inbox.message.header.draft.btn.discard": "Concept verwijderen",

	"inbox.message.header.failed.title": "Verzenden van e-mail is niet gelukt",

	"inbox.conversation.detail.message.list.fetch_updates": "Updates ophalen",
	"editor.thread_node.header.from": "Van: {from}",
	"editor.thread_node.header.send_on": "Verzonden op: {sendOn}",
	"editor.thread_node.header.to": "Aan: {to}",
	"editor.thread_node.header.subject": "Onderwerp: {subject}",
	"editor.thread_node.toggle.show": "Berichtgeschiedenis weergeven",
	"editor.thread_node.toggle.hide": "Berichtgeschiedenis verbergen",

	"inbox.init_walkthrough.title": "Het is eindelijk zover 🎉",
	"inbox.init_walkthrough.description":
		"Ontdek de kracht van inbox zero gecombineerd met onze krachtige teamfuncties.",
	"inbox.init_walkthrough.button": "Sla tutorial over",
	"inbox.message.draft.composer.open.overlay": "Het concept wordt bewerkt",

	"INBOX.COMING_SOON.BUTTON": "Vraag je toegang aan",
	"contact.integration.status.forbidden.title": "Je heb geen toegang tot dit {integrationName} contact.",
	"contact.integration.status.loading.title": "Overeenkomend contact ophalen...",
	"editor.signature_node.add_signature": "Handtekening toevoegen",
	"editor.table_menu.column_group_title": "Kolomopties",
	"editor.table_menu.row_group_title": "Rijopties",
	"editor.table_menu.table_group": "Tabelopties",
	"editor.toolbar.formatting.strikethrough.aria_label.active": "Doorhalen verwijderen",
	"editor.toolbar.formatting.strikethrough.tooltip.active": "Doorhalen verwijderen",
	"editor.toolbar.ordered_list.aria_label.active": "Lijst met opsommingstekens verwijderen",
	"editor.toolbar.text_align.center_align.aria_label": "Tekst gecentreerd uitlijnen",
	"inbox.message.header.draft.title": "Concept",
	"inbox.side_navigation.calendar.not_found": "Geen kalender gevonden",
	"inbox.side_navigation.calendars.title": "Kalender",
	"inbox.side_navigation.labels.title": "Labels",
	"notification.header.read_status.switch.all": "Alles",
	"notification.header.read_status.switch.read_only": "Ongelezen",
	"notification.header.read_status.switch.tooltip": "Schakelen tussen alleen ongelezen versus alles",
	"notification.list_item.chip.added_as_participant": "Deelnemer",
	"notification.list_item.chip.sending_failed": "Verzenden mislukt",
	"settings.channel.lock.button.contact_support": "Upgrade je abonnement",
	"settings.channel.lock.description":
		"Klaar om je dashboard naar een hoger niveau te tillen? Upgrade nu om het volledige potentieel te benutten en te genieten van een naadloze ervaring!",
	"settings.channel.lock.title": "Upgraden om kanalen te verbinden",
	"settings.notifications.browser_notifications.description":
		"Beheer websitemeldingen moeiteloos door je browserinstellingen aan te passen. Zoek eenvoudigweg naar 'Notificaties' in je browservoorkeuren om je ervaring aan te passen.",
	"settings.notifications.browser_notifications.state.access": "Toegang verleend",
	"settings.notifications.browser_notifications.state.no_access": "Geen toegang",
	"settings.notifications.browser_notifications.title": "Browsermeldingen",

	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.PROGRESS_LABEL":
		"Je hebt {value, plural, =0 {geen integraties} one {# integratie} other {# integraties}} over in je plan.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SAVE_INTO_CONTACT": "Opslaan in contact",
	"contact.import.csv.review_import.filters.errors": "Toon enkel fouten",
	"contact.import.csv.review_import.filters.show_all": "Show all",
	"contact.import.csv.review_import.filters.warnings": "Toon enkel waarschuwingen",
	"contact.import.date_format_option.iso_date": "ISO datum standaard",
	"contact.import.field.link_data.flowProxy": "Flow volmacht",
	"contact.import.field.linked_data.title": "Gelinkte data",
	"contact.import.field_config.date_format": "Datum formaat",
	"contact.import.report.collapse.more": "Open rij om conflicterende unieke velden per contact te zien (Plus-icoon links in de rij)",
	"contact.import.report.conflict.bothive_contact.count": "{count} conflicterende contacten",
	"contact.import.report.conflict.bothive_contact.not_found": "Contact niet gevonden (Waarschijnlijk verwijderd)",
	"contact.import.report.conflict_table.bothive_contact.title": "Bestaand Bothive-contact",
	"contact.import.report.conflict_table.conflicting_unique_identifiers.title":
		"Conflicterende unieke identificator(en)",
	"contact.import.report.conflict_table.recommended_action.title": "Aanbevolen actie",
	"contact.import.report.conflict_table.source_contact.title":
		"{source, select, undefined {Bron} other {{source}}} contact",
	"contact.import.report.contact.status.archived": "Gearchiveerd",
	"contact.import.report.contact.status.deleted": "Verwijderd",
	"contact.import.report.table.source.title": "Bron",
	"contact.import.report.table.created.title": "Aangemaakt",
	"contact.import.report.table.updated.title": "Geüpdate",
	"contact.import.report.table.skipped.title": "Niet geïmporteerd vanwege conflicten",
	"contact.import.report.webhook.description": "Synchronisatie via webhook gestart op {date}.",
	"contact.import.report.by_person.description": "Import gestart door {createdBy} op {date}.",
	"contact.import.report.error.title": "Er is iets misgegaan bij het laden van je contactimport.",
	"contact.import.report.failed.title": "Contactimport geslaagd maar met conflicten",
	"contact.import.report.notification.cta.link": "Ga naar conflicten",
	"contact.import.report.recommended_action.adapt_conflicting_fields_on_source_data.description":
		"Dit contact van {source, select, undefined {je externe brongegevens} other {{source}}} conflicteert met bestaande contacten. Pas de conflicterende velden aan in {source, select, undefined {je brongegevens} other {{source}}} of verwijder ze uit het {source, select, undefined {bron} other {{source}}} contact als je geen unieke waarde voor dit contact hebt.",
	"contact.import.report.recommended_action.remove_conflicting_fields_on_source_data_without_same_source_unique_identifier":
		"Dit contact van vanuit je {source, select, undefined {externe brongegevens} other {{source}}} conflicteert met meerdere bestaande contacten. Eén van deze bestaande contacten komt exact overeen met je {source, select, undefined {bron} other {{source}}} contact; hier zijn geen aanpassingen nodig. Voor de andere conflicterende contacten moet je de conflicterende velden aanpassen in {source, select, undefined {je brongegevens} other {{source}}}.",
	"contact.import.report.return_to_contacts": "Terug naar contactoverzicht",
	"contact.import.report.skipped.description":
		"Een aantal contacten kon niet worden {type, select, webhook {gesynchroniseerd} other {geïmporteerd}} vanwege conflicterende velden die uniek moeten zijn. Hieronder staat een overzicht van deze contacten en de conflicterende velden. We raden aan om de {source, select, undefined {bron} other {{source}}} contacten aan te passen of te corrigeren.",
	"contact.import.report.skipped.support":
		"Als je meer informatie nodig hebt, neem dan contact op met ons ondersteuningsteam via:",
	"contact.import.report.skipped.title":
		"Niet {type, select, webhook {gesynchroniseerd} other {geïmporteerd}} contacten vanwege conflicten",
	"contact.import.report.success.title": "Contactimport geslaagd 🚀",
	"contact.import.report.unique_identifier.email.label": "E-mail",
	"contact.import.report.unique_identifier.externalIds.label":
		"{source, select, undefined {Bron} other {{source}}} Unieke ID",
	"contact.import.report.unique_identifier.nationalRegistrationNumber.label": "Nationaal registratienummer",
	"contact.import.report.unique_identifier.phone.label": "Telefoon",
	"contact.import.report.unique_identifier.uniqueIdentifier.label": "Unieke referentie",
	"contact.import.report.webhook.title": "De synchronisatie van een of meer contacten is mislukt via webhook",
	"flows.report.overview.bulk_action.confirm_save_into_contact.button.cancel": "Annuleren",
	"flows.report.overview.bulk_action.confirm_save_into_contact.button.save": "Opslaan",
	"flows.report.overview.bulk_action.confirm_save_into_contact.description":
		"Je staat op het punt de gegevens uit de geselecteerde rapporten in hun contacten op te slaan. Deze actie kan niet ongedaan worden gemaakt en overschrijft de bestaande gegevens.",
	"flows.report.overview.bulk_action.confirm_save_into_contact.failed":
		"Het lijkt erop dat we de gegevens momenteel niet in je contacten kunnen opslaan. Probeer het later nog eens.",
	"flows.report.overview.bulk_action.confirm_save_into_contact.succeeded":
		"Rapporten zijn opgeslagen in je contacten",
	"flows.report.overview.bulk_action.confirm_save_into_contact.title": "Bewaar rapportgegevens in contact",
	"flows.report.overview.bulk_action.modal.delete_reports.invalid":
		"Type '{{value}}' in hoofdletters om het rapport te verwijderen",
	"flows.report.overview.bulk_action.modal.delete_reports.placeholder": "Type {value}",
	"flows.report.overview.bulk_action.modal.delete_reports.success.title": "Rapporten zijn verwijderd.",
	"flows.report.overview.bulk_action.modal.delete_reports.title": "Verwijder geselecteerde rapporten",
	"flows.report.overview.bulk_action.modal.delete_reports.value": "VERWIJDEREN",
	"flows.report.overview.bulk_action.modal.force_delete_contacts.help":
		"Type {value} in hoofdletters om het contact te verwijderen",
	"flows.report.overview.bulk_action.modal.force_delete_contacts.invalid":
		"Type '{{value}}' in hoofdletters om het contact te verwijderen",
	"flows.report.overview.bulk_action.modal.force_delete_contacts.placeholder": "Type {value}",
	"flows.report.overview.filter.STATUS.hidden_option.tooltip":
		"Rapporten met interne status voltooid, worden standaard verborgen",
	"flows.report.overview.filter.account_manager.not_found": "Geen dossierbeheerders gevonden",
	"flows.report_detail.list.filter.account_manager.not_found": "Geen dossierbeheerders gevonden",
	"profile.general.form.language.help": "Talen in Beta zijn functioneel maar kunnen soms eeen vertaling missen.",
	"profile.general.form.language.label": "Taal",
	"profile.language.en_gb": "English (BETA)",
	"profile.language.nl_be": "Nederlands",
	"settings.contact_import.title": "Contact import",
	"settings.integrations.admin_pulse.webhook.form.crm_type.placeholder": "Selecteer CRM type",
	"settings.integrations.admin_pulse.webhook.form.type.placeholder": "Selecteer contact type",
	"settings.integrations.admin_pulse.audit_logs.title": "AdminPulse-taken audit logs",
	"settings.integrations.admin_pulse.audit_logs.description":
		"In deze logs kun je zien wat er gebeurde tijdens het synchroniseren van jouw acties tussen Bothive en AdminPulse taken. Deze logs helpen je om problemen op te sporen en op te lossen.",
	"settings.integrations.admin_pulse.audit_logs.status": "Status",
	"settings.integrations.admin_pulse.audit_logs.contact": "Contact",
	"settings.integrations.admin_pulse.audit_logs.relation_identifier": "AdminPulse id",
	"settings.integrations.admin_pulse.audit_logs.task": "Taak",
	"settings.integrations.admin_pulse.audit_logs.sub_task": "Subtaak",

	"audit_logs.success": "Taak geüpdate",
	"audit_logs.failed": "Taak update mislukt",
	"audit_logs.relation_not_found": "Relatie niet gevonden in AdminPulse",
	"audit_logs.no_tasks_found": "Taak niet gevonden voor contact",
	"audit_logs.no_sub_tasks_found": "Sub taak niet gevonden",
};
