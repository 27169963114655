import { trpc } from "@/trpc";
import { Radio, Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { useState } from "react";
import { TConversationWithContactResponse } from "../..";
import MessageList from "@/modules/inbox/ConversationView/ConversationContent/components/MessageList";

export default function ContactConversationPanelChildren({ _id }: TConversationWithContactResponse[number]) {
	const intl = useIntl();
	const [showAll, setShowAll] = useState(false);

	const {
		data: messages,
		isLoading: isMessagesLoading,
		isRefetching: isMessagesRefetching,
		refetch: refetchMessages,
	} = trpc.inbox.message.allByConversationId.useQuery({ conversationId: _id });

	const latestEmailMessage = messages
		?.toReversed()
		.find((message) => message.type === "email" && !message.isDraft && !message.hasFailed);
	const hasOnlyOneEmailMessage = Boolean((messages?.length || 0) < 2 && latestEmailMessage);

	return (
		<article
			style={{
				background: "var(--grey-light)",
				borderRadius: "var(--rounded)",
				boxShadow: "var(--box-shadow)",
			}}
		>
			<div style={{ padding: "var(--spacer-sm) var(--spacer-sm) 0" }}>
				<Radio.Group size="small" value={showAll} onChange={() => setShowAll((prev) => !prev)}>
					<Radio.Button value={false}>
						<FormattedMessage
							id="contact.conversation.thread.switch.latest_email"
							defaultMessage="Latest e-mail"
						/>
					</Radio.Button>
					<Tooltip
						title={
							hasOnlyOneEmailMessage &&
							intl.formatMessage({
								id: "contact.conversation.thread.switch.all.disabled.tooltip",
								defaultMessage: "Conversation has only one e-mail",
							})
						}
					>
						<Radio.Button value={true} disabled={hasOnlyOneEmailMessage}>
							<FormattedMessage
								id="contact.conversation.thread.switch.all"
								defaultMessage="All messages"
							/>
						</Radio.Button>
					</Tooltip>
				</Radio.Group>
			</div>
			<MessageList
				style={{ padding: "var(--spacer-sm)" }}
				hasActions={false}
				showSubjects={showAll} // show subject bcs we can loose context while scrolling
				conversationId={_id}
				messages={showAll ? messages : [latestEmailMessage].filter(Boolean)}
				isMessagesRefetching={isMessagesRefetching}
				isMessagesLoading={isMessagesLoading}
				refetchMessages={refetchMessages}
			/>
		</article>
	);
}
