import { WarningOutlined } from "@ant-design/icons";
import { IInboxLabel } from "@bothive_core/database";
import { Avatar, Form, Input, InputRef, Select, Tooltip, Typography } from "antd";
import { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDebounce } from "use-debounce";

import FieldLabel from "@/shared/components/field/default";
import ColorPicker from "@/shared/components/input/ColorPicker";
import { useSetFocus } from "@/shared/hooks";
import { trpc } from "@/trpc";
import config from "../config";

export type InboxLabelFormState = Pick<IInboxLabel, "name" | "description" | "type"> & {
	channelId?: string;
	color: string;
};

interface InboxLabelFormProps {
	initialValues?: IInboxLabel | Pick<IInboxLabel, "color">;
	disableChannelInput?: boolean;
	onFinish: (values: InboxLabelFormState) => void;
}

export default function InboxLabelForm({ initialValues, onFinish, disableChannelInput = false }: InboxLabelFormProps) {
	const [form] = Form.useForm();
	const intl = useIntl();
	const type = Form.useWatch<IInboxLabel["type"]>("type", form);
	const [channelSearch, setChannelSearch] = useState<string>("");
	const [debouncedChannelSearch] = useDebounce(channelSearch, 500);

	const inputRef = useRef<InputRef>(null);
	useSetFocus({ ref: inputRef, dependencies: [open] });

	const { data: organizationChannels, isLoading } = trpc.channel.getInboxChannels.useQuery(
		{ q: debouncedChannelSearch },
		{ staleTime: Infinity }
	);

	return (
		<Form
			form={form}
			id="createInboxLabel"
			layout="vertical"
			requiredMark={false}
			initialValues={initialValues}
			onFinish={onFinish}
			style={{ width: "100%", gap: "1em", display: "flex", flexDirection: "column" }}
		>
			<FieldLabel
				id="name"
				label={intl.formatMessage({ id: "inbox.label.form.field.name.label", defaultMessage: "Name" })}
			>
				<Form.Item
					style={{ width: "100%", textAlign: "left" }}
					name="name"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "inbox.label.form.field.name.validation_rule",
								defaultMessage: "Please enter a name",
								description: "This text shows when the required input for name is empty",
							}),
						},
						{
							max: 56,
							message: intl.formatMessage({
								id: "inbox.label.form.field.name.validation_rule.too_long",
								defaultMessage: "Please enter a name of max 56 characters",
								description: "This text shows when th input for name can be max 56 characters long",
							}),
						},
					]}
				>
					<Input
						maxLength={56}
						ref={inputRef}
						placeholder={intl.formatMessage({
							id: "inbox.label.form.field.name.placeholder",
							defaultMessage: "Enter a name",
						})}
					></Input>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				optional
				help={intl.formatMessage({
					id: "inbox.label.form.field.description.help",
					defaultMessage: "Write a description to explain what this label means. (max 256 characters)",
					description:
						"This field is additional info shown beneath the input field to give more context to the user about the goal of a inbox label description.",
				})}
				label={intl.formatMessage({
					id: "inbox.label.form.field.description.label",
					defaultMessage: "Description",
				})}
			>
				<Form.Item name="description" style={{ width: "100%", textAlign: "left" }}>
					<Input.TextArea
						maxLength={256}
						placeholder={intl.formatMessage({
							id: "inbox.label.form.field.description.placeholder",
							defaultMessage: "This label is applied to conversation about client Jhon.",
							description:
								"Default show an example sentence to show what an inbox label description could look like",
						})}
					></Input.TextArea>
				</Form.Item>
			</FieldLabel>
			<Tooltip
				placement="topLeft"
				title={
					disableChannelInput &&
					intl.formatMessage({
						id: "inbox.label.form.field.type.tooltip.disabled",
						defaultMessage:
							"It is not possible to change the inbox label type when it's created as label of a coupled channel.",
						description:
							"This field will only be disabled when it's type === channel at an update modal. We want to prevent the user from changing the channel of a label if it is already coupled with an existing channel.",
					})
				}
			>
				<span>
					<FieldLabel
						id="type"
						label={intl.formatMessage({
							id: "inbox.label.form.field.type.label",
							defaultMessage: "Type",
						})}
					>
						<Form.Item
							style={{ width: "100%", textAlign: "left" }}
							name="type"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "inbox.label.form.field.type.validation_rule",
										defaultMessage: "Please choose a type",
										description: "This text shows when the required input for type is empty",
									}),
								},
							]}
						>
							<Select
								allowClear={true}
								disabled={disableChannelInput}
								placeholder={intl.formatMessage({
									id: "inbox.label.form.field.type.placeholder",
									defaultMessage: "Choose a type",
								})}
								className="loading_select settings-inbox_labels-form-type_option"
							>
								{Array.from(config.labelTypeIntl).map(([key, value]) => (
									<Select.Option key={key} value={key} label={intl.formatMessage(value)}>
										<p className="settings-inbox_labels-form-type_option-title">
											{config.labelTypeIcon.get(key)?.()}
											<FormattedMessage {...value} />
										</p>
										<Typography.Text
											type="secondary"
											className="settings-inbox_labels-form-type_option-description"
										>
											<FormattedMessage {...config.labelDescriptionIntl.get(key)} />
										</Typography.Text>
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</FieldLabel>
				</span>
			</Tooltip>
			{type === "channel" && (
				<Tooltip
					placement="topLeft"
					title={
						disableChannelInput &&
						intl.formatMessage({
							id: "inbox.label.form.field.channel.tooltip.disabled",
							defaultMessage:
								"It is not possible to change the inbox label channel when it's created as label of a coupled channel.",
							description:
								"We want to prevent the user from changing the channel of a label if it is already coupled with an existing channel.",
						})
					}
				>
					<span>
						<FieldLabel
							id="channelId"
							label={intl.formatMessage({
								id: "inbox.label.form.field.channel.label",
								defaultMessage: "Channel",
							})}
						>
							<Form.Item
								name="channelId"
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id: "inbox.label.form.field.channel.validation_rule",
											defaultMessage: "Please choose a channel",
											description: "This text shows when the required input for channel is empty",
										}),
									},
								]}
								style={{ width: "100%", textAlign: "left" }}
							>
								<Select
									className="loading_select"
									allowClear={true}
									showSearch
									options={organizationChannels?.data?.map((channel) => ({
										value: channel._id,
										disabled: channel.disabled,
										label: (
											<span
												style={{
													display: "flex",
													alignItems: "center",
													gap: "1em",
													...(channel.disabled && {
														filter: "grayscale(100%)",
													}),
												}}
											>
												<Avatar shape="square" size="small" src={channel?.logo} />
												<Typography.Text>{channel?.name}</Typography.Text>
												{channel.disabled && (
													<Tooltip
														title={intl.formatMessage({
															id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.RECONNECT.TOOLTIP",
														})}
													>
														<WarningOutlined />
													</Tooltip>
												)}
											</span>
										),
									}))}
									filterOption={false}
									onSearch={(value) => setChannelSearch(value)}
									searchValue={channelSearch}
									showArrow
									disabled={disableChannelInput}
									placeholder={intl.formatMessage({
										id: "inbox.label.form.field.channel.placeholder",
										defaultMessage: "Choose a channel",
									})}
									loading={isLoading}
								/>
							</Form.Item>
						</FieldLabel>
					</span>
				</Tooltip>
			)}
			<FieldLabel
				id="color"
				label={intl.formatMessage({ id: "inbox.label.form.field.color.label", defaultMessage: "Color" })}
			>
				<Form.Item style={{ width: "100%", textAlign: "left" }} name="color">
					<ColorPicker />
				</Form.Item>
			</FieldLabel>
		</Form>
	);
}
