import "./style.scss";

import { LeftOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Image } from "@/components/atoms";
import { Header } from "@/components/molecules";

export default function FlowDetailHeader({
	title,
	description,
	freemium,
	src,
	onClick,
	isLoading,
	disabled,
	onReturn,
	className,
}) {
	const intl = useIntl();
	const isDisabled = disabled && !freemium;

	return (
		<header className={`header--detail header--detail-flow ${className}`}>
			<div className="header--detail-flow-navigation">
				<Button type="text" icon={<LeftOutlined />} onClick={onReturn}>
					<FormattedMessage id="flow_detail_template_modal.overview" defaultMessage="Return to overview" />
				</Button>
				<Tooltip
					title={
						isDisabled
							? intl.formatMessage({
									id: "flow_detail_template_modal.button.create.tooltip",
									defaultMessage:
										"You have reached the amount of flows you can add in your current plan",
									description:
										"Tooltip message show'n when user hover over create flow button when they cannot create a new flow",
							  })
							: ""
					}
				>
					<Button onClick={onClick} loading={isLoading} disabled={isDisabled}>
						<FormattedMessage id="flow_detail_template_modal.button.create" defaultMessage="Create flow" />
					</Button>
				</Tooltip>
			</div>
			<div className="header--detail-flow-content">
				<Image.ADefault src={src} />
				<Header.MHeaderDescription title={title} description={description} />
			</div>
		</header>
	);
}
