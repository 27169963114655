import "./style.scss";

import { Select, Spin } from "antd";
import React from "react";
import { useIntl } from "react-intl";

export default function SelectLoading({
	isLoading,
	value,
	selectedValue,
	placeholder = "",
	children,
	onChange,
	onClear,
	mode,
	showSearch,
	className = "",
	allowClear = true,
	size = "large",
	filterOption,
	notFoundContent = <div></div>,
	onSearch,
	...props
}) {
	const intl = useIntl();

	return (
		<Select
			{...props}
			size={size}
			mode={mode}
			showSearch={showSearch}
			filterOption={filterOption}
			value={value || selectedValue}
			className={`loading_select ${className}`}
			allowClear={allowClear}
			placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ""}
			notFoundContent={isLoading === "loading" ? <Spin size="small" /> : notFoundContent}
			onChange={onChange}
			onSearch={onSearch}
			onClear={onClear}
		>
			{children}
		</Select>
	);
}
