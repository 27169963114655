/*eslint-disable max-len*/
import typesConfig from "@/config/types.config";

export default {
	editorConfig: ({ placeholder, templates, template_preview_replace_values }) => ({
		placeholder,
		language: "nl",
		menubar: "insert",
		contextmenu: "",
		content_style:
			"@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,800'); body { font-family: 'Open Sans', sans-serif; }",
		plugins: ["autolink link image lists"],
		toolbar: `bold italic underline strikethrough | forecolor backcolor | numlist bullist |
	alignleft aligncenter alignright alignjustify | outdent indent image link removeformat |
	fontselect fontsizeselect formatselect`,
		template_preview_replace_values,
		templates: templates,
	}),
	templates: [
		// DEFAULT keep at index 0
		{
			id: "default",
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.DEFAULT.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.DEFAULT.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style=\"font-family:'Open Sans';\"><p>Dag {{contact.firstName}},</p>
				<p>Om de administratie voor jou zo gemakkelijk mogelijk te maken, hebben we een flow voorzien waar onze virtuele assistent je door zal loodsen. Kan je de bijgevoegde vragenlijst even invullen voor ons?</p>
				<p>Je kan het chatgesprek starten via deze beveiligde link: <a href="{{flow_link}}">link</a></p>
				<p>Alvast bedankt om de flow samen met onze virtuele assistent in te vullen!&nbsp;</p><body/></html>`,
		},
		//PB
		{
			id: "6224e0f300166e00116ee48d",
			type: typesConfig.templateTypes.firstSend,
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2022.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2022.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style="font-family: \'Open Sans\';"><p><span style="font-weight: 400;">Dag {{contact.firstName}}</span></p>
				<p><span style="font-weight: 400;">De voorbereiding van je personenbelasting staat opnieuw op onze planning.</span></p>
				<p><span style="font-weight: 400;">We begrijpen dat het verzamelen en aanleveren van alle gegevens jaarlijks heel wat tijd in beslag neemt. Daarom willen we de aangifte vanaf dit jaar effici&euml;nter aanpakken. We hebben hiervoor een flow voorzien waar onze virtuele assistent je door zal loodsen.</span></p>
				<p><span style="font-weight: 400;">Aan de hand van een chatgesprek met onze virtuele assistent kan je namelijk alle gegevens en documenten </span><b>makkelijk</b><span style="font-weight: 400;"> &eacute;n vooral </span><b>veilig</b><span style="font-weight: 400;"> aan ons aanleveren. Dat bespaart je heel wat mailtjes, en zorgt ervoor dat we samen de aangifte veel sneller kunnen afhandelen.&nbsp;</span></p>
				<p><span style="font-weight: 400;">Nadat je bent ingelogd in het systeem, zal je zelfs je gekende informatie zien van de aangifte van vorig jaar. Op die manier hoef je enkel te bevestigen of alles nog klopt, en kan je aanvullen waar nodig. Is er geen data van vorig jaar? Geen probleem, vul de flow dit jaar volledig in en volgend jaar zal je enkel nog de wijzigingen moeten doorgeven.</span></p>
				<p><span style="font-weight: 400;">Je kan het chatgesprek starten via deze beveiligde <a href="{{flow_link}}">link</a>.</span><span style="font-weight: 400;">
				</span><span style="font-weight: 400;">
				</span><span style="font-weight: 400;">Mocht je vragen hebben over je personenbelasting zijn we uiteraard nog steeds even graag persoonlijk te bereiken. De tool die we hebben voorzien dient om jou te ontzorgen, en zeker niet om ons persoonlijk contact weg te nemen.&nbsp;</span></p>
				<p><span style="font-weight: 400;">Alvast bedankt om de flow samen met onze virtuele assistent in te vullen! </span></p><body/><html/>`,
		},
		{
			id: "6224e0f300166e00116ee48d",
			type: typesConfig.templateTypes.reminding,
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2022.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2022.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style="font-family: \'Open Sans\';"><p><span style="font-weight: 400;">Dag {{contact.firstName}}</span></p>
				<p><span style="font-weight: 400;">Een tijdje geleden ontving je van ons een uitnodiging om een vragenlijst in te vullen voor de aangifte van je personenbelasting. Ik wil je er vriendelijk aan herinneren om deze zeker in te vullen zodat we je aangifte zeker tijdig kunnen indienen.</span></p>
				<p><span style="font-weight: 400;">Je kan het chatgesprek voor de aangifte opstarten via deze beveiligde <a href="{{flow_link}}">link</a>.</span><span style="font-weight: 400;"></span><span style="font-weight: 400;">
				</span><span style="font-weight: 400;">Mocht je vragen hebben over je personenbelasting zijn we uiteraard nog steeds even graag persoonlijk te bereiken. De tool die we hebben voorzien dient om jou te ontzorgen, en zeker niet om ons persoonlijk contact weg te nemen.&nbsp;</span></p>
				<p><span style="font-weight: 400;">Alvast bedankt om de flow samen met onze virtuele assistent in te vullen! </span></p><body/><html/>`,
		},

		{
			id: "63bc3c11fd683f414ba207d3",
			type: typesConfig.templateTypes.firstSend,
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2022.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2022.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style="font-family: \'Open Sans\';"><p><span style="font-weight: 400;">Dag {{contact.firstName}}</span></p>
				<p><span style="font-weight: 400;">De voorbereiding van je personenbelasting staat opnieuw op onze planning.</span></p>
				<p><span style="font-weight: 400;">We begrijpen dat het verzamelen en aanleveren van alle gegevens jaarlijks heel wat tijd in beslag neemt. Daarom willen we de aangifte vanaf dit jaar effici&euml;nter aanpakken. We hebben hiervoor een flow voorzien waar onze virtuele assistent je door zal loodsen.</span></p>
				<p><span style="font-weight: 400;">Aan de hand van een chatgesprek met onze virtuele assistent kan je namelijk alle gegevens en documenten </span><b>makkelijk</b><span style="font-weight: 400;"> &eacute;n vooral </span><b>veilig</b><span style="font-weight: 400;"> aan ons aanleveren. Dat bespaart je heel wat mailtjes, en zorgt ervoor dat we samen de aangifte veel sneller kunnen afhandelen.&nbsp;</span></p>
				<p><span style="font-weight: 400;">Nadat je bent ingelogd in het systeem, zal je zelfs je gekende informatie zien van de aangifte van vorig jaar. Op die manier hoef je enkel te bevestigen of alles nog klopt, en kan je aanvullen waar nodig. Is er geen data van vorig jaar? Geen probleem, vul de flow dit jaar volledig in en volgend jaar zal je enkel nog de wijzigingen moeten doorgeven.</span></p>
				<p><span style="font-weight: 400;">Je kan het chatgesprek starten via deze beveiligde <a href="{{flow_link}}">link</a>.</span><span style="font-weight: 400;">
				</span><span style="font-weight: 400;">
				</span><span style="font-weight: 400;">Mocht je vragen hebben over je personenbelasting zijn we uiteraard nog steeds even graag persoonlijk te bereiken. De tool die we hebben voorzien dient om jou te ontzorgen, en zeker niet om ons persoonlijk contact weg te nemen.&nbsp;</span></p>
				<p><span style="font-weight: 400;">Alvast bedankt om de flow samen met onze virtuele assistent in te vullen! </span></p><body/><html/>`,
		},
		{
			id: "63bc3c11fd683f414ba207d3",
			type: typesConfig.templateTypes.reminding,
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2022.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2022.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style="font-family: \'Open Sans\';"><p><span style="font-weight: 400;">Dag {{contact.firstName}}</span></p>
				<p><span style="font-weight: 400;">Een tijdje geleden ontving je van ons een uitnodiging om een vragenlijst in te vullen voor de aangifte van je personenbelasting. Ik wil je er vriendelijk aan herinneren om deze zeker in te vullen zodat we je aangifte zeker tijdig kunnen indienen.</span></p>
				<p><span style="font-weight: 400;">Je kan het chatgesprek voor de aangifte opstarten via deze beveiligde <a href="{{flow_link}}">link</a>.</span><span style="font-weight: 400;"></span><span style="font-weight: 400;">
				</span><span style="font-weight: 400;">Mocht je vragen hebben over je personenbelasting zijn we uiteraard nog steeds even graag persoonlijk te bereiken. De tool die we hebben voorzien dient om jou te ontzorgen, en zeker niet om ons persoonlijk contact weg te nemen.&nbsp;</span></p>
				<p><span style="font-weight: 400;">Alvast bedankt om de flow samen met onze virtuele assistent in te vullen! </span></p><body/><html/>`,
		},
		{
			id: "65567c114e7254410e97e96f",
			type: typesConfig.templateTypes.firstSend,
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2024.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2024.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style="font-family: \'Open Sans\';"><p><span style="font-weight: 400;">Dag {{contact.firstName}}</span></p><br />
				<p><span style="font-weight: 400;">De voorbereiding van je personenbelasting staat opnieuw op onze planning.</span></p>
				<p><span style="font-weight: 400;">We begrijpen dat het verzamelen en aanleveren van alle gegevens jaarlijks heel wat tijd in beslag neemt. Daarom willen we de aangifte vanaf dit jaar effici&euml;nter aanpakken. We hebben hiervoor een flow voorzien waar onze virtuele assistent je door zal loodsen.</span></p><br />
				<p><span style="font-weight: 400;">Aan de hand van een chatgesprek met onze virtuele assistent kan je namelijk alle gegevens en documenten </span><b>makkelijk</b><span style="font-weight: 400;"> &eacute;n vooral </span><b>veilig</b><span style="font-weight: 400;"> aan ons aanleveren. Dat bespaart je heel wat mailtjes, en zorgt ervoor dat we samen de aangifte veel sneller kunnen afhandelen.&nbsp;</span></p><br />
				<p><span style="font-weight: 400;">Nadat je bent ingelogd in het systeem, zal je zelfs je gekende informatie zien van de aangifte van vorig jaar. Op die manier hoef je enkel te bevestigen of alles nog klopt, en kan je aanvullen waar nodig. Is er geen data van vorig jaar? Geen probleem, vul de flow dit jaar volledig in en volgend jaar zal je enkel nog de wijzigingen moeten doorgeven.</span></p><br />
				<p><span style="font-weight: 400;">Je kan het chatgesprek starten via deze beveiligde <a href="{{flow_link}}">link</a>.</span><span style="font-weight: 400;">
				</span><span style="font-weight: 400;">
				</span><span style="font-weight: 400;">Mocht je vragen hebben over je personenbelasting zijn we uiteraard nog steeds even graag persoonlijk te bereiken. De tool die we hebben voorzien dient om jou te ontzorgen, en zeker niet om ons persoonlijk contact weg te nemen.&nbsp;</span></p><br />
				<p><span style="font-weight: 400;">Alvast bedankt om de flow samen met onze virtuele assistent in te vullen! </span></p><body/><html/>`,
		},
		{
			id: "65567c114e7254410e97e96f",
			type: typesConfig.templateTypes.reminding,
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2024.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2024.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style="font-family: \'Open Sans\';"><p><span style="font-weight: 400;">Dag {{contact.firstName}}</span></p><br />
				<p><span style="font-weight: 400;">Een tijdje geleden ontving je van ons een uitnodiging om een vragenlijst in te vullen voor de aangifte van je personenbelasting. Ik wil je er vriendelijk aan herinneren om deze zeker in te vullen zodat we je aangifte zeker tijdig kunnen indienen.</span></p><br />
				<p><span style="font-weight: 400;">Je kan het chatgesprek voor de aangifte opstarten via deze beveiligde <a href="{{flow_link}}">link</a>.</span><span style="font-weight: 400;"></span><span style="font-weight: 400;"><br />
				</span><span style="font-weight: 400;">Mocht je vragen hebben over je personenbelasting zijn we uiteraard nog steeds even graag persoonlijk te bereiken. De tool die we hebben voorzien dient om jou te ontzorgen, en zeker niet om ons persoonlijk contact weg te nemen.&nbsp;</span></p><br />
				<p><span style="font-weight: 400;">Alvast bedankt om de flow samen met onze virtuele assistent in te vullen! </span></p><body/><html/>`,
		},
		// NEW
		{
			id: "63404bb248d9050e116732ab",
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.EENVOUDIG_FACTUREREN.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.EENVOUDIG_FACTUREREN.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style=\"font-family:'Open Sans';\"><p>Dag {{contact.firstName}},</p>
				<p>Om het facturatiepakket EenvoudigFactureren beter te leren kennen, hebben we een flow voorzien waarin onze virtuele assistent je stap voor stap door het pakket begeleidt.</p>
				<p>In het chatgesprek worden alle basisfuncties van het pakket toegelicht aan de hand van praktische video’s.</p>
				<p>Op die manier kan je het pakket op eigen tempo leren kennen, en kan je daarna ook steeds terugkeren naar de flow om enkele zaken te herbekijken als je dat wil.</p>
				<p>Je kan de opleiding starten via deze beveiligde link: <a href="{{flow_link}}">link</a></p>
				<p>Alvast bedankt om de flow samen met onze virtuele assistent te doorlopen!</p>
				<p>Mocht je verder nog vragen hebben, helpen wij jou uiteraard met alle plezier persoonlijk verder.</p><body/></html>`,
		},
		{
			id: "60fa00a048273a94d193d657",
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.YUKI_OPLEIDING.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.YUKI_OPLEIDING.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style=\"font-family:'Open Sans';\"><p>Dag {{contact.firstName}},</p>
				<p>Om het boekhoudpakket Yuki beter te leren kennen, hebben we een flow voorzien waarin onze virtuele assistent je stap voor stap begeleidt.</p>
				<p>In het chatgesprek worden alle basisfuncties van het pakket toegelicht aan de hand van enkele praktische video’s.</p>
				<p>Op die manier kan je dit pakket op eigen tempo leren kennen, en kan je daarna ook steeds terugkeren naar de flow om zaken te herbekijken als je dat wil.</p>
				<p>Je kan de opleiding starten via deze beveiligde link: <a href="{{flow_link}}">link</a></p>
				<p>Alvast bedankt om de flow samen met onze virtuele assistent te doorlopen!</p>
				<p>Mocht je verder nog vragen hebben, helpen wij jou uiteraard met alle plezier persoonlijk verder.</p><body/></html>`,
		},
		{
			id: "5c41ee82c5b2762f2b2f2b32",
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.ADRESWIJZIGING.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.ADRESWIJZIGING.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style=\"font-family:'Open Sans';\"><p>Dag {{contact.firstName}},</p>
				<p>Om de administratie voor je adreswijziging zo vlot mogelijk te laten verlopen, hebben we een flow voorzien waarin onze virtuele assistent je begeleidt bij het doorgeven van je gegevens.</p>
				<p>Kan je de online vragenlijst even invullen voor ons? Je kan het chatgesprek starten via deze beveiligde link: <a href="{{flow_link}}">link</a></p>
				<p>Alvast bedankt om de flow samen met onze virtuele assistent in te vullen! Aarzel niet om ons te contacteren bij verdere vragen.</p><body/></html>`,
		},
		{
			id: "5c41ee82c5b2762f2b2f6f46",
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.BEDRIJFSWAGEN.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.BEDRIJFSWAGEN.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style=\"font-family:'Open Sans';\"><p>Dag {{contact.firstName}},</p>
				<p>Om de inschrijving van je bedrijfswagen zo vlot mogelijk te laten verlopen, hebben we een flow voorzien waarin onze virtuele assistent je begeleidt bij het doorgeven van de gegevens.</p>
				<p>Kan je de online vragenlijst even invullen voor ons? Je kan het chatgesprek starten via deze beveiligde link: <a href="{{flow_link}}">link</a></p>
				<p>Alvast bedankt om de flow samen met onze virtuele assistent in te vullen! Contacteer ons gerust bij verdere vragen.</p><body/></html>`,
		},
		{
			// Nieuwe vennoot
			id: "5c41ee82c5b2762f2b9f5b28",
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.NIEUWE_VENNOOT.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.NIEUWE_VENNOOT.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style=\"font-family:'Open Sans';\"><p>Dag {{contact.firstName}},</p>
				<p>Om de administratie voor jou als vennoot zo vlot mogelijk te laten verlopen, hebben we een flow voorzien waarin onze virtuele assistent je begeleidt bij het doorgeven van je gegevens.</p>
				<p>Kan je de online vragenlijst even invullen voor ons? Je kan het chatgesprek starten via deze beveiligde link: <a href="{{flow_link}}">link</a></p>
				<p>Alvast bedankt om de flow samen met onze virtuele assistent in te vullen! Neem gerust contact op bij verdere vragen.</p><body/></html>`,
		},
		{
			// ONBOARDING
			id: "5c41ee82c5b2762f2b2f4b23",
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.ONBOARDING.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.ONBOARDING.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style=\"font-family:'Open Sans';\"><p>Dag {{contact.firstName}},</p>
				<p>Om de onboarding bij ons kantoor zo vlot mogelijk te laten verlopen, hebben we een flow voorzien waarin onze virtuele assistent je begeleidt bij het doorgeven van je gegevens.</p>
				<p>Kan je de online vragenlijst even invullen voor ons? Zo ben je in no-time opgestart bij ons als nieuwe klant.</p>
				<p>Je kan het chatgesprek starten via deze beveiligde link: <a href="{{flow_link}}">link</a></p>
				<p>Alvast bedankt om de flow samen met onze virtuele assistent in te vullen! Neem gerust contact op bij verdere vragen.</p><body/></html>`,
		},
		{
			// Tevredenheidsenquête
			id: "5c41ee82c5b2762f2b1f4a26",
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.TEVREDENHEIDSENQUETE.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.TEVREDENHEIDSENQUETE.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style=\"font-family:'Open Sans';\"><p>Dag {{contact.firstName}},</p>
				<p>We zijn erg benieuwd naar je mening over de samenwerking met ons kantoor.</p>
				<p>Wil je je tevredenheid en/of zorgen even met ons delen? Op die manier kunnen we onze dienstverlening steeds verbeteren.</p>
				<p>Je kan de enquête starten via deze beveiligde link: <a href="{{flow_link}}">link</a></p>
				<p>Alvast bedankt om de flow samen met onze virtuele assistent in te vullen!</p><body/></html>`,
		},
		{
			//Kennismakingsgesprek
			id: "5c41ee82c5b2762f3a4f4b25",
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.KENNISMAKINGSGESPREK.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.KENNISMAKINGSGESPREK.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style=\"font-family:'Open Sans';\"><p>Dag {{contact.firstName}},</p>
				<p>Om ons kennismakingsgesprek voor te bereiden, hebben we een flow voorzien waarin onze virtuele assistent enkele gegevens van je opvraagt.</p>
				<p>Kan je de online vragenlijst even invullen voor ons? Zo heb je in no-time een kennismakingsgesprek ingeboekt bij ons.</p>
				<p>Je kan het chatgesprek starten via deze beveiligde link: <a href="{{flow_link}}">link</a></p>
				<p>Alvast bedankt om de flow samen met onze virtuele assistent in te vullen!</p><body/></html>`,
		},
		{
			//EID
			id: "6176baddcdf3770011f56f2d",
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.EID.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.EID.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style=\"font-family:'Open Sans';\"><p>Dag {{contact.firstName}},</p>
				<p>Volgens ons systeem is je eID (je elektronische identiteitskaart) verlopen.</p>
				<p>Om de administratie van je nieuwe eID zo vlot mogelijk te laten verlopen, hebben we een flow voorzien waarin onze virtuele assistent je stap voor stap zal begeleiden.</p>
				<p>Kan je de online vragenlijst even invullen voor ons? Je kan het chatgesprek starten via deze beveiligde link: <a href="{{flow_link}}">link</a></p>
				<p>Alvast bedankt om de flow samen met onze virtuele assistent in te vullen! Contacteer ons gerust bij verdere vragen.</p><body/></html>`,
		},
		{
			// Sollicitatiegesprek
			id: "6214f8fc0a39e7001238ee01",
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.SOLLICITATIEGESPREK.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.SOLLICITATIEGESPREK.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style=\"font-family:'Open Sans';\"><p>Dag {{contact.firstName}},</p>
				<p>Om je sollicitatie zo vlot mogelijk te laten verlopen, hebben we een flow voorzien waarin onze virtuele assistent enkele gegevens van je opvraagt.</p>
				<p>Kan je de online vragenlijst even invullen voor ons? Aan de hand van je antwoorden nodigen we je uit voor een eerste gesprek.</p>
				<p>Je kan het chatgesprek starten via deze beveiligde link: <a href="{{flow_link}}">link</a></p>
				<p>Alvast bedankt voor de interesse in een job bij ons kantoor. Neem gerust contact op bij verdere vragen.</p><body/></html>`,
		},
		{
			// Kilometerstand
			id: "638a394dbbeef6401c06c022",
			title: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.KILOMETERSTAND.TITLE",
			description: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.KILOMETERSTAND.DESCRIPTION",
			content: `<html><head><meta charset="utf-16"></head><body style=\"font-family:'Open Sans';\"><p>Dag {{contact.firstName}},</p>
				<p>Om de jaarlijkse kilometerstand van je bedrijfswagens door te geven, hebben we een flow voorzien waarin je per bedrijfswagen de nodige gegevens kan invullen.</p>
				<p>Je kan de flow starten via deze beveiligde link: <a href="{{flow_link}}">link</a></p>
				<p>Alvast bedankt om de flow in te vullen. Contacteer ons gerust bij verdere vragen.</p><body/></html>`,
		},
	],
};
