import "./style.scss";

import { EditOutlined } from "@ant-design/icons";
import { Button, Input, Table } from "antd";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

type EditorTableParams = {
	columns: {
		key: string;
		title: string;
		dataIndex: string;
	}[];
	dataSource: Record<string, any>[]; // TODO improve typing
	onChange: (params: { id: string; data: Record<string, any> }) => void;
};
export default function EditorTable({ dataSource, columns, onChange }: EditorTableParams) {
	const [activeRow, setActiveRow] = useState<string | undefined>(undefined);
	const [rowChanges, setRowChanges] = useState<Record<string, any> | undefined>(undefined);

	const columnData = useMemo(() => {
		if (activeRow === undefined) return columns;

		return columns.map((value) => ({
			...value,
			render: (text, record) => {
				if (activeRow === record.id) {
					return (
						<Input
							value={rowChanges?.[value.dataIndex]}
							onChange={(event) => handleEditChange({ key: value.dataIndex, value: event.target.value })}
						/>
					);
				}

				return <span>{text}</span>;
			},
		}));
	}, [columns, rowChanges, activeRow]);

	const handleEditChange = ({ key, value }: { key: string; value: string }) => {
		setRowChanges((prev) => ({ ...(prev || {}), [key]: value } as Record<string, any>));
	};
	const handleCancelEdit = () => {
		setRowChanges(undefined);
		setActiveRow(undefined);
	};
	const handleUpdateEdit = () => {
		if (!activeRow || !rowChanges) return;

		const { onCell, ...data } = rowChanges;

		onChange({ id: activeRow, data });
		setActiveRow(undefined);
		setRowChanges(undefined);
	};

	return (
		<Table
			bordered
			rowKey="id"
			columns={columnData}
			data-expanded={!!activeRow}
			className="contact_import-editor_table"
			dataSource={dataSource.map((item) => ({
				...item,
				onCell: (record) => ({ record, editing: activeRow === item.id }),
			}))}
			pagination={
				dataSource?.length > 5 && {
					onChange: () => setActiveRow(undefined),
					pageSizeOptions: [5, 10, 20, 50, 100],
					showSizeChanger: true,
					showQuickJumper: true,
					defaultPageSize: 5,
					simple: false,
				}
			}
			onRow={(record: Record<string, any>) => ({
				onClick: () => {
					if (activeRow) return;

					setRowChanges(record);
					!activeRow && setActiveRow(record?.id);
				},
			})}
			expandable={{
				expandedRowKeys: activeRow ? [activeRow] : undefined,
				expandIcon: ({ expanded }) => <EditOutlined disabled={!!activeRow && !expanded} />,
				expandedRowRender: () => (
					<div className="contact_import-editor_table-row_footer">
						<Button type="text" size="small" onClick={handleCancelEdit}>
							<FormattedMessage
								id="contact.import.editor_table.row_footer.cancel"
								defaultMessage="Cancel"
							/>
						</Button>
						<Button size="small" type="primary" onClick={handleUpdateEdit}>
							<FormattedMessage id="contact.import.editor_table.row_footer.save" defaultMessage="Save" />
						</Button>
					</div>
				),
			}}
			rowClassName={(record: Record<string, any>) => {
				let className: string[] = [];

				if ((!activeRow || activeRow !== record.id) && record.warnings?.hasWarnings) {
					className.push("contact_import-editor_table-row-warning");
				}
				if ((!activeRow || activeRow !== record.id) && record.errors?.hasError) {
					className.push("contact_import-editor_table-row-error");
				}
				if (activeRow && activeRow !== record.id) {
					className.push("contact_import-editor_table-row-disabled");
				}
				if (activeRow && activeRow === record.id) {
					className.push("contact_import-editor_table-row-active");
				}

				return className.join(" ");
			}}
		/>
	);
}
