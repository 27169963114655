interface IPageHeader {
	title: string;
	description?: string;
	className?: string;
}
export default function PageHeader({ title, description, className = "" }: IPageHeader) {
	return (
		<header className={`connect_channels-content-header ${className}`}>
			<h1>{title}</h1>
			{description && <p style={{ maxWidth: "var(--width-prose)" }}>{description}</p>}
		</header>
	);
}
