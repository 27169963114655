import Aside from "@/shared/components/aside";
import { actions as dashboardActions } from "@/store/dashboard";
import { ContactsOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import ContactsOverview from "./overview";
import ArchivedContactsOverview from "./overview_archived";

export default function Contacts() {
	const { team } = useParams<{ team: string }>();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(dashboardActions.ui.foldAside());

		return () => {
			dispatch(dashboardActions.ui.unfoldAside());
		};
	}, []);

	return (
		<section style={{ display: "flex", overflowY: "hidden", minHeight: "100vh", width: "100%" }}>
			<Aside
				items={[
					{
						id: "overview",
						to: `/${team}/contact`,
						icon: <ContactsOutlined style={{ fontSize: "18px" }} />,
						label: "CONTACTS.OVERVIEW.ASIDE.OVERVIEW",
					},
					{
						id: "archive",
						to: `/${team}/contact/archive`,
						icon: <FolderOpenOutlined style={{ fontSize: "18px" }} />,
						label: "CONTACTS.OVERVIEW.ASIDE.ARCHIVE",
					},
				]}
			/>
			<Switch>
				<Route exact path="/:team/contact" component={ContactsOverview} />
				<Route exact path="/:team/contact/archive" component={ArchivedContactsOverview} />
				<Route render={() => <Redirect to={`/${team}/contact`} />} />
			</Switch>
		</section>
	);
}
