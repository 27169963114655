import "./style.scss";

import { Badge, Button, Card, Divider, Empty, notification } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { icon } from "../../../assets/images";
import NotificationItem from "../../../shared/components/header/dashboardHeader/components/NotificationItem";
import ListSkeleton from "../../../shared/components/header/dashboardHeader/components/NotificationItem/Skeleton";
import { trpc } from "../../../trpc";

export default function NotificationsCard() {
	const intl = useIntl();
	const history = useHistory();
	const trpcUtils = trpc.useUtils();
	const { team } = useParams<{ team: string }>();

	const {
		data: notificationData,
		isLoading,
		fetchNextPage,
		isInitialLoading,
		isFetchingNextPage,
	} = trpc.notification.notifications.getNotifications.useInfiniteQuery(
		{ isRead: false },
		{
			getNextPageParam: (data): { _id: string; createdAt: Date | undefined } | null => {
				if (!data || !data?.data?.length) return null;
				const latest = data?.data?.[data?.data?.length - 1];
				return { _id: latest._id, createdAt: latest.createdAt };
			},
			keepPreviousData: true,
		}
	);
	const markAsReadUnRead = trpc.notification.notifications.markAsReadUnRead.useMutation();
	const markAllAsRead = trpc.notification.notifications.markAllAsRead.useMutation();

	const total = notificationData?.pages?.[0]?.totalUnread || 0;
	const notifications = useMemo(
		() => notificationData?.pages.flatMap((page) => page.data) || [],
		[notificationData?.pages]
	);

	const allNotificationsFetched = notifications && notifications.length >= total && notifications.length !== 0;

	const handleNotificationClick = async (id: string) => {
		const notification = notifications.find((item) => item._id === id);

		if (!notification) return;

		try {
			switch (notification.type) {
				case "inbox.assigned_to_conversation":
				case "inbox.added_as_participant":
				case "inbox.sending_failed":
				case "inbox.mentioned":
				case "mention":
				case "assignConversation":
				case "conversationStarted":
					history.push(`/${team}/inbox/conversation/${notification.entityId || ""}`);
					break;
				case "flowAssign":
				case "flowReport":
					history.push(`/${team}/flows/${notification.meta?.flowId}/reports?report_id=${notification.link}`);
					break;
				default:
					break;
			}

			handleMarkAsReadUnread({ id, isRead: true });
		} catch (error) {
			console.error("Notification click failed", error);
		}
	};

	const handleMarkAsReadUnread = async ({ id, isRead }: { id: string; isRead: boolean }) => {
		await markAsReadUnRead.mutateAsync({ id, isRead });

		trpcUtils.notification.notifications.getNotifications.cancel();
		trpcUtils.notification.notifications.getNotifications.setInfiniteData({ isRead: false }, (prevInfiniteData) => {
			if (!prevInfiniteData) return;

			let clonedInfiniteData = structuredClone(prevInfiniteData);

			if (isRead) {
				for (let i = 0; i < prevInfiniteData.pages.length; i++) {
					clonedInfiniteData.pages[i] = {
						...prevInfiniteData.pages[i],
						totalUnread: Math.max(clonedInfiniteData.pages[0].totalUnread - 1, 0),
						total: Math.max(clonedInfiniteData.pages[0].total - 1, 0),
						data: prevInfiniteData.pages[i].data.filter((item) => item._id !== id),
					};
				}
			}

			return clonedInfiniteData;
		});
	};

	const handleMarkAllAsRead = async () => {
		try {
			await markAllAsRead.mutateAsync();

			trpcUtils.notification.notifications.getNotifications.cancel();

			trpcUtils.notification.notifications.getNotifications.setInfiniteData(
				{ isRead: false },
				(prevInfiniteData) => {
					if (!prevInfiniteData) return;

					let clonedInfiniteData = structuredClone(prevInfiniteData);

					for (let i = 0; i < prevInfiniteData.pages.length; i++) {
						clonedInfiniteData.pages[i] = {
							...prevInfiniteData.pages[i],
							data: prevInfiniteData.pages[i].data.map((item) => ({ ...item, isRead: true })),
						};
					}

					clonedInfiniteData.pages[0].totalUnread = 0;

					return clonedInfiniteData;
				}
			);

			notification.success({
				message: intl.formatMessage({
					id: "notification.mark_all_as_read.succeeded",
					defaultMessage: "All notifications have been mark as read",
					description: "Marking all notification as read succeeded",
				}),
				placement: "bottomRight",
			});
		} catch (_) {
			notification.error({
				message: intl.formatMessage({
					id: "notification.mark_all_as_read.failed",
					defaultMessage: "Something went wrong, please try again later",
					description: "Marking all notification as read failed for unknown reason",
				}),
				placement: "bottomRight",
			});
		}
	};

	return (
		<Card
			title={
				<Badge
					size="small"
					count={total}
					color="var(--secondary)"
					style={{ color: "var(--primary)", fontWeight: 600, fontSize: "9px" }}
					offset={[4, 0]}
					showZero={false}
				>
					<FormattedMessage id="dashboard.notification.title" defaultMessage="Unread notifications" />
				</Badge>
			}
			className="dashboard-notification"
			extra={
				total ? (
					<Button type="text" size="small" onClick={handleMarkAllAsRead} loading={markAllAsRead.isLoading}>
						<FormattedMessage
							id="dashboard.notification.header.mark_all_as_read"
							defaultMessage="Mark all as read"
						/>
					</Button>
				) : (
					[]
				)
			}
		>
			{!total && !isLoading && (
				<Empty
					description={intl.formatMessage({
						id: "dashboard.notification.empty.description",
						defaultMessage: "You're up-to-date 🎉 job well done time for some coffee",
					})}
					className="dashboard-notification-empty"
					image={icon.notificationsFallback}
				/>
			)}
			{isInitialLoading && (
				<div className="dashboard-notification-list">
					{/* @ts-ignore */}
					<AnimatePresence>
						{Array.from(Array(6).keys()).map((item, index) => (
							<motion.li
								key={`skeleton_${item}`}
								initial={{ opacity: 0, x: 20 }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0 }}
								style={{ width: "100%" }}
								transition={{ duration: 0.15, delay: index * 0.0125 }}
							>
								<ListSkeleton />
							</motion.li>
						))}
					</AnimatePresence>
				</div>
			)}
			{total > 0 && (
				<div className="dashboard-notification-container" id="notificationList">
					<InfiniteScroll
						scrollThreshold={0.6}
						next={() => fetchNextPage()}
						style={{ overflowX: "hidden" }}
						dataLength={notifications?.length || 0}
						hasMore={(notifications?.length || 0) < total}
						loader={isFetchingNextPage && <ListSkeleton />}
						endMessage={
							allNotificationsFetched && (
								<Divider plain>
									<FormattedMessage
										id="dashboard.notification.list.end_of_list"
										defaultMessage="All notifications are fetched 🔔"
										description="Message user has reached the end off the notifications list"
									/>
								</Divider>
							)
						}
						scrollableTarget="notificationList"
						className="dashboard-notification-list"
					>
						{/* @ts-ignore */}
						<AnimatePresence>
							{notifications.map((notification, index) => (
								<motion.li
									key={notification._id}
									initial={{ opacity: 0, x: 20 }}
									animate={{ opacity: 1, x: 0 }}
									exit={{ opacity: 0 }}
									style={{ width: "100%" }}
									transition={{ duration: 0.15, delay: index * 0.0125 }}
								>
									<NotificationItem
										key={notification._id}
										notification={notification}
										onClick={handleNotificationClick}
										onMarkAsReadUnRead={handleMarkAsReadUnread}
										organizationSlug={team}
									/>
								</motion.li>
							))}
						</AnimatePresence>
					</InfiniteScroll>
				</div>
			)}
		</Card>
	);
}
