import "./style.scss";

import { Alert, Button, Form, Input, Switch, notification } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilValue } from "recoil";

import { trpc } from "@/trpc";
import { PageHeader } from "../components";
import { pageType } from "../config";
import { channelSetupState } from "../state";

interface IConnectAction {
	onClick: (type: pageType) => void;
}
export default function SetupChannel({ onClick }: IConnectAction) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const trpcUtils = trpc.useUtils();
	const channelSetup = useRecoilValue(channelSetupState);
	const [conflictError, setConflictMessage] = useState(false);

	const createChannel = trpc.channel.createChannel.useMutation();

	const handleSubmit = async (value) => {
		try {
			await createChannel.mutateAsync({ ...channelSetup, ...value });
			trpcUtils.channel.getInboxChannels.invalidate();
			onClick("sync");
		} catch (error) {
			const httpError = error as { data: { httpStatus: number } };

			if (httpError.data.httpStatus === 409) {
				setConflictMessage(true);
				return;
			}

			notification.error({
				message: intl.formatMessage({
					id: "channels.connect_channel.setup_channel.create",
					defaultMessage: "Could not setup this channels. Please try again later",
					description: "Something went wrong on the server which failed to setup this channels",
				}),
				placement: "bottomRight",
			});
		}
	};

	return (
		<section className="connect_channels-body connect_channels-setup_channel">
			<PageHeader
				title={intl.formatMessage({
					id: "channels.connect_channel.setup_channel.title",
					defaultMessage: "Setup channels",
				})}
			/>
			{conflictError && (
				<Alert
					showIcon
					type="error"
					className="connect_channels-setup_channel-banner"
					message={intl.formatMessage({
						id: "channels.connect_channel.setup_channel.error.channel_already_connected",
						defaultMessage: "Channel is already connected to Bothive in this or another organization.",
						description:
							"Error warning when user tries to connect the channel multiple times to bothive if it's already connected in this organization or another Bothive organization.",
					})}
				/>
			)}
			<Form
				form={form}
				autoComplete="off"
				layout="vertical"
				requiredMark={false}
				onFinish={handleSubmit}
				initialValues={{ ...channelSetup, autoClose: true }}
				className="connect_channels-setup_channels-form"
			>
				<Form.Item
					name="name"
					className="connect_channels-setup_channels-form-name"
					label={intl.formatMessage({
						id: "channels.connect_channel.setup_channel.form.name.label",
						description:
							"Name user can give to their channel to make it recognizable. Default we will use the email address as name",
						defaultMessage: "Channel name",
					})}
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "channels.connect_channel.setup_channel.form.name.error.required",
								defaultMessage: "Channel is required",
							}),
						},
					]}
				>
					<Input size="large" placeholder={channelSetup.name} />
				</Form.Item>
				<Form.Item
					name="autoClose"
					valuePropName="checked"
					label={intl.formatMessage({
						id: "channels.connect_channel.setup_channel.form.auto_close.label",
						defaultMessage: "Auto close",
						description:
							"Auto close switch label, default turned on will automatic close all imported conversations that have been read",
					})}
					help={intl.formatMessage({
						id: "channels.connect_channel.setup_channel.form.auto_close.help",
						defaultMessage:
							"Automatically mark your viewed emails as completed so that you maintain a clear inbox",
						description:
							"Auto close switch help text, explain to user that when this toggle is turned on, during import all imported conversations that have been read will be closed. Turned on is the best way to experience Bothive",
					})}
					className="connect_channels-setup_channels-form-auto_close"
				>
					<Switch />
				</Form.Item>
				<Form.Item shouldUpdate className="connect_channels-setup_channels-form-submit">
					{(form) => {
						const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

						return (
							<Button
								type="primary"
								htmlType="submit"
								disabled={hasErrors}
								loading={createChannel.isLoading}
							>
								<FormattedMessage
									id="channels.connect_channel.setup_channel.form.submit"
									defaultMessage="Create channel"
								/>
							</Button>
						);
					}}
				</Form.Item>
			</Form>
		</section>
	);
}
