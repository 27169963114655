function isOpeningTimesSet({ team }) {
	return !Object.keys(team?.openingTimes?.default || []).filter((key) => team.openingTimes.default[key])?.length;
}

export default {
	isOpeningTimesSet,
	shouldShowSetup({ userPermissions, team }) {
		if (!userPermissions.superAdmin) {
			return false;
		}

		const isOpeningHoursEmpty = isOpeningTimesSet({ team });

		return !team.phoneNumber || !team.walkThrough || !team?.walkThrough.webDev || isOpeningHoursEmpty;
	},
	formatChangedFields(fields) {
		return fields.reduce(
			(prev, field) => ({
				...prev,
				[field.name[0]]: field.value,
			}),
			{}
		);
	},
	getMembersPreview({ team }) {
		return team.users
			.slice(0, 10)
			.reduce((prev, user) => (user.user?.avatar ? [...prev, user.user.avatar] : prev), []);
	},
};
