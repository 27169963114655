import actionTypes from "./permissions.actionTypes";

export const setTeamPermissions = (team) => (dispatch) => {
	if (!team || !team.permissions) {
		return;
	}

	dispatch({
		type: actionTypes.SET_TEAM_PERMISSIONS,
		data: team.permissions,
	});
};

export const setUserPermissions = (permissions) => (dispatch) => {
	if (!permissions) {
		return;
	}

	dispatch({
		type: actionTypes.SET_USER_PERMISSIONS,
		data: permissions,
	});
};
