import "./style.scss";

import { Typography } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

function addKeyCombinations(key) {
	return key.map((key, index) => (
		<>
			{index > 0 && <span className="inbox-shortcuts-combinder">+</span>}
			<kbd key={key} className="inbox-shortcuts-tag">
				{key.toUpperCase()}
			</kbd>
		</>
	));
}

export default ({ keys, label }) => {
	const groupKeys = keys?.map((key, index) => (
		<>
			{index > 0 && <span className="inbox-shortcuts-divider">/</span>}
			{addKeyCombinations(key)}
		</>
	));

	return (
		<Typography.Text className="inbox-shortcuts-row">
			{groupKeys}
			<span className="inbox-shortcuts-label">
				<FormattedMessage id={label} />
			</span>
		</Typography.Text>
	);
};
