import React from "react";

export default function AIcon({ type, value = "", className, active = true, state = "", ...props }) {
	const classType = `icon-${type} ${className} ${active ? "active" : ""}`;

	return <div {...props} className={classType} data-state={state} value={value} />;
}
AIcon.defaultProps = {
	type: "",
	state: undefined,
	className: "",
	value: "",
	active: false,
};
