import moment from "moment";
import { ReactNode } from "react";
import { FormattedMessage, IntlShape } from "react-intl";
import * as z from "zod";

import { defaultValue, typesConfig } from "../config";
import { isString, isMMDDYYYY, isMMDDYYYYInclTime, isIsoDateTime } from "./validators.helpers";

function formatTimeToHH(time) {
	if (time.toString().length === 2) {
		return time.toString();
	}

	return `0${time}`;
}

function formatMilliseconds(milliseconds) {
	const value = milliseconds / 1000;
	const seconds = Math.round(value);
	const hour = Math.floor(Number(seconds) / 3600);
	const minute = Math.floor((Number(seconds) % 3600) / 60);

	return {
		hour: formatTimeToHH(hour),
		minute: formatTimeToHH(minute),
		seconds: formatTimeToHH(Number(seconds) % 60),
	};
}

function formatMinutes(minutes) {
	let hour = Math.floor(Number(minutes) / 60);
	const minute = Math.floor(Number(minutes) % 60);

	hour = hour > 12 ? hour - 12 : hour;

	return `${formatTimeToHH(hour)}:${formatTimeToHH(minute)}`;
}

export default {
	mapValueToControl({ values, controls }) {
		if (!values) {
			return controls;
		}

		let value = controls.value;

		for (const key in values) {
			//eslint-disable-line no-unused-vars
			if (key in controls.controls) {
				value[key] = values[key];
			}
		}

		controls.setValue(value);

		return controls;
	},
	createAnalyticsLabel(values) {
		let formatted: { label: string; value: string | number }[] = [],
			allZero = true;

		values.forEach((value) => {
			const newDate = moment(value.date);

			if (allZero && value.value > 0) {
				allZero = false;
			}

			formatted.push({
				label: newDate.format("DD MMM"),
				value: Math.floor(value.value * 100) / 100, //round value
			});
		});

		return allZero ? [] : formatted;
	},
	createAnalyticsLabelForMonths(values) {
		return values.map((value) => {
			const newDate = moment(new Date(`${value._id.month}/01/${value._id.year}`));

			return {
				label: newDate.format("MMMM YYYY"),
				value: Math.floor(value.value * 100) / 100,
			};
		});
	},
	createAnalyticsErrorLabel({ error, percentage, total }) {
		if (total === 0) {
			return [];
		}

		return [
			{
				label: `Succesvol: ${total - error}`, //TODO make multilang
				value: +(100 - percentage).toFixed(2),
			},
			{
				label: `Onbeantwoord: ${error}`, //TODO make multilang
				value: +percentage.toFixed(2),
			},
		];
	},
	mapToDefaultList({ items, uuidKey = "_id", valueKey = "value" }) {
		if (!items) {
			return [];
		}

		return items.map((item) => ({
			uuid: item[uuidKey],
			value: item[valueKey],
		}));
	},
	mapToStructuredList({ items, uuidKey = "_id", valueKey = "value" }) {
		if (!items) {
			return [];
		}

		return items.map((item) => ({
			uuid: item[uuidKey],
			value: item[valueKey],
			type: typesConfig.cellTypes.string,
		}));
	},
	formatTimeToHH,
	formatSeconds(value) {
		const seconds = Math.round(value);
		const hour = Math.floor(Number(seconds) / 3600);
		const minute = Math.floor((Number(seconds) % 3600) / 60);

		return {
			hour: formatTimeToHH(hour),
			minute: formatTimeToHH(minute),
			seconds: formatTimeToHH(Number(seconds) % 60),
		};
	},
	formatMilliseconds,
	formatAnalyticsValueKey: ({ key, data }) =>
		data.map((item) => ({
			...item,
			...key.reduce(
				(prev, curr, index) => ({
					...prev,
					[curr]: item[`value${index + 1 === 1 ? "" : index + 1}`],
				}),
				{}
			),
		})),
	formatTypesForInput: (items) =>
		items.map((item) => ({
			id: item._id,
			label: item.value,
			value: item._id,
		})),
	formatToAmPm(hour) {
		const datePart = hour >= 12 ? "PM" : "AM";

		return `${hour > 12 ? hour - 12 : hour} ${datePart}`;
	},

	formatComposeEmail({ to, from, bcc, cc, attachments, subject, text, signature }) {
		let html = text;

		if (signature) {
			const threadIndex = html.indexOf('<div type="thread" class="bothive_thread">');

			if (threadIndex > 0) {
				// eslint-disable-next-line max-len
				html = `${html.substring(0, threadIndex)}\n<br /><br />\n${
					signature.content
				}\n<br /><br />\n${html.substring(threadIndex)}`;
			} else {
				html = `${html}\n<br /><br />\n${signature.content}`;
			}
		}

		return {
			to,
			from,
			bcc,
			cc,
			subject,
			text,
			attachments,
			payload: {
				html,
				template: typesConfig.payloadTypes.html,
			},
		};
	},

	createHoursRangeLabel(value) {
		const payload = value.reduce(
			(prev, curr, index) => ({
				...prev,
				[`timeSlot${index + 1}`]: formatMinutes(curr),
			}),
			{}
		);

		return {
			payload,
			label: `MOLECULES.HOURS_RANGE.${value.length}_TIME_SLOTS`,
		};
	},
	createWhatsAppLink(number) {
		const deserializedString = number.replace("whatsapp:", "");
		const firstNumber = deserializedString.slice(0, 2);
		const newNumber = firstNumber === "00" ? deserializedString.slice(2) : deserializedString;

		return `https://wa.me/${newNumber}`;
	},

	formatContact(sender) {
		const username = sender.username;
		const avatar = sender.avatar || defaultValue.inbox.siteUserAvatar;

		return { username, avatar };
	},

	// chat helpers
	//CLIENT
	clientInfo(client) {
		let title: string | ReactNode = <FormattedMessage id="FALLBACK.CONTACT.NAME" />;
		const avatar = client?.avatar ? client.avatar : defaultValue.inbox.siteUserAvatar;

		if (client?.firstName || client?.lastName) {
			title = [client?.firstName, client?.lastName].filter((item) => item).join(" ");
		} else if (client?.email) {
			title = client.email;
		}

		return { title, avatar };
	},
	createUsername({ contact, reverse }) {
		if (contact.username && !reverse) {
			return contact.username;
		}

		if (contact?.firstName || contact?.lastName) {
			let username = [contact?.firstName, contact?.lastName];

			if (reverse) {
				username = username.reverse();
			}

			return username.filter((item) => item).join(" ");
		}

		return contact.email;
	},
	formatJsonToCSV,
	mapJsonKeys,
	getRecipientsList({ messages, all }) {
		const lastMessage = messages[messages.length - 1];

		if (!lastMessage || !lastMessage.recipients?.length) {
			return {};
		}

		const fromRecipient = lastMessage.recipients.find(
			(recipient) => recipient.type === typesConfig.recipientType.from
		);
		const replyToAddress = lastMessage.recipients.reduce(
			(list, recipient) =>
				recipient.type === typesConfig.recipientType.replyTo ? [...list, recipient.address] : list,
			[]
		);
		const recipientsList = {
			to: replyToAddress?.length ? replyToAddress : [fromRecipient.address],
		};

		if (!all) {
			return recipientsList;
		}

		recipientsList.to = lastMessage.recipients.reduce(
			(prev, recipient) =>
				recipient.type === typesConfig.recipientType.to ? [...prev, recipient.address] : prev,
			recipientsList.to
		);

		// Map all cc and bcc fields and begin with to field as base
		return getCopyRecipientsAddressList({
			message: lastMessage,
			defaultValue: recipientsList,
		});
	},
	getRecipientAddressList({ recipients, type }) {
		return recipients.reduce(
			(prev, recipient) => (recipient.type === type ? [...prev, recipient.address] : prev),
			[]
		);
	},
	formatRecipientsToTypesList(recipients) {
		return recipients.reduce(
			(prev, recipient) => ({
				...prev,
				[recipient.type]: [...(prev[recipient.type] || []), recipient.address],
			}),
			{}
		);
	},

	formatBytes({ bytes, decimals = 2 }) {
		if (bytes === 0) {
			return "0 Bytes";
		}

		const byteOrder = 1024;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
		const bytePosition = Math.floor(Math.log(bytes) / Math.log(byteOrder));
		const fixByte = parseFloat((bytes / Math.pow(byteOrder, bytePosition)).toFixed(decimals));

		return `${fixByte} ${sizes[bytePosition]}`;
	},
};

function findField(fields, key) {
	const fallback = { key, fields: [] };

	if (!fields) return fallback;

	const field = fields.find((field) => field.key === key);

	return field || fallback;
}

function formatJsonToCSV({ data, fields, language, level = 0, parentField = {}, meta = {} }: any) {
	let keys = Object?.keys(data);

	const customType = (type) => [
		{
			value: data,
			options: fields.options,
			meta: { ...meta, level },
			type: parentField?.type || type,
			isRequired: parentField?.isRequired || false,
			key: parentField?.name?.[language] || parentField.key,
		},
	];

	if (data.url && data.extension && data.size) {
		// NOTE: I know this is dirty, but see no other solution unless we fully rework the field config flow.
		return customType(typesConfig.fields.fileArray);
	}

	if (typeof data === "string") {
		return customType(typesConfig.fields.string);
	}

	return keys.reduce((prev: any, key: any, index: any) => {
		const location = [meta?.location, key].filter((value) => value).join(".");

		const selectedField = findField(fields, key);

		let _meta: any = { level, location };

		let value = parseJsonData(data[key]);

		if (value && typeof value === "object") {
			let child;
			const _level = level + 1;

			if (Array.isArray(value)) {
				child = value.reduce<any[]>((prev, curr, index) => {
					const field = formatJsonToCSV({
						parentKey: `${key} ${index + 1 > 1 ? index + 1 : ""}`,
						parentField: selectedField,
						level: _level,
						data: curr,
						fields: selectedField?.fields,
						language,
						meta: {
							..._meta,
							location: `${_meta.location}[${index}]`,
						},
					});

					return [...prev, ...field];
				}, []);
			} else {
				child = formatJsonToCSV({
					data: value,
					fields: selectedField?.fields,
					language,
					meta: _meta,
					level: _level,
				});
			}

			return [...prev, ...child];
		}

		if (level && !index) {
			_meta.first = true;
		}

		if (level && index === keys.length - 1) {
			_meta.last = true;
		}

		return [
			...prev,
			{
				value,
				meta: _meta,
				options: fields.options,
				key: selectedField?.name?.[language] || key,
				type: selectedField?.type || typesConfig.fields.string,
				isRequired: selectedField?.isRequired || false,
			},
		];
	}, []);
}

function parseJsonData(data) {
	if (!data) {
		return data;
	}

	try {
		if (Number(data)) {
			return data;
		}

		return JSON.parse(data);
	} catch {
		return data;
	}
}

function mapJsonKeys({ data, prefix }) {
	if (!data) {
		return [];
	}

	// TODO: fix typing reduce (read ts-reset docs)
	return Object.keys(data).reduce<any[]>((prev: any, key: any) => {
		let value = parseJsonData(data[key]);

		const newKey = prefix ? [prefix, key].join(".") : key;

		if (value && typeof value === "object") {
			const child = !Array.isArray(value)
				? mapJsonKeys({ data: value, prefix: newKey })
				: value.reduce<any[]>(
						(prev, curr, index) => [...prev, ...mapJsonKeys({ data: curr, prefix: `${newKey}[${index}]` })],
						[newKey]
				  );

			return [...prev, ...child];
		}

		return [...prev, newKey];
	}, []);
}

function getCopyRecipientsAddressList({ message, defaultValue = {} }) {
	if (!message) {
		return {};
	}

	const allowedRecipientTypes = [typesConfig.recipientType.cc, typesConfig.recipientType.bcc];
	const initList = {
		[typesConfig.recipientType.cc]: [],
		[typesConfig.recipientType.bcc]: [],
		...defaultValue,
	};

	return message.recipients.reduce((list, recipient) => {
		if (!allowedRecipientTypes.includes(recipient.type)) {
			return list;
		}

		list[recipient.type].push(recipient.address);
		list[recipient.type] = [...new Set(list[recipient.type])];

		return list;
	}, initList);
}

export function formatNumberToOrdinal({ number, intl }: { number: number; intl: IntlShape }) {
	const formatter = new Intl.PluralRules("en-US", { type: "ordinal" });

	const suffixes = new Map([
		["one", "NUMBER.ORDINAL.ST"],
		["two", "NUMBER.ORDINAL.ND"],
		["few", "NUMBER.ORDINAL.RD"],
		["other", "NUMBER.ORDINAL.TH"],
	]);

	const rule = formatter.select(number);
	const suffix = intl.formatMessage({ id: suffixes.get(rule) });
	return `${number}${suffix}`;
}

const transformToLodashGetNotationObject = (obj: Object, prefix: string = "") => {
	const result = {};

	for (const [key, value] of Object.entries(obj)) {
		const newPrefix = prefix ? `${prefix}.${key}` : key;

		if (typeof value === "object" && value !== null) {
			Object.assign(result, transformToLodashGetNotationObject(value, newPrefix));
		} else {
			result[newPrefix] = value;
		}
	}

	return result;
};

export function formatArrOfObjectsToArrOfKeysNames(array: Record<string, string | number | Object>[]) {
	const transformedObjects = array.map((obj) => transformToLodashGetNotationObject(obj));

	// IMP: figure out if edited is a field from remapped contact (recoil state) we can delete?
	return [...new Set(transformedObjects.map((obj) => Object.keys(obj)).flat())].filter((item) => item !== "edited"); // ? filter out edited from recoil state
}
