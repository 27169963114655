import { typesConfig } from "../config";

export function getFlowDescription({ flow, language }) {
	const { widgetSettings, template } = flow;

	return {
		name: widgetSettings?.name || template?.name?.[language],
		description: widgetSettings?.description || template?.description?.[language],
		logo: widgetSettings?.logo || template?.logo,
	};
}

export function calculateProgress(progress) {
	switch (progress) {
		case typesConfig.progressState.created:
			return {
				percentage: 10,
				color: "#DE5050",
			};
		case typesConfig.progressState.pendingSend:
			return {
				percentage: 20,
				color: "#DE5050",
			};
		case typesConfig.progressState.inviteSend:
			return {
				percentage: 30,
				color: "#E47D26",
			};
		case typesConfig.progressState.inProgress:
			return {
				percentage: 50,
				color: "#F8C945",
			};
		case typesConfig.progressState.completed:
			return {
				percentage: 100,
				color: "#2EB81F",
			};
		default:
			return {
				percentage: 100,
				color: "#2EB81F",
			};
	}
}

export function compareReportProgress({ report, progress }) {
	const reportProgress = calculateProgress(report?.progress);
	const compareProgress = calculateProgress(progress);

	return reportProgress.percentage > compareProgress.percentage;
}

export default {
	getFlowDescription,
	calculateProgress,
	compareReportProgress,
};
