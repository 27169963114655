import "./style.scss";

import { MemberRouterOutput, trpc } from "@/trpc";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
	LexicalTypeaheadMenuPlugin,
	MenuOption,
	MenuTextMatch,
	useBasicTypeaheadTriggerMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { Avatar, Typography } from "antd";
import { LexicalCommand, TextNode, createCommand } from "lexical";
import { useCallback, useState } from "react";
import * as ReactDOM from "react-dom";

import { $createMentionNode } from "../nodes/mentioningNode";
import { keyEventsHelpers } from "../../../helpers";

const SUGGESTION_LIST_LENGTH_LIMIT = 5;
const PUNCTUATION = "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";
const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]";

const LENGTH_LIMIT = 75;
const ALIAS_LENGTH_LIMIT = 50; // 50 is the longest alias length limit.
const TRIGGERS = ["@"].join("");
const DocumentMentionsRegex = { NAME, PUNCTUATION };
const CapitalizedNameMentionsRegex = new RegExp("(^|[^#])((?:" + DocumentMentionsRegex.NAME + "{" + 1 + ",})$)");
const PUNC = DocumentMentionsRegex.PUNCTUATION;

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
	"(?:" +
	"\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
	" |" + // E.g. " " in "Josh Duck"
	"[" +
	PUNC +
	"]|" + // E.g. "-' in "Salier-Hellendag"
	")";
const AtSignMentionsRegex = new RegExp(
	"(^|\\s|\\()(" + "[" + TRIGGERS + "]" + "((?:" + VALID_CHARS + VALID_JOINS + "){0," + LENGTH_LIMIT + "})" + ")$"
);
// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
	"(^|\\s|\\()(" + "[" + TRIGGERS + "]" + "((?:" + VALID_CHARS + "){0," + ALIAS_LENGTH_LIMIT + "})" + ")$"
);

function checkForCapitalizedNameMentions(text: string, minMatchLength: number): MenuTextMatch | null {
	const match = CapitalizedNameMentionsRegex.exec(text);

	if (match === null) return null;
	// The strategy ignores leading whitespace but we need to know it's
	// length to add it to the leadOffset
	const maybeLeadingWhitespace = match[1];
	const matchingString = match[2];

	if (matchingString != null && matchingString.length >= minMatchLength) {
		return {
			leadOffset: match.index + maybeLeadingWhitespace.length,
			matchingString,
			replaceableString: matchingString,
		};
	}

	return null;
}

function checkForAtSignMentions(text: string, minMatchLength: number): MenuTextMatch | null {
	let match = AtSignMentionsRegex.exec(text);

	if (match === null) match = AtSignMentionsRegexAliasRegex.exec(text);

	if (match !== null) {
		// The strategy ignores leading whitespace but we need to know it's
		// length to add it to the leadOffset
		const maybeLeadingWhitespace = match[1];
		const matchingString = match[3];

		if (matchingString.length >= minMatchLength) {
			return {
				leadOffset: match.index + maybeLeadingWhitespace.length,
				matchingString,
				replaceableString: match[2],
			};
		}
	}

	return null;
}

function getPossibleMenuTextMatch(text: string): MenuTextMatch | null {
	const match = checkForAtSignMentions(text, 1);

	return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
}

class MentionOption extends MenuOption {
	userId: string;
	name: string;
	avatar: string;

	constructor(member: MemberRouterOutput["getAll"]["data"][number]) {
		super(member.name);
		this.name = member.name;
		this.userId = member.userId;
		this.avatar = member.avatar;
	}
}

export const MENTION_MENU_OPENED_COMMAND: LexicalCommand<void> = createCommand("MENTION_MENU_OPENED_COMMAND");
export const MENTION_MENU_CLOSED_COMMAND: LexicalCommand<void> = createCommand("MENTION_MENU_CLOSED_COMMAND");

export default function MentionsPlugin(): JSX.Element | null {
	const [editor] = useLexicalComposerContext();
	const [queryString, setQueryString] = useState<string | null>(null);
	const { data: members } = trpc.member.getAll.useQuery(
		{ q: queryString || undefined, limit: SUGGESTION_LIST_LENGTH_LIMIT },
		{
			enabled: Boolean(queryString?.length),
		}
	);

	const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch("/", { minLength: 0 });

	const onSelectOption = useCallback(
		(selectedOption: MentionOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
			editor.update(() => {
				const mentionNode = $createMentionNode({
					id: selectedOption.userId,
					name: selectedOption.name,
				});

				if (nodeToReplace) nodeToReplace.replace(mentionNode);

				mentionNode.select();
				closeMenu();
			});
		},
		[editor]
	);

	const checkForMentionMatch = useCallback(
		(text: string) => {
			const mentionMatch = getPossibleMenuTextMatch(text);
			const slashMatch = checkForSlashTriggerMatch(text, editor);

			return !slashMatch && mentionMatch ? mentionMatch : null;
		},
		[checkForSlashTriggerMatch, editor]
	);

	return (
		<LexicalTypeaheadMenuPlugin<MentionOption>
			onQueryChange={setQueryString}
			onSelectOption={onSelectOption}
			triggerFn={checkForMentionMatch}
			onOpen={() => editor.dispatchCommand(MENTION_MENU_OPENED_COMMAND, undefined)}
			onClose={() => editor.dispatchCommand(MENTION_MENU_CLOSED_COMMAND, undefined)}
			// @ts-ignore
			options={members?.data || []}
			menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => {
				if (!editor.isEditable()) return null;
				if (!anchorElementRef?.current || !members?.data?.length) return null;

				return ReactDOM.createPortal(
					<ul className="editor-mention_plugin-menu">
						{members.data.map((option, index: number) => (
							<li
								key={index}
								role="option"
								tabIndex={0}
								data-is-focussed={selectedIndex === index}
								className="editor-mention_plugin-menu-item"
								onKeyUp={(event) => {
									const member = members.data[index];

									if (!keyEventsHelpers.enterOrSpacePressed(event) || !member) return;

									setHighlightedIndex(index);
									selectOptionAndCleanUp(new MentionOption(member));
								}}
								onClick={() => {
									const member = members.data[index];

									if (!member) return;

									setHighlightedIndex(index);
									selectOptionAndCleanUp(new MentionOption(member));
								}}
							>
								<Avatar shape="square" size="small" src={option.avatar} />
								<Typography.Text>{option.name}</Typography.Text>
							</li>
						))}
					</ul>,
					anchorElementRef.current
				);
			}}
		/>
	);
}
