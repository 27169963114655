import "./style.scss";

import { CheckCircleFilled, CloseCircleFilled, CopyOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Layout } from "@/components/templates";
import settingsConfig from "@/modules/settings/config";
import { actions as dashboardActions } from "@/store/dashboard";
import { trpc } from "@/trpc";
import CreateApiKey from "./create_api_key";
import ApiKeyHeader from "./header";
import helpers from "./helpers";
import NoKeysFound from "./no_keys/index";
import ApiKeyNotAllowed from "./not_allowed";

import ShowSuccessBanner from "@/shared/components/banner/success";

export default function ApiKeyOverview({ className }: { className?: string }) {
	const intl = useIntl();
	const dispatch = useDispatch();
	const history = useHistory();
	const routeParameters = useParams<{ team: string }>();
	const [offset, setOffset] = useState<number>(1); // Offset needs to be minimum 1, otherwise Antd Table component can't function
	const [showCreateApiKey, toggleCreateApiKey] = useState<boolean>(false);
	const state = useSelector((state) => ({
		limit: state.dashboard.permissions.apiKeys,
		allowViewing: state.dashboard.permissions.userPermissions.editSettings,
	}));
	const { data: apiKeys, isLoading } = trpc.apiKeyManager?.getAll?.useQuery({ offset: offset - 1 }); // offset - 1 because we want 0

	useEffect(() => {
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					id: "apiKey",
					title: intl.formatMessage({
						id: "api_key_overview.page.title",
						defaultMessage: "API key overview",
					}),
					tabTitle: intl.formatMessage({
						id: "api_key_overview.page.title",
						defaultMessage: "API key overview",
					}),
					breadcrumbs: [
						{
							to: settingsConfig.routes.root.replace(":team", routeParameters.team),
							name: intl.formatMessage({
								id: "api_key_overview.header.breadcrumb.settings",
								defaultMessage: "Settings",
							}),
						},
						{
							to: settingsConfig.routes.apiKey.overview.replace(":team", routeParameters.team),
							name: intl.formatMessage({
								id: "api_key_overview.header.breadcrumb.title",
								defaultMessage: "API key overview",
							}),
						},
					],
				},
				intl,
			})
		);
	}, []);

	const handleCopyToClipboard = (event) => {
		event.stopPropagation();
		ShowSuccessBanner({
			title: intl.formatMessage({
				id: "banner.succeeded.copy_key",
				defaultMessage: "Successfully copied to clipboard",
			}),
		});
	};
	const handleRowClick = (record) => {
		const url = settingsConfig.routes.apiKey.detail
			.replace(":team", routeParameters.team)
			.replace(":apiKeyId", record.key);

		history.push(url);
	};
	const handlePagination = (pagination) => {
		setOffset(pagination.current * 20);
	};

	if (isLoading && state.allowViewing) {
		return <Layout.TLoadScreen className="sub-page" />;
	}

	if (!apiKeys?.meta.total) {
		return (
			<>
				<NoKeysFound onClick={() => toggleCreateApiKey(true)} />
				<CreateApiKey visible={showCreateApiKey} onClose={() => toggleCreateApiKey(false)} />
			</>
		);
	}

	function showTable() {
		if (apiKeys && apiKeys?.meta.total) {
			return (
				<Table
					className="t-gap--top"
					rowClassName="page-api-key-overview--row"
					dataSource={helpers.formatDataSource(apiKeys.data)}
					onRow={(record) => ({ onClick: () => handleRowClick(record) })}
					onChange={handlePagination}
					pagination={{
						current: offset,
						total: apiKeys.meta.total,
						pageSize: 20,
					}}
					loading={isLoading}
				>
					<Table.Column
						key="content"
						dataIndex="content"
						title={
							<FormattedMessage
								id="api_key_overview.table.header.description"
								defaultMessage="Description"
							/>
						}
						render={(content) => (
							<div className="page-api-key-overview--content">
								<Typography.Text strong>{content?.title}</Typography.Text>
								<Typography.Text>{content?.description}</Typography.Text>
							</div>
						)}
					/>
					<Table.Column
						key="apiKey"
						dataIndex="apiKey"
						title={<FormattedMessage id="api_key_overview.table.header.api_key" defaultMessage="API key" />}
						render={(value) => (
							<Space.Compact style={{ width: "100%" }} onClick={(event) => handleCopyToClipboard(event)}>
								<Input id="apiKey" value={value} readOnly style={{ cursor: "pointer" }} />
								<Button icon={<CopyOutlined />} type="primary"></Button>
							</Space.Compact>
						)}
					/>
					<Table.Column
						key="permissions"
						dataIndex="permissions"
						className="page-api-key-overview--total-permissions"
						title={
							<FormattedMessage
								id="api_key_overview.table.header.permissions"
								defaultMessage="Permissions"
							/>
						}
					/>
					<Table.Column
						key="createdAt"
						dataIndex="createdAt"
						title={
							<FormattedMessage
								id="api_key_overview.table.header.created_at"
								defaultMessage="Created at"
							/>
						}
						render={(value) => (
							<Typography.Text>
								<FormattedDate value={value} />
							</Typography.Text>
						)}
					/>
					<Table.Column
						key="isEnabled"
						dataIndex="isEnabled"
						title={
							<FormattedMessage
								id="api_key_overview.table.header.is_enabled"
								defaultMessage="Is enabled"
							/>
						}
						render={(value) => (
							<div className="m-table-cell--horizontal">
								{value ? (
									<CheckCircleFilled style={{ fontSize: "20px", color: "var(--success)" }} />
								) : (
									<CloseCircleFilled style={{ fontSize: "20px", color: "var(--error)" }} />
								)}
								<Typography.Text className="cell-body t-gap--left-xs">
									<FormattedMessage
										id={`table_cell.boolean.${value ? "active" : "inactive"}`}
										defaultMessage={value ? "Active" : "Inactive"}
									/>
								</Typography.Text>
							</div>
						)}
					/>
					<Table.Column
						key="edit"
						dataIndex="isEnabled"
						render={() => (
							<Button type="link">
								<EditOutlined style={{ fontSize: "1.5em" }} />
							</Button>
						)}
					/>
				</Table>
			);
		}
	}

	const isAllowedToCreateNewKeys = state.allowViewing && apiKeys.meta.total < state.limit;

	return (
		<section className={`page-settings--content page-api-key-overview ${className}`}>
			<ApiKeyHeader isDisabled={!isAllowedToCreateNewKeys} onClick={() => toggleCreateApiKey(true)} />
			{showTable()}
			{!state.allowViewing && <ApiKeyNotAllowed />}
			<CreateApiKey visible={showCreateApiKey} onClose={() => toggleCreateApiKey(false)} />
		</section>
	);
}
