import "./style.scss";

import { notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMemo, useRef } from "react";
import { FormattedMessage, MessageDescriptor, defineMessage, useIntl } from "react-intl";

import { Component } from "../../../../components/atoms";
import { fileConfig } from "../../../../config";
import {
	allowFileDrop,
	imageUploadWithPreview,
	IImageUploadResult,
	preventFileDragOver,
	IFileErrorCode,
} from "../../../helpers/file.helpers";

export interface ISegmentConfig {
	error?: MessageDescriptor;
	placeholder?: MessageDescriptor;
	overlay?: MessageDescriptor;
}
interface IImageSegment {
	id: string;
	value: string;
	error?: boolean;
	loading?: boolean;
	required?: boolean;
	disabled?: boolean;
	className?: string;
	config?: ISegmentConfig;
	onChange?: (value: IImageUploadResult) => void;
}

const defaultConfig = {
	error: defineMessage({
		id: "image_upload.upload_failed",
		defaultMessage: "Upload failed",
	}),
	placeholder: defineMessage({
		id: "image_upload.pick_a_image",
		defaultMessage: "Choose a image",
	}),
	overlay: defineMessage({
		id: "image_upload.choose_other_image",
		defaultMessage: "Choose a other image",
	}),
};

export default function ImageSegment({
	id,
	value,
	error,
	loading,
	required,
	disabled,
	className,
	onChange,
	config = defaultConfig,
}: IImageSegment) {
	const intl = useIntl();
	const fieldRef = useRef<HTMLInputElement>(null);
	const [api, contextHolder] = notification.useNotification();
	const state = useMemo(() => {
		// Don't re-order if's else functionality wont work correctly
		if (loading) return "loading";
		if (error) return "error";
		if (value) return "set";

		return "init";
	}, [error, loading, value]);

	const _config = { ...defaultConfig, ...config };

	const handleChange = () => {
		const file = fieldRef.current?.files;

		if (!file?.length) return;

		uploadImage(file);
	};
	const uploadImage = async (file: FileList | undefined) => {
		try {
			if (!file?.[0]) return;

			const result = await imageUploadWithPreview({ file: file[0] });

			if (!result || !onChange || !fieldRef.current) return;

			onChange(result);
			fieldRef.current.value = ""; //Allow re-upload same image
		} catch (error) {
			const err = error as { code: IFileErrorCode };
			let message = {
				message: intl.formatMessage({
					id: "image_upload.error.unexpected.title",
					defaultMessage: "Unexpected error",
				}),
				description: intl.formatMessage({
					id: "image_upload.error.unexpected.description",
					defaultMessage: "We currently cannot upload your image, please try again later.",
				}),
			};

			if (err.code === "FILE_SIZE_TO_BIG") {
				message = {
					message: intl.formatMessage({
						id: "image_upload.error.file_size.title",
						defaultMessage: "File size is to big",
					}),
					description: intl.formatMessage(
						{
							id: "image_upload.error.file_size.description",
							defaultMessage: "The file you tried to upload is bigger then {size}",
						},
						{ size: "10MB" }
					),
				};
			}
			if (err.code === "INVALID_TYPE") {
				message = {
					message: intl.formatMessage({
						id: "image_upload.error.invalid_type.title",
						defaultMessage: "File type not supported",
					}),
					description: intl.formatMessage(
						{
							id: "image_upload.error.invalid_type.description",
							defaultMessage:
								"The file you tried to upload is not supported. we only support the following types {types}",
						},
						{ types: fileConfig.fileTypes.image.join(", ") }
					),
				};
			}

			api.error({ ...message, placement: "bottomRight" });
		}
	};

	if (!disabled) {
		preventFileDragOver({ selector: "body" });
		allowFileDrop({ selector: "body", callback: uploadImage });
	}

	return (
		<label
			htmlFor={id}
			data-state={state}
			data-disabled={disabled}
			id={`labelImageUpload-${id}`}
			className={`upload-image_segment ${className}`}
		>
			{contextHolder}
			<figure className="upload-image_segment-preview">
				<img src={value} alt="upload preview" />
				<figcaption className="upload-image_segment-preview-overlay">
					<FormattedMessage {..._config.overlay} />
				</figcaption>
			</figure>
			{!disabled && (
				<p className="upload-image_segment-placeholder">
					<FormattedMessage {..._config.placeholder} />
				</p>
			)}
			<p className="upload-image_segment-error_message">
				<ExclamationCircleOutlined />
				<FormattedMessage {..._config.error} />
			</p>
			<Component.AIcon type="loader--inner" className="upload-image_segment-loader" />
			<input
				id={id}
				type="file"
				ref={fieldRef}
				disabled={disabled}
				aria-disabled={disabled}
				required={required}
				aria-required={required}
				onChange={handleChange}
				accept="image/*"
			/>
		</label>
	);
}

ImageSegment.defaultProps = {
	error: false,
	loading: false,
	required: false,
	disabled: false,
	value: "",
	className: "",

	onChange: undefined,
	onUploading: undefined,
};
