import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import { selectedFlowIdState } from "@/flows/common/state";

import config from "./config";
import { getFlowReports } from "./query";
import {
	isFetchingReportsState,
	reportsList,
	reportsMeta,
	searchFilterState,
	selectedProgressState,
	selectedStatusTypeState,
	selectedContactLabelState,
	selectedAccountManagerState,
} from "./state";

export function useReportList() {
	const { flowId } = useParams();

	// const { search } = useLocation();
	// const queryParams = new URLSearchParams(search);
	const [hookError, setHookError] = useState(undefined);
	const [hookState, setHookState] = useState("hasValue");
	const searchFilter = useRecoilValue(searchFilterState);
	const selectedFlowId = useRecoilValue(selectedFlowIdState);
	const [reports, setReportsList] = useRecoilState(reportsList);
	const selectedProgress = useRecoilValue(selectedProgressState);
	const [isFetchingNewFlow, setIsFetchingNewFlow] = useState(false);
	const selectedStatusType = useRecoilValue(selectedStatusTypeState);
	const [reportMetaData, setReportMetaData] = useRecoilState(reportsMeta);
	const selectedAccountManager = useRecoilValue(selectedAccountManagerState);
	const selectedContactLabel = useRecoilValue(selectedContactLabelState);
	const [isFetchingReports, setIsFetchingReports] = useRecoilState(isFetchingReportsState);

	useEffect(() => {
		fetchReportsList();
	}, [selectedFlowId, searchFilter, selectedStatusType, selectedContactLabel, selectedProgress, selectedAccountManager]);

	const fetchReportsList = async ({ limit, offset = 0, sort } = { limit: config.fetchLimit }) => {
		if (isFetchingReports) return;

		try {
			const searchParams = {
				limit,
				offset,
				q: searchFilter || "", // is required for isQueryParametersChanged otherwise it will not trigger a re-fetch when clearing search input
				flowId: selectedFlowId,
				sort: sort || undefined,
				status: selectedStatusType || "",
				progress: selectedProgress || "",
				accountManager: selectedAccountManager,
				contactLabel: selectedContactLabel || undefined,
			};

			if (reportMetaData?.flowId !== flowId) {
				setIsFetchingNewFlow(true);
			}

			setIsFetchingReports(true);
			setHookState("loading");
			setHookError(undefined);

			const result = await getFlowReports(searchParams);

			setHookState("hasValue");
			setReportsList(result.data);
			setReportMetaData({ ...result.meta, ...searchParams });
			setIsFetchingNewFlow(false);
			setIsFetchingReports(false);
		} catch (error) {
			setHookState("hasError");
			setHookError(error);
			setIsFetchingNewFlow(false);
			setIsFetchingReports(false);
		}
	};

	return [
		{
			state: hookState,
			contents: hookError || reports,
			isFetchingNewFlow,
		},
		fetchReportsList,
	];
}
