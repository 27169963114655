import type { IContact, IContactSyncRule } from "@bothive_core/database";
import * as z from "zod";

// IMP: improve keys by extracting type from zod schema
type ContactFieldByType = {
	string?: (keyof z.infer<typeof contactImportValidationSchema>)[];
	date?: (keyof z.infer<typeof contactImportValidationSchema>)[];
	number?: (keyof z.infer<typeof contactImportValidationSchema>)[];
	boolean?: (keyof z.infer<typeof contactImportValidationSchema>)[];
};

// ! Update this if the beneath function updates. So we can inform our users about the latest supported date formats
// if you make a function that converts eu dates to our supported dates, make sure to mention this to the end user
export const supportedCSVDateFormats = [
	"MM/DD/YYYY",
	"MM/DD/YYYY HH:MM:SS",
	"MM-DD-YYYY",
	"MM-DD-YYYY HH:MM:SS",
	"YYYY-MM-DD",
	"YYYY-MM-DDTHH:MM:SS",
	"YYYY-MM-DDTHH:MM:SSZ",
	"YYYY-MM-DDTHH:MM:SS±HH:MM",
];

const zodDateCustom = z.coerce
	.date({ description: "date" })
	// Accepts mm/dd/yyyy hh:mm:ss and mm-dd-yyyy hh:mm:ss
	.or(z.string().regex(/^\d{2}[-/]\d{2}[-/]\d{4} \d{2}:\d{2}:\d{2}$/))
	.transform((value) => {
		if (typeof value === "string") {
			const date = new Date(value);
			if (isNaN(date.getTime())) {
				// If the string is not in a valid date format, throw an error
				throw new Error(`Invalid date string: ${value}`);
			}
			return date;
		}
		// If the value is already a date object, return it
		return value;
	});

export const contactImportValidationSchema = z.object({
	firstName: z.string({ description: "string" }).optional(),
	lastName: z.string({ description: "string" }).optional(),
	name: z.string({ description: "string" }).optional(),
	middleName: z.string({ description: "string" }).optional(),
	fullName: z.string({ description: "string" }).optional(),
	salutation: z.string({ description: "string" }).optional(),
	avatar: z.string({ description: "string" }).optional(),
	email: z.string({ description: "string" }).optional(),
	phone: z.string({ description: "string" }).optional(),
	nationalRegistrationNumber: z.string({ description: "string" }).optional(),
	birthday: zodDateCustom.optional(),
	language: z.string({ description: "string" }).optional(),
	sex: z.string({ description: "string" }).optional(),
	type: z.string({ description: "string" }).optional(),
	uniqueIdentifier: z.string({ description: "string" }).optional(),
	"address.[0].streetName": z.string({ description: "string" }).optional(),
	"address.[0].streetNumber": z.string({ description: "string" }).optional(),
	"address.[0].postalCode": z.string({ description: "string" }).optional(),
	"address.[0].country": z.string({ description: "string" }).optional(),
	"address.[0].city": z.string({ description: "string" }).optional(),
	"address.[0].bus": z.string({ description: "string" }).optional(),
	"address.[0].province": z.string({ description: "string" }).optional(),
	createdAt: zodDateCustom.optional(),
	updatedAt: zodDateCustom.optional(),
	deletedAt: zodDateCustom.optional(),
	archivedAt: zodDateCustom.optional(),
	"eid.cardNumber": z.string({ description: "string" }).optional(),
	"eid.expirationData": z.string({ description: "string" }).optional(),
	idCardNumber: zodDateCustom.optional(),
	idCardNumberExpiryDate: zodDateCustom.optional(),
	ibanReimbursement: z.string({ description: "string" }).optional(),
	hasDisability: z.boolean({ description: "boolean" }).optional(),
	"bankAccounts.[0].institution.name": z.string({ description: "string" }).optional(),
	"bankAccounts.[0].type": z.string({ description: "string" }).optional(),
	"bankAccounts.[0].iban": z.string({ description: "string" }).optional(),
	"bankAccounts.[0].bicCode": z.string({ description: "string" }).optional(),
});

// Function that makes a grouped object of the zod schema by type. Not hard defined bcs it is calculated on the import schema
export const contactFieldsByType: ContactFieldByType = Object.entries(contactImportValidationSchema.shape).reduce(
	(acc, [propKey, propSchema]) => {
		const type = (propSchema._def.description || "string") as keyof ContactFieldByType;
		if (!acc[type]) {
			acc[type] = [];
		}
		acc[type]?.push(propKey as keyof z.infer<typeof contactImportValidationSchema>);
		return acc;
	},
	{} as ContactFieldByType
);

export const eidDateFilters = ["eid.expirationData", "idCardNumberExpiryDate"];

type TConditionValueSelect = Record<
	IContactSyncRule["conditions"][number]["value"],
	{
		intl: { id: string; defaultMessage: string };
		supportedFields: (keyof IContact | string)[]; //  All fields that are in lodash get notation that the condition value supports
	}
>;
export const conditionValueSelect: TConditionValueSelect = {
	IS_DEFINED: {
		intl: { id: "CONTACTS.SETTINGS.ADD.RULE.MODAL.IS_DEFINED", defaultMessage: "Is Defined" },
		supportedFields: [
			...(contactFieldsByType?.string ? contactFieldsByType.string : []),
			...(contactFieldsByType?.date ? contactFieldsByType.date : []),
			...(contactFieldsByType?.number ? contactFieldsByType.number : []),
			...(contactFieldsByType?.boolean ? contactFieldsByType.boolean : []),
		],
	},
	EQUAL_TO: {
		intl: { id: "CONTACTS.SETTINGS.ADD.RULE.MODAL.EQUAL_TO", defaultMessage: "Equals to" },
		supportedFields: [
			...(contactFieldsByType?.string ? contactFieldsByType.string : []),
			...(contactFieldsByType?.date ? contactFieldsByType.date : []),
		].filter((item) => !eidDateFilters.includes(item)), // eid.expirationData has only on use case for now, checking if it is less or equal to today
	},
	GREATER_THAN: {
		intl: { id: "CONTACTS.SETTINGS.ADD.RULE.MODAL.GREATER_THAN", defaultMessage: "Greater than" },
		supportedFields: [
			...(contactFieldsByType?.date ? contactFieldsByType.date : []),
			...(contactFieldsByType?.number ? contactFieldsByType.number : []),
		].filter((item) => !eidDateFilters.includes(item)), // eid.expirationData has only on use case for now, checking if it is less or equal to today
	},
	LESS_THAN: {
		intl: { id: "CONTACTS.SETTINGS.ADD.RULE.MODAL.LESS_THAN", defaultMessage: "Less than" },
		supportedFields: [
			...(contactFieldsByType?.date ? contactFieldsByType.date : []),
			...(contactFieldsByType?.number ? contactFieldsByType.number : []),
		],
	},
	GREATER_THAN_OR_EQUAL: {
		intl: { id: "CONTACTS.SETTINGS.ADD.RULE.MODAL.GREATER_THAN_OR_EQUAL", defaultMessage: "Greater than or equal" },
		supportedFields: [
			...(contactFieldsByType?.date ? contactFieldsByType.date : []),
			...(contactFieldsByType?.number ? contactFieldsByType.number : []),
		].filter((item) => !eidDateFilters.includes(item)), // eid.expirationData has only on use case for now, checking if it is less or equal to today
	},
	LESS_THAN_OR_EQUAL: {
		intl: { id: "CONTACTS.SETTINGS.ADD.RULE.MODAL.LESS_THAN_OR_EQUAL", defaultMessage: "Less than or equal" },
		supportedFields: [
			...(contactFieldsByType?.date ? contactFieldsByType.date : []),
			...(contactFieldsByType?.number ? contactFieldsByType.number : []),
		],
	},
};

export const conditionValueTranslations = {
	type: "CONTACTS.IMPORT.SETTINGS.TYPE",
	id: "CONTACTS.IMPORT.SETTINGS.ID",
	lastName: "CONTACTS.IMPORT.SETTINGS.LASTNAME",
	name: "CONTACTS.IMPORT.SETTINGS.LASTNAME",
	firstName: "CONTACTS.IMPORT.SETTINGS.FIRSTNAME",
	fullName: "CONTACTS.IMPORT.SETTINGS.FULL_NAME",
	uniqueIdentifier: "CONTACTS.IMPORT.SETTINGS.UNIQUEIDENTIFIER",
	email: "CONTACTS.IMPORT.SETTINGS.EMAIL",
	phone: "CONTACTS.IMPORT.SETTINGS.PHONE",
	nationalRegistrationNumber: "CONTACTS.IMPORT.SETTINGS.NATIONALREGISTRATIONNUMBER",
	avatar: "CONTACTS.IMPORT.SETTINGS.AVATAR",
	hasDisability: "CONTACTS.IMPORT.SETTINGS.HAS_DISABILITY",
	language: "CONTACTS.IMPORT.SETTINGS.LANGUAGE",
	birthday: "CONTACTS.IMPORT.SETTINGS.BIRTHDAY",
	middleName: "MODEL.CONTACT.MIDDLE_NAME",
	ibanReimbursement: "MODEL.CONTACT.IBAN_REIMBURSEMENT",
	"address.[0].streetName": "MODEL.CONTACT.ADDRESS.STREET_NAME",
	"address.[0].streetNumber": "MODEL.CONTACT.ADDRESS.STREET_NUMBER",
	"address.[0].city": "MODEL.CONTACT.ADDRESS.CITY",
	"address.[0].bus": "MODEL.CONTACT.ADDRESS.BUS",
	"address.[0].country": "MODEL.CONTACT.ADDRESS.COUNTRY",
	"address.[0].postalCode": "MODEL.CONTACT.ADDRESS.POSTAL_CODE",
	"eid.cardNumber": "CONTACTS.IMPORT.SETTINGS.EID.CARD_NUMBER",
	idCardNumber: "CONTACTS.IMPORT.SETTINGS.EID.CARD_NUMBER",
	"eid.expirationData": "CONTACTS.IMPORT.SETTINGS.EID.EXPIRATION_DATE",
	idCardNumberExpiryDate: "CONTACTS.IMPORT.SETTINGS.EID.EXPIRATION_DATE",
	"bankAccounts.[0].type": "MODEL.CONTACT.BANK_ACCOUNTS.TYPE",
	"bankAccounts.[0].iban": "MODEL.CONTACT.BANK_ACCOUNTS.IBAN",
	"bankAccounts.[0].institution.name": "MODEL.CONTACT.BANK_ACCOUNTS.INSTITUTION_NAME",
	"bankAccounts.[0].bicCode": "MODEL.CONTACT.BANK_ACCOUNTS.BIC_CODE",
	"linkedToContact.email": "CONTACTS.IMPORT.SETTINGS.email",
	"linkedToContact.phone": "CONTACTS.IMPORT.SETTINGS.phone",
	"linkedToContact.uniqueIdentifier": "CONTACTS.IMPORT.SETTINGS.unique_identifier",
	"linkData.flowProxy": "CONTACTS.IMPORT.SETTINGS.flow_proxy",
	"linkedToContact.nationalRegistrationNumber": "CONTACTS.IMPORT.SETTINGS.national_registration_number",
} as const;
