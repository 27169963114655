import "./style.scss";

import { CheckCircleOutlined } from "@ant-design/icons";
import { Card } from "antd";

import { enterOrSpacePressed } from "@/shared/helpers/keyEvents.helpers";

interface IFlowCard {
	id: string;
	logo: string;
	name: string;
	shortDescription: string;
	selected?: boolean;
	disabled?: boolean;
	onClick: (id: string) => void;
}

export default function FlowCard({ id, logo, name, shortDescription, selected, disabled, onClick }: IFlowCard) {
	const handleSelect = () => {
		if (disabled) return;

		onClick(id);
	};
	const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (!enterOrSpacePressed(event as unknown as KeyboardEvent)) return;

		handleSelect();
	};

	return (
		<Card
			hoverable={!disabled}
			data-disabled={disabled}
			data-selected={selected}
			onKeyUp={handleKeyUp}
			onClick={handleSelect}
			className="setup-flow-card"
		>
			{selected && <CheckCircleOutlined className="setup-flow-card-select_icon" />}
			<img src={logo} className="setup-flow-card-logo" />
			<p className="bold" style={{ width: "100%" }}>
				{name}
			</p>
			<p>{shortDescription}</p>
		</Card>
	);
}
