import qs from "qs";
import { useEffect } from "react";
import { Button, Result } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useRecoilCallback, useRecoilState, useRecoilValueLoadable } from "recoil";

import { Layout } from "@/components/templates";
import { routesConfig } from "@/flows/common/config";
import { reportState } from "@/flows/report_detail/state";
import Aside from "@/shared/components/aside";
import { useCollapsedAside } from "@/shared/hooks";
import DashboardCard from "@/shared/components/card/dashboard";
import { actions as dashboardActions } from "@/store/dashboard";
import { flowState, selectedFlowIdState } from "@/flows/common/state";

import { formatPageConfig } from "./helper";
import style from "./style.module.scss";
import { BellOutlined, ReconciliationOutlined, SettingOutlined } from "@ant-design/icons";

export default function FlowReportTemplate({ children }) {
	useCollapsedAside();

	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const routerParameters = useParams<{ team: string; flowId: string }>();
	const [selectedFlowId, setSelectedFlowId] = useRecoilState(selectedFlowIdState);
	const flow = useRecoilValueLoadable(flowState({ id: routerParameters.flowId }));
	const location = useLocation();

	useEffect(() => {
		if (flow.state !== "hasValue") return;

		if (!flow.contents) {
			handleGoToFlowOverview();
			return;
		}

		const pageConfig = formatPageConfig({
			flow: flow.contents,
			language: intl.locale,
			routerParameters,
		});

		dispatch(dashboardActions.ui.changePageTitle({ config: pageConfig, intl }));
	}, [flow]);

	useEffect(() => {
		if (selectedFlowId !== routerParameters.flowId) {
			setSelectedFlowId(routerParameters.flowId);
		}
	}, []);

	useEffect(() => {
		const queryParameters = qs.parse(location.search.slice(1));

		if (queryParameters?.report_id) {
			redirectToReport({ reportId: queryParameters.report_id });
		}
	}, []);

	const redirectToReport = useRecoilCallback(({ snapshot }) => ({ reportId }) => {
		const url = routesConfig.reportDetail
			.replace(":team", routerParameters.team)
			.replace(":flowId", routerParameters.flowId)
			.replace(":reportId", reportId);

		snapshot.getLoadable(reportState({ flowId: routerParameters.flowId, reportId })); // pre-fetch report

		history.push(url);
	});

	const handleGoToFlowOverview = () => {
		const url = routesConfig.overview?.replace(":team", routerParameters.team);

		history.push(url);
	};

	const renderFlowContent = () => {
		if (flow.state === "loading") {
			return <Layout.TLoadScreen className="sub-page" />;
		}

		if (flow.state === "hasError") {
			return (
				<div className={style.flow_detail_fallback_content}>
					<DashboardCard>
						<Result
							title={intl.formatMessage({ id: "FLOWS.REPORT.FAILED.TITLE" })}
							extra={
								<Button type="primary" onClick={handleGoToFlowOverview}>
									<FormattedMessage id="FLOWS.REPORT.FAILED.BUTTON" />
								</Button>
							}
						/>
					</DashboardCard>
				</div>
			);
		}

		return children;
	};

	return (
		<main className={style.flow_detail_template}>
			<Aside
				items={[
					{
						id: "overview",
						to: routesConfig.overview.replace(":team", routerParameters.team),
						icon: "arrow--left",
						label: "FLOWS.REPORT.TEMPLATE.ASIDE.BACK_TO_OVERVIEW.LABEL",
					},
					{
						id: "rapport",
						to: routesConfig.reports
							.replace(":team", routerParameters.team)
							.replace(":flowId", routerParameters.flowId),
						icon: <ReconciliationOutlined style={{ fontSize: "18px" }} />,
						label: "FLOWS.REPORT.TEMPLATE.ASIDE.REPORT.LABEL",
					},
					{
						id: "statusTypes",
						to: routesConfig.statusTypes
							.replace(":team", routerParameters.team)
							.replace(":flowId", routerParameters.flowId),
						icon: "story",
						label: "FLOWS.REPORT.TEMPLATE.ASIDE.STATUS_TYPES.LABEL",
					},
					...(flow?.contents?.template?.settings?.remindersEnabled
						? [
								{
									id: "reminders",
									to: routesConfig.reminders
										.replace(":team", routerParameters.team)
										.replace(":flowId", routerParameters.flowId),
									icon: <BellOutlined style={{ fontSize: "20px" }} />,
									label: intl.formatMessage({
										id: "FLOWS.REPORT.TEMPLATE.ASIDE.REMINDERS.LABEL",
										defaultMessage: "Automated reminders",
									}),
								},
						  ]
						: []),
					...(flow?.contents?.template?.settings?.configurationEnabled
						? [
								{
									id: "configuration",
									to: routesConfig.configuration
										.replace(":team", routerParameters.team)
										.replace(":flowId", routerParameters.flowId),
									icon: "group",
									label: "FLOWS.REPORT.TEMPLATE.ASIDE.CONFIGURATION.LABEL",
								},
						  ]
						: []),
					{
						id: "settings",
						to: routesConfig.settings
							.replace(":team", routerParameters.team)
							.replace(":flowId", routerParameters.flowId),
						icon: <SettingOutlined style={{ fontSize: "18px" }} />,
						label: "FLOWS.REPORT.TEMPLATE.ASIDE.SETTINGS.LABEL",
					},
				]}
			/>
			<div className={style.flow_detail_content}>{renderFlowContent()}</div>
		</main>
	);
}
