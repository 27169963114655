import { defaultValue } from "../../../config";
import { generatorHelpers, globalHeaders, storageHelpers } from "../../../helpers";
import actionTypes from "./header.actionTypes";

export const isFetching = () => (dispatch) => {
	dispatch({
		type: actionTypes.HEADER_IS_FETCHING,
	});
};
export const fetchingFailed = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCHING_HEADER_FAILED,
	});
};
export const fetchingSucceeded = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCHING_HEADER_SUCCEEDED,
	});
};

export const initDashboardSession = () => (dispatch) => {
	let sessionId = storageHelpers.getSessionId();

	if (!sessionId) {
		sessionId = generatorHelpers.generateUuid();
		storageHelpers.setSessionId(sessionId);
	}

	dispatch({
		type: actionTypes.INIT_DASHBOARD_SESSION,
		data: sessionId,
	});
};
export const checkForUserToken = () => (dispatch) => {
	const token = storageHelpers.getUserToken();

	dispatch({
		type: actionTypes.CHECK_FOR_USER_TOKEN,
		data: token,
	});
};

export const setUserToken = (token) => (dispatch) => {
	storageHelpers.saveUserToken({ token });

	dispatch({
		type: actionTypes.SET_USER_TOKEN,
		data: token,
	});
};

export const checkForUserLanguage = () => (dispatch) => {
	const language = storageHelpers.getLanguage();

	if (!language || language.length > defaultValue.language.length) {
		storageHelpers.setLanguage(defaultValue.language);
	}

	dispatch({
		type: actionTypes.CHECK_FOR_USER_LANGUAGE,
		data: language,
	});
};

export const setUserLanguage = (language) => (dispatch) => {
	const lang = !language || language.length > defaultValue.language.length ? defaultValue.language : language;

	globalHeaders.updateHeaders({ language: lang });
	storageHelpers.setLanguage(lang);
	dispatch({
		type: actionTypes.SET_USER_LANGUAGE,
		data: lang,
	});
};
