import "./style.scss";

import { CheckCircleFilled, PauseCircleFilled } from "@ant-design/icons";
import { Button, Result, Space, Table, Tooltip, Typography } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilCallback, useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";

import { routesConfig } from "@/flows";
import ErrorState from "@/flows/common/components/error";
import { flowState, selectedFlowIdState } from "@/flows/common/state";
import { resetReportStateSelector, searchFilterState } from "@/flows/flow_detail/reports/state";
import { flowTemplates } from "@/flows/overview/create/state";
import EmptyStateFlows from "@/flows/overview/empty";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import SkeletonTable from "@/shared/components/table/skeleton";
import { clipboardHelpers } from "@/shared/helpers";

import config from "../config";
import { useFlowList } from "../hook";
import { flowMeta, flowTablePageState } from "../state";
import helper from "./helper";

export default function FlowOverviewTable({ handleShowSendFlowModal }) {
	const intl = useIntl();
	const history = useHistory();
	const queryParameters = useParams();
	const flowMetaData = useRecoilValue(flowMeta);
	const searchFilter = useRecoilValue(searchFilterState);

	useRecoilValueLoadable(flowTemplates); // pre-fetch templates
	const [currentTablePage, setCurrentTablePage] = useRecoilState(flowTablePageState);
	const [flows, fetchNextFlows] = useFlowList({
		limit: flowMetaData.limit,
		offset: (currentTablePage - 1) * flowMetaData.limit,
	});

	const handleChange = async (pagination) => {
		try {
			await fetchNextFlows({
				limit: pagination.pageSize,
				offset: (pagination.current - 1) * pagination.pageSize,
			});
			setCurrentTablePage(pagination.current);
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "flows.overview.banner.fetch_failed",
					defaultMessage: "Failed to get flows",
				}),
			});
		}
	};

	const handleRowClick = useRecoilCallback(({ snapshot, set }) => ({ event, flow }) => {
		if (!helper.isRowClickAllowed(event) || flows?.state !== "hasValue") return;

		const url = routesConfig.reports.replace(":team", queryParameters.team).replace(":flowId", flow._id);

		snapshot.getLoadable(flowState({ id: flow._id })); // pre-fetch flow
		set(selectedFlowIdState, flow._id);
		set(resetReportStateSelector, undefined);

		history.push(url);
	});

	const handleCopyFlowLink = (link) => {
		clipboardHelpers.copyToClipboard(link);

		ShowSuccessBanner({
			title: intl.formatMessage({
				id: "FLOWS.OVERVIEW.TABLE.BUTTON.COPY_FLOW_LINK.SUCCESS",
				defaultMessage: "Flow link copied to clipboard",
			}),
		});
	};

	if (flows.state === "hasError") {
		return (
			<ErrorState
				title={intl.formatMessage({
					id: "flows.overview.table.error_state.fetch_failed.title",
					defaultMessage: "Failed to get flows",
				})}
				reloadButtonText={intl.formatMessage({
					id: "flows.overview.table.error_state.fetch_failed.button",
					defaultMessage: "Retry",
				})}
				onReloadClick={fetchNextFlows}
			/>
		);
	}

	if (!searchFilter && flows.state === "hasValue" && !flows.contents.length) return <EmptyStateFlows />;

	return (
		<SkeletonTable
			rowKey="_id"
			className="flows-overview-table"
			isLoading={flows.state === "loading"}
			dataSource={flows.contents}
			pagination={{
				...config.table.pagination,
				total: flowMetaData?.total,
				current: currentTablePage,
			}}
			locale={{
				emptyText: <Result title={intl.formatMessage({ id: "FLOWS.OVERVIEW.TABLE.QUERY.NOT_FOUND" })} />,
			}}
			onRow={(flow) => ({ onClick: (event) => handleRowClick({ event, flow }) })}
			onChange={handleChange}
		>
			<Table.Column
				title={<FormattedMessage id="FLOWS.OVERVIEW.TABLE.FLOW" />}
				dataIndex={[]}
				render={({ name, description, template }) => (
					<div className="flows-overview-table-flow">
						<img
							className="flows-overview-table-logo"
							src={template?.logo}
							alt={template?.name?.[intl.locale]}
						/>
						<Space direction="vertically" className="flows-overview-table-name_content">
							<Typography.Text className="bold">{name || template?.name?.[intl.locale]}</Typography.Text>
							<Typography.Text>
								{description || template?.shortDescription?.[intl.locale]}
							</Typography.Text>
						</Space>
					</div>
				)}
				width="60%"
			/>
			<Table.Column
				align="center"
				className="flows-overview-table-actions"
				dataIndex={[]} // Provide complete data source to the flow send button
				render={(flow, { template }) => (
					<Tooltip
						title={
							template?.deactivated
								? intl.formatMessage({
										id: "FLOWS.DEACTIVATED.TOOLTIP",
										defaultMessage:
											"This flow has been deactivated and can not be sent to clients anymore",
								  })
								: null
						}
					>
						<Button
							className="button-yellow"
							onClick={() => handleShowSendFlowModal(flow)}
							disabled={template?.deactivated}
						>
							<FormattedMessage id="FLOWS.OVERVIEW.TABLE.BUTTON.SEND_FLOWS" />
						</Button>
					</Tooltip>
				)}
				width="128px"
			/>
			<Table.Column
				align="center"
				className="flows-overview-table-actions"
				dataIndex={[]}
				render={({ link, template }) => (
					<Tooltip
						title={
							template?.deactivated
								? intl.formatMessage({
										id: "FLOWS.DEACTIVATED.TOOLTIP",
										defaultMessage:
											"This flow has been deactivated and can not be sent to clients anymore",
								  })
								: null
						}
					>
						<Button
							className="button-yellow"
							onClick={() => handleCopyFlowLink(link)}
							disabled={template?.deactivated}
						>
							<FormattedMessage id="FLOWS.OVERVIEW.TABLE.BUTTON.COPY_FLOW_LINK" />
						</Button>
					</Tooltip>
				)}
				width="128px"
			/>
			<Table.Column
				title={
					<span className="flows-overview-table-status">
						<FormattedMessage id="FLOWS.OVERVIEW.TABLE.STATUS" />
					</span>
				}
				dataIndex="isEnabled"
				render={(isEnabled) =>
					isEnabled ? (
						<div className="fot-flow-status">
							<CheckCircleFilled className="active-icon t-gap--right-xs" />
							<Typography.Text>
								<FormattedMessage id="FLOWS.OVERVIEW.TABLE.STATE.ACTIVE" />
							</Typography.Text>
						</div>
					) : (
						<div className="fot-flow-status">
							<PauseCircleFilled className="inactive-icon t-gap--right-xs" />
							<Typography.Text>
								<FormattedMessage id="FLOWS.OVERVIEW.TABLE.STATE.INACTIVE" />
							</Typography.Text>
						</div>
					)
				}
				width="128px"
			/>
		</SkeletonTable>
	);
}
