import "./style.scss";

import React from "react";
import { useIntl } from "react-intl";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { flowTemplates, isAddFlowModalVisibleState } from "@/flows/overview/create/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import TemplateCard from "@/shared/components/card/template";

export default function CardOverview({ handleSelectTemplate, loadingAmount = 9, maxAmount, disabled = false }) {
	const { locale } = useIntl();

	const templates = useRecoilValueLoadable(flowTemplates);

	const setIsModalVisible = useSetRecoilState(isAddFlowModalVisibleState);

	if (templates?.state === "hasError") {
		ShowFailedBanner({
			title: intl.formatMessage({
				id: "FLOWS.OVERVIEW.ADD_FLOW.FETCH_FAILED.TITLE",
				defaultMessage: "Failed to get flows",
			}),
		});
		setIsModalVisible(false);
	}

	const renderCards = () => {
		if (templates?.state === "loading") {
			return (
				<>
					{Array.from({ length: loadingAmount }).map((_, index) => (
						<TemplateCard loading key={index} />
					))}
				</>
			);
		}

		if (templates?.state === "hasValue" && templates?.contents?.data.length) {
			let templateData = [...templates?.contents?.data].sort((a, b) => {
				const aOrder = a.order || Number.MAX_VALUE;
				const bOrder = b.order || Number.MAX_VALUE;

				if (aOrder < bOrder) return -1;
				if (aOrder > bOrder) return 1;
				if (Number(a.comingSoon) < Number(b.comingSoon)) return -1;
				if (Number(a.comingSoon) > Number(b.comingSoon)) return 1;
			});

			if (maxAmount) {
				templateData = templateData.slice(0, maxAmount);
			}

			return (
				<>
					{templateData.map((template) => (
						<TemplateCard
							{...template}
							key={template._id}
							title={template?.name[locale]}
							description={template?.description[locale]}
							onClick={() => handleSelectTemplate(template._id)}
							disabled={disabled}
						/>
					))}
				</>
			);
		}
	};

	return (
		<div className="foco-add-flow-layout">
			<div className="foco-add-flow-list">{renderCards()}</div>
		</div>
	);
}
