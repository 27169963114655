export const propertyType = {
	familyHome: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.FAMILY_HOME",
	secondHome: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.SECOND_HOME",
} as const;

export const propertyRentType = {
	private: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.RENT_TYPE.OPTIONS.PRIVATE",
	professional: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.RENT_TYPE.OPTIONS.PROFESSIONAL",
	mixed: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.RENT_TYPE.OPTIONS.MIXED",
} as const;

export const propertyBuildingType = {
	home: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.HOME",
	apartment: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.APARTMENT",
	industrialBuilding: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.INDUSTRIAL_BUILDING",
	agriculture: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.AGRICULTURE",
	tradingHouse: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.TRADING_HOUSE",
	terrain: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.TERRAIN",
} as const;

export const propertyOwnershipType = {
	fullOwnership: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_TYPE.OPTIONS.FULL_OWNERSHIP",
	bareProperty: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_TYPE.OPTIONS.BARE_PROPERTY",
	usufruct: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_TYPE.OPTIONS.USUFRUCT",
} as const;
