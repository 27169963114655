import "./style.scss";

import { Avatar, Tag } from "antd";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Component } from "@/components/atoms";
import { apiConfig } from "@/config";
import { keyEvents } from "@/helpers";
import { propertyType } from "../config";
import { IProperty } from "..";
import { MapPinIcon } from "@heroicons/react/24/outline";

const countriesList = require("world_countries_lists/data/countries/_combined/countries.json");

interface IPropertyCard {
	id: string;
	value: IProperty;
	onClick: (id: string) => void;
}
export default function PropertyCard({ id, value, onClick }: IPropertyCard) {
	const intl = useIntl();
	const addressUrl = useMemo(() => apiConfig.maps.location({ ...(value?.address || {}), primary: false }), [value]);
	const handleKeyUp = (event: any) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			onClick(id);
		}
	};

	const address = value.address || {};
	const hasPreciseLocation = address?.postalCode && address?.city && address?.streetName;
	const fullCountryName = countriesList.find((country) => country.alpha2 === address?.country)?.[intl.locale];

	return (
		<article
			role="button"
			onKeyUp={handleKeyUp}
			onClick={() => onClick(id)}
			className={`m-card foreign_property_card`}
		>
			<div className="foreign_property_card-image">
				{hasPreciseLocation ? (
					<Avatar src={addressUrl} shape="square" size={64} style={{ minWidth: "64px", width: "100%" }} />
				) : (
					<MapPinIcon className="t-gap--top-sm t-gap--bottom-sm t-gap--left-sm t-gap--right-sm" />
				)}
				<Avatar src={addressUrl} shape="square" size={64} style={{ minWidth: "64px", width: "100%" }} />
			</div>
			<div className="foreign_property_card-content">
				<div className="foreign_property_card-content-chips">
					{value.isRentedOut && (
						<Tag color="green">
							<FormattedMessage id="CONTACTS.DETAIL.PROPERTIES_CARD.CHIP.IS_RENTED" />
						</Tag>
					)}
					{value.isCoOwnership && (
						<Tag color="blue">
							<FormattedMessage id="CONTACTS.DETAIL.PROPERTIES_CARD.CHIP.IS_CO_OWNERSHIP" />
						</Tag>
					)}
					{value.isSold && (
						<Tag color="red">
							<FormattedMessage id="CONTACTS.DETAIL.PROPERTIES_CARD.CHIP.SOLD" />
						</Tag>
					)}
				</div>
				<p>{value?.type && propertyType[value.type] && <FormattedMessage id={propertyType[value.type]} />}</p>
				{hasPreciseLocation || value.name ? (
					<p className="foreign_property_card-name">
						{value.name || `${address?.streetName} ${address?.streetNumber}`}
					</p>
				) : (
					<p style={{ fontSize: ".8em", color: "GrayText" }}>
						{intl.formatMessage({ id: "CONTACTS.DETAIL.PROPERTIES_CARD.NO_PRECISE_LOCATION" })}
					</p>
				)}
				<p className="font-size-sm">
					{hasPreciseLocation
						? `${address?.postalCode} ${address?.city}, ${fullCountryName}`
						: fullCountryName}
				</p>
			</div>
			<Component.AIcon type="edit" className="foreign_property_card-edit_icon" />
		</article>
	);
}
