import "./styles.scss";

import { keyEvents } from "../../../helpers";

interface ICheckBox {
	id?: string;
	label?: string;
	checked?: boolean;
	disabled?: boolean;
	className?: string;
	onChange?: (value: boolean) => void;
}

export default function CheckBox({ id, label, checked, disabled, onChange, className, ...props }: ICheckBox) {
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			handleOnChange();
		}
	};
	const handleOnChange = () => {
		if (onChange) onChange(!checked);
	};

	return (
		<label
			tabIndex={0}
			role="checkbox" //eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
			data-state={checked ? "active" : ""}
			aria-labelledby={label}
			aria-checked={checked}
			onKeyUp={handleKeyUp}
			className={`component_checkbox ${className}`}
			htmlFor={id}
		>
			<input
				id={id}
				type="checkBox"
				onChange={handleOnChange}
				checked={checked}
				aria-disabled={disabled}
				{...props}
			/>
			<div className="component_checkbox-label" aria-checked={checked}></div>
		</label>
	);
}

CheckBox.defaultProps = {
	checked: false,
	disabled: false,
	label: "",
	className: "",
	onChange: () => undefined,
	onClick: () => undefined,
};
