import { detectorHelpers, storageHelpers } from "../../../helpers";
import actionTypes from "./ui.actionTypes";

export const changePageTitle =
	({ config, intl }) =>
	(dispatch) => {
		if (!config) {
			dispatch({
				type: actionTypes.CHANGE_UI_FAILED,
			});
			return;
		}

		const tabTitle = intl ? intl.formatMessage({ id: config?.tabTitle }) : config?.title;

		document.title = tabTitle ? `Bothive - ${tabTitle}` : "Bothive";

		dispatch({
			type: actionTypes.CHANGE_PAGE_CONFIG,
			data: config,
		});
	};

export const checkCookieConcent = () => (dispatch) => {
	const cookie = storageHelpers.getCookieConcent();

	dispatch({
		type: actionTypes.CHECK_COOKIE_CONCENT,
		data: cookie === "YES",
	});
};

export const changeCookieConcent = () => (dispatch) => {
	storageHelpers.setCookieConcent();

	dispatch({
		type: actionTypes.CHANGE_COOKIE_CONCENT,
		data: true,
	});
};

export const checkBrowserSupport = () => (dispatch) => {
	const isSupported = detectorHelpers.isBrowserSupported();
	const id = "browserSupported";

	dispatch({
		type: actionTypes.CHECK_BROWSER_SUPPORT,
		data: isSupported,
	});

	dispatch(!isSupported ? setWarning({ id, message: "WARNING.SUPPORT.IE_NOT_SUPPORTED" }) : hideWarning(id));
};

export const checkIfNetworkIsOnline = () => (dispatch) => {
	const id = "networkOnline";

	detectorHelpers.watchNetworkStatus((data) => {
		dispatch({
			type: actionTypes.CHECK_NETWORK_SUPPORT,
			data: !data,
		});
		dispatch(!data ? setWarning({ id, message: "WARNING.SUPPORT.BROWSER_IS_OFFLINE" }) : hideWarning(id));
	});
};

export const setTemplate = (data) => (dispatch) => {
	if (!data) {
		return;
	}

	dispatch({
		type: actionTypes.SET_TEMPLATE,
		data,
	});
};

export const setWarning =
	({ id, message }) =>
	(dispatch) => {
		dispatch({
			type: actionTypes.SHOW_WARNING_MESSAGE,
			data: {
				id,
				message,
			},
		});
	};

export const foldAside = () => (dispatch) => {
	dispatch({ type: actionTypes.FOLD_ASIDE });
};
export const unfoldAside = () => (dispatch) => {
	dispatch({ type: actionTypes.UNFOLD_ASIDE });
};

export const toggleShortcuts = () => (dispatch) => {
	dispatch({ type: actionTypes.TOGGLE_SHORTCUTS });
};

export const hideWarning = (id) => (dispatch) => {
	dispatch({
		type: actionTypes.REMOVE_WARNING_MESSAGE,
		data: id,
	});
};
