import "./style.scss";

export default {
	blockCursor: "html_editor-blockCursor",
	characterLimit: "html_editor-characterLimit",
	code: "html_editor-code",
	codeHighlight: {
		atrule: "html_editor-token_attr",
		attr: "html_editor-token_attr",
		boolean: "html_editor-token_property",
		builtin: "html_editor-token_selector",
		cdata: "html_editor-token_comment",
		char: "html_editor-token_selector",
		class: "html_editor-token_function",
		"class-name": "html_editor-token_function",
		comment: "html_editor-token_comment",
		constant: "html_editor-token_property",
		deleted: "html_editor-token_property",
		doctype: "html_editor-token_comment",
		entity: "html_editor-token_operator",
		function: "html_editor-token_function",
		important: "html_editor-token_variable",
		inserted: "html_editor-token_selector",
		keyword: "html_editor-token_attr",
		namespace: "html_editor-token_variable",
		number: "html_editor-token_property",
		operator: "html_editor-token_operator",
		prolog: "html_editor-token_comment",
		property: "html_editor-token_property",
		punctuation: "html_editor-token_punctuation",
		regex: "html_editor-token_variable",
		selector: "html_editor-token_selector",
		string: "html_editor-token_selector",
		symbol: "html_editor-token_property",
		tag: "html_editor-token_property",
		url: "html_editor-token_operator",
		variable: "html_editor-token_variable",
	},
	embedBlock: {
		base: "html_editor-embed_block",
		focus: "html_editor-embed_block_focus",
	},
	hashtag: "html_editor-hashtag",
	heading: {
		h1: "html_editor-h1",
		h2: "html_editor-h2",
		h3: "html_editor-h3",
		h4: "html_editor-h4",
		h5: "html_editor-h5",
		h6: "html_editor-h6",
	},
	image: "editor-image",
	indent: "html_editor-indent",
	link: "html_editor-link",
	list: {
		listitem: "html_editor-list_item",
		listitemChecked: "html_editor-list_item_checked",
		listitemUnchecked: "html_editor-list_item_unchecked",
		nested: {
			listitem: "html_editor-nested_list_item",
		},
		olDepth: ["html_editor-ol1", "html_editor-ol2", "html_editor-ol3", "html_editor-ol4", "html_editor-ol5"],
		ulDepth: [
			"html_editor-ul1",
			"html_editor-ul2",
			"html_editor-ul3",
			"html_editor-ul4",
			"html_editor-ul5",
			"html_editor-ul6",
			"html_editor-ul7",
		],
		ol: "html_editor-ol",
		ul: "html_editor-ul",
	},
	ltr: "html_editor-ltr",
	mark: "html_editor-mark",
	markOverlap: "html_editor-markOverlap",
	paragraph: "html_editor-paragraph",
	quote: "html_editor-quote",
	rtl: "html_editor-rtl",
	text: {
		bold: "html_editor-text-bold",
		code: "html_editor-text-code",
		italic: "html_editor-text-italic",
		underline: "html_editor-text-underline",
		strikethrough: "html_editor-text-strike_through",
		underlineStrikethrough: "html_editor-text-underline_strike_through",
		subscript: "html_editor-subscript",
		superscript: "html_editor-superscript",
	},
	table: "html_editor-table",
	tableAddColumns: "html_editor-table-add_columns",
	tableAddRows: "html_editor-table-add_rows",
	tableCell: "html_editor-table-cell",
	tableCellActionButton: "html_editor-table-cell_action_button",
	tableCellActionButtonContainer: "html_editor-table-cell_action_button_container",
	tableCellEditing: "html_editor-table-cell_editing",
	tableCellHeader: "html_editor-table-cell_header",
	tableCellPrimarySelected: "html_editor-table-cell_primary_selected",
	tableCellResizer: "html_editor-table-cell_resizer",
	tableCellSelected: "html_editor-table-cell_selected",
	tableCellSortedIndicator: "html_editor-table-cell_sorted_indicator",
	tableResizeRuler: "html_editor-table-cell_resize_ruler",
	tableSelected: "html_editor-table_selected",
	thread: {
		content: "",
		button: "",
		node: "",
	},
};
