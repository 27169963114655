import { DatePicker } from "antd";
import dayjs, { Dayjs, isDayjs } from "dayjs";

interface IDateFormItem {
	value?: number | string | Date;
	onChange?: (value: Date | null) => void;
	allowClear?: boolean;
	format?: string;
	style?: object;
	disabledDate?: any;
}

export default function DateFormItem({ value, onChange, format = "DD-MM-YYYY", ...DatePickerProps }: IDateFormItem) {
	let _value = value ? dayjs(value, "YYYY-MM-DD") : undefined;

	if (value instanceof Date) _value = dayjs(value);
	if (isDayjs(value)) _value = value;

	return (
		<DatePicker
			{...DatePickerProps}
			format={format}
			defaultValue={_value}
			onChange={(value: Dayjs | null) => {
				if (!onChange) return;

				onChange(value && value.toDate());
			}}
		/>
	);
}
