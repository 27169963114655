import { Redirect } from "react-router-dom";

import { storageHelpers } from "../../shared/helpers";

function RedirectPage() {
	const organizationSlug = storageHelpers.getOrganizationSlug();

	if (organizationSlug) {
		return <Redirect to={`/${organizationSlug}`} />;
	}

	return <Redirect to="/profile" />;
}

export default RedirectPage;
