import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { DRAG_DROP_PASTE } from "@lexical/rich-text";
import { isMimeType, mediaFileReader } from "@lexical/utils";
import { COMMAND_PRIORITY_LOW } from "lexical";
import { useEffect } from "react";

import { INSERT_IMAGE_COMMAND } from "./imagesPlugin";
import { ACCEPTABLE_IMAGE_TYPES, ACCEPTABLE_MIME_TYPES } from "./config";

interface IDragDropPaste {
	onFileUpload?: (params: { data: string; file: File }) => void;
}
export function DragDropPaste({ onFileUpload }: IDragDropPaste): null {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		return editor.registerCommand(
			DRAG_DROP_PASTE,
			(files) => {
				(async () => {
					const filesResult = await mediaFileReader(
						files,
						[ACCEPTABLE_MIME_TYPES].flatMap((x) => x)
					);

					filesResult.forEach(({ file, result }) => {
						if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
							editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
								altText: file.name,
								src: result,
							});
							return;
						}

						if (!onFileUpload) return;

						onFileUpload({ file, data: result });
					});
				})();
				return true;
			},
			COMMAND_PRIORITY_LOW
		);
	}, [editor]);

	return null;
}
