import "./style.scss";

import { Form, Input, Switch, Space, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import DashboardCard from "@/shared/components/card/dashboard";
import FieldSideLabel from "@/shared/components/field/side_label";

import { timingConfig } from "@/config";
import { debounce } from "@/helpers";
import { ApiKeyManagerRouterOutput, trpc } from "@/trpc";
import ShowSuccessBanner from "@/shared/components/banner/success";

export default function Domains({
	apiKey,
	className,
	onChange,
}: {
	apiKey: ApiKeyManagerRouterOutput["getById"] | null | undefined;
	className: string;
	onChange: (values) => void;
}) {
	const intl = useIntl();

	const handleChange = (values) => {
		if (values.name !== "") {
			debounce(() => {
				onChange({ ...apiKey, ...values });
			}, timingConfig.keyboardTimeout);
		}
	};

	const handleCopyToClipboard = () => {
		ShowSuccessBanner({
			title: intl.formatMessage({
				id: "banner.succeeded.copy_key",
				defaultMessage: "Successfully copied to clipboard",
			}),
		});
	};

	return (
		<DashboardCard className={`page-api-key-main-form ${className || ""}`}>
			<Form
				id="mainForm"
				initialValues={apiKey || {}}
				onValuesChange={handleChange}
				requiredMark="optional"
				autoComplete="off"
				layout="vertical"
			>
				<Form.Item
					name="name"
					label={<FormattedMessage id="settings.api_key_detail.form.name.label" defaultMessage="Name" />}
					help={
						<FormattedMessage
							id="settings.api_key_detail.form.name.help"
							defaultMessage="Give your API key a relevant name for better recognition."
						/>
					}
					rules={[
						{
							required: true,
							message: (
								<FormattedMessage
									id="settings.api_key_detail.form.name.required"
									defaultMessage="You need to give your api key a name"
								/>
							),
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="description"
					className="page-api-key-main-form--form-item t-gap--top"
					label={
						<FormattedMessage
							id="settings.api_key_detail.form.description.label"
							defaultMessage="Description"
						/>
					}
					help={
						<FormattedMessage
							id="settings.api_key_detail.form.description.help"
							defaultMessage="A description can help you and your team to better monitor the objectives of this API key and to intervene in the event of abuse."
						/>
					}
				>
					<Input.TextArea />
				</Form.Item>
				<Form.Item
					name="key"
					className="page-api-key-main-form--form-item t-gap--top"
					label={
						<FormattedMessage id="SETTINGS.API_KEY_DETAIL.FORM.API_KEY.LABEL" defaultMessage="Api key" />
					}
				>
					<Space.Compact style={{ width: "100%" }} onClick={handleCopyToClipboard}>
						<Form.Item name="key" style={{ width: "100%" }}>
							<Input readOnly style={{ cursor: "pointer" }} />
						</Form.Item>
						<Button type="primary" icon={<CopyOutlined />} />
					</Space.Compact>
				</Form.Item>
				<FieldSideLabel
					label={intl.formatMessage({
						id: "settings.api_key_detail.form.is_enabled.label",
						defaultMessage: "Is enabled",
					})}
					help={intl.formatMessage({
						id: "settings.api_key_detail.form.is_enabled.help",
						defaultMessage:
							"Disabling the API key has immediate effect and will prevent the application using it from accessing your team.",
					})}
				>
					<Form.Item name="isEnabled" className="page-api-key-main-form--form-item" valuePropName="checked">
						<Switch />
					</Form.Item>
				</FieldSideLabel>
			</Form>
		</DashboardCard>
	);
}
