import "./style.scss";

import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";

import { routesConfig } from "@/flows/common/config";
import ActionButtons from "@/flows/flow_detail/reports/action_buttons";
import BulkActionButtons from "@/flows/flow_detail/reports/bulk_actions";
import ReportFilters from "@/flows/flow_detail/reports/filters";
import ReportsTable from "@/flows/flow_detail/reports/table";
import helper from "@/flows/overview/table/helper";
import { isEditingReportState, reportState } from "@/flows/report_detail/state";
import SendFlowModal from "@/flows/send";

import { reportsList } from "./state";

export default function ReportOverview() {
	const [showSendFlowModal, setShowSendFlowModal] = useState({ visible: false });

	const history = useHistory();
	const { flowId } = useParams();
	const queryParameters = useParams();
	const reports = useRecoilValue(reportsList);
	const setIsEditing = useSetRecoilState(isEditingReportState);

	const handleRowClick = useRecoilCallback(({ snapshot }) => ({ event, report }) => {
		setIsEditing(false);

		if (!helper.isRowClickAllowed(event) || !reports?.length) return;

		// Prevent row click behavior when miss clicking beside the checkbox
		if (
			typeof event.target === "string" &&
			(event.target?.className?.includes("ant-table-selection-column") ||
				event.target?.className?.includes("ant-checkbox-wrapper"))
		) {
			return;
		}

		const url = routesConfig.reportDetail
			.replace(":team", queryParameters.team)
			.replace(":flowId", report.flowId)
			.replace(":reportId", report._id);

		snapshot.getLoadable(reportState({ flowId, reportId: report._id })); // pre-fetch report

		history.push(url);
	});

	return (
		<main className="fro-main">
			<ActionButtons showSendModal={setShowSendFlowModal} />
			<ReportFilters />
			<BulkActionButtons />
			<ReportsTable handleRowClick={handleRowClick} />
			{showSendFlowModal.visible && (
				<SendFlowModal
					{...showSendFlowModal}
					handleClose={() => setShowSendFlowModal({ visible: false })}
					showReports={true}
				/>
			)}
		</main>
	);
}
