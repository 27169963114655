import "./style.scss";

import { Skeleton } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function DashboardCard({
	title,
	description,
	formattedMessageValues,
	descriptionValues,
	className,
	isLoading = false,
	children,
	style = {},
	bodyStyle = {},
}) {
	const generateTitleAndDescription = () => (
		<>
			{title && (
				<h2 className="card-dashboard-title">
					<FormattedMessage id={title} values={formattedMessageValues} />
				</h2>
			)}
			{description && (
				<p className="card-dashboard-description t-gap--bottom">
					<FormattedMessage id={description} values={descriptionValues} />
				</p>
			)}
		</>
	);

	return (
		<div className={`card-dashboard ${className}`} style={style}>
			{isLoading ? <Skeleton active paragraph={{ rows: 1 }} /> : generateTitleAndDescription()}
			<div className="card-dashboard-body" style={bodyStyle}>
				{children}
			</div>
		</div>
	);
}
