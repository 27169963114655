import { stringHelpers } from "@bothive/helpers";
import type { EmailMessage, IMessage, IRecipient, IUser } from "@bothive_core/database";
import { atom, selector } from "recoil";

import { ChannelRouterOutput } from "@/trpc";
import { AttachmentFile } from "./components/footer";
import { IMessageState, ReplyType, WindowState } from "./types";
import { generateSubject, groupRecipientsAddress } from "./utils";

export const composeWindowState = atom<WindowState>({
	key: "icWindowState",
	default: "default",
});

export const openState = atom<boolean>({
	key: "icOpenState",
	default: false,
});

export const composeState = atom<IMessageState | undefined>({
	key: "icComposeState",
	default: undefined,
});

export const attachmentsState = atom<AttachmentFile[]>({
	key: "icAttachmentsState",
	default: [],
});

export const composeWindowReplyTypeState = atom<ReplyType | undefined>({
	key: "icComposeWindowReplyTypeStateState",
	default: undefined,
});

export const composeNewDraft = selector({
	key: "icNewDraft",
	get: () => undefined,
	set: ({ set }) => {
		set(attachmentsState, []);
		set(composeState, {
			to: [],
			subject: "",
			key: Date.now(),
			content: "<p></p><p></p><div data-lexical-signature-placeholder='true'></div><p></p>",
		});
		set(composeWindowReplyTypeState, undefined);
		set(openState, true);
	},
});

function createName(recipient: IRecipient) {
	if (recipient.name) return `${recipient.name} (${recipient.address})`;
	return recipient.address;
}

function createThreadAttributesString(message: IMessage<EmailMessage>) {
	const fromRecipient = message.recipients?.find((recipient) => recipient.type === "from");
	const toRecipient = message.recipients?.reduce<string[]>((prev, recipient) => {
		if (recipient.type !== "to") return prev;

		return [...prev, createName(recipient)];
	}, []);
	const attributes = [
		{ key: "lexical-thread", value: true },
		{ key: "subject", value: message.subject },
		{ key: "send-on", value: message.createdAt },
		{ key: "to", value: toRecipient?.join(",") },
		{ key: "from", value: fromRecipient && createName(fromRecipient) },
	];

	return attributes.reduce<string>((prev, curr) => {
		if (curr.value === undefined) return prev;

		return `${prev} data-${curr.key}="${curr.value}"`;
	}, "");
}

export const forwardMail = selector<IMessage<EmailMessage> | undefined>({
	key: "icForwardMail",
	get: () => undefined,
	set: ({ set }, payload) => {
		if (!payload) return;

		const message: IMessage<EmailMessage> = payload as IMessage<EmailMessage>;
		const recipients = groupRecipientsAddress(message.recipients || []);
		const formattedMessage: IMessageState = {
			key: Date.now(),
			to: [],
			content: "<p></p><p></p><div data-lexical-signature-placeholder='true'></div>",
			replyTo: recipients.replyTo,
			subject: generateSubject(message?.subject || "", "Fwd:"),
		};

		if (!stringHelpers.isEmptyString(message.payload.content)) {
			const attributes = createThreadAttributesString(message);

			formattedMessage.content += `<p></p><div ${attributes}>${message.payload.content}</div><p></p>`;
		}

		set(attachmentsState, message.attachments || []);
		set(composeWindowReplyTypeState, "forward");
		set(composeState, formattedMessage);
		set(composeWindowState, "default");
		set(openState, true);
	},
});

export const continueDraft = selector<IMessage<EmailMessage> | undefined>({
	key: "icContinueDraft",
	get: () => undefined,
	set: ({ set }, payload) => {
		if (!payload) return;

		const message: IMessage<EmailMessage> = payload as IMessage<EmailMessage>;
		const recipients = groupRecipientsAddress(message.recipients || []);
		const formattedMessage: IMessageState = {
			key: message._id,
			_id: message._id,
			...recipients,
			to: recipients.to || [],
			content: message.payload.content,
			subject: message.subject || "",
			inReplyTo: message.inReplyTo,
			from: message.sendFrom,
			conversationId: String(message.conversationId),
		};

		set(attachmentsState, message.attachments || []);
		set(composeWindowReplyTypeState, "continue");
		set(composeState, formattedMessage);
		set(composeWindowState, "default");
		set(openState, true);
	},
});

interface ReplyMailArgs {
	message: IMessage<EmailMessage>;
	channels: ChannelRouterOutput["getInboxChannels"] | undefined;
	user: IUser;
	action: ReplyType;
}

export const replyMail = selector<ReplyMailArgs | undefined>({
	key: "icReplyToMail",
	get: () => undefined,
	set: ({ set }, payload) => {
		if (!payload) return;
		const typedPayload = payload as ReplyMailArgs;

		const channelAddressList = typedPayload.channels?.data.map((channel) => channel.address);

		const messageToReplyRecipients = groupRecipientsAddress(typedPayload.message.recipients || []);
		const messageToReplyRecipientAddresses = new Set([
			messageToReplyRecipients.from,
			...(messageToReplyRecipients.to || []),
			...(messageToReplyRecipients.cc || []),
			...(messageToReplyRecipients.bcc || []),
		]);

		const currentUserChannelInRecipients = typedPayload.channels?.data.find(
			(channel) => channel.address && messageToReplyRecipientAddresses.has(channel.address)
		);

		const newReplyToMessage: IMessageState = {
			key: Date.now(),
			conversationId: `${typedPayload.message.conversationId}`,
			subject:
				typedPayload.action === "reply" || typedPayload.action === "reply_all"
					? generateSubject(typedPayload.message.subject || "", "Re:")
					: "",
			content: "<p></p><p></p><div data-lexical-signature-placeholder='true'></div>",
			inReplyTo: typedPayload.message.uniqueMessageId,
			replyTo: messageToReplyRecipients.replyTo,
			to: messageToReplyRecipients.from ? [messageToReplyRecipients.from] : [],
			from: currentUserChannelInRecipients?._id,
		};

		if (messageToReplyRecipients.from === currentUserChannelInRecipients?.address) {
			// In case we are replying on mail where i was the sender, don(t reply to self but use the other recipients
			newReplyToMessage.to = messageToReplyRecipients.to || [];
		}

		if (typedPayload.action === "reply_all") {
			const combinedToRecipients = new Set(
				[...(newReplyToMessage.to || []), ...(messageToReplyRecipients.to || [])].filter(
					(address) => !channelAddressList?.includes(address)
				)
			);

			newReplyToMessage.to = [...combinedToRecipients];
			newReplyToMessage.cc = messageToReplyRecipients.cc?.filter(
				(address) => !channelAddressList?.includes(address)
			);
		}

		if (!stringHelpers.isEmptyString(typedPayload.message.payload.content)) {
			const attributes = createThreadAttributesString(typedPayload.message);

			newReplyToMessage.content += `<p></p><div ${attributes}>${typedPayload.message.payload.content}</div><p></p>`;
		}

		set(attachmentsState, typedPayload.message.attachments?.filter((attachment) => attachment.isInline) || []);
		set(composeWindowReplyTypeState, typedPayload.action);
		set(composeState, newReplyToMessage);
		set(composeWindowState, "default");
		set(openState, true);
	},
});

export const fileUploadingState = atom<boolean>({
	key: "icFileUploadingState",
	default: false,
});
export const uploadingProgressState = atom<number>({
	key: "icUploadingProgressState",
	default: 0,
});
