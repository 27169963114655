import actionTypes from "./errorHandling.actionTypes";

const initialState = {
	isErrorThrown: false,
	inboxError: false,
	error: undefined,
	info: undefined,
};

export default function (state = initialState, action) {
	const updateState = {
		[actionTypes.ERROR_RESOLVED]: () => ({
			...state,
			error: undefined,
			isErrorThrown: false,
		}),
		[actionTypes.THROW_NEW_ERROR]: () => ({
			...state,
			isErrorThrown: true,
			error: action.data.error,
			info: action.data.info,
		}),
		[actionTypes.INBOX_ERROR_RESOLVED]: () => ({
			...state,
			inboxError: false,
		}),
		[actionTypes.THROW_INBOX_ERROR]: () => ({
			...state,
			inboxError: true,
		}),
	};

	return updateState[action.type] ? updateState[action.type]() : state;
}
