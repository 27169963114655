import actionTypes from "./inbox.actionTypes";

export const showCompose = (data) => (dispatch) => {
	dispatch({
		type: actionTypes.SHOW_COMPOSE,
		data: data || {},
	});
};
export const resizeCompose = (state) => (dispatch) => {
	dispatch({
		type: actionTypes.RESIZE_COMPOSE,
		data: state,
	});
};
export const toggleCompose = () => (dispatch) => {
	dispatch({ type: actionTypes.TOGGLE_COMPOSE });
};
export const hideCompose = () => (dispatch) => {
	dispatch({ type: actionTypes.HIDE_COMPOSE });
};
