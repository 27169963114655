import actionTypes from "./import.actionTypes";

const initialState = {
	list: [],
};

export default function (state = initialState, action) {
	const updateState = {
		[actionTypes.IMPORT_MAP_FIELDS]: () => ({
			...state,
			list: action.data,
		}),
		[actionTypes.RESET_MAPPED_FIELDS]: () => ({
			...state,
			list: [],
		}),
	};

	return updateState[action.type] ? updateState[action.type]() : state;
}
