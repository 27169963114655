import { ContactRouterOutput, trpc } from "@/trpc";
import { DeleteOutlined } from "@ant-design/icons";
import { Alert, Button, notification } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilState, useSetRecoilState } from "recoil";
import DeleteModal from "../../../../shared/components/modal/delete_modal";
import { selectedAllContactsState, selectedContactsState } from "../state";
import { showArchiveModalState } from "./state";
interface DeleteProps {
	filters: {
		q?: string;
		contactType?: string;
		labelId?: string;
		accountManagerId?: string;
	};
	totalContacts?: number;
}

export default function Delete({ filters, totalContacts }: DeleteProps) {
	const [selectedContacts, setSelectedContacts] = useRecoilState(selectedContactsState);
	const [selectedAll, setSelectedAll] = useRecoilState(selectedAllContactsState);
	const [showDeleteBatchModal, setShowDeleteBatchModal] = useState(false);
	const setShowArchiveModal = useSetRecoilState(showArchiveModalState);
	const trpcUtils = trpc.useUtils();
	const intl = useIntl();

	const bulkDeleteContactsMutation = trpc.contact.bulkDeleteContacts.useMutation({
		onSuccess: (result) => {
			notification.success({
				message: intl.formatMessage(
					{
						id: "CONTACT.OVERVIEW.BULK_ACTION.DELETE.SUCCESS",
						defaultMessage: "{count, plural, one {# contact} other {# contacts}} deleted successfully.",
					},
					// @ts-ignore for some reason it complains in this component and not in the others
					{ count: result.nModified }
				),
				placement: "bottomRight",
			});

			trpcUtils.contact.getAllContacts.invalidate();
			setShowDeleteBatchModal(false);
			setSelectedAll(false);
			setSelectedContacts([]);
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "CONTACT.OVERVIEW.BULK_ACTION.DELETE.ERROR",
					defaultMessage: "Something went wrong while executing bulk delete.",
				}),
				placement: "bottomRight",
			});
		},
	});

	const handleDeleteBatch = () => {
		bulkDeleteContactsMutation.mutate({
			contactIds: selectedContacts,
			deleteAll: selectedAll,
			filters,
		});
	};

	return (
		<>
			<Button
				icon={<DeleteOutlined />}
				onClick={() => setShowDeleteBatchModal(true)}
				type={showDeleteBatchModal ? "primary" : "text"}
				loading={bulkDeleteContactsMutation.isLoading}
			>
				{" "}
				<FormattedMessage id="CONTACT.OVERVIEW.BULK_ACTION.DELETE.BUTTON.TITLE" defaultMessage="Delete" />
			</Button>
			<DeleteModal
				title={intl.formatMessage({
					id: selectedAll
						? "CONTACTS.OVERVIEW.DELETE.ALL.MODAL.TITLE"
						: "CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.TITLE",
					defaultMessage: selectedAll ? "Delete all contacts" : "Delete contacts",
				})}
				showDescriptionIcon
				additionalInfo={
					<p className="t-gap--top-sm" style={{ color: "GrayText", fontSize: "0.9em" }}>
						<FormattedMessage
							id="CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.SIDE_EFFECTS.DESCRIPTION"
							defaultMessage="Please note: if you have deleted a contact but have not deleted it in your software with which you automatically sync, this contact can be re-imported after 90 days."
						/>
					</p>
				}
				descriptionType="warning"
				description={intl.formatMessage({
					id: "CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.DESCRIPTION",
					defaultMessage:
						"Deleted contacts will still be visible at archived contacts, reports, linked contacts & files. They will be deleted forever after a period of 90 days.",
				})}
				alternativeAction={
					<Button
						onClick={() => {
							setShowDeleteBatchModal(false);
							setShowArchiveModal(true);
						}}
						className="cts-bulk-actions__button--underline t-gap--top-sm"
						type="link"
					>
						<FormattedMessage
							id="CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.OPTION.ARCHIVE"
							defaultMessage="Archive {count, plural, one {contact} other {contacts}} instead"
							values={{ count: selectedAll ? totalContacts : selectedContacts.length }}
						/>
					</Button>
				}
				deleteLabel={intl.formatMessage({
					id: selectedAll
						? "CONTACTS.OVERVIEW.DELETE.ALL.MODAL.BUTTON"
						: "CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.BUTTON",
					defaultMessage: selectedAll ? "Delete all contacts" : "Delete contacts",
				})}
				isVisible={showDeleteBatchModal}
				isDeleting={bulkDeleteContactsMutation.isLoading}
				onDelete={handleDeleteBatch}
				onClose={() => setShowDeleteBatchModal(false)}
			/>
		</>
	);
}
