import DashboardCard from "@/shared/components/card/dashboard";
import { Button, Skeleton, Alert } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { actions as dashboardActions } from "@/store/dashboard";
import { useEffect, useState, useMemo } from "react";
import config from "./config";
import { useParams } from "react-router-dom";
import DeleteCollapse from "@/shared/components/deleteCollapse";
import IntegrationConnection from "./IntegrationConnection";
import { trpc } from "@/trpc";
import { deleteConnection } from "../common/helpers";
import { useHistory } from "react-router-dom";
import isEqual from "lodash.isequal";

export default function Twinntax() {
	const intl = useIntl();
	const dispatch = useDispatch();
	const history = useHistory();
	const trpcUtils = trpc.useUtils();
	const [showImportModal, setShowImportModal] = useState(false);
	const [checkCanAddIntegration, setCheckCanAddIntegration] = useState(false);
	const routeParameters = useParams() as { connectionId: string; team: "string" };
	const deleteConnectionMutation = trpc.integration.deleteConnection.useMutation();

	const { data: connections, isLoading: connectionLoading } = trpc.integration?.getConnections?.useQuery({
		query: { populate: true, category: "5c38a182092c99228ead1a32" }, //accountancy category
	});
	const { maxIntegrations, allowEditingIntegrations } = useSelector(
		(state) => ({
			maxIntegrations: state.dashboard.permissions.integrations,
			allowEditingIntegrations: state.dashboard.permissions.userPermissions.editIntegrations,
		}),
		isEqual
	);

	const connection = useMemo(() => {
		return connections?.data?.find((connection) => connection._id === routeParameters.connectionId);
	}, [connections]);

	useEffect(() => {
		// @ts-ignore
		const breadcrumbs = config.breadcrumbs(routeParameters);
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					...config,
					breadcrumbs,
				},
				intl,
			})
		);
	}, []);

	useEffect(() => {
		if (!connections?.data?.length) return setCheckCanAddIntegration(true);

		setCheckCanAddIntegration(connections?.data?.length < maxIntegrations);
	}, [connections, maxIntegrations]);

	if (!connection && !connectionLoading) {
		return (
			<section
				className="organizationContainer"
				style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "2em" }}
			>
				{!checkCanAddIntegration && (
					<Alert
						message={intl.formatMessage({
							id: "settings.integrations.twinntax.notification.limit_reached",
							defaultMessage: "You have no integrations left in your current plan.",
						})}
						type="warning"
						showIcon
						style={{ width: "60%", margin: "0 auto", textAlign: "center" }}
					/>
				)}

				<IntegrationConnection
					allowEditing={allowEditingIntegrations}
					canAddIntegration={checkCanAddIntegration}
				/>
			</section>
		);
	}

	return (
		<section
			className="organizationContainer"
			style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "2em" }}
		>
			<>
				<DashboardCard
					title={intl.formatMessage({
						id: "SETTINGS.INTEGRATIONS.TWINNTAX.FORM_SECTION.TITLE",
						defaultMessage: "Bothive is connected with Twinntax",
					})}
				>
					<Skeleton active loading={connectionLoading} style={{ paddingTop: "2em" }}>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								width: "100%",
								paddingTop: "2em",
							}}
						>
							<p style={{ width: "100%", textAlign: "left", whiteSpace: "pre-wrap" }}>
								<FormattedMessage
									id="SETTINGS.INTEGRATIONS.TWINNTAX.FORM_SECTION.DESCRIPTION"
									defaultMessage="Sync MyMinfin files from Twinntax into the PB flow of your contacts based on their national registration number."
								/>
							</p>
						</div>
					</Skeleton>
				</DashboardCard>
				{!connectionLoading && (
					<DeleteCollapse
						headerTitle={intl.formatMessage({
							id: "SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.ACCORDION_TITLE",
							defaultMessage: "Delete integration",
						})}
						panelTitle={intl.formatMessage({
							id: "SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.TITLE",
							defaultMessage: "Delete integration with Admin Pulse",
						})}
						modalDescription={intl.formatMessage({
							id: "SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.DESCRIPTION",
							defaultMessage:
								"Are you sure that you want to stop the integration with Admin Pulse? All current functionalities will not be available to your users anymore when you delete this integration.",
						})}
						buttonText={intl.formatMessage({
							id: "SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.BUTTON",
							defaultMessage: "Delete integration",
						})}
						deleteAction={() =>
							deleteConnection(
								connection?._id,
								intl,
								trpcUtils,
								deleteConnectionMutation,
								history,
								routeParameters.team
							)
						}
					/>
				)}
			</>
		</section>
	);
}
