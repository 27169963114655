import {
	BookOutlined,
	ContactsOutlined,
	MessageOutlined,
	NodeIndexOutlined,
	QuestionCircleOutlined,
	SettingOutlined,
	TeamOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { icon } from "../assets/images";
import privilegeConfig from "./privilege.config";
import routesConfig from "./routes.config";
import typesConfig from "./types.config";
import { dateHelpers } from "@/shared/helpers";

const videoUrl = {
	inbox: "https://www.youtube.com/embed/M4qbNO6AUxw",
};

const profileActionIds = {
	shortcuts: "shortcuts",
};

export default {
	videoUrl,
	authRedirect: {
		title: "AUTH_REDIRECT.FALLBACK.TITLE",
		description: "AUTH_REDIRECT.FALLBACK.DESCRIPTION",
	},
	aside: {
		dashboard: ({ team }) => [
			[
				{
					id: "flows",
					to: routesConfig.dashboard.flow.overview.replace(":team", team),
					icon: <NodeIndexOutlined className="main-aside-menu__icon" />,
					label: "ASIDE.LABELS.FLOWS",
					privilege: privilegeConfig.keys.standard,
					billingType: privilegeConfig.billingKeys.basic,
				},
				{
					id: "inbox",
					to: routesConfig.dashboard.inbox.root.replace(":team", team),
					icon: <MessageOutlined className="main-aside-menu__icon" />,
					label: "ASIDE.LABELS.INBOX",
					privilege: privilegeConfig.keys.standard,
					billingType: privilegeConfig.billingKeys.basic,
				},
				{
					id: "contact",
					to: routesConfig.dashboard.contact.overview.replace(":team", team),
					icon: <ContactsOutlined className="main-aside-menu__icon" />,
					label: "ASIDE.LABELS.CONTACT",
					privilege: privilegeConfig.keys.standard,
					billingType: privilegeConfig.billingKeys.basic,
				},
			],
			[
				{
					id: "support",
					to: routesConfig.dashboard.support.replace(":team", team),
					icon: <QuestionCircleOutlined className="main-aside-menu__icon" />,
					label: "ASIDE.LABELS.SUPPORT",
					privilege: privilegeConfig.keys.standard,
					billingType: privilegeConfig.billingKeys.basic,
				},
				{
					isExternal: true,
					id: "documentation",
					label: "ASIDE.LABELS.DOCUMENTATION",
					to: "https://help.bothive.app/patform/",
					icon: <BookOutlined className="main-aside-menu__icon" />,
					privilege: privilegeConfig.keys.standard,
					billingType: privilegeConfig.billingKeys.basic,
				},
			],
			[
				// TODO enable when billing is active again
				// {
				// 	id: "billing",
				// 	to: routesConfig.dashboard.billing.replace(":team", team),
				// 	icon: "billing",
				// 	label: "ASIDE.LABELS.BILLING",
				// 	privilege: privilegeConfig.keys.editBilling,
				// 	billingType: privilegeConfig.billingKeys.basic,
				// 	isHidden: !template?.billingPage?.enabled,
				// },

				{
					id: "settings",
					to: routesConfig.dashboard.settings.root.replace(":team", team),
					icon: <SettingOutlined className="main-aside-menu__icon" />,
					label: "ASIDE.LABELS.SETTINGS",
					privilege: privilegeConfig.keys.standard,
					billingType: privilegeConfig.billingKeys.basic,
				},
			],
		],
		dashboardDropdown: {
			actions: [
				{
					uuid: "goToMyTeams",
					type: typesConfig.cellTypes.prefixIcon,
					value: {
						icon: "team",
						value: "ASIDE.DROPDOWN.MY_TEAMS",
					},
				},
			],
		},
		profile: (slug) => [
			[
				{
					id: "profile",
					to: routesConfig.profile.overview.replace(":slug", slug),
					icon: <UserOutlined className="main-aside-menu__icon" />,
					label: "ASIDE.PROFILE.LABELS.PROFILE",
				},
				{
					id: "teams",
					to: routesConfig.profile.teams.replace(":slug", slug),
					icon: <TeamOutlined className="main-aside-menu__icon" />,
					label: "ASIDE.PROFILE.LABELS.TEAMS",
				},
			],
		],
	},
	header: {
		profileLinks: (user) => [
			{
				id: "settings",
				to: routesConfig.profile.overview.replace(":slug", user.slug),
				icon: "",
				label: "PROFILE.POPUP.SETTINGS",
			},
			// TEMP disable until inbox settings are completed
			// {
			// 	id: "inbox",
			// 	to: routesConfig.dashboard.settings.inbox.overview.replace(":slug", user.slug),
			// 	icon: "",
			// 	label: "PROFILE.POPUP.INBOX",
			// },
		],
		profileActionIds,
		actions: [
			{
				id: profileActionIds.shortcuts,
				label: "PROFILE.POPUP.ACTIONS.SHORTCUTS",
				shortcut: "/",
			},
		],
		notificationLimit: 25,
	},
	billing: {
		pricingFormat: {
			style: "currency",
			currency: "EUR",
			currencyDisplay: "symbol",
			minimumFractionDigits: 2,
		},
	},
	channelDetail: {
		modal: {
			deleteChannel: {
				title: "CHANNEL_DETAIL_DELETE_MODAL.HEADER.TITLE",
				addLabel: "CHANNEL_DETAIL_DELETE_MODAL.DELETE.BUTTON_LABEL",
				description: "CHANNEL_DETAIL_DELETE_MODAL.HEADER.DESCRIPTION",
			},
		},
	},
	contacts: {
		id: "contactsOverview",
		title: "CONTACT.OVERVIEW.PAGE.TITLE",
		tabTitle: "CONTACT.OVERVIEW.PAGE.TITLE",
		filter: {
			options: [
				{
					uuid: typesConfig.contactTypes.contact,
					value: "CONTACT.FILTER.CONTACT_TYPES.CONTACTS",
				},
				{
					uuid: typesConfig.contactTypes.visitor,
					value: "CONTACT.FILTER.CONTACT_TYPES.VISITORS",
				},
				{
					uuid: typesConfig.contactTypes.emailContact,
					value: "CONTACT.FILTER.CONTACT_TYPES.EMAIL_CONTACTS",
				},
			],
		},
		table: {
			heading: [
				{
					id: "checkbox",
					key: "checkbox",
					type: typesConfig.cellTypes.checkbox,
					cellType: typesConfig.cellTypes.checkbox,
					width: "48px",
				},
				{
					key: "userInfo",
					label: "CONTACT.OVERVIEW.TABLE.HEADER.USER",
					cellType: typesConfig.cellTypes.profile,
					width: "100%",
				},
				{
					key: "email",
					label: "CONTACT.OVERVIEW.TABLE.HEADER.E_MAIL",
					cellType: typesConfig.cellTypes.string,
					width: "50%",
				},
				{
					key: "empty", // "company",
					// label: "CONTACT.OVERVIEW.TABLE.HEADER.COMPANY",
					cellType: typesConfig.cellTypes.icon, //typesConfig.cellTypes.string,
					width: "160px",
				},
				{
					key: "location",
					label: "CONTACT.OVERVIEW.TABLE.HEADER.LOCATION",
					cellType: typesConfig.cellTypes.string,
					width: "256px",
				},
				{
					key: "empty2", // "company",
					// label: "CONTACT.OVERVIEW.TABLE.HEADER.COMPANY",
					cellType: typesConfig.cellTypes.icon, //typesConfig.cellTypes.string,
					width: "160px",
				},
				{
					key: "action",
					label: "",
					cellType: typesConfig.cellTypes.icon,
					width: "48px",
				},
			],
		},
		fallback: {
			title: "CONTACT.OVERVIEW.FALLBACK.NO_CONTACTS_FOUND.TITLE",
			description: "CONTACT.OVERVIEW.FALLBACK.NO_CONTACTS_FOUND.DESCRIPTION",
			img: icon.noTeamFound,
		},
	},
	contactDetail: {
		id: "contactDetail",
		title: "CONTACT_DETAILS.PAGE.TITLE",
		tabTitle: "CONTACT_DETAILS.PAGE.TITLE",
		labelLimit: 100,
		breadCrumbs: ({ team, contact }) => [
			{
				to: routesConfig.dashboard.contact.overview.replace(":team", team),
				name: "CONTACT_DETAILS.HEADER.BREADCRUMB.OVERVIEW",
			},
			{
				to: routesConfig.dashboard.contact.detail.root
					.replace(":team", team)
					.replace(":contactId", contact?.id || contact?._id),
				name: !contact
					? "..."
					: contact?.fullName !== "" && contact?.fullName !== null && contact?.fullName !== undefined
					? contact?.fullName
					: "CONTACT_DETAILS.HEADER.BREADCRUMB.VISITOR",
			},
		],
		aside: ({ team, contactId }) => [
			{
				id: "back",
				to: routesConfig.dashboard.contact.overview.replace(":team", team),
				icon: "arrow--left",
				label: "CONTACT_DETAILS.ASIDE.BACK_TO_OVERVIEW.LABEL",
			},
			{
				id: "overview",
				to: routesConfig.dashboard.contact.detail.root.replace(":team", team).replace(":contactId", contactId),
				icon: "user",
				label: "CONTACT_DETAILS.ASIDE.OVERVIEW.LABEL",
			},
			{
				id: "details",
				to: routesConfig.dashboard.contact.detail.attributes
					.replace(":team", team)
					.replace(":contactId", contactId),
				icon: "book",
				label: "CONTACT_DETAILS.ASIDE.DETAILS.LABEL",
			},
			// {
			// 	id: "conversation",
			// 	to: routesConfig.dashboard.contact.detail.conversations
			// 		.replace(":team", team)
			// 		.replace(":contactId", contactId),
			// 	icon: "chat",
			// 	label: "CONTACT_DETAILS.ASIDE.CONVERSATIONS.LABEL",
			// },
			// {
			// 	id: "rapport",
			// 	to: routesConfig.dashboard.contact.detail.reports
			// 		.replace(":team", team)
			// 		.replace(":contactId", contactId),
			// 	icon: "documentation",
			// 	label: "CONTACT_DETAILS.ASIDE.REPORTS.LABEL",
			// },
			// {
			// 	id: "files",
			// 	to: routesConfig.dashboard.contact.detail.files.replace(":team", team).replace(":contactId", contactId),
			// 	icon: "document",
			// 	label: "CONTACT_DETAILS.ASIDE.FILES.LABEL",
			// },
			{
				// 	id: "analytics",
				// 	to: routesConfig.dashboard.contact.detail.analytics.replace(":team", team).replace(":contactId", contactId),
				// 	icon: "dashboard",
				// 	label: "CONTACT_DETAILS.ASIDE.ANALYTICS.LABEL",
				// }, {
				// 	id: "logs",
				// 	to: routesConfig.dashboard.contact.detail.logs.replace(":team", team).replace(":contactId", contactId),
				// 	icon: "question",
				// 	label: "CONTACT_DETAILS.ASIDE.LOGS.LABEL",
				id: "settings",
				to: routesConfig.dashboard.contact.detail.settings
					.replace(":team", team)
					.replace(":contactId", contactId),
				icon: <SettingOutlined style={{ fontSize: "18px" }} />,
				label: "CONTACT_DETAILS.ASIDE.SETTINGS.LABEL",
			},
		],
		modal: {
			table: {
				heading: [
					{
						key: "streetName",
						label: "CONTACT.EDIT_CONTACT_MODAL.ADDRESS.TABLE.STREET_NAME",
						cellType: typesConfig.cellTypes.string,
						width: "100%",
					},
					{
						key: "city",
						label: "CONTACT.EDIT_CONTACT_MODAL.ADDRESS.TABLE.CITY",
						cellType: typesConfig.cellTypes.string,
						width: "224px",
					},
					{
						key: "country",
						label: "CONTACT.EDIT_CONTACT_MODAL.ADDRESS.TABLE.COUNTRY",
						cellType: typesConfig.cellTypes.string,
						width: "160px",
					},
					{
						key: "primary",
						label: "CONTACT.EDIT_CONTACT_MODAL.ADDRESS.TABLE.PRIMARY",
						cellType: typesConfig.cellTypes.icon,
						width: "96px",
					},
					{
						key: "empty",
						cellType: typesConfig.cellTypes.icon,
						width: "20%",
					},
					{
						key: "icon",
						label: undefined,
						cellType: typesConfig.cellTypes.icon,
						width: "48px",
					},
				],
			},
		},
	},
	contactImportDetail: {
		id: "contactImportDetail",
		title: "CONTACT_IMPORT_REPORT.PAGE.TITLE",
		tabTitle: "CONTACT_IMPORT_REPORT.PAGE.TITLE",
		breadCrumbs: ({ team, contactImport, locale }) => {
			let importBreadCrumb = contactImport
				? dateHelpers.getRelativeDate({
						date: contactImport.createdAt,
						alwaysIncludeTime: true,
						locale,
				  })
				: "...";

			if (contactImport?.integration)
				importBreadCrumb = `${contactImport.integration.name} at ${importBreadCrumb}`;

			const importUrl = routesConfig.dashboard.contact.import.report
				.replace(":team", team)
				.replace(":contactImportId", contactImport?._id);

			return [
				{
					to: routesConfig.dashboard.contact.overview.replace(":team", team),
					name: "CONTACT_IMPORT_REPORT.HEADER.BREADCRUMB.OVERVIEW",
				},
				{
					to: importUrl,
					name: "CONTACT_IMPORT_REPORT.HEADER.BREADCRUMB.IMPORTS",
				},
				{
					to: importUrl,
					name: importBreadCrumb,
				},
			];
		},
	},
	dashboard: {
		id: "dashboard",
		title: "DASHBOARD.PAGE.TITLE",
		tabTitle: "DASHBOARD.PAGE.TITLE",
		table: {
			mostAskedQuestions: {
				heading: [
					{
						key: "name",
						label: "DASHBOARD.MOST_ASKED_QUESTION.QUESTION",
						cellType: typesConfig.cellTypes.string,
						width: "100%",
					},
					{
						key: "value",
						label: "DASHBOARD.MOST_ASKED_QUESTION.COUNT",
						cellType: typesConfig.cellTypes.number,
						width: "128px",
					},
				],
			},
		},
	},
	flows: {
		id: "flowsOverview",
		title: "FLOWS_OVERVIEW.PAGE.TITLE",
		tabTitle: "FLOWS_OVERVIEW.PAGE.TITLE",
		flowsFirstTime: {
			title: "FLOWS_OVERVIEW.FIRST_TIME.TITLE",
			description: "FLOWS_OVERVIEW.FIRST_TIME.DESCRIPTION",
			buttonLabel: "FLOWS_OVERVIEW.FIRST_TIME.BUTTON_LABEL",
		},
		lock: {
			title: "FLOWS_OVERVIEW.LOCK.TITLE",
			description: "FLOWS_OVERVIEW.LOCK.DESCRIPTION",
			buttonLabel: "FLOWS_OVERVIEW.LOCK.UPGRADE_BUTTON",
		},
		noFlowsFound: {
			title: "FLOWS_OVERVIEW.NO_FLOWS_FOUND.TITLE",
			description: "FLOWS_OVERVIEW.NO_FLOWS_FOUND.DESCRIPTION",
			img: icon.flowFallback,
		},
		filter: {
			search: {
				id: "flowSearch",
				placeholder: "FLOWS_OVERVIEW.SEARCH.PLACEHOLDER",
			},
		},
		table: {
			heading: [
				{
					key: "flow",
					label: "FLOWS_OVERVIEW.TABLE.HEADER.FLOW",
					cellType: typesConfig.cellTypes.imageDescription,
					width: "60%",
				},
				{
					key: "spacer",
					cellType: typesConfig.cellTypes.icon,
					width: "5%",
				},
				{
					key: "link",
					label: "FLOWS_OVERVIEW.TABLE.HEADER.LINK",
					cellType: typesConfig.cellTypes.button,
					width: "160px",
					minWidth: "160px",
					maxWidth: "160px",
				},
				{
					key: "send",
					label: "FLOWS_OVERVIEW.TABLE.HEADER.SEND_FLOW",
					cellType: typesConfig.cellTypes.button,
					width: "128px",
					minWidth: "128px",
					maxWidth: "128px",
				},
				{
					key: "spacerActions",
					cellType: typesConfig.cellTypes.icon,
					width: "5%",
				},
				{
					key: "isEnabled",
					label: "FLOWS_OVERVIEW.TABLE.HEADER.STATUS",
					cellType: typesConfig.cellTypes.boolean,
					width: "100px",
				},
				{
					key: "spacerLast",
					cellType: typesConfig.cellTypes.icon,
					width: "5%",
				},
				{
					key: "action",
					cellType: typesConfig.cellTypes.icon,
					width: "64px",
				},
			],
			fallbackMessage: "FLOWS_OVERVIEW.TABLE.FALLBACK",
		},
		flowTemplate: {
			header: {
				title: "FLOW_TEMPLATE_MODAL.HEADER.TITLE",
				description: "FLOW_TEMPLATE_MODAL.HEADER.DESCRIPTION",
			},
			lock: {
				title: "FLOW_TEMPLATE_MODAL.WARNING.TITLE",
				description: "FLOW_TEMPLATE_MODAL.WARNING.DESCRIPTION",
			},
			fallback: {
				description: "FLOW_TEMPLATE_MODAL.FALLBACK.DESCRIPTION",
				buttonLabel: "FLOW_TEMPLATE_MODAL.FALLBACK.BUTTON",
				img: icon.flowFallback,
			},
		},
		flowTemplateDetail: {
			overview: {
				id: "overview",
				icon: "arrow--left",
			},
			fallback: {
				description: "FLOW_DETAIL_TEMPLATE_MODAL.FALLBACK.DESCRIPTION",
				buttonLabel: "FLOW_DETAIL_TEMPLATE_MODAL.FALLBACK.BUTTON",
			},
		},
	},
	flowDetail: {
		id: "flowDetail",
		title: "FLOW_DETAIL.PAGE.TITLE",
		breadcrumbs: ({ team, name, flowId }) => [
			{
				to: routesConfig.dashboard.flow.overview.replace(":team", team),
				name: "FLOW_DETAIL.HEADER.BREADCRUMB.FLOWS",
			},
			{
				to: routesConfig.dashboard.flow.detail.replace(":team", team).replace(":flowId", flowId),
				name: name,
			},
		],
		tabTitle: "FLOW_DETAIL.PAGE.TITLE",
		aside: ({ team, flowId, flow }) => {
			let items = [
				{
					id: "overview",
					to: routesConfig.dashboard.flow.overview.replace(":team", team),
					icon: "arrow--left",
					label: "FLOW_DETAIL.ASIDE.BACK_TO_OVERVIEW.LABEL",
				},
				{
					id: "rapport",
					to: routesConfig.dashboard.flow.reports.replace(":team", team).replace(":flowId", flowId),
					icon: "documentation",
					label: "FLOW_DETAIL.ASIDE.REPORT.LABEL",
				},
			];

			if (flow?.template?.settings?.configurationEnabled) {
				items.push({
					id: "configuration",
					to: routesConfig.dashboard.flow.configuration.replace(":team", team).replace(":flowId", flowId),
					icon: "group",
					label: "FLOW_DETAIL.ASIDE.CONFIGURATION.LABEL",
				});
			}

			return [
				...items,
				{
					id: "statusTypes",
					to: routesConfig.dashboard.flow.statusTypes.replace(":team", team).replace(":flowId", flowId),
					icon: "story",
					label: "FLOW_DETAIL.ASIDE.STATUS_TYPES.LABEL",
				},
				// {
				// 	id: "automation",
				// 	to: routesConfig.dashboard.flow.rule.overview.replace(":team", team).replace(":flowId", flowId),
				// 	icon: "lightning-filled",
				// 	label: "FLOW_DETAIL.ASIDE.AUTOMATION.LABEL",
				// },
				{
					id: "settings",
					to: routesConfig.dashboard.flow.settings.replace(":team", team).replace(":flowId", flowId),
					icon: <SettingOutlined style={{ fontSize: "18px" }} />,
					label: "FLOW_DETAIL.ASIDE.SETTINGS.LABEL",
				},
			];
		},
		configuration: {
			lock: {
				title: "FLOW_DETAIL.CONFIGURATION.TITLE",
				description: "FLOW_DETAIL.CONFIGURATION.DESCRIPTION",
				src: icon.flowFallback,
			},
		},
		report: {
			fallback: {
				title: "FLOW_DETAIL.REPORT.FALLBACK.TITLE",
				description: "FLOW_DETAIL.REPORT.FALLBACK.DESCRIPTION",
				label: "FLOW_DETAIL.REPORT.FALLBACK.INPUT_LABEL",
				img: icon.reportFallback,
			},
		},
		statusTypes: {
			walkThrough: {
				title: "FLOW_DETAIL.STATUS_TYPE.WALK_THROUGH.TITLE",
				description: "FLOW_DETAIL.STATUS_TYPE.WALK_THROUGH.DESCRIPTION",
			},
			table: {
				max: 10,
				heading: [
					{
						key: "title",
						label: "FLOW_DETAIL.STATUS_TYPE.TABLE.HEADER.TITLE",
						cellType: typesConfig.cellTypes.description,
						width: "80%",
					},
					{
						key: "isDefault",
						label: "FLOW_DETAIL.STATUS_TYPE.TABLE.HEADER.IS_DEFAULT",
						cellType: typesConfig.cellTypes.icon,
						width: "96px",
					},
					{
						key: "spacing",
						cellType: typesConfig.cellTypes.icon,
						width: "32px",
					},
					{
						key: "isDone",
						label: "FLOW_DETAIL.STATUS_TYPE.TABLE.HEADER.IS_DONE",
						cellType: typesConfig.cellTypes.icon,
						width: "96px",
					},
					{
						key: "empty",
						cellType: typesConfig.cellTypes.icon,
						width: "20%",
					},
					{
						key: "edit",
						cellType: typesConfig.cellTypes.icon,
						width: "64px",
					},
				],
			},
			modal: {
				title: {
					create: "STATUS_TYPE_MODAL.HEADER.TITLE.CREATE",
					edit: "STATUS_TYPE_MODAL.HEADER.TITLE.EDIT",
				},
				submit: "STATUS_TYPE_MODAL.BUTTON.SUBMIT",
				cancel: "STATUS_TYPE_MODAL.BUTTON.CANCEL",
				delete: "STATUS_TYPE_MODAL.BUTTON.DELETE",
			},
			copyTypes: {
				buttonLabel: "STATUS_TYPE_COPY.BUTTONLABEL",
				modal: {
					title: "STATUS_TYPE_COPY.HEADER.TITLE",
					submit: "STATUS_TYPE_COPY.BUTTON_SUBMIT",
					cancel: "STATUS_TYPE_COPY.BUTTON_CANCEL",
				},
			},
			maxStatusTypes: {
				warning: {
					percentage: 0.9,
					description: "STATUS_TYPE.MAX.WARNING.DESCRIPTION",
				},
				limit: {
					description: "STATUS_TYPE.MAX.LIMIT.DESCRIPTION",
				},
			},
		},
		settings: {
			widgetSettings: {
				id: "widgetSettings",
				label: "FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.HEADER.TITLE",
				description: "FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.HEADER.DESCRIPTION",
			},
			dangerZone: {
				id: "dangerZone",
				label: "FLOW_DETAIL.SETTINGS.DANGER_ZONE.ACCORDION_TITLE",
				section: {
					title: "FLOW_DETAIL.SETTINGS.DANGER_ZONE.TITLE",
					description: "FLOW_DETAIL.SETTINGS.DANGER_ZONE.DESCRIPTION",
					buttonLabel: "FLOW_DETAIL.SETTINGS.DANGER_ZONE.BUTTON",
				},
			},
		},
	},
	integration: {
		facebook: {
			title: "INTEGRATION_MESSENGER.PAGE.TITLE",
			tabTitle: "INTEGRATION_MESSENGER.PAGE.TITLE",
			breadcrumbs: ({ team }) => [
				{
					to: routesConfig.dashboard.settings.root.replace(":team", team),
					name: "SETTINGS.INTEGRATIONS.OVERVIEW.HEADER.BREADCRUMB.SETTINGS",
				},
				{
					to: routesConfig.dashboard.settings.integrations.overview.replace(":team", team),
					name: "SETTINGS.INTEGRATIONS.OVERVIEW.HEADER.BREADCRUMB.PAGE_TITLE",
				},
				{
					to: routesConfig.dashboard.settings.integrations.messengerIntegration.replace(":team", team),
					name: "INTEGRATION_MESSENGER.BREADCRUMB.PAGE_TITLE",
				},
			],
			connect: {
				title: "INTEGRATION_MESSENGER.CONNECT.TITLE",
				description: "INTEGRATION_MESSENGER.CONNECT.DESCRIPTION",
				buttonLabel: "INTEGRATION_MESSENGER.CONNECT.BUTTON",
			},
			pageOverview: {
				title: "INTEGRATION_MESSENGER.PAGE_OVERVIEW.TITLE",
				description: "INTEGRATION_MESSENGER.PAGE_OVERVIEW.DESCRIPTION",
			},
		},
	},
	intentCategoryDetail: {
		id: "intentsOverview",
		title: "SETTINGS.INTENT.CATEGORIES.PAGE.TITLE",
		tabTitle: "SETTINGS.INTENT.CATEGORIES.PAGE.TITLE",
		breadcrumbs: ({ team, categoryId, categoryName }) => [
			{
				to: routesConfig.dashboard.bot.replace(":team", team),
				name: "SETTINGS.WIDGET.SETTINGS.PAGE.TITLE",
			},
			{
				to: routesConfig.dashboard.intents.intents.replace(":team", team),
				name: "SETTINGS.INTENT.CATEGORIES.HEADER.BREADCRUMB.CATEGORIES_TITLE",
			},
			{
				to: routesConfig.dashboard.intents.intentsOverview
					.replace(":team", team)
					.replace(":categoryId", categoryId),
				name: categoryName,
			},
		],
		fallback: {
			title: "SETTINGS.INTENT.CATEGORIES.FALLBACK.TITLE",
			description: "SETTINGS.INTENT.CATEGORIES.FALLBACK.DESCRIPTION",
			img: icon.emptyQuestions,
		},
		filter: {
			search: {
				placeholder: "SETTINGS.INTENT.CATEGORY_DETAIL.SEARCH.PLACEHOLDER",
			},
			dropdown: {
				selectedId: "noFilter",
				items: [
					{
						uuid: "noFilter",
						value: "SETTINGS.INTENT.CATEGORY_DETAIL.FILTER_DROPDOWN.NO_FILTER",
					},
					{
						uuid: "edited",
						value: "SETTINGS.INTENT.CATEGORY_DETAIL.FILTER_DROPDOWN.EDITED_INTENTS_ONLY",
					},
					{
						uuid: "custom",
						value: "SETTINGS.INTENT.CATEGORY_DETAIL.FILTER_DROPDOWN.UNEDITED_INTENTS_ONLY",
					},
				],
			},
		},
		table: {
			heading: [
				{
					key: "name",
					label: "SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.INTENT_NAME",
					cellType: typesConfig.cellTypes.string,
					width: "100%",
				},
				{
					key: "type",
					label: "SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.TYPE",
					cellType: typesConfig.cellTypes.translatedString,
					width: "96px",
				},
				{
					key: "isEdited",
					label: "SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.IS_EDITED",
					cellType: typesConfig.cellTypes.icon,
					width: "160px",
				},
				{
					key: "action",
					cellType: typesConfig.cellTypes.icon,
					width: "64px",
				},
			],
			fallbackMessage: "SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.FALLBACK",
		},
	},
	notifications: {
		id: "notifications",
		title: "NOTIFICATIONS.PAGE.TITLE",
		tabTitle: "NOTIFICATIONS.PAGE.TITLE",

		empty: {
			title: "NOTIFICATION.EMPTY.TITLE",
			description: "NOTIFICATION.EMPTY.DESCRIPTION",
			img: icon.emptyNotification,
		},

		header: {
			markAsSeen: "NOTIFICATIONS.HEADER.MARK_ALL_AS_SEEN",
		},
		table: {
			heading: [
				{
					key: "content",
					label: "NOTIFICATIONS.TABLE.HEADER.NOTIFICATIONS",
					cellType: typesConfig.cellTypes.description,
					width: "100%",
				},
				{
					key: "createdAt",
					label: "",
					cellType: typesConfig.cellTypes.date,
					width: "160px",
				},
			],
		},
	},
	profile: {
		overview: {
			id: "profile",
			title: "PROFILE.PAGE.TITLE",
			tabTitle: "PROFILE.PAGE.TITLE",
			accordion: {
				security: {
					id: "security",
					label: "PROFILE.SECURITY.ACCORDION_TITLE",
				},
			},
			fallback: {
				title: "PROFILE.FALLBACK.TITLE",
				description: "PROFILE.FALLBACK.DESCRIPTION",
				img: icon.noTeamFound,
			},
			popup: {
				destructive: {
					title: "POPUP.DESTRUCTIVE_PROFILE.TITLE",
					button: "POPUP.DESTRUCTIVE_PROFILE.DELETE_BUTTON",
					description: "POPUP.DESTRUCTIVE_PROFILE.DESCRIPTION",
				},
			},
		},
		teams: {
			id: "profile",
			title: "PROFILE_TEAMS.PAGE.TITLE",
			tabTitle: "PROFILE_TEAMS.PAGE.TITLE",
			filter: {
				search: {
					id: "search",
					placeholder: "PROFILE_TEAMS.SEARCH.PLACEHOLDER",
					className: "a-input--field-bordered a-input--icon",
				},
			},
			fallback: {
				title: "PROFILE_TEAMS.FALLBACK.TITLE",
				description: "PROFILE_TEAMS.FALLBACK.DESCRIPTION",
				label: "PROFILE_TEAMS.FALLBACK.LABEL",
				img: icon.noTeamFound,
			},
			table: {
				heading: [
					{
						key: "organization",
						label: "PROFILE_TEAMS.TABLE.HEADER.TEAM_NAME",
						cellType: typesConfig.cellTypes.profile,
						width: "100%",
					},
					{
						key: "action",
						cellType: typesConfig.cellTypes.icon,
						width: "64px",
					},
				],
				max: 20,
				fallbackMessage: "PROFILE_TEAMS.TABLE.FALLBACK",
			},
		},
		advancedSettings: {
			accordion: {
				dangerZone: {
					id: "dangerZone",
					label: "PROFILE.DANGER_ZONE.ACCORDION_TITLE",
					section: {
						title: "PROFILE.DANGER_ZONE.TITLE",
						description: "PROFILE.DANGER_ZONE.DESCRIPTION",
						buttonLabel: "PROFILE.DANGER_ZONE.BUTTON",
					},
				},
			},
		},
	},
	support: {
		id: "support",
		title: "SUPPORT.PAGE.TITLE",
		tabTitle: "SUPPORT.PAGE.TITLE",
	},
	modal: {
		connectUserAccount: {
			splashScreen: {
				id: "splashScreen",
				duration: 420_000, //7 minutes
				header: {
					executing: {
						title: "CONNECT_USER_ACCOUNT_MODAL.SPLASH_SCREEN.HEADER.EXECUTING.TITLE",
						description: "CONNECT_USER_ACCOUNT_MODAL.SPLASH_SCREEN.HEADER.EXECUTING.DESCRIPTION",
					},
					finished: {
						title: "CONNECT_USER_ACCOUNT_MODAL.SPLASH_SCREEN.HEADER.FINISHED.TITLE",
						description: "CONNECT_USER_ACCOUNT_MODAL.SPLASH_SCREEN.HEADER.FINISHED.DESCRIPTION",
					},
				},
				video: {
					src: videoUrl.inbox,
				},
				button: {
					label: "CONNECT_USER_ACCOUNT_MODAL.SPLASH_SCREEN.BUTTON.CLOSE",
				},
			},
		},
	},
	importContact: {
		indexPrefix: "contact_",
		contactList: {
			id: "contactList",
			actions: {
				fetchContacts: "fetchContacts",
				synchronizeContacts: "synchronizeContacts",
			},
			header: {
				title: "IMPORT_CONTACTS_MODAL.CONTACT_LIST.HEADER.TITLE",
				description: "IMPORT_CONTACTS_MODAL.CONTACT_LIST.HEADER.DESCRIPTION",
			},
			table: {
				heading: [
					{
						id: "checkbox",
						key: "checkbox",
						type: typesConfig.cellTypes.checkbox,
						cellType: typesConfig.cellTypes.checkbox,
						width: "48px",
					},
					{
						id: "username",
						key: "username",
						label: "IMPORT_CONTACTS_MODAL.CONTACT_LIST.TABLE.USERNAME",
						cellType: typesConfig.cellTypes.string,
						width: "50%",
					},
					{
						id: "email",
						key: "email",
						label: "IMPORT_CONTACTS_MODAL.CONTACT_LIST.TABLE.EMAIL",
						cellType: typesConfig.cellTypes.string,
						width: "50%",
					},
				],
				max: 20,
			},
			footer: {
				back: {
					label: "IMPORT_CONTACTS_MODAL.CONTACT_LIST.FOOTER.BACK.LABEL",
				},
				import: {
					label: "IMPORT_CONTACTS_MODAL.CONTACT_LIST.FOOTER.IMPORT.LABEL",
				},
			},
		},
		fileImport: {
			id: "fileImport",
			header: {
				title: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.HEADER.TITLE",
				description: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.HEADER.DESCRIPTION",
			},
			settings: {
				delimiter: {
					id: "delimiter",
					label: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.SETTINGS.DELIMITER.LABEL",
					placeholder: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.SETTINGS.DELIMITER.PLACEHOLDER",
					help: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.SETTINGS.DELIMITER.HELP",
				},
				render: {
					label: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.SETTINGS.BUTTON.RENDER",
				},
			},
			mappingHeader: {
				title: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.MAPPING_HEADER.TITLE",
				description: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.MAPPING_HEADER.DESCRIPTION",
			},
			validationCard: {
				title: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.VALIDATION.TITLE",
			},
			footer: {
				change: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.BUTTON.CHANGE_IMPORT_OPTIONS",
				import: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.BUTTON.IMPORT",
			},
		},
		importOptions: {
			id: "importOptions",
			header: {
				title: "IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.HEADER.TITLE",
				description: "IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.HEADER.DESCRIPTION",
			},
			infoBanner: {
				title: "IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.INFO_BANNER.TITLE",
				description: "IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.INFO_BANNER.DESCRIPTION",
			},
			defaultOptions: [
				{
					id: "csv",
					logo: "https://ui-avatars.com/api/?name=csv&background=FFFFFF&color=002140&size=224&length=3",
					label: "IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.OPTIONS.CSV.LABEL",
				},
			],
		},
		importSettings: {
			id: "importSettings",
			labelLimit: 15,
			header: {
				title: "IMPORT_CONTACTS_MODAL.IMPORT_SETTINGS.HEADER.TITLE",
				description: "IMPORT_CONTACTS_MODAL.IMPORT_SETTINGS.HEADER.DESCRIPTION",
			},
			mappingHeader: {
				title: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.MAPPING_HEADER.TITLE",
				description: "IMPORT_CONTACTS_MODAL.FILE_IMPORT.MAPPING_HEADER.DESCRIPTION",
			},
			footer: {
				change: "IMPORT_CONTACTS_MODAL.IMPORT_SETTINGS.BUTTON.CHANGE_SELECTION",
				import: "IMPORT_CONTACTS_MODAL.IMPORT_SETTINGS.BUTTON.IMPORT",
			},
		},
		splashScreen: {
			id: "splashScreen",
			header: {
				title: "IMPORT_CONTACTS_MODAL.SPLASH_SCREEN.HEADER.TITLE",
			},
			button: {
				label: "IMPORT_CONTACTS_MODAL.SPLASH_SCREEN.BUTTON.CLOSE",
			},
		},
	},
};
