import type {
	DOMConversionMap,
	DOMConversionOutput,
	DOMExportOutput,
	EditorConfig,
	LexicalEditor,
	LexicalNode,
	NodeKey,
	SerializedLexicalNode,
	Spread,
} from "lexical";

import { DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

const SignatureComponent = React.lazy(
	// @ts-ignore
	() => import("./signatureComponent")
);

function convertSignatureElement(domNode: HTMLElement): DOMConversionOutput | null {
	const signatureId = domNode.getAttribute("data-lexical-signature-id");

	if (signatureId) {
		const node = $createSignatureNode({ id: signatureId, content: domNode.innerHTML });

		node.__id = signatureId;
		node.__content = domNode.innerHTML;

		return { node };
	}

	const isPlaceholder = domNode.getAttribute("data-lexical-signature-placeholder");

	if (isPlaceholder === "true") {
		const node = $createSignatureNode({ isPlaceholder: true });

		node.__isPlaceholder = true;

		return { node };
	}

	return null;
}

interface ISignatureNode {
	id?: string;
	channelId?: string;
	content?: string;
	isPlaceholder?: boolean;
}

export type SerializedSignatureNode = Spread<ISignatureNode, SerializedLexicalNode>;
export class SignatureNode extends DecoratorNode<JSX.Element> {
	__id: string | undefined;
	__channelId: string | undefined;
	__content: string | undefined;
	__isPlaceholder = false;

	static getType(): string {
		return "signature";
	}

	static clone(node: SignatureNode): SignatureNode {
		return new SignatureNode({
			id: node.__id,
			key: node.__key,
			content: node.__content,
			channelId: node.__channelId,
			isPlaceholder: node.__isPlaceholder,
		});
	}

	static importJSON(serializedNode: SerializedSignatureNode): SignatureNode {
		return new SignatureNode({
			id: serializedNode.id,
			channelId: serializedNode.channelId,
		});
	}

	exportJSON(): SerializedSignatureNode {
		return {
			id: this.__id,
			type: "signature",
			content: this.__content,
			channelId: this.__channelId,
			isPlaceholder: this.__isPlaceholder,
			version: 1,
		};
	}

	constructor({ id, channelId, content, isPlaceholder, key }: ISignatureNode & { key?: NodeKey }) {
		super(key);
		this.__id = id;
		this.__content = content;
		this.__channelId = channelId;
		this.__isPlaceholder = isPlaceholder || false;
	}

	// View
	createDOM(config: EditorConfig): HTMLElement {
		const div = document.createElement("div");
		const className = config.theme.signature;

		div.className = `editor-signature_node-span ${className || ""}`;

		return div;
	}

	updateDOM(): false {
		return false;
	}

	isInline(): false {
		return false;
	}

	static importDOM(): DOMConversionMap<HTMLDivElement> | null {
		return {
			div: (domNode: HTMLDivElement) => {
				if (
					!domNode.hasAttribute("data-lexical-signature-id") &&
					!domNode.hasAttribute("data-lexical-signature-placeholder")
				)
					return null;

				return {
					conversion: convertSignatureElement,
					priority: 1,
				};
			},
		};
	}

	exportDOM(editor: LexicalEditor): DOMExportOutput {
		if (this.__isPlaceholder) return { element: null };

		const element = document.createElement("div");

		if (!this.__id || !this.__content) return { element };

		element.setAttribute("data-lexical-signature-id", this.__id);
		element.innerHTML = this.__content;

		return { element };
	}

	setChannelId(channelId: string): void {
		const self = this.getWritable();
		self.__channelId = channelId;
	}

	changeSignature({ id, content }: { id: string; content: string }): void {
		const self = this.getWritable();
		self.__id = id;
		self.__content = content;
		self.__isPlaceholder = false;
	}

	decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
		return (
			<Suspense fallback={null}>
				<SignatureComponent
					id={this.__id}
					nodeKey={this.getKey()}
					channelId={this.__channelId}
					content={this.__content}
				/>
			</Suspense>
		);
	}
}

export function $createSignatureNode({ id, channelId, content }: ISignatureNode): SignatureNode {
	return new SignatureNode({ id, channelId, content });
}

export function $isSignatureNode(node: LexicalNode | null): node is SignatureNode {
	return node instanceof SignatureNode;
}
