// SEE LEXICAL-MAIN repo
// packages > lexical-markdown > src > MarkdownTransformers.ts
import {
	INLINE_CODE,
	HIGHLIGHT,
	ELEMENT_TRANSFORMERS,
	TextFormatTransformer,
	TextMatchTransformer,
} from "@lexical/markdown";
import { $createLinkNode, $isLinkNode, LinkNode } from "@lexical/link";
import { $createTextNode, $isTextNode } from "lexical";

import { MentionNode, $isMentionNode, $createMentionNode } from "../nodes/mentioningNode";

export const BOLD_STAR: TextFormatTransformer = {
	format: ["bold"],
	tag: "*",
	type: "text-format",
};
export const BOLD_ITALIC_STAR: TextFormatTransformer = {
	format: ["italic", "bold"],
	intraword: false,
	tag: "_*",
	type: "text-format",
};
// export const BOLD_UNDERLINE: TextFormatTransformer = {
// 	format: ["underline", "bold"],
// 	intraword: false,
// 	tag: "__*",
// 	type: "text-format",
// };

export const STRIKETHROUGH: TextFormatTransformer = {
	format: ["strikethrough"],
	tag: "~",
	type: "text-format",
};

export const ITALIC: TextFormatTransformer = {
	format: ["italic"],
	tag: "_",
	type: "text-format",
};

// export const ITALIC_UNDERLINE: TextFormatTransformer = {
// 	format: ["italic", "underline"],
// 	tag: "___",
// 	type: "text-format",
// };

// export const UNDERLINE: TextFormatTransformer = {
// 	format: ["underline"],
// 	tag: "__",
// 	type: "text-format",
// };

export const LINK: TextMatchTransformer = {
	dependencies: [LinkNode],
	export: (node, _, exportFormat) => {
		if (!$isLinkNode(node)) return null;

		const firstChild = node.getFirstChild();
		const linkContent = node.getTextContent()
			? `<${node.getURL()}|${node.getTextContent()}>`
			: `<${node.getURL()}>`;
		// Add text styles only if link has single text node inside. If it's more
		// then one we ignore it as markdown does not support nested styles for links
		if (node.getChildrenSize() === 1 && $isTextNode(firstChild)) {
			return exportFormat(firstChild, linkContent);
		}

		return linkContent;
	},
	importRegExp: /(?:\<([^\<\|@]+)\|)(?:([^\<\|\@]+)\>)|(?:\<([^\<\|\@]+)>)/,
	regExp: /(?:\<([^\<\|@]+)\|)(?:([^\<\|\@]+)\>)|(?:\<([^\<\|\@]+)>)$/,
	replace: (textNode, match) => {
		const [, linkUrl, linkText, linkWithoutTitle] = match;
		const linkNode = $createLinkNode(linkUrl || linkWithoutTitle, { target: "__blank" });
		const linkTextNode = $createTextNode(linkText || linkWithoutTitle);

		linkTextNode.setFormat(textNode.getFormat());
		linkNode.append(linkTextNode);
		textNode.replace(linkNode);
	},
	trigger: ">",
	type: "text-match",
};
export const MAIL_LINK: TextMatchTransformer = {
	dependencies: [LinkNode],
	export: (node, _, exportFormat) => {
		if (!$isLinkNode(node)) return null;

		const firstChild = node.getFirstChild();
		const linkContent = node.getTextContent()
			? `<${node.getURL()}|${node.getTextContent()}>`
			: `<${node.getURL()}>`;
		// Add text styles only if link has single text node inside. If it's more
		// then one we ignore it as markdown does not support nested styles for links
		if (node.getChildrenSize() === 1 && $isTextNode(firstChild)) {
			return exportFormat(firstChild, linkContent);
		}

		return linkContent;
	},
	importRegExp: /(?:\<mailto:([^\<\|]+)\|)(?:([^\<\|]+)\>)|(?:\<mailto:([^\<\|]+)>)/,
	regExp: /(?:\<mailto:([^\<\|]+)\|)(?:([^\<\|]+)\>)|(?:\<mailto:([^\<\|]+)>)$/,
	replace: (textNode, match) => {
		const [, linkUrl, linkText, linkWithoutTitle] = match;
		const linkNode = $createLinkNode(`mailto:${linkUrl}` || linkWithoutTitle, { target: "__blank" });
		const linkTextNode = $createTextNode(linkText || linkWithoutTitle);

		linkTextNode.setFormat(textNode.getFormat());
		linkNode.append(linkTextNode);
		textNode.replace(linkNode);
	},
	trigger: ">",
	type: "text-match",
};
export const MENTIONING: TextMatchTransformer = {
	dependencies: [MentionNode],
	export: (node) => {
		if (!$isMentionNode(node)) return null;

		const linkContent = `<@${node.getMentionId()}|${node.getMentionName()}>`;

		return linkContent;
	},
	importRegExp: /(?:\<@([^@|]+)\|)(?:([^<]+)\>)/,
	regExp: /(?:\<@([^<@|]+)\|)(?:([^<]+)\>)$/,
	replace: (textNode, match) => {
		const [, id, name] = match;
		const mentionNode = $createMentionNode({ id, name });

		textNode.replace(mentionNode);
	},
	trigger: ">",
	type: "text-match",
};

export const TEXT_FORMAT_TRANSFORMERS = [
	INLINE_CODE,
	// UNDERLINE,
	// BOLD_UNDERLINE,
	// ITALIC_UNDERLINE,
	BOLD_ITALIC_STAR,
	BOLD_STAR,
	HIGHLIGHT,
	ITALIC,
	STRIKETHROUGH,
];

export const TEXT_MATCH_TRANSFORMERS = [MENTIONING, LINK, MAIL_LINK];

export const TRANSFORMERS = [...TEXT_MATCH_TRANSFORMERS, ...ELEMENT_TRANSFORMERS, ...TEXT_FORMAT_TRANSFORMERS];
