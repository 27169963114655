const baseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:8980"; //eslint-disable-line no-undef
const fileUrl = process.env.REACT_APP_FILE_SERVER_HOST || "http://localhost:4449"; //eslint-disable-line no-undef

const endpoints = {
	api: "/api/v1",
};

export default {
	baseUrl,
	endpoints,
	flow: {
		getAll: `${baseUrl}${endpoints.api}/flows`,
		getById: `${baseUrl}${endpoints.api}/flows/:id`,
		create: `${baseUrl}${endpoints.api}/flows`,
		update: `${baseUrl}${endpoints.api}/flows/:id`,
		delete: `${baseUrl}${endpoints.api}/flows/:id`,
	},
	report: {
		getAll: `${baseUrl}${endpoints.api}/flows/:id/reports`,
		getTemplates: `${baseUrl}${endpoints.api}/flows/:id/reports/template`,
		getById: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId`,
		download: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId/download`,
		update: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId`,
		assign: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId/assign`,
		updateStatusType: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId/status_type`,
		delete: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId`,
		attachments: `${fileUrl}/v1/serve/flows/:flowId/reports/:reportId/attachments`,
	},
	rules: {
		getHistory: `${baseUrl}${endpoints.api}/rule/history`,
		execute: `${baseUrl}${endpoints.api}/rule/:ruleId`,
	},
};
