import type { TAdminPulsStatus, TFlowActions } from "@bothive_core/database";
import { defineMessages } from "react-intl";

export const flowActionsI18n = defineMessages<TFlowActions>({
	send: { id: "flow_actions.send", defaultMessage: "send" },
	completed: { id: "flow_actions.completed", defaultMessage: "completed" },
	reviewed: { id: "flow_actions.reviewed", defaultMessage: "reviewed" },
});
export const flowActionsOptionI18n = defineMessages<TFlowActions>({
	send: { id: "admin_pulse.tasks.flow_actions.options.send", defaultMessage: "Flow is send" },
	completed: { id: "admin_pulse.tasks.flow_actions.options.completed", defaultMessage: "Report is completed" },
	reviewed: { id: "admin_pulse.tasks.flow_actions.options.reviewed", defaultMessage: "Report is reviewed" },
});
export const flowStatusI18n = defineMessages<TAdminPulsStatus>({
	todo: { id: "admin_pulse.tasks.status.options.todo", defaultMessage: "Todo" },
	inProgress: { id: "admin_pulse.tasks.status.options.inProgress", defaultMessage: "In progress" },
	done: { id: "admin_pulse.tasks.status.options.done", defaultMessage: "Done" },
});
