import React from "react";

import { Component } from "..";

export default function ANormalHeader({
	id,
	value,
	placeholder,
	active,
	disabled,
	className,
	iconClass,
	onClick,
	onKeyUp,
	setRef,
}) {
	let body = <p className="placeholder t-gap--right-sm">{placeholder}</p>;

	if (value) {
		body = (
			<div className="header-wrapper t-gap--right-sm">
				<img src={value.avatar} alt="" className="header-image" />
				<p>{value.username}</p>
			</div>
		);
	}

	return (
		<div
			id={id}
			role="button"
			tabIndex="0"
			aria-pressed={active}
			aria-expanded={active}
			onClick={onClick}
			onKeyUp={onKeyUp}
			ref={setRef}
			data-disabled={disabled}
			className={`a-dropdown-header a-dropdown--user-header ${className} ${active ? "active" : ""}`}
		>
			{body}
			<Component.AIcon type={`arrow--${active ? "up" : "down"}`} className={`fill ${iconClass}`} />
		</div>
	);
}
