import settingsConfig from "@/modules/settings/config";

export default {
	header: {
		id: "settings",
		title: "SETTINGS.NOTIFICATIONS.PAGE.TITLE",
		tabTitle: "SETTINGS.NOTIFICATIONS.PAGE.TITLE",
		breadcrumbs: ({ team }) => [
			{
				to: settingsConfig.routes.root.replace(":team", team),
				name: "SETTINGS.NOTIFICATIONS.BREADCRUMB.SETTINGS",
			},
			{
				to: settingsConfig.routes.notification.replace(":team", team),
				name: "SETTINGS.NOTIFICATIONS.BREADCRUMB.TITLE",
			},
		],
	},
};
