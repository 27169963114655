import React from "react";

import Container from "./_atoms.dropdown.container";
import ListItemUser from "./_atoms.dropdown.list_item_user";
import UserHeader from "./_atoms.dropdown.user_header";

export default function AUserPicker({ items, className, ...props }) {
	const formattedItems =
		items && items.filter((item) => item).map((item) => ({ ...item, value: item, uuid: item._id || item.id }));

	return (
		<Container
			{...props}
			className={className}
			items={formattedItems}
			header={(props) => <UserHeader {...props} />}
			render={(props) => <ListItemUser {...props} user={props.item} />}
		/>
	);
}

AUserPicker.defaultProps = {
	items: [],
	value: undefined,
	className: "",
	headerClassName: "",
	iconClass: "",
	onChange: undefined,
};
