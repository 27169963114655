import React from "react";
import { FormattedMessage } from "react-intl";

import { Component } from "../../atoms";

export default function MLoader({ label, className }) {
	return (
		<div className={`m-article m-article--loader ${className}`}>
			<Component.AIcon type="loader--inner" className="t-gap--bottom" />
			<p>
				<FormattedMessage id={label} />
			</p>
		</div>
	);
}

MLoader.defaultProps = {
	label: "",
	className: "",
};
