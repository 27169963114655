import axios from "axios";

import { queryStringHelpers } from "@/shared/helpers";

import config from "./config";

export const getFlowTemplates = async ({ compact }) => {
	const query = queryStringHelpers.createQueryString({
		compact,
	});

	const { data } = await axios.get(`${config.api.getAllTemplates}${query}`);

	return data;
};

export const createFlow = async ({ templateId }) => {
	const { data } = await axios.post(config.api.create, {
		templateId,
		isEnabled: true,
	});

	if (!data.success) {
		throw new Error();
	}

	return data;
};
