import { FormattedMessage } from "react-intl";
import "./style.scss";

interface IAsidePreview {
	list: string[];
}

export default function AsidePreview({ list }: IAsidePreview) {
	return (
		<div className="setup-aside_preview setup_invite_members-aside_preview">
			<div className="aside_preview-aside"></div>
			<div className="aside_preview-body">
				<div className="aside_preview-header" />
				<div className="aside_preview-content">
					<div className="aside_preview-content-aside">
						<div className="aside_preview-content-aside-item" />
						<div className="aside_preview-content-aside-item" />
						<div className="aside_preview-content-aside-item" />
					</div>
					<div className="aside_preview-content-report">
						<div className="aside_preview-content-report-title" />
						<div className="aside_preview-content-report-body" />
					</div>

					<div className="aside_preview-content-meta_aside">
						<div className="aside_preview-content-meta_aside-input_group">
							<p className="aside_preview-content-meta_aside-input_label">
								<FormattedMessage
									id="setup.invite_members.aside.image.assign"
									defaultMessage="Assign"
								/>
							</p>
							<div className="aside_preview-content-meta_aside-input" />

							{list?.length > 0 && (
								<div className="aside_preview-content-meta_aside-input_list">
									{list.map((item) => (
										<p key={item}>{item}</p>
									))}
								</div>
							)}
						</div>
						<div className="aside_preview-content-meta_aside-group">
							<div className="aside_preview-content-meta_aside-item" />
							<div className="aside_preview-content-meta_aside-item" />
							<div className="aside_preview-content-meta_aside-item" />
						</div>
						<div className="aside_preview-content-meta_aside-group">
							<div className="aside_preview-content-meta_aside-item" />
							<div className="aside_preview-content-meta_aside-item" />
							<div className="aside_preview-content-meta_aside-item" />
						</div>
						<div className="aside_preview-content-meta_aside-group">
							<div className="aside_preview-content-meta_aside-item" />
							<div className="aside_preview-content-meta_aside-item" />
							<div className="aside_preview-content-meta_aside-item" />
							<div className="aside_preview-content-meta_aside-item" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
