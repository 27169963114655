export default {
	MEMBERS_IS_FETCHING: "MEMBERS_IS_FETCHING",
	FETCH_MEMBERS_FAILED: "FETCH_MEMBERS_FAILED",
	FETCH_MEMBERS_SUCCEEDED: "FETCH_MEMBERS_SUCCEEDED",

	FETCH_MEMBERS: "FETCH_MEMBERS",

	UPDATE_MEMBER_FILTERS: "UPDATE_MEMBER_FILTERS",

	TEAM_CHANGED: "TEAM_CHANGED", //listen to actions from the team structure
	FETCH_TEAM: "FETCH_TEAM",
};
