import { ExclamationCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";
import React from "react";

export default function ShowFailedBanner({ title, description = undefined, duration = 4.5 }) {
	let banner = {};

	if (title) banner.message = title;
	if (description) banner.description = description;

	notification.open({
		...banner,
		icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
		placement: "bottomRight",
		duration,
	});
}
