import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { routesConfig } from "../../config";
import { storageHelpers } from "../../helpers";
import { stringHelpers } from "../../shared/helpers";
import { actions as contentActions } from "../../store/content";
import { trpc } from "../../trpc";
import { desktopNotification, requestDeskNotificationPermission } from "@/shared/helpers/notification.helpers";

// All init actions to prevent rerender overhead in template
export default function initDashboard() {
	const inboxId = useRef();
	const teamId = useRef();
	const history = useHistory();
	const dispatch = useDispatch();
	const routeParameters = useParams<{ team: string }>();
	const trpcUtils = trpc.useUtils();
	const state = useSelector((state: any) => ({
		team: state.content.team.team,
		teamFailed: state.content.team.fetchingFailed,
		user: state.profile.account.user,
		chatbot: state.content.team.team.chatbot,
		members: state.content.organization.members.list,
	}));

	const { data: inbox } = trpc.inbox.inbox.getUserInbox.useQuery();

	requestDeskNotificationPermission();

	trpc.notification.notifications.subscribeNotification.useSubscription(
		{
			organizationId: state.team?._id,
			token: storageHelpers.getUserToken(),
		},
		{
			enabled: Boolean(state.team),
			onData(data) {
				trpcUtils.notification.notifications.getNotifications.invalidate();

				// Internal messages get's own notification
				if (data.notification.type !== "inbox.mentioned") {
					// const

					desktopNotification({
						title: stringHelpers.stripMarkdown(data.notification.content.title || ""),
						message: stringHelpers.stripMarkdown(data.notification.content.description || ""),
						onClick: () => {
							if (!data.notification.type.includes("inbox")) return;

							history.push(
								"/:team/inbox/conversation/:conversationId"
									.replace(":team", routeParameters.team)
									.replace(
										"/:conversationId",
										data.notification.entityId ? `/${data.notification.entityId}` : ""
									)
							);
						},
					});
				}
			},
		}
	);

	trpc.inbox.inboxLabel.organizationLabelUpdate.useSubscription(
		{
			organizationId: state.team?._id,
			token: storageHelpers.getUserToken(),
		},
		{
			enabled: Boolean(state.team),
			onData() {
				trpcUtils.inbox.inboxLabel.getAll.invalidate();
				trpcUtils.inbox.inbox.getInboxStatsFixedFolders.invalidate();
				trpcUtils.inbox.inbox.getInboxStatsLabels.invalidate();
			},
		}
	);

	useEffect(() => {
		if (!inbox?._id) return;

		if (!inboxId.current && inbox?._id !== inboxId.current) {
			// Join new room if user changes inbox
			inboxId.current = inbox?._id; // Keep current connect inbox id between rerenders
		}
	}, [inbox?._id]);

	useEffect(() => {
		if (!state.team || state.team === teamId.current) return;

		dispatch(contentActions.flow.flow.fetchFlows()); //todo use recoil
		teamId.current = state.team._id;
	}, [state.team]);

	useEffect(() => {
		const teamSlug = routeParameters.team || storageHelpers.getTeamSlug();

		if (!teamSlug) {
			history.push(routesConfig.profile.overview.replace(":slug", state.user.slug));
			return;
		}

		// trim necessary to remove hidden values from router parameter
		if (state.team.slug.trim() === teamSlug.trim()) return;

		dispatch(contentActions.team.fetchTeam(teamSlug));
	}, [routeParameters.team, state.team?.slug]);

	return null;
}
