import "./style.scss";
import AssignedInboxLabels from "@/modules/inbox/components/AssignedInboxLabels";
import { ExportOutlined, UserOutlined } from "@ant-design/icons";
import { Typography, Divider, Tooltip, Tag, Button, Flex } from "antd";
import { useIntl } from "react-intl";
import { TConversationWithContactResponse } from "../..";
import { useHistory, useParams } from "react-router-dom";
import { Fragment, ReactNode } from "react";
import { dateHelpers } from "@/shared/helpers";
import AvatarGroup from "@/shared/components/antdWrappers/AvatarGroup";
import Avatar from "@/modules/inbox/components/Avatar";

type ContactConversationPanelHeaderProps = TConversationWithContactResponse[number] & {
	showParticipantIndicator: boolean;
};

export default function ContactConversationPanelHeader({
	_id,
	firstMessage,
	channelsPopulated,
	inboxLabelsPopulated,
	participantsPopulated,
	assignedToUser,
	attachments,
	isParticipant,
	updatedAt,
	showParticipantIndicator,
}: ContactConversationPanelHeaderProps) {
	const intl = useIntl();
	const routeParams = useParams<{ team: string }>();
	const history = useHistory();

	function handleNavigateToInbox() {
		history.push(
			"/:team/inbox/conversation/:conversationId"
				.replace(":team", routeParams.team)
				.replace(":conversationId", _id)
		);
	}

	const subject = firstMessage?.subject
		? firstMessage?.subject
		: intl.formatMessage({ id: "conversation.subject.empty", defaultMessage: "(No subject)" });

	const headerItems = [
		// CHANNELS
		!!channelsPopulated.length && (
			<HeaderItem
				label={intl.formatMessage(
					{
						id: "contact.conversation_list.conversation.channel.label",
						defaultMessage: "{count, plural, one {Channel} other {Channels}}",
					},
					{ count: channelsPopulated.length }
				)}
			>
				<AvatarGroup maxCount={2} size="small">
					{channelsPopulated.map((channel) => {
						if (!channel) return;

						return (
							<Tooltip
								key={channel._id}
								title={
									channel.syncStatus === "DELETING"
										? intl.formatMessage(
												{
													id: "inbox.list.item.channel.deleted.tooltip",
													defaultMessage: "{channelAddress} (DELETE IN PROGRESS)",
												},
												{ channelAddress: channel?.address }
										  )
										: channel.address
								}
								overlayStyle={{ maxWidth: "500px" }}
							>
								<Avatar
									style={{
										border: "none",
										...(channel.syncStatus === "DELETING" && {
											filter: "grayscale(100%)",
										}),
									}}
									src={channel.logo}
								/>
							</Tooltip>
						);
					})}
				</AvatarGroup>
			</HeaderItem>
		),
		// PARTICIPANTS

		<HeaderItem
			label={intl.formatMessage(
				{
					id: "contact.conversation_list.conversation.participants.label",
					defaultMessage: "{count, plural, one {Participant} other {Participants}}",
				},
				{ count: participantsPopulated?.length }
			)}
		>
			<AvatarGroup maxCount={2} size="small">
				{participantsPopulated?.map((participant) => {
					if (!participant) return;

					return (
						<Tooltip title={participant.name} key={participant._id}>
							<Avatar
								src={String(participant.avatar)}
								status={participant.labels.includes("CLOSED") ? "closed" : "open"}
							/>
						</Tooltip>
					);
				})}
			</AvatarGroup>
		</HeaderItem>,
		// ASSIGNED_TO
		assignedToUser && (
			<HeaderItem
				label={intl.formatMessage({
					id: "contact.conversation_list.conversation.assigned_to.label",
					defaultMessage: "Assigned to",
				})}
			>
				<Tooltip title={assignedToUser.name}>
					<Avatar
						size="small"
						src={String(assignedToUser.avatar)}
						status={
							participantsPopulated
								?.find((participant) => participant.userId === assignedToUser.userId)
								?.labels.includes("CLOSED")
								? "closed"
								: "open"
						}
					/>
				</Tooltip>
			</HeaderItem>
		),
		// ATTACHMENTS
		!!attachments && (
			<HeaderItem
				label={intl.formatMessage(
					{
						id: "contact.conversation_list.conversation.attachments.label",
						defaultMessage: "{count, plural, one {Attachment} other {Attachments}}",
					},
					{ count: attachments }
				)}
			>
				<Typography.Text strong>{attachments}</Typography.Text>
			</HeaderItem>
		),
		// INBOX_LABELS
		!!inboxLabelsPopulated?.length && (
			<HeaderItem
				label={intl.formatMessage(
					{
						id: "contact.conversation_list.conversation.labels.label",
						defaultMessage: "{count, plural, one {Label} other {Labels}}",
					},
					{ count: inboxLabelsPopulated?.length }
				)}
			>
				<AssignedInboxLabels labels={inboxLabelsPopulated} maxVisible={3} />
			</HeaderItem>
		),
	].filter(Boolean);

	return (
		<header className="ccph-panel-header">
			<Typography.Text
				title={subject}
				strong
				ellipsis
				style={{ maxWidth: "50ch", gridArea: "subject", fontSize: "1.6rem" }}
			>
				{subject}
			</Typography.Text>
			<Flex align="center" style={{ gridArea: "data" }}>
				{headerItems.map((item, index) => (
					<Fragment key={index}>
						{item}
						{index !== headerItems.length - 1 && (
							<Divider className="ccph-panel-header__divider" type="vertical" />
						)}
					</Fragment>
				))}
			</Flex>
			<Typography.Text style={{ gridArea: "time", justifySelf: "end" }} type="secondary">
				{dateHelpers.getRelativeDate({ date: updatedAt, locale: intl.locale, alwaysIncludeTime: true })}
			</Typography.Text>
			<Flex align="center" style={{ gridArea: "actions", justifySelf: "end" }}>
				{showParticipantIndicator && (
					<>
						<Tooltip
							placement="left"
							title={
								isParticipant
									? intl.formatMessage({
											id: "contact.conversation.participant_status.participant",
											defaultMessage: "You are a participant of this conversation",
									  })
									: intl.formatMessage({
											id: "contact.conversation.participant_status.non_participant",
											defaultMessage: "You are not a participant of this conversation",
									  })
							}
						>
							<Tag
								style={{ margin: 0 }}
								icon={<UserOutlined />}
								color={isParticipant ? "var(--success)" : "var(--error)"}
							/>
						</Tooltip>
						<Divider className="ccph-panel-header__divider" type="vertical"></Divider>
					</>
				)}
				<Tooltip
					placement="left"
					title={
						isParticipant
							? intl.formatMessage({
									id: "contact.conversation_list.item.open_in_inbox.btn.tooltip",
									defaultMessage: "Open conversation in inbox",
							  })
							: intl.formatMessage({
									id: "contact.conversation_list.item.open_in_inbox.btn.disabled.tooltip",
									defaultMessage:
										"You are not a participant, you are not able to see this conversation in your inbox",
							  })
					}
				>
					<Button
						disabled={!isParticipant}
						onClick={() => handleNavigateToInbox()}
						type="primary"
						size="small"
						icon={<ExportOutlined />}
					></Button>
				</Tooltip>
			</Flex>
		</header>
	);
}

interface HeaderItemProps {
	label: string;
	children: ReactNode;
}

function HeaderItem({ label, children }: HeaderItemProps) {
	return (
		<Flex gap="var(--spacer-xs)" align="center">
			<Typography.Text type="secondary" strong>
				{label}
			</Typography.Text>
			{children}
		</Flex>
	);
}
