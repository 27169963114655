import { Button, Result } from "antd";
// import qs from "qs";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValueLoadable } from "recoil";

import { Layout } from "@/components/templates";
import { routesConfig } from "@/flows/common/config";
import { flowState, selectedFlowIdState } from "@/flows/common/state";
// import { searchFilterState, selectedProgressState, selectedStatusTypeState } from "@/flows/report/overview/state";
import { selectedReportIdState } from "@/flows/report_detail/state";
import Aside from "@/shared/components/aside";
import DashboardCard from "@/shared/components/card/dashboard";
import { useCollapsedAside } from "@/shared/hooks";

import BrowserHeader from "./browser_header";
import config from "./config";
import style from "./style.module.scss";

export default function ReportDetailTemplate({ children }) {
	useCollapsedAside();

	const intl = useIntl();
	const history = useHistory();
	const routerParameters = useParams();
	const [selectedFlowId, setSelectedFlowId] = useRecoilState(selectedFlowIdState);
	const [selectedReportId, setSelectedReportId] = useRecoilState(selectedReportIdState);
	// const searchFilter = useRecoilValue(searchFilterState);
	// const selectedStatus = useRecoilValue(selectedStatusTypeState);
	// const selectedProgress = useRecoilValue(selectedProgressState);
	const flow = useRecoilValueLoadable(flowState({ id: routerParameters.flowId }));

	useEffect(() => {
		if (flow.state !== "hasValue") {
			return;
		}

		if (!flow.contents) {
			handleGoToFlowOverview();
		}
	}, [flow]);

	useEffect(() => {
		if (selectedFlowId !== routerParameters?.flowId) {
			setSelectedFlowId(routerParameters?.flowId);
		}

		if (selectedReportId !== routerParameters?.reportId) {
			setSelectedReportId(routerParameters?.reportId);
		}

		return () => {
			setSelectedReportId(undefined);
		};
	}, []);

	const handleGoToFlowOverview = () => {
		const url = routesConfig.overview?.replace(":team", routerParameters.team);

		history.push(url);
	};

	const renderFlowContent = () => {
		if (flow.state === "loading") {
			return <Layout.TLoadScreen className="sub-page" />;
		}

		if (flow.state === "hasError") {
			return (
				<div className={style.report_detail_fallback_content}>
					<DashboardCard>
						<Result
							title={intl.formatMessage({ id: "FLOWS.REPORT.FAILED.TITLE" })}
							extra={
								<Button type="primary" onClick={handleGoToFlowOverview}>
									<FormattedMessage id="FLOWS.REPORT.FAILED.BUTTON" />
								</Button>
							}
						/>
					</DashboardCard>
				</div>
			);
		}

		return children;
	};

	const generateReportOverviewLink = () => {
		const url = routesConfig.reports
			.replace(":team", routerParameters?.team)
			.replace(":flowId", routerParameters?.flowId);

		// todo revert when query param handeling is fixed
		// let queryParamsObject = {};
		//
		// if (searchFilter?.length && searchFilter) {
		// 	// check for string length as we can omit empty query params
		// 	queryParamsObject[queryParamsConfig.search] = searchFilter;
		// }
		//
		// if (selectedStatus?.length && selectedStatus) {
		// 	queryParamsObject[queryParamsConfig.status] = selectedStatus;
		// }
		//
		// if (selectedProgress?.length && selectedProgress) {
		// 	queryParamsObject[queryParamsConfig.progress] = selectedProgress;
		// }
		//
		// const queryParams = qs.stringify(queryParamsObject, { skipNulls: true });
		//
		// if (Object.keys(queryParams).length) {
		// 	return `${url}?${queryParams}`;
		// }

		return url;
	};

	return (
		<main className={style.report_detail_template}>
			<BrowserHeader />
			<Aside
				items={config.aside({
					team: routerParameters?.team,
					flowId: routerParameters?.flowId,
					isRemindersEnabled: flow?.contents?.template?.settings?.remindersEnabled,
					isConfigurationEnabled: flow?.contents?.template?.settings?.configurationEnabled,
					reportOverviewLink: generateReportOverviewLink(),
				})}
			/>
			<div className={style.report_detail_content}>{renderFlowContent()}</div>
		</main>
	);
}
