import "./style.scss";

import { useState } from "react";

import { Section } from "@/components/organisms";
import { DestructivePanel } from "@/shared/components/collapse";
import config from "./config";
import ConfirmDelete from "./confirm_delete";

export default function Destructive({ apiKey, ...collapseProps }) {
	const [isConfirmVisible, toggleConfirm] = useState(false);

	return (
		<>
			<DestructivePanel
				key={config.id}
				{...collapseProps} // Get all functional props from parent collapse component
				title={config.title}
			>
				<Section.ODestructive {...config.section} onClick={() => toggleConfirm(true)} />
			</DestructivePanel>
			<ConfirmDelete apiKey={apiKey} visible={isConfirmVisible} onClose={() => toggleConfirm(false)} />
		</>
	);
}
