import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
	$createLineBreakNode,
	$createParagraphNode,
	$getNodeByKey,
	$insertNodes,
	COMMAND_PRIORITY_EDITOR,
	createCommand,
	LexicalCommand,
} from "lexical";
import { useEffect, useState } from "react";

import { $createSignatureNode, $isSignatureNode, SignatureNode } from "../nodes/signature_node";

export type IInsertSignatureCommandPayload = Readonly<{
	id?: string;
	channelId?: string;
}>;

export const INSERT_SIGNATURE_COMMAND: LexicalCommand<IInsertSignatureCommandPayload> =
	createCommand("INSERT_SIGNATURE_COMMAND");

export function SignaturePlugin({ channelId }: { channelId?: string }): JSX.Element | null {
	const [editor] = useLexicalComposerContext();
	const [signatureNodeKey, setSignatureNodeKey] = useState<string>();

	useEffect(() => {
		if (!editor.hasNodes([SignatureNode])) return;

		return mergeRegister(
			editor.registerCommand<IInsertSignatureCommandPayload>(
				INSERT_SIGNATURE_COMMAND,
				({ id, channelId }) => {
					const node = $createSignatureNode({ id, channelId });

					if (signatureNodeKey) {
						const existingNode = $getNodeByKey(signatureNodeKey);

						if (existingNode) {
							editor.update(() => {
								$getNodeByKey(signatureNodeKey)?.replace(node);
								setSignatureNodeKey(node.getKey());
							});
							return true;
						}
					}

					const nodes = [$createLineBreakNode(), node, $createParagraphNode()];

					try {
						$insertNodes(nodes);
					} catch (_) {
						try {
							$insertNodes([$createParagraphNode(), ...nodes]);
						} catch (error) {
							console.error("Insert signature failed", error);
						}
					}

					setSignatureNodeKey(node.getKey());

					return true;
				},
				COMMAND_PRIORITY_EDITOR
			)
		);
	}, [editor, signatureNodeKey]);

	useEffect(() => {
		if (!signatureNodeKey || !channelId) return;

		editor.update(() => {
			const node = $getNodeByKey<SignatureNode>(signatureNodeKey);

			if (!node) return;

			node.setChannelId(channelId);
		});
	}, [editor, channelId, signatureNodeKey]);

	useEffect(() => {
		// Get reference from signature on editor initialization
		// If we don't do this channelId changes wont get triggered
		if (!editor.hasNodes([SignatureNode])) return;

		editor.getEditorState().read(() => {
			const nodeKey = [...editor._keyToDOMMap.keys()].find((nodeKey) => $isSignatureNode($getNodeByKey(nodeKey)));

			setSignatureNodeKey(nodeKey);
		});
	}, []);

	return null;
}
