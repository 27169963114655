import type { IntlShape } from "react-intl";
import axios from "axios";

import { apiConfig, labelConfig } from "../config";
import debounce from "./debounce.helpers";
import stringHelpers from "./string.helpers";
import { isAfter, isBefore, isSameDay, startOfDay } from "date-fns";

export const PHONE_REGEX = /^[\d\/ .+\p{L}()]+$/u; //regex pattern that accepts "/", space, ".", "+", a range of numbers between 0 and 9, all alphabetic characters, and parentheses

export default {
	arrayValidator(control) {
		if (control.value.length > 0) {
			return null;
		}

		return { arrayValidator: true };
	},
	botNotificationTrigger(control) {
		let succeeded = true;

		control.value.forEach((item) => {
			if ((!item.variable.length || !item.operator.length) && item.value) {
				succeeded = false;
			}
		});

		if (succeeded) {
			return null;
		}

		return { botNotificationTrigger: true };
	},
	comparePasswords(control) {
		if (!control.value) {
			return null;
		}

		if (control._parent.value.password?.length < 4 || control._parent.value.repeatPassword?.length < 4) {
			return null;
		}

		if (
			control._parent.value.password === control.value ||
			control._parent.value.repeatPassword === control.value
		) {
			return null;
		}

		return { passwordCompare: true };
	},
	urlValidator(url) {
		const rex = /^(https?):\/\/(-\.)([^\s/?.#-]+\.?)+(\/[^\s]*)$/i;

		return rex.test(url);
	},
	templateLength(maxLength) {
		return (control) => {
			const strippedString = stringHelpers.stripTemplate(control.value);

			if (strippedString.length > maxLength) {
				return { maxLength: true };
			}

			return null;
		};
	},
	numberValidator: async (control) => {
		if (!stringHelpers.isEmpty(control.value) && !/[+-]?([0-9]*[.])[0-9]+/.test(control.value)) {
			return { numberValidator: true };
		}

		return null;
	},
	passwordValidator: async (control) => {
		if (control.value.length < 1) {
			return { passwordValidator: true };
		}

		const result = await axios.post(apiConfig.user.validatePassword, { password: control.value });

		if (!result.data.success) {
			return { passwordValidator: true };
		}

		return null;
	},
	uniqueContactLabel: async (control) => {
		if (control.value.length < 1) {
			return { uniqueContactLabel: true };
		}

		try {
			const result = await axios.get(apiConfig.contact.labels.getByName.replace(":name", control.value));

			if (result.data.data) {
				return { uniqueContactLabel: true };
			}
		} catch (error) {
			return null;
		}

		return null;
	},
	uniqueEmail: async (control) => {
		if (control.value.length < 1) {
			return { uniqueEmail: true };
		}

		try {
			const result = await axios.get(apiConfig.user.verifyEmail.replace(":email", control.value));

			if (result.data.success) {
				return { uniqueEmail: true };
			}
		} catch (error) {
			return null;
		}

		return null;
	},
	valueIsDELETE(control) {
		// TEMP FIX TILL REWORK DELETE USER PAGE
		if (control.value === "DELETE" || control.value === "VERWIJDEREN") {
			return null;
		}

		return { valueIsDELETE: true };
	},
	toggleIsTrue(control) {
		if (control.value) {
			return null;
		}

		return { toggleIsTrue: true };
	},
	valueIsCancelSubscription(control) {
		if (control.value === "CANCEL SUBSCRIPTION") {
			return null;
		}

		return { valueIsCancelSubscription: true };
	},
	valueIsDowngrade(control) {
		if (control.value === "DOWNGRADE") {
			return null;
		}

		return { valueIsDowngrade: true };
	},
	asyncNameValidation: (flowId) => (control) =>
		new Promise((resolve) => {
			debounce(async () => {
				try {
					const result = await axios.get(
						`${apiConfig.flow.statusTypes.getAll.replace(":flowId", flowId)}?q=${control.value}`
					);

					if (result.data.data.data.find((item) => item.name.toLowerCase() === control.value.toLowerCase())) {
						resolve({ flowStatusNameValidation: true });
					}
				} catch (error) {
					resolve(null);
				}
				resolve(null);
			}, 800);
		}),
	vatValidation(control) {
		const cleanVAT = control.value.replace(/ |\.|-|,|, /g, "");
		const expression =
			/^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/gim; //eslint-disable-line max-len

		if (expression.test(cleanVAT)) {
			return null;
		}

		return { vatValidation: true };
	},
	imageIsUploading(control) {
		if (!control.value?.isUploading) {
			return null;
		}

		return { imageIsUploading: true };
	},
	emailValidator(email) {
		const rex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line max-len

		return rex.test(email);
	},
	fidUsername(control) {
		// Check if the username is valid, if valid (null) we don't want to throw an error, if invalid we will.
		// A username contains 5 alphanumeric character groups and are sepperated by hyphens.
		// The groups are respectively 8, 4, 4, 4, 12 characters long.
		const fidRegx = /^[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}$/;
		const isValid = fidRegx.test(control.value);

		if (isValid) {
			return null;
		}

		return { fidUsernameInvalid: true };
	},
	hasUppercase(control) {
		const rex = /(?=.*[A-Z])/;

		if (rex.test(control.value)) {
			return null;
		}

		return { hasUpperCase: true };
	},
	multiLanguage(control) {
		if (!control._parent || !control.meta.key) {
			return null;
		}

		const languageKey = control._parent.value.multiLanguage.key;

		if (
			!control.value[languageKey] &&
			control.value[languageKey] !== "" &&
			(!control._parent.value[control.meta.key][languageKey] ||
				control._parent.value[control.meta.key][languageKey] !== control.value)
		) {
			control.setValue(
				{
					...control._parent.value[control.meta.key],
					[languageKey]: typeof control.value === "string" ? control.value : "",
				},
				{
					onlySelf: true,
					emitEvent: false,
				}
			);
		}
	},
	languageIteratorOneRequired(control) {
		if (!control._parent) {
			return { languageIteratorOneRequired: true };
		}

		const keys = Object.keys(control._parent.value);

		let response = keys.length ? null : { languageIteratorOneRequired: true };

		if (control.value.length && control.value !== " ") {
			return null;
		}

		if (!response) {
			for (const key of keys) {
				//eslint-disable-line no-unused-vars
				if (key.length === 2) {
					if (
						!control._parent.value[key] ||
						!control._parent.value[key].length ||
						control._parent.value[key] === " "
					) {
						response = { languageIteratorOneRequired: true };
					}
				}
			}
		}

		return response;
	},

	objectRequired(control) {
		const keys = Object.keys(control.value);

		let response = keys.length ? null : { objectRequired: true };

		for (const key of keys) {
			//eslint-disable-line no-unused-vars
			if (!control.value[key] || !control.value[key].length) {
				response = { objectRequired: true };
			}
		}

		return response;
	},
	conflictedSystemLabels(control) {
		if (!labelConfig.systemlabels.includes(control.value.toUpperCase().replace(" ", "_"))) {
			return null;
		}

		return { conflictedSystemLabels: true };
	},
	errorMessage({ touched, hasError, type }) {
		let error = touched && hasError("required") ? "FORM_ELEMENT.ERROR.REQUIRED" : undefined;

		if (type === "email" && !error) {
			error = touched && hasError("email") ? "FORM_ELEMENT.ERROR.EMAIL" : undefined;
		}

		const keys = {
			uniqueContactLabel: "FORM_ELEMENT.ERROR.UNIQUE_CONTACT_LABEL_EMAIL",
			uniqueEmail: "FORM_ELEMENT.ERROR.UNIQUE_USER_EMAIL",
			numberValidator: "FORM_ELEMENT.ERROR.NOT_A_NUMBER",
			minLength: "FORM_ELEMENT.ERROR.MIN_LENGTH",
			maxLength: "FORM_ELEMENT.ERROR.MAX_LENGTH",
			botNotificationTrigger: "FORM_ELEMENT.ERROR.TRIGGERS_NOT_COMPLETE",
			arrayValidator: "FORM_ELEMENT.ERROR.ARRAY_LENGTH",
			arrayValidatorMaxLength: "FORM_ELEMENT.ERROR.ARRAY_MAX_LENGTH",
			passwordValidator: "FORM_ELEMENT.ERROR.PASSWORD_VALIDATOR",
			passwordCompare: "FORM_ELEMENT.ERROR.PASSWORD_COMPARE",
			vatValidation: "FORM_ELEMENT.ERROR.COMPANY_NUMBER",
			flowStatusNameValidation: "FORM_ELEMENT.ERROR.NAME",
			hasUpperCase: "FORM_ELEMENT.ERROR.HAS_UPPER_CASE",
			valueIsDELETE: "FORM_ELEMENT.ERROR.VALUE_DELETE",
			valueIsCancelSubscription: "FORM_ELEMENT.ERROR.CANCEL_SUBSCRIPTION",
			valueIsDowngrade: "FORM_ELEMENT.ERROR.DOWNGRADE",
			conflictedSystemLabels: "FORM_ELEMENT.ERROR.CONFLICTED_LABEL",
			fidUsernameInvalid: "FORM_ELEMENT.ERROR.FID_USERNAME_INVALID",
		};

		if (!error) {
			const errorKey = Object.keys(keys).find((key) => touched && hasError(key));

			error = errorKey ? keys[errorKey] : undefined;
		}

		return error;
	},
};

export const isDate = (parameter: unknown): parameter is Date => parameter instanceof Date;
export const isNumber = (parameter: unknown): parameter is number => typeof parameter === "number";
export const isString = (parameter: unknown): parameter is string => typeof parameter === "string";

export function isDefined(value?: unknown) {
	return typeof value !== undefined;
}

export type TComparisonParam = string | number | Date | undefined;

export function isEqual(value?: TComparisonParam, compareToParam?: TComparisonParam) {
	if (typeof value === "undefined" || typeof compareToParam === "undefined") return false;

	if (isDate(value) && isDate(compareToParam)) return isSameDay(startOfDay(value), startOfDay(compareToParam));

	return value === compareToParam;
}

export function isGreaterThan(value?: TComparisonParam, compareToParam?: TComparisonParam) {
	if (typeof value === "undefined" || typeof compareToParam === "undefined") return false;

	if (isDate(value) && isDate(compareToParam)) return isAfter(startOfDay(value), startOfDay(compareToParam));

	if (isString(value) && isString(compareToParam)) return value.length > compareToParam.length;

	return value > compareToParam;
}

export function isLessThan(value?: TComparisonParam, compareToParam?: TComparisonParam) {
	if (typeof value === "undefined" || typeof compareToParam === "undefined") return false;

	if (isDate(value) && isDate(compareToParam)) return isBefore(startOfDay(value), startOfDay(compareToParam));

	// EID EXPIRATIONDATA IMPLEMENTATION
	if (isDate(value) && compareToParam === "CURRENT_DATE") {
		return isLessThan(value, new Date());
	}

	if (isString(value) && isString(compareToParam)) return value.length < compareToParam.length;

	return value < compareToParam;
}

export function isGreaterThanOrEqual(value?: TComparisonParam, compareToParam?: TComparisonParam) {
	return isGreaterThan(value, compareToParam) || isEqual(value, compareToParam);
}

export function isLessThanOrEqual(value?: TComparisonParam, compareToParam?: TComparisonParam) {
	// EID EXPIRATIONDATA IMPLEMENTATION
	if (isDate(value) && compareToParam === "CURRENT_DATE") {
		return isLessThan(value, new Date()) || isEqual(value, new Date());
	}

	return isLessThan(value, compareToParam) || isEqual(value, compareToParam);
}

export const percentageValidator = async (value: number, intl: IntlShape) => {
	let message;

	if (value < 0) {
		message = "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_PERCENTAGE.ERROR_MIN";
	}

	if (value > 100) {
		message = "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_PERCENTAGE.ERROR_MAX";
	}

	if (message) {
		return Promise.reject(
			new Error(
				intl.formatMessage({
					id: message,
				})
			)
		);
	}
};

export function isMMDDYYYY(value: string) {
	return new RegExp(/^(0[1-9]|1[0-2])[-\/](0[1-9]|[12][0-9]|3[01])[-\/](\d{4})$/).test(value);
}

export function isMMDDYYYYInclTime(value: string) {
	return new RegExp(/^(0[1-9]|1[0-2])[-\/](0[1-9]|[12][0-9]|3[01])[-\/](\d{4})\s+(\d{2}):(\d{2}):(\d{2})$/).test(
		value
	);
}

export function isIsoDateTime(value: string) {
	return new RegExp(
		/^(\d{4})-([0-1][0-9])-([0-3][0-9])T([0-2][0-9]):([0-5][0-9]):([0-5][0-9])(\.\d+)?(Z|[+-]([0-2][0-9]):([0-5][0-9]))$/
	).test(value);
}
