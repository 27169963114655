import axios from "axios";

export default {
	setHeaders: (value) => (axios.defaults.headers.common = value),
	updateHeaders: (value) => {
		axios.defaults.headers.common = {
			...axios.defaults.headers.common,
			...value,
		};
	},
};
