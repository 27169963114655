import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import { queryStringHelpers } from "../../../helpers";

function TAccordion({ expanded, updateUrl, className, onChange, history, children }) {
	const [_expanded, setExpanded] = useState(expanded);
	const [componentHasBeenLoaded, setComponentHasBeenLoaded] = useState(false);
	const handleClick = (value) => {
		const newValue = value !== _expanded ? value : undefined;

		setExpanded(newValue);
		onChange(newValue);

		if (updateUrl) {
			history.push(newValue ? `${history.location.pathname}?ot=${newValue}` : history.location.pathname);
		}
	};

	if (!componentHasBeenLoaded) {
		const params = queryStringHelpers.getQueryString(history);

		if (params && params.ot) {
			setExpanded(params.ot);
		}
		setComponentHasBeenLoaded(true);
	}

	return (
		<section className={`t-wrapper ${className}`}>
			{[...(children || children)].map(
				(child, index) =>
					child &&
					React.cloneElement(child, {
						key: `child-${index}-${child.props.id}`,
						expanded: child.props.id === _expanded,
						onClick: () => handleClick(child.props.id),
						className: `${child.props.className} wrapper-child`,
					})
			)}
		</section>
	);
}

export default withRouter(TAccordion);
