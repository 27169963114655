import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useSetRecoilState } from "recoil";

import { showCreateStatusTypeState } from "../status_modal/state";
import style from "./style.module.scss";

export default function StatusTypesHeader({ className }) {
	const setShowCreateStatusType = useSetRecoilState(showCreateStatusTypeState);
	const hasEditPermissions = useSelector((state) => state.dashboard.permissions.userPermissions.editFlows);

	return (
		<header className={`${style.status_types_header} ${className}`}>
			<Button
				type="primary"
				onClick={() => setShowCreateStatusType(true)}
				disabled={!hasEditPermissions}
				size="large"
			>
				<FormattedMessage id="FLOWS.REPORTS.STATUS_TYPES.ADD.BUTTON" />
			</Button>
		</header>
	);
}

StatusTypesHeader.defaultProps = {
	className: "",
};
