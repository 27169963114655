import "./style.scss";

import { Redirect, Route, Switch } from "react-router-dom";

import { Layout } from "../../components/templates";
import { trpc } from "../../trpc";
import Branding from "./branding";
import Flows from "./flows";
import FlowSetup from "./flowSetup";
import InviteMembers from "./inviteMembers";

export default function SetupRoutes() {
	const { data: organization, isLoading } = trpc.organization.getOrganization.useQuery();

	if (isLoading) return <Layout.TLoadScreen />;
	if (!organization && !isLoading) return <Redirect to="/portal/login" />;

	return (
		<Switch>
			<Route path="/:slug/setup/branding" component={Branding} />
			<Route path="/:slug/setup/flow" component={Flows} />
			<Route path="/:slug/setup/flow-setup" component={FlowSetup} />
			<Route path="/:slug/setup/members" component={InviteMembers} />
		</Switch>
	);
}
