import { Alert } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { useRecoilValueLoadable } from "recoil";

import { checkIfFlowExists } from "@/flows/overview/create/state";

import { getWarningBannerConfig } from "./helper";
import style from "./style.module.scss";

export default function WarningBanner({ template, isLocked }) {
	const intl = useIntl();
	const doesFlowExist = useRecoilValueLoadable(checkIfFlowExists);
	const warningConfig = getWarningBannerConfig({ isLocked, doesFlowExist });

	if (!warningConfig) {
		return null;
	}

	return (
		<Alert
			showIcon
			type="warning"
			message={intl.formatMessage({ id: warningConfig.title }, { name: template?.name?.[intl.locale] })}
			description={intl.formatMessage({ id: warningConfig.description })}
			className={style.create_flow_warning_banner}
		/>
	);
}
