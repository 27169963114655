import axios from "axios";

import config from "./config";

export const getTemplateConfig = async ({ templateId }) => {
	try {
		const result = await axios.get(config.template.getConfig.replace(":templateId", templateId));

		if (!result.data.success) {
			throw new Error();
		}

		return result?.data;
	} catch (error) {
		return [];
	}
};
