const defaultContactIds = {
	firstName: "firstName",
	lastName: "lastName",
	username: "username",
	email: "email",
	phone: "phone",
	language: "language",
};

export default {
	defaultContactIds,
	contact: [
		// GENERAL INFO
		{
			id: defaultContactIds.firstName,
			uuid: defaultContactIds.firstName,
			value: "MODEL.CONTACT.FIRST_NAME",
		},
		{
			id: defaultContactIds.lastName,
			uuid: defaultContactIds.lastName,
			value: "MODEL.CONTACT.LAST_NAME",
		},
		{
			id: defaultContactIds.username,
			uuid: defaultContactIds.username,
			value: "MODEL.CONTACT.USERNAME",
		},
		{
			id: defaultContactIds.email,
			uuid: defaultContactIds.email,
			value: "MODEL.CONTACT.EMAIL",
		},
		{
			id: defaultContactIds.phone,
			uuid: defaultContactIds.phone,
			value: "MODEL.CONTACT.PHONE",
		},
		{
			id: "nationalRegistryNumber",
			uuid: "nationalRegistryNumber",
			value: "MODEL.CONTACT.NATIONAL_INSURANCE_NUMBER",
		},
		{
			id: "referenceId",
			uuid: "referenceId",
			value: "MODEL.CONTACT.REFERENCE_ID",
		},
		{
			id: "birthday",
			uuid: "birthday",
			value: "MODEL.CONTACT.BIRTHDAY",
		},
		{
			id: "gender",
			uuid: "gender",
			value: "MODEL.CONTACT.GENDER",
		},
		{
			id: "avatar",
			uuid: "avatar",
			value: "MODEL.CONTACT.AVATAR",
		},

		// ADDRESS
		{
			id: "address.streetName",
			uuid: "address.streetName",
			value: "MODEL.CONTACT.ADDRESS.STREET_NAME",
			type: "array",
		},
		{
			id: "address.streetNumber",
			uuid: "address.streetNumber",
			value: "MODEL.CONTACT.ADDRESS.STREET_NUMBER",
			type: "array",
		},
		{
			id: "address.city",
			uuid: "address.city",
			value: "MODEL.CONTACT.ADDRESS.CITY",
			type: "array",
		},
		{
			id: "address.bus",
			uuid: "address.bus",
			value: "MODEL.CONTACT.ADDRESS.BUS",
			type: "array",
		},
		{
			id: "address.province",
			uuid: "address.province",
			value: "MODEL.CONTACT.ADDRESS.PROVINCE",
			type: "array",
		},
		{
			id: "address.country",
			uuid: "address.country",
			value: "MODEL.CONTACT.ADDRESS.COUNTRY",
			type: "array",
		},
		{
			id: "address.postalCode",
			uuid: "address.postalCode",
			value: "MODEL.CONTACT.ADDRESS.POSTAL_CODE",
			type: "array",
		},
		{
			id: "address.primary",
			uuid: "address.primary",
			value: "MODEL.CONTACT.ADDRESS.PRIMARY",
			type: "array",
		},

		// ATTRIBUTES
		// BANK
		{
			id: "attributes.bank.accountNumber",
			uuid: "attributes.bank.accountNumber",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.ACCOUNT_NUMBER",
		},
		{
			id: "attributes.bank.iban",
			uuid: "attributes.bank.iban",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.IBAN",
		},
		{
			id: "attributes.bank.country",
			uuid: "attributes.bank.country",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.COUNTRY",
		},
		{
			id: "attributes.bank.bic",
			uuid: "attributes.bank.bic",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.BIC",
		},
		{
			id: "attributes.bank.openedAt",
			uuid: "attributes.bank.openedAt",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.OPENED_AT",
		},
		{
			id: "attributes.bank.closedAt",
			uuid: "attributes.bank.closedAt",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.CLOSED_AT",
		},
		{
			id: "attributes.bank.institution.name",
			uuid: "attributes.bank.institution.name",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.NAME",
		},
		{
			id: "attributes.bank.institution.address.streetAndNumber",
			uuid: "attributes.bank.institution.address.streetAndNumber",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.STREET_AND_NUMBER",
		},
		{
			id: "attributes.bank.institution.address.city",
			uuid: "attributes.bank.institution.address.city",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.CITY",
		},
		{
			id: "attributes.bank.institution.address.province",
			uuid: "attributes.bank.institution.address.province",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.PROVINCE",
		},
		{
			id: "attributes.bank.institution.address.country",
			uuid: "attributes.bank.institution.address.country",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.COUNTRY",
		},
		{
			id: "attributes.bank.institution.address.postal",
			uuid: "attributes.bank.institution.address.postal",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.POSTAL",
		},
		{
			id: "attributes.bank.reportedToGovernmentYesNo",
			uuid: "attributes.bank.reportedToGovernmentYesNo",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.REPORTED_TO_GOVERNMENT_YES_NO",
		},
		{
			id: "attributes.bank.receivedInterests",
			uuid: "attributes.bank.receivedInterests",
			value: "MODEL.CONTACT.ATTRIBUTES.BANK.RECEIVED_INTERESTS",
		},

		// FOREIGN LEGAL CONSTRUCTIONS
		{
			id: "attributes.ownerOfForeignLegalConstructions",
			uuid: "attributes.ownerOfForeignLegalConstructions",
			value: "MODEL.CONTACT.ATTRIBUTES.OWNER_OF_FOREIGN_LEGAL_CONSTRUCTIONS",
		},
		{
			id: "attributes.foreignLegalConstructionIncomeRecieved",
			uuid: "attributes.foreignLegalConstructionIncomeRecieved",
			value: "MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_INCOME_RECIEVED",
		},
		{
			id: "attributes.foreignLegalConstructionIncomeAmount.dividents",
			uuid: "attributes.foreignLegalConstructionIncomeAmount.dividents",
			value: "MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_INCOME_AMOUNT.DIVIDENDS",
		},
		{
			id: "attributes.foreignLegalConstructionIncomeAmount.interests",
			uuid: "attributes.foreignLegalConstructionIncomeAmount.interests",
			value: "MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_INCOME_AMOUNT.INTERESTS",
		},
		{
			id: "attributes.foreignLegalConstructionIncomeAmount.other",
			uuid: "attributes.foreignLegalConstructionIncomeAmount.other",
			value: "MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_INCOME_AMOUNT.OTHER",
		},
		{
			id: "attributes.foreignLegalConstructionAlreadyWithholdedTax",
			uuid: "attributes.foreignLegalConstructionAlreadyWithholdedTax",
			value: "MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_ALREADY_WITHHOLDED_TAX",
		},

		// FOREIGN REAL ESTATE
		{
			id: "attributes.foreignRealEstate.name",
			uuid: "attributes.foreignRealEstate.name",
			value: "MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.NAME",
		},
		{
			id: "attributes.foreignRealEstate.rentedOut",
			uuid: "attributes.foreignRealEstate.rentedOut",
			value: "MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.RENTED_OUT",
		},
		{
			id: "attributes.foreignRealEstate.yearlyRentableIncome",
			uuid: "attributes.foreignRealEstate.yearlyRentableIncome",
			value: "MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.YEARLY_RENTABLE_INCOME",
		},
		{
			id: "attributes.foreignRealEstate.yearlyRentableIncome",
			uuid: "attributes.foreignRealEstate.yearlyRentableIncome",
			value: "MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.YEARLY_RENTABLE_INCOME",
		},
		{
			id: "attributes.foreignRealEstate.taxPayedAbroad",
			uuid: "attributes.foreignRealEstate.taxPayedAbroad",
			value: "MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.TAX_PAYED_ABROAD",
		},
		{
			id: "attributes.foreignRealEstate.purchasedAt",
			uuid: "attributes.foreignRealEstate.purchasedAt",
			value: "MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.PURCHASED_AT",
		},
		{
			id: "attributes.foreignRealEstate.soldAt",
			uuid: "attributes.foreignRealEstate.soldAt",
			value: "MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.SOLD_AT",
		},

		// PERSONAL INFO
		{
			id: "attributes.hasDisability",
			uuid: "attributes.hasDisability",
			value: "MODEL.CONTACT.ATTRIBUTES.HAS_DISABILITY",
		},
		{
			id: "attributes.IBANForTaxReturns",
			uuid: "attributes.IBANForTaxReturns",
			value: "MODEL.CONTACT.ATTRIBUTES.IBAN_FOR_TAX_RETURNS",
		},
		{
			id: "attributes.maritalStatus",
			uuid: "attributes.maritalStatus",
			value: "MODEL.CONTACT.ATTRIBUTES.MARITAL_STATUS",
		},
		{
			id: "attributes.matrimonialPropertySystem",
			uuid: "attributes.matrimonialPropertySystem",
			value: "MODEL.CONTACT.ATTRIBUTES.MATRIMONIAL_PROPERTY_SYSTEM",
		},
		{
			id: "attributes.partner.firstName",
			uuid: "attributes.partner.firstName",
			value: "MODEL.CONTACT.ATTRIBUTES.PARTNER.FIRST_NAME",
		},
		{
			id: "attributes.partner.lastName",
			uuid: "attributes.partner.lastName",
			value: "MODEL.CONTACT.ATTRIBUTES.PARTNER.LAST_NAME",
		},
		{
			id: "attributes.birthPlace",
			uuid: "attributes.birthPlace",
			value: "MODEL.CONTACT.ATTRIBUTES.BIRTH_PLACE",
		},
		{
			id: "attributes.birthCountry",
			uuid: "attributes.birthCountry",
			value: "MODEL.CONTACT.ATTRIBUTES.BIRTH_COUNTRY",
		},

		// DEPENDANTS
		{
			id: "attributes.dependents.type",
			uuid: "attributes.dependents.type",
			value: "MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.TYPE",
		},
		{
			id: "attributes.dependents.name",
			uuid: "attributes.dependents.name",
			value: "MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.NAME",
		},
		{
			id: "attributes.dependants.hasDisability",
			uuid: "attributes.dependants.hasDisability",
			value: "MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.HAS_DISABILITY",
		},
		{
			id: "attributes.dependants.birthday",
			uuid: "attributes.dependants.birthday",
			value: "MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.BIRTHDAY",
		},
		{
			id: "attributes.dependants.childcareCertificate",
			uuid: "attributes.dependants.childcareCertificate",
			value: "MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.CHILDCARE_CERTIFICATE",
		},
		{
			id: "attributes.dependants.dependencyType",
			uuid: "attributes.dependants.dependencyType",
			value: "MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.DEPENDENCY_TYPE",
		},

		// OTHER
		{
			id: "attributes.companyNumber",
			uuid: "attributes.companyNumber",
			value: "MODEL.CONTACT.COMPANY_NUMBER",
		},
		{
			id: defaultContactIds.language,
			uuid: defaultContactIds.language,
			value: "MODEL.CONTACT.LANGUAGE",
		},
		{
			id: "attributes.socialSecuritySecretariat",
			uuid: "attributes.socialSecuritySecretariat",
			value: "MODEL.CONTACT.SOCIAL_SECURITY_SECRETARIAT",
		},
		{
			id: "attributes.socialSecurityCaseNumber",
			uuid: "attributes.socialSecurityCaseNumber",
			value: "MODEL.CONTACT.SOCIAL_SECURITY_CASE_NUMBER",
		},
		{
			id: "attributes.socialSecurityContact",
			uuid: "attributes.socialSecurityContact",
			value: "MODEL.CONTACT.SOCIAL_SECURITY_CONTACT",
		},
		{
			id: "labels",
			uuid: "labels",
			value: "MODEL.CONTACT.LABELS",
		},
		{
			id: "timezone",
			uuid: "timezone",
			value: "MODEL.CONTACT.TIME_ZONE",
		},
		{
			id: "createdAt",
			uuid: "createdAt",
			value: "MODEL.CONTACT.CREATED_AT",
		},
	],
};
