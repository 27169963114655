import { trpc } from "@/trpc";
import { SearchOutlined, TeamOutlined } from "@ant-design/icons";
import type { IMember } from "@bothive_core/database";

import { Button, Checkbox, Dropdown, Empty, Form, Input, List, notification, Space, Tag } from "antd";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilValue } from "recoil";
import { selectedAllArchivedContactsState, selectedArchivedContactsState } from "../state";

interface AccountManagersProps {
	filters: {
		q?: string;
		contactType?: string;
		labelId?: string;
		accountManagerId?: string;
	};
	includesDeleted: boolean;
}

export default function AccountManagers({ filters, includesDeleted }: AccountManagersProps) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const [form] = Form.useForm();
	const searchValue = Form.useWatch<string>("search", form);
	const [selectedAccountManagers, setSelectedAccountManagers] = useState<string[]>([]);
	const selectedContacts = useRecoilValue(selectedArchivedContactsState);
	const selectedAll = useRecoilValue(selectedAllArchivedContactsState);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement | null>(null);
	const { data: accountManagers, isLoading } = trpc.member.getAll.useQuery({ limit: 0 });
	const [displayedAccountManagers, setDisplayedAccountManagers] = useState<IMember[]>([]);

	const bulkMutateAccountManagers = trpc.contact.bulkMutateAccountManagers.useMutation({
		onSuccess: (result) => {
			notification.success({
				message: intl.formatMessage(
					{
						id: "CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.SUCCESS",
						defaultMessage:
							"Successfully updated account managers of {count, plural, one {# contact} other {# contacts}}.",
					},
					{ count: result.nModified }
				),
				placement: "bottomRight",
			});

			trpcUtils.contact.getAllContacts.invalidate();
			setIsOpen(false);
			form.resetFields();
			setSelectedAccountManagers([]);
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.ERROR",
					defaultMessage: "Something went wrong while updating account managers.",
				}),
				placement: "bottomRight",
			});
		},
	});

	// Close logic
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleClickOutside = (event: MouseEvent) => {
		if (
			!dropdownRef?.current?.contains(event.target as Node) &&
			!document.querySelector("#cts-bulk-actions__multi-select-dropdown--am")?.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (!searchValue || searchValue === "") {
			setDisplayedAccountManagers(accountManagers?.data || []);
			return;
		}
		setDisplayedAccountManagers(
			() =>
				accountManagers?.data?.filter((label) =>
					label.name.toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase().trim())
				) || []
		);
	}, [searchValue]);

	useEffect(() => {
		if (displayedAccountManagers.length || searchValue) return;
		setDisplayedAccountManagers(accountManagers?.data || []);
	}, [accountManagers]);

	return (
		<div ref={dropdownRef}>
			<Dropdown
				open={isOpen}
				autoFocus
				destroyPopupOnHide
				trigger={["click"]}
				dropdownRender={() => (
					<Space
						id="cts-bulk-actions__multi-select-dropdown--am"
						className="cts-bulk-actions__dropdown-space"
					>
						<Form className="cts-bulk-actions__multi-select-form" form={form}>
							<header>
								<h1 className="t-gap--bottom-xs">
									<FormattedMessage
										id="CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.TITLE"
										defaultMessage="Management by"
									/>
								</h1>
								<Form.Item name="search" noStyle>
									<Input
										placeholder={intl.formatMessage({
											id: "CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.SEARCH_PLACEHOLDER",
											defaultMessage: "Search for account manager",
										})}
										allowClear
										prefix={<SearchOutlined />}
									/>
								</Form.Item>
								<p className="cts-bulk-actions__multi-select-count">
									<FormattedMessage
										id="CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ITEM_SELECTED"
										defaultMessage="{count, plural, =0 {No account managers} one {# account manager} other {# account managers}} selected"
										values={{ count: selectedAccountManagers.length }}
									/>
								</p>
							</header>
							<Form.Item noStyle>
								<List
									loading={isLoading}
									locale={{
										emptyText: (
											<Empty
												image={Empty.PRESENTED_IMAGE_SIMPLE}
												description={intl.formatMessage({
													id: "CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.NOT_FOUND",
													defaultMessage: "No contact labels found",
												})}
											/>
										),
									}}
									size="small"
									bordered={false}
									style={{ maxHeight: "20em", height: "100%", overflow: "auto" }}
									dataSource={displayedAccountManagers}
									renderItem={(item) => (
										<List.Item key={`${item.userId}`}>
											<Checkbox
												key={`${item.userId}`}
												checked={selectedAccountManagers.includes(`${item.userId}`)}
												id={`${item.userId}`}
												onChange={(event) => {
													if (event.target.checked) {
														setSelectedAccountManagers((prev) => [
															...prev,
															event.target.id as string,
														]);
														return;
													}

													setSelectedAccountManagers((prev) =>
														prev.filter(
															(id) => id !== (event.target.id as unknown as string)
														)
													);
												}}
											>
												<p>{item.name}</p>
											</Checkbox>
										</List.Item>
									)}
								/>
							</Form.Item>

							<div className="cts-bulk-actions__multi-select-actions">
								<Button
									loading={
										bulkMutateAccountManagers.variables?.action === "delete" &&
										bulkMutateAccountManagers.isLoading
									}
									danger
									disabled={
										!selectedAccountManagers?.length ||
										(bulkMutateAccountManagers.isLoading &&
											bulkMutateAccountManagers.variables?.action === "add")
									}
									onClick={() => {
										bulkMutateAccountManagers.mutate({
											action: "delete",
											accountManagerIds: selectedAccountManagers,
											contactIds: selectedContacts,
											mutateAll: selectedAll,
											filters: { ...filters, archived: true, deleted: includesDeleted },
										});
									}}
									className="t-gap--right-sm"
									type="primary"
								>
									<FormattedMessage
										id="CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ACTIONS.DELETE"
										defaultMessage="Delete"
									/>
								</Button>
								<Button
									loading={
										bulkMutateAccountManagers.variables?.action === "add" &&
										bulkMutateAccountManagers.isLoading
									}
									disabled={
										!selectedAccountManagers?.length ||
										(bulkMutateAccountManagers.isLoading &&
											bulkMutateAccountManagers.variables?.action === "delete")
									}
									onClick={() => {
										bulkMutateAccountManagers.mutate({
											action: "add",
											accountManagerIds: selectedAccountManagers,
											contactIds: selectedContacts,
											mutateAll: selectedAll,
											filters: { ...filters, archived: true, deleted: includesDeleted },
										});
									}}
									type="primary"
								>
									<FormattedMessage
										id="CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ACTIONS.ADD"
										defaultMessage="Add"
									/>
								</Button>
							</div>
						</Form>
					</Space>
				)}
			>
				<Button
					onClick={() => setIsOpen((prev) => !prev)}
					type={isOpen ? "primary" : "text"}
					icon={<TeamOutlined />}
				>
					{" "}
					<FormattedMessage
						id="CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.BUTTON.TITLE"
						defaultMessage="Account managers"
					/>
				</Button>
			</Dropdown>
		</div>
	);
}
