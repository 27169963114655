const baseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:8980"; //eslint-disable-line no-undef
const fileUrl = process.env.REACT_APP_FILE_SERVER_HOST || "http://localhost:4449"; //eslint-disable-line no-undef

const version = "/v1";

const endpoints = {
	api: "/api/v1",
	serve: "/serve",
};

const fetchLimit = 10;

export default {
	api: {
		baseUrl,
		endpoints,
		getAll: `${baseUrl}${endpoints.api}/flows/:id/reports`,
		getTemplates: `${baseUrl}${endpoints.api}/flows/:id/reports/template`,
		getById: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId`,
		download: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId/download`,
		downloadFiles: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId/downloadFiles`,
		update: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId`,
		assign: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId/assign`,
		delete: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId`,
		attachments: `${baseUrl}${endpoints.api}/flows/:id/reports/:reportId/attachments`,
		attachmentsZipped: `${fileUrl}${version}${endpoints.serve}/flow/reports/:reportId/attachments/zipped`,
	},
	sessionState: {
		completed: "COMPLETED",
		inProgress: "IN_PROGRESS",
		inviteSend: "INVITE_SEND",
		created: "CREATED",
		pendingSend: "PENDING_SEND",
	},
	fetchLimit,
	table: {
		pagination: {
			limit: fetchLimit,
			showSizeChanger: true,
			defaultPageSize: fetchLimit,
			pageSizeOptions: ["10", "20", "50", "100", "200"],
		},
	},
};
