import typesConfig from "./types.config";

const templateList = {
	organization: [
		{
			_id: "companyName",
			name: "TEMPLATE.ORGANIZATION.COMPANY_NAME",
			value: "{{organization.name}}",
		},
		{
			_id: "companyPhone",
			name: "TEMPLATE.ORGANIZATION.COMPANY_PHONE",
			value: "{{organization.phoneNumber}}",
		},
		{
			_id: "companyEmail",
			name: "TEMPLATE.ORGANIZATION.COMPANY_EMAIL",
			value: "{{organization.supportEmail}}",
		},
		{
			_id: "companyLogo",
			name: "TEMPLATE.ORGANIZATION.COMPANY_LOGO",
			value: "{{organization.branding.logo}}",
		},
		{
			_id: "companyIcon",
			name: "TEMPLATE.ORGANIZATION.COMPANY_ICON",
			value: "{{organization.branding.icon}}",
		},
		{
			_id: "locationCountry",
			name: "TEMPLATE.ORGANIZATION.COMPANY_LOCATION_COUNTRY",
			value: "{{organization.locations[0].country}}",
		},
		{
			_id: "locationCity",
			name: "TEMPLATE.ORGANIZATION.COMPANY_LOCATION_CITY",
			value: "{{organization.locations[0].city}}",
		},
		{
			_id: "locationPostalCode",
			name: "TEMPLATE.ORGANIZATION.COMPANY_LOCATION_POSTAL_CODE",
			value: "{{organization.locations[0].postalcode}}",
		},
		{
			_id: "locationStreet",
			name: "TEMPLATE.ORGANIZATION.COMPANY_LOCATION_STREET",
			value: "{{organization.locations[0].street}}",
		},
		{
			_id: "locationNumber",
			name: "TEMPLATE.ORGANIZATION.COMPANY_LOCATION_NUMBER",
			value: "{{organization.locations[0].number}}",
		},
		{
			_id: "locationCompanyNumber",
			name: "TEMPLATE.ORGANIZATION.COMPANY_LOCATION_COMPANY_NUMBER",
			value: "{{organization.locations[0].companyNumber}}",
		},
		{
			_id: "socialMediaFacebook",
			name: "TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_FACEBOOK",
			value: "{{organization.socialMedia.facebook}}",
		},
		{
			_id: "socialMediaLinkedIn",
			name: "TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_LINKEDIN",
			value: "{{organization.socialMedia.linkedIn}}",
		},
		{
			_id: "socialMediaInstagram",
			name: "TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_INSTAGRAM",
			value: "{{organization.socialMedia.instagram}}",
		},
		{
			_id: "socialMediaTwitter",
			name: "TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_TWITTER",
			value: "{{organization.socialMedia.twitter}}",
		},
		{
			_id: "socialMediaYoutube",
			name: "TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_YOUTUBE",
			value: "{{organization.socialMedia.youtube}}",
		},
	],
	user: [
		{
			_id: "firstName",
			name: "TEMPLATE.USER.FIRST_NAME",
			value: "{{user.firstName}}",
		},
		{
			_id: "lastName",
			name: "TEMPLATE.USER.LAST_NAME",
			value: "{{user.lastName}}",
		},
		{
			_id: "username",
			name: "TEMPLATE.USER.USERNAME",
			value: "{{user.username}}",
		},
		{
			_id: "avatar",
			name: "TEMPLATE.USER.AVATAR",
			value: "{{user.avatar}}",
		},
		{
			_id: "email",
			name: "TEMPLATE.USER.EMAIL",
			value: "{{user.email}}",
		},
		{
			_id: "phoneNumber",
			name: "TEMPLATE.USER.PHONE_NUMBER",
			value: "{{user.phoneNumber}}",
		},
	],
};

export default {
	editor: {
		style: [
			{
				id: "bold",
				icon: "bold",
				label: "STYLE_INPUT.MARKUP.BOLD",
				action: typesConfig.editorStyle.bold,
			},
			{
				id: "italic",
				icon: "italic",
				label: "STYLE_INPUT.MARKUP.ITALIC",
				action: typesConfig.editorStyle.italic,
			},
			{
				id: "underlined",
				icon: "underlined",
				label: "STYLE_INPUT.MARKUP.UNDERLINED",
				action: typesConfig.editorStyle.underlined,
			},
		],
		template: {
			company: {
				id: "organization",
				icon: "building",
				items: templateList.organization,
			},
			bot: {
				id: "bot",
				icon: "bot",
				items: [
					{
						_id: "botName",
						name: "STYLE_INPUT.BOT.BOT_NAME",
						value: "{{organization.chatbot.name}}",
					},
				],
			},
			user: {
				id: "user",
				icon: "user",
				items: templateList.user,
			},
		},
		controls: [
			{
				id: "link",
				icon: "link",
			},
			{
				id: "unorderedList",
				icon: "list-unordered",
			},
		],
		send: {
			icon: "send",
			label: "STYLE_INPUT.BUTTON.SEND",
		},
		templateComponents: {
			images: {
				values: [
					templateList.organization[3].value,
					templateList.organization[4].value,
					templateList.user[3].value,
				],
				options: {
					style: {
						width: "96px",
						height: "96px",
					},
				},
			},
			link: {
				values: [templateList.organization[2].value, templateList.user[4].value],
			},
		},
	},
	templateList,
};
