import ReminderHeader from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/components/Header";
import ReminderFooter from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/components/Footer";
import { useIntl } from "react-intl";
import { Button, Card, FormInstance } from "antd";
import { useState } from "react";
import ChannelSelect from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/components/Reminder/components/ChannelSelect";
import SubjectCompose from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/components/Reminder/components/SubjectCompose";

export default function Reminder({
	setCurrentStep,
	form,
}: {
	setCurrentStep: (value: number) => void;
	form: FormInstance;
}) {
	const intl = useIntl();
	const [selectedChannel, setSelectedChannel] = useState<string | undefined>(undefined);

	return (
		<section style={{ width: "650px" }}>
			<ReminderHeader
				title={intl.formatMessage({
					id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_2.TITLE",
					defaultMessage: "Define pb 2024 flow reminder message",
				})}
				description={intl.formatMessage({
					id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_2.DESCRIPTION",
					defaultMessage: "Define which message should be sent as reminder",
				})}
			/>
			<div style={{ paddingBottom: "10em" }}>
				<Card
					title={
						<p style={{ fontWeight: 600, padding: "0.5em" }}>
							{intl.formatMessage({
								id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_2.CHANNEL",
								defaultMessage: "Channel you will send reminder from",
							})}
						</p>
					}
					style={{ width: "100%", textAlign: "left" }}
					size="small"
				>
					<ChannelSelect
						form={form}
						selectedChannel={selectedChannel}
						setSelectedChannel={setSelectedChannel}
					/>
				</Card>
				<div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
					<span style={{ height: "40px", width: "3px", display: "block", backgroundColor: "lightgray" }} />
				</div>
				<Card
					title={
						<p style={{ fontWeight: 600, padding: "0.5em" }}>
							{intl.formatMessage({
								id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP2.MESSAGE",
								defaultMessage: "Message you will send as reminder",
							})}
						</p>
					}
					style={{ width: "100%", textAlign: "left" }}
					size="small"
				>
					<SubjectCompose form={form} />
				</Card>
			</div>
			<ReminderFooter
				onReturn={() => {
					setCurrentStep(0);
				}}
				submitButton={
					<Button
						type="primary"
						style={{ margin: "1em" }}
						onClick={async () => {
							await form.validateFields(["channelId", ["templates", "subject"], ["templates", "body"]]);

							form.submit();
						}}
					>
						{intl.formatMessage({
							id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_2.BUTTON.SUBMIT",
							defaultMessage: "Next",
						})}
					</Button>
				}
			/>
		</section>
	);
}
