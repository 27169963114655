import axios from "axios";

import config from "@/flows/flow_detail/reports/config";

export const downloadAttachments = async ({ reportId }) => {
	const url = config.api.attachmentsZipped.replace(":reportId", reportId);

	const result = await axios.get(url, { responseType: "blob" });

	return result;
};
