import { ReactNode } from "react";

export default function ReminderHeader({
	title,
	description,
}: {
	title: string | ReactNode;
	description: string | ReactNode;
}) {
	return (
		<header className="m-header m-header--description t-gap--bottom">
			<h1>{title}</h1>
			<p>{description}</p>
		</header>
	);
}
