import { CloudDownloadOutlined, DeleteOutlined, SaveOutlined, SendOutlined, TagOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Tooltip, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
	reportsMeta,
	searchFilterState,
	selectedAccountManagerState,
	selectedContactLabelState,
	selectedProgressState,
	selectedStatusTypeState,
} from "@/flows/flow_detail/reports/state";
import { automationSelector } from "@/flows/report_detail/meta_aside/automation/state";
import { useParams } from "react-router";
import {
	allReportsSelectedState,
	isBulkDeletingState,
	isBulkDownloadingState,
	isBulkStatusChangingState,
	selectedReportIdsState,
	totalReportsSelectedState,
} from "../state";
import { trpc } from "../../../../../trpc";
import { flowState } from "../../../../common/state";
import style from "./style.module.scss";
export default function Buttons({
	setShowChangeStatusModal,
	setShowDownloadReportModal,
	setShowSendIntegrationModal,
	setShowBulkDeleteModal,
	setShowFlowSendModal,
	template,
}) {
	const intl = useIntl();
	const queryParams = useParams();

	const flowId = queryParams.flowId;
	const rules = useRecoilValueLoadable(automationSelector);
	const isBulkDeleting = useRecoilValue(isBulkDeletingState);
	const totalFetchedReports = useRecoilValue(reportsMeta).total;
	const flow = useRecoilValueLoadable(flowState({ id: flowId }));
	const selectedReportIds = useRecoilValue(selectedReportIdsState);
	const isBulkDownloading = useRecoilValue(isBulkDownloadingState);
	const isBulkStatusChanging = useRecoilValue(isBulkStatusChangingState);
	const [allReportsSelected, setAllReportsSelected] = useRecoilState(allReportsSelectedState);
	const [totalReportsSelected, setTotalReportsSelected] = useRecoilState(totalReportsSelectedState);

	const searchFilter = useRecoilValue(searchFilterState);
	const selectedStatus = useRecoilValue(selectedStatusTypeState);
	const selectedProgress = useRecoilValue(selectedProgressState);
	const selectedAccountManager = useRecoilValue(selectedAccountManagerState);
	const selectedContactLabel = useRecoilValue(selectedContactLabelState);

	const bulkSaveReportToContact = trpc.report.bulkSaveReportToContact.useMutation();

	if (totalFetchedReports) {
		// The reason we put these values in state is because each action on the table shows an "Nan" or 0 value for a short period until the data is fetched.
		// This is not user friendly so I hoisted these values into state to persist them when for example sorting, or switching table pages and data is refetched.
		const selection = allReportsSelected ? totalFetchedReports : selectedReportIds.length;

		setTotalReportsSelected(selection);
	}

	const confirmSaveIntoContact = () => {
		Modal.confirm({
			width: 512,
			title: intl.formatMessage({
				id: "flows.report.overview.bulk_action.confirm_save_into_contact.title",
				defaultMessage: "Save report data into contact",
			}),
			icon: <SaveOutlined style={{ color: "#002140" }} />,
			content: intl.formatMessage({
				id: "flows.report.overview.bulk_action.confirm_save_into_contact.description",
				defaultMessage:
					"You are about to save the data from the selected reports into their contacts. This action cannot be undone and will override the existing data.",
			}),
			okText: intl.formatMessage({
				id: "flows.report.overview.bulk_action.confirm_save_into_contact.button.save",
				defaultMessage: "Save data",
			}),
			okButtonProps: { style: { background: "#002140" } },
			cancelButtonProps: { style: { color: "#002140", borderColor: "#002140" } },
			cancelText: intl.formatMessage({
				id: "flows.report.overview.bulk_action.confirm_save_into_contact.button.cancel",
				defaultMessage: "Cancel",
			}),
			onOk() {
				const values = {
					flowId,
					q: searchFilter,
					statusId: selectedStatus,
					progress: selectedProgress,
					contactLabel: selectedContactLabel,
					accountManager: selectedAccountManager,
				};

				if (!allReportsSelected) {
					values.reportIds = selectedReportIds;
				}

				bulkSaveReportToContact
					.mutateAsync(values)
					.then(() => {
						notification.success({
							message: intl.formatMessage({
								id: "flows.report.overview.bulk_action.confirm_save_into_contact.succeeded",
								defaultMessage: "Reports have been saved into your contacts",
							}),
							placement: "bottomRight",
						});
					})
					.catch(() => {
						notification.error({
							message: intl.formatMessage({
								id: "flows.report.overview.bulk_action.confirm_save_into_contact.failed",
								defaultMessage:
									"It seems like we cannot save the data into your contacts right now. Please give it another shot later. We'll have this sorted out for you soon!",
							}),
							placement: "bottomRight",
							duration: 8,
						});
					});
			},
		});
	};

	const Buttons = () => (
		<div className={style.report_action_spacer}>
			<Button
				type="text"
				icon={<CloudDownloadOutlined />}
				onClick={() => setShowDownloadReportModal(true)}
				loading={isBulkDownloading}
			>
				{" "}
				<FormattedMessage id="FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.DOWNLOAD_REPORTS" />
			</Button>
			<Button
				type="text"
				icon={<TagOutlined />}
				onClick={() => setShowChangeStatusModal(true)}
				loading={isBulkStatusChanging}
			>
				{" "}
				<FormattedMessage id="FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.CHANGE_STATUS" />
			</Button>
			{template?.settings?.canSaveToContact && (
				<Button
					type="text"
					icon={<SaveOutlined />}
					onClick={confirmSaveIntoContact}
					loading={bulkSaveReportToContact.isLoading}
				>
					{" "}
					<FormattedMessage
						id="FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SAVE_INTO_CONTACT"
						defaultMessage="Save into contact"
					/>
				</Button>
			)}
			<Divider type="vertical" className={style["report-bulk-actions_divider"]} />
			<Tooltip
				title={
					!rules.contents?.data?.length &&
					intl.formatMessage({ id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SEND_TO_INTEGRATION.DISABLED" })
				}
			>
				<Button
					type="text"
					icon={<SendOutlined />}
					onClick={() => setShowSendIntegrationModal(true)}
					loading={isBulkStatusChanging}
					disabled={!rules.contents?.data?.length}
				>
					{" "}
					<FormattedMessage id="FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SEND_TO_INTEGRATION" />
				</Button>
			</Tooltip>
			<Tooltip
				title={
					flow?.contents?.template?.deactivated
						? intl.formatMessage({
								id: "FLOWS.DEACTIVATED.TOOLTIP",
								defaultMessage: "This flow has been deactivated and can not be sent to clients anymore",
						  })
						: null
				}
			>
				<Button
					type="text"
					icon={<SendOutlined />}
					onClick={() => setShowFlowSendModal(true)}
					disabled={flow?.contents?.template?.deactivated}
				>
					{" "}
					<FormattedMessage
						id="FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SEND_TO_EMAIL"
						defaultMessage="Send email"
					/>
				</Button>
			</Tooltip>
			<Divider type="vertical" className={style["report-bulk-actions_divider"]} />
			<Button
				onClick={() => setShowBulkDeleteModal(true)}
				icon={<DeleteOutlined className="t-gap--right-xs" />}
				type={isBulkDeleting ? "primary" : "text"}
			>
				<FormattedMessage id="flows.report.overview.bulk_action.button.delete" defaultMessage="Delete" />
			</Button>
		</div>
	);

	const SelectAllReports = () => (
		<>
			<FormattedMessage
				className={style.select_all_text}
				id="FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SELECTION_TOTAL"
				values={{ selection: totalReportsSelected, total: totalFetchedReports }}
			/>
			{totalFetchedReports > 1 && (
				<Button
					className={style.select_all_button}
					type="link"
					onClick={() => setAllReportsSelected(!allReportsSelected)}
				>
					<FormattedMessage
						id="FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SELECT_ALL"
						values={{ total: totalFetchedReports }}
					/>
				</Button>
			)}
		</>
	);

	const DeSelectAllReports = () => (
		<>
			<FormattedMessage
				className={style.select_all_text}
				id="FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SELECTION_TOTAL"
				values={{ selection: totalReportsSelected }}
			/>
			{totalFetchedReports > 1 && (
				<Button
					className={style.select_all_button}
					type="link"
					onClick={() => setAllReportsSelected(!allReportsSelected)}
				>
					<FormattedMessage id="FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.DESELECT_ALL" />
				</Button>
			)}
		</>
	);

	return (
		<>
			<div className={style.buttons_wrapper}>
				<Buttons />
			</div>
			<div className={style.select_all_wrapper}>
				{allReportsSelected ? <DeSelectAllReports /> : <SelectAllReports />}
			</div>
		</>
	);
}
