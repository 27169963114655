import "./style.scss";

import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Alert, Button, Collapse, Form, Input, Skeleton, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { DestructivePanel } from "@/shared/components/collapse";
import DashboardCard from "@/shared/components/card/dashboard";
import DeleteModal from "@/shared/components/modal/delete_modal";
import { Section } from "@/components/organisms";
import { authenticationHelpers } from "@/shared/helpers";
import { actions as dashboardActions } from "@/store/dashboard";
import { trpc } from "@/trpc";
import { channelSourceToIntegrationId } from "./config";
import { ChannelAnalytics } from "./components";
import { useSelector } from "react-redux";

export default function ChannelDetail() {
	const intl = useIntl();
	const history = useHistory();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const trpcUtils = trpc.useUtils();
	const [isReconnecting, setIsReconnecting] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const routeParameters = useParams<{ channelId: string; team: string }>();
	const organization = useSelector((state) => state.content.team.team);

	const { data: channel, isFetching } = trpc.channel.getById.useQuery(
		{ channelId: routeParameters.channelId },
		{ refetchOnWindowFocus: false }
	);

	const integrationId: string = channelSourceToIntegrationId[`${channel?.integrationId}`];
	const { data: integration } = trpc.integration.getIntegrationById.useQuery(
		{ query: { integrationId } },
		{ enabled: !!integrationId }
	);
	const authenticateIntegration = trpc.integrations.authentication.authenticateIntegration.useMutation();
	const partialSyncChannelMutation = trpc.integrations.communication.partialSyncChannel.useMutation();
	const updateChannelMutation = trpc.channel.updateChannel.useMutation();
	const deleteChannelMutation = trpc.channel.deleteChannel.useMutation({
		onMutate: () => {
			trpcUtils.channel.getById.setData({ channelId: routeParameters.channelId }, (prevData) => {
				if (!prevData) return;

				return structuredClone({ ...prevData, syncStatus: "DELETING" });
			});

			setShowDeleteModal(false);
		},
	});

	useEffect(() => {
		// Set breadcrumbs and header of this page
		const title = intl.formatMessage(
			{
				id: "settings.channel_detail.title",
				defaultMessage: "{channelName} - Channel detail",
				description: "Browser tab title for inbox settings",
			},
			{ channelName: channel?.name || "..." }
		);

		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					id: "channels",
					title: title,
					tabTitle: title,
					breadcrumbs: [
						{
							to: `/${routeParameters.team}`,
							name: intl.formatMessage({
								id: "settings.channel_detail.breadcrumb.settings",
								defaultMessage: "Settings",
								description: "Breadcrumb in channel detail page to settings overview",
							}),
						},
						{
							to: `/${routeParameters.team}/settings/inbox/channels`,
							name: intl.formatMessage({
								id: "settings.channel_detail.breadcrumb.channels_overview",
								defaultMessage: "Channels",
								description: "Breadcrumb in channel detail page to channels overview",
							}),
						},
						{
							to: `/${routeParameters.team}/settings/inbox/channels/${routeParameters.channelId}`,
							name: channel?.name || "...",
						},
					],
				},
				intl,
			})
		);
	}, [channel]);

	const handleUpdate = async (payload) =>
		updateChannelMutation.mutate(
			{
				channelId: routeParameters.channelId,
				...payload,
			},
			{
				onSuccess: () => {
					trpcUtils.channel.getById.invalidate({ channelId: routeParameters.channelId });
					notification.success({
						message: intl.formatMessage({
							id: "settings.channel_detail.update.notification.success",
							defaultMessage: "Channel has been successfully updated",
							description: "Success banner to inform the user update has been executed.",
						}),
						placement: "bottomRight",
					});
				},
				onError: () => {
					notification.error({
						message: intl.formatMessage({
							id: "settings.channel_detail.update.notification.failed",
							defaultMessage: "Could not update this channel, Please try again later.",
							description:
								"Error banner the user gets when something went wrong while updating their channel.",
						}),
						placement: "bottomRight",
					});
					trpcUtils.channel.getById.invalidate();
				},
			}
		);

	const handleDelete = async () =>
		deleteChannelMutation.mutate(
			{ channelId: routeParameters.channelId },
			{
				onSuccess: () => {
					notification.success({
						message: intl.formatMessage({
							id: "settings.channel_detail.delete.notification.success",
							defaultMessage: "Channel has been successfully deleted",
							description: "Success banner to inform the user their channel has been executed.",
						}),
						placement: "bottomRight",
					});
					trpcUtils.channel.getInboxChannels.invalidate();
					history.push(`/${routeParameters.team}/settings/inbox/channels`);
				},
				onError: () => {
					notification.error({
						message: intl.formatMessage({
							id: "settings.channel_detail.delete.notification.failed",
							defaultMessage: "Could not delete this channel, Please try again later.",
							description:
								"Error banner the user gets when something went wrong while deleting their channel.",
						}),
						placement: "bottomRight",
					});
				},
			}
		);

	const handleReconnect = async () => {
		if (!integration) return; // should never happen bcs alert to reconnect is only showed when integration is present

		setIsReconnecting(true);
		authenticationHelpers.openSignInWindow({
			// GET 0auth2 token from integration
			name: integration.name,
			url: `${integration.accountLinking.authUrl}&prompt=select_account&state=${window.origin}`,
			blank: true,
			callback: async (credentials) => {
				try {
					if (!credentials) throw new Error("Credentials not defined");

					const authResult = await authenticateIntegration.mutateAsync({
						scopes: integration.accountLinking.scopes,
						integrationId,
						credentials,
					});

					const partialSyncResult = await partialSyncChannelMutation.mutateAsync({
						channelId: channel?._id,
						integrationId,
						// TODO: Add UI that asks this again, default value should be what was chosen on channel create. It's important to ask this again otherwise conversations that were unhandled but read can be closed unwanted on partial sync.
						// For now false until we made a clear UI for it
						autoCloseRead: false,
					});

					if (!partialSyncResult.success) throw new Error("Could not partial sync");

					trpcUtils.channel.getById.invalidate({ channelId: routeParameters.channelId });
				} catch (error) {
					notification.error({
						message: intl.formatMessage({
							id: "settings.channel_detail.reconnect.notification.failed",
							defaultMessage:
								"Could reconnect your channel. Please try again later or contact our support team",
							description:
								"Error banner the user gets they cannot reconnect their channel, mostly a external issue that we need to help them with",
						}),
						placement: "bottomRight",
					});
				}

				setIsReconnecting(false);
			},
		});
	};

	if (!channel && !isFetching) {
		history.push(`/${routeParameters.team}/settings/inbox/channels`);
		return null;
	}

	return (
		<main className="channels_detail">
			{channel?.disabled && integration && (
				<Alert
					showIcon
					type="warning"
					message={intl.formatMessage({
						id: "settings.channel_detail.alert.reconnect.title",
						defaultMessage: "Reconnect channels",
					})}
					description={intl.formatMessage({
						id: "settings.channel_detail.alert.reconnect.description",
						defaultMessage:
							"Your channel credentials are no longer up-to-date, if you want to continue using this channel you will have to reconnect it.",
						description:
							"If user has changed their password, or admin setup something to reset their channel credentials. Our access to this channel can be revoked and the user is required to reconnect.",
					})}
					className="channel_detail-warning_banner"
					action={
						<Button
							loading={isReconnecting}
							className="channel_detail-warning_banner-button"
							onClick={handleReconnect}
						>
							<FormattedMessage
								id="settings.channel_detail.alert.reconnect.button"
								defaultMessage="Reconnect channel"
								description="Button which user needs to press to reconnect their channel"
							/>
						</Button>
					}
				/>
			)}
			{channel?.syncStatus === "DELETING" && (
				<Alert
					showIcon
					type="error"
					className="channel_detail-warning_banner"
					description={
						<div className="channel_detail-delete_banner">
							<FormattedMessage
								id="settings.channel_detail.alert.delete.description"
								defaultMessage="The deletion of this channel is in progress, this may take some time. You will be redirected when this action is completed or you can leave this page."
								description="Channel delete can take up some time, this banner notifies user about it."
							/>
							<LoadingOutlined />
						</div>
					}
				/>
			)}
			{!channel?.syncStatus && organization?.permissions.inboxAccess && (
				<Alert
					showIcon
					type="warning"
					className="channel_detail-warning_banner"
					description={
						<div className="channel_detail-delete_banner">
							<FormattedMessage
								id="settings.channel_detail.alert.non_inbox_channel.description"
								defaultMessage="This channel was linked prior to the introduction of Bothive Inbox. To utilize this channel for the Inbox, you must first delete the existing coupling and then re-establish the connection."
							/>
						</div>
					}
				/>
			)}
			<ChannelAnalytics channel={channel} isLoading={isFetching} integration={integration} />

			<DashboardCard
				title={intl.formatMessage({
					id: "settings.channel_detail.form.title",
					defaultMessage: "Channel settings",
				})}
			>
				<Skeleton active loading={isFetching} className="t-gap--top">
					<Form
						form={form}
						id="editChannel"
						layout="vertical"
						name="editChannel"
						autoComplete="off"
						requiredMark={false}
						onFinish={handleUpdate}
						initialValues={channel || {}}
						className="channels_detail-form"
					>
						<Form.Item
							name="name"
							className="channels_detail-form-fields"
							label={intl.formatMessage({
								id: "settings.channel_detail.form.name.label",
								description:
									"Name user can give to their channel to make it recognizable. Default we will use the email address as name",
								defaultMessage: "Channel name",
							})}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "settings.channel_detail.form.name.error.required",
										defaultMessage: "Channel is required",
									}),
								},
							]}
						>
							<Input size="large" placeholder={channel?.name} />
						</Form.Item>
						<Form.Item shouldUpdate className="channels_detail-form-submit">
							{(form) => {
								const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

								return (
									<Button
										type="primary"
										htmlType="submit"
										disabled={hasErrors || channel?.syncStatus === "DELETING"}
										loading={updateChannelMutation.isLoading}
									>
										<FormattedMessage
											id="settings.channel_detail.form.submit"
											defaultMessage="Update channel"
										/>
									</Button>
								);
							}}
						</Form.Item>
					</Form>
				</Skeleton>
			</DashboardCard>
			{channel?.syncStatus !== "DELETING" && (
				<Collapse expandIconPosition="end" className="channels_detail-destructive" bordered={false}>
					<DestructivePanel
						key="deleteChannelPanel"
						title={intl.formatMessage({
							id: "settings.channel_detail.delete_card.title",
							defaultMessage: "Delete channel",
						})}
					>
						<Section.ODestructive
							description={intl.formatMessage({
								id: "settings.channel_detail.delete_card.description",
								defaultMessage: `When you delete your channel all your conversations, messages and attachments will be removed from Bothive.\nOnly conversations where other channels are connected on will be kept but you and/or your channel will be removed from it as participant.`,
								description:
									"Explain to the user the dangers from removing their channel and that some conversations will be kept if other users have their channel linked to those conversations.",
							})}
							buttonLabel={intl.formatMessage({
								id: "settings.channel_detail.delete_card.content.button",
								defaultMessage: "Delete channel",
							})}
							onClick={() => setShowDeleteModal(true)}
						/>
					</DestructivePanel>
				</Collapse>
			)}
			<DeleteModal
				title={intl.formatMessage({
					id: "settings.channel_detail.delete_modal.title",
					defaultMessage: "Delete channel",
				})}
				deleteLabel={intl.formatMessage({
					id: "settings.channel_detail.delete_modal.button",
					defaultMessage: "Delete channel",
				})}
				showDescriptionIcon
				descriptionType="warning"
				description={intl.formatMessage({
					id: "settings.channel_detail.delete_modal.description",
					defaultMessage: "When you delete your channel you will lose all your data.",
					description: "Modal description to warn the user one last time they will lose their data",
				})}
				additionalInfo={
					<p className="t-gap--top-sm" style={{ color: "GrayText", fontSize: "0.9em" }}>
						<FormattedMessage
							id="settings.channel_detail.delete_modal.additional_info"
							defaultMessage="Important Notice: Please note that the duration for complete deletion of your channel data may vary based on the size of your channel. Thank you for your patience."
							description="Modal additional info in small text to warn the user this action can take some time relative to their channel size."
						/>
					</p>
				}
				isDeleting={deleteChannelMutation.isLoading}
				isVisible={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				onDelete={handleDelete}
			/>
		</main>
	);
}
