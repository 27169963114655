import { Card, Image } from "antd";

import { keyEventsHelpers } from "@/shared/helpers";

interface IChannelCard {
	name: string;
	description: string;
	logo: string;
	onClick: () => void;
}
export default function ChannelCard({ name, description, logo, onClick }: IChannelCard) {
	const handleKeyUp = (event) => {
		if (keyEventsHelpers.enterOrSpacePressed(event)) {
			onClick();
		}
	};

	return (
		<Card onClick={onClick} onKeyUp={handleKeyUp} hoverable className="connect_channels-add_channels-content-card">
			<Image src={logo} height="48px" width="48px" preview={false} style={{ objectFit: "contain" }} />
			<div className="card-wrapper">
				<p className="card-title">{name}</p>
				<p className="card-description">{description}</p>
			</div>
		</Card>
	);
}
