import React from "react";

import { Component } from "..";

export default function AProfileImage({ available, showStatus, className, ...props }) {
	return (
		<div className={`a-image--profile ${className}`}>
			<img alt="" {...props} className="a-image profile-image" />
			{showStatus && (
				<Component.AIcon type="status" className={`profile-status ${available ? "success" : "error"}`} />
			)}
		</div>
	);
}
