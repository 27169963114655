import axios from "axios";

import { apiConfig, typesConfig } from "../../../../config";
import { fileHelper, formatHelper, queryStringHelpers, stringHelpers, timerHelpers } from "../../../../helpers";
import {
	hideProcessingBanner,
	showFailedMessage,
	showKeyBanner,
	showProcessingBanner,
	showSuccessMessage,
} from "../../../dashboard/banner/banner.action";
import store from "../../../store";
import {
	deleteFailedNoTimeout,
	deleteSucceededNoTimeout,
	formWatcherFailed,
	formWatcherSucceededNoTimeout,
	startDeleteWatcher,
	startFormWatcher,
} from "../../form/form.action";
import actionTypes from "./report.actionTypes";

function startFetchWatcher(dispatch) {
	timerHelpers.startTimer();
	timerHelpers.watchTimeout(() => {
		dispatch(fetchingFailed());
		return;
	});

	dispatch(isFetching());
}
export const isFetching = () => (dispatch) => {
	dispatch({
		type: actionTypes.FLOW_REPORT_IS_FETCHING,
	});
};
export const fetchingFailed = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCH_FLOW_REPORT_FAILED,
	});
};
export const fetchingSucceeded = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCH_FLOW_REPORT_SUCCEEDED,
	});
};

// export const resetFlowReport = () => async (dispatch) => {
// 	dispatch({ type: actionTypes.RESET_FLOW_REPORTS });
// };

export const fetchReportDetail = ({ flowId, reportId }) => async (dispatch) => {
	const flowState = store.getState().content.flow;

	startFetchWatcher(dispatch);

	if (flowState.report.selected?.[reportId]) {
		setTimeout(() => {
			// TEMP fix te force rerender wont be necessary in refactor
			dispatch(fetchingSucceeded());
		}, 100);
		return;
	}

	try {
		const url = apiConfig.flow.report.getById.replace(":reportId", reportId).replace(":id", flowId);
		const reportResponse = await axios.get(url);

		if (reportResponse.status !== 200 || !reportResponse.data.success) {
			throw new Error();
		}

		dispatch({
			type: actionTypes.FETCH_REPORT_DETAIL,
			data: reportResponse.data.data,
		});

		const attachmentsUrl = apiConfig.flow.report.attachments.replace(":reportId", reportId).replace(":id", flowId);

		const attachmentsResponse = await axios.get(attachmentsUrl);

		if (attachmentsResponse.status !== 200 || !attachmentsResponse.data.success) {
			throw new Error();
		}

		dispatch({
			type: actionTypes.FLOW_REPORT_ATTACHMENTS_COUNT,
			data: attachmentsResponse.data.meta.total,
		});

		dispatch(fetchingSucceeded());
		timerHelpers.clearTimer();
	} catch (error) {
		dispatch(fetchingFailed());
		timerHelpers.clearTimer();
	}

	dispatch(fetchingSucceeded());
};

export const fetchPreviousReport = (reportId) => async (dispatch) => {
	const reportState = store.getState().content.flow.report;
	const reportIndex = reportState.list.findIndex((report) => report.id === reportId);

	if (reportIndex - 1 < 0) {
		return;
	}

	const previousReport = reportState.list[reportIndex - 1];

	dispatch({ type: actionTypes.FETCH_PREVIOUS_REPORT_DETAIL });
	dispatch(showKeyBanner({ type: typesConfig.direction.left, keyCode: typesConfig.keyCodes.left }));
	dispatch(fetchReportDetail({ reportId: previousReport.id, flowId: previousReport.flowId }));

	return previousReport.id;
};
export const fetchNextReport = (reportId) => async (dispatch) => {
	const reportState = store.getState().content.flow.report;
	const reportIndex = reportState.list.findIndex((report) => report.id === reportId);

	if (reportIndex + 1 >= reportState.list.length) {
		return;
	}

	const nextReport = reportState.list[reportIndex + 1];

	dispatch({ type: actionTypes.FETCH_NEXT_REPORT_DETAIL });
	dispatch(showKeyBanner({ type: typesConfig.direction.right, keyCode: typesConfig.keyCodes.right }));
	dispatch(fetchReportDetail({ reportId: nextReport.id, flowId: nextReport.flowId }));

	return nextReport.id;
};

export const downloadReport = ({ id, type = typesConfig.downloadTypes.csv }) => async (dispatch) => {
	const bannerId = stringHelpers.randomUuid();

	try {
		dispatch(
			showProcessingBanner({
				id: bannerId,
				message: "BANNER.PROCESSING.REPORT_IS_DOWNLOADING",
				values: { type },
			})
		);

		const flowState = store.getState().content.flow;
		const flowId = flowState.flow.selected?.id || flowState.report.list.find((item) => item.id === id)?.flowId;
		const url = apiConfig.flow.report.download.replace(":id", flowId).replace(":reportId", id);
		const language = store.getState().dashboard.header.lang;

		const file = await axios({
			url: `${url}?type=${type}&lang=${language}`,
			method: "GET",
			responseType: "blob",
		});

		const report = flowState.report.selected[id] || flowState.report.list.find((item) => item.id === id);
		const username = formatHelper.createUsername({ contact: report.contact || report, reverse: true });
		const suffix = username || stringHelpers.randomUuid(8);
		const extension = typesConfig.fileExtensions[type];

		fileHelper.downloadFile({
			file: file.data,
			fileName: `report_${suffix.replace(" ", "_")}.${extension}`,
		});
		dispatch({
			type: actionTypes.DOWNLOAD_FLOW_REPORT,
			data: id,
		});
		dispatch(showSuccessMessage({ message: "BANNER.SUCCEEDED.DOWNLOAD_REPORT" }));
		dispatch(hideProcessingBanner({ id: bannerId }));
	} catch (error) {
		dispatch(showFailedMessage({ message: "BANNER.FAILED.DOWNLOAD_REPORT" }));
		dispatch(hideProcessingBanner({ id: bannerId }));
	}
};

export const downloadAllReportFiles = ({ reportId }) => async (dispatch) => {
	const bannerId = stringHelpers.randomUuid();

	try {
		dispatch(showProcessingBanner({ id: bannerId, message: "BANNER.PROCESSING.DOWNLOAD_REPORT_ATTACHMENTS" }));
		dispatch({ type: actionTypes.FLOW_REPORT_ATTACHMENTS_ARE_FETCHING, data: true });

		const flowState = store.getState().content.flow;

		const url = apiConfig.flow.report.attachmentsZipped.replace(":reportId", reportId);

		const archive = await axios({
			url: url,
			method: "GET",
			responseType: "blob",
		});

		if (archive.status === 204) {
			dispatch(showSuccessMessage({ message: "BANNER.SUCCEEDED.DOWNLOAD_NO_REPORT_ATTACHMENTS" }));
			dispatch(hideProcessingBanner({ id: bannerId }));
			dispatch({ type: actionTypes.FLOW_REPORT_ATTACHMENTS_ARE_FETCHING, data: false });

			return;
		}

		const report =
			flowState.report.selected[reportId] || flowState.report.list.find((item) => item.id === reportId);

		const flowId =
			flowState.flow.selected?.id || flowState.report.list.find((item) => item.id === reportId)?.flowId;
		const flow = flowState.flow.list.find((item) => item.id === flowId);
		const language = store.getState().dashboard.header.lang.slice(0, 2);
		const flowName = flow.name || flow.template.name[language];
		const username = formatHelper.createUsername({
			contact: report.contact || report,
			reverse: true,
		});

		const suffix = username || stringHelpers.randomUuid(8);
		const zipName = `${flowName} ${suffix}.zip`.split(" ").join("_");

		fileHelper.downloadZip({
			zip: archive.data,
			zipName,
		});

		dispatch({
			type: actionTypes.DOWNLOAD_FLOW_REPORT_FILES,
			data: reportId,
		});

		dispatch(showSuccessMessage({ message: "BANNER.SUCCEEDED.DOWNLOAD_REPORT_ATTACHMENTS" }));
		dispatch(hideProcessingBanner({ id: bannerId }));
		dispatch({ type: actionTypes.FLOW_REPORT_ATTACHMENTS_ARE_FETCHING, data: false });
	} catch (error) {
		dispatch(showFailedMessage({ message: "BANNER.FAILED.DOWNLOAD_REPORT_ATTACHMENTS" }));
		dispatch(hideProcessingBanner({ id: bannerId }));
		dispatch({ type: actionTypes.FLOW_REPORT_ATTACHMENTS_ARE_FETCHING, data: false });
	}
};

export const updateReport = ({ reportId, payload }) => async (dispatch) => {
	dispatch(startFormWatcher());

	dispatch({
		type: actionTypes.UPDATE_REPORT_LOCALLY,
		data: {
			reportId,
			payload,
		},
	});

	try {
		const flowState = store.getState().content.flow;
		const url = apiConfig.flow.report.update
			.replace(":reportId", reportId)
			.replace(":id", flowState.flow.selected.id);
		const result = await axios.put(url, payload);

		if (result.status !== 200 || !result.data.success) {
			throw new Error();
		}

		dispatch(
			formWatcherSucceededNoTimeout(() => {
				dispatch(showSuccessMessage({ message: "BANNER.SUCCEEDED.UPDATE_REPORT" }));
				dispatch({
					type: actionTypes.UPDATE_REPORT,
					data: {
						reportId,
						payload: result.data.data,
					},
				});
			})
		);
	} catch (error) {
		dispatch(
			formWatcherFailed(() => {
				dispatch(showFailedMessage({ message: "BANNER.FAILED.UPDATE_REPORT" }));
			})
		);
	}
};

export const assignToReport = ({ reportId, userId }) => async (dispatch) => {
	try {
		const state = store.getState();
		const url = apiConfig.flow.report.assign
			.replace(":reportId", reportId)
			.replace(":id", state.content.flow.flow.selected.id);
		const result = await axios.put(url, { assignee: userId, assigner: state.profile.account.user._id });

		if (result.status !== 200 || !result.data.success) {
			throw new Error();
		}

		const member = userId && state.content.organization.members.list.find((member) => member.userId === userId);

		dispatch({
			type: actionTypes.ASSIGN_TO_REPORT,
			data: {
				reportId,
				member,
			},
		});
	} catch (error) {
		dispatch(showFailedMessage({ message: "BANNER.FAILED.TO_ASSIGN_USER" }));
	}
};

export const deleteReport = (reportId) => async (dispatch) => {
	dispatch(startDeleteWatcher());

	try {
		const flowState = store.getState().content.flow;
		const result = await axios.delete(
			apiConfig.flow.report.delete.replace(":reportId", reportId).replace(":id", flowState.flow.selected.id)
		);

		if (result.status !== 204) {
			throw new Error();
		}

		dispatch(
			deleteSucceededNoTimeout(() => {
				dispatch(showSuccessMessage({ message: "BANNER.SUCCEEDED.DELETE_REPORT" }));
				dispatch({
					type: actionTypes.DELETE_REPORT,
					data: reportId,
				});
			})
		);
	} catch (error) {
		dispatch(
			deleteFailedNoTimeout(() => {
				dispatch(showFailedMessage({ message: "BANNER.FAILED.DELETE_REPORT" }));
			})
		);
	}
};
