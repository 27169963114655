import axios from "axios";

import { apiConfig } from "@/flows/common/config";

async function updateReportStatus({ flowId, statusId, reportId }) {
	const url = apiConfig.report.updateStatusType.replace(":id", flowId).replace(":reportId", reportId);

	const { data } = await axios.put(url, { status: statusId });

	return data;
}

export default { updateReportStatus };
