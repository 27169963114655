import React from "react";

export default function ASaveUrl({ url, className, children, target = "_blank" }) {
	return (
		<a href={url} className={`a-link ${className}`} target={target} rel="noopener noreferrer">
			{children}
		</a>
	);
}

ASaveUrl.defaultProps = {
	className: "",
	children: "",
};
