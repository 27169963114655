import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import { trpc } from "@/trpc";
import { useHistory, useParams } from "react-router-dom";
import settingsConfig from "@/modules/settings/config";

export default function CreateApiKey({ visible, onClose }: { visible: boolean; onClose: () => void }) {
	const intl = useIntl();
	const routeParameters = useParams<{ team: string }>();
	const history = useHistory();
	const trpcUtils = trpc.useUtils();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const createApiKeyMutation = trpc.apiKeyManager.createApiKey.useMutation();

	const createApiKey = (apiKey) => {
		if (!apiKey) return;
		createApiKeyMutation.mutate(apiKey, {
			onSuccess: (response) => {
				if (typeof response.data._id !== "string") return;

				trpcUtils.apiKeyManager.invalidate();
				history.push(
					settingsConfig.routes.apiKey.detail
						.replace(":team", routeParameters.team)
						.replace(":apiKeyId", response.data._id)
				);
				ShowSuccessBanner({
					title: intl.formatMessage({
						id: "banner.succeeded.create_api_key",
						defaultMessage: "API key is successfully created.",
					}),
				});
			},
			onError: () => {
				ShowFailedBanner({
					title: intl.formatMessage({
						id: "banner.failed.create_api_key",
						defaultMessage: "API key could not be created. Please try again later.",
					}),
				});
			},
		});
	};

	const handleSubmit = (payload) => {
		createApiKey(payload);
	};

	return (
		<Modal
			open={visible}
			onCancel={onClose}
			onOk={handleSubmit}
			title={<FormattedMessage id="api_key_create_modal.header.title" defaultMessage="Create API key" />}
			transitionName="" // to disable animation
			footer={[
				<Button key="submit" type="primary" htmlType="submit" form="createApiKey" loading={isLoading}>
					<FormattedMessage id="api_key_create_modal.delete.add" defaultMessage="Create API key" />
				</Button>,
			]}
		>
			<Form
				id="createApiKey"
				onFinish={handleSubmit}
				requiredMark="optional"
				autoComplete="off"
				layout="vertical"
			>
				<Form.Item
					name="name"
					label={<FormattedMessage id="settings.api_key_detail.form.name.label" defaultMessage="Name" />}
					help={
						<FormattedMessage
							id="settings.api_key_detail.form.name.help"
							defaultMessage="Give your API key a relevant name for better recognition."
						/>
					}
					rules={[
						{
							required: true,
							message: (
								<FormattedMessage
									id="settings.api_key_detail.form.name.required"
									defaultMessage="You need to give your api key a name"
								/>
							),
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="description"
					className="t-gap--top-sm"
					label={
						<FormattedMessage
							id="settings.api_key_detail.form.description.label"
							defaultMessage="Description"
						/>
					}
					help={
						<FormattedMessage
							id="settings.api_key_detail.form.description.help"
							defaultMessage="A description can help you and your team to better monitor the objectives of this API key and to intervene in the event of abuse."
						/>
					}
				>
					<Input.TextArea />
				</Form.Item>
			</Form>
		</Modal>
	);
}
