import "./style.scss";

import { BellFilled } from "@ant-design/icons";
import { Avatar, Badge, Breadcrumb, Button, Divider, Dropdown, Tooltip, Typography } from "antd";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { NavLink } from "react-router-dom";
import NotificationList from "./components/NotificationList";
import { routesConfig } from "../../../../config";
import { actions as dashboardActions } from "../../../../store/dashboard";
import { actions as profileActions } from "../../../../store/profile";
import { trpc } from "../../../../trpc";
import { storageHelpers } from "@/shared/helpers";

interface IDashboardHeader {
	title: string;
	breadcrumbs?: any[];
	children?: ReactNode;
	className?: string;
}

export default function DashboardHeader({ title, children, breadcrumbs, className = "" }: IDashboardHeader) {
	const intl = useIntl();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.profile.account.user);
	const [showUnReadOnly, setShowUnReadOnly] = useState<boolean>(
		storageHelpers.getDashboardState()?.notification?.showUnReadOnly || false
	);

	const {
		data: notificationData,
		isLoading,
		fetchNextPage,
		isInitialLoading,
		isFetchingNextPage,
	} = trpc.notification.notifications.getNotifications.useInfiniteQuery(
		{ isRead: showUnReadOnly ? false : undefined },
		{
			getNextPageParam: (data): { _id: string; createdAt: Date | undefined } | null => {
				if (!data || !data?.data?.length) return null;
				const latest = data?.data?.[data?.data?.length - 1];
				return { _id: latest._id, createdAt: latest.createdAt };
			},
			keepPreviousData: true,
		}
	);

	const handleMenuClick = ({ key }: { key: string }) => {
		const actions = {
			shortcuts: () => dispatch(dashboardActions.ui.toggleShortcuts()),
			logout: () => dispatch(profileActions.account.logoutUser({ redirect: true })),
		};

		actions[key] && actions[key]();
	};

	const total = notificationData?.pages?.[0]?.total;
	const totalUnread = notificationData?.pages?.[0]?.totalUnread;
	const notifications = useMemo(
		() => notificationData?.pages.flatMap((page) => page.data) || [],
		[notificationData?.pages]
	);

	function handleShowUnreadChange() {
		setShowUnReadOnly((prev) => {
			const newValue = !prev;

			storageHelpers.updateDashboardState({
				notification: { ...storageHelpers.getDashboardState()?.notification, showUnReadOnly: newValue },
			});

			return newValue;
		});
	}

	return (
		<header className={`dashboard_header ${className}`}>
			<div className="dashboard_header-left_wrapper">
				{!breadcrumbs?.length && <Typography.Text strong>{title}</Typography.Text>}
				{!!breadcrumbs?.length && (
					<Breadcrumb items={breadcrumbs?.map((item) => ({ path: item.to, title: item.value }))} />
				)}
			</div>
			{children}
			<div className="dashboard_header-right_wrapper">
				<Badge
					size="small"
					count={totalUnread}
					color="var(--secondary)"
					style={{ color: "var(--primary)", fontWeight: 600, fontSize: "9px" }}
					offset={[-6, 8]}
					showZero={false}
				>
					<Dropdown
						destroyPopupOnHide
						trigger={["click"]}
						placement="bottomRight"
						dropdownRender={() => (
							<NotificationList
								showUnReadOnly={showUnReadOnly}
								onShowUnReadChange={handleShowUnreadChange}
								total={total || 0}
								onFetchNext={fetchNextPage}
								notifications={notifications}
								isFetchingNextPage={isFetchingNextPage}
								isLoading={isLoading || isInitialLoading}
							/>
						)}
					>
						<Tooltip
							placement="bottomRight"
							title={intl.formatMessage(
								{ id: "NOTIFICATION_POPUP.BUTTON.OPEN" },
								{ totalUnread: totalUnread || 0 }
							)}
						>
							<Button type="text" icon={<BellFilled style={{ fontSize: 24 }} />} />
						</Tooltip>
					</Dropdown>
				</Badge>
				<Dropdown
					dropdownRender={(menu) => (
						<div className="dashboard_header-profile-dropdown">
							<div className="dashboard_header-profile-dropdown-user_item">
								<Avatar shape="square" size="large" src={user.avatar} />
								<Typography.Text>{user.username}</Typography.Text>
							</div>
							<Divider style={{ margin: 0 }} />
							{menu}
						</div>
					)}
					menu={{
						onClick: handleMenuClick,
						items: [
							{
								key: "settings",
								label: (
									<NavLink to={routesConfig.profile.overview.replace(":slug", user.slug)}>
										<FormattedMessage
											id="PROFILE.POPUP.SETTINGS"
											defaultMessage="Profile Settings"
										/>
									</NavLink>
								),
							},
							{
								key: "shortcuts",
								label: (
									<div className="dashboard_header-notification_item">
										<FormattedMessage
											id="profile.popup.actions.shortcuts"
											defaultMessage="Shortcuts"
										/>
										<kbd className="button-shortcut">/</kbd>
									</div>
								),
							},
							{
								key: "logout",
								label: (
									<div className="dashboard_header-notification_item danger">
										<FormattedMessage id="PROFILE.POPUP.LOGOUT" defaultMessage="Logout" />
									</div>
								),
							},
						],
					}}
				>
					<Avatar shape="square" src={user.avatar} />
				</Dropdown>
			</div>
		</header>
	);
}
