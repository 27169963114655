import "./style.scss";

import { Card, Skeleton } from "antd";

export default function ConversationListItemSkeleton({}) {
	return (
		<Card className="notification-list_item notification-list_item-skeleton">
			<Skeleton active paragraph={{ rows: 1 }} className="ant-skeleton-content" />
		</Card>
	);
}
