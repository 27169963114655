import React from "react";

export default function AListItemDefault({ item, selected, className, onClick, onKeyUp, setRef, ...props }) {
	return (
		<li
			tabIndex="0"
			role="option"
			aria-selected={selected}
			onClick={onClick}
			onKeyUp={onKeyUp}
			ref={setRef}
			{...props}
			className={`a-dropdown-list-item ${selected ? "active" : ""} ${className}`}
		>
			<p className="list-item--text">{item && item.value}</p>
		</li>
	);
}
