import { routesConfig } from "@/config";
import { trpc } from "@/trpc";
import { WarningOutlined } from "@ant-design/icons";
import { Button, Empty, Form, FormInstance, Select, Tooltip } from "antd";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function ChannelSelect({
	form,
	selectedChannel,
	setSelectedChannel,
}: {
	form: FormInstance;
	selectedChannel?: string | undefined;
	setSelectedChannel: (value: string) => void;
}) {
	const intl = useIntl();
	const history = useHistory();

	const state: { user: any; team: any } = useSelector((state) => ({
		user: state.profile.account.user,
		team: state.content.team.team,
	}));

	const { data: inbox, isLoading } = trpc.inbox.inbox.getUserInbox.useQuery();

	useEffect(() => {
		if (!inbox?.channels?.length) return;

		const channel = inbox?.channels?.find(({ disabled }) => !disabled);

		if (!channel) return;

		form.setFieldValue("channelId", channel._id);
		setSelectedChannel(channel._id);
	}, [inbox?.channels]);

	return (
		<Form.Item
			name="channelId"
			rules={[
				{
					required: true,
					message: intl.formatMessage({
						id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.REQUIRED_ERROR",
					}),
				},
			]}
			style={{ width: "100%" }}
		>
			<Select
				notFoundContent={
					<Empty
						image={Empty.PRESENTED_IMAGE_SIMPLE}
						description={
							<p>
								{intl.formatMessage({
									id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.EMPTY",
									defaultMessage: "No channels added to your user",
								})}
							</p>
						}
					>
						<Button
							type="link"
							onClick={() =>
								history.push(
									routesConfig.dashboard.settings.inbox.channels.overview.replace(
										":team",
										state.team?.slug
									)
								)
							}
						>
							<FormattedMessage
								id="FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.EMPTY.ADD"
								defaultMessage="Add channel"
							/>
						</Button>
					</Empty>
				}
				loading={isLoading}
			>
				{inbox?.channels.map((channel) => (
					<Select.Option value={channel._id} key={channel._id} disabled={channel.disabled}>
						{channel.name}
						{channel.disabled && (
							<Tooltip
								title={intl.formatMessage({
									id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.RECONNECT.TOOLTIP",
								})}
							>
								<WarningOutlined className="fs-flow_sending-form_item-select-warning_icon" />
							</Tooltip>
						)}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
}
