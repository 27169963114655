import React from "react";
import { injectIntl } from "react-intl";

import { Component } from "..";

class AIconButton extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isActive: props.active,
			isFocussed: false,
		};

		this.bindHandles();
	}

	bindHandles() {
		this.handleClick = this.handleClick.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (state.isActive !== props.active) {
			return { isActive: props.active };
		}

		return null;
	}

	handleClick(event) {
		if (this.props.onClick) {
			this.props.onClick(event);
		}
	}

	handleFocus() {
		this.setState({ isFocussed: true });
	}
	handleBlur() {
		this.setState({ isFocussed: false });
	}

	render() {
		const cName = `a-button a-button--icon ${this.props.className} ${this.state.isActive ? "active" : ""}`;
		const cIcon = `${this.props.iconClass} ${this.props.disabled ? "disabled" : ""}
			${this.state.isFocussed ? "focus" : ""}`;
		const label = this.props.intl.formatMessage({ id: this.props.label || this.props.icon });
		const state = this.props.disabled ? "disabled" : this.props.state;

		return (
			<button
				type={this.props.type}
				aria-label={label}
				className={cName}
				disabled={this.props.disabled}
				aria-disabled={this.props.disabled}
				onClick={this.handleClick}
				onFocus={this.handleFocus}
				onBlur={this.handleBlur}
			>
				<Component.AIcon
					type={this.props.icon}
					className={cIcon}
					value={this.props.value}
					active={this.state.isActive}
					state={state}
				/>
			</button>
		);
	}
}
AIconButton.defaultProps = {
	type: "button",
	icon: "",
	label: undefined,
	state: undefined,
	value: "",
	active: false,
	disabled: false,
	className: "",
	iconClass: "",
	onClick: undefined,
};

export default injectIntl(AIconButton);
