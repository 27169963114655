import React, { useCallback, useState } from "react";

import { Image, Video } from "@/components/atoms";
import { List } from "@/components/molecules";
import { typesConfig } from "@/config";

export default function ImageCarousel({ items, className }) {
	const [selectedImage, changeImage] = useState(items[0]);
	const handleChange = useCallback(
		(id) => {
			changeImage(items.find((item) => item.id === id));
		},
		[items, changeImage]
	);

	return (
		<div className={`carousel carousel--image ${className}`}>
			{selectedImage.type === typesConfig.imageType.image && (
				<Image.ADefault className="carousel-preview" {...selectedImage} />
			)}
			{selectedImage.type === typesConfig.imageType.video && (
				<Video.AEmbed className="carousel-preview" {...selectedImage} />
			)}
			<List.MImageCarousel items={items} selected={selectedImage.id} onClick={handleChange} />
		</div>
	);
}
