import "./style.scss";

import { EditOutlined, LockOutlined } from "@ant-design/icons";
import { IChannel, SyncStatus } from "@bothive_core/database";
import { Button, Empty, Form, Image, Result, Input, Table, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { emptyList, upgradeIcon } from "@/assets/images/icon";
import settingsConfig from "@/modules/settings/config";
import SkeletonTable from "@/shared/components/table/skeleton";
import { actions as dashboardActions } from "@/store/dashboard";
import { trpc } from "@/trpc";
import { SYNC_STATUS_ICON_CONFIG } from "../channelDetail/components/channelAnalytics";
import ConnectChannel from "../connectChannel";

export default function ChannelsOverview() {
	const intl = useIntl();
	const history = useHistory();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const routeParameters = useParams<{ team: string }>();
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });

	const queryType = Form.useWatch<IChannel["type"]>("type", form);
	const [querySearch] = useDebounce(Form.useWatch<string>("search", form), 500);
	const permissions = useSelector((state) => state.dashboard.permissions);

	const { data: channelsData, isFetching } = trpc.channel.getInboxChannels.useQuery(
		{
			offset: (pagination.current - 1) * pagination.pageSize,
			limit: pagination.pageSize,
			type: queryType,
			q: querySearch,
		},
		{ refetchOnWindowFocus: false }
	);

	useEffect(() => {
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [querySearch, queryType]);

	// Set breadcrumbs and header of this page
	useEffect(() => {
		const title = intl.formatMessage({
			id: "settings.channel.title",
			defaultMessage: "Channels",
			description: "Browser tab title for inbox settings",
		});

		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					id: "channels",
					title: title,
					tabTitle: title,
					breadcrumbs: [
						{
							to: settingsConfig.routes.root.replace(":team", routeParameters.team),
							name: intl.formatMessage({
								id: "settings.channel.breadcrumb.settings",
								defaultMessage: "Settings",
								description: "Breadcrumb in channel page to settings overview",
							}),
						},
						{
							to: settingsConfig.routes.inbox.channels.overview.replace(":team", routeParameters.team),
							name: title,
						},
					],
				},
				intl,
			})
		);
	}, []);

	const handleRowClick = (channel: IChannel) => {
		if (!channel) return;

		history.push(
			settingsConfig.routes.inbox.channels.detail
				.replace(":team", routeParameters.team)
				.replace(":channelId", channel._id)
		);
	};

	if (!permissions.connectChannels) {
		return (
			<main className="channels_overview">
				<Result
					icon={<img src={upgradeIcon} />}
					title={intl.formatMessage({
						id: "settings.channel.lock.title",
						defaultMessage: "Upgrade to Connect Channels",
					})}
					subTitle={intl.formatMessage({
						id: "settings.channel.lock.description",
						defaultMessage:
							"Ready to take your dashboard to the next level? Upgrade now to unlock the full potential and enjoy a seamless experience!.",
					})}
					extra={
						<Button
							size="large"
							onClick={() => history.replace(`/${routeParameters.team}/support?option=pricing`)}
						>
							<FormattedMessage
								id="settings.channel.lock.button.contact_support"
								defaultMessage="Upgrade your plan"
							/>
						</Button>
					}
				/>
			</main>
		);
	}

	return (
		<main className="channels_overview">
			<header>
				<Form
					layout="vertical"
					form={form}
					style={{
						display: "flex",
						alignItems: "flex-end",
						width: "100%",
						gap: "1.5em",
					}}
				>
					<Form.Item name="search" style={{ width: "100%", margin: 0 }}>
						<Input
							size="large"
							placeholder={intl.formatMessage({
								id: "settings.channel.header.search_field.placeholder",
								defaultMessage: "Search on channel name or address",
								description:
									"Search field placeholder, explain user can search on channel name or address",
							})}
						/>
					</Form.Item>
					<Button
						type="primary"
						size="large"
						style={{ width: "max-content" }}
						onClick={() => setIsCreateModalOpen(true)}
					>
						<FormattedMessage id="settings.channel.header.add.button.label" defaultMessage="Add channel" />
					</Button>
				</Form>
			</header>
			<SkeletonTable
				rowKey="_id"
				className="t-gap--top"
				isLoading={isFetching}
				dataSource={channelsData?.data}
				onRow={isFetching ? null : (channel) => ({ onClick: () => handleRowClick(channel) })}
				onChange={(pagination: any) => setPagination(pagination)}
				pagination={{
					current: pagination.current,
					pageSize: pagination.pageSize,
					total: channelsData?.total,
					showSizeChanger: true,
					defaultPageSize: 20,
					pageSizeOptions: ["20", "50", "100", "200"],
				}}
				locale={{
					emptyText: (
						<Empty
							image={emptyList}
							imageStyle={{ height: "256px" }}
							description={
								<FormattedMessage
									id="settings.channel.table.empty"
									defaultMessage="No channels found"
									description="Fallback text when no channels have been found"
								/>
							}
						/>
					),
				}}
			>
				<Table.Column
					key="name"
					title={intl.formatMessage({
						id: "settings.channel.table.name.label",
						defaultMessage: "Channel name",
						description: "Table column title for channel name",
					})}
					dataIndex={[]}
					render={({ logo, name, address }) => (
						<div className="channels_overview-table-row-name">
							<Image src={logo} preview={false} height="32px" width="32px" />
							<Typography.Text>{name}</Typography.Text>
						</div>
					)}
				/>
				<Table.Column
					key="isPrivate"
					title={intl.formatMessage({
						id: "settings.channel.table.is_private.label",
						defaultMessage: "Is private",
					})}
					width="10%"
					dataIndex="isPrivate"
					align="center"
					render={(value) =>
						value && (
							<Typography.Text>
								<LockOutlined className="channels_overview-table-row-icon" />
							</Typography.Text>
						)
					}
				/>
				<Table.Column
					key="syncStatus"
					title={intl.formatMessage({
						id: "settings.channel.table.sync_status.label",
						defaultMessage: "Status",
					})}
					width="10%"
					dataIndex="syncStatus"
					align="center"
					render={(syncStatus: SyncStatus | undefined) => (
						<Tooltip title={intl.formatMessage(SYNC_STATUS_ICON_CONFIG[syncStatus || "DEFAULT"].info)}>
							{SYNC_STATUS_ICON_CONFIG[syncStatus || "DEFAULT"].icon}
						</Tooltip>
					)}
				/>
				<Table.Column
					key="edit"
					width="60px"
					dataIndex="edit"
					render={() => (
						<Typography.Text>
							<EditOutlined />
						</Typography.Text>
					)}
				/>
			</SkeletonTable>
			<ConnectChannel open={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} />
		</main>
	);
}
