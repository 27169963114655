export type keyMappingEvents =
	| "conversation.close"
	| "conversation.forward"
	| "conversation.reply"
	| "conversation.reply_all"
	| "conversation.internal_reply"
	| "conversation.reminder"
	| "conversation.toggle_read"
	| "conversation.open_assign_menu"
	| "conversation.open_participants_menu"
	| "conversation.open_label_menu"
	| "conversation.toggle_self_assign"
	| "conversation.delete"
	| "inbox.previous_conversation"
	| "inbox.next_conversation"
	| "inbox.left_status_folder"
	| "inbox.right_status_folder"
	| "inbox.new_message"
	| "inbox.search.focus"
	| "messaging.mark.bold"
	| "messaging.mark.italic"
	| "messaging.mark.underlined"
	| "messaging.mark.strikethrough"
	| "global.close_modal"
	| "global.show_shortcuts";

export const defaultMapping: Record<string, keyMappingEvents> = {
	"shift+r": "conversation.reply",
	r: "conversation.reply_all",
	f: "conversation.forward",
	c: "conversation.internal_reply",
	u: "conversation.toggle_read",
	l: "conversation.open_label_menu",
	a: "conversation.open_assign_menu",
	i: "conversation.toggle_self_assign",
	p: "conversation.open_participants_menu",
	e: "conversation.close",
	s: "inbox.search.focus",
	backspace: "conversation.delete",
	"cmd+b": "messaging.mark.bold",
	"cmd+i": "messaging.mark.italic",
	"cmd+u": "messaging.mark.underlined",
	"shift+cmd+x": "messaging.mark.strikethrough",
	"/": "global.show_shortcuts",
	escape: "global.close_modal",
	n: "inbox.new_message",
	arrowup: "inbox.previous_conversation",
	j: "inbox.previous_conversation",
	arrowdown: "inbox.next_conversation",
	k: "inbox.next_conversation",
	arrowleft: "inbox.left_status_folder",
	arrowright: "inbox.right_status_folder",
};

// Delete-key move conversation to trash

// visual code mapping format
// "key": "shift+cmd+l",
// "command": "liquid.formatDocument",
// "when": "editorTextFocus"
