import { IReminderSettingsDocument } from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/interface";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import { IntlShape } from "react-intl/lib";
import { FlowRemindersRouterOutput } from "@/trpc";
import moment from "moment";
import { stringHelpers } from "@bothive/helpers";
import omit from "lodash.omit";

export const handleCreateOrUpdate = ({
	values,
	intl,
	selectedReminderStrategyData,
	updateFlowReminderSettingsMutation,
	trpcUtils,
	handleClose,
	createFlowReminderSettingsMutation,
	flowId,
}: {
	values: IReminderSettingsDocument;
	intl: IntlShape;
	selectedReminderStrategyData?: FlowRemindersRouterOutput["getRemindersSetting"];
	updateFlowReminderSettingsMutation: any;
	trpcUtils: any;
	handleClose: () => void;
	createFlowReminderSettingsMutation: any;
	flowId: string;
}) => {
	if (!values) {
		return ShowFailedBanner({
			title: intl.formatMessage({
				id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED",
				defaultMessage: "Failed to update or create reminder strategy",
			}),
			description: intl.formatMessage({
				id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_DESCRIPTION",
				defaultMessage: "Please try again later or contact customer support",
			}),
			intl,
		});
	}

	const cutoffDateUTC = values.cutoffDate.toDate();
	cutoffDateUTC.setHours(23, 59, 59, 999);

	const data = {
		flowId: flowId,
		settings: {
			...values,
			timeOfDayUTC: values.timeOfDay.utc().format("HH:mm"),
			cutoffDateUTC: moment(cutoffDateUTC).utc().toDate(),
			templates: [
				{
					body: values.body,
					subject: values.subject,
					...(!stringHelpers.isEmptyString(values.signatureId) ? { signatureId: values.signatureId } : {}),
				},
			],
			allowReEnroll: values.allowReEnroll === undefined ? true : values.allowReEnroll,
		},
	};

	if (selectedReminderStrategyData) {
		updateFlowReminderSettingsMutation.mutate(
			{
				settingId: selectedReminderStrategyData._id,
				...(selectedReminderStrategyData.stoppedAt && { restartQueue: true }),
				...data,
			},
			{
				onSuccess: () => {
					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.SUCCESSFULLY_UPDATED",
							defaultMessage: "Successfully updated reminder strategy",
						}),
						intl,
					});

					trpcUtils.flowReminders.getRemindersSetting.invalidate();

					handleClose();
				},
				onError: () => {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_TO_UPDATE",
							defaultMessage: "Failed to update reminder strategy",
						}),
						description: intl.formatMessage({
							id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_TO_UPDATE_DESCRIPTION",
							defaultMessage: "Please try again later or contact customer support",
						}),
						intl,
					});
				},
			}
		);

		return;
	}

	createFlowReminderSettingsMutation.mutate(data, {
		onSuccess: () => {
			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.SUCCESSFULLY_CREATED",
					defaultMessage: "Successfully created reminder strategy",
				}),
				intl,
			});

			trpcUtils.flowReminders.getRemindersSetting.invalidate();

			handleClose();
		},
		onError: () => {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_TO_CREATE",
					defaultMessage: "Failed to create reminder strategy",
				}),
				description: intl.formatMessage({
					id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FAILED_TO_CREATE_DESCRIPTION",
					defaultMessage: "Please try again later or contact customer support",
				}),
				intl,
			});
		},
	});
};
