import "./style.scss";

import { RightOutlined } from "@ant-design/icons";
import type { IContact, IPopulatedContactLinking } from "@bothive_core/database";
import { Button, Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import UserPreview from "@/shared/components/user/user_preview";
import { trpc } from "../../../../../../../trpc";
import ShowSuccessBanner from "../../../../../../../shared/components/banner/success";
import ShowFailedBanner from "../../../../../../../shared/components/banner/failed";
import DeleteModal from "../../../../../../../shared/components/modal/delete_modal";
import ContactLinkingForm, { IContactLinkingSubmit } from "./modalComponents/ContactLinkingForm";

interface IUpdateModal {
	contact: IContact;
	linkedContact: IPopulatedContactLinking;
	isOpen: boolean;
	onClose: () => void;
}

export default function UpdateModal({ contact, linkedContact, isOpen, onClose }: IUpdateModal) {
	const intl = useIntl();
	const routerParams = useParams<{ team: string }>();
	const trpcUtils = trpc.useUtils();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const contactUrl = `/${routerParams.team}/contact/id/${linkedContact.linkedContactId}`;

	const updateContactLinkingMutation = trpc.contactLinking.updateById.useMutation();
	const deleteContactLinkingMutation = trpc.contactLinking.deleteById.useMutation();

	const handleSubmit = ({ type, flowProxy, data }: IContactLinkingSubmit) => {
		updateContactLinkingMutation.mutate(
			{
				id: linkedContact._id,
				contactId: contact._id,
				flowProxy,
				type,
				data,
			},
			{
				onSuccess: () => {
					onClose();
					trpcUtils.contactLinking.getAllContactLinksByContactId.invalidate();
					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.SUCCESS",
							defaultMessage: "Contact link successfully updated",
						}),
					});
				},
				onError: () => {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.ERROR",
							defaultMessage: "Failed to update contact link",
						}),
					});
				},
			}
		);
	};

	const handleDelete = () => {
		if (!linkedContact?._id) return;

		deleteContactLinkingMutation.mutate(
			{ id: linkedContact._id },
			{
				onSuccess: () => {
					onClose();
					trpcUtils.contactLinking.getAllContactLinksByContactId.invalidate();
					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.SUCCESS",
							defaultMessage: "Contact link successfully deleted",
						}),
					});
				},
				onError: () => {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.ERROR",
							defaultMessage: "Failed to delete contact link",
						}),
					});
				},
			}
		);
	};

	return (
		<>
			<Modal
				width={700}
				open={isOpen}
				destroyOnClose
				onCancel={onClose}
				title={intl.formatMessage({ id: "CONTACT.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.TITLE" })}
				transitionName="" // to disable animation
				footer={[
					<Button key="delete" danger type="primary" onClick={() => setShowDeleteModal(true)}>
						<FormattedMessage id="SETTINGS.INBOX.LABELS.MODAL.UPDATE.DELETE" />
					</Button>,
					<Button key="submit" type="primary" form="updateLinkContactForm" htmlType="submit">
						<FormattedMessage id="CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.SAVE" />
					</Button>,
				]}
			>
				<div className="contact_linking-contact_detail">
					<UserPreview
						src={linkedContact.linkedContact.avatar}
						username={linkedContact.linkedContact.fullName}
						contactUrl={contactUrl}
					/>

					<NavLink className="contact_detail_nav-link" to={contactUrl}>
						<FormattedMessage id="CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.VIEW_CONTACT" />
						<RightOutlined />
					</NavLink>
				</div>
				<ContactLinkingForm
					contact={contact}
					hideLinkedContact={true}
					linkedContact={linkedContact}
					formId="updateLinkContactForm"
					onSubmit={handleSubmit}
				/>
			</Modal>
			<DeleteModal
				title={intl.formatMessage({
					id: "CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.TITLE",
					defaultMessage: "Delete linked contact",
				})}
				deleteLabel={intl.formatMessage({
					id: "CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.BUTTON",
					defaultMessage: "Delete",
				})}
				description={intl.formatMessage({
					id: "CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.DESCRIPTION",
					defaultMessage: "Are you sure you want to delete this linked contact?",
				})}
				isVisible={showDeleteModal}
				onDelete={handleDelete}
				onClose={onClose}
			/>
		</>
	);
}
