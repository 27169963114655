import { Button, Divider, Modal, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { trpc } from "../../../../../trpc";
import { useHistory, useParams } from "react-router";
import ShowFailedBanner from "../../../../../shared/components/banner/failed";
import LabelList from "./LabelList/index";
import { routesConfig } from "../../../../../config";
import { useSelector } from "react-redux";

export default function LabelSelectModal({
	isOpen,
	setIsOpen,
}: {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
}) {
	const intl = useIntl();
	const history = useHistory();
	const params: { contactId: string } = useParams();
	const trpcUtils = trpc.useUtils();

	// @ts-ignore
	const team = useSelector((state) => state.content.team.team);

	const addLabelToContactMutation = trpc.contact.addLabelToContact.useMutation();
	const removeLabelFromContactMutation = trpc.contact.removeLabelFromContact.useMutation();

	const { data: contactLabels } = trpc.contactLabel.all.useQuery({ limit: 0 });
	const { data: contact } = trpc.contact.getContactById.useQuery({ id: params?.contactId });

	const addedLabels = contactLabels?.data?.filter((label) => contact?.labels?.includes(label._id)) || [];
	const unaddedLabels = contactLabels?.data?.filter((label) => !contact?.labels?.includes(label._id)) || [];

	// @ts-ignore
	return (
		<Modal
			title={intl.formatMessage({ id: "CONTACTS.DETAIL.MODAL.LABELS.TITLE" })}
			bodyStyle={{ padding: "8px" }}
			open={isOpen}
			destroyOnClose // destroyOnClose is necessary to keep focus in search input after open/close/open
			footer={null}
			onCancel={() => setIsOpen(false)}
		>
			<LabelList
				items={unaddedLabels}
				isDestructive={undefined}
				onClick={(value: string) => {
					addLabelToContactMutation.mutate(
						{ contactId: params?.contactId, labelId: value },
						{
							onSuccess: () => {
								trpcUtils.contact.getContactById.invalidate();
							},
							onError: () => {
								ShowFailedBanner({
									title: intl.formatMessage({
										id: "CONTACTS.DETAIL.MODAL.LABELS.ADD.FAILED",
										defaultMessage: "Failed to add labels",
									}),
								});
							},
						}
					);
				}}
			/>
			{addedLabels?.length > 0 && (
				<>
					<Divider />
					<Typography.Text type="secondary" className="t-gap--left-sm">
						<FormattedMessage id="CONTACTS.DETAIL.MODAL.LABELS.DIVIDER" />
					</Typography.Text>
					<LabelList
						items={addedLabels}
						isDestructive={true}
						onClick={(value: string) => {
							removeLabelFromContactMutation.mutate(
								{ contactId: params?.contactId, labelId: value },
								{
									onSuccess: () => {
										trpcUtils.contact.getContactById.invalidate();
									},
									onError: () => {
										ShowFailedBanner({
											title: intl.formatMessage({
												id: "CONTACTS.DETAIL.MODAL.LABELS.REMOVE.FAILED",
												defaultMessage: "Failed to remove labels",
											}),
										});
									},
								}
							);
						}}
					/>
				</>
			)}
			{team?.slug && (
				<Button
					type="link"
					onClick={() =>
						history.push(routesConfig.dashboard.settings.contact.labels.replace(":team", team?.slug))
					}
				>
					<FormattedMessage id="CONTACTS.DETAIL.MODAL.LABELS.ADD" />
				</Button>
			)}
		</Modal>
	);
}
