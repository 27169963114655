import React from "react";

export default function AProfileImage({ src, alt = "", fallback = "", className = "", style = {} }) {
	return (
		<img
			style={style}
			alt={alt}
			src={src}
			onError={({ currentTarget }) => {
				currentTarget.onerror = null; // prevents looping

				if (fallback) {
					currentTarget.src = fallback;
				}
			}}
			className={`a-image a-image--default ${className}`}
		/>
	);
}
