import "./style.scss";

import { Component, Image } from "../../../../components/atoms";
import { keyEvents } from "../../../../helpers";
import config from "./config";

const byteValueNumberFormatter = Intl.NumberFormat("en", {
	notation: "compact",
	style: "unit",
	unit: "byte",
	unitDisplay: "narrow",
});

export default function AttachmentCard({ id, url, type, actionIcon, size, name, className, onClick }) {
	const formatByte = byteValueNumberFormatter.format(size)
	const fallbackIcon = config.fallback[type] || config.fallback.fallback;

	const handleClick = () => {
		onClick(id);
	};
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			handleClick();
		}
	};

	return (
		<div
			role="button" //eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
			tabIndex="0"
			onClick={handleClick}
			onKeyUp={handleKeyUp}
			className={`shared-attachment-card ${className}`}
		>
			{url && <Image.ADefault src={url} fallback={fallbackIcon} className="attachment-icon" />}
			<div className="attachment-content">
				<p className="attachment-title">{name}</p>
				<p className="placeholder">{formatByte}</p>
			</div>
			<div className="attachment-action-icon">
				<Component.AIcon type={actionIcon} className="small" />
			</div>
		</div>
	);
}

AttachmentCard.defaultProps = {
	actionIcon: "download",
};
