import config from "./config";

export function formatPageConfig({ flow, language, routerParameters }) {
	let flowName = "...";

	if (flow?._id === routerParameters?.flowId) {
		flowName = flow?.name || flow?.template?.name?.[language];
	}

	const breadcrumbs = config.breadcrumbs({ name: flowName || "...", ...routerParameters });

	return {
		...config.header,
		tabTitle: flowName || config.header.tabTitle,
		breadcrumbs,
	};
}
