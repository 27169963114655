export default {
	formatDataSource(list) {
		return list.map((apiKey) => ({
			key: apiKey._id,
			content: {
				title: apiKey.name,
				description: apiKey.description,
			},
			apiKey: apiKey.key,
			permissions: apiKey.permissions?.length || 0,
			createdAt: apiKey.createdAt,
			isEnabled: apiKey.isEnabled,
		}));
	},
};
