import { Button, Col, Row, Form, Input, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import FieldLabel from "@/shared/components/field/default";
import { useIntl } from "react-intl";
import { addConnection } from "../common/helpers";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { trpc } from "@/trpc";
import { useState } from "react";

export default function IntegrationConnection({
	allowEditing,
	canAddIntegration,
}: {
	allowEditing: boolean;
	canAddIntegration: boolean;
}) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const history = useHistory();
	const location = useLocation();
	const trpcUtils = trpc.useUtils();
	const addConnectionMutation = trpc.integration.addConnection.useMutation();
	const { connectionId: integrationId }: any = useParams();

	return (
		<Row className="integrationModal">
			<Col className="info" xs={24} xl={9}>
				<section className="info-section">
					<h2 className="info-title">
						<FormattedMessage
							id="settings.integrations.twinntax.connect.title"
							defaultMessage="Connect with Twinntax"
						/>
					</h2>
					<p>
						<FormattedMessage
							id="settings.integrations.twinntax.connect.description"
							defaultMessage="Synchronise contacts from Twinntax"
						/>
					</p>
				</section>
			</Col>
			<Col className="login" xs={24} xl={15}>
				<div className="logo-container">
					<img
						src="https://storage.googleapis.com/bothive_assets_8a0s1s3e/integration_icon/twinntax.png"
						alt="Fid-Manager logo"
						className="logo"
					/>
				</div>
				<Tooltip
					title={
						!allowEditing
							? intl.formatMessage({
									id: "settings.integrations.twinntax.tooltip.missing_integrations_permissions",
									defaultMessage: "Missing edit integrations permissions",
							  })
							: !canAddIntegration
							? intl.formatMessage({
									id: "settings.integrations.twinntax.tooltip.limit_reached",
									defaultMessage: "Maximum integrations limit reached",
							  })
							: null
					}
					placement="bottom"
				>
					<Form
						id="login"
						form={form}
						className="login-form"
						disabled={!allowEditing || !canAddIntegration}
						onFinish={async (values) =>
							addConnection(
								intl,
								trpcUtils,
								integrationId,
								history,
								location,
								addConnectionMutation,
								values
							)
						}
					>
						<FieldLabel
							label={intl.formatMessage({
								id: "settings.integrations.twinntax.form.api_key.label",
								defaultMessage: "Api key",
							})}
							help={intl.formatMessage({
								id: "settings.integrations.twinntax.form.api_key.help",
								defaultMessage:
									"You can create an apikey in your Twinntax dashboard under Instellingen -> API Instellingen -> Creëer een API sleutel",
							})}
						>
							<Form.Item
								name="token"
								className="form-input"
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id: "settings.integrations.twinntax.form.api_key.required",
											defaultMessage: "Please fill in an api key",
										}),
									},
								]}
							>
								<Input size="large" autoFocus />
							</Form.Item>
						</FieldLabel>
						<Button
							type="primary"
							htmlType="submit"
							form="login"
							size="large"
							className="form-button"
							loading={addConnectionMutation.isLoading}
						>
							<FormattedMessage
								id="settings.integrations.twinntax.form.submit"
								defaultMessage="Connect with Twinntax"
							/>
						</Button>
					</Form>
				</Tooltip>
			</Col>
		</Row>
	);
}
