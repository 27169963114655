import { atom, selector } from "recoil";

import { getFlowTemplates } from "@/flows/overview/create/query";
import { getFlows } from "@/flows/overview/query";

export const isAddFlowModalVisibleState = atom({
	key: "focIsAddFlowModalVisibleState",
	default: false,
});

export const selectedTemplateIdState = atom({
	key: "focSelectedTemplateIdState",
	default: undefined,
});

export const flowTemplates = selector({
	key: "focFlowTemplates",
	get: async () => {
		const result = await getFlowTemplates({ compact: true });

		if (!result?.success) {
			throw new Error();
		}

		return result;
	},
});

export const selectedFlowTemplate = selector({
	key: "focSelectedFlowTemplate",
	get: ({ get }) => {
		const selectedTemplateId = get(selectedTemplateIdState);
		const templates = get(flowTemplates);

		return templates?.data?.find((item) => item._id === selectedTemplateId);
	},
});

export const checkIfFlowExists = selector({
	key: "focCheckIfFlowExists",
	get: async ({ get }) => {
		const selectedTemplateId = get(selectedTemplateIdState);

		const { data } = await getFlows({ templateId: selectedTemplateId });

		return data.length > 0;
	},
});
