import { atom, atomFamily } from "recoil";

import { typesConfig } from "@/flows/common/config";
import { getReportById } from "@/flows/report_detail/query";

export const selectedReportIdState = atom({
	key: "foSelectedReportId",
	default: "",
});

export const reportViewState = atom({
	key: "frReportViewState",
	default: typesConfig.reportType.excel,
});

export const isEditingReportState = atom({
	key: "frIsEditingReportState",
	default: false,
});

export const isInvalidReportState = atom({
	key: "frIsInvalidReportState",
	default: false,
});

export const reportState = atomFamily({
	key: "frReportState",
	default: async ({ flowId, reportId }) => {
		if (!flowId || !reportId) {
			return undefined;
		}

		const result = await getReportById({ flowId, reportId });

		return result?.data;
	},
});

export const editedReportState = atom({
	key: "frEditedReportState",
	default: undefined,
});
