import React from "react";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";

import { ListItem } from "..";
import { keyEvents } from "../../../helpers";

export default class ADropdownList extends React.Component {
	constructor(props) {
		super(props);

		const focusPosition = props.items.findIndex((item) => (item.uuid || item.id || item._id) === props.selectedId);

		this.state = {
			focusPosition: focusPosition === -1 ? 0 : focusPosition,
		};

		this.bindHandles();
		this.moveFocus(focusPosition === -1 ? 0 : focusPosition);
	}

	componentDidMount() {
		const element = ReactDOM.findDOMNode(this); //eslint-disable-line react/no-find-dom-node
		const elementPos = element.getBoundingClientRect();
		const body = document.body.getBoundingClientRect();

		if (elementPos.right > body.right) {
			const offset = elementPos.right - body.right + 40;

			element.style.left = `-${offset}px`;
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.focusPosition !== this.props.focusPosition) {
			this.moveFocus({ position: this.props.focusPosition });
			this.setState({ focusPosition: this.props.focusPosition });
		}
	}

	bindHandles() {
		this.handleKeyUp = this.handleKeyUp.bind(this);
	}

	handleKeyUp(event) {
		if (keyEvents.upArrowPress(event)) {
			this.setState((prev) => {
				const focusPosition = prev.focusPosition > 0 ? prev.focusPosition - 1 : this.props.items.length - 1;

				this.moveFocus({ position: focusPosition });
				return { focusPosition };
			});
		}

		if (keyEvents.downArrowPress(event)) {
			this.setState((prev) => {
				const focusPosition = prev.focusPosition < this.props.items.length - 1 ? prev.focusPosition + 1 : 0;

				this.moveFocus({ position: focusPosition });
				return { focusPosition };
			});
		}
	}

	moveFocus({ position }) {
		const el = document.querySelectorAll(".a-dropdown--list-item")[position];

		if (!el) {
			return;
		}

		el.focus();
	}

	render() {
		if (!this.props.items.length) {
			return (
				<div className={`a-dropdown--list a-dropdown-fallback ${this.props.className}`}>
					<p>
						<FormattedMessage id="FALLBACK.DROPDOWN.NO_ITEMS_FOUND" />
					</p>
				</div>
			);
		}

		return (
			<ul
				role="listbox"
				tabIndex="0"
				aria-labelledby={this.props.label}
				aria-activedescendant={this.props.items[0].uuid || this.props.items[0].id || this.props.items[0]._id}
				className={`a-dropdown--list a-dropdown--list-default ${this.props.className}`}
				onKeyUp={this.handleKeyUp}
			>
				{this.props.items.map((item) => {
					const id = item.uuid || item.id || item._id;

					return (
						<ListItem.AListItemDropdown
							id={id}
							key={id}
							selected={id === this.props.selectedId}
							value={item.value}
							className={item.className}
							onClick={this.props.onClick}
						/>
					);
				})}
			</ul>
		);
	}
}
