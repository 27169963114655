import axios from "axios";

import { apiConfig } from "@/flows/common/config";

async function executeRule({ flowId, ruleId, reportId, formatType }) {
	const url = apiConfig.rules.execute.replace(":ruleId", ruleId);

	const { data } = await axios.post(url, { formatType, projectId: reportId, flowId, reportId });

	return data;
}

async function automationHistory({ reportId }) {
	const url = `${apiConfig.rules.getHistory}?projectId=${reportId}`;
	const { data } = await axios.get(url);

	if (!data.success) {
		throw new Error();
	}

	return data.data;
}

export default { automationHistory, executeRule };
