import { Button } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";

import { updateReport } from "../../query";
import { editedReportState, isEditingReportState, isInvalidReportState, reportState } from "../../state";
import style from "./style.module.scss";

export default function ViewerFooter() {
	const intl = useIntl();
	const params = useParams();
	const [isSaving, setIsSaving] = useState(false);
	const reportId = params.reportId;
	const [editedReportData, setEditedReportData] = useRecoilState(editedReportState);
	const [isEditing, setIsEditing] = useRecoilState(isEditingReportState);
	const isInvalid = useRecoilValue(isInvalidReportState);
	const setReport = useSetRecoilState(reportState({ reportId, flowId: params?.flowId }));

	const handleCancelEdit = () => {
		setEditedReportData(undefined);
		setIsEditing(false);
	};

	const handleSave = async () => {
		setIsSaving(true);

		try {
			const result = await updateReport({
				reportId,
				flowId: params?.flowId,
				payload: { data: editedReportData },
			});

			if (!result?.success) throw new Error();

			setIsSaving(false);
			setIsEditing(false);
			setReport(result.data);

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT_DETAIL.UPDATE.SUCCESS.TITLE",
					defaultMessage: "Report updated",
				}),
			});
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT_DETAIL.UPDATE.FAILED.TITLE",
					defaultMessage: "Report failed to update",
				}),
				description: intl.formatMessage({
					id: "FLOWS.REPORT_DETAIL.UPDATE.FAILED.DESCRIPTION",
					defaultMessage: "Please try again later or contact customer support",
				}),
			});

			setIsSaving(false);
		}
	};

	if (!isEditing) {
		return null;
	}

	return (
		<footer className={style.viewer_footer}>
			<Button type="text" onClick={() => handleCancelEdit()}>
				<FormattedMessage id="FLOWS.REPORT_DETAIL.VIEWER.FOOTER.CANCEL" />
			</Button>
			<Button type="primary" loading={isSaving} onClick={() => handleSave()} disabled={isInvalid}>
				<FormattedMessage id="FLOWS.REPORT_DETAIL.VIEWER.FOOTER.SAVE" />
			</Button>
		</footer>
	);
}
