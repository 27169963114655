import "./style.scss";

import { Select } from "antd";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValueLoadable } from "recoil";

import { routesConfig } from "@/config";
import { flowState } from "@/flows/common/state";
import { reportState, reportViewState } from "@/flows/report_detail/state";
import FieldSideLabel from "@/shared/components/field/side_label";
import UserPreview from "@/shared/components/user/user_preview";
import { formatHelpers } from "@/shared/helpers";

import { useEffect } from "react";

export default function ReportDetailHeader() {
	const routeParameters = useParams();
	const flowId = routeParameters.flowId;
	const reportId = routeParameters.reportId;
	const flow = useRecoilValueLoadable(flowState({ id: flowId }));
	const [reportViewType, setReportViewType] = useRecoilState(reportViewState);
	const report = useRecoilValueLoadable(reportState({ flowId, reportId }));

	const generateContactUrl = () => {
		if (!report.contents?.contactId || !routeParameters?.team) return;

		return routesConfig.dashboard.contact.detail.root
			.replace(":team", routeParameters.team)
			.replace(":contactId", report.contents?.contactId);
	};

	const username = formatHelpers.createUsername({
		contact: report?.contents?.contact || report?.contents,
		reverse: true,
	});

	useEffect(() => {
		if (!flow?.contents?.template?.previewTypes?.[0]) return;
		if (flow?.contents?.template?.previewTypes?.[0].type === reportViewType) return;

		setReportViewType(flow?.contents?.template?.previewTypes?.[0].type);
	}, [flow?.contents?.template?.previewTypes]);

	return (
		<header className="report_detail-report_header">
			<UserPreview
				username={username}
				id={report.contents?.contactId}
				src={report.contents?.contact?.avatar}
				isLoading={report.state === "loading"}
				contactUrl={generateContactUrl()}
			/>
			<FieldSideLabel label="FLOWS.REPORT_DETAIL.HEADER.PREVIEW_STATE.LABEL" className="header_select">
				<Select
					popupMatchSelectWidth={false}
					loading={flow.state === "loading"}
					defaultValue={reportViewType}
					onChange={setReportViewType}
					value={reportViewType}
				>
					{flow.contents?.template?.previewTypes?.map(({ type }) => (
						<Select.Option key={type} value={type}>
							<p>{type}</p>
						</Select.Option>
					))}
				</Select>
			</FieldSideLabel>
		</header>
	);
}
