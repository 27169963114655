import { DownOutlined } from "@ant-design/icons";
import type { ElementNode, LexicalEditor } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import useLexicalEditable from "@lexical/react/useLexicalEditable";
import {
	$deleteTableColumn__EXPERIMENTAL,
	$deleteTableRow__EXPERIMENTAL,
	$getNodeTriplet,
	$getTableCellNodeFromLexicalNode,
	$getTableColumnIndexFromTableCellNode,
	$getTableNodeFromLexicalNodeOrThrow,
	$getTableRowIndexFromTableCellNode,
	$insertTableColumn__EXPERIMENTAL,
	$insertTableRow__EXPERIMENTAL,
	$isTableCellNode,
	$isTableRowNode,
	$isTableSelection,
	getTableObserverFromTableElement,
	HTMLTableElementWithWithTableSelectionState,
	TableCellHeaderStates,
	TableCellNode,
	TableRowNode,
	TableSelection,
} from "@lexical/table";
import {
	$createParagraphNode,
	$getRoot,
	$getSelection,
	$isElementNode,
	$isParagraphNode,
	$isRangeSelection,
	$isTextNode,
} from "lexical";
import { Dropdown } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";

import { tableMenu } from "../../config";

import "./style.scss";

function invariant(cond?: boolean, message?: string, ...args: string[]): asserts cond {
	if (cond) return;

	throw new Error(
		"Internal Lexical error: invariant() is meant to be replaced at compile " +
			"time. There is no runtime version. Error: " +
			message
	);
}

function computeSelectionCount(selection: TableSelection): {
	columns: number;
	rows: number;
} {
	const selectionShape = selection.getShape();

	return {
		columns: selectionShape.toX - selectionShape.fromX + 1,
		rows: selectionShape.toY - selectionShape.fromY + 1,
	};
}

// This is important when merging cells as there is no good way to re-merge weird shapes (a result
// of selecting merged cells and non-merged)
function isGridSelectionRectangular(selection: TableSelection): boolean {
	const nodes = selection.getNodes();
	const currentRows: Array<number> = [];
	let currentRow: TableRowNode | null = null;
	let expectedColumns: number | null = null;
	let currentColumns = 0;

	for (let i = 0; i < nodes.length; i++) {
		const node = nodes[i];

		if ($isTableCellNode(node)) {
			const row = node.getParentOrThrow();
			invariant($isTableRowNode(row), "Expected CellNode to have a RowNode parent");

			if (currentRow !== row) {
				if (expectedColumns !== null && currentColumns !== expectedColumns) return false;
				if (currentRow !== null) expectedColumns = currentColumns;

				currentRow = row;
				currentColumns = 0;
			}

			const colSpan = node.__colSpan;

			for (let j = 0; j < colSpan; j++) {
				if (currentRows[currentColumns + j] === undefined) currentRows[currentColumns + j] = 0;

				currentRows[currentColumns + j] += node.__rowSpan;
			}

			currentColumns += colSpan;
		}
	}

	return (
		(expectedColumns === null || currentColumns === expectedColumns) &&
		currentRows.every((v) => v === currentRows[0])
	);
}

function $canUnmerge(): boolean {
	const selection = $getSelection();

	if (
		($isRangeSelection(selection) && !selection.isCollapsed()) ||
		($isTableSelection(selection) && !selection.anchor.is(selection.focus)) ||
		(!$isRangeSelection(selection) && !$isTableSelection(selection))
	) {
		return false;
	}

	const [cell] = $getNodeTriplet(selection.anchor);

	return cell.__colSpan > 1 || cell.__rowSpan > 1;
}

function $cellContainsEmptyParagraph(cell: TableCellNode): boolean {
	if (cell.getChildrenSize() !== 1) return false;

	const firstChild = cell.getFirstChild();

	if (!firstChild || !$isParagraphNode(firstChild) || !firstChild.isEmpty()) return false;

	return true;
}

function $selectLastDescendant(node: ElementNode): void {
	const lastDescendant = node.getLastDescendant();

	if ($isTextNode(lastDescendant)) {
		lastDescendant.select();
		return;
	}

	if ($isElementNode(lastDescendant)) {
		lastDescendant.selectEnd();
		return;
	}

	if (lastDescendant !== null) {
		lastDescendant.selectNext();
		return;
	}
}

function TableCellActionMenuContainer({
	anchorElem,
	cellMerge,
}: {
	anchorElem: HTMLElement;
	cellMerge: boolean;
}): JSX.Element | null {
	const intl = useIntl();
	const menuButtonRef = useRef(null);
	const [editor] = useLexicalComposerContext();

	// const [canMergeCells, setCanMergeCells] = useState(false);
	// const [canUnmergeCell, setCanUnmergeCell] = useState(false);
	const [selectionCounts, updateSelectionCounts] = useState({ columns: 1, rows: 1 });
	const [tableCellNode, setTableMenuCellNode] = useState<TableCellNode | null>(null);

	const moveMenu = useCallback(() => {
		const menu = menuButtonRef.current;
		const selection = $getSelection();
		const nativeSelection = window.getSelection();
		const activeElement = document.activeElement;

		if (selection == null || menu == null) return setTableMenuCellNode(null);

		const rootElement = editor.getRootElement();

		if (
			$isRangeSelection(selection) &&
			rootElement !== null &&
			nativeSelection !== null &&
			rootElement.contains(nativeSelection.anchorNode)
		) {
			const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(selection.anchor.getNode());

			if (tableCellNodeFromSelection == null) return setTableMenuCellNode(null);

			const tableCellParentNodeDOM = editor.getElementByKey(tableCellNodeFromSelection.getKey());

			if (tableCellParentNodeDOM == null) return setTableMenuCellNode(null);

			setTableMenuCellNode(tableCellNodeFromSelection);
			return;
		}

		if (!activeElement) return setTableMenuCellNode(null);
	}, [editor]);

	useEffect(() => {
		return editor.registerUpdateListener(() => {
			editor.getEditorState().read(() => {
				moveMenu();
			});
		});
	}, [editor, tableCellNode]);

	useEffect(() => {
		return editor.registerMutationListener(TableCellNode, (nodeMutations) => {
			if (!tableCellNode) return;

			const nodeUpdated = nodeMutations.get(tableCellNode.getKey()) === "updated";

			if (nodeUpdated) {
				editor.getEditorState().read(() => setTableMenuCellNode(tableCellNode.getLatest()));
			}
		});
	});

	useEffect(() => {
		// Positions table cell menu option in the correct cell
		const menuButtonDOM = menuButtonRef.current as HTMLButtonElement | null;

		if (!menuButtonDOM) return;
		if (!tableCellNode) {
			menuButtonDOM.style.opacity = "0";
			menuButtonDOM.style.transform = "translate(-10000px, -10000px)";
			return;
		}

		const tableCellNodeDOM = editor.getElementByKey(tableCellNode.getKey());

		if (!tableCellNodeDOM) {
			menuButtonDOM.style.opacity = "0";
			menuButtonDOM.style.transform = "translate(-10000px, -10000px)";
			return;
		}

		const tableCellRect = tableCellNodeDOM.getBoundingClientRect();
		const menuRect = menuButtonDOM.getBoundingClientRect();
		const anchorRect = anchorElem.getBoundingClientRect();

		const top = tableCellRect.top - anchorRect.top + 4;
		const left = tableCellRect.right - menuRect.width - 4 - anchorRect.left;

		menuButtonDOM.style.height = `${tableCellRect.height}`;
		menuButtonDOM.style.opacity = "1";
		menuButtonDOM.style.transform = `translate(${left}px, ${top}px)`;
	}, [menuButtonRef, tableCellNode, editor, anchorElem]);

	// Merge cells
	useEffect(() => {
		editor.getEditorState().read(() => {
			const selection = $getSelection();
			// Merge cells
			if ($isTableSelection(selection)) {
				const currentSelectionCounts = computeSelectionCount(selection);

				updateSelectionCounts(computeSelectionCount(selection));
				// setCanMergeCells(
				// 	isGridSelectionRectangular(selection) &&
				// 		(currentSelectionCounts.columns > 1 || currentSelectionCounts.rows > 1)
				// );
			}

			// Unmerge cell
			// setCanUnmergeCell($canUnmerge());
		});
	}, [editor]);

	const clearTableSelection = useCallback(() => {
		editor.update(() => {
			if (!tableCellNode) return;
			if (tableCellNode.isAttached()) {
				const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
				const tableElement = editor.getElementByKey(
					tableNode.getKey()
				) as HTMLTableElementWithWithTableSelectionState;

				if (!tableElement) throw new Error("Expected to find tableElement in DOM");

				const tableSelection = getTableObserverFromTableElement(tableElement);

				if (tableSelection !== null) tableSelection.clearHighlight();

				tableNode.markDirty();
				setTableMenuCellNode(tableCellNode.getLatest());
			}

			const rootNode = $getRoot();
			rootNode.selectStart();
		});
	}, [editor, tableCellNode]);

	const mergeTableCellsAtSelection = () => {
		editor.update(() => {
			const selection = $getSelection();

			if (!$isTableSelection(selection)) return;

			const { columns, rows } = computeSelectionCount(selection);
			const nodes = selection.getNodes();
			let firstCell: null | TableCellNode = null;

			for (let i = 0; i < nodes.length; i++) {
				const node = nodes[i];
				if ($isTableCellNode(node)) {
					if (firstCell === null) {
						node.setColSpan(columns).setRowSpan(rows);
						firstCell = node;

						const isEmpty = $cellContainsEmptyParagraph(node);
						let firstChild;

						if (isEmpty && $isParagraphNode((firstChild = node.getFirstChild()))) firstChild.remove();
					} else if ($isTableCellNode(firstCell)) {
						const isEmpty = $cellContainsEmptyParagraph(node);

						if (!isEmpty) firstCell.append(...node.getChildren());

						node.remove();
					}
				}
			}

			if (firstCell !== null) {
				if (firstCell.getChildrenSize() === 0) {
					firstCell.append($createParagraphNode());
				}
				$selectLastDescendant(firstCell);
			}
		});
	};

	const unmergeTableCellsAtSelection = () => {
		console.error("un merge is not supported yet");
		// editor.update(() => {
		// 	$unmergeCell();
		// });
	};

	const insertTableRowAtSelection = useCallback(
		(shouldInsertAfter: boolean) => editor.update(() => $insertTableRow__EXPERIMENTAL(shouldInsertAfter)),
		[editor]
	);

	const insertTableColumnAtSelection = useCallback(
		(shouldInsertAfter: boolean) => editor.update(() => $insertTableColumn__EXPERIMENTAL(shouldInsertAfter)),
		[editor]
	);

	const deleteTableRowAtSelection = useCallback(() => editor.update(() => $deleteTableRow__EXPERIMENTAL()), [editor]);

	const deleteTableAtSelection = useCallback(() => {
		editor.update(() => {
			if (!tableCellNode) return;

			const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

			tableNode.remove();
			clearTableSelection();
		});
	}, [editor, tableCellNode, clearTableSelection]);

	const deleteTableColumnAtSelection = useCallback(
		() => editor.update(() => $deleteTableColumn__EXPERIMENTAL()),
		[editor]
	);

	const toggleTableRowIsHeader = useCallback(() => {
		editor.update(() => {
			if (!tableCellNode) return;

			const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
			const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);
			const tableRows = tableNode.getChildren();

			if (tableRowIndex >= tableRows.length || tableRowIndex < 0) {
				throw new Error("Expected table cell to be inside of table row.");
			}

			const tableRow = tableRows[tableRowIndex];

			if (!$isTableRowNode(tableRow)) throw new Error("Expected table row");

			tableRow.getChildren().forEach((tableCell) => {
				if (!$isTableCellNode(tableCell)) throw new Error("Expected table cell");

				tableCell.toggleHeaderStyle(TableCellHeaderStates.ROW);
			});

			clearTableSelection();
		});
	}, [editor, tableCellNode, clearTableSelection]);

	const toggleTableColumnIsHeader = useCallback(() => {
		editor.update(() => {
			if (!tableCellNode) return;

			const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
			const tableColumnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode);
			const tableRows = tableNode.getChildren();

			for (let r = 0; r < tableRows.length; r++) {
				const tableRow = tableRows[r];

				if (!$isTableRowNode(tableRow)) throw new Error("Expected table row");

				const tableCells = tableRow.getChildren();

				if (tableColumnIndex >= tableCells.length || tableColumnIndex < 0) {
					throw new Error("Expected table cell to be inside of table row.");
				}

				const tableCell = tableCells[tableColumnIndex];

				if (!$isTableCellNode(tableCell)) throw new Error("Expected table cell");

				tableCell.toggleHeaderStyle(TableCellHeaderStates.COLUMN);
			}

			clearTableSelection();
		});
	}, [editor, tableCellNode, clearTableSelection]);

	const handleMenuClick = ({ key }) => {
		const actions = {
			insert_row_above: () => insertTableRowAtSelection(false),
			insert_row_below: () => insertTableRowAtSelection(true),
			insert_column_left: () => insertTableColumnAtSelection(false),
			insert_column_right: () => insertTableColumnAtSelection(true),
			delete_column: deleteTableColumnAtSelection,
			delete_row: deleteTableRowAtSelection,
			delete_table: deleteTableAtSelection,
			row_header: toggleTableRowIsHeader,
			column_header: toggleTableColumnIsHeader,
			merge_cells: mergeTableCellsAtSelection,
			un_merge_cells: unmergeTableCellsAtSelection,
		};

		actions[key] && actions[key]();
	};

	// let mergeMenu: { key: string; label: string }[] = [];
	// if (cellMerge) {
	// 	if (canMergeCells) {
	// 		mergeMenu.push({
	// 			key: "merge_cells",
	// 			label: tableMenu.merge_cells,
	// 		});
	// 	}

	// 	if (canUnmergeCell) {
	// 		mergeMenu.push({
	// 			key: "un_merge_cells",
	// 			label: tableMenu.un_merge_cells,
	// 		});
	// 	}
	// }

	// if (!tableCellNode) return null;

	return (
		<div className="editor-table_cell-menu-container" ref={menuButtonRef}>
			<Dropdown
				menu={{
					onClick: handleMenuClick,
					items: [
						// ...mergeMenu,
						{
							key: "row",
							type: "group",
							label: intl.formatMessage(tableMenu.row_group_title),
							children: [
								{
									key: "row_header",
									label:
										(tableCellNode?.__headerState && TableCellHeaderStates.ROW) ===
										TableCellHeaderStates.ROW
											? intl.formatMessage(tableMenu.remove_row_header)
											: intl.formatMessage(tableMenu.add_row_header),
								},
								{
									key: "insert_row_above",
									label: intl.formatMessage(tableMenu.insert_row_above, {
										selectedRows: selectionCounts.rows,
									}),
								},
								{
									key: "insert_row_below",
									label: intl.formatMessage(tableMenu.insert_row_below, {
										selectedRows: selectionCounts.rows,
									}),
								},
								{
									danger: true,
									key: "delete_row",
									label: intl.formatMessage(tableMenu.delete_row),
								},
							],
						},
						{
							key: "column",
							type: "group",
							label: intl.formatMessage(tableMenu.column_group_title),
							children: [
								{
									key: "column_header",
									label:
										(tableCellNode?.__headerState && TableCellHeaderStates.COLUMN) ===
										TableCellHeaderStates.COLUMN
											? intl.formatMessage(tableMenu.remove_column_header)
											: intl.formatMessage(tableMenu.add_column_header),
								},
								{
									key: "insert_column_left",
									label: intl.formatMessage(tableMenu.insert_column_left, {
										selectedColumns: selectionCounts.columns,
									}),
								},
								{
									key: "insert_column_right",
									label: intl.formatMessage(tableMenu.insert_column_right, {
										selectedColumns: selectionCounts.columns,
									}),
								},
								{
									danger: true,
									key: "delete_column",
									label: intl.formatMessage(tableMenu.delete_column),
								},
							],
						},
						{
							key: "generic",
							type: "group",
							label: intl.formatMessage(tableMenu.table_group_options),
							children: [
								{
									danger: true,
									key: "delete_table",
									label: intl.formatMessage(tableMenu.delete_table),
								},
							],
						},
					],
				}}
			>
				<DownOutlined />
			</Dropdown>
		</div>
	);
}

interface ITableActionMenuPlugin {
	anchorElem?: HTMLElement;
	cellMerge?: boolean;
}
export function TableActionMenuPlugin({ anchorElem = document.body, cellMerge = false }: ITableActionMenuPlugin) {
	const isEditable = useLexicalEditable();

	if (!isEditable) return null;

	return createPortal(
		isEditable ? <TableCellActionMenuContainer anchorElem={anchorElem} cellMerge={cellMerge} /> : null,
		anchorElem
	);
}
