import actionTypes from "./members.actionTypes";

const initialState = {
	list: [],

	isFetching: false,
	fetchingFailed: false,
	fetchingSucceeded: false,

	filter: {
		limit: 20,
		offset: 0,
		total: 0,
	},
};

export default function (state = initialState, action) {
	const updateState = {
		[actionTypes.MEMBERS_IS_FETCHING]: () => ({
			...state,
			isFetching: true,
			fetchingFailed: false,
			fetchingSucceeded: false,
		}),
		[actionTypes.FETCH_MEMBERS_FAILED]: () => ({
			...state,
			isFetching: false,
			fetchingFailed: true,
			fetchingSucceeded: false,
		}),
		[actionTypes.FETCH_MEMBERS_SUCCEEDED]: () => ({
			...state,
			isFetching: false,
			fetchingFailed: false,
			fetchingSucceeded: true,
		}),
		[actionTypes.FETCH_MEMBERS]: () => ({
			...state,
			list: action.data.data,
			filter: action.data.meta,
		}),
		[actionTypes.UPDATE_MEMBER_FILTERS]: () => ({
			...state,
			filter: {
				...state.filter,
				...action.data,
			},
		}),
		[actionTypes.TEAM_CHANGED]: () => initialState,
		[actionTypes.FETCH_TEAM]: () => initialState,
	};

	return updateState[action.type] ? updateState[action.type]() : state;
}
