import { combineReducers } from "redux";

import { actions as flowActions, reducers as flowReducer } from "./flow";
import { actions as formActions, reducers as formReducer } from "./form";
import { actions as organizationActions, reducers as organizationReducer } from "./organization";
import { actions as teamActions, reducers as teamReducer } from "./team";

export const actions = {
	flow: flowActions,
	form: formActions,
	organization: organizationActions,
	team: teamActions,
};

export const reducers = combineReducers({
	flow: flowReducer,
	form: formReducer,
	organization: organizationReducer,
	team: teamReducer,
});
