const routes = {
	root: "/:team/settings",
	advanced: "/:team/settings/advanced",
	apiKey: {
		overview: "/:team/settings/api_key",
		detail: "/:team/settings/api_key/:apiKeyId",
	},
	virtualAssistant: {
		general: "/:team/settings/virtual_assistant",
		responses: "/:team/settings/virtual_assistant/responses",
	},
	branding: {
		main: "/:team/settings/branding",
		widget: "/:team/settings/widget",
	},
	contact: {
		overview: "/:team/settings/contact",
		labels: "/:team/settings/contact/labels",
	},
	members: {
		overview: "/:team/settings/members",
	},
	notification: "/:team/settings/notifications",
	organization: {
		general: "/:team/settings/organization/general",
		openingHours: "/:team/settings/organization/opening_hours",
	},
	intents: {
		overview: "/:team/settings/intents",
		categoryDetail: "/:team/settings/intents/:categoryId",
		detail: "/:team/settings/intents/:categoryId/:intentId",
	},
	integrations: {
		overview: "/:team/settings/integration",
		detail: "/:team/settings/integration/:slug",
		adminPulseIntegration: "/:team/settings/integration/admin_pulse/:connectionId?",
		billitIntegration: "/:team/settings/integration/billit/:connectionId?",
		cmIntegration: "/:team/settings/integration/cm/:connectionId?",
		exactIntegration: "/:team/settings/integration/exact/:connectionId?",
		liantisIntegration: "/:team/settings/integration/liantis/:connectionId?",
		fidManagerIntegration: "/:team/settings/integration/fid_manager/:connectionId?",
		mailchimpIntegration: "/:team/settings/integration/mailchimp/:connectionId?",
		messengerIntegration: "/:team/settings/integration/facebook_messenger/:connectionId?",
		octopusIntegration: "/:team/settings/integration/octopus/:connectionId?",
		silverfinIntegration: "/:team/settings/integration/silverfin/:connectionId?",
		slackIntegration: "/:team/settings/integration/slack/:connectionId?",
		twilioIntegration: "/:team/settings/integration/twilio/:connectionId?",
		yukiIntegration: "/:team/settings/integration/yuki/:connectionId?",
		teamsIntegration: "/:team/settings/integration/teams/:connectionId?",
		twinntaxIntegration: "/:team/settings/integration/twinntax/:connectionId?",
	},
	inbox: {
		settings: "/:team/settings/inbox",
		labels: "/:team/settings/inbox/labels",
		channels: {
			overview: "/:team/settings/inbox/channels",
			detail: "/:team/settings/inbox/channels/:channelId",
		},
		emailSignature: {
			detail: "/:team/settings/inbox/signature/:id",
			overview: "/:team/settings/inbox/signature",
		},
	},
	triggers: {
		overview: "/:team/settings/triggers",
		detail: "/:team/settings/triggers/:triggerId",
	},
};

export default {
	aside: ({ team }) => [
		{
			title: "SETTINGS.ASIDE.OVERVIEW.TITLE",
			items: [
				{
					id: "overview",
					to: routes.root.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.OVERVIEW",
				},
			],
		},
		{
			title: "SETTINGS.ASIDE.INBOX.TITLE",
			items: [
				{
					id: "channels",
					to: routes.inbox.channels.overview.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.INBOX.CHANNELS",
				},
				{
					id: "inboxLabels",
					to: routes.inbox.labels.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.INBOX.LABELS",
				},
				// {
				// 	id: "cannedResponses",
				// 	to: "",
				// 	icon: "",
				// 	label: "SETTINGS.ASIDE.CANNED_RESPONSES",
				// },
				{
					id: "emailSignature",
					to: routes.inbox.emailSignature.overview.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.EMAIL_SIGNATURE",
				},
				// TEMP disable because page isn't temp used
				// {
				// 	id: "inbox",
				// 	to: routes.inbox.settings.replace(":team", team),
				// 	icon: "",
				// 	label: "SETTINGS.ASIDE.INBOX.SETTINGS",
				// },
			],
		},
		// {
		// 	title: "SETTINGS.ASIDE.FLOWS.TITLE",
		// 	items: [
		// 		{
		// 			id: "flowSettings",
		// 			to: "",
		// 			icon: "",
		// 			label: "SETTINGS.ASIDE.FLOW_SETTINGS",
		// 		},
		// 	],
		// },
		{
			title: "SETTINGS.ASIDE.CONTACTS.TITLE",
			items: [
				{
					id: "contact_import",
					to: routes.contact.overview.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.CONTACT_IMPORT",
				},
				{
					id: "contactLabels",
					to: routes.contact.labels.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.CONTACT_LABELS",
				},
			],
		},
		// {
		// 	title: "SETTINGS.ASIDE.VIRTUAL_ASSISTANT.TITLE",
		// 	items: [
		// 		{
		// 			id: "agent",
		// 			icon: "",
		// 			to: routes.virtualAssistant.general.replace(":team", team),
		// 			label: "SETTINGS.ASIDE.VIRTUAL_ASSISTANT",
		// 		},
		// 		{
		// 			id: "defaultAnswers",
		// 			icon: "",
		// 			to: routes.virtualAssistant.responses.replace(":team", team),
		// 			label: "SETTINGS.ASIDE.DEFAULT_ANSWERS",
		// 		},
		// 		{
		// 			id: "intents",
		// 			icon: "",
		// 			to: routes.intents.overview.replace(":team", team),
		// 			label: "SETTINGS.ASIDE.INTENTS",
		// 		},
		// 		{
		// 			id: "triggers",
		// 			icon: "",
		// 			to: routes.triggers.overview.replace(":team", team),
		// 			label: "SETTINGS.ASIDE.TRIGGERS",
		// 		},
		// 	],
		// },
		{
			title: "SETTINGS.ASIDE.TEAM.TITLE",
			items: [
				{
					id: "organization",
					to: routes.organization.general.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.ORGANIZATION",
				},
				{
					id: "openingHours",
					to: routes.organization.openingHours.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.OPENING_HOURS",
				},
				{
					id: "branding",
					to: routes.branding.main.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.BRANDING",
				},
				{
					id: "virtualAssistant",
					to: routes.virtualAssistant.general.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.VIRTUAL_ASSISTANT",
				},
				{
					id: "members",
					to: routes.members.overview.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.USERS",
				},
				{
					id: "notifications",
					to: routes.notification.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.NOTIFICATION_SETTINGS",
				},
			],
		},
		{
			title: "SETTINGS.ASIDE.ADVANCED.TITLE",
			items: [
				{
					id: "integrations",
					to: routes.integrations.overview.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.INTEGRATIONS",
				},
				{
					id: "apiKey",
					to: routes.apiKey.overview.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.API_KEY",
				},
				{
					id: "advanced",
					to: routes.advanced.replace(":team", team),
					icon: "",
					label: "SETTINGS.ASIDE.ADVANCED",
				},
			],
		},
	],
	suggestions: {
		contact: {
			labels: {
				id: "contactLabel",
				title: "SETTINGS.SUGGESTIONS.CONTACT_LABElS.TITLE",
				url: routes.contact.labels,
				content: {
					description: "SETTINGS.SUGGESTIONS.CONTACT_LABElS.DESCRIPTION",
					buttonLabel: "SETTINGS.SUGGESTIONS.CONTACT_LABElS.BUTTON",
				},
			},
		},
		organization: {
			companySettings: {
				id: "companySettings",
				title: "SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.TITLE",
				url: routes.organization.general,
				content: {
					description: "SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.DESCRIPTION",
					buttonLabel: "SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.BUTTON",
				},
			},
			branding: {
				id: "branding",
				title: "SETTINGS.SUGGESTIONS.BRANDING.TITLE",
				url: routes.branding.main,
				content: {
					description: "SETTINGS.SUGGESTIONS.BRANDING.DESCRIPTION",
					buttonLabel: "SETTINGS.SUGGESTIONS.BRANDING.BUTTON",
				},
			},
			openingHours: {
				id: "openingHours",
				title: "SETTINGS.SUGGESTIONS.OPENING_HOURS.TITLE",
				url: routes.organization.openingHours,
				content: {
					description: "SETTINGS.SUGGESTIONS.OPENING_HOURS.DESCRIPTION",
					buttonLabel: "SETTINGS.SUGGESTIONS.OPENING_HOURS.BUTTON",
				},
			},
		},
	},
	routes,
};
