import { useMemo, useState } from "react";
import "./style.scss";

import { keyEventsHelpers } from "../../../../shared/helpers";

interface ICodeInput {
	onChange: (value: string) => void;
}

export default function CodeInput({ onChange }: ICodeInput) {
	const codeLength = 6;
	const [value, setValue] = useState<string[]>([]);
	const fieldIds = useMemo(() => [...Array(codeLength).keys()], []);

	const handleChange = ({ id, event }: { id: number; event: React.ChangeEvent<HTMLInputElement> }) => {
		const inputValue = event.target.value;

		setValue((prev) => {
			const newArr = [...prev];
			newArr[id] = inputValue;
			onChange(newArr.join(""));
			return newArr;
		});

		if (!/[a-zA-Z0-9]/.test(inputValue)) return;

		const code = id === codeLength - 1 ? 0 : id + 1;
		const nextInput = document.querySelector(`#code-${code}`) as HTMLInputElement;

		nextInput.select();
	};
	const handlePasteEvent = ({ id, event }: { id: number; event: React.ClipboardEvent<HTMLInputElement> }) => {
		if (!event.clipboardData.getData("text")) return;

		const clipboardData = event.clipboardData.getData("text").replaceAll("-", "").replaceAll(/\n/g, "").split("");

		if (id === 0) {
			setValue(clipboardData.slice(0, codeLength));
			onChange(clipboardData.slice(0, codeLength).join(""));
			return;
		}

		if (clipboardData.length === codeLength) {
			setValue(clipboardData);
			onChange(clipboardData.join(""));
			return;
		}

		const newValue = [...value.slice(0, id), ...clipboardData.slice(0, codeLength - id)];

		onChange(newValue.join(""));
		setValue(newValue);
	};
	const handleKeyUp = ({ id, event }: { id: number; event: React.KeyboardEvent<HTMLInputElement> }) => {
		if (keyEventsHelpers.deletePressed(event)) {
			const code = id - 1;

			if (code < 0) return;

			const nextInput = document.querySelector(`#code-${code}`) as HTMLInputElement;

			return nextInput.select();
		}
		if (keyEventsHelpers.leftArrowPress(event)) {
			let code = id - 1;

			if (code < 0) code = codeLength - 1;

			const nextInput = document.querySelector(`#code-${code}`) as HTMLInputElement;

			return nextInput.select();
		}
		if (keyEventsHelpers.rightArrowPress(event)) {
			let code = id + 1;

			if (code >= codeLength) code = 0;

			const nextInput = document.querySelector(`#code-${code}`) as HTMLInputElement;

			return nextInput.select();
		}
	};

	return (
		<div className="portal_verify-code_input">
			{fieldIds.slice(0, codeLength / 2).map((id) => (
				<input
					id={`code-${id}`}
					key={`code-${id}`}
					maxLength={1}
					placeholder="X"
					value={value[id]}
					className="portal_verify-code_input-field"
					onChange={(event) => handleChange({ id, event })}
					onPaste={(event) => handlePasteEvent({ id, event })}
					onKeyUp={(event) => handleKeyUp({ id, event })}
				/>
			))}
			<div className="portal_verify-code_input-splitter"></div>
			{fieldIds.slice(codeLength / 2).map((id) => (
				<input
					id={`code-${id}`}
					key={`code-${id}`}
					maxLength={1}
					placeholder="X"
					value={value[id]}
					className="portal_verify-code_input-field"
					onChange={(event) => handleChange({ id, event })}
					onPaste={(event) => handlePasteEvent({ id, event })}
					onKeyUp={(event) => handleKeyUp({ id, event })}
				/>
			))}
		</div>
	);
}
