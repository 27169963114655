import { defineMessages } from "react-intl";

export const tableMenu = defineMessages({
	row_group_title: {
		id: "editor.table_menu.row_group_title",
		defaultMessage: "Row options",
		description: "Wysiwyg editor table component option menu group rows",
	},
	insert_row_above: {
		id: "editor.table_menu.insert_row_above",
		defaultMessage: "Insert {selectedRows, plural, =1 {# row} other {# rows}} above",
		description: "Wysiwyg editor table component option menu: insert rows above button",
	},
	insert_row_below: {
		id: "editor.table_menu.insert_row_below",
		defaultMessage: "Insert {selectedRows, plural, =1 {# row} other {# rows}} below",
		description: "Wysiwyg editor table component option menu: insert rows below button",
	},
	delete_row: {
		id: "editor.table_menu.delete_row",
		defaultMessage: "Delete row",
		description: "Wysiwyg editor table component option menu: Delete row",
	},
	column_group_title: {
		id: "editor.table_menu.column_group_title",
		defaultMessage: "Column options",
		description: "Wysiwyg editor table component option menu group column",
	},
	insert_column_left: {
		id: "editor.table_menu.insert_column_left",
		defaultMessage: "Insert {selectedColumns, plural, =1 {# column} other {# columns}} left",
		description: "Wysiwyg editor table component option menu: insert columns left button",
	},
	insert_column_right: {
		id: "editor.table_menu.insert_column_right",
		defaultMessage: "Insert {selectedColumns, plural, =1 {# column} other {# columns}} right",
		description: "Wysiwyg editor table component option menu: insert columns right button",
	},
	delete_column: {
		id: "editor.table_menu.delete_column",
		defaultMessage: "Delete column",
		description: "Wysiwyg editor table component option menu: Delete column",
	},
	table_group_options: {
		id: "editor.table_menu.table_group",
		defaultMessage: "Table options",
		description: "Wysiwyg editor table component option generic table options group",
	},
	delete_table: {
		id: "editor.table_menu.delete_table",
		defaultMessage: "Delete table",
		description: "Wysiwyg editor table component option menu: Delete table",
	},
	remove_row_header: {
		id: "editor.table_menu.remove_row_header",
		defaultMessage: "Remove row header",
		description: "Wysiwyg editor table component option menu: Remove row header",
	},
	add_row_header: {
		id: "editor.table_menu.add_row_header",
		defaultMessage: "Add row header",
		description: "Wysiwyg editor table component option menu: Add row header",
	},
	remove_column_header: {
		id: "editor.table_menu.remove_column_header",
		defaultMessage: "Remove column header",
		description: "Wysiwyg editor table component option menu: Remove column header",
	},
	add_column_header: {
		id: "editor.table_menu.add_column_header",
		defaultMessage: "Add column header",
		description: "Wysiwyg editor table component option menu: Add column header",
	},
	merge_cells: {
		id: "editor.table_menu.merge_cells",
		defaultMessage: "Merge cells",
		description: "Wysiwyg editor table component option menu: merge cell button",
	},
	un_merge_cells: {
		id: "editor.table_menu.un_merge_cells",
		defaultMessage: "Unmerge cells",
		description: "Wysiwyg editor table component option menu: un merge cell button",
	},

	makeHeaderCell: {
		id: "editor.table_menu.add_cell_header",
		defaultMessage: "Add cell header",
		description: "Wysiwyg editor table component option menu: Convert cell to header",
	},
	removeHeaderCell: {
		id: "editor.table_menu.remove_cell_header",
		defaultMessage: "Remove cell header",
		description: "Wysiwyg editor table component option menu: Convert cell to normal",
	},
	clearCell: {
		id: "editor.table_menu.clear_cell",
		defaultMessage: "Clear cell",
		description: "Wysiwyg editor table component option menu: Clear cell styling",
	},

	removeSorting: {
		id: "editor.table_menu.remove_sorting",
		defaultMessage: "Remove sorting",
		description: "Wysiwyg editor table component option menu: Remove sorting",
	},
	sortAscending: {
		id: "editor.table_menu.sort_ascending",
		defaultMessage: "Sort ascending",
		description: "Wysiwyg editor table component option menu: Sort ascending",
	},
	sortDescending: {
		id: "editor.table_menu.sort_descending",
		defaultMessage: "Sort descending",
		description: "Wysiwyg editor table component option menu: Sort descending",
	},
});

export const supportedFontFamily = [
	{ id: "arial", label: "Arial", value: "Arial", script: "" },
	{ id: "courierNew", label: "Courier New", value: "Courier New", script: "" },
	{ id: "georgia", label: "Georgia", value: "Georgia", script: "" },
	{
		id: "openSans",
		label: "Open Sans",
		value: "Open Sans",
		script: `<link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet">`,
	},
	{ id: "timesNewRoman", label: "Times New Roman", value: "Times New Roman", script: "" },
	{ id: "trebuchetMS", label: "Trebuchet MS", value: "Trebuchet MS", script: "" },
	{ id: "verdana", label: "Verdana", value: "Verdana", script: "" },
];
