import "./style.scss";
import type { FC } from "react";
import { Skeleton } from "antd";
import { motion } from "framer-motion";

interface MessagesLoadingProps {}

const MessagesLoading: FC<MessagesLoadingProps> = ({}) => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ delay: 0.5 }} // Show animation after 500 ms of loading
			className="cl-message"
		>
			<Skeleton active paragraph={{ rows: 2 }} />
			<Skeleton className="cl-loading-content" active paragraph={{ rows: 5 }} />
		</motion.div>
	);
};
export default MessagesLoading;
