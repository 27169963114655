export default {
	databaseName: {
		conversation: "conversation",
		inbox: "inbox",
		labels: "labels",
	},
	collections: {
		conversations: "conversations",
		accounts: "accounts",
		channels: "channels",
		inbox: "inbox",
		inboxCount: "inboxCount",
		inboxList: "inboxList",
		labels: "inboxLabels",
	},
	dbLimit: {
		conversations: 400,
		inboxList: 1200,
	},
};
