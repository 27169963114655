import { typesConfig } from "../../../config";
import actionTypes from "./inbox.actionTypes";

const initialState = {
	compose: {
		state: undefined,
		from: undefined,
		to: undefined,
		cc: undefined,
		bcc: undefined,
		subject: undefined,
		value: undefined,
		attachments: undefined,
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.SHOW_COMPOSE:
			return {
				...state,
				compose: {
					...state.compose,
					...action.data,
					state: typesConfig.windowState.default,
				},
			};
		case actionTypes.RESIZE_COMPOSE:
			return {
				...state,
				compose: {
					...state.compose,
					state: action.data,
				},
			};
		case actionTypes.TOGGLE_COMPOSE:
			return {
				...state,
				compose: {
					...state.compose,
					state: state.compose.state ? undefined : typesConfig.windowState.default,
				},
			};
		case actionTypes.HIDE_COMPOSE:
			return {
				...state,
				compose: initialState.compose,
			};
		default:
			return state;
	}
}
