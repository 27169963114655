import { typesConfig } from "@/config";

export default {
	selectDefaultReportType: ({ previewTypes }) => {
		if (previewTypes?.find((previewType) => previewType.type === typesConfig.downloadTypes.pdf)) {
			return typesConfig.downloadTypes.pdf;
		}

		return typesConfig.downloadTypes.excel;
	},
};
