export default {
	animationDefault: 600,
	browserTabNotification: 2_000,
	debounce: 150,
	keyboardTimeout: 500,
	savingTimeout: 1_000,
	bannerTimeout: 7_500,
	carousel: 7_500,
	longBannerTimeout: 10_000,
	batchCreatedTimeout: 20_000,
	second: 1_000,
	serverTimeout: 15_000,
	typingTimeout: 4_000,
	widgetRetryInterval: 200,
	recoilPreFetch: 50,
};
