import { Alert, Button, Form, Modal, Select, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";

import { flowState, selectedFlowIdState } from "@/flows";
import { automationSelector } from "@/flows/report_detail/meta_aside/automation/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import FieldSideLabel from "@/shared/components/field/side_label";

import { searchFilterState, selectedProgressState, selectedStatusTypeState, selectedContactLabelState } from "../../../state";
import { initSendToIntegration } from "../../query";
import {
	allReportsSelectedState,
	isIntegrationSendingState,
	selectedReportIdsState,
	totalReportsSelectedState,
} from "../../state";
import style from "../../style.module.scss";
import helpers from "../helpers";

export default function DeleteReportsModal({ showSendIntegrationModal, setShowSendIntegrationModal }) {
	const intl = useIntl();

	const totalReportsSelected = useRecoilValue(totalReportsSelectedState);
	const selectedReportIds = useRecoilValue(selectedReportIdsState);
	const flowId = useRecoilValue(selectedFlowIdState);
	const flow = useRecoilValueLoadable(flowState({ id: flowId }));
	const query = useRecoilValue(searchFilterState);
	const rules = useRecoilValueLoadable(automationSelector);
	const progress = useRecoilValue(selectedProgressState);
	const statusType = useRecoilValue(selectedStatusTypeState);
	const allReportsSelected = useRecoilValue(allReportsSelectedState);
	const selectedContactLabel = useRecoilValue(selectedContactLabelState);
	const [isIntegrationSending, setIsIntegrationSending] = useRecoilState(isIntegrationSendingState);

	const defaultReportType = helpers.selectDefaultReportType({ previewTypes: flow.contents?.template?.previewTypes });

	const handleConfirm = async ({ ruleType, reportType, duplicates }) => {
		setIsIntegrationSending(true);

		try {
			const result = await initSendToIntegration({
				flowId,
				reportIds: allReportsSelected ? undefined : selectedReportIds,
				q: query,
				progress,
				statusId: statusType,
				ruleId: ruleType,
				contactLabel: selectedContactLabel,
				duplicates,
				reportType,
			});

			if (result?.status !== 202) throw new Error();

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.SUCCESS.TITLE",
					defaultMessage: "Bulk sending has been started",
				}),
				description: intl.formatMessage({
					id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.SUCCESS.DESCRIPTION",
					defaultMessage: "You will receive a notification when the action is complete.",
				}),
				duration: 6,
			});
		} catch (error) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.FAILED.TITLE",
					defaultMessage: "Bulk sending error.",
				}),
				description: intl.formatMessage({
					id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.FAILED.DESCRIPTION",
					defaultMessage: "Bulk sending has failed",
				}),
			});
		}

		setIsIntegrationSending(false);
		setShowSendIntegrationModal(false);
	};

	return (
		<Modal
			title={intl.formatMessage({ id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.TITLE" })}
			centered
			open={showSendIntegrationModal}
			onCancel={() => setShowSendIntegrationModal(false)}
			mask={true}
			maskClosable={true}
			footer={null}
		>
			<Form
				layout="vertical"
				onFinish={handleConfirm}
				requiredMark={false}
				initialValues={{
					ruleType: rules?.contents?.data?.[0]?._id,
					reportType: defaultReportType,
					duplicates: false,
				}}
			>
				<FieldSideLabel
					label={intl.formatMessage({
						id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.RULE.LABEL",
					})}
				>
					<Form.Item
						name="ruleType"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.RULE.REQUIRED",
								}),
							},
						]}
					>
						<Select loading={rules.state === "loading"} popupMatchSelectWidth={false}>
							{rules?.contents?.data?.map(({ _id, icon, name }) => (
								<Select.Option key={_id} value={_id}>
									<div className={style.rule_item}>
										<img src={icon} alt={name?.[intl.locale]} className={style.select_icon} />
										<p>{name?.[intl.locale]}</p>
									</div>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</FieldSideLabel>
				<FieldSideLabel
					label={intl.formatMessage({
						id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.REPORT_TYPE.LABEL",
					})}
					help={intl.formatMessage({
						id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.REPORT_TYPE.HELP",
					})}
				>
					<Form.Item name="reportType">
						<Select loading={flow.state === "loading"}>
							{flow.contents?.template?.previewTypes?.map(({ type }) => (
								<Select.Option key={type} value={type}>
									<p>{type}</p>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</FieldSideLabel>
				<FieldSideLabel
					label={intl.formatMessage({
						id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.DUPLICATES.LABEL",
					})}
					help={intl.formatMessage({
						id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.DUPLICATES.HELP",
					})}
				>
					<Form.Item name="duplicates">
						<Switch />
					</Form.Item>
				</FieldSideLabel>
				<Form.Item className="t-gap--top">
					<Alert
						message={intl.formatMessage(
							{
								id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.INFO_MESSAGE",
							},
							{
								totalReportsSelected,
							}
						)}
						type="info"
						showIcon
					/>
				</Form.Item>
				<Form.Item className={style.form_item_no_margin}>
					<div className={style.form_footer_wrapper}>
						<Button type="primary" htmlType="submit" loading={isIntegrationSending}>
							{" "}
							<FormattedMessage id="FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.CONFIRM" />
						</Button>
					</div>
				</Form.Item>
			</Form>
		</Modal>
	);
}
