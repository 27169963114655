import "./style.scss";

import { Button, Form, Input, Switch, Typography } from "antd";
import type { IContact } from "@bothive_core/database";
import { WarningOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";

import { ContactRouterOutput } from "@/trpc";
import FieldLabel from "@/shared/components/field/default";
import { ExistingContactDisplay } from "../../../../components/existingContactDisplay";

interface IContactDetail {
	onChange: (value: Partial<ContactRouterOutput["getContactById"]>) => Promise<Boolean>;
	contact?: ContactRouterOutput["getContactById"];
	conflictContact?: IContact;
	className: string;
}

export default function ContactDetail({ contact, conflictContact, onChange, className }: IContactDetail) {
	const intl = useIntl();
	const [form] = Form.useForm();

	const _uniqueIdentifier = Form.useWatch("uniqueIdentifier", form);

	const handleChange = (value) => {
		if (value?.uniqueIdentifier === null) {
			value.uniqueIdentifier = "";
		}

		onChange(value).catch((error) => {});
	};

	// Must check if conflictContact exist because unique identifier can be undefined
	const hasDuplicateConflict = conflictContact && conflictContact.uniqueIdentifier === _uniqueIdentifier;

	return (
		<div style={{ display: "flex", flexDirection: "column", width: "100%" }} className={className}>
			{hasDuplicateConflict && conflictContact && (
				<div className="contact_detail-error-duplicate">
					<Typography.Text type="danger">
						<FormattedMessage
							id="contacts.detail.attributes.detail.error.duplicate"
							defaultMessage="This unique identifier is already added to the following contact in your organization"
							description="Error message for unique identifier that already exists with preview of the contact that has the same unique identifier"
						/>
					</Typography.Text>
					<ExistingContactDisplay contact={conflictContact} />
				</div>
			)}
			<Form
				form={form}
				layout="vertical"
				className="frsf-form contact_detail-form"
				id="updateContactDetailForm"
				onFinish={handleChange}
				initialValues={contact}
			>
				<FieldLabel
					id="uniqueIdentifier"
					label="CONTACTS.DETAIL.ATTRIBUTES.DETAIL.UNIQUE_IDENTIFIER.TITLE"
					help="CONTACTS.DETAIL.ATTRIBUTES.DETAIL.UNIQUE_IDENTIFIER.DESCRIPTION"
				>
					<Form.Item name="uniqueIdentifier" className="unique_identifier" style={{ width: "100%" }}>
						<Input
							placeholder={intl.formatMessage({
								id: "CONTACTS.DETAIL.ATTRIBUTES.DETAIL.UNIQUE_IDENTIFIER.PLACEHOLDER",
							})}
							status={hasDuplicateConflict ? "error" : undefined}
							prefix={hasDuplicateConflict ? <WarningOutlined /> : undefined}
						/>
					</Form.Item>
				</FieldLabel>
				<FieldLabel
					id="hasDisability"
					label="CONTACTS.DETAIL.ATTRIBUTES.DETAIL.DISABLED.TITLE"
					help="CONTACTS.DETAIL.ATTRIBUTES.DETAIL.DISABLED.DESCRIPTION"
				>
					<Form.Item name="hasDisability" valuePropName="checked">
						<Switch />
					</Form.Item>
				</FieldLabel>
			</Form>
			<Button
				key="submit"
				type="primary"
				form="updateContactDetailForm"
				htmlType="submit"
				style={{ width: "fit-content", alignSelf: "flex-end" }}
			>
				<FormattedMessage id="BUTTON.SAVE" />
			</Button>
		</div>
	);
}
