import { useSelector, useDispatch } from "react-redux";
import isEqual from "lodash.isequal";
import { actions as dashboardActions } from "@/store/dashboard";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import config from "./config";
import DashboardCard from "@/shared/components/card/dashboard";
import { Button, Card, Empty, List, Divider, Skeleton } from "antd";
import { useHistory } from "react-router-dom";
import { routesConfig } from "@/config/index.js";
import { IIntegration, IIntegrationConnectionDocument, IIntegrationDocument } from "@bothive_core/database";
import "../common/style.scss";
import ProgressGraph from "./components/ProgressGraph";
import { trpc } from "@/trpc";

export default function IntegrationSettings() {
	const routeParameters = useParams();
	const dispatch = useDispatch();
	const intl = useIntl();
	const history = useHistory();
	const { data: organization, isLoading: organizationLoading } = trpc.organization?.getOrganization?.useQuery();
	const { data: connections, isLoading: connectionLoading } = trpc.integration?.getConnections?.useQuery({
		query: { populate: true, category: "5c38a182092c99228ead1a32" }, // accountancy category
	});

	const { data: integrations, isLoading: integrationsLoading } = trpc.integration?.getIntegrations?.useQuery({
		query: { category: "5c38a182092c99228ead1a32", isDevelopment: false, isPublished: true }, // accountancy category
	});

	const { permissions } = useSelector((state) => ({ permissions: state.dashboard.permissions }), isEqual);

	useEffect(() => {
		// @ts-ignore
		const breadcrumbs = config.breadcrumbs(routeParameters);
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					...config,
					breadcrumbs,
				},
				intl,
			})
		);
	}, []);

	const integrationList = useMemo(() => {
		const connectionIds = connections?.data?.map((connection) => connection?.integration?._id);

		if (integrations) {
			return integrations.filter((integration) => !connectionIds?.includes(integration._id));
		}

		return [];
	}, [connections, integrations]);

	const handleConnectionClick = (connectionId) => {
		const connection = connections?.data?.find((connection) => connection._id === connectionId);
		const url = routesConfig.dashboard.settings.integrations.detail
			.replace(":team", organization?.slug)
			.replace(":slug", connection?.integration?.slug);

		history.push(`${url}/${connectionId}`);
	};

	const goToSubscriptions = () => {
		const url = routesConfig.dashboard.supportRequestParameter
			.replace(":team", organization?.slug)
			.replace(":request", "pricing");

		history.push(url);
	};
	const requestIntegration = () => {
		const url = routesConfig.dashboard.supportRequestParameter
			.replace(":team", organization?.slug)
			.replace(":request", "integration_request");

		history.push(url);
	};
	const handleIntegrationClick = (id) => {
		const integration = integrations?.find((item) => item._id === id);
		const url = routesConfig.dashboard.settings.integrations.detail
			.replace(":team", organization?.slug)
			.replace(":slug", integration?.slug);

		history.push(`${url}/${id}`);
	};

	const disableSubscribe =
		process.env.NODE_ENV === "production" && new Date(organization?.createdAt || "") > new Date("2021-07-09");

	const integrationGraphMessage = useMemo(() => {
		if (connectionLoading) {
			return intl.formatMessage({
				id: "settings.integrations.overview.info.permissions.loading",
				defaultMessage: "Fetching limits...",
			});
		}

		if (permissions.integrations < Number.MAX_SAFE_INTEGER) {
			return intl.formatMessage(
				{
					id: "settings.integrations.overview.info.progress_label",
					defaultMessage:
						"You have {value, plural, =0 {no integrations} one {# integration} other {# integrations}} left in your current plan.",
				},
				{ value: Math.max(permissions.integrations - (connections?.total || 0), 0) }
			);
		}

		return intl.formatMessage({
			id: "settings.integrations.overview.info.progress_label_unlimited",
			defaultMessage: "You have unlimited integrations in your current plan.",
		});
	}, [connectionLoading, permissions]);

	return (
		<section
			className="organizationContainer"
			style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "2em" }}
		>
			<DashboardCard
				bodyStyle={{ textAlign: "left", alignItems: "start" }}
				title={intl.formatMessage({
					id: "settings.integrations.overview.info.title",
					defaultMessage: "Integrations",
				})}
				description={intl.formatMessage(
					{
						id: "settings.integrations.overview.info.description",
						defaultMessage:
							"Integrate with different platform you use to work beter and smarter. Through integrations you can make {botName} smarter and integrate with all your communication channels or extend {botName} functionalities. If you can't find your integration, contact us and we will added for you.",
					},
					{
						botName: organization?.virtualAssistant?.name,
					}
				)}
			>
				{permissions.integrations > 0 && (
					<ProgressGraph
						max={permissions.integrations || 0}
						value={connections?.total || 0}
						label={integrationGraphMessage}
					/>
				)}
				{permissions.integrations === 0 && (
					<div className={`o-section o-section--information`}>
						<div className="section-body">
							<p>
								<FormattedMessage
									id={
										disableSubscribe
											? "settings.integrations.overview.info.no_integrations_no_upgrade"
											: "settings.integrations.overview.info.no_integrations"
									}
									defaultMessage={
										disableSubscribe
											? "You have no integrations in your current subscription. Contact our support team to upgrade your subscription."
											: "You have no integrations in your current subscription. Upgrade to start using integrations."
									}
								/>
							</p>
						</div>
						{!disableSubscribe && (
							<Button onClick={goToSubscriptions}>
								<FormattedMessage
									id="settings.integrations.overview.info.upgrade_button"
									defaultMessage="Upgrade"
								/>
							</Button>
						)}
					</div>
				)}
			</DashboardCard>
			{connections && connections?.total > 0 && (
				<DashboardCard
					title={intl.formatMessage({
						id: "settings.integrations.overview.active.title",
						defaultMessage: "Active integrations",
					})}
				>
					<Divider style={{ backgroundColor: "#002140" }} />
					<List
						style={{ width: "100%" }}
						grid={{ gutter: 8 }}
						dataSource={
							connections.data
								? (connections.data?.sort((a, b) => {
										if (!a.integration || !b.integration) return -1;

										return a.integration.name.localeCompare(b.integration.name);
								  }) as Array<any>)
								: []
						}
						renderItem={(
							connection: {
								integration: IIntegrationDocument;
							} & IIntegrationConnectionDocument
						) => (
							<List.Item onClick={() => handleConnectionClick(connection._id)}>
								<Card
									hoverable
									className="t-gap--bottom integrationCard"
									cover={
										<img
											alt={connection.integration.name}
											src={connection.integration.logo}
											className="integrationImage"
										/>
									}
								>
									<Card.Meta
										title={connection.integration.name}
										description={connection.integration.content.description?.[intl.locale]}
									/>
								</Card>
							</List.Item>
						)}
					/>
				</DashboardCard>
			)}

			<DashboardCard
				title={intl.formatMessage({
					id: "settings.integrations.overview.all_integrations.title",
					defaultMessage: "All integrations",
				})}
			>
				<Divider style={{ backgroundColor: "#002140" }} />
				<hr></hr>
				{integrationsLoading || connectionLoading ? (
					// Loading state
					<List
						style={{ width: "100%" }}
						grid={{ gutter: 16 }}
						dataSource={Array.from({ length: 6 }, (_, index) => index + 1)}
						renderItem={() => (
							<List.Item>
								<Card className="t-gap--bottom integrationCard">
									<Skeleton paragraph={{ rows: 2 }} active />
								</Card>
							</List.Item>
						)}
					/>
				) : // Empty state
				!integrationList?.length ? (
					<Empty
						image={Empty.PRESENTED_IMAGE_SIMPLE}
						imageStyle={{
							height: 60,
						}}
						description={
							<span>
								<FormattedMessage
									id="settings.integrations.overview.all_integrations.fallback"
									defaultMessage="There are no more integrations available. Contact us if you couldn't find your integration and we will add it for you."
								/>
							</span>
						}
					/>
				) : (
					// Data available state
					<List
						style={{ width: "100%" }}
						grid={{ gutter: 16 }}
						dataSource={integrationList.sort((a, b) => a.name.localeCompare(b.name))}
						renderItem={(integration: IIntegration) => (
							<List.Item
								onClick={() => {
									handleIntegrationClick(integration._id);
								}}
							>
								<Card
									hoverable
									className="t-gap--bottom integrationCard"
									cover={
										<img
											alt={integration.name}
											src={integration.logo}
											className="integrationImage"
										/>
									}
								>
									<Card.Meta
										title={integration.name}
										description={integration.content.description?.[intl.locale]}
									/>
								</Card>
							</List.Item>
						)}
					/>
				)}
			</DashboardCard>
			<DashboardCard
				title={intl.formatMessage({
					id: "settings.integrations.overview.request_integration.title",
					defaultMessage: "Request integrations",
				})}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
						paddingTop: "2em",
					}}
				>
					<p style={{ width: "60%", textAlign: "left", whiteSpace: "pre-wrap" }}>
						<FormattedMessage
							id="settings.integrations.overview.request_integration.description"
							defaultMessage="Can’t find the integration with your software in the list? Contact us through our customer service and we will contact you as soon as possible to discuss this integration."
						/>
					</p>
					<Button type="default" size="large" onClick={requestIntegration}>
						<FormattedMessage
							id="settings.integrations.overview.request_integration.button"
							defaultMessage="Request integrations"
						/>
					</Button>
				</div>
			</DashboardCard>
		</section>
	);
}
