function isPageVisible() {
	// @ts-ignore
	if (typeof document.webkitHidden !== "undefined") return !document.webkitHidden;
	// @ts-ignore
	if (typeof document.msHidden !== "undefined") return !document.msHidden;

	return !document.hidden;
}

export function requestDeskNotificationPermission() {
	if (!("Notification" in window)) return;

	if (Notification.permission !== "granted") {
		Notification.requestPermission();
	}
}

interface IDesktopNotification {
	title: string;
	message: string;
	onClick: (event: Event) => void;
}
export function desktopNotification({ title, message, onClick }: IDesktopNotification) {
	if (isPageVisible() || !("Notification" in window) || Notification.permission === "denied") return;

	const notification = new Notification(title, {
		icon: `${process.env.PUBLIC_URL}/logo_small.svg`, //eslint-disable-line no-undef
		badge: "Bothive",
		body: message,
	});

	notification.onclick = onClick;
}
