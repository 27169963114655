import { atomFamily, selector } from "recoil";

import helper from "@/flows/common/helper";
import { flowState, selectedFlowIdState } from "@/flows/common/state";
import { storageHelpers } from "@/helpers";

const widgetPreviewState = atomFamily({
	key: "frswWidgetPreviewState",
	default: undefined,
});

export const widgetPreviewSelector = selector({
	key: "frswWidgetPreviewSelector",
	get: ({ get }) => {
		const flowId = get(selectedFlowIdState);
		const flow = get(flowState({ id: flowId }));
		const widgetPreview = get(widgetPreviewState(flowId));
		const language = storageHelpers.getLanguage();

		const flowDescription = helper.getFlowDescription({ flow, language: "nl" });

		return {
			name: widgetPreview?.name || flowDescription?.name,
			description: widgetPreview?.description || flowDescription?.description,
			logo: widgetPreview?.logo || flowDescription?.logo,
		};
	},
	set: ({ get, set }, value) => {
		const flowId = get(selectedFlowIdState);

		set(widgetPreviewState(flowId), value);
	},
});
