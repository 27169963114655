import "./style.scss";

import { trpc } from "@/trpc";
import { Card, Flex } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import DashboardCard from "@/shared/components/card/dashboard";
import { actions as dashboardActions } from "@/store/dashboard";
import RelatedSettings from "./components/RelatedSettings";
import ContactImport from "../../contacts/import";

export default function ContactImportPage() {
	const intl = useIntl();
	const dispatch = useDispatch();
	const routeParameters = useParams<{ team: string }>();
	const [importModal, setImportModal] = useState<{ open: boolean; integrationId?: string }>({ open: false });

	const { data: integrationConnections } = trpc.integration.getConnections.useQuery({
		query: { features: "contact.import", populate: true },
	});

	useEffect(() => {
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					title: intl.formatMessage({
						id: "settings.contact_import.title",
						defaultMessage: "Contact import",
					}),
					tabTitle: intl.formatMessage({
						id: "settings.contact_import.title",
						defaultMessage: "Contact import",
					}),
					breadcrumbs: [
						{
							to: `/${routeParameters.team}/settings`,
							name: intl.formatMessage({
								id: "settings.contact_import.breadcrumb.settings",
								defaultMessage: "Settings",
							}),
						},
						{
							to: `/${routeParameters.team}/settings/contact`,
							name: intl.formatMessage({
								id: "settings.contact_import.breadcrumb.title",
								defaultMessage: "Contact import",
							}),
						},
					],
				},
				intl,
			})
		);
	}, []);

	const handleCardClick = (integrationId: string) => setImportModal({ open: true, integrationId });

	return (
		<section className="organizationContainer">
			<DashboardCard
				title={intl.formatMessage({
					id: "settings.contact_import.list.title",
					defaultMessage: "Import options",
				})}
				description={intl.formatMessage({
					id: "settings.contact_import.list.description",
					defaultMessage: "Pick the data source from which you want import your contacts",
				})}
			>
				<Flex wrap="wrap" justify="flex-start" gap="middle" className="contact_import-integration">
					<Card className="contact_import-integration-card" hoverable onClick={() => handleCardClick("csv")}>
						<img src="https://ui-avatars.com/api/?name=csv&background=FFFFFF&color=002140&size=224&length=3" />
						<p>
							<FormattedMessage
								id="settings.contact_import.list.default.csv"
								defaultMessage="Manual CSV import"
							/>
						</p>
					</Card>
					{integrationConnections?.data?.map((connection) => (
						<Card
							hoverable
							key={connection.integration.id}
							className="contact_import-integration-card"
							onClick={() => handleCardClick(connection.integration._id || connection.integration.id)}
						>
							<img src={connection.integration.logo} />
							<p>{connection.integration.name}</p>
						</Card>
					))}
				</Flex>
			</DashboardCard>
			<h2 className="t-gap--top t-gap--bottom-xs">
				<FormattedMessage
					id="settings.contact_import.related_settings.title"
					defaultMessage="Related settings"
				/>
			</h2>
			<hr />
			<RelatedSettings slug={routeParameters.team || ""} />
			<ContactImport {...importModal} onClose={() => setImportModal({ open: false })} />
		</section>
	);
}
