export function removeThreadingEmail({ html }: { html: string }): Node | string {
	// create new empty html object
	const htmlParser = new DOMParser();
	const htmlObject = htmlParser.parseFromString(html, "text/html");

	// add email html to object
	// @ts-ignore
	htmlObject.innerHTML = html;
	const htmlBody = htmlObject.getElementsByTagName("BODY")[0];

	if (html.includes("---------- Forwarded message ----------")) return htmlBody;

	if (!htmlBody) {
		// make array of all html children, this way we scope our selecting as threading is always top level
		const htmlChildren = [...htmlObject.children];
		const divsInHtmlChildren = htmlChildren.filter((item) => item.nodeName === "DIV");

		// there should be 1 top level div, which is the threading, if so we remove it
		if (divsInHtmlChildren.length === 1) {
			htmlObject.removeChild(divsInHtmlChildren[0]);

			return htmlObject;
		}
	}

	// Gmail separates threads in body by class name
	const gmailQuote = htmlBody.getElementsByClassName("gmail_quote")[0];
	// Outlook starts thread with a div that has a special id
	const outlookQuote = htmlBody.querySelector("#appendonsend");
	const bothiveQuote = htmlBody.querySelector("blockquote[data-lexical-thread='true']");

	// #region Gmail threading
	// If node has a parent and is not a child of any other node groups (can happen when communicating between different email clients)
	if (gmailQuote?.parentNode && !(outlookQuote?.contains(gmailQuote) || bothiveQuote?.contains(gmailQuote))) {
		htmlBody.querySelectorAll(".gmail_quote").forEach((node) => {
			node.parentNode?.removeChild(node);
		});
		return htmlBody;
	}
	// #endregion

	// #region Outlook thread
	// If node has a parent and is not a child of any other node groups (can happen when communicating between different email clients)
	if (outlookQuote?.parentNode && !(gmailQuote?.contains(outlookQuote) || bothiveQuote?.contains(outlookQuote))) {
		let node = outlookQuote.nextSibling;

		while (node?.parentNode) {
			node.parentNode.removeChild(node);
			node = outlookQuote.nextSibling;
		}

		outlookQuote.parentNode.removeChild(outlookQuote);

		return htmlBody;
	}
	// #endregion

	// #region Bothive threading
	// If node has a parent and is not a child of any other node groups (can happen when communicating between different email clients)
	if (bothiveQuote?.parentNode && !(bothiveQuote?.contains(outlookQuote) || outlookQuote?.contains(bothiveQuote))) {
		htmlBody.querySelectorAll("[data-lexical-thread='true']").forEach((node) => {
			node.parentNode?.removeChild(node);
		});
		return htmlBody;
	}
	// #endregion

	// Standard quote stripping
	const stripedNewLineChildren = [...htmlBody.children].filter((item) => item.nodeName !== "BR");
	const lastChild = stripedNewLineChildren[stripedNewLineChildren.length - 1];

	if (!lastChild?.children) return html;

	const childBlock = [...lastChild.children].filter((item) => item.nodeName !== "BR");
	const lastBlock = childBlock[childBlock.length - 1];

	if (lastBlock?.nodeName === "BLOCKQUOTE") {
		const parentNode = lastBlock.parentNode;

		if (parentNode) {
			parentNode.removeChild(lastBlock);

			return parentNode.parentNode as Node;
		}
	}

	if (lastBlock?.parentNode) {
		const threadQuote = lastBlock.getElementsByTagName("BLOCKQUOTE")[0];

		if (threadQuote?.parentNode) {
			threadQuote.parentNode.removeChild(threadQuote);

			return lastBlock.parentNode.parentNode as Node;
		}
	}

	return html;
}

export function containsThread({ html }: { html: string }): boolean {
	if (!html) return false;

	// create new empty html object
	const htmlParser = new DOMParser();
	const htmlObject = htmlParser.parseFromString(html, "text/html");

	// add email html to object
	// @ts-ignore
	htmlObject.innerHTML = html;
	const htmlBody = htmlObject.getElementsByTagName("BODY")[0];

	if (html.includes("---------- Forwarded message ----------")) return false;

	if (!htmlBody) {
		// make array of all html children, this way we scope our selecting as threading is always top level
		const divsInHtmlChildren = [...htmlObject.children].filter((item) => item.nodeName === "DIV");

		// there should be 1 top level div, which is the threading, if so we remove it
		if (divsInHtmlChildren.length === 1) return false;
	}

	if (
		htmlBody.querySelector("#appendonsend") ||
		htmlBody.getElementsByClassName("gmail_quote")[0] ||
		htmlBody.querySelector("[data-lexical-thread='true']") ||
		htmlBody.getElementsByTagName("BLOCKQUOTE")[0]
	) {
		return true;
	}

	return false;
}
