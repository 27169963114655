import "./styles/global.scss";
import "./styles/style.scss";

import { storageHelpers } from "@/helpers";
import { actions as dashboardActions } from "@/store/dashboard";
import { trpc } from "@/trpc";
import { useIntl } from "react-intl";
import { Suspense, lazy, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DashboardHeader from "../../shared/components/header/dashboardHeader";
import { ComposeWindow } from "./Composer";
import SideNav from "./SideNav";
import config from "./config/header.config";
import useInboxNavigation from "./hooks/useInboxNavigation.hooks";
import BetaLock from "./betaLock";
import InboxSearch from "./inboxSearch";
import InitWalkthrough from "./initWalkthrough";
import { desktopNotification } from "@/shared/helpers/notification.helpers";
import { useHistory, useParams } from "react-router-dom";

const ConversationView = lazy(() => import("./ConversationView"));

export default function Inbox() {
	const intl = useIntl();
	const history = useHistory();
	const routeParameters = useParams<{ team: string }>();
	const dispatch = useDispatch();
	const trpcUtils = trpc.useUtils();
	/**
	 * Keeps track of the already shown notifications for a certain message. This bcs we receive multiple events of a certain message sometimes (OUTLOOK)
	 */
	const emailMessageNotificationRef = useRef(new Set<string>()); // ?: should we clean this up after a certain amount of time?
	const { currentStatus, currentLabel, currentFolder, currentConversation, getSearchParameters, isSearching } =
		useInboxNavigation();

	const { organizationId, userId, hasAccess } = useSelector(
		(state: any) => ({
			hasAccess: state.dashboard.permissions.inboxAccess,
			organizationId: state.content?.team?.team?._id,
			userId: state.profile.account.user._id,
		}),
		shallowEqual
	);

	useEffect(() => {
		dispatch(dashboardActions.ui.foldAside());
		dispatch(dashboardActions.ui.changePageTitle({ config: config.header, intl }));

		return () => {
			dispatch(dashboardActions.ui.unfoldAside());
		};
	}, []);

	function showNewMessageNotification({
		sender,
		content,
		conversationId,
	}: {
		sender: string;
		content: string;
		conversationId: string;
	}) {
		desktopNotification({
			title: sender,
			message: content,
			onClick: (event) => {
				event.preventDefault();
				history.push(
					"/:team/inbox/conversation/:conversationId"
						.replace(":team", routeParameters.team)
						.replace(":conversationId", conversationId)
				);
				window.focus();
			},
		});
	}

	async function handleGeneralConversationUpdate(conversationId: string) {
		trpcUtils.inbox.inbox.getInboxStatsFixedFolders.invalidate();
		trpcUtils.inbox.inbox.getInboxStatsLabels.invalidate();

		// Don't update UI while searching, can lead to odd and buggy feel if not implemented correctly
		if (isSearching) return;

		const conversationContentHasFocussedElement = document
			.querySelector(".cvc-conversation-view")
			?.contains(document.activeElement);

		// Prevent conversation to flash away or update while the person was interacting with it
		if (conversationContentHasFocussedElement) return;

		const updatedConversation = await trpcUtils.inbox.conversation.conversationFromFolder.fetch(
			{
				folder: currentFolder,
				status: currentStatus,
				inboxLabelId: currentLabel,
				conversationId: conversationId,
			},
			{ staleTime: 0, cacheTime: 0 }
		);

		const isConversationInCurrentList = Boolean(
			trpcUtils.inbox.conversation.allFromFolder
				.getInfiniteData({
					folder: currentFolder,
					status: currentStatus,
					inboxLabelId: currentLabel,
					...getSearchParameters(),
				})
				?.pages?.flatMap((page) => page.data)
				?.find((conversation) => conversation._id === conversationId)
		);

		const shouldReplace = updatedConversation && isConversationInCurrentList;
		const shouldRemove = !updatedConversation && isConversationInCurrentList;
		const shouldAdd = updatedConversation && !isConversationInCurrentList;

		// REPLACE
		if (shouldReplace) {
			trpcUtils.inbox.conversation.allFromFolder.setInfiniteData(
				{
					folder: currentFolder,
					status: currentStatus,
					inboxLabelId: currentLabel,
					...getSearchParameters(),
				},
				(prevData) => {
					if (!prevData) return;

					const prevDataClone = structuredClone(prevData);
					const clonedInfiniteData = structuredClone(prevData);

					for (let i = 0; i < prevDataClone.pages.length; i++) {
						clonedInfiniteData.pages[i] = {
							...prevDataClone.pages[i],
							total: prevDataClone.pages[i].total,
							data: prevDataClone.pages[i].data.map((item) => {
								if (item._id !== conversationId) return item;

								return updatedConversation;
							}),
						};
					}

					return clonedInfiniteData;
				}
			);

			if (conversationId === currentConversation) {
				trpcUtils.inbox.conversation.getById.invalidate({
					conversationId: conversationId,
				});

				trpcUtils.inbox.message.allByConversationId.invalidate({
					conversationId: conversationId,
				});
			}

			return;
		}

		// REMOVE
		if (shouldRemove) {
			trpcUtils.inbox.conversation.allFromFolder.setInfiniteData(
				{
					folder: currentFolder,
					status: currentStatus,
					inboxLabelId: currentLabel,
					...getSearchParameters(),
				},
				(prevData) => {
					if (!prevData) return;

					const prevDataClone = structuredClone(prevData);
					const clonedInfiniteData = structuredClone(prevData);

					for (let i = 0; i < prevDataClone.pages.length; i++) {
						clonedInfiniteData.pages[i] = {
							...prevDataClone.pages[i],
							total: prevDataClone.pages[i].total - 1,
							data: prevDataClone.pages[i].data.filter((item) => item._id !== conversationId),
						};
					}

					return clonedInfiniteData;
				}
			);

			return;
		}

		// ADD
		if (shouldAdd) {
			trpcUtils.inbox.conversation.getById.prefetch({ conversationId: updatedConversation._id });
			trpcUtils.inbox.message.allByConversationId.prefetch({
				conversationId: updatedConversation._id,
			});

			trpcUtils.inbox.conversation.allFromFolder.setInfiniteData(
				{
					folder: currentFolder,
					status: currentStatus,
					inboxLabelId: currentLabel,
					...getSearchParameters(),
				},
				(prevInfiniteData) => {
					if (!prevInfiniteData) return;

					const clonedPrevInfiniteData = structuredClone(prevInfiniteData);

					const firstConversation = clonedPrevInfiniteData.pages[0].data[0];
					const lastConversation =
						clonedPrevInfiniteData.pages[clonedPrevInfiniteData.pages.length - 1].data[
							clonedPrevInfiniteData.pages[clonedPrevInfiniteData.pages.length - 1].data.length - 1
						];

					if (!firstConversation && !lastConversation) {
						clonedPrevInfiniteData.pages[0].data.unshift(updatedConversation);
						clonedPrevInfiniteData.pages[0].total = 1;

						return clonedPrevInfiniteData;
					}

					// Move conversation to top of the whole list
					if (updatedConversation.updatedAt! >= firstConversation?.updatedAt!) {
						clonedPrevInfiniteData.pages[0].data.unshift(updatedConversation);
						return clonedPrevInfiniteData;
					}

					// Move conversation to bottom of the whole list
					if (updatedConversation.updatedAt! <= lastConversation?.updatedAt!) {
						clonedPrevInfiniteData.pages[clonedPrevInfiniteData.pages.length - 1].data.push(
							updatedConversation
						);
						return clonedPrevInfiniteData;
					}

					// Find out in which page at which location the conversation should be rendered
					for (let i = 0; i < clonedPrevInfiniteData.pages.length; i++) {
						const firstConversationOfPage = clonedPrevInfiniteData.pages[i].data[0];
						const lastConversationOfPage =
							clonedPrevInfiniteData.pages[i].data[clonedPrevInfiniteData.pages[i].data.length - 1];

						// Determinate if conversation is within this page
						if (
							firstConversationOfPage?.updatedAt &&
							updatedConversation.updatedAt &&
							lastConversationOfPage?.updatedAt &&
							lastConversationOfPage?.updatedAt <= updatedConversation.updatedAt &&
							updatedConversation.updatedAt <= firstConversationOfPage?.updatedAt
						) {
							// Find the index where the new conversation should be inserted based on the updatedAt field
							const index = clonedPrevInfiniteData.pages[i].data.findIndex((conversation) => {
								return updatedConversation.updatedAt! >= conversation.updatedAt!;
							});

							// If no index is found (i.e., it should be inserted at the end), push it to the end
							if (index === -1) {
								clonedPrevInfiniteData.pages[i].data.push(updatedConversation);
							} else {
								// Otherwise, splice the new conversation into the array at the determined index
								clonedPrevInfiniteData.pages[i].data.splice(index, 0, updatedConversation);
							}

							break;
						}
					}

					return clonedPrevInfiniteData;
				}
			);
		}
	}

	// Socket management
	trpc.inbox.conversation.generalConversationChange.useSubscription(
		{
			organizationId,
			token: storageHelpers.getUserToken(),
		},
		{
			onData(data) {
				if (data.organizationId !== organizationId) return;
				if (data.triggeredBy === userId) return;

				setTimeout(async () => {
					handleGeneralConversationUpdate(data.conversationId);
				}, 3_000);
			},
		}
	);

	trpc.inbox.conversation.newEmailMessage.useSubscription(
		{
			organizationId,
			token: storageHelpers.getUserToken(),
		},
		{
			onData: async (data) => {
				setTimeout(async () => {
					trpcUtils.inbox.inbox.getInboxStatsFixedFolders.invalidate();
					trpcUtils.inbox.inbox.getInboxStatsLabels.invalidate();

					handleGeneralConversationUpdate(data.conversationId);

					const sender =
						data.message.type === "email"
							? data.message.authorData?.author.displayName ||
							  data.message.recipients?.find((recipient) => recipient?.type === "from")?.name ||
							  ""
							: "";
					const content = data.message.type === "email" ? data.message.subject || data.message.snippet : "";

					// Don't show notification if it's already shown or if user is the sender
					if (emailMessageNotificationRef.current.has(data.message._id) || data.sentBy === userId) {
						return;
					}

					emailMessageNotificationRef.current.add(data.message._id);

					showNewMessageNotification({
						sender,
						content,
						conversationId: String(data.message.conversationId),
					});
				}, 3_000);
			},
		}
	);

	trpc.inbox.conversation.newConversation.useSubscription(
		{
			organizationId,
			token: storageHelpers.getUserToken(),
		},
		{
			onData(data) {
				setTimeout(async () => {
					trpcUtils.inbox.inbox.getInboxStatsFixedFolders.invalidate();
					trpcUtils.inbox.inbox.getInboxStatsLabels.invalidate();

					handleGeneralConversationUpdate(data.conversationId);

					const sender =
						data.latestMessage.type === "email"
							? data.latestMessage.authorData?.author.displayName ||
							  data.latestMessage.recipients?.find((recipient) => recipient?.type === "from")?.name ||
							  ""
							: "";
					const content =
						data.latestMessage.type === "email"
							? data.latestMessage.subject || data.latestMessage.snippet
							: "";

					// Don't show notification if it's already shown or if user is the sender
					if (emailMessageNotificationRef.current.has(data.latestMessage._id) || data.sentBy === userId) {
						return;
					}

					emailMessageNotificationRef.current.add(data.latestMessage._id);

					showNewMessageNotification({
						sender,
						content,
						conversationId: String(data.latestMessage.conversationId),
					});
				}, 3_000);
			},
		}
	);

	trpc.inbox.conversation.syncConversationBatchSaved.useSubscription(
		{
			organizationId,
			token: storageHelpers.getUserToken(),
		},
		{
			onData(data) {
				if (data.organizationId !== organizationId) return;

				// Wait till hopefully everything is up to date in elastic 😣
				setTimeout(() => {
					const hasConversationsInCurrentList = Boolean(
						trpcUtils.inbox.conversation.allFromFolder
							.getInfiniteData({
								folder: currentFolder,
								status: currentStatus,
								inboxLabelId: currentLabel,
								...getSearchParameters(),
							})
							?.pages?.flatMap((page) => page.data)?.length
					);

					// NOTE: for now only update list when there are no conversations yet, we want to prevent allFromFolder invalidation as much as possible to prevent weird out of sync visual behavior
					if (!hasConversationsInCurrentList) {
						trpcUtils.inbox.conversation.allFromFolder.invalidate();
						trpcUtils.inbox.inbox.getInboxStatsFixedFolders.invalidate();
						trpcUtils.inbox.inbox.getInboxStatsLabels.invalidate();
					}
					trpcUtils.channel.getInboxChannels.invalidate();
				}, 10_000);
			},
		}
	);

	trpc.inbox.conversation.newInternalMessage.useSubscription(
		{
			organizationId,
			token: storageHelpers.getUserToken(),
		},
		{
			onData(data) {
				if (data.organizationId !== organizationId) return;
				if (data.sentBy === userId) return;

				trpcUtils.inbox.conversation.allFromFolder.invalidate();
				trpcUtils.inbox.inbox.getInboxStatsFixedFolders.invalidate();
				trpcUtils.inbox.inbox.getInboxStatsLabels.invalidate();

				trpcUtils.inbox.message.allByConversationId.setData(
					{ conversationId: data.conversationId },
					// @ts-ignore
					(prevData) => {
						return [...(prevData ? prevData : []), data.internalMessage];
					}
				);

				showNewMessageNotification({
					sender: data.internalMessage.authorData.author.displayName,
					content: data.internalMessage.payload.content,
					conversationId: String(data.internalMessage.conversationId),
				});
			},
		}
	);

	if (!hasAccess) return <BetaLock />;

	return (
		<main className="inbox_layout">
			<DashboardHeader
				title={intl.formatMessage({
					id: "inbox.page.title",
					defaultMessage: "Inbox (BETA)",
				})}
				className="inbox_layout-header"
			>
				<InboxSearch />
			</DashboardHeader>
			<div className="inbox_container">
				<SideNav />
				<Suspense fallback={<div />}>
					<ConversationView />
					<ComposeWindow />
				</Suspense>
			</div>
			<InitWalkthrough />
		</main>
	);
}
