import { atom } from "recoil";

export const selectedArchivedContactsState = atom<string[]>({
	key: "selectedArchivedContactsState",
	default: [],
});

export const selectedAllArchivedContactsState = atom<boolean>({
	key: "selectedAllArchivedContactsState",
	default: false,
});
