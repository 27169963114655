import "./style.scss";

import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { routesConfig, typesConfig } from "@/config";
import { isAddFlowModalVisibleState, selectedTemplateIdState } from "@/flows/overview/create/state";
import CardOverview from "@/flows/overview/create/overview/card_overview";
import { useWindowWidth } from "@/shared/hooks";

export default function NoPermissions() {
	const { team } = useParams();
	const { push } = useHistory();
	const width = useWindowWidth();
	const [cardAmount, setCardAmount] = useState(0);

	const setSelectedTemplateId = useSetRecoilState(selectedTemplateIdState);
	const setIsModalVisible = useSetRecoilState(isAddFlowModalVisibleState);

	useEffect(() => {
		if (width > 768) setCardAmount(2);
		if (width > 992) setCardAmount(3);
		if (width > 1200) setCardAmount(4);
	}, [width]);

	const handleSelectTemplate = (templateId) => {
		setSelectedTemplateId(templateId);
		setIsModalVisible(true);
	};

	const handleGoToBilling = () => {
		// TODO enable when billing is active again
		// const url = routesConfig.dashboard.billing.replace(":team", team);

		const url = routesConfig.dashboard.support.replace(":team", team);

		push(`${url}?option=${typesConfig.supportTypes.pricing}`);
	};

	return (
		<section className="fol-locked t-gap--top-xl">
			<header className="fol-locked-header">
				<h1>
					<FormattedMessage id="FLOWS.OVERVIEW.LOCK.TITLE" />
				</h1>
				<p>
					<FormattedMessage id="FLOWS.OVERVIEW.LOCK.DESCRIPTION" />
				</p>
			</header>
			<CardOverview
				handleSelectTemplate={handleSelectTemplate}
				loadingAmount={cardAmount}
				maxAmount={cardAmount}
			/>
			<footer className="t-gap--top">
				<Button onClick={handleGoToBilling} size="large" className="t-gap--bottom-sm">
					<FormattedMessage id="FLOWS.OVERVIEW.LOCK.UPGRADE_BUTTON" />
				</Button>
			</footer>
		</section>
	);
}
