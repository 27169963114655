import "../style.scss";

import { Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { NavLink, useParams } from "react-router-dom";
import { IIntegration } from "@bothive_core/database";
import { useState } from "react";

import { ConnectChannel } from "../../../../shared/components/walk_through";
import ConnectChannelModal from "../../../settings/channels/connectChannel";

export default function ComposeConnectChannel({ className = "" }: { className?: string }) {
	const { team } = useParams<{ team: string }>();
	const [openConnectModal, setOpenConnectModal] = useState(false);
	const [selectedProvider, setSelectedProvider] = useState<IIntegration>();

	const handleClick = (provider: IIntegration) => {
		setOpenConnectModal(true);
		setSelectedProvider(provider);
	};

	return (
		<section className={`inbox-composer-connect_channel ${className}`}>
			<div className="inbox-composer-connect_channel-wrapper">
				<header className="inbox-composer-connect_channel-header">
					<Typography.Title level={3}>
						<FormattedMessage
							id="inbox.compose.connect_channel.title"
							defaultMessage="Connect your email channel"
							description="Intro overlay over the compose when user has no channels connected"
						/>
					</Typography.Title>
					<p>
						<FormattedMessage
							id="inbox.compose.connect_channel.description"
							defaultMessage="Connect email channels with your inbox."
						/>
					</p>
				</header>
				<ConnectChannel onClick={handleClick} className="inbox-composer-connect_channel-connections" />
				<footer className="inbox-composer-connect_channel-footer">
					<p>
						<FormattedMessage
							id="inbox.compose.connect_channel.footer"
							defaultMessage="You can manage your channels in <link>settings / channels</link>"
							values={{
								link: (content) => (
									<NavLink to={`/${team}/settings/inbox/channels`} className="a-link">
										{content}
									</NavLink>
								),
							}}
						/>
					</p>
				</footer>
			</div>
			<ConnectChannelModal
				step="addChannel"
				open={openConnectModal}
				provider={selectedProvider}
				onClose={() => setOpenConnectModal(false)}
			/>
		</section>
	);
}
