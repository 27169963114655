import { routesConfig } from "@/flows";

export default {
	tabTitle: "FLOW_DETAIL.HEADER.BREADCRUMB.FLOWS",
	breadcrumbs: ({ team }) => [
		{
			to: routesConfig.overview.replace(":team", team),
			name: "FLOW_DETAIL.HEADER.BREADCRUMB.FLOWS",
		},
	],
	table: {
		pagination: {
			showSizeChanger: true,
			defaultPageSize: 10,
			pageSizeOptions: ["10", "20", "50", "100", "200"],
		},
	},
	pagination: {
		limit: 20,
	},
};
