import "./style.scss";

import { Form, FormRule, InputNumber, Switch, TimePicker } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

export default function WhenForm() {
	const intl = useIntl();

	const requiredAndNumberRules = [
		{
			type: "number",
			min: 1,
			message: intl.formatMessage({
				id: "flow.detail.reminders.create_strategy.step_1.send_first_after_days.number",
				defaultMessage: "Please fill in a number greater than 0",
			}),
		},
		{
			type: "integer",
			message: intl.formatMessage({
				id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.STEP_1.SEND_FIRST_AFTER_DAYS.NUMBER",
				defaultMessage: "No decimal numbers please",
			}),
		},
	] as FormRule[];

	return (
		<div style={{ display: "flex", gap: "1em", flexDirection: "column" }}>
			<div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
				<p className="word-break-none">
					<FormattedMessage
						id="flow.detail.reminders.create_strategy.step_1.send_first_after_days.1"
						defaultMessage="Send first reminder if report hasn't been filled in for"
					/>
				</p>
				<Form.Item
					name={["sendFirstAfterDays"]}
					rules={[
						...requiredAndNumberRules,
						{
							required: true,
							message: intl.formatMessage({
								id: "flow.detail.reminders.create_strategy.step_1.send_first_after_days.required",
								defaultMessage:
									"Please input the number of days after which the first reminder should be sent.",
							}),
						},
						{
							type: "number",
							max: 365,
							message: intl.formatMessage({
								id: "flow.detail.reminders.create_strategy.step_1.send_first_after_days.max",
								defaultMessage: "Please fill in a number less than 365",
							}),
						},
					]}
					style={{ marginBottom: 0 }}
				>
					<InputNumber precision={0} min={1} max={365} />
				</Form.Item>
				<p className="word-break-none">
					<FormattedMessage
						id="flow.detail.reminders.create_strategy.step_1.send_first_after_days.2"
						defaultMessage="days"
					/>
				</p>
			</div>
			<div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
				<p className="word-break-none">
					<FormattedMessage
						id="flow.detail.reminders.create_strategy.step_1.interval.1"
						defaultMessage="Keep sending new reminders every"
					/>
				</p>
				<Form.Item
					name={["interval"]}
					rules={[
						...requiredAndNumberRules,
						{
							required: true,
							message: intl.formatMessage({
								id: "flow.detail.reminders.create_strategy.step_1.interval.required",
								defaultMessage: "Please fill in the interval",
							}),
						},
						{
							type: "number",
							max: 365,
							message: intl.formatMessage({
								id: "flow.detail.reminders.create_strategy.step_1.interval.max",
								defaultMessage: "Please fill in a number less than 365",
							}),
						},
					]}
					style={{ marginBottom: 0 }}
				>
					<InputNumber precision={0} min={1} max={365} />
				</Form.Item>
				<p className="word-break-none">
					<FormattedMessage
						id="flow.detail.reminders.create_strategy.step_1.interval.2"
						defaultMessage="days for a total of"
					/>
				</p>
				<Form.Item
					name={["total"]}
					rules={[
						...requiredAndNumberRules,
						{
							required: true,
							message: intl.formatMessage({
								id: "flow.detail.reminders.create_strategy.step_1.total.required",
								defaultMessage: "Please fill in the total of reminders",
							}),
						},
						{
							type: "number",
							max: 10_000,
							message: intl.formatMessage({
								id: "flow.detail.reminders.create_strategy.step_1.total.max",
								defaultMessage: "Please fill in a number less than 365",
							}),
						},
					]}
					style={{ marginBottom: 0 }}
				>
					<InputNumber precision={0} min={1} max={10_000} />
				</Form.Item>
				<p className="word-break-none">
					<FormattedMessage
						id="flow.detail.reminders.create_strategy.step_1.interval.3"
						defaultMessage="reminders"
					/>
				</p>
			</div>
			<p className="word-break-none">
				<FormattedMessage
					id="flow.detail.reminders.create_strategy.step_1.interval.1_explanation"
					defaultMessage="until report is completed or stop date is reached"
				/>
			</p>
			<div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
				<p className="word-break-none">
					<FormattedMessage
						id="flow.detail.reminders.create_strategy.step_1.time_of_day.1"
						defaultMessage="Send the reminder at"
					/>
				</p>
				<Form.Item
					name={["timeOfDay"]}
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "flow.detail.reminders.create_strategy.step_1.time_of_day.required",
								defaultMessage: "Please select a time of day",
							}),
						},
					]}
					style={{ marginBottom: 0 }}
				>
					<TimePicker
						format="HH:mm"
						minuteStep={15}
						allowClear={false}
						changeOnBlur
						style={{ width: "135px" }}
					/>
				</Form.Item>
				<p className="word-break-none">
					<FormattedMessage
						id="flow.detail.reminders.create_strategy.step_1.time_of_day.2"
						defaultMessage="o'clock."
					/>
				</p>
			</div>
			<div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
				<p className="word-break-none">
					<FormattedMessage
						id="flow.detail.reminders.create_strategy.allow_reenroll.1"
						defaultMessage="If new report is created also enroll it in this reminder strategy"
					/>
				</p>
				<Form.Item name={["allowReEnroll"]} valuePropName="checked" style={{ marginBottom: 0 }}>
					<Switch />
				</Form.Item>
			</div>
		</div>
	);
}
