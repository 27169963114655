import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

type cardItemInfoType = {
	className?: string;
	link: string;
	icon: string;
	title: string;
	description: string;
	formattedMessageValues?: Record<string, ReactNode>;
};
export default function SettingsCardItem(cardItemInfo: cardItemInfoType) {
	return (
		<NavLink
			to={cardItemInfo.link}
			className={`m-card--settings-options ${cardItemInfo.className}`}
			activeClassName="active"
			exact={true}
		>
			<img src={cardItemInfo.icon} className="a-image--icon card-icon" />
			<div className="card-body">
				<p className="bold">
					<FormattedMessage id={cardItemInfo.title} values={cardItemInfo.formattedMessageValues} />
				</p>
				<p>
					<FormattedMessage id={cardItemInfo.description} values={cardItemInfo.formattedMessageValues} />
				</p>
			</div>
		</NavLink>
	);
}
