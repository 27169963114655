export default {
	FLOW_REPORT_IS_FETCHING: "FLOW_REPORT_IS_FETCHING",
	FETCH_FLOW_REPORT_FAILED: "FETCH_FLOW_REPORT_FAILED",
	FETCH_FLOW_REPORT_SUCCEEDED: "FETCH_FLOW_REPORT_SUCCEEDED",

	SET_FLOW_REPORTS_FILTER: "SET_FLOW_REPORTS_FILTER",
	FETCH_FLOW_REPORTS: "FETCH_FLOW_REPORTS",
	FETCH_NEXT_FLOW_REPORTS: "FETCH_NEXT_FLOW_REPORTS",
	FETCH_REPORT_DETAIL: "FETCH_REPORT_DETAIL",
	FETCH_NEXT_REPORT_DETAIL: "FETCH_NEXT_REPORT_DETAIL",
	FETCH_PREVIOUS_REPORT_DETAIL: "FETCH_PREVIOUS_REPORT_DETAIL",
	DOWNLOAD_FLOW_REPORT: "DOWNLOAD_FLOW_REPORT",
	DOWNLOAD_FLOW_REPORT_FILES: "DOWNLOAD_FLOW_REPORT_FILES",

	REPORT_ALREADY_FETCHED: "REPORT_ALREADY_FETCHED",
	RESET_FLOW_REPORTS: "RESET_FLOW_REPORTS",

	UPDATE_REPORT: "UPDATE_REPORT",
	ASSIGN_TO_REPORT: "ASSIGN_TO_REPORT",
	UPDATE_REPORT_LOCALLY: "UPDATE_REPORT_LOCALLY",

	DELETE_REPORT: "DELETE_REPORT",

	TEAM_CHANGED: "TEAM_CHANGED", //listen to actions from the team structure
	FETCH_TEAM: "FETCH_TEAM",

	SET_REPORT_CANCELLATION: "SET_REPORT_CANCELLATION",
	SET_CURRENT_PAGE: "SET_CURRENT_PAGE",

	FLOW_REPORT_ATTACHMENTS_ARE_FETCHING: "FLOW_REPORT_ATTACHMENTS_ARE_FETCHING",
	FLOW_REPORT_ATTACHMENTS_COUNT: "FLOW_REPORT_ATTACHMENTS_COUNT",
};
