import { ThemeConfig } from "antd";

const tokens: ThemeConfig = {
	components: {
		Button: {
			colorText: "#002140",
			colorBgContainer: "#f8c945",

			dangerColor: "#fff",
			colorError: "#de5050",
			primaryShadow: "none",
		},
		Dropdown: {
			controlItemBgActiveHover: "#00214020",
			controlItemBgActive: "#ebedee",
		},
		Menu: {
			itemHeight: 50,
			itemBg: "#ebedee",
			// itemHoverBg: "#00214020",
			itemSelectedColor: "#002140",
			itemSelectedBg: "#00214020",
		},
		Table: {
			headerSortActiveBg: "#00214090",
			headerSortHoverBg: "#00214090",

			rowHoverBg: "#ebedee",
			headerBg: "#002140",
			headerSplitColor: "#002140",
			headerColor: "#ffffff",
			// colorText: "#ebedee",
			rowSelectedBg: "#ebedee",
			rowSelectedHoverBg: "#ebedee90",
		},
		Select: {
			controlItemBgActive: "#ebedee",
		},
		Steps: {
			controlItemBgActive: "#ebedee",
		},
	},
	token: {
		colorLink: "#002140",
		colorPrimary: "#002140",
		colorSuccess: "#2eb81f",
		colorWarning: "#e47d26",
		colorError: "#de5050",
		colorTextBase: "#002140",
		fontSize: 12,
	},
};

export default tokens;
