import { ConversationRouterOutput } from "@/trpc";
import { stringHelpers } from "@bothive/helpers";
import qs from "qs";
import { useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import dayjs from "dayjs";

import { routesConfig } from "../../../config";
import { StatusFilterOption } from "../config/statusFilters.config";

export const inboxFolders = [
	"personal_conversations",
	"assigned_to_me",
	"assigned_by_me",
	"mentioned",
	"shared_with_me",
	"all_conversations",
	"sent",
	"drafts",
	"outbox",
] as const;
export type InboxFolder = (typeof inboxFolders)[number];

export const statusLabelFilters = ["open", "closed", "trash", "spam"] as const;
export type StatusLabelFilter = (typeof statusLabelFilters)[number];

export interface IInboxSearch {
	q?: string;
	to?: string[];
	from?: string[];
	subject?: string;
	searchWithinFolders?: string[];
	searchWithinLabels?: string[];
	hasAttachments?: boolean;
	fromDate?: string;
	until?: string;
}

interface IGenerateLeftNavBarLocation {
	folder?: InboxFolder;
	label?: string;
	isSearching?: boolean;
}
function generateLeftNavBarLocation({ folder, label, isSearching }: IGenerateLeftNavBarLocation) {
	let url = routesConfig.dashboard.inbox.folder;

	if (isSearching) return "/:team/inbox/search/:conversationId?";
	if (folder) {
		if (inboxFolders.includes(folder)) {
			url = routesConfig.dashboard.inbox.status;
		}

		return url.replace(":folder", folder);
	}

	if (label) return routesConfig.dashboard.inbox.labelStatus.replace(":label", label);

	return url.replace(":folder", "personal_conversations");
}

const allowedSearchParams = [
	"q",
	"from",
	"to",
	"subject",
	"searchWithinFolders",
	"searchWithinLabels",
	"hasAttachments",
	"fromDate",
	"until",
];

const useInboxNavigation = () => {
	const history = useHistory();
	const location = useLocation();
	const params: {
		folder: InboxFolder;
		status: StatusLabelFilter;
		team: string;
		conversationId: string;
		label: string;
	} = useParams();

	const isSearching = location.pathname.includes("/search");
	const statusFiltersVisible = inboxFolders.includes(params.folder);

	const setFolder = useCallback(
		({ folder, conversationId }: { folder: InboxFolder; conversationId?: string }) => {
			const url = generateLeftNavBarLocation({ folder });

			history.push(
				url
					.replace(":team", params.team)
					.replace(":folder", folder)
					.replace(":status", "open")
					.replace("/:conversationId?", conversationId ? `/${conversationId}` : "")
			);
		},
		[params]
	);

	const setLabel = useCallback(
		(label: string) => {
			const url = routesConfig.dashboard.inbox.labelStatus;

			history.push(
				url
					.replace(":team", params.team)
					.replace(`:label`, label)
					.replace(":status", "open")
					.replace("/:conversationId?", "")
			);
		},
		[params]
	);

	const setStatus = useCallback(
		(status: StatusFilterOption, conversationId?: string) => {
			const url = generateLeftNavBarLocation(params);

			history.push(
				url
					.replace(":team", params.team)
					.replace(":status", status)
					.replace(":conversationId?", conversationId || "")
			);
		},
		[params]
	);

	const setConversation = useCallback(
		(conversationId?: string) => {
			const url = generateLeftNavBarLocation({ ...params, isSearching });
			const formatUrl = url
				.replace(":team", params.team)
				.replace(":status", params.status || "open")
				.replace(":conversationId?", conversationId || "");

			history.push(`${formatUrl}${location.search}`);
		},
		[params]
	);

	const setNextConversation = useCallback(
		(conversationList: ConversationRouterOutput["allFromFolder"]["data"]) => {
			const url = generateLeftNavBarLocation({ ...params, isSearching });

			const locationCurrentConversation = conversationList.findIndex(
				(conversation) => conversation._id === params.conversationId
			);

			let nextConversationId;

			if (locationCurrentConversation + 1 < conversationList.length) {
				nextConversationId = conversationList[locationCurrentConversation + 1]?._id;
			} else if (conversationList.length - 1 === 0) {
				nextConversationId = "";
			} else {
				nextConversationId = conversationList[locationCurrentConversation - 1]?._id;
			}

			const formatUrl = url
				.replace(":team", params.team)
				.replace(":status", params.status)
				.replace(":conversationId?", nextConversationId || "");

			history.push(`${formatUrl}${location.search}`);
		},
		[params]
	);

	const setPreviousConversation = useCallback(
		(conversationList: ConversationRouterOutput["allFromFolder"]["data"]) => {
			const url = generateLeftNavBarLocation({ ...params, isSearching });

			const locationCurrentConversation = conversationList.findIndex(
				(conversation) => conversation._id === params.conversationId
			);

			let previousConversationId;

			if (locationCurrentConversation - 1 < conversationList.length) {
				previousConversationId = conversationList[locationCurrentConversation - 1]?._id;
			} else {
				previousConversationId = conversationList[0]?._id;
			}

			const formatUrl = url
				.replace(":team", params.team)
				.replace(":status", params.status)
				.replace(":conversationId?", previousConversationId || "");

			history.push(`${formatUrl}${location.search}`);
		},
		[params]
	);

	const getSearchParameters = useCallback((): Partial<IInboxSearch> => {
		const params = qs.parse<IInboxSearch>(location.search, { ignoreQueryPrefix: true });

		if (!params) return {};

		return Object.keys(params).reduce((prev, key) => {
			let value: string | number | string[] | Date | boolean | dayjs.Dayjs = decodeURI(params[key]);

			const arrayKeys = ["to", "from", "searchWithinFolders", "searchWithinLabels"];

			if (!allowedSearchParams.includes(key)) return prev;

			if (key === "fromDate" || key === "until") value = dayjs.unix(Number(value));
			if (arrayKeys.includes(key))
				value = (value as string).split(",").filter((value) => !stringHelpers.isEmptyString(value));
			if (value === "true") value = true; // qs doesn't parse boolean

			return { ...prev, [key]: value };
		}, {});
	}, [location.search]);
	const setSearch = (queryParams: IInboxSearch) => {
		const filterQueryParams = Object.keys(queryParams).reduce((prev, key) => {
			let value = queryParams[key];

			if (value === true) value = "true"; // qs doesn't stringify boolean
			if (key === "fromDate" || key === "until") value = value?.unix();
			if (!value || stringHelpers.isEmptyString(value)) return prev;
			if (Array.isArray(value) && !value.length) return prev;

			return { ...prev, [key]: encodeURI(value) };
		}, {});

		if (!Object.keys(filterQueryParams).length) {
			setFolder({ folder: "personal_conversations" });
			return;
		}

		const search = qs.stringify(filterQueryParams);
		let pathname = location.pathname;

		if (!isSearching) pathname = `/${params.team}/inbox/search`;

		history.replace({ pathname, search });
	};

	return {
		setLabel,
		setStatus,
		setFolder,
		setSearch,
		isSearching,
		setConversation,
		getSearchParameters,
		setNextConversation,
		statusFiltersVisible,
		setPreviousConversation,
		currentLabel: params.label,
		currentFolder: params.folder,
		currentStatus: params.status,
		path: history.location.pathname,
		currentConversation: params.conversationId,
	};
};

export default useInboxNavigation;
