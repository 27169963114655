import React from "react";

import { AIcon } from "../atoms.component";

export default function AVideoPreview({ src, alt, className }) {
	return (
		<div className={`a-image--video-preview ${className}`}>
			<img alt={alt} src={src} className="image--preview" />
			<AIcon type="video" className="image--icon" />
		</div>
	);
}
