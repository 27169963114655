import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import { trpc } from "../trpc";
import auth2Routes from "./auth2.routes";
import contactRoutes from "./contact.routes";
import history from "./history";
import PortalRoutes from "../modules/portal";
import SetupRoutes from "../modules/setup";
import protectedRoutes from "./protected.routes";
import ScrollToTop from "./scroll-to-top.route";
import { storageHelpers } from "@/helpers";

/**
 * this is the router setup.
 */
const Routing = () => {
	const organizationId = useSelector((state: any) => state.content?.team?.team?._id, shallowEqual);

	const membersQuery = trpc.member.getAll.useQuery(undefined, { enabled: Boolean(storageHelpers.getUserToken()) });

	useEffect(() => {
		if (!organizationId) return;
		membersQuery.refetch();
	}, [organizationId]);

	return (
		<Router history={history}>
			<ScrollToTop>
				<Switch>
					<Route path="/auth" component={auth2Routes} />
					<Route path="/contact" component={contactRoutes} />
					<Route path="/portal" component={PortalRoutes} />
					<Route path="/:slug/setup" component={SetupRoutes} />
					<Route path="/" component={protectedRoutes} />
					<Route render={() => <Redirect to="/" />} />
				</Switch>
			</ScrollToTop>
		</Router>
	);
};

export default Routing;
