import "./style.scss";

import { Button, Form, Input, Modal, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { trpc } from "../../../../../../trpc";

export default function ConfirmDelete({ apiKey, visible, onClose }) {
	const intl = useIntl();
	const history = useHistory();
	const trpcUtils = trpc.useUtils();
	const routeParameters = useParams<{ team: string }>();

	const deleteApiKeyMutation = trpc.apiKeyManager.deleteApiKey.useMutation();

	const deleteApiKey = () => {
		if (!apiKey) return;
		deleteApiKeyMutation.mutate(apiKey, {
			onSuccess: () => {
				onClose();
				trpcUtils.apiKeyManager.invalidate();
				history.replace(`/${routeParameters.team}/settings/api_key`);
				notification.success({
					message: intl.formatMessage({
						id: "banner.succeeded.delete_api_key",
						defaultMessage: "API key is successfully removed.",
					}),
					placement: "bottomRight",
				});
			},
			onError: () => {
				notification.error({
					message: intl.formatMessage({
						id: "banner.failed.delete_api_key",
						defaultMessage: "API key could not be deleted. Please try again later.",
					}),
					placement: "bottomRight",
				});
			},
		});
	};

	const keyword = intl.formatMessage({ id: "keyword.delete", defaultMessage: "DELETE" });

	return (
		<Modal
			open={visible}
			onCancel={onClose}
			title={<FormattedMessage id="SETTINGS.API_KEY_MODAL.DELETE_API_KEY.HEADER.TITLE" />}
			transitionName="" // to disable animation
			footer={null}
			className="page-api-key-permissions--delete-modal"
		>
			<p className="t-gap--bottom-sm">
				<FormattedMessage id="SETTINGS.API_KEY_MODAL.DELETE_API_KEY.HEADER.DESCRIPTION" />
			</p>
			<Form id="delete" requiredMark="optional" autoComplete="off" layout="vertical">
				<Form.Item
					name="name"
					rules={[
						{
							required: true,
							validator: (_, value) => {
								if (value === keyword) return Promise.resolve();

								const message = intl.formatMessage(
									{ id: "SETTINGS.API_KEY_MODAL.DELETE_API_KEY.FORM.DELETE.ERROR" },
									{ keyword }
								);

								return Promise.reject(new Error(message));
							},
						},
					]}
				>
					<Input
						placeholder={intl.formatMessage(
							{
								id: "SETTINGS.API_KEY_MODAL.DELETE_API_KEY.FORM.DELETE.PLACEHOLDER",
								defaultMessage: "Type {keyword} and press the delete button",
							},
							{ keyword }
						)}
					/>
				</Form.Item>
				<footer className="delete-modal--footer">
					<Form.Item shouldUpdate>
						{(form) => {
							const hasErrors =
								!form.isFieldsTouched() || form.getFieldsError().some(({ errors }) => errors.length);

							return (
								<Button
									danger
									loading={deleteApiKeyMutation.isLoading}
									disabled={hasErrors}
									onClick={deleteApiKey}
								>
									<FormattedMessage id="SETTINGS.API_KEY_MODAL.DELETE_API_KEY.DELETE.BUTTON_LABEL" />
								</Button>
							);
						}}
					</Form.Item>
				</footer>
			</Form>
		</Modal>
	);
}
