import React from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { routesConfig } from "../../../config";
import { Buttons, Image } from "../../atoms";

function OModel({ teamSlug, icon, onClick, className }) {
	return (
		<header className={`o-header o-header--contact ${className}`}>
			<NavLink to={routesConfig.dashboard.root.replace(":team", teamSlug)} className="o-header--logo">
				<Image.ALogo />
			</NavLink>
			<Buttons.AIconButton icon={icon} onClick={onClick} iconClass="small" className="a-button--special" />
		</header>
	);
}

OModel.defaultProps = {
	icon: "close",
	className: "",
	onClick: () => undefined,
};

export default withRouter(OModel);
