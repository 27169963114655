import { Collapse } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./style.scss";

import { Section } from "@/components/organisms";
import { DestructivePanel } from "@/shared/components/collapse";
import DeleteModal from "@/shared/components/modal/delete_modal";

export default function DeleteCollapse({
	headerTitle,
	panelTitle,
	isDeleting,
	modalDescription,
	buttonText,
	deleteAction,
}: {
	headerTitle?: string;
	panelTitle: string;
	modalDescription?: string;
	isDeleting?: boolean;
	buttonText: string;
	deleteAction?: () => void;
}) {
	const intl = useIntl();
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	return (
		<>
			<Collapse expandIconPosition="end" className="custom-collapse" bordered={false}>
				<DestructivePanel
					key="deleteChannelPanel"
					title={
						headerTitle ||
						intl.formatMessage({
							id: "SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.ACCORDION_TITLE",
							defaultMessage: "Delete integration",
						})
					}
				>
					<Section.ODestructive
						description={panelTitle}
						buttonLabel={buttonText}
						onClick={() => setShowDeleteModal(true)}
					/>
				</DestructivePanel>
			</Collapse>
			<DeleteModal
				title={
					headerTitle ? (
						headerTitle
					) : (
						<FormattedMessage
							id="SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.ACCORDION_TITLE"
							defaultMessage="Delete integration"
						/>
					)
				}
				isDeleting={isDeleting}
				deleteLabel={buttonText}
				description={modalDescription}
				isVisible={showDeleteModal}
				onDelete={() => deleteAction && deleteAction()}
				onClose={() => setShowDeleteModal(false)}
			/>
		</>
	);
}
