import PopupFullscreen from "@/shared/components/popup/full_screen";
import Logo from "@/shared/components/logo";
import { routesConfig } from "@/config";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FlowSendingSteps from "@/flows/send/components/Steps";
import ContactSelection from "@/flows/send/components/ContactSelection";
import Compose from "@/flows/send/components/Compose";
import { messageValues } from "@/flows/send/interface";
import FlowSendingOverview from "@/flows/send/components/Overview";
import { IContact, IFlowPopulated, TProgress } from "@bothive_core/database";
import FlowSendingResult from "@/flows/send/components/Result";
import { selectedQuery } from "@/flows/send/components/ContactSelection/interface";
import NoChannel from "@/shared/components/no_channel";
import { keyEvents } from "../../helpers";
import { trpc } from "@/trpc";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export default function FlowSending({
	handleClose,
	flow,
	defaultProgress,
	templateType,
	showClose,
	showReports,
	reportSelection,
}: {
	handleClose: () => void;
	flow: IFlowPopulated;
	showClose?: boolean;
	showReports?: boolean;
	reportSelection?: string[];
	defaultProgress?: TProgress[];
	templateType?: string;
}) {
	const [currentStep, setCurrentStep] = React.useState(0);
	const [selectedContacts, setSelectedContacts] = React.useState<Partial<IContact>[]>([]);
	const [messageValues, setMessageValues] = React.useState<messageValues | undefined>(undefined);
	const [sendToCompletedReports, setSendToCompletedReports] = useState<boolean>(false);
	const [query, setQuery] = useState<selectedQuery | undefined>(
		defaultProgress ? { type: "progress", value: defaultProgress } : undefined
	);

	// @ts-ignore
	const team = useSelector((state) => state.content.team.team);

	const { data: inbox } = trpc.inbox.inbox.getUserInbox.useQuery();

	const generateSteps = () => {
		if (!inbox?.channels.length) {
			return <NoChannel />;
		}

		switch (currentStep) {
			case 0:
				return (
					<ContactSelection
						setCurrentStep={setCurrentStep}
						setSelectedContacts={setSelectedContacts}
						flow={flow}
						reportSelection={reportSelection}
						query={query}
						setQuery={setQuery}
						defaultProgress={defaultProgress}
						sendToCompletedReports={sendToCompletedReports}
						setSendToCompletedReports={setSendToCompletedReports}
					/>
				);
			case 1:
				return (
					<Compose
						setCurrentStep={setCurrentStep}
						setMessageValues={setMessageValues}
						messageValues={messageValues}
						templateId={flow.template?._id}
						isReminding={templateType === "reminding"}
					/>
				);
			case 2:
				return (
					<FlowSendingOverview
						contactSelection={selectedContacts}
						messageValues={messageValues}
						setCurrentStep={setCurrentStep}
						flow={flow}
						sendToCompletedReports={sendToCompletedReports}
					/>
				);
			case 3:
				return (
					<FlowSendingResult
						handleClose={handleClose}
						flowId={flow._id}
						showClose={showClose}
						showReports={showReports}
					/>
				);
			default:
				return (
					<ContactSelection
						setCurrentStep={setCurrentStep}
						setSelectedContacts={setSelectedContacts}
						query={query}
						setQuery={setQuery}
						defaultProgress={defaultProgress}
						sendToCompletedReports={sendToCompletedReports}
						setSendToCompletedReports={setSendToCompletedReports}
					/>
				);
		}
	};

	const handleKeyUp = (event: KeyboardEvent) => {
		if (keyEvents.escapePressed(event)) {
			handleClose();
		}
	};

	useEffect(() => {
		document.addEventListener("keyup", handleKeyUp);

		return () => {
			document.removeEventListener("keyup", handleKeyUp);
		};
	}, []);

	return (
		<PopupFullscreen onClose={handleClose} className="t-layout--modal">
			<header className="o-header o-header--contact">
				<NavLink to={routesConfig.dashboard.root.replace(":team", team?.slug)} className="o-header--logo">
					<span style={{ maxWidth: "14rem", display: "grid", placeItems: "center" }}>
						<Logo />
					</span>
				</NavLink>
				<Button type="text" icon={<CloseOutlined />} onClick={handleClose} />
			</header>
			<div style={{ display: "flex" }} className="layout-content">
				<FlowSendingSteps currentStep={currentStep} />
				{generateSteps()}
			</div>
		</PopupFullscreen>
	);
}
