import { Button, Col, Row, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import "../common/style.scss";
import { authenticationHelper } from "@/helpers";
import { useIntl } from "react-intl";
import { addConnection } from "../common/helpers";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { trpc } from "@/trpc";
import { useRecoilRefresher_UNSTABLE } from "recoil";
import { automationSelector } from "@/flows/report_detail/meta_aside/automation/state";

export default function IntegrationConnection({
	allowEditing,
	canAddIntegration,
}: {
	allowEditing: boolean;
	canAddIntegration: boolean;
}) {
	const intl = useIntl();
	const history = useHistory();
	const location = useLocation();
	const { connectionId: integrationId }: any = useParams();
	const trpcUtils = trpc.useUtils();
	const addConnectionMutation = trpc.integration.addConnection.useMutation();
	const { data } = trpc.integration?.getIntegrationById?.useQuery({ query: { integrationId } });
	const invalidateRules = useRecoilRefresher_UNSTABLE(automationSelector);

	return (
		<Row className="integrationModal">
			<Col className="info" xs={24} xl={9}>
				<section className="info-section">
					<h2 className="info-title">
						<FormattedMessage
							id="SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.TITLE"
							defaultMessage="Admin Pulse"
						/>
					</h2>
					<p>
						<FormattedMessage
							id="SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.DESCRIPTION"
							defaultMessage="Connect with Admin Pulse to easily sync your contacts with Bothive and sync flow's with Admin Pulse"
						/>
					</p>
					<ul className="info-features">
						<li>
							<FormattedMessage
								id="SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.FEATURES.SYNC_CONTACTS"
								defaultMessage="Synchronize contacts"
							/>
						</li>
					</ul>
				</section>
			</Col>
			<Col className="login" xs={24} xl={15}>
				<div className="logo-container">
					<img
						src="https://storage.googleapis.com/bothive_assets_8a0s1s3e/integration_icon/AdminPulse_Logo.png"
						alt="AdminPulse logo"
						className="logo"
					/>
				</div>
				<p className="login-description">
					<FormattedMessage
						id="SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.OAUTH2.DESCRIPTION"
						defaultMessage="Connect with Admin Pulse through their login."
					/>
				</p>
				<Tooltip
					title={
						!allowEditing
							? intl.formatMessage({
									id: "settings.integrations.adminpulse.tooltip.missing_integrations_permissions",
									defaultMessage: "Missing edit integrations permissions",
							  })
							: !canAddIntegration
							? intl.formatMessage({
									id: "settings.integrations.adminpulse.tooltip.limit_reached",
									defaultMessage: "Maximum integrations limit reached",
							  })
							: null
					}
					placement="bottom"
				>
					<Button
						type="primary"
						size="large"
						className="button"
						loading={addConnectionMutation.isLoading}
						disabled={!allowEditing || !canAddIntegration}
						onClick={() =>
							authenticationHelper.openSignInWindow({
								url: `${data?.accountLinking?.authUrl}&state=${window.origin}`,
								name,
								callback: (values) => {
									addConnection(
										intl,
										trpcUtils,
										integrationId,
										history,
										location,
										addConnectionMutation,
										{
											token: values.code,
										}
									);

									invalidateRules();
								},
							})
						}
					>
						<FormattedMessage
							id="SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.OAUTH2.BUTTON"
							defaultMessage="Log in with Admin Pulse"
						/>
					</Button>
				</Tooltip>
			</Col>
		</Row>
	);
}
