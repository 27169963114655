import "./style.scss";

import { Spin } from "antd";
import omitBy from "lodash.omitby";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useState } from "react";
import type { IContact } from "@bothive_core/database";

import { ContactRouterOutput, trpc } from "../../../../trpc";
import Collapse from "../../../../shared/components/collapse";
import ShowSuccessBanner from "@/shared/components/banner/success";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ContactBaseValues from "@/modules/contacts/contact_detail/attributes/components/ContactBaseValues";
import ContactAddresses from "@/modules/contacts/contact_detail/attributes/components/ContactAddresses";
import ContactLinking from "@/modules/contacts/contact_detail/attributes/components/ContactLinking";
import ContactType from "@/modules/contacts/contact_detail/attributes/components/ContactType";
import ContactDetail from "@/modules/contacts/contact_detail/attributes/components/ContactDetail";
import DashboardCard from "@/shared/components/card/dashboard";
import {
	BankAccounts,
	ChargingStation,
	EmailAddress,
	ExternalLinks,
	Properties,
	PhoneNumbers,
	Insurances,
} from "./components";

export default function ContactAttributes() {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const params: { contactId: string } = useParams();
	const updateContactMutation = trpc.contact.update.useMutation();
	const [conflictContact, setConflictContact] = useState<IContact | undefined>(undefined);
	const { data: contact, isLoading } = trpc.contact.getContactById.useQuery({ id: params?.contactId });

	const handleChange = (values: Partial<ContactRouterOutput["getContactById"]>): Promise<Boolean> => {
		const _values = omitBy(values, (value) => value === undefined || value === null || value === "");

		return new Promise((resolve, reject) =>
			// TODO: make this more type safe (read ts-reset docs)
			updateContactMutation.mutate(JSON.parse(JSON.stringify({ id: params.contactId, ..._values })) as any, {
				onSuccess: () => {
					trpcUtils.contact.getContactById.invalidate();

					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.SAVE.SUCCESS",
							defaultMessage: "Contact successfully saved",
						}),
					});
					resolve(true);
				},
				onError: (error) => {
					// todo re-add to contact detail, temp put here as it showed two banners
					if (error?.data?.code === "CONFLICT") {
						const details = error?.shape?.message
							? (JSON.parse(error?.shape?.message) as { conflictContact: IContact })
							: null;

						if (details?.conflictContact) setConflictContact(details.conflictContact);

						ShowFailedBanner({
							title: intl.formatMessage({
								id: "CONTACTS.DETAIL.ATTRIBUTES.SAVE.FAILED",
								defaultMessage: "Contact could not be saved",
							}),
							description: intl.formatMessage({
								id: "CONTACTS.DETAIL.ATTRIBUTES.SAVE.FAILED.DUPLICATE.DESCRIPTION",
								defaultMessage: "Some unique fields already exist in your organizations",
							}),
						});
						reject(error);

						return;
					}

					ShowFailedBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.SAVE.FAILED",
							defaultMessage: "Contact could not be saved",
						}),
					});
					reject(error);
				},
			})
		);
	};

	return (
		<div className="contact_attributes">
			<DashboardCard
				title="CONTACTS.DETAIL.ATTRIBUTES.TYPE.TITLE"
				description="CONTACTS.DETAIL.ATTRIBUTES.TYPE.DESCRIPTION"
			>
				{isLoading ? (
					<Spin tip="Loading...">
						<ContactType />
					</Spin>
				) : (
					<ContactType type={contact?.type} />
				)}
			</DashboardCard>
			<DashboardCard title="CONTACTS.DETAIL.ATTRIBUTES.BASE_VALUES.TITLE">
				{isLoading ? (
					<Spin spinning={isLoading} tip="Loading...">
						<ContactBaseValues values={{}} className="t-gap--top" />
					</Spin>
				) : (
					<ContactBaseValues values={contact} conflictContact={conflictContact} className="t-gap--top" />
				)}
			</DashboardCard>
			<DashboardCard title="CONTACTS.DETAIL.ATTRIBUTES.DETAIL.TITLE">
				{isLoading ? (
					<Spin tip="Loading...">
						<ContactDetail onChange={handleChange} className="t-gap--top" />
					</Spin>
				) : (
					<ContactDetail
						contact={contact || undefined}
						onChange={handleChange}
						conflictContact={conflictContact}
						className="t-gap--top"
					/>
				)}
			</DashboardCard>
			<DashboardCard title="CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.TITLE">
				{isLoading ? (
					<Spin tip="Loading...">
						<EmailAddress emailAddresses={[]} className="t-gap--top" />
					</Spin>
				) : (
					<EmailAddress emailAddresses={contact?.email} className="t-gap--top" />
				)}
			</DashboardCard>
			<DashboardCard title="CONTACTS.DETAIL.ATTRIBUTES.PHONE.TITLE">
				{isLoading ? (
					<Spin tip="Loading...">
						<PhoneNumbers phoneNumbers={[]} className="t-gap--top" />
					</Spin>
				) : (
					<PhoneNumbers phoneNumbers={contact?.phone} className="t-gap--top" />
				)}
			</DashboardCard>
			{contact && (
				<Collapse
					className="contact_attributes_collapse"
					expandIconPosition="end"
					defaultActiveKey={[]}
					accordion={false}
				>
					<ExternalLinks contact={contact} />
					<ContactAddresses />
					{contact.type !== "linkedContact" && <ContactLinking contact={contact} />}
					<BankAccounts contact={contact} onChange={handleChange} />
					<Properties contact={contact} onChange={handleChange} />
					<Insurances contact={contact} onChange={handleChange} />
					{/* <Crypto contact={contact} onChange={handleChange} /> */}
					<ChargingStation contact={contact} onChange={handleChange} />
				</Collapse>
			)}
		</div>
	);
}
