import { atom, selector } from "recoil";

import { stringHelpers } from "@/shared/helpers";

import config from "./config";
import { IReport } from "@bothive_core/database";

const defaultReportsMeta = {
	limit: config.fetchLimit,
	offset: 0,
	total: 0,
};

export const isFetchingReportsState = atom({
	key: "froIsFetchingReports",
	default: false,
});

export const reportsMeta = atom({
	key: "froReportsMeta",
	default: defaultReportsMeta,
});

export const reportsTablePageState = atom({
	key: "froReportsTablePage",
	default: 1,
});

// You might need to use the hook instead of using this atom directly
export const reportsList = atom<IReport[]>({
	key: "froReportsList",
	default: [],
});

export const isTableFiltered = selector({
	key: "froIsTableFiltered",
	get: ({ get }) => {
		const search = get(searchFilterState);
		const progress = get(selectedProgressState);
		const statusType = get(selectedStatusTypeState);
		const contactLabel = get(selectedContactLabelState);

		return !stringHelpers.isEmpty(search) || progress || statusType || contactLabel;
	},
});

export const selectedSortingState = atom({
	key: "froSelectedSortingState",
	default: undefined,
});

export const searchFilterState = atom({
	key: "froSearchFilter",
	default: undefined,
});

export const selectedStatusTypeState = atom({
	key: "froSelectedStatusTypeState",
	default: undefined,
});
export const selectedContactLabelState = atom({
	key: "froSelectedContactLabelState",
	default: undefined,
});
export const selectedProgressState = atom({
	key: "froSelectedProgressState",
	default: undefined,
});
export const selectedAccountManagerState = atom({
	key: "froSelectedAccountManagerState",
	default: undefined,
});

export const updateReportInList = selector({
	key: "frsUpdateReportInList",
	get: ({ get }) => get(reportsList),
	set: ({ get, set }, { reportId, payload }: any) => {
		const list = get(reportsList);
		const updatedList = list?.map((report) => (report?.id === reportId ? { ...report, ...payload } : report));

		set(reportsList, updatedList);
	},
});
export const removeReportFromList = selector<any>({
	key: "frsRemoveReportFromList",
	get: ({ get }) => get(reportsList),
	set: ({ get, set }, reportId: any) => {
		const meta = get(reportsMeta);
		const list = get(reportsList);

		set(reportsMeta, { ...meta, total: meta.total - 1 });
		set(
			reportsList,
			list.filter((report) => report.id !== reportId)
		);
	},
});

export const resetReportStateSelector = selector({
	key: "frsResetReportStateSelector",
	get: ({ get }) => get(reportsList),
	set: ({ set }) => {
		set(reportsList, []);
		set(selectedProgressState, undefined);
		set(searchFilterState, undefined);
		set(selectedSortingState, undefined);
		set(selectedStatusTypeState, undefined);
		set(selectedContactLabelState, undefined);
		set(reportsMeta, defaultReportsMeta);
	},
});
