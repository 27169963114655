import React from "react";
import { FormattedMessage } from "react-intl";

function AFieldSideLabel({ id, label, help, error, optional, type, className, bodyClassName, intl, children }) {
	let optionalText, helpText;

	if (help && help !== "") {
		helpText = (
			<p className="form--help-label small">
				<FormattedMessage id={help} />
			</p>
		);
	}

	if (optional) {
		optionalText = (
			<span className="form--optional">
				<FormattedMessage id="FORM_ELEMENT.LABEL.OPTIONAL" />
			</span>
		);
	}

	if (help && help !== "") {
		helpText = (
			<p className="form--help-label">
				<FormattedMessage id={help} />
			</p>
		);
	}

	if (error && error !== "") {
		const injectedLabel = label ? label : "FORM_ELEMENT.ERROR.THIS_FIELD";

		helpText = (
			<p className="form--help-label error small">
				<FormattedMessage id={error} values={{ label: intl.formatMessage({ id: injectedLabel }) }} />
			</p>
		);
	}

	return (
		<div className={`a-form-element a-form-element-side-label ${className}`}>
			<div className={`form--wrapper form--body ${type} ${bodyClassName}`}>
				<label htmlFor={id} className={`form--label ${error ? "error" : ""}`}>
					{label ? <FormattedMessage id={label} defaultMessage={label} /> : undefined}
					{optionalText}
				</label>
				{children}
			</div>
			{helpText && <div className="form--wrapper"> {helpText}</div>}
		</div>
	);
}

AFieldSideLabel.defaultProps = {
	label: undefined,
	value: "",
	optional: false,
	help: undefined,
	type: "left",
	className: "",
	bodyClassName: "",
	children: undefined,
};

export default AFieldSideLabel;
