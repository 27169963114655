import { MenuProps } from "antd";
import { Key, ReactNode } from "react";

export type MenuItem = Required<MenuProps>["items"][number];

export type FormattedMenuItem = {
	type?: string | undefined;
	children?: MenuItem[] | undefined;
	key: Key;
	icon: ReactNode;
	label: ReactNode;
};

function getItem(
	label: ReactNode,
	key: Key,
	icon?: ReactNode,
	children?: MenuItem[],
	type?: string
): FormattedMenuItem {
	return {
		key,
		icon,
		label,
		...(children && { children }),
		...(type && { type }),
	};
}

export { getItem };
