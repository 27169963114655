import "./style.scss";

import { CheckCircleOutlined, CloseCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Select, Tooltip, notification, Tag as AntdTag } from "antd";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";

import { Component } from "@/components/atoms";
import { Layout } from "@/components/templates";
import { timingConfig, typesConfig } from "@/config";
import { debounce } from "@/helpers";
import { Tag } from "@/shared/components/select";
import helpers from "../helpers";
import config from "./config";
import query from "./query";
import { trpc } from "../../../../trpc";

export default function DeveloperSettings({ className }: { className?: string } = { className: "" }) {
	const intl = useIntl();
	const [isFetching, setIsFetching] = useState(false);
	const [notificationSettings, setNotificationSettings] = useState({});
	const notificationTypes = useMemo(
		() =>
			typesConfig.notificationOptions.map((item) => ({
				...item,
				value: intl.formatMessage({ id: item.value }),
			})),
		[]
	);
	const { data: connections } = trpc.integration.getConnections?.useQuery({
		query: { category: "5c38a182092c99228ead1a32" },
	});

	const integrationNotificationTypes = useMemo(() => {
		// SHIT solution => notifications need to be completely redesigned
		if (!connections?.data?.length) return [];

		let list: { id: string; value: string }[] = [];

		if (connections?.data?.find((item) => item.integrationId === "5cdc7149e0f7a731c8b449ca")) {
			// admin pulse id
			list.push({ id: "adminPulse", value: "AdminPulse" });
		}

		return list;
	}, [connections?.data]);

	useEffect(() => {
		setIsFetching(true);
		query
			.fetchNotificationSettings()
			.then(({ data }) => {
				setNotificationSettings(data);
				setIsFetching(false);
			})
			.catch(() => setIsFetching(false));
	}, []);

	const handleChange = ({ fields, id }) => {
		debounce(() => {
			const payload = helpers.formatChangedFields(fields);

			query
				.updateNotificationSettings({ [id]: payload })
				.then(({ data }) => {
					setNotificationSettings(data);
					notification.success({
						message: intl.formatMessage({ id: config.banner.update.success }),
						placement: "bottomRight",
					});
				})
				.catch(() => {
					setIsFetching(false);
					notification.success({
						message: intl.formatMessage({ id: config.banner.update.failed }),
						placement: "bottomRight",
					});
				});
		}, timingConfig.keyboardTimeout);
	};

	if (isFetching) return <Layout.TLoadScreen className="small-loader" />;

	return (
		<>
			{/* Browser notifications */}
			<Component.ADashboardCard
				key="browser-notifications"
				title={intl.formatMessage({
					id: "settings.notifications.browser_notifications.title",
					defaultMessage: "Browser notifications",
				})}
				description={intl.formatMessage({
					id: "settings.notifications.browser_notifications.description",
					defaultMessage:
						"Manage website notifications effortlessly by adjusting your browser settings. Simply find the 'Notifications' section in your browser preferences to customize your experience.",
					description:
						"Text that explains the working of browser notifications to the end user and how to change them",
				})}
				className={clsx(`page-notification-settings-user`, className)}
			>
				<Tooltip
					placement="bottomRight"
					title={intl.formatMessage({ id: "SETTINGS.NOTIFICATIONS.SETTINGS.CARD.TOOLTIP" })}
				>
					<div className="page-notification-settings-user--icon">
						<UserOutlined />
					</div>
				</Tooltip>
				{Notification.permission === "granted" ? (
					<AntdTag color="success" icon={<CheckCircleOutlined />}>
						<FormattedMessage
							id="settings.notifications.browser_notifications.state.access"
							defaultMessage="Access granted"
						/>
					</AntdTag>
				) : (
					<AntdTag color="error" icon={<CloseCircleOutlined />}>
						<FormattedMessage
							id="settings.notifications.browser_notifications.state.no_access"
							defaultMessage="No access"
						/>
					</AntdTag>
				)}
			</Component.ADashboardCard>
			{Object.values(config.sections).map((section) => (
				<Component.ADashboardCard
					key={section.id}
					title={section.title}
					description={section.description}
					className={clsx(`page-notification-settings-user`, className)}
				>
					<Tooltip placement="bottomRight" title={intl.formatMessage({ id: section.tooltip })}>
						<div className="page-notification-settings-user--icon">
							<UserOutlined />
						</div>
					</Tooltip>
					<Form
						autoComplete="off"
						layout="vertical"
						onFieldsChange={(fields) => handleChange({ fields, id: section.id })}
						initialValues={notificationSettings?.[section.id]}
					>
						{Object.keys(section.fieldConfig).map((key) => (
							<Form.Item
								key={key}
								name={key}
								className="page-notification-settings-user--form-item"
								label={<FormattedMessage id={section.fieldConfig[key].label} />}
							>
								<Select
									mode="multiple"
									tagRender={Tag}
									getPopupContainer={(trigger) => trigger.parentNode}
								>
									{notificationTypes.map(({ id, value }) => (
										<Select.Option
											key={id}
											value={id}
											className="page-notification-settings-user--select-option"
										>
											{value}
										</Select.Option>
									))}
									{section.includeIntegrations &&
										integrationNotificationTypes.map(({ id, value }) => (
											<Select.Option
												key={id}
												value={id}
												className="page-notification-settings-user--select-option"
											>
												{value}
											</Select.Option>
										))}
								</Select>
							</Form.Item>
						))}
					</Form>
				</Component.ADashboardCard>
			))}
		</>
	);
}
