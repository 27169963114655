import { useSetRecoilState } from "recoil";

import { StepsTypes } from "../types";
import FilterImport from "./FilterImport";
import ReviewImport from "./ReviewImport";
import { importConfigState } from "./state";

type ImportStepsParams = { step?: StepsTypes; onChange: (step: StepsTypes) => void; onClose: () => void };

export default function ImportSteps({ step, onChange, onClose }: ImportStepsParams) {
	const setImportConfig = useSetRecoilState(importConfigState);

	const handleClose = () => {
		onClose();
		setImportConfig({ type: "2", allowCreate: true, allowUpdate: true });
	};

	switch (step) {
		case "REVIEW_IMPORT":
			return <ReviewImport onClose={handleClose} onBack={(step) => onChange(step || "GET_DATA")} />;
		case "GET_DATA":
		default:
			return <FilterImport onClose={handleClose} onNext={() => onChange("REVIEW_IMPORT")} onBack={handleClose} />;
	}
}
