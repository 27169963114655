import get from "lodash.get";

export default {
	cleanUrl(item) {
		const stripedUrl = item.url.replace(/(https:|http:)\/\//, "");
		const cleanUrl = stripedUrl.substring(stripedUrl.indexOf("/") + 1);

		return cleanUrl || stripedUrl;
	},
	randomUuid(length = 12) {
		const values = "abcdefghijklmonpqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-_+";

		let token = "";

		for (let i = 0; i < length; i++) {
			token += values.substr(Math.floor(Math.random() * values.length), 1);
		}

		return token;
	},
	capitalizeFirstLetter(string) {
		if (!string) return string;

		return string.charAt(0).toUpperCase() + string.slice(1);
	},
	formatNamedList(item) {
		let response = item ? item[0] : "";

		if (item?.length > 1) {
			const list = item.join(", ");
			const pos = list.lastIndexOf(",");

			response = `${list.substring(0, pos)} en ${list.substring(pos + 1)}`;
		}

		return response;
	},
	isEmpty: (string) => !string || string === "" || /^(\s+$)/.test(string),
	getValueLength(value) {
		if (typeof value === "string") return stripTemplate(value).length;

		if (value[0]) {
			if (value[0].type && value[0].children) {
				return value.reduce((prev, curr) => {
					if (curr.type === "text") {
						return (
							prev +
							curr.children.reduce((prev, curr) => (curr.type ? prev + 5 : prev + curr.text.length), 0)
						);
					}

					return prev + 5;
				}, 0);
			}
		}

		return 1;
	},
	stringifyComplexString({ text, team }) {
		if (!text) return "";
		if (typeof text === "string") return text;

		const mergedString = text.reduce((prev, curr) => {
			const value = curr.children.reduce(
				(prev, child) => `${prev}${child.text !== undefined ? child.text : child.value}`,
				""
			);

			return `${prev}${value}`;
		}, "");

		return replaceTemplates({
			string: mergedString,
			payload: { organization: team },
		});
	},
	stripTemplate,
	formatPayload,
	replaceTemplates,
	isUrl(value) {
		if (!value) return false;

		const pattern = new RegExp("^(http|https|file):\\/\\/?"); //eslint-disable-line max-len

		return pattern.test(value);
	},
	isEmail(value) {
		if (!value) return false;

		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
	},
	formatForwardingSubject(subject) {
		const prefix = "Fwd: ";

		if (!subject) return "";
		if (subject.startsWith(prefix)) return subject;

		return `${prefix}${subject}`;
	},
	formatForwardingContent(content) {
		const prefix = "<br /><br /><br />---------- Forwarded message ---------<br /><br />";

		return `${prefix}${content}`;
	},
	isValidHex(value) {
		return /^#[0-9A-F]{6}$/i.test(value);
	},
	hasSymbols(value) {
		return /[^a-zA-Z0-9-9À-ÿ ]+/i.test(value);
	},
	hasNumbers(value) {
		return /[0-9]+/i.test(value);
	},
	isEnterpriseNumberValid: async (value) => {
		const cleanValue = value.replace(/ |\.|-|,|, /g, "");
		const expression =
			/^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/gim; //eslint-disable-line max-len

		if (!expression.test(cleanValue)) return { valid: false };

		try {
			const response = await fetch(`https://controleerbtwnummer.eu/api/validate/${cleanValue}.json`);
			const jsonData = await response.json();

			return jsonData;
		} catch (_) {
			return { valid: false };
		}
	},
	stripMarkdown: (text: string) => {
		return (
			text
				// replace with space
				.replace(
					/ \*|\* | \_|\_ | \_\_|\_\_ | ~|~ | \_\*|\*\_ | \_\_\*|\*\_\_ | \_~|~\_ | ~\_|\_~ | ~\*|\*~ /g,
					" "
				)
				// replace without space
				.replace(/<([^\<\|]+)\||>|</g, "")
		);
	},
	findAllMentionedUsers(string: string) {
		//regex: capture user id from mrkdwn_2 mention tag ex: <@userId|username> -> userId
		const result = string.match(/<@([^\s][a-zA-Z0-9\-\_\.\[\]]*)|!([a-zA-Z0-9\-\_\.\[\]]*)![^\s]>/g); //eslint-disable-line no-useless-escape

		if (!result) {
			return [];
		}

		//Capture groups with /g doesn't remove prefix
		return result.map((userId) => userId.replace(/<@/g, ""));
	},
	truncateString({ string, maxLength = 25 }: { string: string; maxLength?: number }) {
		if (string.length <= maxLength) {
			return string;
		}

		return string.slice(0, maxLength - 3) + "...";
	},
};

function stripTemplate(string) {
	if (!string) return "";

	return string.replace(/{{(organization|user).[a-zA-Z0-9\-_.[\]]*}}/g, (_, key) => key);
}
function replaceTemplates({ string, payload }) {
	if (!string) return "";

	const _payload = formatPayload(payload);

	return string.replace(
		/{{(organization|user).([a-zA-Z0-9\-_.[\]]*)}}/g,
		(
			value,
			key,
			subKey //eslint-disable-line no-useless-escape
		) => get(_payload[key], subKey, value)
	);
}

function formatPayload(payload) {
	// TODO: add correct typing instead of any
	const formatted = JSON.parse(JSON.stringify(payload)) as any;

	if (formatted.organization) {
		formatted.organization.locations = formatted.organization.locations.map((location) => ({
			...location,
			companyNumber: formatCompanyNumber(location.companyNumber),
		}));

		if (formatted.organization?.billing?.address?.companyNumber) {
			formatted.organization.billing.address.companyNumber = formatCompanyNumber(
				formatted.organization.billing?.address?.companyNumber
			);
		}
	}

	return formatted;
}
function formatCompanyNumber(companyNumber) {
	let number = companyNumber.replace(/\./g, "");

	return `${number.slice(0, 4)}.${number.slice(4, 7)}.${number.slice(7)}`;
}

export function normalizeEmptyToUndefined(value: string) {
	if (value === "") {
		return undefined;
	}

	return value;
}
