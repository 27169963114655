import "./style.scss";

import React from "react";
import { FormattedMessage } from "react-intl";

export default function FieldSideLabel({
	id,
	label,
	help,
	error,
	optional,
	type,
	className,
	bodyClassName,
	intl,
	children,
	allowIntl = true,
	labelValue = {},
	style = {},
}) {
	let optionalText, helpText;

	if (help && help !== "") {
		helpText = (
			<p className="form--help-label small">
				{!allowIntl && help}
				{allowIntl && <FormattedMessage id={help} values={labelValue} />}
			</p>
		);
	}

	if (optional) {
		optionalText = (
			<span className="form--optional">
				<FormattedMessage id="FORM_ELEMENT.LABEL.OPTIONAL" />
			</span>
		);
	}

	if (help && help !== "") {
		helpText = (
			<p className="form--help-label">
				{!allowIntl && help}
				{allowIntl && <FormattedMessage id={help} values={labelValue} />}
			</p>
		);
	}

	if (error && error !== "") {
		const injectedLabel = label ? label : "FORM_ELEMENT.ERROR.THIS_FIELD";

		helpText = (
			<p className="form--help-label error small">
				{!allowIntl && error}
				{allowIntl && (
					<FormattedMessage
						id={error}
						values={{ ...labelValue, label: intl.formatMessage({ id: injectedLabel }) }}
					/>
				)}
			</p>
		);
	}

	return (
		<div className={`form-element field-side-label ${className}`} style={style}>
			<div className={`form--wrapper form--body ${type} ${bodyClassName}`}>
				<label htmlFor={id} className={`form--label ${error ? "error" : ""}`}>
					{!allowIntl && label}
					{allowIntl && label && <FormattedMessage id={label} defaultMessage={label} />}
					{optionalText}
				</label>
				{children}
			</div>
			{helpText && <div className="form--wrapper">{helpText}</div>}
		</div>
	);
}
