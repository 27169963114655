import type {
	DOMConversionMap,
	DOMConversionOutput,
	DOMExportOutput,
	EditorConfig,
	LexicalNode,
	NodeKey,
	SerializedLexicalNode,
	Spread,
} from "lexical";

import { DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

const ThreadComponent = React.lazy(
	// @ts-ignore
	() => import("./threadComponent")
);

interface IThreadNode {
	content: string;
	headersIncluded?: boolean; // Prevent infinite loop
	subject?: string;
	sendOn?: string;
	from?: string;
	to?: string;
}

export type SerializedThreadNode = Spread<IThreadNode, SerializedLexicalNode>;

function convertThreadElement(domNode: HTMLElement): DOMConversionOutput | null {
	const content = domNode.innerHTML;

	const subject = domNode.getAttribute("data-subject") || undefined;
	const sendOn = domNode.getAttribute("data-send-on") || undefined;
	const from = domNode.getAttribute("data-from") || undefined;
	const to = domNode.getAttribute("data-to") || undefined;
	const headersIncluded = domNode.getAttribute("data-headers-included") === "true";

	if (content === null) return null;

	const node = $createThreadNode({ content, subject, sendOn, from, to, headersIncluded });

	return { node };
}

export class ThreadNode extends DecoratorNode<JSX.Element> {
	__headersIncluded = false;
	__content: string;
	__subject?: string;
	__sendOn?: string;
	__to?: string;
	__from?: string;

	static getType(): string {
		return "thread";
	}

	static clone(node: ThreadNode): ThreadNode {
		return new ThreadNode({
			headersIncluded: node.__headersIncluded,
			content: node.__content,
			subject: node.__subject,
			sendOn: node.__sendOn,
			from: node.__from,
			to: node.__to,
		});
	}

	static importJSON(serializedNode: SerializedThreadNode): ThreadNode {
		return new ThreadNode({ content: serializedNode.content });
	}

	exportJSON(): SerializedThreadNode {
		return {
			version: 1,
			type: "thread",
			content: this.__content,
			subject: this.__subject,
			sendOn: this.__sendOn,
			from: this.__from,
			to: this.__to,
		};
	}

	constructor({ key, ...params }: IThreadNode & { key?: NodeKey }) {
		super(key);
		this.__headersIncluded = params.headersIncluded || false;
		this.__content = params.content;
		this.__subject = params.subject;
		this.__sendOn = params.sendOn;
		this.__from = params.from;
		this.__to = params.to;
	}

	// View
	createDOM(config: EditorConfig): HTMLElement {
		const div = document.createElement("div");

		div.className = `editor-thread_node-span ${config.theme.thread?.node || ""}`;

		return div;
	}

	setContent({ content, headersIncluded }: { content: string; headersIncluded: boolean }): void {
		const self = this.getWritable();
		self.__content = content;
		self.__headersIncluded = headersIncluded;
	}

	updateDOM(): false {
		return false;
	}

	isInline(): false {
		return false;
	}

	static importDOM(): DOMConversionMap<HTMLDivElement> | null {
		return {
			blockquote: (domNode: HTMLDivElement) => {
				if (!domNode.hasAttribute("data-lexical-thread")) return null;

				return {
					conversion: convertThreadElement,
					priority: 2,
				};
			},
			div: (domNode: HTMLDivElement) => {
				if (!domNode.hasAttribute("data-lexical-thread")) return null;

				return {
					conversion: convertThreadElement,
					priority: 2,
				};
			},
		};
	}

	exportDOM(): DOMExportOutput {
		const element = document.createElement("blockquote"); // blockquote is used to thread in most email clients

		if (!this.__content) return { element };

		element.setAttribute("type", "cite");
		element.setAttribute("data-lexical-thread", "true");
		if (this.__subject) element.setAttribute("data-subject", this.__subject);
		if (this.__sendOn) element.setAttribute("data-send-on", this.__sendOn);
		if (this.__to) element.setAttribute("data-to", this.__to);
		if (this.__from) element.setAttribute("data-from", this.__from);
		if (this.__from) element.setAttribute("data-headers-included", `${this.__headersIncluded}`);
		element.innerHTML = this.__content;

		return { element };
	}

	decorate(_, config: EditorConfig): JSX.Element {
		return (
			<Suspense fallback={null}>
				<ThreadComponent
					theme={config.theme}
					nodeKey={this.getKey()}
					headersIncluded={this.__headersIncluded}
					content={this.__content}
					subject={this.__subject}
					sendOn={this.__sendOn}
					from={this.__from}
					to={this.__to}
				/>
			</Suspense>
		);
	}
}

export function $createThreadNode({ content, subject, sendOn, from, to, headersIncluded }: IThreadNode): ThreadNode {
	return new ThreadNode({ content, subject, sendOn, from, to, headersIncluded });
}

export function $isThreadNode(node: LexicalNode | null): node is ThreadNode {
	return node instanceof ThreadNode;
}
