import ReportDetailHeader from "@/flows/report_detail/report_header";
import ReportDetailViewer from "@/flows/report_detail/viewer";

import MetaAside from "./meta_aside";
import ReportList from "./report_list";
import style from "./style.module.scss";
import ViewerFooter from "./viewer/footer";

export default function ReportDetail() {
	return (
		<main className={style.report_detail}>
			<ReportList />
			<div className={style.report_detail_content}>
				<ReportDetailHeader />
				<ReportDetailViewer />
				<ViewerFooter />
			</div>
			<MetaAside />
		</main>
	);
}
