import { isSameDay } from "date-fns";

import typesConfig from "./types.config";

export default {
	color: {
		primary: "#002140",
		primaryLight: "#CCD3D9",
		primaryShaded: "#7F909F",
		white: "#ffffff",
	},
	font: {
		default: "Open Sans",
	},
	dataPreview: {
		templateFailed: "MOLECULES.DATA_PREVIEW.PDF.TEMPLATE_FAILED",
	},
	emailPreview: {
		attachments: {
			title: "EMAIL_PREVIEW.ATTACHMENTS.HEADER.TITLE",
			buttonLabel: "EMAIL_PREVIEW.ATTACHMENTS.HEADER.BUTTON",
		},
	},
	emojiPicker: {
		set: "Apple",
	},
	linkModal: {
		header: {
			title: "LINK_POPUP.HEADER.TITLE",
		},
		fields: {
			textField: {
				id: "text",
				label: "LINK_POPUP.FORM.TEXT.LABEL",
			},
			linkField: {
				id: "link",
				label: "LINK_POPUP.FORM.LINK.LABEL",
			},
		},
	},
	mainAside: {
		containerClass: () => "a-dropdown--aside-wrapper",
		// listItemClass: () => "dropdown-list",
		headerClass: () => "dropdown-header",
	},
	mapFields: {
		custom: {
			id: "custom",
			uuid: "custom",
			value: "FORM_ELEMENT.MAP_FIELD.CUSTOM.LABEL",
		},
	},
	reportFilter: {
		status: {
			id: "statusDropdown",
			label: "FLOW_DETAIL.REPORT.FILTER.CONTROL.STATUS.LABEL",
		},
		progress: {
			id: "progressDropdown",
			label: "FLOW_DETAIL.REPORT.FILTER.CONTROL.PROGRESS.LABEL",
			types: [
				{
					id: typesConfig.sessionState.created,
					value: "CREATED",
				},
				{
					id: typesConfig.sessionState.inviteSend,
					value: "INVITE_SEND",
				},
				{
					id: typesConfig.sessionState.inProgress,
					value: "IN_PROGRESS",
				},
				{
					id: typesConfig.sessionState.completed,
					value: "COMPLETED",
				},
			],
		},
		search: {
			id: "search",
			label: "FLOW_DETAIL.REPORT.FILTER.SEARCH.LABEL",
			placeholder: "FLOW_DETAIL.REPORT.FILTER.SEARCH.PLACEHOLDER",
		},
	},
};
