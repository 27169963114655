import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript";
import "codemirror/addon/lint/lint";
import "./style.scss";

import { UnControlled } from "react-codemirror2-react-17";

import style from "./style.module.scss";

export default function JsonViewer({ data, className, onChange }) {
	return (
		<div className={`${style.preview_json} ${className}`}>
			<UnControlled
				value={JSON.stringify(data, null, 2)}
				options={{
					mode: {
						name: "javascript",
						json: true,
					},
					lint: true,
					gutters: [],
					autoCloseBrackets: true,
					lineWrapping: true,
					lineNumbers: true,
					readOnly: false,
				}}
				autoCursor={false}
				onChange={(_, editor, data) => onChange(data)}
				className="frdvj_code_mirror"
			/>
		</div>
	);
}
