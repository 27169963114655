import React from "react";
import { FormattedMessage } from "react-intl";

export default function ADefaultTooltip({ message, formattedValues, className }) {
	return (
		<div className={`a-tooltip ${className}`}>
			<p>
				<FormattedMessage id={message} values={formattedValues} />
			</p>
		</div>
	);
}

ADefaultTooltip.defaultProps = {
	formattedValues: undefined,
	className: "",
};
