/*eslint-disable max-len*/
export default {
	// NUMBER FORMATTING
	"NUMBER.ORDINAL.ST": "st",
	"NUMBER.ORDINAL.ND": "nd",
	"NUMBER.ORDINAL.RD": "rd",
	"NUMBER.ORDINAL.TH": "th",

	"ACCORDION.DETAIL_TOGGLE.OFF": "Deactivated",
	"ACCORDION.DETAIL_TOGGLE.ON": "Active",

	"ASSIGNMENT.UNASSIGNED.LABEL": "Unassigned",

	"CHAT_INTERNAL_WRAPPER.CHIP.INTERNAL": "Internal",

	"UTILITY.TIME": "{hour}u {minute}m {seconds}s",

	"BUTTON.DOWNLOAD.LABEL": "Download",

	//BREADCRUMBS
	"BREADCRUMB.SETTINGS": "Settings",

	//ASIDE
	"ASIDE.DROPDOWN.MY_TEAMS": "My teams",
	"ASIDE.DROPDOWN.ADD_TEAM": "Create new team",
	"ASIDE.LABELS.DASHBOARD": "Dashboard",
	"ASIDE.LABELS.BILLING": "Billing",
	"ASIDE.LABELS.FLOWS": "Flows",
	"ASIDE.LABELS.INBOX": "Inbox (BETA)",
	"ASIDE.LABELS.CONTACT": "Contacts",
	"ASIDE.LABELS.DOCUMENTATION": "Documentation",
	"ASIDE.LABELS.SUPPORT": "Support",
	"ASIDE.LABELS.SETTINGS": "Settings",
	"ASIDE.LABELS.LOGOUT": "Logout",

	"ASIDE.PROFILE.LABELS.PROFILE": "Profile",
	"ASIDE.PROFILE.LABELS.TEAMS": "Teams",
	"ASIDE.PROFILE.LABELS.ADVANCED_SETTINGS": "Settings",

	"ASIDE.INBOX.MAIN_INBOX.HEADER.LABEL": "Inbox",
	"ASIDE.INBOX.MAIN_INBOX.PERSONAL.TITLE": "Personal",
	"ASIDE.INBOX.MAIN_INBOX.PERSONAL.INBOX.TITLE": "Inbox",
	"ASIDE.INBOX.MAIN_INBOX.PERSONAL.MY_CONVERSATIONS.LABEL": "My conversations",
	"ASIDE.INBOX.MAIN_INBOX.PERSONAL.ASSIGNED_TO_ME.LABEL": "Assigned to me",
	"ASIDE.INBOX.MAIN_INBOX.PERSONAL.SHARED_WITH_ME.LABEL": "Shared with me",
	"ASIDE.INBOX.MAIN_INBOX.PERSONAL.MENTIONS.LABEL": "Mentioned",
	"ASIDE.INBOX.MAIN_INBOX.PERSONAL.ALL_CONVERSATIONS.LABEL": "All conversations",
	"ASIDE.INBOX.MAIN_INBOX.PERSONAL.OUTBOX.LABEL": "Outbox",
	"ASIDE.INBOX.MAIN_INBOX.PERSONAL.FOLDERS.TITLE": "Folders",
	"ASIDE.INBOX.MAIN_INBOX.FOLDERS.DRAFTS.LABEL": "Drafts",
	"ASIDE.INBOX.MAIN_INBOX.FOLDERS.DELEGATED.LABEL": "Assigned by me",
	"ASIDE.INBOX.MAIN_INBOX.FOLDERS.SEND.LABEL": "Send",
	"ASIDE.INBOX.TEAMS_INBOX.TEAMS.TITLE": "Teams",
	"ASIDE.INBOX.SETTINGS.LABEL": "Inbox settigns",

	"ASIDE.INBOX.MOBILE.BACK_TO_CONVERSATIONS_LIST": "Back to all conversations",

	"ASIDE.INBOX.CHATBOT.TITLE": "Chatbot",

	"ASIDE.INBOX.SHOW_ALL.LABEL": "Show all",
	"ASIDE.INBOX.SHOW_LESS.LABEL": "Show less",

	"STATUS.INBOX.ALL.LABEL": "all",
	"STATUS.INBOX.OPEN.LABEL": "open",
	"STATUS.INBOX.CLOSED.LABEL": "closed",
	"STATUS.INBOX.SNOOZED.LABEL": "snoozed",
	"STATUS.INBOX.SPAM.LABEL": "spam",
	"STATUS.INBOX.TRASH.LABEL": "trash",

	"INBOX.CONVERSATION_LIST.SUB_HEADER.EMPTY_TRASH": "Empty trash",

	"INBOX.CONVERSATION_LIST.FALLBACK.INBOX": "Your inbox is empty 🎉",
	"INBOX.CONVERSATION_LIST.FALLBACK.SEARCH": "Could not find any conversations matching that search term",
	"INBOX.CONVERSATION_LIST.FALLBACK.LABEL": "No conversations found in {label}",
	"INBOX.CONVERSATION_LIST.FALLBACK.CHANNEL": "Channel has no conversations",
	"INBOX.CONVERSATION_LIST.FALLBACK.MEMBER_INBOX": "{memberName}'s inbox is empty",

	"INBOX.CONVERSATION_LIST.LIST_ITEM.MESSAGE_NOT_SEND": "Message failed to sent",

	"INBOX.CONVERSATION.EMPTY_STATE.LABEL": "Select a conversation to get started",

	"INBOX.CONVERSATION.INBOX_INTRO.CONNECT_CHANNEL.TITLE": "Connect your email channel",
	"INBOX.CONVERSATION.INBOX_INTRO.CONNECT_CHANNEL.DESCRIPTION": "Connect email channels with your inbox.",
	"INBOX.CONVERSATION.INBOX_INTRO.CONNECT_CHANNEL.FOOTER": "You can manage your channels in {link}",
	"INBOX.CONVERSATION.INBOX_INTRO.CONNECT_CHANNEL.FOOTER.LINK": "settings > channels",

	//ASSIGNEE_POPUP
	"ASSIGNEE_POPUP.HEADER.TITLE": "Assign member",
	"ASSIGNEE_POPUP.HEADER.SUB_TITLE": "Team members",
	"ASSIGNEE_POPUP.SEARCH.PLACEHOLDER": "Search on name",
	"ASSIGNEE_POPUP.ASSIGNEES.TITLE": "Assignee's",
	"ASSIGNEE_POPUP.USERNAME.ME": "ME",
	"ASSIGNEE_POPUP.NOT_ASSIGNED": "Not assigned",

	"ASSIGNEE_POPUP.ERROR.TITLE": "Error while assigning user",
	"ASSIGNEE_POPUP.ERROR.DESCRIPTION": "The conversation could not be assigned to user.",

	// PARTICIPANT_POPUP
	"PARTICIPANT_POPUP.HEADER.TITLE": "Participants",
	"PARTICIPANT_POPUP.SCOPE.SINGLE.TITLE": "Only visible for {name}.",
	"PARTICIPANT_POPUP.SCOPE.MULTIPLE.TITLE": "Visible for {name} & {otherCount} others.",

	"PARTICIPANT_POPUP.STATUS.READ": "Read",
	"PARTICIPANT_POPUP.STATUS.UN_READ": "Unread",
	"PARTICIPANT_POPUP.STATUS.CLOSED": "Closed",

	"PARTICIPANT_POPUP.ERROR.TITLE": "Error while adding participant.",
	"PARTICIPANT_POPUP.ERROR.DESCRIPTION": "Could not add participant.",

	"PARTICIPANT_POPUP.ACTION.ADD.ERROR.TITLE": "Error while adding participant.",
	"PARTICIPANT_POPUP.ACTION.ADD.ERROR.DESCRIPTION": "Could not add participant.",

	"PARTICIPANT_POPUP.ACTION.DELETE.ERROR.TITLE": "Error while deleting participant.",
	"PARTICIPANT_POPUP.ACTION.DELETE.ERROR.DESCRIPTION": "Could not delete participant.",

	"PARTICIPANT_POPUP.PARTICIPANT.ACTION.DELETE.OWNER.NOT_ALLOWED": "Can't delete receiver.",
	"PARTICIPANT_POPUP.PARTICIPANT.ACTION.DELETE.ASSIGNEE.NOT_ALLOWED": "Can't delete assignee.",

	//TEAM_MEMBER_POPUP

	"TEAM_MEMBER_POPUP.NOT_FOUND.TITLE": "No team members found.",
	"TEAM_MEMBER_POPUP.ERROR.TITLE": "Error while loading teammembers.",

	//AUTH_REDIRECT
	"AUTH_REDIRECT.FALLBACK.TITLE": "Looks like something went wrong",
	"AUTH_REDIRECT.FALLBACK.DESCRIPTION":
		"Something went wrong with the authorization.\nclose this page and try again, maybe your password wasn't correct.",

	//BANNER
	//BANNER ATTACHMENT
	"BANNER.FAILED.UPLOAD_ATTACHMENT.FILE_SIZE": "Cannot upload attachments bigger than 10MB.",
	"BANNER.FAILED.UPLOAD_ATTACHMENT": "Failed to upload attachment. Please try again later",
	//BANNER API_KEY
	"BANNER.SUCCEEDED.CREATE_API_KEY": "API key is successfully created.",
	"BANNER.FAILED.CREATE_API_KEY": "API key could not be created. Please try again later.",
	"BANNER.SUCCEEDED.UPDATE_API_KEY": "API key is successfully updated.",
	"BANNER.FAILED.UPDATE_API_KEY": "API key could not be updated. Please try again later.",
	"BANNER.SUCCEEDED.DELETE_API_KEY": "API key is successfully removed.",
	"BANNER.FAILED.DELETE_API_KEY": "API key could not be removed. Please try again later.",
	//BANNER BOT
	"BANNER.SUCCEEDED.CHANGE_BOT": "The changes have been saved successfully",
	"BANNER.FAILED.CHANGE_BOT": "The changes couldn't be saved. Please try again later",
	//BANNER CANCEL_SUBSCRIPTION
	"BANNER.SUCCEEDED.CANCEL_SUBSCRIPTION": "Your subscription has been canceled successfully",
	"BANNER.FAILED.CANCEL_SUBSCRIPTION": "Your subscription couldn't be canceled. Please try again later",
	//BANNER COPIED_TO_CLIPBOARD
	"BANNER.SUCCEEDED.COPIED_TO_CLIPBOARD": "Successfully copied to clipboard",
	//BANNER CONTACT_WEB_DEVELOPER
	"BANNER.SUCCEEDED.CONTACT_WEB_DEVELOPER": "Email is successfully send to your web developer.",
	"BANNER.FAILED.CONTACT_WEB_DEVELOPER": "Email couldn't be send to your web developer. Please try again later.",
	//BANNER COOKIE_CONCENT
	"BANNER.COOKIE_CONCENT.BODY":
		"By logging in to the website you agree to the use of cookies and our terms and conditions.",
	"BANNER.COOKIE_CONCENT.LINK": "Read our terms and conditions",
	//BANNER DELETE_CONTACT
	"BANNER.SUCCEEDED.DELETE_CONTACT": "Contact is successfully deleted",
	"BANNER.FAILED.DELETE_CONTACT": "Could not delete contact. Please try again later.",
	//BANNER DELETE_CONTACT_BATCH
	"BANNER.SUCCEEDED.DELETE_CONTACT_BATCH": "Contacts are successfully deleted",
	"BANNER.FAILED.DELETE_CONTACT_BATCH": "Could not delete contacts. Please try again later.",
	//BANNER DELETE_ALL_CONTACTS
	"BANNER.SUCCEEDED.DELETE_ALL_CONTACTS": "All contacts are successfully deleted",
	"BANNER.FAILED.DELETE_ALL_CONTACTS": "Could not delete all contacts. Please try again later.",
	//BANNER UPDATE_NOTIFICATION_SETTINGS
	"BANNER.SUCCEEDED.UPDATE_NOTIFICATION_SETTINGS": "You have successfully updated your notification settings",
	"BANNER.FAILED.UPDATE_NOTIFICATION_SETTINGS": "You could not update your notification settings. Please try again",
	//BANNER FILE
	"BANNER.FAILED.FILE_IS_TO_LARGE": "Upload failed. Image size can't be larger than 10MB",
	"BANNER.FAILED.FILE_TYPE_NOT_SUPPORTED": "Upload failed. File type is not supported. Only {types} are supported.",
	"BANNER.FAILED.ATTACHMENT_IS_TO_LARGE": "Upload failed. Attachment can't be larger than 5MB",
	"BANNER.FAILED.ATTACHMENT_LIMIT_REACHED": "Upload failed. You can only upload 20MB of attachments.",
	"BANNER.FAILED.ATTACHMENTS_AMOUNT_REACHED":
		"You can upload max {amount}} attachments. {freeAmount, plural, =0 {You don't have any space left.} one {You can upload one extra attachment.} other {You can upload # extra attachments.}}",
	"BANNER.FAILED.ATTACHMENT_DELETE": "Something went wrong while deleting the attachment.",
	//BANNER INBOX
	"BANNER.FAILED.INBOX.AS_UNSEEN": "Could not mark conversation as unseen. Please try again later.",
	"BANNER.FAILED.INBOX.SEEN": "Could not mark conversation as seen. Please try again later.",
	"BANNER.FAILED.INBOX.AS_SPAM": "Could not mark conversation as SPAM. Please try again later.",
	"BANNER.FAILED.INBOX.UN_SPAM": "Could not un mark conversation as SPAM. Please try again later.",
	"BANNER.FAILED.INBOX.MARK_AS_ARCHIVED": "Could not mark conversation as archive. Please try again later.",
	"BANNER.FAILED.INBOX.UN_ARCHIVE": "Could not un mark conversation as archived. Please try again later.",
	"BANNER.FAILED.INBOX.CLOSE_CONVERSATION": "Could not close the conversation. Please try again later.",
	"BANNER.FAILED.INBOX.OPEN_CONVERSATION": "Could not open the conversation. Please try again later.",
	"BANNER.FAILED.INBOX.TRASH_CONVERSATION": "Could not move conversation to trash. Please try again later.",
	"BANNER.SUCCEEDED.INBOX.DRAFT_EMAIL.SEND": "E-mail send",
	"BANNER.SUCCEEDED.INBOX.NEW_EMAIL.SEND": "New e-mail send",
	"BANNER.SUCCEEDED.INBOX.DRAFT_SAVED": "Draft changes saved",

	"BANNER.FAILED.INBOX.ADD_LABEL": "Label could not be added to conversation. Please try again later.",
	"BANNER.FAILED.INBOX.DELETE_LABEL": "Label could not be removed from conversation. Please try again later.",

	"BANNER.SUCCESS.EMPTY_CONVERSATIONS": "Conversations are deleted",
	"BANNER.FAILED.EMPTY_CONVERSATIONS": "Could not delete you conversations. Please try again later.",
	//BANNER INTENT
	"BANNER.FAILED.INTENT_UPDATED": "Updating intent failed. Please try again later.",
	"BANNER.SUCCEEDED.INTENT_UPDATED": "Intent updated successfully.",
	"BANNER.FAILED.INTENT_RESET": "Resetting intent failed. Please try again later.",
	"BANNER.SUCCEEDED.INTENT_RESET": "Intent was successfully reset.",
	//BANNER DOWNLOAD_CONVERSATION_ATTACHMENT
	"BANNER.FAILED.DOWNLOAD_CONVERSATION_ATTACHMENT": "Downloading the attachment failed. Please try again later.",
	"BANNER.PROCESSING.DOWNLOAD_CONVERSATION_ATTACHMENT": "Downloading attachment",
	"BANNER.FAILED.DOWNLOAD_ALL_CONVERSATION_ATTACHMENTS":
		"Downloading all the attachment failed. Please try again later.",
	"BANNER.PROCESSING.DOWNLOAD_ALL_CONVERSATION_ATTACHMENTS": "Downloading all attachments",
	//BANNER PAYMENT
	"BANNER.FAILED.PAYMENT":
		"Your credit card could not be processed. Try again or use another card. If you keep expiring any problems please contact our customer service.",
	//BANNER QUESTION_SETTINGS
	"BANNER.SUCCEEDED.QUESTION_SETTINGS_UPDATE": "The changes have been saved successfully",
	"BANNER.FAILED.QUESTION_SETTINGS_UPDATE": "The changes couldn't be saved. Please try again later",
	//BANNER RESET_PASSWORD
	"BANNER.SUCCEEDED.RESEND_RESET_PASSWORD_MAIL": "An email has been sent to change your password",
	"BANNER.FAILED.RESEND_RESET_PASSWORD_MAIL":
		"We couldn't send an email to change your password. Please try again later",
	"BANNER.FAILED.RESET_PASSWORD": "Password couldn't be changed.",
	//BANNER SETTINGS
	"BANNER.SUCCEEDED.SETTINGS_UPDATE": "The changes have been saved successfully",
	"BANNER.FAILED.SETTINGS_UPDATE": "The changes couldn't be saved. Please try again later",
	"BANNER.SUCCEEDED.SETTINGS_COPIED": "Successfully copied to clipboard",
	"BANNER.FAILED.SETTINGS_COPIED": "Could not be copied to clipboard, try again later",
	"BANNER.SUCCEEDED.SETTINGS_ADD_USER": "User has been invited",
	"BANNER.FAILED.SETTINGS_ADD_USER": "User couldn't been invited. Please try again later",
	"BANNER.FAILED.USER_LIMITED_REACHED":
		"User couldn't been invited. You have reached the limit of user accounts that can be added to your team.",
	"BANNER.FAILED.DUPLICATED_USER_ADDED": "User is already a member of this team. You cannot add duplicated users.",

	//BANNER MEMBER
	"BANNER.SUCCEEDED.MEMBER_UPDATED": "User has been updated.",
	"BANNER.FAILED.MEMBER_UPDATED": "User couldn't been updated. Please try again later",
	"BANNER.SUCCEEDED.MEMBER_REMOVED": "User has been removed",
	"BANNER.FAILED.MEMBER_REMOVED": "User couldn't been removed. Please try again later",

	"BANNER.SUCCEEDED.RESEND_INVITE": "Invite has been resend to user",
	//BANNER TEAM_GEDEACTIVEERD
	"BANNER.FAILED.TEAM_GEDEACTIVEERD": "Team is deactivated",
	// BANNER.FAILED.UPDATE_TEAM
	"BANNER.SUCCEEDED.UPDATE_TEAM": "Team has been updated successfully.",
	"BANNER.FAILED.UPDATE_TEAM": "Updating team failed. Please try again later.",
	//BANNER TIMED_POPUP
	"BANNER.FAILED.TRIGGER_ADDED": "Add trigger failed. Please try again later",
	"BANNER.FAILED.CREATE.DUPLICATE":
		"Add trigger failed. You already have a trigger with these conditions. Save as draft or change the conditions",
	"BANNER.FAILED.CREATE.LIMITED_REACHED":
		"Add trigger failed. You have reached your trigger limit. Upgrade your plan or delete a other trigger to create this trigger.",
	"BANNER.FAILED.UPDATE.DUPLICATE":
		"Update trigger failed. You already have a trigger with these conditions. Save as draft or change the conditions",
	"BANNER.FAILED.UPDATE.LIMITED_REACHED":
		"Update trigger failed. You have reached your trigger limit. Upgrade your plan or delete a other trigger to create this trigger.",

	"BANNER.FAILED.TRIGGER_REMOVED": "Deleting trigger failed. Please try again later",
	"BANNER.FAILED.TRIGGER_UPDATED": "Updating trigger failed. Please try again later",
	"BANNER.SUCCEEDED.TRIGGER_ADDED": "Trigger has been added successfully",
	"BANNER.SUCCEEDED.TRIGGER_REMOVED": "Trigger has been removed successfully",
	"BANNER.SUCCEEDED.TRIGGER_UPDATED": "Trigger has been updated successfully",
	//BANNER USER
	"BANNER.SUCCEEDED.USER_CHANGED": "The changes have been saved successfully",
	"BANNER.FAILED.USER_CHANGED": "The changes couldn't be saved. Please try again later",
	"BANNER.FAILED.OWNER_CANT_BE_DELETED":
		"You are an owner of a team. You can only delete your account when your team has been removed.",
	//BANNER USER_ACCOUNT
	//BANNER CONNECT_INBOX_ACCOUNT
	"BANNER.FAILED.UPDATE_INBOX_ACCOUNT": "You could not update your account. Please try again",
	//BANNER UPDATE_CHANNEL
	"BANNER.SUCCEEDED.UPDATE_CHANNEL": "You have successfully updated your channel",
	"BANNER.FAILED.CHANNEL_ALREADY_CONNECTED": "You are already connected this channel to this team.",
	"BANNER.FAILED.UPDATE_CHANNEL": "You could not update your channel. Please try again",
	//BANNER DELETE_CHANNEL
	"BANNER.SUCCEEDED.DELETE_CHANNEL": "You have successfully deleted your channel",
	"BANNER.FAILED.DELETE_CHANNEL": "You could not delete your channel. Please try again",
	//BANNER CONNECT_USER_WITH_INTEGRATION
	//BANNER LIANTIS
	"BANNER.SUCCEEDED.LIANTIS": "Report successfully sent to Liantis",
	"BANNER.FAILED.LIANTIS": "Report could not be sent to Liantis. Try again later or contact our customer support.",

	//BANNER FLOW REPORT FID
	"BANNER.SUCCEEDED.FID": "Report successfully sent to the FID platform",
	"BANNER.DUPLICATE.FID": "Report already exists in the FID platform",
	"BANNER.FAILED.FID":
		"Report could not be sent to the FID platform. Try again later or contact our customer support.",

	//BANNER FLOW REPORT ADMIN_PULSE
	"BANNER.SUCCEEDED.ADMIN_PULSE": "Report successfully sent to AdminPulse",
	"BANNER.FAILED.ADMIN_PULSE":
		"Report could not be sent to AdminPulse. Try again later or contact our customer support.",

	"BANNER.FAILED.UPDATE_CONNECTION": "Integration could not be updated",
	"BANNER.SUCCEEDED.UPDATE_CONNECTION": "Integration is successfully updated",
	//VERIFY_ACCOUNT
	"BANNER.SUCCEEDED.REQUEST_NEW_VERIFY_CODE": "New verification code has been send",
	"BANNER.FAILED.REQUEST_NEW_VERIFY_CODE": "New verification code has been send. Please try again later",
	"BANNER.FAILED.VERIFY_ACCOUNT": "User couldn't be verified. Please try again later",

	//BANNER FLOW
	"BANNER.FAILED.DOWNLOAD_REPORT": "Report could not be downloaded. Please try again later.",
	"BANNER.SUCCEEDED.DOWNLOAD_REPORT": "Report downloaded",
	"BANNER.PROCESSING.REPORT_IS_DOWNLOADING": "Preparing to download {type} version of a report.",
	"BANNER.FAILED.CREATE_FLOW": "Flow could not be created. Please try again later.",
	"BANNER.SUCCEEDED.CREATE_FLOW": "Flow is successfully created",
	"BANNER.FAILED.UPDATE_FLOW": "Flow could not be updated. Please try again later.",
	"BANNER.SUCCEEDED.UPDATE_FLOW": "Flow is successfully updated.",
	"BANNER.FAILED.DELETE_FLOW": "Flow could not be removed. Please try again later.",
	"BANNER.SUCCEEDED.DELETE_FLOW": "Flow is successfully removed.",
	"BANNER.FAILED.DOWNLOAD_REPORT_ATTACHMENTS":
		"Report attachments could not be downloaded. Try again later or contact our customer support.",
	"BANNER.PROCESSING.DOWNLOAD_REPORT_ATTACHMENTS": "Preparing to download bundled report attachments",
	"BANNER.SUCCEEDED.DOWNLOAD_NO_REPORT_ATTACHMENTS": "Report has no attachments available",
	"BANNER.SUCCEEDED.DOWNLOAD_REPORT_ATTACHMENTS": "Report attachments downloaded",

	//BANNER LABEL
	"BANNER.SUCCEEDED.CREATE_LABEL": "Label is successfully created.",
	"BANNER.FAILED.CREATE_LABEL": "Label could not be created. Please try again later.",
	"BANNER.SUCCEEDED.UPDATE_LABEL": "Label is successfully updated.",
	"BANNER.FAILED.UPDATE_LABEL": "Label could not be updated. Please try again later.",
	"BANNER.SUCCEEDED.DELETE_LABEL": "Label is successfully removed.",
	"BANNER.FAILED.DELETE_LABEL": "Label could not be removed. Please try again later.",

	//BANNER REPORT
	"BANNER.FAILED.TO_ASSIGN_USER": "Could not assign user to report. Please try again later.",
	"BANNER.FAILED.UPDATE_REPORT": "Report could not be updated. Please try again later.",
	"BANNER.SUCCEEDED.UPDATE_REPORT": "Report is successfully updated",
	"BANNER.FAILED.DELETE_REPORT": "Report could not be removed. Please try again later.",
	"BANNER.SUCCEEDED.DELETE_REPORT": "Report is successfully removed.",

	//BANNER SIGNATURE
	"BANNER.SUCCEEDED.CREATE_SIGNATURE": "Signature is successfully created.",
	"BANNER.FAILED.CREATE_SIGNATURE": "Signature could not be created. Please try again later.",
	"BANNER.SUCCEEDED.UPDATE_SIGNATURE": "Signature is successfully updated.",
	"BANNER.FAILED.UPDATE_SIGNATURE": "Signature could not be updated. Please try again later.",
	"BANNER.SUCCEEDED.DELETE_SIGNATURE": "Signature is successfully removed.",
	"BANNER.FAILED.DELETE_SIGNATURE": "Signature could not be removed. Please try again later.",
	//BANNER STATUS_TYPE
	"BANNER.SUCCEEDED.CREATE_STATUS_TYPE": "Status type is successfully created.",
	"BANNER.FAILED.CREATE_STATUS_TYPE": "Status type could not be created. Please try again later.",
	"BANNER.SUCCEEDED.COPY_STATUS_TYPE": "Status type is successfully updated.",
	"BANNER.SUCCEEDED.UPDATE_STATUS_TYPE": "Status type is successfully copied.",
	"BANNER.FAILED.UPDATE_STATUS_TYPE": "Status type could not be updated. Please try again later.",
	"BANNER.FAILED.COPY_STATUS_TYPE": "Status types could not be copied. Please try again later.",
	"BANNER.SUCCEEDED.DELETE_STATUS_TYPE": "Status type is successfully removed.",
	"BANNER.FAILED.DELETE_STATUS_TYPE": "Status type could not be removed. Please try again later.",

	//BOT
	"SETTINGS.WIDGET.SETTINGS.PAGE.TITLE": "Chatbot",
	"SETTINGS.WIDGET.SETTINGS.BREADCRUMB.SETTINGS": "Settings",
	"SETTINGS.WIDGET.SETTINGS.BREADCRUMB.WIDGET": "Widget",

	//SETTINGS.WIDGET.MOCKUP_ASIDE
	"SETTINGS.WIDGET.MOCKUP_ASIDE.TITLE": "Widget preview",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.DESCRIPTION":
		"See how the widget will be displayed on your website while you make changes.",

	"SETTINGS.WIDGET.MOCKUP_ASIDE.FOOTER.LINK": "See the widget on your website",

	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.HEADER.TITLE": "Preview widget",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.FORM.URL.LABEL":
		"On which website or page of your website do you want to preview the widget?",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.FORM.URL.PLACEHOLDER": "https://www.bothive.be",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.FORM.URL.REQUIRED": "Looks like you forgot to add a url",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.FORM.API_KEY.LABEL": "API key",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.FORM.API_KEY.HELP":
		"You need a API key with the 'widget.load' permission.",
	"SETTINGS.WIDGET.MOCKUP_ASIDE.PREVIEW_WEBSITE_MODAL.FOOTER.PREVIEW": "Preview widget",

	//BOT DEFAULT RESPONSES
	"BOT_DEFAULT_RESPONSE.PAGE.TITLE": "Chatbot - Default response",
	"BOT_DEFAULT_RESPONSE.HEADER.BREADCRUMB.CHATBOT": "Chatbot",
	"BOT_DEFAULT_RESPONSE.HEADER.BREADCRUMB.DEFAULT_RESPONSES": "Default responses",

	"BOT_DEFAULT_RESPONSE.FALLBACK.TITLE": "Something went wrong",
	"BOT_DEFAULT_RESPONSE.FALLBACK.DESCRIPTION":
		"Default response cannot be shown. If this keeps happing pleas contact support@bothive.be",

	"BOT_DEFAULT_RESPONSE.TITLE.TITLE": "Header title",
	"BOT_DEFAULT_RESPONSE.TITLE.DESCRIPTION": "Change the title displayed in the widget header",
	"BOT_DEFAULT_RESPONSE.TITLE.PLACEHOLDER": "Need help?",

	"BOT_DEFAULT_RESPONSE.GREETING_TITLE.TITLE": "Welcome title",
	"BOT_DEFAULT_RESPONSE.GREETING_TITLE.DESCRIPTION":
		"When your user opens the widget they will see a greeting message with this as title.",
	"BOT_DEFAULT_RESPONSE.GREETING_TITLE.PLACEHOLDER": "Hello I am \\{\\{bot-name\\}\\}",

	"BOT_DEFAULT_RESPONSE.GREETING_MESSAGE.TITLE": "Welcome message",
	"BOT_DEFAULT_RESPONSE.GREETING_MESSAGE.DESCRIPTION":
		"When your user opens the widget they will see a greeting message with this as description.",
	"BOT_DEFAULT_RESPONSE.GREETING_MESSAGE.PLACEHOLDER":
		"I am a chatbot specifically designed to help you with any questions you have about \\{\\{team-name\\}\\}",

	"BOT_DEFAULT_RESPONSE.OFFLINE_MESSAGE.TITLE": "Offline message",
	"BOT_DEFAULT_RESPONSE.OFFLINE_MESSAGE.DESCRIPTION": "description context",
	"BOT_DEFAULT_RESPONSE.OFFLINE_MESSAGE.PLACEHOLDER":
		"If the user request live chat but nobody is online, this will be the default response.",

	"BOT_DEFAULT_RESPONSE.LIVE_CHAT_REQUESTED.TITLE": "Live chat is started",
	"BOT_DEFAULT_RESPONSE.LIVE_CHAT_REQUESTED.DESCRIPTION":
		"When the user starts the live chat this message will be send by the chatbot.",

	"BOT_DEFAULT_RESPONSE.CLOSED_LIVE_CHAT.TITLE": "Live chat is closed",
	"BOT_DEFAULT_RESPONSE.CLOSED_LIVE_CHAT.DESCRIPTION":
		"If the live chat is closed from the dashboard this will be the first message of the bot to let the user know they can talk back to the chatbot.",
	"BOT_DEFAULT_RESPONSE.CLOSED_LIVE_CHAT.PLACEHOLDER": "Live chat is closed",

	//BOT ADVANCED SETTINGS
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.ACCORDION_TITLE": "Advanced settings (BETA)",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.HIDE_WIDGET.LABEL": "Hide website widget",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.HIDE_GDPR_BANNER.LABEL": "Hide GDPR banner in the widget",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.HIDE_GDPR_BANNER.HELP":
		"If you choose to hide the GDPR banner you will be responsible to show a gdpr banner on your website.",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.HIDE_WIDGET_WHEN_OFFLINE.LABEL":
		"Hide website widget outside openings hours",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.HIDE_WIDGET_WHEN_OFFLINE.HELP":
		"We strongly advise against this option. This will remove the biggest benefits of the widget, so you will miss out on many opportunities.",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.HIDE_WIDGET_WHEN_LANGUAGE_IS_NOT_SUPPORTED.LABEL":
		"Hide when language is not supported",
	"SETTINGS.WIDGET.SETTINGS.ADVANCED_SETTINGS.SHOW_BRANDING": "Show Bothive branding",

	//VIRTUAL_ASSISTANT_SETTINGS.GENERAL
	"VIRTUAL_ASSISTANT_SETTINGS.TITLE": "Virtual assistant",
	"VIRTUAL_ASSISTANT_SETTINGS.BREADCRUMB.SETTINGS": "Settings",
	"VIRTUAL_ASSISTANT_SETTINGS.BREADCRUMB.TITLE": "Virtual assistant",

	//VIRTUAL_ASSISTANT_RESPONSES
	"VIRTUAL_ASSISTANT_RESPONSES.TITLE": "Default responses",
	"VIRTUAL_ASSISTANT_RESPONSES.BREADCRUMB.SETTINGS": "Settings",
	"VIRTUAL_ASSISTANT_RESPONSES.BREADCRUMB.RESPONSES": "Default responses",
	//VIRTUAL_ASSISTANT_RESPONSES.FORM
	"VIRTUAL_ASSISTANT_RESPONSES.TITLE.LABEL": "Header title",
	"VIRTUAL_ASSISTANT_RESPONSES.TITLE.DESCRIPTION": "Change the title displayed in the widget header",
	"VIRTUAL_ASSISTANT_RESPONSES.FORM.TITLE.PLACEHOLDER": "Need help?",

	"VIRTUAL_ASSISTANT_RESPONSES.GREETING_TITLE.LABEL": "Welcome title",
	"VIRTUAL_ASSISTANT_RESPONSES.GREETING_TITLE.DESCRIPTION":
		"When your user opens the widget they will see a greeting message with this as title.",
	"VIRTUAL_ASSISTANT_RESPONSES.FORM.GREETING_TITLE.PLACEHOLDER": "Hello I am \\{\\{bot-name\\}\\}",

	"VIRTUAL_ASSISTANT_RESPONSES.GREETING_MESSAGE.LABEL": "Welcome message",
	"VIRTUAL_ASSISTANT_RESPONSES.GREETING_MESSAGE.DESCRIPTION":
		"When your user opens the widget they will see a greeting message with this as description.",
	"VIRTUAL_ASSISTANT_RESPONSES.FORM.GREETING_MESSAGE.PLACEHOLDER":
		"I am a chatbot specifically designed to help you with any questions you have about \\{\\{team-name\\}\\}",

	"VIRTUAL_ASSISTANT_RESPONSES.OFFLINE_MESSAGE.LABEL": "Offline message",
	"VIRTUAL_ASSISTANT_RESPONSES.OFFLINE_MESSAGE.DESCRIPTION": "description context",
	"VIRTUAL_ASSISTANT_RESPONSES.FORM.OFFLINE_MESSAGE.PLACEHOLDER":
		"If the user request live chat but nobody is online, this will be the default response.",

	"VIRTUAL_ASSISTANT_RESPONSES.LIVE_CHAT_REQUESTED.LABEL": "Live chat is started",
	"VIRTUAL_ASSISTANT_RESPONSES.LIVE_CHAT_REQUESTED.DESCRIPTION":
		"When the user starts the live chat this message will be send by the chatbot.",

	"VIRTUAL_ASSISTANT_RESPONSES.CLOSED_LIVE_CHAT.LABEL": "Live chat is closed",
	"VIRTUAL_ASSISTANT_RESPONSES.CLOSED_LIVE_CHAT.DESCRIPTION":
		"If the live chat is closed from the dashboard this will be the first message of the bot to let the user know they can talk back to the chatbot.",
	"VIRTUAL_ASSISTANT_RESPONSES.FORM.CLOSED_LIVE_CHAT.PLACEHOLDER": "Live chat is closed",

	"VIRTUAL_ASSISTANT_RESPONSES.SUGGESTIONS.TITLE": "Settings you might find useful",
	//BOT GREETING
	"BOT.GREETING.TITLE": "Default response",
	"BOT.GREETING.DESCRIPTION":
		"Default responses are use by Bothive to say hi to the user, show that an action happened or handle errors. In the website widget we visualize these action, on other platforms like Facebook Messenger this isn't always possible. Here Bothive gives default response which can be customized here.",
	"BOT.GREETING.BUTTON": "Go to default response",

	//BOT STYLING
	"SETTINGS.WIDGET.SETTINGS.STYLING.ACCORDION_TITLE": "Styling",
	"SETTINGS.WIDGET.SETTINGS.STYLING.ACCORDION_DESCRIPTION": "Select your favorite colors as lay-out for the chatbot",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BRAND_COLOR.LABEL": "Primary color",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BRAND_COLOR.PLACEHOLDER": "#08112B",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BRAND_COLOR.HELP": "This color is used in the widget, header and in all icons",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BRAND_COLOR_LIGHT.LABEL": "Secondary color",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BRAND_COLOR_LIGHT.PLACEHOLDER": "#54628A",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BRAND_COLOR_LIGHT.HELP":
		"The Secondary color is used to make the applications more dynamic",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BOT_BUBBLE_COLOR.LABEL": "Bot bubble",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BOT_BUBBLE_COLOR.PLACEHOLDER": "#E6E7E7",
	"SETTINGS.WIDGET.SETTINGS.STYLING.BOT_BUBBLE_COLOR.HELP": "Bot bubble color",
	"SETTINGS.WIDGET.SETTINGS.STYLING.FONT": "Font",
	"SETTINGS.WIDGET.SETTINGS.STYLING.ADD_CUSTOM_FONT": "Add custom font",
	"SETTINGS.WIDGET.SETTINGS.STYLING.ADD_CUSTOM_FONT.LOCK": "Upgrade your plan to add your own font",

	"SETTINGS.WIDGET.SETTINGS.SUGGESTIONS.TITLE": "Settings you might find useful",
	//BOT TRIGGER
	"BOT.TRIGGERS.ACCORDION_TITLE": "Trigger (BETA)",
	"BOT.TRIGGERS.DESCRIPTION":
		"Triggers are used to get the attention of you site visitors. With triggers your site vistors will interact with Bothive much faster. These triggers can be changed to specific page to make it relevant for your site visitor.",
	"BOT.TRIGGERS.BUTTON": "Go to triggers",

	//BUTTON_ICON
	"BUTTON_ICON.COPY.LABEL": "Copy {label}",
	"BUTTON_ICON.SHOW_PASSWORD": "Show password",
	"BUTTON_ICON.HIDE_PASSWORD": "Hide password",

	//CHANNEL_DETAIL_DELETE_MODAL
	"CHANNEL_DETAIL_DELETE_MODAL.HEADER.TITLE": "Delete channel",
	"CHANNEL_DETAIL_DELETE_MODAL.HEADER.DESCRIPTION":
		"Are you sure you want to delete this channel? This action can not be undone.",
	"CHANNEL_DETAIL_DELETE_MODAL.DELETE.BUTTON_LABEL": "Delete channel",

	//COMPONENT.EMAIL_EDITOR
	"COMPONENT.EMAIL_EDITOR.HEADER.TO.LABEL": "To:",
	"COMPONENT.EMAIL_EDITOR.HEADER.TO.PLACEHOLDER": "To who do you want to send the email",

	"COMPONENT.EMAIL_EDITOR.HEADER.FROM.LABEL": "From: ",
	"COMPONENT.EMAIL_EDITOR.HEADER.FROM.PLACEHOLDER": "From which email account do you want the send the email",
	"COMPONENT.EMAIL_EDITOR.HEADER.FROM.FALLBACK.TITLE": "No channel addresses found",
	"COMPONENT.EMAIL_EDITOR.HEADER.FROM.FALLBACK.DESCRIPTION": "Go to settings > channels to connect a channel",
	"COMPONENT.EMAIL_EDITOR.HEADER.FROM.FALLBACK.BUTTON": "Add channel",

	"COMPONENT.EMAIL_EDITOR.HEADER.BUTTON.CC": "Cc",
	"COMPONENT.EMAIL_EDITOR.HEADER.BUTTON.BCC": "Bcc",
	"COMPONENT.EMAIL_EDITOR.HEADER.CC.LABEL": "Cc:",
	"COMPONENT.EMAIL_EDITOR.HEADER.CC.PLACEHOLDER": "Send copy to",

	"COMPONENT.EMAIL_EDITOR.HEADER.BCC.LABEL": "Bcc:",
	"COMPONENT.EMAIL_EDITOR.HEADER.BCC.PLACEHOLDER": "Send blind copy to",

	"COMPONENT.EMAIL_EDITOR.HEADER.SUBJECT.LABEL": "Subject:",
	"COMPONENT.EMAIL_EDITOR.HEADER.SUBJECT.PLACEHOLDER": "Describe what your email is about",

	"COMPONENT.EMAIL_EDITOR.BODY.PLACEHOLDER": "Type something ...",

	"COMPONENT.EMAIL_EDITOR.FOOTER.BUTTON.FORMATTING": "Toggle Formatting menu",
	"COMPONENT.EMAIL_EDITOR.FOOTER.ADD_ATTACHMENT": "Add attachment",
	"COMPONENT.EMAIL_EDITOR.DROP_ZONE.ADD_ATTACHMENT": "Upload attachment",
	"COMPONENT.EMAIL_EDITOR.FOOTER.BUTTON.SEND": "Send",
	"COMPONENT.EMAIL_EDITOR.RULE.CHANNEL.REQUIRED": "Select at least one channel.",
	"COMPONENT.EMAIL_EDITOR.RULE.TO.REQUIRED": "Insert at least one receiver.",

	"COMPONENT.SORT_TABLE.SORT.TOOLTIP": "Drag to sort this row",

	"COMPOSE_EMAIL_MODEL.HEADER.TITLE": "Compose email",
	"COMPOSE_EMAIL_MODEL.HEADER.BUTTON.MINIMIZE": "Minimize",
	"COMPOSE_EMAIL_MODEL.HEADER.BUTTON.MAXIMIZE": "Maximize",
	"COMPOSE_EMAIL_MODEL.HEADER.BUTTON.COLLAPSE": "Collapse",
	"COMPOSE_EMAIL_MODEL.HEADER.BUTTON.ENLARGE": "Enlarge",
	"COMPOSE_EMAIL_MODEL.HEADER.BUTTON.CLOSE": "Close",
	"COMPOSE_EMAIL_MODEL.VALIDATION.MAX_RECIPIENTS": "Max {max} recipients possible.",
	"COMPOSE_EMAIL_MODEL.VALIDATION.EMAIL": "{address} is no valid e-mailaddress.",
	"COMPOSE_EMAIL_MODEL.FOOTER.UPLOADING_ATTACHMENT": "Uploading attachment",
	"COMPOSE_EMAIL_MODEL.FOOTER.LOADING_SIGNATURE": "Fetching e-mail signatures",
	"COMPOSE_EMAIL_MODEL.CONFIRM.ARE_YOU_SURE_TO_CLOSE_COMPOSE": "Do you want to save this email as draft?",

	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.TITLE": "Connect your email channel",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.DESCRIPTION": "Connect email channels with your inbox.",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.FOOTER.LINK": "settings > channels",
	"COMPOSE_EMAIL_MODEL.FOOTER.SIGNATURE.SELECT.NO_SIGNATURE": "No signature",
	"COMPOSE_EMAIL_MODEL.CONNECT_CHANNEL.FOOTER": "You can manage your channels in <link>settings / channels</link>",

	//CONTACT
	"CONTACT.OVERVIEW.PAGE.TITLE": "Contacts (BETA)",

	"CONTACT.FILTER.SEARCH.PLACEHOLDER": "Search for name or email exp: Sophia or sophia@bothive.be",
	"CONTACT.FILTER.LABEL.PLACEHOLDER": "Filter on labels",
	"CONTACT.FILTER.DETAIL.TOTAL_RESULTS":
		"{value, plural, =0 {Nobody found} one {# contact found} other {# contacts found}}",
	"CONTACT.FILTER.BUTTON.NEW_CLIENT": "New contact",

	"CONTACT.OVERVIEW.TABLE_OPTIONS.DELETE_SELECTED_CONTACTS":
		"Delete {value, plural, one {# contact} other {# contacts}}",

	"CONTACTS.OVERVIEW.ASIDE.OVERVIEW": "Contacts overview",
	"CONTACTS.OVERVIEW.ASIDE.ARCHIVE": "Contacts archive",

	"CONTACT.OVERVIEW.TABLE.HEADER.USER": "User",
	"CONTACT.OVERVIEW.TABLE.HEADER.E_MAIL": "E-mail",
	"CONTACT.OVERVIEW.TABLE.HEADER.LOCATION": "Location",
	"CONTACT.OVERVIEW.TABLE.HEADER.COMPANY": "Company",

	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.BUTTON.TITLE": "Labels",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.TITLE": "Label as",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.NOT_FOUND": "No contact labels found",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ACTIONS.DELETE": "Delete",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.MODAL.ACTIONS.ADD": "Add",
	"CONTACT.OVERVIEW.BULK_ACTION.CONTACT_LABELS.ERROR": "Something went wrong while updating multiple contacts.",

	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.BUTTON.TITLE": "Account managers",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.TITLE": "Management by",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.NOT_FOUND": "No account managers found",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ACTIONS.DELETE": "Delete",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.MODAL.ACTIONS.ADD": "add",
	"CONTACT.OVERVIEW.BULK_ACTION.ACCOUNT_MANAGERS.ERROR": "Something went wrong while updating multiple contacts.",

	//CONTACT_SETTINGS.ASIDE
	"CONTACT_SETTINGS.ASIDE.OVERVIEW": "Overview",
	"CONTACT_SETTINGS.ASIDE.LABELS": "Labels",

	//CONTACT_SETTINGS.OVERVIEW
	"CONTACT_SETTINGS.OVERVIEW.TITLE": "Contact settings",
	"CONTACT_SETTINGS.OVERVIEW.BREADCRUMB.SETTINGS": "Settings",
	"CONTACT_SETTINGS.OVERVIEW.BREADCRUMB.TITLE": "Contacten settings",
	"CONTACT_SETTINGS.OVERVIEW.SYNC.TITLE": "Import contacts from other applications or databases",
	"CONTACT_SETTINGS.OVERVIEW.SYNC.DESCRIPTION":
		"Quickly and easily synchronize contacts from other CRM systems with Bothive.",

	"CONTACT_SETTINGS.OVERVIEW.SUGGESTIONS.TITLE": "Settings you might find useful",

	"CONTACT_SETTINGS.OVERVIEW.SUGGESTIONS.INTEGRATIONS.TITLE": "Integrations",
	"CONTACT_SETTINGS.OVERVIEW.SUGGESTIONS.INTEGRATIONS.DESCRIPTION":
		"Integrate with various CRM systems to quickly synchronize contacts.",
	"CONTACT_SETTINGS.OVERVIEW.SUGGESTIONS.INTEGRATIONS.BUTTON": "Manage integrations",

	//CONTACT_SETTINGS.LABELS
	"CONTACT_SETTINGS.LABELS.TITLE": "Contact labels",
	"CONTACT_SETTINGS.LABELS.BREADCRUMB.SETTINGS": "Settings",
	"CONTACT_SETTINGS.LABELS.BREADCRUMB.TITLE": "Contact labels",
	"CONTACT_SETTINGS.LABELS.TABLE.FALLBACK": "You haven't set any labels.",
	"CONTACT_SETTINGS.LABELS.TABLE.NAME": "Label name",
	"CONTACT_SETTINGS.LABELS.TABLE.ADD_LABEL": "Add new label",

	//CONTACT.DETAILS
	"CONTACT_DETAILS.PAGE.TITLE": "Contact details",
	"CONTACT_DETAILS.HEADER.BREADCRUMB.OVERVIEW": "Contacts",
	"CONTACT_DETAILS.HEADER.BREADCRUMB.VISITOR": "Visitor",
	"CONTACT_DETAILS.NOT_FOUND.TITLE": "Contact not found",
	"CONTACT_DETAILS.NOT_FOUND.DESCRIPTION": "It seems like this contact does not exists inside your organization.",
	"CONTACT_DETAILS.NOT_FOUND.RETURN": "Go back",

	//CONTACT_IMPORT
	"CONTACT_IMPORT_REPORT.HEADER.BREADCRUMB.OVERVIEW": "Contacts",
	"CONTACT_IMPORT_REPORT.HEADER.BREADCRUMB.IMPORTS": "Imports",
	"CONTACT_IMPORT_REPORT.PAGE.TITLE": "Contact import report",

	//CONTACT_TYPE
	"CONTACT_DETAILS.CONTACT_TYPE.CONTACT": "Contact",
	"CONTACT_DETAILS.CONTACT_TYPE.EMAIL_CONTACT": "E-mail contact",
	"CONTACT_DETAILS.CONTACT_TYPE.LINKED_CONTACT": "Linked contact",
	"CONTACT_DETAILS.CONTACT_TYPE.VISITOR": "Visitor",
	//CONTACT.DETAILS.ASIDE
	"CONTACT_DETAILS.ASIDE.BACK_TO_OVERVIEW.LABEL": "Back to overview",
	"CONTACT_DETAILS.ASIDE.OVERVIEW.LABEL": "Overview",
	"CONTACT_DETAILS.ASIDE.DETAILS.LABEL": "Details",
	"CONTACT_DETAILS.ASIDE.CONVERSATIONS.LABEL": "Conversations",
	"CONTACT_DETAILS.ASIDE.REPORTS.LABEL": "Reports",
	"CONTACT_DETAILS.ASIDE.FILES.LABEL": "File",
	"CONTACT_DETAILS.ASIDE.ANALYTICS.LABEL": "Analytics",
	"CONTACT_DETAILS.ASIDE.LOGS.LABEL": "Logs",
	"CONTACT_DETAILS.ASIDE.SETTINGS.LABEL": "Settings",
	//CONTACT_DETAILS.CONTACT_INFO
	"CONTACT_DETAILS.CONTACT_INFO.BUTTON.SEND_MESSAGE": "Send message",
	"CONTACT_DETAILS.CONTACT_INFO.META.PHONE.LABEL": "Phone",
	"CONTACT_DETAILS.CONTACT_INFO.META.BIRTHDAY.LABEL": "Birthday",
	"CONTACT_DETAILS.CONTACT_INFO.META.STREET.LABEL": "Street",
	"CONTACT_DETAILS.CONTACT_INFO.META.CITY.LABEL": "City",
	"CONTACT_DETAILS.CONTACT_INFO.META.POSTAL_CODE.LABEL": "Postal code",
	"CONTACT_DETAILS.CONTACT_INFO.META.REFERENCE_ID.LABEL": "Reference id",
	"CONTACT_DETAILS.CONTACT_INFO.META.BUTTON.SEE_ALL": "See more info",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.ACCORDION_TITLE": "Delete contact",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.TITLE": "Delete this contact",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.DESCRIPTION":
		"Important note: all documents, reports, and data linked to this contact will be permanently deleted after 90 days.",
	"CONTACT_DETAILS.SETTINGS.DANGER_ZONE.BUTTON": "Delete contact",

	// CONTACT_DETAILS.UNIFIED_INBOX
	"CONTACT_DETAILS.UNIFIED_INBOX.FILTER.CHANNELS.PLACEHOLDER": "Show from all accessible channels",
	"CONTACT_DETAILS.UNIFIED_INBOX.FAILED.TITLE": "Loading failed",
	"CONTACT_DETAILS.UNIFIED_INBOX.FAILED.DESCRIPTION":
		"The conversations of {username} could not be loaded.\nPlease try again later or contact our customer service.",
	"CONTACT_DETAILS.UNIFIED_INBOX.EMPTY":
		"Here you will find all the conversations you and your team have had with {username}.",
	"CONTACTS_LABEL_TABLE.NAME.TITLE": "Name",
	//CONTACTS_LABEL_MODAL
	"CONTACTS_LABEL_MODAL.CREATE.TITLE": "Create label",
	"CONTACTS_LABEL_MODAL.CREATE.BUTTON.CREATE": "Create label",
	"CONTACTS_LABEL_MODAL.CREATE.BUTTON.CANCEL": "Cancel",

	"CONTACTS_LABEL_MODAL.FORM.NAME.LABEL": "Label name",
	"CONTACTS_LABEL_MODAL.FORM.NAME.PLACEHOLDER": "Personal income tax",
	"CONTACTS_LABEL_MODAL.FORM.DESCRIPTION.LABEL": "Label description",
	"CONTACTS_LABEL_MODAL.FORM.DESCRIPTION.PLACEHOLDER":
		"Attach this label to contacts who should receive the personal income tax.",
	"CONTACTS_LABEL_MODAL.FORM.DESCRIPTION.HELP":
		"Explain this label or write a description to your colleagues when a contact needs to have this label",
	"CONTACTS_LABEL_MODAL.FORM.COLOR.LABEL": "Label color",
	"CONTACTS_LABEL_MODAL.FORM.COLOR.PLACEHOLDER": "#F8C945",

	"CONTACTS_LABEL_MODAL.EDIT.TITLE": "Edit label",
	"CONTACTS_LABEL_MODAL.EDIT.BUTTON.SAVE": "Save",
	"CONTACTS_LABEL_MODAL.EDIT.BUTTON.CANCEL": "Cancel",
	"CONTACTS_LABEL_MODAL.EDIT.BUTTON.DELETE": "Delete",

	"contacts_label_modal.update.title": "Update label",

	//CONTACT_IMPORT
	CONTACTS_IMPORT_TABLE_HEADER_TITLE_NAME: "Name",
	CONTACTS_IMPORT_TABLE_HEADER_TITLE_EMAIL: "E-mail",

	//DASHBOARD
	"DASHBOARD.PAGE.TITLE": "Dashboard",

	"DASHBOARD.SESSIONS.SECTION_TITLE": "Sessions",
	"DASHBOARD.SESSIONS_KEY.SECTION_TITLE": "Sessions",
	"DASHBOARD.MESSAGES_PER_SESSIONS.SECTION_TITLE": "Messages per session",
	"DASHBOARD.MESSAGES_PER_SESSIONS_KEY.SECTION_TITLE": "Messages/session",
	"DASHBOARD.ERROR_RATE.SECTION_TITLE": "Answer the questions",
	"DASHBOARD.AVERAGE_MESSAGE_DURATION.SECTION_TITLE": "Average session duration",
	"DASHBOARD.AVERAGE_SESSION_TIME.SECTION_TITLE": "Average time per session",
	"DASHBOARD.VISITORS.UNIQUE_SITE_VISITORS":
		"Unique website {value, plural, =0 {visitors} one {visitor} other {visitors}}", //eslint-disable-line max-len
	"DASHBOARD.VISITORS.UNIQUE_CHAT_USERS": "Unique chatbot {value, plural, =0 {users} one {user} other {users}}", //eslint-disable-line max-len
	"DASHBOARD.MOST_ASKED_QUESTION.SECTION_TITLE": "Most asked questions",
	"DASHBOARD.MOST_ASKED_QUESTION.FALLBACK":
		"There is not enough data to analyze the most frequently asked questions.",
	"DASHBOARD.MOST_ASKED_QUESTION.QUESTION": "Asked questions",
	"DASHBOARD.MOST_ASKED_QUESTION.COUNT": "Amount",
	"DASHBOARD.AVERAGE_CONVERSATION_BY_TIME_OF_DAY.SECTION_TITLE": "Average sessions by time of day",
	"DASHBOARD.SUMMARY.SECTION_TITLE": "Summary",
	"DASHBOARD.SUMMARY.USER_RATE": "unique users",
	"DASHBOARD.SUMMARY.RETURN_RATE": "Returning users",
	"DASHBOARD.SUMMARY.FAIL_RATE": "Fail rate",
	"DASHBOARD.SUMMARY.TOTAL_TIME": "Total time saved",

	//FAILED_EMAIL
	"FAILED_EMAIL.HEADER.TITLE":
		"E-mail could not be send. If this problem persists, please contact our customer success.",
	"FAILED_EMAIL.HEADER.MENU.DELETE_EMAIL": "Delete e-mail",
	//DRAFT_EMAIL
	"DRAFT_EMAIL.DRAFT_HEADER.TITLE": "Draft",
	"DRAFT_EMAIL.DRAFT_HEADER.FAILED.TITLE": "Failed draft message",
	"DRAFT_EMAIL.DRAFT_HEADER.MENU.DELETE_DRAFT": "Delete draft",
	"DRAFT_EMAIL.FOOTER.BUTTON.UPDATE": "Update draft",
	"DRAFT_EMAIL.FOOTER.BUTTON.SEND": "Send draft",

	//EMAIL_PREVIEW
	"EMAIL_PREVIEW.MENU.FORWARD.LABEL": "Forward email",
	"EMAIL_PREVIEW.MENU.COLLAPSE.LABEL": "Collapse e-mail",
	"EMAIL_PREVIEW.MENU.EXPAND.LABEL": "Expand e-mail",
	"EMAIL_PREVIEW.MENU.SHOW_THREAD.LABEL": "Show included thread",
	"EMAIL_PREVIEW.MENU.HIDE_THREAD.LABEL": "Hide included thread",

	"EMAIL_PREVIEW.ATTACHMENTS.HEADER.TITLE": "{attachments} attachments",
	"EMAIL_PREVIEW.ATTACHMENTS.HEADER.BUTTON": "Save all",

	"EMAIL_PREVIEW.ACTIONS.SHOW_THREAD": "Show included thread",
	"EMAIL_PREVIEW.ACTIONS.HIDE_THREAD": "Hide included thread",

	//FALLBACK
	"FALLBACK.ANALYTICS.NOT_ENOUGH_DATA": "Insufficient data available",
	"FALLBACK.DROPDOWN.NO_ITEMS_FOUND": "No items found",
	"FALLBACK.ERROR_HANDLER.TITLE": "Something went wrong and page can't be displayed",
	"FALLBACK.NOT_FOUND.DEFAULT": "No data could be found",
	"FALLBACK.TABLE.NO_DATA": "No data could be found",
	"FALLBACK.NO_TEAM.TITLE": "It looks like something went wrong",
	"FALLBACK.NO_TEAM.DESCRIPTION": "Your team couldn't be found. Please log out and try to login again.",
	"FALLBACK.LOCK.CONTACT_SALE": "Talk to sales",
	"FALLBACK.CONTACT.NAME": "Unknown contact",
	"FALLBACK.CONTACT.NAME.SHORT": "Unknown",

	//FILTER FILES
	"FILTER.FILES.FOOTER.TOTAL_RESULTS":
		"{value, plural, =0 {No files found} one {# file found} other {# files found}}",

	"FILTER.FILES.FORM.FILE_TYPES.LABEL": "File type",
	"FILTER.FILES.FORM.SEARCH.LABEL": "File name",
	"FILTER.FILES.FORM.SEARCH.PLACEHOLDER": "Search on file name, extension, ...",
	"FILTER.FILES.FORM.FILE_TYPES.PLACEHOLDER": "File type",

	//FLOWS_OVERVIEW
	"FLOWS_OVERVIEW.PAGE.TITLE": "Flows overview",
	"FLOWS_OVERVIEW.SEARCH.PLACEHOLDER": "Search for flow",
	"FLOWS_OVERVIEW.SEARCH.DETAIL.TOTAL_RESULTS":
		"{value, plural, =0 {Couldn't find any flows} one {# flow found} other {# flows found}}",
	"FLOWS_OVERVIEW.SEARCH.BUTTON.CREATE_FLOW": "Create flow",

	"FLOWS_OVERVIEW.FIRST_TIME.TITLE": "Create your first flow",
	"FLOWS_OVERVIEW.FIRST_TIME.DESCRIPTION":
		"With flows, you’ll be able to digitally process administrative procedures. Users are guided through an automatically generated flow, step by step. They complete the process autonomously in an interactive and user friendly chat environment.",
	"FLOWS_OVERVIEW.FIRST_TIME.BUTTON_LABEL": "See all or templates",

	"FLOWS_OVERVIEW.LOCK.TITLE": "Flows the forms of the future",
	"FLOWS_OVERVIEW.LOCK.DESCRIPTION":
		"With flows, you’ll be able to digitally process administrative procedures. Users are guided through an automatically generated flow, step by step. They complete the process autonomously in an interactive and user friendly chat environment.",
	"FLOWS_OVERVIEW.LOCK.UPGRADE_BUTTON": "Upgrade your plan",

	"FLOWS_OVERVIEW.NO_FLOWS_FOUND.TITLE": "Could not find any flows",
	"FLOWS_OVERVIEW.NO_FLOWS_FOUND.DESCRIPTION": "You haven't created any flows that match your search.",

	"FLOWS_OVERVIEW.TABLE.HEADER.FLOW": "Flow",
	"FLOWS_OVERVIEW.TABLE.HEADER.LINK": "Shareable link",
	"FLOWS_OVERVIEW.TABLE.ROW.LINK.BUTTON": "Copy flow link",
	"FLOWS_OVERVIEW.TABLE.HEADER.STATUS": "Status",
	"FLOWS_OVERVIEW.TABLE.FALLBACK": "Could not find any flows",

	//FLOW_DELETE_MODAL
	"FLOW_DELETE_MODAL.HEADER.TITLE": "Delete flow",
	"FLOW_DELETE_MODAL.HEADER.DESCRIPTION":
		"Are you sure you want to delete this flow? This action can not be undone and will deactivate alle links!",
	"FLOW_DELETE_MODAL.DELETE.BUTTON_LABEL": "Delete flow",

	//FLOW_TEMPLATE_MODAL
	"FLOW_TEMPLATE_MODAL.HEADER.TITLE": "Choose a template",
	"FLOW_TEMPLATE_MODAL.HEADER.DESCRIPTION":
		"Templates have all the power and functionality you expect. They're created and managed by Bothive for you so you can focus on what is really important, the responses of your customers.",
	"FLOW_TEMPLATE_MODAL.CARD.BUTTON.SELECT": "Select",
	"FLOW_TEMPLATE_MODAL.CARD.CHIP.COMING_SOON": "Coming soon",

	"FLOW_TEMPLATE_MODAL.WARNING.TITLE": "Upgrade your plan.",
	"FLOW_TEMPLATE_MODAL.WARNING.DESCRIPTION":
		"You need to upgrade your plan to create a new flow. These are the available flows if you want to see a demo or more information contact or support team.",

	"FLOW_TEMPLATE_MODAL.FALLBACK.DESCRIPTION":
		"There are no flows that you or your agent can use yet. Contact our support team for more information or get early access to new flows.",
	"FLOW_TEMPLATE_MODAL.FALLBACK.BUTTON": "Contact support",

	//FLOW_TEMPLATE_DETAIL_MODAL
	"FLOW_DETAIL_TEMPLATE_MODAL.OVERVIEW": "Return to overview",
	"FLOW_DETAIL_TEMPLATE_MODAL.BUTTON": "Create flow",
	"FLOW_DETAIL_TEMPLATE_MODAL.FALLBACK.DESCRIPTION":
		"There is no description available for this flow. Contact our support team for more information",
	"FLOW_DETAIL_TEMPLATE_MODAL.FALLBACK.BUTTON": "Contact support",
	"FLOW_DETAIL_TEMPLATE.MODAL.EXISTS.TITLE": "{name} already exists",
	"FLOW_DETAIL_TEMPLATE.MODAL.EXISTS.DESCRIPTION":
		"You've already made one of these flows, you can still create another one",

	//FLOW_DETAIL
	"FLOW_DETAIL.PAGE.TITLE": "Flow",

	"FLOW_DETAIL.HEADER.BREADCRUMB.FLOWS": "Flows overview",

	"FLOW_DETAIL.SETTINGS.FORM.IS_ENABLED.LABEL": "Enable flow",
	"FLOW_DETAIL.SETTINGS.FORM.IS_ENABLED.HELP": "Disabled flows are no longer available to your users.",
	"FLOW_DETAIL.SETTINGS.FORM.UNIQUE_LINK.LABEL": "Shareable link to flow",
	"FLOW_DETAIL.SETTINGS.FORM.UNIQUE_LINK.HELP": "Share this link with clients you’d like to collect data from.",

	"FLOW_DETAIL.SETTINGS.CUSTOMIZE.HEADER.TITLE": "Customize flow",
	"FLOW_DETAIL.SETTINGS.CUSTOMIZE.FORM.NAME.LABEL": "Name",
	"FLOW_DETAIL.SETTINGS.CUSTOMIZE.FORM.DESCRIPTION.LABEL": "Description",

	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.HEADER.TITLE": "Widget settings",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.HEADER.DESCRIPTION":
		"Customize how this flow will be shown in the widget on your website",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.LOGO.TITLE": "Icon",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.LOGO.LABEL": "Upload icon",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.LOGO.HELP": "Maximum size: 10MB",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.NAME.LABEL": "Name",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.DESCRIPTION.LABEL": "Description",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.HIDE_FLOW.LABEL": "Hide flow in widget",
	"FLOW_DETAIL.SETTINGS.WIDGET_SETTINGS.FORM.HIDE_FLOW.HELP":
		"If you hide the flow in the widget, it will not be visible on your website or other place where you included the flow widget.",

	"FLOW_DETAIL.ASIDE.BACK_TO_OVERVIEW.LABEL": "Back to overview",
	"FLOW_DETAIL.ASIDE.REPORT.LABEL": "Reports",
	"FLOW_DETAIL.ASIDE.CONFIGURATION.LABEL": "Personalization",
	"FLOW_DETAIL.ASIDE.STATUS_TYPES.LABEL": "Status types",
	"FLOW_DETAIL.ASIDE.AUTOMATION.LABEL": "Automation",
	"FLOW_DETAIL.ASIDE.SETTINGS.LABEL": "Flow Settings",

	"FLOW_DETAIL.AUTOMATION.WELCOME.HEADER.TITLE": "Automize your flows",
	"FLOW_DETAIL.AUTOMATION.WELCOME.HEADER.DESCRIPTION":
		"Rules save you time by automating repetitive processes. For example, you can create a rule when a report is assigned to a specific team member, change status types or forward a report to a other service or organization. You can create your own rules or pick one of our pre-build templates te get started.",
	"FLOW_DETAIL.AUTOMATION.WELCOME.HEADER.BUTTON_LABEL": "Create my first rule",
	"FLOW_DETAIL.AUTOMATION.WALK_THROUGH.TITLE": "Automize your flows",
	"FLOW_DETAIL.AUTOMATION.WALK_THROUGH.DESCRIPTION":
		"Rules save you time by automating repetitive processes. For example, you can create a rule when a report is assigned to a specific team member, change status types or forward a report to a other service or organization. You can create your own rules or pick one of our pre-build templates te get started.",
	"FLOW_DETAIL.AUTOMATION.TABLE.HEADER.NAME": "Name",
	"FLOW_DETAIL.AUTOMATION.TABLE.HEADER.IS_ENABLED": "Enabled",
	"FLOW_DETAIL.AUTOMATION.TABLE.BUTTON.CREATE_RULE": "Create rule",

	"FLOW_DETAIL.REPORT.TABLE.HEADER.CONTACT": "Contact",
	"FLOW_DETAIL.REPORT.TABLE.HEADER.ASSIGNEE": "Assigned",
	"FLOW_DETAIL.REPORT.TABLE.HEADER.STATUS": "Status",
	"FLOW_DETAIL.REPORT.TABLE.HEADER.PROGRESS": "Progress",
	"FLOW_DETAIL.REPORT.FALLBACK.TITLE": "Reports",
	"FLOW_DETAIL.REPORT.FALLBACK.OR": "Or",
	"FLOW_DETAIL.REPORT.FALLBACK.SEND": "Send flow to contacts",

	"FLOW_DETAIL.REPORT.FALLBACK.DESCRIPTION":
		"Here you'll find the report of all completed flows. Currently, no one has completed any flows.\nShare this link with clients you'd like to collect data from.",
	"FLOW_DETAIL.REPORT.FALLBACK.INPUT_LABEL": "Share this link with clients you'd like to collect data from.",
	"FLOW_DETAIL.REPORT.TABLE.HEADER.CREATED_AT": "Created at",
	"FLOW_DETAIL.REPORT.TABLE.HEADER.LAST_UPDATED": "Last updated",
	"FLOW_DETAIL.REPORT.FILTER.CONTROL.STATUS.LABEL": "Status",
	"FLOW_DETAIL.REPORT.FILTER.CONTROL.STATUS.PLACEHOLDER": "All open statuses",
	"FLOW_DETAIL.REPORT.FILTER.CONTROL.PROGRESS.LABEL": "Progress",
	"FLOW_DETAIL.REPORT.FILTER.CONTROL.PROGRESS.PLACEHOLDER": "All progress levels",
	"FLOW_DETAIL.REPORT.FILTER.SEARCH.LABEL": "Search on contact",
	"FLOW_DETAIL.REPORT.FILTER.SEARCH.PLACEHOLDER":
		"Search on contact name or email ex.: sophia or sophia@bothiv.be ...",
	"FLOW_DETAIL.REPORT.FILTER.DETAIL.TOTAL_RESULTS":
		"{value, plural, =0 {No reports found} one {# report found} other {# reports found}}",

	"FLOW_DETAIL.REPORT.BUTTON.COPY_LINK": "Copy link",
	"FLOW_DETAIL.REPORT.BUTTON.SEND": "Send flow",
	"FLOW_DETAIL.REPORT.BUTTON.REMIND": "Remind contacts",

	"FLOW_DETAIL.STATUS_TYPE.WALK_THROUGH.TITLE": "Collaborate better with status types",
	"FLOW_DETAIL.STATUS_TYPE.WALK_THROUGH.DESCRIPTION":
		"Status types helps you and your team to work better and more efficiently together. Combines this with automation rules and you get more done with less.",

	"FLOW_DETAIL.STATUS_TYPE.TABLE.HEADER.TITLE": "Status type",
	"FLOW_DETAIL.STATUS_TYPE.TABLE.HEADER.IS_DEFAULT": "Default",
	"FLOW_DETAIL.STATUS_TYPE.TABLE.HEADER.IS_DONE": "Is done",
	"FLOW_DETAIL.STATUS_TYPE.BUTTON.CREATE_STATUS_TYPE": "Create new status type",

	"STATUS_TYPE_MODAL.HEADER.TITLE.CREATE": "Create new status type",
	"STATUS_TYPE_MODAL.HEADER.TITLE.EDIT": "Edit status type",
	"STATUS_TYPE_MODAL.FORM.NAME.LABEL": "Name",
	"STATUS_TYPE_MODAL.FORM.NAME.PLACEHOLDER": "Enter new name",
	"STATUS_TYPE_MODAL.FORM.DESCRIPTION.LABEL": "Description",
	"STATUS_TYPE_MODAL.FORM.DESCRIPTION.PLACEHOLDER": "Let people know when to use this status type",
	"STATUS_TYPE_MODAL.FORM.IS_DEFAULT.LABEL": "Is Default",
	"STATUS_TYPE_MODAL.FORM.IS_DEFAULT.HELP": "Status type will be assigned to new reports",
	"STATUS_TYPE_MODAL.FORM.ORDER.LABEL": "Order",
	"STATUS_TYPE_MODAL.FORM.ORDER.HELP": "Define in which order the status needs to be shown 0 < ∞",
	"STATUS_TYPE_MODAL.FORM.WARNING_BANNER.DEFAULT_IS_ON":
		"Flows always need 1 default status type. Make a other status type default to turn this one off.",
	"STATUS_TYPE_MODAL.FORM.WARNING_BANNER.DEFAULT_REPLACE": "This will replace {{default}} as default status type",
	"STATUS_TYPE_MODAL.FORM.IS_DONE.LABEL": "Done",
	"STATUS_TYPE_MODAL.FORM.IS_DONE.HELP":
		"Reports with this status type will be seen as done and will not be shown in the report list by default.",
	"STATUS_TYPE_MODAL.BUTTON.SUBMIT": "Save",
	"STATUS_TYPE_MODAL.BUTTON.CANCEL": "Cancel",
	"STATUS_TYPE_MODAL.BUTTON.DELETE": "Delete",

	"STATUS_TYPE_COPY.BUTTONLABEL": "Copy from other flows",
	"STATUS_TYPE_COPY.HEADER.TITLE": "Copy status types from other flows",
	"STATUS_TYPE_COPY.BUTTON_SUBMIT": "Add",
	"STATUS_TYPE_COPY.BUTTON_CANCEL": "Cancel",
	"STATUS_TYPE_COPY.FORM.DROPDOWN_LABEL": "Flows",
	"STATUS_TYPE_COPY.FORM.OVERRIDE_LABEL": "Override",
	"STATUS_TYPE_COPY.FORM.OVERRIDE.HELP": "Keep existing status types or override with chosen ones",

	"STATUS_TYPE.MAX.WARNING.DESCRIPTION":
		"You've used {currentStatusTypes} of your {maxStatusTypes} available statustypes, upgrade your plan to increase this limit.",
	"STATUS_TYPE.MAX.LIMIT.DESCRIPTION":
		"You have reached your limit of {maxStatusTypes} statustypes, upgrade your plan to increase this limit.",

	"FLOW_DETAIL.CONFIGURATION.TITLE": "Personalize your flow",
	"FLOW_DETAIL.CONFIGURATION.DESCRIPTION":
		"Do you want to retrieve more data or do you find the flow just too extensive? Soon you will be able to edit our standard flows to your preference. Contact our support team to be the first to access this feature!",
	"FLOW_DETAIL.CONFIGURATION.FORM.SUBMIT": "Save",

	"FLOW_DETAIL.SETTINGS.DANGER_ZONE.ACCORDION_TITLE": "Delete flow",
	"FLOW_DETAIL.SETTINGS.DANGER_ZONE.TITLE": "Delete this flow",
	"FLOW_DETAIL.SETTINGS.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to delete this flow? All your reports will be destroyed and the unique link will not be available anymore. This action is not reversabel.",
	"FLOW_DETAIL.SETTINGS.DANGER_ZONE.BUTTON": "Delete flow",
	"FLOW_DETAIL.FIELDS.REQUIRED.HOVER": "Mandatory field",

	//FORM_ELEMENT
	"FORM_ELEMENT.LABEL.OPTIONAL": "(optional)",
	"FORM_ELEMENT.ERROR.THIS_FIELD": "This field",
	"FORM_ELEMENT.ERROR.REQUIRED": "{label} is required",
	"FORM_ELEMENT.ERROR.MIN_LENGTH": "{label} is not long enough",
	"FORM_ELEMENT.ERROR.MAX_LENGTH": "{label} has too many characters",
	"FORM_ELEMENT.ERROR.ARRAY_LENGTH": "You have not selected enough items",
	"FORM_ELEMENT.ERROR.TRIGGERS_NOT_COMPLETE": "Not all fields are filled",
	"FORM_ELEMENT.ERROR.EMAIL": "This is not a correct e-mail address",
	"FORM_ELEMENT.ERROR.UNIQUE_CONTACT_LABEL_EMAIL": "A contact label has already been registered in your team",
	"FORM_ELEMENT.ERROR.UNIQUE_USER_EMAIL": "A user has already been registered with this email address",
	"FORM_ELEMENT.ERROR.UNIQUE_TEAM_EMAIL": "A team has already been registered with this email address",
	"FORM_ELEMENT.ERROR.NOT_A_NUMBER": "{label} should be a number and can only contain values (0-9)",
	"FORM_ELEMENT.ERROR.PASSWORD_VALIDATOR": "Password is not correct",
	"FORM_ELEMENT.ERROR.PASSWORD_COMPARE": "Passwords do not match",
	"FORM_ELEMENT.ERROR.HAS_UPPER_CASE": "{label} needs at least one uppercase",
	"FORM_ELEMENT.ERROR.OPENING_HOURS_INVALID":
		"The opening hours are incorrect. We do not accept overlapping opening hours",
	"FORM_ELEMENT.ERROR.UPLOAD_FAILED": "Uploaden failed",
	"FORM_ELEMENT.ERROR.COMPANY_NUMBER": "Company number does not have the correct format",
	"FORM_ELEMENT.ERROR.NAME": "{label} already exists",
	"FORM_ELEMENT.BUTTON.DEFAULT": "Save",
	"FORM_ELEMENT.BUTTON.ADD_FIELD": "Add field",
	"FORM_ELEMENT.BUTTON.SEND": "Send",
	"FORM_ELEMENT.BUTTON.NEW_ROW": "Add new row",
	"FORM_ELEMENT.PROMPT.UNSAVED_CHANGES": "Are you sure you want to navigate without saving?",
	"FORM_ELEMENT.SEGMENT.CHOOSE_A_IMAGE": "Choose an image",
	"FORM_ELEMENT.SEGMENT.UPLOAD_IMAGE": "Upload image",
	"FORM_ELEMENT.SEGMENT.CHOOSE_A_FILE": "Choose an file",
	"FORM_ELEMENT.SEGMENT.FILE_UPLOADED": "{value, plural, =0 {# files} one {# file} other {# files}} uploaded",
	"FORM_ELEMENT.INCL_EXCL.INCLUDE_TITLE": "Accept the following types of customers",
	"FORM_ELEMENT.INCL_EXCL.EXCLUDE_TITLE": "Exclude the following types of customers",
	"FORM_ELEMENT.ERROR.INVALID_COUPON": "This coupon is not valid",
	"FORM_ELEMENT.ERROR.VALUE_DELETE": "The input is not equal to DELETE",
	"FORM_ELEMENT.ERROR.VALUE_ARCHIVE": "The input is not equal to ARCHIVE",
	"FORM_ELEMENT.ERROR.CANCEL_SUBSCRIPTION": "The input is not equal to CANCEL SUBSCRIPTION",
	"FORM_ELEMENT.ERROR.DOWNGRADE": "The input is not equal to DOWNGRADE",
	"FORM_ELEMENT.LANGUAGE.EN": "English",
	"FORM_ELEMENT.LANGUAGE.NL": "Dutch",
	"FORM_ELEMENT.MAP_FIELD.CUSTOM.LABEL": "Custom",
	"FORM_ELEMENT.TIME_DROPDOWN.HOUR": "Hours",
	"FORM_ELEMENT.TIME_DROPDOWN.MINUTE": "Minutes",
	"FORM_ELEMENT.ERROR.CONFLICTED_LABEL":
		"This label name cannot be chosen as it is used for internal labeling. Apologies for this inconvenience.",
	"FORM_ELEMENT.ERROR.FID_USERNAME_INVALID": "Invalid FID username",

	"FORM_ELEMENT.FORM_GROUP.ADD_LOCATION": "Add location",
	"FORM_ELEMENT.FORM_GROUP.LINK_CONTACT": "Link contact",
	"FORM_ELEMENT.RULE_FORM.BUTTON.ADD_COMPONENT": "Add new component",

	//GRAPH
	"GRAPH.HOUR_SHORT_HAND.H": "{hour} h",
	"GRAPH.HOUR_SHORT_HAND.MO": "Mo",
	"GRAPH.HOUR_SHORT_HAND.TU": "Tu",
	"GRAPH.HOUR_SHORT_HAND.WE": "We",
	"GRAPH.HOUR_SHORT_HAND.TH": "Th",
	"GRAPH.HOUR_SHORT_HAND.FR": "Fr",
	"GRAPH.HOUR_SHORT_HAND.SA": "Sa",
	"GRAPH.HOUR_SHORT_HAND.SU": "Su",

	//IMPORT_CONTACTS_MODAL.CONTACT_LIST
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.HEADER.TITLE": "Import contacts",
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.HEADER.DESCRIPTION":
		"Below is a list of contacts that cannot be imported because of missing data. Complete the missing info to be able to import the contacts.",
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.TABLE.USERNAME": "Name",
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.TABLE.EMAIL": "Email",
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.FOOTER.BACK.LABEL": "Change import options",
	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.FOOTER.IMPORT.LABEL":
		"Go to next step with {value, plural, =0 {all contacts} one {# contact} other {# contacts}}",

	"IMPORT_CONTACTS_MODAL.CONTACT_LIST.BANNER.FAILED": "Importing your contacts failed. Please try again later.",

	//IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS
	"IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.OPTIONS.CSV.LABEL": "Import from csv",
	"IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.OPTIONS.ADD_IMPORT_OPTIONS.LABEL": "Add import options",

	"IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.INFO_BANNER.TITLE": "New import features",
	"IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.INFO_BANNER.DESCRIPTION":
		"You can now also import contacts directly from FID-manager. Go to your integrations to connect FID-manager with Bothive and import contacts automatically.",

	// INBOX
	"inbox.page.title": "Inbox (BETA)",
	"INBOX.PAGE.TITLE": "Inbox (BETA)",

	// Coming soon
	"INBOX.COMING_SOON.TITLE": "Coming soon",
	"INBOX.COMING_SOON.DESCRIPTION": "The inbox for the modern accountant",
	"INBOX.COMING_SOON.FEATURES.ASSIGN": "Assigne team members",
	"INBOX.COMING_SOON.FEATURES.TEAM_INBOX": "Share your inbox with your team",
	"INBOX.COMING_SOON.FEATURES.INBOX_ZERO": "Build for inbox zero",
	"INBOX.COMING_SOON.BUTTON": "Request access",

	//INBOX ASIDE
	"INBOX.ASIDE.BUTTON.COMPOSE": "Compose",
	"INBOX.ASIDE.CHANNEL.TITLE": "Channel",
	"INBOX.ASIDE.CHANNEL.EMPTY.DESCRIPTION": "No channels found",
	"INBOX.ASIDE.CHANNEL.EMPTY.BUTTON": "Connect a channel",
	"INBOX.ASIDE.CHANNEL.RECONNECT.TITLE": "Reconnect this channel",
	"INBOX.ASIDE.LABELS.TITLE": "Labels",
	"INBOX.ASIDE.LABELS.EMPTY.DESCRIPTION": "No labels found",
	"INBOX.ASIDE.LABELS.EMPTY.BUTTON": "Add a label",
	"INBOX.ASIDE.LABELS.BUTTON.SHOW_LESS": "Show less labels",
	"INBOX.ASIDE.LABELS.BUTTON.ADD_LABELS": "Add label",
	"INBOX.ASIDE.LABELS.BUTTON.SHOW_MORE": "Show more labels",
	//INBOX.CONVERSATION_ASIDE
	"INBOX.CONVERSATION_ASIDE.CHIP.EMAIL": "E-mail",
	"INBOX.CONVERSATION_ASIDE.CHIP.MESSENGER": "Messenger",
	"INBOX.CONVERSATION_ASIDE.CHIP.DEFAULT": "Message",
	"INBOX.CONVERSATION_ASIDE.CHIP.WHATSAPP": "WhatsApp",

	"INBOX.CONVERSATION_ASIDE.PAYLOAD.BUTTONS": "Options",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.FILE": "File preview",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.INLINEBUTTONS": "Options",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.IMAGE": "Image",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.INVOICE": "Invoice",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.OPENINGHOURS": "Opening hours",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.UPLOAD": "Upload file",
	"INBOX.CONVERSATION_ASIDE.PAYLOAD.NOT_SUPPORTED": "Message preview not supported",

	"INBOX.CONVERSATION_ASIDE.SUB_HEADER.EMPTY_TRASH": "Empty trash",
	"INBOX.CONVERSATION_ASIDE.CONFIRM_DELETE_MODAL.EMPTY_TRASH.TITLE": "Empty trash",
	"INBOX.CONVERSATION_ASIDE.CONFIRM_DELETE_MODAL.EMPTY_TRASH.BUTTON_LABEL": "Empty trash",
	"INBOX.CONVERSATION_ASIDE.CONFIRM_DELETE_MODAL.EMPTY_TRASH.DESCRIPTION":
		"Are you sure you want to delete all conversation in trash?",
	"INBOX.CONVERSATION_ASIDE.CONFIRM_DELETE_MODAL.EMPTY_TRASH.EXTRA_DESCRIPTION":
		"Note: This conversation will also be deleted for all assigned or listed team members. Only if their email address is in the email header (to, from, cc, bcc) can they recover the conversation.",
	"INBOX.CONVERSATION_ASIDE.CONFIRM_DELETE_MODAL.EMPTY_TRASH.PLACEHOLDER":
		"Type {keyword} and press the delete button",
	"INBOX.CONVERSATION_ASIDE.CONFIRM_DELETE_MODAL.EMPTY_TRASH.ERROR": "Your input doesn't match {keyword}",

	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.UNSEEN": "Mark as unseen",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.SEEN": "Mark as seen",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.CLOSE": "Close conversation",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.OPEN": "Open conversation",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.UN_ARCHIVE": "Move to inbox",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.ARCHIVED": "Archive",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.SPAM": "Mark as spam",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.UN_SPAM": "Unspam",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.DELETE": "Delete conversation",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.UN_TRASH": "Put conversation back",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.DELETE_DRAFT": "Delete draft",
	"INBOX.CONVERSATION_ASIDE.CONTEXT_MENU.UN_TRASH_DRAFT": "Put draft back",

	//INBOX.CONVERSATION
	"INBOX.CONVERSATION.HEADER.BUTTONS.ASSIGN_USER": "Assign user",
	"INBOX.CONVERSATION.HEADER.BUTTONS.CLOSE": "Close conversation",
	"INBOX.CONVERSATION.HEADER.BUTTONS.OPEN": "Open conversation",
	"INBOX.CONVERSATION.HEADER.BUTTONS.UN_ARCHIVE": "Move to inbox",
	"INBOX.CONVERSATION.HEADER.BUTTONS.ARCHIVED": "Archive",
	"INBOX.CONVERSATION.HEADER.BUTTONS.SPAM": "Mark as spam",
	"INBOX.CONVERSATION.HEADER.BUTTONS.UN_SPAM": "Unspam",
	"INBOX.CONVERSATION.HEADER.BUTTONS.DELETE": "Delete conversation",
	"INBOX.CONVERSATION.HEADER.BUTTONS.UN_TRASH": "Put conversation back",

	"INBOX.CONVERSATION.FOOTER.EDITOR.label": "Only visible for your team",
	"INBOX.CONVERSATION.FOOTER.EDITOR.PLACEHOLDER": "Send an internal message",

	"INBOX.CONVERSATION.REPLY_FOOTER.REPLY": "Reply to conversation",
	"INBOX.CONVERSATION.REPLY_FOOTER.REPLY_ALL": "Reply to all",
	"INBOX.CONVERSATION.REPLY_FOOTER.FORWARD": "Forward email",
	"INBOX.CONVERSATION.REPLY_FOOTER.ASSIGN": "Assign user",

	"INBOX.CONVERSATION.LIST.FAILED_MESSAGE.TOOLTIP": "Conversation contains a failed message",
	"INBOX.CONVERSATION.LIST.DRAFT_MESSAGE.TOOLTIP": "Conversation contains a draft",

	//INBOX.SHORTCUTS
	"INBOX.SHORTCUTS.TITLE": "Keyboard shortcuts",

	"INBOX.SHORTCUTS.INBOX.TITLE": "Inbox",
	"INBOX.SHORTCUTS.INBOX.NEW_MESSAGE.LABEL": "Compose new message",
	"INBOX.SHORTCUTS.INBOX.NEXT_CONVERSATION.LABEL": "Go to next conversation",
	"INBOX.SHORTCUTS.INBOX.PREVIOUS_CONVERSATION.LABEL": "Go to previous conversation",
	"INBOX.SHORTCUTS.INBOX.left_status_folder.LABEL": "Go to the left status folder",
	"INBOX.SHORTCUTS.INBOX.right_status_folder.LABEL": "Go to the right status folder",
	"INBOX.SHORTCUTS.INBOX.inbox.search.focus.LABEL": "Focus searchbar",

	"INBOX.SHORTCUTS.CONVERSATION.TITLE": "Conversation",
	"INBOX.SHORTCUTS.CONVERSATION.REPLY.LABEL": "Reply to conversation",
	"INBOX.SHORTCUTS.CONVERSATION.REPLY_ALL.LABEL": "Reply to all",
	"INBOX.SHORTCUTS.CONVERSATION.FORWARD.LABEL": "Forward conversation",
	"INBOX.SHORTCUTS.CONVERSATION.internal_reply.LABEL": "Reply in internal chat",
	"INBOX.SHORTCUTS.CONVERSATION.CLOSE.LABEL": "Close/open conversation",
	"INBOX.SHORTCUTS.CONVERSATION.MARK_AS_UNREAD.LABEL": "Mark as seen/unseen",
	"INBOX.SHORTCUTS.CONVERSATION.OPEN_ASSIGN.LABEL": "Open assign modal",
	"INBOX.SHORTCUTS.CONVERSATION.OPEN_LABEL.LABEL": "Open labels modal",
	"INBOX.SHORTCUTS.CONVERSATION.OPEN_PARTICIPANTS.LABEL": "Open participants modal",
	"INBOX.SHORTCUTS.CONVERSATION.DELETE.LABEL": "Delete conversation",
	"INBOX.SHORTCUTS.CONVERSATION.toggle_self_assign.LABEL": "Assign yourself",

	"INBOX.SHORTCUTS.MESSAGING.TITLE": "Messaging",
	"INBOX.SHORTCUTS.MESSAGING.BOLD.LABEL": "Bold text",
	"INBOX.SHORTCUTS.MESSAGING.ITALIC.LABEL": "Italic text",
	"INBOX.SHORTCUTS.MESSAGING.UNDERLINED.LABEL": "Underlined text",

	"INBOX.SHORTCUTS.GLOBAL.TITLE": "Misc",
	"INBOX.SHORTCUTS.GLOBAL.CLOSE_MODAL.LABEL": "Close menu or popup",
	"INBOX.SHORTCUTS.GLOBAL.SHORTCUTS.LABEL": "Show shortcuts",

	//INBOX CHAT
	"INBOX.CHAT.NO_CHAT": "No chat selected",

	"INBOX.PAYLOAD.DOWNLOAD_FILE": "download file",

	//INBOX_LABEL_POPUP
	"INBOX_LABEL_POPUP.HEADER.TITLE": "Labels",
	"INBOX_LABEL_POPUP.FALLBACK.TITLE": "No labels found.",
	"INBOX_LABEL_POPUP.SEARCH.PLACEHOLDER": "Search on label name",
	"INBOX_LABEL_POPUP.FOOTER.ADD_LABEL": "Create new label",
	"INBOX_LABEL_POPUP.ASSIGNED_HEADER.TITLE": "Assigned labels",
	"INBOX_LABEL_POPUP.FALLBACK.ERROR.TITLE": "An error occurred while loading labels.",

	"INBOX_LABEL_POPUP.ERROR.TITLE": "Something went wrong while marking label ",
	"INBOX_LABEL_POPUP.ERROR.DESCRIPTION": "Something went wrong while adding label to the conversation.",

	//INBOX_SETTINGS
	"SETTINGS.INBOX.CHANNELS.TITLE": "Channels",
	"SETTINGS.INBOX.CHANNELS.CONNECTED.DESCRIPTION": "Connected with {channelName}",
	"SETTINGS.INBOX.CHANNELS.CONNECTED.BUTTON_LABEL": "Edit connection",

	"SETTINGS.INBOX.CHANNELS.CONNECTION.TITLE": "Connect a new channel",
	"SETTINGS.INBOX.CHANNELS.CONNECTION.DESCRIPTION":
		"Pick one of the following channels to connect those with your Bothive inbox.",
	"SETTINGS.INBOX.CHANNELS.CONNECTION.FALLBACK": "Could not find any channels",
	"SETTINGS.INBOX.CHANNELS.ROOT": "Settings",

	"SETTINGS.INBOX.CHANNELS.LOCK.TITLE": "Connect channels",
	"SETTINGS.INBOX.CHANNELS.LOCK.DESCRIPTION":
		"Connect your Gmail or Outlook inbox with the Bothive team inbox and work together.",
	"SETTINGS.INBOX.CHANNELS.LOCK.BUTTON.CONTACT_SUPPORT": "Upgrade your plan",

	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.HEADER.TITLE": "Setup channel",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.HEADER.DESCRIPTION": "Configure your new email channel",

	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.NAME.LABEL": "name",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.NAME.ERROR.IS_REQUIRED": "A channel name is required",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.AUTO_CLOSE.LABEL": "Auto close",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.AUTO_CLOSE.HELP":
		"Automatically mark conversations you've already seen as closed",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.IS_PRIVATE.LABEL": "Is private",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.IS_PRIVATE.HELP":
		"Private channels can only be seen by you or the team owner('s) not be other team members.",

	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.FORM.SUBMIT": "Create channel",

	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.IS_PUBLIC_WARNING.TITLE": "Warning",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SETUP.IS_PUBLIC_WARNING.DESCRIPTION":
		"Your channel will be able to be added to their inbox by anyone on your team. These will be able to receive, send, reply, edit and delete messages.",

	"SETTINGS.INBOX.CONNECT_CHANNELS.SYNCING.HEADER.EXECUTING.TITLE": "Synchronizing",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SYNCING.HEADER.EXECUTING.DESCRIPTION":
		"We've initiated the synchronization of your inbox. This process might take a few minutes, but rest assured, you can continue using our platform during this synchronization.",
	"SETTINGS.INBOX.CONNECT_CHANNELS.SYNCING.BUTTON.CLOSE": "Close synchronization screen",
	"SETTINGS.INBOX.CONNECT_CHANNELS.ERROR.BLOCKED_POPUP":
		"In order to log in, we need to be able to open a popup. You have to give us permission for this in your browser settings.",

	//INBOX_SETTINGS_PROFILE
	"INBOX_SETTINGS_PROFILE.DETAIL.PAGE.TITLE": "Inbox settings",
	"INBOX_SETTINGS_PROFILE.DETAIL.ASIDE.INBOX.LABEL": "Inbox settings",
	"INBOX_SETTINGS_PROFILE.DETAIL.ASIDE.CHANNELS.LABEL": "Channels",
	"INBOX_SETTINGS_PROFILE.DETAIL.ASIDE.LABELS.LABEL": "Labels",
	"INBOX_SETTINGS_PROFILE.DETAIL.ASIDE.SIGNATURES.LABEL": "Signatures",
	"INBOX_SETTINGS_PROFILE.DETAIL.ASIDE.TEMPLATES.LABEL": "Templates",

	//INBOX_SETTINGS_PROFILE.CHANNEL_DETAIL
	"INBOX_SETTINGS_PROFILE.CHANNEL_DETAIL.DETAILS.FORM.NAME.LABEL": "name",
	"INBOX_SETTINGS_PROFILE.CHANNEL_DETAIL.DETAILS.FORM.ORGANIZATION.LABEL": "Connect to team",
	"INBOX_SETTINGS_PROFILE.CHANNEL_DETAIL.DETAILS.FORM.IS_PRIVATE.LABEL": "Is private",
	"INBOX_SETTINGS_PROFILE.CHANNEL_DETAIL.DETAILS.FORM.IS_PRIVATE.HELP":
		"Private channels can only be seen by you or the team owner('s) not be other team members.",

	//INBOX_SETTINGS_PROFILE.SIGNATURES
	"INBOX_SETTINGS_PROFILE.SIGNATURES.TABLE.ADD_SIGNATURE": "Add signature",

	//INTEGRATION
	"SETTINGS.INTEGRATIONS.OVERVIEW.PAGE.TITLE": "Integrations overview",
	"SETTINGS.INTEGRATIONS.OVERVIEW.HEADER.BREADCRUMB.SETTINGS": "Settings",
	"SETTINGS.INTEGRATIONS.OVERVIEW.HEADER.BREADCRUMB.PAGE_TITLE": "Integrations overview",

	"SETTINGS.INTEGRATIONS.OVERVIEW.FALLBACK.TITLE": "Something went wrong",
	"SETTINGS.INTEGRATIONS.OVERVIEW.FALLBACK.DESCRIPTION":
		"Integrations overview cannot be shown. If this keeps happing pleas contact support@bothive.be",

	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.TITLE": "Integrations",
	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.DESCRIPTION":
		"Integrate with different platform you use to work beter and smarter. Through integrations you can make Bothive smarter and integrate with all your communication channels or extend Bothive functionalities. If you can't find your integration, contact us and we will added for you.",
	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.NO_INTEGRATIONS":
		"You have no integrations in your current subscription. Upgrade to start using integrations.",
	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.NO_INTEGRATIONS_NO_UPGRADE":
		"You have no integrations in your current subscription. Contact our support team to upgrade your subscription.",
	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.UPGRADE_BUTTON": "Upgrade subscription",

	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.PROGRESS_LABEL":
		"You have {value, plural, =0 {no integrations} one {# integration} other {# integrations}} left in your current plan.",
	"SETTINGS.INTEGRATIONS.OVERVIEW.INFO.PROGRESS_LABEL_UNLIMITED":
		"You have unlimited integrations in your current plan.",

	"SETTINGS.INTEGRATIONS.OVERVIEW.ACTIVE.TITLE": "Active integration",
	"SETTINGS.INTEGRATIONS.OVERVIEW.ACTIVE.FALLBACK": "You haven't made any integrations yet.",
	"SETTINGS.INTEGRATIONS.OVERVIEW.ALL_INTEGRATIONS.TITLE": "All integrations",
	"SETTINGS.INTEGRATIONS.OVERVIEW.ALL_INTEGRATIONS.FALLBACK":
		"There are no more integrations available. Contact us if you couldn't find your integration and we will add it for you.",

	"SETTINGS.INTEGRATIONS.OVERVIEW.REQUEST_INTEGRATION.TITLE": "Request integrations",
	"SETTINGS.INTEGRATIONS.OVERVIEW.REQUEST_INTEGRATION.DESCRIPTION":
		"Can’t find the integration with your software in the list? Contact us through our customer service and we will contact you as soon as possible to discuss this integration.",
	"SETTINGS.INTEGRATIONS.OVERVIEW.REQUEST_INTEGRATION.BUTTON": "Request integration",

	// SETTINGS.INTEGRATIONS.CONTROLLER
	"SETTINGS.INTEGRATIONS.CONTROLLER.SECTION.BACK_BUTTON": "Back to integrations",

	// SETTINGS.INTEGRATIONS.ADMIN_PULSE
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.PAGE.TITLE": "AdminPulse integration",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.BREADCRUMB.PAGE_TITLE": "AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.TITLE": "AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.DESCRIPTION":
		"Connect with AdminPulse to easily sync your contacts with Bothive and sync flow's with AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.FEATURES.SYNC_CONTACTS": "Synchronize contacts",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.OAUTH2.DESCRIPTION": "Connect with Admin Pulse through their login.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.LOGIN.OAUTH2.BUTTON": "Log in with Admin Pulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.TITLE": "Delete integration with AdminPulse",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to stop the integration with AdminPulse? All current functionalities will not be available to your users anymore when you delete this integration.",
	"SETTINGS.INTEGRATIONS.ADMIN_PULSE.DANGER_ZONE.BUTTON": "Delete integration",

	// SETTINGS.INTEGRATIONS.BILLIT
	"SETTINGS.INTEGRATIONS.BILLIT.PAGE.TITLE": "Billit integration",
	"SETTINGS.INTEGRATIONS.BILLIT.BREADCRUMB.PAGE_TITLE": "Billit",
	"SETTINGS.INTEGRATIONS.BILLIT.LOGIN.TITLE": "Billit",
	"SETTINGS.INTEGRATIONS.BILLIT.LOGIN.DESCRIPTION":
		"Connect with Billit to extend Bothive's knowledge and functionalities.",
	"SETTINGS.INTEGRATIONS.BILLIT.LOGIN.FEATURES.UPLOAD_INVOICES": "Upload invoices",
	"SETTINGS.INTEGRATIONS.BILLIT.LOGIN.OAUTH2.DESCRIPTION": "Connect with Billit through their login.",
	"SETTINGS.INTEGRATIONS.BILLIT.LOGIN.OAUTH2.BUTTON": "Log in with Billit",
	"SETTINGS.INTEGRATIONS.BILLIT.FORM_SECTION.TITLE": "Bothive is connected with Billit",
	"SETTINGS.INTEGRATIONS.BILLIT.FORM_SECTION.DESCRIPTION":
		"Allow your customers to request basic information about their account.",
	"SETTINGS.INTEGRATIONS.BILLIT.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.BILLIT.DANGER_ZONE.TITLE": "Delete integration with Billit",
	"SETTINGS.INTEGRATIONS.BILLIT.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to stop the integration with Billit? All current functionalities will not be available to your users anymore when you delete this integration.",
	"SETTINGS.INTEGRATIONS.BILLIT.DANGER_ZONE.BUTTON": "Delete integration",

	// SETTINGS.INTEGRATIONS.EXACT
	"SETTINGS.INTEGRATIONS.EXACT.PAGE.TITLE": "Exact integration",
	"SETTINGS.INTEGRATIONS.EXACT.BREADCRUMB.PAGE_TITLE": "Exact",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.TITLE": "Exact",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.DESCRIPTION":
		"Connect with Exact to extend Bothive's knowledge and give personalized answers.",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.FEATURES.GET_BALANCE": "Get temporary balance",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.FEATURES.SEE_OPEN_INVOICES": "Get all open invoices",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.FEATURES.SEE_OPEN_DEBTOR": "Get all clients who still need to pay",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.FEATURES.SEE_PROFIT": "See your profit or loss",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.OAUTH2.DESCRIPTION": "Connect with exact through their login.",
	"SETTINGS.INTEGRATIONS.EXACT.LOGIN.OAUTH2.BUTTON": "Log in with Exact",

	"SETTINGS.INTEGRATIONS.EXACT.FORM_SECTION.TITLE": "Bothive is connected with Exact",
	"SETTINGS.INTEGRATIONS.EXACT.FORM_SECTION.DESCRIPTION":
		"Allow your customers to request basic information about their account.",

	"SETTINGS.INTEGRATIONS.EXACT.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.EXACT.DANGER_ZONE.TITLE": "Delete integration with Exact",
	"SETTINGS.INTEGRATIONS.EXACT.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to stop the integration with Exact? All current functionalities will not be available to your users anymore when you delete this integration.",
	"SETTINGS.INTEGRATIONS.EXACT.DANGER_ZONE.BUTTON": "Delete integration",

	// SETTINGS.INTEGRATIONS.LIANTIS
	"SETTINGS.INTEGRATIONS.LIANTIS.PAGE.TITLE": "Liantis integration",
	"SETTINGS.INTEGRATIONS.LIANTIS.BREADCRUMB.PAGE_TITLE": "Liantis",
	"SETTINGS.INTEGRATIONS.LIANTIS.LOGIN.TITLE": "Liantis",

	"SETTINGS.INTEGRATIONS.LIANTIS.FORM_SECTION.TITLE": "Bothive is connected with Liantis",
	"SETTINGS.INTEGRATIONS.LIANTIS.FORM_SECTION.DESCRIPTION": "Send flow data automatically to Liantis",

	"SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.TITLE": "Connect with Liantis",
	"SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.DESCRIPTION": "Connect Liantis to Bothive to send flow data",
	"SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.BUTTON": "Login to Liantis",

	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.TITLE": "Delete integration with Liantis",
	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to stop the integration with Liantis? All current functionalities will not be available when you delete this integration.",
	"SETTINGS.INTEGRATIONS.LIANTIS.DANGER_ZONE.BUTTON": "Delete integration",

	// SETTINGS.INTEGRATIONS.FID_MANAGER
	"SETTINGS.INTEGRATIONS.FID_MANAGER.PAGE.TITLE": "Fid-Manager integration",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.BREADCRUMB.PAGE_TITLE": "Fid-Manager",

	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.TITLE": "Fid-Manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.DESCRIPTION":
		"Connect with Fid-Manager to easily sync your contacts with Bothive and sync flow's with FID-manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FEATURES.SYNC_CONTACTS": "Synchronize contacts",

	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.BASE_URL.LABEL": "Base url",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.BASE_URL.PLACEHOLDER": "https://company-name.fid-manager.be/nl",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.LABEL": "Login",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.LOGIN.PLACEHOLDER": "a000a000-000a-0000-a0aa-aaa00a00a0000",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.PASSWORD.LABEL": "Password",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.LOGIN.FORM.SUBMIT": "Connect with Fid-Manager",

	"SETTINGS.INTEGRATIONS.FID_MANAGER.FORM_SECTION.TITLE": "Bothive is connected with Fid-Manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.FORM_SECTION.DESCRIPTION":
		"By connecting with Fid-Manager Bothive can automatically import data from flow reports into your Fid-Manager application, synchronize and update contacts.",

	"SETTINGS.INTEGRATIONS.FID_MANAGER.SYNC_CONTACTS.TITLE": "Import your Fid-Manager customers as contacts",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.SYNC_CONTACTS.DESCRIPTION":
		"Automatically import your customers from within Fid-Manager into your Bothive team to link both contacts and improve data synchronization and contact detection.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.SYNC_CONTACTS.BUTTON_LABEL": "Import contacts",

	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.TITLE": "Delete integration with Fid-Manager",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to stop the integration with Fid-Manager? All current functionalities will not be available when you delete this integration.",
	"SETTINGS.INTEGRATIONS.FID_MANAGER.DANGER_ZONE.BUTTON": "Delete integration",

	// SETTINGS.INTEGRATIONS.MESSENGER
	"SETTINGS.INTEGRATIONS.MESSENGER.PAGE.TITLE": "Messenger integration",
	"SETTINGS.INTEGRATIONS.MESSENGER.BREADCRUMB.PAGE_TITLE": "Messenger",

	"SETTINGS.INTEGRATIONS.MESSENGER.CONNECT.TITLE": "Connect with your Facebook pagina",
	"SETTINGS.INTEGRATIONS.MESSENGER.CONNECT.DESCRIPTION":
		"Connect your Facebook account to Bothive to use our inbox and all our functionalities with Facebook Messenger. We will not post on your Facebook without your permission.",
	"SETTINGS.INTEGRATIONS.MESSENGER.CONNECT.BUTTON": "Login with Facebook",

	"SETTINGS.INTEGRATIONS.MESSENGER.PAGE_OVERVIEW.TITLE": "Your Facebook pages",
	"SETTINGS.INTEGRATIONS.MESSENGER.PAGE_OVERVIEW.DESCRIPTION":
		"Select the Facebook page that you want to connect with Bothive.",
	"SETTINGS.INTEGRATIONS.MESSENGER.PAGE_OVERVIEW.FALLBACK": "You are not a Facebook page administrator.",

	"INTEGRATION.PLATFORM_LIST.CONNECTED": "Connected",
	"INTEGRATION.PLATFORM_LIST.CONNECT": "Connect",
	"INTEGRATION.PLATFORM_LIST.DISCONNECT": "Disconnect",

	// SETTINGS.INTEGRATIONS.MAILCHIMP
	"SETTINGS.INTEGRATIONS.MAILCHIMP.PAGE.TITLE": "Mailchimp integrations",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.BREADCRUMB.PAGE_TITLE": "Mailchimp",

	"SETTINGS.INTEGRATIONS.MAILCHIMP.LOGIN.TITLE": "Mailchimp",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.LOGIN.DESCRIPTION":
		"Connect with Mailchimp to automatically add new clients to your mailing list.",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.LOGIN.FORM.API_KEY.TITLE": "API-key",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.LOGIN.FORM.API_KEY.PLACEHOLDER": "af0000afda00000af000afc000000000-us4",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.LOGIN.FORM.SUBMIT": "Connect with Mailchimp",

	"SETTINGS.INTEGRATIONS.MAILCHIMP.FORM_SECTION.TITLE": "Bothive is connected with Mailchimp",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.FORM_SECTION.DESCRIPTION":
		"When Bothive request or detects a email address, Bothive will automatically add it to your Mailchimp mailing list.",

	"SETTINGS.INTEGRATIONS.MAILCHIMP.SETTINGS.LIST.TITLE": "Mailing list",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.SETTINGS.LIST.HELP":
		"Select the mailing list where you want to add new clients to.",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.SETTINGS.DUBBEL_OPT_IN.LABEL":
		"Only subscribe user to mailing list after dubbel opt-in",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.SETTINGS.DUBBEL_OPT_IN.HELP":
		"This is a required option to be GDPR compliant. If you turn this option off, you will be responsible to get the users permission.",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.SETTINGS.TAGS.TITLE": "Tags",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.SETTINGS.TAGS.HELP": "Press enter to add a new tag.",

	"SETTINGS.INTEGRATIONS.MAILCHIMP.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.DANGER_ZONE.TITLE": "Delete integration with Mailchimp",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to stop the integration with Mailchimp? All current functionalities will not be available to your users anymore when you delete this integration.",
	"SETTINGS.INTEGRATIONS.MAILCHIMP.DANGER_ZONE.BUTTON": "Delete integration",

	// SETTINGS.INTEGRATIONS.OCTOPUS
	"SETTINGS.INTEGRATIONS.OCTOPUS.PAGE.TITLE": "Octopus integration",
	"SETTINGS.INTEGRATIONS.OCTOPUS.BREADCRUMB.PAGE_TITLE": "Octopus",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.TITLE": "Octopus",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.DESCRIPTION":
		"Connect with Octopus to extend Bothive's knowledge and give personalized answers.",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.FEATURES.GET_BALANCE": "Get temporary balance",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.FEATURES.SEE_OPEN_INVOICES": "Get all open invoices",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.FEATURES.SEE_OPEN_DEBTOR": "Get all clients who still need to pay",

	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.FORM.NAME": "Name",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.FORM.PASSWORD": "Password",
	"SETTINGS.INTEGRATIONS.OCTOPUS.LOGIN.FORM.SUBMIT": "Connect with Octopus",

	"SETTINGS.INTEGRATIONS.OCTOPUS.FORM_SECTION.TITLE": "Bothive is connected with Octopus",
	"SETTINGS.INTEGRATIONS.OCTOPUS.FORM_SECTION.DESCRIPTION":
		"Allow your customers to request basic information about their account.",

	"SETTINGS.INTEGRATIONS.OCTOPUS.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.OCTOPUS.DANGER_ZONE.TITLE": "Delete integration with Octopus",
	"SETTINGS.INTEGRATIONS.OCTOPUS.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to stop the integration with Octopus? All current functionalities will not be available to your users anymore when you delete this integration.",
	"SETTINGS.INTEGRATIONS.OCTOPUS.DANGER_ZONE.BUTTON": "Delete integration",

	// SETTINGS.INTEGRATIONS.SILVERFIN
	"SETTINGS.INTEGRATIONS.SILVERFIN.PAGE.TITLE": "Silverfin integration",
	"SETTINGS.INTEGRATIONS.SILVERFIN.BREADCRUMB.PAGE_TITLE": "Silverfin",
	"SETTINGS.INTEGRATIONS.SILVERFIN.LOGIN.TITLE": "Connect with your silverfin account",
	"SETTINGS.INTEGRATIONS.SILVERFIN.LOGIN.DESCRIPTION":
		"By connecting with Silverfin you give Bothive the ability to view the customers files and respond accordingly.",
	"SETTINGS.INTEGRATIONS.SILVERFIN.LOGIN.BUTTON": "Login with Silverfin",

	"SETTINGS.INTEGRATIONS.SILVERFIN.CONNECTED.TITLE": "You are connected with Silverfin",
	"SETTINGS.INTEGRATIONS.SILVERFIN.CONNECTED.DESCRIPTION":
		"By connecting with Silverfin you give Bothive the ability to view the customers files and respond accordingly.",

	"SETTINGS.INTEGRATIONS.SILVERFIN.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.SILVERFIN.DANGER_ZONE.TITLE": "Delete integration with Silverfin",
	"SETTINGS.INTEGRATIONS.SILVERFIN.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to stop the integration with Silverfin? All current functionalities will not be available to your users anymore when you delete this integration.",
	"SETTINGS.INTEGRATIONS.SILVERFIN.DANGER_ZONE.BUTTON": "Delete integration",

	// SETTINGS.INTEGRATIONS.SLACK
	"SETTINGS.INTEGRATIONS.SLACK.PAGE.TITLE": "Slack integration",
	"SETTINGS.INTEGRATIONS.SLACK.BREADCRUMB.PAGE_TITLE": "Slack",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.TITLE": "Invite Bothive in your Slack workspace",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.DESCRIPTION":
		"By inviting Bothive inside your Slack workspace, Bothive will be able to notify you and your workspace when something important happens like new live chat request, appointment made, email received, ...",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.FEATURES.LIVE_CHAT": "New live request",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.FEATURES.ASSIGNED_TO_CONVERSATION": "Assigned to conversation",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.FEATURES.FLOW_RAPPORT": "New flow rapport created",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.FEATURES.EMAIL_RECEIVED": "Email received",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.BUTTON": "Login with Slack",

	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.OAUTH2.DESCRIPTION": "Connect with Slack through their login.",
	"SETTINGS.INTEGRATIONS.SLACK.LOGIN.OAUTH2.BUTTON": "Log in with Slack",

	"SETTINGS.INTEGRATIONS.SLACK.CONNECTED.TITLE": "Bothive joined your Slack workspace",
	"SETTINGS.INTEGRATIONS.SLACK.CONNECTED.DESCRIPTION":
		"By inviting Bothive inside your Slack workspace, Bothive will be able to notify you and your workspace when something important happens like new live chat request, appointment made, email received, ...",

	"SETTINGS.INTEGRATIONS.SLACK.CHANNELS.DEFAULT_VALUE": "--Not subscribed--",
	"SETTINGS.INTEGRATIONS.SLACK.CHANNELS.INBOX.LABEL": "Inbox notifications",
	"SETTINGS.INTEGRATIONS.SLACK.CHANNELS.INBOX.HELP":
		"All notifications related to the team inbox for example live chat request or new email will be posted in this channel.",

	"SETTINGS.INTEGRATIONS.SLACK.CHANNELS.FLOW_REPORT.LABEL": "Flow notifications",
	"SETTINGS.INTEGRATIONS.SLACK.CHANNELS.FLOW_REPORT.HELP":
		"Notification will be posted in the channel when a new report is created.",

	"SETTINGS.INTEGRATIONS.SLACK.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.SLACK.DANGER_ZONE.TITLE": "Delete integration with Slack",
	"SETTINGS.INTEGRATIONS.SLACK.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want Bothive to leave your Slack workspace? Bothive will not be able to send notification or other important messages in your Slack channel.",
	"SETTINGS.INTEGRATIONS.SLACK.DANGER_ZONE.BUTTON": "Delete integration",

	// SETTINGS.INTEGRATIONS.TEAMS
	"SETTINGS.INTEGRATIONS.TEAMS.PAGE.TITLE": "Teams integration",
	"SETTINGS.INTEGRATIONS.TEAMS.BREADCRUMB.PAGE_TITLE": "Teams",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.TITLE": "Invite Bothive in your Teams workspace",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.DESCRIPTION":
		"By inviting Bothive inside your Teams workspace, Bothive will be able to notify you and your workspace when something important happens like new live chat request, appointment made, email received, ...",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.FEATURES.LIVE_CHAT": "New live request",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.FEATURES.ASSIGNED_TO_CONVERSATION": "Assigned to conversation",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.FEATURES.FLOW_RAPPORT": "New flow rapport created",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.FEATURES.EMAIL_RECEIVED": "Email received",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.BUTTON": "Login with Teams",

	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.OAUTH2.DESCRIPTION": "Connect with Teams through their login.",
	"SETTINGS.INTEGRATIONS.TEAMS.LOGIN.OAUTH2.BUTTON": "Log in with Teams",

	"SETTINGS.INTEGRATIONS.TEAMS.CONNECTED.TITLE": "Bothive joined your Teams workspace",
	"SETTINGS.INTEGRATIONS.TEAMS.CONNECTED.DESCRIPTION":
		"By inviting Bothive inside your Teams workspace, Bothive will be able to notify you and your workspace when something important happens like new live chat request, appointment made, email received, ...",

	"SETTINGS.INTEGRATIONS.TEAMS.CHANNELS.DEFAULT_VALUE": "--Not subscribed--",
	"SETTINGS.INTEGRATIONS.TEAMS.CHANNELS.INBOX.LABEL": "Inbox notifications",
	"SETTINGS.INTEGRATIONS.TEAMS.CHANNELS.INBOX.HELP":
		"All notifications related to the team inbox for example live chat request or new email will be posted in this channel.",

	"SETTINGS.INTEGRATIONS.TEAMS.CHANNELS.FLOW_REPORT.LABEL": "Flow notifications",
	"SETTINGS.INTEGRATIONS.TEAMS.CHANNELS.FLOW_REPORT.HELP":
		"Notification will be posted in the channel when a new report is created.",

	"SETTINGS.INTEGRATIONS.TEAMS.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.TEAMS.DANGER_ZONE.TITLE": "Delete integration with Teams",
	"SETTINGS.INTEGRATIONS.TEAMS.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want Bothive to leave your Teams workspace? Bothive will not be able to send notification or other important messages in your Teams channel.",
	"SETTINGS.INTEGRATIONS.TEAMS.DANGER_ZONE.BUTTON": "Delete integration",

	// SETTINGS.INTEGRATIONS.TWINNTAX
	"SETTINGS.INTEGRATIONS.TWINNTAX.PAGE.TITLE": "Twinntax integration",
	"SETTINGS.INTEGRATIONS.TWINNTAX.BREADCRUMB.PAGE_TITLE": "Twinntax",
	"SETTINGS.INTEGRATIONS.TWINNTAX.LOGIN.TITLE": "Twinntax",

	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM_SECTION.TITLE": "Bothive is connected with Twinntax",
	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM_SECTION.DESCRIPTION":
		"Sync MyMinfin files from Twinntax into the PB flow of your contacts based on their national registration number.",

	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM.APIKEY": "Api key",
	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM.APIKEY.REQUIRED": "Please fill in an api key",
	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM.SUBMIT": "Connect with Twinntax",
	"SETTINGS.INTEGRATIONS.TWINNTAX.FORM.HELP":
		"You can create an apikey in your Twinntax dashboard under Instellingen -> API Instellingen -> Creëer een API sleutel",

	"SETTINGS.INTEGRATIONS.TWINNTAX.CONNECT.TITLE": "Connect with Twinntax",
	"SETTINGS.INTEGRATIONS.TWINNTAX.CONNECT.DESCRIPTION":
		"Sync MyMinfin files from Twinntax into the PB flow of your contacts based on their national registration number.",
	"SETTINGS.INTEGRATIONS.TWINNTAX.CONNECT.BUTTON": "Login to Twinntax",

	"SETTINGS.INTEGRATIONS.TWINNTAX.SYNC_CONTACTS.TITLE": "Import your Twinntax customers as contacts",
	"SETTINGS.INTEGRATIONS.TWINNTAX.SYNC_CONTACTS.DESCRIPTION":
		"Automatically import your customers from within Twinntax into your Bothive team to link both contacts and improve data synchronization and contact detection.",
	"SETTINGS.INTEGRATIONS.TWINNTAX.SYNC_CONTACTS.BUTTON_LABEL": "Import contacts",

	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.TITLE": "Delete integration with Twinntax",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to stop the integration with Twinntax? All current functionalities will not be available when you delete this integration.",
	"SETTINGS.INTEGRATIONS.TWINNTAX.DANGER_ZONE.BUTTON": "Delete integration",

	// SETTINGS.INTEGRATIONS.TWILIO
	"SETTINGS.INTEGRATIONS.TWILIO.PAGE.TITLE": "Twilio integration",
	"SETTINGS.INTEGRATIONS.TWILIO.BREADCRUMB.PAGE_TITLE": "Twilio",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.TITLE": "Twilio",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.DESCRIPTION":
		"Connect your Twilio WhatsApp account to Bothive to use our inbox and all our functionalities with WhatsApp.",

	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.SID.LABEL": "Account SID",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.SID.PLACEHOLDER": "ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.SID.HELP": "Your Account SID from www.twilio.com/console.",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.AUTH_TOKEN.LABEL": "Auth token",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.AUTH_TOKEN.HELP": "Your Auth Token from www.twilio.com/console",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.WHATSAPP_NUMBER.LABEL": "Whatsapp nummer",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.WHATSAPP_NUMBER.PLACEHOLDER": "+32460000000",
	"SETTINGS.INTEGRATIONS.TWILIO.LOGIN.FORM.SUBMIT": "Connect with Twilio",

	"SETTINGS.INTEGRATIONS.TWILIO.DETAIL.ACCOUNT_ID.LABEL": "Account id:",
	"SETTINGS.INTEGRATIONS.TWILIO.DETAIL.PHONE_NUMBER.LABEL": "WhatsApp number:",

	"SETTINGS.INTEGRATIONS.TWILIO.FORM_SECTION.TITLE": "Bothive is connected with Twilio",

	"SETTINGS.INTEGRATIONS.TWILIO.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.TWILIO.DANGER_ZONE.TITLE": "Delete integration with Twilio",
	"SETTINGS.INTEGRATIONS.TWILIO.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to stop the integration with Twilio? All current functionalities will not be available to your users anymore when you delete this integration.",
	"SETTINGS.INTEGRATIONS.TWILIO.DANGER_ZONE.BUTTON": "Delete integration",

	// SETTINGS.INTEGRATIONS.WHATS_APP
	"SETTINGS.INTEGRATIONS.CM.PAGE.TITLE": "WhatsApp integration",
	"SETTINGS.INTEGRATIONS.CM.BREADCRUMB.PAGE_TITLE": "WhatsApp",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.TITLE": "WhatsApp",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.DESCRIPTION":
		"Connect your WhatsApp business account to Bothive to use our inbox and all our functionalities with WhatsApp. \n\nContact us to get a WhatsApp business account",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FOOTER.LABEL": "Powered by",

	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.API_KEY": "Api-key",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.API_KEY.PLACEHOLDER": "00000000-0000-0000-0000-000000000000",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.API_KEY.HELP": "Your api-key can be found in the gateway app.",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.CHANNEL_ID": "Channel ID",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.CHANNEL_ID.PLACEHOLDER": "00000000-0000-0000-0000-000000000000",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.CHANNEL_ID.HELP": "Your channel id can be found in url of CM channel.",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.ACCOUNT_ID": "Account ID",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.ACCOUNT_ID.PLACEHOLDER": "00000000-0000-0000-0000-000000000000",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.ACCOUNT_ID.HELP": "Your account id can be found in url of CM channel.",
	"SETTINGS.INTEGRATIONS.CM.LOGIN.FORM.SUBMIT": "Connect with WhatsApp",

	"SETTINGS.INTEGRATIONS.CM.DETAIL.ACCOUNT_ID.LABEL": "Account id:",
	"SETTINGS.INTEGRATIONS.CM.DETAIL.PHONE_NUMBER.LABEL": "Phone number:",
	"SETTINGS.INTEGRATIONS.CM.DETAIL.QR_CODE.BUTTON_DOWNLOAD": "Download QR code",

	"SETTINGS.INTEGRATIONS.CM.FORM_SECTION.TITLE": "Bothive is connected with WhatsApp business",

	"SETTINGS.INTEGRATIONS.CM.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.CM.DANGER_ZONE.TITLE": "Delete WhatsApp business integration",
	"SETTINGS.INTEGRATIONS.CM.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to stop your WhatsApp business account? All current functionalities will not be available to your users anymore when you delete this integration.\n\n\rContact us and we will disconnect your WhatsApp business integration.",

	// SETTINGS.INTEGRATIONS.YUKI
	"SETTINGS.INTEGRATIONS.YUKI.PAGE.TITLE": "Yuki integration",
	"SETTINGS.INTEGRATIONS.YUKI.BREADCRUMB.PAGE_TITLE": "Yuki",

	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.TITLE": "Yuki",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.DESCRIPTION":
		"Connecteer with Yuki to extend Bothive's knowledge and give personalized answers.",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.FEATURES.GET_BALANCE": "Get temporary balance",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.FEATURES.SEE_OPEN_INVOICES": "Get all open invoices",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.FEATURES.SEE_OPEN_DEBTOR": "Get all clients who still need to pay",

	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.FORM.NAME": "Name",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.FORM.PASSWORD": "Password",
	"SETTINGS.INTEGRATIONS.YUKI.LOGIN.FORM.SUBMIT": "Connect with Yuki",

	"SETTINGS.INTEGRATIONS.YUKI.FORM_SECTION.TITLE": "Bothive is connected with Yuki",
	"SETTINGS.INTEGRATIONS.YUKI.FORM_SECTION.DESCRIPTION":
		"Allow your customers to request basic information about their account.",

	"SETTINGS.INTEGRATIONS.YUKI.DANGER_ZONE.ACCORDION_TITLE": "Delete integration",
	"SETTINGS.INTEGRATIONS.YUKI.DANGER_ZONE.TITLE": "Delete integration with Yuki",
	"SETTINGS.INTEGRATIONS.YUKI.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to stop the integration with Yuki? All current functionalities will not be available to your users anymore when you delete this integration.",
	"INTEGRATION_YUKI.DANGER_ZONE.BUTTON": "Delete integration",

	//INTEGRATION_NOTIFICATIONS
	"SETTINGS.INTEGRATIONS.NOTIFICATION.SUCCESS": "{integrationName} is removed!",
	"SETTINGS.INTEGRATIONS.NOTIFICATION.FAILED": "{integrationName} could not be removed, Please try again later!",

	//INTENT_CATEGORIES
	"SETTINGS.INTENT.CATEGORIES.PAGE.TITLE": "Intents categories",
	"SETTINGS.INTENT.CATEGORIES.HEADER.BREADCRUMB.PAGE_TITLE": "Intent categories",
	"SETTINGS.INTENT.CATEGORIES.HEADER.BREADCRUMB.SETTINGS": "Settings",
	"SETTINGS.INTENT.CATEGORIES.TABLE.CATEGORY_NAME": "Category name",

	//INTENT CUSTOMIZE
	"SETTINGS.INTENT.CATEGORIES.FALLBACK.TITLE": "Intents overview",
	"SETTINGS.INTENT.CATEGORIES.FALLBACK.DESCRIPTION":
		"Viewing intents is currently not available in your current plan, upgrade to a higher plan to be able to use this functionality.",

	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.NO_INTENTS_FOUND": "No intents found",
	"SETTINGS.INTENT.CATEGORIES.NO_INTENTS.SUGGEST_NEW_INTENT": "Suggest new intent",

	"SETTINGS.INTENT.CATEGORY_DETAIL.SEARCH.PLACEHOLDER": "Search on intent name",
	"SETTINGS.INTENT.CATEGORY_DETAIL.FILTER_DROPDOWN.NO_FILTER": "No filter",
	"SETTINGS.INTENT.CATEGORY_DETAIL.FILTER_DROPDOWN.EDITED_INTENTS_ONLY": "Edited intents",
	"SETTINGS.INTENT.CATEGORY_DETAIL.FILTER_DROPDOWN.UNEDITED_INTENTS_ONLY": "Unedited intents",
	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.FALLBACK": "Could not find any intents",
	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.INTENT_NAME": "Intent name",
	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.TYPE": "Type",
	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.TYPE_STATIC": "Static",
	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.TYPE_DYNAMIC": "Dynamic",
	"SETTINGS.INTENT.CATEGORY_DETAIL.TABLE.IS_EDITED": "Has been edited",

	// Setting suggestions
	"SETTINGS.SUGGESTIONS.CONTACT_LABElS.TITLE": "Contact labels",
	"SETTINGS.SUGGESTIONS.CONTACT_LABElS.DESCRIPTION": "Segment/group your contacts by contact labels.",
	"SETTINGS.SUGGESTIONS.CONTACT_LABElS.BUTTON": "Manage labels",

	"SETTINGS.SUGGESTIONS.OPENING_HOURS.TITLE": "Opening hours",
	"SETTINGS.SUGGESTIONS.OPENING_HOURS.DESCRIPTION":
		"Define your opening hours so {agent} can help your customers even better",
	"SETTINGS.SUGGESTIONS.OPENING_HOURS.BUTTON": "Manage your Opening hours",

	"SETTINGS.SUGGESTIONS.BRANDING.TITLE": "Styling",
	"SETTINGS.SUGGESTIONS.BRANDING.DESCRIPTION": "Personalize how we advertise your company",
	"SETTINGS.SUGGESTIONS.BRANDING.BUTTON": "Change Styling",

	"SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.TITLE": "Company settings",
	"SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.DESCRIPTION": "Manage your basic company settings",
	"SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.BUTTON": "Manage Company settings",
	//INTENT DETAIL
	"SETTINGS.INTENT.DETAIL.PAGE.TITLE": "Details (BETA)",

	"INTENT_DETAIL.VIEW.FALLBACK.TITLE": "Intents view",
	"INTENT_DETAIL.VIEW.FALLBACK.DESCRIPTION":
		"Viewing intents is currently not available in your current plan, upgrade to a higher plan to be able to use this functionality.",

	"SETTINGS.INTENT.DETAIL.FALLBACK.VIEW.TITLE": "Something went wrong",
	"SETTINGS.INTENT.DETAIL.FALLBACK.VIEW.DESCRIPTION":
		"The intent details cannot be shown. If this keeps happing pleas contact support@bothive.be",

	"SETTINGS.INTENT.DETAIL.FALLBACK.EDIT.TITLE": "Editing intents is not available",
	"SETTINGS.INTENT.DETAIL.FALLBACK.EDIT.DESCRIPTION":
		"Editing intents is currently not available in your current plan.",
	"SETTINGS.INTENT.DETAIL.FALLBACK.EDIT.CTA.DESCRIPTION":
		"Upgrade to a higher plan to be able to use this functionality.",
	"SETTINGS.INTENT.DETAIL.FALLBACK.EDIT.CTA.BUTTON": "Upgrade plan",

	"SETTINGS.INTENT.DETAIL.WARNING.INTENT_IS_FF":
		"This is a dynamic intent. This means that the answer will depend on the question that is asked. This will give a beter / specifiek answer or will be based on the time or location of the user. By change the answer this intent will become static.",
	"SETTINGS.INTENT.DETAIL.WARNING.INTENT_WITH_PAYLOAD":
		"Attention!\nSome questions cannot be edited directly from the Dashboard. If no answer is registered, contact our customer service or the person responsible for your project to apply the desired changes.",
	"SETTINGS.INTENT.DETAIL.PREVIEW.TITLE": "{name} - Current answer:",
	"SETTINGS.INTENT.DETAIL.CUSTOMIZE_ANSWER.TITLE": "{name} - Customize answer:",
	"INTENT_DETAIL.BUTTON.RESET_ANSWER": "Reset answer",
	"INTENT_DETAIL.BUTTON.SAVE_ANSWER": "Save",

	"SETTINGS.INTENT.DETAIL.POPUP.TITLE": "Confirm change",
	"SETTINGS.INTENT.DETAIL.POPUP.MESSAGE":
		"Are you sure you want to change the standard answer? Be warned you will be responsible to keep the answers relevant and up-to-date.",
	"SETTINGS.INTENT.DETAIL.POPUP.ADD_BUTTON": "Change answer",

	//LINK_POPUP
	"LINK_POPUP.HEADER.TITLE": "Add link",
	"LINK_POPUP.FORM.TEXT.LABEL": "Text",
	"LINK_POPUP.FORM.LINK.LABEL": "Link",

	//Members

	"ORGANIZATION_SETTINGS.MEMBERS.TABLE.PROFILE": "User",
	"ORGANIZATION_SETTINGS.MEMBERS.TABLE.LAST_LOGIN": "Last login",
	"ORGANIZATION_SETTINGS.MEMBERS.TABLE.SUPER_ADMIN": "Super admin",

	"ORGANIZATION_SETTINGS.MEMBERS.TABLE.LOCK_MESSAGE": "Upgrade your plan to add more users",
	"ORGANIZATION_SETTINGS.MEMBERS.TABLE.BUTTON": "New user",

	//NOTIFICATION_POPUP
	"NOTIFICATION_POPUP.BUTTON.OPEN":
		"{totalUnread, plural, =0 {Notifications} one {# unread notification} other {# unread notifications}}",
	"NOTIFICATION_POPUP.TITLE.NOTIFICATIONS": "Notifications",
	"NOTIFICATION_POPUP.EMPTY.TITLE": "No notifications right now",
	"NOTIFICATION_POPUP.EMPTY.DESCRIPTION": "You're up-to-date 🎉 job well done time for some coffee",
	"NOTIFICATION_POPUP.TITLE.BUTTON.VIEW_ALL": "View all ({total})",
	"NOTIFICATION_POPUP.TITLE.BUTTON.CLOSE": "Close",

	//NOTIFICATION
	"NOTIFICATIONS.PAGE.TITLE": "Notifications",
	"NOTIFICATIONS.HEADER.MARK_ALL_AS_SEEN": "Mark all as seen",
	"NOTIFICATION.EMPTY.TITLE": "No notifications right now",
	"NOTIFICATION.EMPTY.DESCRIPTION": "You're up-to-date 🎉 job well done time for some coffee",
	"NOTIFICATIONS.TABLE.HEADER.NOTIFICATIONS": "Notifications",

	//NOTIFICATION_CARD
	"NOTIFICATION_CARD.HEADER.CHIP.ANNOUNCEMENT": "Announcement",
	"NOTIFICATION_CARD.HEADER.CHIP.CONVERSATION_STARTED": "New conversation",
	"NOTIFICATION_CARD.HEADER.CHIP.ASSIGNED_TO_CONVERSATION": "Conversation assignment",
	"NOTIFICATION_CARD.HEADER.CHIP.ADDED_AS_PARTIIPANT": "Participant",
	"NOTIFICATION_CARD.HEADER.CHIP.LIVE_CHAT": "Live chat",
	"NOTIFICATION_CARD.HEADER.CHIP.MENTIONED": "Mentioned",
	"NOTIFICATION_CARD.HEADER.CHIP.MESSAGE_ERROR": "Error",
	"NOTIFICATION_CARD.HEADER.CHIP.ASSIGNED_TO_FLOW": "Flow assignment",
	"NOTIFICATION_CARD.HEADER.CHIP.NEW_FLOW_REPORT": "Flow report",
	"NOTIFICATION_CARD.HEADER.CHIP.FLOW_REMINDER_FAILED": "Flow reminder error",
	"NOTIFICATION_CARD.HEADER.CHIP.SYNCHRONIZATION": "Synchronization",
	"NOTIFICATION_CARD.HEADER.CHIP.REPORT.BULK_DOWNLOAD": "Bulk download reports",

	"NOTIFICATION_CARD.FOOTER.MARK_AS_DONE": "Mark as completed",

	//ORGANIZATION_SETTINGS.BRANDING
	"ORGANIZATION_SETTINGS.BRANDING.TITLE": "Branding settings",
	"ORGANIZATION_SETTINGS.BRANDING.BREADCRUMB.SETTINGS": "Branding settings",
	"ORGANIZATION_SETTINGS.BRANDING.BREADCRUMB.TITLE": "Branding settings",

	"ORGANIZATION_SETTINGS.BRANDING.LOGO.TITLE": "Logo en iconen",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.DESCRIPTION":
		"Upload your logo and icon that we can use to personalize outgoing communications.",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.FORM.ICON.TITLE": "Icon",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.FORM.ICON.LABEL": "Upload Icon",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.FORM.ICON.HELP": "Maximum size: 10MB",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.FORM.LOGO.TITLE": "Logo",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.FORM.LOGO.LABEL": "Upload logo",
	"ORGANIZATION_SETTINGS.BRANDING.LOGO.FORM.LOGO.HELP": "Maximum size: 10MB",

	"ORGANIZATION_SETTINGS.BRANDING.COLOR.FORM.BRAND_COLOR.LABEL": "Brand color",
	"ORGANIZATION_SETTINGS.BRANDING.COLOR.FORM.BRAND_COLOR.PLACEHOLDER": "#F8C945",
	"ORGANIZATION_SETTINGS.BRANDING.COLOR.FORM.ACCENT_COLOR.LABEL": "Accent color",
	"ORGANIZATION_SETTINGS.BRANDING.COLOR.FORM.ACCENT_COLOR.PLACEHOLDER": "#002140",
	"ORGANIZATION_SETTINGS.BRANDING.COLOR.FORM.TEXT_COLOR.LABEL": "Text color",
	"ORGANIZATION_SETTINGS.BRANDING.COLOR.FORM.TEXT_COLOR.PLACEHOLDER": "#002140",

	"ORGANIZATION_SETTINGS.BRANDING.FONT.TITLE": "Fonts",
	"ORGANIZATION_SETTINGS.BRANDING.FONT.DESCRIPTION":
		"Configure the font used by the widget or flow portal that you can place on your website.",
	"ORGANIZATION_SETTINGS.BRANDING.FONT.FORM.PRIMARY_FONT.LABEL": "Font",

	"ORGANIZATION_SETTINGS.MODAL.APPLY_TO_WIDGET.TITLE": "Apply branding to widget",
	"ORGANIZATION_SETTINGS.MODAL.APPLY_TO_WIDGET.DESCRIPTION":
		"Do you want to overwrite the current house style for the widget with your new house style?",
	"ORGANIZATION_SETTINGS.MODAL.APPLY_TO_WIDGET.DECLINE": "No",
	"ORGANIZATION_SETTINGS.MODAL.APPLY_TO_WIDGET.ACCEPT": "Yes, also apply to the widget",

	//ORGANIZATION_SETTINGS.GENERAL
	"ORGANIZATION_SETTINGS.GENERAL.TITLE": "Organization settings",
	"ORGANIZATION_SETTINGS.GENERAL.BREADCRUMB.SETTINGS": "settings",
	"ORGANIZATION_SETTINGS.GENERAL.BREADCRUMB.TITLE": "Organization settings",

	"ORGANIZATION_SETTINGS.GENERAL.BASIC.TITLE": "Basic settings",
	"ORGANIZATION_SETTINGS.GENERAL.BASIC.FORM.NAME.LABEL": "Company name",
	"ORGANIZATION_SETTINGS.GENERAL.BASIC.FORM.PHONE.LABEL": "Phone number",
	"ORGANIZATION_SETTINGS.GENERAL.BASIC.FORM.PHONE.HELP": "This phone number can be used to contact your business",
	"ORGANIZATION_SETTINGS.GENERAL.BASIC.FORM.SUPPORT_EMAIL.LABEL": "Support email",
	"ORGANIZATION_SETTINGS.GENERAL.BASIC.FORM.SUPPORT_EMAIL.HELP":
		"This email address can be used to contact your business",

	"ORGANIZATION_SETTINGS.GENERAL.LOCATIONS.TITLE": "Locations",
	"ORGANIZATION_SETTINGS.GENERAL.LOCATIONS.TABLE.ADD_BUTTON": "Add location",

	//SETTINGS SOCIAL MEDIA
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.TITLE": "Social media",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.ADD_FIELDS": "Add social media link",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.ADD_FIELDS.LABEL.PLACEHOLDER": "Facebook",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.ADD_FIELDS.VALUE.PLACEHOLDER":
		"https://www.facebook.com/bothivechatbots",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.FIELD_LABELS.FACEBOOK.FIELD.PLACEHOLDER":
		"https://www.facebook.com/bothivechatbots",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.FIELD_LABELS.LINKEDIN.FIELD.PLACEHOLDER":
		"https://be.linkedin.com/company/bothive-chatbots",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.FIELD_LABELS.INSTAGRAM.FIELD.PLACEHOLDER":
		"https://www.instagram.com/bothiveHQ/",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.FIELD_LABELS.TWITTER.FIELD.PLACEHOLDER":
		"https://twitter.com/BothiveHQ",
	"ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.FORM.FIELD_LABELS.YOUTUBE.FIELD.PLACEHOLDER":
		"https://www.youtube.com/channel/UCA4zAo0t7MKrUYIqbLgTliQ",

	"ORGANIZATION_SETTINGS.GENERAL.SUGGESTIONS.TITLE": "Settings you might find useful",

	//ORGANIZATION_SETTINGS.OPENING_HOURS
	"ORGANIZATION_SETTINGS.OPENING_HOURS.TITLE": "Opening hours",
	"ORGANIZATION_SETTINGS.OPENING_HOURS.BREADCRUMB.SETTINGS": "Settings",
	"ORGANIZATION_SETTINGS.OPENING_HOURS.BREADCRUMB.TITLE": "Opening hours",

	"ORGANIZATION_SETTINGS.OPENING_HOURS.BASIC.TITLE": "Office hours",
	"ORGANIZATION_SETTINGS.OPENING_HOURS.BASIC.DESCRIPTION": "What are your normal office hours during the week?",

	"ORGANIZATION_SETTINGS.OPENING_HOURS.SUGGESTIONS.TITLE": "Settings you might find useful",
	"ORGANIZATION_SETTINGS.OPENING_HOURS.SUGGESTIONS.GENERAL.TITLE": "Organization settings",
	"ORGANIZATION_SETTINGS.OPENING_HOURS.SUGGESTIONS.GENERAL.DESCRIPTION":
		"Manage the basic settings of your company that we can see your customers and will be applied automatically.",
	"ORGANIZATION_SETTINGS.OPENING_HOURS.SUGGESTIONS.GENERAL.BUTTON": "Manage your je organization settings",

	//ORGANIZATION_INFORMATION
	"ORGANIZATION_INFORMATION.PAGE.TITLE": "Organization information",
	"ORGANIZATION_INFORMATION.BREADCRUMB.SETTINGS": "Settings",
	"ORGANIZATION_INFORMATION.BREADCRUMB.TITLE": "Organization information",

	"ORGANIZATION_INFORMATION.HERO.TITLE": "Let {agent} get to know your company",
	"ORGANIZATION_INFORMATION.HERO.DESCRIPTION":
		"Nowadays everyone wants to be helped quickly and personally. on the basis of these questions {agent} can help your customers better with relevant information about you.",

	"ORGANIZATION_INFORMATION.REQUEST_MORE_SETTINGS.TITLE": "Request a new question",
	"ORGANIZATION_INFORMATION.REQUEST_MORE_SETTINGS.DESCRIPTION":
		"Do you want to add a new question to the chatbot? This can be done by contacting our customer support so that we can provide the training and add it to the chatbot.",
	"ORGANIZATION_INFORMATION.REQUEST_MORE_SETTINGS.BUTTON": "Request a new question",

	//POPUP
	"POPUP.BUTTON.CANCEL": "Cancel",
	"POPUP.BUTTON.CLOSE": "Close",
	"POPUP.BUTTON.DELETE": "Delete",
	"POPUP.BUTTON.INVITE_USER": "Invite user",
	"POPUP.BUTTON.SAVE": "Save",
	"POPUP.PLACEHOLDER.DESTRUCTIVE": "Type DELETE",
	"POPUP.PLACEHOLDER.ARCHIVE": "Type ARCHIVE",
	"POPUP.PLACEHOLDER.DESTRUCTIVE.CHANNEL": "Type DELETE",

	//POPUP ADD USER
	"POPUP.ADD_USER.TITLE": "Add new user",
	"POPUP.ADD_USER.INVITE_USER_HELP": "If user didn't receive a email, please look if it isn't in the spam folder.",

	//POPUP EDIT USER
	"POPUP.EDIT_USER.TITLE": "Edit user",

	//POPUP DESTRUCTIVE_PROFILE
	"POPUP.DESTRUCTIVE_PROFILE.TITLE": "Remove your profiel",
	"POPUP.DESTRUCTIVE_PROFILE.DELETE_BUTTON": "Remove profile",
	"POPUP.DESTRUCTIVE_PROFILE.DESCRIPTION":
		"Are you sure you want to delete your profile? This action cannot be undone.",

	//POPUP DESTRUCTIVE_PROFILE
	"POPUP.DEACTIVATE_TEAM.TITLE": "Remove team",
	"POPUP.DEACTIVATE_TEAM.BUTTON_LABEL": "remove team",
	"POPUP.DEACTIVATE_TEAM.DESCRIPTION": "Are you sure you want to delete your team? This action cannot be undone.",

	//POPUP TERMS_AND_CONDITIONS
	"POPUP.TERMS_AND_CONDITIONS_UPDATE.TITLE": "Terms of service",
	"POPUP.TERMS_AND_CONDITIONS_UPDATE.LOG_OUT": "Log out",
	"POPUP.TERMS_AND_CONDITIONS_UPDATE.ACCEPT": "Accept the conditions",

	//PROFILE
	"PROFILE.PAGE.TITLE": "Profile",
	"PROFILE.FALLBACK.TITLE": "Profile information could not be found",
	"PROFILE.FALLBACK.DESCRIPTION":
		"Log out and try to login again. if this keeps happing please contact or customer support",

	//PROFILE.INBOX_SETTINGS
	"PROFILE.SETTINGS.INBOX.CHANNELS.NO_CHANNELS.TITLE": "Connect your emails",
	"PROFILE.SETTINGS.INBOX.CHANNELS.NO_CHANNELS.DESCRIPTION":
		"Connect your emails with your Bothive team to see all emails send to that client in 1 overview.",
	"PROFILE.SETTINGS.INBOX.CHANNELS.NO_CHANNELS.BUTTON.CONNECT": "Connect a email account",

	//PROFILE_TEAMS
	"PROFILE_TEAMS.PAGE.TITLE": "My teams",
	"PROFILE_TEAMS.SEARCH.PLACEHOLDER": "Search for team name",
	"PROFILE_TEAMS.TABLE.HEADER.TEAM_NAME": "Team name",
	"PROFILE_TEAMS.TABLE.FALLBACK": "Could find any teams",

	"PROFILE_TEAMS.FALLBACK.TITLE": "Teams",
	"PROFILE_TEAMS.FALLBACK.DESCRIPTION":
		"You are not a member of team. Ask the administrator of a team to invite you or create your own team",
	"PROFILE_TEAMS.FALLBACK.LABEL": "Create a team",

	//POPUP
	"PROFILE.POPUP.AVAILABLE": "Available",
	"PROFILE.POPUP.INBOX": "Inbox Settings",
	"profile.popup.settings": "Profile Settings",
	"profile.popup.actions.shortcuts": "Shortcuts",
	"profile.popup.logout": "Logout",
	//GENERAL
	"profile.general.accordion_title": "General",
	"profile.general.form.avatar.label": "Avatar",
	"profile.general.form.avatar.help": "Maximum size: 10MB",
	"profile.general.form.first_name.label": "First name",
	"profile.general.form.last_name.label": "Last name",
	"profile.general.form.email.label": "E-mail",
	"profile.general.form.phone_number.label": "Phonenumber",
	//SECURITY
	"PROFILE.SECURITY.ACCORDION_TITLE": "Security",
	"PROFILE.SECURITY.OLD_PASSWORD": "Old password",
	"PROFILE.SECURITY.NEW_PASSWORD": "New password",

	//QUESTION
	"QUESTION_SETTINGS.PAGE.TITLE": "Question Settings",

	"QUESTION_SETTINGS.STANDARD_QUESTIONS.TITLE": "Edit standard intents (BETA)",
	"QUESTION_SETTINGS.STANDARD_QUESTIONS.DESCRIPTION":
		"Do you find that Bothive current answers do not fit your company, change them here! But be warned you will be responsible to keep the answers relevant and up-to-date.\n\n\rAt the moment it is only possible to customize basic intents. The complete dataset is not available at the moment or can be change in de settings below",
	"QUESTION_SETTINGS.STANDARD_QUESTIONS.BUTTON": "Edit standard intents",

	"QUESTION.ASK_QUESTION.TITLE": "Request a new question",
	"QUESTION.ASK_QUESTION.DESCRIPTION":
		"Do you want to add a new question to the chatbot? This can be done by contacting our customer support so that we can provide the training and add it to the chatbot.",
	"QUESTION.ASK_QUESTION.BUTTON": "Request a new question",

	//REPORT_DETAIL_HEADER
	"REPORT_DETAIL_ASIDE.FALLBACK.EMPTY.TITLE": "{username} hasn't started filling in this flow.",
	"REPORT_DETAIL_ASIDE.ACTIONS.TITLE": "Actions:",
	"REPORT_DETAIL_ASIDE.ACTIONS.DOWNLOAD_ATTACHMENTS": "Download Attachments ({count})",
	"REPORT_DETAIL_ASIDE.INTEGRATIONS.TITLE": "Integrations:",
	"REPORT_DETAIL_ASIDE.INTEGRATIONS.ADD": "Add ...",

	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.TITLE": "Save report data in contact",
	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.DESCRIPTION":
		"Do you want to save the data from this report in the contact? This will overwrite the current data in this contact but will also make this data available in other reports.",
	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.SAVE_DECISION": "Do not show this popup again and remember my choice.",
	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.SAVE": "Save in contact",
	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.DONT_SAVE": "Do not save in contact",
	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.ERROR.MESSAGE":
		"Something went wrong while saving the report data in the contact.",
	"REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.SUCCESS.MESSAGE": "Repportdata applied succesfully to contact.",
	//SETTINGS

	//SETTINGS ASIDE
	"SETTINGS.ASIDE.OVERVIEW.TITLE": "Overzicht",
	"SETTINGS.ASIDE.INBOX.TITLE": "Inbox",
	"SETTINGS.ASIDE.FLOWS.TITLE": "Flows",
	"SETTINGS.ASIDE.CONTACTS.TITLE": "Contacten",
	"SETTINGS.ASIDE.VIRTUAL_ASSISTANT.TITLE": "Agent",
	"SETTINGS.ASIDE.TEAM.TITLE": "Team",
	"SETTINGS.ASIDE.ADVANCED.TITLE": "Advanced",

	"SETTINGS_OVERVIEW.PAGE.TITLE": "Settings",
	"SETTINGS.ASIDE.OVERVIEW": "Overview",
	"SETTINGS.ASIDE.ORGANIZATION": "Organization settings",
	"SETTINGS.ASIDE.OPENING_HOURS": "Opening hours",
	"SETTINGS.ASIDE.BRANDING": "Branding",
	"SETTINGS.ASIDE.INBOX.SETTINGS": "Inbox settings",
	"SETTINGS.ASIDE.INBOX.CHANNELS": "Channels",
	"SETTINGS.ASIDE.INBOX.LABELS": "Inbox labels",
	"SETTINGS.ASIDE.CANNED_RESPONSES": "Canned responses",
	"SETTINGS.ASIDE.EMAIL_SIGNATURE": "Email signature",
	"SETTINGS.ASIDE.FLOW_SETTINGS": "Flow settings",
	"SETTINGS.ASIDE.VIRTUAL_ASSISTANT": "Virtual assistent",
	"SETTINGS.ASIDE.ORGANIZATION_QUESTIONS": "Organization questions",
	"SETTINGS.ASIDE.DEFAULT_ANSWERS": "Standard responses",
	"SETTINGS.ASIDE.INTENTS": "Intents",
	"SETTINGS.ASIDE.WIDGET": "Widget",
	"SETTINGS.ASIDE.INTEGRATIONS": "Integration",
	"SETTINGS.ASIDE.CONTACT_IMPORT": "Contact import",
	"SETTINGS.ASIDE.CONTACT_LABELS": "Contact labels",
	"SETTINGS.ASIDE.USERS": "Users",
	"SETTINGS.ASIDE.TRIGGERS": "Triggers",
	"SETTINGS.ASIDE.NOTIFICATION_SETTINGS": "Notifcation settings",
	"SETTINGS.ASIDE.API_KEY": "Api key",
	"SETTINGS.ASIDE.ADVANCED": "Advanced",
	//SETTINGS_OVERVIEW.ORGANIZATION
	"SETTINGS_OVERVIEW.ORGANIZATION.TITLE": "Company settings",

	"SETTINGS_OVERVIEW.ORGANIZATION.GENERAL.TITLE": "General",
	"SETTINGS_OVERVIEW.ORGANIZATION.GENERAL.DESCRIPTION":
		"Basic settings for your company such as name, telephone, locations, ...",
	"SETTINGS_OVERVIEW.ORGANIZATION.OPENING_HOURS.TITLE": "Openings hours",
	"SETTINGS_OVERVIEW.ORGANIZATION.OPENING_HOURS.DESCRIPTION": "At what times are you open or available.",
	//SETTINGS_OVERVIEW.BRANDING
	"SETTINGS_OVERVIEW.BRANDING.TITLE": "Branding",

	"SETTINGS_OVERVIEW.BRANDING.STYLING.TITLE": "Styling",
	"SETTINGS_OVERVIEW.BRANDING.STYLING.DESCRIPTION": "Personalize how we advertise your company",
	"SETTINGS_OVERVIEW.BRANDING.WIDGET.TITLE": "Widget",
	"SETTINGS_OVERVIEW.BRANDING.WIDGET.DESCRIPTION": "Set the widget so that it fits within your company",
	//SETTINGS_OVERVIEW.CONTACTS.TITLE
	"SETTINGS_OVERVIEW.CONTACTS.TITLE": "Contacten",
	"SETTINGS_OVERVIEW.CONTACTS.CONTACTS.TITLE": "Contacts",
	"SETTINGS_OVERVIEW.CONTACTS.CONTACTS.DESCRIPTION": "Contact settings and import options",
	"SETTINGS_OVERVIEW.CONTACTS.LABELS.TITLE": "Contact labels",
	"SETTINGS_OVERVIEW.CONTACTS.LABELS.DESCRIPTION": "Manage your organisations contact labels",
	//SETTINGS_OVERVIEW.VIRTUAL_ASSISTANT
	"SETTINGS_OVERVIEW.VIRTUAL_ASSISTANT.TITLE": "Virtual assistant",

	"SETTINGS_OVERVIEW.VIRTUAL_ASSISTANT.AGENT.TITLE": "Agent",
	"SETTINGS_OVERVIEW.VIRTUAL_ASSISTANT.AGENT.DESCRIPTION":
		"Customize the virtual assistant so that it fits perfectly within your team",
	"SETTINGS_OVERVIEW.VIRTUAL_ASSISTANT.RESPONSES.TITLE": "Answers and errors",
	"SETTINGS_OVERVIEW.VIRTUAL_ASSISTANT.RESPONSES.DESCRIPTION":
		"You can adjust the way {agent} gives answers or errors according to your own preferences.",
	//SETTINGS_OVERVIEW.MEMBERS
	"SETTINGS_OVERVIEW.MEMBERS.TITLE": "Members",
	"SETTINGS_OVERVIEW.MEMBERS.DESCRIPTION": "Manage the users who have access to the platform and their permissions",

	//SETTINGS.NOTIFICATIONS.PAGE.TITLE
	"SETTINGS.NOTIFICATIONS.PAGE.TITLE": "Notification settings",
	"SETTINGS.NOTIFICATIONS.BREADCRUMB.SETTINGS": "Settings",
	"SETTINGS.NOTIFICATIONS.BREADCRUMB.TITLE": "Notification settings",

	"SETTINGS.NOTIFICATIONS.BILLING.TITLE": "Billing emails",
	"SETTINGS.NOTIFICATIONS.BILLING.FORM.EMAIL.LABEL": "Email address to receive invoice and reminders",
	"SETTINGS.NOTIFICATIONS.BILLING.FORM.EMAIL.PLACEHOLDER": "facturen@bothive.be",
	"SETTINGS.NOTIFICATIONS.BILLING.FORM.EMAIL.EMAIL_ERROR": "Email looks to be incomplete",

	"SETTINGS.NOTIFICATIONS.TECHNICAL.TITLE": "Technical notifications",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DESCRIPTION":
		"Technical notifications are focused for developers, and will notify them on change that where made to or api's or SDK.",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.FORM.DEVELOPER.LABEL": "Email developer",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.FORM.DEVELOPER.PLACEHOLDER": "developer@bothive.be",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.FORM.DEVELOPER.EMAIL_ERROR": "Email looks to be incomplete",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.FORM.DEVELOPER.BUTTON": "Sent email",

	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.TITLE": "Contact your web developer",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.DESCRIPTION":
		"We will gladly contact your web developer. Enter the contact email of your web developer here and we will send an email with instructions on how to place the widget on your website.",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.FORM.EMAIL_WEB_DEVELOPER.LABEL": "Email web developer",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.FORM.API_KEY.LABEL": "API key",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.FORM.API_KEY.HELP":
		"You need a API key with the 'widget.load' permission.",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.FORM.API_KEY.ERROR": "You forgot to select a API key.",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.SUBMIT_BUTTON": "Send email",
	"SETTINGS.NOTIFICATIONS.TECHNICAL.DEVELOPER_EMAIL_OPTIONS.SKIP_BUTTON": "skip",

	//NOTIFICATION_OPTIONS.DEFAULT
	"NOTIFICATION_OPTIONS.DEFAULT": "Dashboard",
	"NOTIFICATION_OPTIONS.EMAIL": "Email",
	//PROFILE_NOTIFICATION_SETTINGS
	"SETTINGS.NOTIFICATIONS.FLOW.CARD.TITLE": "Flow",
	"SETTINGS.NOTIFICATIONS.FLOW.CARD.TOOLTIP": "These settings are personal to you",
	"SETTINGS.NOTIFICATIONS.FLOW.CARD.DESCRIPTION":
		"Select the options you would like to receive from the drop-down menu.",
	"SETTINGS.NOTIFICATIONS.FLOW.FORM.ASSIGN.LABEL": "Receive notification when assigned to report",
	"SETTINGS.NOTIFICATIONS.FLOW.FORM.REPORT_ACTIONS.LABEL": "Receive notification when a action occures on a report",
	"SETTINGS.NOTIFICATIONS.INBOX.CARD.TITLE": "Inbox",
	"SETTINGS.NOTIFICATIONS.INBOX.CARD.TOOLTIP": "These settings are personal to you",
	"SETTINGS.NOTIFICATIONS.INBOX.CARD.DESCRIPTION":
		"Select the options you would like to receive from the drop-down menu",
	"SETTINGS.NOTIFICATIONS.INBOX.FORM.ASSIGN.LABEL": "Receive notification when assigned to conversation",
	"SETTINGS.NOTIFICATIONS.INBOX.FORM.PARTICIPANT.LABEL":
		"Receive notification when added to conversation as participant",
	"SETTINGS.NOTIFICATIONS.INBOX.FORM.LIVE_CHAT.LABEL": "Receive notification when live chat started",
	"SETTINGS.NOTIFICATIONS.INBOX.FORM.MENTIONED.LABEL":
		"Receive notification when you are mentioned in a conversation",
	"SETTINGS.NOTIFICATIONS.INBOX.FORM.NEW_CONVERSATION.LABEL": "Receive notification when new conversation started",
	"SETTINGS.NOTIFICATIONS.INBOX.FORM.ACCOUNT_MANAGED_CONVERSATION.LABEL":
		"Receive notification when new conversation is started from contact where you are account manager from",
	"SETTINGS.NOTIFICATIONS.SETTINGS.CARD.TITLE": "Settings",
	"SETTINGS.NOTIFICATIONS.SETTINGS.CARD.DESCRIPTION":
		"Select the communication channels on which we should notify you for specific settings.",
	"SETTINGS.NOTIFICATIONS.SETTINGS.CARD.TOOLTIP": "These settings are personal to you",
	"SETTINGS.NOTIFICATIONS.SETTINGS.INTEGRATION.LABEL":
		"Receive notification when an existing integration connection has to be reconnected.",

	//SETTINGS_OVERVIEW.KNOWLEDGE_CENTER.TITLE
	"SETTINGS_OVERVIEW.KNOWLEDGE_CENTER.TITLE": "knowledge center",

	"SETTINGS_OVERVIEW.KNOWLEDGE_CENTER.QUESTIONS.TITLE": "Business inquiries",
	"SETTINGS_OVERVIEW.KNOWLEDGE_CENTER.QUESTIONS.DESCRIPTION": "Specific questions about you that {agent} can answer.",
	"SETTINGS_OVERVIEW.KNOWLEDGE_CENTER.INTENTS.TITLE": "Intents",
	"SETTINGS_OVERVIEW.KNOWLEDGE_CENTER.INTENTS.DESCRIPTION":
		"Intents are all possible questions that {agent} can answer.",
	//SETTINGS_OVERVIEW.CHANNELS
	"SETTINGS_OVERVIEW.CHANNELS.TITLE": "Channels",
	"SETTINGS_OVERVIEW.CHANNELS.DESCRIPTION": "Connect chat and email channels with your inbox.",
	"SETTINGS_OVERVIEW.CHANNELS.GOOGLE.TOOLTIP": "Connect with Gmail",
	"SETTINGS_OVERVIEW.CHANNELS.OFFICE.TOOLTIP": "Connect with Outlook",
	"SETTINGS_OVERVIEW.CHANNELS.FOOTER.BUTTON": "Manage channels",
	//SETTINGS_OVERVIEW.INTEGRATONS
	"SETTINGS_OVERVIEW.INTEGRATIONS.TITLE": "Integrations",
	"SETTINGS_OVERVIEW.INTEGRATIONS.GRAPH.UNLIMITED": "Unlimited",
	"SETTINGS_OVERVIEW.INTEGRATIONS.DESCRIPTION":
		"Through integrations you can extend the functionalities of Bothive. By integrating on different platforms you can synchronize Bothive with your crm and extend Bothive knowledge.",
	"SETTINGS_OVERVIEW.INTEGRATIONS.FOOTER.BUTTON": "Manage integraties",
	//SETTINGS_OVERVIEW.ADVANCED
	"SETTINGS_OVERVIEW.ADVANCED.TITLE": "Advanced",

	"SETTINGS_OVERVIEW.ADVANCED.WIDGET.TITLE": "Widget",
	"SETTINGS_OVERVIEW.ADVANCED.WIDGET.DESCRIPTION": "Advanced widget settings",
	"SETTINGS_OVERVIEW.ADVANCED.TRIGGERS.TITLE": "Triggers",
	"SETTINGS_OVERVIEW.ADVANCED.TRIGGERS.DESCRIPTION":
		"Triggers attract the attention of your website visitors and stimulate interaction with the widget",
	"SETTINGS_OVERVIEW.ADVANCED.CONTACT_INFO.TITLE": "Contact info",
	"SETTINGS_OVERVIEW.ADVANCED.CONTACT_INFO.DESCRIPTION": "Set here how we may contact you and which email addresses.",
	"SETTINGS_OVERVIEW.ADVANCED.NOTIFICATION_SETTINGS.TITLE": "Notificatie-instellingen",
	"SETTINGS_OVERVIEW.ADVANCED.NOTIFICATION_SETTINGS.DESCRIPTION": "Set up how we can keep you informed.",
	"SETTINGS_OVERVIEW.ADVANCED.API_KEY.TITLE": "API key",
	"SETTINGS_OVERVIEW.ADVANCED.API_KEY.DESCRIPTION":
		"The API key is a unique code used to access the Bothive API and identify a customer",
	"SETTINGS_OVERVIEW.ADVANCED.DESTRUCTIVE.TITLE": "Dangerous options",
	"SETTINGS_OVERVIEW.ADVANCED.DESTRUCTIVE.DESCRIPTION": "Destructive options such as deactivating your team",

	//SETTINGS DEPRECATED
	"settings.page.title": "Settings",
	//SETTINGS GENERAL
	"SETTINGS.GENERAL.COMPANY_NAME": "Company name",
	"SETTINGS.GENERAL.COMPANY_NUMBER": "Vat number",
	"SETTINGS.GENERAL.COMPANY_NUMBER_PLACEHOLDER": "Ex. BE0665684769",
	"SETTINGS.GENERAL.PHONE": "Phone number",
	"SETTINGS.GENERAL.PHONE_HELP": "This phone number can be used to contact your business",
	"SETTINGS.GENERAL.SUPPORT_EMAIL": "Support e-mailadres",
	"SETTINGS.GENERAL.SUPPORT_EMAIL_HELP": "This email address can be used to contact your business",
	"SETTINGS.GENERAL.SUPPORT_EMAIL_HELP_SIGN_UP": "This e-mail address is used by customers to contact your business",
	//SETTINGS BRANDING
	"SETTINGS.BRANDING.ICON.TITLE": "Icon",
	"SETTINGS.BRANDING.ICON.LABEL": "Upload Icon",
	"SETTINGS.BRANDING.ICON.HELP": "Maximum size: 10MB",
	"SETTINGS.BRANDING.LOGO.TITLE": "Logo",
	"SETTINGS.BRANDING.LOGO.LABEL": "Upload logo",
	"SETTINGS.BRANDING.LOGO.HELP": "Maximum size: 10MB",

	"SETTINGS.BRANDING.BRAND_COLOR.LABEL": "Brand color",
	"SETTINGS.BRANDING.BRAND_COLOR.PLACEHOLDER": "#F8C945",

	"SETTINGS.BRANDING.ACCENT_COLOR.LABEL": "Accent color",
	"SETTINGS.BRANDING.ACCENT_COLOR.PLACEHOLDER": "#002140",

	"SETTINGS.BRANDING.TEXT_COLOR.LABEL": "Text color",
	"SETTINGS.BRANDING.TEXT_COLOR.PLACEHOLDER": "#002140",
	//SETTINGS LOCATIONS
	"SETTINGS.LOCATIONS.POPUP.NEW_LOCATION_TITLE": "Add new location",
	"SETTINGS.LOCATIONS.POPUP.EDIT_LOCATION_TITLE": "Edit location",
	"SETTINGS.LOCATIONS.POPUP.CANCEL_BUTTON": "Cancel",
	"SETTINGS.LOCATIONS.POPUP.REMOVE_LOCATION": "Remove",
	"SETTINGS.LOCATIONS.POPUP.UPDATE_LOCATION": "Update",
	"SETTINGS.LOCATIONS.POPUP.ADD_NEW_LOCATION": "Create new location",

	"SETTINGS.LOCATIONS.POPUP.SUB_HEADER.CONTACT_INFO": "Contact information",

	"SETTINGS.LOCATIONS.COUNTRY": "country",
	"SETTINGS.LOCATIONS.CITY": "City",
	"SETTINGS.LOCATIONS.POSTALCODE": "Postalcode",
	"SETTINGS.LOCATIONS.STREET": "Street",
	"SETTINGS.LOCATIONS.NUMBER": "Number",
	"SETTINGS.LOCATIONS.EMAIL": "E-mail",
	"SETTINGS.LOCATIONS.TELEPHONE_NUMBER": "Telephone number",
	"SETTINGS.LOCATIONS.COMPANY_NUMBER": "Company number",
	"SETTINGS.LOCATIONS.COMPANY_NUMBER_PLACEHOLDER": "Ex. BE0665684769",
	"SETTINGS.LOCATIONS.ADD_NEW_LOCATION": "Add new location",
	//SETTINGS USERS
	"SETTINGS.USERS_POPUP.EMAIL.LABEL": "E-mail",

	//SETTINGS API_KEY
	"API_KEY_OVERVIEW.PAGE.TITLE": "API key overview",
	"API_KEY_OVERVIEW.HEADER.BREADCRUMB.SETTINGS": "Settings",
	"API_KEY_OVERVIEW.HEADER.BREADCRUMB.TITLE": "API key overview",

	"API_KEY_OVERVIEW.LOCK.TITLE": "Power up with API keys",
	"API_KEY_OVERVIEW.LOCK.DESCRIPTION":
		"API keys gives you the access to give developers access to you Bothive team and manage what they can access.",
	"API_KEY_OVERVIEW.LOCK.BUTTON.CONTACT_SUPPORT": "Upgrade your plan",

	"API_KEY_OVERVIEW.NO_KEYS_FOUND.TITLE": "Power up with API keys",
	"API_KEY_OVERVIEW.NO_KEYS_FOUND.DESCRIPTION":
		"API keys gives you the access to give developers access to you Bothive team and manage what they can access.",
	"API_KEY_OVERVIEW.NO_KEYS_FOUND.BUTTON.CREATE_API_KEY": "Create API key",

	"FILTER.API_KEY.BUTTON.CREATE_API_KEY": "Create API key",

	"API_KEY_OVERVIEW.TABLE.HEADER.DESCRIPTION": "Description",
	"API_KEY_OVERVIEW.TABLE.HEADER.API_KEY": "API key",
	"API_KEY_OVERVIEW.TABLE.HEADER.PERMISSIONS": "Permissions",
	"API_KEY_OVERVIEW.TABLE.HEADER.CREATED_AT": "Created at",
	"API_KEY_OVERVIEW.TABLE.HEADER.IS_ENABLED": "Is enabled",
	"API_KEY_OVERVIEW.TABLE.ROW.API_KEY.BUTTON": "Copy API key",

	"API_KEY_CREATE_MODAL.HEADER.TITLE": "Create API key",
	"API_KEY_CREATE_MODAL.DELETE.ADD": "Create API key",
	"API_KEY_CREATE_MODAL.DELETE.CANCEL": "Cancel",

	//SETTTINGS API_KEY_DETAIL
	"SETTINGS.API_KEY_DETAIL.PAGE.TITLE": "API key detail",
	"SETTINGS.API_KEY_DETAIL.HEADER.BREADCRUMB.SETTINGS": "Settigns",
	"SETTINGS.API_KEY_DETAIL.HEADER.BREADCRUMB.OVERVIEW": "API keys",

	"SETTINGS.API_KEY_DETAIL.FORM.NAME.LABEL": "Name",
	"SETTINGS.API_KEY_DETAIL.FORM.NAME.HELP": "Give your API key a relevant name for beter reconnaision.",
	"SETTINGS.API_KEY_DETAIL.FORM.NAME.REQUIRED": "You need to give your api key a name",
	"SETTINGS.API_KEY_DETAIL.FORM.DESCRIPTION.LABEL": "Description",
	"SETTINGS.API_KEY_DETAIL.FORM.DESCRIPTION.HELP":
		"A description can help you and your team to better monitor the objectives of this API key and to intervene in the event of abuse.",
	"SETTINGS.API_KEY_DETAIL.FORM.API_KEY.LABEL": "Api key",
	"SETTINGS.API_KEY_DETAIL.FORM.IS_ENABLED.LABEL": "Is enabled",
	"SETTINGS.API_KEY_DETAIL.FORM.IS_ENABLED.HELP":
		"Disabling the API key has immediate effect and will prevent the application using it from accessing your team.",

	"SETTINGS.API_KEY_DETAIL.PERMISSIONS.TITLE": "Permissions",
	"SETTINGS.API_KEY_DETAIL.PERMISSIONS.DESCRIPTION":
		"Protect your data by limiting the API keys's data access. We recommend giving the API key the least amount of permissions needed to complete it's goal.",
	"SETTINGS.API_KEY_DETAIL.PERMISSIONS.SELECT_DOMAIN.PLACEHOLDER": "Select a permission",
	"SETTINGS.API_KEY_DETAIL.PERMISSIONS.TABLE.PERMISSION": "Permission",
	"SETTINGS.API_KEY_DETAIL.PERMISSIONS.WARNING.DOMAIN_LOCK.DESCRIPTION":
		"The permission requires a domain to be added before this API key will work.",

	"SETTINGS.API_KEY_DETAIL.DOMAIN.TITLE": "Trusted domains",
	"SETTINGS.API_KEY_DETAIL.DOMAIN.DESCRIPTION":
		"Protect your API key from unwanted access by restriciting the API key to a specific range of trusted domains. Some permissions require that you add trusted domains to the API key before it will work.",
	"SETTINGS.API_KEY_DETAIL.DOMAIN.TABLE.DOMAIN": "Domain name",
	"SETTINGS.API_KEY_DETAIL.DOMAIN.BUTTON.ADD_DOMAIN": "Add domain",

	"SETTINGS.API_KEY_DETAIL.DANGER_ZONE.ACCORDION_TITLE": "Delete API key",
	"SETTINGS.API_KEY_DETAIL.DANGER_ZONE.TITLE": "Delete this API key",
	"SETTINGS.API_KEY_DETAIL.DANGER_ZONE.DESCRIPTION":
		"Are you sure that you want to delete this API key? This action is not reversabel and will have immediat effect.",
	"SETTINGS.API_KEY_DETAIL.DANGER_ZONE.BUTTON": "Delete API key",

	//SETTINGS EMAIL SIGNATURES
	"SETTINGS.INBOX.EMAIL_SIGNATURE.TITLE": "Email signatures",
	"SETTINGS.INBOX.EMAIL_SIGNATURE.BREADCRUMB.SETTINGS": "Settings",
	"SETTINGS.INBOX.EMAIL_SIGNATURE.BREADCRUMB.SIGNATURES": "Email signatures",

	"SETTINGS.INBOX.EMAIL_SIGNATURE.HEADER.BUTTON.CREATE": "Create signature",

	"SETTINGS.INBOX.EMAIL_SIGNATURE.TABLE.HEADER.NAME": "Name",
	"SETTINGS.INBOX.EMAIL_SIGNATURE.TABLE.HEADER.PREVIEW": "Signature",
	"SETTINGS.INBOX.EMAIL_SIGNATURE.TABLE.HEADER.IS_DEFAULT": "Is default",

	//SETTINGS EMAIL SIGNATURES DETAIL
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.TITLE": "Email signature",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BREADCRUMB.SETTINGS": "Settings",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BREADCRUMB.OVERVIEW": "Signatures",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BREADCRUMB.CREATE": "New signature",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.NAME.LABEL": "Name",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.NAME.ERROR": "You forgot to name your signature.",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.CONTENT.LABEL": "Signature",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.CONTENT.PLACEHOLDER": "With kind regarts",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.CONTENT.ERROR": "You forgot to add your signature.",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.IS_CHANNEL.LABEL": "Channel",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.IS_CHANNEL.HELP":
		"Link it to a channel to be available only for that channel.",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.IS_PRIMARY.LABEL": "Is primary",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.IS_PRIMARY.HELP": "Select this signature by default.",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.DELETE": "Delete",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.FORM.SUBMIT": "Save",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BANNER.CREATE.SUCCESS": "Email signature is created.",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BANNER.CREATE.FAILED":
		"Email signature could not be created please try again.",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BANNER.UPDATE.SUCCESS": "Email signature is updated.",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BANNER.UPDATE.FAILED":
		"Email signature could not be updated please try again.",

	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BANNER.DELETE.SUCCESS": "Email signature is removed.",
	"SETTINGS.INBOX.EMAIL_SIGNATURE_DETAIL.BANNER.DELETE.FAILED":
		"Email signature could not be removed please try again.",

	// SETTINGS INBOX LABELS
	"SETTINGS.INBOX.LABELS.TITLE": "Inbox labels",
	"SETTINGS.INBOX.LABELS.BREADCRUMB.SETTINGS": "Settings",
	"SETTINGS.INBOX.LABELS.BREADCRUMB.INBOX.LABELS": "Inbox labels",
	"SETTINGS.INBOX.LABELS.TABLE.NAME": "Name",
	"SETTINGS.INBOX.LABELS.TABLE.COLOR": "Color",
	"SETTINGS.INBOX.LABELS.TABLE.CHANNEL_NAME": "Channel",
	"SETTINGS.INBOX.LABELS.ADD": "Add new label",

	"SETTINGS.INBOX.LABELS.NO_INBOX.TITLE": "You don't have a inbox in this team",
	"SETTINGS.INBOX.LABELS.NO_INBOX.DESCRIPTION":
		"you haven't got a inbox in this team. This can have multiple reasons like you are not a member of this team, or server went down or you don't have permissions to have a inbox in this team.",

	"SETTINGS.INBOX.LABELS.MODAL.ADD.FORM.TITLE": "Add label",
	"SETTINGS.INBOX.LABELS.MODAL.ADD.FORM.NAME": "Name",
	"SETTINGS.INBOX.LABELS.MODAL.ADD.FORM.NAME.REQUIRED": "Please fill in a name",
	"SETTINGS.INBOX.LABELS.MODAL.ADD.FORM.COLOR": "Label color",
	"SETTINGS.INBOX.LABELS.MODAL.ADD.FORM.COLOR.REQUIRED": "Please choose a color",
	"SETTINGS.INBOX.LABELS.MODAL.ADD.CONFIRM": "Add",
	"SETTINGS.INBOX.LABELS.MODAL.ADD.RETURN": "Return",

	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.TITLE": "Update label",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.NAME": "Name",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.NAME.REQUIRED": "Please fill in a name",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.COLOR": "Label color",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.COLOR.REQUIRED": "Please choose a color",

	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.CHANNEL": "Channel",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.CHANNEL.HELP":
		"Labels connected to a channel are only visible for inboxes in that channel",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.FORM.OPTION.PUBLIC": "Public in your team",

	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.DELETE": "Delete",
	"SETTINGS.INBOX.LABELS.MODAL.UPDATE.CONFIRM": "Update",
	"SETTINGS.INBOX.LABELS.EMPTY": "No labels added yet",

	"SETTINGS.API_KEY_MODAL.DELETE_API_KEY.HEADER.TITLE": "Delete API key",
	"SETTINGS.API_KEY_MODAL.DELETE_API_KEY.HEADER.DESCRIPTION":
		"Are you sure you want to delete this API key? This action can not be reversed.",

	"SETTINGS.API_KEY_MODAL.DELETE_API_KEY.FORM.DELETE.PLACEHOLDER": "Type {keyword} and press the delete button",
	"SETTINGS.API_KEY_MODAL.DELETE_API_KEY.FORM.DELETE.ERROR": "Your input doesn't match {keyword}",

	"SETTINGS.API_KEY_MODAL.DELETE_API_KEY.DELETE.BUTTON_LABEL": "Delete API KEY",

	"SETTINGS.API_KEY_MODAL.ADD_DOMAIN.HEADER.TITLE": "Add new domain",
	"SETTINGS.API_KEY_MODAL.ADD_DOMAIN.FORM.DOMAIN_NAME.PLACEHOLDER":
		"Vb. https://www.bothive.be, http://getbothive.com, https://...",
	"SETTINGS.API_KEY_MODAL.ADD_DOMAIN.FORM.DOMAIN_NAME.ERROR_MESSAGE": "Don't forget the domain name",
	"SETTINGS.API_KEY_MODAL.ADD_DOMAIN.BUTTON.ADD_DOMAIN": "Add domain",
	"SETTINGS.API_KEY_MODAL.ADD_DOMAIN.BUTTON.CANCEL": "Cancel",

	//STYLE_INPUT
	"STYLE_INPUT.BOT.BOT_NAME": "Name",

	"STYLE_INPUT.MARKUP.BOLD": "Bold",
	"STYLE_INPUT.MARKUP.ITALIC": "Italic",
	"STYLE_INPUT.MARKUP.UNDERLINED": "Underlined",
	"STYLE_INPUT.BUTTON.SEND": "Send",

	//TABLE_CELL
	"TABLE_CELL.BOOLEAN.ACTIVE": "Active",
	"TABLE_CELL.BOOLEAN.INACTIVE": "Inactive",
	"TABLE_CELL.FALLBACK.DEFAULT": "Could not find enough data",

	//TEMPLATE
	"TEMPLATE.ORGANIZATION.COMPANY_NAME": "Company name",
	"TEMPLATE.ORGANIZATION.COMPANY_PHONE": "Phone",
	"TEMPLATE.ORGANIZATION.COMPANY_EMAIL": "Email",
	"TEMPLATE.ORGANIZATION.COMPANY_LOGO": "Logo",
	"TEMPLATE.ORGANIZATION.COMPANY_ICON": "Icon",
	"TEMPLATE.ORGANIZATION.COMPANY_LOCATION_COUNTRY": "Country",
	"TEMPLATE.ORGANIZATION.COMPANY_LOCATION_CITY": "City",
	"TEMPLATE.ORGANIZATION.COMPANY_LOCATION_POSTAL_CODE": "Postal code",
	"TEMPLATE.ORGANIZATION.COMPANY_LOCATION_STREET": "Streetname",
	"TEMPLATE.ORGANIZATION.COMPANY_LOCATION_NUMBER": "Streetnumber",
	"TEMPLATE.ORGANIZATION.COMPANY_LOCATION_COMPANY_NUMBER": "Companynumber",
	"TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_FACEBOOK": "Facebook",
	"TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_LINKEDIN": "Linkedin",
	"TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_INSTAGRAM": "Instagram",
	"TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_TWITTER": "Twitter",
	"TEMPLATE.ORGANIZATION.COMPANY_SOCIAL_MEDIA_YOUTUBE": "YouTube",

	"TEMPLATE.USER.FIRST_NAME": "Firstname",
	"TEMPLATE.USER.LAST_NAME": "Lastname",
	"TEMPLATE.USER.USERNAME": "Username",
	"TEMPLATE.USER.AVATAR": "Avatar",
	"TEMPLATE.USER.EMAIL": "E-mail",
	"TEMPLATE.USER.PHONE_NUMBER": "Phonenumber",

	//WARNING
	"WARNING.SUPPORT.ERROR":
		"Something went wrong, this page can't be displayed correctly. If this keeps happing pleas contact support@bothive.be",
	"WARNING.SUPPORT.IE_NOT_SUPPORTED":
		"Our dashboard no longer supports this browser. You can still use the website but for the best user experience we recommend using a modern browser such as Chrome, Firefox or Use Safari. However, the chatbot widget on your website becomes still supported in all browsers so that your customers can reach you everywhere.",
	"WARNING.SUPPORT.BROWSER_IS_OFFLINE": "No internet connection found",

	//WIDGET_BUBBLE
	"WIDGET_BUBBLE.SENDER.SEND_BY": "New message from {name}",

	//WIDGET_MOCKUP
	"WIDGET_MOCKUP.FOOTER.POWERED_BY.LABEL": "Powered by Bothive",
	"WIDGET_MOCKUP.FOOTER.SEND.PLACEHOLDER": "Ask me a question",

	//MOLECULES
	"MOLECULES.LOCATION_CARD.PRIMARY_ADDRESS": "Primary address",

	"MOLECULES.UNLIMITED": "Unlimited",
	"MOLECULES.HOURS_RANGE.2_TIME_SLOTS": "{label} from {timeSlot1} to {timeSlot2}",
	"MOLECULES.HOURS_RANGE.4_TIME_SLOTS": "{label} from {timeSlot1} to {timeSlot2} and from {timeSlot1} to {timeSlot2}",

	"MOLECULES.DETAIL_TOGGLE.OFF": "Deactivated",
	"MOLECULES.DETAIL_TOGGLE.ON": "Active",

	"MOLECULES.DATA_PREVIEW.PDF.TEMPLATE_FAILED": "Template couldn't load",

	"MOLECULES.EMAIL_HEADER.TO": "To:",
	"MOLECULES.EMAIL_HEADER.FROM": "From:",
	"MOLECULES.EMAIL_HEADER.CC": "Cc:",
	"MOLECULES.EMAIL_HEADER.BCC": "Bcc:",

	"MOLECULES.EMAIL_HEADER.TITLE": "Reply",
	"MOLECULES.EMAIL_HEADER.OPTIONS.CLOSE_REPLY": "Close reply",
	"MOLECULES.EMAIL_FOOTER.ATTACHMENTS": "{count, plural, one {# attachment} other {# attachments}}",

	"MOLECULES.EMAIL_FOOTER.ADD_SIGNATURE": "Add signature",

	"MOLECULES.FOOTER.CANCEL": "Cancel",
	"MOLECULES.FOOTER.DELETE": "Delete",
	"MOLECULES.FOOTER.SAVE": "Save",

	"MOLECULES.INDICATOR.IS_TYPING": "{username} is typing",

	//MODEL
	"MODEL.CONTACT.REFERENCE_ID": "Reference id",
	"MODEL.CONTACT.FIRST_NAME": "First name",
	"MODEL.CONTACT.LAST_NAME": "Last name",
	"MODEL.CONTACT.USERNAME": "Full name",
	"MODEL.CONTACT.GENDER": "Gender",
	"MODEL.CONTACT.AVATAR": "Avatar",
	"MODEL.CONTACT.BIRTHDAY": "Birthday",
	"MODEL.CONTACT.BIRTH_COUNTRY": "Birth country",
	"MODEL.CONTACT.BIRTH_PLACE": "Birth place",
	"MODEL.CONTACT.EMAIL": "E-mail",
	"MODEL.CONTACT.PHONE": "Phone",
	"MODEL.CONTACT.LANGUAGE": "Language",
	"MODEL.CONTACT.NATIONAL_INSURANCE_NUMBER": "National insurance number",
	"MODEL.CONTACT.COMPANY_NUMBER": "Company number",

	"MODEL.CONTACT.ADDRESS.STREET_NUMBER": "Address: Street number",
	"MODEL.CONTACT.ADDRESS.STREET_NAME": "Address: Street name",
	"MODEL.CONTACT.ADDRESS.BUS": "Address: Bus",
	"MODEL.CONTACT.ADDRESS.CITY": "Address: City",
	"MODEL.CONTACT.ADDRESS.PROVINCE": "Address: Province",
	"MODEL.CONTACT.ADDRESS.COUNTRY": "Address: Country",
	"MODEL.CONTACT.ADDRESS.POSTAL_CODE": "Address: Postal code",
	"MODEL.CONTACT.ADDRESS.PRIMARY": "Address: Dommicilie",

	"MODEL.CONTACT.ATTRIBUTES.BANK.ACCOUNT_NUMBER": "Bank: Account number",
	"MODEL.CONTACT.ATTRIBUTES.BANK.IBAN": "Bank: IBAN",
	"MODEL.CONTACT.ATTRIBUTES.BANK.COUNTRY": "Bank: Country",
	"MODEL.CONTACT.ATTRIBUTES.BANK.BIC": "Bank: BIC",
	"MODEL.CONTACT.ATTRIBUTES.BANK.OPENED_AT": "Bank: Account opened at",
	"MODEL.CONTACT.ATTRIBUTES.BANK.CLOSED_AT": "Bank: Account closed at",
	"MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.NAME": "Bank: Name",
	"MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.STREET_AND_NUMBER": "Bank: Street & number",
	"MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.CITY": "Bank: City",
	"MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.PROVINCE": "Bank: Province",
	"MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.COUNTRY": "Bank: Country",
	"MODEL.CONTACT.ATTRIBUTES.BANK.INSTITUTION.ADDRESS.POSTAL": "Bank: Postal code",
	"MODEL.CONTACT.ATTRIBUTES.BANK.REPORTED_TO_GOVERNMENT_YES_NO": "Bank: Reported to government",
	"MODEL.CONTACT.ATTRIBUTES.BANK.RECEIVED_INTERESTS": "Bank: Interest verkregen in euro",

	"MODEL.CONTACT.ATTRIBUTES.OWNER_OF_FOREIGN_LEGAL_CONSTRUCTIONS": "Owner of foreign legal constructions",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_INCOME_RECIEVED":
		"Foreign legal constructions: Income received",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_INCOME_AMOUNT.DIVIDENDS":
		"Foreign legal constructions: Income amount: Dividends",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_INCOME_AMOUNT.INTERESTS":
		"Foreign legal constructions: Income amount: Interests",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_INCOME_AMOUNT.OTHER":
		"Foreign legal constructions: Income amount: Other",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_LEGAL_CONSTRUCTION_ALREADY_WITHHOLDED_TAX":
		"Foreign legal constructions: Already withheld tax",

	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.NAME": "Foreign real estate: Name",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.RENTED_OUT": "Foreign real estate: Rented out",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.YEARLY_RENTABLE_INCOME":
		"Foreign real estate: Yearly rentable income",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.TAX_PAYED_ABROAD": "Foreign real estate: Tax paid abroad",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.PURCHASED_AT": "Foreign real estate: Purchased at",
	"MODEL.CONTACT.ATTRIBUTES.FOREIGN_REAL_ESTATE.SOLD_AT": "Foreign real estate: Sold at",

	"MODEL.CONTACT.ATTRIBUTES.HAS_DISABILITY": "Personal: Has disability",
	"MODEL.CONTACT.ATTRIBUTES.IBAN_FOR_TAX_RETURNS": "Personal: IBAN for tax returns",
	"MODEL.CONTACT.ATTRIBUTES.MARITAL_STATUS": "Personal: Marital status",
	"MODEL.CONTACT.ATTRIBUTES.MATRIMONIAL_PROPERTY_SYSTEM": "Personal: Matrimonial property system",
	"MODEL.CONTACT.ATTRIBUTES.PARTNER.FIRST_NAME": "Personal: Partner: First name",
	"MODEL.CONTACT.ATTRIBUTES.PARTNER.LAST_NAME": "Personal: Partner: Last name",
	"MODEL.CONTACT.ATTRIBUTES.BIRTH_PLACE": "Personal: Birth place",
	"MODEL.CONTACT.ATTRIBUTES.BIRTH_COUNTRY": "Personal: Birth country",

	"MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.TYPE": "Dependant: Type",
	"MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.NAME": "Dependant: Name",
	"MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.HAS_DISABILITY": "Dependant: Has disability",
	"MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.BIRTHDAY": "Dependant: Birthday",
	"MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.CHILDCARE_CERTIFICATE": "Dependant: Childcare certificate",
	"MODEL.CONTACT.ATTRIBUTES.DEPENDANTS.DEPENDENCY_TYPE": "Dependant: Dependency type",

	"MODEL.CONTACT.SOCIAL_SECURITY_SECRETARIAT": "Social security secretariat",
	"MODEL.CONTACT.SOCIAL_SECURITY_CASE_NUMBER": "Social security case number",
	"MODEL.CONTACT.SOCIAL_SECURITY_CONTACT": "Social security contact",
	"MODEL.CONTACT.LABELS": "Labels",

	"MODEL.CONTACT.TIME_ZONE": "Timezone",
	"MODEL.CONTACT.CREATED_AT": "Contact since",

	//DATE
	"DATE_PICKER.STATIC_RANGE.THIS_WEEK": "This week",
	"DATE_PICKER.STATIC_RANGE.LAST_WEEK": "Last week",
	"DATE_PICKER.STATIC_RANGE.THIS_MONTH": "This month",
	"DATE_PICKER.STATIC_RANGE.LAST_MONTH": "Last month",
	"DATE_PICKER.STATIC_RANGE.LAST_6_MONTHS": "Last 6 months",
	"DATE_PICKER.STATIC_RANGE.THIS_YEAR": "This year",
	"DATE_PICKER.STATIC_RANGE.LAST_2_YEARS": "Last 2 year",
	"DATE_PICKER.STATIC_RANGE.LAST_5_YEARS": "Last 5 year",

	//REPORT PROGRESS
	CREATED: "Created",
	COMPLETE: "Complete",
	COMPLETED: "Complete",
	IN_PROGRESS: "In progress",
	INVITE_SEND: "Invite sent",
	PENDING_SEND: "Pending invite send",

	//FLOW OVERVIEW SEND FLOW MODAL
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.HEADER.TITLE": 'Send flow "{flowName}" to contacts',
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.FOOTER.SEND": "Send",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.LABEL": "Label",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.LABEL.PLACEHOLDER": "Send email to contacts by label",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.CONTACT": "Contact",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.CONTACT.PLACEHOLDER": "Select contacts",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.ACCOUNT_MANAGER": "Account manager",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.ACCOUNT_MANAGER.PLACEHOLDER": "Select contacts by account manager",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.PROGRESS": "Progress",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.PROGRESS.PLACEHOLDER": "Select contacts by the progress of their reports",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.ALL": "All contacts",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.FILTER.LABEL": "Filter",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.FILTER.REQUIRED_ERROR": "You haven't completely filled in the filter",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.LABEL": "Channel",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.REQUIRED_ERROR": "You haven't selected a channel.",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.RECONNECT.TOOLTIP": "Reconnect this channel",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.LABEL": "Message",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.ERROR_EMPTY": "You haven't filled in a message",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.ERROR_NO_LINK_VARIABLE":
		"Please add {{flow_link}} variable, this allows your customers to use the personalised flow experience.",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SIGNATURE.LABEL": "Signature",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SIGNATURE.NOSIGNATURES":
		"You haven't added signatures yet, you can add one in Settings -> Email signatures",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.PLACEHOLDER":
		"Write message to your contacts. Please use variable {{ flow_link }} to refer to the link your contacts need to use to fill in this flow. You can add this (and other) variables by clicking insert + template",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.NO_INBOX.TITLE": "Functionality unavailable.",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.NO_INBOX.DESCRIPTION":
		"To send flow links to your contacts you need to connect your email address with Bothive inbox. Please contact us for a demo.",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.NO_INBOX.FOOTER.CANCEL": "Cancel",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.NO_INBOX.FOOTER.REQUEST_DEMO": "Request inbox demonstration",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2022.TITLE": "Personal tax flow",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2022.DESCRIPTION":
		"You can use this template as a starting point as a message to your clients when they receive their personalised flow link.",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2024.TITLE": "Personal tax flow",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.PB2024.DESCRIPTION":
		"You can use this template as a starting point as a message to your clients when they receive their personalised flow link.",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.DEFAULT.TITLE": "Flow sending template",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.TEMPLATES.DEFAULT.DESCRIPTION":
		"You can use this template as a starting point as a message to your clients when they receive their personalised flow link.",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.FOOTER.COPY_LINK.TOOLTIP": "Click here to copy flow link",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.FOOTER.COPY_LINK": "Copy flow link",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.FOOTER.SEND.DISABLED": "Please fill in all necessary fields",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.ALERT_VARIABLE.TITLE": "Add flow link variable",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.ALERT_VARIABLE.DESCRIPTION":
		"Please add {{flow_link}} variable, this allows your customers to use the personalised flow experience.",

	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.SUBJECT.LABEL": "Subject",

	"FLOWS_OVERVIEW.SEND_FLOW.BANNER.SUCCESS": "Flow mail successfully sent",
	"FLOWS_OVERVIEW.SEND_FLOW.BANNER.FAILED": "Flow mail could not be sent",

	"FLOWS_OVERVIEW.TABLE.HEADER.SEND_FLOW": "Send flow",
	"FLOWS_OVERVIEW.TABLE.ROW.SEND_FLOW": "Send flow",

	"FLOWS_OVERVIEW.SEND_FLOW.SUCCESS.TITLE": "Your flow is being sent",
	"FLOWS_OVERVIEW.SEND_FLOW.SUCCESS.DESCRIPTION":
		'We\'re sending your flow to all selected contacts. This can take up to 30 minutes. You can view the status per report in reports overview under "Progression" You will also receive a notification when all emails are sent.',
	"FLOWS_OVERVIEW.SEND_FLOW.SUCCESS.BUTTON": "Go to reports",

	"FLOWS.OVERVIEW.TABLE.FLOW": "Flow",
	"FLOWS.OVERVIEW.TABLE.FLOW.FETCH_FAILED.TITLE": "Failed to get flows",
	"FLOWS.OVERVIEW.TABLE.FLOW.FETCH_FAILED.BUTTON": "Retry",
	"FLOWS.OVERVIEW.TABLE.QUERY.NOT_FOUND": "No flows found",
	"FLOWS.OVERVIEW.TABLE.STATUS": "Status",
	"FLOWS.OVERVIEW.TABLE.STATE.ACTIVE": "Active",
	"FLOWS.OVERVIEW.TABLE.STATE.INACTIVE": "Inactive",
	"FLOWS.OVERVIEW.TABLE.BUTTON.COPY_FLOW_LINK": "Copy link",
	"FLOWS.OVERVIEW.TABLE.BUTTON.SEND_FLOWS": "Send flow",

	"FLOWS.OVERVIEW.FIRST_TIME.TITLE": "Create your first flow",
	"FLOWS.OVERVIEW.FIRST_TIME.DESCRIPTION":
		"With flows, you’ll be able to digitally process administrative procedures. Users are guided through an automatically generated flow, step by step. They complete the process autonomously in an interactive and user friendly chat environment.",
	"FLOWS.OVERVIEW.FIRST_TIME.BUTTON_LABEL": "See all or templates",

	"FLOWS.OVERVIEW.LOCK.TITLE": "Flows the forms of the future",
	"FLOWS.OVERVIEW.LOCK.DESCRIPTION":
		"With flows, you’ll be able to digitally process administrative procedures. Users are guided through an automatically generated flow, step by step. They complete the process autonomously in an interactive and user friendly chat environment.",
	"FLOWS.OVERVIEW.LOCK.UPGRADE_BUTTON": "Upgrade your plan",

	"FLOWS.OVERVIEW.SEARCH.PLACEHOLDER": "Search for flow",
	"FLOWS.OVERVIEW.SEARCH.TOTAL_FOUND":
		"{total, plural, =0 {No flows found} one {# flow found} other {# flows found}} {totalEnabled, plural, =0 { } one {from which # active flow} other {from which # active flows}}",
	"FLOWS.OVERVIEW.ADD_FLOW.BUTTON": "Add flow",

	"FLOWS.OVERVIEW.ADD_FLOW.TITLE": "Flow template",
	"FLOWS.OVERVIEW.ADD_FLOW.FETCH_FAILED.TITLE": "Failed to get flows",
	"FLOWS.OVERVIEW.ADD_FLOW.DESCRIPTION":
		"Choose a template to add a flow. Would you like to request a new kind of flow? Please contact us through our customer support or mail us at support@bothive.be",

	"FLOWS.OVERVIEW.ADD_FLOW.FALLBACK.DESCRIPTION":
		"There is no description available for this flow. Contact our support team for more information",
	"FLOWS.OVERVIEW.ADD_FLOW.FALLBACK.BUTTON": "Contact support",

	"FLOWS.OVERVIEW.ADD_FLOW.WARNING.PLAN.TITLE": "Upgrade your plan.",
	"FLOWS.OVERVIEW.ADD_FLOW.WARNING.PLAN.DESCRIPTION":
		"You need to upgrade your plan to create a new flow. These are the available flows if you want to see a demo or more information contact or support team.",

	"FLOWS.OVERVIEW.ADD_FLOW.WARNING.EXISTS.TITLE": "{name} already exists",
	"FLOWS.OVERVIEW.ADD_FLOW.WARNING.EXISTS.DESCRIPTION":
		"You've already made one of these flows, you can still create another one",

	"FLOWS.OVERVIEW.ADD_FLOW.WARNING.LOCKED.TITLE": "Upgrade your plan.",
	"FLOWS.OVERVIEW.ADD_FLOW.WARNING.LOCKED.DESCRIPTION":
		"You need to upgrade your plan to create a new flow. These are the available flows if you want to see a demo or more information contact or support team.",

	"FLOWS.OVERVIEW.ADD_FLOW.BANNER.FAILED": "Flow cannot be created at the moment. Try again later.",

	"FLOWS.REPORT.OVERVIEW.TABLE.FETCH_FAILED.TITLE": "Could not fetch reports",
	"FLOWS.REPORT.OVERVIEW.TABLE.EMPTY.TITLE":
		"Here you'll find the report of all completed flows. Currently, no one has completed any flows.",
	"FLOWS.REPORT.OVERVIEW.TABLE.FETCH_FAILED.BUTTON": "Retry",
	"FLOWS.REPORT.OVERVIEW.TABLE.QUERY.NOT_FOUND": "No reports found",
	"FLOWS.REPORT.OVERVIEW.TABLE.FIRSTNAME": "First name",
	"FLOWS.REPORT.OVERVIEW.TABLE.LASTNAME": "Last name",
	"FLOWS.REPORT.OVERVIEW.TABLE.ASSIGNEE": "Assigned to",
	"FLOWS.REPORT.OVERVIEW.TABLE.STATUS": "Status",
	"FLOWS.REPORT.OVERVIEW.TABLE.PROGRESS": "Progression",
	"FLOWS.REPORT.OVERVIEW.TABLE.LABELS": "Contact labels",
	"FLOWS.REPORT.OVERVIEW.TABLE.CREATED_AT": "Created at",
	"FLOWS.REPORT.OVERVIEW.TABLE.LAST_UPDATED": "Last changed",

	"FLOWS.REPORT.OVERVIEW.FETCH_FAILED.TITLE": "Could not fetch status types",
	"FLOWS.REPORT.OVERVIEW.FILTER.STATUS.LABEL": "Status",
	"FLOWS.REPORT.OVERVIEW.FILTER.STATUS.PLACEHOLDER": "All open statuses",
	"FLOWS.REPORT.OVERVIEW.FILTER.PROGRESS.LABEL": "Progress",
	"FLOWS.REPORT.OVERVIEW.FILTER.PROGRESS.PLACEHOLDER": "All progress levels",
	"FLOWS.REPORT.OVERVIEW.LABELS.TITLE": "Filter on contact label",
	"FLOWS.REPORT.OVERVIEW.LABELS.PLACEHOLDER": "Filter on contact label",
	"FLOWS.REPORT.OVERVIEW.FILTER.SEARCH.LABEL": "Search on contact",
	"FLOWS.REPORT.OVERVIEW.FILTER.SEARCH.PLACEHOLDER":
		"Search on contact name or email ex.: sophia or sophia@bothiv.be ...",
		"FLOWS.REPORT.OVERVIEW.LABELS.LABEL":  "Filter contact labels",
		"FLOWS.REPORT.OVERVIEW.LABELS.PLACEHOLDER": "Filter contact labels",
	"FLOWS.REPORT.OVERVIEW.FILTER.DETAIL.TOTAL_RESULTS":
		"{total, plural, =0 {No reports found} one {# report found} other {# reports found}}",
	"FLOWS.REPORT.SETTINGS.CUSTOMIZE.FORM.NAME.LABEL": "Name",
	"FLOWS.REPORT.SETTINGS.CUSTOMIZE.FORM.NAME.LABEL.REQUIRED": "Please fill in a flow name",

	//--------------//
	// BULK ACTIONS //
	//--------------//
	// DOWNLOAD REPORT
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SELECTION_TOTAL":
		"Currently there {selection, plural, =0 {are no reports} one {is # report} other {are # reports}} selected.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SELECT_ALL":
		" Select {total, plural, one {# report} other {all reports (#)}}",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.DESELECT_ALL": "Undo selection",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.DOWNLOAD_REPORTS": "Download Reports",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.TITLE": "Download Reports",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.CONFIRM": "Confirm",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.INFO_MESSAGE":
		"You are about to download {totalReportsSelected, plural, =0 {0 reports} one {# {reportType} report} other {# {reportType} reports}} {withAttachments, plural, =0 {excluding} one {including} other {}} attachments. You'll receive a notification and email when the files are ready to be downloaded.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.REPORT_TYPE.LABEL": "Report type",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.REPORT_TYPE.HELP":
		"Desired file type for the flow reports.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.INCLUDE_ATTACHMENTS.LABEL": "Include attachments",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.INCLUDE_ATTACHMENTS.HELP":
		"Include all attachments for each report.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.SUCCESS.TITLE": "The report download has started.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.SUCCESS.DESCRIPTION":
		"You will receive a notification and email containing the download link the files have been processed.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.FAILED.TITLE": "Bulk download error",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.FAILED.DESCRIPTION":
		"The bulk download action failed to start.",
	// CHANGE STATUS
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.CHANGE_STATUS": "Change Status",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.TITLE": "Change report status type",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.CONFIRM": "Confirm",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.INFO_MESSAGE":
		"You are about to change the status type of {totalReportsSelected, plural, =0 {0 reports} one {# report} other {# reports}}. You'll receive a notification when the action is complete.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.STATUS_TYPE.LABEL": "Status type",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.STATUS_TYPE.PLACEHOLDER": "Select status type...",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.CHANGE_STATUS.STATUS_TYPE.REQUIRED_MSG":
		"Please select a new status type first.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.SUCCESS.TITLE": "Bulk report status change started.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.SUCCESS.DESCRIPTION":
		"You will receive a notification when the action is complete.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.FAILED.TITLE": "Bulk report status change error.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.CHANGE_STATUS.FAILED.DESCRIPTION": "The bulk report status change failed",
	// SEND TO INTEGRATION
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SEND_TO_INTEGRATION": "Send to integration",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SEND_TO_INTEGRATION.DISABLED": "No integrations available",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.TITLE": "Send to integration",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.RULE.LABEL": "Integratie selecteren",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.RULE.REQUIRED": "Please select an integration",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.REPORT_TYPE.LABEL": "Report type",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.REPORT_TYPE.HELP":
		"Not every report type is supported by every integration",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.DUPLICATES.LABEL": "Send duplicate reports",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.DUPLICATES.HELP":
		"If reports were previously sent, they will be sent again",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.SEND_TO_INTEGRATION.INFO_MESSAGE":
		"You are about to send of {totalReportsSelected, plural, =0 {0 reports} one {# report} other {# reports}}. You'll receive a notification when the action is complete.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.SUCCESS.TITLE": "Bulk sending has been started",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.SUCCESS.DESCRIPTION":
		"You will receive a notification when the action is complete.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.FAILED.TITLE": "Bulk sending error.",
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.SEND_TO_INTEGRATION.FAILED.DESCRIPTION": "Bulk sending has failed",
	// PARTNER PUSH
	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.PARTNER_PUSH": "Partner Push",

	"FLOWS.REPORT.TEMPLATE.ASIDE.BACK_TO_OVERVIEW.LABEL": "Back to overview",
	"FLOWS.REPORT.TEMPLATE.ASIDE.BACK_TO_REPORT_OVERVIEW.LABEL": "Back to report overview",
	"FLOWS.REPORT.TEMPLATE.ASIDE.REPORT.LABEL": "Reports",
	"FLOWS.REPORT.TEMPLATE.ASIDE.CONFIGURATION.LABEL": "Personalization",
	"FLOWS.REPORT.TEMPLATE.ASIDE.STATUS_TYPES.LABEL": "Status types",
	"FLOWS.REPORT.TEMPLATE.ASIDE.SETTINGS.LABEL": "Flow Settings",

	"FLOWS.REPORT_DETAIL.TEMPLATE.PAGE.TITLE": "Report detail",
	"FLOWS.REPORT_DETAIL.TEMPLATE.BREADCRUMB.FLOWS": "Flows",

	"FLOWS.REPORT_DETAIL.HEADER.PREVIEW_STATE.LABEL": "View as:",

	"FLOWS.REPORT_DETAIL.REPORT_LIST.FETCH_FAILED.TITLE": "Reports could not be fetched",
	"FLOWS.REPORT_DETAIL.REPORT_LIST.FALLBACK.DESCRIPTION": "No reports found",
	"FLOWS.REPORT_DETAIL.REPORT_LIST.END_OF_LIST": "All reports are fetched 🙌",

	"FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.LABEL": "Assigned to",
	"FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.PLACEHOLDER": "Assignee an team member",
	"FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.BANNER.ASSIGN_USER_FAILED":
		"{username} could not be assigned to report. Please try again later.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.ASSIGNEE.BANNER.UNASSIGN_FAILED":
		"could not unassigne user from report. Please try again later.",

	"FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.MARK_AS_COMPLETED": "Mark as completed",
	"FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.MARKED_AS_COMPLETED": "Marked as completed",

	"FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.LABEL": "Status",
	"FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.PLACEHOLDER": "Set the report status",
	"FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.BANNER.FAILED": "Status type could not be changed. Please try again later.",

	"FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.AUTHOR.LABEL": "Filled in by:",
	"FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.PROGRESS.LABEL": "Progress",
	"FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.CREATED_AT.LABEL": "Created at:",
	"FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.UPDATED_AT.LABEL": "Last edit by my:",
	"FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.LAST_FILLED_IN_AT.LABEL": "Last edit by contact:",
	"FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.LAST_SYNCED_TO_CONTACT_AT.LABEL": "Last sync to contact:",

	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.TITLE": "Automatisation",
	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.FOOTER.ADD_BUTTON": "Add automatisation",

	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.CONFIRMATION_MODAL.CANCEL": "Cancel",
	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.BANNER.FAILED": "Automation could not be executed, try again later.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.BANNER.SUCCEEDED": "Automation has been executed.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.TOOLTIP.SUCCEEDED": "Automation has been executed.",
	"FLOWS.REPORT_DETAIL.META_ASIDE.AUTOMATION.TOOLTIP.FAILED": "Automation could not be executed.",

	"FLOWS.REPORTS.STATUS_TYPES.ADD.BUTTON": "Add new status type",
	"FLOWS.REPORTS.STATUS_TYPES.TABLE.STATUS_TYPE": "Status type",
	"FLOWS.REPORTS.STATUS_TYPES.TABLE.DEFAULT": "Default",
	"FLOWS.REPORTS.STATUS_TYPES.TABLE.IS_DONE": "Completed",
	"FLOWS.REPORTS.STATUS_TYPES.TABLE.EMPTY_TEXT": "No status types found.",
	"FLOWS.REPORTS.STATUS_TYPES.REORDER_FAILED": "Re ordering status types failed, try again later.",

	"FLOWS.REPORTS.STATUS_TYPES.COPY.TITLE": "Copy status types",
	"FLOWS.REPORTS.STATUS_TYPES.COPY.DESCRIPTION":
		"Quickly copy the status types from another flow into this one to keep your workflow.",
	"FLOWS.REPORTS.STATUS_TYPES.COPY.BUTTON": "Copy status types",

	"FLOWS.REPORTS.STATUS_TYPES.MODAL.ADD": "Add",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.UPDATE": "Update",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.ADD.TITLE": "Add status type",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.ADD.FAILED": "Failed to add status type",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT": "Edit",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT.TITLE": "Edit status type",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT.IS_DEFAULT.TOOLTIP":
		"Status types which are assigned by default can not be deleted",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT.FAILED": "Could not edit status type",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT.DUPLICATE_NAME": "Status name already exist in this flow.",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE": "DELETE",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE.FAILED": "Could not delete status type",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE.SUCCEEDED": "Status type has been deleted",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.NAME": "Name",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.NAME.REQUIRED": "Please give your statustype a name",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DESCRIPTION": "Description",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DESCRIPTION.PLACEHOLDER": "When do you use this statustype?",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DEFAULT": "Default",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DEFAULT.DESCRIPTION": "Status type will be assigned to new reports",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DEFAULT.WARNING_BANNER.NEED_ONE":
		"Flows always need 1 default status type. Make a other status type default to turn this one off.",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DONE.WARNING_BANNER.REPLACE":
		"This will replace <bold>{{default}}</bold> as default status type",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DONE": "Voltooid",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.DONE.DESCRIPTION":
		"Reports with this status type will be seen as done and will not be shown in the report list by default.",

	"FLOWS.REPORTS.STATUS_TYPES.MAX.WARNING":
		"You've used {currentStatusTypes} of your {maxStatusTypes} available statustypes, upgrade your plan to increase this limit.",
	"FLOWS.REPORTS.STATUS_TYPES.MAX.LIMIT":
		"You have reached your limit of {maxStatusTypes} statustypes, upgrade your plan to increase this limit.",

	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY": "Copy",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.BUTTON": "Copy status types",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.TITLE": "Copy status types from other flow",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.FLOWS": "Flows",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.FLOWS.REQUIRED": "Please select a flow",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.FLOWS.SEARCH_NOT_FOUND": "No flows found",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.OVERWRITE": "Overwrite",
	"FLOWS.REPORTS.STATUS_TYPES.MODAL.COPY.OVERWRITE.HELP": "Overwrite existing status types of this flow",
	"FLOWS.REPORTS.STATUS_TYPES.COPY.SUCCEEDED": "Status types have been copied.",
	"FLOWS.REPORTS.STATUS_TYPES.COPY.FAILED": "Failed to copy status types, try again later.",

	"FLOWS.REPORT.STATUS_TYPES.TABLE.STATUS_TYPE": "Statustype",
	"FLOWS.REPORT.STATUS_TYPES.TABLE.DEFAULT": "Standaard",
	"FLOWS.REPORT.STATUS_TYPES.TABLE.IS_DONE": "Voltooid",

	"FLOWS.REPORT.FAILED.TITLE": "No flow found",
	"FLOWS.REPORT.FAILED.BUTTON": "Go to flow overview",

	"FLOWS.REPORT.SETTINGS.LOADING": "Fetching flow settings",
	"FLOWS.REPORT.SETTINGS.CUSTOMIZE.HEADER.TITLE": "Manage flow",
	"FLOWS.REPORT.SETTINGS.CUSTOMIZE.HEADER.DESCRIPTION":
		"Manage the flow settings and personalize the flow name and description.",
	"FLOWS.REPORT.SETTINGS.FLOW_NAME.FORM.NAME.LABEL": "Name",
	"FLOWS.REPORT.SETTINGS.FLOW_NAME.FORM.NAME.LABEL.REQUIRED": "Please fill in a flow name",
	"FLOWS.REPORT.SETTINGS.CUSTOMIZE.FORM.DESCRIPTION.LABEL": "Description",
	"FLOWS.REPORT.SETTINGS.CUSTOMIZE.FORM.DESCRIPTION.LABEL.REQUIRED": "Please fill in a flow description",
	"FLOWS.REPORT.SETTINGS.FORM.IS_ENABLED.LABEL": "Enable flow",
	"FLOWS.REPORT.SETTINGS.FORM.IS_ENABLED.HELP": "If disabled, the flow will not be visible to the users",
	"FLOWS.REPORT.SETTINGS.FORM.SHOW_FLOW.LABEL": "Show flow on your website",
	"FLOWS.REPORT.SETTINGS.FORM.SHOW_FLOW.HELP":
		"If you disable this setting, the flow will not be shown on your website",

	"FLOWS.REPORT.SETTINGS.UNIQUE_LINK.LABEL": "Unique link",
	"FLOWS.REPORT.SETTINGS.UNIQUE_LINK.TOOLTIP": "Copy link",
	"FLOWS.REPORT.SETTINGS.UNIQUE_LINK.HELP": "Share this link with customers where you want to get their data.",

	"FLOWS.REPORT.SETTINGS.FORM.SUCCEEDED": "Flow updated",
	"FLOWS.REPORT.SETTINGS.FORM.FAILED": "Failed to update flow",
	"FLOWS.REPORT.SETTINGS.FORM.FAILED.DESCRIPTION": "Please try again later or contact customer support",

	"FLOWS.REPORT.SETTINGS.WIDGET.HEADER.TITLE": "Widget settings",
	"FLOWS.REPORT.SETTINGS.WIDGET.HEADER.DESCRIPTION":
		"Customize how this flow will be shown in the widget on your website",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.TITLE": "Icon",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.CUSTOMIZE": "Customize icon",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.LABEL": "Upload icon",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.HELP": "Maximum size: 10MB",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.ERROR.FILE_SIZE": "Please upload an icon with a size of less than 10MB",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.LOGO.ERROR.FILETYPE":
		"Please upload an icon in one of the following formats: jpg or png",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.NAME.LABEL": "Name",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.NAME.LABEL.REQUIRED": "Please fill in a name",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.DESCRIPTION.LABEL": "Description",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.DESCRIPTION.LABEL.REQUIRED": "Please fill in a description",
	"FLOWS.REPORT.SETTINGS.WIDGET.FORM.BUTTON.SAVE": "Save",

	"FLOWS.REPORT.SETTINGS.WIDGET.PREVIEW.TITLE": "Widget preview",
	"FLOWS.REPORT.SETTINGS.WIDGET.PREVIEW.DESCRIPTION": "This is how your widget will look like on your website",

	"FLOWS.REPORTS.CONFIGURATION.FALLBACK.TITLE": "Personalize your flow",
	"FLOWS.REPORTS.CONFIGURATION.FALLBACK.DESCRIPTION":
		"Do you want to retrieve more data or do you find the flow just too extensive? Soon you will be able to edit our standard flows to your preference. Contact our support team to be the first to access this feature!",
	"FLOWS.REPORTS.CONFIGURATION.FORM.SUBMIT": "Save",

	"FLOW.REPORTS.CONFIGURATION.UPDATE.SUCCESS.TITLE": "Flow configuration updated",
	"FLOW.REPORTS.CONFIGURATION.UPDATE.FAILED.TITLE": "Flow configuration failed to update",
	"FLOW.REPORTS.CONFIGURATION.UPDATE.FAILED.DESCRIPTION": "Please try again later or contact customer support",
	"FLOW.REPORTS.CONFIGURATION.FETCH_FAILED.TITLE": "Failed to fetch flow configuration",
	"FLOW.REPORTS.CONFIGURATION.FETCH_FAILED.BUTTON": "Try again",

	"FLOWS.DETAIL.SETTINGS.DELETE_SUCCEEDED.TITLE": "Flow is successfully removed.",
	"FLOWS.DETAIL.SETTINGS.DELETE_FAILED.TITLE": "Flow could not be removed. Please try again later.",

	"FLOWS.REPORT_DETAIL.FETCH_FAILED.TITLE": "Failed to fetch flow",

	"FLOWS.REPORT_DETAIL.PDF.FAILED.TITLE": "Failed to generate PDF",
	"FLOWS.REPORT_DETAIL.PDF.FAILED.DESCRIPTION": "Please try again later or contact customer support",

	"FLOWS.REPORT_DETAIL.ACTIONS.TITLE": "Actions",
	"FLOWS.REPORT_DETAIL.ACTIONS.EDIT": "Edit",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOADEN": "Download",
	"FLOWS.REPORT_DETAIL.ACTIONS.SAVE_TO_CONTACT": "Save to contact",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS": "Download attachments",
	"FLOWS.REPORT_DETAIL.ACTIONS.REMINDER": "Send reminder",
	"FLOWS.REPORT_DETAIL.ACTIONS.DELETE": "Delete",

	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.SUCCEEDED.TITLE": "Report downloaded",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.FAILED.TITLE": "Failed to download report",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.FAILED.DESCRIPTION":
		"Please try again later or contact customer support",

	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.SUCCEEDED.TITLE": "Attachments downloaded",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.FAILED.TITLE": "Failed to download attachments",
	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.FAILED.DESCRIPTION":
		"Please try again later or contact customer support",

	"FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_ATTACHMENTS.BUTTON.BANNER.NO_RESULT.TITLE": "No attachments found",

	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.TITLE": "Delete report",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.DESCRIPTION":
		"Are you sure you want to delete this report? This action can not be undone.",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.LABEL.BUTTON_LABEL": "Delete report",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.SENT.WARNING.TITLE": "This report has already been sent",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.SENT.WARNING.DESCRIPTION":
		"Your customer will not be able to fill in this report when you delete it",

	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.SUCCEEDED.TITLE": "Report deleted",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.FAILED.TITLE": "Report could not be deleted",
	"FLOW.REPORT_DETAIL.ACTIONS.DELETE.FAILED.DESCRIPTION": "Please try again later or contact customer support",

	"FLOWS.REPORT_DETAIL.EMPTY_REPORT.TITLE": "{username} hasn't started filling in this report.",
	"FLOWS.REPORT_DETAIL.VIEWER.FOOTER.CANCEL": "Cancel",
	"FLOWS.REPORT_DETAIL.VIEWER.FOOTER.SAVE": "Save",

	"FLOWS.REPORT_DETAIL.LIST.FILTER.TITLE":
		"{total, plural, =0 {No active filters} one {# active filter} other {# active filters}}",
	"FLOWS.REPORT_DETAIL.LIST.FILTER.SEARCH.LABEL": "Search on contact",
	"FLOWS.REPORT_DETAIL.LIST.FILTER.SEARCH.PLACEHOLDER": "Search on contact name or email.",
	"FLOWS.REPORT_DETAIL.LIST.FILTER.STATUS.LABEL": "Status",
	"FLOWS.REPORT_DETAIL.LIST.FILTER.STATUS.PLACEHOLDER": "All open statuses",
	"FLOWS.REPORT_DETAIL.LIST.FILTER.PROGRESS.LABEL": "Progress",
	"FLOWS.REPORT_DETAIL.LIST.FILTER.PROGRESS.PLACEHOLDER": "All progress levels",

	"flows.report_detail.list.filter.labels.title": "Filter on contact label",
	"flows.report_detail.list.filter.labels.placeholder": "Filter on contact label",
	
	"FLOWS.REPORT_DETAIL.UPDATE.SUCCESS.TITLE": "Report updated",
	"FLOWS.REPORT_DETAIL.UPDATE.FAILED.TITLE": "Report failed to update",
	"FLOWS.REPORT_DETAIL.UPDATE.FAILED.DESCRIPTION": "Please try again later or contact customer support",

	"FLOWS.SEND.INPUT.WARNING.TITLE":
		"Please note that the link in the e-mail templates will only work if you send this e-mail via this module.",

	"CONTACTS.OVERVIEW.ERROR.TITLE": "Failed to fetch contacts",
	"CONTACTS.OVERVIEW.EMPTY.TITLE": "No contacts found",

	"CONTACTS.OVERVIEW.SELECT.TYPE.TITLE": "Select contact type",
	"CONTACTS.OVERVIEW.SELECT.TYPE.PLACEHOLDER": "Select contact type",
	"contact.overview.select.type.customer": "Customer",
	"contact.overview.select.type.prospect": "Prospect",
	"contact.overview.select.type.other": "Other",
	"contact.overview.select.type.linked_contact": "Linked contact",

	"CONTACTS.OVERVIEW.SELECT.LABELS.TITLE": "Select labels",
	"CONTACTS.OVERVIEW.SELECT.LABELS.PLACEHOLDER": "Select labels",

	"CONTACTS.OVERVIEW.SELECT.ACCOUNT_MANAGER.TITLE": "Select account manager",
	"CONTACTS.OVERVIEW.SELECT.ACCOUNT_MANAGER.PLACEHOLDER": "Select account manager",
	"CONTACTS.OVERVIEW.SELECT.ACCOUNT_MANAGER.NOT_FOUND": "No account managers found",

	"CONTACT.OVERVIEW.SEARCH.TITLE": "Search for contact",
	"CONTACT.OVERVIEW.SEARCH.PLACEHOLDER":
		"Search for contact name, email, phone number, national number or unique reference",

	"CONTACTS.OVERVIEW.SHOW_ARCHIVED.TITLE": "Show archived contacts",
	"CONTACT.OVERVIEW.SHOW_DELETED.TITLE": "Show deleted contacts",

	// CONTACT BULKACTIONS
	"CONTACT.OVERVIEW.BULK_ACTION.ACTION.ARCHIVE": "ARCHIVE",
	"CONTACT.OVERVIEW.BULK_ACTION.ACTION.DELETE": "DELETE",

	"CONTACT.OVERVIEW.BULK_ACTION.BUTTON.SELECTION_TOTAL":
		"Currently there {selection, plural, =0 {are no contacts} one {is # contact} other {are # contacts}} selected.",
	"CONTACT.OVERVIEW.BULK_ACTION.DELETE.BUTTON.TITLE": "Delete",
	"CONTACT.OVERVIEW.BULK_ACTION.BUTTON.SELECT_ALL":
		"Select {total, plural, one {# contact} other {all contacts (#)}}",
	"CONTACT.OVERVIEW.BULK_ACTION.BUTTON.DESELECT_ALL": "Undo selection",

	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.BUTTON.TITLE": "Archive",
	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.TITLE": "Archive contacts",
	"CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.BUTTON": "Archive contacts",

	"CONTACTS.OVERVIEW.BULK_ACTION.UNARCHIVE.BUTTON.TITLE": "Unarchive",
	"CONTACTS.OVERVIEW.BULK_ACTION.UNARCHIVE.BUTTON.DISABLED.TOOLTIP":
		"You have {count, plural, one {# deleted contact} other {# deleted contacts} } that are not archived inside your selection, you can't perform an unarchive action on contacts that are only deleted",

	"CONTACTS.OVERVIEW.ADD.MODAL.TITLE": "Add contact",
	"CONTACTS.OVERVIEW.ADD.MODAL.TYPE": "Contact type",
	"CONTACTS.OVERVIEW.ADD.MODAL.TYPE.REQUIRED": "Please select a contact type",
	"CONTACTS.OVERVIEW.ADD.MODAL.FIRSTNAME": "First name",
	"CONTACTS.OVERVIEW.ADD.MODAL.FIRSTNAME.REQUIRED": "Please enter a first name",
	"CONTACTS.OVERVIEW.ADD.MODAL.LASTNAME": "Last name",
	"CONTACTS.OVERVIEW.ADD.MODAL.LASTNAME.REQUIRED": "Please enter a last name",
	"CONTACTS.OVERVIEW.ADD.MODAL.EMAIL": "Email",
	"CONTACTS.OVERVIEW.ADD.MODAL.EMAIL.REQUIRED": "Please enter an email",
	"CONTACTS.OVERVIEW.ADD.MODAL.NATIONALREGISTRATIONNUMBER": "National registration number",
	"CONTACTS.OVERVIEW.ADD.MODAL.NATIONALREGISTRATIONNUMBER.REQUIRED": "Please enter a national registration number",
	"CONTACTS.OVERVIEW.ADD.MODAL.LABELS": "Label",
	"CONTACTS.OVERVIEW.ADD.MODAL.LABELS.EMPTY": "No labels found",
	"CONTACTS.OVERVIEW.ADD.MODAL.LABELS.EMPTY.BUTTON": "Add label",

	"contacts_import.validate.form.full_name.label": "Name",
	"contacts_import.validate.form.full_name.error_required": "Name is required",

	"CONTACT.OVERVIEW.ADD.MODAL.SUCCESS": "Contact created",
	"CONTACT.OVERVIEW.ADD.MODAL.FAILED": "Failed to create contact",
	"CONTACT.OVERVIEW.ADD.MODAL.FAILED.DESCRIPTION": "Please check if a contact with this email already exists",
	"CONTACT.OVERVIEW.ADD.MODAL.FAILED.DUPLICATE": "A contact with this {field} already exists",
	"CONTACT.OVERVIEW.ADD.MODAL.FAILED.DUPLICATE.DESCRIPTION": "Please use a different {field}",

	"CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.TITLE": "Delete contacts",
	"CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.OPTION.ARCHIVE":
		"Archive {count, plural, one {contact} other {contacts}} instead",
	"CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.BUTTON": "Delete contacts",

	"CONTACTS.OVERVIEW.DELETE.ALL.MODAL.TITLE": "Delete all contacts",
	"CONTACTS.OVERVIEW.DELETE.ALL.MODAL.BUTTON": "Delete all contacts",

	"CONTACTS.DETAIL.ACCOUNT_MANAGER.TITLE": "Account managers",
	"CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.TITLE": "Add account manager",
	"CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.ADD.FAILED": "Failed to add account manager",
	"CONTACTS.DETAIL.MODAL.ACCOUNT_MANAGER.REMOVE.FAILED": "Failed to remove account manager",
	"CONTACTS.DETAIL.ADD.ACCOUNT_MANAGER": "Add account manager",

	"CONTACTS.DETAIL.LABELS.TITLE": "Labels",
	"CONTACTS.DETAIL.MODAL.LABELS.TITLE": "Add labels",
	"CONTACTS.DETAIL.MODAL.LABELS.DIVIDER": "Added labels",
	"CONTACTS.DETAIL.MODAL.LABELS.ADD.FAILED": "Failed to add labels",
	"CONTACTS.DETAIL.MODAL.LABELS.REMOVE.FAILED": "Failed to remove labels",
	"CONTACTS.DETAIL.MODAL.LABELS.ADD": "Add label",

	"CONTACTS.DETAIL.ATTRIBUTES.MIDDLENAME": "Middle name",
	"CONTACTS.DETAIL.ATTRIBUTES.SALUTATION": "Salutation",
	"CONTACTS.DETAIL.ATTRIBUTES.SALUTATION.PLACEHOLDER": "Mr.",
	"CONTACTS.DETAIL.ATTRIBUTES.SEX": "Sex",
	"CONTACTS.DETAIL.ATTRIBUTES.SEX.PLACEHOLDER": "Male",
	"CONTACTS.DETAIL.ATTRIBUTES.NATIONALREGISTRATIONNUMBER": "National registration number",
	"CONTACTS.DETAIL.ATTRIBUTES.BIRTHDATE": "Birthdate",
	"CONTACTS.DETAIL.ATTRIBUTES.LANGUAGE": "Language",

	"LANGUAGE.ENGLISH": "English",
	"LANGUAGE.DUTCH": "Dutch",
	"LANGUAGE.FRENCH": "French",

	"BUTTON.SAVE": "Save",

	"CONTACTS.DETAIL.ATTRIBUTES.SAVE.SUCCESS": "Contact successfully saved",
	"CONTACTS.DETAIL.ATTRIBUTES.SAVE.FAILED": "Contact could not be saved",
	"CONTACTS.DETAIL.ATTRIBUTES.SAVE.FAILED.DUPLICATE.DESCRIPTION":
		"The content of this field needs to be unique in this organization",

	"CONTACTS.DETAIL.ADDRESSES.TITLE": "Addresses",
	"CONTACT.DETAIL.ATTRIBUTES.ADD.ADDRESS": "Add address",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.STREETNAME": "Streetname",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.STREETNAME.REQUIRED": "Please fill in a streetname",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.STREETNUMBER": "Streetnumber",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.STREETNUMBER.REQUIRED": "Please fill in a streetnumber",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.CITY": "City",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.CITY.REQUIRED": "Please fill in a city",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.BUS": "Bus",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PROVINCE": "Province",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PROVINCE.REQUIRED": "Please fill in a province",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.COUNTRY": "Country",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.COUNTRY.REQUIRED": "Please fill in a country",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.POSTALCODE": "Postal code",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.POSTALCODE.REQUIRED": "Please fill in a postal code",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PRIMARY": "Primary",

	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PRIMARY.CAUTION.TITLE": "Primary address",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PRIMARY.CAUTION.DESCRIPTION":
		"Are you sure you want to set this address as primary? This will remove the primary status from the current primary address.",

	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.SUCCESS": "Address successfully added",
	"CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.FAILED": "Address failed to add",

	"CONTACT.DETAIL.ATTRIBUTES.UPDATE.ADDRESS": "Update address",
	"CONTACTS.DETAIL.ATTRIBUTES.UPDATE.ADDRESS.SUCCESS": "Address successfully updated",
	"CONTACTS.DETAIL.ATTRIBUTES.UPDATE.ADDRESS.FAILED": "Address failed to update",
	"CONTACTS.DETAIL.ATTRIBUTES.DELETE.ADDRESS.SUCCESS": "Address successfully deleted",
	"CONTACTS.DETAIL.ATTRIBUTES.DELETE.ADDRESS.FAILED": "Address failed to delete",

	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.TITLE": "Archive",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.DESCRIPTION":
		"If you archive this contact, it will no longer be shown by default in searches or bulk actions. You can always retrieve archived contacts in your contacts overview and put them back.",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.BUTTON": "Archive contact",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.SUCCESS": "Contact successfully archived",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVE.FAILED": "Failed to archive contact",

	"CONTACTS.DETAIL.SETTINGS.ARCHIVED.TITLE": "Archived",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVED.DESCRIPTION":
		"This contact has been archived. You can put this contact back in your contacts overview by dearchiving it.",
	"CONTACTS.DETAIL.SETTINGS.ARCHIVED.BUTTON": "Dearchive contact",
	"CONTACTS.DETAIL.SETTINGS.DEARCHIVE.SUCCESS": "Contact successfully dearchived",
	"CONTACTS.DETAIL.SETTINGS.DEARCHIVE.FAILED": "Failed to dearchive contact",

	"CONTACTS.DETAIL.ATTRIBUTES.CONTACT_LINKING": "Contact linking",
	"CONTACTS.DETAIL.ATTRIBUTES.CONTACT_LINKING.ADD.BUTTON": "Link contact",

	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.TITLE": "Email addresses",

	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.LABEL.TITLE": "Label",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADDRESS.TITLE": "Address",

	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESS.LABEL.STANDARD": "Standard",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESS.LABEL.WORK": "Work",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESS.LABEL.BILLING": "Billing",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESS.LABEL.PRIVATE": "Private",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESS.LABEL.OTHER": "Other",

	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADD.ADDRESS": "Add email address",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADD.ADDRESS.PRIMARY.LABEL": "Primary",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADD.ADDRESS.PRIMARY.TOOLTIP":
		"Make another email address primary to undo this",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.UPDATE.SUCCESS.TITLE": "Email address saved",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.UPDATE.FAILED.TITLE": "Email address could not be saved",

	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.FORM.ERROR": "Please enter at least 1 email address",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADDRESS.INVALID_ERROR": "Please enter a valid email address",
	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADDRESS.PRIMARY.ERROR.TITLE":
		"{email} cannot be deleted because it was designated as the primary e-mail address of this contact",

	"CONTACTS.DETAIL.ATTRIBUTES.EMAIL_ADDRESSES.ADD.DELETE_BUTTON.TOOLTIP": "Primary email can not be removed.",

	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.TITLE": "Phone numbers",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.TITLE": "Label",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.NUMBER.LABEL": "Number",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.NUMBER.INVALID_ERROR": "Please enter a valid phone number",

	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.STANDARD": "Standard",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.MOBILE": "Mobile",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.HOME": "Home",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.PRIVATE": "Private",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.WHATSAPP": "WhatsApp",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.COMPANY": "Company",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.LABEL.OTHER": "Other",

	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.ADD": "Add phone number",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.UPDATE.SUCCESS.TITLE": "Phone number successfully updated",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.UPDATE.FAILED.TITLE": "Phone number failed to update",

	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.PRIMARY.LABEL": "Primary",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.PRIMARY.TOOLTIP": "Make another number primary",
	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.ERROR.TITLE": "Please enter a valid phone number",

	"CONTACTS.DETAIL.ATTRIBUTES.PHONE.DELETE_BUTTON.TOOLTIP": "Primary phonenumber cannot be deleted.",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.TITLE": "Link contact",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.VIEW_CONTACT": "View contact",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.SAVE": "Save",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.SUCCESS": "Contact successfully linked",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.FAILED": "Failed to link contact",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.LINK_TYPE": "Link type",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.RELATIONSHIP": "Relationship",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.RELATIONSHIP.ERROR_REQUIRED": "Relationship is required",

	"CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.CHILD": "Child",
	"CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.PARTNER": "Partner",
	"CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.DEPENDENT": "Dependent",
	"CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.OTHER": "Other",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.TYPE": "Type",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.BIRTHDAY": "Birthday",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.NAME": "Name",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.HASDAYCARECERTIFICATE": "Has daycare certificate",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.AMOUNTDEPENDENT": "Amount dependent",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.AMOUNTDEPENDENTSUBTYPE": "Amount dependent subtype",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.HASDISABILITY": "Has disability",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.ADDITIONAL_INCOME": "Additional income",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.ADDITIONAL_INCOME_AMOUNT.LABEL": "Income",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.HAS_REDUCED_SELF_RELIANCE.LABEL": "Has reduced self reliance",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DEPENDENT.DEPENDENT_SINCE.LABEL": "Dependent since",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.MARITAL_STATUS": "Marital status",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.MATRIMONIAL_PROPERTY_SYSTEM": "Matrimonial property system",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.WEDDING_DATE": "Wedding date",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.DIVORCE_DATE": "Divorce date",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.DIVORCE_DATE.ERROR":
		"Divorce date can not be earlier then wedding date",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.WIDOW_DATE": "Widow date",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.COHABITATION_DATE": "Cohabitation date",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.END_COHABITATION_DATE": "End cohabitation date",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.END_COHABITATION_DATE.ERROR":
		"End date can not be earlier then start cohabition date",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.RELATIVE.FLOW_PROXY.LABEL": "Flow proxy",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.RELATIVE.FLOW_PROXY.HELP":
		"{name} may fill out a flow on behalf of this linked contact.",

	"FLOWS.REPORT.TEMPLATE.ASIDE.REMINDERS.LABEL": "Automated reminders",

	MARRIED_WITHOUT_BEING_LEGALLY_COHABITING: "Married without being legally cohabiting",
	MARRIED: "Married",
	LEGALLY_COHABITING: "Legally cohabiting",
	WIDOWER_WIDOW: "Widower/widow",
	DIVORCED: "Divorced",
	ACTUALLY_DIVORCED: "Actually divorced",
	OTHER: "Other",

	STATUTORY_SYSTEM: "Statutory system",
	SEPARATION_OF_GOODS: "Separation of goods",
	OVERALL_COMMUNITY: "Overall community",
	OTHER: "Other",

	FISCALLYATMYEXPENSE: "Fiscally at my expense",
	FISCALLYDEPENDENTONOTHERPARENT: "Fiscally dependent on the other parent",

	FULLYCHARGED: "Fully at the expense",
	CHARGEDPARTLY: "Partly at the expense",
	"CO-PARENTSHIP": "Co-parentship",

	"CONTACT.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.TITLE": "Update linked contact",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.SUCCESS": "Contact link successfully updated",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.UPDATE.ERROR": "Failed to update contact link",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.SUCCESS": "Contact link successfully deleted",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.ERROR": "Failed to delete contact link",

	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.TITLE": "Delete address",
	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.DESCRIPTION": "Are you sure you want to delete this address?",
	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.DELETE.BUTTON": "Delete",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.TITLE": "Delete linked contact",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.DESCRIPTION":
		"Are you sure you want to delete this linked contact?",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.DELETE.BUTTON": "Delete",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.RELATIONSHIP.EMPTY": "No contacts found",
	"CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.RELATIONSHIP.EMPTY.BUTTON": "Create new contact",

	"CONTACTS.OVERVIEW.ADD_CONTACT": "Add contact",
	"CONTACTS.OVERVIEW.IMPORT_CONTACT": "Import contacts",

	"CONTACTS.DETAIL.ATTRIBUTES.TYPE.TITLE": "Contact type",
	"CONTACTS.DETAIL.ATTRIBUTES.TYPE.DESCRIPTION":
		"Select the type of contact. This will determine how the contact functions in the platform",

	"CONTACTS.DETAIL.ATTRIBUTES.BASE_VALUES.TITLE": "Personal data",

	"contact.type.customer.title": "Customer",
	"contacts.type.customer.description": "Contacts belonging to your customer base",
	"contact.type.prospect.title": "Prospect",
	"contacts.type.prospect.description": "Contacts who already came into contact with your office",
	"contact.type.other.title": "Other",
	"contacts.type.other.description": "Contacts of a different type on which you want to be able to filter",
	"contact.type.linked_contact.title": "Linked contact",
	"contacts.type.linked_contact.description":
		"Contact that you can link to other contacts but does not belong to your customer base",

	"CONTACTS.DETAIL.ATTRIBUTES.DETAIL.TITLE": "Detail",
	"CONTACTS.DETAIL.ATTRIBUTES.DETAIL.UNIQUE_IDENTIFIER.TITLE": "Unique identifier",
	"CONTACTS.DETAIL.ATTRIBUTES.DETAIL.UNIQUE_IDENTIFIER.DESCRIPTION":
		"Enter the unique identifier of the contact. This is a unique number that is used to identify the contact.",
	"CONTACTS.DETAIL.ATTRIBUTES.DETAIL.UNIQUE_IDENTIFIER.PLACEHOLDER": "REF-0001",
	"CONTACTS.DETAIL.ATTRIBUTES.DETAIL.DISABLED.TITLE": "Disabled",
	"CONTACTS.DETAIL.ATTRIBUTES.DETAIL.DISABLED.DESCRIPTION": "Is this contact more than 66% disabled?",

	// IMPORT HERE
	"contacts.detail.attributes.bank_accounts.title": "Bank accounts and crypto",
	"contacts.detail.attributes.bank_accounts.add_button": "Add bank account",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.CREATE_TITLE": "Add bank account",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.UPDATE_TITLE": "Bank account details",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FOOTER.DELETE": "Delete",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FOOTER.SAVE": "Save",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.LABEL": "Bank account type",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.ERROR_REQUIRED": "Please select a bank account type",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.CURRENT_ACCOUNT": "Current account",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.PRIVATE_ACCOUNT": "Private account",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.SAVINGS_ACCOUNT": "Savings account",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.CRYPTO_WALLET": "Crypto wallet",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.OTHER": "Other",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IBAN.LABEL": "IBAN",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IBAN.PLACEHOLDER": "BE68 5390 0754 7034",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IBAN.ERROR_REQUIRED": "Please enter an IBAN",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IBAN.ERROR_INVALID":
		"The IBAN you entered is invalid. Please check the IBAN and try again.",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IS_CLOSED.LABEL": "Is closed",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BIC.LABEL": "BIC",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BIC.PLACEHOLDER": "AAAABBCCDD",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BIC.ERROR_REQUIRED": "Please enter a BIC",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BANK_NAME.LABEL": "Bank name",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BANK_NAME.HELP":
		"Enter the name of the bank. This is the name of the bank that is registered with the IBAN.",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BANK_NAME.ERROR_REQUIRED": "Please enter a bank name",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.COUNTRY.LABEL": "Country",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.COUNTRY.HELP": "Select the country where the bank account is registered.",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_OPENED.LABEL": "Date opened",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_CLOSED.LABEL": "Date closed",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IS_DECLARED.LABEL": "Is declared",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.HAS_INCOME_OF_CHILDREN.LABEL": "Has income of children",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_SINCE_CHILD_INCOME.LABEL": "Date since child income",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_SINCE_CHILD_INCOME.HELP":
		"Enter the date since the income of the children is declared on this bank account.",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_SINCE_CHILD_INCOME.ERROR_INVALID":
		"This is not a valid year (1900 - {currentYear})",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_NAME": "Institution name",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_NAME.ERROR_REQUIRED":
		"Please enter an institution name",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_NAME.PLACEHOLDER": "e.g. Coinbase",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_COUNTRY": "Institution country",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_COUNTRY.ERROR_REQUIRED": "Please select a country",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_COUNTRY.HELP":
		"Select the country where the wallet institution is registered.",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.IS_DECLARED": "Declared to CAP/NBB",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.IS_DECLARED.ERROR_REQUIRED": "Please select an option",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.IS_CLOSED.LABEL": "Is closed",

	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.TITLE": "Delete bank account",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.DESCRIPTION":
		"Are you sure you want to delete this bank account? This action cannot be undone.",
	"CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.BUTTON": "Delete",
	"CONTACTS.DETAIL.BANK_ACCOUNTS.CARD.NO_DETAILS": "No details known",

	"CONTACTS.DETAIL.ATTRIBUTES.PROPERTIES.TITLE": "Foreign properties",
	"CONTACTS.DETAIL.PROPERTIES_CARD.NO_PRECISE_LOCATION": "No percise location available",
	"CONTACTS.DETAIL.ATTRIBUTES.PROPERTIES.ADD_BUTTON": "Add foreign property",
	"CONTACTS.DETAIL.PROPERTIES_CARD.CHIP.SOLD": "Sold",
	"CONTACTS.DETAIL.PROPERTIES_CARD.CHIP.IS_RENTED": "Is rented",
	"CONTACTS.DETAIL.PROPERTIES_CARD.CHIP.IS_CO_OWNERSHIP": "Is co-ownership",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.CREATE_TITLE": "Add foreign property",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.UPDATE_TITLE": "Foreign property details",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FOOTER.DELETE": "Delete",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FOOTER.SAVE": "Save",
	"contacts.detail.PROPERTIES_MODAL.form.type.label": "Property type",
	"contacts.detail.PROPERTIES_MODAL.form.type.error_required": "Please select a property type",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.TYPE.OPTIONS.FAMILY_HOME": "Family home",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.TYPE.OPTIONS.SECOND_HOME": "Second home",
	"contacts.detail.PROPERTIES_MODAL.form.building_type.label": "Building type",
	"contacts.detail.PROPERTIES_MODAL.form.building_type.error_required": "Please select a building type",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.HOME": "Home",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.FAMILY_HOME": "Family home",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.SECOND_HOME": "Second home",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.APARTMENT": "Apartment",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.INDUSTRIAL_BUILDING": "Industrial building",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.AGRICULTURE": "Agriculture",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.TRADING_HOUSE": "Trading house",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.BUILDING_TYPE.OPTIONS.TERRAIN": "Terrain",
	"contacts.detail.PROPERTIES_MODAL.form.ownership_type.label": "Ownership type",
	"contacts.detail.PROPERTIES_MODAL.form.ownership_type.error_required": "Please select an ownership type",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_TYPE.OPTIONS.FULL_OWNERSHIP": "Full ownership",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_TYPE.OPTIONS.BARE_PROPERTY": "Bare property",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_TYPE.OPTIONS.USUFRUCT": "Usufruct",
	"contacts.detail.PROPERTIES_MODAL.form.is_co_ownership.label": "Is co-ownership",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_PERCENTAGE.LABEL": "Percentage ownership",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_PERCENTAGE.HELP":
		"For what percentage does <bold>{name}</bold> own this property",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_PERCENTAGE.ERROR_MIN": "Percentage cannot be less then 0",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.OWNERSHIP_PERCENTAGE.ERROR_MAX": "Percentage cannot be greater then 100",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NAME.LABEL": "Street name",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NAME.PLACEHOLDER": "Coupure Rechts",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NAME.ERROR_REQUIRED": "Please enter a street name",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NUMBER.LABEL": "Street number",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NUMBER.PLACEHOLDER": "620",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NUMBER.ERROR_REQUIRED": "Please enter a street number",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.BUS.LABEL": "Bus",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.CITY.LABEL": "City",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.CITY.PLACEHOLDER": "Gent",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.CITY.ERROR_REQUIRED": "Please enter a city",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.POSTAL_CODE.LABEL": "Gent",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.POSTAL_CODE.PLACEHOLDER": "9000",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.POSTAL_CODE.ERROR_REQUIRED": "Please enter a postal code",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.PROVINCE.LABEL": "Province",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.PROVINCE.PLACEHOLDER": "Oost-Vlaanderen",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.COUNTRY.LABEL": "Country",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.COUNTRY.PLACEHOLDER": "België",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.COUNTRY.ERROR_REQUIRED": "Please enter a country",
	"contacts.detail.PROPERTIES_MODAL.form.property_value.label": "Property value",
	"contacts.detail.PROPERTIES_MODAL.form.property_value.help": "Enter the value of this property.",
	"contacts.detail.PROPERTIES_MODAL.form.original_value.label": "Original value",
	"contacts.detail.PROPERTIES_MODAL.form.original_value.help": "Enter the original value of this property.",
	"contacts.detail.PROPERTIES_MODAL.form.renovation_costs.label": "Renovation costs",
	"contacts.detail.PROPERTIES_MODAL.form.is_rented_out.label": "Is rented out",

	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.RENT_TYPE.OPTIONS.PRIVATE": "Private",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.RENT_TYPE.OPTIONS.PROFESSIONAL": "Professional",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.RENT_TYPE.OPTIONS.MIXED": "Mixed",

	"contacts.detail.PROPERTIES_MODAL.form.is_sold.label": "Is sold",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.label": "Sold date",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.help": "Enter the sold date of this property.",
	"contacts.detail.PROPERTIES_MODAL.form.sold_date.error": "Sold date can not be before purchase date",

	"CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.TITLE": "Delete foreign property",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.DESCRIPTION":
		"Are you sure you want to delete this foreign property?",
	"CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.BUTTON": "Delete foreign property",

	"CONTACTS.DETAIL.ATTRIBUTES.INSURANCES.TITLE": "Insurances",
	"CONTACTS.DETAIL.ATTRIBUTES.INSURANCES.ADD_BUTTON": "Add insurance",
	"CONTACTS.DETAIL.INSURANCES_MODAL.CREATE_TITLE": "Add insurance",
	"CONTACTS.DETAIL.INSURANCES_MODAL.UPDATE_TITLE": "Insurance",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.TYPE.LABEL": "Insurance type",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.TYPE.ERROR_REQUIRED": "Please enter an insurance type",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.TYPE.OPTION.LIFE_INSURANCE": "Life insurance",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.INSTITUTION_NAME.LABEL": "Institution name",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.INSTITUTION_NAME.ERROR_REQUIRED": "Please enter an institution name",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.ADDRESS.COUNTRY.LABEL": "Country",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.ADDRESS.COUNTRY.PLACEHOLDER": "Belgium",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FORM.ADDRESS.COUNTRY.ERROR_REQUIRED": "Please enter a country",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FOOTER.SAVE": "Save",
	"CONTACTS.DETAIL.INSURANCES_MODAL.FOOTER.DELETE": "Delete",
	"CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.TITLE": "Delete insurance",
	"CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.BUTTON": "Delete insurance",
	"CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.DESCRIPTION": "Are you sure you want to delete this insurance?",

	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.TITLE": "Charging station",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.HAS_CHARGING_STATION.LABEL": "Has charging station",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.GREEN_POWER.LABEL": "Green power",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INSPECTED.LABEL": "Inspected",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INTELLIGENT.LABEL": "Intelligent",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.LOCATED_AT_DOMICILE.LABEL": "Connected to network",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INSTALLED.LABEL": "Installed by installer",
	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INSTALLATION_YEAR.LABEL": "Installation year",

	"CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INSTALLATION_YEAR.LABEL.ERROR_REQUIRED":
		"Year of installation is required",

	"CONTACTS.IMPORT.STEPS.IMPORT_OPTIONS": "Import options",
	"CONTACTS.IMPORT.STEPS.OVERVIEW": "Overview",
	"CONTACTS.IMPORT.STEPS.MAP": "Map fields",
	"CONTACTS.IMPORT.STEPS.VALIDATE": "Validate",
	"CONTACTS.IMPORT.STEPS.SETTINGS": "Settings",
	"CONTACTS.IMPORT.STEPS.IMPORT": "Import",

	"CONTACTS.IMPORT.OVERVIEW.TITLE": "Import contacts",
	"CONTACTS.IMPORT.OVERVIEW.DESCRIPTION": "Overview of the import",

	"CONTACTS.IMPORT.FETCH.FAILED": "Failed to fetch contacts",

	"CONTACTS.IMPORT.ADMIN_PULSE.OWN_COMPANY": "Own company",

	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.CRMTYPE": "Select CRM type",
	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.CRMTYPE.PLACEHOLDER": "Select CRM type",

	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.TYPE.COMPANY": "Company",
	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.TYPE.PERSON": "Person",
	"CONTACTS.IMPORT.ADMIN_PULSE.SELECT.TYPE.SOLE_PROPRIETOR": "Sole proprietor",

	"CONTACTS.IMPORT.VALIDATE.NEXT.BUTTON": "Next",

	"CONTACTS.IMPORT.VALIDATE.DESCRIPTION":
		"{value, plural, =0 {All contacts can be imported} one {# 1 contact is incomplete and cannot be imported} other {# contacts are incomplete and can not be imported}}. You can edit contacts data here.",

	"CONTACTS.IMPORT.VALIDATE.ONLY_NATIONALNUMBER": "Only national number",
	"CONTACTS.IMPORT.VALIDATE.ONLY_PHONENUMBER": "Only phone number",
	"CONTACTS.IMPORT.VALIDATE.ONLY_EMAIL": "Only email",
	"CONTACTS.IMPORT.VALIDATE.NO_DATA": "No data",

	"CONTACTS.OVERVIEW.ADD.MODAL.PHONE": "Phone",
	"CONTACTS.OVERVIEW.ADD.MODAL.PHONE.REQUIRED": "Please enter a phone number",

	"CONTACTS.OVERVIEW.VALIDATE.LIST.TITLE":
		"{value, plural, =0 {No incomplete contacts} one {# incomplete contact} other {# incomplete contacts}}",

	"CONTACTS.IMPORT.RETURN": "Previous",

	"CONTACTS.IMPORT.VALIDATE.SAVE": "Save contact",
	"CONTACTS.IMPORT.VALIDATE.SKIP":
		"Go to next step and {value, plural, =0 {} one {skip # contact} other {skip # contacts}}",
	"CONTACTS.IMPORT.VALIDATE.SKIP.TOOLTIP.ALL_INCOMPLETE": "All selected contacts are incomplete",

	"CONTACTS.IMPORT.SETTINGS.TITLE": "Import settings",
	"CONTACTS.IMPORT.SETTINGS.DESCRIPTION": "Select the settings for the import",
	"CONTACTS.IMPORT.SETTINGS.RULES.DESCRIPTION": "Via rules you can add labels to a contact conditionally",

	"CONTACTS.IMPORT.SETTINGS.LABEL.TITLE": "Add label to contacts",
	"CONTACTS.IMPORT.SETTINGS.LABEL.HELP":
		"Hint: with labels you can find and edit all contacts from a specific import",

	"CONTACTS.IMPORT.SETTINGS.IMPORT.BUTTON": "{value, plural, one {import # contact} other {import # contacts}}",

	"CONTACTS.IMPORT.SETTINGS.LABEL.EMPTY.TITLE": "No labels found",
	"CONTACTS.IMPORT.SETTINGS.LABEL.ADD.TITLE": "Add new label",

	"CONTACTS.IMPORT.IMPORT.ERROR.TITLE": "Import failed",
	"CONTACTS.IMPORT.IMPORT.ERROR.DESCRIPTION":
		"The import failed. Please try again later. If the problem persists, please contact support.",
	"CONTACTS.IMPORT.IMPORT.ERROR.BUTTON": "Exit import",

	"CONTACTS.IMPORT.IMPORT.PROGRESS": "{amount} of {total} contacts imported",
	"CONTACTS.IMPORT.IMPORT.PROGRESS.PERCENT": "{percent}% imported",

	"CONTACTS.IMPORT.INTEGRATION_LIST.ERROR": "Failed to fetch integrations",

	"CONTACTS.CSV.OVERVIEW.TITLE": "Import csv",
	"CONTACTS.CSV.OVERVIEW.DESCRIPTION": "Import contacts from a csv file",
	"CONTACTS.CSV.OVERVIEW.CHOOSE_FILE": "Choose file",
	"CONTACTS.CSV.OVERVIEW.CHOOSE_FILE.DESCRIPTION": "Select a csv file to import",
	"CONTACTS.CSV.OVERVIEW.DELIMITER": "Delimiter",
	"CONTACTS.CSV.OVERVIEW.DELIMITER.BUTTON": "Apply delimiter",
	"CONTACTS.CSV.OVERVIEW.DELIMITER.HELP": "Change this value if the columns are not correctly shown",

	"CONTACTS.CSV.MAP.TITLE": "Map fields",
	"CONTACTS.CSV.MAP.DESCRIPTION": "Map the fields of the csv file to the fields of the contact",

	"CONTACTS.CSV.MAP.SELECT": "Import",
	"CONTACTS.CSV.MAP.FROM": "From",
	"CONTACTS.CSV.MAP.TO": "To",

	"CONTACTS.CSV.MAP.WARNING.TITLE": "Required fields",
	"CONTACTS.CSV.MAP.WARNING.DESCRIPTION": "Please map the following fields to continue:",

	"CONTACTS.SETTINGS.ADD.RULE.MODAL.TITLE": "Add rule",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.DESCRIPTION":
		"If a contact matches against your rule, the choicen label will be added to this contact.",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.LABEL": "Label",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.BUTTON": "Add rule",
	"CONTACTS.SETTINGS.DELETE.RULE.MODAL.BUTTON": "Delete rule",

	"CONTACTS.SETTINGS.ADD.RULE.MODAL.IS_DEFINED": "Is defined",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.EQUAL_TO": "Is equal to",

	"CONTACTS.SETTINGS.ADD.RULE.MODAL.IF": "If",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.IS": "Is",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.AND": "And",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.OR": "Or",
	"CONTACTS.SETTINGS.RULE.MODAL.CONDITION.ERROR.EMPTY": "You haven't set any labels",
	"CONTACTS.SETTINGS.RULE.MODAL.CONDITION.ERROR.EMPTY_FIELDS": "You have empty fields in your rules",

	"CONTACTS.SETTINGS.ADD.RULE.MODAL.ADD_AND": "Add 'AND' rule",
	"CONTACTS.SETTINGS.ADD.RULE.MODAL.ADD_OR": "Add 'Or' rule",

	"CONTACTS.SETTINGS.RULE.TABLE.CONDITION": "Condition",
	"CONTACTS.SETTINGS.RULE.TABLE.LABEL": "Label",
	"CONTACTS.SETTINGS.RULE.ADD.BUTTON": "Add rule",

	"CONTACTS.SETTINGS.RULE.TABLE.CONTENT":
		"Type: {type, select, if {If} and {And} or {Or}} Field: {field} Value: {value, select, IS_DEFINED {is defined} EQUAL_TO {is equal to}} {value, select, EQUAL_TO {Input: {input}} other {''}}",

	"CONTACTS.SETTINGS.RULE.MODAL.LABEL.TITLE": "Label",
	"CONTACTS.SETTINGS.RULE.MODAL.LABEL.HELP":
		"Hint: with labels you can find and edit all contacts from a specific import",
	"CONTACTS.SETTINGS.RULE.MODAL.LABEL.REQUIRED": "Please enter a label",

	"CONTACTS.SETTINGS.RULE.ADD.LABEL.SUCCESS": "Label added",
	"CONTACTS.SETTINGS.RULE.ADD.LABEL.FAILED":
		"Failed to add label. Please try again later. If the problem persists, please contact support.",
	"CONTACTS.SETTINGS.RULE.ADD.LABEL.FAILED.CONFLICT": "Failed to add label. A label with this name already exists.",
	"CONTACTS.SETTINGS.RULE.LABEL.ADD.BUTTON": "Add new label",

	"CONTACTS.OVERVIEW.TABLE.ACCOUNT_MANAGERS": "Account managers",
	"CONTACTS.OVERVIEW.TABLE.LABELS": "Labels",
	"CONTACTS.OVERVIEW.TABLE.TYPE": "Type",
	"CONTACTS.OVERVIEW.TABLE.CREATED_AT": "Created at",
	"CONTACTS.OVERVIEW.TABLE.UPDATED_AT": "Updated at",
	"CONTACTS.OVERVIEW.TABLE.ARCHIVED_AT": "Archived at",
	"CONTACTS.OVERVIEW.TABLE.DELETED_AT": "Deleted at",
	"CONTACTS.OVERVIEW.TABLE.HARD_DELETE_MESSAGE": "Deleted at",
	"CONTACTS.OVERVIEW.TABLE.HARD_DELETE.MESSAGE": "{days, plural, =0 {Today} one {{days} day} other {{days} days}}",
	"CONTACTS.OVERVIEW.TABLE.HARD_DELETE.MESSAGE.TOOLTIP":
		"{days, plural, =0 {Today this contact will be deleted forever} one {In {days} day this contact will be deleted forever} other {In {days} days this contact will be deleted forever}}",

	"CONTACTS.DETAIL.ATTRIBUTES.ADDRESSES.PRIMARY.TOOLTIP": "Can't be changed as this is the primary address",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.DISABLED":
		"Linking is disabled for this contact because this contact is archived.",

	"CONTACTS.DETAIL.ATTRIBUTES.LINKING.SELECT.ADD": "Add contact",

	"CONTACTS.SETTINGS.LABELS.HEX.ERROR": "Please enter a valid hex color code",

	"CONTACTS.SETTINGS.LABELS.NAME.REQUIRED": "Please enter a name for the label",
	"CONTACTS.SETTINGS.NAME.DUPLICATE": "A label with this name already exists, please choose another name",

	"CONTACTS.OVERVIEW.INTRO.TITLE": "Add your first contacts",
	"CONTACTS.OVERVIEW.INTRO.DESCRIPTION":
		"You can add contacts manually, import them from a csv file or via an integration.",
	"CONTACTS.OVERVIEW.INTRO.FOOTER_BUTTON": "Create contact manually",

	"CONTACTS.IMPORT.DONE.CLOSE": "Close",

	// GENERAL

	"MODEL.CONTACT.GENERAL_INFORMATION": "General information",
	"MODEL.CONTACT.FIRST_NAME": "First name",
	"MODEL.CONTACT.LAST_NAME": "Last name",
	"MODEL.CONTACT.MIDDLE_NAME": "Middle name",
	"MODEL.CONTACT.FULLNAME": "Full name",
	"MODEL.CONTACT.SALUTATION": "Salutation",
	"MODEL.CONTACT.EMAIL": "Email",
	"MODEL.CONTACT.PHONE": "Phone",
	"MODEL.CONTACT.NATIONAL_REGISTRATION_NUMBER": "National registration number",
	"MODEL.CONTACT.BIRTHDAY": "Birthday",
	"MODEL.CONTACT.LANGUAGE": "Language",
	"MODEL.CONTACT.SEX": "Sex",
	"MODEL.CONTACT.UNIQUE_IDENTIFIER": "Unique identifier",
	"MODEL.CONTACT.IBAN_REIMBURSEMENT": "IBAN reimbursement",
	"MODEL.CONTACT.HAS_DISABILITY": "Has disability",

	// ADDRESS

	"MODEL.CONTACT.ADDRESS": "Address",
	"MODEL.CONTACT.ADDRESS.STREET_NAME": "Street name",
	"MODEL.CONTACT.ADDRESS.STREET_NUMBER": "Street number",
	"MODEL.CONTACT.ADDRESS.CITY": "City",
	"MODEL.CONTACT.ADDRESS.BUS": "Bus",
	"MODEL.CONTACT.ADDRESS.PROVINCE": "Province",
	"MODEL.CONTACT.ADDRESS.POSTAL_CODE": "Postal code",

	// EID
	"MODEL.CONTACT.EID": "EID",
	"MODEL.CONTACT.EID.CARD_NUMBER": "Card number",
	"MODEL.CONTACT.EID.EXPIRATION_DATE": "Expiration date",

	// CHARGING STATION
	"MODEL.CONTACT.CHARGING_STATION": "Charging station",
	"MODEL.CONTACT.CHARGING_STATION.IS_CONNECTED_TO_NETWORK": "Is connected to network",
	"MODEL.CONTACT.CHARGING_STATION.IS_INTELLIGENT": "Is intelligent",
	"MODEL.CONTACT.CHARGING_STATION.HAS_CHARGING_STATION": "Has charging station",
	"MODEL.CONTACT.CHARGING_STATION.IS_USING_GREEN_POWER": "Is using green power",
	"MODEL.CONTACT.CHARGING_STATION.IS_EXAMINED": "Is examined",

	// CRYPTO
	"MODEL.CONTACT.CRYPTO": "Crypto",
	"MODEL.CONTACT.CRYPTO.ADDRESS": "Address",
	"MODEL.CONTACT.CRYPTO.HAS_CRYPTO": "Has crypto",

	// BANK
	"MODEL.CONTACT.BANK": "Bank",
	"MODEL.CONTACT.BANK_ACCOUNTS.BANK_NAME": "Bank name",
	"MODEL.CONTACT.BANK_ACCOUNTS.TYPE": "Type account",
	"MODEL.CONTACT.BANK_ACCOUNTS.IBAN": "IBAN",
	"MODEL.CONTACT.BANK_ACCOUNTS.INSTITUTION_NAME": "Institution name",
	"MODEL.CONTACT.BANK_ACCOUNTS.INSTITUTION_ADDRESS_COUNTRY": "Institution address country",
	"MODEL.CONTACT.BANK_ACCOUNTS.BIC_CODE": "BIC code",

	// FOREIGN PROPERTIES
	"MODEL.CONTACT.FOREIGN_PROPERTIES": "Foreign properties",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.TYPE": "Type",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.BUILDING_TYPE": "Building type",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.STREET_NUMBER": "Street number",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.STREET_NAME": "Street name",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.CITY": "City",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.BUS": "Bus",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.PROVINCE": "Province",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.COUNTRY": "Country",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ADDRESS.POSTAL_CODE": "Postal code",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.OWNERSHIP_TYPE": "Ownership type",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.IS_CO_OWNERSHIP": "Is co-ownership",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.CO_OWNER_NAMES": "Co-owner names",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.PROPERTY_VALUE": "Property value",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.ORIGINAL_VALUE": "Original value",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.RENOVATION_COSTS": "Renovation costs",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.IS_RENTED_OUT": "Is rented out",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.POSSIBLE_RENT_AMOUNT": "Possible rent amount",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.RENT_AMOUNT": "Rent amount",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.PURCHASE_DATE": "Purchase date",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.IS_SOLD": "Is sold",
	"MODEL.CONTACT.FOREIGN_PROPERTIES.SOLD_DATE": "Sold date",

	// CONTACT LINKING
	"MODEL.CONTACT.CONTACT_LINKING": "Contact linking",
	"MODEL.CONTACT.CONTACT_LINKING.GENERAL": "General",
	"MODEL.CONTACT.CONTACT_LINKING.FIRST_NAME": "First name",
	"MODEL.CONTACT.CONTACT_LINKING.LAST_NAME": "Last name",
	"MODEL.CONTACT.CONTACT_LINKING.TYPE": "Type",

	// CONTACT LINKING CHILD
	"MODEL.CONTACT.CONTACT_LINKING_CHILD": "Contact linking child",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.AMOUNT_DEPENDENT": "Amount dependent",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.AMOUNT_DEPENDENT_SUBTYPE": "Amount dependent subtype",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.HAS_DISABILITY": "Has disability",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.HAS_DAYCARE_CERTIFICATE": "Has daycare certificate",

	// CONTACT LINKING PARTNER
	"MODEL.CONTACT.CONTACT_LINKING_PARTNER": "Contact linking partner",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.INCOME": "Income",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.MARITAL_STATUS": "Marital status",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.MATRIMONIAL_PROPERTY_SYSTEM": "Matrimonial property system",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.WEDDING_DATE": "Wedding date",
	"MODEL.CONTACT.CONTACT_LINKING.DATA.DIVORCE_DATE": "Divorce date",

	"CONTACTS.FORM.NATIONALREGISTRATIONNUMBER.HELP":
		"Entering a national registry number helps match contacts and prevents duplicates",

	"SETTINGS.CONTACTS.LABELS.EMPTY": "No labels found",

	"CONTACTS.IMPORT.VALIDATE.NO_INCOMPLETE_CONTACTS": "No incomplete contacts found",
	"CONTACTS.IMPORT.VALIDATE.NO_INCOMPLETE.CONTACTS.DESCRIPTION": "All contacts are complete and can be imported",

	"CONTACTS.IMPORT.CSV.OVERVIEW.FILE_FAULTY.TITLE": "File changed",
	"CONTACTS.IMPORT.CSV.OVERVIEW.FILE_FAULTY.DESCRIPTION":
		"Some of the fields of this CSV have been changed because they contained invalid data.",

	"CONTACTS.IMPORT.VALIDATE.BASIC_INFO.COLLAPSE.TITLE":
		"{value, plural, =0 {Basic data} one {Basic data, {value} field missing} other {Basic data, {value} fields missing}}",
	"CONTACTS.IMPORT.VALIDATE.ADDRESSES.COLLAPSE.TITLE":
		"{value, plural, =0 {Addresses} one {Addresses, {value} field missing} other {Addresses, {value} fields missing}}",
	"CONTACTS.IMPORT.VALIDATE.BANK_ACCOUNTS.COLLAPSE.TITLE":
		"{value, plural, =0 {Bank account} one {Bank account, {value} field missing} other {Bank account, {value} fields missing}}",

	"CONTACTS.IMPORT.SETTINGS.TYPE": "Type",
	"CONTACTS.IMPORT.SETTINGS.ID": "ID",
	"CONTACTS.IMPORT.SETTINGS.LASTNAME": "Last name",
	"CONTACTS.IMPORT.SETTINGS.FIRSTNAME": "First name",
	"CONTACTS.IMPORT.SETTINGS.UNIQUEIDENTIFIER": "Unique identifier",
	"CONTACTS.IMPORT.SETTINGS.EMAIL": "Email",
	"CONTACTS.IMPORT.SETTINGS.PHONE": "Phone",
	"CONTACTS.IMPORT.SETTINGS.NATIONALREGISTRATIONNUMBER": "National registry number",
	"CONTACTS.IMPORT.SETTINGS.EID.EXPIRATION_DATE": "Eid expirationdate",
	"CONTACTS.IMPORT.SETTINGS.EID.CARD_NUMBER": "Eid card number",
	"CONTACTS.IMPORT.SETTINGS.BIRTHDAY": "Birthday",

	"REDIRECT.AUTH_SUCCEEDED.TITLE": "Proccessing your connection",
	"REDIRECT.AUTH_SUCCEEDED.DESCRIPTION":
		"We are processing your connection if it takes longer than a few seconds, refresh this page.",
	"REDIRECT.AUTH_SUCCEEDED.BUTTON": "Refresh page",

	"settings.member.delete.not_authorized": "You are not authorized to delete this member",
	"settings.member.delete.owner_deleted": "Owners cannot remove themself from a organization",
	"settings.member.delete.something_went_wrong": "You can temporarily not remove this member",
	"settings.member.invite.duplicate_member": "Member is already invite in your organization",
	"settings.member.invite.not_authorized": "You are not authorized to invite new members",
	"settings.member.invite.plan_limit_reached":
		"You have reached the amount of members you can add in your plan, upgrade your plan to invite new members",
	"settings.member.invite.something_went_wrong": "You can temporarily not added any new members",
	"settings.member.resend_invite.not_authorized": "You are not authorized to invite members",
	"settings.member.resend_invite.something_went_wrong": "You can temporarily not resend the members invite",
	"settings.member.update.not_authorized": "You are not authorized to update this member",
	"settings.member.update.something_went_wrong": "You can temporarily not update this member",

	"FLOWS.REPORT.OVERVIEW.BULK_ACTION.BUTTON.SEND_TO_EMAIL": "Send email",
	"FLOWS.SEND.COMPOSE.BUTTONS.NEXT": "Next",
	"FLOWS.SEND.COMPOSE.CHANNEL": "Channel",
	"FLOWS.SEND.COMPOSE.EMAIL": "Email",
	"FLOWS.SEND.COMPOSE.PREVIOUS": "Previous",
	"FLOWS.SEND.COMPOSE.SUBJECT": "Subject",
	"FLOWS.SEND.COMPOSE.SUBJECT.REQUIRED": "Please fill in a subject",
	"FLOWS.SEND.COMPOSE.TITLE": "Compose message",
	"FLOWS.SEND.COMPOSE.DESCRIPTION": "Compose a message to send to your contacts",
	"FLOWS.SEND.CONTACT_SELECTION.BUTTONS.NEXT": "Next",
	"FLOWS.SEND.CONTACT_SELECTION.BUTTONS.SEND": "{value, plural, one {Send # email} other {Send # emails}}",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.CAN_BE_SENT": "Can be sent",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.EMAIL": "Email",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.HEADER.NAME": "Name",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.CANNOT_BE_SENT": "Email missing or invalid",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.CAN_BE_SENT": "Can be sent",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.EMAIL": "Email",
	"FLOWS.SEND.CONTACT_SELECTION.TABLE.NAME": "Name",
	"FLOWS.SEND.CONTACT_SELECTION.TITLE": "Contact selection",
	"FLOWS.SEND.CONTACT_SELECTION.DESCRIPTION": "Select contacts by filters",
	"FLOWS.SEND.CONTACT_SELECTION.SEND_TO_COMPLETED_REPORTS": "Also send to contacts that already completed this flow",
	"FLOWS.SEND.CONTACT_SELECTION.ALERT":
		"{count, plural, =1 {# contact} other {# contacts}} will not receive your message as they do not have an email address.",
	"FLOWS.SEND.OVERVIEW.CHANNEL": "Channel:",
	"FLOWS.SEND.OVERVIEW.EMAIL": "Email:",
	"FLOWS.SEND.OVERVIEW.FLOW": "Flow:",
	"FLOWS.SEND.OVERVIEW.SUBJECT": "Subject:",
	"FLOWS.SEND.OVERVIEW.TITLE": "Overview",
	"FLOWS.SEND.OVERVIEW.DESCRIPTION": "Validate your contacts and message before you send it.",
	"FLOWS.SEND.RESULT.CLOSE": "Close",
	"FLOWS.SEND.RESULT.DESCRIPTION":
		"We're sending your flow to all selected contacts. This can take up to 30 minutes. You can view the status per report in reports overview under 'Progression'. You will also receive a notification when all emails are sent.",
	"FLOWS.SEND.RESULT.REPORTS": "Go to reports",
	"FLOWS.SEND.RESULT.TITLE": "Your flow is being sent to your selected contacts",
	"FLOWS.SEND.STEP.COMPOSE_MESSAGE": "Compose message",
	"FLOWS.SEND.STEP.CONTACT_SELECT": "Select contacts",
	"FLOWS.SEND.STEP.OVERVIEW": "Overview",
	"FLOWS.SEND.OVERVIEW.SEND_FLOW_FAILED.TITLE": "Flow could not be sent.",
	"FLOWS.SEND.OVERVIEW.SEND_FLOW_FAILED.DESCRIPTION":
		"Something went wrong while sending the flow, please try again or contact our customer support",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.EMPTY": "No channels added to your user",
	"FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.EMPTY.ADD": "Add channel",
	"INBOX.COMING_SOON.DESCRIPTION":
		"Finish emails more quickly by collaborating efficiently in the shared team inbox.",
	"INBOX.COMING_SOON.FEATURES.ASSIGN": "Assign emails to team members",
	"INBOX.COMING_SOON.FEATURES.INBOX_ZERO": "Create an internal chat conversation for every email",
	"INBOX.COMING_SOON.FEATURES.TEAM_INBOX": "Achieve inbox zero by collaborating to the max",
	"INBOX.COMING_SOON.TITLE": "Soon",
	"POPUP.BUTTON.DELETE": "Delete",
	"settings.member.delete.not_authorized": "You are not authorized to delete this member",
	"settings.member.delete.owner_deleted": "Owners cannot remove themself from a organization",
	"settings.member.delete.something_went_wrong": "You can temporarily not remove this member",
	"settings.member.invite.duplicate_member": "Member is already invite in your organization",
	"settings.member.invite.not_authorized": "You are not authorized to invite new members",
	"settings.member.invite.plan_limit_reached":
		"You have reached the amount of members you can add in your plan, upgrade your plan to invite new members",
	"settings.member.invite.something_went_wrong": "You can temporarily not added any new members",
	"settings.member.resend_invite.not_authorized": "You are not authorized to invite members",
	"settings.member.resend_invite.something_went_wrong": "You can temporarily not resend the members invite",
	"settings.member.update.not_authorized": "You are not authorized to update this member",
	"settings.member.update.something_went_wrong": "You can temporarily not update this member",

	"settings.notifications.contacts.card.title": "Contacts",
	"settings.notifications.contacts.card.description": "Get notifications for contacts",
	"settings.notifications.contacts.form.synchronization.label": "Contact import",

	"editor.toolbar.formatting.bold.tooltip": "Format bold",
	"editor.toolbar.formatting.bold.aria_label": "Format bold",
	"editor.toolbar.formatting.bold.tooltip.active": "Remove bold format",
	"editor.toolbar.formatting.bold.aria_label.active": "Remove bold format",

	"editor.toolbar.formatting.italic.tooltip": "Format italic",
	"editor.toolbar.formatting.italic.aria_label": "Format italic",
	"editor.toolbar.formatting.italic.tooltip.active": "Remove italic format",
	"editor.toolbar.formatting.italic.aria_label.active": "Remove italic format",

	"editor.toolbar.formatting.underline.tooltip": "Format underline",
	"editor.toolbar.formatting.underline.aria_label": "Format underline",
	"editor.toolbar.formatting.underline.tooltip.active": "Remove underline format",
	"editor.toolbar.formatting.underline.aria_label.active": "Remove underline format",

	"editor.toolbar.formatting.strikethrough.tooltip": "Format strikethrough",
	"editor.toolbar.formatting.strikethrough.aria_label": "Format strikethrough",
	"editor.toolbar.formatting.strikethrough.tooltip.active": "Remove strike through format",
	"editor.toolbar.formatting.strikethrough.aria_label.active": "Remove strike through format",

	"editor.toolbar.formatting.insert_link.tooltip": "Format text as link",
	"editor.toolbar.formatting.insert_link.aria_label": "Format text as link",

	"editor.toolbar.paragraph_styles.bullet_list.tooltip": "Bullet List",
	"editor.toolbar.paragraph_styles.bullet_list.aria_label": "format paragraph into bullet list",
	"editor.toolbar.paragraph_styles.bullet_list.tooltip.active": "Remove bullet list",
	"editor.toolbar.paragraph_styles.bullet_list.aria_label.active": "format bullet list to paragraph",

	"editor.toolbar.paragraph_styles.numbered_list.tooltip": "Number List",
	"editor.toolbar.paragraph_styles.numbered_list.aria_label": "format paragraph into number list",
	"editor.toolbar.paragraph_styles.numbered_list.tooltip.active": "Remove number List",
	"editor.toolbar.paragraph_styles.numbered_list.aria_label.active": "format number list to paragraph",

	"editor.toolbar.text_align.left_align.tooltip": "Align text left",
	"editor.toolbar.text_align.left_align.aria_label": "Align text left",
	"editor.toolbar.text_align.center_align.tooltip": "Align text center",
	"editor.toolbar.text_align.center_align.aria_label": "Align text center",
	"editor.toolbar.text_align.right_align.tooltip": "Align text right",
	"editor.toolbar.text_align.right_align.aria_label": "Align text right",

	"editor.toolbar.code.tooltip": "Highlight text",
	"editor.toolbar.code.aria_label": "Highlight text",
	"editor.toolbar.code.tooltip.active": "Remove highlight format",
	"editor.toolbar.code.aria_label.active": "Remove highlight format",
};
