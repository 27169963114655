import { errorConfig, timingConfig } from "../../../config";
import actionTypes from "./banner.actionTypes";

export const showKeyBanner = ({ type, keyCode, message }) => (dispatch) => {
	let keyIcons = {
		37: "key-arrow--left",
		38: "key-arrow--up",
		39: "key-arrow--right",
		40: "key-arrow--down",
	};

	dispatch({
		type: actionTypes.SHOW_KEY_BANNER,
		data: {
			type,
			message,
			icon: keyCode && keyIcons[keyCode],
		},
	});

	setTimeout(() => dispatch(hideKeyBanner()), timingConfig.animationDefault);
};

export const hideKeyBanner = () => (dispatch) => {
	dispatch({ type: actionTypes.HIDE_KEY_BANNER });
};

export const hideBanner = () => (dispatch) => {
	dispatch({ type: actionTypes.HIDE_BANNER });
};

export const showFailedMessage = (data) => (dispatch) => {
	dispatch({
		type: actionTypes.SHOW_FAILED_MESSAGE,
		data,
	});
};
export const showSuccessMessage = (data) => (dispatch) => {
	dispatch({
		type: actionTypes.SHOW_SUCCESS_MESSAGE,
		data,
	});
};
export const showInfoMessage = (data) => (dispatch) => {
	dispatch({
		type: actionTypes.SHOW_INFO_MESSAGE,
		data,
	});
};

export const showProcessingBanner = ({ id, message, values }) => (dispatch) => {
	dispatch({
		type: actionTypes.SHOW_PROCESSING_BANNER,
		data: {
			id,
			message,
			values,
		},
	});
};
export const hideProcessingBanner = ({ id }) => (dispatch) => {
	dispatch({
		type: actionTypes.HIDE_PROCESSING_BANNER,
		data: { id },
	});
};

export const copiedToClipboard = () => (dispatch) => {
	dispatch({
		type: actionTypes.SHOW_COPIED_TO_CLIPBOARD,
		data: { message: "BANNER.SUCCEEDED.COPIED_TO_CLIPBOARD" },
	});
};
export const settingsCopiedToClipboard = () => (dispatch) => {
	dispatch({
		type: actionTypes.SHOW_SETTINGS_COPIED_TO_CLIPBOARD,
		data: { message: "BANNER.SUCCEEDED.SETTINGS_COPIED" },
	});
};

export const creditCardInvalid = (code) => (dispatch) => {
	let message = "BANNER.FAILED.INVALID_CREDIT_CARD";

	switch (code) {
		case errorConfig.stripeErrorCodes.authenticationFailed:
			message = "BANNER.FAILED.AUTHENTICATION_CREDIT_CARD";
			break;
		default:
			break;
	}

	dispatch({
		type: actionTypes.SHOW_CREDIT_CARD_INVALID,
		data: { message },
	});
};
