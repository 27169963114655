import "./style.scss";

import { Component } from "@/components/atoms";
import { keyEvents } from "@/helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { IInsurance } from "@bothive_core/database";
import { insuranceType } from "../config";

const countriesList = require("world_countries_lists/data/countries/_combined/countries.json");

interface IInsuranceCard {
	id: string;
	value: IInsurance;
	onClick: (id: string) => void;
}
export default function InsuranceCard({ id, value, onClick }: IInsuranceCard) {
	const intl = useIntl();

	const handleKeyUp = (event: any) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			onClick(id);
		}
	};

	const countryCode = value.country;
	const country = countriesList.find((country) => country.alpha2 === countryCode)?.[intl.locale];

	return (
		<article role="button" onKeyUp={handleKeyUp} onClick={() => onClick(id)} className="m-card insurance_card">
			<div className="insurance_card-content">
				<p>{insuranceType[value.type] && <FormattedMessage id={insuranceType[value.type]} />}</p>
				<p className="insurance_card-title">{value.institutionName}</p>
				<p>{country || countryCode}</p>
			</div>
			<Component.AIcon type="edit" className="insurance_card-edit_icon" />
		</article>
	);
}
