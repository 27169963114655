import storageHelpers from "@/helpers/storage.helpers";
import { Button, Checkbox, Form, Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

export default function ({ flowId, onHandleSaveToContact, isOpen, setIsOpen }) {
	const [form] = Form.useForm();
	const intl = useIntl();
	const saveChoice = Form.useWatch<Boolean>("saveChoice", form);

	const handleSaveToContact = () => {
		if (saveChoice) handleSavePermissions(true);
		setIsOpen(false);
		onHandleSaveToContact();
	};

	const handleDoNotSaveToContact = () => {
		if (saveChoice) handleSavePermissions(false);
		setIsOpen(false);
	};

	const handleSavePermissions = (save: boolean) => {
		const permissions =
			storageHelpers.getJson<{ [flowId: string]: "YES" | "NO" }>({
				key: "PERMISSIONS_SAVE_REPORT_TO_CONTACT",
			}) || {};

		storageHelpers.saveJson({
			key: "PERMISSIONS_SAVE_REPORT_TO_CONTACT",
			value: { ...permissions, [flowId]: save ? "YES" : "NO" },
		});
	};

	return (
		<Modal
			title={intl.formatMessage({ id: "REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.TITLE" })}
			open={isOpen}
			onOk={handleSaveToContact}
			footer={[
				<Button ghost key="delete" type="primary" onClick={handleDoNotSaveToContact}>
					<FormattedMessage id="REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.DONT_SAVE" />
				</Button>,
				<Button key="submit" type="primary" className="t-gap--left-sm" onClick={handleSaveToContact}>
					<FormattedMessage id="REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.SAVE" />
				</Button>,
			]}
			onCancel={handleDoNotSaveToContact}
			destroyOnClose
		>
			<p>
				<FormattedMessage id="REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.DESCRIPTION" />
			</p>
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1em" }}>
				<label htmlFor="saveChoice" style={{ color: "GrayText" }}>
					<FormattedMessage id="REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.MODAL.SAVE_DECISION" />
				</label>
				<Form form={form} id="saveReportToContactForm">
					<Form.Item name="saveChoice" noStyle valuePropName="checked">
						<Checkbox />
					</Form.Item>
				</Form>
			</div>
		</Modal>
	);
}
