export default {
	aside: {
		conversations: 288,
		default: 240,
		inbox: 196,
	},
	avatar: {
		default: 60,
	},
	header: {
		default: 64,
		small: 42,
	},
	height: {
		xl: 160,
		lg: 80,
		md: 40,
		sm: 20,
		xs: 10,
		xxs: 5,
	},
	screen: {
		desktop: 992,
		mobile: 640,
		tablet: 770,
	},
	spacing: {
		xl: 80,
		lg: 40,
		md: 20,
		sm: 1,
		xs: 5,
		xxs: 2,
	},
	width: {
		xl: 400,
		lg: 250,
		md: 200,
		sm: 150,
		xs: 100,
	},
};
