import "./style.scss";

import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSetRecoilState } from "recoil";

import CardOverview from "@/flows/overview/create/overview/card_overview";
import { isAddFlowModalVisibleState, selectedTemplateIdState } from "@/flows/overview/create/state";
import DashboardCard from "@/shared/components/card/dashboard";
import { useWindowWidth } from "@/shared/hooks";

export default function EmptyStateFlows() {
	const width = useWindowWidth();
	const [cardAmount, setCardAmount] = useState(0);

	useEffect(() => {
		if (width > 768) setCardAmount(2);
		if (width > 992) setCardAmount(3);
		if (width > 1200) setCardAmount(4);
	}, [width]);

	const setSelectedTemplateId = useSetRecoilState(selectedTemplateIdState);
	const setIsModalVisible = useSetRecoilState(isAddFlowModalVisibleState);

	const handleSelectTemplate = (templateId) => {
		setSelectedTemplateId(templateId);
		setIsModalVisible(true);
	};

	return (
		<DashboardCard>
			<section className="foe-empty">
				<header className="foe-empty-header">
					<h1>
						<FormattedMessage id="FLOWS.OVERVIEW.FIRST_TIME.TITLE" />
					</h1>
					<p>
						<FormattedMessage id="FLOWS.OVERVIEW.FIRST_TIME.DESCRIPTION" />
					</p>
				</header>
				<CardOverview
					handleSelectTemplate={handleSelectTemplate}
					loadingAmount={cardAmount}
					maxAmount={cardAmount}
				/>
				<footer className="t-gap--top">
					<Button onClick={() => setIsModalVisible(true)} size="large" className="t-gap--bottom-sm">
						<FormattedMessage id="FLOWS.OVERVIEW.FIRST_TIME.BUTTON_LABEL" />
					</Button>
				</footer>
			</section>
		</DashboardCard>
	);
}
