import isEqual from "lodash.isequal";
import LogRocket from "logrocket";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Banner } from "../components/molecules";
import { Layout } from "../components/templates";
import { apiConfig, routesConfig } from "../config";
import { storageHelpers } from "../shared/helpers";
import { trpc } from "../trpc";
import { actions as dashboardActions } from "../store/dashboard";
import { actions as profileActions } from "../store/profile";
import { useEffect } from "react";

function DashboardTemplate({ children }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useLocation();
	const state = useSelector(
		(state) => ({
			banner: state.dashboard.banner,
			warningId: state.dashboard.ui.warningId,
			warningMessage: state.dashboard.ui.warningMessage,
			showWarningBanner: state.dashboard.ui.showWarningBanner,
			keyBanner: state.dashboard.banner.keyBanner,
			processingBanner: state.dashboard.banner.processingBanner,
			showCookieConcent: !state.dashboard.ui.cookieConcent,
		}),
		isEqual
	);
	const {
		data: user,
		isLoading: isVerifyingUser,
		isError: isVerificationFailed,
	} = trpc.authentication.validateToken.useQuery({}, { refetchOnWindowFocus: false });

	useEffect(() => {
		if (!storageHelpers.getUserToken()) {
			history.push(routesConfig.portal.login);
			return;
		}

		const organizationSlug = storageHelpers.getOrganizationSlug();

		if (organizationSlug && params.pathname === "/") {
			history.push(`/${organizationSlug}`);
		}

		dispatch(dashboardActions.ui.checkCookieConcent());
		dispatch(dashboardActions.ui.checkBrowserSupport());

		// Necessary for pwa update
		history.listen(() => {
			if (!window.swUpdateReady) return;

			window.swUpdateReady = false;
			window.stop();
			window.location.reload();
		});
	}, []);

	useEffect(() => {
		if (!user) return;
		if (!user.verified) return history.push("/portal/verify");

		if (process.env.NODE_ENV === "production") {
			const logRocketMetadata = { name: user.username, email: user.email };

			LogRocket.identify(user._id, logRocketMetadata);
		}

		// TODO put user into store
		dispatch(profileActions.account.setUser(user));
		dispatch(dashboardActions.header.setUserLanguage(user.language));
	}, [user]);

	useEffect(() => {
		if (!isVerificationFailed) return;

		dispatch(profileActions.account.logoutUser());
		storageHelpers.removeUserTokens();
	}, [isVerificationFailed]);

	const buildBanner = () => {
		if (!state.banner.show) return;

		return (
			<Banner.MBannerDefault {...state.banner} onClose={() => dispatch(dashboardActions.banner.hideBanner())}>
				<p>
					<FormattedMessage id={state.banner.message} values={state.banner.values} />
				</p>
			</Banner.MBannerDefault>
		);
	};

	const buildErrorBanner = () => {
		if (!state.showWarningBanner) return null;

		return (
			<Banner.MBannerError onClose={() => dispatch(dashboardActions.ui.hideWarning(state.warningId))}>
				<p>
					<FormattedMessage id={state.warningMessage} />
				</p>
			</Banner.MBannerError>
		);
	};

	if (!user || isVerifyingUser) return <Layout.TLoadScreen />;

	return (
		<div className="t-layout--template">
			{buildErrorBanner()}
			{children}
			{state.showCookieConcent && (
				<Banner.MCookieConcent
					url={`${apiConfig.fileUrl}/v1/serve/tac/current`}
					onClose={() => dispatch(dashboardActions.ui.changeCookieConcent())}
				/>
			)}
			{state.processingBanner.messages.length > 0 && (
				<Banner.MProcessing messages={state.processingBanner.messages} />
			)}
			{buildBanner()}
		</div>
	);
}

export default DashboardTemplate;
