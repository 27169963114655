import axios from "axios";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";

import { apiConfig, routesConfig, timingConfig } from "../../../config";
import { globalHeaders, localDbHelpers, storageHelpers, timerHelpers } from "../../../helpers";
import { formWatcherFailed, formWatcherSucceeded, startFormWatcher } from "../../content/form/form.action";
import * as banner from "../../dashboard/banner/banner.action";
import * as headerActions from "../../dashboard/header/header.action";
import store from "../../store";
import actionTypes from "./account.actionTypes";

const identifyLogRocket =
	({ id, _id, firstName, lastName, email }) =>
	(dispatch) => {
		if (process.env.NODE_ENV !== "production") {
			//eslint-disable-line no-undef
			return;
		}

		const logRocketMetadata = {
			name: `${firstName} ${lastName}`,
			email: email,
		};

		LogRocket.identify(id || _id, logRocketMetadata);
		Sentry.setUser({ id, ...logRocketMetadata });

		dispatch({ type: actionTypes.IDENTIFY_LOGROCKET_SESSION, id: id || _id, logRocketMetadata });
	};

function startFetchWatcher(dispatch) {
	timerHelpers.startTimer();
	timerHelpers.watchTimeout(() => {
		dispatch(fetchingFailed());
		return;
	});

	dispatch(isFetching());
}

export const isFetching = () => (dispatch) => {
	dispatch({
		type: actionTypes.ACCOUNT_IS_FETCHING,
	});
};
export const fetchingFailed = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCHING_ACCOUNT_FAILED,
	});
};
export const fetchingSucceeded = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCHING_ACCOUNT_SUCCEEDED,
	});
};
export const isValidating = () => (dispatch) => {
	dispatch({
		type: actionTypes.ACCOUNT_IS_VALIDATING,
	});
};
export const validationFailed = () => (dispatch) => {
	dispatch({
		type: actionTypes.VALIDATING_ACCOUNT_FAILED,
	});
};
export const validationSucceeded = () => (dispatch) => {
	dispatch({
		type: actionTypes.VALIDATING_ACCOUNT_SUCCEEDED,
	});
};

export const resetFetch = () => (dispatch) => {
	dispatch({
		type: actionTypes.RESET_FETCH,
	});
};

export const setUser = (user) => async (dispatch) => {
	dispatch({
		type: actionTypes.FETCH_USER_BY_ID,
		data: user,
	});
};
export const fetchUserById = (id) => async (dispatch) => {
	startFetchWatcher(dispatch);

	try {
		const result = await axios.get(apiConfig.user.getByUuid.replace(":uuid", id));

		dispatch({
			type: actionTypes.FETCH_USER_BY_ID,
			data: result.data.data,
		});
		dispatch(fetchingSucceeded());
		timerHelpers.clearTimer();
	} catch (error) {
		dispatch(fetchingFailed());
		timerHelpers.clearTimer();
	}
};

export const addTeamToUser = (value) => (dispatch) => {
	dispatch({
		type: actionTypes.ADD_GROUP_TO_USER,
		data: value,
	});
};

export const setLoggedInUser =
	({ user, token }) =>
	async (dispatch) => {
		dispatch({ type: actionTypes.LOGIN_USER, data: user });
		dispatch(saveSession({ user, token }));
	};

export const loginUser = (value) => async (dispatch) => {
	dispatch(isFetching());

	try {
		const result = await axios.post(apiConfig.auth.login, value);

		if (result.status !== 200 || !result.data?.success) {
			throw new Error();
		}

		dispatch(saveSession(result.data));
		dispatch({
			type: actionTypes.LOGIN_USER,
			data: result.data.user,
		});
		dispatch(identifyLogRocket(result.data.user));
		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const signUser = (result) => async (dispatch) => {
	dispatch(saveSession(result));
	dispatch({
		type: actionTypes.SIGN_USER,
		data: result.user,
	});
};

export const signOutUser = () => async (dispatch) => {
	dispatch({ type: actionTypes.SIGN_OUT_USER });
};

export const logoutUser =
	({ redirect } = { redirect: true }) =>
	async (dispatch) => {
		axios.get(apiConfig.auth.logout);

		setTimeout(() => {
			dispatch({ type: actionTypes.LOGOUT_USER });

			localDbHelpers.deleteAll();
			storageHelpers.removeUserTokens();

			if (redirect) {
				window.location = routesConfig.portal.login;
			}
		}, timingConfig.debounce);
	};

export const validateToken = () => async (dispatch) => {
	dispatch(isValidating());

	try {
		const result = await axios.get(apiConfig.auth.validateToken);

		dispatch({
			type: actionTypes.VALIDATE_TOKEN,
			data: result.data.data,
		});
		dispatch(identifyLogRocket(result.data.data));
		dispatch(headerActions.setUserLanguage(result.data.data.language));
		dispatch(validationSucceeded());
	} catch (error) {
		dispatch(logoutUser());
		dispatch(validationFailed());
		storageHelpers.removeUserTokens();
	}
};

export const createUser = (value) => async (dispatch) => {
	dispatch(startFormWatcher());

	try {
		const body = {
			...value,
			language: store.getState().dashboard.header.lang,
		};
		const result = await axios.post(apiConfig.user.signUp, body);

		if (result.status !== 201 || !result.data?.success) {
			throw new Error();
		}

		dispatch(
			formWatcherSucceeded(() => {
				dispatch(signUser(result.data));
				dispatch({ type: actionTypes.CREATE_USER });
			})
		);
	} catch (error) {
		dispatch(formWatcherFailed(() => undefined));
	}
};
export const createInviteUser =
	({ userId, payload }) =>
	async (dispatch) => {
		dispatch(startFormWatcher());

		try {
			const body = {
				...payload,
				language: store.getState().dashboard.header.lang,
			};
			const result = await axios.post(`${apiConfig.user.user}?userId=${userId}`, body);

			if (result.status !== 201) {
				throw new Error();
			}

			dispatch(
				formWatcherSucceeded(() => {
					dispatch(signUser(result.data));
					dispatch({ type: actionTypes.CREATE_INVITE_USER });
				})
			);
		} catch (error) {
			dispatch(formWatcherFailed(() => undefined));
		}
	};

export const updateUserLocally = (value) => async (dispatch) => {
	dispatch({
		type: actionTypes.UPDATE_USER_LOCALLY,
		data: value,
	});
};

export const updateUser = (value) => async (dispatch) => {
	dispatch(startFormWatcher());
	dispatch({
		type: actionTypes.UPDATE_USER,
		data: value,
	});

	try {
		const result = await axios.put(apiConfig.user.update, value);

		if (result.status !== 200 || !result.data?.success) {
			throw new Error();
		}

		dispatch(
			formWatcherSucceeded(() => {
				dispatch({
					type: actionTypes.UPDATE_USER,
					data: result.data.data,
				});
				dispatch(headerActions.setUserLanguage(result.data.data.language));
				dispatch(banner.showSuccessMessage({ message: "BANNER.SUCCEEDED.USER_CHANGED" }));
			})
		);
	} catch (error) {
		dispatch(
			formWatcherFailed(() => {
				dispatch(banner.showFailedMessage({ message: "BANNER.FAILED.USER_CHANGED" }));
			})
		);
	}
};
export const changeUserPassword = (body) => async (dispatch) => {
	dispatch(startFormWatcher());

	try {
		const result = await axios.put(apiConfig.user.updatePassword, body);

		if (result.status !== 200 || !result.data?.success) {
			throw new Error();
		}

		dispatch(
			formWatcherSucceeded(() => {
				dispatch({ type: actionTypes.CHANGE_USER_PASSWORD });
				dispatch(updateToken(result.data.token));
				dispatch(banner.showSuccessMessage({ message: "BANNER.SUCCEEDED.USER_CHANGED" }));
			})
		);
	} catch (error) {
		dispatch(
			formWatcherFailed(() => {
				dispatch(banner.showFailedMessage({ message: "BANNER.FAILED.USER_CHANGED" }));
			})
		);
	}
};

export const deleteUser = () => async (dispatch) => {
	function deleteFailed() {
		dispatch(fetchingFailed());
		dispatch(banner.showFailedMessage({ message: "BANNER.FAILED.OWNER_CANT_BE_DELETED" }));
	}

	try {
		const result = await axios.delete(apiConfig.user.user);

		if (result.status !== 204) {
			deleteFailed();
			return;
		}

		dispatch({ type: actionTypes.DELETE_USER });
		dispatch(fetchingSucceeded());
		dispatch(logoutUser());
	} catch (error) {
		deleteFailed();
	}
};

export const verifyAccount = (token) => async (dispatch) => {
	dispatch(isFetching());

	function failed() {
		dispatch(fetchingFailed());
		dispatch(banner.showFailedMessage({ message: "BANNER.FAILED.VERIFY_ACCOUNT" }));
	}

	try {
		const uuid = storageHelpers.getUserUuid();
		const result = await axios.post(apiConfig.user.verifyAccount.replace(":uuid", uuid), { token });

		if (result.status !== 200) {
			failed();
			return;
		}

		dispatch({
			type: actionTypes.VERIFY_ACCOUNT,
			data: result.data.user,
		});
		dispatch(fetchingSucceeded());
	} catch (error) {
		failed();
	}
};

export const requestNewVerificationCode = () => async (dispatch) => {
	dispatch(isFetching());

	function deleteFailed() {
		dispatch(fetchingFailed());
		dispatch(banner.showFailedMessage({ message: "BANNER.FAILED.REQUEST_NEW_VERIFY_CODE" }));
	}

	try {
		const uuid = storageHelpers.getUserUuid();
		const result = await axios.get(apiConfig.user.requestNewVerificationCode.replace(":uuid", uuid));

		if (result.status !== 200) {
			deleteFailed();
			return;
		}

		dispatch({ type: actionTypes.VERIFY_ACCOUNT });
		dispatch(fetchingSucceeded());
		dispatch(banner.showSuccessMessage({ message: "BANNER.SUCCEEDED.REQUEST_NEW_VERIFY_CODE" }));
	} catch (error) {
		deleteFailed();
	}
};

export const requestResetPassword = (email) => async (dispatch) => {
	dispatch(isFetching());

	function requestFailed() {
		dispatch(fetchingFailed());
		dispatch(banner.showFailedMessage({ message: "BANNER.FAILED.RESEND_RESET_PASSWORD_MAIL" }));
	}

	try {
		const result = await axios.post(apiConfig.user.requestResetPassword, { email });

		if (result.status !== 200) {
			requestFailed();
			return;
		}

		dispatch({ type: actionTypes.REQUEST_RESET_PASSWORD });
		dispatch(fetchingSucceeded());
		dispatch(banner.showSuccessMessage({ message: "BANNER.SUCCEEDED.RESEND_RESET_PASSWORD_MAIL" }));
	} catch (error) {
		requestFailed();
	}
};

export const saveSession =
	({ user, token }) =>
	async (dispatch) => {
		dispatch(updateToken(token));
		dispatch(headerActions.setUserLanguage(user.language));
		dispatch(identifyLogRocket(user));

		storageHelpers.saveUserUuid({ uuid: user._id });
	};

const updateToken = (token) => async (dispatch) => {
	const bearerToken = `Bearer ${token}`;

	dispatch(headerActions.setUserToken(bearerToken));
	storageHelpers.saveUserToken({ token: bearerToken });
	globalHeaders.updateHeaders({
		Authorization: bearerToken,
	});
};

export const changeAvailability = (isAvailable) => async (dispatch) => {
	dispatch({
		type: actionTypes.CHANGE_AVAILABILITY,
		data: isAvailable,
	});
};
