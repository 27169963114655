import React from "react";
import { Route, Switch } from "react-router-dom";

import { routesConfig } from "@/flows";
import ReportConfiguration from "@/flows/flow_detail/configuration";
import Reminders from "@/flows/flow_detail/reminders";
import ReportOverview from "@/flows/flow_detail/reports";
import ReportSettings from "@/flows/flow_detail/settings";
import StatusTypesOverview from "@/flows/flow_detail/status_types";
import FlowReportTemplate from "@/flows/flow_detail/template";
import FlowOverview from "@/flows/overview";
import ReportDetail from "@/flows/report_detail/index";
import ReportDetailTemplate from "@/flows/report_detail/template";

const FlowDetailRoutes = () => (
	<FlowReportTemplate>
		<Switch>
			<Route path={routesConfig.settings} component={ReportSettings} />
			<Route path={routesConfig.configuration} component={ReportConfiguration} />
			<Route path={routesConfig.reminders} component={Reminders} />
			<Route path={routesConfig.statusTypes} component={StatusTypesOverview} />
			<Route path={routesConfig.reports} component={ReportOverview} />
		</Switch>
	</FlowReportTemplate>
);

const ReportDetailRoutes = () => (
	<ReportDetailTemplate>
		<Switch>
			<Route path={routesConfig.reportDetail} component={ReportDetail} />
		</Switch>
	</ReportDetailTemplate>
);

// todo move recoil intl provider up to parent when it gets rewritten
export const FlowRoutes = () => (
	<Switch>
		<Route path={routesConfig.reportDetail} component={ReportDetailRoutes} />
		<Route path={routesConfig.detail} component={FlowDetailRoutes} />
		<Route path={routesConfig.overview} component={FlowOverview} />
	</Switch>
);
