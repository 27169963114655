import { FormInstance } from "antd";

import { DatePicker, Form, Input, Switch } from "antd";
import dayjs from "dayjs";
import { useIntl } from "react-intl";

import DateFormItem from "@/shared/components/input/DateFormItem";
import FieldLabel from "../../../../../../../../shared/components/field/default";
import { CountryPicker } from "../../../../../../../../shared/components/select";

export default function BankAccountItems({ form }: { form: FormInstance }) {
	const intl = useIntl();
	const countryCode = Form.useWatch("country", form);
	const isClosed = Form.useWatch("isClosed", form);
	const isBelgiumAccount = countryCode === "be";

	return (
		<>
			<FieldLabel id="iban" label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IBAN.LABEL">
				<Form.Item
					name="iban"
					className="bank_account_modal-form_item"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IBAN.ERROR_REQUIRED",
							}),
						},
						{
							validator: async (_, value) => {
								const regex = /^[a-zA-Z]{2}[\w|\s]{13,40}$/;

								if (!regex.test(value)) {
									const message = intl.formatMessage({
										id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IBAN.ERROR_INVALID",
									});

									return Promise.reject(new Error(message));
								}
							},
						},
					]}
				>
					<Input
						placeholder={intl.formatMessage({
							id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IBAN.PLACEHOLDER",
						})}
					/>
				</Form.Item>
			</FieldLabel>

			<FieldLabel id="bicCode" label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BIC.LABEL">
				<Form.Item
					name="bicCode"
					className="bank_account_modal-form_item"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BIC.ERROR_REQUIRED",
							}),
						},
					]}
				>
					<Input
						placeholder={intl.formatMessage({
							id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BIC.PLACEHOLDER",
						})}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="name"
				label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BANK_NAME.LABEL"
				help="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BANK_NAME.HELP"
			>
				<Form.Item
					name="name"
					className="bank_account_modal-form_item"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.BANK_NAME.ERROR_REQUIRED",
							}),
						},
					]}
				>
					<Input />
				</Form.Item>
			</FieldLabel>

			<FieldLabel
				id="country"
				label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.COUNTRY.LABEL"
				help="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.COUNTRY.HELP"
			>
				<Form.Item
					name="country"
					className="bank_account_modal-form_item"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.COUNTRY.LABEL.REQUIRED",
							}),
						},
					]}
				>
					<CountryPicker />
				</Form.Item>
			</FieldLabel>
			<FieldLabel id="dateOpened" label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_OPENED.LABEL" optional>
				<Form.Item name="dateOpened" className="bank_account_modal-form_item">
					<DateFormItem
						allowClear={false}
						format="DD-MM-YYYY"
						style={{ width: "100%" }}
						disabledDate={(current) => current && current > dayjs().endOf("day")}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldLabel id="isClosed" label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IS_CLOSED.LABEL">
				<Form.Item name="isClosed" valuePropName="checked">
					<Switch />
				</Form.Item>
			</FieldLabel>
			{isClosed && (
				<FieldLabel id="dateClosed" label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_CLOSED.LABEL" optional>
					<Form.Item name="dateClosed" className="bank_account_modal-form_item">
						<DatePicker
							className="form_item-date_picker"
							format="DD-MM-YYYY"
							disabledDate={(current) => current && current > dayjs().endOf("day")}
						/>
					</Form.Item>
				</FieldLabel>
			)}
			{!isBelgiumAccount && countryCode && (
				<FieldLabel id="isDeclared" label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IS_DECLARED.LABEL">
					<Form.Item name="isDeclared" valuePropName="checked">
						<Switch />
					</Form.Item>
				</FieldLabel>
			)}
			{isBelgiumAccount && (
				<>
					<FieldLabel
						id="isRefundAccount"
						label={intl.formatMessage({
							id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.IS_REFUND_ACCOUNT.LABEL",
							defaultMessage: "Refund to this account",
							description: "this account may be used to receive refunds from pb",
						})}
					>
						<Form.Item name="isRefundAccount" valuePropName="checked">
							<Switch />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						id="incomeOfChildrenOnAccount"
						label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.HAS_INCOME_OF_CHILDREN.LABEL"
					>
						<Form.Item name="incomeOfChildrenOnAccount" valuePropName="checked">
							<Switch />
						</Form.Item>
					</FieldLabel>
				</>
			)}
			{Form.useWatch("incomeOfChildrenOnAccount", form) && (
				<FieldLabel
					id="incomeOfChildrenOnAccountYear"
					label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_SINCE_CHILD_INCOME.LABEL"
				>
					<Form.Item
						name="incomeOfChildrenOnAccountYear"
						className="bank_account_modal-form_item"
						rules={[
							{
								validator: async (_, value: number) => {
									const currentYear = new Date().getFullYear();

									if (value < 1900 || value > currentYear) {
										const message = intl.formatMessage(
											{
												id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.DATE_SINCE_CHILD_INCOME.ERROR_INVALID",
											},
											{ currentYear }
										);

										return Promise.reject(new Error(message));
									}
								},
							},
						]}
					>
						<Input type="number" placeholder={`${new Date().getFullYear()}`} />
					</Form.Item>
				</FieldLabel>
			)}
		</>
	);
}
