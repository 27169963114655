import {
	AlignCenterOutlined,
	AlignLeftOutlined,
	AlignRightOutlined,
	BoldOutlined,
	CodeOutlined,
	DownOutlined,
	FormOutlined,
	ItalicOutlined,
	LineOutlined,
	LinkOutlined,
	OrderedListOutlined,
	PlusOutlined,
	StrikethroughOutlined,
	TableOutlined,
	UnderlineOutlined,
	UnorderedListOutlined,
} from "@ant-design/icons";
import { $createCodeNode } from "@lexical/code";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
	$isListNode,
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_UNORDERED_LIST_COMMAND,
	ListNode,
	REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";
import { $createHeadingNode, $createQuoteNode, $isHeadingNode } from "@lexical/rich-text";
import {
	$getSelectionStyleValueForProperty,
	$isAtNodeEnd,
	$isParentElementRTL,
	$patchStyleText,
	$selectAll,
	$setBlocksType,
} from "@lexical/selection";
import { $isTableNode, INSERT_TABLE_COMMAND } from "@lexical/table";
import {
	$findMatchingParent,
	$getNearestBlockElementAncestorOrThrow,
	$getNearestNodeOfType,
	mergeRegister,
} from "@lexical/utils";
import { Button, Divider, Dropdown, Select, Tooltip } from "antd";
import {
	$createParagraphNode,
	$getSelection,
	$isRangeSelection,
	$isRootOrShadowRoot,
	$isTextNode,
	FORMAT_ELEMENT_COMMAND,
	FORMAT_TEXT_COMMAND,
	SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage, useIntl } from "react-intl";

import TooltipWrapper from "../../TooltipWrapper";
import { FloatingLinkEditor } from "../components";
import { INSERT_SIGNATURE_COMMAND } from "../plugin/signaturePlugin";
import { OptionTools } from "./types";

const LowPriority = 1;

const supportedBlockTypes = new Set([
	"paragraph",
	"quote",
	"code",
	"h1",
	"h2",
	"h3",
	"h4",
	"h5",
	"h6",
	"ul",
	"ol",
	"bullet",
	"number",
]);
const rootTypeToRootName = {
	root: "Root",
	table: "Table",
};

type blockType =
	| "code"
	| "h1"
	| "h2"
	| "h3"
	| "h4"
	| "h5"
	| "h6"
	| "ol"
	| "paragraph"
	| "quote"
	| "ul"
	| "bullet"
	| "number";
const blockTypeToBlockName: Record<blockType, string> = {
	code: "code",
	h1: "h1",
	h2: "h2",
	h3: "h3",
	h4: "h4",
	h5: "h5",
	h6: "h6",
	paragraph: "paragraph",
	quote: "quote",
	bullet: "bullet",
	ul: "ul",
	number: "number",
	ol: "ol",
};

function getSelectedNode(selection) {
	const anchor = selection.anchor;
	const focus = selection.focus;
	const anchorNode = selection.anchor.getNode();
	const focusNode = selection.focus.getNode();

	if (anchorNode === focusNode) return anchorNode;

	const isBackward = selection.isBackward();

	if (isBackward) return $isAtNodeEnd(focus) ? anchorNode : focusNode;

	return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
}

function BlockOptionsDropdownList({ value, editor, blockType }) {
	switch (value) {
		case "paragraph":
			if (blockType !== "paragraph") {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$setBlocksType(selection, () => $createParagraphNode());
					}
				});
			}
			break;
		case "h1":
		case "h2":
		case "h3":
		case "h4":
		case "h5":
		case "h6":
			if (blockType !== value) {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$setBlocksType(selection, () => $createHeadingNode(value));
					}
				});
			}
			break;
		case "ul":
			if (blockType !== "ul") {
				editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
			} else {
				editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
			}
			break;
		case "ol":
			if (blockType !== "ol") {
				editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
			} else {
				editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
			}
			break;
		case "quote":
			if (blockType !== "quote") {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$setBlocksType(selection, () => $createQuoteNode());
					}
				});
			}
			break;
		case "code":
			if (blockType !== "code") {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$setBlocksType(selection, () => $createCodeNode());
					}
				});
			}
			break;
	}
}

export default function ToolbarPlugin({ channelId, tools = [] }: { channelId?: string; tools?: OptionTools[] }) {
	const intl = useIntl();
	const [editor] = useLexicalComposerContext();
	const toolbarRef = useRef(null);
	const [blockType, setBlockType] = useState<blockType>("paragraph");
	const [rootType, setRootType] = useState<keyof typeof rootTypeToRootName>("root");
	const [isRTL, setIsRTL] = useState<boolean>(false);
	const [isLink, setIsLink] = useState<boolean>(false);
	const [isBold, setIsBold] = useState<boolean>(false);
	const [isItalic, setIsItalic] = useState<boolean>(false);
	const [isUnderline, setIsUnderline] = useState<boolean>(false);
	const [isStrikethrough, setIsStrikethrough] = useState<boolean>(false);
	const [isCode, setIsCode] = useState<boolean>(false);
	const [fontColor, setFontColor] = useState<string>("#000");
	const [bgColor, setBgColor] = useState<string>("#fff");
	const [fontSize, setFontSize] = useState<string>("15px");
	const [fontFamily, setFontFamily] = useState<string>("Arial");
	const [isSubscript, setIsSubscript] = useState<boolean>(false);
	const [isSuperscript, setIsSuperscript] = useState<boolean>(false);

	const clearFormatting = useCallback(() => {
		editor.update(() => {
			const selection = $getSelection();

			if ($isRangeSelection(selection)) {
				selection.getNodes().forEach((node) => {
					if ($isTextNode(node)) {
						node.setFormat(0);
						node.setStyle("");
						$getNearestBlockElementAncestorOrThrow(node).setFormat("");
					}

					if ($isDecoratorBlockNode(node)) {
						node.setFormat("");
					}
				});
			}
		});
	}, [editor]);

	const $updateToolbar = useCallback(() => {
		const selection = $getSelection();

		if (!$isRangeSelection(selection)) return;

		const anchorNode = selection.anchor.getNode();
		let element =
			anchorNode.getKey() === "root"
				? anchorNode
				: $findMatchingParent(anchorNode, (e) => {
						const parent = e.getParent();
						return parent !== null && $isRootOrShadowRoot(parent);
				  });

		if (element === null) element = anchorNode.getTopLevelElementOrThrow();

		const elementKey = element.getKey();
		const elementDOM = editor.getElementByKey(elementKey);

		// Update text format
		setIsBold(selection.hasFormat("bold"));
		setIsItalic(selection.hasFormat("italic"));
		setIsUnderline(selection.hasFormat("underline"));
		setIsStrikethrough(selection.hasFormat("strikethrough"));
		setIsSubscript(selection.hasFormat("subscript"));
		setIsSuperscript(selection.hasFormat("superscript"));
		setIsCode(selection.hasFormat("code"));
		setIsRTL($isParentElementRTL(selection));

		// Update links
		const node = getSelectedNode(selection);
		const parent = node.getParent();

		setIsLink($isLinkNode(parent) || $isLinkNode(node));

		const tableNode = $findMatchingParent(node, $isTableNode);

		setRootType($isTableNode(tableNode) ? "table" : "root");

		if (elementDOM !== null) {
			if ($isListNode(element)) {
				const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
				const type = parentList ? parentList.getListType() : element.getListType();

				setBlockType(type as blockType);
			} else {
				const type = $isHeadingNode(element) ? element.getTag() : element.getType();

				if (type in blockTypeToBlockName) {
					setBlockType(type as keyof typeof blockTypeToBlockName);
				}
				// if ($isCodeNode(element)) {
				// 	const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
				// 	setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : "");
				// 	return;
				// }
			}
		}
		// Handle buttons
		setFontSize($getSelectionStyleValueForProperty(selection, "font-size", "15px"));
		setFontColor($getSelectionStyleValueForProperty(selection, "color", "#000"));
		setBgColor($getSelectionStyleValueForProperty(selection, "background-color", "#fff"));
		setFontFamily($getSelectionStyleValueForProperty(selection, "font-family", "Arial"));
	}, [editor]);

	const handleFontSizeChange = useCallback(
		(option: string) => {
			editor.update(() => {
				const selection = $getSelection();

				if ($isRangeSelection(selection)) {
					$patchStyleText(selection, { "font-size": option });
				}
			});
		},
		[editor]
	);
	const handleFontFamilyChange = useCallback(
		(option: string) => {
			editor.update(() => {
				const selection = $getSelection();

				if ($isRangeSelection(selection)) {
					$patchStyleText(selection, { "font-family": option });
				}
			});
		},
		[editor]
	);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => $updateToolbar());
			}),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				(_payload) => {
					$updateToolbar();
					return false;
				},
				LowPriority
			)
		);
	}, [editor, $updateToolbar]);

	const insertLink = useCallback(() => {
		if (!isLink) return editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");

		editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
	}, [editor, isLink]);

	const handleFormatClick = ({ key }: { key: string }) => {
		const actions = {
			superscript: () => {
				if (isSubscript) editor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript"); // Is subscript is set, undo it first
				editor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript");

				isSubscript;
			},
			subscript: () => {
				if (isSuperscript) editor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript"); // Is superScript is set, undo it first
				editor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript");
			},
			clear: clearFormatting,
		};

		actions[key] && actions[key]();
	};
	const handleInsertClick = ({ key }: { key: string }) => {
		const actions = {
			horizontalRule: () => editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined),
			image: () => editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined),
			table: () => editor.dispatchCommand(INSERT_TABLE_COMMAND, { columns: "5", rows: "5" }),
			signature: () => editor.dispatchCommand(INSERT_SIGNATURE_COMMAND, { channelId }),
		};

		actions[key] && actions[key]();
	};

	return (
		<div className="html_editor-toolbar" ref={toolbarRef}>
			{supportedBlockTypes.has(blockType) && (
				<>
					<Select
						bordered={false}
						value={blockType}
						popupMatchSelectWidth={false}
						onChange={(value) => BlockOptionsDropdownList({ value, editor, blockType })}
						options={[
							{
								value: "paragraph",
								label: (
									<FormattedMessage
										id="editor.toolbar.paragraph_styles.normal"
										defaultMessage="Normal"
										description="Html editor paragraph name Normal"
									/>
								),
							},
							{
								value: "h1",
								label: (
									<FormattedMessage
										id="editor.toolbar.paragraph_styles.heading_1"
										defaultMessage="Heading 1"
										description="Html editor paragraph name Heading 1"
									/>
								),
							},
							{
								value: "h2",
								label: (
									<FormattedMessage
										id="editor.toolbar.paragraph_styles.heading_2"
										defaultMessage="Heading 2"
										description="Html editor paragraph name Heading 2"
									/>
								),
							},
							{
								value: "h3",
								label: (
									<FormattedMessage
										id="editor.toolbar.paragraph_styles.heading_3"
										defaultMessage="Heading 3"
										description="Html editor paragraph name Heading 3"
									/>
								),
							},
							{
								value: "quote",
								label: (
									<FormattedMessage
										id="editor.toolbar.paragraph_styles.quote"
										defaultMessage="Quote"
										description="Html editor paragraph name Quote"
									/>
								),
							},
							{
								value: "ul",
								label: (
									<div className="selection_option">
										<UnorderedListOutlined className="t-gap--right-xs" />
										<FormattedMessage
											id="editor.toolbar.paragraph_styles.bullet_list"
											defaultMessage="Bullet List"
											description="Html editor paragraph name Bullet List"
										/>
									</div>
								),
							},
							{
								value: "ol",
								label: (
									<div className="selection_option">
										<OrderedListOutlined className="t-gap--right-xs" />
										<FormattedMessage
											id="editor.toolbar.paragraph_styles.numbered_list"
											defaultMessage="Number List"
											description="Html editor paragraph name Numbered List"
										/>
									</div>
								),
							},
							{
								value: "code",
								label: (
									<div className="selection_option">
										<CodeOutlined className="t-gap--right-xs" />
										<FormattedMessage
											id="editor.toolbar.paragraph_styles.code_block"
											defaultMessage="Code Block"
											description="Html editor paragraph name Code Block"
										/>
									</div>
								),
							},
						]}
					/>
					<Divider type="vertical" />
				</>
			)}
			<TooltipWrapper
				title={intl.formatMessage({
					id: "editor.toolbar.formatting.bold.tooltip",
					defaultMessage: "format text to bold",
				})}
				shortcut="B"
			>
				<Button
					type="text"
					data-active={isBold}
					icon={<BoldOutlined />}
					className="toolbar-button"
					onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
					aria-label={intl.formatMessage({
						id: "editor.toolbar.formatting.bold.aria_label",
						defaultMessage: "format text to bold",
						description: "A11y aria label to format selected text to bold",
					})}
				/>
			</TooltipWrapper>
			<TooltipWrapper
				title={intl.formatMessage({
					id: "editor.toolbar.formatting.italic.tooltip",
					defaultMessage: "Format italic",
				})}
				shortcut="I"
			>
				<Button
					type="text"
					data-active={isItalic}
					icon={<ItalicOutlined />}
					className="toolbar-button"
					onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
					aria-label={intl.formatMessage({
						id: "editor.toolbar.formatting.italic.aria_label",
						defaultMessage: "Format italic",
						description: "A11y aria label to format selected text to italics",
					})}
				/>
			</TooltipWrapper>
			<TooltipWrapper
				title={intl.formatMessage({
					id: "editor.toolbar.formatting.underline.tooltip",
					defaultMessage: "Format underline",
				})}
				shortcut="U"
			>
				<Button
					type="text"
					data-active={isUnderline}
					icon={<UnderlineOutlined />}
					className="toolbar-button"
					aria-label={intl.formatMessage({
						id: "editor.toolbar.formatting.underline.aria_label",
						defaultMessage: "Format underline",
						description: "A11y aria label to format selected text to underline",
					})}
					onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}
				/>
			</TooltipWrapper>
			<Tooltip
				title={intl.formatMessage({
					id: "editor.toolbar.formatting.strikethrough.tooltip",
					defaultMessage: "Format strikethrough",
				})}
			>
				<Button
					type="text"
					data-active={isStrikethrough}
					icon={<StrikethroughOutlined />}
					className="toolbar-button"
					aria-label={intl.formatMessage({
						id: "editor.toolbar.formatting.strikethrough.aria_label",
						defaultMessage: "Format strikethrough",
						description: "A11y aria label to format selected text to strikethrough",
					})}
					onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")}
				/>
			</Tooltip>
			<Tooltip
				title={intl.formatMessage({
					id: "editor.toolbar.formatting.insert_link.tooltip",
					defaultMessage: "Format text as link",
				})}
			>
				<Button
					aria-label={intl.formatMessage({
						id: "editor.toolbar.formatting.insert_link.aria_label",
						defaultMessage: "Format text as link",
						description: "A11y aria label to format selected text to insert link",
					})}
					type="text"
					data-active={isLink}
					icon={<LinkOutlined />}
					className="toolbar-button"
					onClick={insertLink}
				/>
			</Tooltip>
			{isLink &&
				createPortal(
					<FloatingLinkEditor editor={editor} className="html_editor-toolbar-link_editor" />,
					document.body
				)}
			<Divider type="vertical" />
			<Tooltip
				title={intl.formatMessage({
					id: "editor.toolbar.paragraph_styles.bullet_list.tooltip",
					defaultMessage: "Bullet list",
				})}
			>
				<Button
					type="text"
					data-active={blockType === "ul"}
					icon={<UnorderedListOutlined />}
					className="toolbar-button"
					aria-label={intl.formatMessage({
						id: "editor.toolbar.paragraph_styles.bullet_list.aria_label",
						defaultMessage: "format paragraph into bullet list",
						description: "Html editor paragraph name Bullet List",
					})}
					onClick={() => BlockOptionsDropdownList({ value: "ul", editor, blockType })}
				/>
			</Tooltip>
			<Tooltip
				title={intl.formatMessage({
					id: "editor.toolbar.paragraph_styles.numbered_list.tooltip",
					defaultMessage: "Number list",
				})}
			>
				<Button
					type="text"
					data-active={blockType === "ol"}
					icon={<OrderedListOutlined />}
					className="toolbar-button"
					aria-label={intl.formatMessage({
						id: "editor.toolbar.paragraph_styles.numbered_list",
						defaultMessage: "Number List",
						description: "Html editor paragraph name Numbered List",
					})}
					onClick={() => BlockOptionsDropdownList({ value: "ol", editor, blockType })}
				/>
			</Tooltip>
			<Divider type="vertical" />
			{blockType !== "code" && (
				<>
					<Select
						bordered={false}
						value={fontFamily}
						popupMatchSelectWidth={false}
						onChange={handleFontFamilyChange}
						options={[
							{ label: "Arial", value: "Arial" },
							{ label: "Courier New", value: "Courier New" },
							{ label: "Georgia", value: "Georgia" },
							{ label: "Open Sans", value: "Open Sans" },
							{ label: "Times New Roman", value: "Times New Roman" },
							{ label: "Trebuchet MS", value: "Trebuchet MS" },
							{ label: "Verdana", value: "Verdana" },
						]}
					/>
					<Select
						bordered={false}
						value={fontSize}
						popupMatchSelectWidth={false}
						onChange={handleFontSizeChange}
						options={Array(10)
							.fill(0)
							.map((_, index) => ({
								value: `${10 + index}px`,
								label: `${10 + index}px`,
							}))}
					/>
					<Divider type="vertical" />
				</>
			)}
			<Dropdown
				menu={{
					onClick: handleFormatClick,
					selectedKeys: [isSubscript ? "subscript" : "", isSuperscript ? "superscript" : ""],
					items: [
						{
							key: "superscript",
							label: intl.formatMessage({
								id: "editor.toolbar.superscript",
								defaultMessage: "Superscript",
								description: "Html editor mobile superscript button label",
							}),
						},
						{
							key: "subscript",
							label: intl.formatMessage({
								id: "editor.toolbar.subscript",
								defaultMessage: "Subscript",
								description: "Html editor mobile subscript button label",
							}),
						},
						{
							key: "clear",
							label: intl.formatMessage({
								id: "editor.toolbar.clear_formatting",
								defaultMessage: "Clear formatting",
								description: "Html editor mobile clear formatting button label",
							}),
						},
					],
				}}
			>
				<span className="html_editor-toolbar-dropdown-header">
					<FormattedMessage
						id="editor.toolbar.formatting.title"
						defaultMessage="Formatting"
						description="Html editor toolbar dropdown title where user can find extra formatting options"
					/>
					<DownOutlined className="t-gap--left-xs" />
				</span>
			</Dropdown>
			<Divider type="vertical" />
			<Dropdown
				menu={{
					onClick: handleInsertClick,
					items: [
						{
							key: "horizontalRule",
							icon: <LineOutlined className="t-gap--right-xs" />,
							label: intl.formatMessage({
								id: "editor.toolbar.horizontal_rule",
								defaultMessage: "Horizontal Rule",
								description: "Html editor mobile horizontal rule button label",
							}),
						},
						// {
						// 	key: "image",
						// 	icon: <FileImageOutlined className="t-gap--right-xs" />,
						// 	label: intl.formatMessage({
						// 		id: "editor.toolbar.insert_image",
						// 		defaultMessage: "Insert Image",
						// 		description: "Html editor mobile insert image button label",
						// 	}),
						// },
						{
							key: "table",
							icon: <TableOutlined className="t-gap--right-xs" />,
							label: intl.formatMessage({
								id: "editor.toolbar.table",
								defaultMessage: "Table",
								description: "Html editor mobile table button label",
							}),
						},
						tools?.includes("signature") && {
							key: "signature",
							icon: <FormOutlined className="t-gap--right-xs" />,
							label: intl.formatMessage({
								id: "editor.toolbar.signature",
								defaultMessage: "Signature",
								description: "Html editor mobile add signature button label",
							}),
						},
					].filter(Boolean),
				}}
			>
				<span className="html_editor-toolbar-dropdown-header">
					<PlusOutlined className="t-gap--right-xs" />
					<FormattedMessage
						id="editor.toolbar.insert.title"
						defaultMessage="Insert"
						description="Html editor toolbar dropdown title where user can find extra options"
					/>
				</span>
			</Dropdown>
			<Divider type="vertical" />
			<Tooltip
				title={intl.formatMessage({
					id: "editor.toolbar.text_align.left_align.tooltip",
					defaultMessage: "Align text left",
				})}
			>
				<Button
					type="text"
					icon={<AlignLeftOutlined />}
					className="toolbar-button"
					aria-label={intl.formatMessage({
						id: "editor.toolbar.text_align.left_align.aria_label",
						defaultMessage: "Align text left",
						description: "A11y aria label to format selected text to left align",
					})}
					onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left")}
				/>
			</Tooltip>
			<Tooltip
				title={intl.formatMessage({
					id: "editor.toolbar.text_align.center_align.tooltip",
					defaultMessage: "Align text center",
				})}
			>
				<Button
					type="text"
					icon={<AlignCenterOutlined />}
					className="toolbar-button"
					aria-label={intl.formatMessage({
						id: "editor.toolbar.text_align.center_align.aria_label",
						defaultMessage: "Align text center",
						description: "A11y aria label to format selected text to center align",
					})}
					onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center")}
				/>
			</Tooltip>
			<Tooltip
				title={intl.formatMessage({
					id: "editor.toolbar.text_align.right_align.tooltip",
					defaultMessage: "Align text right",
				})}
			>
				<Button
					type="text"
					icon={<AlignRightOutlined />}
					className="toolbar-button"
					aria-label={intl.formatMessage({
						id: "editor.toolbar.text_align.right_align.aria_label",
						defaultMessage: "Align text right",
						description: "A11y aria label to format selected text to right align",
					})}
					onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right")}
				/>
			</Tooltip>
			{/* <Button
				aria-label={intl.formatMessage({
					id:"editor.toolbar.text_align.justify_align",
					defaultMessage: "Format justify align",
					description: "A11y aria label to format selected text to justify align"
				})}
				icon={<Align />}
				className="toolbar-item"
				onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify")}
			/> */}
		</div>
	);
}
