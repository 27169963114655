import { Button, Col, Row, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { addConnection } from "../common/helpers";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { trpc } from "@/trpc";

export default function IntegrationConnection({
	allowEditing,
	canAddIntegration,
}: {
	allowEditing: boolean;
	canAddIntegration: boolean;
}) {
	const intl = useIntl();
	const history = useHistory();
	const location = useLocation();
	const trpcUtils = trpc.useUtils();
	const addConnectionMutation = trpc.integration.addConnection.useMutation();
	const { connectionId: integrationId }: any = useParams();

	return (
		<Row className="integrationModal">
			<Col className="info" xs={24} xl={9}>
				<section className="info-section">
					<h2 className="info-title">
						<FormattedMessage
							id="SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.TITLE"
							defaultMessage="Connect with Liantis"
						/>
					</h2>
					<p>
						<FormattedMessage
							id="SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.DESCRIPTION"
							defaultMessage="Connect Liantis to Bothive to send flow data"
						/>
					</p>
				</section>
			</Col>
			<Col className="login" xs={24} xl={15}>
				<div className="logo-container liantisLogo">
					<img
						src="https://storage.googleapis.com/bothive_assets_8a0s1s3e/integration_icon/liantis_icon.png"
						alt="Liantis logo"
						className="logo"
					/>
				</div>
				<Tooltip
					title={
						!allowEditing
							? intl.formatMessage({
									id: "settings.integrations.liantis.tooltip.missing_integrations_permissions",
									defaultMessage: "Missing edit integrations permissions",
							  })
							: !canAddIntegration
							? intl.formatMessage({
									id: "settings.integrations.liantis.tooltip.limit_reached",
									defaultMessage: "Maximum integrations limit reached",
							  })
							: null
					}
					placement="bottom"
				>
					<Button
						type="primary"
						size="large"
						className="button t-gap--top-lg"
						onClick={() =>
							addConnection(intl, trpcUtils, integrationId, history, location, addConnectionMutation)
						}
						loading={addConnectionMutation.isLoading}
						disabled={!allowEditing || !canAddIntegration}
					>
						<FormattedMessage
							id="SETTINGS.INTEGRATIONS.LIANTIS.CONNECT.BUTTON"
							defaultMessage="Login to Liantis"
						/>
					</Button>
				</Tooltip>
			</Col>
		</Row>
	);
}
