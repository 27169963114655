import "./style.scss";
import { ReactNode } from "react";

interface IExternalLink {
	href: string;
	children: ReactNode;
	className?: string;
}

export default function ExternalLink({ href, className, children }: IExternalLink) {
	return (
		<a href={href} className={`component_external_link ${className}`} target="_blank" rel="noopener noreferrer">
			{children}
		</a>
	);
}

ExternalLink.defaultProps = {
	className: "",
};
