import "./style.scss";

import { DeleteOutlined, FileTextOutlined, InboxOutlined } from "@ant-design/icons";
import { generatorHelpers } from "@bothive/helpers";
import { Alert, Button, Card, Form, Input, Radio, Select, Space, Switch, Typography, Upload, UploadFile } from "antd";
import csvToJson from "csvtojson";
import { useState } from "react";
import { FormattedMessage, MessageDescriptor, defineMessage, useIntl } from "react-intl";
import { useRecoilState, useSetRecoilState } from "recoil";

import { ContactLinkingTypeI18n } from "../../config";
import { ImportConfig, fileState, importConfigState, importDataState } from "./state";
import { StepParams } from "../types";
import FieldSideLabel from "@/shared/components/field/side_label";

const MAX_ROWS = 10_000;
const MAX_COLUMNS = 50;

export default function FileUpload({ onNext, onBack, onClose }: StepParams & { onNext: () => void }) {
	const intl = useIntl();
	const [form] = Form.useForm<ImportConfig>();
	const setImportData = useSetRecoilState(importDataState);
	const [isValidatingFile, setIsFileValidating] = useState(false);
	const [uploadedFile, setUploadedFile] = useRecoilState(fileState);
	const [importConfig, setImportConfig] = useRecoilState(importConfigState);
	const [importError, setImportError] = useState<MessageDescriptor | undefined>(undefined);

	const uploadType = Form.useWatch("type", form);

	const handleFieldChange = (changedFields) => {
		const field = changedFields[0];

		if (!field.name.includes("type")) return;

		form.setFieldValue("linkedContactType", field.value === "linkedContact" ? "partner" : undefined);
	};

	const handleFileUpload = ({ file }: { file: UploadFile<any> }) => {
		setImportError(undefined);
		setUploadedFile(file);
	};

	const handleClose = () => onClose();

	const handleNext = async () => {
		setIsFileValidating(true);

		try {
			const config = form.getFieldsValue();

			if (!uploadedFile) return;

			const fileContents = await uploadedFile.originFileObj?.text();
			const json = await csvToJson({ output: "json", delimiter: config.delimiter, flatKeys: true }).fromString(
				fileContents || ""
			);

			const idRows = json.map((item) => ({ ...item, id: item.id || generatorHelpers.generateId() }));

			if (MAX_ROWS < json.length) {
				setIsFileValidating(false);
				setImportError(
					defineMessage({
						id: "contact.import.csv.file_upload.error.max_rows",
						defaultMessage: "The CSV file you uploaded can only contain maximum of {maxRows} rows",
					})
				);
				return;
			}

			const countColumns = json.map((columns) => Object.keys(columns).length);

			if (countColumns.find((columnCount) => MAX_COLUMNS < columnCount)) {
				setIsFileValidating(false);
				setImportError(
					defineMessage({
						id: "contact.import.csv.file_upload.error.max_columns",
						defaultMessage: "The CSV file you uploaded can only contain maximum of {maxColumns} columns",
					})
				);
				return;
			}

			setImportConfig(config);
			setImportData(idRows);
			onNext();
		} catch (_) {
			setImportError(
				defineMessage({
					id: "contact.import.csv.file_upload.error.failed",
					defaultMessage: "The CSV file cannot be uploaded. Review your file for errors and try again later.",
				})
			);
		}

		setIsFileValidating(false);
	};

	return (
		<Form
			form={form}
			preserve={false}
			layout="vertical"
			onFinish={handleNext}
			initialValues={importConfig}
			onFieldsChange={handleFieldChange}
			className="contact_import-content_wrapper contact_import-upload"
		>
			<Card
				title={intl.formatMessage({
					id: "contact.import.csv.file_upload.title",
					defaultMessage: "Upload import file",
				})}
				className="contact_import-content-card"
			>
				<Form.Item name="type">
					<Radio.Group>
						<Space direction="vertical">
							<Radio value="customer">
								<div className="type-option_label">
									<Typography.Text strong>
										<FormattedMessage
											id="contact.import.csv.file_upload.what_to_import.option.contacts.label"
											defaultMessage="Contacts"
										/>
									</Typography.Text>
									<Typography.Text type="secondary">
										<FormattedMessage
											id="contact.import.csv.file_upload.what_to_import.option.contacts.help"
											defaultMessage="Contacts will be imported using email, phone, national registration number or unique identifier. <link>View example file</link>"
											values={{
												link: (content) => (
													<Typography.Link
														target="_blank"
														rel="noopener noreferrer"
														className="type-option-link"
														href="https://storage.googleapis.com/bothive_assets_8a0s1s3e/examples/contact_import_example.csv"
													>
														{content}
													</Typography.Link>
												),
											}}
										/>
									</Typography.Text>
								</div>
							</Radio>
							<Radio value="linkedContact">
								<div className="type-option_label">
									<Typography.Text strong>
										<FormattedMessage
											id="contact.import.csv.file_upload.what_to_import.option.linked_contacts.label"
											defaultMessage="Linked"
										/>
									</Typography.Text>
									<Typography.Text type="secondary">
										<FormattedMessage
											id="contact.import.csv.file_upload.what_to_import.option.linked_contacts.help"
											defaultMessage="Linked contacts will be imported and linked to a existing contact using email, phone, national registration number or unique identifier. <link>View example file</link>"
											values={{
												link: (content) => (
													<Typography.Link
														target="_blank"
														rel="noopener noreferrer"
														className="type-option-link"
														href="https://storage.googleapis.com/bothive_assets_8a0s1s3e/examples/contact_linked_to_example.csv"
													>
														{content}
													</Typography.Link>
												),
											}}
										/>
									</Typography.Text>
								</div>
							</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				{!uploadedFile && (
					<Upload.Dragger
						accept=".csv"
						maxCount={1}
						multiple={false}
						name="csv_upload"
						itemRender={() => <div></div>}
						fileList={uploadedFile && [uploadedFile]}
						onChange={handleFileUpload}
					>
						<div>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								<FormattedMessage
									id="contact.import.csv.file_upload.choose_file.title"
									defaultMessage="Choose file"
								/>
							</p>
							<p className="ant-upload-hint">
								<FormattedMessage
									id="contact.import.csv.file_upload.choose_file.description"
									defaultMessage="Select a csv file to import"
								/>
							</p>
						</div>
					</Upload.Dragger>
				)}
				{uploadedFile && (
					<div className="contact_import-upload_preview">
						<Typography.Text>
							<FormattedMessage
								id="contact.import.csv.file_upload.preview_file.label"
								defaultMessage="Selected file"
							/>
						</Typography.Text>
						<div className="upload_form_item-preview">
							<div className="upload_form_item-preview-content">
								<FileTextOutlined /> {uploadedFile.name}
							</div>
							<Button
								danger
								type="text"
								icon={<DeleteOutlined />}
								onClick={() => setUploadedFile(undefined)}
							/>
						</div>
					</div>
				)}
			</Card>
			{uploadedFile && (
				<Card
					title={intl.formatMessage({
						id: "contact.import.csv.file_upload.details.title",
						defaultMessage: "Import details",
					})}
					className="contact_import-content-card"
				>
					<div className="contact_import-content-card-body">
						{importError && (
							<Alert
								type="error"
								message={intl.formatMessage(importError, {
									maxRows: MAX_ROWS,
									maxColumns: MAX_COLUMNS,
								})}
							/>
						)}
						<Form.Item
							id="delimiter"
							name="delimiter"
							className="upload-switch-form_item"
							label={intl.formatMessage({
								id: "contact.import.csv.file_upload.details.delimiter.label",
								defaultMessage: "Delimiter",
							})}
							help={intl.formatMessage({
								id: "contact.import.csv.file_upload.details.delimiter.help",
								defaultMessage:
									"Change this value if the columns are not correctly shown in the next step",
							})}
						>
							<Input />
						</Form.Item>
						{uploadType === "linkedContact" && (
							<Form.Item
								id="linkedContactType"
								name="linkedContactType"
								label={intl.formatMessage({
									id: "contact.import.csv.file_upload.details.linked_contact_type.label",
									defaultMessage: "Type of linked contacts",
								})}
								help={intl.formatMessage({
									id: "contact.import.csv.file_upload.details.linked_contact_type.help",
									defaultMessage: "What type of linked contacts are you importing",
								})}
							>
								<Select
									options={[
										{ value: "child", label: intl.formatMessage(ContactLinkingTypeI18n.child) },
										{
											value: "dependent",
											label: intl.formatMessage(ContactLinkingTypeI18n.dependent),
										},
										{ value: "other", label: intl.formatMessage(ContactLinkingTypeI18n.other) },
										{
											value: "partner",
											label: intl.formatMessage(ContactLinkingTypeI18n.partner),
										},
									]}
								/>
							</Form.Item>
						)}
						<FieldSideLabel
							id="allowCreate"
							label={intl.formatMessage({
								id: "contact.import.csv.file_upload.details.allow_create.label",
								defaultMessage: "Do you want to add new contacts?",
							})}
							className="upload-switch-form_item"
						>
							<Form.Item name="allowCreate" valuePropName="checked">
								<Switch
									checkedChildren={intl.formatMessage({
										id: "contact.import.csv.file_upload.details.toggle.yes",
										defaultMessage: "Yes",
									})}
									unCheckedChildren={intl.formatMessage({
										id: "contact.import.csv.file_upload.details.toggle.no",
										defaultMessage: "No",
									})}
								/>
							</Form.Item>
						</FieldSideLabel>
						<FieldSideLabel
							id="allowUpdate"
							label={intl.formatMessage({
								id: "contact.import.csv.file_upload.details.allow_update.label",
								defaultMessage: "Do you want to update existing contacts?",
							})}
							className="upload-switch-form_item"
						>
							<Form.Item name="allowUpdate" valuePropName="checked">
								<Switch
									checkedChildren={intl.formatMessage({
										id: "contact.import.csv.file_upload.details.toggle.yes",
										defaultMessage: "Yes",
									})}
									unCheckedChildren={intl.formatMessage({
										id: "contact.import.csv.file_upload.details.toggle.no",
										defaultMessage: "No",
									})}
								/>
							</Form.Item>
						</FieldSideLabel>
						<footer className="contact_import-content-footer">
							<Button type="text" danger onClick={handleClose}>
								<FormattedMessage
									id="contact.import.csv.file_upload.footer.cancel"
									defaultMessage="Cancel import"
								/>
							</Button>
							<Form.Item shouldUpdate className="contact_import-content-footer-submit">
								{() => {
									const isDisabled =
										!!form.getFieldsError().filter(({ errors }) => errors.length).length ||
										!uploadedFile;

									return (
										<Button
											type="primary"
											htmlType="submit"
											disabled={isDisabled}
											loading={isValidatingFile}
										>
											<FormattedMessage
												id="contact.import.csv.file_upload.footer.next"
												defaultMessage="Next"
											/>
										</Button>
									);
								}}
							</Form.Item>
						</footer>
					</div>
				</Card>
			)}
		</Form>
	);
}
