import "./style.scss";

import { Col, Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { integrationConfig } from "../../../../../../config";
import { Panel } from "../../../../../../shared/components/collapse";
import { ContactRouterOutput } from "../../../../../../trpc";

// THIS IS SHIT - FAST HOTFIX
interface IExternalLinks {
	contact: ContactRouterOutput["getContactById"];
	panelProps?: any;
}
export default function ExternalLinks({ contact, ...panelProps }: IExternalLinks) {
	const intl = useIntl();

	const partnersMap = {
		"5cdc7149e0f7a731c8b449ca": "admin_pulse",
		admin_pulse: "admin_pulse",
	};

	if (!contact.externalIds || !Object.values(contact.externalIds).length) return null;

	const group = Object.keys(contact.externalIds).reduce((prev, id) => {
		if (!partnersMap[id]) return prev;

		return { ...prev, [partnersMap[id]]: contact.externalIds?.[id] };
	}, {});

	return (
		<Panel
			key="externalLinks"
			title={intl.formatMessage({
				id: "contacts.detail.attributes.external_links.title",
				defaultMessage: "External links",
			})}
			{...panelProps}
		>
			<Row className="contact_external_Links">
				{Object.keys(group).map((key) => {
					if (!integrationConfig.partnersMap[key]) return;

					const partnerMappedId = integrationConfig.partnersMap[key];

					return (
						<Col
							key={key}
							xs={{ flex: "100%" }}
							sm={{ flex: "50%" }}
							md={{ flex: "25%" }}
							className="contact_external_Links-col"
						>
							<img
								src={integrationConfig[partnerMappedId].icon}
								className="contact_external_Links-col-image"
							/>
							<div className="contact_external_Links-col-body">
								<p className="body-title">
									<FormattedMessage
										id="contacts.detail.attributes.external_links.admin_pulse.label"
										defaultMessage="Admin Pulse"
									/>
								</p>
								<p>{group?.[key]}</p>
							</div>
						</Col>
					);
				}, {})}
			</Row>
		</Panel>
	);
}
