import { Button, Tooltip } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { selectedFlowIdState } from "@/flows/common/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";

import { removeStatusType, statusTypesList } from "../../state";
import config from "../config";
import { updateStatusTypeList } from "../helper";
import { editStatusTypeIdState, showEditStatusTypeState } from "../state";
import StatusTypesModalTemplate from "../template";
import { deleteStatusType, updateStatusType } from "./query";
import style from "./style.module.scss";

export default function EditStatusTypesModal() {
	const intl = useIntl();
	const [isUpdating, setIsUpdating] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const flowId = useRecoilValue(selectedFlowIdState);
	const statusTypeId = useRecoilValue(editStatusTypeIdState);
	const setDeletedStatusType = useSetRecoilState(removeStatusType);
	const [statusTypes, setStatusTypes] = useRecoilState(statusTypesList);
	const [issModalVisible, setIsModalVisible] = useRecoilState(showEditStatusTypeState);

	const statusType = statusTypes?.find((statusType) => statusType?._id === statusTypeId);

	const handleUpdate = async (payload) => {
		let errorTitle = config.updateError.default;

		setIsUpdating(true);

		try {
			const result = await updateStatusType({ flowId, statusTypeId, payload });

			if (!result.success) {
				errorTitle = config.updateError[result.error.code];
				throw new Error();
			}

			const updatedList = updateStatusTypeList({ list: statusTypes, updatedStatus: result.data });

			setStatusTypes(updatedList);
			setIsModalVisible(false);
		} catch (error) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: errorTitle || config.updateError.default,
					defaultMessage: errorTitle || config.updateError.default,
				}),
			});
		}

		setIsUpdating(false);
	};

	const handleDelete = async () => {
		setIsDeleting(true);

		try {
			await deleteStatusType({ flowId, statusTypeId });

			setDeletedStatusType(statusTypeId);
			setIsModalVisible(false);
			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE.SUCCEEDED",
					defaultMessage: "Status type has been deleted",
				}),
			});
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE.FAILED",
					defaultMessage: "Could not delete status type",
				}),
			});
		}

		setIsDeleting(false);
	};

	return (
		<StatusTypesModalTemplate
			title="FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT.TITLE"
			handleSubmit={(values) => handleUpdate(values)}
			onCancel={() => setIsModalVisible(false)}
			isVisible={issModalVisible}
			initialValues={statusType}
			footer={
				<footer className={style.status_type_edit_footer}>
					<Tooltip
						title={
							statusType?.isDefault &&
							intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT.IS_DEFAULT.TOOLTIP" })
						}
					>
						<Button
							danger
							key="delete"
							type="primary"
							onClick={handleDelete}
							loading={isDeleting}
							disabled={isUpdating || statusType?.isDefault}
						>
							<FormattedMessage id="FLOWS.REPORTS.STATUS_TYPES.MODAL.DELETE" />
						</Button>
					</Tooltip>
					<Button
						key="submit"
						type="primary"
						form="StatusTypeForm"
						htmlType="submit"
						loading={isUpdating}
						disabled={isDeleting}
					>
						<FormattedMessage id="FLOWS.REPORTS.STATUS_TYPES.MODAL.EDIT" />
					</Button>
				</footer>
			}
		/>
	);
}
