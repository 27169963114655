import axios from "axios";

import { apiConfig, routesConfig } from "../../../config";
import { globalHeaders, storageHelpers } from "../../../helpers";
import * as banner from "../../dashboard/banner/banner.action";
import { setTeamPermissions, setUserPermissions } from "../../dashboard/permissions/permissions.action";
import { addTeamToUser } from "../../profile/account/account.action";
import store from "../../store";
import {
	formWatcherFailed,
	formWatcherSucceeded,
	startFormWatcher,
	updateTeam as updateFormTeam,
} from "../form/form.action";
import actionTypes from "./team.actionTypes";

export const isFetching = () => (dispatch) => {
	dispatch({
		type: actionTypes.TEAM_IS_FETCHING,
	});
};
export const fetchingFailed = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCHING_TEAM_FAILED,
	});
};
export const fetchingSucceeded = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCHING_TEAM_SUCCEEDED,
	});
};

function formatTeam(data) {
	if (!data) {
		return undefined;
	}

	return {
		...data,
		chatbot: {
			...(data?.chatbot || []),
			type: data?.chatbot?.chatbotUuid,
		},
	};
}

export const fetchTeam =
	(slug, reFetch = false) =>
	async (dispatch) => {
		try {
			const team = store.getState().content.team;

			if (team.isFetching) return;

			if (team?.team && team?.team?.slug === slug && reFetch === false) {
				globalHeaders.updateHeaders({ organizationId: team?.team._id });
				dispatch({ type: actionTypes.TEAM_IS_ALREADY_FETCHED });
				return;
			}

			dispatch(isFetching());
			const result = await axios.get(apiConfig.team.getBySlug.replace(":slug", slug || team.team?.slug));

			if (result.status !== 200) throw new Error();

			const organization = result.data.organization;

			globalHeaders.updateHeaders({ organizationId: organization._id });

			const [membership] = await Promise.all([
				axios.get(apiConfig.team.getUsersMembership.replace(":id", organization._id)),
			]);

			if (membership.status !== 200) throw new Error();

			dispatch(
				setTeamSettings({
					team: organization,
					permissions: membership.data.data.permissions,
				})
			);
			dispatch({
				type: actionTypes.FETCH_TEAM,
				data: formatTeam(organization),
			});

			dispatch(fetchingSucceeded());
		} catch (error) {
			storageHelpers.removeTeamSlug();
			storageHelpers.removeTeamUuid();
			window.location = routesConfig.profile.root; // If failed redirect user to login page is necessary for login when wrong team slug is selected
			dispatch(fetchingFailed());
		}
	};

export const changeTeam = (organizationId) => async (dispatch) => {
	dispatch(isFetching());

	const team = store.getState().content.team;
	const previousOrganizationId = team.team?._id;

	if (previousOrganizationId === organizationId) {
		dispatch({ type: actionTypes.TEAM_IS_ALREADY_FETCHED });
		return;
	}

	try {
		globalHeaders.updateHeaders({ organizationId });

		const result = await axios.get(apiConfig.team.getByUuid.replace(":uuid", organizationId));

		if (result.status !== 200) return dispatch(fetchingFailed());

		const organization = result.data.data;
		const [membership] = await Promise.all([
			axios.get(apiConfig.team.getUsersMembership.replace(":id", organizationId)),
		]);

		if (membership.status !== 200) return dispatch(fetchingFailed());

		dispatch(
			setTeamSettings({
				team: organization,
				permissions: membership.data.data.permissions,
			})
		);
		dispatch({
			type: actionTypes.TEAM_CHANGED,
			data: formatTeam(organization),
		});
		window.location = routesConfig.dashboard.root.replace(":team", organization.slug);

		dispatch(fetchingSucceeded());
	} catch (error) {
		globalHeaders.updateHeaders({ organizationId: previousOrganizationId });
		dispatch(fetchingFailed());
	}
};

export const updateTeamLocally = (team) => (dispatch) => {
	dispatch(setTeamSettings({ team }));
	dispatch({
		type: actionTypes.UPDATE_TEAM_LOCAL,
		data: formatTeam(team),
	});
};

export const updateBillingInformation = (payload) => async (dispatch) => {
	dispatch({ type: actionTypes.UPDATE_BILLING_INFORMATION });
	dispatch(updateFormTeam({ value: { billing: payload } }));
};

export const createTeam =
	({ createTeam, chatbotUuid, ...team }) =>
	async (dispatch) => {
		dispatch(startFormWatcher());

		if (!createTeam) {
			return;
		}

		const body = {
			team: {
				...team,
				chatbot: {
					chatbotUuid,
				},
			},
		};

		try {
			const result = await axios.post(apiConfig.team.create, body);

			if (result.status !== 201) throw new Error();

			const organization = result.data.data;

			globalHeaders.updateHeaders({ organizationId: organization._id });

			const [membership] = await Promise.all([
				axios.get(apiConfig.team.getUsersMembership.replace(":id", organization._id)),
			]);

			dispatch(
				formWatcherSucceeded(() => {
					dispatch(
						setTeamSettings({
							team: organization,
							permissions: membership.data.data.permissions,
						})
					);

					dispatch(addTeamToUser(organization));
					dispatch({
						type: actionTypes.CREATE_TEAM,
						data: formatTeam(organization),
					});
				})
			);
		} catch (error) {
			dispatch(
				formWatcherFailed(() => {
					dispatch(banner.showFailedMessage({ message: "BANNER.FAILED.PAYMENT" }));
				})
			);
		}
	};

export const updateTeam = (value) => async (dispatch) => {
	dispatch(startFormWatcher());
	dispatch(updateTeamLocally(value));

	try {
		const uuid = storageHelpers.getTeamUuid();
		const result = await axios.put(apiConfig.team.getByUuid.replace(":uuid", uuid), value);

		if (result.status !== 200) {
			throw new Error();
		}

		dispatch(
			formWatcherSucceeded(() => {
				dispatch({
					type: actionTypes.UPDATE_TEAM,
					data: formatTeam(result.data.result),
				});
				dispatch(banner.showSuccessMessage({ message: "BANNER.SUCCEEDED.UPDATE_TEAM" }));
			})
		);
	} catch (error) {
		dispatch(
			formWatcherFailed(() => {
				dispatch(banner.showFailedMessage({ message: "BANNER.FAILED.UPDATE_TEAM" }));
			})
		);
	}
};

export const clearTeam = () => (dispatch) => {
	dispatch({ type: actionTypes.CLEAR_TEAM });
};

const setTeamSettings =
	({ team, permissions }) =>
	(dispatch) => {
		if (team._id) {
			storageHelpers.saveTeamUuid({ uuid: team._id });
		}
		if (team.slug) {
			storageHelpers.saveTeamSlug(team.slug);
		}

		if (team.permissions) {
			dispatch(setTeamPermissions(team));
		}

		if (permissions) {
			dispatch(setUserPermissions(permissions));
		}

		if (team._id) {
			globalHeaders.updateHeaders({
				organizationId: team._id,
				"organization-slug": team?.slug,
			});
		}
	};
