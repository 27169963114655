import "./style.scss";

import { debounce } from "@/helpers";
import { MessageRouterInput, MessageRouterOutput, trpc } from "@/trpc";
import { IFile, IRecipient, IUser } from "@bothive_core/database";
import { captureException } from "@sentry/react";
import { Form, notification } from "antd";
import axios from "axios";
import { LexicalEditor } from "lexical";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import HtmlEditor from "@/shared/components/editor/htmlEditor";
import { applyStyles } from "@/shared/components/editor/theme";
import { TOnImageUpload } from "@/shared/components/editor/nodes";
import { INSERT_SIGNATURE_COMMAND } from "@/shared/components/editor/plugin";
import { useSetRecoilState } from "recoil";
import folderCountConfig from "../config/folderCount.config.ts";
import useInboxNavigation from "../hooks/useInboxNavigation.hooks";
import { Footer, MailHeader } from "./components";
import { AttachmentFile } from "./components/footer";
import { fileUploadSize, maxAttachmentsSize } from "./config";
import { composeState, fileUploadingState, uploadingProgressState } from "./state";
import { IComposeForm, IMessageState, IOnFileUpload, ReplyType } from "./types";
import { TEMP_ID_PREFIX, formHasErrors, isTempId } from "./utils";

function convertComposerMessageToDbEmailMessage({
	message,
	author,
	fromRecipientAddress,
	attachments,
	isDraft,
}: {
	message: MessageRouterInput["composeDraftEmail"] & { _id: string; conversationId: string };
	author: IUser;
	fromRecipientAddress: string;
	attachments: AttachmentFile[];
	isDraft: boolean;
}): MessageRouterOutput["allByConversationId"][number] {
	const recipients: IRecipient[] = [
		...(message.to?.map<IRecipient>((to) => ({ type: "to", address: to })) || []),
		...(message.cc?.map<IRecipient>((cc) => ({ type: "cc", address: cc })) || []),
		...(message.bcc?.map<IRecipient>((bcc) => ({ type: "bcc", address: bcc })) || []),
		{ type: "from", address: fromRecipientAddress },
	];

	const remappedMessage: MessageRouterOutput["allByConversationId"][number] = {
		_id: message._id,
		type: "email",
		isDraft,
		conversationId: message.conversationId,
		snippet: message.payload.content,
		hasFailed: false,
		payload: message.payload,
		recipients,
		authorData: {
			type: "user",
			author: { ...author, displayName: author.username },
		},
		// @ts-ignore
		attachments: attachments,
		inReplyTo: message.inReplyTo,
	};

	return remappedMessage;
}

interface IComposeController {
	initialValue?: IMessageState;
	replyType?: ReplyType;
	attachments?: AttachmentFile[];
	onClose?: () => void;
}

function ComposeController({ initialValue, attachments, replyType, onClose }: IComposeController) {
	const INPUT_DEBOUNCE = 1_000;

	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const checkedForDuplicates = useRef(false);
	const editorRef = useRef<LexicalEditor>();
	const [form] = Form.useForm<IComposeForm>();
	const {
		currentStatus,
		currentLabel,
		currentFolder,
		currentConversation,
		setNextConversation,
		getSearchParameters,
	} = useInboxNavigation();

	const { user, organizationId } = useSelector((state) => ({
		user: state.profile.account.user,
		organizationId: state.content?.team?.team?._id,
	}));
	const [_messageId, setMessageId] = useState<string>(
		initialValue?._id || `${TEMP_ID_PREFIX}_messageId_${Date.now()}`
	);
	const [conversationId, setConversationId] = useState<string>(
		initialValue?.conversationId || `${TEMP_ID_PREFIX}_conversationId_${Date.now()}`
	);
	const isComposedDraftInCurrentConversationMessages = currentConversation === conversationId;
	const [_attachments, setAttachments] = useState(attachments || []);
	const setComposeDraft = useSetRecoilState(composeState);
	const setFileUploading = useSetRecoilState(fileUploadingState);
	const setUploadingProgress = useSetRecoilState(uploadingProgressState);

	const selectedChannelId = Form.useWatch("from", form);
	const totalAttachmentsSize = useMemo(
		() => _attachments.reduce((prev, attachment) => prev + (attachment.file?.size || 0), 0),
		[_attachments]
	);
	const messageIdRef = useRef<string>(_messageId); // Hack to access message Id from inside editor

	messageIdRef.current = _messageId;

	// Queries
	const channelsQuery = trpc.channel.getInboxChannels.useQuery(
		{ type: "email", limit: 0 },
		{ refetchOnWindowFocus: false, staleTime: Infinity }
	);

	// Mutations
	const createDraftMutation = trpc.inbox.message.composeDraftEmail.useMutation({
		onSuccess: (data) => {
			if (data._id) {
				if (isComposedDraftInCurrentConversationMessages) {
					// Replace optimistic message with new database message
					trpcUtils.inbox.message.allByConversationId.setData(
						{
							conversationId: currentConversation,
						},
						(prevData) => {
							if (!prevData) return;

							const clonedPrevData = structuredClone(prevData);
							const indexOptimisticMessage = prevData.findIndex((message) => message._id === _messageId);

							return [
								...clonedPrevData.slice(0, indexOptimisticMessage),
								data,
								...clonedPrevData.slice(indexOptimisticMessage + 1),
							];
						}
					);
				}

				setMessageId(data._id);
				setConversationId(String(data.conversationId));
				setComposeDraft(
					(current) => current && { ...current, _id: data._id, conversationId: String(data.conversationId) }
				);
			}
		},
		onMutate: (variables) => {
			// Prevent overwrites of our optimistic update
			trpcUtils.inbox.inbox.getInboxStatsFixedFolders.cancel();
			trpcUtils.inbox.message.allByConversationId.cancel();
			trpcUtils.inbox.conversation.allFromFolder.cancel();

			setComposeDraft((current) => current && { ...current, _id: _messageId, conversationId });

			trpcUtils.inbox.inbox.getInboxStatsFixedFolders.setData(
				{ folders: folderCountConfig.foldersWithCount },
				(prevData) => {
					if (!prevData) return;

					const clonedPrevData = structuredClone(prevData);

					if (
						isComposedDraftInCurrentConversationMessages &&
						trpcUtils.inbox.message.allByConversationId
							.getData({ conversationId })
							?.find((message) => message.type === "email" && message.isDraft)
					) {
						// Draft counts are already up to date
						return clonedPrevData;
					}

					clonedPrevData["drafts"] += 1;

					return clonedPrevData;
				}
			);

			if (isComposedDraftInCurrentConversationMessages) {
				trpcUtils.inbox.message.allByConversationId.setData(
					{
						conversationId: currentConversation,
					},
					(prevData) => {
						if (!prevData) return;

						const clonedPrevData = structuredClone(prevData);

						const newDraft = convertComposerMessageToDbEmailMessage({
							message: { ...variables, _id: _messageId, conversationId },
							author: user,
							fromRecipientAddress:
								channelsQuery.data?.data?.find((channel) => channel._id === variables.sendFrom)
									?.uniqueIdentifier || "",
							attachments: _attachments,
							isDraft: true,
						});

						clonedPrevData.push(newDraft);
						return clonedPrevData;
					}
				);
			}

			// Update conversation participants so layout of conversation list items are up to date
			trpcUtils.inbox.conversation.allFromFolder.setInfiniteData(
				{
					folder: currentFolder,
					status: currentStatus,
					inboxLabelId: currentLabel,
					...getSearchParameters(),
				},
				(prevInfiniteData) => {
					if (!prevInfiniteData) return;

					const clonedInfiniteData = structuredClone(prevInfiniteData);

					pageLoop: for (let pageIndex = 0; pageIndex < prevInfiniteData.pages.length; pageIndex++) {
						for (
							let conversationIndex = 0;
							conversationIndex < prevInfiniteData.pages[pageIndex].data.length;
							conversationIndex++
						) {
							const conversation = prevInfiniteData.pages[pageIndex].data[conversationIndex];

							if (conversation._id !== conversationId) continue;

							clonedInfiniteData.pages[pageIndex].data[conversationIndex].participants =
								clonedInfiniteData.pages[pageIndex].data[conversationIndex].participants.map(
									(participant) => {
										if (participant.userId !== user._id) return participant;

										return {
											...participant,
											labels: [...participant.labels, "DRAFT"],
										};
									}
								);

							break pageLoop;
						}
					}

					return clonedInfiniteData;
				}
			);
		},
		onError: () => {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "conversation.compose.draft.create.failed",
					defaultMessage: "Failed to create draft. Try edit again to trigger a save.",
					description:
						"Error when draft does not get created while typing in the draft composer. Only by typing you can trigger a save in the background",
				}),
				intl,
			});

			trpcUtils.inbox.inbox.getInboxStatsFixedFolders.invalidate();
			trpcUtils.inbox.inbox.getInboxStatsLabels.invalidate();
		},
	});

	const updateDraftMutation = trpc.inbox.message.updateDraftEmail.useMutation({
		onSuccess: (data, variables) => {
			// Replace optimistic message with new database message
			if (isComposedDraftInCurrentConversationMessages) {
				trpcUtils.inbox.message.allByConversationId.setData(
					{
						conversationId: currentConversation,
					},
					(prevData) => {
						if (!prevData) return;

						const clonedPrevData = structuredClone(prevData);
						const indexOptimisticMessage = prevData.findIndex((message) => message._id === _messageId);

						return [
							...clonedPrevData.slice(0, indexOptimisticMessage),
							data,
							...clonedPrevData.slice(indexOptimisticMessage + 1),
						];
					}
				);
			}

			if (variables.updateAndSend) return onClose && onClose();
		},
		onMutate: (variables) => {
			if (!variables.updateAndSend) return;

			// Prevent overwrites of our optimistic update
			trpcUtils.inbox.inbox.getInboxStatsFixedFolders.cancel({ folders: folderCountConfig.foldersWithCount });
			trpcUtils.inbox.message.allByConversationId.cancel();
			trpcUtils.inbox.conversation.allFromFolder.cancel();

			const messagesOfCurrentConversation = trpcUtils.inbox.message.allByConversationId.getData({
				conversationId,
			});
			const draftWasOnlyDraftInConversation =
				(messagesOfCurrentConversation?.filter((message) => message.type === "email" && message.isDraft)
					.length || 0) === 1;
			const draftWasOnlyFailedInConversation =
				(messagesOfCurrentConversation?.filter((message) => message.type === "email" && message.hasFailed)
					.length || 0) === 1;

			// When this is a first message of a conversation messages of current conversation will be undefined
			if (draftWasOnlyDraftInConversation || typeof messagesOfCurrentConversation === "undefined") {
				if (isComposedDraftInCurrentConversationMessages) {
					trpcUtils.inbox.conversation.allFromFolder.setInfiniteData(
						{
							folder: currentFolder,
							status: currentStatus,
							inboxLabelId: currentLabel,
							...getSearchParameters(),
						},
						(prevInfiniteData) => {
							if (!prevInfiniteData) return;

							const clonedInfiniteData = structuredClone(prevInfiniteData);

							if (
								(currentFolder === "drafts" || currentFolder === "outbox") &&
								draftWasOnlyDraftInConversation
							) {
								for (let i = 0; i < prevInfiniteData.pages.length; i++) {
									clonedInfiniteData.pages[i] = {
										...prevInfiniteData.pages[i],
										total: prevInfiniteData.pages[i].total - 1,
										data: prevInfiniteData.pages[i].data.filter(
											(item) => item._id !== currentConversation
										),
									};
								}
								setNextConversation(clonedInfiniteData.pages.flatMap((page) => page.data));
								return clonedInfiniteData;
							}

							pageLoop: for (let pageIndex = 0; pageIndex < prevInfiniteData.pages.length; pageIndex++) {
								for (
									let conversationIndex = 0;
									conversationIndex < prevInfiniteData.pages[pageIndex].data.length;
									conversationIndex++
								) {
									const conversation = prevInfiniteData.pages[pageIndex].data[conversationIndex];

									if (conversation._id !== currentConversation) continue;

									clonedInfiniteData.pages[pageIndex].data[conversationIndex].draftMessage =
										undefined;
									clonedInfiniteData.pages[pageIndex].data[conversationIndex].participants =
										clonedInfiniteData.pages[pageIndex].data[conversationIndex].participants.map(
											(participant) => {
												if (participant.userId !== user._id) return participant;

												const labelFilters = [
													"DRAFT",
													draftWasOnlyFailedInConversation && "OUTBOX",
												].filter(Boolean);

												return {
													...participant,
													labels: participant.labels.filter(
														(label) => !labelFilters.includes(label)
													),
												};
											}
										);

									break pageLoop;
								}
							}

							return clonedInfiniteData;
						}
					);

					trpcUtils.inbox.message.allByConversationId.setData(
						{ conversationId: currentConversation },
						(prevData) => {
							if (!prevData) return;

							return structuredClone(prevData).map((message) => {
								if (message._id !== _messageId) return message;

								return { ...message, isDraft: false, hasFailed: false };
							});
						}
					);
				}

				trpcUtils.inbox.inbox.getInboxStatsFixedFolders.setData(
					{ folders: folderCountConfig.foldersWithCount },
					(prevData) => {
						if (!prevData) return;

						const clonedPrevData = structuredClone(prevData);

						const prevDraftCount = clonedPrevData["drafts"];
						const prevOutboxCount = clonedPrevData["outbox"];

						clonedPrevData["drafts"] = Math.max(prevDraftCount - 1, 0);

						if (draftWasOnlyFailedInConversation) {
							clonedPrevData["outbox"] = Math.max(prevOutboxCount - 1, 0);
						}

						return clonedPrevData;
					}
				);
			}

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "conversation.compose.draft.sent.succeeded",
					defaultMessage: "Message sent.",
				}),
				intl,
			});

			onClose && onClose();
		},
		onError: (_, variables) => {
			if (!variables.updateAndSend) {
				ShowFailedBanner({
					title: intl.formatMessage({
						id: "conversation.compose.draft.update.failed",
						defaultMessage: "Failed to update draft.",
					}),
					intl,
				});

				return;
			}

			ShowFailedBanner({
				title: intl.formatMessage({
					id: "conversation.compose.draft.send.failed",
					defaultMessage: "Failed to send draft.",
				}),
				intl,
			});

			trpcUtils.inbox.message.allByConversationId.invalidate({ conversationId: currentConversation });
			trpcUtils.inbox.inbox.getInboxStatsFixedFolders.invalidate();
			trpcUtils.inbox.inbox.getInboxStatsLabels.invalidate();
		},
	});

	const deleteDraftMutation = trpc.inbox.message.deleteDraftMessage.useMutation({
		onMutate: () => {
			// Prevent overwrites of our optimistic update
			trpcUtils.inbox.inbox.getInboxStatsFixedFolders.cancel();
			trpcUtils.inbox.message.allByConversationId.cancel();
			trpcUtils.inbox.conversation.allFromFolder.cancel();

			const messagesOfCurrentConversation = trpcUtils.inbox.message.allByConversationId.getData({
				conversationId,
			});
			const conversationsFromCurrentFolder = trpcUtils.inbox.conversation.allFromFolder.getInfiniteData({
				folder: currentFolder,
				status: currentStatus,
				inboxLabelId: currentLabel,
				...getSearchParameters(),
			});
			const draftWasOnlyDraftInConversation =
				(messagesOfCurrentConversation?.filter((message) => message.type === "email" && message.isDraft)
					.length || 0) === 1;
			const draftWasOnlyFailedInConversation =
				(messagesOfCurrentConversation?.filter((message) => message.type === "email" && message.hasFailed)
					.length || 0) === 1;

			// When this is a first message of a conversation messages of current conversation will be undefined
			if (draftWasOnlyDraftInConversation || typeof messagesOfCurrentConversation === "undefined") {
				trpcUtils.inbox.inbox.getInboxStatsFixedFolders.setData(
					{ folders: folderCountConfig.foldersWithCount },
					(prevData) => {
						if (!prevData) return;

						const clonedPrevData = structuredClone(prevData);

						const prevDraftCount = clonedPrevData["drafts"];
						const prevOutboxCount = clonedPrevData["outbox"];

						clonedPrevData["drafts"] = Math.max(prevDraftCount - 1, 0);

						if (draftWasOnlyFailedInConversation) {
							clonedPrevData["outbox"] = Math.max(prevOutboxCount - 1, 0);
						}

						return clonedPrevData;
					}
				);
			}

			if (!isComposedDraftInCurrentConversationMessages) return onClose && onClose();

			if (draftWasOnlyDraftInConversation) {
				trpcUtils.inbox.conversation.allFromFolder.setInfiniteData(
					{
						folder: currentFolder,
						status: currentStatus,
						inboxLabelId: currentLabel,
						...getSearchParameters(),
					},
					(prevInfiniteData) => {
						if (!prevInfiniteData) return;

						const clonedInfiniteData = structuredClone(prevInfiniteData);

						if (currentFolder === "drafts" || currentFolder === "outbox") {
							for (let i = 0; i < prevInfiniteData.pages.length; i++) {
								clonedInfiniteData.pages[i] = {
									...prevInfiniteData.pages[i],
									total: prevInfiniteData.pages[i].total - 1,
									data: prevInfiniteData.pages[i].data.filter(
										(item) => item._id !== currentConversation
									),
								};
							}

							return clonedInfiniteData;
						}

						pageLoop: for (let pageIndex = 0; pageIndex < prevInfiniteData.pages.length; pageIndex++) {
							for (
								let conversationIndex = 0;
								conversationIndex < prevInfiniteData.pages[pageIndex].data.length;
								conversationIndex++
							) {
								const conversation = prevInfiniteData.pages[pageIndex].data[conversationIndex];

								if (conversation._id !== currentConversation) continue;

								clonedInfiniteData.pages[pageIndex].data[conversationIndex].draftMessage = undefined;
								clonedInfiniteData.pages[pageIndex].data[conversationIndex].participants =
									clonedInfiniteData.pages[pageIndex].data[conversationIndex].participants.map(
										(participant) => {
											if (participant.userId !== user._id) return participant;

											const labelFilters = [
												"DRAFT",
												draftWasOnlyFailedInConversation && "OUTBOX",
											].filter(Boolean);

											return {
												...participant,
												labels: participant.labels.filter(
													(label) => !labelFilters.includes(label)
												),
											};
										}
									);

								break pageLoop;
							}
						}

						return clonedInfiniteData;
					}
				);
			}

			if (draftWasOnlyDraftInConversation && (currentFolder === "drafts" || currentFolder === "outbox")) {
				setNextConversation(conversationsFromCurrentFolder?.pages?.flatMap((page) => page.data) || []);
			}

			if (
				((currentFolder === "drafts" || currentFolder === "outbox") && !draftWasOnlyDraftInConversation) ||
				(currentFolder !== "drafts" && currentFolder !== "outbox")
			) {
				trpcUtils.inbox.message.allByConversationId.setData(
					{ conversationId: currentConversation },
					(prevData) => {
						if (!prevData) return;

						return structuredClone(prevData).filter((_message) => _message._id !== _messageId);
					}
				);
			}

			onClose && onClose();
		},
		onError: () => {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "conversation.compose.draft.discard.failed",
					defaultMessage: "Failed to discard draft.",
				}),
				intl,
			});

			trpcUtils.inbox.message.allByConversationId.invalidate({ conversationId: currentConversation });
			trpcUtils.inbox.conversation.allFromFolder.invalidate();
			trpcUtils.inbox.inbox.getInboxStatsFixedFolders.invalidate();
			trpcUtils.inbox.inbox.getInboxStatsLabels.invalidate();
		},
	});

	const updateDraft = ({
		messageId,
		values,
		updateAndSend = false,
	}: {
		messageId: string;
		values: IComposeForm;
		updateAndSend?: boolean;
	}) => {
		if (!values.to?.length && !values.subject && values.content === "") return;

		return updateDraftMutation.mutateAsync({
			messageId,
			...formatDraftInputs({
				...values,
				inReplyTo: initialValue?.inReplyTo,
				attachments: _attachments,
				conversationId: initialValue?.conversationId,
				replyTo: initialValue?.replyTo,
			}),
			updateAndSend,
		});
	};

	/**
	 * Duplicates a given array of attachments so we can use the duplicated ones in our new draft message.
	 * Only needed for reply drafts with attachments.
	 */
	const duplicateAttachments = async ({ attachments }: { attachments: AttachmentFile[] }) => {
		try {
			// CREATE DRAFT MESSAGE IF IT DOES NOT ALREADY EXISTS
			if (!form.getFieldValue("from")) {
				throw new Error("Could not upload files: SendFrom field is missing.");
			}

			const newDraft = await createDraftMutation.mutateAsync({
				...formatDraftInputs({
					...form.getFieldsValue(),
					...((replyType === "reply" || replyType === "reply_all") && {
						inReplyTo: initialValue?.inReplyTo,
						replyTo: initialValue?.replyTo,
						conversationId: initialValue?.conversationId,
					}),
				}),
			});

			if (!newDraft._id) throw new Error("Could not upload files: MessageId is missing.");

			const newMessageId = newDraft._id as string;

			// LET THE FILE SERVICE CREATE NEW FILES FROM THE ORIGINAL ONES
			const fileRelations: { newUrl: string; originalUrl: string }[] = [];
			const duplicatedAttachments: AttachmentFile[] = [];

			await Promise.all(
				attachments.map(async (attachment) => {
					try {
						// Inline image need to be re-injected this causes the form to re-render and makes the cursor jump
						if (attachment.isInline) return;
						if (!attachment.file) {
							throw new Error(`Attachment with fileId ${attachment.fileId} is missing a file`);
						}

						const duplicatedFile = await axios.post<{ data: IFile }>(
							`${process.env.REACT_APP_FILE_SERVER_HOST}/v1/attachment/duplicate/${attachment.fileId}`,
							{
								newMessageId,
								conversationId: newDraft.conversationId,
								originalMessageId: attachment.file.messageId,
							}
						);

						if (duplicatedFile.status !== 201) {
							throw new Error(`File could not be duplicated: ${attachment.fileId}`);
						}

						fileRelations.push({ newUrl: duplicatedFile.data.data.url, originalUrl: attachment.file.url });
						duplicatedAttachments.push({
							fileId: duplicatedFile.data.data._id,
							isInline: attachment.isInline,
							cid: attachment.cid,
							file: duplicatedFile.data.data,
						});
					} catch (error) {
						console.error(error);
						captureException(error);
					}
				})
			);

			setMessageId(newMessageId);
			setAttachments(duplicatedAttachments);

			// This code is used to re-inject the inline attachments
			// if (duplicatedAttachments.filter((attachment) => attachment.isInline).length) {
			// 	let newContent = form.getFieldValue("content");

			// 	for (const relation of fileRelations) {
			// 		if (!relation) continue;

			// 		const regex = new RegExp(relation.originalUrl.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"), "g");
			// 		newContent = newContent.replace(regex, relation.newUrl);
			// 	}

			// 		form.setFieldValue("content", newContent);
			// }
		} catch (error: any) {
			console.error(error);
			captureException(error);
		}
	};

	const handleSubmit = async () => {
		if (isTempId(_messageId)) return;

		const values = form.getFieldsValue();

		values.content = applyStyles.applyHtmlStyle({ content: values.content });

		await updateDraft({ messageId: _messageId, values, updateAndSend: true });
	};

	const handleDiscardDraft = () => {
		if (isTempId(_messageId)) {
			onClose && onClose();

			return;
		}

		deleteDraftMutation.mutate({ messageId: _messageId });
	};

	const handleFormChange = async (changedValues: Partial<IComposeForm>, values: IComposeForm) => {
		if (!changedValues) return;
		// Prevent cursor pointer change in lexical to trigger form change
		if (changedValues.content && changedValues.content === initialValue?.content) return;

		debounce(() => {
			if (formHasErrors(form)) return;

			if (isTempId(_messageId) && !createDraftMutation.isLoading) {
				if (!values.to?.length && !values.subject && values.content === "") return;

				return createDraftMutation.mutate(
					formatDraftInputs({
						...values,
						inReplyTo: initialValue?.inReplyTo,
						attachments: _attachments,
						replyTo: initialValue?.replyTo,
						conversationId: initialValue?.conversationId,
					})
				);
			}

			if (isTempId(_messageId)) return;

			updateDraft({ messageId: _messageId, values });
		}, INPUT_DEBOUNCE);
	};

	function handleInsertSignature() {
		if (!editorRef.current) return;

		editorRef.current.dispatchCommand(INSERT_SIGNATURE_COMMAND, { channelId: selectedChannelId });
	}

	const handleInlineImageUpload: TOnImageUpload = async ({ data, name }) => {
		const res: Response = await fetch(data);
		const blob: Blob = await res.blob();

		const file = new File([blob], name, { type: "image/png" });

		const result = await uploadFile({ files: [file], isInline: true });

		return { file: result?.[0] };
	};

	const handleFileUpload = async ({ file }: { file: File }) => {
		try {
			setFileUploading(true);

			await uploadFile({ files: [file], onUploadProgress: setUploadingProgress });
		} catch (error) {
			console.error("File upload failed", error);
		}

		setFileUploading(false);
	};
	const handleInlineImageDelete = (fileId: string) => {
		axios.delete(`${process.env.REACT_APP_FILE_SERVER_HOST}/v1/file/${fileId}`).finally();
		setAttachments((prevAttachments) => prevAttachments.filter((attachment) => attachment.fileId !== fileId));
	};

	const uploadFile = async ({ files, onUploadProgress, isInline = false }: IOnFileUpload) => {
		let fileMessageId = messageIdRef.current;
		if (isTempId(fileMessageId)) {
			notification.error({
				message: intl.formatMessage({
					id: "inbox.compose.attachments.upload_error.no_draft_yet",
					defaultMessage: "Upload failed, try again when draft update succeeded.",
					description:
						"Explanation about attachment upload failed when user tries to upload attachments while draft is not created yet.",
				}),
				placement: "bottomRight",
			});

			throw new Error("Message does not exists for file upload");
		}

		try {
			//#region VALIDATION STEPS
			if (files[0].size > fileUploadSize) {
				notification.error({
					message: intl.formatMessage({
						id: "inbox.compose.attachments.upload_error.file_size",
						defaultMessage: "Upload failed. Attachment can't be larger than 20MB",
						description: "Error a single attachments file cannot exceed 20MB",
					}),
					placement: "bottomRight",
				});
				return { error: true };
			}

			const totalSize = totalAttachmentsSize + files[0].size;

			if (totalSize > maxAttachmentsSize) {
				notification.error({
					message: intl.formatMessage({
						id: "inbox.compose.attachments.upload_error.attachment_size_limit",
						defaultMessage: "Upload failed. You can only upload 20MB of attachments.",
						description: "Error the sum of all attachments file size exceed 20MB",
					}),
					placement: "bottomRight",
				});
				return;
			}
			//#endregion

			const sendFrom = form.getFieldValue("from");

			if (!sendFrom) throw new Error("Could not upload files: SendFrom field is missing.");

			// Upload the files to the file service
			const createdFiles = await Promise.all(
				[...files].map(async (file) => {
					try {
						const body = new FormData();

						body.append("file", file);
						body.append("isInline", `${isInline}`);
						body.append("messageId", fileMessageId as string);

						if (file.size > fileUploadSize) {
							notification.error({
								message: intl.formatMessage({
									id: "inbox.compose.attachments.upload_error.file_size_error",
									defaultMessage: "Cannot upload attachments bigger than 25MB.",
								}),
								placement: "bottomRight",
							});
							return;
						}

						const uploadResult = await axios.post<{ data: IFile }>(
							`${process.env.REACT_APP_FILE_SERVER_HOST}/v1/attachment`,
							body,
							{
								headers: { organizationId },
								onUploadProgress: (data) => {
									if (!onUploadProgress) return;

									onUploadProgress(Math.round((100 * data.loaded) / (data.total || 1)) - 33);
								},
							}
						);

						if (uploadResult.status !== 201) throw new Error(`File not created: ${file.name}`);

						return uploadResult.data.data;
					} catch (error) {
						notification.error({
							message: intl.formatMessage({
								id: "inbox.compose.attachments.upload_error.unknown_error",
								defaultMessage: `Something went wrong while uploading file with name ${file.name}.`,
							}),
							placement: "bottomRight",
						});
					}
				})
			);

			// Show the user the feedback of upload files
			setAttachments((prevAttachments) => {
				const updatedAttachments = [
					...prevAttachments,
					...createdFiles
						.filter(Boolean)
						.map((file) => ({ file, cid: file._id, isInline, fileId: file._id })),
				];

				// Link the created files on the server to the message attachments
				updateDraftMutation.mutate({
					messageId: fileMessageId as string,
					attachments: updatedAttachments,
					sendFrom,
				});

				return updatedAttachments;
			});
			setMessageId(fileMessageId);

			return createdFiles;
		} catch (error: any) {
			console.error(error);
			notification.error({
				message: intl.formatMessage({
					id: "inbox.compose.attachments.upload_error.general_error",
					defaultMessage: "Failed to upload attachment. Please try again later",
					description: "Server responded with a generic error",
				}),
				placement: "bottomRight",
			});
		}
	};

	const calculatedInitialValue = useMemo(() => {
		if (!initialValue?.from) return { ...(initialValue || {}), from: channelsQuery.data?.data[0]?._id };

		return initialValue;
	}, [initialValue, channelsQuery.data?.data]);

	useEffect(() => {
		// Prevent duplicating attachments multiple times if it's called short after each other
		if (checkedForDuplicates.current) return;
		// If a new reply draft is initiated on a message that has inline attachments => duplicate those attachments so the new draft has it's own attachments
		// NOTE: Atm only supported for inline attachments
		if (
			replyType &&
			["reply", "reply_all"].includes(replyType) &&
			initialValue?.inReplyTo &&
			isTempId(_messageId) &&
			attachments?.filter((attachments) => attachments.isInline).length
		) {
			checkedForDuplicates.current = true;
			duplicateAttachments({ attachments: attachments.filter((attachments) => attachments.isInline) });
		}

		if (replyType === "forward" && isTempId(_messageId) && attachments?.length) {
			checkedForDuplicates.current = true;
			duplicateAttachments({ attachments });
		}
	}, [initialValue?.inReplyTo, replyType, editorRef.current]);

	return (
		<Form
			form={form}
			name="basic"
			autoComplete="off"
			requiredMark={false}
			onFinish={handleSubmit}
			onValuesChange={handleFormChange}
			className="inbox-composer-controller"
			initialValues={calculatedInitialValue}
		>
			<MailHeader form={form} channels={channelsQuery.data?.data || []} />
			<div className="inbox-composer-content">
				<Form.Item name="content" noStyle>
					<HtmlEditor
						tools={["signature"]}
						editorRef={editorRef}
						channelId={selectedChannelId}
						onFileUpload={handleFileUpload}
						onImageUpload={handleInlineImageUpload}
						onImageDelete={handleInlineImageDelete}
					/>
				</Form.Item>
			</div>
			<Footer
				messageId={_messageId}
				attachments={_attachments}
				setAttachments={setAttachments}
				onFileUpload={uploadFile}
				onDiscardDraft={handleDiscardDraft}
				sendFrom={form.getFieldValue("from")}
				onInsertSignature={handleInsertSignature}
				isSending={Boolean(updateDraftMutation.isLoading && updateDraftMutation.variables?.updateAndSend)}
				isCreating={createDraftMutation.isLoading}
				isUpdating={updateDraftMutation.isLoading}
				isDeleting={deleteDraftMutation.isLoading}
			/>
		</Form>
	);
}

export default memo(ComposeController);

type FormatDraftInputsArgs = IComposeForm & {
	attachments?: AttachmentFile[];
	inReplyTo?: string;
} & Pick<IMessageState, "replyTo" | "conversationId">;

function formatDraftInputs(values: FormatDraftInputsArgs) {
	const formattedResult: MessageRouterInput["composeDraftEmail"] = {
		to: values.to,
		cc: values.cc?.length ? values.cc : undefined,
		bcc: values.bcc?.length ? values.bcc : undefined,
		payload: {
			template: "html",
			content: values.content || "",
		},
		sendFrom: values.from,
		subject: values.subject,
		...(values.attachments?.length && { attachments: values.attachments }),
		...(values.inReplyTo && { inReplyTo: values.inReplyTo }),
		...(values?.replyTo && { replyTo: values.replyTo }),
		...(values?.conversationId && { conversationId: `${values.conversationId}` }),
		...(values?.attachments && { attachments: values.attachments }),
	};

	return formattedResult;
}
