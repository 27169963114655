import { keyMappingConfig } from "../../config";

export function createKeyHash(event: KeyboardEvent) {
	const list: string[] = [];

	event.metaKey && list.push("cmd");
	event.ctrlKey && list.push("ctrl");
	event.altKey && list.push("alt");
	event.shiftKey && list.push("shift");
	list.push(event.key.toLowerCase());

	return list.join("+").toLocaleLowerCase();
}

export function enterOrSpacePressed(event: KeyboardEvent): Boolean {
	return event.keyCode === 13 || event.keyCode === 32;
}
export function enterOrTabPressed(event: KeyboardEvent): Boolean {
	return event.keyCode === 13 || event.keyCode === 9;
}
export function deletePressed(event: KeyboardEvent): Boolean {
	return event.keyCode === 8;
}
export function tabPressed(event: KeyboardEvent): Boolean {
	return event.keyCode === 9;
}
export function escapePressed(event: KeyboardEvent): Boolean {
	return event.keyCode === 27;
}
export function enterPressed(event: KeyboardEvent): Boolean {
	return event.keyCode === 13;
}
export function shiftEnterPressed(event: KeyboardEvent): Boolean {
	return event.shiftKey && event.keyCode === 13;
}
export function leftArrowPress(event: KeyboardEvent): Boolean {
	return event.keyCode === 37;
}
export function upArrowPress(event: KeyboardEvent): Boolean {
	return event.keyCode === 38;
}
export function rightArrowPress(event: KeyboardEvent): Boolean {
	return event.keyCode === 39;
}
export function downArrowPress(event: KeyboardEvent): Boolean {
	return event.keyCode === 40;
}
export function arrowPress(event: KeyboardEvent): Boolean {
	return [37, 38, 39, 40].includes(event.keyCode);
}
export function saveIsPressed(event: KeyboardEvent): Boolean {
	return (event.ctrlKey || event.metaKey) && event.keyCode === 83;
}
export function arrayContainsKeyCode({ event, array }): Boolean {
	return array.find((item) => item === event.keyCode);
}
export function isMetaPressed(event: KeyboardEvent): Boolean {
	return event.ctrlKey || event.metaKey;
}

export function isInsideEditor({ event }): Boolean {
	// if event path is 4 then it's triggered inside the body else it's mostly inside a input field
	return (
		event.target instanceof HTMLTextAreaElement ||
		event.target instanceof HTMLInputElement ||
		event.target.contentEditable === "true"
	);
}

interface IMapShortcutKeysInbox {
	event: KeyboardEvent;
	mapping: Record<string, keyMappingConfig.keyMappingEvents>;
}
export function mapShortcutKeysInbox({ event, mapping }: IMapShortcutKeysInbox): string {
	const keyHash = createKeyHash(event);

	return mapping?.[keyHash];
}

interface IHandleShortcutPress {
	event: KeyboardEvent;
	functionMap: Record<keyMappingConfig.keyMappingEvents, () => void>;
}
export function handleShortcutPress({ event, functionMap }: IHandleShortcutPress) {
	if (isInsideEditor({ event }) || isMetaPressed(event)) return;

	const keyEvent = mapShortcutKeysInbox({ event, mapping: keyMappingConfig.defaultMapping });

	if (!functionMap[keyEvent]) return;

	event.preventDefault();
	functionMap[keyEvent]();
}
