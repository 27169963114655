import axios from "axios";
import { atomFamily, selector } from "recoil";

import { apiConfig } from "@/flows/common/config";
import { selectedFlowIdState } from "@/flows/common/state";

import config from "./config";

export const automationSelector = selector({
	key: "frReportAutomation",
	get: async ({ get }) => {
		const flowId = get(selectedFlowIdState);
		const url = `${apiConfig.baseUrl}/api/v1/rule/trigger/${config.ruleTriggerType}?projectId=${flowId}`;
		const { data } = await axios.get(url);

		if (!data.success) {
			throw new Error();
		}

		return data;
	},
});

export const automationStatusSelector = atomFamily({
	key: "frAutomationStatusSelector",
	default: [],
});
