import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "./style.scss";

interface IMemberList {
	value: string[];
	onChange: (value: string[]) => void;
}

export default function MemberList({ value, onChange }: IMemberList) {
	const handleDelete = (email: string) => {
		onChange(value.filter((item) => item !== email));
	};

	return (
		<div className="setup_invite_members-member_list">
			{value.map((email) => (
				<div key={email} className="setup_invite_members-member_list-item">
					<p>{email}</p>
					<Button type="text" danger icon={<DeleteOutlined />} onClick={() => handleDelete(email)} />
				</div>
			))}
		</div>
	);
}
