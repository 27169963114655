import { DeleteOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Form, Button, Select, TimePicker, FormListFieldData, FormInstance } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface IFieldComponent {
	form: FormInstance<any>;
	field: FormListFieldData;
	remove: (index: number | number[]) => void;
	openingList: any;
	options: {
		value: string;
		label: {
			id: string;
			defaultMessage: string;
		};
	}[];
	allowEditing: boolean;
}

export default function FieldComponent({ form, field, remove, openingList, options, allowEditing }: IFieldComponent) {
	const intl = useIntl();
	const [closed, setClosed] = useState(false);
	const [firstPicker, setFirstPicker] = useState(false);
	const [secondPicker, setSecondPicker] = useState(false);

	const time1 = Form.useWatch(["openingHours", field.name, "time"], form);
	const time2 = Form.useWatch(["openingHours", field.name, "time2"], form);

	const disabledTimeFirstPicker = () => {
		const disabledHours = () => {
			const hours: number[] = [];

			if (!time2) return hours;

			const startHour = time2?.[0]?.hour();
			const endHour = time2?.[1]?.hour();

			for (let i = startHour; i <= endHour; i++) {
				hours.push(i);
			}

			return hours;
		};

		const disabledMinutes = (selectedHour: number) => {
			const minutes: number[] = [];

			if (!time2) return minutes;

			if (selectedHour === time2[0]?.hour()) {
				for (let i = 0; i < time2[0]?.minute(); i++) {
					minutes.push(i);
				}
			}

			if (selectedHour === time2[1]?.hour()) {
				for (let i = time2[1]?.minute(); i < 60; i++) {
					minutes.push(i);
				}
			}

			return minutes;
		};

		return {
			disabledHours,
			disabledMinutes,
		};
	};

	const disabledTimeSecondPicker = () => {
		const disabledHours = () => {
			const hours: number[] = [];

			if (!time1) return hours;

			const startHour = time1[0]?.hour();
			const endHour = time1[1]?.hour();

			if (time1[1].minute() < 45) {
				for (let i = startHour; i < endHour; i++) {
					hours.push(i);
				}

				return hours;
			}

			for (let i = startHour; i <= endHour; i++) {
				hours.push(i);
			}

			return hours;
		};
		const disabledMinutes = (selectedHour: number) => {
			const minutes: number[] = [];

			if (time1 && selectedHour === time1[1]?.hour()) {
				for (let i = 0; i <= time1[1]?.minute(); i++) {
					minutes.push(i);
				}
			}

			return minutes;
		};

		return {
			disabledHours,
			disabledMinutes,
		};
	};

	const deleteHandler = () => {
		const newValue = openingList[field.name];

		if (secondPicker) {
			delete newValue.time2;
			setSecondPicker(false);
			form.setFieldValue(["openingHours", field.name], newValue);
			return;
		}

		if (firstPicker) {
			delete newValue.time;
			setClosed(true);
			setFirstPicker(false);
			form.setFieldValue(["openingHours", field.name], newValue);
			return;
		}

		remove(field.name);
	};

	const addHandler = () => {
		if (!closed && !secondPicker) return setSecondPicker(true);

		setClosed(false);
		setFirstPicker(true);
	};

	useEffect(() => {
		if (!openingList) return;
		if (!openingList[field.name]?.time || openingList[field.name]?.time?.length === 0) return setClosed(true);

		closed && setClosed(false);
		!firstPicker && setFirstPicker(true);

		if (openingList[field.name]?.time2?.length > 0 && !secondPicker) setSecondPicker(true);
	}, [field]);

	if (openingList?.length) {
		const openingListDayType = openingList
			.filter(Boolean)
			.map(({ dayType }) => (typeof dayType === "object" ? dayType.value : dayType));
		// remove current value from list so we see it in our dropdown options
		openingListDayType.splice(field.name, 1);

		options = options.filter(({ value }) => !openingListDayType.includes(value));
	}

	return (
		<div
			style={{
				display: "flex",
				width: "100%",
				justifyContent: "flex-start",
				gap: "16px",
				alignItems: "center",
				marginBottom: "1em",
			}}
		>
			<Form.Item
				{...field}
				name={[field.name, "dayType"]}
				key={field.name + "dayType"}
				initialValue={options[0]?.value}
				style={{ height: "100%", marginBottom: "unset" }}
			>
				<Select
					style={{ width: 130, textAlign: "left" }}
					options={options.map((option) => ({
						value: option.value,
						label: intl.formatMessage({
							id: option.label.id,
							defaultMessage: option.label.defaultMessage,
						}),
					}))}
					getPopupContainer={(trigger) => trigger.parentNode}
				/>
			</Form.Item>
			{/* {closed && (
				<span key={field.name + "closed"}>
					<FormattedMessage id="component.openings_hours.closed" defaultMessage="Closed" />
				</span>
			)} */}
			{!closed && (
				<>
					<span style={{ height: "100%" }}>
						<FormattedMessage id="component.openings_hours.from" defaultMessage="From" />
					</span>
					{(!closed || firstPicker) && (
						<Form.Item
							{...field}
							name={[field.name, "time"]}
							key={field.name + "time"}
							style={{
								display: "flex",
								alignItems: "center",
								height: "100%",
								marginBottom: "unset",
							}}
						>
							<TimePicker.RangePicker
								changeOnBlur
								format="HH:mm"
								minuteStep={15}
								style={{ marginLeft: "1%", marginRight: "1%" }}
								allowClear={false}
								disabledTime={disabledTimeFirstPicker}
							/>
						</Form.Item>
					)}
					{secondPicker && (
						<>
							<span key={field.name + "and"} style={{ height: "100%" }}>
								<FormattedMessage id="component.openings_hours.and" defaultMessage="and" />
							</span>
							<Form.Item
								{...field}
								name={[field.name, "time2"]}
								key={field.name + "time2"}
								style={{
									display: "flex",
									alignItems: "center",
									height: "100%",
									marginBottom: "unset",
								}}
							>
								<TimePicker.RangePicker
									changeOnBlur
									format="HH:mm"
									minuteStep={15}
									disabledTime={disabledTimeSecondPicker}
									allowClear={false}
								/>
							</Form.Item>
						</>
					)}
				</>
			)}
			<div style={{ display: "flex", marginLeft: "-1.5em" }}>
				{!secondPicker && !closed && allowEditing && (
					<Button type="text" onClick={addHandler} style={{ marginLeft: "1em" }} icon={<PlusOutlined />} />
				)}
				{closed && !firstPicker && allowEditing && (
					<Button type="text" onClick={addHandler} style={{ marginLeft: "1em" }} icon={<EditOutlined />} />
				)}
				{allowEditing && (
					<Button
						type="text"
						onClick={deleteHandler}
						style={{ marginLeft: "1em" }}
						icon={<DeleteOutlined />}
					/>
				)}
			</div>
		</div>
	);
}
