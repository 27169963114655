import { Avatar, Flex, Tag } from "antd";
import { Component } from "@/components/atoms";
import { useCallback } from "react";
import { keyEvents } from "@/helpers";
import type { IContact } from "@bothive_core/database";

import { tagConfig } from "./config";
import { FormattedMessage } from "react-intl";

export default function ContactLinkCard({
	_id,
	onClick,
	type,
	linkedContact,
	maritalStatus,
}: {
	_id: string;
	onClick: (id: string) => void;
	type?: string;
	maritalStatus?: string;
	linkedContact: IContact;
}) {
	const linkedContactTypeTag = type && tagConfig[type];
	const linkedContactMaritalStatusTag = maritalStatus && tagConfig[maritalStatus];

	const handleClick = useCallback(() => onClick && onClick(_id), [_id, onClick]);
	const handleKeyUp = useCallback(
		(event: any) => {
			if (keyEvents.enterOrSpacePressed(event)) {
				handleClick();
			}
		},
		[handleClick]
	);

	return (
		<article
			role="button" //eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
			onClick={handleClick}
			onKeyUp={handleKeyUp}
			className={`m-card m-card--location`}
		>
			{linkedContact.avatar && (
				<Avatar src={linkedContact.avatar} shape="square" size={64} style={{ minWidth: "64px" }} />
			)}
			<div className="card--content">
				<Flex gap={1}>
					{linkedContactTypeTag && (
						<Tag color={linkedContactTypeTag.color} className="t-gap--bottom-xs">
							<FormattedMessage id={linkedContactTypeTag.label} />
						</Tag>
					)}
					{linkedContactMaritalStatusTag && (
						<Tag color={linkedContactMaritalStatusTag.color} className="t-gap--bottom-xs">
							<FormattedMessage id={linkedContactMaritalStatusTag.label} />
						</Tag>
					)}
				</Flex>

				<p className="semi-bold"> {linkedContact?.fullName} </p>
			</div>
			<Component.AIcon type="edit" className="card--edit-icon" />
		</article>
	);
}
