import "./style.scss";
import {
	ArrowsAltOutlined,
	DeleteOutlined,
	EditOutlined,
	EnterOutlined,
	MoreOutlined,
	ShrinkOutlined,
	WarningOutlined,
} from "@ant-design/icons";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { Alert, Button, Divider, Dropdown, Tooltip, Typography } from "antd";
import dompurify from "dompurify";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import { forwardRef, useEffect, useMemo, useRef, useState, MouseEvent, KeyboardEvent } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { EmailMessage as EmailMessageType, IMessage } from "@bothive_core/database";

import { keyMappingConfig } from "@/config";
import { keyEventsHelpers } from "@/shared/helpers";
import { ERecipientTypes } from "@/modules/inbox/types/message.types";
import { fileHelpers } from "@/shared/helpers";
import { MessageRouterOutput, trpc } from "../../../../../../../trpc";
import ShadowView from "../../../../../components/ShadowViewer";
import { containsThread, removeThreadingEmail } from "./utils";
import { dateHelpers } from "@/shared/helpers";
import AttachmentCard from "../../AttachmentCard";
import { ReplyType } from "@/modules/inbox/Composer/types";
import {
	composeWindowReplyTypeState,
	continueDraft,
	forwardMail,
	composeState,
	openState,
	replyMail,
} from "@/modules/inbox/Composer/state";
import ReplyAllOutlined from "@/assets/images/icon/ReplyAllOutlined";
import { ReplyAction } from "../../ReplyFooter/config";
import ReplyFooter from "../../ReplyFooter";
import { timeoutHelpers } from "@bothive/helpers";

type recipientType = "to" | "cc" | "bcc";

interface MessageBaseProps {
	message: MessageRouterOutput["allByConversationId"][number] & IMessage<EmailMessageType>;
	messages: MessageRouterOutput["allByConversationId"];
	isCollapsed?: boolean;
	showSubject?: boolean;
	articleProps?: HTMLMotionProps<"article">;
}

export interface MessageWithActionsProps extends MessageBaseProps {
	hasActions: true;
	messageListRef: { current: any };
	isLastEmailMessage: boolean;
	onDeleteDraft: (messageId: string) => void;
}

export interface MessageWithoutActionsProps extends MessageBaseProps {
	hasActions: false;
}

type MessageProps = MessageWithActionsProps | MessageWithoutActionsProps;

const EmailMessage = forwardRef((props: MessageProps, ref: any) => {
	const intl = useIntl();
	const setForwardMessage = useSetRecoilState(forwardMail);
	const setReplyMessage = useSetRecoilState(replyMail);
	const setContinueDraft = useSetRecoilState(continueDraft);

	const isComposeWindowOpen = useRecoilValue(openState);
	const composeWindowReplyType = useRecoilValue(composeWindowReplyTypeState);
	const composeDraftMessage = useRecoilValue(composeState);

	// Queries
	const channelsQuery = trpc.channel.getInboxChannels.useQuery(
		{ type: "email", limit: 0 },
		{ refetchOnWindowFocus: false, staleTime: Infinity }
	);

	const attachmentsRef = useRef<HTMLDivElement | null>(null);
	const user = useSelector((state: any) => state.profile.account.user);
	const [showThread, setShowThread] = useState<boolean>(false);
	const [collapsed, setCollapsed] = useState<boolean>(Boolean(props.isCollapsed));
	const hasThread = useMemo(
		() => containsThread({ html: props.message.payload.content }),
		[props.message.payload.content]
	);
	const nonInlineAttachments = useMemo(
		() => props.message.attachments?.filter((attachment) => !attachment.isInline),
		[props.message.attachments]
	);
	const existingReplyDraft = props.messages.find(
		(_message) =>
			_message.type === "email" && _message.isDraft && _message.inReplyTo === props.message.uniqueMessageId
	);

	const recipientsObj = useMemo(
		() => ({
			from: props.message.recipients?.find((recipient) => recipient.type === ERecipientTypes.from),
			to: props.message.recipients
				?.filter((recipient) => recipient.type === ERecipientTypes.to)
				.map((recipient) => recipient.address),
			cc: props.message.recipients
				?.filter((recipient) => recipient.type === ERecipientTypes.cc)
				.map((recipient) => recipient.address),
			bcc: props.message.recipients
				?.filter((recipient) => recipient.type === ERecipientTypes.bcc)
				.map((recipient) => recipient.address),
			replyTo: props.message.recipients?.find((recipient) => recipient.type === ERecipientTypes.replyTo),
		}),
		[props.message.recipients]
	);

	function dumpurifyHook(node) {
		//Open all links in new tab
		if (node.nodeName && node.nodeName === "A") {
			node.setAttribute("target", "_blank");
			node.setAttribute("rel", "noopener noreferrer");
		}
	}

	const config = { ADD_ATTR: ["target", "rel"] }; // add this to allow 'target' and 'rel' attributes
	const htmlObject = showThread
		? props.message.payload.content
		: removeThreadingEmail({ html: props.message.payload.content });

	dompurify.addHook("beforeSanitizeElements", dumpurifyHook);
	const cleanHTML = dompurify.sanitize(htmlObject, config);
	dompurify.removeHook("beforeSanitizeElements");

	const [showAttachmentNavHelper, setShowAttachmentNavHelper] = useState(false);

	const calcShowAttachmentNavHelper = () => {
		if (!attachmentsRef.current) return;

		const docViewTop = window.scrollY || window.pageYOffset;
		const docViewBottom = docViewTop + window.innerHeight;

		const elemRect = attachmentsRef.current?.getBoundingClientRect();
		const elemTop = elemRect.top + docViewTop;
		const elemBottom = elemTop + elemRect.height;

		setShowAttachmentNavHelper(!Boolean(elemBottom <= docViewBottom && elemTop >= docViewTop));
	};

	const handleDownloadAllAttachments = () => {
		if (!nonInlineAttachments?.length) return;

		nonInlineAttachments.forEach(async (attachment) => {
			if (!attachment.file) return;

			await timeoutHelpers.wait(100);
			fileHelpers.downloadFileFromUrl({ url: attachment.file.url, fileName: attachment.file.name });
		});
	};

	function handleActionsClick({ key, domEvent }: { key: ReplyType; domEvent?: KeyboardEvent | MouseEvent }) {
		domEvent?.stopPropagation();

		if (key === "forward") {
			setForwardMessage(structuredClone(props.message));
			return;
		}

		if (existingReplyDraft) {
			setContinueDraft(structuredClone(existingReplyDraft) as IMessage<EmailMessageType>);
			return;
		}

		setReplyMessage({ message: structuredClone(props.message), channels: channelsQuery.data, action: key, user });
	}

	const hasReplyDraftOpen = Boolean(
		composeWindowReplyType &&
			isComposeWindowOpen &&
			composeDraftMessage?.inReplyTo &&
			props.message.uniqueMessageId === composeDraftMessage?.inReplyTo
	);

	const MORE_ACTION_ITEMS = [
		{
			key: "reply",
			label: intl.formatMessage({
				id: "inbox.message.actions.dropdown.reply.title",
				defaultMessage: "Reply",
			}),
			disabled: hasReplyDraftOpen,
			icon: <EnterOutlined style={{ transform: "scaleY(-1)" }} />,
		},
		{
			key: "reply_all",
			label: intl.formatMessage({
				id: "inbox.message.actions.dropdown.reply_all.title",
				defaultMessage: "Reply all",
			}),
			disabled: hasReplyDraftOpen,
			icon: <ReplyAllOutlined />,
		},
		{
			key: "forward",
			label: intl.formatMessage({
				id: "inbox.message.actions.dropdown.forward.title",
				defaultMessage: "Forward",
			}),
			icon: <EnterOutlined style={{ transform: "rotate(180deg)" }} />,
		},
	];

	function handleKeyUp(event) {
		if (!props.hasActions || !props.isLastEmailMessage) return;

		const functionMap: Record<keyMappingConfig.keyMappingEvents, () => void> = {
			"conversation.reply": () => handleActionsClick({ key: "reply" }),
			"conversation.reply_all": () => handleActionsClick({ key: "reply_all" }),
			"conversation.forward": () => handleActionsClick({ key: "forward" }),
		};
		keyEventsHelpers.handleShortcutPress({ event, functionMap });
	}

	//Keyboard shortcuts
	useEffect(
		() => {
			if (props.hasActions && props.isLastEmailMessage) {
				// Should only execute when state.selectedId is changed else selected id won't be updated in handleKeyUp
				window.addEventListener("keyup", handleKeyUp);

				return () => {
					window.removeEventListener("keyup", handleKeyUp);
				};
			}
		},
		props.hasActions ? [props.isLastEmailMessage] : []
	);

	useEffect(() => {
		if (!props.hasActions || !props.messageListRef.current) return;

		props.messageListRef.current?.addEventListener("scroll", calcShowAttachmentNavHelper);

		return () => props.messageListRef.current?.removeEventListener("scroll", calcShowAttachmentNavHelper);
	}, []);

	function handleEditDraft() {
		setContinueDraft(props.message);
	}

	function handleDeleteDraft() {
		if (!props.hasActions || !props.onDeleteDraft || !props.message._id) return;

		props.onDeleteDraft(props.message._id);
	}

	return (
		<motion.article className="msi-message-container" {...props.articleProps}>
			{props.message._id === composeDraftMessage?._id && (
				<span className="msi-message-overlay">
					<Alert
						showIcon
						type="warning"
						message={
							<FormattedMessage
								id="inbox.message.draft.composer.open.overlay"
								defaultMessage="Draft is being edited in composer window"
								description="Overlay that's show above the current draft message that is getting edited in the composer window"
							/>
						}
					></Alert>
				</span>
			)}
			<article
				ref={ref}
				tabIndex={0}
				role="button"
				className="msi-message"
				data-is-draft={props.message.isDraft}
				onKeyDown={(event) => {
					if (event.key === "Enter") setCollapsed((prev) => !prev);
				}}
			>
				{props.message.isDraft && !props.message.hasFailed && (
					<section className="msi-message-draft__title">
						<FormattedMessage id="inbox.message.header.draft.title" defaultMessage="Draft" />
					</section>
				)}
				{props.message.hasFailed && (
					<section className="msi-message-failed__title">
						<WarningOutlined />
						<FormattedMessage
							id="inbox.message.header.failed.title"
							defaultMessage="Failed to send e-mail"
						/>
					</section>
				)}
				<header
					data-content-is-collapsed={collapsed}
					onClick={() => setCollapsed((prev) => !prev)}
					className="msi-header"
				>
					{(props.message.isDraft || props.message.hasFailed) &&
						props.hasActions &&
						props.message._id !== composeDraftMessage?._id && (
							<div onClick={(e) => e.stopPropagation()} className="msi-message-draft__actions">
								<Tooltip
									placement="bottom"
									title={intl.formatMessage({
										id: "inbox.message.header.draft.btn.edit",
										defaultMessage: "Edit draft",
									})}
								>
									<Button
										size="small"
										type="text"
										onClick={() => handleEditDraft()}
										icon={<EditOutlined style={{ color: "var(--primary)" }} />}
									></Button>
								</Tooltip>
								<Divider style={{ background: "var(--primary)" }} type="vertical" />
								<Tooltip
									placement="bottom"
									title={intl.formatMessage({
										id: "inbox.message.header.draft.btn.discard",
										defaultMessage: "Discard draft",
									})}
								>
									<Button
										type="text"
										size="small"
										onClick={() => handleDeleteDraft()}
										icon={<DeleteOutlined />}
									></Button>
								</Tooltip>
							</div>
						)}

					<div className="msi-header-first_line">
						<div className="msi-header-recipients__item" onClick={(event) => event.stopPropagation()}>
							<Typography.Text className="msi-header-recipients__item-title">
								{props.message.authorData?.author.displayName || (
									<FormattedMessage id="inbox.message.header.from.label" defaultMessage="From:" />
								)}
							</Typography.Text>
							<Typography.Paragraph
								className="msi-header-recipients__item-detail"
								ellipsis={{ rows: 1, tooltip: true, expandable: false }}
							>
								{props.message.authorData?.author.displayName
									? `<${recipientsObj.from?.address}>`
									: recipientsObj.from?.address}
							</Typography.Paragraph>
						</div>
						<div className="msi-header-detail">
							<p className="msi-header-detail__date">
								{dateHelpers.getRelativeDate({
									date: props.message.createdAt,
									alwaysIncludeTime: true,
									locale: intl.locale,
								})}
							</p>
							<Tooltip
								placement="topRight"
								title={
									collapsed
										? intl.formatMessage({
												id: "inbox.message.header.collapse_button.tooltip.expanded",
												defaultMessage: "Expanded email",
										  })
										: intl.formatMessage({
												id: "inbox.message.header.collapse_button.tooltip.collapse",
												defaultMessage: "Collapse email",
										  })
								}
							>
								<Button
									type="text"
									size="small"
									className="msi-header-detail__collapse_controller"
									icon={
										collapsed ? (
											<ArrowsAltOutlined style={{ color: "var(--accent)" }} />
										) : (
											<ShrinkOutlined style={{ color: "var(--primary)" }} />
										)
									}
									onClick={(event) => {
										event.stopPropagation();
										setCollapsed(!collapsed);
									}}
									aria-label={
										collapsed
											? intl.formatMessage({
													id: "inbox.message.header.collapse_button.aria_label.expanded",
													defaultMessage: "Expanded email",
											  })
											: intl.formatMessage({
													id: "inbox.message.header.collapse_button.aria_label.collapse",
													defaultMessage: "Collapse email",
											  })
									}
								/>
							</Tooltip>
							{!props.message.isDraft && props.hasActions && (
								<>
									<Tooltip
										title={intl.formatMessage({
											id: "inbox.message.actions.dropdown.reply.title",
											defaultMessage: "Reply",
										})}
									>
										<Button
											onClick={(event) => handleActionsClick({ key: "reply", domEvent: event })}
											disabled={hasReplyDraftOpen}
											type="text"
											size="small"
											icon={<EnterOutlined style={{ transform: "scaleY(-1)" }} />}
										/>
									</Tooltip>
									<Dropdown
										trigger={["click"]}
										menu={{
											items: MORE_ACTION_ITEMS,
											onClick: ({ key, domEvent }) =>
												handleActionsClick({ key: key as ReplyAction, domEvent }),
										}}
									>
										<Button
											onClick={(event) => event.stopPropagation()}
											type="text"
											size="small"
											icon={<MoreOutlined style={{ color: "var(--primary)" }} />}
										/>
									</Dropdown>
								</>
							)}
						</div>
					</div>
					{!!recipientsObj.to && <EmailRecipientsList type="to" recipients={recipientsObj.to} />}
					{!!recipientsObj.cc?.length && <EmailRecipientsList type="cc" recipients={recipientsObj.cc} />}
					{!!recipientsObj.bcc?.length && <EmailRecipientsList type="bcc" recipients={recipientsObj.bcc} />}
					{!!recipientsObj.replyTo && (
						<EmailRecipient type="replyTo" recipient={recipientsObj.replyTo.address} />
					)}
					{!!props.message.subject && props.showSubject && (
						<div className="msi-header-recipients__item">
							<p className="msi-header-recipients__item-subtitle">
								<FormattedMessage id="inbox.message.header.subject" defaultMessage="Subject:" />
							</p>
							<p className="msi-header-recipients__item-detail">{props.message.subject}</p>
						</div>
					)}
					{/* @ts-ignore */}
					<AnimatePresence>
						{!!nonInlineAttachments?.length && !collapsed && showAttachmentNavHelper && (
							<motion.button
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								onClick={(event) => {
									event.stopPropagation();

									attachmentsRef.current?.scrollIntoView({
										behavior: "smooth",
										block: "nearest",
										inline: "nearest",
									});
								}}
								className="msi-header-attachment-btn"
							>
								<PaperClipIcon className="btn-icon" />
								<FormattedMessage
									id="inbox.message.header.go_to_attachments"
									defaultMessage="Go to {count, plural, one {# attachment} other {# attachments}}"
									values={{ count: nonInlineAttachments?.length }}
								/>
							</motion.button>
						)}
					</AnimatePresence>
				</header>
				<main data-content-is-collapsed={collapsed} className="msi-content">
					{props.message.payload.template === "html" && (
						<ShadowView>
							<div
								style={{ width: "100%", maxWidth: "100%", overflowY: "auto" }}
								dangerouslySetInnerHTML={{ __html: cleanHTML || props.message.snippet }}
							/>
						</ShadowView>
					)}
					{props.message.payload.template !== "html" && (
						<div
							className="msi-content-text_content"
							dangerouslySetInnerHTML={{ __html: cleanHTML || props.message.snippet }}
						/>
					)}
					{hasThread && (
						<Button
							type="text"
							size="small"
							className="msi-content-thread_button"
							onClick={() => setShowThread(!showThread)}
						>
							{!showThread && (
								<FormattedMessage
									id="inbox.message.content.show_thread"
									defaultMessage="Show message thread"
									description="Button underneath email with thread to show to full email conversation"
								/>
							)}
							{showThread && (
								<FormattedMessage
									id="inbox.message.content.hide_thread"
									defaultMessage="Hide message thread"
									description="Button underneath email with thread to hide to full email thread and only show the email"
								/>
							)}
						</Button>
					)}
				</main>
				{!!nonInlineAttachments?.length && (
					<section data-content-is-collapsed={collapsed} ref={attachmentsRef} className="msi-attachments">
						<div className="msi-attachments__header">
							<h1 className="header-xs">
								<FormattedMessage
									id="inbox.message.footer.attachments.title"
									defaultMessage="{count, plural, one {# attachment} other {# attachments}}"
									values={{ count: nonInlineAttachments.length }}
								/>
							</h1>
							{nonInlineAttachments?.length > 1 && (
								<>
									<span className="t-gap--left-xs">-</span>
									<Button
										type="text"
										size="small"
										className="msi-content-thread_button"
										onClick={handleDownloadAllAttachments}
									>
										<FormattedMessage
											id="inbox.message.footer.attachments.download_all_button.label"
											defaultMessage="Download all attachments"
											description="Button when more then 1 attachment is included in the mail to download all attachments at once"
										/>
									</Button>
								</>
							)}
						</div>
						<div className="msi-attachments__list">
							{nonInlineAttachments.map((attachment) => (
								<AttachmentCard
									key={attachment.fileId}
									fileId={attachment.fileId}
									{...attachment.file}
								/>
							))}
						</div>
					</section>
				)}
			</article>
			{props.hasActions &&
				props.isLastEmailMessage &&
				!hasReplyDraftOpen &&
				!existingReplyDraft &&
				!props.message.isDraft && (
					<ReplyFooter
						style={{ marginBottom: "var(--spacer-md)" }}
						onClickReplyOption={(key) => handleActionsClick({ key })}
					/>
				)}
		</motion.article>
	);
});

export default EmailMessage;

function EmailRecipientsList({ type, recipients }: { type: recipientType; recipients: string[] }) {
	const label = defineMessages<recipientType>({
		to: { id: "inbox.message.header.to.label", defaultMessage: "To:" },
		cc: { id: "inbox.message.header.cc.label", defaultMessage: "Cc:" },
		bcc: { id: "inbox.message.header.bcc.label", defaultMessage: "Bcc:" },
	});

	return (
		<div className="msi-header-recipients__item" onClick={(event) => event.stopPropagation()}>
			<p className="msi-header-recipients__item-subtitle">
				<FormattedMessage {...label[type]} />
			</p>
			<Typography.Paragraph
				className="msi-header-recipients__item-detail"
				ellipsis={{ rows: 1, tooltip: true, expandable: false }}
			>
				{recipients.join(", ")}
			</Typography.Paragraph>
		</div>
	);
}

function EmailRecipient({ type, recipient }: { type: "replyTo"; recipient: string }) {
	const label = defineMessages<"replyTo">({
		replyTo: { id: "inbox.message.header.replyTo.label", defaultMessage: "Reply to:" },
	});

	return (
		<div className="msi-header-recipients__item" onClick={(event) => event.stopPropagation()}>
			<p className="msi-header-recipients__item-subtitle">
				<FormattedMessage {...label[type]} />
			</p>
			<Typography.Paragraph
				className="msi-header-recipients__item-detail"
				ellipsis={{ rows: 1, tooltip: true, expandable: false }}
			>
				{recipient}
			</Typography.Paragraph>
		</div>
	);
}
