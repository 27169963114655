import React, { useState } from "react";

import { keyEvents } from "../../../helpers";
import { Component } from "../../atoms";

export default function MBannerError({ children, className, onClose }) {
	const [collapsed, setCollapsed] = useState(true);

	const handleKeyDown = (event) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			handleClick();
		}
	};

	const handleClick = () => {
		if (!collapsed && onClose) {
			onClose();
		}

		setCollapsed(!collapsed);
	};

	return (
		<section // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
			role="dialog"
			onClick={handleClick}
			onKeyDown={handleKeyDown}
			className={`m-banner--page-error ${collapsed && "collapsed"} ${className}`}
		>
			<Component.AIcon type="warning" className="white banner-error-icon" />
			<div className="banner-error-content">{children}</div>
		</section>
	);
}

MBannerError.defaultProps = {
	children: undefined,
	className: "",
	onClose: undefined,
};
