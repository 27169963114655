import { combineReducers } from "redux";

import { actions as membersActions, reducers as membersReducer } from "./members";

const actions = {
	members: membersActions,
};

const reducers = combineReducers({
	members: membersReducer,
});

export { actions, reducers };
