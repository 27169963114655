import { Skeleton } from "antd";
import lodashSet from "lodash.set";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { flowState } from "@/flows";
import NotFoundState from "@/flows/common/components/not_found";
import { typesConfig } from "@/flows/common/config";
import { compareReportProgress } from "@/flows/common/helper";
import {
	editedReportState,
	isEditingReportState,
	isInvalidReportState,
	reportState,
	reportViewState,
} from "@/flows/report_detail/state";
import CSVViewer from "@/flows/report_detail/viewer/csv";
import helpers from "@/flows/report_detail/viewer/csv/helpers";
import EmptyReport from "@/flows/report_detail/viewer/empty_report";
import JsonViewer from "@/flows/report_detail/viewer/json";
import PDFViewer from "@/flows/report_detail/viewer/pdf";
import { generatorHelpers } from "@/helpers";

import style from "./style.module.scss";

export default function ReportDetailViewer({ className }) {
	const intl = useIntl();

	const queryParams = useParams();
	const reportViewType = useRecoilValue(reportViewState);
	const [isEditing, setIsEditing] = useRecoilState(isEditingReportState);
	const setIsInvalid = useSetRecoilState(isInvalidReportState);

	const flowId = queryParams.flowId;
	const reportId = queryParams.reportId;

	const report = useRecoilValueLoadable(reportState({ flowId, reportId }));
	const flow = useRecoilValueLoadable(flowState({ id: flowId }));
	const setEditedReportData = useSetRecoilState(editedReportState);
	const { branding } = useSelector((state) => ({
		branding: state.content.team.team.branding,
		language: state.dashboard.header.lang,
	}));
	const language = "nl-BE"; // TODO default show in DUTCH

	const data =
		{ ...report.contents?.data, createdAt: report.contents?.createdAt, updatedAt: report.contents?.updatedAt } ||
		{};
	const csvExcelData = { ...report.contents?.data } || {};
	const fields = flow.contents?.template?.fields;

	if (
		(fields && fields.length > 0 && reportViewType === typesConfig.reportType.csv) ||
		reportViewType === typesConfig.reportType.excel
	) {
		const fieldKeys = fields?.map((field) => field.key);

		if (fieldKeys) {
			Object.keys(csvExcelData).forEach((key) => {
				if (!fieldKeys.includes(key)) delete csvExcelData[key];
			});
		}
	}

	const handleDataChange = ({ value, key }) => {
		setIsEditing(true);
		setIsInvalid(false);

		if (reportViewType === typesConfig.reportType.json) {
			try {
				setEditedReportData(JSON.parse(value));
			} catch (error) {
				setIsInvalid(true);
			}
		}

		if (reportViewType === typesConfig.reportType.csv || reportViewType === typesConfig.reportType.excel) {
			if (!key) {
				return;
			}

			setEditedReportData((prev) => {
				const mutatedData = JSON.parse(JSON.stringify(prev || report?.contents?.data));

				lodashSet(mutatedData, key, value);

				return lodashSet(mutatedData, key, value);
			});
		}
	}; // todo figure out why since I added this there is an additional cell "undefined" in the csv which also happens in previous (production) version of the app

	if (report.state === "loading" || flow.state === "loading") {
		return <Skeleton active className={style.skeleton_loader} />;
	}

	if (report.state === "hasError" || flow.state === "hasError" || !report.contents) {
		return (
			<NotFoundState
				title={intl.formatMessage({ id: "FLOWS.REPORT_DETAIL.FETCH_FAILED.TITLE" })}
				className={style.report_viewer_fallback}
			/>
		);
	}

	const isReportInProgress = compareReportProgress({
		report: report.contents,
		progress: typesConfig.progressState.inviteSend,
	});

	if (!isReportInProgress) {
		return (
			<EmptyReport
				username={report.contents?.contact?.username || report.contents?.contact?.firstName}
				className={`${style.report_viewer} ${className}`}
			/>
		);
	}

	if (reportViewType === typesConfig.reportType.excel || reportViewType === typesConfig.reportType.csv) {
		const dataFormattedForExcelOrCSV = helpers.formatJsonToCSV({ fields, language, data: csvExcelData });

		return (
			<CSVViewer
				isEditing={isEditing}
				renderId={generatorHelpers.generateUuid()}
				items={dataFormattedForExcelOrCSV}
				className={`${style.report_viewer} ${className}`}
				direction={reportViewType === typesConfig.reportType.csv ? "horizontal" : "vertical"}
				onChange={(value) => handleDataChange({ ...value })}
			/>
		);
	}

	if (reportViewType === typesConfig.reportType.pdf) {
		const pdfPreviewType = flow.contents?.template?.previewTypes.find(
			(item) => item.type === typesConfig.reportType.pdf
		);

		return (
			<PDFViewer
				data={{ ...data, ...branding }}
				templateUrl={pdfPreviewType?.url}
				className={`${style.report_viewer} ${className}`}
			/>
		);
	}

	return (
		<JsonViewer
			data={data}
			className={`${style.report_viewer} ${className}`}
			onChange={(value) => handleDataChange({ value })}
		/>
	);
}

ReportDetailViewer.defaultProps = {
	className: "",
};
