import Empty from "./components/Empty";
import { FormattedMessage, useIntl } from "react-intl";
import { Alert, Button } from "antd";
import ReminderSettings from "./components/ReminderSettings";
import HistoryList from "./components/HistoryList";
import CreateReminderStrategy from "@/flows/flow_detail/reminders/components/CreateReminderStrategy";
import { useState } from "react";
import { FlowRemindersRouterOutput, trpc } from "@/trpc";
import { useParams } from "react-router-dom";

export default function Reminders() {
	const [showReminderStrategyModal, setShowReminderStrategyModal] = useState<boolean>(false);
	const [selectedReminderStrategyData, setSelectedReminderStrategyData] = useState<
		FlowRemindersRouterOutput["getRemindersSetting"] | undefined
	>(undefined);
	const { flowId } = useParams<{ flowId: string }>();
	const { data: reminderSetting, isLoading: isReminderSettingLoading } =
		trpc.flowReminders.getRemindersSetting.useQuery({
			flowId,
		});

	const intl = useIntl();

	if (!reminderSetting && !isReminderSettingLoading) {
		return (
			<>
				{!!showReminderStrategyModal && (
					<CreateReminderStrategy
						onClose={() => {
							setShowReminderStrategyModal(false);
							setSelectedReminderStrategyData(undefined);
						}}
						selectedReminderStrategyData={selectedReminderStrategyData}
					/>
				)}
				<Empty onCreate={() => setShowReminderStrategyModal(true)} />
			</>
		);
	}

	return (
		<>
			{!!showReminderStrategyModal && (
				<CreateReminderStrategy
					onClose={() => {
						setShowReminderStrategyModal(false);
						setSelectedReminderStrategyData(undefined);
					}}
					selectedReminderStrategyData={selectedReminderStrategyData}
				/>
			)}
			<main
				style={{ height: "100%", width: "100%", textAlign: "left", display: "flex", flexDirection: "column" }}
			>
				<section style={{ padding: "2em 2em 0em 2em" }}>
					{reminderSetting?.stoppedAt && (
						<div className="t-gap--bottom">
							<Alert
								type="warning"
								showIcon
								message={intl.formatMessage({
									id: "flow.detail.reminders.banner.stopped.title",
									defaultMessage: "Automatic reminders have been stopped for this flow",
								})}
								action={[
									<Button
										type="text"
										onClick={() => {
											setShowReminderStrategyModal(true);
											setSelectedReminderStrategyData(reminderSetting);
										}}
									>
										<FormattedMessage
											id="flow.detail.reminders.banner.stopped.button.restart.title"
											defaultMessage="Restart reminders"
										/>
									</Button>,
								]}
							/>
						</div>
					)}
					{!reminderSetting?.stoppedAt && (
						<ReminderSettings
							setting={reminderSetting}
							isLoading={isReminderSettingLoading}
							setShowReminderStrategyModal={setShowReminderStrategyModal}
							setSelectedReminderStrategyData={setSelectedReminderStrategyData}
						/>
					)}
				</section>
				<HistoryList />
			</main>
		</>
	);
}
