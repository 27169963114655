import { IReportField } from "@bothive_core/database";

export function getFieldLabels(fields: IReportField[]) {
	return fields
		.reduce<(IReportField & { type: "fileArray" })[]>((prev, curr) => {
			if (curr.type === "fileArray") return [...prev, curr];
			if (curr.fields?.length) return [...prev, getFieldLabels(curr.fields)].flat();

			return prev;
		}, [])
		.flat();
}

type UploadToBucketParams = {
	url: string;
	file: File;
	onProgress: (progress: number) => void;
};

export function uploadToBucket({ url, file, onProgress }: UploadToBucketParams) {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();

		xhr.upload.onprogress = (event) => onProgress((event.loaded / event.total) * 100);
		xhr.upload.onload = async () => {
			await new Promise((done) => setTimeout(done, 750)); // wait until request full completed

			if (xhr.status !== 200) return reject();

			resolve(true);
		};
		xhr.upload.ontimeout = (error) => {
			console.error("Upload timeout error", error);
			reject();
		};
		xhr.upload.onerror = (error) => {
			console.error("Upload error", error);
			reject();
		};
		xhr.open("PUT", url, true);
		xhr.setRequestHeader("Content-Type", file?.type || "application/unknown");
		xhr.send(file);
	});
}
