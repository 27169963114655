import React, { useState } from "react";

import { timingConfig } from "../../../config";
import { debounce } from "../../../helpers";
import Container from "./_atoms.dropdown.container";
import ListItemDefault from "./_atoms.dropdown.list_item_default";
import SearchHeader from "./_atoms.dropdown.search_header";

export default function ADropdownSearchable({
	items,
	exclude,
	persistance,
	iconClass,
	isLoading,
	className,
	onChange,
	onSearch,
	...props
}) {
	let filteredItems = items,
		init = false;
	const valueById = items.find((item) => (item.uuid || item.id || item._id) === props.value)?.value;
	const [_value, setValue] = useState(valueById || props.value);
	const [searchValue, setSearchValue] = useState(undefined);

	if (exclude && exclude.length) {
		filteredItems = filteredItems.filter(
			(item) => !exclude.find((exclude) => exclude === (item.uuid || item.id || item._id))
		);
	}

	if (searchValue) {
		filteredItems = filteredItems.filter((item) => item.value.toLowerCase().includes(searchValue.toLowerCase()));
	}

	if (!init && !isLoading && valueById && valueById !== _value) {
		setValue(valueById);
		init = true;
	}

	const handleChange = (value) => {
		setValue(value);
		setSearchValue(value);

		debounce(() => {
			onSearch && onSearch(value);
		}, timingConfig.keyboardTimeout);
	};

	const handleDropdownChange = (value, type) => {
		onChange(value, type);
		setSearchValue(undefined);
		persistance && setValue(filteredItems.find((item) => (item.uuid || item.id || item._id) === value)?.value);
	};

	return (
		<Container
			{...props}
			className={className}
			items={filteredItems}
			excludeKeyCodes={[32]}
			onChange={handleDropdownChange}
			header={(props) => (
				<SearchHeader {...props} iconClass={iconClass} onChange={handleChange} value={searchValue || _value} />
			)}
			// render={(props) => (
			// 	<ListItemDefault
			// 		{...props}
			// 		isLoading={isLoading}
			// 		iconClass={iconClass}
			// 		onChange={handleChange}
			// 		value={searchValue || _value}
			// 	/>
			// )}
			render={(props) => <ListItemDefault {...props} />}
		/>
	);
}
