import { Divider, Skeleton } from "antd";

export default function GroupSkeleton() {
	return (
		<article
			className="flow-reminder-history__group"
			style={{ padding: "2em", width: "100%", display: "flex", justifyContent: "space-between" }}
		>
			<Skeleton title active paragraph={false} style={{ width: "40ch" }} />
			<div style={{ display: "flex", alignItems: "center" }}>
				<Skeleton title style={{ height: "16px", minWidth: "4em", width: "4em" }} paragraph={false} active />
				<Divider type="vertical" />
				<Skeleton title style={{ height: "16px", minWidth: "4em", width: "4em" }} paragraph={false} active />
			</div>
		</article>
	);
}
