import { fi } from "date-fns/locale";

interface GetRelativeDateArgs {
	date: string | Date;
	alwaysIncludeTime?: boolean;
	locale: string;
}

export function getRelativeDate({ date, alwaysIncludeTime = false, locale = "en" }: GetRelativeDateArgs) {
	if (!date) return "";

	const _date = new Date(date);
	const today = new Date();
	const isToday = _date.toISOString().slice(0, 10) === today.toISOString().slice(0, 10);
	const isWithinAWeek = Math.abs(today.getTime() - _date.getTime()) <= 7 * 24 * 60 * 60 * 1000;

	const options: Intl.DateTimeFormatOptions = {
		...(isToday
			? { timeStyle: "short" }
			: isWithinAWeek
			? { dateStyle: "short", timeStyle: "short" }
			: alwaysIncludeTime
			? { dateStyle: "short", timeStyle: "short" }
			: { dateStyle: "short" }),
	};

	return new Intl.DateTimeFormat(locale, options).format(_date);
}

/**
 * Get the difference in days between 2 given dates.
 */
export function getDifference(date1: string, date2?: string): number {
	const _date2 = date2 ? Date.parse(date2) : Date.now();

	return Math.floor((_date2 - Date.parse(date1)) / 86400000);
}
