import DashboardCard from "@/shared/components/card/dashboard";
import FieldLabel from "@/shared/components/field/default";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Input, Button, Skeleton } from "antd";
import { trpc } from "@/trpc";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import "../style.scss";
import { IOrganizationDocument } from "@bothive_core/database";
import { LeanDocument } from "mongoose";
import { useEffect } from "react";
import { PHONE_REGEX } from "@/helpers/validators.helpers";

export default function CompanySettings({
	allowEditing,
	organization,
	isLoading,
	className,
}: {
	allowEditing: boolean;
	organization: LeanDocument<IOrganizationDocument> | undefined;
	isLoading: boolean;
	className?: string;
}) {
	const [form] = Form.useForm();
	const intl = useIntl();
	const updateOrganizationMutation = trpc.organization.updateOrganization.useMutation();

	useEffect(() => {
		form.setFieldsValue(organization);
	}, [organization]);

	const updateOrganization = (organization) => {
		updateOrganizationMutation.mutate(organization, {
			onSuccess: () => {
				ShowSuccessBanner({
					title: intl.formatMessage({
						id: "organization_settings.general.basic.form.success",
						defaultMessage: "Company settings have been successfully saved.",
					}),
				});
			},
			onError: () => {
				ShowFailedBanner({
					title: intl.formatMessage({
						id: "banner.failed.settings_update",
						defaultMessage: "The changes couldn't be saved. Please try again later",
					}),
				});
			},
		});
	};

	return (
		<DashboardCard
			title={intl.formatMessage({
				id: "organization_settings.general.title",
				defaultMessage: "Company settings",
			})}
			className={className}
		>
			<Skeleton active loading={isLoading} className="t-gap--top">
				<Form
					form={form}
					id="editCompany"
					name="editCompany"
					layout="horizontal"
					className="company_settings-form"
					disabled={!allowEditing}
					onFinish={(values) => {
						updateOrganization({
							name: values.name,
							email: values.email,
							phone: values.phone,
						});
					}}
					initialValues={organization}
					autoComplete="off"
				>
					<FieldLabel
						label={intl.formatMessage({
							id: "organization_settings.general.form.name.label",
							defaultMessage: "Company name",
						})}
					>
						<Form.Item
							name="name"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "organization_settings.general.form.name.error_required",
										defaultMessage: "Company name is required",
									}),
								},
							]}
						>
							<Input />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						label={intl.formatMessage({
							id: "organization_settings.general.form.email.label",
							defaultMessage: "E-mail",
						})}
						help={intl.formatMessage({
							id: "organization_settings.general.form.email.help",
							defaultMessage: "This email address can be used to contact your business",
						})}
					>
						<Form.Item
							name="email"
							className="formItem"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "organization_settings.general.form.email.error_required",
										defaultMessage: "Email is required",
									}),
								},
								{
									type: "email",
									message: intl.formatMessage({
										id: "organization_settings.general.form.email.invalid_error",
										defaultMessage: "Email looks to be incomplete",
									}),
								},
							]}
						>
							<Input />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						label={intl.formatMessage({
							id: "organization_settings.general.form.phone.label",
							defaultMessage: "Phone number",
						})}
						help={intl.formatMessage({
							id: "organization_settings.general.form.phone.help",
							defaultMessage: "This phone number can be used to contact your business",
						})}
						optional
					>
						<Form.Item
							name="phone"
							className="formItem"
							rules={[
								{
									pattern: PHONE_REGEX,
									message: intl.formatMessage({
										id: "organization_settings.general.form.phone.validation",
										defaultMessage: "Please fill in a valid phone number",
										description:
											"This is shown when the user inputs a phone number with characters other than numbers or +",
									}),
								},
							]}
						>
							<Input placeholder="Phone Number" />
						</Form.Item>
					</FieldLabel>
					{allowEditing && (
						<Button
							key="submit"
							type="primary"
							htmlType="submit"
							style={{ display: "flex", width: "fit-content", alignSelf: "flex-end" }}
							form="editCompany"
							loading={updateOrganizationMutation.isLoading}
						>
							<FormattedMessage
								id={intl.formatMessage({
									id: "organization_settings.general.form.save",
									defaultMessage: "Save",
								})}
							/>
						</Button>
					)}
				</Form>
			</Skeleton>
		</DashboardCard>
	);
}
