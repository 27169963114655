import React from "react";
import { Route, Switch } from "react-router-dom";

import { Handler } from "../../components/organisms";
import { routesConfig } from "../../config";
import { Template } from "../../pages";
import { LoginRedirectPage } from "../../pages/redirect";
import dashboardRoutes from "./dashboard.routes";
import profileRoutes from "./profile.routes";

export default () => (
	<Template>
		<Handler.OErrorFallback>
			<Switch>
				<Route path={routesConfig.profile.root} component={profileRoutes} />
				<Route path={routesConfig.dashboard.root} component={dashboardRoutes} />
				<Route render={() => <LoginRedirectPage />} />
			</Switch>
		</Handler.OErrorFallback>
	</Template>
);
