export default {
	HEADER_IS_FETCHING: "HEADER_IS_FETCHING",
	FETCHING_HEADER_FAILED: "FETCHING_HEADER_FAILED",
	FETCHING_HEADER_SUCCEEDED: "FETCHING_HEADER_SUCCEEDED",

	CHECK_FOR_USER_TOKEN: "CHECK_FOR_USER_TOKEN",
	SET_USER_TOKEN: "SET_USER_TOKEN",
	CHECK_FOR_USER_LANGUAGE: "CHECK_FOR_USER_LANGUAGE",
	SET_USER_LANGUAGE: "SET_USER_LANGUAGE",

	INIT_DASHBOARD_SESSION: "INIT_DASHBOARD_SESSION",
};
