import "./style.scss";

import { Collapse as AntCollapse } from "antd";

export { default as Panel } from "./collapse_panel";
export { default as DestructivePanel } from "./destructive_panel";

export default function Collapse({ className = "", ...props }) {
	return <AntCollapse bordered={false} accordion className={`shared-collapse ${className || ""}`} {...props} />;
}
