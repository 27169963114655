function skeletonList({ max, min } = { max: 5, min: 1 }) {
	let list = [];
	const _min = max > min ? min : 1; // if max is less then min value set min to 1 because skeleton list should always have 1 item
	const randomValue = Math.floor(Math.random() * max) + _min;

	for (let id = 0; id < randomValue; id++) {
		list.push({ id, key: id });
	}

	return list;
}

export default {
	skeletonList,
};
