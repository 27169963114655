import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { timingConfig } from "@/config";
import { queryParamsConfig } from "@/flows/common/config";
import { debounceHelpers, objectHelpers, queryStringHelpers } from "@/shared/helpers";

import { useFlowList } from "../hook";
import { flowMeta } from "../state";
import style from "./style.module.scss";

export default function FlowHeader({ onCreateClick }) {
	const history = useHistory();
	const { formatMessage } = useIntl();
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const flowMetaData = useRecoilValue(flowMeta);

	// eslint-disable-next-line no-unused-vars
	const [_, fetchFlows] = useFlowList();
	const [searchFilter, setSearchFilter] = useState("");

	const handleSearch = (search) => {
		debounceHelpers(async () => {
			setSearchFilter(search);
			await fetchFlows({
				limit: 0,
				offset: 0,
				q: search,
			});
		}, timingConfig.debounce);
	};

	const addNewFiltersToUrl = (filter) => {
		let search = "";

		if (!objectHelpers.isEmpty(filter)) {
			search = queryStringHelpers.createQueryString(filter);
		}

		history.push({ search });
	};

	useEffect(() => {
		if (queryParams.get(queryParamsConfig.modal) !== "open") {
			addNewFiltersToUrl({
				[queryParamsConfig.search]: searchFilter,
			});
		}
	}, [searchFilter]);

	useEffect(() => {
		const searchQuery = queryParams.get(queryParamsConfig.search);

		if (searchQuery) {
			handleSearch(searchQuery);
		}
	}, []);

	return (
		<header className={style.flows_header}>
			<div className={style.flows_header_search}>
				<Input
					size="large"
					placeholder={formatMessage({
						id: "FLOWS.OVERVIEW.SEARCH.PLACEHOLDER",
					})}
					defaultValue={queryParams.get(queryParamsConfig.search) || ""}
					onChange={(input) => handleSearch(input.target.value)}
				/>
				<Button type="primary" onClick={onCreateClick} size="large">
					<FormattedMessage id="FLOWS.OVERVIEW.ADD_FLOW.BUTTON" />
				</Button>
			</div>
			<div className={style.flows_header_footer}>
				<p className={style.flows_header_result}>
					<FormattedMessage
						id="FLOWS.OVERVIEW.SEARCH.TOTAL_FOUND"
						values={{
							total: flowMetaData.total || 0,
							totalEnabled: flowMetaData.totalEnabled || 0,
						}}
					/>
				</p>
			</div>
		</header>
	);
}
