import React from "react";
import { FormattedMessage } from "react-intl";

export default function ANotFound({ label, className }) {
	return (
		<section className={`a-fallback ${className}`}>
			<h1 className="fallback--title">
				<FormattedMessage id={label} defaultMessage="label" />
			</h1>
		</section>
	);
}
