import "../style.scss";

import { Form, Input, Button, Modal, Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { MemberRouterInput, trpc } from "@/trpc";
import FieldLabel from "@/shared/components/field/default";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import MemberPermissions from "./memberPermissions";

interface IAddMember {
	allowEditing: boolean;
	showModal: boolean;
	onClose: () => void;
}
export default function AddMember({ allowEditing, showModal, onClose }: IAddMember) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const [form] = Form.useForm();
	const inviteMemberMutation = trpc.member.inviteMember.useMutation();
	const { refetch: refetchMembers } = trpc.member.getMembers.useQuery();

	const errors = {
		BAD_REQUEST: intl.formatMessage({
			id: "SETTINGS.ORGANIZATION.MEMBER.ADD.LIMIT",
			defaultMessage: "Member limit reached - upgrade your plan",
		}),
		CONFLICT: intl.formatMessage({
			id: "SETTINGS.ORGANIZATION.MEMBER.EXISTS",
			defaultMessage: "The member already exists in your organization.",
		}),
	};

	const defaultError = intl.formatMessage({
		id: "SETTINGS.ORGANIZATION.MEMBER.ADD.FAILED",
		defaultMessage: "The member couldn't be invited. Please try again later",
	});

	const createMember = (member: MemberRouterInput["inviteMember"]) => {
		inviteMemberMutation.mutate(member, {
			onSuccess: () => {
				trpcUtils.member.getAll.invalidate();
				refetchMembers();
				onClose();
				ShowSuccessBanner({
					title: intl.formatMessage({
						id: "SETTINGS.ORGANIZATION.MEMBER.ADD.SUCCESS",
						defaultMessage: "The member has been successfully invited.",
					}),
				});
			},
			onError: (error) => {
				const errorMessage = error?.data?.code ? errors[error?.data?.code] : defaultError;

				ShowFailedBanner({
					title: errorMessage,
				});
			},
		});
	};

	return (
		<>
			<Modal
				title={intl.formatMessage({ id: "SETTINGS.MEMBERS.INVITE.TITLE", defaultMessage: "Invite user" })}
				open={showModal}
				destroyOnClose
				centered
				onCancel={() => {
					onClose();
					form.resetFields();
				}}
				width={650}
				footer={
					<Row justify="space-between">
						<Button
							type="text"
							htmlType="button"
							onClick={() => {
								onClose();
							}}
						>
							<FormattedMessage id="settings.members.popup.cancel_button" defaultMessage="Cancel" />
						</Button>
						<Button
							type="primary"
							form="addMember"
							htmlType="submit"
							loading={inviteMemberMutation.isLoading}
						>
							<FormattedMessage id="settings.members.popup.invite_button" defaultMessage="Invite" />
						</Button>
					</Row>
				}
			>
				<Form
					preserve={false}
					id="addMember"
					form={form}
					disabled={!allowEditing}
					onFinish={(fields) =>
						createMember({
							email: fields.email,
							permissions: {
								editFlows: fields.editFlows,
								editIntegrations: fields.editIntegrations,
								editSettings: fields.editSettings,
								manageUsers: fields.manageUsers,
								readAllContactConversations: fields.readAllContactConversations,
								superAdmin: fields.superAdmin,
							},
						})
					}
					initialValues={
						{
							editFlows: false,
							editSettings: false,
							editIntegrations: false,
							manageUsers: false,
							readAllContactConversations: false,
							superAdmin: false,
						} satisfies MemberRouterInput["inviteMember"]["permissions"]
					}
				>
					<FieldLabel
						label="SETTINGS.USERS_POPUP.EMAIL.LABEL"
						help={intl.formatMessage({
							id: "SETTINGS.USERS_POPUP.EMAIL.HELP",
							defaultMessage:
								"Didn't receive an email? View the spam folder of contact our customer service.",
						})}
						defaultLabelMessage="E-mail"
					>
						<Form.Item
							name="email"
							rules={[
								{
									type: "email",
									required: true,
									message: intl.formatMessage({
										id: "SETTINGS.NOTIFICATIONS.BILLING.FORM.EMAIL.EMAIL_ERROR",
										defaultMessage: "Email looks to be incomplete",
									}),
								},
							]}
							style={{ width: "100%" }}
						>
							<Form.Item name="email" style={{ width: "100%" }}>
								<Input style={{ width: "100%" }} />
							</Form.Item>
						</Form.Item>
					</FieldLabel>
					<MemberPermissions form={form} />
				</Form>
			</Modal>
		</>
	);
}
