import { typesConfig } from "@/config";
import { stringHelpers } from "@/helpers";
import { fileHelpers, formatHelpers } from "@/shared/helpers";

function downloadZip({ zip, zipName }) {
	const url = window.URL.createObjectURL(new Blob([zip], { type: "application/zip" }));
	const link = document.createElement("a");

	link.href = url;
	link.setAttribute("download", zipName);
	link.setAttribute("charset", "utf-16");
	document.body.appendChild(link);
	link.click();

	if (document.contains(link)) {
		// don't remove link if it already removed from body
		document.body.removeChild(link);
	}
}

export default {
	downloadZip,
	generateDownload: ({ report, flowName, fileData, reportViewType, headers }) => {
		const username = formatHelpers.createUsername({
			contact: report?.contents?.contact || report?.contents,
			reverse: true,
		});

		const suffix = username || stringHelpers.randomUuid(4);

		let extension = typesConfig.fileExtensions[reportViewType];

		if (headers["content-type"] === "application/zip") {
			fileHelpers.downloadZip({ zip: fileData, zipName: `${flowName}_${suffix.replace(" ", "_")}.zip` });
			return;
		}

		fileHelpers.downloadFile({
			file: fileData, //reportViewType === "pdf" ? Buffer.from(fileData, "base64") : fileData,
			fileName: `${flowName}_${suffix.replace(" ", "_")}.${extension}`,
			type: extension,
		});
	},
};
