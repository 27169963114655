import helper from "@/flows/common/helper";

export function cleanFlowList({ flowId, flows, language }) {
	if (!flows?.length) {
		return [];
	}

	const filteredFlows = flows.filter((flow) => flow._id !== flowId);

	return filteredFlows.sort((flowA, flowB) => {
		const descriptionA = helper.getFlowDescription({ flow: flowA, language: "nl" });
		const descriptionB = helper.getFlowDescription({ flow: flowB, language: "nl" });

		return descriptionA.name.localeCompare(descriptionB.name);
	});
}
