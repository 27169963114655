import { Image } from "../../../components/atoms";
import { useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { Result, Table, Tooltip, Tag as AntDTag, Avatar } from "antd";
import { trpc } from "../../../trpc";
import { websiteIcon } from "../../../assets/images/icon";
import ErrorState from "../../../flows/common/components/error";
import SkeletonTable from "../../../shared/components/table/skeleton";
import ContactOverviewHeader from "./components/Header";
import { useDebounce } from "use-debounce";

import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { routesConfig } from "@/config";
import { actions as dashboardActions } from "../../../store/dashboard";
import Tag from "@/shared/components/antdWrappers/Tag";
import BulkActions from "./BulkActions/BulkActions";
import { useRecoilState } from "recoil";
import { selectedAllArchivedContactsState, selectedArchivedContactsState } from "./state";
import { calculateDeleteDate } from "../utils";
import AvatarGroup from "@/shared/components/antdWrappers/AvatarGroup";

export default function ArchivedContactsOverview() {
	const [sort, setSort] = useState<{ field: string; order: string } | undefined>(undefined);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
	const [search, setSearch] = useState<string | undefined>(undefined);
	const [contactType, setContactType] = useState<string | undefined>(undefined);
	const [labelId, setLabelId] = useState<string | undefined>(undefined);
	const [accountManagerId, setAccountManagerId] = useState<string | undefined>(undefined);
	const [selectedContacts, setSelectedContacts] = useRecoilState(selectedArchivedContactsState);
	const [selectedAll, setSelectedAll] = useRecoilState(selectedAllArchivedContactsState);
	const [showDeleted, setShowDeleted] = useState(false);

	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const team = useSelector(({ content }: { content: { team: any } }) => content.team.team);

	const [debouncedSearch] = useDebounce(search, 500);
	const { data: accountManagers } = trpc.member.getAll.useQuery({ limit: 0 });
	const { isLoading, isError, data } = trpc.contact.getAllContacts.useQuery({
		sort,
		limit: pagination.pageSize,
		offset: (pagination.current - 1) * pagination.pageSize,
		q: debouncedSearch,
		contactType,
		labelId,
		accountManagerId,
		archived: true,
		deleted: showDeleted,
	});

	useEffect(() => {
		setSelectedContacts([]);
		setSelectedAll(false);
		setPagination({ ...pagination, current: 1 });
	}, [debouncedSearch, contactType, labelId, accountManagerId, showDeleted]);

	useEffect(() => {
		const selectedContactsFilteredOnNewData = data?.data?.filter((contact) =>
			selectedContacts.includes(contact._id)
		);
		if (
			selectedContactsFilteredOnNewData &&
			selectedContactsFilteredOnNewData?.length !== selectedContacts.length
		) {
			setSelectedContacts(selectedContactsFilteredOnNewData.map(({ _id }) => _id));
		}

		// If on last page shift to new last page
		if (
			pagination.current - 1 > 0 &&
			data?.data?.length === 0 &&
			Math.ceil((data?.total || 0) / pagination.pageSize) === pagination.current - 1
		) {
			setPagination({
				current: pagination.current - 1,
				pageSize: pagination.pageSize,
			});
		}
	}, [data?.data]);

	const { data: contactLabels } = trpc.contactLabel.all.useQuery({ limit: 0 });

	useEffect(() => {
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					id: "contactsOverview",
					title: "CONTACT.OVERVIEW.PAGE.TITLE",
					tabTitle: "CONTACT.OVERVIEW.PAGE.TITLE",
				},
				intl,
			})
		);
	}, []);

	const handleTableChange = ({ sorter, pagination }) => {
		if (sorter?.columnKey && sorter?.order) {
			setSort({ field: sorter.columnKey, order: sorter.order });
		}
		setPagination({ current: pagination.current, pageSize: pagination.pageSize });
	};

	const handleSearch = (value: string) => {
		if (value === "") {
			setSearch(undefined);
		}

		setSearch(value);
	};

	const handleContactSelect = ({ _id }: { _id: string }) => {
		if (!_id) return;

		setSelectedContacts((prevValues) => {
			if (prevValues.includes(_id)) {
				return prevValues.filter((value) => value !== _id);
			}

			return [...prevValues, _id];
		});
	};

	const handleContactSelectAll = ({ selected, contacts }: { selected: boolean; contacts: { _id: string }[] }) => {
		// remove all shown contacts from selectedContacts but keep the ones from other pages
		if (!selected) {
			setSelectedContacts((prevValues) => {
				return [
					...new Set(
						prevValues.filter(
							(value) => !data?.data?.map((contact: { _id?: string }) => contact?._id).includes(value)
						)
					),
				];
			});

			return;
		}

		// add all shown contacts to selectedContacts
		setSelectedContacts((prevValues) => [
			...new Set([
				...prevValues,
				...contacts.filter((item) => item?._id).map((contact: { _id: string }) => contact?._id),
			]),
		]);
	};

	useEffect(() => {
		if (selectedAll && selectedContacts.length === 1) {
			setSelectedAll(false);
			const pageSelection = data?.data.map((item) => item._id);
			const deselectItemIndex = pageSelection?.indexOf(selectedContacts[selectedContacts.length - 1]);
			if (typeof deselectItemIndex !== "undefined" && deselectItemIndex > -1) {
				pageSelection?.splice(deselectItemIndex, 1);
			}

			setSelectedContacts(pageSelection || []);
		}
	}, [selectedContacts, data?.total]);

	return (
		<>
			<div className="t-section t-section--detail-view t-section--contact-overview">
				<ContactOverviewHeader
					contactAmount={data?.total || 0}
					handleSearch={handleSearch}
					setContactType={(contactType) => setContactType(contactType)}
					setLabelId={(labelId) => setLabelId(labelId)}
					labelId={labelId || ""}
					setAccountManagerId={(accountManager) => setAccountManagerId(accountManager)}
					setShowDeleted={setShowDeleted}
					showDeleted={showDeleted}
				/>
				{(!!selectedContacts.length || selectedAll) && (
					<BulkActions
						contactQueryData={data}
						filters={{
							q: debouncedSearch,
							contactType,
							labelId,
							accountManagerId,
						}}
						includesDeleted={showDeleted}
					/>
				)}
				{isError ? (
					<ErrorState title={intl.formatMessage({ id: "CONTACTS.OVERVIEW.ERROR.TITLE" })} />
				) : (
					<SkeletonTable
						rowKey="_id"
						className="t-gap--top fro-table"
						rowClassName="t-section--contact-overview--table-row"
						dataSource={data?.data}
						isLoading={isLoading}
						locale={{
							emptyText: (
								<Result
									title={intl.formatMessage({
										id: "CONTACTS.OVERVIEW.ARCHIVED.EMPTY.TITLE",
										defaultMessage: "No archived contacts found",
									})}
								/>
							),
						}}
						onChange={(pagination: any, _: any, sorter: any) => handleTableChange({ pagination, sorter })}
						onRow={(contact: { _id: string }) => ({
							onClick: (event: any) => {
								// If click happens in a column with these classes it should be ignored to prevent accidental clicks
								try {
									if (
										typeof event.target === "string" &&
										(event.target?.className?.includes("ant-table-selection-column") ||
											event.target?.className?.includes("ant-checkbox-wrapper"))
									) {
										return;
									}

									if (!contact._id) return;
								} catch (_) {
									// event.target.className.includes can sometimes not be a function
								}

								history.push(
									routesConfig.dashboard.contact.detail.root
										.replace(":team", team?.slug)
										.replace(":contactId", contact._id)
								);
							},
						})}
						rowSelection={
							!!data?.data?.length && {
								hideSelectAll: selectedAll,
								onSelect: (contact: { _id: string }) => handleContactSelect(contact),
								onSelectAll: (selected: boolean, contacts: { _id: string }[]) =>
									handleContactSelectAll({ selected, contacts }),
								selectedRowKeys: selectedAll ? data?.data.map((item) => item._id) : selectedContacts,
							}
						}
						pagination={{
							current: pagination.current,
							pageSize: pagination.pageSize,
							total: data?.total,
							showSizeChanger: true,
							defaultPageSize: 20,
							pageSizeOptions: ["20", "50", "100", "200"],
						}}
					>
						<Table.Column
							key="firstName"
							title={<FormattedMessage id="FLOWS.REPORT.OVERVIEW.TABLE.FIRSTNAME" />}
							width="128px"
							ellipsis={{ showTitle: false }}
							render={({ firstName, fullName, lastName, avatar }) => {
								if (!firstName && !lastName && !fullName) {
									return <FormattedMessage id="FALLBACK.CONTACT.NAME.SHORT" />;
								}

								const name = firstName || fullName.split(" ")[0];

								return (
									<Tooltip placement="top" title={name}>
										<div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
											<Image.ADefault src={avatar || websiteIcon} className="small" />
											{name}
										</div>
									</Tooltip>
								);
							}}
							sorter={true}
						/>
						<Table.Column
							key="lastName"
							title={<FormattedMessage id="FLOWS.REPORT.OVERVIEW.TABLE.LASTNAME" />}
							width="128px"
							ellipsis={{ showTitle: false }}
							render={({ lastName, fullName }) => {
								if (!lastName && !fullName) return "";

								const name = lastName || fullName.split(" ").slice(1).join(" ");

								return (
									<Tooltip placement="top" title={name}>
										{name}
									</Tooltip>
								);
							}}
							sorter={true}
						/>
						<Table.Column
							key="type"
							title={<FormattedMessage id="CONTACTS.OVERVIEW.TABLE.TYPE" />}
							width="128px"
							dataIndex="type"
							sorter={true}
							render={(type) => {
								const generateTypeDescription = (type?: string) => {
									switch (type) {
										case "customer":
											return <FormattedMessage id="contact.overview.select.type.customer" />;
										case "prospect":
											return <FormattedMessage id="contact.overview.select.type.prospect" />;
										case "other":
											return <FormattedMessage id="contact.overview.select.type.other" />;
										default:
											return undefined;
									}
								};

								return <p>{generateTypeDescription(type)}</p>;
							}}
						/>
						<Table.Column
							key="labels"
							title={<FormattedMessage id="CONTACTS.OVERVIEW.TABLE.LABELS" />}
							width="128px"
							dataIndex="labels"
							render={(labels) => {
								const assignedLabels = contactLabels?.data?.filter((label) =>
									labels?.includes(label._id)
								);
								const MAX_LABELS = 2;

								const renderLabels = assignedLabels?.slice(0, MAX_LABELS);
								const extraLabels = assignedLabels?.slice(MAX_LABELS).map((label) => label.name);

								return (
									<div
										style={{
											display: "flex",
											gap: "0.5em",
											flexWrap: "wrap",
										}}
									>
										{renderLabels?.map((label) => (
											<Tooltip key={label._id} title={label.name}>
												<Tag
													key={label._id}
													color={label.color}
													style={{
														marginRight: 0,
														maxWidth: "100px",
														overflow: "hidden",
														textOverflow: "ellipsis",
														whiteSpace: "nowrap",
													}}
												>
													{label.name}
												</Tag>
											</Tooltip>
										))}
										{!!extraLabels?.length && (
											<Tooltip title={extraLabels?.length ? extraLabels?.join(", ") : ""}>
												<Tag
													intent="count"
													style={{
														marginRight: 0,
													}}
												>
													{extraLabels?.length}
												</Tag>
											</Tooltip>
										)}
									</div>
								);
							}}
						/>
						<Table.Column
							key="accountManagers"
							title={<FormattedMessage id="CONTACTS.OVERVIEW.TABLE.ACCOUNT_MANAGERS" />}
							width="128px"
							dataIndex="accountManagers"
							render={(accountManagerIds) => {
								const assignedAccountManagers = accountManagers?.data.filter((accountManager) =>
									accountManagerIds?.includes(accountManager.userId)
								);

								return (
									<div style={{ display: "flex", gap: "0.5em", flexWrap: "wrap" }}>
										{!!assignedAccountManagers?.length && (
											<AvatarGroup maxCount={3}>
												{assignedAccountManagers?.map((manager) => (
													<Tooltip title={manager?.name || manager?.name} placement="top">
														<Avatar src={manager.avatar} size="default" shape="square" />
													</Tooltip>
												))}
											</AvatarGroup>
										)}
									</div>
								);
							}}
						/>
						<Table.Column
							key="archivedAt"
							title={
								<FormattedMessage
									id="CONTACTS.OVERVIEW.TABLE.ARCHIVED_AT"
									defaultMessage="Archived at"
								/>
							}
							width="128px"
							dataIndex="archivedAt"
							sorter={true}
							render={(archivedAt) => archivedAt && <FormattedDate value={archivedAt} />}
						/>
						{showDeleted && (
							<Table.Column
								key="deletedAt"
								title={
									<FormattedMessage
										id="CONTACTS.OVERVIEW.TABLE.DELETED_AT"
										defaultMessage="Deleted at"
									/>
								}
								width="128px"
								dataIndex="deletedAt"
								sorter={true}
								render={(deletedAt) => {
									if (!deletedAt) return null;

									const displayValue = calculateDeleteDate(deletedAt);

									return (
										<span style={{ display: "flex", alignItems: "center", gap: "1em" }}>
											<FormattedDate value={deletedAt} />
											<Tooltip
												placement="left"
												title={intl.formatMessage(
													{
														id: "CONTACTS.OVERVIEW.TABLE.HARD_DELETE.MESSAGE.TOOLTIP",
														defaultMessage:
															"{days, plural, =0 {Today this contact will be deleted forever} one {In {days} day this contact will be deleted forever} other {In {days} days this contact will be deleted forever}}",
													},
													{ days: displayValue }
												)}
											>
												<AntDTag color="red">
													<FormattedMessage
														id="CONTACTS.OVERVIEW.TABLE.HARD_DELETE.MESSAGE"
														defaultMessage="{days, plural, =0 {Today} one {{days} day} other {{days} days}}"
														values={{
															days: displayValue,
														}}
													/>
												</AntDTag>
											</Tooltip>
										</span>
									);
								}}
							/>
						)}
					</SkeletonTable>
				)}
			</div>
		</>
	);
}
