import { Alert } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { routesConfig, typesConfig } from "@/flows/common/config";
import { compareReportProgress } from "@/flows/common/helper";
import { removeReportFromList } from "@/flows/flow_detail/reports/state";
import { reportState } from "@/flows/report_detail/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import DeleteModal from "@/shared/components/modal/delete_modal";

import query from "./query";

export default function DeleteFlow({ isVisible, setShowModal }) {
	const intl = useIntl();
	const history = useHistory();
	const queryParams = useParams();
	const flowId = queryParams.flowId;
	const reportId = queryParams.reportId;
	const [isDeleting, setIsDeleting] = useState(false);
	const report = useRecoilValueLoadable(reportState({ flowId, reportId }));
	const removeDeletedReportFromList = useSetRecoilState(removeReportFromList);

	const handleDelete = async () => {
		setIsDeleting(true);

		try {
			await query.deleteReport({ flowId: queryParams?.flowId, reportId: queryParams?.reportId });

			const url = routesConfig.reports
				.replace(":team", queryParams?.team)
				.replace(":flowId", queryParams?.flowId);

			setShowModal(false);

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOW.REPORT_DETAIL.ACTIONS.DELETE.SUCCEEDED.TITLE",
					defaultMessage: "Report deleted",
				}),
			});

			setIsDeleting(false);

			removeDeletedReportFromList(queryParams?.reportId);

			history.push(url);
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOW.REPORT_DETAIL.ACTIONS.DELETE.FAILED.TITLE",
					defaultMessage: "Report could not be deleted",
				}),
				description: intl.formatMessage({
					id: "FLOW.REPORT_DETAIL.ACTIONS.DELETE.FAILED.DESCRIPTION",
					defaultMessage: "Please try again later or contact customer support",
				}),
			});

			setIsDeleting(false);
		}
	};

	const generateWarning = () => {
		const isReportInProgress = compareReportProgress({
			report: report.contents,
			progress: typesConfig.progressState.inviteSend,
		});

		if (!isReportInProgress) {
			return;
		}

		return (
			<Alert
				message={intl.formatMessage({ id: "FLOW.REPORT_DETAIL.ACTIONS.DELETE.SENT.WARNING.TITLE" })}
				description={intl.formatMessage({ id: "FLOW.REPORT_DETAIL.ACTIONS.DELETE.SENT.WARNING.DESCRIPTION" })}
				type="warning"
				showIcon
				className="t-gap--bottom"
			/>
		);
	};

	return (
		<DeleteModal
			title={intl.formatMessage({
				id: "FLOW.REPORT_DETAIL.ACTIONS.DELETE.TITLE",
				defaultMessage: "Delete report",
			})}
			description={intl.formatMessage({
				id: "FLOW.REPORT_DETAIL.ACTIONS.DELETE.DESCRIPTION",
				defaultMessage: "Are you sure you want to delete this report? This action can not be undone.",
			})}
			deleteLabel={intl.formatMessage({
				id: "FLOW.REPORT_DETAIL.ACTIONS.DELETE.LABEL.BUTTON_LABEL",
				defaultMessage: "Delete report",
			})}
			isVisible={isVisible}
			isDeleting={isDeleting}
			onDelete={handleDelete}
			banner={generateWarning()}
			onClose={() => setShowModal(false)}
		></DeleteModal>
	);
}
