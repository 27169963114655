import { routesConfig } from "@/flows/common/config";
import { BellOutlined, FileTextOutlined, SettingOutlined } from "@ant-design/icons";

export default {
	header: {
		title: "FLOWS.REPORT_DETAIL.TEMPLATE.PAGE.TITLE",
		tabTitle: "FLOWS.REPORT_DETAIL.TEMPLATE.PAGE.TITLE",
	},
	breadcrumbs: ({ team, flowName, reportName, flowId, reportId }) => [
		{
			to: routesConfig.overview.replace(":team", team),
			name: "FLOWS.REPORT_DETAIL.TEMPLATE.BREADCRUMB.FLOWS",
		},
		{
			to: routesConfig.reports.replace(":team", team).replace(":flowId", flowId),
			name: flowName,
		},
		{
			to: routesConfig.reportDetail
				.replace(":team", team)
				.replace(":flowId", flowId)
				.replace(":reportId", reportId),
			name: reportName || " ",
		},
	],
	aside: ({ team, flowId, reportOverviewLink, isConfigurationEnabled = false, isRemindersEnabled = false }) => {
		let items = [
			{
				id: "reportOverview",
				to: reportOverviewLink,
				icon: "arrow--left",
				label: "FLOWS.REPORT.TEMPLATE.ASIDE.BACK_TO_REPORT_OVERVIEW.LABEL",
			},
			{
				id: "rapport",
				to: routesConfig.reports.replace(":team", team).replace(":flowId", flowId),
				icon: <FileTextOutlined style={{ fontSize: "18px" }} />,
				label: "FLOWS.REPORT.TEMPLATE.ASIDE.REPORT.LABEL",
			},
		];

		if (isRemindersEnabled) {
			items.push({
				id: "reminders",
				to: routesConfig.reminders.replace(":team", team).replace(":flowId", flowId),
				icon: <BellOutlined style={{ fontSize: "20px" }} />,
				label: "FLOWS.REPORT.TEMPLATE.ASIDE.REMINDERS.LABEL",
			});
		}

		if (isConfigurationEnabled) {
			items.push({
				id: "configuration",
				to: routesConfig.configuration.replace(":team", team).replace(":flowId", flowId),
				icon: "group",
				label: "FLOWS.REPORT.TEMPLATE.ASIDE.CONFIGURATION.LABEL",
			});
		}

		return [
			...items,
			{
				id: "statusTypes",
				to: routesConfig.statusTypes.replace(":team", team).replace(":flowId", flowId),
				icon: "story",
				label: "FLOWS.REPORT.TEMPLATE.ASIDE.STATUS_TYPES.LABEL",
			},
			{
				id: "settings",
				to: routesConfig.settings.replace(":team", team).replace(":flowId", flowId),
				icon: <SettingOutlined style={{ fontSize: "18px" }} />,
				label: "FLOWS.REPORT.TEMPLATE.ASIDE.SETTINGS.LABEL",
			},
		];
	},
};
