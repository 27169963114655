import "./style.scss";

import { InboxLabelRouterOutput, trpc } from "@/trpc";
import { EditOutlined } from "@ant-design/icons";
import { Button, Empty, Form, Input, Select, Table, Typography } from "antd";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import SkeletonTable from "../../../shared/components/table/skeleton";
import { actions as dashboardActions } from "../../../store/dashboard";
import settingsConfig from "@/modules/settings/config";
import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { IInboxLabel } from "@bothive_core/database";
import { useDebounce } from "use-debounce";
import CreateModal from "./components/createModal";
import config from "./config";
import Tag from "@/shared/components/antdWrappers/Tag";
import UpdateModal from "./components/updateModal";

export default function InboxLabels() {
	const intl = useIntl();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const routeParameters: { team: string } = useParams();

	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
	const [selectedInboxLabelId, setSelectedInboxLabelId] = useState<string | null>(null);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });

	const [querySearch] = useDebounce(Form.useWatch<string>("search", form), 500);
	const queryType = Form.useWatch<IInboxLabel["type"]>("type", form);

	const { data: inboxLabelsData, isFetching } = trpc.inbox.inboxLabel.getAll.useQuery({
		limit: pagination.pageSize,
		offset: (pagination.current - 1) * pagination.pageSize,
		q: querySearch,
		type: queryType,
		excludeTypes: ["system"],
	});
	const selectedInboxLabel = inboxLabelsData?.data.find((label) => label._id === selectedInboxLabelId);

	useEffect(() => {
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [querySearch, queryType]);

	// Set breadcrumbs and header of this page
	useEffect(() => {
		const title = intl.formatMessage({ id: "settings.inbox.label.title", defaultMessage: "Inbox labels" });
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					id: "label",
					title: title,
					tabTitle: title,
					breadcrumbs: [
						{
							to: settingsConfig.routes.root.replace(":team", routeParameters.team),
							name: "BREADCRUMB.SETTINGS",
						},
						{
							to: settingsConfig.routes.inbox.labels.replace(":team", routeParameters.team),
							name: title,
						},
					],
				},
				intl,
			})
		);
	}, [dispatch]);

	return (
		<main
			style={{
				width: "100%",
				height: "100%",
				padding: "var(--spacer-md)",
				overflow: "auto",
			}}
		>
			<header>
				<Form
					layout="vertical"
					form={form}
					style={{
						display: "flex",
						alignItems: "flex-end",
						width: "100%",
						gap: "1.5em",
					}}
				>
					<Form.Item
						label={intl.formatMessage({
							id: "inbox.label.actions.search.input.label",
							defaultMessage: "Search on name",
							description: "Label of the input field to search for a certain inbox label",
						})}
						name="search"
						style={{ width: "100%", margin: 0 }}
					>
						<Input
							size="large"
							placeholder={intl.formatMessage({
								id: "inbox.label.actions.search.input.placeholder",
								defaultMessage: "Search on label name",
								description: "Placeholder of the input field to search for a certain inbox label",
							})}
						/>
					</Form.Item>
					<Form.Item
						label={intl.formatMessage({
							id: "inbox.label.actions.filter.type.input.label",
							defaultMessage: "Filter on type",
							description: "Label of the dropdown to filter on a certain inbox label",
						})}
						name="type"
						style={{ width: "30em", margin: 0 }}
					>
						<Select
							className="loading_select"
							allowClear={true}
							size="large"
							placeholder={intl.formatMessage({
								id: "inbox.label.actions.filter.type.input.placeholder",
								defaultMessage: "Filter on type",
								description: "Placeholder of the dropdown to filter on a certain inbox label",
							})}
							options={Array.from(config.labelTypeIntl).map(([key, value]) => ({
								label: intl.formatMessage(value),
								value: key,
							}))}
						/>
					</Form.Item>
					<Button
						onClick={() => setIsCreateModalOpen(true)}
						type="primary"
						size="large"
						style={{ width: "max-content" }}
					>
						<FormattedMessage id="inbox.label.actions.add.button.title" defaultMessage="Add label" />
					</Button>
				</Form>
			</header>
			<SkeletonTable
				style={{ marginTop: "2em" }}
				rowKey="_id"
				dataSource={inboxLabelsData?.data}
				isLoading={isFetching}
				onRow={(label: InboxLabelRouterOutput["getAll"]["data"][number]) => ({
					onClick: () => {
						setSelectedInboxLabelId(label._id);
						setIsUpdateModalOpen(true);
					},
				})}
				onChange={(pagination: any) => setPagination(pagination)}
				pagination={{
					current: pagination.current,
					pageSize: pagination.pageSize,
					total: inboxLabelsData?.total,
					showSizeChanger: true,
					defaultPageSize: 20,
					pageSizeOptions: ["20", "50", "100", "200"],
				}}
				locale={{
					emptyText: (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={
								<p>
									<FormattedMessage
										id="SETTINGS.CONTACTS.LABELS.EMPTY"
										defaultMessage="No labels found"
									/>
								</p>
							}
						/>
					),
				}}
			>
				<Table.Column
					key="name"
					title={intl.formatMessage({
						id: "inbox.label.table.header.name",
						defaultMessage: "Name",
					})}
					ellipsis={true}
					dataIndex="name"
					render={(_, label: InboxLabelRouterOutput["getAll"]["data"][number]) => (
						<Tag
							key={label._id}
							color={label.color}
							style={{
								marginRight: 0,
								width: "fit-content",
							}}
							onClose={undefined}
						>
							{label.name}
						</Tag>
					)}
				/>
				<Table.Column
					key="description"
					title={intl.formatMessage({
						id: "inbox.label.table.header.description",
						defaultMessage: "Description",
					})}
					dataIndex="description"
					render={(_, { description }: InboxLabelRouterOutput["getAll"]["data"][number]) => (
						<Typography.Text>{description}</Typography.Text>
					)}
				/>
				<Table.Column
					key="type"
					width="128px"
					dataIndex="type"
					title={intl.formatMessage({
						id: "inbox.label.table.header.type",
						defaultMessage: "Type",
					})}
					render={(_, label: InboxLabelRouterOutput["getAll"]["data"][number]) => (
						<div className="settings-inbox_labels-type">
							{config.labelTypeIcon.get(label.type)?.()}
							<Typography.Text>
								<FormattedMessage {...(config.labelTypeIntl.get(label.type) as MessageDescriptor)} />
							</Typography.Text>
						</div>
					)}
				/>
				<Table.Column
					key="channel"
					dataIndex="channel"
					title={intl.formatMessage({
						id: "inbox.label.table.header.channel",
						defaultMessage: "Channel",
					})}
					render={(_, label: InboxLabelRouterOutput["getAll"]["data"][number]) => (
						<Typography.Text>{label.channel?.address || ""}</Typography.Text>
					)}
				/>
				<Table.Column
					key="edit"
					width="60px"
					align="center"
					dataIndex="edit"
					render={() => (
						<Typography.Text>
							<EditOutlined />
						</Typography.Text>
					)}
				/>
			</SkeletonTable>
			<CreateModal isOpen={isCreateModalOpen} setIsOpen={setIsCreateModalOpen} />
			{selectedInboxLabel && (
				<UpdateModal
					selectedInboxLabel={selectedInboxLabel}
					isOpen={isUpdateModalOpen}
					setIsOpen={setIsUpdateModalOpen}
				/>
			)}
		</main>
	);
}
