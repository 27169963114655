import axios from "axios";

import { apiConfig } from "../../../config";
import { storageHelpers, timerHelpers } from "../../../helpers";
import * as banner from "../../dashboard/banner/banner.action";
import store from "../../store";
import { updateTeamLocally } from "../team/team.action";
import actionTypes from "./form.actionTypes";

export const isSaving = () => (dispatch) => {
	dispatch({
		type: actionTypes.IS_SAVING,
	});
};
export const savingFailed = () => (dispatch) => {
	dispatch({
		type: actionTypes.SAVING_FAILED,
	});
};
export const savingSucceeded = () => (dispatch) => {
	dispatch({
		type: actionTypes.SAVING_SUCCEEDED,
	});
};

export const isDeleting = () => (dispatch) => {
	dispatch({
		type: actionTypes.IS_DELETING,
	});
};
export const deletingFailed = () => (dispatch) => {
	dispatch({
		type: actionTypes.DELETING_FAILED,
	});
};
export const deletingSucceeded = () => (dispatch) => {
	dispatch({
		type: actionTypes.DELETING_SUCCEEDED,
	});
};

export const initForm = () => (dispatch) => {
	dispatch({ type: actionTypes.INIT_FORM });
};
export const updateForm = (data) => (dispatch) => {
	dispatch({
		type: actionTypes.UPDATE_FORM,
		data,
	});
	dispatch(savingSucceeded());
};

export const startFormWatcher = () => (dispatch) => {
	timerHelpers.startTimer();
	timerHelpers.watchTimeout(() => {
		dispatch(savingFailed());
		return;
	});

	dispatch(isSaving());
	dispatch({ type: actionTypes.START_FORM_WATCHER });
};
export const formWatcherSucceeded = (callback) => (dispatch) => {
	dispatch({ type: actionTypes.FORM_WATCHER_SUCCEEDED });
	timerHelpers.endTimeout(() => {
		callback && callback();
		dispatch(savingSucceeded());
	});
};
export const formWatcherFailed = (callback) => (dispatch) => {
	dispatch({ type: actionTypes.FORM_WATCHER_FAILED });
	timerHelpers.endTimeout(() => {
		callback && callback();
		dispatch(savingFailed());
	});
};

export const formWatcherSucceededNoTimeout = (callback) => (dispatch) => {
	dispatch({ type: actionTypes.FORM_WATCHER_SUCCEEDED });
	dispatch(savingSucceeded());
	callback && callback();
};
export const formWatcherFailedNoTimeout = (callback) => (dispatch) => {
	dispatch({ type: actionTypes.FORM_WATCHER_FAILED });
	dispatch(savingFailed());
	callback && callback();
};

export const startDeleteWatcher = () => (dispatch) => {
	timerHelpers.startTimer();
	timerHelpers.watchTimeout(() => {
		dispatch(deletingFailed());
		return;
	});

	dispatch(isDeleting());
	dispatch({ type: actionTypes.START_DELETE_WATCHER });
};
export const deleteWatcherSucceeded = (callback) => (dispatch) => {
	dispatch({ type: actionTypes.DELETE_WATCHER_SUCCEEDED });
	timerHelpers.endTimeout(() => {
		callback && callback();
		dispatch(deletingSucceeded());
	});
};
export const deleteWatcherFailed = (callback) => (dispatch) => {
	dispatch({ type: actionTypes.DELETE_WATCHER_FAILED });
	timerHelpers.endTimeout(() => {
		callback && callback();
		dispatch(deletingFailed());
	});
};

export const deleteSucceededNoTimeout = (callback) => (dispatch) => {
	dispatch({ type: actionTypes.DELETE_WATCHER_SUCCEEDED });
	dispatch(deletingSucceeded());
	callback && callback();
};
export const deleteFailedNoTimeout = (callback) => (dispatch) => {
	dispatch({ type: actionTypes.DELETE_WATCHER_FAILED });
	dispatch(deletingFailed());
	callback && callback();
};

//DEPRECATED move to correct content action and use form watcher dispatchers
export const updateTeam =
	({
		value,
		successBanner = { message: "BANNER.SUCCEEDED.SETTINGS_UPDATE" },
		failedBanner = { message: "BANNER.FAILED.SETTINGS_UPDATE" },
		updateLocal = true,
	}) =>
	async (dispatch) => {
		dispatch(startFormWatcher());
		const uuid = storageHelpers.getTeamUuid();

		function updateFailed() {
			dispatch(savingFailed());
			dispatch(banner.showFailedMessage(failedBanner));
		}

		if (updateLocal) {
			dispatch(updateTeamLocally(value));
		}

		try {
			const result = await axios.put(apiConfig.team.getByUuid.replace(":uuid", uuid), value);

			timerHelpers.endTimeout(() => {
				if (result.status !== 200) {
					updateFailed();
					return;
				}

				dispatch({ type: actionTypes.UPDATE_TEAM });
				dispatch(updateTeamLocally(result.data.data));
				dispatch(savingSucceeded());
				dispatch(banner.showSuccessMessage(successBanner));
			});
		} catch (error) {
			timerHelpers.endTimeout(updateFailed);
		}
	};
export const sendSupportEmail = (value) => async (dispatch) => {
	dispatch(startFormWatcher());

	const body = {
		team: storageHelpers.getTeamUuid(),
		...value,
	};

	function updateFailed() {
		dispatch(savingFailed());
		dispatch(banner.showFailedMessage({ message: "BANNER.FAILED.SEND_SUPPORT" }));
	}

	try {
		const result = await axios.post(apiConfig.support.supportMail, body);

		timerHelpers.endTimeout(() => {
			if (result.status !== 200) {
				updateFailed();
				return;
			}

			dispatch({ type: actionTypes.SEND_SUPPORT_EMAIL });
			dispatch(savingSucceeded());
			dispatch(banner.showSuccessMessage({ message: "BANNER.SUCCEEDED.SEND_SUPPORT" }));
		});
	} catch (error) {
		timerHelpers.endTimeout(updateFailed);
	}
};

export const acceptTermsAndConditions = () => async (dispatch) => {
	dispatch(startFormWatcher());

	try {
		const uuid = store.getState().content.team.team._id;
		const result = await axios.post(apiConfig.team.acceptTermsAndConditions.replace(":uuid", uuid));

		if (result.status !== 201) {
			throw new Error();
		}

		timerHelpers.endTimeout(() => {
			dispatch(savingSucceeded());
			dispatch({ type: actionTypes.ACCEPT_TERMS_AND_CONDITIONS });
			dispatch(updateTeamLocally(result.data.result));
		});
	} catch (error) {
		dispatch(savingFailed());
	}
};

export const resetPassword =
	({ user, token, password }) =>
	async (dispatch) => {
		dispatch(startFormWatcher());

		function updateFailed() {
			dispatch(savingFailed());
			dispatch(banner.showFailedMessage({ message: "BANNER.FAILED.RESET_PASSWORD" }));
		}

		try {
			const result = await axios.put(apiConfig.user.resetPassword, { user, token, password });

			timerHelpers.endTimeout(() => {
				if (result.status !== 200 && !result.data.success) {
					updateFailed();
					return;
				}

				dispatch({ type: actionTypes.RESET_PASSWORD });
				dispatch(savingSucceeded());
			});
		} catch (error) {
			timerHelpers.endTimeout(updateFailed);
		}
	};
