import React from "react";

import { Component } from "../../atoms";

export default function TLoadScreen({ className }) {
	return (
		<div className={`t-layout--loader ${className}`}>
			<Component.ALoader />
		</div>
	);
}
