import "./style.scss";

import { CloseOutlined } from "@ant-design/icons";
import { IIntegration } from "@bothive_core/database";
import { Button, Steps, StepProps } from "antd";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { Image } from "@/components/atoms";
import { pageType } from "./config";
import AddChannel from "./addChannel";
import SetupChannel from "./setupChannel";
import Syncing from "./syncing";
import { trpc } from "../../../../trpc";

interface IConnectChannelParams {
	open: boolean;
	onClose: () => void;
	provider?: IIntegration;
	step?: pageType;
}

export default function ConnectChannel({ open, provider, onClose, step = "addChannel" }: IConnectChannelParams) {
	const intl = useIntl();
	const dialogRef = useRef<HTMLDialogElement>(null);
	const [currentStep, setCurrentStep] = useState<pageType>(step);
	const { data: organization } = trpc.organization.getOrganization.useQuery();

	useEffect(() => {
		if (!dialogRef.current) return () => undefined;

		dialogRef.current.addEventListener("cancel", handleClose);
		dialogRef.current.addEventListener("close", handleClose);

		return () => {
			setCurrentStep("addChannel");
			dialogRef.current?.removeEventListener("cancel", handleClose);
			dialogRef.current?.removeEventListener("close", handleClose);
		};
	}, [dialogRef.current]);

	const handleClose = () => {
		setCurrentStep("addChannel");
		onClose();
	};

	function generateContent() {
		switch (currentStep) {
			case "setup":
				return <SetupChannel onClick={setCurrentStep} />;
			case "sync":
				return <Syncing onClick={handleClose} />;
			case "addChannel":
			default:
				return <AddChannel provider={provider} onClick={setCurrentStep} />;
		}
	}

	function generateSteps(): StepProps[] {
		const stepIndex: Record<pageType, number> = {
			addChannel: 0,
			setup: 1,
			sync: 2,
		};

		function stepStatus(step: pageType): StepProps["status"] {
			if (step === currentStep) return "process";

			return stepIndex[step] < stepIndex[currentStep] ? "finish" : "wait";
		}

		const basicSteps = [
			{
				status: stepStatus("addChannel"),
				title: intl.formatMessage({
					id: "channels.connect_channel.steps.add_channel",
					defaultMessage: "Add channel",
				}),
			},
			{
				status: stepStatus("setup"),
				title: intl.formatMessage({
					id: "channels.connect_channel.steps.setup_channel",
					defaultMessage: "Setup channel",
				}),
			},
		];

		if (organization?.permissions.inboxAccess) {
			basicSteps.push({
				status: stepStatus("sync"),
				title: intl.formatMessage({
					id: "channels.connect_channel.steps.sync",
					defaultMessage: "Sync",
				}),
			});
		}

		return basicSteps;
	}

	return (
		<dialog open={open} ref={dialogRef} className="connect_channels">
			<header className="connect_channels-header">
				<Image.ALogo className="connect_channels-header-logo" />
				<Button type="text" icon={<CloseOutlined />} onClick={handleClose} />
			</header>
			<div className="connect_channels-content">
				<Steps className="connect_channels-content-steps" direction="vertical" items={generateSteps()} />
				{generateContent()}
			</div>
		</dialog>
	);
}
