import "./style.scss";

import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { logo } from "../../assets/images";
import AcceptInvite from "./acceptInvite";
import Login from "./login";
import CreateOrganization from "./createOrganization";
import ResetPassword from "./resetPassword";
import SignUp from "./signUp";
import SignUpCompleted from "./signUpCompleted";
import VerifyUser from "./verifyUser";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import storageHelpers from "@/helpers/storage.helpers";

export default function Portal() {
	const user = useSelector((state) => state.profile.account.user);
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (!user && !storageHelpers.getUserToken()) return;

		// User must be able to create team when signed in
		// Verify page does it's own check on state so when we would do it here, it crashes by too many updates in state
		if (location.pathname === "/portal/organization" || location.pathname === "/portal/verify") {
			return;
		}

		return history.push(`/`);
	}, [user]);

	return (
		<main className="portal">
			<section className="portal-aside">
				<img src={logo.logoWhite} alt="Bothive logo" className="portal-aside-logo" />
				<article className="portal-aside-slider"></article>
			</section>
			<Switch>
				<Route path="/portal/invite" component={AcceptInvite} />
				<Route path="/portal/login" component={Login} />
				<Route path="/portal/organization" component={CreateOrganization} />
				<Route path="/portal/reset-password" component={ResetPassword} />
				<Route path="/portal/sign_up/completed" component={SignUpCompleted} />
				<Route path="/portal/sign_up" component={SignUp} />
				<Route path="/portal/verify" component={VerifyUser} />
			</Switch>
		</main>
	);
}
