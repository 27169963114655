import "./style.scss";

import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { queryParamsConfig } from "@/flows/common/config";
import AddFlow from "@/flows/overview/create";
import { isAddFlowModalVisibleState } from "@/flows/overview/create/state";
import FlowHeader from "@/flows/overview/header";
import NoPermissions from "@/flows/overview/locked";
import FlowOverviewTable from "@/flows/overview/table";
import SendFlowModal from "@/flows/send";
import { actions as dashboardActions } from "@/store/dashboard";

import config from "./config";
import { useFlowList } from "./hook";

export default function FlowOverview() {
	const history = useHistory();
	const intl = useIntl();
	const { search } = useLocation();
	const dispatch = useDispatch();
	const queryParameters = useParams();
	const searchParams = new URLSearchParams(search);

	const [flows] = useFlowList();
	const [showSendFlowModal, setShowSendFlowModal] = useState({ visible: false });

	const hasFlowPermissions = useSelector((state) => state.dashboard.permissions.flows);
	const setIsAddFlowModalVisible = useSetRecoilState(isAddFlowModalVisibleState);

	useEffect(() => {
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: { tabTitle: config.tabTitle, breadcrumbs: config.breadcrumbs({ team: queryParameters?.team }) },
				intl,
			})
		);
	}, []);

	const handleShowSendFlowModal = (flow) => {
		setShowSendFlowModal({
			visible: true,
			flow,
		});
	};

	const showCreateFlowModal = () => {
		setIsAddFlowModalVisible(true);
		history.push({ search: "?modal=open" });
	};

	useEffect(() => {
		if (searchParams.get(queryParamsConfig.modal) === "open") {
			showCreateFlowModal();
		}
	}, []);

	const isFiltered = searchParams.get(queryParamsConfig.search);

	const generateBody = () => {
		if (!hasFlowPermissions && !flows?.contents?.length) return <NoPermissions />;

		return (
			<>
				<FlowHeader onCreateClick={showCreateFlowModal} />
				<FlowOverviewTable isFiltered={isFiltered} handleShowSendFlowModal={handleShowSendFlowModal} />
				{showSendFlowModal.visible && (
					<SendFlowModal
						{...showSendFlowModal}
						handleClose={() => setShowSendFlowModal({ visible: false })}
						showReports={true}
					/>
				)}
			</>
		);
	};

	return (
		<main className="t-section">
			{generateBody()}
			<AddFlow />
		</main>
	);
}
