import "./style.scss";

import { Skeleton, Table } from "antd";
import React from "react";

export default function SkeletonTable({
	isLoading,
	useSkeletonLoader = true,
	children,
	dataSource,
	className,
	skeletonRowCount = 10,
	...props
}) {
	const rowCount = dataSource?.length || skeletonRowCount;

	const renderTableData = () => {
		if (isLoading && useSkeletonLoader) {
			const ArrayWithEmptyRows = [...Array(rowCount)];

			return ArrayWithEmptyRows.map((_, index) => ({ key: `key_${index}` }));
		}

		return dataSource;
	};

	const renderTableColumns = () => {
		if (isLoading && useSkeletonLoader) {
			const ArrayWithEmptyColumns = Array.from({ length: children?.length || 4 });

			const skeletonColumnComponents = ArrayWithEmptyColumns.map((_, index) => (
				<Table.Column
					key={`key_${index}`}
					title={<span className="ts-title-placeholder"></span>}
					render={() => <Skeleton key={index} title active={isLoading} paragraph={false} />}
				/>
			));

			return skeletonColumnComponents;
		}

		return children;
	};

	return (
		<Table
			className={className}
			rowClassName="sk-table-row"
			dataSource={renderTableData()}
			loading={!useSkeletonLoader && isLoading}
			{...props}
		>
			{renderTableColumns()}
		</Table>
	);
}
