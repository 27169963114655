import "codemirror/addon/lint/lint";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript";

import React from "react";
import { UnControlled } from "react-codemirror2-react-17";

export default function ACodeField({ value, className, lineNumbers, showGutter, readOnly, onChange }) {
	const gutter = ["CodeMirror-linenumbers"];

	return (
		<UnControlled
			value={value}
			options={{
				mode: {
					name: "javascript",
					json: true,
				},
				lint: true,
				gutters: showGutter ? gutter : [],
				autoCloseBrackets: true,
				lineWrapping: true,
				lineNumbers,
				readOnly,
			}}
			autoCursor={false}
			onChange={onChange}
			className={`a-input--code-editor ${className}`}
		/>
	);
}
