import { dateHelpers } from "@/helpers";

function getAutomationStatusFromHistory(historyList) {
	const sortByDate = dateHelpers.sortDatesOldToNew({ data: historyList, key: "executedAt" });

	return sortByDate.reduce(
		(prev, historyItem) => ({
			...prev,
			[historyItem.ruleId]: {
				...historyItem,
				successHistory: [...(prev[historyItem.ruleId]?.successHistory || []), historyItem.isSuccessful],
			},
		}),
		{}
	);
}

export default { getAutomationStatusFromHistory };
