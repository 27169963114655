import "./style.scss";

import { Table, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useState } from "react";

import { routesConfig } from "@/config";
import DashboardCard from "../../../../../../shared/components/card/dashboard";
import SkeletonTable from "../../../../../../shared/components/table/skeleton";
import { trpc } from "../../../../../../trpc";
import { ApTaskTypes } from "./config";

export default function AdminPulseAuditLog() {
	const intl = useIntl();
	const { connectionId, team } = useParams() as { connectionId: string; team: string };
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

	const { data: auditLogs, isLoading } = trpc.auditLog.getAllAuditLogs.useQuery({
		entityId: connectionId,
		limit: pagination.pageSize,
		offset: (pagination.current - 1) * pagination.pageSize,
	});

	const handleTableChange = ({ pagination }) => {
		setPagination({ current: pagination.current, pageSize: pagination.pageSize });
	};

	if (!auditLogs?.total && !isLoading) return null;

	return (
		<DashboardCard
			title={intl.formatMessage({
				id: "settings.integrations.admin_pulse.audit_logs.title",
				defaultMessage: "AdminPulse tasks logs",
			})}
		>
			<div className="admin_pulse-audit_log-header">
				<p className="admin_pulse-audit_log-header-description">
					<FormattedMessage
						id="settings.integrations.admin_pulse.audit_logs.description"
						defaultMessage="In these logs, you can see what happened during the synchronization of your actions between Bothive and AdminPulse tasks. These logs help you identify and resolve issues."
					/>
				</p>
			</div>
			<SkeletonTable
				pagination={
					(auditLogs?.total || 0) > 5 && {
						showSizeChanger: true,
						showQuickJumper: false,
						total: auditLogs?.total,
						current: pagination.current,
						pageSize: pagination.pageSize,
						pageSizeOptions: ["5", "10", "20", "50"],
					}
				}
				isLoading={isLoading}
				style={{ paddingTop: "2em" }}
				dataSource={auditLogs?.data || []}
				className="admin_pulse-audit_log-table"
				onChange={(pagination: any) => handleTableChange({ pagination })}
			>
				<Table.Column
					key="status"
					dataIndex="meta"
					title={intl.formatMessage({
						id: "settings.integrations.admin_pulse.audit_logs.status",
						defaultMessage: "Status",
					})}
					render={(meta) =>
						ApTaskTypes[meta.type] && (
							<Typography.Text
								className="admin_pulse-audit_log_status"
								data-is-error={meta.type !== "SUCCESS"}
							>
								<FormattedMessage {...ApTaskTypes[meta.type]} />
							</Typography.Text>
						)
					}
				/>
				<Table.Column
					key="contact"
					dataIndex="contact"
					title={intl.formatMessage({
						id: "settings.integrations.admin_pulse.audit_logs.contact",
						defaultMessage: "Contact",
					})}
					render={(contact) => (
						<NavLink
							to={routesConfig.dashboard.contact.detail.root
								.replace(":team", team)
								.replace(":contactId", contact?._id)}
						>
							{contact?.fullName || ""}
						</NavLink>
					)}
				/>
				<Table.Column
					ellipsis
					key="relationIdentifier"
					dataIndex={["payload", "relationIdentifier"]}
					title={intl.formatMessage({
						id: "settings.integrations.admin_pulse.audit_logs.relation_identifier",
						defaultMessage: "Admin Pulse id",
					})}
					render={(relationIdentifier) => <Typography.Text>{relationIdentifier}</Typography.Text>}
				/>
				<Table.Column
					ellipsis
					key="taskName"
					dataIndex={["payload", "task", "name"]}
					title={intl.formatMessage({
						id: "settings.integrations.admin_pulse.audit_logs.task",
						defaultMessage: "Task",
					})}
					render={(taskName) => <Typography.Text>{taskName}</Typography.Text>}
				/>
				<Table.Column
					ellipsis
					key="taskName"
					dataIndex={["payload", "task", "subTaskName"]}
					title={intl.formatMessage({
						id: "settings.integrations.admin_pulse.audit_logs.sub_task",
						defaultMessage: "Sub task",
					})}
					render={(subTaskName) => <Typography.Text>{subTaskName}</Typography.Text>}
				/>
			</SkeletonTable>
		</DashboardCard>
	);
}
