import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";

export default function ODestructive({
	title,
	description,
	buttonLabel,
	formattedMessageValues,
	hideButton,
	loading,
	className,
	onClick,
}) {
	return (
		<div className={`o-section o-section--destructive ${className}`}>
			{title && (
				<h2 className="destructive-title">
					<FormattedMessage id={title} values={formattedMessageValues} />
				</h2>
			)}
			<div className="body">
				<p className="destructive-body-paragraph">
					<FormattedMessage id={description} values={formattedMessageValues} />
				</p>
				{!hideButton && (
					<Button loading={loading} danger onClick={onClick}>
						<FormattedMessage id={buttonLabel} />
					</Button>
				)}
			</div>
		</div>
	);
}
