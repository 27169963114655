import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Button, Modal, Row, Alert, Tooltip } from "antd";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import DeleteModal from "@/shared/components/modal/delete_modal";
import "../../../style.scss";
import AddressForm from "./form/AddressForm";
import ContactForm from "./form/ContactForm";
import { trpc } from "@/trpc";
import { IOrganization, IOrganizationAddress } from "@bothive_core/database";

export default function EditModal({
	selectedLocation,
	allowEditing,
	organization,
	currentAddressId,
	setCurrentAddressId,
}: {
	selectedLocation: IOrganizationAddress;
	allowEditing: boolean;
	organization?: IOrganization;
	currentAddressId: string | undefined;
	setCurrentAddressId: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const [form] = Form.useForm();
	const [showAlert, setShowAlert] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const removeLocationMutation = trpc.organization.deleteLocation.useMutation();
	const updateLocationMutation = trpc.organization.updateLocation.useMutation();

	const primaryAddress = organization?.address?.find((address) => address.isPrimary);

	const closeModals = () => {
		setShowConfirm(false);
		setCurrentAddressId("");
		setShowAlert(false);
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(selectedLocation);
	}, [currentAddressId]);

	const updateLocation = (address) => {
		updateLocationMutation.mutate(
			{ ...address, _id: selectedLocation?._id },
			{
				onSuccess: () => {
					trpcUtils.organization.getOrganization.invalidate();
					closeModals();

					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "organization_settings.general.locations.edit_modal.update.succeeded",
							defaultMessage: "The address has been successfully saved.",
						}),
					});
				},
				onError: () => {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "organization_settings.general.locations.edit_modal.update.failed",
							defaultMessage: "The address couldn't be updated. Please try again later",
						}),
					});
				},
			}
		);
	};

	const removeLocation = (address) => {
		removeLocationMutation.mutate(address, {
			onSuccess: () => {
				trpcUtils.organization.getOrganization.invalidate();
				closeModals();
				ShowSuccessBanner({
					title: intl.formatMessage({
						id: "organization_settings.general.locations.edit_modal.delete.succeeded",
						defaultMessage: "The address has been successfully removed.",
					}),
				});
			},
			onError: (error) => {
				if (error?.data?.code === "BAD_REQUEST") {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "organization_settings.general.locations.edit_modal.delete_primary.failed",
							defaultMessage: "Cannot remove the only primary address.",
						}),
					});
				} else {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "organization_settings.general.locations.edit_modal.delete.failed",
							defaultMessage: "The address couldn't be removed. Please try again later",
						}),
					});
				}
			},
		});
	};

	return (
		<>
			<DeleteModal
				title={intl.formatMessage({
					id: "organization_settings.general.locations.delete_modal.title",
					defaultMessage: "Delete address",
				})}
				deleteLabel={intl.formatMessage({
					id: "organization_settings.general.locations.delete_modal.delete_button",
					defaultMessage: "Delete",
				})}
				description={intl.formatMessage({
					id: "organization_settings.general.locations.delete_modal.description",
					defaultMessage: "Are you sure you want to delete this address?",
				})}
				isVisible={showConfirm}
				onDelete={() => removeLocation({ _id: form.getFieldValue("_id") })}
				onClose={() => setShowConfirm(false)}
			/>
			<Modal
				forceRender
				title={
					allowEditing
						? intl.formatMessage({
								id: "organization_settings.general.locations.edit_modal.title",
								defaultMessage: "Edit Location",
						  })
						: intl.formatMessage({
								id: "organization_settings.general.locations.view_modal.title",
								defaultMessage: "View Location",
						  })
				}
				open={!!currentAddressId}
				onCancel={() => {
					setCurrentAddressId(undefined);
					setShowAlert(false);
				}}
				width={650}
				centered
				footer={
					allowEditing ? (
						<Row justify="space-between">
							<Tooltip
								title={
									organization?.address?.length === 1 &&
									intl.formatMessage({
										id: "organization_settings.general.locations.delete_button.tooltip",
										defaultMessage: "Primary address can't be removed",
										description:
											"Tooltip over delete button when user tries to delete last location",
									})
								}
							>
								<Button
									danger
									type="primary"
									htmlType="button"
									onClick={() => setShowConfirm(true)}
									disabled={organization?.address?.length === 1}
									loading={removeLocationMutation.isLoading}
								>
									<FormattedMessage
										id="organization_settings.general.locations.delete_button.label"
										defaultMessage="Remove"
									/>
								</Button>
							</Tooltip>
							<Button
								type="primary"
								htmlType="submit"
								form="editLocation"
								loading={updateLocationMutation.isLoading}
							>
								<FormattedMessage
									id="organization_settings.general.locations.update_button.label"
									defaultMessage="Update"
								/>
							</Button>
						</Row>
					) : null
				}
			>
				{primaryAddress && showAlert && !selectedLocation?.isPrimary && (
					<Alert
						message={intl.formatMessage({
							id: "organization_settings.general.locations.alert.title",
							defaultMessage: "Primary address",
							description:
								"Alert banner to notify to user that marking this location as primary will change current primary address to normal",
						})}
						description={intl.formatMessage({
							id: "organization_settings.general.locations.alert.description",
							defaultMessage:
								"Are you sure you want to set this address as primary? This will remove the primary status from the current primary address.",
							description:
								"Alert banner to notify to user that marking this location as primary will change current primary address to normal",
						})}
						type="warning"
						showIcon
						className="t-gap--bottom"
					/>
				)}
				<Form
					id="editLocation"
					form={form}
					initialValues={{ selectedLocation, isPrimary: false }}
					disabled={!allowEditing}
					onFinish={updateLocation}
				>
					<AddressForm />
					<ContactForm
						disableSwitch={organization?.address?.length === 1 || !allowEditing}
						onIsPrimaryChange={(value) => setShowAlert(value && !!primaryAddress && true)}
					/>
				</Form>
			</Modal>
		</>
	);
}
