export default {
	isRowClickAllowed({ target }) {
		// If click happens in a column with these classes it should be ignored to prevent accidental clicks
		if (
			typeof target?.className === "string" &&
			(target?.className?.includes("button-yellow") ||
				target?.className?.includes("flows-overview-table-actions"))
		) {
			return false;
		}

		return true;
	},
};
