import "./style.scss";

import { RightOutlined } from "@ant-design/icons";
import { Progress, Result, Table, Tag, Tooltip } from "antd";
import React, { useEffect } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import { Image } from "@/components/atoms";
import { websiteIcon } from "@/assets/images/icon";
import config from "@/flows/flow_detail/reports/config";
import ErrorState from "@/flows/common/components/error";
import { calculateProgress } from "@/flows/common/helper";
import EmptyStateReports from "@/flows/flow_detail/reports/empty";
import { useReportList } from "@/flows/flow_detail/reports/hook";
import { allReportsSelectedState, selectedReportIdsState } from "@/flows/flow_detail/reports/bulk_actions/state";
import {
	reportsMeta,
	isTableFiltered,
	searchFilterState,
	selectedSortingState,
	reportsTablePageState,
	selectedProgressState,
	selectedStatusTypeState,
	selectedAccountManagerState,
} from "@/flows/flow_detail/reports/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import SkeletonTable from "@/shared/components/table/skeleton";

export default function ReportsTable({ handleRowClick }) {
	const MAX_LABELS = 2;

	const intl = useIntl();
	const queryParams = useParams();
	const isFiltered = useRecoilValue(isTableFiltered);
	const reportMetaData = useRecoilValue(reportsMeta);
	const [reports, fetchReportsList] = useReportList();
	const searchFilter = useRecoilValue(searchFilterState);
	const selectedProgress = useRecoilValue(selectedProgressState);
	const selectedStatusType = useRecoilValue(selectedStatusTypeState);
	const [sortState, setSortState] = useRecoilState(selectedSortingState);
	const [currentTablePage, setCurrentTablePage] = useRecoilState(reportsTablePageState);
	const [selectedReportIds, setSelectedReportIds] = useRecoilState(selectedReportIdsState);
	const [allReportsSelected, setAllReportsSelected] = useRecoilState(allReportsSelectedState);
	const [selectedAccountManager, setSelectedAccountManager] = useRecoilState(selectedAccountManagerState);

	useEffect(() => {
		fetchReports({ offset: (currentTablePage - 1) * reportMetaData.limit });

		// Reset selection when filters change
		setAllReportsSelected(false);
		setSelectedReportIds([]);
	}, [searchFilter, selectedStatusType, selectedProgress]);

	useEffect(() => {
		setSelectedReportIds([]);

		if (allReportsSelected) {
			const tempSelectedReportIds = reports.contents.map(({ _id: reportId }) => reportId);

			setSelectedReportIds([...new Set(tempSelectedReportIds)]);
		}
	}, [allReportsSelected]);

	const fetchReports = async ({ limit, offset, sort } = { offset: 0 }) => {
		try {
			await fetchReportsList({
				...reportMetaData,
				sort,
				offset,
				q: searchFilter,
				progress: selectedProgress,
				status: selectedStatusType,
				flowId: queryParams?.flowId,
				limit: limit || reportMetaData.limit,
				accountManager: selectedAccountManager,
			});
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "flows.detail.banner.fetch_failed",
					defaultMessage: "Failed to get flows",
				}),
			});
		}
	};

	const handleTableChange = ({ sorter, pagination }) => {
		setAllReportsSelected(false);

		let sort;

		if (sorter?.order) {
			sort = {
				property: sorter.columnKey,
				order: sorter.order === "ascend" ? "asc" : "desc",
			};

			setSortState(sort);
		} else if (!sorter?.order && sortState) {
			setSortState(undefined);
		}

		fetchReports({
			sort: sort,
			limit: pagination.pageSize,
			offset: (pagination.current - 1) * pagination.pageSize,
		});
		setCurrentTablePage(pagination.current);
	};

	const toggleSelectReport = ({ _id: reportId }) => {
		if (!reportId) return;

		if (allReportsSelected) {
			setAllReportsSelected(false);
		}

		const selectedIds = selectedReportIds.includes(reportId)
			? selectedReportIds.filter((stateFlowId) => stateFlowId !== reportId)
			: [...selectedReportIds, reportId];

		return setSelectedReportIds(selectedIds);
	};

	const selectAllPageReports = (selected) => {
		let tempSelectedReportIds = [];

		if (selected) {
			tempSelectedReportIds = reports.contents.map(({ _id }) => _id);
		}

		setSelectedReportIds([...new Set(tempSelectedReportIds)]);
	};

	if (reports.state === "hasError") {
		return (
			<ErrorState
				title={intl.formatMessage({ id: "FLOWS.REPORT.OVERVIEW.TABLE.FETCH_FAILED.TITLE" })}
				reloadButtonText={intl.formatMessage({ id: "FLOWS.REPORT.OVERVIEW.TABLE.FETCH_FAILED.BUTTON" })}
				onReloadClick={fetchReports}
			/>
		);
	}

	if (!isFiltered && reports.state === "hasValue" && !reportMetaData.total) {
		return <EmptyStateReports title={intl.formatMessage({ id: "FLOWS.REPORT.OVERVIEW.TABLE.EMPTY.TITLE" })} />;
	}

	return (
		<SkeletonTable
			rowKey="_id" // used for selection
			dataSource={reports.contents || []}
			isLoading={reports.state === "loading"}
			onRow={(report) => ({ onClick: (event) => handleRowClick({ event, report }) })}
			onChange={(pagination, _, sorter) => handleTableChange({ pagination, sorter })}
			pagination={{
				...config.table.pagination,
				total: reportMetaData?.total,
				current: currentTablePage,
			}}
			useSkeletonLoader={!reportMetaData.total || reports?.isFetchingNewFlow}
			className="fro-table"
			scroll={{ y: true }}
			locale={{
				emptyText: (
					<Result
						status="warning"
						title={intl.formatMessage({ id: "FLOWS.REPORT.OVERVIEW.TABLE.QUERY.NOT_FOUND" })}
					/>
				),
			}}
			rowSelection={{
				type: "checkbox",
				selectedRowKeys: selectedReportIds,
				onSelect: (report) => toggleSelectReport(report),
				onSelectAll: (selected, _, changeReports) => selectAllPageReports(selected, changeReports),
			}}
		>
			<Table.Column
				key="avatar"
				width="64px"
				dataIndex={["contact", "avatar"]}
				render={(avatar) => <Image.ADefault src={avatar || websiteIcon} className="small" />}
			/>
			<Table.Column
				key="firstName"
				title={<FormattedMessage id="FLOWS.REPORT.OVERVIEW.TABLE.FIRSTNAME" />}
				width="128px"
				dataIndex="contact"
				ellipsis={{ showTitle: false }}
				render={(contact) => {
					if (!contact || (!contact?.firstName && !contact?.lastName && !contact?.fullName)) {
						return <FormattedMessage id="FALLBACK.CONTACT.NAME.SHORT" />;
					}

					const name =
						!contact.firstName && !contact.lastName ? contact.fullName.split(" ")[0] : contact.firstName;

					return (
						<Tooltip placement="top" title={name}>
							{name}
						</Tooltip>
					);
				}}
				sorter={true}
			/>
			<Table.Column
				key="lastName"
				title={<FormattedMessage id="FLOWS.REPORT.OVERVIEW.TABLE.LASTNAME" />}
				width="128px"
				dataIndex={["contact", "lastName"]}
				ellipsis={{ showTitle: false }}
				render={(lastName) =>
					lastName && (
						<Tooltip placement="top" title={lastName}>
							{lastName}
						</Tooltip>
					)
				}
				sorter={true}
			/>
			<Table.Column
				key="assignee"
				dataIndex="assignee"
				width="160px"
				title={<FormattedMessage id="FLOWS.REPORT.OVERVIEW.TABLE.ASSIGNEE" />}
				ellipsis={{ showTitle: false }}
				className="fro-assignee-row"
				render={(assignee) =>
					assignee && (
						<Tooltip
							placement="top"
							title={
								assignee?.fullName ? assignee.fullName : `${assignee?.firstName} ${assignee?.lastName}`
							}
						>
							<Image.ADefault
								src={assignee.avatar}
								className="small t-gap--right-sm fro-assignee-image"
							/>
							<span className="fro-assignee-name">
								{assignee?.fullName
									? assignee.fullName
									: `${assignee?.firstName} ${assignee?.lastName}`}
							</span>
						</Tooltip>
					)
				}
			/>
			<Table.Column
				key="status"
				width="128px"
				dataIndex={["status", "name"]}
				title={<FormattedMessage id="FLOWS.REPORT.OVERVIEW.TABLE.STATUS" />}
			/>
			<Table.Column
				key="progress"
				dataIndex="progress"
				title={<FormattedMessage id="FLOWS.REPORT.OVERVIEW.TABLE.PROGRESS" />}
				sorter={true}
				width="128px"
				render={(progress) => (
					<Tooltip title={progress && <FormattedMessage id={progress} />}>
						<Progress
							percent={calculateProgress(progress).percentage}
							strokeColor={calculateProgress(progress).color}
							showInfo={false}
							trailColor="rgba(#002140, 0.1)"
						/>
					</Tooltip>
				)}
			/>
			<Table.Column
				key="labels"
				title={<FormattedMessage id="FLOWS.REPORT.OVERVIEW.TABLE.LABELS" />}
				dataIndex={[]}
				render={(report) => {
					const renderLabels = report.contact?.labels?.slice(0, MAX_LABELS).filter(Boolean);
					const extraLabels = report.contact?.labels
						?.slice(MAX_LABELS)
						?.reduce((prev, label) => (label ? [...prev, label?.name] : prev), []);

					return (
						<div style={{ display: "flex", gap: "0.5em", flexWrap: "wrap" }}>
							{renderLabels?.map((label) => {
								if (!label) return;

								return (
									<Tooltip title={label.name}>
										<Tag
											key={label._id}
											color={label.color}
											style={{
												marginRight: 0,
												maxWidth: "160px",
												overflow: "hidden",
												textOverflow: "ellipsis",
												whiteSpace: "nowrap",
											}}
										>
											{label.name}
										</Tag>
									</Tooltip>
								);
							})}
							{!!extraLabels?.length && (
								<Tooltip title={extraLabels?.length ? extraLabels?.join(", ") : ""}>
									<Tag
										intent="count"
										style={{
											marginRight: 0,
										}}
									>
										{extraLabels?.length}
									</Tag>
								</Tooltip>
							)}
						</div>
					);
				}}
			/>
			<Table.Column
				key="createdAt"
				dataIndex="createdAt"
				width="144px"
				sorter={true}
				title={<FormattedMessage id="FLOWS.REPORT.OVERVIEW.TABLE.CREATED_AT" />}
				render={(value) => <FormattedDate value={value} />}
			/>
			<Table.Column
				key="lastFilledInAt"
				dataIndex="lastFilledInAt"
				width="144px"
				sorter={true}
				title={<FormattedMessage id="FLOWS.REPORT.OVERVIEW.TABLE.LAST_UPDATED" />}
				render={(value) => value && <FormattedDate value={value} />}
			/>
			<Table.Column key="edit" width="40px" render={() => <RightOutlined />} />
		</SkeletonTable>
	);
}
