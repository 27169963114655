import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import type { IQueue } from "@bothive_core/database";

import { Avatar, List, Tag } from "antd";
import { ReactNode } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { formatNumberToOrdinal } from "../../../../../../helpers/format.helpers";

type TStatusConfig = Record<
	string,
	{ color: "success" | "error"; intlText: { id: string; defaultMessage: string }; icon: ReactNode }
>;

const statusConfig: TStatusConfig = {
	SUCCESS: {
		color: "success",
		intlText: {
			id: "FLOW.DETAIL.REMINDERS.STATUS.SUCCESS",
			defaultMessage: "Succeeded",
		},
		icon: <CheckCircleOutlined />,
	},
	FAILED: {
		color: "error",
		intlText: {
			id: "FLOW.DETAIL.REMINDERS.STATUS.FAILED",
			defaultMessage: "Failed",
		},
		icon: <ExclamationCircleOutlined />,
	},
};

interface ListItemProps {
	reminderQueueItem: any;
}

export default function ListItem({ reminderQueueItem }: ListItemProps) {
	const statusData: TStatusConfig[number] | undefined =
		statusConfig[(reminderQueueItem.status as IQueue["status"]) || "FAILED"];

	const intl = useIntl();
	return (
		<List.Item
			key={reminderQueueItem._id}
			style={{
				display: "grid",
				gap: "2em",
				gridTemplateColumns: "2fr 1fr 1fr 15ch",
				alignItems: "center",
			}}
		>
			<span style={{ display: "flex", alignItems: "center" }}>
				<Avatar
					src={
						reminderQueueItem.contact?.avatar ||
						`https://ui-avatars.com/api/?name=${reminderQueueItem.email?.[0]}+${reminderQueueItem.email?.[1]}&background=D4D6DB&color=293249&size=224`
					}
					shape="square"
					size="large"
					className="t-gap--right-sm"
				/>
				<div>
					<p>{reminderQueueItem.contact?.fullName}</p>
					<p style={{ fontSize: "1em", color: "GrayText" }}>{reminderQueueItem.email}</p>
				</div>
			</span>
			<p>
				<FormattedMessage
					id="FLOW.DETAIL.REMINDERS.HISTORY.LIST.ITEM.REMINDER_COUNT"
					defaultMessage="{count} reminder"
					values={{ count: formatNumberToOrdinal({ number: reminderQueueItem.index, intl }) }}
				/>
			</p>
			<p>
				<FormattedDate
					value={reminderQueueItem.executeAt}
					day="2-digit"
					month="2-digit"
					year="numeric"
					hour="2-digit"
					minute="2-digit"
					formatMatcher="basic"
				/>
			</p>

			<span
				style={{
					display: "flex",
					justifyContent: "end",
				}}
			>
				<Tag
					style={{
						width: "fit-content",
						height: "fit-content",
						margin: 0,
					}}
					icon={statusData?.icon}
					color={statusData?.color}
				>
					<FormattedMessage
						id={statusData?.intlText.id || "NOT FOUND"}
						defaultMessage={statusData?.intlText.defaultMessage || "SUCCEEDED"}
					/>
				</Tag>
			</span>
		</List.Item>
	);
}
