import React from "react";
import { injectIntl } from "react-intl";

class ATextField extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value || "",
			error: props.error,
		};

		this.bindHandles();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.error !== state.error) {
			return { error: props.error };
		}

		if (props.value !== state.value) {
			return { value: props.value };
		}

		return null;
	}

	bindHandles() {
		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleKeyUp(event) {
		if (this.props.onKeyUp) {
			this.props.onKeyUp({ event, value: this.state.value });
		}
	}

	handleChange(event) {
		const value = event.target.value;
		const error = this.props.required && value.length < 1;

		if (this.props.onChange) {
			this.props.onChange(value);
		}

		this.setState({ value, error });
	}

	render() {
		const cName = `a-input ${this.props.className}
			${this.state.error ? "error" : ""} ${this.props.disabled ? "disabled" : ""}`;
		const placeholder = this.props.placeholder ? this.props.intl.formatMessage({ id: this.props.placeholder }) : "";
		const props = { ...this.props };

		delete props.error;
		delete props.optional;
		delete props.defaultValue;
		delete props.fieldConfig;
		delete props.children;

		return (
			<div className={cName} data-value={this.state.value} style={this.props.style}>
				<input
					{...props}
					value={this.state.value}
					placeholder={placeholder}
					aria-label={props.label ? this.props.intl.formatMessage({ id: props.label }) : ""}
					className="a-input--field a-input--no-outline"
					aria-required={this.props.required}
					aria-invalid={this.state.error}
					onChange={this.handleChange}
					onKeyUp={this.handleKeyUp}
				/>
				{this.props.children}
			</div>
		);
	}
}

ATextField.defaultProps = {
	name: "nameDefaultInput-change-this",
	value: "",
	autoComplete: "off",
	className: "",
	disabled: false,
	required: false,
	error: false,

	onChange: undefined,
	onKeyUp: undefined,
};

export default injectIntl(ATextField);
