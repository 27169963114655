import { Form, FormInstance, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import dayjs from "dayjs";

import FieldLabel from "@/shared/components/field/default";
import DateFormItem from "@/shared/components/input/DateFormItem";
import { maritalStatusTypes, matrimonialPropertySystemTypes } from "../config";

export default function PartnerForm({ form }: { form: FormInstance }) {
	const intl = useIntl();

	const maritalStatus = Form.useWatch("maritalStatus", form);
	const isDivorced = ["separated", "defactoSeparated"].includes(maritalStatus);

	return (
		<>
			<FieldLabel
				id="maritalStatus"
				label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.MARITAL_STATUS"
				optional
			>
				<Form.Item name="maritalStatus" style={{ width: "100%", textAlign: "left" }}>
					<Select>
						{Object.keys(maritalStatusTypes).map((key) => (
							<Select.Option key={key} value={key}>
								<FormattedMessage id={maritalStatusTypes[key]} />
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</FieldLabel>
			{"cohabiting" !== maritalStatus && !isDivorced && (
				<FieldLabel
					optional
					id="matrimonialPropertySystem"
					label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.MATRIMONIAL_PROPERTY_SYSTEM"
				>
					<Form.Item style={{ width: "100%", textAlign: "left" }} name="matrimonialPropertySystem">
						<Select>
							{Object.keys(matrimonialPropertySystemTypes).map((key) => (
								<Select.Option key={key} value={key}>
									<FormattedMessage id={matrimonialPropertySystemTypes[key]} />
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</FieldLabel>
			)}
			{"cohabiting" !== maritalStatus && !isDivorced && (
				<FieldLabel
					id="weddingDate"
					label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.WEDDING_DATE"
					optional
				>
					<Form.Item name="weddingDate" style={{ width: "100%", textAlign: "left" }}>
						<DateFormItem
							style={{ width: "100%" }}
							disabledDate={(current) => current && current > dayjs().endOf("day")}
						/>
					</Form.Item>
				</FieldLabel>
			)}
			{isDivorced && (
				<FieldLabel id="divorceDate" optional label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.DIVORCE_DATE">
					<Form.Item
						name="divorceDate"
						style={{ width: "100%", textAlign: "left" }}
						rules={[
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value) {
										return Promise.resolve();
									}

									const startDate = getFieldValue("weddingDate");
									if (
										Date.parse(new Date(value).toString()) <
										Date.parse(new Date(startDate).toString())
									) {
										const label = intl.formatMessage({
											id: "CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.DIVORCE_DATE.ERROR",
										});

										return Promise.reject(new Error(label));
									}

									return Promise.resolve();
								},
							}),
						]}
					>
						<DateFormItem
							style={{ width: "100%" }}
							disabledDate={(current) => current && current > dayjs().endOf("day")}
						/>
					</Form.Item>
				</FieldLabel>
			)}
			{"widower" === maritalStatus && (
				<FieldLabel id="widowDate" optional label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.WIDOW_DATE">
					<Form.Item
						name="widowDate"
						style={{ width: "100%", textAlign: "left" }}
					>
						<DateFormItem
							style={{ width: "100%" }}
							disabledDate={(current) => current && current > dayjs().endOf("day")}
						/>
					</Form.Item>
				</FieldLabel>
			)}
			{"cohabiting" === maritalStatus && (
				<FieldLabel
					id="cohabitationDate"
					optional
					label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.COHABITATION_DATE"
				>
					<Form.Item
						name="cohabitationDate"
						style={{ width: "100%", textAlign: "left" }}
					>
						<DateFormItem
							style={{ width: "100%" }}
							disabledDate={(current) => current && current > dayjs().endOf("day")}
						/>
					</Form.Item>
				</FieldLabel>
			)}
			{"cohabiting" === maritalStatus && (
				<FieldLabel
					id="cancelCohabitationDate"
					label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.END_COHABITATION_DATE"
					optional
				>
					<Form.Item
						name="cancelCohabitationDate"
						style={{ width: "100%", textAlign: "left" }}
						rules={[
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value) return Promise.resolve();

									const startDate = getFieldValue("cohabitationDate");

									if (
										Date.parse(new Date(value).toString()) <
										Date.parse(new Date(startDate).toString())
									) {
										const label = intl.formatMessage({
											id: "CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.PARTNER.END_COHABITATION_DATE.ERROR",
										});

										return Promise.reject(new Error(label));
									}

									return Promise.resolve();
								},
							}),
						]}
					>
						<DateFormItem
							style={{ width: "100%" }}
							disabledDate={(current) => current && current > dayjs().endOf("day")}
						/>
					</Form.Item>
				</FieldLabel>
			)}
		</>
	);
}
