import React from "react";
import { injectIntl } from "react-intl";

import { Component } from "..";

function ANormalHeader({
	id,
	value,
	placeholder,
	active,
	hideIcon,
	className,
	iconClass,
	disabled,
	onClick,
	onKeyUp,
	setRef,
	intl,
}) {
	if (typeof value !== "string") {
		value = undefined;
	}

	return (
		<div
			id={id}
			role="button"
			tabIndex="0"
			aria-pressed={active}
			aria-expanded={active}
			onClick={onClick}
			onKeyUp={onKeyUp}
			ref={setRef}
			data-disabled={disabled}
			className={`a-dropdown-header ${className} ${active ? "active" : ""}`}
		>
			<p className={`${!value && placeholder ? "placeholder" : ""} t-gap--right-sm`}>
				{value || (placeholder && intl.formatMessage({ id: placeholder }))}
			</p>
			{!hideIcon && (
				<Component.AIcon
					type={`arrow--${active ? "up" : "down"}`}
					className={`fill dropdown-header--icon ${iconClass}`}
				/>
			)}
		</div>
	);
}

export default injectIntl(ANormalHeader);
