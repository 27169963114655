import { routesConfig } from "@/config";
import { Route, Switch } from "react-router-dom";
import IntegrationOverview from "../../overview";
import AdminPulse from "../../adminPulse";
import FidManager from "../../fidManager";
import Twinntax from "../../twinntax";
import Liantis from "../../liantis";

export default function IntegrationRoutes() {
	return (
		<Switch>
			<Route path={routesConfig.dashboard.settings.integrations.adminPulseIntegration} component={AdminPulse} />
			<Route path={routesConfig.dashboard.settings.integrations.fidManagerIntegration} component={FidManager} />
			<Route path={routesConfig.dashboard.settings.integrations.twinntaxIntegration} component={Twinntax} />
			<Route path={routesConfig.dashboard.settings.integrations.liantisIntegration} component={Liantis} />
			<Route path={routesConfig.dashboard.settings.integrations.overview} component={IntegrationOverview} />
		</Switch>
	);
}
