import React from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";

import { stringHelpers } from "../../../helpers";

function AFieldLabel({ id, label, labelValue, optional, error, help, value, maxLength, className, children, intl }) {
	let maxLengthText,
		optionalText,
		helpText = <p></p>;

	if (optional) {
		optionalText = (
			<span className="form--optional">
				<FormattedMessage id="FORM_ELEMENT.LABEL.OPTIONAL" />
			</span>
		);
	}

	if (help && help !== "") {
		helpText = (
			<p className="form--help-label">
				<FormattedMessage id={help} values={labelValue} />
			</p>
		);
	}

	if (maxLength && maxLength !== 0) {
		maxLengthText = (
			<p className="form--help-label">
				<FormattedNumber value={stringHelpers.getValueLength(value)} />/<FormattedNumber value={maxLength} />
			</p>
		);
	}

	if (error && error !== "") {
		const injectedLabel = label ? label : "FORM_ELEMENT.ERROR.THIS_FIELD";

		helpText = (
			<p className="form--help-label error small">
				<FormattedMessage id={error} values={{ label: intl.formatMessage({ id: injectedLabel }) }} />
			</p>
		);
	}

	return (
		<div className={`a-form-element ${className}`}>
			<label htmlFor={id} className="form--label">
				{label && <FormattedMessage id={label} values={labelValue} />}
				{optionalText}
			</label>
			<div className="form--wrapper">{children}</div>
			<div className="form--wrapper">
				{helpText}
				{maxLengthText}
			</div>
		</div>
	);
}

export default injectIntl(AFieldLabel);
