import { defineMessage } from "react-intl";

export const chipConfig = {
	announcement: {
		label: defineMessage({
			id: "notification.list_item.chip.announcement",
			defaultMessage: "Announcement",
		}),
		color: "#6CA4D9",
	},
	integrationRefresh: {
		label: defineMessage({
			id: "notification.list_item.chip.integration_refresh",
			defaultMessage: "Refresh",
		}),
		color: "orange",
	},
	conversationStarted: {
		label: defineMessage({
			id: "notification.list_item.chip.conversation_started",
			defaultMessage: "New conversation",
		}),
		color: "#2B3F7A",
	},
	"contact.import.succeeded": {
		label: defineMessage({
			id: "notification.list_item.chip.contact_import",
			defaultMessage: "Contact import",
		}),
		color: "#3FA6FA",
	},
	"contact.import.failed": {
		label: defineMessage({
			id: "notification.list_item.chip.contact_import",
			defaultMessage: "Contact import",
		}),
		color: "#de5050",
	},
	"contact.webhook.sync.failed": {
		label: defineMessage({
			id: "notification.list_item.chip.contact_webhook_sync",
			defaultMessage: "Contact Webhook",
		}),
		color: "#004d8c",
	},
	"inbox.assigned_to_conversation": {
		label: defineMessage({
			id: "notification.list_item.chip.assigned_to_conversation",
			defaultMessage: "Conversation assignment",
		}),
		color: "#6755FA",
	},
	"inbox.added_as_participant": {
		label: defineMessage({
			id: "notification.list_item.chip.added_as_participant",
			defaultMessage: "Participant",
		}),
		color: "#6755FA",
	},
	"inbox.sending_failed": {
		label: defineMessage({
			id: "notification.list_item.chip.sending_failed", // "notification.list_item.chip.message_error",
			defaultMessage: "Sending failed",
		}),
		color: "#de5050",
	},
	assignConversation: {
		label: defineMessage({
			id: "notification.list_item.chip.assigned_to_conversation",
			defaultMessage: "Conversation assignment",
		}),
		color: "#6755FA",
	},
	mention: {
		label: defineMessage({
			id: "notification.list_item.chip.mentioned",
			defaultMessage: "Mentioned",
		}),
		color: "#128C7E",
	},
	"inbox.mentioned": {
		label: defineMessage({
			id: "notification.list_item.chip.mentioned",
			defaultMessage: "Mentioned",
		}),
		color: "#128C7E",
	},
	integrationError: {
		label: defineMessage({
			id: "notification.list_item.chip.integration_error",
			defaultMessage: "Error",
		}),
		color: "#de5050",
	},
	flowAssign: {
		label: defineMessage({
			id: "notification.list_item.chip.assigned_to_flow",
			defaultMessage: "Flow assignment",
		}),
		color: "#4285F4",
	},
	flowReport: {
		label: defineMessage({
			id: "notification.list_item.chip.new_flow_report",
			defaultMessage: "Flow report",
		}),
		color: "#815DE3",
	},
	flowReminderFailed: {
		label: defineMessage({
			id: "notification.list_item.chip.flow_reminder_failed",
			defaultMessage: "Flow reminder error",
		}),
		color: "#de5050",
	},
	reportBulkDownload: {
		label: defineMessage({
			id: "notification.list_item.chip.report.bulk_download",
			defaultMessage: "Bulk download reports",
		}),
		color: "#2EB81F",
	},
	synchronization: {
		label: defineMessage({
			id: "notification.list_item.chip.synchronization",
			defaultMessage: "Synchronization",
		}),
		color: "#3FA6FA",
	},
	flowSending: {
		label: defineMessage({
			id: "notification.list_item.chip.flow_sending",
			defaultMessage: "Flow sending",
		}),
		color: "#2EB81F",
	},
};
