import { IInboxLabel } from "@bothive_core/database";
import { Button, Modal, Tooltip } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import DeleteModal from "@/shared/components/modal/delete_modal";
import { trpc } from "@/trpc";
import InboxLabelForm from "./inboxLabelForm";

interface UpdateModalArgs {
	selectedInboxLabel: IInboxLabel;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function MutateModal({ isOpen, setIsOpen, selectedInboxLabel }: UpdateModalArgs) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	function cleanUpSuccessfulMutation() {
		trpcUtils.inbox.inboxLabel.getAll.invalidate();
		setIsDeleteModalOpen(false);
		setIsOpen(false);
	}

	// Do the exact same query as in initial inboxLabelForm so it's more performant to use this cached version without refetch.
	// If in the form the q parameter changes for channel search, a refetch will still be done for that component only.
	const { data: organizationChannels } = trpc.channel.getInboxChannels.useQuery(
		// TODO
		{ q: "" },
		{ staleTime: Infinity }
	);

	const updateInboxLabelMutation = trpc.inbox.inboxLabel.update.useMutation({
		onSuccess: () => {
			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "inbox.label.modal.update.action.update.result.success",
					defaultMessage: "Inbox label updated successfully.",
				}),
			});

			cleanUpSuccessfulMutation();
		},
		onError: () => {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "inbox.label.modal.update.action.update.result.error",
					defaultMessage: "Failed to update inbox label.",
				}),
			});
		},
	});

	const removeInboxLabelMutation = trpc.inbox.inboxLabel.remove.useMutation({
		onSuccess: () => {
			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "inbox.label.modal.update.action.delete.result.success",
					defaultMessage: "Inbox label deleted successfully.",
				}),
			});

			cleanUpSuccessfulMutation();
		},
		onError: () => {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "inbox.label.modal.update.action.delete.result.error",
					defaultMessage: "Failed to delete inbox label.",
				}),
			});
		},
	});

	const currentChannelIsDisabled = Boolean(
		organizationChannels?.data?.find((channel) => channel._id === selectedInboxLabel.channelId)?.disabled
	);

	return (
		<Modal
			width={700}
			title={intl.formatMessage({
				id: "inbox.label.modal.update.title",
				defaultMessage: "Edit inbox label",
			})}
			open={isOpen || false}
			destroyOnClose
			onCancel={() => {
				setIsOpen(false);
			}}
			footer={[
				<Button
					danger
					type="primary"
					form="createInboxLabel"
					loading={removeInboxLabelMutation.isLoading}
					onClick={() => setIsDeleteModalOpen(true)}
				>
					<FormattedMessage
						id="inbox.label.modal.update.actions.delete.button.title"
						defaultMessage="Delete"
					/>
				</Button>,
				<Tooltip
					title={
						currentChannelIsDisabled &&
						intl.formatMessage({
							id: "inbox.label.modal.update.actions.save_changes.button.disabled",
							defaultMessage:
								"Before applying any changes, please ensure that you reconnect your channel",
						})
					}
				>
					<Button
						className="t-gap--left-sm"
						disabled={currentChannelIsDisabled}
						key="submit"
						type="primary"
						form="createInboxLabel"
						htmlType="submit"
						loading={updateInboxLabelMutation.isLoading}
					>
						<FormattedMessage
							id="inbox.label.modal.update.actions.save_changes.button.title"
							defaultMessage="Save changes"
						/>
					</Button>
				</Tooltip>,
			]}
		>
			<InboxLabelForm
				onFinish={(values) =>
					updateInboxLabelMutation.mutate({
						inboxLabelId: selectedInboxLabel._id,
						...values,
					})
				}
				disableChannelInput={selectedInboxLabel.type === "channel"}
				initialValues={{
					...(selectedInboxLabel.name && { name: selectedInboxLabel.name }),
					...(selectedInboxLabel.description && { description: selectedInboxLabel.description }),
					...(selectedInboxLabel.color && { color: selectedInboxLabel.color }),
					...(selectedInboxLabel.type && { type: selectedInboxLabel.type }),
					...(selectedInboxLabel.channelId && { channelId: selectedInboxLabel.channelId }),
				}}
			/>
			<DeleteModal
				title={intl.formatMessage({
					id: "inbox.label.modal.delete.title",
					defaultMessage: "Delete inbox label",
				})}
				descriptionType="info"
				showDescriptionIcon
				isDeleting={removeInboxLabelMutation.isLoading}
				deleteLabel={intl.formatMessage({
					id: "inbox.label.modal.delete.action.delete.button.title",
					defaultMessage: "Delete",
				})}
				description={intl.formatMessage({
					id: "inbox.label.modal.delete.action.consequence",
					defaultMessage:
						"Deleting this inbox label will remove it from associated channels and conversations. This action is irreversible, so please confirm before proceeding.",
				})}
				isVisible={isDeleteModalOpen}
				onDelete={() =>
					removeInboxLabelMutation.mutate({
						inboxLabelId: selectedInboxLabel._id,
						channelId: selectedInboxLabel.channelId ? String(selectedInboxLabel.channelId) : undefined,
					})
				}
				onClose={() => setIsDeleteModalOpen(false)}
			/>
		</Modal>
	);
}
