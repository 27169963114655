import "./style.scss";

import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { IEmailSignature } from "@bothive_core/database";
import { Button, Empty, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { emptyList } from "@/assets/images/icon";
import settingsConfig from "@/modules/settings/config";
import SkeletonTable from "@/shared/components/table/skeleton";
import { actions as dashboardActions } from "@/store/dashboard";
import { trpc } from "@/trpc";
import { ShadowEmailView } from "../../../../shared/components/shadowDom";
import SignatureModal from "./signatureModal";

export default function ChannelsOverview() {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
	const routeParameters = useParams<{ team: string }>();
	const [selectedSignature, setSelectedSignature] = useState<IEmailSignature | undefined>();

	const { data: signatureData, isFetching } = trpc.emailSignature.getSignatures.useQuery(
		{
			offset: (pagination.current - 1) * pagination.pageSize,
			limit: pagination.pageSize,
		},
		{ refetchOnWindowFocus: false, staleTime: Infinity }
	);

	useEffect(() => {
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, []);

	useEffect(() => {
		const title = intl.formatMessage({
			id: "settings.email_signature.title",
			defaultMessage: "Email signatures",
			description: "Browser tab title for email signature settings",
		});

		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					id: "emailSignatures",
					title: title,
					tabTitle: title,
					breadcrumbs: [
						{
							to: settingsConfig.routes.root.replace(":team", routeParameters.team),
							name: intl.formatMessage({
								id: "settings.email_signature.breadcrumb.settings",
								defaultMessage: "Settings",
								description: "Breadcrumb in channel page to settings overview",
							}),
						},
						{
							to: `/${routeParameters.team}/settings/inbox/signature`,
							name: title,
						},
					],
				},
				intl,
			})
		);
	}, []);

	const handleRowClick = (emailSignature: IEmailSignature) => {
		if (!emailSignature) return;

		setSelectedSignature(emailSignature);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setSelectedSignature(undefined);
		setIsModalOpen(false);
	};

	return (
		<main className="signatures_overview">
			<header className="signatures_overview-header">
				<Button
					type="primary"
					size="large"
					style={{ width: "max-content" }}
					onClick={() => setIsModalOpen(true)}
				>
					<FormattedMessage
						id="settings.email_signature.header.add.button.label"
						defaultMessage="Add email signature"
					/>
				</Button>
			</header>
			<SkeletonTable
				rowKey="_id"
				isLoading={isFetching}
				dataSource={signatureData?.data}
				className="signatures_overview-table t-gap--top"
				onRow={isFetching ? null : (signature) => ({ onClick: () => handleRowClick(signature) })}
				onChange={(pagination: any) => setPagination(pagination)}
				pagination={{
					current: pagination.current,
					pageSize: pagination.pageSize,
					total: signatureData?.total,
					showSizeChanger: true,
					defaultPageSize: 20,
					pageSizeOptions: ["20", "50", "100", "200"],
				}}
				locale={{
					emptyText: (
						<Empty
							image={emptyList}
							imageStyle={{ height: "256px" }}
							description={
								<FormattedMessage
									id="settings.email_signature.table.empty"
									defaultMessage="No email signatures found"
									description="Fallback text when no email signatures have been found"
								/>
							}
						/>
					),
				}}
			>
				<Table.Column
					ellipsis
					key="name"
					width="20%"
					dataIndex="name"
					title={intl.formatMessage({
						id: "settings.email_signature.table.name.label",
						defaultMessage: "Signature name",
						description: "Table column title for signature name",
					})}
				/>
				<Table.Column
					width="40%"
					key="content"
					dataIndex="content"
					title={intl.formatMessage({
						id: "settings.email_signature.table.content.label",
						defaultMessage: "Preview",
						description: "Table column title for signature preview",
					})}
					className="signatures_overview-row-content"
					render={(value) => <ShadowEmailView html={value} />}
				/>
				<Table.Column
					key="isPrimary"
					width="96px"
					align="center"
					dataIndex="isPrimary"
					title={intl.formatMessage({
						id: "settings.email_signature.table.is_primary.label",
						defaultMessage: "Is primary",
					})}
					render={(value) =>
						value && (
							<Typography.Text>
								<CheckOutlined />
							</Typography.Text>
						)
					}
				/>
				<Table.Column
					key="edit"
					width="48px"
					align="center"
					dataIndex="edit"
					render={() => (
						<Typography.Text>
							<EditOutlined />
						</Typography.Text>
					)}
				/>
			</SkeletonTable>
			<SignatureModal
				key={selectedSignature?._id || Date.now()}
				isOpen={isModalOpen}
				signature={selectedSignature}
				onClose={handleCloseModal}
				isFirstSignature={signatureData?.total === 0}
			/>
		</main>
	);
}
