import React from "react";

import { keyEvents } from "../../../helpers";

export default class OPopupBase extends React.Component {
	constructor(props) {
		super(props);
		this.bindHandles();
	}

	componentDidMount() {
		document.addEventListener("keydown", this.handleKeyDown);
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.handleKeyDown);
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	bindHandles() {
		this.handleWrapperRef = this.handleWrapperRef.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	handleWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleKeyDown(event) {
		if (keyEvents.escapePressed(event) && this.props.onClose) {
			this.props.onClose();
		}
	}

	handleClickOutside(event) {
		if (this.props.onClose && this.wrapperRef && !this.wrapperRef.contains(event.target) && this.props.onClose) {
			this.props.onClose();
		}
	}

	render() {
		const { title, className, children, ...props } = this.props;

		return (
			<section
				{...props}
				role="dialog"
				aria-labelledby={`dialog-${title}`}
				className={`o-popup o-popup--general ${className}`}
				ref={this.handleWrapperRef}
			>
				{children}
			</section>
		);
	}
}
