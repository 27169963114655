import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { trpc } from "../../../../trpc";

import ContactLabelFormItems from "@/settings/contact/labels/components/ContactLabelFormItems";
import ShowFailedBanner from "@/shared/components/banner/failed";
import { Alert, Button, FormInstance, Modal } from "antd";

interface ContactLabelModalProps {
	open: boolean;
	label?: {
		_id: string;
		name: string;
		description: string;
		color: string;
		isSystem: boolean;
	};
	setShowUpdateModal: (boolean) => void;
}

export default function UpdateContactLabelModal({ open, label, setShowUpdateModal }: ContactLabelModalProps) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [formInstance, setFormInstance] = useState<FormInstance>();
	const updateLabelMutation = trpc.contactLabel.updateById.useMutation();
	const deleteLabelMutation = trpc.contactLabel.deleteById.useMutation();

	const handleSubmit = (values) => {
		updateLabelMutation.mutate(
			{ labelId: label?._id, ...values },
			{
				onSuccess: () => {
					trpcUtils.contactLabel.all.invalidate();
					setShowUpdateModal(false);
				},
				onError: (error) => {
					if (error?.data?.code === "CONFLICT") {
						formInstance?.setFields([
							{
								name: "name",
								errors: [intl.formatMessage({ id: "CONTACTS.SETTINGS.NAME.DUPLICATE" })],
							},
						]);

						return;
					}

					ShowFailedBanner({
						title: intl.formatMessage({
							id: "BANNER.FAILED.UPDATE_LABEL",
							defaultMessage: "Label could not be updated. Please try again later.",
						}),
					});
					setShowUpdateModal(false);
				},
			}
		);
	};

	const handleDelete = () => {
		if (!label) return;
		deleteLabelMutation.mutate(
			{ labelId: label._id },
			{
				onSuccess: () => {
					trpcUtils.contactLabel.all.invalidate();
					setShowUpdateModal(false);
					setShowDeleteModal(false);
				},
				onError: () => {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "BANNER.FAILED.DELETE_LABEL",
							defaultMessage: "Label could not be removed. Please try again later.",
						}),
					});
					setShowDeleteModal(false);
				},
			}
		);
	};

	return (
		<Modal
			open={open}
			destroyOnClose
			onCancel={() => setShowUpdateModal(false)}
			title={<FormattedMessage id="contacts_label_modal.update.title" defaultMessage="Update label" />}
			width={700}
			footer={
				!label?.isSystem && [
					<Button
						key="delete"
						danger
						type="primary"
						loading={deleteLabelMutation.isLoading}
						onClick={() => setShowDeleteModal(true)}
					>
						<FormattedMessage id="contacts_label_modal.update.footer.delete" defaultMessage="Delete" />
					</Button>,
					<Button
						key="submit"
						type="primary"
						form="contactLabelForm"
						htmlType="submit"
						loading={updateLabelMutation.isLoading}
					>
						<FormattedMessage id="contacts_label_modal.update.footer.submit" defaultMessage="Update" />
					</Button>,
				]
			}
		>
			{label?.isSystem && (
				<Alert
					message={intl.formatMessage({
						id: "contacts_label_modal.system_label.warning",
						defaultMessage: "This is a system label created by us, it cannot be changed.",
					})}
					type="warning"
					showIcon
					className="t-gap--bottom-sm"
				/>
			)}
			<ContactLabelFormItems
				value={label}
				disabled={label?.isSystem}
				onSubmit={handleSubmit}
				getFormInstance={setFormInstance}
			/>
			<Modal
				open={showDeleteModal}
				title={<FormattedMessage id="contacts_label_modal.delete_label.title" defaultMessage="Delete label" />}
				footer={[
					<Button
						key="submit"
						type="text"
						form="contactLabelForm"
						htmlType="submit"
						loading={updateLabelMutation.isLoading}
					>
						<FormattedMessage id="contacts_label_modal.update.footer.cancel" defaultMessage="Cancel" />
					</Button>,
					<Button
						key="delete"
						danger
						type="primary"
						loading={deleteLabelMutation.isLoading}
						onClick={handleDelete}
					>
						<FormattedMessage id="contacts_label_modal.update.footer.delete" defaultMessage="Delete" />
					</Button>,
				]}
				onCancel={() => setShowDeleteModal(false)}
			>
				<p>
					<FormattedMessage
						id="contacts_label_modal.delete_label.body"
						defaultMessage="Are you sure you want to remove this label? This will remove the label from all your contacts and synchronisation settings"
					/>
				</p>
			</Modal>
		</Modal>
	);
}
