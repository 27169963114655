import { validator } from "../helpers";

// Are used in flow config
// dropdown
// tagInput
// tagDropdown
// textArea
// textField
// checkBox
// colorPicker
const templates = {
	checkBox: "checkBox",
	dropdown: "dropdown",
	password: "password",
	radioButton: "radioButton",
	tagInput: "tagInput",
	tagDropdown: "tagDropdown",
	textArea: "textArea",
	textField: "textField",
};

export default {
	channelDetail: {
		deleteChannel: [
			{
				id: "delete",
				key: "delete",
				placeholder: "POPUP.PLACEHOLDER.DESTRUCTIVE.CHANNEL",
				required: true,
				autoFocus: true,
				defaultValue: ["", ["required", "valueIsDELETE"]],
				autoComplete: "off",
				template: templates.textField,
			},
		],
	},
	profile: {
		overview: {
			general: [
				{
					id: "avatar",
					key: "avatar",
					label: "PROFILE.GENERAL.AVATAR",
					title: "PROFILE.GENERAL.AVATAR_MODAL",
					help: "PROFILE.GENERAL.AVATAR_HELP",
					required: true,
					defaultValue: ["", ["imageIsUploading"]],
					fieldClass: "o-form--user-segment",
					template: templates.imageUpload,
				},
				{
					id: "firstName",
					key: "firstName",
					label: "PROFILE.GENERAL.FIRST_NAME",
					maxLength: 20,
					required: true,
					defaultValue: ["", ["required", "maxLength(20)"]],
					fieldClass: "o-form--user-first-name",
					autoComplete: "given-name",
					template: templates.textField,
				},
				{
					id: "lastName",
					key: "lastName",
					label: "PROFILE.GENERAL.LAST_NAME",
					maxLength: 20,
					required: true,
					defaultValue: ["", ["required", "maxLength(20)"]],
					fieldClass: "o-form--user-last-name",
					autoComplete: "family-name",
					template: templates.textField,
				},
				{
					id: "email",
					key: "email",
					label: "PROFILE.GENERAL.EMAIL",
					type: "email",
					required: true,
					disabled: true,
					defaultValue: ["", ["required", "email"]],
					fieldClass: "o-form--user-email",
					autoComplete: "email",
					template: templates.textField,
				},
				{
					id: "phone",
					key: "phone",
					label: "PROFILE.GENERAL.PHONE_NUMBER",
					type: "tel",
					optional: true,
					defaultValue: ["", []],
					fieldClass: "o-form--user-phone-number",
					autoComplete: "tel",
					template: templates.textField,
				},
			],
			security: [
				{
					id: "oldPassword",
					key: "oldPassword",
					type: "password",
					required: true,
					label: "PROFILE.SECURITY.OLD_PASSWORD",
					defaultValue: ["", "required", "passwordValidator"],
					autoComplete: "new-password",
					template: templates.password,
				},
				{
					id: "password",
					key: "password",
					type: "password",
					required: true,
					label: "PROFILE.SECURITY.NEW_PASSWORD",
					defaultValue: ["", "required"],
					template: templates.password,
				},
			],
			delete: [
				{
					id: "delete",
					key: "delete",
					placeholder: "POPUP.PLACEHOLDER.DESTRUCTIVE",
					required: true,
					defaultValue: ["", ["required", "valueIsDELETE"]],
					template: templates.textField,
				},
			],
		},
		advancedSettings: {
			delete: [
				{
					id: "delete",
					key: "delete",
					placeholder: "POPUP.PLACEHOLDER.DESTRUCTIVE",
					required: true,
					defaultValue: ["", ["required", "valueIsDELETE"]],
					template: templates.textField,
				},
			],
		},
	},
	settings: {
		delete: [
			{
				id: "delete",
				key: "delete",
				placeholder: "POPUP.PLACEHOLDER.DESTRUCTIVE",
				required: true,
				defaultValue: ["", ["required", "valueIsDELETE"]],
				template: templates.textField,
			},
		],
	},
	templates,
};
