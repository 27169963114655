import "./style.scss";

import { Button, Form, Input, InputNumber, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import { apiConfig } from "@/config";
import Checkbox from "@/shared/components/checkbox";
import FieldLabel from "@/shared/components/field/default";
import FieldSideLabel from "@/shared/components/field/side_label";
import { ExternalLink } from "@/shared/components/link";
import {
	debounceHelpers,
	globalHeaderHelpers,
	queryStringHelpers,
	storageHelpers,
	stringHelpers,
} from "@/shared/helpers";
import ShowFailedBanner from "../../../shared/components/banner/failed";
import { trpc } from "../../../trpc";
import { userState } from "../state";

export default function CreateOrganization() {
	const intl = useIntl();
	const history = useHistory();
	const [form] = Form.useForm();
	const location = useLocation();
	const [user, setUser] = useRecoilState(userState);
	const createOrganization = trpc.organization.create.useMutation();

	interface ISubmit {
		name: string;
		email: string;
		phone: string;
		enterpriseNumber: string;
		companySize: number;
	}
	const handleSubmit = (body: ISubmit) => {
		const queryParameters = queryStringHelpers.formatQueryString(location.search);
		const payload = { ...body, type: queryParameters?.type };

		createOrganization.mutate(payload, {
			onSuccess: (organization) => {
				storageHelpers.saveOrganizationId(organization._id);
				storageHelpers.saveOrganizationSlug(organization.slug);
				globalHeaderHelpers.updateHeaders({
					organizationId: organization._id,
					"organization-slug": organization.slug,
				});
				history.push(`/${organization.slug}/setup/members`);
				setUser(undefined); // clear user to prevent go back;
			},
			onError: (error) => {
				if (error.message.includes("eu_vat")) {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "portal.organization.failed.invalid_vat",
							defaultMessage:
								"We couldn't verify your enterprise number is correct. If this error persists contact our customer support",
							description: "Show error when stripe returns 'Invalid value for eu_vat' error",
						}),
						intl,
					});
					return;
				}

				ShowFailedBanner({
					title: intl.formatMessage({
						id: "portal.organization.failed.bad_request",
						defaultMessage: "There was an error while creating your organization. Please try again later.",
						description: "Show error when unknown failure happened",
					}),
					intl,
				});
				return;
			},
		});
	};

	if (!user) {
		history.goBack();
		return null;
	}

	return (
		<section className="portal_body">
			<article className="portal_body-article">
				<header className="portal_body-header">
					<Typography.Title>
						<FormattedMessage id="portal.organization.title" defaultMessage="Create your organization" />
					</Typography.Title>
					<p>
						<FormattedMessage
							id="portal.organization.description"
							defaultMessage="Describe your organization and create it in Bothive."
						/>
					</p>
				</header>
				<Form id="organizationForm" form={form} onFinish={handleSubmit} className="portal_body-form">
					<FieldLabel
						id="enterpriseNumber"
						label={intl.formatMessage({
							id: "portal.organization.enterprise_number.label",
							defaultMessage: "Enterprise number",
						})}
					>
						<Form.Item
							name="enterpriseNumber"
							className="portal_body-form-field"
							required
							rules={[
								{
									required: true,
									validator: async (_, value) => {
										// is required error
										if (!value?.length) {
											const message = intl.formatMessage({
												id: "portal.organization.enterprise_number.error.required",
												defaultMessage: "Your enterprise number is required",
											});

											return Promise.reject(new Error(message));
										}

										return new Promise((resolve, reject) =>
											debounceHelpers(async () => {
												// We cannot show a loader here because we lose field focus
												const isEnterpriseNumberValid =
													await stringHelpers.isEnterpriseNumberValid(value);

												if (!isEnterpriseNumberValid.valid) {
													const message = intl.formatMessage({
														id: "portal.organization.enterprise_number.error.invalid_format",
														defaultMessage:
															"Enterprise number is invalid or not correctly formatted ex: BE0728.495.140",
													});

													return reject(new Error(message));
												}

												if (stringHelpers.isEmpty(form.getFieldValue("name"))) {
													form.setFields([
														{
															name: "name",
															value: isEnterpriseNumberValid.name,
															touched: true,
														},
													]);
												}

												return resolve(true);
											})
										);
									},
								},
							]}
						>
							<Input id="enterpriseNumber" size="large" maxLength={20} placeholder="BE0728495140" />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						id="name"
						label={intl.formatMessage({
							id: "portal.organization.name.label",
							defaultMessage: "Company name",
						})}
					>
						<Form.Item
							name="name"
							className="portal_body-form-field"
							required
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "portal.organization.name.error_is_required",
										defaultMessage: "The name of your company is required",
									}),
								},
								{
									max: 48,
									message: intl.formatMessage({
										id: "portal.organization.name.to_long",
										defaultMessage: "The company name can be maximum 48 characters long",
									}),
								},
							]}
						>
							<Input id="name" size="large" maxLength={48} placeholder="Bothive" />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						id="email"
						label={intl.formatMessage({ id: "portal.organization.email.label", defaultMessage: "E-mail" })}
					>
						<Form.Item
							name="email"
							className="portal_body-form-field"
							required
							rules={[
								{
									type: "email",
									message: intl.formatMessage({
										id: "portal.organization.email.invalid_email",
										defaultMessage: "Hmm… that email doesn't look valid",
									}),
								},
								{
									required: true,
									message: intl.formatMessage({
										id: "portal.organization.email.error_is_required",
										defaultMessage: "Enter a e-mail address",
									}),
								},
							]}
							extra={intl.formatMessage({
								id: "portal.organization.email.help",
								defaultMessage: "We will use this e-mail to contact you.",
							})}
						>
							<Input id="email" type="email" size="large" max={128} placeholder="software@bothive.be" />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						id="phone"
						label={intl.formatMessage({
							id: "portal.organization.phone.label",
							defaultMessage: "Phone number",
						})}
					>
						<Form.Item
							name="phone"
							className="portal_body-form-field"
							rules={[
								{
									pattern: /^[+\d]+$/,
									message: intl.formatMessage({
										id: "portal.organization.phone.validation",
										defaultMessage: "Please fill in a valid phone number",
										description:
											"This is shown when the user inputs a phone number with characters other than numbers or +",
									}),
								},
							]}
						>
							<Input id="phone" type="tel" size="large" placeholder="0491234578" />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						id="companySize"
						label={intl.formatMessage({
							id: "portal.organization.company_size.label",
							defaultMessage: "Company size",
						})}
					>
						<Form.Item
							name="companySize"
							className="portal_body-form-field"
							required
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "portal.organization.company_size.error_is_required",
										defaultMessage: "How many employees does your company have.",
									}),
								},
							]}
						>
							<InputNumber
								id="companySize"
								size="large"
								type="number"
								className="portal_body-form-number_field"
								placeholder="150"
								min={1}
								max={100000000}
							/>
						</Form.Item>
					</FieldLabel>
					<FieldSideLabel
						id="acceptTos"
						type="right"
						allowIntl={false}
						className="portal_organization-accept_tos t-gap--top-sm"
						//@ts-ignore
						label={intl.formatMessage(
							{
								id: "portal.organization.accept_tos.label",
								defaultMessage: "I have read and accept the <link>terms of services</link>.",
							},
							{
								link: (content) => (
									<ExternalLink
										href={`${apiConfig.fileUrl}/v1/serve/tac/current`}
										className="portal_organization-link"
									>
										{content}
									</ExternalLink>
								),
							}
						)}
					>
						<Form.Item
							name="acceptTos"
							valuePropName="checked"
							required
							rules={[
								{
									required: true,
									validator: (_, value) => {
										// checked should be true to be valid
										if (!value) return Promise.reject(new Error());
										return Promise.resolve();
									},
									message: intl.formatMessage({
										id: "portal.organization.accept_tos.error_is_required",
										defaultMessage: "You can only continue if you accept our terms of services.",
									}),
								},
							]}
						>
							<Checkbox id="acceptTos" label="acceptTos" />
						</Form.Item>
					</FieldSideLabel>
					<Form.Item shouldUpdate className="t-gap--top portal_body-form-field-submit_wrapper">
						{() => {
							const isDisabled =
								!form.isFieldsTouched(true) ||
								!!form.getFieldsError().filter(({ errors }) => errors.length).length;

							return (
								<Button
									size="large"
									type="primary"
									htmlType="submit"
									loading={createOrganization.isLoading}
									form="organizationForm"
									disabled={isDisabled}
									className="portal_body-form-field-submit_button"
								>
									<FormattedMessage
										id="portal.organization.submit"
										defaultMessage="Create organization"
									/>
								</Button>
							);
						}}
					</Form.Item>
				</Form>
			</article>
		</section>
	);
}
