import React from "react";

import { timingConfig } from "../../../config";
import { keyEvents } from "../../../helpers";

export default function MDefaultBanner({ status, timing, children, className, onClose }) {
	const timer = setTimeout(() => handleClose(), timing);
	const handleClose = () => {
		clearTimeout(timer);
		onClose();
	};
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			onClose();
		}
	};

	return (
		<section // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
			role="dialog"
			onClick={handleClose}
			onKeyUp={handleKeyUp}
			data-status={status}
			className={`m-banner ${className}`}
		>
			{children}
		</section>
	);
}

MDefaultBanner.defaultProps = {
	timing: timingConfig.bannerTimeout,
	children: undefined,
	className: "",
	onClose: undefined,
};
