import { Button, Result } from "antd";
import React from "react";

import DashboardCard from "@/shared/components/card/dashboard";

export default function ErrorState({
	title,
	description = "",
	reloadButtonText = undefined,
	onReloadClick = undefined,
	style = {},
}) {
	return (
		<DashboardCard style={style}>
			<Result
				status="error"
				title={title}
				subTitle={description ? description : undefined}
				extra={
					reloadButtonText &&
					onReloadClick && (
						<Button type="primary" onClick={onReloadClick}>
							{reloadButtonText}
						</Button>
					)
				}
			/>
		</DashboardCard>
	);
}
