import "./style.scss";

import { EyeInvisibleOutlined } from "@ant-design/icons";
import { Empty, Form, Input, Select, Tooltip } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import { timingConfig } from "@/config";
import { queryParamsConfig } from "@/flows/common/config";
import config from "@/flows/flow_detail/config";
import {
	searchFilterState,
	selectedProgressState,
	selectedStatusTypeState,
	selectedContactLabelState,
	selectedAccountManagerState,
} from "@/flows/flow_detail/reports/state";
import { useStatusTypes } from "@/flows/flow_detail/status_types/hook";
import SelectLoading from "@/shared/components/select/loading";
import { debounceHelpers } from "@/shared/helpers";

import { reportsMeta } from "../state";
import { trpc } from "../../../../trpc";
import ContactLabels from "../../../../shared/components/ContactLabelsSelector";

export default function ReportFilters() {
	const [form] = Form.useForm();
	const { search } = useLocation();
	const { formatMessage } = useIntl();
	const queryParams = new URLSearchParams(search);

	const [statusTypes] = useStatusTypes();
	const [searchLabel, setSearchLabel] = useState();
	const reportsMetaState = useRecoilValue(reportsMeta);
	const [searchFilter, setSearchFilter] = useRecoilState(searchFilterState);
	const [selectedStatus, setSelectedStatus] = useRecoilState(selectedStatusTypeState);
	const [selectedProgress, setSelectedProgress] = useRecoilState(selectedProgressState);
	const [selectedContactLabel, setSelectedContactLabel] = useRecoilState(selectedContactLabelState);
	const [selectedAccountManager, setSelectedAccountManager] = useRecoilState(selectedAccountManagerState);
	const { data: accountManagers, isLoading: isAccountManagerFetching } = trpc.member.getAll.useQuery({ limit: 0 });
	const { data: contactLabels, isLoading: isLabelLoading } = trpc.contactLabel.all.useQuery({
		q: searchLabel,
		limit: 0,
	}); // allow unlimited labels as there is no pagination

	const handleSearch = (search) => {
		debounceHelpers(() => {
			setSearchFilter(search);
		}, timingConfig.debounce);
	};

	return (
		<header className="reports-header">
			<Form
				layout="vertical"
				className="filter-report"
				form={form}
				initialValues={{
					search: searchFilter || queryParams.get(queryParamsConfig.search) || "",
					statusType: selectedStatus,
					progress: selectedProgress,
					contactLabelId: selectedContactLabel,
					accountManager: selectedAccountManager,
				}}
			>
				<Form.Item
					label={formatMessage({ id: "FLOWS.REPORT.OVERVIEW.FILTER.STATUS.LABEL" })}
					name="statusType"
					className="filter-report-statusType"
				>
					<SelectLoading
						value={selectedStatus}
						isLoading={statusTypes?.state === "loading"}
						placeholder="FLOWS.REPORT.OVERVIEW.FILTER.STATUS.PLACEHOLDER"
						onChange={(status) => {
							setSelectedStatus(status);
						}}
						onClear={() => setSelectedStatus(undefined)}
						showSearch
					>
						{statusTypes?.contents?.length > 0 &&
							statusTypes.contents.map((item) => (
								<Select.Option
									key={item?._id}
									value={item?._id}
									data-is-done={item?.isDone}
									className="filter-dropdown-item"
								>
									{item?.name}
									{item?.isDone && (
										<Tooltip
											title={formatMessage({
												id: "flows.report.overview.filter.STATUS.hidden_option.tooltip",
												defaultMessage: "Reports with internal done status are default hidden",
												description:
													"Explain in tooltip reports with internal done status types are default hidden in reports overview",
											})}
										>
											<EyeInvisibleOutlined className="t-gap--left-xs" />
										</Tooltip>
									)}
								</Select.Option>
							))}
					</SelectLoading>
				</Form.Item>
				<Form.Item
					label={formatMessage({ id: "FLOWS.REPORT.OVERVIEW.FILTER.PROGRESS.LABEL" })}
					name="progress"
					className="filter-report-progress"
				>
					<SelectLoading
						value={selectedProgress}
						placeholder="FLOWS.REPORT.OVERVIEW.FILTER.PROGRESS.PLACEHOLDER"
						onChange={setSelectedProgress}
						onClear={() => setSelectedProgress(undefined)}
					>
						{config.reportProgress.progressTypesArray.map((progressType) => (
							<Select.Option key={progressType} value={progressType}>
								<FormattedMessage id={progressType} />
							</Select.Option>
						))}
					</SelectLoading>
				</Form.Item>
				<Form.Item
					label={formatMessage({
						id: "flows.report.overview.filter.account_manager.label",
						defaultMessage: "Filter on account manager",
					})}
					name="accountManager"
					className="filter-report-account_manager"
				>
					<SelectLoading
						showSearch
						onChange={setSelectedAccountManager}
						isLoading={isAccountManagerFetching}
						onClear={() => setSelectedAccountManager(undefined)}
						placeholder={formatMessage({
							id: "flows.report.overview.filter.account_manager.placeholder",
							defaultMessage: "Show all reports",
						})}
						filterOption={(input, option) => option?.children.toLowerCase().includes(input.toLowerCase())}
						notFoundContent={
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description={
									<p>
										<FormattedMessage
											id="flows.report.overview.filter.account_manager.not_found"
											defaultMessage="Geen dossierbeheerders gevonden"
										/>
									</p>
								}
							/>
						}
					>
						{accountManagers?.data.map(({ userId, name }) => (
							<Select.Option key={`${userId}`} value={`${userId}`} className="filter-dropdown-item">
								{name}
							</Select.Option>
						))}
					</SelectLoading>
				</Form.Item>
				<Form.Item label={formatMessage({ id: "FLOWS.REPORT.OVERVIEW.LABELS.TITLE" })} className="filter-contact-label" name="contactLabelId">
					<ContactLabels
						placeholder={formatMessage({
							id: "FLOWS.REPORT.OVERVIEW.LABELS.PLACEHOLDER",
						})}
						contactLabels={contactLabels?.data}
						isLabelLoading={isLabelLoading}
						onSearch={(value) => setSearchLabel(value)}
						onChange={(labelId) => setSelectedContactLabel(labelId)}
						onClear={() => setSelectedContactLabel(undefined)}
					/>
				</Form.Item>
				<Form.Item
					label={formatMessage({ id: "FLOWS.REPORT.OVERVIEW.FILTER.SEARCH.LABEL" })}
					name="search"
					className="filter-report-search"
				>
					<Input
						placeholder={formatMessage({ id: "FLOWS.REPORT.OVERVIEW.FILTER.SEARCH.PLACEHOLDER" })}
						onChange={(input) => handleSearch(input.target.value)}
						size="large"
					/>
				</Form.Item>
			</Form>
			<div className="reports-header-footer">
				<p className="reports-header-result">
					<FormattedMessage
						id="FLOWS.REPORT.OVERVIEW.FILTER.DETAIL.TOTAL_RESULTS"
						values={{
							total: reportsMetaState?.total || 0,
						}}
					/>
				</p>
			</div>
		</header>
	);
}
