import { keyMappingConfig } from "../config";

function createKeyHash(event) {
	const list = [];

	event.metaKey && list.push("cmd");
	event.ctrlKey && list.push("ctrl");
	event.altKey && list.push("alt");
	event.shiftKey && list.push("shift");
	list.push(event.key.toLowerCase());

	return list.join("+").toLocaleLowerCase();
}

export default {
	enterOrSpacePressed: (event) => event.keyCode === 13 || event.keyCode === 32,
	enterOrTabPressed: (event) => event.keyCode === 13 || event.keyCode === 9,
	deletePressed: (event) => event.keyCode === 8,
	tabPressed: (event) => event.keyCode === 9,
	escapePressed: (event) => event.keyCode === 27,
	enterPressed: (event) => event.keyCode === 13,
	shiftEnterPressed: (event) => event.shiftKey && event.keyCode === 13,
	leftArrowPress: (event) => event.keyCode === 37,
	upArrowPress: (event) => event.keyCode === 38,
	rightArrowPress: (event) => event.keyCode === 39,
	downArrowPress: (event) => event.keyCode === 40,
	arrowPress: (event) => [37, 38, 39, 40].includes(event.keyCode),
	saveIsPressed: (event) => (event.ctrlKey || event.metaKey) && event.keyCode === 83,
	arrayContainsKeyCode: ({ event, array }) => array.find((item) => item === event.keyCode),
	isMetaPressed: (event) => event.ctrlKey || event.metaKey,

	createKeyHash,
	isInsideEditor({ event }) {
		// if event path is 4 then it's triggered inside the body else it's mostly inside a input field
		const path = event?.path || (event.composedPath && event.composedPath()); // composedPath necessary in firefox & safari

		return path?.length > 4;
	},
	mapShortcutKeysInbox({ event, mapping }) {
		const keyHash = createKeyHash(event);
		const _mapping = mapping || keyMappingConfig.defaultMapping;

		return _mapping[keyHash];
	},
};
