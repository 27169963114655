import dompurify from "dompurify"; //prevent xss
import React from "react";

import ShadowView from "./shadowView";

function dumpurifyHook(node) {
	//Open all links in new tab
	if ("href" in node) {
		node.setAttribute("target", "_blank");
		node.setAttribute("rel", "noopener noreferrer");
	}
}

interface IShadowEmailView {
	html: string;
	className?: string;
}
function ShadowEmailView({ html, className }: IShadowEmailView) {
	dompurify.addHook("beforeSanitizeAttributes", function (node) {
		dumpurifyHook(node);
	});
	dompurify.addHook("afterSanitizeAttributes", function (node) {
		dumpurifyHook(node);
	});

	return (
		<ShadowView className={className}>
			<div
				style={{ width: "100%", maxWidth: "100%", overflowY: "auto" }}
				dangerouslySetInnerHTML={{ __html: dompurify.sanitize(html) }}
			/>
		</ShadowView>
	);
}

export default React.memo(ShadowEmailView); //Improves UI scroll enormously
