import { useIntl } from "react-intl";
import { Collapse, CollapseProps, Divider, Tag, Skeleton, Space, Typography } from "antd";
import { CSSProperties } from "react";
import { CalendarFilled } from "@ant-design/icons";

export default function DayOverviewSkeleton() {
	const intl = useIntl();

	const panelStyle: CSSProperties = {
		background: "white",
		border: "1px solid var(--grey-500)",
		borderRadius: "var(--rounded)",
		marginBottom: "var(--spacer-sm)",
	};

	const conversationPanels: CollapseProps["items"] = Array.from(Array(4).keys()).map((_, index) => ({
		key: index,
		showArrow: false,
		style: panelStyle,
		label: <Skeleton style={{ padding: "var(--spacer-sm)", width: "40ch" }} active paragraph={false} />,
		children: <Skeleton active />,
	}));

	return (
		<li className="do-day-overview">
			<Tag color="var(--primary)" className="do-day-overview__date-tag">
				<Space>
					<CalendarFilled />
					<Typography.Text style={{ color: "inherit", fontSize: "inherit" }} strong>
						...
					</Typography.Text>
				</Space>
			</Tag>
			<Divider className="do-day-overview__line" type="vertical" />
			<Collapse ghost className="do-day-overview__conversation-list" items={conversationPanels} />
		</li>
	);
}
