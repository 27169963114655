import "./style.scss";

import { Alert, Select, Table, Typography, Button } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { DeleteOutlined } from "@ant-design/icons";
import { Panel } from "@/shared/components/collapse";
import helpers from "./helpers";
import { ApiKeyManagerRouterOutput } from "@/trpc";

export default function Permissions({
	apiKey,
	language,
	permissions,
	onChange,
	...collapseProps
}: {
	apiKey: ApiKeyManagerRouterOutput["getById"] | null | undefined;
	language: string;
	permissions: ApiKeyManagerRouterOutput["getPermissions"] | undefined;
	onChange: (permissions) => void;
}) {
	const [showPermissionConflictBanner, setShowPermissionConflictBanner] = useState(false);
	const intl = useIntl();
	const dataSource = helpers.formatDataSource({
		apiKey,
		language,
		permissions,
	});
	const selectOptions = helpers.formatSelectPermissions({
		apiKey,
		language,
		permissions,
	});
	const handleDelete = (permissionKey) => {
		const permissions = apiKey?.permissions?.filter((item) => item !== permissionKey);
		onChange(permissions);
	};
	const handleAddPermission = (permissionKey) => {
		if (apiKey?.permissions) {
			const isConflictingPermissions = helpers.checkForConflictingPermissions({
				newPermissionKey: permissionKey,
				existingPermissionKeys: apiKey?.permissions,
				allPermissionKeys: permissions,
			});

			if (isConflictingPermissions) {
				return setShowPermissionConflictBanner(true);
			}
		}

		const newPermissions = [...(apiKey?.permissions || []), permissionKey];

		onChange(newPermissions);
	};
	const handleSelectFilter = (inputValue, option) => option.value.includes(inputValue);

	return (
		<Panel
			{...collapseProps} // Get all functional props from parent collapse component
			key="permissions"
			title={intl.formatMessage({
				id: "settings.api_key_detail.permissions.title",
				defaultMessage: "Permissions",
			})}
			className="page-api-key-permissions"
		>
			<div className="page-api-key-permissions--content">
				{showPermissionConflictBanner && (
					<div className="page-api-key-permissions--alert">
						<Alert
							message={intl.formatMessage({
								id: "settings.api_key_detail.permissions.conflict.banner",
								defaultMessage:
									"You have added one or more permissions that are not compatible with each other. If you require those permissions, please create multiple API keys to enhance your security.",
							})}
							type="warning"
							showIcon
							onClose={() => setShowPermissionConflictBanner(false)}
							closable
						/>
					</div>
				)}
				<Typography.Text>
					<FormattedMessage
						id="settings.api_key_detail.permissions.description"
						defaultMessage="Protect your data by limiting the API keys's data access. We recommend giving the API key the least amount of permissions needed to complete it's goal."
					/>
				</Typography.Text>
				<Select
					placeholder={intl.formatMessage({
						id: "settings.api_key_detail.permissions.select_domain.placeholder",
						defaultMessage: "Select a permission",
					})}
					filterOption={handleSelectFilter}
					onChange={handleAddPermission}
					notFoundContent={null}
					className="page-api-key-permissions--select t-gap--top-sm"
					value={null} // don't show selected value in the header
					showSearch
					size="large"
				>
					{selectOptions.map((item) => (
						<Select.Option
							key={item.key}
							value={item.key}
							className="page-api-key-permissions--select-option"
						>
							<Typography.Text strong>{item.title}</Typography.Text>
							<Typography.Text>{item.description}</Typography.Text>
						</Select.Option>
					))}
				</Select>
				{dataSource.length > 0 && (
					<Table
						className="page-api-key-permissions--table t-gap--top-sm"
						dataSource={dataSource}
						pagination={false}
					>
						<Table.Column
							key="permission"
							dataIndex="permission"
							title={
								<FormattedMessage
									id="settings.api_key_detail.permissions.table.permission"
									defaultMessage="Permission"
								/>
							}
							render={(content) => (
								<div className="table--description">
									<Typography.Text strong>{content.title}</Typography.Text>
									<Typography.Text>{content.description}</Typography.Text>
								</div>
							)}
						/>
						<Table.Column
							key="deleteKey"
							dataIndex="deleteKey"
							width="48px"
							render={(id) => (
								<Button type="text" onClick={() => handleDelete(id)}>
									<DeleteOutlined style={{ fontSize: "1.4em" }} />
								</Button>
							)}
						/>
					</Table>
				)}
			</div>
		</Panel>
	);
}
