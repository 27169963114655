export default {
	IS_SAVING: "IS_SAVING",
	SAVING_FAILED: "SAVING_FAILED",
	SAVING_SUCCEEDED: "SAVING_SUCCEEDED",

	IS_DELETING: "IS_DELETING",
	DELETING_FAILED: "DELETING_FAILED",
	DELETING_SUCCEEDED: "DELETING_SUCCEEDED",

	START_FORM_WATCHER: "START_FORM_WATCHER",
	FORM_WATCHER_FAILED: "FORM_WATCHER_FAILED",
	FORM_WATCHER_SUCCEEDED: "FORM_WATCHER_SUCCEEDED",

	START_DELETE_WATCHER: "START_DELETE_WATCHER",
	DELETE_WATCHER_SUCCEEDED: "DELETE_WATCHER_SUCCEEDED",
	DELETE_WATCHER_FAILED: "DELETE_WATCHER_FAILED",

	INIT_FORM: "INIT_FORM",
	UPDATE_FORM: "UPDATE_FORM",

	CREATE_TEAM: "CREATE_TEAM",

	SEND_SUPPORT_EMAIL: "SEND_SUPPORT_EMAIL",

	UPDATE_TEAM: "UPDATE_TEAM",
	UPDATE_GREETING: "UPDATE_GREETING",
	UPDATE_DEFAULT_RESPONSE: "UPDATE_DEFAULT_RESPONSE",
	UPDATE_THEME: "UPDATE_THEME",
	CREATE_NEW_FONT: "CREATE_NEW_FONT",
	UPDATE_LIVE_CHAT_SETTINGS: "UPDATE_LIVE_CHAT_SETTINGS",

	UPDATE_LOCATION: "UPDATE_LOCATION",
	UPDATE_OPENING_HOURS: "UPDATE_OPENING_HOURS",
	UPDATE_PLATFORM: "UPDATE_PLATFORM",
	UPDATE_SETTINGS: "UPDATE_SETTINGS",
	CONTACT_WEB_DEVELOPER: "CONTACT_WEB_DEVELOPER",
	ACCEPT_TERMS_AND_CONDITIONS: "ACCEPT_TERMS_AND_CONDITIONS",
	UPDATE_QUESTION_SETTINGS: "UPDATE_QUESTION_SETTINGS",

	CONNECT_WITH_FACEBOOK: "CONNECT_WITH_FACEBOOK",
	UPDATE_USER_FROM_TEAM: "UPDATE_USER_FROM_TEAM",

	ADD_USER_TO_TEAM: "ADD_USER_TO_TEAM",
	REMOVE_USER_FROM_TEAM: "REMOVE_USER_FROM_TEAM",

	SUBSCRIPTION_PROCESSED: "SUBSCRIPTION_PROCESSED",

	RESET_PASSWORD: "RESET_PASSWORD",

	CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",

	RECEIVED_COUPON_DATA: "RECEIVED_COUPON_DATA",
};
