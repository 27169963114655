import { BugOutlined, CloseOutlined, DownloadOutlined } from "@ant-design/icons";
import { Avatar, Button, Tooltip, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import "./style.scss";

interface AttachmentCardProps {
	fileId: string;
	name?: string;
	url?: string;
	previewUrl?: string;
	size?: number;
	onDelete?: (fileId: string) => void;
	isDeleting?: boolean;
}

const byteValueNumberFormatter = Intl.NumberFormat("en", {
	notation: "compact",
	style: "unit",
	unit: "byte",
	unitDisplay: "narrow",
});

export default function AttachmentCard({
	fileId,
	name,
	url,
	previewUrl,
	size,
	onDelete,
	isDeleting,
}: AttachmentCardProps) {
	const intl = useIntl();
	const isCorrupted = !url;

	return (
		<Tooltip title={name} mouseEnterDelay={1}>
			<article className="aca-attachment-card">
				{previewUrl ? (
					<img src={previewUrl} className="aca-attachment-card__icon" />
				) : (
					<Avatar
						size="large"
						shape="square"
						style={{ background: "var(--error)", gridRow: "1 / 3" }}
						src={<BugOutlined />}
					/>
				)}
				<Typography.Paragraph className="aca-attachment-card__title" ellipsis>
					{isCorrupted ? (
						<FormattedMessage
							id="inbox.conversation.attachment.corrupt.label"
							defaultMessage="Failed attachment"
						/>
					) : (
						name
					)}
				</Typography.Paragraph>
				{<p className="aca-attachment-card__size"> {byteValueNumberFormatter.format(size || 0)}</p>}
				{!isCorrupted && (
					<Button
						aria-label={intl.formatMessage({
							id: "inbox.conversation.attachment.download_attachment.aria_label",
							defaultMessage: "download file",
						})}
						className="aca-attachment-card__download"
						type="link"
						href={url}
						target="_blank"
						size="large"
						download
						icon={<DownloadOutlined />}
					/>
				)}
				{onDelete && (
					<Button
						className="aca-attachment-card__delete"
						aria-label={intl.formatMessage({
							id: "inbox.conversation.attachment.delete_attachment.aria_label",
							defaultMessage: "delete file",
						})}
						size="small"
						shape="circle"
						type="primary"
						disabled={isDeleting}
						loading={isDeleting}
						danger
						icon={<CloseOutlined />}
						onClick={() => onDelete(fileId)}
					/>
				)}
			</article>
		</Tooltip>
	);
}
