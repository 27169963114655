import React, { useEffect, useRef } from "react";

import { typesConfig } from "../../../config";
import { keyEvents } from "../../../helpers";
import { Buttons, Image } from "../../atoms";

export default function MImageCarousel({ items, selected, className, onClick }) {
	let scrollAnimation;
	const listRef = useRef(null);

	useEffect(
		() =>
			function cleanup() {
				clearInterval(scrollAnimation);
			}
	);
	const calculateScrollSize = () => {
		const maxScroll = listRef.current.scrollWidth - listRef.current.clientWidth;
		const scrollSize = maxScroll / 2;

		return scrollSize;
	};
	const handleLeftScroll = () => {
		const scrollSize = calculateScrollSize();
		const maxTempScroll = Math.max(listRef.current.scrollLeft - scrollSize, 0);

		clearInterval(scrollAnimation);
		scrollAnimation = setInterval(() => {
			if (listRef.current.scrollLeft <= maxTempScroll || listRef.current.scrollLeft === 0) {
				return clearInterval(scrollAnimation);
			}

			listRef.current.scrollLeft = listRef.current.scrollLeft - scrollSize / 10;
		}, 25);
	};
	const handleRightScroll = () => {
		const scrollSize = calculateScrollSize();
		const maxTempScroll = listRef.current.scrollLeft + scrollSize;

		clearInterval(scrollAnimation);
		scrollAnimation = setInterval(() => {
			if (listRef.current.scrollLeft >= maxTempScroll) {
				return clearInterval(scrollAnimation);
			}

			listRef.current.scrollLeft = listRef.current.scrollLeft + scrollSize / 10;
		}, 25);
	};
	const handleKeyUp = ({ id, event }) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			this.handleChange(id);
		}
	};
	const handleClick = (id) => {
		onClick(id);
	};

	return (
		<div className={`m-list--image-carousel ${className}`}>
			<Buttons.AIconButton
				icon="arrow--left"
				iconClass="small"
				className="small list-carousel--button"
				onClick={handleLeftScroll}
			/>
			<ul ref={listRef} className="m-list m-list-horizontal list--horizontal-scroll">
				{items.map((item) => (
					<li
						key={item._id}
						role="option"
						tabIndex="0"
						aria-selected={selected === item.id}
						onClick={() => handleClick(item.id)}
						onKeyUp={(event) => handleKeyUp({ event, id: item.id })}
						className="list-carousel--items"
					>
						{item.type === typesConfig.imageType.image && (
							<Image.ADefault src={item.src} alt={item.alt} className="list-carousel--image" />
						)}
						{item.type === typesConfig.imageType.video && (
							<Image.AVideoPreview
								src={item.preview || item.src}
								alt={item.alt}
								className="list-carousel--video"
							/>
						)}
					</li>
				))}
			</ul>
			<Buttons.AIconButton
				icon="arrow--right"
				iconClass="small"
				className="small list-carousel--button"
				onClick={handleRightScroll}
			/>
		</div>
	);
}

MImageCarousel.defaultProps = {
	selected: "",
	className: "",
	onClick: undefined,
};
