import ArchiveModal from "@/shared/components/modal/archive_modal";
import { ContactRouterOutput, trpc } from "@/trpc";
import { FolderOpenOutlined } from "@ant-design/icons";

import { Button, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilState } from "recoil";
import { selectedAllContactsState, selectedContactsState } from "../state";
import { showArchiveModalState } from "./state";

interface ArchiveProps {
	filters: {
		q?: string;
		contactType?: string;
		labelId?: string;
		accountManagerId?: string;
	};
}

export default function Archive({ filters }: ArchiveProps) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const [selectedContacts, setSelectedContacts] = useRecoilState(selectedContactsState);
	const [selectedAll, setSelectedAll] = useRecoilState(selectedAllContactsState);
	const [showArchiveModal, setShowArchiveModal] = useRecoilState(showArchiveModalState);

	const bulkMutateArchiveMutation = trpc.contact.bulkMutateArchive.useMutation({
		onSuccess: (result) => {
			notification.success({
				message: intl.formatMessage(
					{
						id: "CONTACT.OVERVIEW.BULK_ACTION.ARCHIVE.SUCCESS",
						defaultMessage: "{count, plural, one {# contact} other {# contacts}} archived successfully.",
					},
					{ count: result.nModified }
				),
				placement: "bottomRight",
			});

			trpcUtils.contact.getAllContacts.invalidate();
			setShowArchiveModal(false);
			setSelectedAll(false);
			setSelectedContacts([]);
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "CONTACT.OVERVIEW.BULK_ACTION.ARCHIVE.ERROR",
					defaultMessage: "Something went wrong while executing bulk archive.",
				}),
				placement: "bottomRight",
			});
		},
	});

	function handleArchiveContacts() {
		bulkMutateArchiveMutation.mutate({
			action: "archive",
			contactIds: selectedContacts,
			mutateAll: selectedAll,
			filters,
		});
	}

	return (
		<>
			<Button
				loading={bulkMutateArchiveMutation.isLoading}
				onClick={() => setShowArchiveModal((prev) => !prev)}
				type={showArchiveModal ? "primary" : "text"}
				icon={<FolderOpenOutlined />}
			>
				{" "}
				<FormattedMessage id="CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.BUTTON.TITLE" defaultMessage="Archive" />
			</Button>
			<ArchiveModal
				showDescriptionIcon
				descriptionType="warning"
				title={intl.formatMessage({
					id: "CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.TITLE",
					defaultMessage: "Archive contacts",
				})}
				description={intl.formatMessage(
					{
						id: "CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.DESCRIPTION",
						defaultMessage:
							"When you archive a contact, the contact won't be visible in search and you can't perform any automation's on it. The contact will continue to match with incoming data.",
					},
					{ count: selectedContacts.length }
				)}
				archiveLabel={intl.formatMessage({
					id: "CONTACTS.OVERVIEW.BULK_ACTION.ARCHIVE.MODAL.BUTTON",
					defaultMessage: "Archive contacts",
				})}
				placeholder={intl.formatMessage({
					id: "POPUP.PLACEHOLDER.ARCHIVE",
					defaultMessage: "Type ARCHIVE and click on ARCHIVE",
				})}
				isVisible={showArchiveModal}
				isExecuting={bulkMutateArchiveMutation.isLoading}
				onDelete={handleArchiveContacts}
				onClose={() => setShowArchiveModal(false)}
				children={undefined}
			/>
		</>
	);
}
