import { Empty } from "antd";
import type { ReactNode } from "react";
import "./style.scss";

import { inboxEmptyList } from "@/assets/images/icon";

interface ConversationListEmptyProps {
	description: ReactNode;
	className?: string;
}

function ConversationListEmpty({ description, className = "" }: ConversationListEmptyProps) {
	return (
		<div className={`cle-empty ${className}`}>
			<Empty image={inboxEmptyList} description={description} />
		</div>
	);
}

export default ConversationListEmpty;
