export default {
	imageSize: 10000000,
	fontSize: 5000000, //5mb
	fileSize: 5000000, //5mb
	fileTypes: {
		audio: ["aif", "mp3", "mpa", "ogg"],
		compression: ["7z", "pkg", "rar", "tar.gz", "z", "zip"],
		data: ["dat", "db", "log", "mdb", "sql", "tar", "xml"],
		documents: ["docx", "pages", "rtf", "txt"],
		font: ["fnt", "fon", "otf", "ttf"],
		image: ["bmp", "jpg", "jpeg", "png", "gif", "svg", "svg+xml", "tif", "tiff"],
		pdf: ["pdf"],
		presentation: ["key", "odp", "pps", "ppt", "pptx"],
		spreadsheet: ["csv", "numbers", "ods", "xls", "xlsm", "xlsx"],
		video: [
			"3g2",
			"3gp",
			"3gpp2",
			"3gpp",
			"avi",
			"flv",
			"h264",
			"m4v",
			"mkv",
			"mov",
			"mp4",
			"mpeg4",
			"mpg",
			"mpeg",
			"rm",
			"swf",
			"vob",
			"wmv",
		],
	},
	font: {
		maxSize: 5000000, //5mb
		fileTypes: ["ttf", "otf", "woff", "woff2"],
	},
	previewIcon: {
		audio: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/icons/audio_preview.svg",
		compression: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/icons/compression_preview.svg",
		data: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/icons/document_preview.svg",
		font: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/icons/document_preview.svg",
		image: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/icons/image_preview.svg",
		pdf: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/icons/pdf_preview.svg",
		presentation: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/icons/presentation_preview.svg",
		spreadsheet: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/icons/spreadsheet_preview.svg",
		video: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/icons/video_preview.svg",
		text: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/icons/text_preview.svg",
		fallback: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/icons/document_preview.svg",
	},
};
