import { Form, Input } from "antd";
import FieldLabel from "@/shared/components/field/default";
import { useIntl } from "react-intl";
import { CountryPicker } from "@/shared/components/select";
import { normalizeEmptyToUndefined } from "@/shared/helpers/string.helpers";

export default function AddressForm({ inputRef }: { inputRef?: any }) {
	const intl = useIntl();

	return (
		<div style={{ display: "grid", gap: "1em" }}>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "65% 15% 15%",
					gap: "1em",
				}}
			>
				<FieldLabel id="streetName" label="CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.STREETNAME">
					<Form.Item
						name="streetName"
						style={{ width: "100%" }}
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: "CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.STREETNAME.REQUIRED",
								}),
							},
						]}
					>
						<Input ref={inputRef} />
					</Form.Item>
				</FieldLabel>
				<FieldLabel id="streetNumber" label="CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.STREETNUMBER">
					<Form.Item
						name="streetNumber"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: "CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.STREETNUMBER.REQUIRED",
								}),
							},
						]}
					>
						<Input />
					</Form.Item>
				</FieldLabel>
				<FieldLabel id="bus" label="CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.BUS" optional>
					<Form.Item name="bus">
						<Input />
					</Form.Item>
				</FieldLabel>
			</div>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "31% 32% 32%",
					gap: "1em",
				}}
			>
				<FieldLabel id="postalCode" label="CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.POSTALCODE">
					<Form.Item
						name="postalCode"
						style={{ width: "100%" }}
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: "CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.POSTALCODE.REQUIRED",
								}),
							},
						]}
					>
						<Input />
					</Form.Item>
				</FieldLabel>
				<FieldLabel id="city" label="CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.CITY">
					<Form.Item
						name="city"
						style={{ width: "100%" }}
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: "CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.CITY.REQUIRED",
								}),
							},
						]}
					>
						<Input />
					</Form.Item>
				</FieldLabel>
				<FieldLabel id="province" label="CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.PROVINCE" optional>
					<Form.Item normalize={normalizeEmptyToUndefined} name="province" style={{ width: "100%" }}>
						<Input />
					</Form.Item>
				</FieldLabel>
			</div>
			<FieldLabel id="country" label="CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.COUNTRY">
				<Form.Item
					name="country"
					style={{ width: "100%" }}
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.ATTRIBUTES.ADD.ADDRESS.COUNTRY.REQUIRED",
							}),
						},
					]}
				>
					<CountryPicker />
				</Form.Item>
			</FieldLabel>
		</div>
	);
}
