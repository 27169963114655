import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { icon } from "@/assets/images";
import { routesConfig, typesConfig } from "@/config";
import FallbackLock from "@/shared/components/fallback/lock";

export default function ConfigurationFallback() {
	const { team } = useParams();
	const history = useHistory();

	const supportUrl = routesConfig.dashboard.support.replace(":team", team);

	return (
		<FallbackLock
			title="FLOWS.REPORTS.CONFIGURATION.FALLBACK.TITLE"
			description="FLOWS.REPORTS.CONFIGURATION.FALLBACK.DESCRIPTION"
			icon={icon.flowFallback}
			className="t-gap--top-xl"
			buttonChildren={
				<div>
					<Button
						className="t-gap--top"
						onClick={() => history.push(`${supportUrl}?option=${typesConfig.supportTypes.pricing}`)}
					>
						<FormattedMessage id="FALLBACK.LOCK.CONTACT_SALE" />
					</Button>
				</div>
			}
		/>
	);
}
