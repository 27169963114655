import "./style.scss";

import { Avatar, Tooltip, Typography } from "antd";
import { HTMLMotionProps, motion } from "framer-motion";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { MarkdownText } from "@/shared/components/text";
import { dateHelpers } from "@/shared/helpers";
import { ConversationRouterOutput, trpc } from "../../../../trpc";

type ConversationListItemProps = HTMLMotionProps<"li"> & {
	conversation: ConversationRouterOutput["allFromFolder"]["data"][number];
};

const ConversationListItem = ({ conversation, ...props }: ConversationListItemProps) => {
	const intl = useIntl();
	const history = useHistory();
	const trpcUtils = trpc.useUtils();
	const { team } = useParams<{ team: string }>();
	const user = useSelector((state: any) => state.profile.account.user);

	const handleOpenConversation = () => {
		history.push(`/${team}/inbox/conversation/${conversation._id}`);
	};

	const recipients = useMemo(() => {
		const message = conversation.firstMessage;

		if (!message) return "";
		if (message?.author?.userId === user._id) {
			const filteredList = message.recipients.filter(
				(recipient) => recipient.recipientType === "to" && recipient.entityId !== user._id
			);
			// If both contacts and users are in list, filter out the users
			const onlyContactsInList = filteredList.filter((item) => item.entityType === "contact");
			// If list has no contact recipients show the other type of recipients
			const recipientsList = onlyContactsInList.length ? onlyContactsInList : filteredList;

			if (recipientsList?.length) {
				const recipientsPrefix = intl.formatMessage({ id: "MOLECULES.EMAIL_HEADER.TO", defaultMessage: "to:" });
				const formattedRecipients = recipientsList
					.map((recipient) => recipient.name || recipient.address)
					.join(", ");

				return `${recipientsPrefix.toLowerCase()} ${formattedRecipients}`;
			}
		}

		const sender = message.recipients?.find((recipient) => recipient.recipientType === "from");

		return sender?.name || sender?.address || "";
	}, [conversation]);

	const participant = conversation.participants.find((participant) => participant.userId === user._id);

	return (
		<motion.li
			{...props}
			onKeyPress={handleOpenConversation}
			onClick={handleOpenConversation}
			className="dashboard-conversation_item"
		>
			<div className="dashboard-conversation_item-header" data-is-read={participant?.isRead}>
				<Tooltip title={recipients}>
					<Typography.Paragraph className="conversation_item-header-recipients" ellipsis>
						{recipients}
					</Typography.Paragraph>
				</Tooltip>
				<Typography.Paragraph>
					<time className="conversation_item-header-time">
						{dateHelpers.getRelativeDate({ date: conversation.updatedAt, locale: intl.locale })}
					</time>
				</Typography.Paragraph>
			</div>
			<div className="dashboard-conversation_item-content">
				<div className="dashboard-conversation_item-content_left">
					<Typography.Paragraph className="dashboard-conversation_item-subject" ellipsis>
						{conversation.firstMessage?.subject || (
							<FormattedMessage id="conversation.subject.empty" defaultMessage="(No subject)" />
						)}
					</Typography.Paragraph>
					<Typography.Paragraph ellipsis={{ rows: 2 }} className="dashboard-conversation_item-snippet">
						<MarkdownText value={conversation.firstMessage?.content || ""} />
					</Typography.Paragraph>
				</div>
			</div>
		</motion.li>
	);
};
export default ConversationListItem;
