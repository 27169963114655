import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import { reportState, reportViewState } from "@/flows/report_detail/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import StatusButton from "@/shared/components/button/status_button";

import { queryStringHelpers } from "../../../../../helpers";
import helpers from "./helpers";

export default function DownloadReportButton({ flowName, disabled }) {
	const intl = useIntl();
	const queryParams = useParams();
	const flowId = queryParams.flowId;
	const reportId = queryParams.reportId;
	const [isDownloading, setIsDownloading] = useState(false);
	const reportViewType = useRecoilValue(reportViewState);
	const report = useRecoilValueLoadable(reportState({ flowId, reportId }));

	const handleDownloadReport = async () => {
		try {
			setIsDownloading(true);

			const query = queryStringHelpers.createQueryString({
				lang: intl.locale,
				type: reportViewType,
			});
			const url = `${process.env.REACT_APP_FILE_SERVER_HOST}/v1/serve/flow/reports/${reportId}/zipped`;

			const { data, headers } = await axios.get(`${url}${query}`, { responseType: "blob" });

			await helpers.generateDownload({ report, flowName, fileData: data, reportViewType, headers });

			setIsDownloading(false);

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.SUCCEEDED.TITLE",
					defaultMessage: "Report downloaded",
				}),
			});
		} catch (error) {
			console.error("Failed to download report", error);
			setIsDownloading(false);

			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.FAILED.TITLE",
					defaultMessage: "Failed to download report",
				}),
				description: intl.formatMessage({
					id: "FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOAD_REPORT.BUTTON.BANNER.FAILED.DESCRIPTION",
					defaultMessage: "Please try again later or contact customer support",
				}),
			});
		}
	};

	return (
		<StatusButton
			icon={<DownloadOutlined className="t-gap--right-xs" />}
			onClick={handleDownloadReport}
			isLoading={isDownloading}
			disabled={disabled}
		>
			<FormattedMessage id="FLOWS.REPORT_DETAIL.ACTIONS.DOWNLOADEN" />
		</StatusButton>
	);
}
