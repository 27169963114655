import DashboardCard from "@/shared/components/card/dashboard";
import FieldLabel from "@/shared/components/field/default";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Button, Image } from "antd";
import "../style.scss";
import { ImageUpload } from "@/shared/components/upload";

export interface ILogoIconSettingsValue {
	logo: string;
	icon: string;
}
interface ILogoIconSettings {
	isSaving: boolean;
	allowEditing: boolean;
	branding: ILogoIconSettingsValue;
	onFinish: (value: ILogoIconSettingsValue) => void;
}

export default function LogoIconSettings({ branding, allowEditing, isSaving, onFinish }: ILogoIconSettings) {
	const intl = useIntl();

	function generateForm() {
		if (allowEditing) {
			return (
				<Form
					id="editImages"
					name="editImages"
					layout="horizontal"
					autoComplete="off"
					onFinish={onFinish}
					disabled={!allowEditing}
					initialValues={branding}
					className="branding-logo-form"
				>
					<div className="branding-logo-form-upload_zone">
						<FieldLabel
							label={intl.formatMessage({
								id: "organization_settings.branding.logo.form.logo.label",
								defaultMessage: "Upload logo",
							})}
						>
							<Form.Item
								name="logo"
								extra={intl.formatMessage({
									id: "organization_settings.branding.logo.form.logo.help",
									defaultMessage: "Maximum size: 10MB",
								})}
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id: "organization_settings.branding.logo.form.logo.required_error",
											defaultMessage: "Logo is required",
										}),
									},
								]}
							>
								<ImageUpload
									id="logo"
									crop={{ aspect: 16 / 9 }}
									className="branding-logo-logo_upload"
								/>
							</Form.Item>
						</FieldLabel>
						<FieldLabel
							label={intl.formatMessage({
								id: "organization_settings.branding.logo.form.icon.label",
								defaultMessage: "Upload icon",
							})}
						>
							<Form.Item
								name="icon"
								extra={intl.formatMessage({
									id: "organization_settings.branding.logo.form.icon.help",
									defaultMessage: "Maximum size: 10MB",
								})}
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id: "organization_settings.branding.logo.form.icon.required_error",
											defaultMessage: "Icon is required",
										}),
									},
								]}
							>
								<ImageUpload id="icon" crop={{ aspect: 1 / 1 }} className="branding-logo-icon_upload" />
							</Form.Item>
						</FieldLabel>
					</div>
					<Button
						key="submit"
						type="primary"
						htmlType="submit"
						loading={isSaving}
						form="editImages"
						size="large"
						className="saveButton"
					>
						<FormattedMessage id="organization_settings.branding.logo.form.submit" defaultMessage="Save" />
					</Button>
				</Form>
			);
		}

		if (branding.icon === undefined && branding.logo === undefined) {
			return (
				<p className="branding-logo-fallback">
					<FormattedMessage
						id="organization_settings.branding.missing_logo_icon_permissions"
						defaultMessage="Your organization does not have a logo and icon at this time. Please request someone with the necessary permissions to upload them."
					/>
				</p>
			);
		}

		return (
			<div className="branding-logo-form-upload_zone">
				<Image width={355} height={200} src={branding.logo} className="branding-logo-logo_upload" />
				<Image width={200} height={200} src={branding.icon} className="branding-logo-icon_upload" />
			</div>
		);
	}

	return (
		<DashboardCard
			title={intl.formatMessage({
				id: "organization_settings.branding.logo.title",
				defaultMessage: "Logo and icon",
			})}
			description={intl.formatMessage({
				id: "organization_settings.branding.logo.description",
				defaultMessage: "Upload your logo and icon that we can use to personalize outgoing communications.",
			})}
			className="branding-logo"
		>
			{generateForm()}
		</DashboardCard>
	);
}
