import { useLayoutEffect, type FC, useRef, useState, Ref } from "react";
import { Avatar as AntdAvatar, AvatarProps as AntdAvatarProps, Badge } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

export interface AvatarProps extends AntdAvatarProps {
	/**
	 * Indicates if a user closed conversation or not
	 */
	status?: "open" | "closed";
}

export default function Avatar({ status = "open", ...props }: AvatarProps) {
	const avatarRef = useRef<HTMLSpanElement | null>(null);
	const [avatarSize, setAvatarSize] = useState({ width: 0, height: 0 });

	useLayoutEffect(() => {
		function getAvatarSize() {
			if (avatarRef.current) {
				const { width, height } = avatarRef.current?.getBoundingClientRect();
				setAvatarSize({ width, height });
			}
		}

		getAvatarSize();
	}, []);

	const badgeOffset = calculateBadgeOffset(avatarSize);

	return (
		<Badge
			offset={badgeOffset}
			count={
				status === "closed" ? (
					<CheckCircleFilled style={{ color: "var(--success)", background: "white", borderRadius: "100%" }} />
				) : (
					0
				)
			}
		>
			<AntdAvatar ref={avatarRef} shape="square" {...props}></AntdAvatar>
		</Badge>
	);
}

function calculateBadgeOffset({ width, height, percentage = 0.9 }): [number, number] {
	return [-(width * percentage), height * percentage];
}
