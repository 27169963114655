import { useDispatch } from "react-redux";
import { actions as dashboardActions } from "@/store/dashboard";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Form, Select, Button, Input } from "antd";
import FieldLabel from "@/shared/components/field/default";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import { trpc } from "@/trpc";

export default function SupportPage() {
	const intl = useIntl();
	const [form] = Form.useForm();
	const location = useLocation();
	const dispatch = useDispatch();
	const routeParameters = useParams<{ team: string }>();
	const sendSupportEmail = trpc.support.sendSupportEmail.useMutation();

	const questions = [
		{
			value: "new_question",
			label: intl.formatMessage({
				id: "support.form.question.new_question",
				defaultMessage: "New question",
			}),
		},
		{
			value: "request_feature",
			label: intl.formatMessage({
				id: "support.form.question.feature_request",
				defaultMessage: "Feature request",
			}),
		},
		{
			value: "bug",
			label: intl.formatMessage({
				id: "support.form.question.bug",
				defaultMessage: "Bug",
			}),
		},
		{
			value: "help",
			label: intl.formatMessage({
				id: "support.form.question.help",
				defaultMessage: "Help",
			}),
		},
		{
			value: "installation_help",
			label: intl.formatMessage({
				id: "support.form.question.installation_help",
				defaultMessage: "Installation help",
			}),
		},
		{
			value: "integration_request",
			label: intl.formatMessage({
				id: "support.form.question.integration_request",
				defaultMessage: "Integration request",
			}),
		},
		{
			value: "pricing",
			label: intl.formatMessage({
				id: "support.form.question.pricing",
				defaultMessage: "Subscriptions",
			}),
		},
	];

	useEffect(() => {
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					title: intl.formatMessage({ id: "support.page.title", defaultMessage: "Customer support" }),
					tabTitle: intl.formatMessage({ id: "support.page.tab_title", defaultMessage: "Customer support" }),
					breadcrumbs: [
						{
							to: `/${routeParameters.team}/support`,
							name: intl.formatMessage({
								id: "support.page.breadcrumb.title",
								defaultMessage: "Customer support",
							}),
						},
					],
				},
				intl,
			})
		);
	}, []);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);

		const requestType = searchParams.get("requestType");
		const subject = searchParams.get("subject");

		if (requestType) form.setFieldValue("requestType", requestType);
		if (subject) form.setFieldValue("description", subject);
	}, [location.search]);

	interface ISubmitBody {
		requestType: string;
		priority: string;
		description: string;
		content: string;
	}

	const handleSubmit = async (body: ISubmitBody) => {
		try {
			await sendSupportEmail.mutateAsync(body);
			form.resetFields();
			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "support.banner.email_send.succeeded",
					defaultMessage: "Thank you for contacting us. We will contact you as quickly as possible.",
				}),
			});
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "support.banner.email_send.failed",
					defaultMessage: "Your message couldn't be send. Please try again later",
				}),
			});
		}
	};

	return (
		<Form
			style={{
				width: "100%",
				padding: "var(--spacer-md)",
				display: "flex",
				flexDirection: "column",
				gap: "var(--spacer-sm)",
			}}
			className="t-section"
			form={form}
			id="contactSupport"
			name="contactSupport"
			initialValues={{ priority: "priority_normal" }}
			onFinish={handleSubmit}
			autoComplete="off"
		>
			<div style={{ display: "flex", gap: "var(--spacer-md)", justifyContent: "center" }}>
				<FieldLabel
					label="support.form.request"
					style={{ width: "50%" }}
					defaultLabelMessage="Type of application"
				>
					<Form.Item
						style={{ width: "100%", height: "4vh" }}
						name="requestType"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: "support.form.request.required",
									defaultMessage: "Please select a choice",
								}),
							},
						]}
					>
						<Select
							style={{ width: "100%", textAlign: "left" }}
							size="large"
							options={questions}
							filterSort={(a, b) => a.label.localeCompare(b.label)}
						/>
					</Form.Item>
				</FieldLabel>
				<FieldLabel label="support.form.important" style={{ width: "50%" }} defaultLabelMessage="Priority">
					<Form.Item style={{ width: "100%", height: "4vh" }} name="priority">
						<Select
							style={{ width: "100%", textAlign: "left" }}
							size="large"
							options={[
								{
									value: "priority_low",
									label: intl.formatMessage({
										id: "support.form.priority.low",
										defaultMessage: "Low",
									}),
								},
								{
									value: "priority_normal",
									label: intl.formatMessage({
										id: "support.form.priority.normal",
										defaultMessage: "Normal",
									}),
								},
								{
									value: "priority_high",
									label: intl.formatMessage({
										id: "support.form.priority.high",
										defaultMessage: "High",
									}),
								},
								{
									value: "priority_critical",
									label: intl.formatMessage({
										id: "support.form.priority.critical",
										defaultMessage: "Critical",
									}),
								},
							]}
						/>
					</Form.Item>
				</FieldLabel>
			</div>
			<FieldLabel label="support.form.description" defaultLabelMessage="Description" style={{ width: "100%" }}>
				<Form.Item
					name="description"
					style={{ width: "100%" }}
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "support.form.description.required",
								defaultMessage: "Description is required",
							}),
						},
					]}
				>
					<Input size="large" style={{ width: "100%" }} maxLength={80} showCount />
				</Form.Item>
			</FieldLabel>

			<FieldLabel label="support.form.content" defaultLabelMessage="Message" style={{ width: "100%" }}>
				<Form.Item
					style={{ width: "100%" }}
					name="content"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "support.form.content.required",
								defaultMessage: "Content is required",
							}),
						},
					]}
				>
					<Input.TextArea size="large" rows={6} style={{ width: "100%" }} />
				</Form.Item>
			</FieldLabel>
			<Form.Item style={{ display: "flex", width: "fit-content", alignSelf: "flex-end" }}>
				<Button
					size="large"
					type="primary"
					htmlType="submit"
					form="contactSupport"
					loading={sendSupportEmail.isLoading}
				>
					<FormattedMessage id="support.form.button.send" defaultMessage="Send" />
				</Button>
			</Form.Item>
		</Form>
	);
}
