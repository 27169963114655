import "./style.scss";

import { FormattedMessage, useIntl } from "react-intl";

import { keyEvents } from "@/helpers";
import { Component } from "@/components/atoms";
import { ContactRouterOutput } from "@/trpc";

const countriesList = require("world_countries_lists/data/countries/_combined/countries.json");

interface IBankAccountCard {
	id: string;
	value: Required<ContactRouterOutput["getContactById"]>["bankAccounts"][0];
	onClick: (id: string) => void;
}

export default function BankAccountCard({ id, value, onClick }: IBankAccountCard) {
	const intl = useIntl();
	const handleKeyUp = (event: any) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			onClick(id);
		}
	};

	const types = {
		currentAccount: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.CURRENT_ACCOUNT",
		privateAccount: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.PRIVATE_ACCOUNT",
		savingsAccount: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.SAVINGS_ACCOUNT",
		cryptoWallet: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.CRYPTO_WALLET",
		other: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.TYPE.OPTION.OTHER",
	};

	const countryCode = value.institution?.address?.country;
	const country = countriesList.find((country) => country.alpha2 === countryCode)?.[intl.locale];
	const countryDisplayValue = country || countryCode;
	const detailInfo = [value.institution?.name, countryDisplayValue].filter(Boolean).join(" - ");

	return (
		<article role="button" onKeyUp={handleKeyUp} onClick={() => onClick(id)} className={`m-card bank_account_card`}>
			<p>
				<FormattedMessage id={types[value.type] || "bank_account_card_format_id_undefined"} />
			</p>
			{value.type === "cryptoWallet" ? (
				<>
					<p className="bank_account_card-title">{value.institution.name}</p>
					{countryDisplayValue && <p>{countryDisplayValue}</p>}
				</>
			) : (
				<>
					<p className="bank_account_card-title">{value.iban}</p>
					{detailInfo.length ? (
						<p>{detailInfo}</p>
					) : (
						<p style={{ color: "GrayText" }}>
							<FormattedMessage id="CONTACTS.DETAIL.BANK_ACCOUNTS.CARD.NO_DETAILS" />
						</p>
					)}
				</>
			)}
			<Component.AIcon type="edit" className="card--edit-icon" />
		</article>
	);
}
