import { Button } from "antd";
import { useIntl } from "react-intl";

export default function ReminderFooter({
	onReturn,
	onSubmit,
	submitButtonText,
	submitButtonDisabled = false,
	submitButton,
}: {
	onReturn?: () => void;
	onSubmit?: () => void;
	submitButtonText?: string;
	submitButtonDisabled?: boolean;
	submitButton?: React.ReactNode;
}) {
	const intl = useIntl();

	return (
		<footer
			style={{
				position: "absolute",
				bottom: 0,
				left: 0,
				right: 0,
				backgroundColor: "white",
				width: "100vw",
				display: "flex",
				justifyContent: "flex-end",
				alignItems: "center",
				borderTop: "1px solid rgba(0, 33, 64, 0.2)",
				zIndex: 999,
			}}
		>
			{onReturn && (
				<Button type="text" onClick={() => onReturn()} style={{ margin: "1em" }}>
					{intl.formatMessage({
						id: "FLOW.DETAIL.REMINDERS.CREATE_STRATEGY.FOOTER.RETURN",
						defaultMessage: "Return",
					})}
				</Button>
			)}
			{onSubmit && (
				<Button type="primary" onClick={onSubmit} disabled={submitButtonDisabled} style={{ margin: "1em" }}>
					{submitButtonText}
				</Button>
			)}
			{submitButton && submitButton}
		</footer>
	);
}
