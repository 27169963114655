export const FILE_SIZE_LIMIT = 25 * 1000 * 1000;
export const disabledFileTypes = [
	"dmg",
	"exe",
	"swf",
	"bat",
	"jar",
	"jnlp",
	"py",
	"pyc",
	"pyo",
	"pyw",
	"pyz",
	"pyzw",
	"ps1",
	"ps1xml",
	"ps2",
	"ps2xml",
	"psc1",
	"psc2",
	"psd1",
	"psdm1",
	"psd1",
	"psdm1",
	"cer",
	"crt",
	"der",
	"appcontent-ms",
	"settingcontent-ms",
	"cnt",
	"hpj",
	"website",
	"webpnp",
	"mcf",
	"printerexport",
	"pl",
	"theme",
	"vbp",
	"xbap",
	"xll",
	"xnk",
	"msu",
	"diagcab",
	"grp",
];
