export default function ReplyAllOutlined() {
	return (
		<span role="img" aria-label="enter" className="anticon anticon-enter ant-dropdown-menu-item-icon">
			<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
				<path
					fill="currentColor"
					d="M10.329 9.81V7.594c.053-5.124-.365 1.037-.365.978l-.16-.978h-6.51v.978c0 .09-.104.14-.173.084l-1.9-1.5a.107.107 0 010-.169l1.9-1.5a.107.107 0 01.174.085v1.004h6.67c.472 0 .856.385.856.857l-.196 2.557c-.642-4.69-.296-2.108-.296-.178z"
				></path>
				<path
					fill="currentColor"
					d="M10.714 10.58h-.803a.108.108 0 01-.107-.107V3.536h-6.51v.977c0 .09-.104.141-.173.085l-1.9-1.5a.107.107 0 010-.169l1.9-1.5a.107.107 0 01.174.084v1.005h6.67c.472 0 .856.384.856.857v7.098a.108.108 0 01-.107.107z"
				></path>
			</svg>
		</span>
	);
}
