function generateRandomToken({ length } = { length: 24 }) {
	let token = "";
	const values = "abcdefghijklmonpqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-_";

	for (let i = 0; i < length; i++) {
		token += values.substr(Math.floor(Math.random() * values.length), 1);
	}

	return token;
}

export default {
	generateRandomToken: generateRandomToken,
	generateUuid: ({ length } = { length: 20 }) => {
		const timestamp = Date.now().toString(16);

		return `${timestamp}${generateRandomToken({ length })}`;
	},
	randomColor: () => {
		const generate = () => Math.floor(Math.random() * 16777215).toString(16);

		let value = "";

		do {
			value = generate();
		} while (value.length !== 6);

		return value;
	},
	skeletonList({ max, min } = { max: 5, min: 1 }) {
		let list = [];
		const _min = max > min ? min : 1; // if max is less then min value set min to 1 because skeleton list should always have 1 item
		const randomValue = Math.floor(Math.random() * max) + _min;

		for (let id = 0; id < randomValue; id++) {
			list.push({ id, key: id });
		}

		return list;
	},
};
