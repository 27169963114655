function archiveValueValidator({ value, intl }) {
	if (
		value ===
		intl
			.formatMessage({ id: "CONTACT.OVERVIEW.BULK_ACTION.ACTION.ARCHIVE", defaultMessage: "ARCHIVE" })
			.toUpperCase()
	) {
		return Promise.resolve();
	}

	const errorMessage = intl.formatMessage({ id: "FORM_ELEMENT.ERROR.VALUE_ARCHIVE" });

	return Promise.reject(new Error(errorMessage));
}

export default { archiveValueValidator };
