import "./style.scss";

import type { INotification } from "@bothive_core/database";
import { Button, Card, Divider, Empty, Space, Switch, Tooltip, notification } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { icon } from "../../../../../assets/images";
import { trpc } from "../../../../../trpc";
import NotificationItem from "./NotificationItem";
import ListSkeleton from "./NotificationItem/Skeleton";

interface INotificationList {
	notifications: INotification[];
	total: number;
	isLoading: boolean;
	isFetchingNextPage: boolean;
	showUnReadOnly: boolean;
	onShowUnReadChange: () => void;
	onFetchNext: () => void;
}

export default function NotificationList({
	notifications,
	total,
	isLoading,
	isFetchingNextPage,
	showUnReadOnly,
	onShowUnReadChange,
	onFetchNext,
}: INotificationList) {
	const intl = useIntl();
	const history = useHistory();
	const trpcUtils = trpc.useUtils();
	const { team } = useParams<{ team: string }>();

	const markAsReadUnRead = trpc.notification.notifications.markAsReadUnRead.useMutation();
	const markAllAsRead = trpc.notification.notifications.markAllAsRead.useMutation();

	if (markAsReadUnRead.isError || markAllAsRead.isError) {
		trpcUtils.notification.notifications.getNotifications.invalidate();
	}

	const allNotificationsFetched = notifications && notifications.length >= total && notifications.length !== 0;

	const handleNotificationClick = async (id: string) => {
		const notification = notifications.find((item) => item._id === id);

		if (!notification) return;

		try {
			switch (notification.type) {
				case "inbox.assigned_to_conversation":
				case "inbox.added_as_participant":
				case "inbox.sending_failed":
				case "inbox.mentioned":
				case "mention":
				case "assignConversation":
				case "conversationStarted":
					history.push(`/${team}/inbox/conversation/${notification.entityId || ""}`);
					break;
				case "flowAssign":
				case "flowReport":
					history.push(`/${team}/flows/${notification.meta?.flowId}/reports?report_id=${notification.link}`);
					break;
				default:
					break;
			}

			handleMarkAsReadUnread({ id, isRead: true });
		} catch (error) {
			console.error("Notification click failed", error);
		}
	};

	const handleMarkAsReadUnread = async ({ id, isRead }: { id: string; isRead: boolean }) => {
		await markAsReadUnRead.mutateAsync({ id, isRead });

		trpcUtils.notification.notifications.getNotifications.cancel();

		trpcUtils.notification.notifications.getNotifications.setInfiniteData(
			{ isRead: showUnReadOnly ? false : undefined },
			(prevInfiniteData) => {
				if (!prevInfiniteData) return;

				let clonedInfiniteData = structuredClone(prevInfiniteData);

				if (isRead && showUnReadOnly) {
					for (let i = 0; i < prevInfiniteData.pages.length; i++) {
						clonedInfiniteData.pages[i] = {
							...prevInfiniteData.pages[i],
							totalUnread: Math.max(clonedInfiniteData.pages[0].totalUnread - 1, 0),
							total: Math.max(clonedInfiniteData.pages[0].total - 1, 0),
							data: prevInfiniteData.pages[i].data.filter((item) => item._id !== id),
						};
					}
				} else {
					for (let i = 0; i < prevInfiniteData.pages.length; i++) {
						clonedInfiniteData.pages[i] = {
							...prevInfiniteData.pages[i],
							totalUnread: isRead
								? Math.max(clonedInfiniteData.pages[0].totalUnread - 1, 0)
								: clonedInfiniteData.pages[0].totalUnread + 1,
							data: prevInfiniteData.pages[i].data.map((item) => {
								if (item._id !== id) return item;

								return { ...item, isRead: isRead };
							}),
						};
					}
				}

				return clonedInfiniteData;
			}
		);
	};

	const handleMarkAllAsRead = async () => {
		try {
			await markAllAsRead.mutateAsync();

			trpcUtils.notification.notifications.getNotifications.cancel();

			trpcUtils.notification.notifications.getNotifications.setInfiniteData(
				{ isRead: showUnReadOnly ? false : undefined },
				(prevInfiniteData) => {
					if (!prevInfiniteData) return;

					let clonedInfiniteData = structuredClone(prevInfiniteData);

					if (showUnReadOnly) {
						return {
							pages: [{ data: [], total: 0, totalUnread: 0 }],
							pageParams: clonedInfiniteData.pageParams,
						};
					}

					for (let i = 0; i < prevInfiniteData.pages.length; i++) {
						clonedInfiniteData.pages[i] = {
							...prevInfiniteData.pages[i],
							data: prevInfiniteData.pages[i].data.map((item) => ({ ...item, isRead: true })),
						};
					}

					clonedInfiniteData.pages[0].totalUnread = 0;

					return clonedInfiniteData;
				}
			);

			notification.success({
				message: intl.formatMessage({
					id: "notification.mark_all_as_read.succeeded",
					defaultMessage: "All notifications have been mark as read",
					description: "Marking all notification as read succeeded",
				}),
				placement: "bottomRight",
			});
		} catch (_) {
			notification.error({
				message: intl.formatMessage({
					id: "notification.mark_all_as_read.failed",
					defaultMessage: "Something went wrong, please try again later",
					description: "Marking all notification as read failed for unknown reason",
				}),
				placement: "bottomRight",
			});
		}
	};

	return (
		<Card
			title={
				<Space>
					<FormattedMessage id="notification.title" defaultMessage="Notifications" />
					<Tooltip
						title={intl.formatMessage({
							id: "notification.header.read_status.switch.tooltip",
							defaultMessage: "Switch between unread only vs all",
							description:
								"Tooltip for a switch component that switches between the state of showing all notifications vs unread only.",
						})}
					>
						<Switch
							checked={!!showUnReadOnly}
							onChange={onShowUnReadChange}
							checkedChildren={intl.formatMessage({
								id: "notification.header.read_status.switch.read_only",
								defaultMessage: "Unread",
							})}
							unCheckedChildren={intl.formatMessage({
								id: "notification.header.read_status.switch.all",
								defaultMessage: "All",
							})}
							defaultChecked
						/>
					</Tooltip>
				</Space>
			}
			extra={
				total ? (
					<Button type="text" size="small" onClick={handleMarkAllAsRead} loading={markAllAsRead.isLoading}>
						<FormattedMessage
							id="notification.header.button.mark_all_as_read"
							defaultMessage="Mark all as read"
						/>
					</Button>
				) : (
					[]
				)
			}
			className="notification-dropdown"
		>
			{!total && !isLoading && (
				<Empty
					description={intl.formatMessage({
						id: "notification.empty.description",
						defaultMessage: "You're up-to-date 🎉 job well done time for some coffee",
					})}
					className="notification-dropdown-fallback"
					image={icon.notificationsFallback}
				/>
			)}
			{isLoading && total === 0 && (
				<div className="notification-dropdown-list">
					{/* @ts-ignore */}
					<AnimatePresence>
						{Array.from(Array(3).keys()).map((item, index) => (
							<motion.li
								key={`skeleton_${item}`}
								initial={{ opacity: 0, x: 20 }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0 }}
								style={{ width: "100%" }}
								transition={{ duration: 0.15, delay: index * 0.0125 }}
							>
								<ListSkeleton />
							</motion.li>
						))}
					</AnimatePresence>
				</div>
			)}
			{total > 0 && (
				<div className="notification-dropdown-container" id="notificationList">
					<InfiniteScroll
						scrollThreshold={0.6}
						next={() => onFetchNext()}
						style={{ overflowX: "hidden" }}
						dataLength={notifications?.length || 0}
						hasMore={(notifications?.length || 0) < total}
						loader={isFetchingNextPage && <ListSkeleton />}
						endMessage={
							allNotificationsFetched && (
								<Divider plain>
									<FormattedMessage
										id="notifications.list.end_of_list"
										defaultMessage="All notifications are fetched 🔔"
										description="Message user has reached the end off the notifications list"
									/>
								</Divider>
							)
						}
						scrollableTarget="notificationList"
						className="notification-dropdown-list"
					>
						{/* @ts-ignore */}
						<AnimatePresence>
							{notifications.map((notification, index) => (
								<motion.li
									key={notification._id}
									initial={{ opacity: 0, x: 20 }}
									animate={{ opacity: 1, x: 0 }}
									exit={{ opacity: 0 }}
									style={{ width: "100%" }}
									transition={{ duration: 0.15, delay: index * 0.0125 }}
								>
									<NotificationItem
										key={notification._id}
										notification={notification}
										onClick={handleNotificationClick}
										onMarkAsReadUnRead={handleMarkAsReadUnread}
										organizationSlug={team}
									/>
								</motion.li>
							))}
						</AnimatePresence>
					</InfiniteScroll>
				</div>
			)}
		</Card>
	);
}
