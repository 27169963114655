function isQueryValueDefined(value) {
	if (Array.isArray(value)) {
		return value.length > 0;
	}

	return !!value || value === false;
}

function createQueryString(params) {
	return (
		"?" +
		Object.keys(params)
			.reduce((prev, key) => (isQueryValueDefined(params[key]) ? [...prev, `${key}=${params[key]}`] : prev), [])
			.join("&")
	);
}

export default {
	getQueryString(history) {
		const queryString = history?.location?.search;

		if (!queryString?.length) {
			return undefined;
		}

		const items = queryString.replace("?", "").split("&");
		const params = {};

		items.forEach((item) => {
			const value = item.split("=");

			params[value[0]] = decodeURI(value[1]);
		});

		return params;
	},
	addParam({ history, key, value }) {
		const params = this.getQueryString(history) || {};

		params[[key]] = value;

		return createQueryString(params);
	},
	createQueryString,
	removeParam({ history, key }) {
		const params = this.getQueryString(history) || {};

		delete params[[key]];

		return createQueryString(params);
	},
};
