import { useSetRecoilState } from "recoil";
import FileUpload from "./FileUpload";
import MapFields from "./MapFields";
import ReviewImport from "./ReviewImport";
import { StepsTypes } from "../types";
import { fileState, importDataState, importConfigState, importMappingState } from "./state";
import { useEffect } from "react";

type ImportStepsParams = { step?: StepsTypes; onChange: (step: StepsTypes) => void; onClose: () => void };

export default function ImportSteps({ step, onChange, onClose }: ImportStepsParams) {
	const setUploadedFile = useSetRecoilState(fileState);
	const setImportData = useSetRecoilState(importDataState);
	const setImportConfig = useSetRecoilState(importConfigState);
	const setImportMapping = useSetRecoilState(importMappingState);

	const handleClose = () => {
		onClose();
		setImportData([]);
		setImportMapping({});
		setUploadedFile(undefined);
		setImportConfig({
			delimiter: ";",
			type: "customer",
			allowCreate: true,
			allowUpdate: true,
		});
	};

	useEffect(() => {
		return () => handleClose();
	}, []);

	switch (step) {
		case "MAP_FILES":
			return (
				<MapFields
					onClose={handleClose}
					onNext={() => onChange("REVIEW_IMPORT")}
					onBack={(step) => onChange(step || "GET_DATA")}
				/>
			);
		case "REVIEW_IMPORT":
			return <ReviewImport onClose={handleClose} onBack={(step) => onChange(step || "MAP_FILES")} />;
		case "GET_DATA":
		default:
			return <FileUpload onClose={handleClose} onNext={() => onChange("MAP_FILES")} onBack={handleClose} />;
	}
}
