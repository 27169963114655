import React from "react";
import { injectIntl } from "react-intl";

class ATextArea extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value || "",
			error: props.error,
		};

		this.bindHandles();
	}

	static getDerivedStateFromProps(props, state) {
		const error = state.error !== props.error ? props.error : state.error;
		const value = state.value !== props.value ? props.value || "" : state.value;

		return { value, error };
	}

	bindHandles() {
		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleKeyUp(event) {
		if (this.props.onKeyUp) {
			this.props.onKeyUp({ event, value: this.state.value });
		}
	}

	handleChange(event) {
		const value = event.target.value;
		const error = this.props.required && value.length < 1;

		if (this.props.onChange) {
			this.props.onChange(value);
		}

		this.setState({ value, error });
	}

	render() {
		const cName = `a-input a-input--field a-input--text-area a-input--no-outline ${this.props.className}
			${this.state.error ? "error" : ""} ${this.props.disabled ? "disabled" : ""}`;
		const placeholder = this.props.placeholder ? this.props.intl.formatMessage({ id: this.props.placeholder }) : "";
		const props = { ...this.props };

		delete props.error;
		delete props.optional;
		delete props.defaultValue;
		delete props.validator;

		return (
			<textarea
				{...props}
				value={this.state.value}
				placeholder={placeholder}
				className={cName}
				aria-label={props.label ? this.props.intl.formatMessage({ id: props.label }) : ""}
				aria-required={this.props.required}
				aria-invalid={this.state.error}
				onChange={this.handleChange}
				onKeyUp={this.handleKeyUp}
				onBlur={this.props.onBlur}
			/>
		);
	}
}

ATextArea.defaultProps = {
	name: "nameDefaultInput-change-this",
	value: "",
	autoComplete: "off",
	className: "",
	disabled: false,
	required: false,
	error: false,

	onChange: undefined,
	onKeyUp: undefined,
	onBlur: undefined,
};

export default injectIntl(ATextArea);
