import "./style.scss";

import { Card, Divider, Empty, Select, Skeleton } from "antd";
import { AnimatePresence } from "framer-motion";
import { useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage, useIntl } from "react-intl";

import { filterDuplicatesByField } from "@/helpers/array.helpers";
import { storageHelpers } from "../../../../shared/helpers";
import { icon } from "../../../../assets/images";
import { trpc } from "../../../../trpc";
import ListItem from "./listItem";
import { InboxFolder } from "@/modules/inbox/hooks/useInboxNavigation.hooks";

export default function ConversationsCard() {
	const intl = useIntl();
	const dashboardState = storageHelpers.getDashboardState();
	const [selectedFolder, setSelectedFolder] = useState<InboxFolder>(
		dashboardState.conversationFolder || "personal_conversations"
	);

	const { data, isFetching, isInitialLoading, fetchNextPage, isFetchingNextPage } =
		trpc.inbox.conversation.allFromFolder.useInfiniteQuery(
			{
				folder: selectedFolder,
				status: "open",
			},
			{
				getNextPageParam: (data) => data.cursor,
				keepPreviousData: true,
			}
		);

	const conversations = useMemo(() => {
		if (!data?.pages.length) return [];
		return filterDuplicatesByField({ array: data.pages.flatMap((page) => page.data), field: "_id" });
	}, [data?.pages]);

	const folderOptions = useMemo(
		() => [
			{
				value: "personal_conversations",
				label: intl.formatMessage({
					id: "dashboard.conversation.folder_options.my_conversations.label",
					defaultMessage: "My conversations",
				}),
			},
			{
				value: "assigned_to_me",
				label: intl.formatMessage({
					id: "dashboard.conversation.folder_options.assigned_to_me.label",
					defaultMessage: "Assigned to me",
				}),
			},
			{
				value: "mentioned",
				label: intl.formatMessage({
					id: "dashboard.conversation.folder_options.mentions.label",
					defaultMessage: "Mentions",
				}),
			},
			{
				value: "shared_with_me",
				label: intl.formatMessage({
					id: "dashboard.conversation.folder_options.shared_with_me.label",
					defaultMessage: "Shared with me",
				}),
			},
			{
				value: "all_conversations",
				label: intl.formatMessage({
					id: "dashboard.conversation.folder_options.all_conversations.label",
					defaultMessage: "All conversations",
				}),
			},
			{
				value: "drafts",
				label: intl.formatMessage({
					id: "dashboard.conversation.folder_options.drafts.label",
					defaultMessage: "Drafts",
				}),
			},
			{
				value: "assigned_by_me",
				label: intl.formatMessage({
					id: "dashboard.conversation.folder_options.delegated.label",
					defaultMessage: "Delegated",
				}),
			},
			{
				value: "sent",
				label: intl.formatMessage({
					id: "dashboard.conversation.folder_options.send.label",
					defaultMessage: "Send",
				}),
			},
		],
		[intl]
	);

	const handleFolderChange = (conversationFolder: InboxFolder) => {
		setSelectedFolder(conversationFolder);
		storageHelpers.updateDashboardState({ conversationFolder });
	};

	return (
		<Card
			title={
				<div className="dashboard-conversation-title">
					<FormattedMessage id="dashboard.conversations.title" defaultMessage="Open conversations in" />
					<Select
						bordered={false}
						value={selectedFolder}
						options={folderOptions}
						popupMatchSelectWidth={false}
						onChange={handleFolderChange}
					/>
				</div>
			}
			className="dashboard-conversation"
		>
			{!conversations?.length && !isInitialLoading && (
				<Empty
					image={icon.inboxEmptyList}
					description={intl.formatMessage({
						id: "dashboard.conversation.empty.description",
						defaultMessage: "You're all caught up with all your conversations for today.",
					})}
					className="dashboard-conversation-empty"
				/>
			)}
			<div id="dashboard-conversation-container">
				<InfiniteScroll
					style={{ overflowX: "hidden" }}
					next={fetchNextPage}
					scrollThreshold={0.6}
					dataLength={conversations?.length || 0}
					hasMore={(conversations?.length || 0) < (data?.pages[0]?.total || 0)}
					loader={
						isFetching && (
							<Card className="dashboard-conversation_item">
								<Skeleton active paragraph={{ rows: 2 }} />
							</Card>
						)
					}
					endMessage={
						conversations &&
						conversations.length >= (data?.pages[0]?.total || 0) &&
						conversations.length !== 0 && (
							<Divider plain>
								<FormattedMessage
									id="dashboard.conversations.all_conversations.fetched"
									defaultMessage="All conversations fetched 📭"
									description="Message user has reached the end off the conversations list"
								/>
							</Divider>
						)
					}
					scrollableTarget="conversationList"
					className="dashboard-conversation-list"
				>
					{/* @ts-ignore */}
					<AnimatePresence>
						{conversations &&
							!isInitialLoading &&
							conversations.map((conversation) => (
								<ListItem key={conversation._id} conversation={conversation} />
							))}
					</AnimatePresence>
				</InfiniteScroll>
			</div>
		</Card>
	);
}
