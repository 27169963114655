export default {
	CHANGE_UI_FAILED: "CHANGE_UI_FAILED",
	CHANGE_PAGE_CONFIG: "CHANGE_PAGE_CONFIG",

	CHECK_COOKIE_CONCENT: "CHECK_COOKIE_CONCENT",
	CHANGE_COOKIE_CONCENT: "CHANGE_COOKIE_CONCENT",

	CHECK_BROWSER_SUPPORT: "CHECK_BROWSER_SUPPORT",
	CHECK_NETWORK_SUPPORT: "CHECK_NETWORK_SUPPORT",

	SHOW_WARNING_MESSAGE: "SHOW_WARNING_MESSAGE",
	REMOVE_WARNING_MESSAGE: "REMOVE_WARNING_MESSAGE",

	FOLD_ASIDE: "FOLD_ASIDE",
	UNFOLD_ASIDE: "UNFOLD_ASIDE",

	TOGGLE_SHORTCUTS: "TOGGLE_SHORTCUTS",
};
