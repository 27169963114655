import React from "react";

export default function AEmbed({ src, width, height, alt, className }) {
	return (
		<div className={`a-video-embed ${className}`}>
			<iframe
				src={src}
				allowFullScreen
				allow="autoplay; encrypted-media"
				rel="0"
				frameBorder="0"
				title={alt}
				width={width}
				height={height}
			/>
		</div>
	);
}
AEmbed.defaultProps = {
	width: "100%",
	height: "100%",
	alt: "",
	className: "",
};
