import { Alert, Form, FormInstance, Input, InputRef } from "antd";
import FieldLabel from "@/shared/components/field/default";
import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { ColorPicker } from "@/shared/components/input";
import { useSetFocus } from "@/shared/hooks";
import { generatorHelpers } from "@/helpers";
interface IContactLabelForm {
	name: string;
	description?: string;
	color: string;
}
interface IContactLabelFormItems {
	disabled?: boolean;
	value?: Partial<IContactLabelForm>;
	getFormInstance?: (form: FormInstance) => void;
	onSubmit: (value: IContactLabelForm) => void;
	labelSearchValue?: string;
}

export default function ContactLabelFormItems({
	value,
	disabled,
	getFormInstance,
	onSubmit,
	labelSearchValue,
}: IContactLabelFormItems) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const inputRef = useRef<InputRef>(null);
	const [hexError, setHexError] = useState(false);

	useSetFocus({ ref: inputRef, dependencies: [open] });

	useEffect(() => {
		if (!getFormInstance) return;

		getFormInstance(form);
	}, [form]);

	return (
		<Form
			form={form}
			disabled={disabled}
			layout="vertical"
			id="contactLabelForm"
			initialValues={{
				...value,
				name: labelSearchValue || value?.name || "",
				description: value?.description || "",
				color: value?.color || `#${generatorHelpers.randomColor()}`,
			}}
			onFinish={onSubmit}
			style={{ width: "100%", gap: "2em", display: "flex", flexDirection: "column" }}
		>
			<FieldLabel label="CONTACTS_LABEL_MODAL.FORM.NAME.LABEL">
				<Form.Item
					name="name"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "CONTACTS.SETTINGS.LABELS.NAME.REQUIRED",
							}),
						},
						{
							max: 56,
							message: intl.formatMessage({
								id: "contacts.settings.labels.name.validation_rule.too_long",
								defaultMessage: "Please enter a name of max 56 characters",
								description: "This text shows when th input for name can be max 56 characters long",
							}),
						},
					]}
					style={{ width: "100%", textAlign: "left" }}
				>
					<Input
						maxLength={56}
						ref={inputRef}
						disabled={disabled}
						placeholder={intl.formatMessage({
							id: "CONTACTS_LABEL_MODAL.FORM.NAME.PLACEHOLDER",
						})}
						defaultValue={labelSearchValue}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				label="CONTACTS_LABEL_MODAL.FORM.DESCRIPTION.LABEL"
				help="CONTACTS_LABEL_MODAL.FORM.DESCRIPTION.HELP"
				optional
			>
				<Form.Item name="description" style={{ width: "100%", textAlign: "left" }}>
					<Input.TextArea
						disabled={disabled}
						placeholder={intl.formatMessage({
							id: "CONTACTS_LABEL_MODAL.FORM.DESCRIPTION.PLACEHOLDER",
						})}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldLabel label="CONTACTS_LABEL_MODAL.FORM.COLOR.LABEL">
				<Form.Item style={{ maxWidth: 400, width: "100%", textAlign: "left" }} name="color">
					<ColorPicker isValidHex={(value) => setHexError(!value)} disabled={disabled} />
				</Form.Item>
			</FieldLabel>
			{hexError && (
				<Alert
					style={{ marginBottom: 24 }}
					message={intl.formatMessage({ id: "CONTACTS.SETTINGS.LABELS.HEX.ERROR" })}
					type="error"
					showIcon
				/>
			)}
		</Form>
	);
}
