import "./style.scss";

import { apiConfig } from "@/config";
import NavigationBar from "@/flows/send/components/Navigation";
import FlowSendingList from "@/flows/send/components/Overview/List";
import { messageValues } from "@/flows/send/interface";
import ShowFailedBanner from "@/shared/components/banner/failed";
import { stringHelpers } from "@bothive/helpers";
import { IContact, IFlowPopulated } from "@bothive_core/database";
import { Button, Card } from "antd";
import axios from "axios";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import MEmail from "../../../../components/molecules/molecules.data_preview/_molecules.data_preview.email";
import { trpc } from "@/trpc";

export default function FlowSendingOverview({
	contactSelection,
	messageValues,
	setCurrentStep,
	flow,
	sendToCompletedReports,
}: {
	contactSelection: Partial<IContact>[];
	messageValues: messageValues | undefined;
	setCurrentStep: (value: number) => void;
	flow: IFlowPopulated;
	sendToCompletedReports: boolean;
}) {
	const [selectedContactId, setSelectedContactId] = React.useState<string>(contactSelection[0]?._id);
	const [isSending, setIsSending] = React.useState<boolean>(false);

	const intl = useIntl();

	const { data: inbox } = trpc.inbox.inbox.getUserInbox.useQuery();

	// @ts-ignore
	const { id: userId } = useSelector((state) => state.profile.account.user);

	const addSignatureToMessage = (message: string): string => {
		if (messageValues?.signatureId) {
			const channelSignatures =
				inbox?.signatures?.[messageValues.channel.id] || Object.values(inbox?.signatures || {})?.[0];

			const signature = channelSignatures?.find((item) => item._id === messageValues.signatureId);

			message = `${message}<br><br>${signature?.content}`;
		}

		return message;
	};

	const { htmlBody, subject } = useMemo(() => {
		const contact = contactSelection.find((contact) => contact._id === selectedContactId);
		const payload = { contact, firstName: stringHelpers.firstToUppercase(contact?.firstName || "") };

		let htmlBody = stringHelpers.replaceTemplates({ string: messageValues?.message || "", payload });

		htmlBody = addSignatureToMessage(htmlBody);

		return {
			htmlBody,
			subject: stringHelpers.replaceTemplates({ string: messageValues?.subject || "", payload }),
		};
	}, [messageValues, contactSelection, selectedContactId]);

	const sendFlow = async () => {
		setIsSending(true);

		const result = await axios.post(apiConfig.flow.sendFlow.replace(":id", flow.id), {
			flowId: flow.id,
			channelId: messageValues?.channel?.id,
			subject: messageValues?.subject,
			message: addSignatureToMessage(messageValues?.message || ""),
			userId,
			contactIds: contactSelection.map((contact) => contact._id),
			sendToCompletedReports: sendToCompletedReports,
		});

		if (result.status !== 202) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.SEND.OVERVIEW.SEND_FLOW_FAILED.TITLE",
					defaultMessage: "Flow could not be sent",
				}),
				description: intl.formatMessage({
					id: "FLOWS.SEND.OVERVIEW.SEND_FLOW_FAILED.DESCRIPTION",
					defaultMessage:
						"Something went wrong while sending the flow, please try again or contact our customer support",
				}),
			});

			setIsSending(false);

			return;
		}

		setIsSending(false);

		setCurrentStep(3);
	};

	return (
		<div style={{ display: "flex", gap: "2em", flexDirection: "column" }}>
			<header className="m-header m-header--description">
				<h1>
					<FormattedMessage id="FLOWS.SEND.OVERVIEW.TITLE" defaultMessage="Overview" />
				</h1>
				<p>
					<FormattedMessage
						id="FLOWS.SEND.OVERVIEW.DESCRIPTION"
						defaultMessage="Validate your contacts and message before you send it"
					/>
				</p>
			</header>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					gap: "4em",
					paddingBottom: "12em",
					maxHeight: "650px",
				}}
			>
				<FlowSendingList
					contactSelection={contactSelection}
					setSelectedContactId={setSelectedContactId}
					selectedContactId={selectedContactId}
				/>
				<div style={{ display: "flex", flexDirection: "column", maxHeight: "650px" }}>
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "1fr auto",
							textAlign: "left",
							// flex: 1,
							gap: "1em",
							justifyItems: "start",
						}}
					>
						<p className="send_report-overview-label">
							<FormattedMessage id="FLOWS.SEND.OVERVIEW.FLOW" defaultMessage="Flow:" />
						</p>
						<p>{flow?.template?.name?.[intl.locale]}</p>
						<p className="send_report-overview-label">
							<FormattedMessage id="FLOWS.SEND.OVERVIEW.CHANNEL" defaultMessage="Channel:" />
						</p>
						<p> {messageValues?.channel?.name}</p>
						<p className="send_report-overview-label">
							<FormattedMessage id="FLOWS.SEND.OVERVIEW.SUBJECT" defaultMessage="Subject:" />
						</p>
						<p>{subject}</p>
						<p className="send_report-overview-label">
							<FormattedMessage id="FLOWS.SEND.OVERVIEW.EMAIL" defaultMessage="Email:" />
						</p>
					</div>
					<Card className="t-gap--top-sm" style={{ overflowY: "scroll" }}>
						<MEmail html={htmlBody} />
					</Card>
				</div>
			</div>
			<NavigationBar>
				<Button type="text" style={{ margin: "1em" }} onClick={() => setCurrentStep(1)}>
					<FormattedMessage id="FLOWS.SEND.COMPOSE.PREVIOUS" defaultMessage="Previous" />
				</Button>
				<Button type="primary" style={{ margin: "1em" }} onClick={() => sendFlow()} loading={isSending}>
					<FormattedMessage
						id="FLOWS.SEND.CONTACT_SELECTION.BUTTONS.SEND"
						defaultMessage="{value, plural, one {Send # email} other {Send # emails}}"
						values={{
							value: contactSelection.length,
						}}
					/>
				</Button>
			</NavigationBar>
		</div>
	);
}
