import "./style.scss";

import { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Header } from "../../../components/molecules";
import { Section } from "../../../components/organisms";
import { Layout } from "../../../components/templates";
import { DashboardCard } from "../../../shared/components/card";
import FieldLabel from "../../../shared/components/field/default";
import ImageUpload from "../../../shared/components/upload/imageUpload";
import ShowFailedBanner from "../../../shared/components/banner/failed";
import ShowSuccessBanner from "../../../shared/components/banner/success";
import { actions as dashboardActions } from "../../../store/dashboard/index";
import { trpc } from "../../../trpc";
import config from "./config";

/**
 * TODO
 * move all components into module with ant design components
 */

export default function VirtualAssistantGeneral({ className }: { className: string }) {
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const routeParameters = useParams<{ team: string }>();
	const isAllowedToEditing = useSelector((state) => state.dashboard.permissions.userPermissions.editSettings);

	const { data: organization, isLoading } = trpc.organization.getOrganization.useQuery();
	const updateVirtualAssistant = trpc.organization.updateVirtualAssistant.useMutation();

	useEffect(() => {
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					title: intl.formatMessage({
						id: "virtual_assistant_settings.title",
						defaultMessage: "Virtual assistant",
					}),
					tabtitle: intl.formatMessage({
						id: "virtual_assistant_settings.tab_title",
						defaultMessage: "Virtual assistant",
					}),
					breadcrumbs: [
						{
							to: `/${routeParameters.team}/settings`,
							name: intl.formatMessage({
								id: "virtual_assistant_settings.breadcrumb.settings",
								defaultMessage: "Settings",
							}),
						},
						{
							to: `/${routeParameters.team}/settings/organization/general`,
							name: intl.formatMessage({
								id: "virtual_assistant_settings.breadcrumb.title",
								defaultMessage: "Virtual assistant",
							}),
						},
					],
				},
			})
		);
	}, []);

	const handleSubmit = async (value) => {
		if (!isAllowedToEditing) return;

		try {
			await updateVirtualAssistant.mutateAsync(value);

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "settings.virtual_assistant.update.succeeded",
					defaultMessage: "Your virtual assistant has been updated.",
				}),
				intl,
			});
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "settings.virtual_assistant.update.failed",
					defaultMessage: "Could not update your virtual assistant please try again later.",
				}),
				intl,
			});
		}
	};

	function handleSuggestionClick(id: string) {
		const suggestion = config.suggestions.find((suggestion) => suggestion.id === id);

		if (!suggestion) return console.error("Suggestion not found");

		history.push(suggestion.url.replace(":team", routeParameters.team));
	}

	if (isLoading || !organization) return <Layout.TLoadScreen />;

	return (
		<section className={`page-settings--content page-settings--suggestions ${className}`}>
			<DashboardCard
				title={intl.formatMessage({
					id: "settings.virtual_assistant.title",
					defaultMessage: "Virtual assistant",
				})}
				description={intl.formatMessage({
					id: "settings.virtual_assistant.description",
					defaultMessage:
						"Give your chatbot more personality by changing the name and avatar. This will be the first thing the user will see when they communicate with your chatbot",
				})}
			>
				<Form
					id="virtualAssistantForm"
					onFinish={handleSubmit}
					disabled={!isAllowedToEditing}
					initialValues={organization.virtualAssistant}
					className="virtual_assistant-form"
				>
					<FieldLabel
						id="avatar"
						className="virtual_assistant-form-icon"
						label={intl.formatMessage({
							id: "settings.virtual_assistant.form.avatar.label",
							defaultMessage: "Avatar",
						})}
						help={intl.formatMessage({
							id: "settings.virtual_assistant.form.avatar.help",
							defaultMessage: "Maximum avatar size is 10MB",
						})}
					>
						<Form.Item
							name="avatar"
							className="setup_content-form-field"
							required
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "settings.virtual_assistant.form.avatar.required",
										defaultMessage: "Avatar is required",
									}),
								},
							]}
						>
							<ImageUpload id="avatar" crop={{ aspect: 1 / 1 }} disabled={!isAllowedToEditing} />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						id="name"
						className="virtual_assistant-form-name"
						label={intl.formatMessage({
							id: "settings.virtual_assistant.form.name.label",
							defaultMessage: "Name",
						})}
					>
						<Form.Item
							name="name"
							className="virtual_assistant-form-field"
							required
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "settings.virtual_assistant.form.name.required",
										defaultMessage: "Your virtual assistant should really have a name",
									}),
								},
							]}
						>
							<Input size="large" placeholder="Sophia" maxLength={50} />
						</Form.Item>
					</FieldLabel>

					<footer className="virtual_assistant-form-footer">
						<Button
							size="large"
							type="primary"
							htmlType="submit"
							form="virtualAssistantForm"
							disabled={!isAllowedToEditing}
							loading={updateVirtualAssistant.isLoading}
						>
							<FormattedMessage id="settings.virtual_assistant.form.footer.save" defaultMessage="Save" />
						</Button>
					</footer>
				</Form>
			</DashboardCard>
			<Header.MDefaultHeader
				title={intl.formatMessage({
					id: "settings.virtual_assistant.suggestions.title",
					defaultMessage: "Settings you might find useful",
				})}
				className="t-gap--top-lg"
			/>
			<div className="virtual_assistant-suggestions">
				{config.suggestions.map((suggestion) => (
					<DashboardCard key={suggestion.id} {...suggestion}>
						<Section.OSectionInformation
							{...suggestion.content}
							formattedMessageValues={{ agent: organization.virtualAssistant?.name }}
							onClick={() => handleSuggestionClick(suggestion.id)}
						/>
					</DashboardCard>
				))}
			</div>
		</section>
	);
}
