import { useState } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import Buttons from "./buttons";
import ChangeStatusModal from "./modals/change_status";
import DownloadReportsModal from "./modals/download_reports";
import SendIntegrationModal from "./modals/send_integration";
import DeleteReportsModal from "./modals/delete_reports";
import { selectedReportIdsState } from "./state";
import SendFlowModal from "../../../send";
import { flowState, selectedFlowIdState } from "../../../common/state";
import { typesConfig } from "../../../../config";
import { allReportsSelectedState } from "./state";

export default function BulkActionButtons() {
	const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
	const [showDownloadReportModal, setShowDownloadReportModal] = useState(false);
	const [showSendIntegrationModal, setShowSendIntegrationModal] = useState(false);
	const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
	const [showFlowSendModal, setShowFlowSendModal] = useState(false);

	const allReportsSelected = useRecoilValue(allReportsSelectedState);
	const selectedReportIds = useRecoilValue(selectedReportIdsState);

	const flowId = useRecoilValue(selectedFlowIdState);
	const flow = useRecoilValueLoadable(flowState({ id: flowId }));

	return (
		<>
			{selectedReportIds?.length > 0 && (
				<Buttons
					template={flow.contents?.template}
					{...{
						setShowSendIntegrationModal,
						setShowDownloadReportModal,
						setShowChangeStatusModal,
						setShowBulkDeleteModal,
						setShowFlowSendModal,
					}}
				/>
			)}
			<DownloadReportsModal
				visible={showDownloadReportModal}
				{...{ showDownloadReportModal, setShowDownloadReportModal }}
			/>
			<ChangeStatusModal
				visible={showChangeStatusModal}
				{...{ showChangeStatusModal, setShowChangeStatusModal }}
			/>
			<SendIntegrationModal
				visible={showSendIntegrationModal}
				{...{ showSendIntegrationModal, setShowSendIntegrationModal }}
			/>
			<DeleteReportsModal visible={showBulkDeleteModal} {...{ showBulkDeleteModal, setShowBulkDeleteModal }} />
			{showFlowSendModal && (
				<SendFlowModal
					flow={flow?.contents}
					handleClose={() => setShowFlowSendModal(false)}
					showReports={true}
					reportSelection={selectedReportIds}
					defaultProgress={
						allReportsSelected ? Object.values(typesConfig.sessionState).map((item) => item) : undefined
					}
				/>
			)}
		</>
	);
}
