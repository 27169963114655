import { routesConfig } from "@/flows/common/config";

export default {
	header: {
		title: "FLOW_DETAIL.PAGE.TITLE",
		tabTitle: "FLOW_DETAIL.PAGE.TITLE",
	},
	breadcrumbs: ({ team, name, flowId }) => [
		{
			to: routesConfig.overview.replace(":team", team),
			name: "FLOW_DETAIL.HEADER.BREADCRUMB.FLOWS",
		},
		{
			to: routesConfig.reports.replace(":team", team).replace(":flowId", flowId),
			name: name,
		},
	],
};
