import "./style.scss";

import { Button, Form, Input, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useSetRecoilState } from "recoil";

import { flowState } from "@/flows/common/state";
import { updateFlowList } from "@/flows/overview/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import DashboardCard from "@/shared/components/card/dashboard";
import FieldLabel from "@/shared/components/field/default";
import FieldSideLabel from "@/shared/components/field/side_label";

import FlowLink from "./flow_link";
import { updateFlow } from "./query";

export default function FlowCard({ flow, isLoading, isDisabled }) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const updateFlowInList = useSetRecoilState(updateFlowList);
	const updateFlowState = useSetRecoilState(flowState({ id: flow._id }));

	const updateFlowSettings = async ({ name, description, isEnabled, isHidden }) => {
		try {
			const payload = {
				name,
				description,
				isEnabled,
				widgetSettings: {
					...flow.widgetSettings,
					isHidden: !isHidden, // inverted as the phrasing of switch is "show on website?" and property is "isHidden"
				},
			};

			const result = await updateFlow({ id: flow._id, payload });

			if (!result?.success) {
				throw new Error();
			}

			updateFlowState({ ...flow, ...payload });
			updateFlowInList({ flowId: flow._id, payload });
			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT.SETTINGS.FORM.SUCCEEDED.TITLE",
					defaultMessage: "Flow changed",
				}),
			});
		} catch (error) {
			console.error({ error });
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT.SETTINGS.FORM.FAILED.TITLE",
					defaultMessage: "Flow could not be updated",
				}),
				description: intl.formatMessage({
					id: "FLOWS.REPORT.SETTINGS.FORM.FAILED.DESCRIPTION",
					defaultMessage: "Please try again later or contact customer support",
				}),
			});
		}
	};

	return (
		<DashboardCard
			title="FLOWS.REPORT.SETTINGS.CUSTOMIZE.HEADER.TITLE"
			description="FLOWS.REPORT.SETTINGS.CUSTOMIZE.HEADER.DESCRIPTION"
			isLoading={isLoading}
			className="frsf-card"
		>
			<Form
				form={form}
				disabled={isDisabled}
				initialValues={{
					name: flow.name,
					description: flow.description,
					isEnabled: flow.isEnabled,
					isHidden: !flow.widgetSettings?.isHidden,
				}}
				className="frsf-form"
				layout="vertical"
				onFinish={(payload) => updateFlowSettings(payload)}
			>
				<div className="frsf-wrapper">
					<FieldLabel label="FLOWS.REPORT.SETTINGS.CUSTOMIZE.FORM.NAME.LABEL">
						<Form.Item name="name" rules={[{ max: 64 }]} className="form-item">
							<Input placeholder={flow.template?.name?.[intl.locale]} />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						className="t-gap--top-sm"
						label="FLOWS.REPORT.SETTINGS.CUSTOMIZE.FORM.DESCRIPTION.LABEL"
					>
						<Form.Item name="description" rules={[{ max: 256 }]} className="form-item">
							<Input.TextArea
								className="text-area"
								rows={4}
								placeholder={flow.template?.description?.[intl.locale]}
							/>
						</Form.Item>
					</FieldLabel>
					<FieldSideLabel
						className="t-gap--top-sm"
						label="FLOWS.REPORT.SETTINGS.FORM.IS_ENABLED.LABEL"
						help="FLOWS.REPORT.SETTINGS.FORM.IS_ENABLED.HELP"
					>
						<Form.Item name="isEnabled">
							<Switch defaultChecked={flow.isEnabled} />
						</Form.Item>
					</FieldSideLabel>
					<FieldSideLabel
						className="t-gap--top-sm"
						label="FLOWS.REPORT.SETTINGS.FORM.SHOW_FLOW.LABEL"
						help="FLOWS.REPORT.SETTINGS.FORM.SHOW_FLOW.HELP"
					>
						<Form.Item name="isHidden">
							<Switch defaultChecked={!flow?.widgetSettings?.isHidden} />
						</Form.Item>
					</FieldSideLabel>
					{!flow?.template?.deactivated && (
						<FieldLabel
							label="FLOWS.REPORT.SETTINGS.UNIQUE_LINK.LABEL"
							help="FLOWS.REPORT.SETTINGS.UNIQUE_LINK.HELP"
							className="t-gap--top-sm"
						>
							<Form.Item className="form-item">
								<FlowLink link={flow.link} isLoading={isLoading} />
							</Form.Item>
						</FieldLabel>
					)}
					<Form.Item>
						<Button type="primary" htmlType="submit" className="save-button t-gap--top" loading={isLoading}>
							<FormattedMessage id="FLOWS.REPORT.SETTINGS.WIDGET.FORM.BUTTON.SAVE" />
						</Button>
					</Form.Item>
				</div>
			</Form>
		</DashboardCard>
	);
}
