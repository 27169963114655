import "./style.scss";

import { DeleteOutlined, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import type { IContact } from "@bothive_core/database";
import { Button, Form, Input, Select, Switch, Tooltip, Typography } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import FieldLabel from "@/shared/components/field/default";
import { trpc } from "../../../../../../trpc";
import { ExistingContactDisplay } from "../../../../components/existingContactDisplay";

interface IContactEmailAddresses {
	emailAddresses?: {
		name: string;
		value: string;
		isPrimary: boolean;
	}[];
	className: string;
}

export default function ContactEmailAddresses({ emailAddresses, className }: IContactEmailAddresses) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const params: { contactId: string } = useParams();
	const [conflictContact, setConflictContact] = useState<IContact | undefined>(undefined);

	const trpcUtils = trpc.useUtils();
	const updateEmailAddressMutation = trpc.contact.updateEmail.useMutation();

	const selectOptions = [
		{
			label: intl.formatMessage({
				id: "contacts.detail.attributes.email_address.label.standard",
				defaultMessage: "Standard",
			}),
			value: "STANDARD",
		},
		{
			label: intl.formatMessage({
				id: "contacts.detail.attributes.email_address.label.work",
				defaultMessage: "Work",
			}),
			value: "WORK",
		},
		{
			label: intl.formatMessage({
				id: "contacts.detail.attributes.email_address.label.billing",
				defaultMessage: "Billing",
			}),
			value: "BILLING",
		},
		{
			label: intl.formatMessage({
				id: "contacts.detail.attributes.email_address.label.private",
				defaultMessage: "Private",
			}),
			value: "PRIVATE",
		},
		{
			label: intl.formatMessage({
				id: "contacts.detail.attributes.email_address.label.other",
				defaultMessage: "Other",
			}),
			value: "OTHER",
		},
	];

	const handleValuesChange = (newValue, oldValue) => {
		// If address is set to primary, set old primary address to false
		if (!newValue.emailAddresses || !Array.isArray(newValue.emailAddresses)) return;

		const newPrimaryIndex = newValue.emailAddresses.findIndex((value) => value?.isPrimary);

		// if primary is not changed -> exit
		if (newPrimaryIndex === -1) return;

		const previousPrimaryIndex = oldValue.emailAddresses.findIndex(
			(value, index) => value?.isPrimary && newPrimaryIndex !== index
		);

		// if primary is not changed against old values -> exit
		if (newPrimaryIndex === previousPrimaryIndex || previousPrimaryIndex === -1) return;

		oldValue.emailAddresses[previousPrimaryIndex].isPrimary = false;

		form.setFieldsValue(oldValue);
	};

	const handleSubmit = ({ emailAddresses }) => {
		updateEmailAddressMutation.mutate(
			{ contactId: params.contactId, emails: emailAddresses },
			{
				onSuccess: () => {
					setConflictContact(undefined);
					trpcUtils.contact.getContactById.invalidate();
					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "contacts.detail.attributes.email_addresses.update.success.title",
							defaultMessage: "Email address saved",
						}),
					});
				},
				onError: (error) => {
					if (error?.data?.code === "CONFLICT") {
						const details = error?.shape?.message
							? (JSON.parse(error?.shape?.message) as { conflictContact: IContact })
							: null;

						if (details?.conflictContact) setConflictContact(details.conflictContact);

						ShowFailedBanner({
							title: intl.formatMessage({
								id: "contacts.detail.attributes.email_addresses.update.failed.title",
								defaultMessage: "Email address could not be saved",
							}),
							description: intl.formatMessage({
								id: "contacts.detail.attributes.email_addresses.update.failed.description",
								defaultMessage: "The content of this field needs to be unique in this organization",
							}),
						});
						return;
					}
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "contacts.detail.attributes.email_addresses.update.failed.title",
							defaultMessage: "Email address could not be saved",
						}),
					});
				},
			}
		);
	};

	return (
		<Form
			form={form}
			preserve={false}
			layout="vertical"
			id="contactEmailForm"
			initialValues={{ emailAddresses }}
			onValuesChange={handleValuesChange}
			onFinish={handleSubmit}
			className={`contact_email_address ${className || ""}`}
		>
			<Form.List
				name="emailAddresses"
				rules={[
					{
						validator: async (_, emailAddresses) => {
							if (!emailAddresses?.length) {
								const errorLabel = intl.formatMessage({
									id: "contacts.detail.attributes.email_addresses.form.error",
									defaultMessage: "Please enter at least 1 email address",
								});

								return Promise.reject(new Error(errorLabel));
							}
						},
					},
				]}
			>
				{(fields, { add, remove }, { errors }) => (
					<>
						{fields.map((field, index) => {
							const isPrimaryField = form.getFieldValue(["emailAddresses", field.name, "isPrimary"]);
							const hasConflict = conflictContact?.email?.find(
								(email) => email.value === form.getFieldValue(["emailAddresses", field.name, "value"])
							);

							return (
								<>
									{hasConflict && conflictContact && (
										<div className="email_address-form_row-error">
											<Typography.Text type="danger">
												<FormattedMessage
													id="contacts.detail.attributes.email_addresses.error.duplicate"
													defaultMessage="Email address is already added to the following contact in your organization"
													description="Error message for email address that already exists with preview of the contact that has the same email"
												/>
											</Typography.Text>
											<ExistingContactDisplay
												contact={conflictContact}
												className="t-gap--bottom"
											/>
										</div>
									)}
									<Form.Item key={field.key} className="email_address-form_row">
										<FieldLabel
											id={`emailAddresses_${index}_name`}
											label={intl.formatMessage({
												id: "contacts.detail.attributes.email_addresses.label.title",
												defaultMessage: "Label",
											})}
											style={{ width: "fit-content", marginRight: 16 }}
										>
											<Form.Item {...field} name={[field.name, "name"]}>
												<Select
													placement="bottomLeft"
													style={{ width: 254, textAlign: "left" }}
													options={selectOptions}
												/>
											</Form.Item>
										</FieldLabel>
										<FieldLabel
											id={`emailAddresses_${index}_value`}
											label={intl.formatMessage({
												id: "contacts.detail.attributes.email_addresses.address.title",
												defaultMessage: "Address",
											})}
											className="email_address-form_row-email"
										>
											<Form.Item
												{...field}
												name={[field.name, "value"]}
												validateTrigger={["onChange", "onBlur"]}
												rules={[
													{
														required: true,
														type: "email",
														message: intl.formatMessage({
															id: "contacts.detail.attributes.email_addresses.address.invalid_error",
															defaultMessage: "Please enter a valid email address",
														}),
													},
												]}
											>
												<Input
													placeholder="sophia@bothive.be"
													status={hasConflict ? "error" : undefined}
													prefix={hasConflict ? <WarningOutlined /> : undefined}
												/>
											</Form.Item>
										</FieldLabel>
										<FieldLabel
											id={`emailAddresses_${index}_isPrimary`}
											label={intl.formatMessage({
												id: "contacts.detail.attributes.email_addresses.add.address.primary.label",
												defaultMessage: "Primary",
											})}
											style={{ width: "100px", marginLeft: "32px" }}
										>
											<Tooltip
												title={
													isPrimaryField &&
													intl.formatMessage({
														id: "contacts.detail.attributes.email_addresses.add.address.primary.tooltip",
														defaultMessage:
															"Make another email address primary to undo this",
													})
												}
											>
												<Form.Item
													{...field}
													name={[field.name, "isPrimary"]}
													valuePropName="checked"
												>
													<Switch disabled={isPrimaryField} />
												</Form.Item>
											</Tooltip>
										</FieldLabel>
										<Form.Item>
											<Tooltip
												title={
													isPrimaryField &&
													intl.formatMessage({
														id: "contacts.detail.attributes.email_addresses.add.delete_button.tooltip",
														defaultMessage: "Primary email can not be removed.",
													})
												}
											>
												<Button
													type="text"
													disabled={isPrimaryField}
													icon={<DeleteOutlined />}
													onClick={() => remove(field.name)}
													className="email_address-form_row-delete"
												/>
											</Tooltip>
										</Form.Item>
									</Form.Item>
								</>
							);
						})}
						<Form.Item>
							<Button
								style={{ width: "fit-content" }}
								type="text"
								icon={<PlusOutlined />}
								onClick={() =>
									add({
										name: "STANDARD",
										value: "",
										isPrimary: !form.getFieldValue("emailAddresses")?.length,
									})
								}
							>
								{" "}
								<FormattedMessage
									id="contacts.detail.attributes.email_addresses.add.address"
									defaultMessage="Add email address"
								/>
							</Button>
							<Form.ErrorList errors={errors} />
						</Form.Item>
					</>
				)}
			</Form.List>
			<Form.Item style={{ width: "fit-content", alignSelf: "flex-end" }}>
				<Button
					key="submit"
					type="primary"
					htmlType="submit"
					className="t-gap--top"
					style={{ width: "fit-content", alignSelf: "flex-end" }}
				>
					<FormattedMessage id="contacts.detail.attributes.email_addresses.submit" defaultMessage="Save" />
				</Button>
			</Form.Item>
		</Form>
	);
}
