import React from "react";

export default function NavigationBar({ children }: { children: React.ReactNode }) {
	return (
		<div
			style={{
				position: "absolute",
				bottom: 0,
				backgroundColor: "white",
				width: "100vw",
				display: "flex",
				justifyContent: "center",
				borderTop: "1px solid rgba(0, 33, 64, 0.2)",
			}}
		>
			{children}
		</div>
	);
}
