/** @deprecated is here only to support the old rules component */
export const rulesFileKeys = [
	"type",
	"firstName",
	"middleName",
	"lastName",
	"fullName",
	"uniqueIdentifier",
	"email",
	"phone",
	"nationalRegistrationNumber",
	"hasDisability",
	"language",
	"birthday",
	"ibanReimbursement",
	"address.[0].streetName",
	"address.[0].streetNumber",
	"address.[0].city",
	"address.[0].bus",
	"address.[0].country",
	"address.[0].postalCode",
	"eid.cardNumber",
	"idCardNumber",
	"eid.expirationData",
	"idCardNumberExpiryDate",
];
