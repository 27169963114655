import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

function createListItemClass({ item, config }) {
	if (!config?.listItemClass) {
		return "";
	}

	return config.listItemClass(item);
}

export default function AListWrapper({
	items,
	selected,
	className,
	onClick,
	onKeyUp,
	fallback,
	showFallback,
	render,
	setRef,
	config,
}) {
	const listRef = useRef();
	const [isLoaded, setIsLoaded] = useState(false);
	const [showOnTop, setShowOnTop] = useState(false);

	useEffect(() => {
		if (!listRef.current || isLoaded) {
			return;
		}

		const elementPos = listRef.current.getBoundingClientRect();
		const body = document.body.getBoundingClientRect();

		// if dropdown disappears of window bottom
		if (elementPos.bottom > body.bottom) {
			setShowOnTop(true);
		}

		// if dropdown doesn't disappears of window bottom
		if (showOnTop && elementPos.bottom < body.bottom) {
			setShowOnTop(false);
		}

		setIsLoaded(true);
	}, [listRef.current]);

	if (!items?.length) {
		if (!showFallback) {
			return null;
		}

		return (
			<div className={`a-dropdown-list a-dropdown-fallback ${className}`}>
				{fallback || (
					<p>
						<FormattedMessage id="FALLBACK.DROPDOWN.NO_ITEMS_FOUND" />
					</p>
				)}
			</div>
		);
	}

	return (
		<ul ref={listRef} data-on-top={showOnTop} className={`a-dropdown-list ${className}`}>
			{items.map((item, index) =>
				render({
					item,
					key: `list_item_${item.uuid || item.id || item._id}`,
					className: createListItemClass({ item, config }),
					selected: (item.uuid || item.id || item._id) === selected,
					"aria-posinset": index,
					"aria-setsize": items.length,
					onClick: (event) => onClick({ event, index, item }),
					onKeyUp: (event) => onKeyUp({ event, index, item }),
					setRef: setRef,
				})
			)}
		</ul>
	);
}
