import "./style.scss";

import { Skeleton } from "antd";

export default function FormLoader() {
	return (
		<div className="sign_up-form sign_up-form_loader">
			<Skeleton.Input active size="large" className="sign_up-form-first_name sign_up-form_loader-skeleton" />
			<Skeleton.Input active size="large" className="sign_up-form-last_name sign_up-form_loader-skeleton" />
			<Skeleton.Input active size="large" className="sign_up-form-email sign_up-form_loader-skeleton" />
			<Skeleton.Input active size="large" className="sign_up-form-phone sign_up-form_loader-skeleton" />
			<Skeleton.Input active size="large" className="sign_up-form-password sign_up-form_loader-skeleton" />
			<Skeleton.Button active size="large" className="sign_up-form-submit sign_up-form_loader-skeleton" />
		</div>
	);
}
