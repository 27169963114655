import type { IMessage, InternalMessage as InternalMessageType } from "@bothive_core/database";
import { Avatar } from "antd";
import { HTMLMotionProps, motion } from "framer-motion";
import { forwardRef } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import "./style.scss";

import { dateHelpers } from "@/shared/helpers";
import type { EditorThemeClasses } from "lexical";
import { MarkdownText } from "../../../../../../../shared/components/text";
import { MessageRouterOutput } from "../../../../../../../trpc";

export const messageDarkTheme: EditorThemeClasses = {
	link: "icm-message-markdown-link-dark",
	list: {
		ul: "icm-message-markdown-ul",
		olDepth: ["icm-message-markdown-ol1"],
	},
	text: {
		code: "editor-markdown-text-code icm-message-markdown-text-code-dark tempaalabla",
	},
};

type InternalMessageProps = Pick<
	MessageRouterOutput["allByConversationId"][number] & IMessage<InternalMessageType>,
	"authorData" | "author" | "payload" | "createdAt"
> &
	HTMLMotionProps<"article"> & { hasEqualSenderAsNext: boolean; hasEqualSenderAsPrevious: boolean };

const InternalMessage = forwardRef(
	(
		{
			authorData,
			author,
			payload,
			createdAt,
			hasEqualSenderAsNext,
			hasEqualSenderAsPrevious,
			...props
		}: InternalMessageProps,
		ref: any
	) => {
		const intl = useIntl();
		const userId = useSelector((state: any) => state.profile.account.user._id, shallowEqual);

		return (
			<motion.article
				ref={ref}
				className="icm-message"
				data-has-equal-sender={hasEqualSenderAsNext}
				data-is-own-message={userId === author?.id}
				data-is-first-from-sender={!hasEqualSenderAsPrevious}
				data-is-last-from-sender={!hasEqualSenderAsNext}
				{...props}
			>
				<p className="icm-message-sender__name">{authorData?.type === "user" && authorData?.author.username}</p>
				<div className="icm-message-container">
					<p className="icm-message-send-time">
						{dateHelpers.getRelativeDate({ date: createdAt, alwaysIncludeTime: true, locale: intl.locale })}
					</p>
					<div className="icm-message-content">
						<MarkdownText
							value={payload.content}
							namespace="internalMessage"
							theme={userId === author?.id ? messageDarkTheme : {}}
						/>
					</div>
				</div>
				{!hasEqualSenderAsPrevious ? (
					<Avatar
						shape="square"
						className="icm-message-sender__image"
						src={authorData?.type === "user" ? authorData?.author.avatar : ""}
						alt={`${authorData?.type === "user" && authorData?.author.username} | avatar`}
					/>
				) : (
					<span style={{ width: 32, gridArea: "avatar" }}></span>
				)}
			</motion.article>
		);
	}
);

export default InternalMessage;
