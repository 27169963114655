import "./style.scss";

import { Button, Form, InputNumber, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { Panel } from "../../../../../../shared/components/collapse";
import FieldLabel from "@/shared/components/field/default";
import { ContactRouterOutput } from "@/trpc";

type IChargingStation = Required<ContactRouterOutput["getContactById"]>["chargingStation"];

interface IChargingStationProps {
	onChange: (value: Partial<ContactRouterOutput["getContactById"]>) => void;
	contact: ContactRouterOutput["getContactById"];
	panelProps?: any;
}

function ChargingStation({ contact, onChange, ...panelProps }: IChargingStationProps) {
	const intl = useIntl();
	const [form] = Form.useForm();

	const handleSubmit = (value: IChargingStation) => {
		const chargingStation = Object.keys(value).reduce<IChargingStation>(
			(prev: IChargingStation, key: string) => ({
				...prev,
				[key]: value[key] || false,
			}),
			{} as IChargingStation
		);

		onChange({ chargingStation });
	};

	return (
		<Panel
			key="chargingStation"
			title={intl.formatMessage({ id: "CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.TITLE" })}
			{...panelProps}
		>
			<Form
				form={form}
				layout="vertical"
				id="chargingStationForm"
				className="frsf-form charging_station_form"
				onFinish={handleSubmit}
				initialValues={contact.chargingStation}
			>
				<FieldLabel
					id="hasChargingStation"
					label="CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.HAS_CHARGING_STATION.LABEL"
				>
					<Form.Item name="hasChargingStation" valuePropName="checked">
						<Switch />
					</Form.Item>
				</FieldLabel>
				{Form.useWatch("hasChargingStation", form) && (
					<div className="charging_station_form-section">
						<FieldLabel
							id="installationYear"
							label="CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INSTALLATION_YEAR.LABEL"
						>
							<Form.Item
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id: "CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INSTALLATION_YEAR.LABEL.ERROR_REQUIRED",
										}),
									},
								]}
								name="installationYear"
							>
								<InputNumber
									style={{ width: "100%" }}
									min={2000}
									placeholder={new Date().getFullYear().toString()}
								/>
							</Form.Item>
						</FieldLabel>
						<FieldLabel
							id="isInstalled"
							label="CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INSTALLED.LABEL"
						>
							<Form.Item name="isInstalled" valuePropName="checked">
								<Switch />
							</Form.Item>
						</FieldLabel>
						<FieldLabel
							id="isLocatedAtDomicile"
							label="CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.LOCATED_AT_DOMICILE.LABEL"
						>
							<Form.Item name="isLocatedAtDomicile" valuePropName="checked">
								<Switch />
							</Form.Item>
						</FieldLabel>
						<FieldLabel
							id="isIntelligent"
							label="CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INTELLIGENT.LABEL"
						>
							<Form.Item name="isIntelligent" valuePropName="checked">
								<Switch />
							</Form.Item>
						</FieldLabel>
						<FieldLabel
							id="isUsingGreenPower"
							label="CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.GREEN_POWER.LABEL"
						>
							<Form.Item name="isUsingGreenPower" valuePropName="checked">
								<Switch />
							</Form.Item>
						</FieldLabel>
						<FieldLabel
							id="isInspected"
							label="CONTACTS.DETAIL.ATTRIBUTES.CHARGING_STATION.FORM.INSPECTED.LABEL"
						>
							<Form.Item name="isInspected" valuePropName="checked">
								<Switch />
							</Form.Item>
						</FieldLabel>
					</div>
				)}
				<Button
					key="submit"
					type="primary"
					htmlType="submit"
					form="chargingStationForm"
					style={{ justifySelf: "flex-end", width: "fit-content" }}
				>
					<FormattedMessage id="BUTTON.SAVE" />
				</Button>
			</Form>
		</Panel>
	);
}

export default ChargingStation;
