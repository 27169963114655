import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Aside, Header } from "../../components/organisms";
import { Layout } from "../../components/templates";
import { globalHeaders } from "../../helpers";

const mapStateToProps = (state) => ({
	user: state.profile.account.user,
	title: state.dashboard.ui.title || "",
	breadcrumbs: state.dashboard.ui.breadcrumbs || [],
	userIsFetching: state.profile.account.isFetching,
});

function ProfileTemplate({ user, title, breadcrumbs, userIsFetching, children }) {
	let header;

	useEffect(() => {
		globalHeaders.updateHeaders({ organizationId: undefined });
	}, []);

	if (!user || userIsFetching) {
		return <Layout.TLoadScreen />;
	}

	if (user) {
		header = <Header.OProfileHeader title={title} breadcrumbs={breadcrumbs} />;
	}

	return (
		<div className="t-layout--template">
			<Aside.OAsideProfile />
			<div className="t-layout--dashboard-template-content">
				{header}
				{children}
			</div>
		</div>
	);
}

export default withRouter(connect(mapStateToProps, {})(injectIntl(ProfileTemplate)));
