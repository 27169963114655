import "./style.scss";

import { ReactNode } from "react";

import { AccordionHeader } from "../../../../components/molecules";

type DestructivePanel = {
	panelKey?: string;
	title: string;
	isActive?: boolean;
	onItemClick?: (panelKey?: string) => void;
	children?: JSX.Element | ReactNode;
};
export default function DestructivePanel({ panelKey, title, isActive, onItemClick, children }: DestructivePanel) {
	const handleClick = () => {
		if (!onItemClick) return;
		onItemClick(panelKey);
	};

	return (
		<article className="shared-destructive-panel" data-is-active={isActive}>
			<AccordionHeader.MDestructive
				id={panelKey}
				label={title}
				expanded={isActive}
				onClick={handleClick}
				className="destructive-header"
			/>
			{isActive && children}
		</article>
	);
}
