import React from "react";

import { keyEvents } from "../../../helpers";
import ListWrapper from "./_atoms.dropdown.list_wrapper";

export default class AContainer extends React.Component {
	constructor(props) {
		super(props);

		this.arrayOfRefs = [];
		this.headerRef = undefined;

		this.state = {
			active: props.active,
			selectedId: props.value,
		};

		this.bindHandles();
	}

	bindHandles() {
		this.setRef = this.setRef.bind(this);
		this.clearRef = this.clearRef.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.handleHeaderClick = this.handleHeaderClick.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleWrapperRef = this.handleWrapperRef.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.handleSelectFirst = this.handleSelectFirst.bind(this);
		this.handleSelectOption = this.handleSelectOption.bind(this);
		this.handleOptionKeyUp = this.handleOptionKeyUp.bind(this);
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	componentDidUpdate() {
		const listNode = this.arrayOfRefs.find((ref) => ref.className.includes("a-dropdown-list-item active"));

		if (listNode) {
			listNode.scrollIntoView(false);
		}
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	static getDerivedStateFromProps(props, state) {
		if (state.selectedId !== props.selectedId && props.selectedId) {
			return { selectedId: props.selectedId };
		}

		return null;
	}

	setRef(element) {
		if (!element) {
			return;
		}

		this.arrayOfRefs.push(element);
	}
	clearRef() {
		this.arrayOfRefs = [];
	}

	handleToggle() {
		if (this.props.disabled) {
			return;
		}

		this.setState((prev) => ({ active: !prev.active }));
	}

	handleHeaderClick() {
		if (this.state.active && this.props.config?.keepOpenOnHeaderClick) {
			return;
		}

		this.handleToggle();
	}

	handleClickOutside(event) {
		if (this.state.active && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ active: false });
		}
	}

	handleWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleKeyUp(event) {
		event.preventDefault();
		event.stopPropagation();

		if (this.props.disabled) {
			return;
		}

		if (
			this.props.excludeKeyCodes &&
			keyEvents.arrayContainsKeyCode({
				event,
				array: this.props.excludeKeyCodes,
			})
		) {
			return;
		}

		if (keyEvents.enterOrSpacePressed(event)) {
			this.handleToggle();
		}

		if (keyEvents.escapePressed(event)) {
			this.setState({ active: false });
		}

		if (keyEvents.upArrowPress(event) || keyEvents.downArrowPress(event)) {
			this.handleSelectFirst(event);
		}
	}

	handleSelectFirst(event) {
		event.preventDefault();
		event.stopPropagation();

		const focusIndex = keyEvents.upArrowPress(event) ? this.getPreviousIndex() : this.getNextIndex();

		this.setState({ active: true }, () => this.arrayOfRefs[focusIndex].focus());
	}

	getPreviousIndex() {
		const nodeIndex = this.arrayOfRefs.findIndex((ref) => ref.className.includes("active")) || 0;

		if (nodeIndex <= 0) {
			return this.arrayOfRefs.length - 1;
		}

		return nodeIndex - 1;
	}
	getNextIndex() {
		const nodeIndex = this.arrayOfRefs.findIndex((ref) => ref.className.includes("active")) || 0;

		if (nodeIndex >= this.arrayOfRefs.length) {
			return 0;
		}

		return nodeIndex + 1;
	}

	handleSelectOption({ item }) {
		if (this.props.disabled) {
			return;
		}

		this.setState((prev) => {
			const id = item?.uuid || item?.id || item?._id;
			const selectedId = this.props.isToggle && prev.selectedId === id ? undefined : id;
			const type = item?.type;

			if (this.props.onChange) {
				this.props.onChange(selectedId, type);
			}

			return {
				active: false,
				selectedId,
			};
		});
	}

	handleOptionKeyUp({ event, index }) {
		event.preventDefault();
		event.stopPropagation();

		if (this.props.disabled) {
			return;
		}

		if (keyEvents.downArrowPress(event) || keyEvents.upArrowPress(event)) {
			const focusIndex = keyEvents.upArrowPress(event) ? index - 1 : index + 1;

			if (focusIndex < 0 || focusIndex > this.arrayOfRefs.length - 1) {
				this.headerRef.focus();
			} else {
				this.arrayOfRefs[focusIndex].focus();
			}
		}

		if (keyEvents.enterOrSpacePressed(event)) {
			this.handleSelectOption(this.props.items[index]);
		}

		if (keyEvents.escapePressed(event)) {
			this.handleToggle();
		}
	}

	generateHeaderClass({ value, config }) {
		if (!config?.headerClass) {
			return "";
		}

		return config.headerClass(value);
	}

	generateContainerClass({ value, config }) {
		if (!config?.containerClass) {
			return "";
		}

		return config.containerClass(value);
	}

	render() {
		let list;

		if (this.state.active) {
			list = (
				<ListWrapper
					items={this.props.items}
					showFallback={this.props.config?.showFallback}
					fallback={this.props.config?.fallback}
					selected={this.state.selectedId}
					onClick={this.handleSelectOption}
					onKeyUp={this.handleOptionKeyUp}
					render={this.props.render}
					config={this.props.config}
					setRef={this.setRef}
					className={this.props.listClassName}
				/>
			);
		} else {
			this.clearRef();
		}

		const selectedValue = this.props.items.find(
			(item) => item && (item.uuid || item.id || item._id) === this.state.selectedId
		);
		const containerClass = this.generateContainerClass({ value: selectedValue, config: this.props.config });

		return (
			<div
				ref={this.handleWrapperRef}
				data-active={this.state.active}
				className={`a-dropdown ${this.state.active ? "active" : ""} ${containerClass}
				${this.props.disabled ? "disabled" : ""} ${this.props.className}`}
			>
				{this.props.header({
					id: `${this.props.id}-header`,
					value: selectedValue ? selectedValue.value : undefined,
					className: this.generateHeaderClass({ value: selectedValue, config: this.props.config }),
					placeholder: this.props.placeholder,
					disabled: this.props.disabled,
					config: this.props.config,
					active: this.state.active,
					onClick: this.handleHeaderClick,
					onKeyUp: this.handleKeyUp,
					setRef: (ref) => (this.headerRef = ref),
				})}
				{list}
			</div>
		);
	}
}
