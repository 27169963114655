import React from "react";
import { Route, Switch } from "react-router-dom";

import { Handler } from "../components/organisms";
import { routesConfig } from "../config";
import { Redirect } from "../pages";

export default () => (
	<Handler.OErrorFallback>
		<Switch>
			<Route path="/auth/success" component={Redirect.AuthSuccessPage} />
		</Switch>
	</Handler.OErrorFallback>
);
