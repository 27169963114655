import "./style.scss";

import { UserOutlined } from "@ant-design/icons";
import { Button, Form, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { trpc } from "@/trpc";
import { FormElement, Toggle } from "@/components/atoms";
import { List } from "@/components/molecules";
import { Section } from "@/components/organisms";
import Collapse, { DestructivePanel, Panel } from "@/shared/components/collapse";
import { actions as dashboardActions } from "@/store/dashboard";
import { actions as profileActions } from "@/store/profile";
import DeleteModal from "@/shared/components/modal/delete_modal";
import ShowFailedBanner from "@/shared/components/banner/failed";
import { storageHelpers } from "@/shared/helpers";

export default function Advanced({ className }) {
	const intl = useIntl();
	const dispatch = useDispatch();
	const routeParameters = useParams<{ team: string }>();
	const [showDelete, setShowDelete] = useState(false);
	const deleteOrganization = trpc.organization.deleteOrganization.useMutation();
	const state = useSelector((state) => ({
		allowViewing: state.dashboard.permissions.userPermissions.superAdmin,
		user: state.profile.account.user,
		isSaving: state.content.form.isSaving,
	}));

	const experimentalList = [];

	useEffect(() => {
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					title: intl.formatMessage({
						id: "settings.advanced.page.title",
						defaultMessage: "Advanced settings",
					}),
					tabTitle: intl.formatMessage({
						id: "settings.advanced.page.tab_title",
						defaultMessage: "Advanced settings",
					}),
					breadcrumbs: [
						{
							to: `/${routeParameters.team}/settings`,
							name: intl.formatMessage({
								id: "settings.advanced.breadcrumb.settings",
								defaultMessage: "Settings",
							}),
						},
						{
							to: `/${routeParameters.team}/settings/advanced`,
							name: intl.formatMessage({
								id: "settings.advanced.breadcrumb.advanced",
								defaultMessage: "Advanced settings",
							}),
						},
					],
				},
			})
		);
	}, []);

	const updateExperimentalFeatures = (value) => {
		dispatch(profileActions.account.updateUser(value));
	};

	const handleDelete = async () => {
		if (!state.allowViewing) return;

		try {
			await deleteOrganization.mutateAsync();

			storageHelpers.unsetOrganization();
			window.location.href = `/profile/${state.user.slug}`;
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "settings.advanced.delete_organization_modal.failed",
					defaultMessage: "Your organization could not be deleted, please contact our support team.",
				}),
			});
		}
	};

	return (
		<section className={`page-settings--content page-settings--advanced ${className}`}>
			<Collapse expandIconPosition="end">
				<Panel
					key="experimentalFeatures"
					title={intl.formatMessage({
						id: "settings.advanced.experimental_features.accordion.title",
						defaultMessage: "Experimental features",
					})}
				>
					<Tooltip
						placement="bottomRight"
						title={intl.formatMessage({
							id: "settings.advanced.experimental_features.tooltip",
							defaultMessage: "These experimental features will only be turned on for you personally.",
						})}
					>
						<div className="page-settings--advanced-icon">
							<UserOutlined />
						</div>
					</Tooltip>
					<Form
						id="mainForm"
						initialValues={{ experimentalFeatures: state.user.experimentalFeatures }}
						onFinish={updateExperimentalFeatures}
						className="page-settings--advanced-form"
						requiredMark="optional"
						autoComplete="off"
						layout="vertical"
					>
						<FormElement.AFieldSideLabel
							label={intl.formatMessage({
								id: "settings.advanced.experimental_features.toggle.label",
								defaultMessage:
									"Try our latest features, these are still under development and may contain errors or change",
							})}
							help={intl.formatMessage({
								id: "settings.advanced.experimental_features.toggle.help",
								defaultMessage:
									"Please note: these features are in test version and may contain minor errors or malfunctions.",
							})}
						>
							<Form.Item name="experimentalFeatures" className="page-settings--advanced-form-item">
								<Toggle.AToggleDefault className="small" />
							</Form.Item>
						</FormElement.AFieldSideLabel>
						<footer className="page-settings--advanced-form-footer">
							<Button htmlType="submit" type="primary" className="t-gap--top" loading={state.isSaving}>
								<FormattedMessage
									id="SETTINGS.ADVANCED.EXPERIMENTAL_FEATURES.SUBMIT"
									defaultMessage="Save"
								/>
							</Button>
						</footer>
					</Form>
				</Panel>
				{state.allowViewing && (
					<DestructivePanel
						key="dangerZone"
						title={intl.formatMessage({
							id: "settings.advanced.danger_zone.accordion_title",
							defaultMessage: "Dangerous options",
						})}
					>
						<Section.ODestructive
							title={intl.formatMessage({
								id: "settings.advanced.danger_zone.title",
								defaultMessage: "Delete your team",
							})}
							description={intl.formatMessage({
								id: "settings.advanced.danger_zone.description",
								defaultMessage:
									"Are you sure you want to delete your team? This action cannot be undone. Your subscription will be canceled immediately and the chatbot will be deactivated.", //eslint-disable-line max-len
							})}
							buttonLabel={intl.formatMessage({
								id: "settings.advanced.danger_zone.button",
								defaultMessage: "Cancel your team",
							})}
							onClick={() => setShowDelete(true)}
						/>
					</DestructivePanel>
				)}
			</Collapse>
			<DeleteModal
				title={intl.formatMessage({
					id: "settings.advanced.delete_organization_modal.title",
					defaultMessage: "Delete organization",
				})}
				description={intl.formatMessage({
					id: "settings.advanced.delete_organization_modal.description",
					defaultMessage:
						"Are you sure you want to delete your organization? This action is irreversible! Your subscription will be canceled immediately and your flows will be deactivated.",
				})}
				deleteLabel={intl.formatMessage({
					id: "settings.advanced.delete_organization_modal.delete_button",
					defaultMessage: "Delete organization",
				})}
				isVisible={showDelete && state.allowViewing}
				isDeleting={deleteOrganization.isLoading}
				onDelete={handleDelete}
				onClose={() => setShowDelete(false)}
			/>
		</section>
	);
}
