import { storageConfig } from "../config";

function getCookie(key) {
	const value = document.cookie.split(";");
	const items = value.reduce((prev, curr) => {
		const values = curr.split("=");

		return { ...prev, [values[0].trim()]: values[1] };
	}, {});

	return items[key];
}

function getCookieDomain() {
	const domain = {
		production: "file.bothive.be",
		staging: "file.staging.bothive.be",
		development: "localhost:4449",
	};

	// eslint-disable-next-line no-undef
	return domain[process.env.NODE_ENV];
}

function setCookie({ key, value, expireTime = storageConfig.expireTime.year }) {
	const now = new Date();
	const expiresAt = new Date(now.setTime(now.getTime() + expireTime));
	const domain = getCookieDomain();

	document.cookie = `${key}=${value};expires=${expiresAt};path=/`;
	document.cookie = `${key}=${value};expires=${expiresAt};Domain=${domain}`; // Set cookie for file gateway
}

function deleteCookie({ key }) {
	const domain = getCookieDomain();

	document.cookie = `${key}=null;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
	document.cookie = `${key}=null;expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=${domain}`; // delete cookie for file gateway
}

export default {
	save: ({ key, value }) => localStorage?.setItem(key, value),
	get: ({ key }) => localStorage?.getItem(key),
	remove: ({ key }) => localStorage?.removeItem(key),

	saveJson: ({ key, value }: { key: string; value: any }) => {
		try {
			localStorage?.setItem(key, JSON.stringify(value));
		} catch (error) {
			console.error(error);
		}
	},
	getJson: <T>({ key }: { key: string }) => {
		try {
			const result = localStorage?.getItem(key);
			if (!result) return null;
			return JSON.parse(result) as T;
		} catch (error) {
			console.error(error);
			return null;
		}
	},

	getTeamUuid: () => localStorage?.getItem(storageConfig.teamUuid),
	saveTeamUuid: ({ uuid }) => {
		localStorage?.setItem(storageConfig.teamUuid, uuid);
		setCookie({
			value: uuid,
			key: storageConfig.organizationId,
			expireTime: storageConfig.expireTime.token,
		});
	},
	removeTeamUuid: () => {
		// pwa
		// localStorage.removeItem(storageConfig.teamUuid);
		localStorage?.setItem("rtu", "true");
		deleteCookie({ key: storageConfig.organizationId });
	},
	getTeamSlug: () => localStorage?.getItem(storageConfig.teamSlug),
	saveTeamSlug: (slug) => localStorage?.setItem(storageConfig.teamSlug, slug),
	removeTeamSlug: () => localStorage?.removeItem(storageConfig.teamSlug),

	getCookieConcent: () => localStorage?.getItem(storageConfig.cookieConcent),
	setCookieConcent: () => localStorage?.setItem(storageConfig.cookieConcent, "YES"),

	getSessionId: () => sessionStorage.getItem(storageConfig.sessionId),
	setSessionId: (sessionId) => sessionStorage.setItem(storageConfig.sessionId, sessionId),

	getUserToken: () => getCookie(storageConfig.token),
	saveUserToken: ({ token }) => {
		setCookie({
			value: token,
			key: storageConfig.token,
			expireTime: storageConfig.expireTime.token,
		});
	},
	removeUserTokens() {
		deleteCookie({ key: storageConfig.token });

		localStorage?.removeItem(storageConfig.userUuid);
		deleteCookie({ key: storageConfig.userId });
	},

	getUserUuid: () => getCookie(storageConfig.userId),
	saveUserUuid: ({ uuid }) => {
		localStorage?.setItem(storageConfig.userUuid, uuid);
		setCookie({
			key: storageConfig.userId,
			value: uuid,
			expireTime: storageConfig.expireTime.token,
		});

		// eslint-disable-next-line no-undef
		if (process.env.NODE_ENV === "development") {
			document.cookie = `userId=${uuid}; Domain=localhost:4449`;
		}
	},

	setLanguage: (language) => localStorage?.setItem(storageConfig.language, language),
	getLanguage: () => localStorage?.getItem(storageConfig.language),

	getCookie,
};
