import "./style.scss";

import FieldLabel from "@/shared/components/field/default";
import { WarningOutlined } from "@ant-design/icons";
import type { IContact } from "@bothive_core/database";
import { Button, Form, Input, Select, Typography } from "antd";
import dayjs from "dayjs";
import omitBy from "lodash.omitby";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import DateFormItem from "@/shared/components/input/DateFormItem";
import { trpc } from "../../../../../../trpc";
import { ExistingContactDisplay } from "../../../../components/existingContactDisplay";

type ContactBaseValuesTypes = {
	values: any;
	conflictContact?: IContact;
	className?: string;
};
export default function ContactBaseValues({ values, conflictContact, className = "" }: ContactBaseValuesTypes) {
	const [form] = Form.useForm();
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const params: { contactId: string } = useParams();

	const updateContactMutation = trpc.contact.update.useMutation();

	const _nn = Form.useWatch("nationalRegistrationNumber", form);
	// Must check if conflictContact exist because nationalRegistrationNumber can be undefined
	const hasDuplicateConflict = conflictContact && conflictContact.nationalRegistrationNumber === _nn;

	const handleSubmit = (values) => {
		const _values = omitBy(values, (value) => value === undefined || value === null || value === "");

		updateContactMutation.mutate(
			{ id: params.contactId, ..._values },
			{
				onSuccess: () => {
					trpcUtils.contact.getContactById.invalidate();

					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "contacts.detail.attributes.save.success",
							defaultMessage: "Contact successfully saved",
						}),
					});
				},
				onError: (error) => {
					if (error?.data?.code === "CONFLICT") {
						ShowFailedBanner({
							title: intl.formatMessage({
								id: "contacts.detail.attributes.save.failed.duplicate.title",
								defaultMessage: "Contact could not be saved",
							}),
							description: intl.formatMessage({
								id: "contacts.detail.attributes.save.failed.duplicate.description",
								defaultMessage: "National registry number needs to be unique",
							}),
						});

						return;
					}

					ShowFailedBanner({
						title: intl.formatMessage({
							id: "contacts.detail.attributes.save.failed",
							defaultMessage: "Contact could not be saved",
						}),
					});
				},
			}
		);
	};

	return (
		<div style={{ display: "flex", flexDirection: "column", width: "100%" }} className={className}>
			{hasDuplicateConflict && conflictContact && (
				<div className="contact_detail-error-duplicate">
					<Typography.Text type="danger">
						<FormattedMessage
							id="contacts.detail.attributes.detail.error.duplicate"
							defaultMessage="This national registration number is already added to the following contact in your organization"
							description="Error message for national registration number that already exists with preview of the contact that has the same national registration number"
						/>
					</Typography.Text>
					<ExistingContactDisplay contact={conflictContact} />
				</div>
			)}
			<Form
				form={form}
				layout="vertical"
				className="frsf-form"
				id="updateContactForm"
				onFinish={handleSubmit}
				style={{ width: "100%" }}
				initialValues={values}
			>
				<div className="base_values">
					<FieldLabel id="firstName" label="CONTACTS.OVERVIEW.ADD.MODAL.FIRSTNAME">
						<Form.Item
							name="firstName"
							style={{ width: "100%", textAlign: "left" }}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "CONTACTS.OVERVIEW.ADD.MODAL.FIRSTNAME.REQUIRED",
									}),
								},
							]}
						>
							<Input />
						</Form.Item>
					</FieldLabel>
					<FieldLabel id="lastName" label="CONTACTS.OVERVIEW.ADD.MODAL.LASTNAME">
						<Form.Item
							name="lastName"
							style={{ width: "100%", textAlign: "left" }}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "CONTACTS.OVERVIEW.ADD.MODAL.LASTNAME.REQUIRED",
									}),
								},
							]}
						>
							<Input />
						</Form.Item>
					</FieldLabel>
					<FieldLabel id="middleName" label="CONTACTS.DETAIL.ATTRIBUTES.MIDDLENAME" optional>
						<Form.Item name="middleName" style={{ width: "100%", textAlign: "left" }}>
							<Input />
						</Form.Item>
					</FieldLabel>
					<FieldLabel id="salutation" label="CONTACTS.DETAIL.ATTRIBUTES.SALUTATION" optional={true}>
						<Form.Item name="salutation" style={{ width: "100%", textAlign: "left" }}>
							<Input
								placeholder={intl.formatMessage({
									id: "CONTACTS.DETAIL.ATTRIBUTES.SALUTATION.PLACEHOLDER",
								})}
							/>
						</Form.Item>
					</FieldLabel>
					<FieldLabel id="sex" label="CONTACTS.DETAIL.ATTRIBUTES.SEX" optional={true}>
						<Form.Item name="sex" style={{ width: "100%", textAlign: "left" }}>
							<Input
								placeholder={intl.formatMessage({ id: "CONTACTS.DETAIL.ATTRIBUTES.SEX.PLACEHOLDER" })}
							/>
						</Form.Item>
					</FieldLabel>
					<FieldLabel id="birthday" label="CONTACTS.DETAIL.ATTRIBUTES.BIRTHDATE" optional={true}>
						<Form.Item style={{ width: "100%", textAlign: "left" }} name="birthday">
							<DateFormItem
								allowClear={false}
								format="DD-MM-YYYY"
								style={{ width: "100%" }}
								disabledDate={(current) => current && current > dayjs().endOf("day")}
							/>
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						id="nationalRegistrationNumber"
						label="CONTACTS.DETAIL.ATTRIBUTES.NATIONALREGISTRATIONNUMBER"
						optional={true}
					>
						<Form.Item name="nationalRegistrationNumber" style={{ width: "100%", textAlign: "left" }}>
							<Input
								placeholder="94.04.22-000.00"
								status={hasDuplicateConflict ? "error" : undefined}
								prefix={hasDuplicateConflict ? <WarningOutlined /> : undefined}
							/>
						</Form.Item>
					</FieldLabel>
					<FieldLabel id="language" label="CONTACTS.DETAIL.ATTRIBUTES.LANGUAGE" optional={true}>
						<Form.Item name="language" style={{ width: "100%", textAlign: "left" }}>
							<Select allowClear={false}>
								<Select.Option value="en-GB" className="filter-dropdown-item">
									<FormattedMessage id="LANGUAGE.ENGLISH" />
								</Select.Option>
								<Select.Option value="nl-BE" className="filter-dropdown-item">
									<FormattedMessage id="LANGUAGE.DUTCH" />
								</Select.Option>
								<Select.Option value="fr-BE" className="filter-dropdown-item">
									<FormattedMessage id="LANGUAGE.FRENCH" />
								</Select.Option>
							</Select>
						</Form.Item>
					</FieldLabel>
				</div>
			</Form>
			<Button
				key="submit"
				type="primary"
				form="updateContactForm"
				htmlType="submit"
				style={{ width: "fit-content", alignSelf: "flex-end" }}
			>
				<FormattedMessage id="BUTTON.SAVE" />
			</Button>
		</div>
	);
}
