import "./style.scss";

import { CloseCircleOutlined } from "@ant-design/icons";
import { Alert, Card, Divider, Statistic, Typography } from "antd";
import { useMemo } from "react";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";

import { importErrorMessages, importWarningMessages } from "./config";
import { ContactRouterOutput } from "../../../../../trpc";

export const emptyAnalytics: ContactRouterOutput["analyzeImport"] = {
	errors: {
		total: 0,
		missingName: false,
		noIdentifier: false,
		invalidEmail: new Set(),
		invalidPhone: new Set(),
		noLinkedContactFound: false,
		missingLinkedContacts: new Set(),
		invalidLinkedContactIdentifier: false,
		invalidNationalRegistrationNumber: new Set(),
	},
	warnings: {
		total: 0,
		duplicateId: new Set(),
		duplicateEmail: new Set(),
		duplicatePhone: new Set(),
		unknownAccountManagers: new Set(),
		duplicateNationalRegistrationNumber: new Set(),
	},
	totalUpdatedContacts: 0,
};

type ImportAnalyticsParams = {
	total: number;
	isLoading?: boolean;
	allowCreate: boolean;
	allowUpdate: boolean;
	analytics?: ContactRouterOutput["analyzeImport"];
};

export default function ImportAnalytics({ total, analytics, allowCreate, allowUpdate }: ImportAnalyticsParams) {
	const intl = useIntl();

	const totalNewContacts = Math.max(
		0,
		total - (analytics?.errors?.total || 0) - (analytics?.totalUpdatedContacts || 0)
	);

	const analyticsMessages = useMemo(() => {
		let errors: MessageDescriptor[] = [];
		let warnings: MessageDescriptor[] = [];

		// Errors
		if (!analytics) return { errors, warnings };
		if (analytics.errors.missingName) errors.push(importErrorMessages.missingName);
		if (analytics.errors.noIdentifier) errors.push(importErrorMessages.noIdentifier);
		if (analytics.errors.noLinkedContactFound) {
			errors.push(importErrorMessages.noLinkedContactFound);
		}
		if (analytics.errors.invalidEmail.size) errors.push(importErrorMessages.invalidEmail);
		if (analytics.errors.invalidPhone.size) errors.push(importErrorMessages.invalidPhone);
		if (analytics.errors.missingLinkedContacts.size) {
			errors.push(importErrorMessages.missingLinkedContacts);
		}
		if (analytics.errors.invalidLinkedContactIdentifier) {
			errors.push(importErrorMessages.invalidLinkedContactIdentifier);
		}
		if (analytics.errors.invalidNationalRegistrationNumber.size) {
			errors.push(importErrorMessages.invalidNationalRegistrationNumber);
		}

		// Warnings
		if (analytics.warnings.duplicateId.size) warnings.push(importWarningMessages.duplicateId);
		if (analytics.warnings.duplicateEmail.size) warnings.push(importWarningMessages.duplicateEmail);
		if (analytics.warnings.duplicatePhone.size) warnings.push(importWarningMessages.duplicatePhone);
		if (analytics.warnings.unknownAccountManagers.size) {
			warnings.push(importWarningMessages.unknownAccountManagers);
		}
		if (analytics.warnings.duplicateNationalRegistrationNumber.size) {
			warnings.push(importWarningMessages.duplicateNationalRegistrationNumber);
		}

		return { errors, warnings };
	}, [analytics]);

	return (
		<div className="contact_import-analytics">
			<section className="contact_import-analytics-main_stats">
				<Card className="contact_import-analytics-card">
					<Statistic
						title={intl.formatMessage({
							id: "contact.import.analytics.stats.new_contacts",
							defaultMessage: "New contacts",
						})}
						value={allowCreate ? totalNewContacts : 0}
					/>
				</Card>
				<Card className="contact_import-analytics-card">
					<Statistic
						title={intl.formatMessage({
							id: "contact.import.analytics.stats.update_contacts",
							defaultMessage: "Update contacts",
						})}
						value={allowUpdate ? analytics?.totalUpdatedContacts : 0}
					/>
				</Card>
				<Card className="contact_import-analytics-card">
					<Statistic
						title={intl.formatMessage({
							id: "contact.import.analytics.stats.errors",
							defaultMessage: "Errors",
						})}
						valueStyle={analytics?.errors?.total ? { color: "#de5050" } : undefined}
						value={analytics?.errors?.total}
					/>
				</Card>
				<Card className="contact_import-analytics-card">
					<Statistic
						title={intl.formatMessage({
							id: "contact.import.analytics.stats.warnings",
							defaultMessage: "Warnings",
						})}
						valueStyle={analytics?.warnings?.total ? { color: "#e47d26" } : undefined}
						value={analytics?.warnings?.total}
					/>
				</Card>
			</section>
			<Typography.Text type="secondary" className="contact_import-analytics-main_stats-description">
				<FormattedMessage
					id="contact.import.analytics.stats.description"
					defaultMessage="These are estimated figures; the actual import may vary based on any warnings or errors encountered."
				/>
			</Typography.Text>
			{Boolean(analyticsMessages.errors.length || analyticsMessages.warnings.length) && (
				<>
					<Divider />
					<article className="contact_import-analytics-details">
						{!!analyticsMessages.errors.length && (
							<Alert
								type="error"
								className="contact_import-analytics-details-banner"
								message={
									<Typography.Title level={5}>
										<FormattedMessage
											id="contact.import.analytics.analytics.error.title"
											defaultMessage="Import validation errors"
										/>
									</Typography.Title>
								}
								description={
									<>
										<Typography.Text type="secondary" strong>
											<FormattedMessage
												id="contact.import.analytics.analytics.error.description"
												defaultMessage="Contacts with errors won't be imported, please address the listed errors."
											/>
										</Typography.Text>
										<ul className="t-gap--top-xs">
											{analyticsMessages.errors.map((item) => (
												<li key={item.id} className="contact_import-analytics-details-item">
													<Typography.Text type="danger">
														<CloseCircleOutlined />
													</Typography.Text>{" "}
													<FormattedMessage {...item} />
												</li>
											))}
										</ul>
									</>
								}
							/>
						)}
						{!!analyticsMessages.warnings.length && (
							<Alert
								type="warning"
								className="contact_import-analytics-details-banner"
								message={
									<Typography.Title level={5}>
										<FormattedMessage
											id="contact.import.analytics.analytics.warning.title"
											defaultMessage="Import validation warnings"
										/>
									</Typography.Title>
								}
								description={
									<>
										<Typography.Text type="secondary" strong>
											<FormattedMessage
												id="contact.import.analytics.analytics.warning.description"
												defaultMessage="Only one contact will be imported; both may be skipped."
											/>
										</Typography.Text>
										<ul className="t-gap--top-xs">
											{analyticsMessages.warnings.map((item) => (
												<li key={item.id} className="contact_import-analytics-details-item">
													<Typography.Text type="warning">
														<CloseCircleOutlined />
													</Typography.Text>{" "}
													<FormattedMessage {...item} />
												</li>
											))}
										</ul>
									</>
								}
							/>
						)}
					</article>
				</>
			)}
		</div>
	);
}
