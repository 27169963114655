import "./style.scss";

import type { IIntegration } from "@bothive_core/database";
import { Alert } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSetRecoilState } from "recoil";

import { Layout } from "@/components/templates";
import { authenticationHelpers } from "@/shared/helpers";
import { trpc } from "@/trpc";
import { PageHeader } from "../components";
import { pageType } from "../config";
import { channelSetupState } from "../state";
import Card from "./card";

interface IConnectAction {
	provider?: IIntegration;
	onClick: (type: pageType) => void;
}
export default function SelectChannels({ provider, onClick }: IConnectAction) {
	const intl = useIntl();
	const [isConnecting, setIsConnecting] = useState(false);
	const [connectingError, setConnectingError] = useState<string | undefined>();
	const setChannelSetup = useSetRecoilState(channelSetupState);

	const { data: providerData, isFetching } = trpc.channel.getChannelProviders.useQuery({});
	const authenticateIntegration = trpc.integrations.authentication.authenticateIntegration.useMutation();
	const isLoading = isFetching || isConnecting;

	const handleSelect = async (provider: IIntegration) => {
		setIsConnecting(true);
		authenticationHelpers.openSignInWindow({
			// GET 0auth2 token from integration
			blank: true,
			name: provider.name,
			url: `${provider.accountLinking.authUrl}&prompt=select_account&state=${window.origin}`,
			callback: async (credentials, error) => {
				if (error) {
					setIsConnecting(false);
					setConnectingError(
						intl.formatMessage({
							id: "channels.connect_channel.add_channel.error.blocked_popup",
							defaultMessage:
								"In order to log in, we need to be able to open a popup. You have to give us permission for this in your browser settings.",
							description:
								"Some browsers block popup's but we need to trigger a popup for the user to connect a channel",
						})
					);
					return;
				}

				// Create a account with these 0auth2 token which can be used when create the channel
				try {
					const { account, uniqueIdentifier } = await authenticateIntegration.mutateAsync({
						scopes: provider.accountLinking.scopes,
						integrationId: provider._id,
						credentials,
					});

					if (!account) throw new Error();

					setChannelSetup({
						name: account.email,
						address: account.email,
						integrationId: provider._id,
						uniqueIdentifier: uniqueIdentifier,
					});
					onClick("setup");
				} catch (error) {
					setConnectingError(
						intl.formatMessage(
							{
								id: "channels.connect_channel.add_channel.error.generic_error",
								defaultMessage:
									"Something went wrong, while connecting with {providerName}. Please try again later.",
								description:
									"Something unexpected happen while connecting channel, user should try again later",
							},
							{ providerName: provider.name }
						)
					);
				}

				setIsConnecting(false);
			},
		});
	};

	useEffect(() => {
		if (!provider) return;
		handleSelect(provider);
	}, [provider]);

	useEffect(() => {
		return () => {
			setIsConnecting(false);
			setConnectingError(undefined);
		};
	});

	return (
		<section className="connect_channels-body connect_channels-add_channel">
			<PageHeader
				title={intl.formatMessage({
					id: "channels.connect_channel.add_channel.title",
					defaultMessage: "Add channels",
				})}
				description={intl.formatMessage({
					id: "channels.connect_channel.add_channel.description",
					defaultMessage: "Select the provider you want to connect to Bothive",
				})}
				className="t-gap--bottom"
			/>
			{connectingError && (
				<Alert
					showIcon
					type="error"
					className="connect_channels-add_channels-banner t-gap--bottom"
					message={connectingError}
				/>
			)}
			<div className="connect_channels-add_channels-content">
				{isLoading && <Layout.TLoadScreen className="connect_channels-add_channels-content-loader" />}
				{!isLoading &&
					providerData?.data?.map((providerData) => (
						<Card
							key={providerData._id}
							logo={providerData.logo}
							name={providerData.name}
							description={providerData.content.description[intl.locale]}
							onClick={() => handleSelect(providerData)}
						/>
					))}
			</div>
		</section>
	);
}
