import React from "react";
import { Steps } from "antd";
import { useIntl } from "react-intl";

export default function FlowSendingSteps({ currentStep }: { currentStep: number }) {
	const intl = useIntl();

	return (
		<div style={{ marginTop: "3em", height: "50vh", minWidth: "250px", marginRight: "2em" }}>
			<Steps
				direction="vertical"
				current={currentStep}
				items={[
					{
						title: intl.formatMessage({
							id: "FLOWS.SEND.STEP.CONTACT_SELECT",
							defaultMessage: "Select contacts",
						}),
					},
					{
						title: intl.formatMessage({
							id: "FLOWS.SEND.STEP.COMPOSE_MESSAGE",
							defaultMessage: "Compose message",
						}),
					},
					{
						title: intl.formatMessage({
							id: "FLOWS.SEND.STEP.OVERVIEW",
							defaultMessage: "Overview",
						}),
					},
				]}
				style={{ height: "100%" }}
			/>
		</div>
	);
}
