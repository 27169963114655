const day = 86400000;
const expireMoments = {
	year: 365 * day,
	day,
};

function getCookieDomain(): string {
	const domain = {
		production: "file.bothive.be",
		staging: "file.staging.bothive.be",
		development: "localhost:4449",
	};

	// eslint-disable-next-line no-undef
	return domain[process.env.NODE_ENV];
}

function getCookie<Type = any>(key: string): Type {
	const value = document.cookie.split(";");
	const items = value.reduce((prev, curr) => {
		const values = curr.split("=");

		return { ...prev, [values[0].trim()]: values[1] };
	}, {});

	return items[key];
}
interface ISetCookie {
	key: string;
	value: boolean | number | string;
	expireTime: number;
}

export function setCookie({ key, value, expireTime = expireMoments.year }: ISetCookie) {
	const now = new Date();
	const expiresAt = new Date(now.setTime(now.getTime() + expireTime));
	const domain = getCookieDomain();

	document.cookie = `${key}=${value};expires=${expiresAt};path=/`;
	document.cookie = `${key}=${value};expires=${expiresAt};Domain=${domain}`; // Set cookie for file gateway
}

export function deleteCookie({ key }: { key: string }) {
	const domain = getCookieDomain();

	document.cookie = `${key}=null;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
	document.cookie = `${key}=null;expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=${domain}`; // delete cookie for file gateway
}

export function getUserToken(): string | undefined {
	return getCookie<string>("token");
}
export function saveUserToken({ token }) {
	setCookie({
		value: token,
		key: "token",
		expireTime: 90 * day, //3months valid
	});
}

export function getOrganizationId() {
	return localStorage.getItem("organizationId");
}
export function saveOrganizationId(id) {
	localStorage.setItem("organizationId", id);
}

export function getOrganizationSlug() {
	return localStorage.getItem("organizationSlug");
}
export function saveOrganizationSlug(slug) {
	localStorage.setItem("organizationSlug", slug);
}

export function unsetOrganization() {
	// pwa
	// localStorage.removeItem("organizationId");
	// localStorage.removeItem("organizationSlug");
}

export function removeUserTokens() {
	deleteCookie({ key: "token" });

	localStorage.removeItem("userUuid");
	deleteCookie({ key: "userId" });
}

export function getState() {
	try {
		return JSON.parse(localStorage.getItem("state") || "") as Record<string, any>;
	} catch (_) {
		return {};
	}
}
export function getInboxState() {
	const state = getState();

	return state.inbox || {};
}
export function updateInboxState(params: Record<string, any>) {
	const state = getState();

	localStorage.setItem(
		"state",
		JSON.stringify({
			...state,
			inbox: { ...(state.inbox || {}), ...params },
		})
	);
}

export function getDashboardState() {
	const state = getState();

	return state.dashboard || {};
}
export function updateDashboardState(params: Record<string, any>) {
	const state = getState();

	localStorage.setItem(
		"state",
		JSON.stringify({
			...state,
			dashboard: { ...(state.dashboard || {}), ...params },
		})
	);
}

export function getContactState() {
	const state = getState();

	return state.contact || {};
}
export function updateContactState(params: Record<string, any>) {
	const state = getState();

	localStorage.setItem(
		"state",
		JSON.stringify({
			...state,
			contact: { ...(state.contact || {}), ...params },
		})
	);
}
