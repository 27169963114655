import {
	CheckCircleFilled,
	DeleteOutlined,
	EditOutlined,
	LinkOutlined,
	LoadingOutlined,
	SendOutlined,
} from "@ant-design/icons";
import { IAttachment, IFile } from "@bothive_core/database";
import { Button, Divider, Progress, Tooltip, notification } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormattedMessage, MessageDescriptor, defineMessage, useIntl } from "react-intl";
import { useRecoilState } from "recoil";

import { fileHelper } from "@/helpers";
import { trpc } from "@/trpc";
import { fileUploadingState, uploadingProgressState } from "../state";
import { IOnFileUpload } from "../types";
import AttachmentsList from "./AttachmentsList";
import { isTempId } from "../utils";

export type AttachmentFile = IAttachment & { file?: Omit<IFile, "organizationId" | "locked"> };

interface IComposeFooter {
	isSending: boolean;
	isCreating: boolean;
	isUpdating: boolean;
	isDeleting: boolean;
	messageId: string;
	sendFrom?: string;
	attachments: AttachmentFile[];
	onDiscardDraft: () => void;
	onInsertSignature: () => void;
	onFileUpload: (params: IOnFileUpload) => void;
	setAttachments: Dispatch<SetStateAction<AttachmentFile[]>>;
}
export default function Footer({
	messageId,
	sendFrom,
	attachments,
	isSending,
	isCreating,
	isUpdating,
	isDeleting,
	onFileUpload,
	onDiscardDraft,
	onInsertSignature,
	setAttachments,
}: IComposeFooter) {
	const intl = useIntl();
	const [isTouched, setIsTouched] = useState(false);
	const [isUploading, setIsUploading] = useRecoilState(fileUploadingState);
	const [uploadProgress, setUploadProgress] = useRecoilState(uploadingProgressState);
	const isMutating = (isCreating || isUpdating) && !isSending;

	const updateDraftMutation = trpc.inbox.message.updateDraftEmail.useMutation();

	const handleAddAttachment = () => {
		try {
			fileHelper.pickFile({
				config: { multiple: true },
				callback: handleFileUpload,
			});
		} catch (error: any) {
			handleFailedUpload({ message: error.message || "BANNER.FAILED.UPLOAD_ATTACHMENT" });
		}
	};

	const handleFileUpload = async (files: FileList) => {
		try {
			setIsUploading(true);

			await onFileUpload({ files: [...files], onUploadProgress: (progress) => setUploadProgress(progress) });
		} catch (error: any) {
			console.error(error);
			handleFailedUpload({
				message: defineMessage({
					id: "inbox.compose.attachments.upload_error.general_error",
					defaultMessage: "Failed to upload attachment. Please try again later",
					description: "Server responded with a generic error",
				}),
			});
		} finally {
			setUploadProgress(0);
			setIsUploading(false);
		}
	};

	function handleFailedUpload({
		message,
		values,
	}: {
		message: MessageDescriptor;
		values?: { [key: string]: string | number };
	}) {
		notification.error({
			message: intl.formatMessage(message, values),
			placement: "bottomRight",
		});
		setUploadProgress(0);
		setIsUploading(false);
	}

	useEffect(() => {
		if (!isMutating) return;
		setIsTouched(true);
	}, [isMutating]);

	return (
		<footer className="inbox-composer-mail_footer">
			<AttachmentsList
				attachments={attachments}
				onChange={(updatedAttachments) => {
					if (!messageId || typeof messageId === "number" || !sendFrom) return;

					updateDraftMutation.mutate({
						messageId: messageId,
						attachments: updatedAttachments,
						sendFrom,
					});

					setAttachments(updatedAttachments);
				}}
			/>
			{isUploading && (
				<div className="inbox-composer-footer-attachments-progress">
					<Progress
						data-value={intl.formatMessage({
							id: "inbox.compose.footer.uploading_attachments",
							defaultMessage: "Uploading attachment",
						})}
						percent={uploadProgress}
						showInfo={false}
					/>
				</div>
			)}
			<div className="inbox-composer-mail_footer-controls">
				<div className="insert_buttons">
					<Tooltip
						title={
							!messageId
								? intl.formatMessage({
										id: "inbox.compose.footer.add_attachments.disabled.tooltip",
										defaultMessage: "We are setting up your draft ...",
										description:
											"Explanation about attachment icon when user tries to upload attachments while draft is not created yet.",
								  })
								: intl.formatMessage({
										id: "inbox.compose.footer.add_attachments.tooltip",
										defaultMessage: "Add attachment",
										description: "Explanation about attachment icon when user hovers over icon",
								  })
						}
					>
						<Button
							disabled={isTempId(messageId)}
							icon={<LinkOutlined />}
							type="text"
							onClick={handleAddAttachment}
						/>
					</Tooltip>
					<Tooltip
						title={intl.formatMessage({
							id: "inbox.compose.footer.add_signature.label",
							defaultMessage: "Add signature",
						})}
					>
						<Button type="text" icon={<EditOutlined />} onClick={onInsertSignature} />
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip
						title={intl.formatMessage({
							id: "inbox.compose.footer.button.discard_draft.label",
							defaultMessage: "Discard draft",
						})}
					>
						<Button
							type="text"
							htmlType="button"
							loading={isDeleting}
							disabled={isSending || isMutating}
							onClick={onDiscardDraft}
							icon={<DeleteOutlined />}
						/>
					</Tooltip>
				</div>
				<div className="action_buttons">
					{/* @ts-ignore */}
					<AnimatePresence exitBeforeEnter>
						{isMutating && (
							<motion.p
								key="updating"
								initial={{ y: 10, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								exit={{ y: -10, opacity: 0 }}
								transition={{ duration: 0.1 }}
								style={{ display: "flex", alignItems: "center" }}
							>
								<LoadingOutlined className="t-gap--right-xs" />
								<FormattedMessage
									id="inbox.compose.footer.button.status.saved_updates"
									defaultMessage="Updating draft"
								/>
							</motion.p>
						)}
						{messageId && !isMutating && isTouched && (
							<motion.p
								key="saved"
								initial={{ y: 10, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								exit={{ y: -10, opacity: 0 }}
								transition={{ duration: 0.1 }}
								style={{ display: "flex", alignItems: "center" }}
							>
								<CheckCircleFilled style={{ color: "var(--success)" }} className="t-gap--right-xs" />
								<FormattedMessage
									id="inbox.compose.footer.button.status.update_succeeded"
									defaultMessage="Saved updates"
								/>
							</motion.p>
						)}
					</AnimatePresence>
					<Button
						icon={<SendOutlined />}
						disabled={isSending || isDeleting || isUploading || !messageId || typeof messageId === "number"}
						loading={isSending}
						type="primary"
						htmlType="submit"
					>
						{" "}
						<FormattedMessage id="inbox.compose.footer.button.send.label" defaultMessage="Send" />
					</Button>
				</div>
			</div>
		</footer>
	);
}
