import "../style.scss";

import { Button, List, Avatar, Tooltip, Skeleton } from "antd";
import { FormattedMessage } from "react-intl";
import { trpc } from "@/trpc";
import { IMember } from "@bothive_core/database";

import { generatorHelpers } from "../../../../shared/helpers";

type MembersOverviewType = {
	onClick: () => void;
	className?: string;
};

export default function MembersOverview({ onClick, className }: MembersOverviewType) {
	const { isLoading, data } = trpc.member.getAll.useQuery({ limit: 9 });

	const dataSource = isLoading
		? generatorHelpers.skeletonList({ max: 6, min: 2 })
		: (data?.data.sort((a, b) => a?.name?.localeCompare(b?.name)) as IMember[]); //alphabetically sort members

	return (
		<div className={`settings-members-card ${className}`}>
			<List
				grid={{
					//@ts-ignore "Type 'number[]' is not assignable to type 'number'" - AntD typing issue
					gutter: [8, 8],
					xs: 1,
					sm: 2,
					md: 5,
					lg: 5,
					xl: 5,
					xxl: 5,
				}}
				className="settings-members-card--image-cluster"
				dataSource={dataSource}
				renderItem={(item: { key: string } | IMember) => {
					if (isLoading) {
						const renderItem = item as { key: string };

						return (
							<Skeleton.Avatar
								key={renderItem.key}
								shape="square"
								size={60}
								active
								className="settings-members-card--image-size"
							/>
						);
					}

					const member = item as IMember;

					return (
						<Tooltip title={member.name} placement="bottom">
							<Avatar src={member?.avatar} className="settings-members-card--image-size" shape="square" />
						</Tooltip>
					);
				}}
			/>
			<Button type="default" size="large" onClick={onClick}>
				<FormattedMessage id={"settings_overview.members.footer.button"} defaultMessage="Manage members" />
			</Button>
		</div>
	);
}
