import React from "react";
import { FormattedMessage } from "react-intl";

import { Component } from "../../atoms";

export default function MProcessing({ messages, className }) {
	return (
		<section // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
			role="dialog"
			className={`m-banner m-banner--processing ${className}`}
		>
			<Component.AIcon type="loader" className="small white" />
			<div className="banner-body">
				{messages.map((message) => (
					<p key={message.id}>
						<FormattedMessage id={message.message} values={message.values} />
					</p>
				))}
			</div>
		</section>
	);
}

MProcessing.defaultProps = {
	messages: [],
	className: "",
};
