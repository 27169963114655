import { Space } from "antd";
import React from "react";

import CopyStatusTypes from "./copy_status_types";
import Header from "./header";
import StatusTypesModal from "./status_modal";
import style from "./style.module.scss";
import StatusTypesTable from "./table";

export default function StatusTypesOverview() {
	return (
		<main className={style.status_type_overview}>
			<Space direction="vertical" size="large" className="frs-overview-space">
				<Header />
				<CopyStatusTypes />
				<StatusTypesTable />
			</Space>
			<StatusTypesModal.CreateStatusTypes />
			<StatusTypesModal.EditStatusTypes />
		</main>
	);
}
