import axios from "axios";

import { apiConfig } from "@/flows/common/config";

export const getReportById = async ({ flowId, reportId }) => {
	const { data } = await axios.get(apiConfig.report.getById.replace(":id", flowId).replace(":reportId", reportId));

	return data;
};

export const updateReport = async ({ flowId, reportId, payload }) => {
	const { data } = await axios.put(
		apiConfig.report.update.replace(":id", flowId).replace(":reportId", reportId),
		payload
	);

	return data;
};
