import React from "react";
import { connect } from "react-redux";

import { actions as dashboardActions } from "../../../store/dashboard";
import { Fallback } from "../../atoms";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
		this.previousState = false;
	}

	componentDidCatch(error, info) {
		console.error("error", error);
		console.error("info", info);

		this.setState({ hasError: true });
		this.props.throwNewError({ error, info });
	}

	render() {
		if (this.previousState) {
			this.previousState = false;
			this.setState({ hasError: false });
			this.props.errorResolved();
		}

		if (this.state.hasError) {
			this.previousState = true;
			return <Fallback.ANotFound label={this.props.title} />;
		}

		return this.props.children;
	}
}

ErrorBoundary.defaultProps = {
	title: "FALLBACK.ERROR_HANDLER.TITLE",
};

export default connect(undefined, {
	errorResolved: dashboardActions.errorHandling.errorResolved,
	throwNewError: dashboardActions.errorHandling.throwNewError,
})(ErrorBoundary);
