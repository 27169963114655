import React from "react";
import { FormattedMessage } from "react-intl";

export default function ADashboardCard({
	title = "",
	description = "",
	formattedMessageValues = undefined,
	descriptionValues = undefined,
	className = "",
	children,
	style,
	childStyle = {},
}) {
	return (
		<div className={`a-card ${className}`} style={style}>
			{title && (
				<h2 className="a-card--dashboard-title">
					<FormattedMessage id={title} values={formattedMessageValues} />
				</h2>
			)}
			{description && (
				<p className="card-description t-gap--bottom">
					<FormattedMessage id={description} values={descriptionValues} />
				</p>
			)}
			<div className="card-body" style={childStyle}>
				{children}
			</div>
		</div>
	);
}
