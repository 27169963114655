import React from "react";
import { FormattedMessage } from "react-intl";

import { Component } from "../../atoms";

export default function MError({ title, description, className }) {
	return (
		<section className={`m-fallback m-fallback--error${className}`}>
			<div className="fallback--content">
				{title && (
					<h1 className="title fallback--title">
						<FormattedMessage id={title} />
					</h1>
				)}
				<Component.AIcon type="error" className="fill fallback--image" />
				{description && (
					<p>
						<FormattedMessage id={description} />
					</p>
				)}
			</div>
		</section>
	);
}

MError.defaultProps = {
	title: undefined,
	description: undefined,
	className: "",
};
