import { atom } from "recoil";

export type ImportConfig = {
	q?: string;
	crmType?: "0" | "2" | "3" | "4";
	type?: "0" | "1" | "2";
	allowCreate: boolean;
	allowUpdate: boolean;
};
export const importConfigState = atom<ImportConfig>({
	key: "ciApImportConfigState",
	default: { type: "2", allowCreate: true, allowUpdate: true },
});
