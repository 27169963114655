import actionTypes from "./account.actionTypes";

const initialState = {
	user: undefined,
	isFetching: false,
	fetchingFailed: false,
	fetchingSucceeded: false,

	isValidating: false,
	validationFailed: false,
	validationSucceeded: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.ACCOUNT_IS_FETCHING:
			return {
				...state,
				isFetching: true,
				fetchingFailed: false,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCHING_ACCOUNT_FAILED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: true,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCHING_ACCOUNT_SUCCEEDED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: false,
				fetchingSucceeded: true,
			};

		case actionTypes.ACCOUNT_IS_VALIDATING:
			return {
				...state,
				isValidating: true,
				validationFailed: false,
				validationSucceeded: false,
			};
		case actionTypes.VALIDATING_ACCOUNT_FAILED:
			return {
				...state,
				isValidating: false,
				validationFailed: true,
				validationSucceeded: false,
			};
		case actionTypes.VALIDATING_ACCOUNT_SUCCEEDED:
			return {
				...state,
				isValidating: false,
				validationFailed: false,
				validationSucceeded: true,
			};
		case actionTypes.RESET_FETCH:
			return {
				...state,
				isFetching: false,
				fetchingFailed: false,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCH_USER_BY_ID:
			return {
				...state,
				user: action.data,
			};
		case actionTypes.VERIFY_ACCOUNT:
			return {
				...state,
				user: {
					...state.user,
					...action.data,
				},
			};
		case actionTypes.UPDATE_USER:
			return {
				...state,
				user: {
					...state.user,
					...action.data,
				},
			};
		case actionTypes.UPDATE_USER_LOCALLY:
			return {
				...state,
				user: {
					...state.user,
					...action.data,
				},
			};
		case actionTypes.ADD_GROUP_TO_USER:
			return {
				...state,
				user: {
					...state.user,
					...action.data,
					teams: [...(state?.user?.teams || []).filter((item) => item._id !== action.data._id), action.data],
				},
			};
		case actionTypes.LOGIN_USER:
		case actionTypes.SIGN_USER:
		case actionTypes.VALIDATE_TOKEN:
			return {
				...state,
				user: action.data,
			};
		case actionTypes.LOGOUT_USER:
		case actionTypes.DELETE_USER:
		case actionTypes.SIGN_OUT_USER:
			return {
				...state,
				user: undefined,
			};
		case actionTypes.CHANGE_AVAILABILITY:
			return {
				...state,
				user: {
					...state.user,
					isAvailable: action.data,
				},
			};
		case actionTypes.REQUEST_NEW_VERIFICATION_CODE:
		case actionTypes.REQUEST_RESET_PASSWORD:
		default:
			return state;
	}
}
