export default {
	FLOW_STATUS_TYPE_IS_FETCHING: "FLOW_STATUS_TYPE_IS_FETCHING",
	FETCH_FLOW_STATUS_TYPE_FAILED: "FETCH_FLOW_STATUS_TYPE_FAILED",
	FETCH_FLOW_STATUS_TYPE_SUCCEEDED: "FETCH_FLOW_STATUS_TYPE_SUCCEEDED",

	FLOW_STATUS_TYPES_ALREADY_FETCHED: "FLOW_STATUS_TYPES_ALREADY_FETCHED",
	FETCH_FLOW_STATUS_TYPES: "FETCH_FLOW_STATUS_TYPES",

	CREATE_STATUS_TYPE: "CREATE_STATUS_TYPE",

	UPDATE_STATUS_TYPE: "UPDATE_STATUS_TYPE",
	UPDATE_STATUS_TYPE_LOCALLY: "UPDATE_STATUS_TYPE_LOCALLY",

	COPY_STATUS_TYPE: "COPY_STATUS_TYPE",

	DELETE_STATUS_TYPE: "DELETE_STATUS_TYPE",

	TEAM_CHANGED: "TEAM_CHANGED", //listen to actions from the team structure
	FETCH_TEAM: "FETCH_TEAM",
};
