function getFittedUnit(value) {
	if (Math.pow(1024, 5) < value) {
		return "petabyte";
	}

	if (Math.pow(1024, 4) < value) {
		return "terabyte";
	}

	if (Math.pow(1024, 3) < value) {
		return "gigabyte";
	}

	if (Math.pow(1024, 2) < value) {
		return "megabyte";
	}

	if (1024 < value) {
		return "kilobyte";
	}

	return "byte";
}

export default {
	unit: {
		style: "unit",
		unit: "byte",
		unitDisplay: "narrow",
	},
	uniteRelative: (value) => ({
		style: "unit",
		unit: getFittedUnit(value),
		unitDisplay: "narrow",
	}),
	priceFormat: {
		style: "currency",
		currency: "EUR",
		currencyDisplay: "symbol",
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	},
	dateFormat: {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	},
	weekDays: [
		"GRAPH.HOUR_SHORT_HAND.MO",
		"GRAPH.HOUR_SHORT_HAND.TU",
		"GRAPH.HOUR_SHORT_HAND.WE",
		"GRAPH.HOUR_SHORT_HAND.TH",
		"GRAPH.HOUR_SHORT_HAND.FR",
		"GRAPH.HOUR_SHORT_HAND.SA",
		"GRAPH.HOUR_SHORT_HAND.SU",
	],
};
