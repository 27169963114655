import { MessageDescriptor, defineMessage } from "react-intl";
import { InboxFolder } from "../hooks/useInboxNavigation.hooks";

export type StatusFilterOption = "open" | "closed" | "trash" | "spam" | "all"; //"snoozed" |

const config: Record<
	StatusFilterOption,
	{ label: MessageDescriptor; urlParam: string; visible: (InboxFolder | "custom_folder")[] }
> = {
	all: {
		label: defineMessage({ id: "STATUS.INBOX.ALL.LABEL", defaultMessage: "ALL" }),
		urlParam: "open",
		visible: ["sent", "drafts", "assigned_by_me"],
	},
	open: {
		label: defineMessage({ id: "STATUS.INBOX.OPEN.LABEL", defaultMessage: "OPEN" }),
		urlParam: "open",
		visible: [
			"personal_conversations",
			"assigned_to_me",
			"mentioned",
			"shared_with_me",
			"all_conversations",
			"outbox",
			"custom_folder",
		],
	},
	closed: {
		label: defineMessage({ id: "STATUS.INBOX.CLOSED.LABEL", defaultMessage: "CLOSED" }),
		urlParam: "closed",
		visible: [
			"personal_conversations",
			"assigned_to_me",
			"mentioned",
			"shared_with_me",
			"all_conversations",
			"custom_folder",
		],
	},
	// snoozed: {
	// 	label: defineMessage({ id: "STATUS.INBOX.SNOOZED.LABEL", defaultMessage: "SNOOZED" }),
	// 	urlParam: "snoozed",
	// 	visible: [
	// 		"personal_conversations",
	// 		"assigned_to_me",
	// 		"mentioned",
	// 		"shared_with_me",
	// 		"all_conversations",
	// 		"custom_folder",
	// 	],
	// },
	trash: {
		label: defineMessage({ id: "STATUS.INBOX.TRASH.LABEL", defaultMessage: "TRASH" }),
		urlParam: "trash",
		visible: [
			"personal_conversations",
			"assigned_to_me",
			"mentioned",
			"shared_with_me",
			"all_conversations",
			"sent",
			"outbox",
			"custom_folder",
		],
	},
	spam: {
		label: defineMessage({ id: "STATUS.INBOX.SPAM.LABEL", defaultMessage: "SPAM" }),
		urlParam: "spam",
		visible: [
			"personal_conversations",
			"assigned_to_me",
			"mentioned",
			"shared_with_me",
			"all_conversations",
			"custom_folder",
		],
	},
};

export default config;
