import "./style.scss";

import { DeleteOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Select, Tooltip } from "antd";
import { useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import type { IContact, IContactSyncRule } from "@bothive_core/database";
import dayjs from "dayjs";

import { TCondition } from "./type";
import { conditionValueSelect, contactFieldsByType, eidDateFilters } from "./config";

interface IRuleRow {
	condition: TCondition;
	fieldOptions?: { label: string; value: string }[];
	onChange: (value: TCondition) => void;
	onDelete: () => void;
}

function RuleRow({ condition, fieldOptions, onChange, onDelete }: IRuleRow) {
	const intl = useIntl();
	const tagText = useMemo(() => {
		const tag = {
			if: "CONTACTS.SETTINGS.ADD.RULE.MODAL.IF",
			and: "CONTACTS.SETTINGS.ADD.RULE.MODAL.AND",
			or: "CONTACTS.SETTINGS.ADD.RULE.MODAL.OR",
		};

		return tag[condition.type] ? intl.formatMessage({ id: tag[condition.type] }) : "";
	}, [condition]);

	useEffect(() => {
		onChange({
			...condition,
			// @ts-ignore
			value: conditionValueSelect[condition.value || ""]?.supportedFields?.includes(condition.field)
				? condition.value
				: undefined,
			...(condition.input === "CURRENT_DATE" && eidDateFilters.includes(condition.field) && { input: undefined }),
			...(eidDateFilters.includes(condition.field) && { input: "CURRENT_DATE" }),
		});
	}, [condition.field]);

	return (
		<div className="condition_row">
			<p className="condition_row-tag">{tagText}</p>
			<Select
				value={condition.field}
				allowClear={false}
				options={fieldOptions?.map((item) => ({
					label: intl.formatMessage({ id: item.label }),
					value: item.value,
				}))}
				placeholder={intl.formatMessage({
					id: "CONTACTS.SETTINGS.ADD.RULE.MODAL.INPUT.FIELD.PLACEHOLDER`",
					defaultMessage: "Select a contact field",
				})}
				popupMatchSelectWidth={false}
				className="condition_row-select loading_select"
				onChange={(field) => onChange({ ...condition, field })}
			/>
			<p style={{ minWidth: "fit-content", fontWeight: 600 }}>
				<FormattedMessage id="CONTACTS.SETTINGS.ADD.RULE.MODAL.IS" />
			</p>
			<Select
				allowClear={false}
				value={condition.value}
				popupMatchSelectWidth={false}
				placeholder={intl.formatMessage({
					id: "CONTACTS.SETTINGS.ADD.RULE.MODAL.INPUT.COMPARISON.PLACEHOLDER`",
					defaultMessage: "Select a comparison",
				})}
				className="condition_row-select loading_select"
				onChange={(value) => onChange({ ...condition, value })}
			>
				{Object.entries(conditionValueSelect).map(([key, value]) => {
					if (condition.field && !value.supportedFields.includes(condition.field)) return null;

					return (
						<Select.Option key={key} value={key}>
							<FormattedMessage id={value.intl.id} defaultMessage={value.intl.defaultMessage} />
						</Select.Option>
					);
				})}
			</Select>
			{condition.value !== "IS_DEFINED" && (
				<VariableConditionInput
					field={condition.field as keyof IContact}
					input={condition.input}
					onChange={(value) => onChange({ ...condition, input: value || undefined })}
				/>
			)}
			<Button type="text" icon={<DeleteOutlined />} className="condition_row-delete" onClick={onDelete} />
		</div>
	);
}

export default RuleRow;

interface IVariableConditionInputProps {
	field: keyof IContact | string;
	input?: IContactSyncRule["conditions"][number]["input"];
	onChange: (value: string | number | Date | null) => void;
}

function VariableConditionInput({ field, input, onChange }: IVariableConditionInputProps) {
	const intl = useIntl();

	if (eidDateFilters.includes(field)) {
		return (
			<Tooltip
				title={intl.formatMessage({
					id: "contacts.settings.add.rule.modal.eid_expiration_date.fixed_value.tooltip",
					defaultMessage:
						"It is important to note that it is only possible to work with a `less than or equal` rule when determining the eid expiration date.",
				})}
			>
				<p style={{ fontWeight: 600 }}>
					<FormattedMessage
						id="contacts.settings.add.rule.modal.eid_expiration_date.fixed_value"
						defaultMessage="day of import"
					/>
				</p>
			</Tooltip>
		);
	}

	// FIELD HAS VALUE OF TYPE DATE
	if (contactFieldsByType?.date?.includes(field as any)) {
		return (
			<DatePicker
				style={{ width: "100%" }}
				value={(input as Date | undefined) ? dayjs(input) : dayjs(new Date())}
				onChange={(value) => onChange(value ? value.toDate() : null)}
			/>
		);
	}

	// ! No numbers to support yet

	// FIELD HAS VALUE OF TYPE STRING
	return <Input value={input as string | undefined} onChange={(event) => onChange(event.target.value)} />;
}
