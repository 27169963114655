import { stringHelpers } from "@bothive/helpers";

export default {
	getRandomValue: (items) => {
		if (!items || !items.length) {
			return "";
		}

		if (items.length === 1) {
			return items[0];
		}

		return items[Math.floor(Math.random() * items.length)];
	},
	shuffle: (items) =>
		items
			.map((a) => ({ sort: Math.random(), value: a }))
			.sort((a, b) => a.sort - b.sort)
			.map((a) => a.value),
	sortByBoolean: <T extends { [key: string]: any }>({
		item,
		key,
		direction = 1,
	}: {
		item: T[];
		key: string;
		direction: number;
	}) => item.sort((a, b) => (a[key] === b[key] ? 0 : a[key] ? direction : -direction)),
	findLastIndex: ({ items, callback }) => items.reduce((prev, curr, index) => (callback(curr) ? index : prev), 0),
	sortAlphabetical: ({ items, key }) => items.sort((a, b) => a[key].localeCompare(b[key])),
};

export function filterDuplicatesByField<T extends Record<K, string | number>, K extends keyof T>({
	array,
	field,
	includeIfValueIsEmpty = true,
}: {
	array: (T | undefined)[];
	field: K;
	includeIfValueIsEmpty?: boolean;
}): T[] {
	const uniqueArray: T[] = [];
	const seenFieldValues = new Set<string | number>();
	const cleanArray = array.filter((item) => item !== null && typeof item !== "undefined") as T[];

	for (const item of cleanArray) {
		const value = item[field];

		if (stringHelpers.isEmptyString(`${value}`) || typeof value === "undefined") {
			if (includeIfValueIsEmpty) {
				uniqueArray.push(item);
			}

			continue;
		}

		if (!seenFieldValues.has(value)) {
			uniqueArray.push(item);
			seenFieldValues.add(value);
		}
	}

	return uniqueArray;
}
