import { combineReducers } from "redux";

import { reducers as contentReducer } from "../content";
import { reducers as dashboardReducer } from "../dashboard";
import { reducers as profileReducer } from "../profile";

export default combineReducers({
	content: contentReducer,
	dashboard: dashboardReducer,
	profile: profileReducer,
});
