import style from "../../../../flows/flow_detail/template/style.module.scss";
import Aside from "../../../../shared/components/aside";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageConfig, routesConfig } from "../../../../config";
import { useDispatch, useSelector } from "react-redux";
import { trpc } from "../../../../trpc";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import { useCollapsedAside } from "../../../../shared/hooks";
import { actions as dashboardActions } from "../../../../store/dashboard";
import { MessageOutlined, SettingOutlined, SolutionOutlined, UserOutlined } from "@ant-design/icons";
import type { IOrganization } from "@bothive_core/database";

export default function ContactDetailTemplate({ children }: { children: React.ReactNode }) {
	useCollapsedAside();

	const params = useParams<{ contactId: string; team: string }>();
	const dispatch = useDispatch();
	const history = useHistory();
	const intl = useIntl();
	const organization: IOrganization = useSelector((state) => state.content.team.team);

	const { data: contact, isLoading } = trpc.contact.getContactById.useQuery({ id: params.contactId });

	useEffect(() => {
		if (!isLoading && !contact) {
			history.push(routesConfig.dashboard.contact.overview.replace(":team", params?.team));
			return;
		}

		const breadcrumbs = pageConfig.contactDetail.breadCrumbs({
			contact,
			// @ts-ignore
			team: params?.team,
		});

		dispatch(dashboardActions.ui.changePageTitle({ config: { ...pageConfig.contactDetail, breadcrumbs }, intl }));
	}, [contact]);

	return (
		<main className={style.flow_detail_template}>
			<Aside
				items={[
					{
						id: "back",
						to: `/${params.team}/contact${contact?.archivedAt ? "/archive" : ""}`,
						icon: "arrow--left",
						label: "CONTACT_DETAILS.ASIDE.BACK_TO_OVERVIEW.LABEL",
					},
					{
						id: "overview",
						to: routesConfig.dashboard.contact.detail.root
							.replace(":team", params.team)
							.replace(":contactId", params.contactId),
						icon: <UserOutlined style={{ fontSize: "18px" }} />,
						label: "CONTACT_DETAILS.ASIDE.OVERVIEW.LABEL",
					},
					{
						id: "details",
						to: routesConfig.dashboard.contact.detail.attributes
							.replace(":team", params.team)
							.replace(":contactId", params.contactId),
						icon: <SolutionOutlined style={{ fontSize: "18px" }} />,
						label: "CONTACT_DETAILS.ASIDE.DETAILS.LABEL",
					},
					organization.permissions.readContactConversations && {
						id: "conversations",
						to: routesConfig.dashboard.contact.detail.conversations
							.replace(":team", params.team)
							.replace(":contactId", params.contactId),
						icon: <MessageOutlined style={{ fontSize: "18px" }} />,
						label: "CONTACT_DETAILS.ASIDE.CONVERSATIONS.LABEL",
					},
					{
						id: "settings",
						to: routesConfig.dashboard.contact.detail.settings
							.replace(":team", params.team)
							.replace(":contactId", params.contactId),
						icon: <SettingOutlined style={{ fontSize: "18px" }} />,
						label: "CONTACT_DETAILS.ASIDE.SETTINGS.LABEL",
					},
				].filter(Boolean)}
			/>
			<div className={style.flow_detail_content}>{children}</div>
		</main>
	);
}
