import "./style.scss";

import { Form, Select } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Dropdown, FormElement, Input, Toggle } from "@/components/atoms";
import { Input as MInput } from "@/components/molecules";
import { templateConfig } from "@/config";

function verticalLabels({ fieldConfig, language, disabled, items }) {
	let field;

	switch (fieldConfig.template) {
		case templateConfig.templates.dropdown:
			field = !fieldConfig.isHidden && (
				<Dropdown.ADefault
					{...fieldConfig}
					disabled={disabled}
					className={fieldConfig.className}
					placeholder={fieldConfig.placeholder?.[language]}
				/>
			);
			break;
		case templateConfig.templates.tagInput:
			field = !fieldConfig.isHidden && (
				<Select
					mode="tags"
					{...fieldConfig}
					disabled={disabled}
					dropdownRender={null}
					notFoundContent={null}
					className={fieldConfig.className}
					placeholder={fieldConfig.placeholder?.[language]}
				/>
			);
			break;
		case templateConfig.templates.tagDropdown:
			field = !fieldConfig.isHidden && (
				<MInput.MTagDropdownInput
					{...fieldConfig}
					items={items[fieldConfig.key]}
					className={fieldConfig.className}
					placeholder={fieldConfig.placeholder?.[language]}
				/>
			);
			break;
		case templateConfig.templates.textArea:
			field = !fieldConfig.isHidden && (
				<Input.ATextField
					{...fieldConfig}
					disabled={disabled}
					placeholder={fieldConfig.placeholder?.[language]}
					className={`a-input--field-bordered ${fieldConfig.className}`}
				/>
			);
			break;
		case templateConfig.templates.textField:
			field = !fieldConfig.isHidden && (
				<Input.ATextArea
					{...fieldConfig}
					disabled={disabled}
					placeholder={fieldConfig.placeholder?.[language]}
					className={`a-input--field-bordered ${fieldConfig.className}`}
				/>
			);
			break;
		default:
			return;
	}

	return (
		<Form.Item
			name={fieldConfig.id}
			className="a-form-element--ant-d"
			label={<FormattedMessage id={fieldConfig.label?.[language]} />}
			help={<FormattedMessage id={fieldConfig.help?.[language]} />}
		>
			{field}
		</Form.Item>
	);
}

function horizontalLabels({ fieldConfig, disabled, language }) {
	let field;

	switch (fieldConfig.template) {
		case templateConfig.templates.checkBox:
			field = !fieldConfig.isHidden && (
				<Toggle.AToggleDefault disabled={disabled} className={fieldConfig.className} />
			);
			break;
		default:
			return;
	}

	return (
		!fieldConfig.isHidden && (
			<FormElement.AFieldSideLabel
				label={fieldConfig.label?.[language]}
				help={fieldConfig.help?.[language]}
				className="a-form-element--horizontal-ant-override flow-form_generator-vertical_label"
			>
				<Form.Item name={fieldConfig.id}>{field}</Form.Item>
			</FormElement.AFieldSideLabel>
		)
	);
}

export default function FlowConfigurationFormGenerator({ fields = [], language, disabled, items }) {
	return fields.map((fieldConfig) => {
		const verticalFieldLabels = verticalLabels({
			fieldConfig,
			language,
			disabled,
			items,
		});

		if (verticalFieldLabels) return verticalFieldLabels;

		return horizontalLabels({ fieldConfig, language, disabled, items });
	});
}
