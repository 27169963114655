import type { IContactLabel } from "@bothive_core/database";
import { Button, Form, Modal, Typography } from "antd";
import { useEffect } from "react";
import { FormattedMessage, defineMessage, useIntl } from "react-intl";

import ConditionComponent from "../../../../../../shared/components/ConditionComponent";
import { IRule, TCondition } from "../../../../../../shared/components/ConditionComponent/type";
import ContactLabels from "../../../../../../shared/components/ContactLabelsSelector";
import FieldLabel from "../../../../../../shared/components/field/default";
import { stringHelpers } from "../../../../../../shared/helpers";

interface IRuleModal {
	visible: boolean;
	isLabelLoading: boolean;
	rules: IRule[];
	fileKeys: string[];
	ruleId: number | undefined;
	contactLabels: IContactLabel[] | undefined;
	onClose: () => void;
	onChange: (rules: IRule[]) => void;
	originFileKeys?: string[];
}

export default function RuleModal({
	visible,
	contactLabels,
	isLabelLoading,
	ruleId,
	onClose,
	onChange,
	fileKeys,
	rules = [],
}: IRuleModal) {
	const intl = useIntl();
	const [form] = Form.useForm();

	const defaultRule = { conditions: [{ type: "if", value: "IS_DEFINED" }], label: undefined };

	const handleDelete = () => {
		onChange(rules?.filter((rule) => rule.id !== ruleId));
		onClose();
	};

	const handleSubmit = (value) => {
		let changedRules: IRule[] = [...rules];

		if (typeof ruleId !== "undefined") {
			changedRules = rules?.map((rule) => (rule.id === ruleId ? { id: ruleId, ...value } : rule));
		} else {
			changedRules.push({ ...value, id: rules.length });
		}

		onChange(changedRules);
		onClose();
	};

	useEffect(() => {
		const value = typeof ruleId !== "undefined" ? rules.find((rule) => rule.id === ruleId) : defaultRule;

		form.setFieldsValue(value);
	}, [ruleId, rules]);

	return (
		<Modal
			width={700}
			open={visible}
			transitionName=""
			onCancel={onClose}
			destroyOnClose={true}
			title={intl.formatMessage({ id: "contact.import.rule_import_modal.title", defaultMessage: "Add rule" })}
			footer={[
				typeof ruleId !== "undefined" && (
					<Button danger key="delete" type="primary" onClick={handleDelete}>
						<FormattedMessage id="contact.import.rule_import_modal.delete" defaultMessage="Delete rule" />
					</Button>
				),
				<Button key="submit" type="primary" htmlType="submit" form="importRuleForm">
					<FormattedMessage id="contact.import.rule_import_modal.add" defaultMessage="Add rule" />
				</Button>,
			]}
		>
			<Typography.Text>
				<FormattedMessage
					id="contact.import.rule_import_modal.description"
					defaultMessage="If a contact matches against your rule, the chosen label will be added to this contact."
				/>
			</Typography.Text>
			<Form
				id="importRuleForm"
				form={form}
				preserve={false}
				layout="vertical"
				onFinish={handleSubmit}
				initialValues={defaultRule}
				className="t-gap--top-sm"
			>
				<Form.Item
					className="t-gap--bottom"
					name="conditions"
					validateTrigger="onBlur"
					rules={[
						{
							validator: async (_, value: TCondition[]) => {
								let errorMessage;

								if (!value?.length) {
									errorMessage = defineMessage({
										id: "contact.import.rule_import_modal.condition.error.empty",
										defineMessage: "You haven't set any labels",
									});
								}

								const errorRule = value.find(
									(item) => stringHelpers.isEmpty(item.field) || stringHelpers.isEmpty(item.value)
								);

								if (errorRule) {
									errorMessage = defineMessage({
										id: "contact.import.rule_import_modal.condition.error.empty_fields",
										defineMessage: "You have empty fields in your rules",
									});
								}

								if (errorMessage) return Promise.reject(new Error(intl.formatMessage(errorMessage)));
							},
						},
					]}
				>
					<ConditionComponent fileKeys={fileKeys} />
				</Form.Item>
				<FieldLabel
					label={intl.formatMessage({
						id: "contact.import.rule_import_modal.label.title",
						defaultMessage: "Label",
					})}
					help={intl.formatMessage({
						id: "contact.import.rule_import_modal.label.help",
						defaultMessage: "Hint: with labels you can find and edit all contacts from a specific import",
					})}
				>
					<Form.Item
						name="label"
						style={{ width: "100%" }}
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: "contact.import.rule_import_modal.label.error.required",
									defaultMessage: "Please enter a label",
								}),
							},
						]}
					>
						<ContactLabels
							contactLabels={contactLabels}
							isLabelLoading={isLabelLoading}
							style={{ width: "100%" }}
							size="middle"
							showAddButton
						/>
					</Form.Item>
				</FieldLabel>
			</Form>
		</Modal>
	);
}
