import "./style.scss";

import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { Button, Row, Col, Image } from "antd";
import { routesConfig, typesConfig } from "@/config";
import { icon } from "@/assets/images";

export default function ApiKeyNotAllowed() {
	const history = useHistory();
	const routeParameters = useParams<{ team: string }>();
	const handleClick = () => {
		const url = routesConfig.dashboard.support.replace(":team", routeParameters.team);

		history.replace(`${url}?option=${typesConfig.supportTypes.pricing}`);
	};

	return (
		<>
			<div className="overlay">
				<Row className="upgradePlan">
					<Col className="info" xs={24} xl={9}>
						<section className="info-section">
							<h2 className="info-title">
								<FormattedMessage
									id="api_key_overview.lock.title"
									defaultMessage="Power up with API keys"
								/>
							</h2>
							<p>
								<FormattedMessage
									id="api_key_overview.lock.description"
									defaultMessage="API keys gives you the access to give developers access to you Bothive team and manage what they can access."
								/>
							</p>
							<Button type="primary" size="large" className="button t-gap--top-lg" onClick={handleClick}>
								<FormattedMessage
									id="api_key_overview.lock.button.contact_support"
									defaultMessage="Upgrade your plan"
								/>
							</Button>
						</section>
					</Col>
					<Col className="icon" xs={24} xl={15}>
						<div>
							<Image src={icon.locked} alt="locked" preview={false} />
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
}
