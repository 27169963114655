import "./style.scss";

import { ArrowRightOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Form, Input, Select, Switch, Tag, Typography } from "antd";
import { useMemo } from "react";
import { FormattedMessage, MessageDescriptor, defineMessages, useIntl } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";

import { stringHelpers } from "@bothive/helpers";
import { StepParams } from "../types";
import { ImportMappingFieldOption, toFields } from "./config";
import { importConfigState, importDataState, importMappingState } from "./state";
import { DateFormatMapping, ImportMapping, MappingConfig, MappingOperations } from "./types";

const FieldPreview = ({ value }: { value?: string }) => {
	return <Tag className="contact_import-map_fields-from">{value}</Tag>;
};

const warningList = defineMessages({
	noIdentifier: {
		id: "contact.import.csv.map_fields.warning.identifier",
		defaultMessage:
			"You need to map at least one valid identifier (email, phone, national registration number or unique identifier) to import your contacts.",
	},
	noLinkedContact: {
		id: "contact.import.csv.map_fields.warning.linked_contact",
		defaultMessage:
			"You need to map at least one valid identifier (email, phone, national registration number or unique identifier) of the existing contact.",
	},
	noName: {
		id: "contact.import.csv.map_fields.warning.name",
		defaultMessage: "You need to map a name.",
	},
});

type FieldsFrom = {
	enabled: boolean;
	from: string;
	to: string;
	config: string;
};

export default function MapFields({ onNext, onBack }: StepParams & { onNext: () => void }) {
	const intl = useIntl();
	const importConfig = useRecoilValue(importConfigState);
	const [form] = Form.useForm<{ fields: FieldsFrom[] }>();
	const [importData, setImportData] = useRecoilState(importDataState);
	const [importMapping, setImportMapping] = useRecoilState(importMappingState);

	const mapping = Form.useWatch("fields", form);
	const toFieldOptions = toFields(importConfig.type);

	const toFieldConfigField = useMemo(
		() =>
			Object.values(toFieldOptions).reduce<Record<string, ImportMappingFieldOption["options"][0]["config"]>>(
				(prev, item) => ({
					...prev,
					...item.options.reduce((prev, option) => ({ ...prev, [option.value]: option.config }), {}),
				}),
				{}
			),
		[]
	);

	const handleNext = async ({ fields }: { fields: FieldsFrom[] }) => {
		const fieldMapping = fields.reduce<Record<string, ImportMapping>>((prev, curr) => {
			if (stringHelpers.isEmptyString(curr.to) || !curr.enabled) return prev;

			const config = toFieldConfigField[curr.to];
			const mapping: ImportMapping = { to: curr.to };

			if (curr.config && config) mapping.config = { operation: config.operation, value: curr.config };

			return { ...prev, [curr.from]: mapping };
		}, {} as Record<string, ImportMapping>);

		setImportData((prev) =>
			prev.map((data) => {
				const _data = structuredClone(data);

				Object.entries(fieldMapping).forEach(([from, mapping]) => {
					if (!mapping.config) return;

					const formatValue = mappingOperations[mapping.config.operation];

					if (!formatValue) return;

					_data[from] = formatValue({ value: _data[from], config: mapping.config });
				}, {});

				return _data;
			})
		);

		setImportMapping(fieldMapping);
		onNext();
	};

	const fields = useMemo(() => {
		if (!importData) return [];

		const combineRows = importData.reduce((prev, curr) => ({ ...prev, ...curr }), {});

		return Object.keys(combineRows).map((key) => ({
			from: key,
			enabled: !!importMapping[key],
			to: importMapping[key]?.to || "",
			config: importMapping[key]?.config?.value,
		}));
	}, [importData]);
	const warningMessages = useMemo(() => {
		const warnings: MessageDescriptor[] = [];

		const uniqueIdentifier = ["email", "phone", "nationalRegistrationNumber", "uniqueIdentifier"];
		const partnerIdentifier = [
			"linkedToContact.email",
			"linkedToContact.phone",
			"linkedToContact.nationalRegistrationNumber",
			"linkedToContact.uniqueIdentifier",
		];

		if (!mapping?.find((field) => uniqueIdentifier.includes(field.to) && field.enabled)) {
			warnings.push(warningList.noIdentifier);
		}
		if (
			importConfig.type === "linkedContact" &&
			!mapping?.find((field) => partnerIdentifier.includes(field.to) && field.enabled)
		) {
			warnings.push(warningList.noLinkedContact);
		}
		if (!mapping?.find((field) => ["firstName", "lastName", "fullName"].includes(field.to) && field.enabled)) {
			warnings.push(warningList.noName);
		}

		return warnings;
	}, [mapping]);

	const handleFieldsChange = (changedField) => {
		const field = changedField[0];

		if (field.name[2] !== "to") return;

		const name = [field.name[0], field.name[1], "enabled"];

		form.setFieldValue(name, !!field.value);
	};

	return (
		<Form
			form={form}
			onFinish={handleNext}
			initialValues={{ fields }}
			onFieldsChange={handleFieldsChange}
			className="contact_import-content_wrapper contact_import-map_fields"
		>
			<Card
				title={intl.formatMessage({
					id: "contact.import.csv.map_fields.title",
					defaultMessage: "Map fields",
				})}
				className="contact_import-content-card contact_import-map_fields-card"
			>
				<div className="contact_import-content-card-body">
					{!!warningMessages.length && (
						<Alert
							type="warning"
							className="contact_import-map_fields-alert"
							message={
								<Typography.Title level={5}>
									<FormattedMessage
										id="contact.import.csv.map_fields.warning.title"
										defaultMessage="We are missing some field mappings to continue the contact import"
									/>
								</Typography.Title>
							}
							description={
								<div>
									<ul className="contact_import-map_fields-alert-list">
										{warningMessages.map((item) => (
											<li key={item.id}>
												<FormattedMessage {...item} />
											</li>
										))}
									</ul>
								</div>
							}
						/>
					)}
					<header className="contact_import-map_fields-grid contact_import-map_fields-header">
						<Typography.Text strong>
							<FormattedMessage
								id="contact.import.csv.map_fields.header.enabled"
								defaultMessage="Import"
							/>
						</Typography.Text>
						<Typography.Text style={{ gridColumn: "2/4" }}>
							<FormattedMessage id="contact.import.csv.map_fields.header.from" defaultMessage="From" />
						</Typography.Text>
						<Typography.Text className="t-gap--left-sm">
							<FormattedMessage id="contact.import.csv.map_fields.header.to" defaultMessage="To" />
						</Typography.Text>
					</header>

					<div className="contact_import-map_fields-content">
						<Form.List name="fields">
							{(fields) =>
								fields.map(({ key, name, ...restField }) => {
									const toField = form.getFieldValue(["fields", name, "to"]);
									const configField = toFieldConfigField[toField];

									if (configField && !form.getFieldValue(["fields", name, "config"])) {
										form.setFieldValue(["fields", name, "config"], configField.defaultValue);
									}

									return (
										<div
											key={key}
											className="contact_import-map_fields-grid"
											data-extended={!!configField}
										>
											<Form.Item {...restField} name={[name, "enabled"]} valuePropName="checked">
												<Switch />
											</Form.Item>
											<Form.Item {...restField} name={[name, "from"]} shouldUpdate>
												<FieldPreview />
											</Form.Item>
											<ArrowRightOutlined />
											<Form.Item {...restField} name={[name, "to"]}>
												<Select
													allowClear
													showSearch
													filterOption={(input, option) =>
														`${option?.label}`?.toLowerCase()?.includes(input.toLowerCase())
													}
													notFoundContent={null}
													options={Object.values(toFieldOptions).map((group) => ({
														label: intl.formatMessage(group.label),
														options: group.options.map((option) => ({
															...option,
															label: intl.formatMessage(option.label),
														})),
													}))}
												/>
											</Form.Item>
											{configField && (
												<>
													<p className="t-gap--bottom-sm">
														<FormattedMessage {...configField.label} />
													</p>
													<Form.Item {...restField} name={[name, "config"]}>
														{configField.type === "SELECT" && (
															<Select
																options={configField.options?.map((item) => ({
																	...item,
																	label:
																		typeof item.label === "string"
																			? item.label
																			: intl.formatMessage(item.label),
																}))}
																defaultValue={configField.defaultValue}
															/>
														)}
														{configField.type === "TEXT_INPUT" && <Input />}
													</Form.Item>
												</>
											)}
										</div>
									);
								})
							}
						</Form.List>
					</div>
					<footer className="contact_import-content-footer">
						<Button type="text" onClick={() => onBack()}>
							<FormattedMessage id="contact.import.csv.map_fields.footer.back" defaultMessage="Back" />
						</Button>
						<Form.Item className="contact_import-content-footer-submit">
							<Button type="primary" htmlType="submit" disabled={!!warningMessages.length}>
								<FormattedMessage
									id="contact.import.csv.map_fields.footer.next"
									defaultMessage="Next"
								/>
							</Button>
						</Form.Item>
					</footer>
				</div>
			</Card>
		</Form>
	);
}

const mappingOperations: Record<MappingOperations, (params: { value: string; config: MappingConfig }) => string> = {
	FORMAT_DATE: ({ value, config }: { value: string; config: DateFormatMapping }) => {
		if (stringHelpers.isEmptyString(value)) return "";

		console.groupCollapsed(config.value);

		try {
			switch (config.value) {
				case "DD/MM/YYYY": {
					const splitDates = value.split("/");

					return new Date(`${splitDates[1]}/${splitDates[0]}/${splitDates[2]}`).toString() || "";
				}
				case "MM/DD/YYYY":
					return new Date(value).toString();
				case "utc":
				default:
					return value;
			}
		} catch (error) {
			console.error("Failed to format date", error);
			return "";
		}
	},
};
