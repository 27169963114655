import React from "react";

import { Component, Input } from "..";

export default function ASearchHeader({
	id,
	value,
	disabled,
	placeholder,
	active,
	isLoading,
	className,
	iconClass,
	onClick,
	onChange,
	onKeyUp,
	setRef,
}) {
	return (
		<div
			id={id}
			role="button"
			tabIndex="0"
			aria-pressed={active}
			aria-expanded={active}
			onClick={onClick}
			onKeyUp={onKeyUp}
			ref={setRef}
			data-disabled={disabled}
			className={`a-dropdown-header a-dropdown-header--searchable ${className}
			${disabled ? "disabled" : ""} ${active ? "active" : ""}`}
		>
			<Input.ATextField
				id={`searchableHeader_${id}`}
				value={value}
				name="doNotShowAutoComplete"
				disabled={disabled}
				placeholder={placeholder}
				onChange={onChange}
				className="searchable--field"
				autoComplete="off"
			/>
			{isLoading && <Component.AIcon type={"loader"} className={`small ${iconClass}`} />}
			{!isLoading && (
				<Component.AIcon type={`arrow--${active ? "up" : "down"}`} className={`fill ${iconClass}`} />
			)}
		</div>
	);
}
