import Reminder from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/components/Reminder";
import Setup from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/components/Setup";
import { SendFirstAfterDaysState } from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/components/Setup/components/StopForm";
import { handleCreateOrUpdate } from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/helpers";
import NoChannel from "@/shared/components/no_channel";
import { FlowRemindersRouterOutput, trpc } from "@/trpc";
import { Form, Steps } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import PopupFullscreen from "../../../../../shared/components/popup/full_screen/index";
import config from "../../../../send/components/Compose/config";
import { flowState } from "@/flows/common/state";

export default function CreateReminderStrategy({
	onClose,
	selectedReminderStrategyData,
}: {
	onClose: () => void;
	selectedReminderStrategyData?: FlowRemindersRouterOutput["getRemindersSetting"];
}) {
	const [currentStep, setCurrentStep] = useState(0);
	const setSendAfterFirstDays = useSetRecoilState(SendFirstAfterDaysState);

	const intl = useIntl();
	const params = useParams<{ flowId: string }>();

	const [form] = Form.useForm();

	const flow = useRecoilValueLoadable(flowState({ id: params?.flowId }));
	const createFlowReminderSettingsMutation = trpc.flowReminders.createFlowReminderSettings.useMutation();
	const updateFlowReminderSettingsMutation = trpc.flowReminders.updateFlowReminderSettings.useMutation();
	const trpcUtils = trpc.useUtils();

	const { data: inbox } = trpc.inbox.inbox.getUserInbox.useQuery();

	const handleKeyUp = (event: KeyboardEvent) => {
		if (event.key === "Escape") {
			handleClose();
		}
	};

	const handleClose = () => {
		form.resetFields();

		onClose();
	};

	useEffect(() => {
		document.addEventListener("keyup", handleKeyUp);

		return () => document.removeEventListener("keyup", handleKeyUp);
	}, []);

	useEffect(() => {
		const templateId = flow.contents.templateId;

		if (!selectedReminderStrategyData) {
			const messageValue =
				config.templates.find((template) => template.id === templateId && template.type === "reminding")
					?.content ||
				config.templates.find((template) => template.id === templateId && template.type)?.content ||
				config.templates.find((template) => template.id === "default")?.content;

			form.setFieldsValue({ body: messageValue });
			return;
		}

		const { settings } = selectedReminderStrategyData;

		const localDate = new Date();

		const [hours, minutes] = settings?.timeOfDayUTC?.split(":");

		// set the hours and minutes based on the UTC time string
		localDate.setUTCHours(+hours);
		localDate.setUTCMinutes(+minutes);

		const timeOfDayLocal = localDate.toLocaleTimeString();
		const cutOffDateLocal = settings?.cutoffDateUTC ? new Date(settings?.cutoffDateUTC) : undefined;

		const messageValue =
			config.templates.find((template) => template.id === templateId && template.type === "reminding")?.content ||
			config.templates.find((template) => template.id === templateId && template.type)?.content ||
			config.templates.find((template) => template.id === "default")?.content;

		form.setFieldsValue({
			...settings,
			subject: settings?.templates?.[0]?.subject || "",
			signatureId: settings?.templates?.[0]?.signatureId,
			body: settings?.templates?.[0]?.body || messageValue,
			timeOfDay: timeOfDayLocal ? moment(timeOfDayLocal, "HH:mm") : undefined,
			cutoffDate: cutOffDateLocal ? moment(cutOffDateLocal) : undefined,
			allowReEnroll: settings?.allowReEnroll === undefined ? true : settings.allowReEnroll,
		});
	}, [selectedReminderStrategyData]);

	const generateStep = () => {
		if (!inbox?.channels.length) {
			return (
				<div style={{ display: inbox?.channels.length ? "none" : "block" }}>
					<NoChannel />
				</div>
			);
		}

		return (
			<>
				<div style={{ display: currentStep === 0 ? "block" : "none" }}>
					<Setup setCurrentStep={setCurrentStep} form={form} />
				</div>

				<div style={{ display: currentStep === 1 ? "block" : "none" }}>
					<Reminder setCurrentStep={setCurrentStep} form={form} />
				</div>
			</>
		);
	};

	return (
		<PopupFullscreen onClose={() => handleClose()} className="t-layout--modal">
			<div style={{ display: "flex" }} className="layout-content">
				<div style={{ marginTop: "3em", height: "50vh", minWidth: "250px", marginRight: "2em" }}>
					<Steps
						direction="vertical"
						current={currentStep}
						items={[
							{
								title: intl.formatMessage({
									id: "flow.detail.reminders.create_strategy.step_1.step",
									defaultMessage: "Setup",
								}),
							},
							{
								title: intl.formatMessage({
									id: "flow.detail.reminders.create_strategy.step_2.title",
									defaultMessage: "Define reminder",
								}),
							},
						]}
						style={{ height: "100%" }}
					/>
				</div>
				<Form
					id="reminderSettings"
					form={form}
					onFinish={(values) =>
						handleCreateOrUpdate({
							values,
							intl,
							flowId: params.flowId,
							selectedReminderStrategyData,
							updateFlowReminderSettingsMutation,
							trpcUtils,
							handleClose,
							createFlowReminderSettingsMutation,
						})
					}
					onFieldsChange={(changedFields) => {
						const sendFirstAfterDays = changedFields.find(
							(field) => field.name[0] === "sendFirstAfterDays"
						)?.value;
						setSendAfterFirstDays(sendFirstAfterDays);
					}}
				>
					{generateStep()}
				</Form>
			</div>
		</PopupFullscreen>
	);
}
