import axios from "axios";

import { apiConfig } from "../../../../config";
import { queryStringHelpers, timerHelpers } from "../../../../helpers";
import actionTypes from "./members.actionTypes";

function startFetchWatcher(dispatch) {
	timerHelpers.startTimer();
	timerHelpers.watchTimeout(() => {
		dispatch(fetchingFailed());
		return;
	});

	dispatch(isFetching());
}

export const isFetching = () => (dispatch) => {
	dispatch({
		type: actionTypes.MEMBERS_IS_FETCHING,
	});
};
export const fetchingFailed = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCH_MEMBERS_FAILED,
	});
};
export const fetchingSucceeded = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCH_MEMBERS_SUCCEEDED,
	});
};

export const updateFilter = (data) => (dispatch) => {
	dispatch({
		type: actionTypes.UPDATE_MEMBER_FILTERS,
		data,
	});
};

export const fetchMembers = ({ limit, offset, q } = { limit: 20, offset: 0, q: undefined }) => async (dispatch) => {
	dispatch(updateFilter({ limit, offset, q }));
	startFetchWatcher(dispatch);

	try {
		const query = queryStringHelpers.createQueryString({ limit, offset, q });
		const result = await axios.get(`${apiConfig.members.getAll}${query}`);

		if (result.status !== 200 || !result.data.success) {
			throw new Error();
		}

		dispatch({
			type: actionTypes.FETCH_MEMBERS,
			data: result.data,
		});
		timerHelpers.clearTimer();
		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
		timerHelpers.clearTimer();
	}
};
