import { useRef, useState } from "react";
import { Crop } from "react-image-crop";

import { IImageUploadResult, dataURLtoFile, imageToCanvas, uploadImage } from "../../helpers/file.helpers";
import CropModal from "./components/CropModal";
import ImageSegment, { ISegmentConfig } from "./components/ImageSegment";

const initialCrop = {
	unit: "%",
	width: 60,
	aspect: 4 / 4,
};

interface IImageUpload {
	id: string;
	value: string;
	fileSize: number;
	disabled?: boolean;
	className?: string;
	crop: Partial<Crop>;
	config?: ISegmentConfig;
	onChange?: (url: string) => void;
}

export default function ImageUpload({
	id,
	crop,
	fileSize,
	config,
	disabled,
	value,
	className,
	onChange,
}: IImageUpload) {
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showCrop, setShowCrop] = useState(false);
	const originalImage = useRef<IImageUploadResult>();
	const handleImageUpload = async (value: IImageUploadResult) => {
		originalImage.current = value;

		// Skip crop for svg and just upload it. Crop on svg does not work and does not make sense bcs svg is computational
		if (typeof value.url === "string" && value.type === "image/svg+xml") {
			try {
				if (!onChange) return;
				setLoading(true);
				setShowCrop(false);

				const file = dataURLtoFile(value.url, value.name);
				const { url } = await uploadImage(file);

				onChange(url);
				setLoading(false);
			} catch (error) {
				setError(true);
				setLoading(false);
			}

			return;
		}

		const canvasUrl = await imageToCanvas({ src: value.url as string, minWidth: 480 });

		if (canvasUrl) originalImage.current.url = canvasUrl;

		setError(false);
		setLoading(false);
		setShowCrop(true);
	};

	const handleUploadImage = async (file: File) => {
		try {
			if (!onChange) return;

			setLoading(true);
			setShowCrop(false);

			const { url } = await uploadImage(file);

			onChange(url);
			setLoading(false);
		} catch (error) {
			setError(true);
			setLoading(false);
		}
	};

	return (
		<>
			<ImageSegment
				id={id}
				value={value}
				error={error}
				config={config}
				loading={loading}
				disabled={disabled}
				onChange={handleImageUpload}
				className={className}
			/>
			<CropModal
				crop={crop}
				setModal={setShowCrop}
				open={showCrop}
				value={originalImage.current as IImageUploadResult}
				onClose={() => setShowCrop(false)}
				onSubmit={handleUploadImage}
			/>
		</>
	);
}

ImageUpload.defaultProps = {
	value: "",
	fileSize: 10000000,
	crop: initialCrop,
	disabled: false,
	className: "",
	onChange: () => undefined,
};
