import "./style.scss";

import { List, Skeleton } from "antd";
import React from "react";

import UserPreview from "@/shared/components/user/user_preview";

export default ({ id }) => (
	<List.Item key={id} className="report_list_item_skeleton">
		<div className="report_skeleton_header">
			<UserPreview isLoading />
		</div>
		<div className="report_skeleton_footer">
			<Skeleton title={true} paragraph={false} size="small" active width={50} />
		</div>
	</List.Item>
);
