import CountryPicker from "@/shared/components/select/country_picker";
import { Form, Input, Switch } from "antd";
import { useIntl } from "react-intl";

import FieldLabel from "../../../../../../../../shared/components/field/default";

export const CryptoWalletItems = () => {
	const intl = useIntl();

	return (
		<>
			<FieldLabel id="name" label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_NAME">
				<Form.Item
					name="name"
					className="bank_account_modal-form_item"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_NAME.ERROR_REQUIRED",
							}),
						},
					]}
				>
					<Input
						placeholder={intl.formatMessage({
							id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_NAME.PLACEHOLDER",
						})}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="country"
				label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_COUNTRY"
				help="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_COUNTRY.HELP"
			>
				<Form.Item
					name="country"
					className="bank_account_modal-form_item"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.INSTITUTION_COUNTRY.ERROR_REQUIRED",
							}),
						},
					]}
				>
					<CountryPicker />
				</Form.Item>
			</FieldLabel>
			<FieldLabel id="isDeclared" label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.IS_DECLARED">
				<Form.Item name="isDeclared" valuePropName="checked">
					<Switch />
				</Form.Item>
			</FieldLabel>
			<FieldLabel id="isClosed" label="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FORM.CRYPTO.IS_CLOSED.LABEL">
				<Form.Item name="isClosed" valuePropName="checked">
					<Switch />
				</Form.Item>
			</FieldLabel>
		</>
	);
};
