import { Form, FormInstance, Select } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDebounce } from "use-debounce";
import { stringHelpers } from "@bothive/helpers";
import type { IAdminPulseContactFilter } from "@bothive_core/database";

import { partnerConfig } from "../../../../../../../config";
import ConditionComponent from "../../../../../../../shared/components/ConditionComponent";
import { TCondition } from "../../../../../../../shared/components/ConditionComponent/type";
import ContactLabelsSelector from "../../../../../../../shared/components/ContactLabelsSelector";
import { trpc } from "../../../../../../../trpc";
import { contactTypesI18n, crmTypesI18n } from "../config";

interface IWebhookRulesForm {
	form?: FormInstance;
	values?: IAdminPulseContactFilter;
	onSubmit: (values) => void;
}
export default function WebhookRulesForm({ form, values, onSubmit }: IWebhookRulesForm) {
	const intl = useIntl();
	const [labelSearchValue, setLabelSearchValue] = useState<string | undefined>(undefined);
	const [debouncedSearch] = useDebounce(labelSearchValue, 500);
	const { data: contactLabels, isLoading: isLabelLoading } = trpc.contactLabel.all.useQuery({
		q: debouncedSearch,
		limit: 0,
	});

	return (
		<Form
			form={form}
			preserve={false}
			layout="vertical"
			onFinish={onSubmit}
			id="webhookRulesForm"
			initialValues={values}
		>
			<div>
				<Form.Item
					label={intl.formatMessage({
						id: "settings.integrations.admin_pulse.webhook.form.crm_type.label",
						defaultMessage: "CRM type",
					})}
					name="crmType"
				>
					<Select
						size="large"
						placeholder={intl.formatMessage({
							id: "settings.integrations.admin_pulse.webhook.form.crm_type.placeholder",
							defaultMessage: "Select CRM type",
						})}
						allowClear={true}
					>
						{Object.keys(crmTypesI18n).map((key) => (
							<Select.Option value={key} key={key}>
								<FormattedMessage {...crmTypesI18n[key]} />
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label={intl.formatMessage({
						id: "settings.integrations.admin_pulse.webhook.form.type.label",
						defaultMessage: "Type",
					})}
					name="type"
				>
					<Select
						size="large"
						placeholder={intl.formatMessage({
							id: "settings.integrations.admin_pulse.webhook.form.type.placeholder",
							defaultMessage: "Select contact type",
						})}
						allowClear={true}
					>
						{Object.keys(contactTypesI18n).map((key) => (
							<Select.Option value={key} key={key}>
								<FormattedMessage {...contactTypesI18n[key]} />
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</div>

			<Form.Item
				name="rules"
				validateTrigger="onBlur"
				rules={[
					{
						validator: async (_, value: TCondition[]) => {
							let errorMessage;

							if (!value?.length) return true;

							const errorRule = value.find(
								(item) =>
									stringHelpers.isEmptyString(item.field) || stringHelpers.isEmptyString(item.value)
							);

							if (errorRule) {
								errorMessage = intl.formatMessage({
									id: "settings.integrations.admin_pulse.webhook.form.rules.invalid.empty",
									defaultMessage: "You have empty fields in your rules",
								});
							}

							if (errorMessage) {
								return Promise.reject(new Error(intl.formatMessage({ id: errorMessage })));
							}
						},
					},
				]}
				label={intl.formatMessage({
					id: "settings.integrations.admin_pulse.webhook.form.rules.label",
					defaultMessage: "Rules",
				})}
				help={intl.formatMessage({
					id: "settings.integrations.admin_pulse.webhook.form.rules.help",
					defaultMessage:
						"Rules fine-tune Admin Pulse filters and help to label specific contacts effortlessly.",
					description:
						"Rules fine tunes the default Admin pulse filters and can be useful to add labels to specific contacts",
				})}
			>
				<ConditionComponent fileKeys={partnerConfig.adminPulse.contactImportKeys || []} />
			</Form.Item>
			<Form.Item
				name="labelId"
				style={{ width: "100%" }}
				label={intl.formatMessage({
					id: "settings.integrations.admin_pulse.webhook.form.label.label",
					defaultMessage: "Label",
				})}
				help={intl.formatMessage({
					id: "settings.integrations.admin_pulse.webhook.form.label.help",
					defaultMessage:
						"This label will be attached to contacts that match your rules. Stay organized effortlessly!",
					description:
						"Help text under form input to explain that this label will be attached to the contacts who match with the rules",
				})}
			>
				<ContactLabelsSelector
					isLabelLoading={isLabelLoading}
					contactLabels={contactLabels?.data}
					onSearch={setLabelSearchValue}
					showAddButton
					allowClear
				/>
			</Form.Item>
		</Form>
	);
}
