import { Button } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useRecoilState, useRecoilValue } from "recoil";

import { selectedFlowIdState } from "@/flows/common/state";
import ShowFailedBanner from "@/shared/components/banner/failed";

import { addStatusType } from "../../state";
import config from "../config";
import { showCreateStatusTypeState } from "../state";
import StatusTypesModalTemplate from "../template";
import { createStatusType } from "./query";

export default function CreateStatusTypesModal() {
	const intl = useIntl();
	const [isLoading, setIsLoading] = useState(false);
	const flowId = useRecoilValue(selectedFlowIdState);
	const [statusTypes, addNewStatusType] = useRecoilState(addStatusType);
	const [issModalVisible, setIsModalVisible] = useRecoilState(showCreateStatusTypeState);
	const maxStatusTypes = useSelector((state) => state.dashboard.permissions.statusTypes);

	const handleAdd = async (payload) => {
		let errorTitle = config.updateError.default;

		setIsLoading(true);

		try {
			const result = await createStatusType({ flowId, payload });

			if (result.error) {
				errorTitle = config.updateError[result.error.code];
				throw new Error();
			}

			addNewStatusType(result.data);
			setIsModalVisible(false);
		} catch (_) {
			ShowFailedBanner({ title: intl.formatMessage({ id: errorTitle, defaultMessage: errorTitle }) });
		}

		setIsLoading(false);
	};

	return (
		<StatusTypesModalTemplate
			title="FLOWS.REPORTS.STATUS_TYPES.MODAL.ADD.TITLE"
			handleSubmit={(values) => handleAdd(values)}
			onCancel={() => setIsModalVisible(false)}
			isVisible={issModalVisible}
			footer={[
				<Button
					key="submit"
					type="primary"
					form="StatusTypeForm"
					htmlType="submit"
					loading={isLoading}
					disabled={statusTypes?.length >= maxStatusTypes}
				>
					<FormattedMessage id="FLOWS.REPORTS.STATUS_TYPES.MODAL.ADD" />
				</Button>,
			]}
		/>
	);
}
