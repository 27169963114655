import Tag from "@/shared/components/antdWrappers/Tag";
import { ConversationRouterOutput } from "@/trpc";
import { Tooltip } from "antd";
import { TagFilled } from "@ant-design/icons";
import { contrastTextByBackground } from "@/helpers/color.helpers";
import "./style.scss";

interface AssignedLabelsProps {
	labels: ConversationRouterOutput["getById"]["labelData"];
	maxVisible?: number;
}

export default function AssignedLabels({ labels, maxVisible = 5 }: AssignedLabelsProps) {
	const visibleLabels = labels.slice(0, maxVisible);
	const hiddenLabels = labels.slice(maxVisible, labels.length);

	if (!visibleLabels?.length) return null;

	return (
		<div className="albl-assigned-labels-container">
			{visibleLabels.map((label) => {
				const textColor = contrastTextByBackground({
					backgroundColor: label.color || "gray",
					lightTextColor: "#fafcfc", // $matte--black
					darkTextColor: "#141414", // $white--light
				});

				return (
					<Tag
						icon={<TagFilled style={{ color: textColor }} />}
						title={label.name}
						className="truncate"
						key={label._id}
						color={label.color}
						style={{ margin: 0, maxWidth: "30ch" }}
					>
						{label.name}
					</Tag>
				);
			})}
			{labels.length > maxVisible && (
				<Tooltip title={hiddenLabels.map((item) => item.name).join(", ")}>
					<Tag style={{ background: "var(--primary)" }}>+ {labels.length - maxVisible}</Tag>
				</Tooltip>
			)}
		</div>
	);
}
