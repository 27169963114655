import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { Section } from "@/components/organisms";
import { routesConfig } from "@/flows/common/config";
import { selectedFlowIdState } from "@/flows/common/state";
import { removeFlow } from "@/flows/overview/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import Collapse, { DestructivePanel } from "@/shared/components/collapse";
import DeleteModal from "@/shared/components/modal/delete_modal";

import query from "./query";

export default function DeleteFlowModal() {
	const intl = useIntl();
	const params = useParams();
	const history = useHistory();
	const selectedFlowId = useRecoilValue(selectedFlowIdState);
	const deleteFlow = useSetRecoilState(removeFlow);
	const [isDeleting, setIsDeleting] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const handleDelete = async () => {
		setIsDeleting(true);

		try {
			await query.deleteFlow({ flowId: selectedFlowId });

			const url = routesConfig.overview.replace(":team", params.team);

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOWS.DETAIL.SETTINGS.DELETE_SUCCEEDED.TITLE",
					defaultMessage: "Flow is successfully removed.",
				}),
			});
			deleteFlow(selectedFlowId);
			setIsDeleting(false);
			setShowModal(false);
			history.push(url);
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.DETAIL.SETTINGS.DELETE_FAILED.TITLE",
					defaultMessage: "Flow could not be removed. Please try again later.",
				}),
			});
			setIsDeleting(false);
		}
	};

	return (
		<>
			<Collapse expandIconPosition="end">
				<DestructivePanel id="dangerZone" title="FLOW_DETAIL.SETTINGS.DANGER_ZONE.ACCORDION_TITLE">
					<Section.ODestructive
						title="FLOW_DETAIL.SETTINGS.DANGER_ZONE.TITLE"
						description="FLOW_DETAIL.SETTINGS.DANGER_ZONE.DESCRIPTION"
						buttonLabel="FLOW_DETAIL.SETTINGS.DANGER_ZONE.BUTTON"
						onClick={() => setShowModal(true)}
					/>
				</DestructivePanel>
			</Collapse>
			<DeleteModal
				title={intl.formatMessage({
					id: "FLOW_DELETE_MODAL.HEADER.TITLE",
					defaultMessage: "Delete flow",
				})}
				deleteLabel={intl.formatMessage({
					id: "FLOW_DELETE_MODAL.DELETE.BUTTON_LABEL",
					defaultMessage: "Delete flow",
				})}
				description={intl.formatMessage({
					id: "FLOW_DELETE_MODAL.HEADER.DESCRIPTION",
					defaultMessage:
						"Are you sure you want to delete this flow? This action can not be undone and will deactivate alle links!",
				})}
				isVisible={showModal}
				isDeleting={isDeleting}
				onDelete={handleDelete}
				onClose={() => setShowModal(false)}
			/>
		</>
	);
}
