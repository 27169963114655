import axios from "axios";

import { apiConfig } from "../../../../config";
import { timerHelpers } from "../../../../helpers";
import store from "../../../store";
import actionTypes from "./status_types.actionTypes";

function startFetchWatcher(dispatch) {
	timerHelpers.startTimer();
	timerHelpers.watchTimeout(() => {
		dispatch(fetchingFailed());
		return;
	});

	dispatch(isFetching());
}
export const isFetching = () => (dispatch) => {
	dispatch({
		type: actionTypes.FLOW_STATUS_TYPE_IS_FETCHING,
	});
};
export const fetchingFailed = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCH_FLOW_STATUS_TYPE_FAILED,
	});
};
export const fetchingSucceeded = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCH_FLOW_STATUS_TYPE_SUCCEEDED,
	});
};

export const fetchFlowStatusTypes = (flowId) => async (dispatch) => {
	const state = store.getState().content.flow.statusTypes.list;

	if (state.length && state[0].flowId === flowId) {
		dispatch({ type: actionTypes.FLOW_STATUS_TYPES_ALREADY_FETCHED });
		return;
	}

	startFetchWatcher(dispatch);

	try {
		const result = await axios.get(apiConfig.flow.statusTypes.getAll.replace(":flowId", flowId));

		if (result.status !== 200 || !result.data.success) {
			throw new Error();
		}

		dispatch({
			type: actionTypes.FETCH_FLOW_STATUS_TYPES,
			data: result.data,
		});
		dispatch(fetchingSucceeded());
		timerHelpers.clearTimer();
	} catch (error) {
		dispatch(fetchingFailed());
		timerHelpers.clearTimer();
	}

	dispatch(fetchingSucceeded());
};
