import { Liquid } from "liquidjs";

const countriesList = require("world_countries_lists/data/countries/_combined/countries.json");

export default {
	parseHtml: async ({ templateContents, data, language } = { language: "nl" }) => {
		const engine = new Liquid();

		engine.registerFilter("replaceCountry", (value) => {
			if (!value) return;

			const country = countriesList.find((item) => item.alpha2 === value.toLowerCase());

			return country?.[language.slice(0, 2)] || value;
		});

		const parsedHtml = await engine.parseAndRender(templateContents, data);

		const adjustedTargetsString = parsedHtml.replace("<head>", "<head><base target='_blank' />");

		return adjustedTargetsString;
	},
};
