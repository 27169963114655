import "./style.scss";

import { useState, useEffect } from "react";
import { Button, Empty, Tooltip, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { icon } from "@/assets/images";

import isEqual from "lodash.isequal";
import { useSelector } from "react-redux";
import { trpc } from "@/trpc";

export default function NoKeysFound({ onClick, className }: { onClick: () => void; className?: string }) {
	const intl = useIntl();
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const allowEditing = useSelector((state) => state.dashboard.permissions.userPermissions.editSettings, isEqual);
	const { data: apiKeys } = trpc.apiKeyManager?.getAll?.useQuery({ offset: 0 });
	const { data: organization } = trpc.organization.getOrganization.useQuery();

	useEffect(() => {
		if (apiKeys && organization && apiKeys.meta?.total == organization.permissions?.apiKeys && !allowEditing) {
			return setIsDisabled(true);
		}
		return setIsDisabled(false);
	}, [, apiKeys, organization]);

	return (
		<Empty
			image={icon.apiKey}
			imageStyle={{
				height: 256,
			}}
			className={`api-key-overview--no-keys ${className || ""}`}
			description={
				<div className="t-gap--top t-gap--bottom">
					<Typography.Title level={2}>
						<FormattedMessage
							id="api_key_overview.no_keys_found.title"
							defaultMessage="Power up with API keys"
						/>
					</Typography.Title>
					<Typography.Text>
						<FormattedMessage
							id="api_key_overview.no_keys_found.description"
							defaultMessage="API keys gives you the access to give developers access to you Bothive team and manage what they can access."
						/>
					</Typography.Text>
				</div>
			}
		>
			<Tooltip
				title={
					!allowEditing
						? intl.formatMessage({
								id: "settings.api_key.overview.no_keys.disabled",
								defaultMessage: "You can not create API keys, please upgrade your plan",
								description:
									"This message is shown when the organization has no permissions to create API keys",
						  })
						: null
				}
			>
				<Button type="primary" onClick={onClick} disabled={isDisabled}>
					<FormattedMessage
						id="api_key_overview.no_keys_found.button.create_api_key"
						defaultMessage="Create API key"
					/>
				</Button>
			</Tooltip>
		</Empty>
	);
}
