import { atom, selector } from "recoil";

export const showCreateStatusTypeState = atom({
	key: "frsShowCreateStatusTypeState",
	default: false,
});

export const showEditStatusTypeState = selector({
	key: "frsShowEditStatusTypeState",
	get: ({ get }) => {
		const editStatusType = get(editStatusTypeIdState);

		return editStatusType !== undefined;
	},
	set: ({ set }, value) => {
		if (value) {
			// eslint-disable-next-line max-len
			console.error(
				"Set can only be used to hide edit status modal, if you want to show the edit status type modal set editStatusTypeIdState"
			);
			return;
		}

		set(editStatusTypeIdState, undefined);
	},
});

export const editStatusTypeIdState = atom({
	key: "frsEditStatusTypeIdState",
	default: undefined,
});
