import "./style.scss";

import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Component } from "@/components/atoms";
import { timingConfig } from "@/config";
import { debounce } from "@/helpers";
import helpers from "../helpers";
import config from "./config";
import DeveloperEmailOptions from "./developer_email_options";

export default function DeveloperSettings({ notificationSettings, className, onChange }) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [showMailOptions, setShowMailOptions] = useState(false);

	const handleChange = (changedFields) => {
		debounce(() => {
			const formErrors = form.getFieldsError();

			if (formErrors.find((field) => field.errors?.length)) {
				return;
			}

			const result = helpers.formatChangedFields(changedFields);

			onChange(result);
		}, timingConfig.keyboardTimeout);
	};

	return (
		<Component.ADashboardCard
			title={config.title}
			description={config.description}
			className={`page-notification-settings-developer ${className || ""}`}
		>
			<Form
				form={form}
				autoComplete="off"
				layout="vertical"
				onFieldsChange={handleChange}
				initialValues={notificationSettings}
				className="t-gap--top-sm"
			>
				<Form.Item label={<FormattedMessage id={config.fieldConfig.developer.label} />}>
					<div className="developer-notification--wrapper">
						<Form.Item
							name="developer"
							rules={[
								{
									type: "email",
									message: intl.formatMessage({ id: config.fieldConfig.developer.emailError }),
								},
							]}
						>
							<Input
								type="email"
								className="developer-notification--input t-gap--right-sm"
								placeholder={intl.formatMessage({ id: config.fieldConfig.developer.placeholder })}
							/>
						</Form.Item>
						<Form.Item>
							<Button type="default" onClick={() => setShowMailOptions(true)}>
								<FormattedMessage id={config.fieldConfig.developer.button} />
							</Button>
						</Form.Item>
					</div>
				</Form.Item>
			</Form>
			<DeveloperEmailOptions
				developerEmail={form.getFieldValue("developer")}
				visible={showMailOptions}
				onClose={() => setShowMailOptions(false)}
			/>
		</Component.ADashboardCard>
	);
}
