import "./style.scss";

import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { $convertFromMarkdownString } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { EditorThemeClasses } from "lexical";
import merge from "lodash.merge";

import { MentionNode } from "../../editor/nodes";
import { AutoLinkPlugin, CodeHighlightPlugin, ListMaxIndentLevelPlugin, MentionPlugin } from "../../editor/plugin";
import { markdownTheme } from "../../editor/theme";
import { TRANSFORMERS } from "../../editor/transformers/markdownTransformers";

export function MarkdownText({
	value,
	theme,
	className,
	namespace = "markdownText",
}: {
	value?: string;
	namespace?: string;
	theme?: Partial<EditorThemeClasses>;
	className?: string;
}) {
	const editorConfig = {
		namespace,
		theme: merge(structuredClone(markdownTheme), structuredClone(theme || {})),
		editable: false,
		editorState: () => value && $convertFromMarkdownString(value, TRANSFORMERS),
		// Handling of errors during update
		onError(error) {
			throw error;
		},
		nodes: [
			HeadingNode,
			MentionNode,
			ListNode,
			ListItemNode,
			QuoteNode,
			CodeNode,
			CodeHighlightNode,
			AutoLinkNode,
			LinkNode,
		],
	};

	return (
		<LexicalComposer initialConfig={editorConfig}>
			<div className={className}>
				<RichTextPlugin
					contentEditable={<ContentEditable />}
					placeholder={() => <span></span>}
					ErrorBoundary={LexicalErrorBoundary}
				/>
				<MentionPlugin />
				<ListPlugin />
				<LinkPlugin />
				<HistoryPlugin />
				<AutoLinkPlugin />
				<AutoFocusPlugin />
				<CodeHighlightPlugin />
				<ListMaxIndentLevelPlugin maxDepth={7} />
				<MarkdownShortcutPlugin transformers={TRANSFORMERS} />
			</div>
		</LexicalComposer>
	);
}
