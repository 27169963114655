import "./style.scss";

import { Button, Form, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import FieldLabel from "@/shared/components/field/default";
import { queryStringHelpers } from "@/shared/helpers";
import ShowFailedBanner from "../../../shared/components/banner/failed";
import { trpc } from "../../../trpc";
import { userState } from "../state";
import { saveUserTokens } from "../utils";
import FormLoader from "./components/FormLoader";
import { PHONE_REGEX } from "@/helpers/validators.helpers";

export default function AcceptInvite() {
	const intl = useIntl();
	const history = useHistory();
	const location = useLocation();
	const setUser = useSetRecoilState(userState);
	const createUser = trpc.user.create.useMutation();
	const queryString = queryStringHelpers.formatQueryString(location.search);
	const completeInvitedUser = trpc.user.completeInvitedUser.useMutation();
	const { isLoading, data } = trpc.user.getUserById.useQuery(
		{
			userId: queryString?.user,
		},
		{
			enabled: Boolean(queryString?.user),
		}
	);
	const isFetching = Boolean(queryString?.user) && isLoading;

	useEffect(() => {
		if (!data?.user?.initialized) return;
		if (queryString.organization_id) return history.push(`/portal/invite${location.search}`);

		history.push("/portal/login");
	}, [data]);

	const createNewUser = (body) => {
		createUser.mutate(body, {
			onSuccess: ({ token, user }) => {
				setUser(user);
				saveUserTokens({ token, user });
				history.push(`/portal/verify${location.search}`);
			},
			onError: (error) => {
				if (error?.data?.stack?.includes("A user with this email already exists")) {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "portal.sign_up.failed.user_exists",
							defaultMessage: "There already exists a user with this email address",
							description:
								"Show this error message if user account can not be created because there already exists a user with this email address",
						}),
						intl,
					});
					return;
				}

				ShowFailedBanner({
					title: intl.formatMessage({
						id: "portal.sign_up.failed.bad_request",
						defaultMessage: "There was an error while creating your account. Please try again later.",
						description: "Show error when unknown failure happened",
					}),
					intl,
				});
				return;
			},
		});
	};

	const signUpInvitedUser = (body) => {
		completeInvitedUser.mutate(
			{ ...body, email: data?.user?.email, userId: queryString?.user },
			{
				onSuccess: ({ token, user }) => {
					saveUserTokens({ token, user });
					history.push(`/portal/invite${location.search}`);
				},
				onError: (error) => {
					if (error?.data?.code === "NOT_FOUND") {
						ShowFailedBanner({
							title: intl.formatMessage({
								id: "portal.sign_up.invite_failed.not_found",
								defaultMessage: "Could not complete your invite. Please contact our support team",
								description:
									"Invited user could not be found in database, something went wrong with get the userId from the invite link",
							}),
							intl,
						});
						return;
					}

					ShowFailedBanner({
						title: intl.formatMessage({
							id: "portal.sign_up.invite_failed.bad_request",
							defaultMessage: "There was an error while completing your invite. Please try again later.",
							description:
								"Show error when unknown failure happened while completing sign up for invited user",
						}),
						intl,
					});
					return;
				},
			}
		);
	};

	const handleSubmit = (body) => {
		if (queryString?.user) return signUpInvitedUser(body);

		return createNewUser(body);
	};

	return (
		<section className="portal_body">
			<article className="portal_body-article">
				<header className="portal_body-header">
					<Typography.Title>
						<FormattedMessage id="portal.sign_up.title" defaultMessage="Welcome to Bothive" />
					</Typography.Title>
					<p>
						<FormattedMessage
							id="portal.sign_up.description"
							defaultMessage="Do you already have a account? <link>Login to my account</link>"
							values={{
								link: (content) => (
									<NavLink to="/portal/login" className="portal_body-header-link">
										{content}
									</NavLink>
								),
							}}
						/>
					</p>
				</header>
				{isFetching && <FormLoader />}
				{!isFetching && (
					<Form id="signUpForm" initialValues={data?.user} onFinish={handleSubmit} className="sign_up-form">
						<FieldLabel
							id="firstName"
							className="sign_up-form-first_name"
							label={intl.formatMessage({
								id: "portal.sign_up.firstName.label",
								defaultMessage: "First name",
							})}
						>
							<Form.Item
								name="firstName"
								className="portal_body-form-field"
								required
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id: "portal.sign_up.firstName.error_is_required",
											defaultMessage: "Your first name is required",
										}),
									},
									{
										max: 48,
										message: intl.formatMessage({
											id: "portal.sign_up.firstName.to_long",
											defaultMessage: "First name can be maximum 48 characters long",
										}),
									},
								]}
							>
								<Input id="firstName" size="large" maxLength={48} placeholder="John" />
							</Form.Item>
						</FieldLabel>
						<FieldLabel
							id="lastName"
							className="sign_up-form-last_name"
							label={intl.formatMessage({
								id: "portal.sign_up.lastName.label",
								defaultMessage: "Last name",
							})}
						>
							<Form.Item
								name="lastName"
								className="portal_body-form-field"
								required
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id: "portal.sign_up.lastName.error_is_required",
											defaultMessage: "Your last name is required",
										}),
									},
									{
										max: 48,
										message: intl.formatMessage({
											id: "portal.sign_up.lastName.to_long",
											defaultMessage: "Last name can be maximum 48 characters long",
										}),
									},
								]}
							>
								<Input id="lastName" size="large" maxLength={48} placeholder="Doe" />
							</Form.Item>
						</FieldLabel>

						<FieldLabel
							id="email"
							className="sign_up-form-email"
							label={intl.formatMessage({ id: "portal.sign_up.email.label", defaultMessage: "E-mail" })}
						>
							<Form.Item
								name="email"
								className="portal_body-form-field"
								required
								rules={[
									{
										type: "email",
										message: intl.formatMessage({
											id: "portal.sign_up.email.invalid_email",
											defaultMessage: "Hmm… that email doesn't look valid",
										}),
									},
									// Email is invalid or already taken
									{
										required: true,
										message: intl.formatMessage({
											id: "portal.sign_up.email.error_is_required",
											defaultMessage: "Enter a e-mail address",
										}),
									},
								]}
							>
								<Input
									id="email"
									type="email"
									size="large"
									placeholder="john.doe@bothive.be"
									disabled={Boolean(queryString?.user)}
								/>
							</Form.Item>
						</FieldLabel>
						<FieldLabel
							id="phone"
							className="sign_up-form-phone"
							label={intl.formatMessage({
								id: "portal.sign_up.phone.label",
								defaultMessage: "Phone number",
							})}
						>
							<Form.Item
								name="phone"
								rules={[
									{
										pattern: PHONE_REGEX,
										message: intl.formatMessage({
											id: "portal.sign_up.phone.validation",
											defaultMessage: "Please fill in a valid phone number",
											description:
												"This is shown when the user inputs a phone number with characters other than numbers or +",
										}),
									},
								]}
								className="portal_body-form-field"
							>
								<Input id="phone" type="tel" size="large" placeholder="0491234567" />
							</Form.Item>
						</FieldLabel>
						<FieldLabel
							id="password"
							className="sign_up-form-password"
							label={intl.formatMessage({
								id: "portal.sign_up.password.label",
								defaultMessage: "Password",
							})}
						>
							<Form.Item
								name="password"
								className="portal_body-form-field"
								required
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id: "portal.sign_up.password.error_is_required",
											defaultMessage: "Enter your password",
										}),
									},
									{
										min: 5,
										message: intl.formatMessage({
											id: "portal.sign_up.password.min_length",
											defaultMessage: "Your password need to have at least 5 characters",
										}),
									},
									{
										validator: async (_, value: string) => {
											if (/[A-Z]/.test(value)) return;

											const message = intl.formatMessage({
												id: "portal.sign_up.password.no_capital_case",
												defaultMessage:
													"Your password needs to contain at least 1 capital case",
											});

											return Promise.reject(new Error(message));
										},
									},
								]}
							>
								<Input.Password id="password" type="password" autoComplete="password" size="large" />
							</Form.Item>
						</FieldLabel>
						<Form.Item shouldUpdate className="sign_up-form-submit">
							{(form) => {
								const isDisabled = !!form.getFieldsError().filter(({ errors }) => errors.length).length;

								return (
									<Button
										size="large"
										type="primary"
										form="signUpForm"
										htmlType="submit"
										disabled={isDisabled}
										loading={createUser.isLoading || completeInvitedUser.isLoading}
										className="portal_body-form-field-submit_button"
									>
										<FormattedMessage id="portal.sign_up.submit" defaultMessage="Create account" />
									</Button>
								);
							}}
						</Form.Item>
					</Form>
				)}
			</article>
		</section>
	);
}
