import { typesConfig } from "../../../config";
import actionTypes from "./banner.actionTypes";

const initialState = {
	show: false,
	status: undefined,
	message: "",
	values: {},
	keyBanner: {
		show: false,
		type: undefined,
		icon: undefined,
		message: undefined,
	},
	processingBanner: {
		messages: [],
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.SHOW_CREDIT_CARD_INVALID:
		case actionTypes.SHOW_FAILED_MESSAGE:
			return {
				...state,
				show: true,
				status: typesConfig.bannerStatus.error,
				...action.data,
			};
		case actionTypes.SHOW_COPIED_TO_CLIPBOARD:
		case actionTypes.SHOW_SETTINGS_COPIED_TO_CLIPBOARD:
		case actionTypes.SHOW_SUCCESS_MESSAGE:
			return {
				...state,
				show: true,
				status: typesConfig.bannerStatus.success,
				...action.data,
			};
		case actionTypes.SHOW_INFO_MESSAGE:
			return {
				...state,
				show: true,
				status: typesConfig.bannerStatus.info,
				...action.data,
			};
		case actionTypes.SHOW_KEY_BANNER:
			return {
				...state,
				keyBanner: {
					show: true,
					...action.data,
				},
			};
		case actionTypes.HIDE_KEY_BANNER:
			return {
				...state,
				keyBanner: {
					show: false,
					type: undefined,
					icon: undefined,
					message: undefined,
				},
			};
		case actionTypes.HIDE_BANNER:
			return {
				...state,
				show: false,
				state: "",
			};
		case actionTypes.SHOW_PROCESSING_BANNER:
			return {
				...state,
				processingBanner: {
					messages: [
						...state.processingBanner.messages,
						{
							id: action.data.id,
							message: action.data.message,
							values: action.data.values,
						},
					],
				},
			};
		case actionTypes.HIDE_PROCESSING_BANNER:
			return {
				...state,
				processingBanner: {
					messages: state.processingBanner.messages.filter((item) => item.id !== action.data.id),
				},
			};
		default:
			return state;
	}
}
