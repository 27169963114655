import { Alert } from "antd";
import { useIntl } from "react-intl";

import helpers from "./helpers";
import style from "./style.module.scss";
import { ApiKeyManagerRouterOutput } from "@/trpc";
import { IApiPermission } from "@bothive_core/database";

export default function WarningBanner({
	apiKey,
	permissions,
	className,
}: {
	apiKey: ApiKeyManagerRouterOutput["getById"] | null | undefined;
	permissions: IApiPermission[];
	className?: string;
}) {
	const intl = useIntl();

	const warningMessage = helpers.checkForCredentialWarning({
		apiKey,
		permissions,
		intl,
	});

	if (!warningMessage) {
		return null;
	}

	return (
		<Alert
			message={intl.formatMessage({ id: warningMessage })}
			className={`${style.api_key_warning_banner} ${className}`}
			type="warning"
			showIcon
		/>
	);
}
