import "./style.scss";

import { Button } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { PageHeader } from "../components";
import { trpc } from "../../../../../trpc";

export default function Syncing({ onClick }: { onClick: () => void }) {
	const intl = useIntl();
	const { data: organization } = trpc.organization.getOrganization.useQuery();

	if (!organization?.permissions.inboxAccess) {
		return (
			<section className="connect_channels-body connect_channels-syncing">
				<PageHeader
					title={intl.formatMessage({
						id: "channels.connect_channel.complete.title",
						defaultMessage: "Channel connected",
					})}
					description={intl.formatMessage({
						id: "channels.connect_channel.complete.description",
						defaultMessage:
							"Your channel has been connect to your organization you can now use it send flows to your contacts",
					})}
					className="t-gap--bottom"
				/>
				<Button htmlType="submit" onClick={onClick}>
					<FormattedMessage id="channels.connect_channel.complete.close_screen" defaultMessage="Close" />
				</Button>
			</section>
		);
	}

	return (
		<section className="connect_channels-body connect_channels-syncing">
			<PageHeader
				title={intl.formatMessage({
					id: "channels.connect_channel.syncing.title",
					defaultMessage: "Channel syncing in progress 🚀",
				})}
				description={intl.formatMessage({
					id: "channels.connect_channel.syncing.description",
					defaultMessage:
						"We've initiated the synchronization of your inbox. This process might take a few minutes, but rest assured, you can continue using our platform during this synchronization.",
					description:
						"Channel is started synchronizing, depending on the users inbox size this can take minutes up to hours to completely sync. Users can close this screen we will sync in the background.",
				})}
				className="t-gap--bottom"
			/>
			<Button htmlType="submit" onClick={onClick}>
				<FormattedMessage
					id="channels.connect_channel.syncing.close_screen"
					defaultMessage="Close synchronization screen"
				/>
			</Button>
		</section>
	);
}
