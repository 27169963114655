import { filterDuplicatesByField } from "@/helpers/array.helpers";
import { ConversationRouterOutput, trpc } from "@/trpc";
import { Button, Divider, Result, Typography } from "antd";
import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { useCallback, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import ConversationListEmpty from "@/modules/inbox/ConversationView/ConversationList/ConversationListEmpty";
import { getSearchQueryParameters } from "../ContactConversationFilters";
import DayOverview from "./components/DayOverview";
import DayOverviewSkeleton from "./components/DayOverview/Skeleton";
import "./style.scss";

export type TConversationWithContactResponse = ConversationRouterOutput["allFromContact"]["data"];

interface IDateFormattedConversationList {
	[key: string]: TConversationWithContactResponse;
}

interface ContactConversationListProps {
	showFilters: boolean;
	onChangeShowFilters: () => void;
}

export default function ContactConversationList({ showFilters, onChangeShowFilters }: ContactConversationListProps) {
	const intl = useIntl();
	const routeParams = useParams<{ contactId: string }>();

	const getSearchParameters = useCallback(getSearchQueryParameters, [location.search]);
	const isSearching = location.search;

	const { data, error, isFetching, isInitialLoading, fetchNextPage } =
		trpc.inbox.conversation.allFromContact.useInfiniteQuery(
			{
				contactId: routeParams.contactId,
				...getSearchParameters(),
			},
			{
				getNextPageParam: (data) => data.cursor,
				keepPreviousData: true,
				retry: false,
			}
		);

	const groupByDate = (items: TConversationWithContactResponse | undefined, dateKey: "createdAt" | "updatedAt") =>
		items?.reduce<IDateFormattedConversationList>((formattedObject, current) => {
			const currentItemDate = structuredClone(current[dateKey]);

			if (!currentItemDate || typeof current.updatedAt === "string") return formattedObject;

			currentItemDate.setHours(0, 0, 0, 0);

			const dateString = currentItemDate.toISOString();

			formattedObject[dateString] = [...(formattedObject[dateString] || []), current];
			return formattedObject;
		}, {});

	const conversations = useMemo(() => {
		if (!data?.pages.length) return [];
		return filterDuplicatesByField({ array: data.pages.flatMap((page) => page.data), field: "_id" });
	}, [data?.pages]);

	const formattedConversations = useMemo(
		() => (isSearching ? { search: conversations } : groupByDate(conversations, "updatedAt") || {}),
		[conversations, isSearching]
	);

	const isConversationListDefined =
		(formattedConversations && !!Object.keys(formattedConversations).length && !formattedConversations.search) ||
		// Check search is not undefined
		!!formattedConversations.search?.length;

	// NOTE: Be aware this check isn't enough when extra forbidden reason are added in the backend.
	if (error?.data?.code === "FORBIDDEN") {
		return (
			<Result
				style={{ maxWidth: "var(--width-prose)", margin: "auto" }}
				status="403"
				title={intl.formatMessage({
					id: "contact.conversation_list.forbidden.organization_channel_conflict.title",
					defaultMessage: "No access!",
				})}
				subTitle={intl.formatMessage({
					id: "contact.conversation_list.forbidden.organization_channel_conflict.sub_title",
					defaultMessage:
						"You do not have access to the conversations due to privacy reasons. One or more email addresses of this contact match with connected channels within this organization. For further assistance, please contact our support department.",
				})}
			/>
		);
	}

	return (
		<>
			<section className="ccl-history-overview__stats">
				<Typography.Text>
					<FormattedMessage
						id="contact.conversation_list.results.count"
						defaultMessage="{count, plural, one {<b>#</b> conversation} other {<b>#</b> conversations}} found"
						values={{ count: data?.pages[0]?.total || 0, b: (content) => <strong>{content}</strong> }}
					/>
				</Typography.Text>
				<Divider type="vertical" style={{ background: "var(--primary-shaded)", marginRight: 0 }} />
				<Button
					onClick={() => onChangeShowFilters()}
					icon={showFilters ? <FilterOutlined /> : <FilterFilled />}
					size="small"
					type="link"
				>
					{" "}
					{showFilters ? (
						<FormattedMessage
							id="contact.conversation_list.filters.btn.hide"
							defaultMessage="Hide filters"
							description="Button to hide filters for the current view"
						/>
					) : (
						<FormattedMessage
							id="contact.conversation_list.filters.btn.show"
							defaultMessage="Show filters"
							description="Button to show filters for the current view"
						/>
					)}
				</Button>
			</section>
			<section className="ccl-list-container" id="conversationListContainer">
				{isInitialLoading && (
					<ul className="ccl-list">
						<DayOverviewSkeleton />
					</ul>
				)}
				{!isInitialLoading && isConversationListDefined && (
					<InfiniteScroll
						className="ccl-list"
						next={fetchNextPage}
						scrollThreshold={0.6}
						dataLength={conversations?.length || 0}
						hasMore={(conversations?.length || 0) < (data?.pages[0]?.total || 0)}
						loader={isFetching && <DayOverviewSkeleton />}
						endMessage={
							conversations &&
							conversations.length >= (data?.pages[0]?.total || 0) &&
							conversations.length !== 0 && (
								<Divider plain>
									<FormattedMessage
										id="contact.conversation_list.all_conversations.fetched"
										defaultMessage="All conversations fetched 📭"
										description="Message user has reached the end off the conversations list"
									/>
								</Divider>
							)
						}
						scrollableTarget="conversationListContainer"
					>
						{formattedConversations &&
							!isInitialLoading &&
							!!Object.keys(formattedConversations).length &&
							Object.keys(formattedConversations).map((dateString, dateLabelIndex) => (
								<DayOverview
									key={dateLabelIndex}
									groupKey={
										dateString === "search"
											? intl.formatMessage({
													id: "contact.conversation_list.separator.search",
													defaultMessage: "Search mode (most relevant first)",
													description:
														"Text user sees in as title before all conversations, when not searching this will be like 'today' or 'tomorrow'. When searching we just say it's sorted on relevance.",
											  })
											: dateString
									}
									conversations={formattedConversations[dateString]}
								/>
							))}
					</InfiniteScroll>
				)}
				{!isInitialLoading && !isConversationListDefined && (
					<ConversationListEmpty
						className="ccl-list-container-empty"
						description={
							<FormattedMessage
								id="contact.conversation_list.empty_list.label"
								defaultMessage="No conversations found"
							/>
						}
					/>
				)}
			</section>
		</>
	);
}
