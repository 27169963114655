import type { IContact } from "@bothive_core/database";
import { Button, Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { trpc } from "../../../../../../../trpc";
import ShowSuccessBanner from "../../../../../../../shared/components/banner/success";
import ShowFailedBanner from "../../../../../../../shared/components/banner/failed";
import ContactLinkingForm, { IContactLinkingSubmit } from "./modalComponents/ContactLinkingForm";
import { useState } from "react";

interface ICreateModal {
	contact: IContact;
	isOpen: boolean;
	onClose: () => void;
}

export default function CreateModal({ contact, isOpen, onClose }: ICreateModal) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const [isSaving, setIsSaving] = useState(false);
	const createContactLinkMutation = trpc.contactLinking.create.useMutation();

	const handleSubmit = ({ type, linkedContactId, flowProxy, data }: IContactLinkingSubmit) => {
		setIsSaving(true);
		createContactLinkMutation.mutate(
			{
				contactId: contact._id,
				linkedContactId,
				flowProxy,
				type,
				data,
			},
			{
				onSuccess: () => {
					onClose();
					setIsSaving(false);
					trpcUtils.contactLinking.getAllContactLinksByContactId.invalidate();
					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.SUCCESS",
							defaultMessage: "Contact successfully linked",
						}),
					});
				},
				onError: () => {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.FAILED",
							defaultMessage: "Failed to link contact",
						}),
					});
				},
			}
		);
	};

	return (
		<>
			<Modal
				width={700}
				open={isOpen}
				destroyOnClose
				transitionName="" // to disable animation
				title={intl.formatMessage({ id: "CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.TITLE" })}
				onCancel={onClose}
				footer={[
					<Button key="submit" type="primary" form="linkContactForm" htmlType="submit" loading={isSaving}>
						<FormattedMessage id="CONTACTS.DETAIL.ATTRIBUTES.LINKING.MODAL.SAVE" />
					</Button>,
				]}
			>
				<ContactLinkingForm contact={contact} onSubmit={handleSubmit} />
			</Modal>
		</>
	);
}
