import * as AButtons from "./atoms.buttons";
import * as ACheckbox from "./atoms.checkbox";
import * as AComponent from "./atoms.component";
import * as ADropdown from "./atoms.dropdown";
import * as AFallback from "./atoms.fallback";
import * as AFormElement from "./atoms.form-element";
import * as AImage from "./atoms.image";
import * as AInput from "./atoms.input";
import * as ALink from "./atoms.link";
import * as AListItem from "./atoms.list-item";
import * as ARadioButton from "./atoms.radio-button";
import * as AToggle from "./atoms.toggle";
import * as ATooltip from "./atoms.tooltip";
import * as AVideo from "./atoms.video";

export const Buttons = AButtons;
export const Component = AComponent;
export const Checkbox = ACheckbox;
export const Dropdown = ADropdown;
export const Fallback = AFallback;
export const FormElement = AFormElement;
export const Image = AImage;
export const Input = AInput;
export const Link = ALink;
export const ListItem = AListItem;
export const RadioButton = ARadioButton;
export const Toggle = AToggle;
export const Tooltip = ATooltip;
export const Video = AVideo;
