import "./style.scss";

import DashboardCard from "@/shared/components/card/dashboard";
import { PlusOutlined } from "@ant-design/icons";
import { IOrganization } from "@bothive_core/database";
import { Button, Form, Skeleton } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import { trpc } from "@/trpc";

import SocialsRow from "./row";

export default function SocialSettings({
	allowEditing,
	organization,
	isLoading,
	className,
}: {
	allowEditing: boolean;
	organization: IOrganization | undefined;
	isLoading: boolean;
	className?: string;
}) {
	const intl = useIntl();
	const [socialForm] = Form.useForm();
	const trpcUtils = trpc.useUtils();
	const updateSocialsMutation = trpc.organization.updateSocials.useMutation();

	const socialMedia = organization?.socialMedia
		? Object.entries(organization?.socialMedia).map((value) => ({ platform: value[0], url: value[1] }))
		: [];

	const updateSocials = (values) => {
		const payload = Object.fromEntries(values.socialMedia.map(({ platform, url }) => [platform, url]));

		updateSocialsMutation.mutate(payload, {
			onSuccess: () => {
				trpcUtils.organization.getOrganization.invalidate();
				ShowSuccessBanner({
					title: intl.formatMessage({
						id: "SETTINGS.ORGANIZATION.SOCIALS.UPDATE.SUCCESS",
						defaultMessage: "Socials have been successfully saved.",
					}),
				});
			},
			onError: () => {
				ShowFailedBanner({
					title: intl.formatMessage({
						id: "SETTINGS.ORGANIZATION.SOCIALS.UPDATE.FAILED",
						defaultMessage: "The changes couldn't be saved. Please try again later",
					}),
				});
			},
		});
	};

	function generateForm() {
		if (
			(organization && !organization?.socialMedia && !allowEditing) ||
			(organization &&
				organization?.socialMedia &&
				Object.keys(organization.socialMedia).length === 0 &&
				!allowEditing)
		) {
			return (
				<p className="location-fallback">
					<FormattedMessage
						id="organization_settings.general.socials.missing_socials_permissions"
						defaultMessage="Your organization does not have any social media link(s) at this time. Please request someone with the necessary permissions to add them."
					/>
				</p>
			);
		}

		return (
			<Skeleton active loading={isLoading} className="t-gap--top">
				<Form
					form={socialForm}
					id="socials"
					name="socials"
					layout="horizontal"
					autoComplete="off"
					disabled={!allowEditing}
					onFinish={updateSocials}
					initialValues={{ socialMedia }}
					className="settings-organization-social_settings t-gap--top"
				>
					<Form.List name="socialMedia">
						{(fields, { add, remove }) => (
							<>
								{fields.map((field) => (
									<SocialsRow
										key={field.key}
										field={field}
										form={socialForm}
										onRemove={remove}
										isEnabled={allowEditing}
									/>
								))}
								{allowEditing && (
									<Form.Item>
										<Button type="text" size="large" onClick={() => add()} icon={<PlusOutlined />}>
											<span>
												<FormattedMessage
													id="settings.organization.socials.add_field"
													defaultMessage="Add field"
												/>
											</span>
										</Button>
									</Form.Item>
								)}
							</>
						)}
					</Form.List>

					{allowEditing && (
						<Form.Item shouldUpdate className="settings-organization-social_settings-submit">
							{() => {
								const isDisabled =
									!socialForm.isFieldsTouched(true) ||
									!socialForm.getFieldsError().filter(({ errors }) => errors.length).length;

								return (
									<Button
										key="submit"
										type="primary"
										form="socials"
										htmlType="submit"
										disabled={!isDisabled}
										loading={updateSocialsMutation.isLoading}
									>
										<FormattedMessage
											id="settings.organization.socials.submit"
											defaultMessage="Save"
										/>
									</Button>
								);
							}}
						</Form.Item>
					)}
				</Form>
			</Skeleton>
		);
	}

	return (
		<DashboardCard className={className} title="ORGANIZATION_SETTINGS.GENERAL.SOCIAL_MEDIA.TITLE">
			{generateForm()}
		</DashboardCard>
	);
}
