import settingsConfig from "@/modules/settings/config";

export default {
	title: "ORGANIZATION_SETTINGS.OPENING_HOURS.BREADCRUMB.TITLE",
	tabTitle: "ORGANIZATION_SETTINGS.OPENING_HOURS.BREADCRUMB.TITLE",
	breadcrumbs: ({ team }) => [
		{
			to: settingsConfig.routes.root.replace(":team", team),
			name: "settings.advanced.breadcrumb.settings",
		},
		{
			to: settingsConfig.routes.organization.openingHours.replace(":team", team),
			name: "ORGANIZATION_SETTINGS.OPENING_HOURS.BREADCRUMB.TITLE",
		},
	],
};
