import { Form, Input, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { Dispatch, SetStateAction } from "react";
import Filters from "../../components/Filters";

export default function ContactOverviewHeader({
	contactAmount,
	handleSearch,
	setContactType,
	labelId,
	setLabelId,
	setAccountManagerId,
	setShowDeleted,
	showDeleted,
}: {
	contactAmount: number;
	handleSearch: (value: string) => void;
	setContactType: Dispatch<SetStateAction<string | undefined>>;
	labelId: string;
	setLabelId: Dispatch<SetStateAction<string | undefined>>;
	setAccountManagerId: Dispatch<SetStateAction<string | undefined>>;
	showDeleted: boolean;
	setShowDeleted: Dispatch<SetStateAction<boolean>>;
}) {
	const intl = useIntl();
	const [form] = Form.useForm();

	return (
		<header className="reports-header t-gap--top">
			<Form layout="vertical" form={form}>
				<div style={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
					<Form.Item
						className="t-gap--right"
						label={intl.formatMessage({ id: "CONTACT.OVERVIEW.SEARCH.TITLE" })}
						name="search"
					>
						<Input
							size="large"
							placeholder={intl.formatMessage({
								id: "CONTACT.OVERVIEW.SEARCH.PLACEHOLDER",
							})}
							onChange={(input) => handleSearch(input.target.value)}
						/>
					</Form.Item>
					<Form.Item
						label={intl.formatMessage({
							id: "CONTACT.OVERVIEW.SHOW_DELETED.TITLE",
							defaultMessage: "Show deleted contacts",
						})}
						name="showDeleted"
					>
						<Switch checked={showDeleted} onChange={(value) => setShowDeleted(value)} />
					</Form.Item>
				</div>
			</Form>
			<Filters
				labelId={labelId}
				setAccountManagerId={setAccountManagerId}
				setContactType={setContactType}
				setLabelId={setLabelId}
			/>
			<div className="reports-header-footer">
				<p className="reports-header-result">
					<FormattedMessage
						id="CONTACT.FILTER.DETAIL.TOTAL_RESULTS"
						values={{
							value: contactAmount || 0,
						}}
					/>
				</p>
			</div>
		</header>
	);
}
