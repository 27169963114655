function isNetworkOnline() {
	return navigator.onLine;
}

export default {
	watchNetworkStatus(callback) {
		function updateStatus() {
			callback(isNetworkOnline());
		}

		window.addEventListener("online", updateStatus);
		window.addEventListener("offline", updateStatus);
	},
	isNetworkOnline: isNetworkOnline,
	isCssVarSupported() {
		return (
			(window.CSS && window.CSS.supports && window.CSS.supports("--css-vars", 0)) ||
			navigator.userAgent.search("Safari") !== -1
		);
	},
	isBrowserSupported() {
		return !(/*@cc_on!@*/ (false || document.documentMode || (!document.documentMode && window.StyleMedia)));
	},
	isPageVisible() {
		if (typeof document.webkitHidden !== "undefined") {
			return !document.webkitHidden;
		}

		if (typeof document.msHidden !== "undefined") {
			return !document.msHidden;
		}

		return !document.hidden;
	},
	isMac() {
		return navigator.userAgent.toLowerCase().includes("mac");
	},
};
