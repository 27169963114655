import { Form, Button } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import DashboardCard from "@/shared/components/card/dashboard";
import FieldLabel from "@/shared/components/field/default";
import "../style.scss";
import ColorPicker from "@/shared/components/colorPicker";
import { stringHelpers } from "@/shared/helpers";

export interface IColorSchemeValue {
	brandColor: string;
	accentColor: string;
	textColor: string;
}
interface IColorSchemeSettings {
	isSaving: boolean;
	allowEditing: boolean;
	branding: IColorSchemeValue;
	onFinish: (value: IColorSchemeValue) => void;
}

export default function ColorSchemeSettings({ allowEditing, branding, isSaving, onFinish }: IColorSchemeSettings) {
	const intl = useIntl();
	const [form] = Form.useForm();

	return (
		<DashboardCard
			title={intl.formatMessage({
				id: "organization_settings.branding.color.title",
				defaultMessage: "Colorscheme",
			})}
			description={
				allowEditing &&
				intl.formatMessage({
					id: "organization_settings.branding.color.description",
					defaultMessage:
						"Change the colors for outgoing communications to match your office's corporate identity.",
				})
			}
		>
			<Form
				form={form}
				id="editColors"
				name="editColors"
				layout="horizontal"
				className="branding-color-form t-gap--top"
				disabled={!allowEditing}
				initialValues={branding}
				autoComplete="off"
				onFinish={onFinish}
			>
				<FieldLabel
					label={intl.formatMessage({
						id: "organization_settings.branding.color.form.brand_color.label",
						defaultMessage: "Brand color",
					})}
				>
					<Form.Item
						className="branding-color-form__form-item--color-picker"
						name="brandColor"
						required
						rules={[
							{
								validator: async (_, value) => {
									if (!value?.length || value === "#") {
										const message = intl.formatMessage({
											id: "organization.settings.branding.colors.brand_color.is_required",
											defaultMessage: "Brand color is required",
										});

										return Promise.reject(new Error(message));
									}

									if (!stringHelpers.isValidHex(value)) {
										const message = intl.formatMessage({
											id: "organization.settings.branding.colors.brand_color.invalid_color",
											defaultMessage: "Brand color is not a valid hex color",
										});

										return Promise.reject(new Error(message));
									}
								},
							},
						]}
					>
						<ColorPicker placeholder="#2082C0" />
					</Form.Item>
				</FieldLabel>
				<FieldLabel
					label={intl.formatMessage({
						id: "organization_settings.branding.color.form.accent_color.label",
						defaultMessage: "Accent color",
					})}
				>
					<Form.Item
						className="branding-color-form__form-item--color-picker"
						name="accentColor"
						required
						rules={[
							{
								validator: async (_, value) => {
									if (!value?.length || value === "#") {
										const message = intl.formatMessage({
											id: "organization.settings.branding.colors.accent_color.is_required",
											defaultMessage: "Accent color is required",
										});

										return Promise.reject(new Error(message));
									}

									if (!stringHelpers.isValidHex(value)) {
										const message = intl.formatMessage({
											id: "organization.settings.branding.colors.accent_color.invalid_color",
											defaultMessage: "Accent color is not a valid hex color",
										});

										return Promise.reject(new Error(message));
									}
								},
							},
						]}
					>
						<ColorPicker placeholder="#50ABDE" />
					</Form.Item>
				</FieldLabel>
				<FieldLabel
					label={intl.formatMessage({
						id: "organization_settings.branding.color.form.text_color.label",
						defaultMessage: "Text color",
					})}
				>
					<Form.Item
						className="branding-color-form__form-item--color-picker"
						name="textColor"
						required
						rules={[
							{
								validator: async (_, value) => {
									if (!value?.length || value === "#") {
										const message = intl.formatMessage({
											id: "organization.settings.branding.colors.text_color.is_required",
											defaultMessage: "Text color is required",
										});

										return Promise.reject(new Error(message));
									}

									if (!stringHelpers.isValidHex(value)) {
										const message = intl.formatMessage({
											id: "organization.settings.branding.colors.text_color.invalid_color",
											defaultMessage: "Text color is not a valid hex color",
										});

										return Promise.reject(new Error(message));
									}
								},
							},
						]}
					>
						<ColorPicker placeholder="#002410" />
					</Form.Item>
				</FieldLabel>
				{allowEditing && (
					<Button
						key="submit"
						type="primary"
						htmlType="submit"
						form="editColors"
						loading={isSaving}
						size="large"
						className="saveButton"
					>
						<FormattedMessage id="organization_settings.branding.color.form.submit" defaultMessage="Save" />
					</Button>
				)}
			</Form>
		</DashboardCard>
	);
}
