import isEqual from "lodash.isequal";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import { actions as dashboardActions } from "@/store/dashboard";
import { trpc } from "@/trpc";
import { Layout } from "../../../components/templates";
import ColorSettings, { IColorSchemeValue } from "./components/ColorSchemeSettings";
import LogoIconSettings, { ILogoIconSettingsValue } from "./components/LogoIconSettings";
import "./style.scss";

export default function BrandingPage() {
	const intl = useIntl();
	const dispatch = useDispatch();
	const routeParameters = useParams<{ team: string }>();
	const { data: organization, isLoading } = trpc.organization.getOrganization.useQuery();

	// MUTATIONS
	const MUTATION_RESPONSE_HANDLERS = {
		onSuccess: () => {
			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "organization_settings.branding.save.success",
					defaultMessage: "Branding settings have been successfully saved.",
				}),
			});
		},
		onError: () => {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "organization_settings.branding.save.failed",
					defaultMessage: "The changes couldn't be saved. Please try again later",
				}),
			});
		},
	};
	const updateLogoIconMutation = trpc.organization.updateBranding.useMutation(MUTATION_RESPONSE_HANDLERS);
	const updateBrandColorsMutation = trpc.organization.updateBranding.useMutation(MUTATION_RESPONSE_HANDLERS);

	const allowEditing = useSelector((state) => state.dashboard.permissions.userPermissions.editSettings, isEqual);

	useEffect(() => {
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					title: intl.formatMessage({
						id: "organization_settings.branding.title",
						defaultMessage: "Branding settings",
					}),
					tabTitle: intl.formatMessage({
						id: "organization_settings.branding.tab_title",
						defaultMessage: "Branding settings",
					}),
					breadcrumbs: [
						{
							to: `/${routeParameters.team}/settings`,
							name: intl.formatMessage({
								id: "organization_settings.branding.breadcrumb.settings",
								defaultMessage: "Settings",
							}),
						},
						{
							to: `/${routeParameters.team}/settings/branding`,
							name: intl.formatMessage({
								id: "organization_settings.branding.breadcrumb.page_title",
								defaultMessage: "Branding settings",
							}),
						},
					],
				},
			})
		);
	}, []);

	if (!organization?.branding || isLoading) return <Layout.TLoadScreen />;

	return (
		<section className="settings-branding">
			<LogoIconSettings
				allowEditing={allowEditing}
				isSaving={updateLogoIconMutation.isLoading}
				branding={organization.branding}
				onFinish={(value) => {
					if (!organization) return;
					updateLogoIconMutation.mutate({ ...organization.branding, ...value });
				}}
			/>
			<ColorSettings
				allowEditing={allowEditing}
				isSaving={updateBrandColorsMutation.isLoading}
				branding={organization.branding}
				onFinish={(value) => {
					if (!organization) return;
					updateBrandColorsMutation.mutate({ ...organization.branding, ...value });
				}}
			/>
		</section>
	);
}
