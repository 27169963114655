import "./style.scss";
import { Result, Card } from "antd";
import { useIntl } from "react-intl";

import LogoLoader from "@/shared/components/loader/logoLoader";
import { logo } from "../../../assets/images";
import { trpc } from "@/trpc";
import { Redirect, useParams } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { integrationLogoMap } from "@/shared/config/integration.config";

export default function IntegrationContactSplashScreen() {
	const params = useParams<{ team: string; integrationId: string; externalId: string }>();

	//TODO: change in database and remove this line of code
	const tempIntegrationIdMap = { admin_pulse: "5cdc7149e0f7a731c8b449ca" };
	const integrationId = tempIntegrationIdMap[params.integrationId] || params.integrationId;

	const contactQuery = trpc.contact.getContactByExternalId.useQuery({
		externalId: params.externalId,
		integrationId: params.integrationId,
	});
	const organizationQuery = trpc.organization.getOrganizationById.useQuery(
		{
			organizationId: contactQuery.data?.organizationId as string,
		},
		{ enabled: Boolean(contactQuery.data?.organizationId) }
	);
	const integrationQuery = trpc.integration.getIntegrationById.useQuery({ query: { integrationId } });

	if (organizationQuery.data && contactQuery.data) {
		return <Redirect to={`/${organizationQuery.data.slug}/contact/id/${contactQuery.data._id}`} />;
	}

	return (
		<Card
			title={
				<section className="integration-splash-card__header">
					<img className="integration-splash-card__header-img" src={logo.logo} alt="logo" />
					<CloseOutlined />
					<img
						className="integration-splash-card__header-img"
						src={integrationLogoMap.get(integrationId)}
						alt="integration logo"
					/>
				</section>
			}
			className="integration-splash-card"
		>
			<main className="integration-splash-card__body">
				{(contactQuery.isLoading || organizationQuery.isLoading) && <Loading />}
				{contactQuery.isError && !organizationQuery.isLoading && (
					<Forbidden integrationName={integrationQuery.data?.name || ""} />
				)}
			</main>
		</Card>
	);
}

function Loading() {
	const intl = useIntl();

	return (
		<Result
			icon={<LogoLoader style={{ margin: "auto" }} />}
			title={intl.formatMessage({
				id: "contact.integration.status.loading.title",
				defaultMessage: "Fetching matching contact...",
				description: "Text shown when we are loading the details about a specific integration contact",
			})}
		/>
	);
}

function Forbidden({ integrationName }: { integrationName: string }) {
	const intl = useIntl();

	return (
		<Result
			status="403"
			title={intl.formatMessage(
				{
					id: "contact.integration.status.forbidden.title",
					defaultMessage: "You are not allowed to see this {integrationName} contact",
					description:
						"Text shown when the user has no access to the contact with the specified integrationId",
				},
				{ integrationName }
			)}
		/>
	);
}
