import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable } from "recoil";

import { flowState } from "@/flows";
import ErrorState from "@/flows/common/components/error";
import ConfigurationFallback from "@/flows/flow_detail/configuration/fallback";
import FlowConfigurationFormGenerator from "@/flows/flow_detail/configuration/form_generator";
import helpers from "@/flows/flow_detail/configuration/helpers";
import { flowConfiguration } from "@/flows/flow_detail/configuration/state";
import style from "@/flows/flow_detail/configuration/style.module.scss";
import { updateFlow } from "@/flows/flow_detail/settings/flow/query";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import Collapse, { Panel } from "@/shared/components/collapse";
import { useHasFlowEditPermissions } from "@/shared/hooks";

export default function ReportConfiguration() {
	const [fieldData, setFieldData] = useState([]);
	const [isUpdating, setIsUpdating] = useState(false);
	const { flowId } = useParams();
	const intl = useIntl();
	const [form] = Form.useForm();
	const hasEditPermissions = useHasFlowEditPermissions();
	const [flow, setFlow] = useRecoilState(flowState({ id: flowId }));
	const configuration = useRecoilValueLoadable(flowConfiguration({ templateId: flow.templateId }));
	const reloadConfiguration = useRecoilRefresher_UNSTABLE(flowConfiguration({ templateId: flow.templateId }));

	if (configuration?.state === "hasError") {
		return (
			<ErrorState
				title={intl.formatMessage({ id: "FLOW.REPORTS.CONFIGURATION.FETCH_FAILED.TITLE" })}
				reloadButtonText={intl.formatMessage({ id: "FLOW.REPORTS.CONFIGURATION.FETCH_FAILED.BUTTON" })}
				onReloadClick={() => reloadConfiguration()}
			/>
		);
	}

	useEffect(() => {
		setFieldData(configuration?.contents?.sections);
	}, [configuration]);

	const handleSubmit = async (configuration) => {
		try {
			setIsUpdating(true);

			const result = await updateFlow({
				id: flowId,
				payload: {
					configuration: {
						...flow.configuration,
						...configuration,
					},
				},
			});

			if (!result?.success) throw new Error();

			setIsUpdating(false);

			setFlow({
				...flow,
				configuration: {
					...flow.configuration,
					...configuration,
				},
			});

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOW.REPORTS.CONFIGURATION.UPDATE.SUCCESS.TITLE",
					defaultMessage: "Flow configuration updated",
				}),
			});
		} catch (error) {
			setIsUpdating(false);

			return ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOW.REPORTS.CONFIGURATION.UPDATE.FAILED.TITLE",
					defaultMessage: "Flow configuration failed to update",
				}),
				description: intl.formatMessage({
					id: "FLOW.REPORTS.CONFIGURATION.UPDATE.FAILED.DESCRIPTION",
					defaultMessage: "Please try again later or contact customer support",
				}),
			});
		}
	};

	const generateForms = () => {
		if (configuration.state === "loading") return <Panel isLoading />;
		if (!fieldData?.length) return <ConfigurationFallback />;

		return fieldData.map((section) => {
			const defaultValues = section.fields.reduce(
				(prev, field) => ({
					...prev,
					[field.id]: field.defaultValue[0],
				}),
				{}
			);

			const handleFormChange = () => {
				helpers.setDefaultFieldValues({
					rules: section?.configuration?.setDefaultValue,
					form,
				});

				helpers.hideOrShowFields({
					rules: section?.configuration?.hideOrShowFields,
					form,
					section,
					setFieldData,
				});
			};

			return (
				<Panel {...section} key={section.id} title={section.title[intl.locale]}>
					{section.description && <p>{section.description[intl.locale]}</p>}
					<Form
						form={form}
						layout="vertical"
						requiredMark="optional"
						className={style.configuration_form}
						onFieldsChange={(_, fields) => handleFormChange(fields)}
						initialValues={{ ...defaultValues, ...flow.configuration }}
						onChange={handleFormChange}
						onFinish={handleSubmit}
					>
						<FlowConfigurationFormGenerator
							fields={section.fields}
							language={intl.locale}
							disabled={!hasEditPermissions}
						/>
						<footer className={style.form_footer}>
							<Button type="primary" htmlType="submit" loading={isUpdating}>
								<FormattedMessage id="FLOW_DETAIL.CONFIGURATION.FORM.SUBMIT" />
							</Button>
						</footer>
					</Form>
				</Panel>
			);
		});
	};

	return (
		<main className={style.form_section}>
			<Collapse expandIconPosition="end">{generateForms()}</Collapse>
		</main>
	);
}
