import { FormInstance } from "antd";
import { IntlShape } from "react-intl";
import { stringHelpers } from "@bothive/helpers";
import { IAttachment, IFile, IRecipient, RecipientType, SubjectAbbreviation } from "@bothive_core/database";

import { maxRecipientsAmount } from "./config";
import { AttachmentFile } from "./components/footer";

export const TEMP_ID_PREFIX = "temp_id";

export function isTempId(id: string) {
	return id.includes(TEMP_ID_PREFIX);
}

export function emailArrValidator(intl: IntlShape) {
	return {
		validator: (_: any, value: string[]) => {
			const emailRegex = RegExp(/(.+)@(.+){2,}\.(.+){2,}/);
			try {
				value?.forEach((address) => {
					if (!emailRegex.test(address)) {
						throw intl.formatMessage(
							{
								id: "inbox.compose.fields.email_validation.error",
								defaultMessage: "{address} is no valid e-mail address.",
								description: "Generic error that is used on multiple different email fields",
							},
							{ address }
						);
					}
				});
				return Promise.resolve();
			} catch (error: any) {
				return Promise.reject(new Error(error));
			}
		},
	};
}

export function maxRecipientsValidator(form: FormInstance, intl: IntlShape) {
	return {
		validator: () => {
			const recipientsCapacity =
				(maxRecipientsAmount || 100) -
				(form.getFieldValue("to")?.length || 0) -
				(form.getFieldValue("cc")?.length || 0) -
				(form.getFieldValue("bcc")?.length || 0);

			if (recipientsCapacity < 0) {
				return Promise.reject(
					new Error(
						intl.formatMessage(
							{
								id: "inbox.compose.fields.recipients_validation.max_error",
								defaultMessage: "Max {max} recipients possible.",
								description: "Generic error that is used on multiple different recipient fields",
							},
							{ max: maxRecipientsAmount || 100 }
						)
					)
				);
			}

			return Promise.resolve();
		},
	};
}

export function formHasErrors(form: FormInstance) {
	let hasErrors = false;

	form.getFieldsError()?.forEach((field) => {
		if (field.errors.length) hasErrors = true;
	});

	if (stringHelpers.isEmptyString(form.getFieldValue("from"))) {
		hasErrors = true;
	}

	return hasErrors;
}

export function generateSubject(subject: string, prefix: SubjectAbbreviation) {
	return subject.toLowerCase().startsWith(prefix.toLocaleLowerCase()) ? subject : `${prefix} ${subject}`;
}

export function groupRecipientsAddress(recipients: IRecipient[]) {
	const groups = recipients.reduce<Partial<Record<RecipientType, string[]>>>(
		(prev, curr) => ({
			...prev,
			[curr.type]: [...(prev[curr.type] || []), curr.address],
		}),
		{}
	);

	return {
		...groups,
		from: groups?.from?.[0],
		replyTo: groups?.replyTo?.[0],
	};
}

export const remapFilesToEmailAttachments = (files: IFile[] | undefined) => {
	if (!files) return undefined;

	return files.map((file) => {
		const attachment: AttachmentFile = {
			fileId: file._id,
			isInline: false,
			cid: file._id,
			file,
		};

		return attachment;
	});
};
