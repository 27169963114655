export enum ECrmType {
	other = "0",
	prospect = "2",
	customer = "3",
	ownCompany = "4",
}
export enum EType {
	company = "0",
	soleProprietor = "1",
	person = "2",
}
