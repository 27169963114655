import React from "react";

import { ListItem } from "../../atoms";

export default function MAsideLinks({ IsFolded, items, className, onFocus, onBlur }) {
	return items.map((section, index) => (
		<section key={`asideSection-${index}`} className="m-aside--section">
			<ul className={`m-list m-list--vertical ${className}`}>
				{section.map((item) => (
					<li
						key={item.id}
						onFocus={(event) => onFocus({ item, event })}
						onMouseOver={(event) => onFocus({ item, event })}
						onBlur={(event) => onBlur({ item, event })}
						onMouseLeave={(event) => onBlur({ item, event })}
						className="m-list--item"
					>
						<ListItem.AListItemAside {...item} IsFolded={IsFolded} />
					</li>
				))}
			</ul>
		</section>
	));
}

MAsideLinks.defaultProps = {
	IsFolded: false,
	className: "",
	onFocus: () => undefined,
	onBlur: () => undefined,
};
