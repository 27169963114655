import { CheckOutlined } from "@ant-design/icons";
import { Button, Form, notification, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { updateReportInList } from "@/flows/flow_detail/reports/state";
import { removeReportFromList } from "@/flows/flow_detail/reports/state";
import { useStatusTypes } from "@/flows/flow_detail/status_types/hook";
import { reportState } from "@/flows/report_detail/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import SelectLoading from "@/shared/components/select/loading";

import query from "./query";
import style from "./style.module.scss";
import SaveToContactModal from "./Automation/SaveToContact/Modal";
import { trpc } from "@/trpc";
import type { IStatusType } from "@bothive_core/database";
import storageHelpers from "@/helpers/storage.helpers";

export default function StatusSection({ className }) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [statusTypes] = useStatusTypes();
	const updateReportList = useSetRecoilState(updateReportInList);
	const [isSaveToContactOpen, setSaveToContactOpen] = useState(false);
	const removeDoneReportFromList = useSetRecoilState(removeReportFromList);
	const { flowId, reportId } = useParams<{ flowId: string; reportId: string }>();

	const setReport = useSetRecoilState(reportState({ flowId, reportId }));
	const report = useRecoilValueLoadable(reportState({ flowId, reportId }));
	const { data: flowTemplate } = trpc.flowTemplate.getByFlowId.useQuery({ flowId });

	const isReportIsCompleted = useMemo(
		() => statusTypes?.contents.find((status) => status?._id === report?.contents?.status)?.isDone,
		[statusTypes, report]
	);

	const handleUpdate = async (statusId) => {
		try {
			const selectedStatus = statusTypes?.contents.find((status) => status._id === statusId) as IStatusType;

			setReport({ ...report.contents, status: statusId });

			await query.updateReportStatus({ reportId, flowId, statusId });

			if (selectedStatus?.isDone) {
				removeDoneReportFromList(reportId);
				handleSaveToContact();

				return;
			}

			updateReportList({ reportId, payload: { status: selectedStatus } });
		} catch (error) {
			console.error("Failed to update report status", error);
			setReport(report.contents);
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.BANNER.FAILED",
					defaultMessage: "Status type could not be changed. Please try again later.",
				}),
			});
		}
	};

	const seedContactMutation = trpc.report.saveReportToContact.useMutation({
		onError: () => {
			notification.error({
				message: intl.formatMessage({ id: "REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.ERROR.MESSAGE" }),
				placement: "bottomRight",
			});
		},
		onSuccess: () => {
			notification.success({
				message: intl.formatMessage({ id: "REPORT_DETAIL_ASIDE.SYNC_TO_CONTACT.SUCCESS.MESSAGE" }),
				placement: "bottomRight",
			});
		},
	});

	const handleSaveToContact = () => {
		if (!report.contents || !flowTemplate?.settings?.canSaveToContact) return;
		if (!["IN_PROGRESS", "COMPLETED"].includes(report.contents?.progress)) return;

		const permissions =
			storageHelpers.getJson<{ [flowId: string]: "YES" | "NO" }>({
				key: "PERMISSIONS_SAVE_REPORT_TO_CONTACT",
			}) || {};

		if (!permissions[flowId]) return setSaveToContactOpen(true);
		if (permissions[flowId] === "NO") return;

		seedContactMutation.mutateAsync({ reportId });
	};

	const markReportAsCompleted = async () => {
		const isDoneStatusType = statusTypes?.contents?.find((item) => item?.isDone === true);

		await handleUpdate(isDoneStatusType?._id);
	};

	useEffect(() => {
		if (!report.contents) return;

		form.setFieldsValue({ status: report.contents?.status || "" });
	}, [report.contents]);

	return (
		<>
			<Space direction="vertical" size="large" className={`${style.status_section} ${className}`}>
				{isReportIsCompleted ? (
					<Button icon={<CheckOutlined className="t-gap--right-xs" />} className={style.completed_button}>
						<FormattedMessage id="FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.MARKED_AS_COMPLETED" />
					</Button>
				) : (
					<Button
						type="primary"
						onClick={() => markReportAsCompleted()}
						className={style.mark_as_completed_button}
					>
						<FormattedMessage id="FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.MARK_AS_COMPLETED" />
					</Button>
				)}
				<Form layout="vertical" form={form}>
					<Form.Item
						label={intl.formatMessage({ id: "FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.LABEL" })}
						name="status"
					>
						<SelectLoading
							isLoading={!statusTypes || statusTypes?.state === "loading"}
							placeholder="FLOWS.REPORT_DETAIL.META_ASIDE.STATUS.PLACEHOLDER"
							onChange={handleUpdate}
							allowClear={false}
							showSearch
						>
							{statusTypes?.contents?.map((item) => (
								<Select.Option
									key={item._id}
									value={item._id}
									data-is-done={item.isDone}
									className="filter-dropdown-item"
								>
									<p>{item.name}</p>
								</Select.Option>
							))}
						</SelectLoading>
					</Form.Item>
				</Form>
			</Space>
			<SaveToContactModal
				flowId={flowId}
				isOpen={isSaveToContactOpen}
				setIsOpen={setSaveToContactOpen}
				onHandleSaveToContact={() => seedContactMutation.mutateAsync({ reportId })}
			/>
		</>
	);
}

StatusSection.defaultProps = {
	className: "",
};
