import actionTypes from "./ui.actionTypes";

const initialState = {
	pageId: undefined,
	title: "",
	breadcrumbs: undefined,
	config: undefined,
	cookieConcent: false,
	supportedBrowser: true,
	isNetworkOffline: false,
	showWarningBanner: false,
	showShortcut: false,
	isAsideFolded: false,
	warningId: undefined,
	warningMessage: "",
	warningHistory: [],
	walkThrough: undefined,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.CHANGE_PAGE_CONFIG:
			return {
				...state,
				pageId: action.data.id,
				title: action.data.title,
				breadcrumbs: action.data.breadcrumbs,
				config: {
					...action.data,
					title: undefined,
				},
			};
		case actionTypes.CHECK_COOKIE_CONCENT:
		case actionTypes.CHANGE_COOKIE_CONCENT:
			return {
				...state,
				cookieConcent: action.data,
			};
		case actionTypes.CHECK_BROWSER_SUPPORT:
			return {
				...state,
				supportedBrowser: action.data,
			};
		case actionTypes.CHECK_NETWORK_SUPPORT:
			return {
				...state,
				isNetworkOffline: action.data,
			};
		case actionTypes.FOLD_ASIDE:
			return {
				...state,
				isAsideFolded: true,
			};
		case actionTypes.UNFOLD_ASIDE:
			return {
				...state,
				isAsideFolded: false,
			};
		case actionTypes.TOGGLE_SHORTCUTS:
			return {
				...state,
				showShortcut: !state.showShortcut,
			};
		case actionTypes.SHOW_WARNING_MESSAGE: {
			if (state.warningMessage) {
				state.warningHistory.push({
					id: state.warningId,
					message: state.warningMessage,
				});
			}

			return {
				...state,
				showWarningBanner: true,
				warningId: action.data.id,
				warningMessage: action.data.message,
				warningHistory: state.warningHistory,
			};
		}
		case actionTypes.REMOVE_WARNING_MESSAGE: {
			if (action.data === state.warningId) {
				const previousWarning = state.warningHistory.length ? state.warningHistory.pop() : "";

				return {
					...state,
					showWarningBanner: !!previousWarning,
					warningId: previousWarning.id,
					warningMessage: previousWarning.message,
					warningHistory: state.warningHistory,
				};
			}

			return {
				...state,
				warningHistory: state.warningHistory.filter((item) => item.id !== action.data),
			};
		}
		case actionTypes.CHANGE_UI_FAILED:
		default:
			return state;
	}
}
