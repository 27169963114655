import React from "react";
import { FormattedMessage } from "react-intl";
import { useRecoilValue } from "recoil";

import TemplateCard from "@/shared/components/card/template";

import { widgetPreviewSelector } from "../state";
import style from "./style.module.scss";

export default function WidgetPreview() {
	const widgetSettings = useRecoilValue(widgetPreviewSelector);

	return (
		<div className={style.widget_preview}>
			<header className={style.widget_preview_header}>
				<h2 className={style.header_title}>
					<FormattedMessage id="FLOWS.REPORT.SETTINGS.WIDGET.PREVIEW.TITLE" />
				</h2>
				<p>
					<FormattedMessage id="FLOWS.REPORT.SETTINGS.WIDGET.PREVIEW.DESCRIPTION" />
				</p>
			</header>
			<TemplateCard title={widgetSettings.name} {...widgetSettings} disabled className="frsw-widget" />
		</div>
	);
}
