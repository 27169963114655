import { Form, Switch } from "antd";
import type { IContact } from "@bothive_core/database";

import FieldSideLabel from "@/shared/components/field/side_label";

export default function RelativeForm({ contact }: { contact: IContact }) {
	return (
		<div style={{ width: "100%" }}>
			<FieldSideLabel
				id="flowProxy"
				label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.RELATIVE.FLOW_PROXY.LABEL"
				help="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.RELATIVE.FLOW_PROXY.HELP"
				labelValue={{ name: contact?.fullName }}
			>
				<Form.Item name="flowProxy" valuePropName="checked">
					<Switch />
				</Form.Item>
			</FieldSideLabel>
		</div>
	);
}
