import actionTypes from "./team.actionTypes";

const initialState = {
	team: undefined,
	settings: undefined,

	isFetching: false,
	fetchingFailed: false,
	fetchingSucceeded: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.TEAM_IS_FETCHING:
			return {
				...state,
				isFetching: true,
				fetchingFailed: false,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCHING_TEAM_FAILED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: true,
				fetchingSucceeded: false,
			};
		case actionTypes.TEAM_IS_ALREADY_FETCHED:
		case actionTypes.FETCHING_TEAM_SUCCEEDED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: false,
				fetchingSucceeded: true,
			};

		case actionTypes.FETCH_TEAM:
		case actionTypes.TEAM_CHANGED:
		case actionTypes.TEAM_ADDED:
		case actionTypes.CREATE_TEAM:
			return {
				...state,
				team: action.data,
			};
		case actionTypes.CLEAR_TEAM:
		case actionTypes.DELETE_TEAM:
			return {
				...state,
				team: undefined,
				settings: undefined,
			};
		case actionTypes.UPDATE_TEAM:
		case actionTypes.UPDATE_TEAM_LOCAL:
			return {
				...state,
				team: {
					...state.team,
					...action.data,
					accountant: {
						...(state.team?.accountant || []),
						...(action.data?.accountant || []),
					},
					billing: {
						...(state.team?.billing || []),
						...(action.data?.billing || []),
					},
					chatbot: {
						...(state.team?.chatbot || []),
						...(action.data?.chatbot || []),
					},
					settings: {
						...(state.team?.settings || []),
						...(action.data?.settings || []),
					},
					walkThrough: {
						...(state.team?.walkThrough || []),
						...(action.data?.walkThrough || []),
					},
				},
			};
		default:
			return state;
	}
}
