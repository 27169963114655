import type { FC, ReactNode } from "react";
import "./style.scss";

interface KeyboardShortcutProps {
	title: ReactNode;
	shortcut: string;
}

const KeyboardShortcut: FC<KeyboardShortcutProps> = ({ title, shortcut }) => {
	return (
		<div className="kbs-keyboard-shortcut">
			<p>{title}</p>
			<kbd>{shortcut}</kbd>
		</div>
	);
};
export default KeyboardShortcut;
