import { typesConfig } from "@/config";

function formatJsonToCSV({ data, fields, language, level = 0, parentField = {}, meta = {} }) {
	const keys = Object.keys(data);

	const customType = (type) => [
		{
			value: data,
			meta: { ...meta, level },
			options: fields?.options || [],
			type: parentField?.type || type,
			isRequired: parentField?.isRequired || false,
			key: parentField?.name?.[language] || parentField.key,
		},
	];

	if (data.url && data.extension && data.size) {
		// NOTE: I know this is dirty, but see no other solution unless we fully rework the field config flow.
		return customType(typesConfig.fields.fileArray);
	}

	if (typeof data === "string") {
		return customType(typesConfig.fields.string);
	}

	return keys.reduce((prev, key, index) => {
		const location = [meta?.location, key].filter((value) => value).join(".");

		const selectedField = findField(fields, key);

		let _meta = { level, location };

		let value = parseJsonData(data[key]);

		if (value && typeof value === "object") {
			let child;
			const _level = level + 1;

			if (Array.isArray(value)) {
				child = value.reduce((prev, curr, index) => {
					const field = formatJsonToCSV({
						parentKey: `${key} ${index + 1 > 1 ? index + 1 : ""}`,
						parentField: selectedField,
						level: _level,
						data: curr,
						fields: selectedField?.fields,
						language,
						meta: {
							..._meta,
							location: `${_meta.location}[${index}]`,
						},
					});

					return [...prev, ...field];
				}, []);
			} else {
				child = formatJsonToCSV({
					language,
					meta: _meta,
					data: value,
					level: _level,
					fields: selectedField?.fields,
				});
			}

			return [...prev, ...child];
		}

		if (level && !index) {
			_meta.first = true;
		}

		if (level && index === keys.length - 1) {
			_meta.last = true;
		}

		return [
			...prev,
			{
				value,
				meta: _meta,
				options: selectedField.options,
				key: selectedField?.name?.[language] || selectedField?.[language]|| key,
				type: selectedField?.type || typesConfig.fields.string,
				isRequired: selectedField?.isRequired || false,
			},
		];
	}, []);
}

function parseJsonData(data) {
	if (!data) return data;

	try {
		if (Number(data)) return data;

		return JSON.parse(data);
	} catch {
		return data;
	}
}

function findField(fields, key) {
	const fallback = { key, fields: [] };

	if (!fields) return fallback;

	const field = fields.find((field) => field.key === key);

	return field || fallback;
}

export default {
	formatJsonToCSV,
};
