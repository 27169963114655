import React from "react";
import { FormattedMessage } from "react-intl";

import { Component, Toggle } from "../../atoms";

export default function MDestructive({ id, expanded, label, className, onClick }) {
	return (
		<header
			id={`accordion-${id}`}
			className={`m-accordion-header m-accordion-header--destructive  ${expanded ? "active" : ""} ${className}`}
		>
			<div className="header-wrapper--left">
				<Component.AIcon type="error" className="small t-gap--right-sm" />
				<h2>{label ? <FormattedMessage id={label} /> : undefined}</h2>
			</div>
			<Toggle.AToggleDefault
				id={id}
				checked={expanded}
				onChange={() => onClick(id)}
				className="a-toggle--destructive small destructive"
			/>
		</header>
	);
}

MDestructive.defaultProps = {
	expanded: false,
	className: "",
	onClick: () => undefined,
};
