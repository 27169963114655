export default {
	overview: "/:team/flows",
	detail: "/:team/flows/:flowId",
	reports: "/:team/flows/:flowId/reports",
	reportDetail: "/:team/flows/:flowId/reports/:reportId",
	configuration: "/:team/flows/:flowId/configuration",
	statusTypes: "/:team/flows/:flowId/status_types",
	settings: "/:team/flows/:flowId/settings",
	reminders: "/:team/flows/:flowId/reminders",
};
