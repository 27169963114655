import "./style.scss";
import type { EditorThemeClasses } from "lexical";

const theme: EditorThemeClasses = {
	blockCursor: "editor-markdown-blockCursor",
	characterLimit: "editor-markdown-characterLimit",
	codeHighlight: {
		atrule: "editor-markdown-tokenAttr",
		attr: "editor-markdown-tokenAttr",
		boolean: "editor-markdown-tokenProperty",
		builtin: "editor-markdown-tokenSelector",
		cdata: "editor-markdown-tokenComment",
		char: "editor-markdown-tokenSelector",
		class: "editor-markdown-tokenFunction",
		"class-name": "editor-markdown-tokenFunction",
		comment: "editor-markdown-tokenComment",
		constant: "editor-markdown-tokenProperty",
		deleted: "editor-markdown-tokenProperty",
		doctype: "editor-markdown-tokenComment",
		entity: "editor-markdown-tokenOperator",
		function: "editor-markdown-tokenFunction",
		important: "editor-markdown-tokenVariable",
		inserted: "editor-markdown-tokenSelector",
		keyword: "editor-markdown-tokenAttr",
		namespace: "editor-markdown-tokenVariable",
		number: "editor-markdown-tokenProperty",
		operator: "editor-markdown-tokenOperator",
		prolog: "editor-markdown-tokenComment",
		property: "editor-markdown-tokenProperty",
		punctuation: "editor-markdown-tokenPunctuation",
		regex: "editor-markdown-tokenVariable",
		selector: "editor-markdown-tokenSelector",
		string: "editor-markdown-tokenSelector",
		symbol: "editor-markdown-tokenProperty",
		tag: "editor-markdown-tokenProperty",
		url: "editor-markdown-tokenOperator",
		variable: "editor-markdown-tokenVariable",
	},
	embedBlock: {
		base: "editor-markdown-embed_block",
		focus: "editor-markdown-embed_block_focus",
	},
	hashtag: "editor-markdown-hashtag",
	heading: {
		h1: "editor-markdown-h1",
		h2: "editor-markdown-h2",
		h3: "editor-markdown-h3",
		h4: "editor-markdown-h4",
		h5: "editor-markdown-h5",
		h6: "editor-markdown-h6",
	},
	image: "editor-image",
	indent: "editor-markdown-indent",
	link: "editor-markdown-link",
	list: {
		listitem: "editor-markdown-list_item",
		listitemChecked: "editor-markdown-list_item_checked",
		listitemUnchecked: "editor-markdown-list_item_unchecked",
		nested: {
			listitem: "editor-markdown-nested_list_item",
		},
		olDepth: [
			"editor-markdown-ol1",
			"editor-markdown-ol2",
			"editor-markdown-ol3",
			"editor-markdown-ol4",
			"editor-markdown-ol5",
			"editor-markdown-ol6",
			"editor-markdown-ol7",
		],
		ul: "editor-markdown-ul",
		ol: "editor-markdown-ol",
		ulDepth: [
			"editor-markdown-ul1",
			"editor-markdown-ul2",
			"editor-markdown-ul3",
			"editor-markdown-ul4",
			"editor-markdown-ul5",
			"editor-markdown-ul6",
			"editor-markdown-ul7",
		],
	},
	ltr: "editor-markdown-ltr",
	mark: "editor-markdown-mark",
	markOverlap: "editor-markdown-markOverlap",
	paragraph: "editor-markdown-paragraph",
	quote: "editor-markdown-quote",
	rtl: "editor-markdown-rtl",
	code: "editor-markdown-code",
	text: {
		bold: "editor-markdown-text-bold",
		code: "editor-markdown-text-code",
		italic: "editor-markdown-text-italic",
		underline: "editor-markdown-text-underline",
		strikethrough: "editor-markdown-text-strike_through",
		underlineStrikethrough: "editor-markdown-text-underline_strike_through",
	},
	mention: "editor-markdown-mention_node",
};

export default theme;
