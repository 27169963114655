import { HTMLProps } from "react";
import { logo } from "../../../assets/images";
import clsx from "clsx";

interface LogoLoaderProps extends HTMLProps<HTMLDivElement> {}

export default function LogoLoader({ className, ...props }: LogoLoaderProps) {
	return (
		<div {...props} className={clsx(`a-component--main-loader`, className)}>
			<div className="icon-loader loader-animation" />
			<img src={logo.logoSmall} alt="logo" className="loader-logo" />
		</div>
	);
}
