import React from "react";
import { Route, Switch } from "react-router-dom";

import { Handler } from "../../components/organisms";
import { routesConfig } from "../../config";
import { FlowRoutes } from "../../flows"; // use path alias
import Inbox from "../../modules/inbox";
import PermaLink from "../../modules/inbox/permaLink";
import { Dashboard } from "../../pages";
import settingsRoutes from "@/modules/settings";
import { ContactRoutes } from "../../modules/contacts/common/routes";
import SupportPage from "../../modules/support";
import DashboardPage from "../../modules/dashboard";

export default () => (
	<Dashboard.DashboardTemplate>
		<Handler.OErrorFallback>
			<Switch>
				<Route path="/:team/contact" component={ContactRoutes} />
				<Route path={routesConfig.dashboard.flow.root} component={FlowRoutes} />
				<Route exact path="/:team/inbox" component={Inbox} />
				<Route exact path="/:team/inbox/search/:conversationId?" component={Inbox} />
				<Route exact path="/:team/inbox/folder/:folder/:conversationId?" component={Inbox} />
				<Route exact path="/:team/inbox/conversation/:conversationId" component={PermaLink} />
				<Route exact path="/:team/inbox/folder/:folder/status/:status/:conversationId?" component={Inbox} />
				<Route path="/:team/inbox/label/:label/status/:status/:conversationId?" component={Inbox} />
				<Route path={routesConfig.dashboard.documentation} component={Dashboard.DocumentationPage} />
				{/* eslint-disable-next-line max-len*/}
				<Route path={routesConfig.dashboard.settings.root} component={settingsRoutes} />
				<Route path={routesConfig.dashboard.support} component={SupportPage} />
				<Route path={routesConfig.dashboard.root} component={DashboardPage} />
			</Switch>
		</Handler.OErrorFallback>
	</Dashboard.DashboardTemplate>
);
