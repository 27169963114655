import "./style.scss";

import type { IBankAccount, TBankAccountType } from "@bothive_core/database";
import { Button, Modal } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import DeleteModal from "../../../../../../../shared/components/modal/delete_modal";
import BankAccountsForm from "./BankAccountsForm";

interface IBankAccountModal {
	id?: string;
	isOpen: boolean;
	value?: IBankAccount;
	onSubmit: (value: IBankAccount) => void;
	onUpdate: ({ id, payload }: { id: string; payload: IBankAccount }) => void;
	onDelete: (id: string) => void;
	onClose: () => void;
}
interface IBankAccountForm {
	type: TBankAccountType;
	iban: string;
	bicCode: string;
	name: string;
	country: string;
	dateOpened: Date;
	dateClosed: Date;
	isDeclared: boolean;
	incomeOfChildrenOnAccount: boolean;
	incomeOfChildrenOnAccountYear: number;
	isRefundAccount: boolean;
}
export default function BankAccountModal({
	id,
	value,
	isOpen,
	onSubmit,
	onUpdate,
	onDelete,
	onClose,
}: IBankAccountModal) {
	const intl = useIntl();

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const handleSubmit = (value: IBankAccountForm) => {
		const payload: IBankAccount = {
			type: value.type,
			iban: value.iban,
			institution: { name: value.name, address: { country: value.country } },
			bicCode: value.bicCode,
			dateOpened: value.dateOpened && new Date(value.dateOpened),
			dateClosed: value.dateClosed && new Date(value.dateClosed),
			isDeclared: value.isDeclared,
			incomeOfChildrenOnAccount: value.incomeOfChildrenOnAccount,
			incomeOfChildrenOnAccountYear: value.incomeOfChildrenOnAccountYear && +value.incomeOfChildrenOnAccountYear,
			isRefundAccount: value.isRefundAccount,
		};

		id ? onUpdate({ id, payload }) : onSubmit(payload);
	};
	const handleDelete = () => {
		onDelete(id as string);
		setShowDeleteModal(false);
	};

	return (
		<>
			<Modal
				open={isOpen}
				title={intl.formatMessage({
					id: id
						? "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.UPDATE_TITLE"
						: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.CREATE_TITLE",
				})}
				onCancel={onClose}
				width={700}
				destroyOnClose
				className="bank_account_modal"
				bodyStyle={{ height: "100%", maxHeight: "75vh", overflow: "auto" }}
				wrapClassName="contact_modal-wrapper"
				footer={[
					id && (
						<Button
							danger
							key="delete"
							type="primary"
							className="t-gap--right-sm"
							onClick={() => setShowDeleteModal(true)}
						>
							<FormattedMessage id="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FOOTER.DELETE" />
						</Button>
					),
					<Button key="submit" type="primary" htmlType="submit" form="bankAccountForm">
						<FormattedMessage id="CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.FOOTER.SAVE" />
					</Button>,
				]}
			>
				<BankAccountsForm
					handleSubmit={handleSubmit}
					initialValues={{
						...(value || {}),
						name: value?.institution?.name,
						country: value?.institution?.address?.country,
						dateOpened: value?.dateOpened && dayjs(value.dateOpened),
						dateClosed: value?.dateClosed && dayjs(value.dateClosed),
					}}
				/>
			</Modal>
			<DeleteModal
				title={intl.formatMessage({
					id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.TITLE",
					defaultMessage: "Delete bank account",
				})}
				deleteLabel={intl.formatMessage({
					id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.BUTTON",
					defaultMessage: "Delete",
				})}
				description={intl.formatMessage({
					id: "CONTACTS.DETAIL.BANK_ACCOUNTS_MODAL.DELETE_MODAL.DESCRIPTION",
					defaultMessage: "Are you sure you want to delete this bank account? This action cannot be undone.",
				})}
				isVisible={showDeleteModal}
				onDelete={handleDelete}
				onClose={() => setShowDeleteModal(false)}
			/>
		</>
	);
}
