import "../style.scss";

import { Button, Modal, Row, Form, Input, Tooltip, Space } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useEffect, useMemo, useState } from "react";
import DeleteModal from "@/shared/components/modal/delete_modal";
import FieldLabel from "@/shared/components/field/default";
import { MemberRouterInput, trpc } from "@/trpc";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import { IMember } from "@bothive_core/database";

import MemberPermissions from "./memberPermissions";

interface IEditMember {
	member: IMember;
	allowEditing: boolean;
	showModal: boolean;
	onClose: () => void;
}
export default function EditMember({ allowEditing, showModal, onClose, member }: IEditMember) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const trpcUtils = trpc.useUtils();
	const [showConfirm, setShowConfirm] = useState(false);
	const isSuperAdmin = Form.useWatch<boolean>("superAdmin", form);
	const reSendMemberInviteMutation = trpc.member.reSendMemberInvite.useMutation();
	const updateMemberMutation = trpc.member.updateMember.useMutation();
	const deleteMemberMutation = trpc.member.deleteMember.useMutation();
	const { data: members, refetch: refetchMembers } = trpc.member.getMembers.useQuery();

	const reSendMemberInvite = () => {
		reSendMemberInviteMutation.mutate(
			{ memberId: member._id },
			{
				onSuccess: () => {
					trpcUtils.member.invalidate();
					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "settings.organization.edit_member.resend_invite.success",
							defaultMessage: "Invite has been sent.",
						}),
					});
				},
				onError: () => {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "settings.organization.edit_member.resend_invite.failed",
							defaultMessage: "Invite could not be sent. Please try again later",
						}),
					});
				},
			}
		);
	};
	const updateMember = (formValue) => {
		const payload: MemberRouterInput["updateMember"] = {
			_id: member._id,
			permissions: {
				editFlows: formValue.editFlows,
				editIntegrations: formValue.editIntegrations,
				editSettings: formValue.editSettings,
				manageUsers: formValue.manageUsers,
				readAllContactConversations: formValue.readAllContactConversations,
				superAdmin: formValue.superAdmin,
			},
		};

		updateMemberMutation.mutate(payload, {
			onSuccess: () => {
				trpcUtils.member.getAll.invalidate();
				refetchMembers();
				onClose();
				ShowSuccessBanner({
					title: intl.formatMessage({
						id: "settings.organization.edit_member.edit.success",
						defaultMessage: "The member has been successfully edited.",
					}),
				});
			},
			onError: (error) => {
				if (error.data?.code === "BAD_REQUEST") {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "settings.organization.edit_member.edit.downgrade_power_admin_failed",
							defaultMessage:
								"You cannot downgrade the permissions of this super admin because you need at least 1 super admin in your organization",
						}),
					});
					return;
				}

				ShowFailedBanner({
					title: intl.formatMessage({
						id: "settings.organization.edit_member.edit.failed",
						defaultMessage: "The member couldn't be edit. Please try again later",
					}),
				});
			},
		});
	};
	const deleteMember = (member) => {
		deleteMemberMutation.mutate(member, {
			onSuccess: () => {
				trpcUtils.member.getAll.invalidate();
				refetchMembers();
				setShowConfirm(false);
				onClose();
				ShowSuccessBanner({
					title: intl.formatMessage({
						id: "settings.organization.edit_member.remove.success",
						defaultMessage: "The member has been successfully removed.",
					}),
				});
			},
			onError: () => {
				ShowFailedBanner({
					title: intl.formatMessage({
						id: "settings.organization.edit_member.remove.failed",
						defaultMessage: "The member couldn't be removed. Please try again later",
					}),
				});
			},
		});
	};

	const allowDelete = useMemo(
		() => (members?.length || 0) > 1 && (!isSuperAdmin || members),
		[isSuperAdmin, members, members?.length]
	);

	const deleteTooltip = defineMessages({
		superAdmin: {
			id: "settings.organization.edit_member.remove.super_admin",
			defaultMessage: "Super admin can't be deleted",
		},
		lastMember: {
			id: "settings.organization.edit_member.remove.last_member",
			defaultMessage: "The only member can't be removed",
		},
	});

	useEffect(() => {
		if (!member) return;

		form.resetFields();
		form.setFieldsValue({ ...member, ...(member.permissions || []) });
	}, [member]);

	if (!member) return null;

	return (
		<>
			<Modal
				forceRender
				title={intl.formatMessage({
					id: "settings.organization.edit_member.edit.title",
					defaultMessage: "Edit user",
				})}
				open={showModal}
				centered
				destroyOnClose
				onCancel={onClose}
				width={650}
				footer={
					<Row justify="space-between">
						<Tooltip
							title={
								!allowDelete
									? intl.formatMessage(
											isSuperAdmin ? deleteTooltip.superAdmin : deleteTooltip.lastMember
									  )
									: ""
							}
						>
							<Button
								danger
								type="primary"
								htmlType="button"
								loading={deleteMemberMutation.isLoading}
								onClick={() => setShowConfirm(true)}
								disabled={!allowDelete}
							>
								<FormattedMessage
									id="settings.organization.edit_member.edit.delete"
									defaultMessage="Remove"
								/>
							</Button>
						</Tooltip>
						<Button
							type="primary"
							htmlType="submit"
							loading={updateMemberMutation.isLoading}
							form="editMember"
						>
							<FormattedMessage
								id="settings.organization.edit_member.edit.update"
								defaultMessage="Update"
							/>
						</Button>
					</Row>
				}
			>
				<Form
					id="editMember"
					preserve={false}
					form={form}
					disabled={!allowEditing}
					onFinish={updateMember}
					initialValues={
						{
							editFlows: false,
							editSettings: false,
							editIntegrations: false,
							manageUsers: false,
							readAllContactConversations: false,
							superAdmin: false,
						} satisfies MemberRouterInput["updateMember"]["permissions"]
					}
				>
					<FieldLabel
						label={intl.formatMessage({
							id: "settings.organization.edit_member.form.email.label",
							defaultMessage: "email",
						})}
						help={intl.formatMessage({
							id: "settings.organization.edit_member.form.email.help",
							defaultMessage:
								"Didn't receive an email? View the spam folder of contact our customer service.",
						})}
					>
						<Form.Item
							name="email"
							rules={[
								{
									type: "email",
									required: true,
									message: intl.formatMessage({
										id: "settings.organization.edit_member.form.email.email_error",
										defaultMessage: "Email looks to be incomplete",
									}),
								},
							]}
							style={{ width: "100%" }}
						>
							<Space.Compact style={{ width: "100%" }}>
								<Form.Item style={{ width: "100%" }} name="email">
									<Input disabled />
								</Form.Item>
								{!member.accepted && (
									<Button
										type="primary"
										loading={reSendMemberInviteMutation.isLoading}
										onClick={reSendMemberInvite}
									>
										<FormattedMessage
											id="settings.users_popup.resend_invite"
											defaultMessage="Resend invite"
										/>
									</Button>
								)}
							</Space.Compact>
						</Form.Item>
					</FieldLabel>
					<MemberPermissions form={form} />
				</Form>
			</Modal>
			<DeleteModal
				title={intl.formatMessage(
					{
						id: "settings.organization.edit_member.delete_modal.title",
						defaultMessage: "Delete {username}",
					},
					{ username: member.name }
				)}
				deleteLabel={intl.formatMessage({
					id: "settings.organization.edit_member.delete_modal.button",
					defaultMessage: "Delete member",
				})}
				description={intl.formatMessage(
					{
						id: "settings.organization.edit_member.delete_modal.description",
						defaultMessage:
							"You are on point to delete {username} from your organization. Are you sure you want to continue?",
					},
					{ username: member.name }
				)}
				isVisible={showConfirm}
				isDeleting={deleteMemberMutation.isLoading}
				onDelete={() => deleteMember({ _id: member._id })}
				onClose={() => setShowConfirm(false)}
			/>
		</>
	);
}
