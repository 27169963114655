import "./style.scss";

import type { IContact, IInsurance } from "@bothive_core/database";
import { Button, Form, Input, Modal, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useRef, useState } from "react";

import FieldLabel from "../../../../../../../shared/components/field/default";
import DeleteModal from "../../../../../../../shared/components/modal/delete_modal";
import { insuranceType } from "../config";
import { useSetFocus } from "@/shared/hooks";
import CountryPicker from "@/shared/components/select/country_picker";

interface IInsuranceModal {
	id?: string;
	isOpen: boolean;
	value?: IInsurance;
	contact: IContact;
	onSubmit: (value: IInsurance) => void;
	onUpdate: ({ id, payload }: { id: string; payload: IInsurance }) => void;
	onDelete: (id: string) => void;
	onClose: () => void;
}

interface IInsuranceForm extends IInsurance {}

export default function InsuranceModal({ id, value, isOpen, onSubmit, onUpdate, onDelete, onClose }: IInsuranceModal) {
	const intl = useIntl();
	const selectRef = useRef<any>(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const handleSubmit = (value: IInsuranceForm) => {
		id ? onUpdate({ id, payload: value }) : onSubmit(value);
	};
	const handleDelete = () => {
		onDelete(id as string);
		setShowDeleteModal(false);
	};

	useSetFocus({ ref: selectRef, dependencies: [selectRef, isOpen] });

	return (
		<>
			<Modal
				className="insurances_modal"
				open={isOpen}
				title={intl.formatMessage({
					id: id
						? "CONTACTS.DETAIL.INSURANCES_MODAL.UPDATE_TITLE"
						: "CONTACTS.DETAIL.INSURANCES_MODAL.CREATE_TITLE",
				})}
				onCancel={onClose}
				destroyOnClose
				footer={[
					id && (
						<Button
							danger
							key="delete"
							type="primary"
							className="t-gap--right-sm"
							onClick={() => setShowDeleteModal(true)}
						>
							<FormattedMessage id="CONTACTS.DETAIL.INSURANCES_MODAL.FOOTER.DELETE" />
						</Button>
					),
					<Button key="submit" type="primary" htmlType="submit" form="insurancesForm">
						<FormattedMessage id="CONTACTS.DETAIL.INSURANCES_MODAL.FOOTER.SAVE" />
					</Button>,
				]}
			>
				<Form
					id="insurancesForm"
					layout="vertical"
					requiredMark="optional"
					className="insurances_modal-form"
					onFinish={handleSubmit}
					initialValues={value || { type: Object.keys(insuranceType)[0] }}
				>
					<FieldLabel id="type" label="CONTACTS.DETAIL.INSURANCES_MODAL.FORM.TYPE.LABEL">
						<Form.Item
							className="insurances_modal-form_item"
							name="type"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "CONTACTS.DETAIL.INSURANCES_MODAL.FORM.TYPE.ERROR_REQUIRED",
									}),
								},
							]}
						>
							<Select autoFocus={true} ref={selectRef}>
								{Object.keys(insuranceType).map((key) => (
									<Select.Option key={key} value={key}>
										<FormattedMessage id={insuranceType[key]} />
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						id="institutionName"
						label="CONTACTS.DETAIL.INSURANCES_MODAL.FORM.INSTITUTION_NAME.LABEL"
					>
						<Form.Item
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "CONTACTS.DETAIL.INSURANCES_MODAL.FORM.INSTITUTION_NAME.ERROR_REQUIRED",
									}),
								},
							]}
							className="insurances_modal-form_item"
							name="institutionName"
						>
							<Input />
						</Form.Item>
					</FieldLabel>
					<FieldLabel id="country" label="CONTACTS.DETAIL.INSURANCES_MODAL.FORM.ADDRESS.COUNTRY.LABEL">
						<Form.Item
							className="insurances_modal-form_item"
							name="country"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "CONTACTS.DETAIL.INSURANCES_MODAL.FORM.ADDRESS.COUNTRY.ERROR_REQUIRED",
									}),
								},
							]}
						>
							<CountryPicker
								placeholder={intl.formatMessage({
									id: "CONTACTS.DETAIL.INSURANCES_MODAL.FORM.ADDRESS.COUNTRY.PLACEHOLDER",
								})}
							/>
						</Form.Item>
					</FieldLabel>
				</Form>
			</Modal>
			<DeleteModal
				title={intl.formatMessage({
					id: "CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.TITLE",
					defaultMessage: "Delete insurance",
				})}
				deleteLabel={intl.formatMessage({
					id: "CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.BUTTON",
					defaultMessage: "Delete insurance",
				})}
				description={intl.formatMessage({
					id: "CONTACTS.DETAIL.INSURANCES_MODAL.DELETE_MODAL.DESCRIPTION",
					defaultMessage: "Are you sure you want to delete this insurance?",
				})}
				isVisible={showDeleteModal}
				onDelete={handleDelete}
				onClose={() => setShowDeleteModal(false)}
			/>
		</>
	);
}
