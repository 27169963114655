import axios from "axios";
import csvToJson from "csvtojson";

import { apiConfig, errorConfig, fileConfig } from "../config";
import { showFailedMessage } from "../store/dashboard/banner/banner.action";
import store from "../store/store";

function formatFileTypesToList(fileType) {
	const joined = fileType.join(", ");
	const index = joined.lastIndexOf(", ");

	return `${joined.substring(0, index)} &${joined.substring(index + 1)}`;
}

export default {
	downloadFile({ file, fileName, type }) {
		let blob;
		if (type === "excel") {
			blob = new Blob([file], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		} else {
			blob = new Blob(["\ufeff", file]);
		}

		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");

		link.href = url;
		link.setAttribute("download", fileName);

		if (type !== "excel") {
			link.setAttribute("charset", "utf-16");
		}

		document.body.appendChild(link);
		link.click();

		if (document.contains(link)) {
			document.body.removeChild(link);
		}
	},
	downloadZip({ zip, zipName }) {
		const url = window.URL.createObjectURL(new Blob([zip], { type: "application/zip" }));
		const link = document.createElement("a");

		link.href = url;
		link.setAttribute("download", zipName);
		link.setAttribute("charset", "utf-16");
		document.body.appendChild(link);
		link.click();

		if (document.contains(link)) {
			// don't remove link if it already removed from body
			document.body.removeChild(link);
		}
	},
	downloadFileFromUrl({ url, fileName }) {
		window.open(url, fileName);
	},
	openFile({ url }) {
		const newTab = window.open(url, "_blank");

		if (newTab) {
			newTab.focus();
		}
	},
	pickFile({ config = {}, callback }) {
		const input = document.createElement("input");

		input.type = "file";

		if (config?.multiple) {
			input.multiple = "multiple";
		}

		document.body.appendChild(input);
		document.body.onfocus = () => {
			// handle cancel click
			document.body.onfocus = null;

			if (document.contains(input)) {
				// don't remove input if it already removed from body
				document.body.removeChild(input);
			}
		};

		input.click();
		input.addEventListener("change", (file) => {
			callback(file.target.files);

			if (document.contains(input)) {
				// don't remove input if it already removed from body
				document.body.removeChild(input);
			}
		});
	},

	createPreviewFromFile(file) {
		return new Promise((resolve) => {
			const reader = new FileReader();

			reader.addEventListener(
				"load",
				() => {
					resolve(reader.result);
				},
				true
			);

			reader.readAsDataURL(file);
		});
	},

	csv2Json: async ({ data, delimiter = ";" }) => csvToJson({ output: "json", delimiter }).fromString(data),

	imageUploadWithPreview: (file) =>
		new Promise((resolve, reject) => {
			if (file === undefined || file === null || file === []) {
				return null;
			}

			if (file.size > fileConfig.imageSize) {
				imageFileToLarge();
				reject(new Error("File upload failed"));
			}

			if (!fileConfig.fileTypes.image.includes(file.type.replace(/[a-zA-Z0-9]+\//, "").toLowerCase())) {
				fileTypeNotSupported(formatFileTypesToList(fileConfig.fileTypes.image));
				reject(new Error("File upload failed"));
			}

			const reader = new FileReader();

			reader.addEventListener(
				"load",
				() => {
					resolve({
						url: reader.result,
						name: file.name,
						type: file.type,
					});
				},
				true
			);

			reader.readAsDataURL(file);
		}),
	fileUploadLocally: ({ file, types = [] }) =>
		new Promise((resolve, reject) => {
			if (file === undefined || file === null || file === []) {
				return null;
			}

			if (file.size > fileConfig.imageSize) {
				imageFileToLarge();
				reject(errorConfig.FILE_UPLOAD_FAILED);
			}

			if (types.length && !types.includes(file.type.replace(/[a-zA-Z0-9]+\//, "").toLowerCase())) {
				fileTypeNotSupported(formatFileTypesToList(types));
				reject(errorConfig.FILE_UPLOAD_FAILED);
			}

			const reader = new FileReader();

			reader.addEventListener(
				"load",
				() =>
					resolve({
						data: reader.result,
						name: file.name,
						type: file.type,
						preview: getMimetypePreview(file.name),
					}),
				true
			);

			reader.readAsText(file);
		}),
	allowFileDrop: ({ selector, callback }) => {
		document.querySelector(selector).addEventListener("drop", (ev) => {
			ev.preventDefault();
			callback(ev.dataTransfer.files);
		});
	},
	preventFileDragOver: ({ selector }) => {
		document.querySelector(selector).addEventListener("dragover", (ev) => ev.preventDefault());
	},
	imageUpload: async (file) => {
		const body = new FormData();

		body.append("file", file);

		if (file.size > fileConfig.imageSize) {
			imageFileToLarge();
			throw new Error("File upload failed");
		}

		if (!fileConfig.fileTypes.image.includes(file.type.replace(/[a-zA-Z0-9]+\//, "").toLowerCase())) {
			fileTypeNotSupported(formatFileTypesToList(fileConfig.fileTypes.image));
			throw new Error("File upload failed");
		}

		try {
			const result = await axios.post(`${apiConfig.fileUrl}/v1/image`, body);

			return result.data.data;
		} catch (error) {
			throw new Error("File upload failed");
		}
	},
	fileUpload: async ({ file, config, payload = undefined }) => {
		if (!config) {
			throw new Error("File upload failed");
		}

		const body = new FormData();
		const organizationId = store.getState().content.team.team._id;

		body.append("file", file);

		if (payload) {
			Object.keys((key) => body.append(key, payload[key]));
		}

		if (file.size > config.maxSize) {
			imageFileToLarge();
			throw new Error("File upload failed");
		}

		const fileType = file.type ? file.type : file.name.split(".").pop();

		if (config?.fileTypes && !config.fileTypes.includes(fileType.replace(/[a-zA-Z0-9]+\//, "")?.toLowerCase())) {
			fileTypeNotSupported(formatFileTypesToList(fileConfig.font.fileTypes));
			throw new Error("File upload failed");
		}

		try {
			return await axios.post(config.url, body, { headers: { organizationId } });
		} catch (error) {
			throw new Error("File upload failed");
		}
	},
	getStringFromFile: async (url) => {
		try {
			return await axios.get(url);
		} catch (error) {
			throw errorConfig.GET_TEMPLATE_FAILED;
		}
	},
	cropImage({ image, crop, fileName, fileType }) {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;

		if (!crop?.width || !crop?.height) {
			return;
		}

		canvas.width = crop.width;
		canvas.height = crop.height;

		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve) => {
			canvas.toBlob((blob) => {
				if (!blob) {
					return;
				}

				resolve(new File([blob], fileName, { type: fileType }));
			}, fileType);
		});
	},

	extendImage({ src, minHeight = 0, minWidth = 0 }) {
		if (!src) {
			return;
		}

		return new Promise((resolve, reject) => {
			try {
				const image = new Image();
				const canvas = document.createElement("canvas");
				const context = canvas.getContext("2d");

				image.src = src;
				image.onload = function () {
					const maxSize = Math.max(this.width, this.height);
					const canvasWidth = Math.max(maxSize, minWidth);
					const canvasHeight = Math.max(maxSize, minHeight);

					const widthOffset = (canvasWidth - this.width) / 2;
					const heightOffset = (canvasHeight - this.height) / 2;

					canvas.width = canvasWidth;
					canvas.height = canvasHeight;
					context.fillStyle = "#FFFFFF";
					context.fillRect(0, 0, canvasWidth, canvasHeight);
					context.drawImage(this, widthOffset, heightOffset, this.width, this.height);

					resolve(canvas.toDataURL());
				};
			} catch (error) {
				reject(error);
			}
		});
	},
	imageFileToLarge,
};

function fileTypeNotSupported(types) {
	store.dispatch(
		showFailedMessage({
			message: "BANNER.FAILED.FILE_TYPE_NOT_SUPPORTED",
			values: { types },
		})
	);
}
function imageFileToLarge() {
	store.dispatch(showFailedMessage({ message: "BANNER.FAILED.FILE_IS_TO_LARGE" }));
}
function getMimetypePreview(name) {
	const extension = name.split(".").pop();
	const category = Object.keys(fileConfig.fileTypes).find((key) =>
		fileConfig.fileTypes[key].find((item) => item === extension)
	);

	return fileConfig.previewIcon[category] || fileConfig.previewIcon.fallback;
}
