function hexToRgb(hex: string) {
	const _hex = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => r + r + g + g + b + b);
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(_hex);

	if (!result) return { r: 0, g: 0, b: 0 };

	return {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16),
	};
}
function luminance({ r, g, b }: { r: number; g: number; b: number }) {
	const rgb = [r, g, b].map((color) => {
		const rgbColor = color / 255;

		return rgbColor <= 0.03928 ? rgbColor / 12.92 : Math.pow((rgbColor + 0.055) / 1.055, 2.4);
	});

	return rgb[0] * 0.2126 + rgb[1] * 0.7152 + rgb[2] * 0.0722;
}

function isDarkColor(color) {
	const rgbColor = hexToRgb(color);
	const colorLuminance = luminance(rgbColor);

	return colorLuminance < 0.5;
}

export function contrastTextByBackground({
	backgroundColor,
	lightTextColor,
	darkTextColor,
}: {
	backgroundColor: string;
	lightTextColor: string;
	darkTextColor: string;
}) {
	return isDarkColor(backgroundColor) ? lightTextColor : darkTextColor;
}

export default {
	hexToRgb,
	luminance,
	isLightColor(color) {
		const rgbColor = hexToRgb(color);
		const colorLuminance = luminance(rgbColor);

		return colorLuminance > 0.5;
	},
	isDarkColor,
	contrastTextByBackground,
};
