import { MenuOutlined } from "@ant-design/icons";
import { Skeleton, Table, Tooltip } from "antd";
import { arrayMoveImmutable } from "array-move";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

import { generatorHelpers } from "@/helpers";

import helper from "./helper";
import style from "./style.module.scss";

const DragHandle = SortableHandle(() => (
	<Tooltip title={<FormattedMessage id="COMPONENT.SORT_TABLE.SORT.TOOLTIP" />}>
		<MenuOutlined style={{ cursor: "grab" }} className={style.sortable_handle} />
	</Tooltip>
));
const SortableItem = SortableElement((props) => <tr {...props} className={style.sortable_table_row} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

/*
	the loading skeleton is shown based on the config provided in de column, each column accepts a skeleton object with the config for
	the skeleton (see ant design) and isEnabled boolean, if the boolean is false the skeleton will not be shown
	```
	columns = [{
		title: intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.TABLE.DEFAULT" }),
		dataIndex: "isDefault",
		width: "128px",
		skeleton: {
			isEnabled: true,
		},
		render: (isDefault) => isDefault && <CheckOutlined />,
	}]
	```
*/
export default function SortableTable({
	dataSource,
	isLoading,
	isDisabled,
	columns,
	rowKey,
	onReorder, // ({ data, index }) => void ({ data: new re-arranged data, index: index of moved item in new data })
	onClick,
	...props
}) {
	const loadingDataSource = useMemo(() => generatorHelpers.skeletonList(), []);
	const _columns = useMemo(() => {
		let columnConfig = [...columns];

		if (!isDisabled) {
			columnConfig.unshift({
				width: 48,
				skeleton: {
					isEnabled: false,
				},
				align: "center",
				className: style.sortable_drag_handler,
				render: () => <DragHandle />,
			});
		}

		if (isLoading) {
			columnConfig = columnConfig.map((column, index) => {
				if (column.skeleton && !column.skeleton?.isEnabled) {
					return { ...column, render: () => <p> </p> };
				}

				const skeletonConfig = {
					title: true,
					active: true,
					paragraph: false,
					...(column.skeleton || []),
				};

				return { ...column, render: () => <Skeleton key={index} {...skeletonConfig} /> };
			});
		}

		return columnConfig;
	}, [isLoading]);

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex === newIndex) {
			return;
		}

		const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter((data) => !!data);

		onReorder({
			data: newData,
			index: newIndex,
		});
	};

	const DraggableContainer = (props) => (
		<SortableBody
			useDragHandle
			disableAutoscroll
			helperClass={style.sortable_draggable_row}
			onSortEnd={onSortEnd}
			{...props}
		/>
	);

	const DraggableBodyRow = ({ ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = dataSource.findIndex((data) => data[rowKey] === restProps["data-row-key"]);

		return <SortableItem index={index} {...restProps} />;
	};

	const handleRowClick = ({ event, value }) => {
		if (!helper.isRowClickAllowed(event) || !onClick) {
			return;
		}

		onClick(value);
	};

	return (
		<Table
			{...props}
			onRow={(value) => ({
				onClick: (event) => handleRowClick({ event, value }),
			})}
			dataSource={isLoading ? loadingDataSource : dataSource}
			columns={_columns}
			rowKey={rowKey}
			components={{
				body: {
					wrapper: DraggableContainer,
					row: DraggableBodyRow,
				},
			}}
		/>
	);
}
