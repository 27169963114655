import { Empty, Select, SelectProps, Spin, Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import Tag from "@/shared/components/antdWrappers/Tag";
import type { IInboxLabel } from "@bothive_core/database";
import { CSSProperties } from "react";

interface IContactLabels extends SelectProps {
	isLabelLoading: boolean;
	inboxLabels?: IInboxLabel[];
}

export default function InboxLabelSelect({
	isLabelLoading,
	inboxLabels,
	size = "large",
	...antDProps
}: IContactLabels) {
	const intl = useIntl();
	const tagStyle: CSSProperties = {
		maxWidth: "170px",
		textOverflow: "ellipsis",
		overflowX: "hidden",
		whiteSpace: "nowrap",
		margin: "auto 0 auto",
	};

	return (
		<>
			<Select
				showSearch
				size={size}
				maxTagCount={5} // TODO: fix styling of max render
				className="loading_select"
				tagRender={(item) => {
					const isHidden = !inboxLabels?.find((label) => label._id === item.value);

					return (
						<span style={{ marginRight: "var(--spacer-xxs)", display: "grid", justifyItems: "center" }}>
							{isHidden ? (
								<Tooltip
									title={intl.formatMessage({
										id: "inbox.inbox_labels.select.hidden.label.tooltip",
										defaultMessage: "Private label of other user",
									})}
								>
									<Tag color="var(--primary-shaded)" style={tagStyle} key={item.value}>
										{intl.formatMessage({
											id: "inbox.inbox_labels.select.hidden.label",
											defaultMessage: "Hidden label",
										})}
									</Tag>
								</Tooltip>
							) : (
								item.label
							)}
						</span>
					);
				}}
				notFoundContent={
					isLabelLoading ? (
						<Spin size="small" />
					) : (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={
								<p>
									<FormattedMessage
										id="SETTINGS.CONTACTS.LABELS.EMPTY"
										defaultMessage="No labels found"
									/>
								</p>
							}
						/>
					)
				}
				filterOption={(input, option: any) =>
					option?.children?.props?.children.toLowerCase().includes(input.toLowerCase())
				}
				{...antDProps}
			>
				{inboxLabels?.map((item) => (
					<Select.Option
						key={item?._id}
						label={item.name}
						value={item?._id}
						style={{ lineHeight: 0, margin: "auto 0" }}
						className="filter-dropdown-item"
					>
						<Tag title={item.name} key={item._id} color={item.color} style={tagStyle}>
							{item.name}
						</Tag>
					</Select.Option>
				))}
			</Select>
		</>
	);
}
