export default {
	ACCOUNT_IS_FETCHING: "ACCOUNT_IS_FETCHING",
	FETCHING_ACCOUNT_FAILED: "FETCHING_ACCOUNT_FAILED",
	FETCHING_ACCOUNT_SUCCEEDED: "FETCHING_ACCOUNT_SUCCEEDED",
	ACCOUNT_IS_VALIDATING: "ACCOUNT_IS_VALIDATING",
	VALIDATING_ACCOUNT_FAILED: "VALIDATING_ACCOUNT_FAILED",
	VALIDATING_ACCOUNT_SUCCEEDED: "VALIDATING_ACCOUNT_SUCCEEDED",
	RESET_FETCH: "RESET_FETCH",

	FETCH_USER_BY_ID: "FETCH_USER_BY_ID",
	CREATE_USER: "CREATE_USER",
	CREATE_INVITE_USER: "CREATE_INVITE_USER",
	UPDATE_USER: "UPDATE_USER",
	UPDATE_USER_LOCALLY: "UPDATE_USER_LOCALLY",
	CHANGE_USER_PASSWORD: "CHANGE_USER_PASSWORD",
	ADD_GROUP_TO_USER: "ADD_GROUP_TO_USER",

	LOGIN_USER: "LOGIN_USER",
	SIGN_USER: "SIGN_USER",
	LOGOUT_USER: "LOGOUT_USER",
	DELETE_USER: "DELETE_USER",
	VALIDATE_TOKEN: "VALIDATE_TOKEN",

	VERIFY_ACCOUNT: "VERIFY_ACCOUNT",

	SIGN_OUT_USER: "SIGN_OUT_USER",

	REQUEST_NEW_VERIFICATION_CODE: "REQUEST_NEW_VERIFICATION_CODE",
	REQUEST_RESET_PASSWORD: "REQUEST_RESET_PASSWORD",

	CHANGE_AVAILABILITY: "CHANGE_AVAILABILITY",

	IDENTIFY_LOGROCKET_SESSION: "IDENTIFY_LOGROCKET_SESSION",
};
