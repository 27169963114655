import "./style.scss";

import type { IContact, IProperty } from "@bothive_core/database";
import { Button, Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";

import DeleteModal from "../../../../../../../shared/components/modal/delete_modal";
import MainForm, { IPropertyValues } from "./modalComponents/MainForm";

interface IPropertyModal {
	id?: string;
	isOpen: boolean;
	value?: IProperty;
	contact: IContact;
	onSubmit: (value: IProperty) => void;
	onUpdate: ({ id, payload }: { id: string; payload: IProperty }) => void;
	onDelete: (id: string) => void;
	onClose: () => void;
}

export default function PropertyCardModal({
	id,
	value,
	isOpen,
	contact,
	onSubmit,
	onUpdate,
	onDelete,
	onClose,
}: IPropertyModal) {
	const intl = useIntl();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const formValues = { ...(value || {}), ...(value?.address || {}) };

	const handleSubmit = (value: IPropertyValues) => {
		const { streetName, streetNumber, bus, city, postalCode, province, country, ...props } = value;

		const payload: IProperty = {
			...props,
			address: {
				streetName,
				streetNumber,
				bus,
				city,
				postalCode,
				province,
				country,
			},
			rentAmount: value.rentAmount && +value.rentAmount,
			rentedWithFurniture:
				typeof value.rentedWithFurniture === "undefined"
					? value.hasRealEstateMovablePropertySplit
					: value.rentedWithFurniture,
			purchaseDate: value.purchaseDate && new Date(value.purchaseDate),
			soldDate: value.soldDate && new Date(value.soldDate),
		};

		id ? onUpdate({ id, payload }) : onSubmit(payload);
	};
	const handleDelete = () => {
		onDelete(id as string);
		setShowDeleteModal(false);
	};

	return (
		<>
			<Modal
				open={isOpen}
				title={intl.formatMessage({
					id: id
						? "CONTACTS.DETAIL.PROPERTIES_MODAL.UPDATE_TITLE"
						: "CONTACTS.DETAIL.PROPERTIES_MODAL.CREATE_TITLE",
				})}
				onCancel={onClose}
				destroyOnClose
				className="foreign_property_modal"
				wrapClassName="contact_modal-wrapper"
				footer={[
					id && (
						<Button
							danger
							key="delete"
							type="primary"
							className="t-gap--right-sm"
							onClick={() => setShowDeleteModal(true)}
						>
							<FormattedMessage id="CONTACTS.DETAIL.PROPERTIES_MODAL.FOOTER.DELETE" />
						</Button>
					),
					<Button key="submit" type="primary" htmlType="submit" form="PropertyForm">
						<FormattedMessage id="CONTACTS.DETAIL.PROPERTIES_MODAL.FOOTER.SAVE" />
					</Button>,
				]}
			>
				<MainForm
					isOpen={isOpen}
					isStrict={!id}
					contact={contact}
					initialValues={formValues}
					onSubmit={handleSubmit}
				/>
			</Modal>
			<DeleteModal
				title={intl.formatMessage({
					id: "CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.TITLE",
					defaultMessage: "Delete foreign property",
				})}
				deleteLabel={intl.formatMessage({
					id: "CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.BUTTON",
					defaultMessage: "Delete foreign property",
				})}
				description={intl.formatMessage({
					id: "CONTACTS.DETAIL.PROPERTIES_MODAL.DELETE_MODAL.DESCRIPTION",
					defaultMessage: "Are you sure you want to delete this foreign property?",
				})}
				isVisible={showDeleteModal}
				onDelete={handleDelete}
				onClose={() => setShowDeleteModal(false)}
			/>
		</>
	);
}
