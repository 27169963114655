import "./style.scss";

import { Tag } from "antd";
import React from "react";

export default function tagRender({ label, closable, onClose }) {
	const onPreventMouseDown = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};

	return (
		<Tag
			className="select-tag"
			onMouseDown={onPreventMouseDown}
			closable={closable}
			onClose={onClose}
			style={{ marginRight: 3 }}
		>
			{label}
		</Tag>
	);
}
