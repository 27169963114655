import React from "react";

import Container from "./_atoms.dropdown.container";
import ListItemDefault from "./_atoms.dropdown.list_item_default";
import NormalHeader from "./_atoms.dropdown.normal_header";

export default ({ headerClass, iconClass, hideIcon, renderList, className, ...props }) => {
	const render = renderList || ((props) => <ListItemDefault {...props} />);

	return (
		<Container
			{...props}
			className={className}
			header={(props) => (
				<NormalHeader hideIcon={hideIcon} iconClass={iconClass} className={headerClass} {...props} />
			)}
			render={render}
		/>
	);
};
