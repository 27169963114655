import actionTypes from "./flow.actionTypes";

const initialState = {
	list: [],
	selected: undefined,

	isFetching: false,
	isFetchingDetail: false,
	fetchingFailed: false,
	fetchingSucceeded: false,
	warning: undefined,

	filter: {
		search: "",
		limit: 20,
		offset: 0,
		total: 0,
	},
};

export default function (state = initialState, action) {
	const updateState = {
		[actionTypes.FLOW_IS_FETCHING]: () => ({
			...state,
			isFetching: true,
			fetchingFailed: false,
			fetchingSucceeded: false,
		}),
		[actionTypes.FLOW_DETAIL_IS_FETCHING]: () => ({
			...state,
			isFetchingDetail: true,
			fetchingFailed: false,
			fetchingSucceeded: false,
		}),
		[actionTypes.FETCH_FLOW_FAILED]: () => ({
			...state,
			isFetching: false,
			fetchingFailed: true,
			fetchingSucceeded: false,
		}),
		[actionTypes.FETCH_FLOW_SUCCEEDED]: () => ({
			...state,
			isFetching: false,
			isFetchingDetail: false,
			fetchingFailed: false,
			fetchingSucceeded: true,
		}),

		[actionTypes.RESET_FLOW_DETAIL]: () => ({
			...state,
			selected: undefined,
		}),
		[actionTypes.FETCH_FLOWS]: () => ({
			...state,
			list: action.data.flows || [],
			filter: {
				...state.filter,
				...action.data.filter,
			},
		}),
		[actionTypes.CREATE_FLOW]: () => ({
			...state,
			list: [action.data, ...state.list.filter((flow) => flow._id !== action.data.id)],
			selected: action.data,
		}),
		[actionTypes.UPDATE_FLOWS_FILTER]: () => ({
			...state,
			filter: {
				...state.filter,
				...action.data,
			},
		}),
		[actionTypes.FETCH_FLOW_DETAILS]: () => ({
			...state,
			selected: action.data,
		}),

		[actionTypes.UPDATE_FLOW]: () => ({
			...state,
			list: state.list.map((flow) => (flow._id === action.data._id ? action.data : flow)),
			selected: action.data,
		}),
		[actionTypes.UPDATE_FLOW_LOCALLY]: () => ({
			...state,
			selected: {
				...state.selected,
				...action.data,
			},
		}),
		[actionTypes.DELETE_FLOW_LOCALLY]: () => ({
			...state,
			list: state.list.filter((flow) => flow._id !== action.data),
			selected: undefined,
			reports: undefined,
		}),
		[actionTypes.DELETE_FLOW]: () => ({
			...state,
			list: state.list.filter((flow) => flow._id !== action.data),
			selected: undefined,
			reports: undefined,
		}),
		[actionTypes.SHOW_FLOW_WARNING]: () => ({
			...state,
			warning: action.data,
		}),
		[actionTypes.HIDE_FLOW_WARNING]: () => ({
			...state,
			warning: undefined,
		}),
		[actionTypes.TEAM_CHANGED]: () => initialState,
		[actionTypes.FETCH_TEAM]: () => initialState,
	};

	return updateState[action.type] ? updateState[action.type]() : state;
}
