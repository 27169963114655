import { ApiKeyManagerRouterOutput, trpc } from "@/trpc";

type TransformedPermission = {
	key: string;
	title: string;
	description: string;
};
export default {
	formatSelectPermissions({
		apiKey,
		language,
		permissions,
	}: {
		apiKey: ApiKeyManagerRouterOutput["getById"] | null | undefined;
		language: string;
		permissions: ApiKeyManagerRouterOutput["getPermissions"] | undefined;
	}) {
		if (!permissions) {
			return [];
		}

		return permissions.reduce((prev: TransformedPermission[], permission) => {
			if (apiKey?.permissions?.some((item) => item === permission.key)) {
				return prev;
			}

			return [
				...prev,
				{
					key: permission.key,
					title: permission.name[language] || permission,
					description: permission && permission.description && permission.description[language], //IDE keeps showing 'is possibly undefined'
				},
			];
		}, []);
	},
	formatDataSource({ apiKey, language, permissions }) {
		if (!apiKey?.permissions?.length) {
			return [];
		}

		return apiKey.permissions?.map((key) => {
			const permission = permissions.find((item) => item.key === key);

			return {
				permission: {
					title: permission?.name[language] || key,
					description: permission?.description[language],
				},
				deleteKey: key,
			};
		});
	},
	checkForConflictingPermissions({ newPermissionKey, existingPermissionKeys, allPermissionKeys }) {
		const existingPermissions = allPermissionKeys.filter((item) => existingPermissionKeys.includes(item.key));

		const existingPermissionsConflict = existingPermissions.map((item) => item?.options?.permissionConflict).flat();

		if (existingPermissionsConflict.includes(newPermissionKey)) {
			return true;
		}
	},
};
