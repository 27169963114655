import { auth_redirect } from "./style.module.scss";

import { Button, Result } from "antd";
import React from "react";
import { withRouter } from "react-router-dom";

import { Fallback } from "../../components/molecules";
import { Component } from "../../components/atoms";
import { pageConfig } from "../../config";
import { queryStringHelpers } from "../../helpers";
import { FormattedMessage, useIntl } from "react-intl";

function AuthSuccessPage({ history }) {
	const intl = useIntl();
	const params = queryStringHelpers.getQueryString(history);

	if (params.error) {
		return (
			<section className="t-layout--template">
				<Fallback.MError {...pageConfig.authRedirect} />
			</section>
		);
	}

	if (window.opener) {
		window.opener.focus();
		window.opener.postMessage(params, params.origin);
	}

	window.close();

	return (
		<section className={auth_redirect}>
			<Result
				icon={<Component.ALoader />}
				title={intl.formatMessage({ id: "REDIRECT.AUTH_SUCCEEDED.TITLE" })}
				subTitle={intl.formatMessage({ id: "REDIRECT.AUTH_SUCCEEDED.DESCRIPTION" })}
				extra={
					<Button type="primary" onClick={() => window.location.reload()}>
						<FormattedMessage id="REDIRECT.AUTH_SUCCEEDED.BUTTON" />
					</Button>
				}
			/>
		</section>
	);
}

export default withRouter(AuthSuccessPage);
