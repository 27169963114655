import React, { useState } from "react";

import { List } from "..";
import { keyEvents } from "../../../helpers";
import { Input } from "../../atoms";

export default function MTagInput({ value, error, disabled, validator, className, onChange, ...props }) {
	const [_temp, setTemp] = useState("");
	const [_value, setValue] = useState(value || []);
	const [hasError, setError] = useState(error);

	const handleKeyUp = ({ event, value }) => {
		const isValid = validator ? validator(value) : true;

		if (_value.find((val) => val === value) || !isValid) {
			setError(true);
			return;
		}

		setError(false);

		if (keyEvents.enterPressed(event) && isValid) {
			const chips = [..._value, value];

			setValue(chips);
			setTemp("");
			onChange && onChange(chips);
		}
	};
	const removeChip = (item) => {
		const val = _value.filter((val) => val !== item);

		setValue(val);
		onChange && onChange(val);
	};

	let chipList;

	if (_value && _value.length) {
		chipList = <List.MChipList items={_value} onClick={!disabled ? removeChip : undefined} />;
	}

	return (
		<div className={`m-input--tag ${className}`}>
			<Input.ATextField
				{...props}
				value={_temp}
				error={hasError}
				disabled={disabled}
				onChange={(value) => setTemp(value)}
				onKeyUp={handleKeyUp}
				className="a-input--field-bordered"
			/>
			{chipList}
		</div>
	);
}

MTagInput.defaultProps = {
	value: [],
	error: false,
	className: "",
	validator: undefined,

	onChange: undefined,
	onKeyUp: undefined,
};
