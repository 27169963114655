import Editor from "@/flows/send/components/Compose/components/Editor";
import FieldLabel from "@/shared/components/field/default";
import { Form, FormInstance, Input, Select } from "antd";
import { useIntl } from "react-intl";

import { trpc } from "../../../../../../../../../trpc";
import { ShadowEmailView } from "../../../../../../../../../shared/components/shadowDom";

export default function SubjectCompose({ form }: { form: FormInstance }) {
	const intl = useIntl();

	const channelId = Form.useWatch("channelId", form);
	const signaturesQuery = trpc.emailSignature.getSignatures.useQuery({ channelId }, { refetchOnWindowFocus: false });

	if (signaturesQuery.isLoading && !signaturesQuery.data) return null;

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "2em" }}>
			<FieldLabel
				label={intl.formatMessage({
					id: "FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.COMPOSE.SUBJECT",
					defaultMessage: "Subject",
				})}
			>
				<Form.Item
					name="subject"
					style={{ width: "100%" }}
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.COMPOSE.SUBJECT.REQUIRED",
								defaultMessage: "Please fill in a subject",
							}),
						},
					]}
				>
					<Input />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				label={intl.formatMessage({
					id: "FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.COMPOSE",
					defaultMessage: "Email content",
				})}
			>
				<Form.Item
					name="body"
					style={{ width: "100%" }}
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.COMPOSE.ERROR_EMPTY",
								defaultMessage: "Please fill in a message",
							}),
						},
						{
							validator: async (_, value) => {
								if (value?.length && !value.includes("{{flow_link}}")) {
									const message = intl.formatMessage({
										id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.ERROR_NO_LINK_VARIABLE",
									});

									return Promise.reject(new Error(message));
								}
							},
						},
					]}
				>
					<Editor channelId={channelId} />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				label={intl.formatMessage({
					id: "FLOWS.FLOW_DETAIL.REMINDERS.CREATE_REMINDER_STRATEGY.SIGNATURE",
					defaultMessage: "Send with signature",
				})}
			>
				<Form.Item name="signatureId" style={{ width: "100%" }}>
					<Select allowClear optionLabelProp="name">
						{signaturesQuery.data?.data?.map((signature) => (
							<Select.Option value={signature._id} name={signature.name}>
								<ShadowEmailView html={signature.content || ""} />
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</FieldLabel>
		</div>
	);
}
