import React from "react";
import { Route, Switch } from "react-router-dom";

import { Handler } from "../../components/organisms";
import { routesConfig } from "../../config";
import { Profile } from "../../pages";

export default () => (
	<Profile.TemplatePage>
		<Handler.OErrorFallback>
			<Switch>
				<Route path={routesConfig.profile.teams} component={Profile.TeamsPage} />
				<Route path={routesConfig.profile.overview} component={Profile.ProfilePage} />
				<Route path={routesConfig.profile.root} component={Profile.ProfilePage} />
			</Switch>
		</Handler.OErrorFallback>
	</Profile.TemplatePage>
);
