import "./style.scss";

import { AttachmentFile } from "../footer";
import AttachmentCard from "@/modules/inbox/ConversationView/ConversationContent/components/AttachmentCard";
import { useState } from "react";

interface AttachmentsProps {
	attachments: AttachmentFile[];
	onChange: (attachments: AttachmentFile[]) => void;
}

export default function Attachments({ attachments, onChange }: AttachmentsProps) {
	const filteredAttachments = attachments.filter((attachment: AttachmentFile) => !attachment.isInline);

	if (!filteredAttachments?.length) return null;

	const handleDelete = async (id: string) => {
		const filteredAttachments = attachments.filter((item) => item.fileId !== id);
		onChange(filteredAttachments);
	};

	return (
		<div className="inbox-composer-footer-attachments_list">
			{filteredAttachments.map((item) => (
				<AttachmentCard
					key={item.fileId}
					fileId={item.fileId}
					onDelete={() => handleDelete(`${item.fileId}`)}
					{...item.file}
				/>
			))}
		</div>
	);
}
