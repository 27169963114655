import { Button, Tooltip } from "antd";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";

import { ContactRouterOutput, trpc } from "../../../../../../trpc";
import { Card, CreateModal, UpdateModal } from "./components";
import { Panel } from "../../../../../../shared/components/collapse";
import { IPopulatedContactLinking } from "@bothive_core/database";
interface IContactLinking {
	contact: ContactRouterOutput["getContactById"];
	panelProps?: any;
}

export default function ContactLinking({ contact, ...panelProps }: IContactLinking) {
	const intl = useIntl();
	const params: { contactId: string } = useParams();
	const [showModal, setShowModal] = useState<boolean>(false);
	const selectedContactLinkId = useRef<string | undefined>(undefined);

	const { data: contactLinks } = trpc.contactLinking.getAllContactLinksByContactId.useQuery({
		contactId: params.contactId,
	});

	const linkedContact = contactLinks?.find(({ _id }) => _id === selectedContactLinkId.current);

	const handleCardClick = (id: string) => {
		selectedContactLinkId.current = id;
		setShowModal(true);
	};
	const handleClose = () => {
		selectedContactLinkId.current = undefined;
		setShowModal(false);
	};

	return (
		<>
			<Panel
				key="contactLinking"
				title={intl.formatMessage({ id: "CONTACTS.DETAIL.ATTRIBUTES.CONTACT_LINKING" })}
				{...panelProps}
			>
				<div className="t-list--horizontal">
					{contactLinks?.map((link: any) => (
						<Card
							key={link._id}
							_id={link._id}
							type={link?.type}
							maritalStatus={link?.data?.maritalStatus}
							linkedContact={link?.linkedContact}
							onClick={handleCardClick}
						/>
					))}
					<Tooltip
						title={
							contact?.archivedAt
								? intl.formatMessage({ id: "CONTACTS.DETAIL.ATTRIBUTES.LINKING.DISABLED" })
								: undefined
						}
					>
						<Button
							type="text"
							size="large"
							style={{ width: "fit-content" }}
							onClick={() => setShowModal(true)}
							disabled={!!contact?.archivedAt}
							icon={<PlusOutlined />}
						>
							{" "}
							<FormattedMessage id="CONTACTS.DETAIL.ATTRIBUTES.CONTACT_LINKING.ADD.BUTTON" />
						</Button>
					</Tooltip>
				</div>
			</Panel>
			{!selectedContactLinkId.current && (
				<CreateModal contact={contact} isOpen={showModal} onClose={handleClose} />
			)}
			{linkedContact && (
				<UpdateModal
					contact={contact}
					isOpen={showModal}
					onClose={handleClose}
					linkedContact={linkedContact as unknown as IPopulatedContactLinking}
				/>
			)}
		</>
	);
}
