import axios from "axios";

export function setHeaders(value: Record<string, any>) {
	axios.defaults.headers.common = value;
}
export function updateHeaders(value: Record<string, any>) {
	axios.defaults.headers.common = {
		...axios.defaults.headers.common,
		...value,
	};
}
