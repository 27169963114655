import { Button, Form, Input } from "antd";
import { Dispatch, SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { DownloadOutlined, UserAddOutlined } from "@ant-design/icons";
import Filters from "../../components/Filters";

export default function ContactOverviewHeader({
	labelId,
	contactAmount,
	handleSearch,
	setContactType,
	setLabelId,
	setAccountManagerId,
	setCreateContactModal,
}: {
	labelId: string;
	contactAmount: number;
	handleSearch: (value: string) => void;
	setContactType: Dispatch<SetStateAction<string | undefined>>;
	setLabelId: Dispatch<SetStateAction<string | undefined>>;
	setAccountManagerId: Dispatch<SetStateAction<string | undefined>>;
	setCreateContactModal: Dispatch<SetStateAction<boolean>>;
}) {
	const intl = useIntl();
	const history = useHistory();
	const { team } = useParams<{ team: string }>();

	const goToImport = () => {
		history.push(`/${team}/settings/contact`);
	};

	return (
		<header className="reports-header t-gap--top">
			<Form style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button type="text" icon={<DownloadOutlined />} onClick={goToImport}>
					{" "}
					<FormattedMessage id="CONTACTS.OVERVIEW.IMPORT_CONTACT" defaultMessage="Import contacts" />
				</Button>
				<Button type="text" icon={<UserAddOutlined />} onClick={() => setCreateContactModal(true)}>
					{" "}
					<FormattedMessage id="CONTACTS.OVERVIEW.ADD_CONTACT" />
				</Button>
			</Form>
			<Form layout="vertical" className="contacts-header-filters">
				<Filters
					labelId={labelId}
					setAccountManagerId={setAccountManagerId}
					setContactType={setContactType}
					setLabelId={setLabelId}
				/>
				<Form.Item
					label={intl.formatMessage({ id: "CONTACT.OVERVIEW.SEARCH.TITLE" })}
					name="search"
					className="contacts-header-filters-search"
				>
					<Input
						size="large"
						placeholder={intl.formatMessage({
							id: "CONTACT.OVERVIEW.SEARCH.PLACEHOLDER",
						})}
						onChange={(input) => handleSearch(input.target.value)}
					/>
				</Form.Item>
			</Form>
			<div className="reports-header-footer">
				<p className="reports-header-result">
					<FormattedMessage
						id="CONTACT.FILTER.DETAIL.TOTAL_RESULTS"
						values={{
							value: contactAmount || 0,
						}}
					/>
				</p>
			</div>
		</header>
	);
}
