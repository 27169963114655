import React from "react";

import { Image } from "..";

export default function AListItemUser({ user, selected, className, onClick, onKeyUp, setRef, ...props }) {
	return (
		<li
			tabIndex="0"
			role="option"
			aria-selected={selected}
			onClick={onClick}
			onKeyUp={onKeyUp}
			ref={setRef}
			{...props}
			className={`a-dropdown-list-item a-dropdown-list-item--image ${selected ? "active" : ""} ${className}`}
		>
			<Image.AProfile src={user.avatar} className="small t-gap--right-sm" />
			<p className="a-dropdown--text">{user.username}</p>
		</li>
	);
}

AListItemUser.defaultProps = {
	value: undefined,
	selected: undefined,
	className: "",
	setRef: undefined,
	onClick: () => undefined,
	onKeyUp: () => undefined,
};
