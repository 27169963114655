import type { TContactLinkingType } from "@bothive_core/database";
import { atom } from "recoil";
import { UploadFile } from "antd/es/upload";

import { ImportMapping } from "./types";

export type ImportConfig = {
	type: "customer" | "linkedContact";
	delimiter: string;
	allowCreate: boolean;
	allowUpdate: boolean;
	linkedContactType?: TContactLinkingType;
};
export const importConfigState = atom<ImportConfig>({
	key: "ciImportConfigState",
	default: {
		delimiter: ";",
		type: "customer",
		allowCreate: true,
		allowUpdate: true,
	},
});

export const fileState = atom<UploadFile<any> | undefined>({
	key: "ciFileState",
	default: undefined,
});

export const importDataState = atom<Record<string, any>[]>({
	key: "ciImportDataState",
	default: undefined,
});

// From => To
export const importMappingState = atom<Record<string, ImportMapping>>({
	key: "ciImportMappingState",
	default: {},
});
