/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";

import { flowState } from "@/flows/common/state";
import { reportState } from "@/flows/report_detail/state";
import config from "@/flows/report_detail/template/config";
import { actions as dashboardActions } from "@/store/dashboard";

import { formatBreadcrumbs } from "./helper";

// Set breadcrumbs and tab title without re rendering children from template
export default function BrowserHeader() {
	const intl = useIntl();
	const dispatch = useDispatch();
	const routerParameters = useParams();
	const report = useRecoilValueLoadable(reportState(routerParameters));
	const flow = useRecoilValueLoadable(flowState({ id: routerParameters.flowId }));

	useEffect(() => {
		if (flow.state !== "hasValue" && !flow.contents && report.state !== "hasValue" && !report.contents) {
			return;
		}

		const breadcrumbs = formatBreadcrumbs({
			flow: flow.contents,
			report: report.contents,
			language: intl.locale,
			routerParameters,
		});

		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					...config.header,
					breadcrumbs,
					tabTitle: config.header.tabTitle,
				},
				intl,
			})
		);
	}, [flow, report]);

	return null;
}
