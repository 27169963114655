import { atom } from "recoil";

export const selectedContactsState = atom<string[]>({
	key: "selectedContactsState",
	default: [],
});

export const selectedAllContactsState = atom<boolean>({
	key: "selectedAllContactsState",
	default: false,
});
