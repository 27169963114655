// @ts-nocheck
import isEqual from "lodash.isequal";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { Dropdown, Skeleton } from "antd";
import type { IOrganization } from "@bothive_core/database";
import { DownOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

import { routesConfig } from "../../../config";
import { actions as contentActions } from "../../../store/content";
import { trpc } from "../../../trpc";
import { Image, Tooltip } from "../../atoms";
import { List } from "../../molecules";

export default function OAsideDashboard({ className }) {
	const tooltipRef = useRef(null);
	const history = useHistory();
	const [tooltipLabel, setTooltip] = useState(undefined);
	const dispatch = useDispatch();
	const state = useSelector(
		(state) => ({
			team: state.content.team.team,
			user: state.profile.account.user,
			permissions: state.dashboard.permissions.userPermissions,
			isAsideFolded: state.dashboard.ui.isAsideFolded,
		}),
		isEqual
	);

	const trpcUtils = trpc.useUtils();
	const intl = useIntl();

	const { data: memberships, isLoading } = trpc.member.getMemberships.useQuery({});

	//Only show your first 4 memberships + your current one in de team picker
	const membershipOrganizations = useMemo(
		() =>
			memberships?.data?.slice(0, 4).reduce(
				(prev, curr) => {
					if (curr.organization?._id === state.team._id) {
						return prev;
					}

					return [curr.organization, ...prev];
				},
				[{ ...state.team }]
			) || [],
		[memberships, state.team]
	);

	const currentSelectedOrganization: IOrganization | undefined = membershipOrganizations.find(
		(item) => item._id === state.team._id
	);

	useEffect(() => {
		if (state.isAsideFolded && tooltipRef?.current) {
			// @ts-ignore
			tooltipRef.current.style.display = "none";
		}
	}, [state.isAsideFolded]);

	function handleChange(value) {
		if (value.key === "goToMyTeams") {
			trpcUtils.organization.getOrganization.invalidate();

			history.push(routesConfig.profile.teams.replace(":slug", state.user.slug));

			return;
		}

		dispatch(contentActions.team.changeTeam(value.key));
	}

	function handleMouseOver({ item, event }) {
		if (!state.isAsideFolded) {
			return;
		}

		setTooltip(item.label);
		showTooltip({ event });
	}

	function showTooltip({ event }) {
		event.preventDefault();

		const yPosition = event.pageY || event.clientY;

		// @ts-ignore
		tooltipRef.current.style.top = `${yPosition}px`;
		// @ts-ignore
		tooltipRef.current.style.left = `55px`;
		// @ts-ignore
		tooltipRef.current.style.transform = "translate(0%, -50%)";
		// @ts-ignore
		tooltipRef.current.style.display = "flex";
	}

	function hideTooltip() {
		// @ts-ignore
		tooltipRef.current.style.display = "none";
		setTooltip(undefined);
	}

	// @ts-ignore
	if (!tooltipLabel && tooltipRef?.current && tooltipRef?.current?.style?.display !== "none") {
		// @ts-ignore
		tooltipRef.current.style.display = "none";
	}

	return (
		<aside className={`o-aside ${state.isAsideFolded ? "aside--folded" : ""} ${className}`}>
			<NavLink to={routesConfig.dashboard.root.replace(":team", state.team.slug)} className="o-aside--logo-link">
				<figure className="o-aside--logo">
					{state.isAsideFolded ? <Image.ALogoSmall /> : <Image.ALogoRelative />}
				</figure>
			</NavLink>
			<Dropdown
				overlayStyle={{ padding: ".5rem" }}
				trigger={["click"]}
				menu={{
					items: [
						...membershipOrganizations.map((organization) => ({
							key: organization._id,
							label: organization.name,
						})),
						{
							type: "divider",
						},
						{
							key: "goToMyTeams",
							label: intl.formatMessage({ id: "ASIDE.DROPDOWN.MY_TEAMS" }),
						},
					],
					selectedKeys: [currentSelectedOrganization?._id],
					onClick: handleChange,
				}}
			>
				<a className="aside-dropdown hide-on-small-aside" onClick={(e) => e.preventDefault()}>
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "8px" }}>
						<Skeleton active loading={isLoading} paragraph={false}>
							<p>{currentSelectedOrganization?.name}</p>
						</Skeleton>
						{/** @ts-ignore **/}
						<DownOutlined />
					</div>
				</a>
			</Dropdown>
			<div ref={tooltipRef} className="aside-tooltip">
				<Tooltip.ADefault message={tooltipLabel || " "} />
			</div>
			<div className="aside-body">
				<div>
					<List.MAsideLinksDashboard
						team={state.team}
						teamName={state.team.slug}
						IsFolded={state.isAsideFolded}
						permissions={state.permissions}
						teamType={state.team.billing.plan}
						onFocus={handleMouseOver}
						onBlur={hideTooltip}
					/>
				</div>
			</div>
		</aside>
	);
}
