import { ContactRouterOutput, trpc } from "@/trpc";
import { SmallDashOutlined, LeftOutlined } from "@ant-design/icons";
import { dateHelpers } from "@/shared/helpers";
import { ContactUniqueIdentifiers, IContact } from "@bothive_core/database";
import type { ContactImport } from "@bothive_core/database/models/contactImport";
import { Table, TableProps, Tooltip, Typography, Tag, Skeleton, Button, Result, Image, Alert, Spin } from "antd";
import { MessageDescriptor, defineMessage, useIntl, FormattedMessage } from "react-intl";
import { useParams, NavLink, useHistory } from "react-router-dom";
import { Component } from "../../../components/atoms";
import { actions as dashboardActions } from "../../../store/dashboard";
import { pageConfig } from "../../../config";
import { useDispatch } from "react-redux";
import { ReactNode, useEffect } from "react";

export default function ContactImportReport() {
	const params: { contactImportId: string; team: string } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const intl = useIntl();

	const {
		data: contactImport,
		isError,
		isLoading,
	} = trpc.contact.getContactImport.useQuery({ contactImportId: params.contactImportId });

	const conflictTableColumns: TableProps<ConflictDataRow>["columns"] = [
		{
			title: intl.formatMessage(
				{
					id: "contact.import.report.conflict_table.source_contact.title",
					defaultMessage: "{source, select, undefined {Source} other {{source}}} contact",
				},
				{ source: contactImport?.integration?.name }
			),
			dataIndex: "importContact",
			key: "importContact",
			align: "left",
			render: (contact) => SourceContact({ contact, integrationId: contactImport?.integration?._id }),
		},
		{
			title: intl.formatMessage({
				id: "contact.import.report.conflict_table.bothive_contact.title",
				defaultMessage: "Existing Bothive contact",
			}),
			dataIndex: "conflictBothiveContact",
			key: "conflictBothiveContact",
			align: "left",
			render: (contact) => BothiveContact({ contact, organizationSlug: params.team }),
		},

		{
			title: intl.formatMessage({
				id: "contact.import.report.conflict_table.recommended_action.title",
				defaultMessage: "Recommended action",
			}),
			dataIndex: "recommendedAction",
			key: "recommendedAction",
			align: "left",
			width: "var(--width-prose-lg)",
			render: (action) => RecommendedAction({ action, contactDataSource: contactImport?.integration?.name }),
		},
		{
			title: intl.formatMessage({
				id: "contact.import.report.conflict_table.conflicting_unique_identifiers.title",
				defaultMessage: "Conflicting unique identifier(s)",
			}),
			dataIndex: "conflictingUniqueIdentifiers",
			key: "conflictingUniqueIdentifiers",
			align: "left",
			render: (conflictingUniqueIdentifiers) =>
				ConflictingUniqueIdentifiers({
					conflictingUniqueIdentifiers,
					contactDataSource: contactImport?.integration?.name,
				}),
		},
	];

	useEffect(() => {
		if (!contactImport || !params.team) return;

		const breadcrumbs = pageConfig.contactImportDetail.breadCrumbs({
			contactImport: contactImport,
			team: params.team,
			locale: intl.locale,
		});

		dispatch(
			dashboardActions.ui.changePageTitle({ config: { ...pageConfig.contactImportDetail, breadcrumbs }, intl })
		);
	}, [contactImport]);

	if (isError)
		return (
			<Component.ADashboardCard style={{ margin: "auto" }}>
				<Result
					status="error"
					title={intl.formatMessage({
						id: "contact.import.report.error.title",
						defaultMessage: "Something went wrong while loading your contact import.",
					})}
				/>
			</Component.ADashboardCard>
		);

	if (isLoading)
		return (
			<Component.ADashboardCard style={{ margin: "var(--spacer-md)" }}>
				<Skeleton active style={{ width: "var(--width-prose-xl)", marginTop: "var(--spacer-md)" }}></Skeleton>
			</Component.ADashboardCard>
		);

	return (
		<div
			style={{
				overflow: "auto",
				padding: "var(--spacer-md)",
				width: "100%",
				display: "grid",
				gap: "var(--spacer-md)",
			}}
		>
			<Button
				icon={<LeftOutlined />}
				type="primary"
				style={{ marginLeft: "auto", width: "max-content" }}
				onClick={() => history.push(`/${params.team}/contact`)}
			>
				{" "}
				<FormattedMessage
					id="contact.import.report.return_to_contacts"
					defaultMessage="Back to contact overview"
				/>
			</Button>
			<Component.ADashboardCard
				style={{ maxHeight: "unset" }}
				childStyle={{ height: "auto" }}
				title={intl.formatMessage(generateContactImportStatusTitle(contactImport))}
				description={
					contactImport &&
					intl.formatMessage(generateContactImportDescription(contactImport), {
						createdBy: [contactImport.createdByUser?.firstName, contactImport.createdByUser?.lastName].join(
							" "
						),
						date: dateHelpers.getRelativeDate({
							date: contactImport.createdAt,
							alwaysIncludeTime: true,
							locale: intl.locale,
						}),
					})
				}
			>
				<Table
					style={{ margin: "0 auto", width: "100%" }}
					size="small"
					dataSource={[
						{
							key: "1",
							...(contactImport.integration?.logo && {
								source: <Image width={100} src={contactImport.integration.logo} />,
							}),
							created: contactImport.created,
							updated: contactImport.updated,
							skipped: contactImport.skipped,
						},
					]}
					columns={[
						contactImport?.integration?.logo &&
							({
								title: intl.formatMessage({
									id: "contact.import.report.table.source.title",
									defaultMessage: "Source",
								}),
								dataIndex: "source",
								key: "source",
								align: "center",
								width: "25%",
							} as const),
						{
							title: intl.formatMessage({
								id: "contact.import.report.table.created.title",
								defaultMessage: "Created",
							}),
							dataIndex: "created",
							key: "created",
							align: "center",
							width: contactImport?.integration?.logo ? "25%" : "33.33%",
						} as const,
						{
							title: intl.formatMessage({
								id: "contact.import.report.table.updated.title",
								defaultMessage: "Updated",
							}),
							dataIndex: "updated",
							key: "updated",
							align: "center",
							width: contactImport?.integration?.logo ? "25%" : "33.33%",
						} as const,
						{
							title: intl.formatMessage({
								id: "contact.import.report.table.skipped.title",
								defaultMessage: "Not imported due to conflicts",
							}),
							dataIndex: "skipped",
							key: "skipped",
							align: "center",
							width: contactImport?.integration?.logo ? "25%" : "33.33%",
						} as const,
					].filter(Boolean)}
					pagination={false}
				/>
			</Component.ADashboardCard>
			{!!contactImport?.skipped && (
				<Component.ADashboardCard
					title={intl.formatMessage(
						{
							id: "contact.import.report.skipped.title",
							defaultMessage:
								"Not {type, select, webhook {synchronized} other {imported}} contacts due to conflicts",
						},
						{ type: contactImport.type }
					)}
					description={intl.formatMessage(
						{
							id: "contact.import.report.skipped.description",
							defaultMessage: `A number of contacts could not be {type, select, webhook {synchronized} other {imported}} due to conflicting fields that must be unique. Below is an overview of these contacts and the conflicting fields. We recommend that you adjust or correct the {source, select, undefined {source} other {{source}}} data.`,
						},
						{ type: contactImport.type, source: contactImport.integration?.name }
					)}
					style={{ maxHeight: "unset" }}
					childStyle={{
						display: "grid",
						gridTemplateColumns: "1fr",
						textAlign: "left",
						height: "auto",
					}}
				>
					<Alert
						style={{ maxWidth: "var(--width-prose-xl)" }}
						type="info"
						showIcon
						message={
							<>
								<FormattedMessage
									id="contact.import.report.skipped.support"
									defaultMessage="If you need any additional information don't hesitate to contact our support team at:"
								/>{" "}
								<a href="mailto:support@bothive.be">support@bothive.be.</a>
							</>
						}
					/>
					<Table
						style={{ marginTop: "var(--spacer-sm)" }}
						size="small"
						dataSource={formatImportConflictsToTable(contactImport.importConflicts)}
						columns={conflictTableColumns}
					/>
				</Component.ADashboardCard>
			)}
		</div>
	);
}

function formatImportConflictsToTable(
	importConflicts: ContactRouterOutput["getContactImport"]["importConflicts"]
): ConflictDataRow[] {
	return Object.entries(importConflicts).map<ConflictDataRow>(([importContactUniqueId, conflictGroup]) => {
		const hasMultipleConflicts = conflictGroup.conflicts.length > 1;
		const firstConflict = conflictGroup.conflicts[0];

		const row: ConflictDataRow = {
			key: importContactUniqueId,
			importContact: firstConflict.importContact,
			conflictBothiveContact: firstConflict.conflictBothiveContact,
			conflictingUniqueIdentifiers: firstConflict.conflictingUniqueIdentifiers,
			recommendedAction: conflictGroup.recommendedAction,
		};

		if (hasMultipleConflicts) {
			row.conflictBothiveContact = conflictGroup.conflicts.length;
			row.conflictingUniqueIdentifiers = undefined;
			row["children"] = conflictGroup.conflicts.map<ConflictDataRow>((conflict) => ({
				key: importContactUniqueId ?? "",
				conflictBothiveContact: conflict.conflictBothiveContact,
				conflictingUniqueIdentifiers: conflict.conflictingUniqueIdentifiers,
			}));
		}

		return row;
	});
}

function generateContactImportStatusTitle(contactImport: ContactRouterOutput["getContactImport"]): MessageDescriptor {
	if (contactImport.type === "webhook") {
		return defineMessage({
			id: "contact.import.report.webhook.title",
			defaultMessage: "The synchronization of one or more contacts has failed via webhook",
		});
	}

	if (!contactImport.skipped)
		return defineMessage({
			id: "contact.import.report.success.title",
			defaultMessage: "Contact import succeeded 🚀",
		});

	if (!contactImport.created && !contactImport.updated)
		return defineMessage({
			id: "contact.import.report.failed.title",
			defaultMessage: "Contact import failed",
		});

	return defineMessage({
		id: "contact.import.report.failed.title",
		defaultMessage: "Contact import succeeded but ran into conflicts",
	});
}

function generateContactImportDescription(contactImport: ContactRouterOutput["getContactImport"]): MessageDescriptor {
	if (contactImport.type === "webhook")
		return defineMessage({
			id: "contact.import.report.webhook.description",
			defaultMessage: `Synchronization happened through webhook at
					{date}.`,
		});

	return defineMessage({
		id: "contact.import.report.by_person.description",
		defaultMessage: `Import started by
					{createdBy} at
					{date}.`,
	});
}

interface ConflictDataRow {
	key: string;
	importContact?: ContactImport["importConflicts"][number]["importContact"];
	/**
	 * If there are more than two conflicts for a give, contact, only show the count in the top-level row.
	 */
	conflictBothiveContact?: IContact | number;
	conflictingUniqueIdentifiers?: string[];
	children?: ConflictDataRow[];
	recommendedAction?: ContactRouterOutput["getContactImport"]["importConflicts"][number]["recommendedAction"];
}

const UNIQUE_IDENTIFIER_MAP: Record<ContactUniqueIdentifiers, { color: string; label: MessageDescriptor }> = {
	email: {
		color: "gold",
		label: defineMessage({
			id: "contact.import.report.unique_identifier.email.label",
			defaultMessage: "E-mail",
		}),
	},
	phone: {
		color: "pink",
		label: defineMessage({
			id: "contact.import.report.unique_identifier.phone.label",
			defaultMessage: "Phone",
		}),
	},
	nationalRegistrationNumber: {
		color: "green",
		label: defineMessage({
			id: "contact.import.report.unique_identifier.nationalRegistrationNumber.label",
			defaultMessage: "National registration number",
		}),
	},
	uniqueIdentifier: {
		color: "cyan",
		label: defineMessage({
			id: "contact.import.report.unique_identifier.uniqueIdentifier.label",
			defaultMessage: "Unique reference",
		}),
	},
	externalIds: {
		color: "magenta",
		label: defineMessage({
			id: "contact.import.report.unique_identifier.externalIds.label",
			defaultMessage: "{source, select, undefined {Source} other {{source}}} Unique ID",
		}),
	},
};

const RECOMMENDED_ACTION_MAP: Record<
	ContactRouterOutput["getContactImport"]["importConflicts"][number]["recommendedAction"],
	MessageDescriptor
> = {
	adapt_conflicting_fields_on_source_data: defineMessage({
		id: "contact.import.report.recommended_action.adapt_conflicting_fields_on_source_data.description",
		defaultMessage: `This contact from {source, select, undefined {your external source data} other {{source}}} conflicts with existing contacts. Adjust the conflicting fields in {source, select, undefined {your source data} other {{source}}} or remove them from the {source, select, undefined {source} other {{source}}} contact if you do not have a unique value for this contact.`,
	}),
	remove_conflicting_fields_on_source_data_without_same_source_unique_identifier: defineMessage({
		id: "contact.import.report.recommended_action.remove_conflicting_fields_on_source_data_without_same_source_unique_identifier",
		defaultMessage:
			"This contact from your {source, select, undefined {your external source data} other {{source}}} conflicts with multiple existing contacts. One of these contacts is an exact match with {source, select, undefined {your source data} other {{source}}}; no adjustments are needed here. For the other conflicting contacts, you will need to adjust the conflicting fields in {source, select, undefined {your source data} other {{source}}}.",
	}),
};

interface SourceContactProps {
	contact: ContactImport["importConflicts"][number]["importContact"];
	integrationId?: string;
}

function SourceContact({ contact, integrationId }: SourceContactProps) {
	if (!contact) return;

	const externalId = integrationId && contact.externalIds?.[integrationId];

	return (
		<div>
			<Typography.Text>{contact.fullName}</Typography.Text>{" "}
			{externalId && (
				<Typography.Text copyable code>
					{externalId}
				</Typography.Text>
			)}
		</div>
	);
}

interface BothiveContactProps {
	contact?: IContact | number;
	organizationSlug: string;
}

function BothiveContact({ contact, organizationSlug }: BothiveContactProps) {
	const intl = useIntl();

	if (typeof contact === "number") {
		return (
			<Tooltip
				title={intl.formatMessage({
					id: "contact.import.report.collapse.more",
					defaultMessage:
						"Open row to see conflicting unique fields per contact (Plus icon on the left in the row)",
				})}
			>
				<Typography.Text type="secondary">
					<FormattedMessage
						id="contact.import.report.conflict.bothive_contact.count"
						defaultMessage="{count} conflicting contacts"
						values={{ count: contact }}
					/>
				</Typography.Text>
			</Tooltip>
		);
	}

	if (!contact) {
		return (
			<Typography.Text>
				<FormattedMessage
					id="contact.import.report.conflict.bothive_contact.not_found"
					defaultMessage="Contact not found (Probably deleted)"
				/>
			</Typography.Text>
		);
	}

	return (
		<NavLink to={`/${organizationSlug}/contact/id/${contact._id}`} target="_blank">
			<Typography.Text>{contact.fullName}</Typography.Text>{" "}
			<Typography.Text copyable code>
				{contact._id}
			</Typography.Text>
			{(contact.deletedAt || contact.archivedAt) && (
				<Tag style={{ marginLeft: "var(--spacer-sm)" }} color={contact.deletedAt ? "red" : "gold"}>
					{contact.deletedAt ? (
						<FormattedMessage id="contact.import.report.contact.status.deleted" defaultMessage="Deleted" />
					) : (
						<FormattedMessage
							id="contact.import.report.contact.status.archived"
							defaultMessage="Archived"
						/>
					)}
				</Tag>
			)}
		</NavLink>
	);
}

interface RecommendedActionProps {
	action?: ContactRouterOutput["getContactImport"]["importConflicts"][number]["recommendedAction"];
	contactDataSource?: NonNullable<ContactRouterOutput["getContactImport"]["integration"]>["name"];
}

function RecommendedAction({ action, contactDataSource }: RecommendedActionProps) {
	const intl = useIntl();

	return (
		action && (
			<Typography.Text>
				{intl.formatMessage(RECOMMENDED_ACTION_MAP[action], {
					source: contactDataSource,
				})}
			</Typography.Text>
		)
	);
}

interface ConflictingUniqueIdentifiersProps {
	conflictingUniqueIdentifiers?: ContactUniqueIdentifiers[];
	contactDataSource?: NonNullable<ContactRouterOutput["getContactImport"]["integration"]>["name"];
}
function ConflictingUniqueIdentifiers({
	conflictingUniqueIdentifiers,
	contactDataSource,
}: ConflictingUniqueIdentifiersProps) {
	const intl = useIntl();

	if (!conflictingUniqueIdentifiers)
		return (
			<Tooltip
				title={intl.formatMessage({
					id: "contact.import.report.collapse.more",
					defaultMessage:
						"Open row to see conflicting unique fields per contact (Plus icon on the left in the row)",
				})}
			>
				<SmallDashOutlined />
			</Tooltip>
		);

	return (
		<ul style={{ maxWidth: "var(--width-prose-sm)" }}>
			{conflictingUniqueIdentifiers.map((field) => {
				const formattedField = UNIQUE_IDENTIFIER_MAP[field];

				return (
					<Tag style={{ margin: "var(--spacer-xs)" }} color={formattedField.color}>
						{intl.formatMessage(formattedField.label, {
							source: contactDataSource,
						})}
					</Tag>
				);
			})}
		</ul>
	);
}
