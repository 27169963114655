import "./style.scss";

import { ContactRouterOutput, trpc } from "@/trpc";
import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { Alert, Button, Dropdown, Form, Modal, Input, notification, Typography, Tooltip } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilState } from "recoil";
import DeleteModal from "../../../../shared/components/modal/delete_modal";
import { selectedAllArchivedContactsState, selectedArchivedContactsState } from "../state";
import { TContactType } from "@bothive_core/database";
interface DeleteProps {
	filters: {
		q?: string;
		contactType?: string;
		labelId?: string;
		accountManagerId?: string;
	};
	includesDeleted: boolean;
	contacts?: ContactRouterOutput["getAllContacts"];
}

const deleteOptions = ["soft_delete", "force_delete"] as const;
type TDeleteOptions = (typeof deleteOptions)[number];

export default function Delete({ filters, includesDeleted, contacts }: DeleteProps) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const trpcUtils = trpc.useUtils();
	const [selectedContacts, setSelectedContacts] = useRecoilState(selectedArchivedContactsState);
	const [selectedAll, setSelectedAll] = useRecoilState(selectedAllArchivedContactsState);
	const [showSoftDeleteBatchModal, setShowSoftDeleteBatchModal] = useState(false);
	const [showHardDeleteBatchModal, setShowHardDeleteBatchModal] = useState(false);

	const bulkDeleteContactsMutation = trpc.contact.bulkDeleteContacts.useMutation({
		onSuccess: (result) => {
			notification.success({
				message: intl.formatMessage(
					{
						id: "CONTACT.OVERVIEW.BULK_ACTION.SOFT_DELETE.SUCCESS",
						defaultMessage: "{count, plural, one {# contact} other {# contacts}} deleted successfully.",
					},
					// @ts-ignore for some reason it complains in this component and not in the others
					{ count: result.nModified }
				),
				placement: "bottomRight",
			});

			trpcUtils.contact.getAllContacts.reset();
			setShowSoftDeleteBatchModal(false);
			setSelectedAll(false);
			setSelectedContacts([]);
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "CONTACT.OVERVIEW.BULK_ACTION.SOFT_DELETE.ERROR",
					defaultMessage: "Something went wrong while executing bulk delete.",
				}),
				placement: "bottomRight",
			});
		},
	});
	const bulkForceDeleteContactsMutation = trpc.contact.bulkForceDeleteContacts.useMutation({
		onSuccess: (result) => {
			notification.success({
				message: intl.formatMessage(
					{
						id: "CONTACT.OVERVIEW.BULK_ACTION.FORCE_DELETE.SUCCESS",
						defaultMessage: "{count, plural, one {# contact} other {# contacts}} deleted successfully.",
					},
					// @ts-ignore for some reason it complains in this component and not in the others
					{ count: result.total }
				),
				placement: "bottomRight",
			});

			trpcUtils.contact.getAllContacts.reset();
			setShowSoftDeleteBatchModal(false);
			setSelectedAll(false);
			setSelectedContacts([]);
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "CONTACT.OVERVIEW.BULK_ACTION.FORCE_DELETE.ERROR",
					defaultMessage: "Something went wrong while executing bulk delete.",
				}),
				placement: "bottomRight",
			});
		},
	});

	const handleSoftDelete = () => {
		bulkDeleteContactsMutation.mutate({
			contactIds: selectedContacts,
			deleteAll: selectedAll,
			filters: { ...filters, archived: true },
		});
	};
	const handleForceDelete = () => {
		bulkForceDeleteContactsMutation.mutate({
			contactIds: selectedContacts,
			filters: {
				...filters,
				includeDeleted: includesDeleted,
				contactType: filters.contactType as TContactType,
			},
		});
	};

	const { data: deletedContacts } = trpc.contact.getAllContacts.useQuery(
		{
			limit: 0,
			...filters,
			archived: true,
			//
			deleted: true,
		},
		{ enabled: includesDeleted }
	);

	const totalSelected = selectedAll
		? deletedContacts?.data?.length
		: contacts?.data?.filter((contact) => selectedContacts.includes(contact._id)).length;

	const deletedInSelectionAmount = selectedAll
		? // @ts-ignore
		  deletedContacts?.data?.filter((contact) => contact.deletedAt).length
		: // @ts-ignore
		  contacts?.data?.filter((contact) => selectedContacts.includes(contact._id) && contact.deletedAt).length;

	const menuProps = {
		items: [
			{
				label: (
					<Tooltip
						title={
							!!deletedInSelectionAmount &&
							intl.formatMessage(
								{
									id: "contact.overview.bulk_action.delete.button.soft_delete.tooltip",
									defaultMessage:
										"You have {count, plural, one {# contact} other {# contacts}} that are already deleted inside your selection, you can't perform a delete action on contacts that are already deleted",
								},
								{
									count: deletedInSelectionAmount,
								}
							)
						}
					>
						<p>
							<FormattedMessage
								id="contact.overview.bulk_action.delete.button.soft_delete"
								defaultMessage="Soft delete"
							/>
						</p>
					</Tooltip>
				),
				key: "soft_delete",
				icon: <DeleteOutlined />,
				disabled: !!deletedInSelectionAmount,
			},
			{
				label: (
					<Tooltip
						title={intl.formatMessage(
							{
								id: "contact.overview.bulk_action.delete.button.force_delete.tooltip",
								defaultMessage:
									"Immediately delete {count, plural, one {# contact} other {# contacts}}.",
							},
							{
								count: totalSelected,
							}
						)}
					>
						<p>
							<FormattedMessage
								id="contact.overview.bulk_action.delete.button.force_delete"
								defaultMessage="Force delete"
							/>
						</p>
					</Tooltip>
				),
				danger: true,
				key: "force_delete",
				icon: <CloseCircleOutlined />,
			},
		] satisfies { [key: string]: any; key: TDeleteOptions }[],
		onClick: (event: any & { key: TDeleteOptions }) => {
			if (event.key === "force_delete") return setShowHardDeleteBatchModal(true);
			return setShowSoftDeleteBatchModal(true);
		},
	};
	const compareValue = intl.formatMessage({
		id: "contact.overview.bulk_action.force_delete.value",
		defaultMessage: "FORCE DELETE",
	});

	return (
		<>
			<Dropdown menu={menuProps}>
				<Button
					icon={<DeleteOutlined />}
					type={showSoftDeleteBatchModal ? "primary" : "text"}
					loading={bulkDeleteContactsMutation.isLoading || bulkForceDeleteContactsMutation.isLoading}
				>
					{" "}
					<FormattedMessage id="CONTACT.OVERVIEW.BULK_ACTION.DELETE.BUTTON.TITLE" defaultMessage="Delete" />
				</Button>
			</Dropdown>

			<DeleteModal
				title={intl.formatMessage({
					id: selectedAll
						? "CONTACTS.OVERVIEW.DELETE.ALL.MODAL.TITLE"
						: "CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.TITLE",
					defaultMessage: selectedAll ? "Delete all contacts" : "Delete contacts",
				})}
				showDescriptionIcon
				additionalInfo={
					<p className="t-gap--top-sm" style={{ color: "GrayText", fontSize: "0.9em" }}>
						<FormattedMessage
							id="CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.SIDE_EFFECTS.DESCRIPTION"
							defaultMessage="Please note: if you have deleted a contact but have not deleted it in your software with which you automatically sync, this contact can be re-imported after 90 days."
						/>
					</p>
				}
				descriptionType="warning"
				description={intl.formatMessage({
					id: "CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.DESCRIPTION",
					defaultMessage:
						"Deleted contacts will still be visible at archived contacts, reports, linked contacts & files. They will be deleted forever after a period of 90 days.",
				})}
				deleteLabel={intl.formatMessage({
					id: selectedAll
						? "CONTACTS.OVERVIEW.DELETE.ALL.MODAL.BUTTON"
						: "CONTACTS.OVERVIEW.DELETE.BATCH.MODAL.BUTTON",
					defaultMessage: selectedAll ? "Delete all contacts" : "Delete contacts",
				})}
				isVisible={showSoftDeleteBatchModal}
				isDeleting={bulkDeleteContactsMutation.isLoading}
				onDelete={handleSoftDelete}
				onClose={() => setShowSoftDeleteBatchModal(false)}
			/>
			<Modal
				mask={true}
				footer={null}
				maskClosable={true}
				open={showHardDeleteBatchModal}
				onCancel={() => setShowHardDeleteBatchModal(false)}
				title={intl.formatMessage({
					id: "contact.overview.bulk_action.force_delete_modal.title",
					defaultMessage: "Force delete contacts",
				})}
			>
				<Form
					form={form}
					preserve={false}
					layout="vertical"
					id="bulkChangeStatus"
					requiredMark={false}
					onFinish={handleForceDelete}
					initialValues={{ isDeleted: "" }}
				>
					<Alert
						showIcon
						type="warning"
						className="t-gap--bottom-sm"
						message={intl.formatMessage(
							{
								id: "contact.overview.bulk_action.force_delete_modal.info_message",
								defaultMessage:
									"You're about to delete {total, plural, one {1 contact} other {# contacts}}. This action is irreversible and will delete all data forever.",
							},
							{ total: totalSelected }
						)}
					/>
					<Typography.Text>
						<FormattedMessage
							id="contact.overview.bulk_action.force_delete_modal.description"
							defaultMessage="<bold>We recommend using soft delete</bold> this gives you 90 days before the your data will be effectively removed."
							values={{
								total: totalSelected,
								bold: (content) => <Typography.Text strong>{content}</Typography.Text>,
							}}
						/>
					</Typography.Text>
					<ul className="force_delete_contacts-list">
						<li>
							<FormattedMessage
								id="contact.overview.bulk_action.force_delete_modal.explain.contacts"
								defaultMessage="We will remove the contacts and all linked data"
							/>
						</li>
						<li>
							<FormattedMessage
								id="contact.overview.bulk_action.force_delete_modal.explain.linked_contact"
								defaultMessage="We will remove all linked contacts from {total, plural, one {this contact} other {these contacts}}"
								values={{ total: totalSelected }}
							/>
						</li>
						<li>
							<FormattedMessage
								id="contact.overview.bulk_action.force_delete_modal.explain.reports"
								defaultMessage="We will remove all reports from {total, plural, one {this contact} other {these contacts}}"
								values={{ total: totalSelected }}
							/>
						</li>
						<li>
							<FormattedMessage
								id="contact.overview.bulk_action.force_delete_modal.explain.files"
								defaultMessage="We will remove all files from {total, plural, one {this contact} other {these contacts}}"
								values={{ total: totalSelected }}
							/>
						</li>
					</ul>
					<Form.Item
						name="isDeleted"
						rules={[
							{
								validator: async (_, value: string) => {
									if (value !== compareValue) {
										const message = intl.formatMessage(
											{
												id: "flows.report.overview.bulk_action.modal.force_delete_contacts.invalid",
												defaultMessage: "Type '{{value}}' in capital case to delete contacts",
											},
											{ value: compareValue }
										);

										return Promise.reject(new Error(message));
									}
								},
							},
						]}
						help={intl.formatMessage(
							{
								id: "flows.report.overview.bulk_action.modal.force_delete_contacts.help",
								defaultMessage: "Type {value} in capital case to contacts reports",
							},
							{ value: compareValue }
						)}
					>
						<Input
							size="large"
							placeholder={intl.formatMessage(
								{
									id: "flows.report.overview.bulk_action.modal.force_delete_contacts.placeholder",
									defaultMessage: "Type {value}",
								},
								{ value: compareValue }
							)}
						/>
					</Form.Item>

					<div className="force_delete_contacts-footer">
						<Button type="text">
							<FormattedMessage
								id="contact.overview.bulk_action.force_delete_modal.cancel"
								defaultMessage="Cancel"
							/>
						</Button>

						<Form.Item shouldUpdate className="force_delete_contacts-footer-submit">
							{() => {
								const isDisabled =
									!form.isFieldsTouched(true) ||
									!!form.getFieldsError().filter(({ errors }) => errors.length).length;

								return (
									<Button
										danger
										type="primary"
										htmlType="submit"
										disabled={isDisabled}
										loading={bulkForceDeleteContactsMutation.isLoading}
									>
										{" "}
										<FormattedMessage
											id="contact.overview.bulk_action.force_delete_modal.confirm"
											defaultMessage="Delete {total, plural, one {1 contact} other {# contacts}}"
											values={{ total: totalSelected }}
										/>
									</Button>
								);
							}}
						</Form.Item>
					</div>
				</Form>
			</Modal>
		</>
	);
}
