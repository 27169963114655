import { IChannel, IIntegration, SyncStatus } from "@bothive_core/database";
import { Statistic, Skeleton, Image, Tooltip } from "antd";
import { FormattedDate, FormattedMessage, MessageDescriptor, defineMessage, useIntl } from "react-intl";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, LoadingOutlined } from "@ant-design/icons";

import DashboardCard from "@/shared/components/card/dashboard";
import { ReactNode } from "react";

export const SYNC_STATUS_ICON_CONFIG: Record<SyncStatus | "DEFAULT", { info: MessageDescriptor; icon: ReactNode }> = {
	PENDING: {
		info: defineMessage({ id: "channel.status.pending.tooltip", defaultMessage: "Synchronization in progress" }),
		icon: <LoadingOutlined className="statistic-content-icon" />,
	},
	SUCCESS: {
		info: defineMessage({ id: "channel.status.success.tooltip", defaultMessage: "Synchronization succeeded" }),
		icon: <CheckCircleOutlined className="statistic-content-icon success" />,
	},
	DEFAULT: {
		info: defineMessage({ id: "channel.status.connected.tooltip", defaultMessage: "Channel connected" }),
		icon: <CheckCircleOutlined className="statistic-content-icon success" />,
	},
	FAILED: {
		info: defineMessage({ id: "channel.status.failed.tooltip", defaultMessage: "Synchronization failed" }),
		icon: <CloseCircleOutlined className="statistic-content-icon error" />,
	},
	IN_PROGRESS: {
		info: defineMessage({
			id: "channel.status.in_progress.tooltip",
			defaultMessage: "Synchronization in progress",
		}),
		icon: <LoadingOutlined className="statistic-content-icon" />,
	},
	DELETING: {
		info: defineMessage({
			id: "channel.status.deleting.tooltip",
			defaultMessage: "Channel delete in progress",
		}),
		icon: <DeleteOutlined className="statistic-content-icon error" />,
	},
};

interface IChannelAnalytics {
	channel?: IChannel | null;
	integration?: IIntegration;
	isLoading: boolean;
}
export default function ChannelAnalytics({ channel, integration, isLoading }: IChannelAnalytics) {
	const intl = useIntl();

	const dateFormatter = (value) => {
		return <FormattedDate value={value} month="2-digit" day="2-digit" year="numeric" />;
	};

	return (
		<DashboardCard
			title={intl.formatMessage({
				id: "settings.channel_detail.analytics.title",
				defaultMessage: "Channel analytics",
			})}
		>
			<div className="channels_detail-analytics t-gap--top">
				<div className="channels_detail-analytics-statistic">
					<p className="ant-statistic-title">
						<FormattedMessage
							id="settings.channel_detail.analytics.integration"
							defaultMessage="Connected integration"
						/>
					</p>
					{isLoading && <Skeleton.Avatar active size={32} shape="square" />}
					{!isLoading && (
						<div className="statistic-content">
							<Image
								src={integration?.logo}
								height="32px"
								width="32px"
								preview={false}
								style={{ objectFit: "contain" }}
							/>
							<p className="statistic-content-text">{integration?.name}</p>
						</div>
					)}
				</div>
				<Statistic
					title={intl.formatMessage({
						id: "settings.channel_detail.analytics.connected",
						defaultMessage: "Connected on",
					})}
					// @ts-ignore
					value={channel?.createdAt}
					formatter={dateFormatter}
					loading={isLoading}
				/>
				<Statistic
					title={intl.formatMessage({
						id: "settings.channel_detail.analytics.last_synced",
						defaultMessage: "Last synced",
					})}
					// @ts-ignore
					value={
						channel?.lastSyncAt ||
						intl.formatMessage({
							id: "settings.channel_detail.analytics.not_applicable",
							defaultMessage: "N/A",
						})
					}
					formatter={channel?.lastSyncAt && dateFormatter}
					loading={isLoading}
				/>
				<div className="channels_detail-analytics-statistic">
					<p className="ant-statistic-title">
						<FormattedMessage
							id="settings.channel_detail.analytics.is_syncing"
							defaultMessage="Status"
							description="Title above icon to indicate channel is sync'ing or has done syncing"
						/>
					</p>
					<Tooltip title={intl.formatMessage(SYNC_STATUS_ICON_CONFIG[channel?.syncStatus || "DEFAULT"].info)}>
						{SYNC_STATUS_ICON_CONFIG[channel?.syncStatus || "DEFAULT"].icon}
					</Tooltip>
				</div>
			</div>
		</DashboardCard>
	);
}
