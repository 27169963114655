import "./style.scss";

import {
	$createRangeSelection,
	$setSelection,
	INSERT_LINE_BREAK_COMMAND,
	INSERT_PARAGRAPH_COMMAND,
	LexicalEditor,
} from "lexical";
import { useEffect, useRef } from "react";

import HtmlEditor from "@/shared/components/editor/htmlEditor";
import { INSERT_SIGNATURE_COMMAND } from "@/shared/components/editor/plugin";
import { trpc } from "../../../../../../trpc";
import { OptionTools } from "@/shared/components/editor/htmlEditor/types";

export default function Editor({
	value,
	onChange,
	channelId,
	tools = [],
}: {
	value?: string;
	channelId?: string;
	tools?: OptionTools[];
	onChange?: (value: string | undefined) => void;
}) {
	const editorRef = useRef<LexicalEditor>();

	const signaturesQuery = trpc.emailSignature.getSignatures.useQuery({ channelId });

	function handleInsertSignature() {
		if (!editorRef.current) return;

		editorRef.current.dispatchCommand(INSERT_SIGNATURE_COMMAND, { channelId });
	}

	useEffect(() => {
		if (!tools.includes("signature")) return;
		if (!editorRef.current || !channelId || !signaturesQuery.data?.total) return;

		// Move cursor to top so we inject signature before previous conversation
		editorRef.current.update(() => $setSelection($createRangeSelection()));
		// Inject 2 paragraphs and line break, then signature and move cursor back to start point
		editorRef.current.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined);
		editorRef.current.dispatchCommand(INSERT_LINE_BREAK_COMMAND, true);

		handleInsertSignature();
		// Move cursor back to top
		editorRef.current.update(() => $setSelection($createRangeSelection()));
	}, [channelId, signaturesQuery.data?.total]);

	return (
		<HtmlEditor
			value={value}
			tools={tools}
			onChange={onChange}
			channelId={channelId}
			className="layout-modal-flow-send-compose"
		/>
	);
}
