import React from "react";

import { typesConfig } from "../../../config";
import { Article } from "../../molecules";

const items = [
	{
		label: "CONTACT_DETAILS.CONTACT_INFO.META.PHONE.LABEL",
		key: "phone",
		type: typesConfig.cellTypes.string,
	},
	{
		label: "CONTACT_DETAILS.CONTACT_INFO.META.BIRTHDAY.LABEL",
		key: "birthday",
		type: typesConfig.cellTypes.date,
	},
	{
		label: "CONTACT_DETAILS.CONTACT_INFO.META.REFERENCE_ID.LABEL",
		key: "uniqueIdentifier",
		type: typesConfig.cellTypes.string,
	},
	{
		label: "CONTACT_DETAILS.CONTACT_INFO.META.STREET.LABEL",
		key: "street",
		type: typesConfig.cellTypes.string,
	},
	{
		label: "CONTACT_DETAILS.CONTACT_INFO.META.CITY.LABEL",
		key: "city",
		type: typesConfig.cellTypes.string,
	},
	{
		label: "CONTACT_DETAILS.CONTACT_INFO.META.POSTAL_CODE.LABEL",
		key: "postalCode",
		type: typesConfig.cellTypes.string,
	},
];

export default function OContact({ contact, className, onSendMessage, onMetaClick }) {
	if (!contact) return null;

	const primaryAddress = contact.address?.find((item) => item.primary);
	const meta = {
		birthday: contact?.birthday,
		phone: contact?.phone?.find((item) => item.isPrimary)?.value,
		uniqueIdentifier: contact?.uniqueIdentifier,
		street: primaryAddress ? [primaryAddress.streetName, primaryAddress.streetNumber].join(" ") : "-",
		city: primaryAddress?.city || "-",
		postalCode: primaryAddress?.postalCode || "-",
	};

	return (
		<div className={`o-section o-section--contact ${className}`}>
			<Article.MContactInfo {...contact} onClick={onSendMessage} className="contact-info" />
			<Article.MMetaInfo
				items={items}
				meta={meta}
				buttonLabel="CONTACT_DETAILS.CONTACT_INFO.META.BUTTON.SEE_ALL"
				className="contact-meta"
				onClick={onMetaClick}
			/>
		</div>
	);
}

OContact.defaultProps = {
	className: "",
	onSendMessage: undefined,
	onMetaClick: undefined,
};
