import { defaultValue } from "../../../config";
import actionTypes from "./header.actionTypes";

const initialState = {
	uniqueId: undefined,
	token: undefined,
	sessionId: undefined,
	lang: defaultValue.language,
	isFetching: false,
	fetchingFailed: false,
	fetchingSucceeded: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.HEADER_IS_FETCHING:
			return {
				...state,
				isFetching: true,
				fetchingFailed: false,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCHING_HEADER_FAILED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: true,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCHING_HEADER_SUCCEEDED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: false,
				fetchingSucceeded: true,
			};
		case actionTypes.CHECK_FOR_USER_LANGUAGE:
		case actionTypes.SET_USER_LANGUAGE:
			return {
				...state,
				lang: action.data || state.lang,
			};
		case actionTypes.SET_USER_TOKEN:
		case actionTypes.CHECK_FOR_USER_TOKEN:
			return {
				...state,
				token: action.data,
			};
		case actionTypes.INIT_DASHBOARD_SESSION:
			return {
				...state,
				sessionId: action.data,
			};
		default:
			return state;
	}
}
