import React from "react";

import { Header } from "../../molecules";
import OPopupBase from "./_organisms.popup.base";

export default function OPopupGeneral({ title, formattedValues, onClose, className, children }) {
	return (
		<OPopupBase onClose={onClose} className={className}>
			<Header.MHeaderPopup title={title} formattedValues={formattedValues} />
			<article className="o-popup--general-body popup--body">{children}</article>
		</OPopupBase>
	);
}

OPopupGeneral.defaultProps = {
	formattedValues: undefined,
	children: undefined,
	className: "",
};
