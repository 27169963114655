import "./style.scss";

import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { conditionValueTranslations } from "./config";
import ConditionRow from "./conditionRow";
import { RuleType, TCondition } from "./type";

interface IConditionComponent {
	value?: TCondition[];
	fileKeys: string[];
	onChange?: (value: TCondition[]) => void;
}

function ConditionComponent({ value = [], fileKeys, onChange }: IConditionComponent) {
	const fieldOptions = useMemo(() => {
		return (
			fileKeys?.map((item: string) => ({ value: item, label: conditionValueTranslations[item] || item })) || []
		);
	}, [fileKeys]);

	const handleAddCondition = (type: RuleType) => {
		if (!onChange) return;

		const defaultCondition: TCondition = {
			type: value.length ? type : "if",
			field: fileKeys[0],
			value: "IS_DEFINED",
		};

		onChange([...value, defaultCondition]);
	};
	const handleChange = ({ index, condition }: { index: number; condition: TCondition }) => {
		if (!onChange) return;

		const _val = [...value];

		_val[index] = condition;

		onChange(_val);
	};
	const handleDelete = (index: number) => {
		if (!onChange) return;

		const _val = [...value];

		_val.splice(index, 1);

		if (!_val?.length) return onChange(_val);

		if (_val[0].type !== "if") _val[0].type = "if";

		onChange(_val);
	};

	return (
		<div className="condition_component">
			{value?.map((condition, index) => (
				<ConditionRow
					key={`${condition.type}_${index}`}
					condition={condition}
					fieldOptions={fieldOptions}
					onChange={(condition) => handleChange({ condition, index })}
					onDelete={() => handleDelete(index)}
				/>
			))}
			<div style={{ display: "flex", gap: "2em", flexDirection: "row", alignItems: "center" }}>
				<Button
					style={{ width: "fit-content" }}
					type="text"
					icon={<PlusOutlined />}
					onClick={() => handleAddCondition("and")}
				>
					{""} <FormattedMessage id="CONTACTS.SETTINGS.ADD.RULE.MODAL.ADD_AND" />
				</Button>
				<Button
					style={{ width: "fit-content" }}
					type="text"
					icon={<PlusOutlined />}
					onClick={() => handleAddCondition("or")}
				>
					{""} <FormattedMessage id="CONTACTS.SETTINGS.ADD.RULE.MODAL.ADD_OR" />
				</Button>
			</div>
		</div>
	);
}

export default ConditionComponent;
