import "./style.scss";

import SkeletonTable from "@/shared/components/table/skeleton";
import { actions as dashboardActions } from "@/store/dashboard";
import { trpc } from "@/trpc";
import { EditOutlined } from "@ant-design/icons";
import { IMember } from "@bothive_core/database";
import { Button, Table, Tooltip, Typography } from "antd";
import isEqual from "lodash.isequal";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CreateModal from "./components/AddMember";
import EditModal from "./components/EditMember";

export default function MembersOverviewSettings() {
	const intl = useIntl();
	const dispatch = useDispatch();
	const routeParameters = useParams<{ team: string }>();
	const [showAddModal, setAddModal] = useState(false);
	const [showEditModal, setEditModal] = useState(false);
	const [selectedMember, setSelectedMember] = useState<IMember>();
	const { isLoading, data: members } = trpc.member.getAll.useQuery({ limit: 0 });

	useEffect(() => {
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					title: intl.formatMessage({
						id: "organization_settings.members.breadcrumbs.title",
						defaultMessage: "Members",
					}),
					tabTitle: intl.formatMessage({
						id: "organization_settings.members.breadcrumbs.tab_title",
						defaultMessage: "Members",
					}),
					breadcrumbs: [
						{
							to: `/${routeParameters.team}/settings`,
							name: intl.formatMessage({
								id: "organization_settings.members.breadcrumbs.settings",
								defaultMessage: "Settings",
							}),
						},
						{
							to: `/${routeParameters.team}/settings/members`,
							name: intl.formatMessage({
								id: "organization_settings.members.breadcrumbs.self",
								defaultMessage: "Members",
							}),
						},
					],
				},
			})
		);
	}, []);

	const { allowEditing, membersPermissions } = useSelector(
		(state) => ({
			membersPermissions: state.dashboard.permissions?.users,
			allowEditing: state.dashboard.permissions?.userPermissions?.manageUsers,
		}),
		isEqual
	);
	const isBillingLocked = membersPermissions <= (members?.meta?.total || 0);

	const handleRowClick = (member: IMember) => {
		if (!allowEditing) return;

		setSelectedMember(member);
		setEditModal(true);
	};

	return (
		<section className="member_settings">
			{allowEditing && (
				<Tooltip
					title={
						isBillingLocked
							? intl.formatMessage({
									id: "organization_settings.members.table.button.tooltip",
									defaultMessage: "Upgrade your plan to invite new members",
							  })
							: null
					}
					placement="left"
				>
					<Button type="primary" onClick={() => setAddModal(true)} disabled={isBillingLocked}>
						<FormattedMessage id="organization_settings.members.table.button" defaultMessage="New user" />
					</Button>
				</Tooltip>
			)}
			<SkeletonTable
				rowKey="_id"
				isLoading={isLoading}
				dataSource={members?.data}
				className="member_settings-table"
				onRow={
					membersPermissions ? (contact: IMember) => ({ onClick: () => handleRowClick(contact) }) : () => {}
				}
			>
				<Table.Column
					key="name"
					dataIndex={[]}
					title={<FormattedMessage id="organization_settings.members.table.profile" defaultMessage="User" />}
					render={(user) => (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								gap: "16px",
								width: "100%",
								alignItems: "center",
								cursor: allowEditing ? "pointer" : "default",
							}}
							key={user}
						>
							<img src={user.avatar} style={{ width: "40px", height: "40px" }} title={user.name}></img>
							<Typography.Text>{user?.name}</Typography.Text>
						</div>
					)}
				/>
				{membersPermissions && (
					<Table.Column
						key="edit"
						width="42px"
						dataIndex={[]}
						render={() => (
							<Button
								style={{ marginLeft: "auto" }}
								type="text"
								icon={allowEditing ? <EditOutlined /> : null}
								size="small"
							/>
						)}
					/>
				)}
			</SkeletonTable>
			<EditModal
				allowEditing={allowEditing}
				showModal={showEditModal}
				onClose={() => setEditModal(false)}
				member={selectedMember as IMember}
			/>
			<CreateModal allowEditing={allowEditing} showModal={showAddModal} onClose={() => setAddModal(false)} />
		</section>
	);
}
