import { ApartmentOutlined, BankFilled, LockFilled, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { InboxLabelType } from "@bothive_core/database";
import { ReactNode } from "react";
import { MessageDescriptor, defineMessage } from "react-intl";

export default {
	labelTypeIntl: new Map([
		[
			"user",
			defineMessage({
				id: "inbox.label.type.user.label",
				defaultMessage: "Private",
				description:
					"Label specific for this user, we save it under the 'user' type but users only need to know that it is private.",
			}),
		],
		[
			"channel",
			defineMessage({
				id: "inbox.label.type.channel.label",
				defaultMessage: "Channel",
			}),
		],
		[
			"organization",
			defineMessage({
				id: "inbox.label.type.organization.label",
				defaultMessage: "Organization",
			}),
		],
	]) satisfies Map<Omit<InboxLabelType, "system">, MessageDescriptor>,
	labelDescriptionIntl: new Map([
		[
			"user",
			defineMessage({
				id: "inbox.label.type.user.description",
				defaultMessage: "Private labels are only visible for yourself",
				description: "Explain private labels are only visible to the user them self",
			}),
		],
		[
			"channel",
			defineMessage({
				id: "inbox.label.type.channel.description",
				defaultMessage:
					"Channel labels are synced to the linked channel and visible to everyone who uses this channel",
				description:
					"Explain channel labels are 2-way synced to the external channel and is visible to everyone who has connected this channel to their inbox",
			}),
		],
		[
			"organization",
			defineMessage({
				id: "inbox.label.type.organization.description",
				defaultMessage: "Organization labels are visible and usable by everyone in your organization",
				description: "Explain Organization labels are visible and usable to everyone in your organization",
			}),
		],
	]) satisfies Map<Omit<InboxLabelType, "system">, MessageDescriptor>,
	labelTypeIcon: new Map([
		["user", (color = "var(--primary)") => <LockFilled style={{ color }} />],
		["channel", (color = "var(--primary)") => <ApartmentOutlined style={{ color }} />],
		["organization", (color = "var(--primary)") => <BankFilled style={{ color }} />],
	]) satisfies Map<Omit<InboxLabelType, "system">, (color: string) => ReactNode | null>,
};
