import axios from "axios";

import config from "../../config";

export const copyStatusTypes = async ({ flowId, payload }) => {
	const url = config.api.copy.replace(":flowId", flowId);
	const { data } = await axios.put(url, payload);

	if (!data.success) {
		throw new Error();
	}

	return data;
};
