const billingKeys = {
	basic: "basic",
	premium: "premium",
};

export default {
	keys: {
		editBilling: "editBilling",
		superAdmin: "superAdmin",
		standard: "standard",
	},

	billingKeys,
	hasPremiumPrivileges: (type) => type === billingKeys.basic || type === billingKeys.premium,
};
