import "./style.scss";

import { CopyOutlined } from "@ant-design/icons";
import { Button, Form, Input, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useMemo } from "react";

import { setupArrow } from "../../../assets/images/icon";
import { clipboardHelpers } from "@/shared/helpers/";
import FieldLabel from "@/shared/components/field/default";
import ShowSuccessBanner from "@/shared/components/banner/success";
import ShowFailedBanner from "@/shared/components/banner/failed";
import { trpc } from "../../../trpc";
import AsidePreview from "../flows/asidePreview";
import SetupSteps from "../components/SetupSteps";

export default function FlowSetup() {
	const intl = useIntl();
	const history = useHistory();
	const routerParams = useParams<{ slug: string }>();
	const { data: apiKey } = trpc.apiKeyManager.getWidgetKey.useQuery();
	const { data: organization } = trpc.organization.getOrganization.useQuery();
	const { data: flows } = trpc.flow.getOrganizationFlows.useQuery({ limit: 6, compact: true });
	const sendKeyToWebDeveloper = trpc.apiKeyManager.sendKeyToWebDeveloper.useMutation();
	const previewList = useMemo(() => flows?.data?.map((item) => item.template), [flows]);
	const codeBlock = `<head>
	<script src="https://widget.bothive.be"></script>
</head>
<body>
	<div id="flow-widget" style="width:100%;height: 100%;"></div>
	<script>
		Bothive.widget.initFlowPage({
			apiKey: "${apiKey?.key}"
			config: { container: "#flow-widget" },
		});
	</script>
</body>`;

	const handleSubmit = async ({ email }: { email: string }) => {
		try {
			if (!apiKey || !email) throw new Error();

			await sendKeyToWebDeveloper.mutateAsync({ email, apiKey: apiKey.key as string });

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "setup.flow_setup.send_email.success",
					defaultMessage: "Email has been send to your web-developer.",
				}),
				intl,
			});
			handleGoToNext();
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "setup.flow_setup.send_email.failed",
					defaultMessage: "Could send the instructions to your web developer, please try again later.",
				}),
				intl,
			});
		}
	};

	const handleCopyCode = () => {
		clipboardHelpers.copyToClipboard(codeBlock);
		ShowSuccessBanner({
			title: intl.formatMessage({
				id: "setup.flow_setup.code.copied_to_clipboard",
				defaultMessage: "Code copied to clipboard.",
			}),
			intl,
		});
	};

	const handleGoToNext = () => {
		history.push(`/${routerParams.slug}`);
	};

	return (
		<main className="setup">
			<section className="setup-body">
				<SetupSteps currentStep={2} />
				<article className="setup_content">
					<header className="setup_content-header">
						<Typography.Title>
							<FormattedMessage id="setup.flow_setup.title" defaultMessage="Flow widget" />
						</Typography.Title>
						<p>
							<FormattedMessage
								id="setup.flow_setup.description"
								defaultMessage="Enter the e-mail address of your web developer so that we can send all necessary information to place the flow widget on your website."
							/>
						</p>
					</header>

					<Form id="flowSetupForm" className="setup_content-form">
						<div className="setup-flow_setup-wrapper">
							<FieldLabel
								id="email"
								label={intl.formatMessage({
									id: "setup.flow_setup.form.web_developer.label",
									defaultMessage: "E-mail web-developer",
								})}
							>
								<Form.Item
									name="email"
									required
									className="setup-flow_setup-email_input"
									rules={[
										{
											required: true,
											message: intl.formatMessage({
												id: "setup.flow_setup.form.web_developer.is_required",
												defaultMessage: "The email of your web-developer is required",
											}),
										},
										{
											type: "email",
											message: intl.formatMessage({
												id: "setup.flow_setup.form.web_developer.invalid_email",
												defaultMessage: "Doesn't look like a valid email address",
											}),
										},
									]}
								>
									<Input
										id="email"
										type="email"
										size="large"
										placeholder="developer@bothive.be"
										className="setup-flow_setup-email_input-field"
									/>
								</Form.Item>
							</FieldLabel>
							<h2 style={{ fontWeight: "normal" }} className="t-gap--top">
								<FormattedMessage
									id="setup.flow_setup.self_setup.title"
									defaultMessage="Or you can do it by yourself"
								/>
							</h2>
							<p>
								<FormattedMessage
									id="setup.flow_setup.self_setup.description"
									defaultMessage="Add the piece of code below in the <head> and <body> tag and adjust the style attribute to fit within the website."
								/>
							</p>
							<div className="setup-flow_setup-code_block">
								<Button
									type="text"
									htmlType="button"
									icon={<CopyOutlined />}
									onClick={handleCopyCode}
									className="setup-flow_setup-code_block-button"
								/>
								<pre style={{ overflow: "scroll" }}>
									<code
										style={{
											fontFamily: "Cascadia Code, monospace",
										}}
									>
										{codeBlock}
									</code>
								</pre>
							</div>
						</div>
						<footer className="setup_content-footer t-gap--top-lg">
							<Form.Item shouldUpdate className="setup-flow_setup-form_item">
								{(form) => {
									const isDisabled =
										!form.isFieldsTouched(true) ||
										!!form.getFieldsError().filter(({ errors }) => errors.length).length;

									return (
										<Button
											size="large"
											type="primary"
											disabled={isDisabled}
											loading={sendKeyToWebDeveloper.isLoading}
											onClick={() => handleSubmit(form.getFieldsValue())}
											className="setup_content-footer-button"
										>
											<FormattedMessage
												id="setup.flow_setup.button.send_mail"
												defaultMessage="Send e-mail to your web-developer and finish setup"
												description="Send e-mail to your web-developer and finish setup and redirected the user to their organization"
											/>
										</Button>
									);
								}}
							</Form.Item>
							<Button
								type="text"
								size="large"
								htmlType="button"
								onClick={handleGoToNext}
								className="setup_content-footer-button"
							>
								<FormattedMessage
									id="setup.flow_setup.button.go_to_organization"
									defaultMessage="Finish setup"
									description="Finish setup and redirected the user to their organization"
								/>
							</Button>
						</footer>
					</Form>
				</article>
			</section>
			<section className="setup_aside">
				<header className="setup_aside-header">
					<p className="setup_aside-header-title">
						<FormattedMessage
							id="setup.flow_setup.aside.title"
							defaultMessage="Put flows directly on your website"
						/>
					</p>
					<img src={setupArrow} alt="" className="setup_aside-header-icon" />
				</header>
				<AsidePreview list={previewList || []} brandColor={organization?.branding?.brandColor} />
				<p className="setup_aside-help">
					<FormattedMessage
						id="setup.flow_setup.aside.help"
						defaultMessage="This is an example on how your web-developer could implement it on your website"
					/>
				</p>
			</section>
		</main>
	);
}
