import React, { useCallback, useRef, useState } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import { LeftOutlined } from "@ant-design/icons";
import { pageConfig, routesConfig, sizeConfig } from "../../../config";
import { Image, Tooltip } from "../../atoms";
import { List } from "../../molecules";

const mapStateToProps = (state) => ({
	team: state.content.team.team,
	user: state.profile.account.user,
	isAsideFolded: state.dashboard.ui.isAsideFolded,
});

function OAsideProfile({ team, user, isAsideFolded, className, history }) {
	const tooltipRef = useRef(null);
	const [tooltipLabel, setTooltip] = useState(undefined);
	const handleButtonClick = useCallback(
		() => history.push(routesConfig.dashboard.root.replace(":team", team.slug)),
		[history, team]
	);

	const handleMouseOver = ({ item, event }) => {
		if (!isAsideFolded) {
			return;
		}

		setTooltip(item.label);
		showTooltip({ event });
	};

	const showTooltip = ({ event }) => {
		event.preventDefault();

		const yPosition = event.pageY || event.clientY;

		tooltipRef.current.style.top = `${yPosition}px`;
		tooltipRef.current.style.left = `${sizeConfig.height.lg + sizeConfig.spacing.sm}px`;
		tooltipRef.current.style.transform = "translate(0%, -50%)";
		tooltipRef.current.style.display = "flex";
	};
	const hideTooltip = () => {
		tooltipRef.current.style.display = "none";
		setTooltip(undefined);
	};

	if (!tooltipLabel && tooltipRef?.current && tooltipRef?.current?.style?.display !== "none") {
		tooltipRef.current.style.display = "none";
	}

	return (
		<aside className={`o-aside ${isAsideFolded ? "aside--folded" : ""} ${className}`}>
			<NavLink to={routesConfig.profile.root.replace(":slug", user.slug)} className="o-aside--logo-link">
				<figure className="o-aside--logo">
					{isAsideFolded ? <Image.ALogoSmall /> : <Image.ALogoRelative />}
				</figure>
			</NavLink>
			{team && !isAsideFolded && (
				<a
					className="aside-dropdown"
					onClick={(e) => {
						e.preventDefault();
						handleButtonClick();
					}}
				>
					<div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
						<LeftOutlined />
						<p>{team.name}</p>
					</div>
				</a>
			)}
			<div ref={tooltipRef} className="aside-tooltip">
				<Tooltip.ADefault message={tooltipLabel || " "} />
			</div>
			<div className="aside-body">
				<List.MAsideLinks
					IsFolded={isAsideFolded}
					items={pageConfig.aside.profile(user.slug)}
					onFocus={handleMouseOver}
					onBlur={hideTooltip}
				/>
			</div>
		</aside>
	);
}

OAsideProfile.defaultProps = {
	team: undefined,
	isAsideFolded: false,
	className: "",
};

export default withRouter(connect(mapStateToProps, {})(OAsideProfile));
