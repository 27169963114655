import actionTypes from "./notification.actionTypes";

const initialState = {
	lastNotification: undefined,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.SEND_DESKTOP_NOTIFICATION:
			return {
				...state,
				lastNotification: action.data,
			};
		case actionTypes.REQUEST_DESKTOP_NOTIFICATION_PERMISSION:
		case actionTypes.DESKTOP_NOTIFICATION_DECLINED:
		case actionTypes.START_BROWSER_TAB_NOTIFICATION:
		case actionTypes.STOP_BROWSER_TAB_NOTIFICATION:
		case actionTypes.START_NEW_MESSAGE_NOTIFICATIONS:
		default:
			return state;
	}
}
