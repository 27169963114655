import { atom } from "recoil";

export const allReportsSelectedState = atom({
	key: "frobAllReportsSelected",
	default: false,
});

export const selectedReportIdsState = atom({
	key: "frobSelectedReportIds",
	default: [],
});

export const totalReportsSelectedState = atom<number>({
	key: "frobTotalReportsSelected",
	default: 0,
});

export const isBulkDownloadingState = atom({
	key: "frobIsBulkDownloading",
	default: false,
});

export const isBulkStatusChangingState = atom({
	key: "frobIsBulkStatusChanging",
	default: false,
});
export const isBulkDeletingState = atom({
	key: "frobIsBulkDeletingState",
	default: false,
});

export const isIntegrationSendingState = atom({
	key: "frobIsIntegrationSending",
	default: false,
});
