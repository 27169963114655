import { DeleteOutlined, EditOutlined, ExportOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { flowState } from "@/flows/common/state";
import DownloadReportButton from "@/flows/report_detail/meta_aside/actions/download_report_button";
import style from "@/flows/report_detail/meta_aside/actions/style.module.scss";
import StatusButton from "@/shared/components/button/status_button";
import { trpc } from "@/trpc";
import { typesConfig } from "../../../common/config";
import { isEditingReportState, reportViewState } from "../../state";
import SaveToContactButton from "./SaveToContactButton";
import DeleteFlow from "./delete_flow";
import { queryStringHelpers } from "../../../../shared/helpers";

export default function Actions({ reportId, isReportInProgress }) {
	const intl = useIntl();
	const pbTemplateId = "65567c114e7254410e97e96f";
	const { team, flowId } = useParams<{ team: string; flowId: string }>();

	const flow = useRecoilValue(flowState({ id: flowId }));
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const setIsEditing = useSetRecoilState(isEditingReportState);
	const [reportViewType, setReportViewType] = useRecoilState(reportViewState);
	const user = useSelector((state) => state.profile.account.user);

	const handleEdit = () => {
		if (reportViewType === typesConfig.reportType.pdf) setReportViewType(typesConfig.reportType.excel);
		setIsEditing(true);
	};

	const handleOpenFlow = () => {
		if (`${flow.templateId}` !== pbTemplateId) return;

		const queryString = queryStringHelpers.createQueryString({
			reportId,
			"user-id": user._id,
			"user-email": user.email,
			"user-name": user.firstName,
		});
		// Only open new flow interface
		window.open(`${process.env.REACT_APP_NEW_FLOW_HOST}/${team}/${flowId}${queryString}`, "_blank");
	};

	const { data: flowTemplate } = trpc.flowTemplate.getByFlowId.useQuery({ flowId });
	const flowName = flow?.name || flow?.template?.name?.[intl.locale];

	return (
		<section className={style.actions_group}>
			<header className={style.actions_group_header}>
				<p>
					<FormattedMessage id="FLOWS.REPORT_DETAIL.ACTIONS.TITLE" />
				</p>
			</header>
			<Tooltip
				title={
					!isReportInProgress
						? intl.formatMessage({
								id: "FLOWS.REPORT_DETAIL.ACTIONS.EDIT_TOOLTIP.DISABLED",
								defaultMessage: "You can only edit or download a report that is in progress",
						  })
						: null
				}
			>
				<div className={style.actions_group_content}>
					{flowTemplate?.settings?.canSaveToContact && <SaveToContactButton />}

					<StatusButton
						icon={<EditOutlined className="t-gap--right-xs" />}
						onClick={() => handleEdit()}
						disabled={!isReportInProgress}
					>
						<FormattedMessage id="FLOWS.REPORT_DETAIL.ACTIONS.EDIT" />
					</StatusButton>
					<DownloadReportButton flowName={flowName} disabled={!isReportInProgress} />
					{/*todo implement when backend supports it*/}
					{/*<ActionButton>*/}
					{/*	<FormattedMessage id="FLOWS.REPORT_DETAIL.ACTIONS.REMINDER" />*/}
					{/*</ActionButton>*/}
					{`${flow.templateId}` === pbTemplateId && (
						<StatusButton
							icon={<ExportOutlined className="t-gap--right-xs" />}
							onClick={() => handleOpenFlow()}
						>
							<FormattedMessage
								id="FLOWS.REPORT_DETAIL.ACTIONS.OPEN_FLOW"
								defaultMessage="Open flow as contact"
							/>
						</StatusButton>
					)}
					<StatusButton
						icon={<DeleteOutlined className="t-gap--right-xs" />}
						onClick={() => setIsDeleteModalVisible(true)}
					>
						<FormattedMessage id="FLOWS.REPORT_DETAIL.ACTIONS.DELETE" />
					</StatusButton>
				</div>
			</Tooltip>

			<DeleteFlow isVisible={isDeleteModalVisible} setShowModal={setIsDeleteModalVisible} />
		</section>
	);
}
