import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
	$createLineBreakNode,
	$createParagraphNode,
	$insertNodes,
	COMMAND_PRIORITY_EDITOR,
	createCommand,
	LexicalCommand,
} from "lexical";
import { useEffect } from "react";

import { $createThreadNode, ThreadNode } from "../nodes/thread_node";

export type IInsertThreadCommandPayload = Readonly<{
	content: string;
}>;

export const INSERT_THREAD_COMMAND: LexicalCommand<IInsertThreadCommandPayload> =
	createCommand("INSERT_FORWARD_MAIL_COMMAND");

export function ThreadPlugin(): JSX.Element | null {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (!editor.hasNodes([ThreadNode])) return;

		return editor.registerCommand<IInsertThreadCommandPayload>(
			INSERT_THREAD_COMMAND,
			({ content }) => {
				const node = $createThreadNode({ content });
				const nodes = [$createLineBreakNode(), node, $createLineBreakNode(), $createParagraphNode()];

				try {
					$insertNodes(nodes);
				} catch (_) {
					try {
						$insertNodes([$createParagraphNode(), ...nodes]);
					} catch (error) {
						console.error("Insert thread failed", error);
					}
				}

				return true;
			},
			COMMAND_PRIORITY_EDITOR
		);
	}, [editor]);

	return null;
}
