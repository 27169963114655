import DashboardCard from "@/shared/components/card/dashboard";
import SkeletonTable from "@/shared/components/table/skeleton";
import { FormattedMessage, useIntl } from "react-intl";
import { Alert, Button, Modal, notification, Skeleton, Table } from "antd";
import { EditOutlined, StopOutlined } from "@ant-design/icons";
import { useState } from "react";
import { FlowRemindersRouterOutput, trpc } from "@/trpc";
import type { IFlowReminderSetting } from "@bothive_core/database";

interface ReminderSettingsProps {
	setting?: FlowRemindersRouterOutput["getRemindersSetting"];
	isLoading?: boolean;
	setShowReminderStrategyModal: (show: boolean) => void;
	setSelectedReminderStrategyData: (data: FlowRemindersRouterOutput["getRemindersSetting"]) => void;
}

export default function ReminderSettings({
	setting,
	isLoading = false,
	setShowReminderStrategyModal,
	setSelectedReminderStrategyData,
}: ReminderSettingsProps) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const dateFormatter = new Intl.DateTimeFormat(intl.locale, {
		dateStyle: "full",
	});

	const [showStopModal, setShowStopModal] = useState(false);

	const stopFlowReminderSettingsMutation = trpc.flowReminders.stopFlowReminderSettings.useMutation({
		onSuccess: () => {
			notification.success({
				message: intl.formatMessage({
					id: "FLOW.DETAIL.REMINDERS.SETTINGS.ACTIONS.STOP.SUCCESS",
					defaultMessage: "Successfully stopped reminders for these settings.",
				}),
				placement: "bottomRight",
			});

			trpcUtils.flowReminders.getRemindersSetting.invalidate();
			setShowStopModal(false);
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "FLOW.DETAIL.REMINDERS.SETTINGS.ACTIONS.STOP.ERROR",
					defaultMessage: "Something went wrong while stopping the reminders for these settings.",
				}),
				placement: "bottomRight",
			});
		},
	});

	return (
		<>
			<DashboardCard
				title={intl.formatMessage({
					id: "FLOW.DETAIL.REMINDERS.SETTINGS.TITLE",
					defaultMessage: "Reminder settings",
				})}
				bodyStyle={{ textAlign: "left", display: "block" }}
			>
				{isLoading ? (
					<Skeleton className="t-gap--top-sm" title active paragraph={false} style={{ width: "75ch" }} />
				) : (
					<p className="t-gap--top-sm" style={{ whiteSpace: "pre-line", lineHeight: 1.8 }}>
						<FormattedMessage
							id="FLOW.DETAIL.REMINDERS.SETTINGS.STATUS"
							defaultMessage={`Send {total, plural, =0 {<b>no</b> reminders} one {<b>#</b> reminder} other {a total of <b>#</b> reminders}}. With a time interval between each reminder of {days, plural, =0 {<b>no</b> days} one {<b>#</b> day} other {<b>#</b> days}}.\nWe stop sending reminders if the contact completes the report, or if the end date <b>{endDate}</b> is reached.`}
							values={{
								total: setting?.settings.total,
								days: setting?.settings.interval,
								endDate: dateFormatter.format(setting?.settings.cutoffDateUTC),
								b: (content) => <strong>{content}</strong>,
							}}
						/>
					</p>
				)}
				{/* Reminder Templates */}
				<SkeletonTable
					className="t-gap--top"
					pagination={false}
					isLoading={isLoading}
					skeletonRowCount={1}
					dataSource={setting?.settings.templates || []}
					onRow={() => ({
						onClick: () => {
							if (!setting || setting?.stoppedAt) return;

							setSelectedReminderStrategyData(setting);
							setShowReminderStrategyModal(true);
						},
					})}
					style={{ pointerEvents: setting?.stoppedAt ? "none" : "auto" }}
				>
					<Table.Column
						key="name"
						dataIndex={[]}
						title={
							<FormattedMessage
								id="FLOW.DETAIL.REMINDERS.SETTINGS.TABLE.COLUMN.TEMPLATE.DESCRIPTION"
								defaultMessage="Name"
							/>
						}
						ellipsis={true}
						render={() =>
							intl.formatMessage({
								id: "FLOW.DETAIL.REMINDERS.SETTINGS.TABLE.DEFAULT.TEMPLATE",
								defaultMessage: "Default mail template",
							})
						}
					/>
					<Table.Column
						key="subject"
						dataIndex={[]}
						title={
							<FormattedMessage
								id="FLOW.DETAIL.REMINDERS.SETTINGS.TABLE.COLUMN.TEMPLATE.SUBJECT"
								defaultMessage="Subject"
							/>
						}
						ellipsis={true}
						render={(value: IFlowReminderSetting["settings"]["templates"][number]) => value.subject}
					/>
				</SkeletonTable>
				{!setting?.stoppedAt && (
					<section className="t-gap--top" style={{ display: "flex", gap: "1em", justifyContent: "flex-end" }}>
						<Button
							disabled={isLoading}
							icon={<StopOutlined />}
							onClick={() => setShowStopModal(true)}
							type="primary"
							danger
						>
							{" "}
							<FormattedMessage
								id="FLOW.DETAIL.REMINDERS.SETTINGS.BUTTON.STOP"
								defaultMessage="Stop reminders"
							/>
						</Button>

						<Button
							disabled={isLoading}
							icon={<EditOutlined />}
							type="primary"
							onClick={() => {
								if (!setting) return;

								setSelectedReminderStrategyData(setting);
								setShowReminderStrategyModal(true);
							}}
						>
							{" "}
							<FormattedMessage
								id="FLOW.DETAIL.REMINDERS.SETTINGS.BUTTON.EDIT"
								defaultMessage="Edit settings"
							/>
						</Button>
					</section>
				)}
			</DashboardCard>
			<Modal
				open={showStopModal}
				title={intl.formatMessage({
					id: "FLOW.DETAIL.REMINDERS.SETTINGS.STOP_MODAL.TITLE",
					defaultMessage: "Stop reminders",
				})}
				onCancel={() => setShowStopModal(false)}
				destroyOnClose
				footer={[
					<Button
						disabled={stopFlowReminderSettingsMutation.isLoading}
						type="text"
						onClick={() => setShowStopModal(false)}
					>
						<FormattedMessage id="POPUP.BUTTON.CANCEL" defaultMessage="Cancel" />
					</Button>,
					<Button
						loading={stopFlowReminderSettingsMutation.isLoading}
						type="primary"
						danger
						onClick={() =>
							stopFlowReminderSettingsMutation.mutate({
								settingId: setting?._id,
							})
						}
					>
						<FormattedMessage
							id="FLOW.DETAIL.REMINDERS.SETTINGS.STOP_MODAL.BUTTON.STOP.TITLE"
							defaultMessage="Stop reminders"
						/>
					</Button>,
				]}
			>
				<Alert
					message={intl.formatMessage({
						id: "FLOW.DETAIL.REMINDERS.SETTINGS.STOP_MODAL.DESCRIPTION",
						defaultMessage:
							"When you stop reminders, your contacts won't get reminded by any new reminders. And currently you won't be able to restart your notifications manually.",
					})}
					type="warning"
					showIcon
				/>
			</Modal>
		</>
	);
}
