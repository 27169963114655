import axios from "axios";

import config from "../../config";

export const createStatusType = async ({ flowId, payload }) => {
	const url = config.api.create.replace(":flowId", flowId);
	const { data, status } = await axios.post(url, payload);

	// Don't check here for success because data can contain custom error
	if (status !== 201) {
		throw new Error("Failed to create status type");
	}

	return data;
};
