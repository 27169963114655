import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, FormInstance, Input, Select, Space, Spin } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useParams } from "react-router-dom";
import { IChannel } from "@bothive_core/database";

import { emailArrValidator, maxRecipientsValidator } from "../utils";
import { IComposeForm } from "../types";
import RecipientSelect from "./recipientSelect";

interface IMailHeader {
	channels: IChannel[];
	isInitialized?: boolean;
	form: FormInstance<IComposeForm>;
}

type OptionFields = "cc" | "bcc";

function NoChannelsFound() {
	const { team } = useParams<{ team: string }>();

	return (
		<div>
			<FormattedMessage
				id="inbox.compose.fields.from.fallback"
				defaultMessage="We could not find any channels. go to <link>settings > channels</link> to connect a channel"
				values={{
					link: (content) => <NavLink to={`/${team}/settings/inbox/channels`}>{content}</NavLink>,
				}}
			/>
		</div>
	);
}

const HIDDEN_FORM_FIELD_STYLE: CSSProperties = { visibility: "hidden", width: 0, height: 0, margin: 0 };

export default function MailHeader({ channels = [], isInitialized, form }: IMailHeader) {
	const intl = useIntl();
	const fromChannelId = Form.useWatch<string | undefined>("from", form);

	const toFieldRef = useRef<any | null>(null);
	const [optionalFields, setOptionalFields] = useState<Record<OptionFields, boolean>>({
		cc: Boolean(form.getFieldValue("cc")?.length),
		bcc: Boolean(form.getFieldValue("bcc")?.length),
	});

	const formatChannels = useMemo(
		() =>
			channels?.map((channel) => {
				const label = channel.name === channel.address ? channel.name : `${channel.name} (${channel.address})`;

				return { value: channel._id, label };
			}),
		[channels]
	);

	useEffect(() => {
		if (!toFieldRef.current || !isInitialized) return;
		toFieldRef.current.focus();
	}, [toFieldRef, isInitialized]);

	return (
		<div className="inbox-composer-mail_header">
			<Form.Item
				label={intl.formatMessage({ id: "inbox.compose.fields.from.label", defaultMessage: "From:" })}
				name="from"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: "inbox.compose.fields.from.error_required",
							defaultMessage: "From which channel do you want to sent this message",
						}),
					},
				]}
			>
				<Select
					bordered={false}
					suffixIcon={null}
					options={formatChannels}
					className="inbox-composer-mail_header-fields"
					notFoundContent={<NoChannelsFound />}
					placeholder={intl.formatMessage({
						id: "inbox.compose.fields.from.placeholder",
						defaultMessage: "From which channel do you want to sent this message",
					})}
				/>
			</Form.Item>
			<div className="receiver_fields">
				<div className="receiver_fields-wrapper">
					<Form.Item
						label={intl.formatMessage({ id: "inbox.compose.fields.to.label", defaultMessage: "To:" })}
						name="to"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: "inbox.compose.fields.to.error_required",
									defaultMessage: "Who needs to receive this message",
								}),
							},
							emailArrValidator(intl),
							maxRecipientsValidator(form, intl),
						]}
					>
						<RecipientSelect
							autoFocus
							mode="tags"
							suffixIcon={null}
							ref={toFieldRef}
							className="inbox-composer-mail_header-fields"
							bordered={false}
							placeholder={intl.formatMessage({
								id: "inbox.compose.fields.to.placeholder",
								defaultMessage: "Who needs to receive this message",
							})}
							fromChannelIds={fromChannelId ? [fromChannelId] : []}
						/>
					</Form.Item>
					<Form.Item
						style={!optionalFields.cc ? HIDDEN_FORM_FIELD_STYLE : undefined}
						label={intl.formatMessage({ id: "inbox.compose.fields.cc.label", defaultMessage: "Cc:" })}
						name="cc"
						rules={[emailArrValidator(intl), maxRecipientsValidator(form, intl)]}
					>
						<RecipientSelect
							autoFocus
							mode="tags"
							suffixIcon={null}
							className="inbox-composer-mail_header-fields"
							bordered={false}
							placeholder={intl.formatMessage({
								id: "inbox.compose.fields.cc.placeholder",
								defaultMessage: "Who needs to receive a copy of this message",
							})}
							fromChannelIds={fromChannelId ? [fromChannelId] : []}
						/>
					</Form.Item>
					<Form.Item
						style={!optionalFields.bcc ? HIDDEN_FORM_FIELD_STYLE : undefined}
						label={intl.formatMessage({ id: "inbox.compose.fields.bcc.label", defaultMessage: "Bcc:" })}
						name="bcc"
						rules={[emailArrValidator(intl), maxRecipientsValidator(form, intl)]}
					>
						<RecipientSelect
							autoFocus
							mode="tags"
							suffixIcon={null}
							className="inbox-composer-mail_header-fields"
							bordered={false}
							placeholder={intl.formatMessage({
								id: "inbox.compose.fields.bcc.placeholder",
								defaultMessage: "Who needs to receive a blind copy of this message",
							})}
							fromChannelIds={fromChannelId ? [fromChannelId] : []}
						/>
					</Form.Item>
				</div>
				<div className="receiver_fields-controls">
					{!optionalFields.cc && (
						<Button type="text" onClick={() => setOptionalFields((prev) => ({ ...prev, cc: true }))}>
							<FormattedMessage
								id="inbox.compose.button.cc"
								defaultMessage="Cc"
								description="Button to add cc field to email"
							/>
						</Button>
					)}
					{!optionalFields.bcc && (
						<Button type="text" onClick={() => setOptionalFields((prev) => ({ ...prev, bcc: true }))}>
							<FormattedMessage
								id="inbox.compose.button.bcc"
								defaultMessage="Bcc"
								description="Button to add bcc field to email"
							/>
						</Button>
					)}
				</div>
			</div>
			<Form.Item
				label={intl.formatMessage({ id: "inbox.compose.fields.subject.label", defaultMessage: "Subject:" })}
				name="subject"
			>
				<Input
					bordered={false}
					className="inbox-composer-mail_header-fields"
					disabled={form.getFieldValue("subject")?.toLowerCase()?.startsWith("re:")}
					placeholder={intl.formatMessage({
						id: "inbox.compose.fields.subject.placeholder",
						defaultMessage: "What's this message about",
					})}
				/>
			</Form.Item>
		</div>
	);
}
