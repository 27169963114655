import React from "react";

import { pageConfig, privilegeConfig } from "../../../config";
import { ListItem } from "../../atoms";

export default function MAsideLinksDashboard({
	team,
	teamName,
	permissions,
	teamType,
	IsFolded,
	className,
	onFocus,
	onBlur,
}) {
	return pageConfig.aside.dashboard({ team: teamName }).map((section, index) => (
		<section key={`asideSection-${index}`} className="m-aside--section">
			<ul className={`m-list m-list--vertical ${className}`}>
				{section.map((item) => {
					if (
						item.isHidden ||
						(item.privilege !== privilegeConfig.keys.standard && !permissions[item.privilege])
					) {
						return "";
					}

					if (
						item.billingType === privilegeConfig.billingKeys.premium &&
						teamType !== privilegeConfig.billingKeys.premium
					) {
						return "";
					}

					return (
						<li
							key={item.id}
							onFocus={(event) => onFocus({ item, event })}
							onMouseOver={(event) => onFocus({ item, event })}
							onBlur={(event) => onBlur({ item, event })}
							onMouseLeave={(event) => onBlur({ item, event })}
							className="m-list--item"
						>
							<ListItem.AListItemAside {...item} IsFolded={IsFolded} />
						</li>
					);
				})}
			</ul>
		</section>
	));
}
