import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { selectedFlowIdState } from "@/flows/common/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import SortableTable from "@/shared/components/table/sortable";

import { useStatusTypes } from "../hook";
import { reorderStatusType } from "../query";
import { statusTypesList, statusTypesMeta } from "../state";
import { editStatusTypeIdState } from "../status_modal/state";
import style from "./style.module.scss";

export default function StatusTypesTable({ setShowEditModal }) {
	const intl = useIntl();
	const meta = useRecoilValue(statusTypesMeta);
	const flowId = useRecoilValue(selectedFlowIdState);
	const [statusTypes, fetchNextStatusTypes] = useStatusTypes();
	const setEditStatusTypeId = useSetRecoilState(editStatusTypeIdState);
	const setStatusTypesList = useSetRecoilState(statusTypesList);
	const hasEditPermissions = useSelector((state) => state.dashboard.permissions.userPermissions.editFlows);

	const columns = useMemo(
		() => [
			{
				dataIndex: [],
				title: intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.TABLE.STATUS_TYPE" }),
				width: "60%",
				skeleton: {
					isEnabled: true,
				},
				className: style.status_type_cell_visible,
				render: ({ name, description }) => (
					<div className={style.status_type_cell}>
						<Typography.Text strong>{name}</Typography.Text>
						<Typography.Text>{description}</Typography.Text>
					</div>
				),
			},
			{
				dataIndex: "isDefault",
				title: intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.TABLE.DEFAULT" }),
				width: 128,
				skeleton: {
					isEnabled: true,
				},
				render: (isDefault) => isDefault && <CheckOutlined />,
			},
			{
				dataIndex: "isDone",
				title: intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.TABLE.IS_DONE" }),
				width: 128,
				align: "center",
				skeleton: {
					isEnabled: false,
				},
				render: (isDone) => isDone && <CheckOutlined />,
			},
			{
				dataIndex: "id",
				width: 64,
				align: "center",
				skeleton: {
					isEnabled: false,
				},
				render: (id) => hasEditPermissions && <EditOutlined onClick={() => setShowEditModal(id)} />,
			},
		],
		[hasEditPermissions]
	);

	const handleChange = async (pagination) => {
		fetchNextStatusTypes({
			limit: pagination.pageSize,
			offset: (pagination.current - 1) * pagination.pageSize,
		});
	};
	const handleReorder = async ({ data, index }) => {
		if (!hasEditPermissions) {
			return;
		}

		try {
			const updateOrder = data.map((statusType, index) => ({ ...statusType, order: index }));

			setStatusTypesList(updateOrder);

			await reorderStatusType({ flowId, statusType: updateOrder[index] });
		} catch (_) {
			setStatusTypesList(data); // revert with old order values on failure
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORTS.STATUS_TYPES.REORDER_FAILED",
					defaultMessage: "Re ordering status types failed, try again later.",
				}),
			});
		}
	};
	const handleRowClick = (statusType) => {
		if (!hasEditPermissions) {
			return;
		}

		setEditStatusTypeId(statusType._id);
	};

	return (
		<SortableTable
			rowKey="order"
			columns={columns}
			isDisabled={!hasEditPermissions}
			isLoading={statusTypes?.state === "loading"}
			dataSource={statusTypes.contents}
			pagination={{
				total: meta.total,
				defaultPageSize: meta.limit,
			}}
			onReorder={handleReorder}
			onChange={handleChange}
			onClick={(statusType) => handleRowClick(statusType)}
			locale={{
				emptyText: intl.formatMessage({ id: "FLOWS.REPORTS.STATUS_TYPES.TABLE.EMPTY_TEXT" }),
			}}
		/>
	);
}
