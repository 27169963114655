import React from "react";
import { FormattedMessage } from "react-intl";

export default function ALabelLoader({ direction, label, className }) {
	return (
		<div data-direction={direction} className={`a-component--label-loader ${className}`}>
			<p>
				<FormattedMessage id={label} />
			</p>
			<div className="icon-loader small" />
		</div>
	);
}
ALabelLoader.defaultProps = {
	direction: "left",
	className: "",
};
