import "./style.scss";

import { List, Progress, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

import { calculateProgress } from "@/flows/common/helper";
import { dateHelpers } from "@/helpers";
import UserPreview from "@/shared/components/user/user_preview";

export default ({ id, contact, progress, updatedAt, lastFilledInAt, selected, onClick }) => {
	const username = contact?.fullName || [contact?.firstName, contact?.lastName].join(" ").trim();

	return (
		<List.Item key={id} onClick={() => onClick(id)} className="report_list_item" data-selected={selected}>
			<div className="report_header">
				<UserPreview
					id={contact?.id}
					src={contact?.avatar}
					username={username}
					className="report_header_user"
				/>
				<time>{dateHelpers.getRelativeDate(lastFilledInAt || updatedAt)}</time>
			</div>
			<div className="report_footer">
				<Tooltip title={progress && <FormattedMessage id={progress} />}>
					<Progress
						percent={calculateProgress(progress).percentage}
						strokeColor={calculateProgress(progress).color}
						showInfo={false}
						trailColor="rgba(#002140, 0.1)"
					/>
				</Tooltip>
			</div>
		</List.Item>
	);
};
