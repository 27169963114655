import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { routesConfig, typesConfig } from "@/config";
import config from "@/flows/overview/create/detail/config";
import { selectedFlowTemplate } from "@/flows/overview/create/state";
import ImageCarousel from "@/shared/components/carousel/image";
import EmptyFallback from "@/shared/components/fallback/empty";
import { MarkdownText } from "../../../../../shared/components/text/markdown";

export default function FlowDetailBody() {
	const { team } = useParams();
	const { locale } = useIntl();

	const selectedTemplate = useRecoilValue(selectedFlowTemplate);

	const generateBody = () => {
		if (!selectedTemplate || !selectedTemplate.content) {
			const url = routesConfig.dashboard.support.replace(":team", team);

			return (
				<EmptyFallback className="fallback--flow-description" {...config.fallback}>
					<NavLink to={`${url}?option=${typesConfig.supportTypes.pricing}`} className="a-link font-size-sm">
						<FormattedMessage id={config.fallback.buttonLabel} />
					</NavLink>
				</EmptyFallback>
			);
		}

		return (
			<div className="layout-content-detail">
				<MarkdownText value={selectedTemplate.content[locale]} className="layout-content-text" />
				{!!selectedTemplate.images?.length && (
					<ImageCarousel items={selectedTemplate.images} className="layout-content-carousel t-gap--top-lg" />
				)}
			</div>
		);
	};

	return <div className="layout-content">{generateBody()}</div>;
}
