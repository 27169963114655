import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import { Buttons } from "../../atoms";

function AHeaderPopup({ title, formattedValues, intl, onClick, className }) {
	return (
		<header className={`m-header--popup ${className}`}>
			<h1>
				<FormattedMessage id={title} values={formattedValues} />
			</h1>
			{onClick && (
				<Buttons.AIconButton
					icon="close"
					label={intl.formatMessage({ id: "POPUP.BUTTON.CLOSE" })}
					iconClass="small"
					onClick={onClick}
					className="header-popup--close"
				/>
			)}
		</header>
	);
}

AHeaderPopup.defaultProps = {
	formattedValues: undefined,
	className: "",
	onClick: undefined,
};

export default injectIntl(AHeaderPopup);
