import "./assets/style/style.scss";

import superjson from "superjson";
import { ConfigProvider } from "antd";
import enGb from "antd/lib/locale/en_GB";
import nlBe from "antd/lib/locale/nl_BE";
import { useMemo, useRef, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { connect, shallowEqual, useSelector } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWSClient, httpBatchLink, splitLink, wsLink } from "@trpc/client";

import axios from "axios";
import { trpc } from "./trpc";
import Routes from "./routes";
import { apiConfig } from "./config";
import translation from "./translation";
import { storageHelpers } from "./helpers";
import themeConfig from "./assets/style/themeConfig";

const mapStateToProps = (state: any) => ({
	language: state.dashboard.header.lang?.slice(0, 2),
});

const antIntl = {
	en: enGb,
	nl: nlBe,
};

function App({ language }: { language: "nl" | "en" }) {
	const organizationId = useSelector((state: any) => state.content?.team?.team?._id, shallowEqual);
	const organizationIdRef = useRef(organizationId);

	useEffect(() => {
		organizationIdRef.current = organizationId;
	}, [organizationId]);

	const queryClient = useMemo(() => new QueryClient(), []);
	const trpcClient = useMemo(
		() =>
			trpc.createClient({
				links: [
					splitLink({
						condition(op) {
							return op.type === "subscription";
						},
						true: wsLink({ client: createWSClient({ url: apiConfig.trpcWsUrl }) }),
						false: httpBatchLink({
							url: apiConfig.trpcUrl,
							headers() {
								return {
									organizationid: storageHelpers.getTeamUuid() || "",
									authorization: storageHelpers.getUserToken() || "",
								};
							},
						}),
					}),
				],
				transformer: superjson,
			}),
		[]
	);

	// When the stored token and memory token are not the same, logout the user. This indicates that the user logged in in another window.
	// To make it ourselves easy we just refresh the page, so we are sure al state is clean again.
	useEffect(() => {
		const handleWindowFocus = () => {
			const storedCookieToken = storageHelpers.getUserToken();
			const memoryToken = axios.defaults.headers.common.Authorization;

			if (storedCookieToken === memoryToken) return;

			history.go(0);
		};

		window.addEventListener("focus", handleWindowFocus);

		return () => {
			window.removeEventListener("focus", handleWindowFocus);
		};
	}, []);

	return (
		//@ts-ignore
		<IntlProvider locale={language} messages={translation[language]} onError={() => undefined}>
			<ConfigProvider locale={antIntl[language as "en"]} theme={themeConfig}>
				<trpc.Provider client={trpcClient} queryClient={queryClient}>
					<QueryClientProvider client={queryClient} contextSharing={true}>
						<Routes />
					</QueryClientProvider>
				</trpc.Provider>
			</ConfigProvider>
		</IntlProvider>
	);
}

export default connect(mapStateToProps, {})(App);
