import axios from "axios";

import { apiConfig } from "@/config";

async function deleteFlow({ flowId }) {
	const url = apiConfig.flow.delete.replace(":id", flowId);
	const { status } = await axios.delete(url);

	if (status !== 204) {
		throw new Error();
	}

	return true;
}

export default {
	deleteFlow,
};
