import "./style.scss";

import { typesConfig } from "@/config";
import { trpc } from "@/trpc";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Modal, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { useRecoilState } from "recoil";

import { fileHelpers } from "@/shared/helpers";
import ShowFailedBanner from "@/shared/components/banner/failed";
import FieldSideLabel from "@/shared/components/field/side_label";
import { selectedAllContactsState, selectedContactsState } from "../state";

interface DownloadProps {
	filters: {
		q?: string;
		contactType?: string;
		labelId?: string;
		accountManagerId?: string;
	};
}

type TDownloadType = "csv" | "excel" | "json";

export default function Download({ filters }: DownloadProps) {
	const intl = useIntl();
	const [selectedContacts, setSelectedContacts] = useRecoilState(selectedContactsState);
	const [selectedAll, setSelectedAll] = useRecoilState(selectedAllContactsState);
	const [showModal, setShowModal] = useState(false);

	const bulkDownloadMutation = trpc.contact.bulkDownload.useMutation();

	async function handleExport({ type }: { type: TDownloadType }) {
		try {
			const data = await bulkDownloadMutation.mutateAsync({
				contactIds: selectedContacts,
				mutateAll: selectedAll,
				filters,
				type,
			});

			const extension = typesConfig.fileExtensions[type];
			const fileName = intl.formatMessage({
				id: "contacts.overview.bulk_action.download.filename",
				defaultMessage: "contacts",
			});

			fileHelpers.downloadFile({ file: data.data, fileName: `${fileName}.${extension}`, type: extension });

			setShowModal(false);
			setSelectedAll(false);
			setSelectedContacts([]);
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "contacts.overview.bulk_action.download.failed.title",
					defaultMessage: "Failed to download your contacts",
					description: "Filename of the downloaded export",
				}),
				intl,
			});
		}
	}

	return (
		<>
			<Button
				loading={bulkDownloadMutation.isLoading}
				onClick={() => setShowModal((prev) => !prev)}
				type={showModal ? "primary" : "text"}
				icon={<UploadOutlined />}
			>
				{" "}
				<FormattedMessage id="contacts.overview.bulk_action.export.button.title" defaultMessage="Export" />
			</Button>

			<Modal
				open={showModal}
				title={intl.formatMessage({
					id: "contacts.overview.bulk_action.download.modal.title",
					defaultMessage: "Download contacts",
				})}
				destroyOnClose
				onCancel={() => setShowModal(false)}
				footer={null}
			>
				<Form initialValues={{ type: "csv" }} onFinish={handleExport}>
					<Alert
						message={intl.formatMessage({
							id: "contacts.overview.bulk_action.download.info_banner",
							defaultMessage:
								"Downloading your contacts can take a few minutes, don't close your browser until its completed",
						})}
						type="info"
						className="t-gap--bottom"
					/>
					<FieldSideLabel
						label={intl.formatMessage({
							id: "contacts.overview.bulk_action.download.form.type.label",
							defaultMessage: "File type",
						})}
						help={intl.formatMessage({
							id: "contacts.overview.bulk_action.download.form.type.help",
							defaultMessage: "Desired file type of your export",
						})}
					>
						<Form.Item name="type">
							<Select popupMatchSelectWidth>
								{/* Excel version is broken but csv version not */}
								<Select.Option key="csv" value="csv">
									excel
								</Select.Option>
								{/* <Select.Option key="csv" value="csv">
									csv
								</Select.Option> */}
								<Select.Option key="json" value="json">
									json
								</Select.Option>
							</Select>
						</Form.Item>
					</FieldSideLabel>
					<footer className="download_contacts-footer t-gap--top">
						<Button type="text" onClick={() => setShowModal(false)}>
							<FormattedMessage
								id="contacts.overview.bulk_action.export.modal.footer.cancel"
								defaultMessage="Cancel"
							/>
						</Button>
						<Button type="primary" htmlType="submit" loading={bulkDownloadMutation.isLoading}>
							<FormattedMessage
								id="contacts.overview.bulk_action.export.modal.footer.submit_button"
								defaultMessage="Exporteren"
							/>
						</Button>
					</footer>
				</Form>
			</Modal>
		</>
	);
}
