import GroupSkeleton from "./components/GroupSkeleton";

export default function Loading() {
	return (
		<div style={{ width: "100%", padding: "2em" }}>
			{Array.from(Array(5).keys()).map((_, index) => (
				<GroupSkeleton key={index} />
			))}
		</div>
	);
}
