import settingsConfig from "@/modules/settings/config";

export default {
	title: "SETTINGS.INTEGRATIONS.OVERVIEW.PAGE.TITLE",
	tabTitle: "SETTINGS.INTEGRATIONS.OVERVIEW.PAGE.TITLE",
	breadcrumbs: ({ team }) => [
		{
			to: settingsConfig.routes.root.replace(":team", team),
			name: "SETTINGS.INTEGRATIONS.OVERVIEW.HEADER.BREADCRUMB.SETTINGS",
		},
		{
			to: settingsConfig.routes.members.overview.replace(":team", team),
			name: "SETTINGS.INTEGRATIONS.OVERVIEW.HEADER.BREADCRUMB.PAGE_TITLE",
		},
	],
};
