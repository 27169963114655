import { atom, selector } from "recoil";

import config from "./config";

export const statusTypesMeta = atom({
	key: "frsStatusTypesMeta",
	default: {
		limit: config.pagination.limit,
		offset: 0,
		total: 0,
	},
});

// If you want to use statusTypesList atom first check if the useStatusTypes hook might be a better solution
export const statusTypesList = atom({
	key: "frsStatusTypesList",
	default: [],
	effects: [
		({ setSelf, onSet }) => {
			onSet((value) => {
				const sortedValue = [...value]
					.sort((a, b) => a.order - b.order)
					.map((statusType, index) => ({ ...statusType, order: index }));

				setSelf(sortedValue);
			});
		},
	],
});

// Helper selectors -> gets shouldn't be used
export const addStatusType = selector({
	key: "frsAddStatusType",
	get: ({ get }) => get(statusTypesList),
	set: ({ get, set }, statusType) => {
		let list = get(statusTypesList);
		const meta = get(statusTypesMeta);

		if (statusType.isDefault) {
			// If new status is Default mark others as not default
			list = list.map((status) => ({ ...status, isDefault: false }));
		}

		set(statusTypesMeta, { ...meta, total: meta.total + 1 });
		set(statusTypesList, [...list, statusType]);
	},
});
export const removeStatusType = selector({
	key: "frsRemoveStatusType",
	get: ({ get }) => get(statusTypesList),
	set: ({ get, set }, statusTypeId) => {
		const meta = get(statusTypesMeta);
		const list = get(statusTypesList);

		set(statusTypesMeta, { ...meta, total: meta.total - 1 });
		set(
			statusTypesList,
			list.filter((status) => status._id !== statusTypeId)
		);
	},
});
