import { Empty } from "antd";
import { useIntl } from "react-intl";

import { reportFallback } from "@/assets/images/icon";

import style from "./style.module.scss";

export default function EmptyReport({ username, className }) {
	const intl = useIntl();

	return (
		<Empty
			image={reportFallback}
			description={intl.formatMessage({ id: "FLOWS.REPORT_DETAIL.EMPTY_REPORT.TITLE" }, { username })}
			className={`${style.empty_report} ${className}`}
		/>
	);
}
