import LogRocket from "logrocket";
import type { IUser } from "@bothive_core/database";

import { globalHeaderHelpers, storageHelpers } from "@/shared/helpers";

export function saveUserTokens({ token, user }: { token; user: IUser }) {
	const bearerToken = `Bearer ${token}`;

	storageHelpers.saveUserToken({ token: bearerToken });
	globalHeaderHelpers.updateHeaders({ Authorization: bearerToken });

	if (process.env.NODE_ENV === "production") {
		LogRocket.identify(user._id, {
			name: user.username as string,
			email: user.email,
		});
	}
}
