import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import type { ReactNode } from "react";
import KeyboardShortcut from "../../../modules/inbox/components/KeyboardShortcut";

interface TooltipWrapperProps {
	title: ReactNode;
	shortcut?: string;
	children: JSX.Element;
	placement?: TooltipPlacement;
}

function TooltipWrapper({ title, children, shortcut, placement = "bottomLeft" }) {
	return (
		<Tooltip
			placement={placement as TooltipPlacement}
			title={shortcut ? <KeyboardShortcut title={title} shortcut={shortcut} /> : title}
			arrow={{ pointAtCenter: true }}
		>
			{children}
		</Tooltip>
	);
}
export default TooltipWrapper;
