import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import { Button, Result, Table } from "antd";

import SkeletonTable from "@/shared/components/table/skeleton";
import { useSetRecoilState } from "recoil";
import { useDebounce } from "use-debounce";
import { Component, Input } from "../../components/atoms";
import { pageConfig, routesConfig, typesConfig } from "../../config";
import { userState } from "../../modules/portal/state";
import { actions as contentActions } from "../../store/content";
import { actions as dashboardActions } from "../../store/dashboard";
import { trpc } from "../../trpc";

export default function TeamsPage() {
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [debouncedSearch] = useDebounce(search, 500);
	const setPortalUserState = useSetRecoilState(userState);
	const state = useSelector((state) => ({
		user: state.profile.account.user,
	}));

	const trpcUtils = trpc.useUtils();

	useEffect(() => {
		dispatch(dashboardActions.ui.changePageTitle({ config: pageConfig.profile.teams, intl: intl }));
	}, []);

	function handleCreateNewTeam() {
		dispatch(contentActions.form.updateForm({ createTeam: true }));
		history.push("/portal/organization");
		setPortalUserState(state.user);
	}

	const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
	const { data: memberships, isLoading } = trpc.member.getMemberships.useQuery({
		q: debouncedSearch,
		limit: pagination.pageSize,
		offset: (pagination.current - 1) * pagination.pageSize,
	});

	function handleRowClick(membershipId) {
		const membership = memberships?.data.find((membership) => membership._id === membershipId);

		if (!membership) throw new Error("Could not find membership from the clicked organization.");

		history.push(routesConfig.dashboard.root.replace(":team", membership.organization.slug));
		window.location.reload();
	}

	return (
		<main className="t-section">
			<div className="o-filter o-filter--search-create">
				<Input.ATextField
					id="search"
					value={search}
					{...pageConfig.profile.teams.filter.search}
					onChange={setSearch}
				>
					<Component.AIcon type="search" className="input-icon small" />
				</Input.ATextField>
				{state.user.type === typesConfig.userType.powerUser && (
					<Button type="primary" size="large" onClick={handleCreateNewTeam}>
						<FormattedMessage id={pageConfig.profile.teams.fallback.label} />
					</Button>
				)}
			</div>
			<SkeletonTable
				rowKey="_id"
				className="t-gap--top fro-table"
				rowClassName="t-section--contact-overview--table-row"
				dataSource={memberships?.data || []}
				isLoading={isLoading}
				locale={{
					emptyText: (
						<Result
							title={
								debouncedSearch !== ""
									? intl.formatMessage({
											id: "PROFILE_TEAMS.TABLE.EMPTY_SEARCH",
											defaultMessage: "No teams found",
									  })
									: intl.formatMessage({
											id: "PROFILE_TEAMS.TABLE.NO_TEAMS",
											defaultMessage: "You are not part of a team yet",
									  })
							}
							extra={
								debouncedSearch === "" && (
									<Button type="primary" onClick={handleCreateNewTeam}>
										<FormattedMessage id={pageConfig.profile.teams.fallback.label} />
									</Button>
								)
							}
						/>
					),
				}}
				onRow={({ _id }) => ({
					onClick: () => handleRowClick(_id),
				})}
				onChange={(pagination: any, _: any, sorter: any) =>
					setPagination({ current: pagination.current, pageSize: pagination.pageSize })
				}
				pagination={{
					current: pagination.current,
					pageSize: pagination.pageSize,
					total: memberships?.total,
					showSizeChanger: true,
					defaultPageSize: 20,
					pageSizeOptions: ["20", "50", "100", "200"],
				}}
			>
				<Table.Column
					key="_id"
					className="cursor-pointer"
					title={<FormattedMessage id="PROFILE_TEAMS.TABLE.HEADER.TEAM_NAME" defaultMessage="Team name" />}
					ellipsis={{ showTitle: false }}
					render={({ organization }) => (
						<span style={{ display: "flex", alignItems: "center" }}>
							<div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
								<img
									src={organization.branding.logo}
									style={{ width: "5rem", height: "4rem", objectFit: "contain" }}
								/>
								{organization.name}
							</div>
							<Button style={{ marginLeft: "auto" }} type="text" icon={<RightOutlined />} />
						</span>
					)}
				/>
			</SkeletonTable>
		</main>
	);
}
