import Header from "./header";
import List from "./list";
import style from "./style.module.scss";

export default function ReportList() {
	return (
		<div className={style.report_list}>
			<Header />
			<List />
		</div>
	);
}
