import React from "react";

import { logo } from "../../../assets/images";

export default function ALogoRelative({ className }) {
	return (
		<picture className={`a-image a-image--logo a-image--logo-relative ${className}`}>
			<source media="(min-width: 770px)" srcSet={logo.logo} />
			<source srcSet={logo.logoSmall} />
			<img src={logo.logo} alt="logo" className={`a-image a-image--logo ${className}`} />
		</picture>
	);
}
