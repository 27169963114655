import type { ParticipantLabel } from "@bothive_core/database";
import { InboxFolder } from "../hooks/useInboxNavigation.hooks";

type FolderOrder = { label: ParticipantLabel; folder: InboxFolder };

/**
 * Array ordered by most import participant label in case a certain label is present
 */
const folderOrder: FolderOrder[] = [
	{ label: "DRAFT", folder: "drafts" },
	{ label: "DELEGATED", folder: "assigned_by_me" },
	{ label: "SENT", folder: "sent" },
	{ label: "SHARED", folder: "shared_with_me" },
	{ label: "MENTIONED", folder: "mentioned" },
	{ label: "INBOX", folder: "personal_conversations" },
];

/**
 * Returns in which status filter the user his conversation will be present, calculated from the user his participant labels.
 */
export function locateConversationStatus(participantLabels: ParticipantLabel[]) {
	if (participantLabels.includes("TRASH")) return "trash";
	if (participantLabels.includes("SPAM")) return "spam";
	if (participantLabels.includes("CLOSED")) return "closed";
	if (participantLabels.includes("SNOOZED")) return "snoozed";

	return "open";
}

interface LocateConversationFolderArgs {
	participantLabels: ParticipantLabel[];
	assignedToUserId?: string;
	userId: string;
}

/**
 * Returns in which folder the user his conversation will be present, calculated from the user his participant labels.
 * The returned folder will be the MOST relevant folder for the conversation.
 */
export function locateConversationFolder({
	participantLabels,
	assignedToUserId,
	userId,
}: LocateConversationFolderArgs) {
	if (assignedToUserId === userId) return "assigned_to_me";

	const highestFolder = folderOrder.find((item) => participantLabels.includes(item.label));

	return highestFolder?.folder || "all_conversations";
}

/**
 * Returns all folders the user his conversation will be present in, calculated from the user his participant labels.
 */
export function locateConversationFolders({
	participantLabels,
	assignedToUserId,
	userId,
}: LocateConversationFolderArgs) {
	const folders: InboxFolder[] = ["all_conversations"];

	if (assignedToUserId === userId) folders.push("assigned_to_me");

	const foldersWhereConversationWillBePresentForUser = folderOrder
		.filter((item) => participantLabels.includes(item.label))
		.map(({ folder }) => folder);

	folders.push(...foldersWhereConversationWillBePresentForUser);

	return folders;
}
