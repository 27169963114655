import { Form, FormInstance, Select, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import FieldLabel from "@/shared/components/field/default";
import { amountDependent, amountDependentSubtype } from "../config";

export default function ChildForm({ form }: { form: FormInstance }) {
	const intl = useIntl();

	const amountDependentState = Form.useWatch("amountDependent", form);

	return (
		<>
			<FieldLabel id="amountDependent" label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.AMOUNTDEPENDENT">
				<Form.Item
					name="amountDependent"
					style={{ width: "100%", textAlign: "left" }}
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.AMOUNTDEPENDENT.REQUIRED",
							}),
						},
					]}
				>
					<Select autoFocus={true}>
						{Object.keys(amountDependent).map((key) => (
							<Select.Option key={key} value={key}>
								<FormattedMessage id={amountDependent[key]} />
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</FieldLabel>
			{amountDependentState === "co" && (
				<FieldLabel
					id="amountDependentSubtype"
					label="CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.AMOUNTDEPENDENTSUBTYPE"
				>
					<Form.Item
						name="amountDependentSubtype"
						style={{ width: "100%", textAlign: "left" }}
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: "CONTACTS.DETAIL.ATTRIBUTES.LINKED.MODAL.CHILD.AMOUNTDEPENDENTSUBTYPE.REQUIRED",
								}),
							},
						]}
					>
						<Select autoFocus={true}>
							{Object.keys(amountDependentSubtype).map((key) => (
								<Select.Option key={key} value={key}>
									<FormattedMessage id={amountDependentSubtype[key]} />
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</FieldLabel>
			)}
		</>
	);
}
