const baseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:8980"; //eslint-disable-line no-undef

const endpoints = {
	api: "/api/v1",
};

export default {
	api: {
		baseUrl,
		endpoints,
		getAll: `${baseUrl}${endpoints.api}/flows/:flowId/status_types`,
		create: `${baseUrl}${endpoints.api}/flows/:flowId/status_types`,
		update: `${baseUrl}${endpoints.api}/flows/:flowId/status_types/:statusTypeId`,
		reOrder: `${baseUrl}${endpoints.api}/flows/:flowId/status_types/re-order`,
		copy: `${baseUrl}${endpoints.api}/flows/:flowId/status_types/copy`,
		delete: `${baseUrl}${endpoints.api}/flows/:flowId/status_types/:statusTypeId`,
	},
	banner: {
		warningThreshold: 0.9, // threshold 0-1 when warning banner should be shown you almost reached the max allowed status types
	},
	pagination: {
		limit: 20,
	},
};
