import "./style.scss";

import { typesConfig } from "@/config";
import { trpc } from "@/trpc";
import { DownloadOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Modal, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { useRecoilState } from "recoil";

import { fileHelpers } from "@/shared/helpers";
import ShowFailedBanner from "@/shared/components/banner/failed";
import FieldSideLabel from "@/shared/components/field/side_label";
import { selectedArchivedContactsState, selectedAllArchivedContactsState } from "../state";

interface DownloadProps {
	filters: {
		q?: string;
		contactType?: string;
		labelId?: string;
		accountManagerId?: string;
	};
	includesDeleted?: boolean;
}

type TDownloadType = "csv" | "excel" | "json";

export default function Download({ filters, includesDeleted }: DownloadProps) {
	const intl = useIntl();
	const [selectedContacts, setSelectedContacts] = useRecoilState(selectedArchivedContactsState);
	const [selectedAll, setSelectedAll] = useRecoilState(selectedAllArchivedContactsState);
	const [showModal, setShowModal] = useState(false);

	const bulkDownloadMutation = trpc.contact.bulkDownload.useMutation();

	async function handleDownload({ type }: { type: TDownloadType }) {
		try {
			const data = await bulkDownloadMutation.mutateAsync({
				contactIds: selectedContacts,
				mutateAll: selectedAll,
				filters: {
					...filters,
					deleted: includesDeleted,
					archived: true,
				},
				type,
			});

			const extension = typesConfig.fileExtensions[type];
			const fileName = intl.formatMessage({
				id: "contacts.archived_overview.bulk_action.export.filename",
				defaultMessage: "archived_contacts",
				description: "filename of the downloaded export",
			});

			fileHelpers.downloadFile({ file: data.data, fileName: `${fileName}.${extension}` });

			setShowModal(false);
			setSelectedAll(false);
			setSelectedContacts([]);
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "contacts.archived_overview.bulk_action.export.failed.title",
					defaultMessage: "Failed to download your contacts",
				}),
				intl,
			});
		}
	}

	return (
		<>
			<Button
				loading={bulkDownloadMutation.isLoading}
				onClick={() => setShowModal((prev) => !prev)}
				type={showModal ? "primary" : "text"}
				icon={<DownloadOutlined />}
			>
				{" "}
				<FormattedMessage id="contacts.overview.bulk_action.export.button.title" defaultMessage="Export" />
			</Button>

			<Modal
				open={showModal}
				title={intl.formatMessage({
					id: "contacts.archived_overview.bulk_action.export.modal.title",
					defaultMessage: "Download contacts",
				})}
				destroyOnClose
				onCancel={() => setShowModal(false)}
				footer={null}
			>
				<Form initialValues={{ type: "excel" }} onFinish={handleDownload}>
					<Alert
						message={intl.formatMessage({
							id: "contacts.archived_overview.bulk_action.export.info_banner",
							defaultMessage:
								"Downloading your contacts can take a few minutes, don't close your browser until its completed",
						})}
						type="info"
						className="t-gap--bottom"
					/>
					<FieldSideLabel
						label={intl.formatMessage({
							id: "contacts.archived_overview.bulk_action.export.form.type.label",
							defaultMessage: "File type",
						})}
						help={intl.formatMessage({
							id: "contacts.archived_overview.bulk_action.export.form.type.help",
							defaultMessage: "Desired file type of your export",
						})}
					>
						<Form.Item name="type">
							<Select popupMatchSelectWidth>
								<Select.Option key="excel" value="excel">
									excel
								</Select.Option>
								<Select.Option key="csv" value="csv">
									csv
								</Select.Option>
								<Select.Option key="json" value="json">
									json
								</Select.Option>
							</Select>
						</Form.Item>
					</FieldSideLabel>
					<footer className="download_contacts-footer t-gap--top">
						<Button type="text" onClick={() => setShowModal(false)}>
							<FormattedMessage
								id="contacts.archived_overview.bulk_action.export.modal.footer.cancel"
								defaultMessage="Cancel"
							/>
						</Button>
						<Button type="primary" htmlType="submit">
							<FormattedMessage
								id="contacts.archived_overview.bulk_action.export.modal.footer.submit"
								defaultMessage="Download"
							/>
						</Button>
					</footer>
				</Form>
			</Modal>
		</>
	);
}
