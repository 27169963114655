import "./style.scss";

import React from "react";
import { FormattedMessage } from "react-intl";

export default function FallbackLock({ title, icon, description, buttonChildren, className }) {
	return (
		<section className={`fallback-lock fallback--lock ${className}`}>
			<div className="fallback--content">
				<article className="fallback--content-aside">
					<div>
						<h2 className="headline fallback--title">
							<FormattedMessage id={title} />
						</h2>
						<p>
							<FormattedMessage id={description} />
						</p>
					</div>
					{buttonChildren}
				</article>
				<img src={icon} alt="" className="fallback--image" />
			</div>
		</section>
	);
}
