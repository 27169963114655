import { trpc } from "../../../../trpc";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, FormInstance, Modal } from "antd";
import ContactLabelFormItems from "@/settings/contact/labels/components/ContactLabelFormItems";
import ShowFailedBanner from "@/shared/components/banner/failed";

interface ContactLabelModalProps {
	open: boolean;
	setShowCreateModal: (boolean) => void;
	setCreatedLabel?: (labelId: string) => void;
	labelSearchValue?: string;
}

export default function CreateContactLabelModal({
	open,
	setShowCreateModal,
	setCreatedLabel,
	labelSearchValue,
}: ContactLabelModalProps) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const [isLoading, setIsLoading] = useState(false);
	const [formInstance, setFormInstance] = useState<FormInstance>();
	const createLabelMutation = trpc.contactLabel.create.useMutation();

	const handleSubmit = (values) => {
		setIsLoading(true);

		createLabelMutation.mutate(
			{ ...values },
			{
				onSuccess: (response) => {
					trpcUtils.contactLabel.all.invalidate();
					setShowCreateModal(false);
					setIsLoading(false);

					if (setCreatedLabel) setCreatedLabel(response);
				},
				onError: (error) => {
					if (error?.data?.code === "CONFLICT") {
						formInstance?.setFields([
							{
								name: "name",
								errors: [intl.formatMessage({ id: "CONTACTS.SETTINGS.NAME.DUPLICATE" })],
							},
						]);

						setIsLoading(false);
						return;
					}

					ShowFailedBanner({
						title: intl.formatMessage({
							id: "BANNER.FAILED.CREATE_LABEL",
							defaultMessage: "Label could not be created. Please try again later.",
						}),
					});
					setShowCreateModal(false);
					setIsLoading(false);
				},
			}
		);
	};

	return (
		<Modal
			open={open}
			width={700}
			destroyOnClose
			title={<FormattedMessage id="CONTACTS_LABEL_MODAL.CREATE.TITLE" />}
			onCancel={() => setShowCreateModal(false)}
			footer={[
				<Button key="submit" type="primary" form="contactLabelForm" htmlType="submit" loading={isLoading}>
					<FormattedMessage id="FLOWS.REPORTS.STATUS_TYPES.MODAL.ADD" />
				</Button>,
			]}
		>
			<ContactLabelFormItems
				getFormInstance={setFormInstance}
				onSubmit={handleSubmit}
				labelSearchValue={labelSearchValue}
			/>
		</Modal>
	);
}
