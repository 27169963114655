import { detectorHelpers } from "../../../helpers";
import store from "../../store";
import actionTypes from "./notification.actionTypes";

const permissionTypes = {
	denied: "denied",
	granted: "granted",
	default: "default",
};

export const requestDeskNotificationPermission = () => (dispatch) => {
	if (!("Notification" in window)) {
		return;
	}

	if (Notification.permission !== permissionTypes.granted) {
		Notification.requestPermission();
		dispatch({ type: actionTypes.REQUEST_DESKTOP_NOTIFICATION_PERMISSION });
	}
};

export const sendDesktopNotification =
	({ title, message, onClick }) =>
	(dispatch) => {
		if (
			detectorHelpers.isPageVisible() ||
			!("Notification" in window) ||
			Notification.permission === permissionTypes.denied ||
			!store.getState().profile.account.user.isAvailable
		) {
			return;
		}

		const notification = new Notification(title, {
			icon: `${process.env.PUBLIC_URL}/logo_small.svg`, //eslint-disable-line no-undef
			badge: "Bothive",
			body: message,
		});

		notification.onclick = onClick;

		dispatch({
			type: actionTypes.SEND_DESKTOP_NOTIFICATION,
			lastNotification: notification,
		});
	};

export const startTabBarNotification = (message) => async (dispatch) => {
	if (detectorHelpers.isPageVisible() || !store.getState().profile.account.user.isAvailable) {
		return;
	}

	dispatch({ type: actionTypes.START_BROWSER_TAB_NOTIFICATION });
};
export const stopTabBarNotification = () => async (dispatch) => {
	dispatch({ type: actionTypes.STOP_BROWSER_TAB_NOTIFICATION });
};
