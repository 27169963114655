export default {
	FLOW_IS_FETCHING: "FLOW_IS_FETCHING",
	FLOW_DETAIL_IS_FETCHING: "FLOW_DETAIL_IS_FETCHING",
	FETCH_FLOW_FAILED: "FETCH_FLOW_FAILED",
	FETCH_FLOW_SUCCEEDED: "FETCH_FLOW_SUCCEEDED",

	FETCH_FLOWS: "FETCH_FLOWS",
	FETCH_FLOW_DETAILS: "FETCH_FLOW_DETAILS",

	FLOW_ALREADY_FETCHED: "FLOW_ALREADY_FETCHED",

	UPDATE_FLOWS_FILTER: "UPDATE_FLOWS_FILTER",

	RESET_FLOW_DETAIL: "RESET_FLOW_DETAIL",

	CREATE_FLOW: "CREATE_FLOW",

	UPDATE_FLOW: "UPDATE_FLOW",
	UPDATE_FLOW_LOCALLY: "UPDATE_FLOW_LOCALLY",

	DELETE_FLOW: "DELETE_FLOW",
	DELETE_FLOW_LOCALLY: "DELETE_FLOW_LOCALLY",

	HIDE_FLOW_WARNING: "HIDE_FLOW_WARNING",
	SHOW_FLOW_WARNING: "SHOW_FLOW_WARNING",

	TEAM_CHANGED: "TEAM_CHANGED", //listen to actions from the team structure
	FETCH_TEAM: "FETCH_TEAM", //listen to actions from the team structure
};
