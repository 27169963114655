import { Redirect, Route, Switch } from "react-router-dom";

import { Handler } from "../../components/organisms";
import { ContactLabels } from "../../settings/contact";
import AdvancedPage from "./advanced";
import { ApiKeyDetail, ApiKeyOverview } from "./api_key";
import BrandingSettings from "./branding";
import { ChannelDetail, ChannelOverview } from "./channels";
import ContactImport from "./contact_import";
import { SignatureOverview } from "./email_signature";
import config from "./config";
import InboxLabels from "./inboxLabels";
import IntegrationRoutes from "./integrations/common/routes";
import MembersOverview from "./members";
import NotificationSettings from "./notification";
import OpeningHours from "./openinghours";
import OrganizationSettings from "./organization";
import OverViewPage from "./overview";
import Template from "./template";
import VirtualAssistant from "./virtual_assistant";

export default () => (
	<Handler.OErrorFallback>
		<Template>
			<Switch>
				<Route path={config.routes.inbox.channels.detail} component={ChannelDetail} />
				<Route path={config.routes.inbox.channels.overview} component={ChannelOverview} />
				<Route path={config.routes.inbox.labels} component={InboxLabels} />
				<Route path="/:team/settings/inbox/signature/:signatureId?" component={SignatureOverview} />
				<Route path={config.routes.integrations.overview} component={IntegrationRoutes} />
				<Route path={config.routes.advanced} component={AdvancedPage} />
				<Route path={config.routes.apiKey.detail} component={ApiKeyDetail} />
				<Route path={config.routes.apiKey.overview} component={ApiKeyOverview} />
				<Route path={config.routes.contact.labels} component={ContactLabels} />
				<Route path="/:team/settings/contact" component={ContactImport} />
				<Route path={config.routes.virtualAssistant.general} component={VirtualAssistant} />
				<Route path={config.routes.members.overview} component={MembersOverview} />
				<Route path={config.routes.notification} component={NotificationSettings} />
				<Route path={config.routes.organization.general} component={OrganizationSettings} />
				<Route path={config.routes.organization.openingHours} component={OpeningHours} />
				<Route path={config.routes.branding.main} component={BrandingSettings} />
				<Route path={config.routes.root} component={OverViewPage} />
				<Route
					render={({ match }: any) => (
						<Redirect to={config.routes.root.replace(":team", match.params.team)} />
					)}
				/>
			</Switch>
		</Template>
	</Handler.OErrorFallback>
);
