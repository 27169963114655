import actionTypes from "./status_types.actionTypes";

const initialState = {
	list: [],
	meta: {
		total: 0,
	},
	isFetching: false,
	fetchingFailed: false,
	fetchingSucceeded: false,
};

function sortStatusTypes(data) {
	return (data || []).sort(
		(a, b) =>
			a.order - b.order || +b.isDefault - +a.isDefault || +a.isDone - +b.isDone || a.name.localeCompare(b.name)
	);
}

export default function (state = initialState, action) {
	const updateState = {
		[actionTypes.FLOW_STATUS_TYPE_IS_FETCHING]: () => ({
			...state,
			isFetching: true,
			fetchingFailed: false,
			fetchingSucceeded: false,
		}),
		[actionTypes.FETCH_FLOW_STATUS_TYPE_FAILED]: () => ({
			...state,
			isFetching: false,
			fetchingFailed: true,
			fetchingSucceeded: false,
		}),
		[actionTypes.FETCH_FLOW_STATUS_TYPE_SUCCEEDED]: () => ({
			...state,
			isFetching: false,
			fetchingFailed: false,
			fetchingSucceeded: true,
		}),

		[actionTypes.FETCH_FLOW_STATUS_TYPES]: () => ({
			...state,
			list: sortStatusTypes(action.data.data),
			meta: action.data.meta,
		}),
		[actionTypes.CREATE_STATUS_TYPE]: () => {
			let list = state.list;

			if (action.data.isDefault) {
				list = state.list.map((item) => (item.isDefault ? { ...item, isDefault: false } : item));
			}

			return {
				...state,
				list: sortStatusTypes([...list, action.data]),
				meta: { total: state.meta.total + 1 },
			};
		},
		[actionTypes.UPDATE_STATUS_TYPE]: () => ({
			...state,
			list: sortStatusTypes(
				state.list.map((status) => {
					if (status._id === action.data._id) {
						return action.data;
					}

					if (action.data.isDefault && status.isDefault) {
						return { ...status, isDefault: false };
					}

					return status;
				})
			),
		}),
		[actionTypes.COPY_STATUS_TYPE]: () => ({
			...state,
			list: sortStatusTypes(action.data.override ? [...action.data.data] : [...state.list, ...action.data.data]),
		}),
		[actionTypes.UPDATE_STATUS_TYPE_LOCALLY]: () => ({
			...state,
			list: sortStatusTypes(
				state.list.map((status) => {
					if (status._id === action.data._id) {
						return { ...status, ...action.data };
					}

					if (action.data.isDefault && status.isDefault) {
						return { ...status, isDefault: false };
					}

					return status;
				})
			),
		}),
		[actionTypes.DELETE_STATUS_TYPE]: () => ({
			...state,
			list: sortStatusTypes(state.list.filter((status) => status._id !== action.data)),
			meta: { total: state.meta.total - 1 },
		}),
		[actionTypes.TEAM_CHANGED]: () => initialState,
		[actionTypes.FETCH_TEAM]: () => initialState,
	};

	return updateState[action.type] ? updateState[action.type]() : state;
}
