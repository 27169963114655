import Contacts from "../../index";
import { Route, Switch } from "react-router-dom";
import ContactDetail from "@/modules/contacts/contact_detail/overview";
import ContactDetailTemplate from "@/modules/contacts/contact_detail/template";
import ContactAttributes from "@/modules/contacts/contact_detail/attributes";
import ContactImportReport from "@/modules/contacts/contact_import_report";
import ContactConversations from "@/modules/contacts/contact_detail/conversations";
import ContactDetailSettings from "@/modules/contacts/contact_detail/settings";

const ContactDetailRoutes = () => (
	<ContactDetailTemplate>
		<Switch>
			<Route path="/:team/contact/id/:contactId/settings" component={ContactDetailSettings} />
			<Route path="/:team/contact/id/:contactId/attributes" component={ContactAttributes} />
			<Route path="/:team/contact/id/:contactId/conversations" component={ContactConversations} />
			<Route path="/:team/contact/id/:contactId" component={ContactDetail} />
		</Switch>
	</ContactDetailTemplate>
);

export const ContactRoutes = () => (
	<Switch>
		<Route path="/:team/contact/import/id/:contactImportId" component={ContactImportReport} />
		<Route path="/:team/contact/id/:contactId" component={ContactDetailRoutes} />
		<Route path="/:team/contact" component={Contacts} />
		<Route render={() => <Redirect to="/:team/contact" />} />
	</Switch>
);
