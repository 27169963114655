import { TContactLinkingType, TContactType } from "@bothive_core/database";
import { defineMessages } from "react-intl";

export const contactTypesI18n = defineMessages<TContactType>({
	customer: {
		id: "contact.type.customer",
		defaultMessage: "customer",
	},
	prospect: {
		id: "contact.type.prospect",
		defaultMessage: "prospect",
	},
	linkedContact: {
		id: "contact.type.linked_contact",
		defaultMessage: "linked contacts",
	},
	anonymous: {
		id: "contact.type.anonymous",
		defaultMessage: "Anonymous",
	},
	other: {
		id: "contact.type.other",
		defaultMessage: "other",
	},
	addressBookContact: {
		id: "contact.type.address_book_contact",
		defaultMessage: "Address book",
	},
});

export const ContactLinkingTypeI18n = defineMessages<TContactLinkingType>({
	child: {
		id: "contact_linking.type.child",
		defaultMessage: "child",
	},
	dependent: {
		id: "contact_linking.type.dependent",
		defaultMessage: "dependent",
	},
	lawyer: {
		id: "contact_linking.type.lawyer",
		defaultMessage: "lawyer",
	},
	notary: {
		id: "contact_linking.type.notary",
		defaultMessage: "notary",
	},
	other: {
		id: "contact_linking.type.other",
		defaultMessage: "other",
	},
	partner: {
		id: "contact_linking.type.partner",
		defaultMessage: "partner",
	},
	parent: {
		id: "contact_linking.type.parent",
		defaultMessage: "parent",
	},
	sibling: {
		id: "contact_linking.type.sibling",
		defaultMessage: "sibling",
	},
	guardian: {
		id: "contact_linking.type.guardian",
		defaultMessage: "guardian",
	},
});

export const dependentType = {
	child: "CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.CHILD",
	dependent: "CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.DEPENDENT",
	partner: "CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.PARTNER",
	other: "CONTACT.DETAIL.ATTRIBUTES.LINKING.MODAL.TYPE.OTHER",
} as const;
