import React from "react";

import { keyEvents } from "../../../helpers";

export default function ARadioButtonDefault(props) {
	const { onChange, label, checked, disabled, id, className } = props;
	const cName = `a-radio-button ${checked ? "active" : ""} ${disabled ? "disabled" : ""} ${className}`;
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			onChange();
		}
	};
	const handleOnChange = () => {
		if (onChange) {
			onChange(!checked);
		}
	};

	return (
		<label
			role="radio" //eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
			tabIndex="0"
			aria-labelledby={label}
			aria-checked={checked}
			onKeyUp={handleKeyUp}
			className={cName}
			htmlFor={id}
		>
			<input type="radio" {...props} onChange={handleOnChange} checked={checked} aria-disabled={disabled} />
			<div className="a-radio-button--label" aria-checked={checked}></div>
		</label>
	);
}

ARadioButtonDefault.defaultProps = {
	disabled: false,
	label: "",
	name: "",
	className: "",
};
