import React from "react";
import { FormattedMessage } from "react-intl";

import { keyEvents } from "../../../helpers";

export default function AListItemDropdown({ id, value, selected, className, onClick }) {
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			onClick(id);
		}
	};

	return (
		<li
			id={id}
			role="option"
			tabIndex="0"
			aria-selected={selected}
			onClick={() => onClick(id)}
			onKeyUp={handleKeyUp}
			className={`a-dropdown--list-item ${className} ${selected ? "selected" : ""}`}
		>
			<p className="a-dropdown--text">
				<FormattedMessage id={value} />
			</p>
		</li>
	);
}

AListItemDropdown.defaultProps = {
	selected: false,
	className: "",
	onClick: undefined,
};
