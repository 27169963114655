import React from "react";

import { keyEvents } from "../../../helpers";

export default class ADefaultToggle extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			checked: props.checked || props.value,
		};

		this.bindHandles();
	}

	static getDerivedStateFromProps(props, state) {
		const checked = props.checked || props.value;

		if (state.checked !== checked) {
			return { checked: checked };
		}

		return null;
	}

	bindHandles() {
		this.handleChange = this.handleChange.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
	}

	handleChange() {
		if (this.props.onChange) {
			this.props.onChange(!this.state.checked);
		}

		this.setState((prev) => ({ checked: !prev.checked }));
	}

	handleKeyUp(event) {
		if (keyEvents.enterOrSpacePressed(event)) {
			this.handleChange();
		}
	}

	render() {
		const props = { ...this.props };

		delete props.defaultValue;
		delete props.error;

		return (
			<label
				role="checkbox" //eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
				tabIndex="0"
				data-checked={this.state.checked}
				data-disabled={this.props.disabled}
				aria-labelledby={this.props.label}
				aria-checked={this.state.checked}
				onKeyUp={this.handleKeyUp}
				className={`a-toggle ${this.props.className}`}
				htmlFor={this.props.id || this.props._id}
			>
				<input
					type="checkBox"
					{...props}
					onChange={this.handleChange}
					checked={this.state.checked}
					aria-disabled={this.props.disabled}
				/>
				<div className="a-toggle--label" aria-checked={this.state.checked} />
			</label>
		);
	}
}

ADefaultToggle.defaultProps = {
	label: "",
	checked: false,
	disabled: false,
	className: "",
	onChange: undefined,
};
