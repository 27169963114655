import { fieldTranslations } from "./config";
import { ImportMapping } from "./types";

type ToKeys = keyof typeof fieldTranslations;

export function getKeyFromMapping({ key, mapping }: { key: ToKeys; mapping: Record<string, ImportMapping> }) {
	return Object.entries(mapping).find(([_, value]) => value.to === key)?.[0];
}

export function reverseMapping(mapping: Record<string, ImportMapping>) {
	return Object.entries(mapping).reduce<Record<string, string>>(
		(prev, [key, value]) => ({ ...prev, [value.to]: key }),
		{}
	);
}
