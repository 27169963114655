import "./style.scss";

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { actions as dashboardActions } from "@/store/dashboard";
import Aside from "./aside";
import config from "./config";

function SettingsTemplate({ match, foldAside, unfoldAside, children }) {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(dashboardActions.ui.foldAside());

		return () => {
			dispatch(dashboardActions.ui.unfoldAside());
		};
	}, [foldAside, unfoldAside]);

	return (
		<main className="page-settings--template">
			<Aside items={config.aside(match.params)} />
			{children}
		</main>
	);
}

export default withRouter(SettingsTemplate);
