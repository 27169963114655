import "./style.scss";

import { DeleteOutlined } from "@ant-design/icons";
import { Alert, Button, List, Typography, notification } from "antd";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { FILE_SIZE_LIMIT, disabledFileTypes } from "./config";
import { ErrorType } from "./types";

interface IFileUploadParams {
	value?: File[];
	disabled?: boolean;
	className?: string;
	uploadLimit?: number;
	uploadParams?: Record<string, any>;
	onChange?: (files: File[]) => void;
}

const defaultErrorState = {
	limit: [],
	size: [],
	type: [],
	duplicate: [],
};

const errorMessages = defineMessages({
	limit: {
		id: "file_upload.error.limit.message",
		defaultMessage: "You are allowed to upload  {plural,limit, one {# file} other {# files}}",
	},
	size: {
		id: "file_upload.error.size.message",
		defaultMessage: "Max file size is 25Mb",
	},
	type: {
		id: "file_upload.error.type.message",
		defaultMessage: "File type is not allowed",
	},
	duplicate: {
		id: "file_upload.error.duplicate.message",
		defaultMessage: "File is already uploaded",
	},
});

export default function FileUpload({ value, uploadLimit, disabled, className = "", onChange }: IFileUploadParams) {
	const intl = useIntl();
	const fieldRef = useRef<HTMLInputElement>(null);
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
	const [uploadError, setUploadError] = useState<ErrorType>(defaultErrorState);

	useEffect(() => setUploadedFiles(value || []), [value]);

	useEffect(() => {
		const dropZone = document.querySelector("body");

		const handleDragEnter = (e: DragEvent) => {
			e.preventDefault();
			e.stopPropagation();
		};
		const handleDragOver = (e: DragEvent) => {
			e.preventDefault();
			e.stopPropagation();
		};
		const handleDrop = (event: ChangeEvent<HTMLInputElement> | DragEvent) => {
			event.preventDefault();
			event.stopPropagation();
			onUpload(event);
		};

		dropZone?.addEventListener("dragenter", handleDragEnter);
		dropZone?.addEventListener("dragover", handleDragOver);
		dropZone?.addEventListener("drop", handleDrop);

		return () => {
			dropZone?.removeEventListener("dragenter", handleDragEnter);
			dropZone?.removeEventListener("dragover", handleDragOver);
			dropZone?.removeEventListener("drop", handleDrop);
		};
	}, []);

	const onUpload = async (event: ChangeEvent<HTMLInputElement> | DragEvent) => {
		try {
			event.preventDefault();
			event.stopPropagation();

			let files = event instanceof DragEvent ? event?.dataTransfer?.files : event?.target.files;

			// Filter files that don't meet validation
			let selectedFiles = [...(files as unknown as File[])].filter((file) => {
				// limit size (mb)
				if (file.size > FILE_SIZE_LIMIT) {
					setUploadError((prev) => ({ ...prev, size: [...new Set([...prev.size, file.name])] }));
					return false;
				}
				// block extensions
				const fileType = file.name.split(".").pop();
				if (!fileType || disabledFileTypes.includes(fileType)) {
					setUploadError((prev) => ({ ...prev, type: [...new Set([...prev.type, file.name])] }));
					return false;
				}
				// block duplicates
				const duplicatedFile = uploadedFiles.find((item) => item.name === file.name);

				if (duplicatedFile) {
					setUploadError((prev) => ({ ...prev, duplicate: [...new Set([...prev.duplicate, file.name])] }));
					return false;
				}

				return true;
			});

			if (!selectedFiles?.length) return;

			const totalFilesAfterUpload = selectedFiles.length + uploadedFiles.length;

			// Filter files that exceed the file limit
			if (uploadLimit && uploadLimit < totalFilesAfterUpload) {
				let fileLimit = uploadLimit - uploadedFiles.length;

				if (fileLimit < 0) {
					setUploadError((prev) => ({
						...prev,
						limit: [...prev.limit, ...selectedFiles.map((item) => item.name)],
					}));
					return;
				}

				selectedFiles = selectedFiles.slice(0, fileLimit);
				setUploadError((prev) => ({
					...prev,
					limit: [...prev.limit, ...selectedFiles.slice(fileLimit).map((item) => item.name)],
				}));
			}

			setUploadedFiles((prevState) => {
				const newValues = [...prevState, ...selectedFiles];

				onChange && onChange(newValues);
				return newValues;
			});

			if (fieldRef.current) fieldRef.current.value = ""; //Allow re-upload same file
		} catch (error) {
			console.error("Failed to upload file", error);
			notification.error({
				message: intl.formatMessage({
					id: "file_upload.upload.failed",
					defaultMessage: "We temporarily cannot upload this file, try again later",
				}),
				placement: "bottomRight",
				duration: 8,
			});
		}
	};

	const handleFileDelete = async (fileName: string) => {
		setUploadedFiles((prev) => {
			const newValues = prev.filter((item) => item.name !== fileName);

			onChange && onChange(newValues);
			return newValues;
		});
	};

	return (
		<div className={`upload-file-wrapper ${className || ""}`}>
			<label htmlFor="upload" className="upload-file-label">
				<input id="upload" type="file" name="file" ref={fieldRef} onChange={onUpload} disabled={disabled} />
				<Typography.Text type="secondary">
					<FormattedMessage
						id="file_upload.segment.label"
						defaultMessage="Click or drag file(s) to this area to upload"
					/>
				</Typography.Text>
			</label>
			{Object.keys(uploadError).map((key) => {
				if (!uploadError[key]?.length) return;

				return (
					<Alert
						closable
						type="error"
						className="upload-file-error"
						message={intl.formatMessage(errorMessages[key])}
						onClose={() => setUploadError((prev) => ({ ...prev, [key]: [] }))}
						description={
							<ul>
								{uploadError[key].map((fileName) => (
									<li key={fileName}>{fileName}</li>
								))}
							</ul>
						}
					/>
				);
			})}
			{uploadedFiles?.length > 0 && (
				<List
					bordered
					size="small"
					dataSource={uploadedFiles}
					className="upload-file-stack"
					renderItem={(fileState) => (
						<List.Item className="upload-file-stack_item">
							{fileState.name}
							<Button
								danger
								type="text"
								disabled={disabled}
								icon={<DeleteOutlined />}
								onClick={() => handleFileDelete(fileState.name)}
							/>
						</List.Item>
					)}
				/>
			)}
		</div>
	);
}
