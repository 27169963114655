import "./style.scss";

import { Space, Spin } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import { flowState, selectedFlowIdState } from "@/flows/common/state";

import DeleteFlow from "./delete_flow";
import FlowCard from "./flow";
import WidgetCard from "./widget";

export default function ReportSettings() {
	const isAllowedToEdit = useSelector((state) => state.dashboard.permissions.userPermissions.editFlows);
	const flowId = useRecoilValue(selectedFlowIdState);
	const flow = useRecoilValueLoadable(flowState({ id: flowId }));

	const generateBody = () => {
		const isLoading = flow?.state === "loading";

		return (
			<Space direction="vertical" size="large" className="frs-overview-space">
				<FlowCard flow={flow.contents} isLoading={isLoading} isDisabled={!isAllowedToEdit} />
				<WidgetCard isLoading={isLoading} isDisabled={!isAllowedToEdit} />
				{isAllowedToEdit && <DeleteFlow />}
			</Space>
		);
	};

	return (
		<main className="frs-overview">
			<Spin spinning={flow?.state === "loading"} tip={<FormattedMessage id="FLOWS.REPORT.SETTINGS.LOADING" />}>
				{generateBody()}
			</Spin>
		</main>
	);
}
