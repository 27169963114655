import { RouteComponentProps } from "react-router-dom";
import { IntlShape } from "react-intl/lib";
import { Button, Form, Modal, notification, Input, Select, Switch, Space } from "antd";

import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import { routesConfig } from "@/config";

type History = RouteComponentProps["history"];
type Location = RouteComponentProps["location"];

export function replaceUrlID(connectionId: string, history: History, location: Location) {
	const urlSegments = location.pathname.split("/");
	urlSegments[urlSegments.length - 1] = connectionId;
	const newPath = urlSegments.join("/");
	history.replace(newPath);
}

export function addConnection(
	intl: IntlShape,
	trpcUtils: any,
	integrationId: string,
	history: History,
	location: Location,
	addConnectionMutation: any,
	values?: {
		token?: string;
		baseUrl?: string;
		username?: string;
		password?: string;
	}
) {
	addConnectionMutation.mutate(
		{
			integrationId,
			...values,
		},
		{
			onSuccess: (data) => {
				trpcUtils.integration.getConnections.invalidate();
				replaceUrlID(data._id, history, location);
				ShowSuccessBanner({
					title: intl.formatMessage({
						id: "settings.integrations.integration.add.success",
						defaultMessage: "The integration has been successfully added.",
					}),
				});
			},
			onError: (error) => {
				if (error.message.includes("limit")) {
					notification.error({
						message: intl.formatMessage({
							id: "settings.integrations.integration.add.limit_reached",
							defaultMessage:
								"You have reached the amount of integrations you can add in your current plan",
						}),
						placement: "bottomRight",
						duration: 0, // Don't close automatically
					});
					return;
				}

				notification.error({
					message: intl.formatMessage({
						id: "settings.integrations.integration.add.failed",
						defaultMessage: "The integration couldn't be added. Please try again later",
					}),
					placement: "bottomRight",
				});
			},
		}
	);
}

export function deleteConnection(
	connectionId: string,
	intl: IntlShape,
	trpcUtils,
	deleteConnectionMutation: any,
	history: History,
	teamSlug: string
) {
	deleteConnectionMutation.mutate(
		{ _id: connectionId },
		{
			onSuccess: () => {
				history.push(routesConfig.dashboard.settings.integrations.overview.replace(":team", teamSlug));
				trpcUtils.integration.getConnections.invalidate();
				ShowSuccessBanner({
					title: intl.formatMessage({
						id: "settings.integrations.integration.delete.success",
						defaultMessage: "The integration has been successfully deleted.",
					}),
				});
			},
			onError: () => {
				ShowFailedBanner({
					title: intl.formatMessage({
						id: "settings.integrations.integration.delete.failed",
						defaultMessage: "The integration couldn't be deleted. Please try again later",
					}),
				});
			},
		}
	);
}
