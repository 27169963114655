import "./style.scss";

import { Select } from "antd";
import { useIntl } from "react-intl";

const countriesList = require("world_countries_lists/data/countries/_combined/countries.json");

function CountryPicker(props: any) {
	const intl = useIntl();

	return (
		<Select
			{...props}
			showSearch
			autoComplete="doNotShow"
			className="country_picker"
			filterOption={(input, option) =>
				`${option?.label || ""} ${option?.value || ""}`.toLowerCase().includes(input.toLowerCase())
			}
		>
			{countriesList.map((country) => (
				<Select.Option key={country.alpha2} value={country.alpha2} label={country[intl.locale]}>
					{country[intl.locale]}
				</Select.Option>
			))}
		</Select>
	);
}

export default CountryPicker;
