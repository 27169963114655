import "./style.scss";

import { Button, Modal, Typography } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { trpc } from "../../../trpc";

export default function InitWalkthrough() {
	const user = useSelector((state) => state.profile.account.user);
	const updateWalkthroughFinished = trpc.user.updateWalkthroughFinished.useMutation();

	const [showWalkthrough, setShowWalkthrough] = useState(!user?.walkthrough?.initInbox);

	const handleClose = () => {
		setShowWalkthrough(false);
		updateWalkthroughFinished.mutateAsync({ initInbox: true }).finally();
	};

	if (user.walkthrough?.initInbox) return null;

	return (
		<Modal
			title={
				<Typography.Title className="inbox_init_walkthrough-title">
					<FormattedMessage id="inbox.init_walkthrough.title" defaultMessage="It's finally here 🎉" />
				</Typography.Title>
			}
			footer={null}
			open={showWalkthrough}
			onCancel={handleClose}
			width="75vw"
			centered
		>
			<div className="inbox_init_walkthrough-body">
				<Typography.Text>
					<FormattedMessage
						id="inbox.init_walkthrough.description"
						defaultMessage="Discover the power of inbox zero combined with our powerful team features. "
					/>
				</Typography.Text>
				<iframe
					className="inbox_init_walkthrough-frame"
					src="https://app.storylane.io/share/pd5xkvkgoqsp"
					allow="fullscreen"
				></iframe>
				<Button onClick={handleClose} className="inbox_init_walkthrough-button">
					<FormattedMessage id="inbox.init_walkthrough.button" defaultMessage="Start using" />
				</Button>
			</div>
		</Modal>
	);
}
