import "./style.scss";

import React from "react";

import CardOverview from "@/flows/overview/create/overview/card_overview";
import HeaderDescription from "@/shared/components/header/description";

export default function AddFlowSelect({ handleSelectTemplate }) {
	return (
		<div className="foc-add-flow-layout">
			<HeaderDescription
				title="FLOWS.OVERVIEW.ADD_FLOW.TITLE"
				description="FLOWS.OVERVIEW.ADD_FLOW.DESCRIPTION"
				className="layout-header t-gap--bottom"
			/>
			<CardOverview handleSelectTemplate={handleSelectTemplate} />
		</div>
	);
}
