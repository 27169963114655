import { useIntl } from "react-intl";
import settingsConfig from "@/modules/settings/config";
import { useParams } from "react-router-dom";
import { icon } from "@/assets/images";

export function organizationData() {
	const routeParameters = useParams<{ team: string }>();
	const intl = useIntl();
	return [
		{
			id: "general",
			title: intl.formatMessage({
				id: "settings_overview.organization.general.title",
				defaultMessage: "General",
			}),
			description: intl.formatMessage({
				id: "settings_overview.organization.general.description",
				defaultMessage: "Basic settings for your company such as name, telephone, locations, ...",
			}),
			link: settingsConfig.routes.organization.general.replace(":team", routeParameters.team),
			icon: icon.settings.general,
		},
		{
			id: "openingHours",
			title: intl.formatMessage({
				id: "settings_overview.organization.opening_hours.title",
				defaultMessage: "Opening hours",
			}),
			description: intl.formatMessage({
				id: "settings_overview.organization.opening_hours.description",
				defaultMessage: "At what times are you open or available.",
			}),
			link: settingsConfig.routes.organization.openingHours.replace(":team", routeParameters.team),
			icon: icon.settings.openingshours,
		},
	];
}

export function brandingData() {
	const routeParameters = useParams<{ team: string }>();
	const intl = useIntl();
	return [
		{
			id: "styling",
			title: intl.formatMessage({
				id: "settings_overview.branding.styling.title",
				defaultMessage: "Styling",
			}),
			description: intl.formatMessage({
				id: "settings_overview.branding.styling.description",
				defaultMessage: "Personalize how we advertise your company.",
			}),
			link: settingsConfig.routes.branding.main.replace(":team", routeParameters.team),
			icon: icon.settings.branding,
		},
	];
}

export function contactsData() {
	const routeParameters = useParams<{ team: string }>();
	const intl = useIntl();
	return [
		{
			id: "contacts",
			title: intl.formatMessage({
				id: "settings_overview.contacts.contacts.title",
				defaultMessage: "Contacts",
			}),
			description: intl.formatMessage({
				id: "settings_overview.contacts.contacts.description",
				defaultMessage: "Contact settings and import options.",
			}),
			link: settingsConfig.routes.contact.overview.replace(":team", routeParameters.team),
			icon: icon.settings.contacts,
		},
		{
			id: "contactLabels",
			title: intl.formatMessage({
				id: "settings_overview.contacts.labels.title",
				defaultMessage: "Contact labels",
			}),
			description: intl.formatMessage({
				id: "settings_overview.contacts.labels.description",
				defaultMessage: "Manage your organizations contact labels.",
			}),
			link: settingsConfig.routes.contact.labels.replace(":team", routeParameters.team),
			icon: icon.settings.contactLabels,
		},
	];
}

export function channelData() {
	const routeParameters = useParams<{ team: string }>();
	const intl = useIntl();
	return [
		{
			id: "google",
			tooltip: intl.formatMessage({
				id: "settings_overview.channels.google.tooltip",
				defaultMessage: "Connect with Gmail",
			}),
			src: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/integration_icon/google_icon.png",
		},
		{
			id: "outlook",
			tooltip: intl.formatMessage({
				id: "settings_overview.channels.office.tooltip",
				defaultMessage: "Connect with Outlook",
			}),
			src: "https://storage.cloud.google.com/bothive_assets_8a0s1s3e/integration_icon/office_icon.svg",
		},
	];
}

export function advancedData() {
	const routeParameters = useParams<{ team: string }>();
	const intl = useIntl();
	return [
		{
			id: "notificationSettings",
			title: intl.formatMessage({
				id: "settings_overview.advanced.notification_settings.title",
				defaultMessage: "Notification settings",
			}),
			description: intl.formatMessage({
				id: "settings_overview.advanced.notification_settings.description",
				defaultMessage: "Set up how we can keep you informed.",
			}),
			link: settingsConfig.routes.notification.replace(":team", routeParameters.team),
			icon: icon.settings.notification,
		},
		{
			id: "apiKey",
			title: intl.formatMessage({
				id: "settings_overview.advanced.api_key.title",
				defaultMessage: "API key",
			}),
			description: intl.formatMessage({
				id: "settings_overview.advanced.api_key.description",
				defaultMessage: "The API key is a unique code used to access the Bothive API and identify a customer.",
			}),
			link: settingsConfig.routes.apiKey.overview.replace(":team", routeParameters.team),
			icon: icon.settings.apiKey,
		},
		{
			id: "destructive",
			title: intl.formatMessage({
				id: "settings_overview.advanced.destructive.title",
				defaultMessage: "Dangerous options",
			}),
			description: intl.formatMessage({
				id: "settings_overview.advanced.destructive.description",
				defaultMessage: "Destructive options such as deactivating your team.",
			}),
			link: settingsConfig.routes.advanced.replace(":team", routeParameters.team),
			icon: icon.settings.dangerzone,
		},
	];
}
