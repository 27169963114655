import React from "react";

import { FormElement } from "../components/atoms"; //eslint-disable-line no-unused-vars
import { typesConfig } from "../config";
import formatHelpers from "./format.helpers";
import validator from "./validators.helpers";

function formatMinutes(minutes) {
	let hour = Math.floor(Number(minutes) / 60);

	let datePart = "AM";
	const minute = Math.floor(Number(minutes) % 60);

	if (hour >= 12) {
		datePart = "PM";
		hour = hour > 12 ? hour - 12 : hour;
	}

	return `${formatHelpers.formatTimeToHH(hour)}:${formatHelpers.formatTimeToHH(minute)} ${datePart}`;
}

export default {
	generateBasicControlRenderedField(child) {
		return ({ handler, touched, hasError, meta }) => {
			const error = validator.errorMessage({ type: meta.type, touched, hasError });

			return child({ ...meta, ...handler(), error: touched && error !== undefined });
		};
	},
	generateControlRenderedField(child, fieldElement = []) {
		return ({ handler, touched, hasError, meta }) => {
			const error = validator.errorMessage({ type: meta.type, touched, hasError });
			const { fieldClass, multiLanguage, ...props } = meta;
			const { value, ...handlers } = handler();

			return (
				<FormElement.AFieldLabel
					{...props}
					{...fieldElement}
					{...handlers}
					value={multiLanguage ? value[multiLanguage] : value}
					error={error}
					className={`o-form--input ${fieldClass}`}
				>
					{child({ ...props, ...handler(), error: touched && error !== undefined, disabled: props.disabled })}
				</FormElement.AFieldLabel>
			);
		};
	},
	generateControlRenderedSideField(child, fieldElement = []) {
		return ({ handler, touched, hasError, meta }) => {
			const error = validator.errorMessage({ type: meta.type, touched, hasError });
			const {
				disabled,
				fieldClass,
				fieldType,
				bodyClassName,
				multiLanguage, //eslint-disable-line no-unused-vars
				...props
			} = meta;

			return (
				<FormElement.AFieldSideLabel
					{...props}
					{...fieldElement}
					disabled={disabled}
					bodyClassName={bodyClassName}
					{...handler()}
					error={error}
					className={`o-form--input ${fieldClass}`}
					type={fieldType}
				>
					{child({ ...props, ...handler(), disabled, error: touched && error !== undefined })}
				</FormElement.AFieldSideLabel>
			);
		};
	},
	generateTimes({ min = 0, max = 60 * 24 }) {
		let values = [];

		while (!values.length || values[values.length - 1].id < max) {
			const id = values.length ? values[values.length - 1].id + 15 : min || 0;

			values.push({ id, value: formatMinutes(id) });
		}

		return values;
	},
	shouldComponentBeLocked({ permissions, type, team, totalMembers }) {
		switch (type) {
			case typesConfig.lockTypes.locations:
				return permissions.locations <= team.address.length;
			case typesConfig.lockTypes.users:
				return permissions.users <= totalMembers;
			case typesConfig.lockTypes.customizeFont:
				return !permissions.customizeFont;
			case typesConfig.lockTypes.liveChat:
				return !permissions.liveChat;
			default:
				return false;
		}
	},
};
