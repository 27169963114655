import { FilterOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import { queryParamsConfig } from "@/flows/common/config";
import {
	searchFilterState,
	selectedProgressState,
	selectedStatusTypeState,
	selectedContactLabelState,
	selectedAccountManagerState,
} from "@/flows/flow_detail/reports/state";

import FilterModal from "./filter_modal";
import { getTotalActiveFilters } from "./helper";
import style from "./style.module.scss";

export default function ReportListHeader() {
	const { search } = useLocation();
	const [status, setStatus] = useRecoilState(selectedStatusTypeState);
	const [filter, setSearchFilter] = useRecoilState(searchFilterState);
	const [progress, setProgress] = useRecoilState(selectedProgressState);
	const [selectedContactLabel, setSelectedContactLabel] = useRecoilState(selectedContactLabelState);
	const [selectedAccountManager, setSelectedAccountManager] = useRecoilState(selectedAccountManagerState);

	const activeFilters = getTotalActiveFilters(status, progress, filter, selectedContactLabel, selectedAccountManager);

	useEffect(() => {
		if (!search.length) {
			return;
		}

		const queryParams = new URLSearchParams(search);

		setStatus(queryParams.get(queryParamsConfig.status));
		setProgress(queryParams.get(queryParamsConfig.progress));
		setSearchFilter(queryParams.get(queryParamsConfig.search));
		setSelectedContactLabel(queryParams.get(queryParamsConfig.label));
		setSelectedAccountManager(queryParams.get(queryParamsConfig.accountManager));
	}, []);

	return (
		<header className={style.report_list_header}>
			<p>
				<FormattedMessage id="FLOWS.REPORT_DETAIL.LIST.FILTER.TITLE" values={{ total: activeFilters }} />
			</p>
			<Popover trigger="click" content={<FilterModal />} placement="bottom">
				<Button type="text" size="large" icon={<FilterOutlined />} />
			</Popover>
		</header>
	);
}
