import { Avatar, Skeleton } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

import { websiteIcon } from "@/assets/images/icon";

import style from "./style.module.scss";

export default function UserPreview({ src, username, contactUrl, isLoading, className = "" }) {
	if (isLoading) {
		return (
			<div className={`${style.user_preview_skeleton} ${className}`}>
				<Skeleton.Avatar shape="square" active className="t-gap--right-sm" />
				<Skeleton.Input active />
			</div>
		);
	}

	const generatePreview = () => (
		<div className={`${style.user_preview} ${className}`}>
			<Avatar src={src || websiteIcon} shape="square" className={style.user_preview_avatar} />
			<p className={style.user_preview_name}>{username || <FormattedMessage id="FALLBACK.CONTACT.NAME" />}</p>
		</div>
	);

	if (!contactUrl) {
		return <div className={`${style.user_preview} ${className}`}>{generatePreview()}</div>;
	}

	return <NavLink to={contactUrl}>{generatePreview()}</NavLink>;
}
