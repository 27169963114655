import "./style.scss";

import { Avatar, List, Typography } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Buttons } from "@/components/atoms";

export default function AssigneeList({ userId, items, onClick, isDestructive }) {
	const intl = useIntl();

	let actions = () => [<Buttons.AIconButton key="add" icon="add" className="success" iconClass="small success" />];
	const handleClick = (_id) => {
		if (!onClick || isDestructive) return;

		onClick(_id);
	};

	if (isDestructive) {
		actions = (_id) => [
			<Buttons.AIconButton
				key="delete"
				icon="delete"
				className="error"
				iconClass="error"
				onClick={() => onClick(_id)}
			/>,
		];
	}

	return (
		<List
			itemLayout="horizontal"
			dataSource={items}
			locale={{
				emptyText: intl.formatMessage({ id: "ASSIGNEE_POPUP.FALLBACK.TITLE" }),
			}}
			className="contact-assignee-list"
			renderItem={(assignee) => (
				<List.Item
					key={assignee.userId}
					data-state={isDestructive ? "destructive" : "default"}
					actions={actions(assignee.userId)}
					className="contact-assignee-list-item"
					onClick={() => handleClick(assignee.userId)}
				>
					<div className="list-item--content">
						<Avatar shape="square" size="small" src={assignee.avatar} />
						<Typography.Text className="t-gap--left-sm">
							{assignee.username || assignee.name}
							{assignee.userId === userId && (
								<span className="list-item--me">
									<FormattedMessage id="ASSIGNEE_POPUP.USERNAME.ME" />
								</span>
							)}
						</Typography.Text>
					</div>
				</List.Item>
			)}
		/>
	);
}
