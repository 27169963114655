import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { actions as dashboardActions } from "@/store/dashboard";

const usePrevious = (value) => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
};

const useCollapsedAside = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(dashboardActions.ui.foldAside());

		return () => {
			dispatch(dashboardActions.ui.unfoldAside());
		};
	}, []);
};

const useWindowWidth = () => {
	const [windowSize, setWindowSize] = useState(undefined);

	useEffect(() => {
		function handleResize() {
			setWindowSize(window.innerWidth);
		}

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
};

const useHasFlowEditPermissions = () => useSelector((state) => state.dashboard.permissions.userPermissions.editFlows);

const useSetFocus = ({ ref, dependencies }) => {
	useEffect(() => {
		if (ref?.current) {
			setTimeout(() => {
				ref?.current?.focus();
			}, 100);
		}
	}, dependencies);
};

export { useCollapsedAside, useHasFlowEditPermissions, usePrevious, useWindowWidth, useSetFocus };
