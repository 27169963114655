import { Skeleton } from "antd";
import React from "react";

import { keyEvents } from "../../../helpers";

export default function MIntegrationCard({
	id,
	logo,
	name,
	language,
	selected,
	disabled,
	content,
	className,
	onClick,
	loading,
}) {
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event) && onClick) {
			handleClick();
		}
	};
	const handleClick = () => {
		if (disabled) {
			return;
		}

		onClick(id);
	};

	if (loading) {
		return (
			<article
				data-disabled={true}
				data-state="loading"
				className={`m-card m-card--integration  ${className || ""}`}
			>
				<Skeleton
					loading
					active
					title={false}
					paragraph={false}
					avatar={{ shape: "square" }}
					className="integration-image"
				/>
				<Skeleton loading active avatar={false} paragraph={{ rows: 2 }} />
			</article>
		);
	}

	return (
		<article
			role="button" //eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
			tabIndex="0"
			onClick={handleClick}
			onKeyUp={handleKeyUp}
			data-disabled={disabled}
			data-state={selected && "selected"}
			className={`m-card m-card--integration  ${className || ""}`}
		>
			<img src={logo} alt="" className="a-image integration-image" />
			<p className="headline font-size-md">{name}</p>
			<p className="m-card--description">{content.description[language]}</p>
		</article>
	);
}
