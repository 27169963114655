import moment from "moment";

function convertDateToUnixTimestamp(date) {
	return Math.round(date.getTime() / 1000);
}

export default {
	convertDateToUnixTimestamp,
	getTodayUnixTimestamp: () => convertDateToUnixTimestamp(new Date()),
	formateTimestampToDate(timestamp) {
		return moment.unix(timestamp).format("DD/MM/YYYY");
	},
	formateDate(timestamp) {
		return moment(timestamp).format("DD/MM/YYYY");
	},
	groupByDate({ items, key = "timestamp" }) {
		const groups = {};

		items.forEach((value) => {
			const dateKey = moment(value[key]).format("DD_MM_YYYY");

			groups[dateKey] = {
				[key]: value[key],
				sections: [...(groups[dateKey]?.sections || []), value],
			};
		});

		return Object.keys(groups).map((key) => groups[key]);
	},
	getPastDate(offset) {
		const date = new Date();

		date.setDate(date.getDate() - offset);

		return date;
	},
	getNewestDate(date1, date2) {
		return new Date(date1) > new Date(date2) ? date1 : date2;
	},
	sortDatesNewToOld({ data, key = "createdAt" }) {
		return data.sort((a, b) => Date.parse(a[key]) < Date.parse(b[key]));
	},
	sortDatesOldToNew({ data, key = "createdAt" }) {
		return data.sort((a, b) => Date.parse(a[key]) > Date.parse(b[key]));
	},
	isDatePast(date) {
		return new Date(date) > new Date();
	},
	getRelativeDate(date) {
		const now = moment();
		const _date = moment(date);

		if (now.format("DD_MM_YYYY") === _date.format("DD_MM_YYYY")) {
			return _date.format("kk:mm");
		}

		if (now.diff(_date, "days") <= 7) {
			return _date.format("kk:mm DD/MM/YY");
		}

		return _date.format("DD/MM/YYYY");
	},
};
