import { ContactRouterOutput } from "@/trpc";
import { Button, Divider, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { useRecoilState } from "recoil";
import { selectedAllArchivedContactsState, selectedArchivedContactsState } from "../state";
import AccountManagers from "./AccountManagers";
import ContactLabels from "./ContactLabels";
import "../../style.scss";
import Unarchive from "./Unarchive";
import Restore from "./Restore";
import Delete from "./Delete";
import Download from "./Download";

interface BulkActionsProps {
	contactQueryData?: ContactRouterOutput["getAllContacts"];
	includesDeleted: boolean;
	filters: {
		q?: string;
		contactType?: string;
		labelId?: string;
		accountManagerId?: string;
	};
}

export default function BulkActions({ contactQueryData, filters, includesDeleted }: BulkActionsProps) {
	const [selectedAll, setSelectedAll] = useRecoilState(selectedAllArchivedContactsState);
	const [selectedContacts, setSelectedContacts] = useRecoilState(selectedArchivedContactsState);

	return (
		<section className="contact-overview_archived-bulk_actions">
			<Space>
				<ContactLabels filters={filters} includesDeleted={includesDeleted} />
				<AccountManagers filters={filters} includesDeleted={includesDeleted} />
				<Divider type="vertical" className="cts-bulk-actions__divider" />
				<Download filters={filters} includesDeleted={includesDeleted} />
				<Divider type="vertical" className="cts-bulk-actions__divider" />
				<Unarchive filters={filters} contacts={contactQueryData} includesDeleted={includesDeleted} />
				{includesDeleted && <Restore filters={filters} contacts={contactQueryData} />}
				<Divider type="vertical" className="cts-bulk-actions__divider" />
				<Delete filters={filters} contacts={contactQueryData} includesDeleted={includesDeleted} />
			</Space>
			<div className="cts-bulk-actions__selection-description">
				<p>
					<FormattedMessage
						id="CONTACT.OVERVIEW.BULK_ACTION.BUTTON.SELECTION_TOTAL"
						defaultMessage="Currently there {selection, plural, =0 {are no contacts} one {is # contact} other {are # contacts}} selected."
						values={{ selection: selectedAll ? contactQueryData?.total : selectedContacts.length }}
					/>
				</p>
				{selectedContacts.length !== contactQueryData?.total && (
					<Button
						onClick={() => {
							if (selectedAll) {
								setSelectedAll(false);
								setSelectedContacts([]);
								return;
							}

							setSelectedAll(true);
							setSelectedContacts([]);
						}}
						type="link"
					>
						{" "}
						<FormattedMessage
							id={
								selectedAll
									? "CONTACT.OVERVIEW.BULK_ACTION.BUTTON.DESELECT_ALL"
									: "CONTACT.OVERVIEW.BULK_ACTION.BUTTON.SELECT_ALL"
							}
							defaultMessage={
								selectedAll
									? "Undo selection"
									: "Select {total, plural, one {# contact} other {all # contacts}}"
							}
							values={{ total: contactQueryData?.total || 0 }}
						/>
					</Button>
				)}
			</div>
		</section>
	);
}
