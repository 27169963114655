import { CheckOutlined, WarningOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useIntl } from "react-intl";

import Icon from "@/shared/components/icon";

import style from "./style.module.scss";

export default function StatusSuffix({ tooltip, isLoading, isSuccessful }) {
	const intl = useIntl();
	const tooltipMessage = tooltip && intl.formatMessage({ id: isSuccessful ? tooltip.success : tooltip.failure });

	const SuffixIcon = () => {
		if (isLoading) {
			return <Icon type="loader" className="white small" />;
		}

		return isSuccessful ? <CheckOutlined /> : <WarningOutlined />;
	};

	// eslint-disable-next-line eqeqeq
	if (isSuccessful == undefined && !isLoading) {
		return null;
	}

	return (
		<Tooltip placement="topRight" title={tooltipMessage}>
			<div data-success={isSuccessful} data-loading={isLoading} className={style.status_suffix}>
				{SuffixIcon()}
			</div>
		</Tooltip>
	);
}
