import { defineMessages } from "react-intl";

export const searchableFolders = defineMessages({
	INBOX: {
		id: "inbox.search.advanced.search_within.options.personal_conversations",
		defaultMessage: "My conversations",
	},
	ASSIGNED_TO_ME: {
		id: "inbox.search.advanced.search_within.options.assigned_to_me",
		defaultMessage: "Assigned to me",
	},
	MENTIONED: {
		id: "inbox.search.advanced.search_within.options.mentioned",
		defaultMessage: "mentions",
	},
	SHARED: {
		id: "inbox.search.advanced.search_within.options.shared_with_me",
		defaultMessage: "Shared with me",
	},
	DRAFT: {
		id: "inbox.search.advanced.search_within.options.drafts",
		defaultMessage: "Drafts",
	},
	DELEGATED: {
		id: "inbox.search.advanced.search_within.options.assigned_by_me",
		defaultMessage: "Assigned by me",
	},
	SENT: {
		id: "inbox.search.advanced.search_within.options.sent",
		defaultMessage: "Sent",
	},
	OPEN: {
		id: "inbox.search.advanced.search_within.options.open",
		defaultMessage: "Open",
	},
	CLOSED: {
		id: "inbox.search.advanced.search_within.options.closed",
		defaultMessage: "Closed",
	},
	SNOOZED: {
		id: "inbox.search.advanced.search_within.options.snoozed",
		defaultMessage: "Snoozed",
	},
	TRASH: {
		id: "inbox.search.advanced.search_within.options.trash",
		defaultMessage: "Trash",
	},
	SPAM: {
		id: "inbox.search.advanced.search_within.options.spam",
		defaultMessage: "Spam",
	},
});
