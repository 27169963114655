import { useState } from "react";
import { notification, Tabs, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import MonacoEditor from "@uiw/react-monacoeditor";
import axios from "axios";
import type { IFile } from "@bothive_core/database";
import { useSelector } from "react-redux";

import { ShadowView } from "@/shared/components/shadowDom";
import HtmlEditor from "@/shared/components/editor/htmlEditor";
import "./style.scss";
import { TOnImageUpload } from "@/shared/components/editor/nodes";

interface IContentEditor {
	value?: string;
	onChange?: (value: string) => void;
}
export default function ContentEditor({ value, onChange }: IContentEditor) {
	const intl = useIntl();
	const { userId, organizationId } = useSelector((state) => ({
		userId: state.profile.account.user?._id,
		organizationId: state.content?.team?.team?._id,
	}));

	const handleInlineImageUpload: TOnImageUpload = async ({ data, name }) => {
		try {
			const res: Response = await fetch(data);
			const blob: Blob = await res.blob();

			const file = new File([blob], name, { type: "image/png" });

			const body = new FormData();

			body.append("file", file);
			body.append("userId", userId);

			if (file.size > 10_000_000) {
				//10MB
				notification.error({
					message: intl.formatMessage({
						id: "file_upload.upload_error.file_size_error",
						defaultMessage: "Cannot upload attachments bigger than 10MB.",
					}),
					placement: "bottomRight",
				});
				return { error: true };
			}

			const uploadResult = await axios.post<{ data: IFile }>(
				`${process.env.REACT_APP_FILE_SERVER_HOST}/v1/file`,
				body,
				{ headers: { organizationId } }
			);

			if (uploadResult.status !== 201) throw new Error(`File not created: ${file.name}`);

			return { file: uploadResult.data.data as IFile };
		} catch (_) {
			return { error: true };
		}
	};
	const handleInlineImageDelete = (fileId: string) => {
		axios.delete(`${process.env.REACT_APP_FILE_SERVER_HOST}/v1/file/${fileId}`).finally();
	};

	return (
		<Tabs
			size="small"
			tabPosition="bottom"
			className="signatures_modal-content_editor"
			items={[
				{
					key: "wysiwyg",
					label: intl.formatMessage({
						id: "settings.email_signature.modal.form.content_editor.tab.wysiwyg",
						defaultMessage: "Rich text",
					}),
					children: (
						<HtmlEditor
							value={value || ""}
							onImageUpload={handleInlineImageUpload}
							onImageDelete={handleInlineImageDelete}
							className="content_editor-editor"
							onChange={onChange}
						/>
					),
				},
				// {
				// 	key: "html",
				// 	label: intl.formatMessage({
				// 		id: "settings.email_signature.modal.form.content_editor.tab.html",
				// 		defaultMessage: "HTML",
				// 	}),
				// 	children: (
				// 		<div className="content_editor-code_editor-wrapper">
				// 			<MonacoEditor
				// 				value={value || ""}
				// 				language="html"
				// 				options={{
				// 					folding: true,
				// 					automaticLayout: true,
				// 					minimap: { enabled: false },
				// 					lineNumbers: "on",
				// 					fontFamily: "Open Sans",
				// 					fontSize: 14,
				// 				}}
				// 				width="100%"
				// 				height="20vh"
				// 				onChange={onChange}
				// 				className="content_editor-code"
				// 			/>
				// 			<Typography.Text>
				// 				<FormattedMessage
				// 					id="settings.email_signature.modal.form.content_editor.preview"
				// 					defaultMessage="Preview"
				// 				/>
				// 			</Typography.Text>
				// 			{/* <ShadowView className="content_editor-preview">
				// 				{value}
				// 			</ShadowView> */}
				// 		</div>
				// 	),
				// },
			]}
		></Tabs>
	);
}
