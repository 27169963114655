import type { IFile } from "@bothive_core/database";
import type {
	DOMConversionMap,
	DOMConversionOutput,
	DOMExportOutput,
	EditorConfig,
	LexicalNode,
	NodeKey,
	SerializedLexicalNode,
	Spread,
} from "lexical";

import { $applyNodeReplacement, DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

// @ts-ignore
const ImageComponent = React.lazy(() => import("./imageComponent"));

export type TOnImageUpload = (params: { data: string; name: string }) => Promise<{ file?: IFile; error?: boolean }>;

export interface ImagePayload {
	src: string;
	id?: string;
	raw?: string;
	key?: NodeKey;
	altText: string;
	height?: number | string;
	width?: number | string;
	maxWidth?: number;
	className?: string;
	style?: CSSStyleDeclaration;
	onImageUpload?: TOnImageUpload;
}

function convertImageElement(domNode: Node): null | DOMConversionOutput {
	if (domNode instanceof HTMLImageElement) {
		const { alt: altText, src, width, height } = domNode;
		const id = domNode.getAttribute("data-id") || undefined;
		const node = $createImageNode({ id, altText, height, src, width });

		return { node };
	}

	return null;
}

export type SerializedImageNode = Spread<Omit<ImagePayload, "key">, SerializedLexicalNode>;

export class ImageNode extends DecoratorNode<JSX.Element> {
	__src: string;
	__id?: string;
	__raw?: string;
	__altText: string;
	__maxWidth?: number;
	__isLoading: boolean = false;
	__onImageUpload?: TOnImageUpload;
	__width: "inherit" | number | string;
	__height: "inherit" | number | string;
	__style?: CSSStyleDeclaration;
	__className?: string;

	constructor(params: ImagePayload) {
		super(params.key);
		this.__id = params.id;
		this.__src = params.src;
		this.__style = params.style;
		this.__altText = params.altText;
		this.__maxWidth = params.maxWidth;
		this.__className = params.className;
		this.__onImageUpload = params.onImageUpload;
		this.__width = params.width || this.__style?.getPropertyValue("width") || "inherit";
		this.__height = params.height || this.__style?.getPropertyValue("height") || "inherit";

		if (params.raw) this.__raw = params.raw;
	}

	static getType(): string {
		return "image";
	}

	static clone(node: ImageNode): ImageNode {
		return new ImageNode({
			id: node.__id,
			src: node.__src,
			key: node.__key,
			raw: node.__raw,
			style: node.__style,
			width: node.__width,
			height: node.__height,
			altText: node.__altText,
			maxWidth: node.__maxWidth,
			className: node.__className,
		});
	}

	static importJSON(serializedNode: SerializedImageNode): ImageNode {
		return $createImageNode(serializedNode);
	}

	exportDOM(): DOMExportOutput {
		const element = document.createElement("img");

		element.setAttribute("src", this.__src);
		element.setAttribute("alt", this.__altText);
		element.setAttribute("width", this.__width.toString());
		element.setAttribute("height", this.__height.toString());
		this.__id && element.setAttribute("data-id", this.__id);

		return { element };
	}

	static importDOM(): DOMConversionMap | null {
		return {
			img: (node: Node) => ({
				conversion: convertImageElement,
				priority: 0,
			}),
		};
	}

	exportJSON(): SerializedImageNode {
		return {
			version: 1,
			type: "image",
			id: this.getId(),
			src: this.getSrc(),
			style: this.__style,
			maxWidth: this.__maxWidth,
			altText: this.getAltText(),
			className: this.__className,
			width: this.__width === "inherit" ? 0 : this.__width,
			height: this.__height === "inherit" ? 0 : this.__height,
		};
	}

	setWidthAndHeight(width: "inherit" | number, height: "inherit" | number): void {
		const writable = this.getWritable();

		writable.__width = width;
		writable.__height = height;
	}

	setId(id: string): void {
		const writable = this.getWritable();

		writable.__id = id;
	}
	setSrc(src: string): void {
		const writable = this.getWritable();

		writable.__src = src;
	}
	setIsLoading(isLoading: boolean): void {
		const writable = this.getWritable();

		writable.__isLoading = isLoading;
	}

	// View
	createDOM(config: EditorConfig): HTMLElement {
		const span = document.createElement("span");
		const theme = config.theme;
		const className = theme.image;

		if (className !== undefined) {
			span.className = className;
		}

		return span;
	}

	updateDOM(): false {
		return false;
	}

	remove() {
		// prevent deleting when image is still uploading
		if (this.__isLoading) return;

		super.remove();
	}
	forceRemove() {
		// Used to delete when upload failed
		super.remove();
	}

	getId(): string | undefined {
		return this.__id;
	}
	getSrc(): string {
		return this.__src;
	}
	getRawData(): string | undefined {
		return this.__raw;
	}

	getAltText(): string {
		return this.__altText;
	}

	decorate(): JSX.Element {
		return (
			<Suspense fallback={null}>
				<ImageComponent
					id={this.__id}
					src={this.__src}
					width={this.__width}
					height={this.__height}
					nodeKey={this.getKey()}
					altText={this.__altText}
					maxWidth={this.__maxWidth}
					className={this.__className || ""}
					onImageUpload={this.__onImageUpload}
					style={this.__style}
					resizable={true}
				/>
			</Suspense>
		);
	}
}

export function $createImageNode({
	altText,
	height,
	src,
	id,
	width,
	key,
	className,
	style,
	onImageUpload,
	maxWidth = 700,
}: ImagePayload): ImageNode {
	return $applyNodeReplacement(
		new ImageNode({
			id,
			src,
			raw: src,
			altText,
			maxWidth,
			width,
			height,
			key,
			className,
			onImageUpload,
			style,
		})
	);
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
	return node instanceof ImageNode;
}
