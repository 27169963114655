import "./style.scss";

import { Button, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilValue } from "recoil";

import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import { queryStringHelpers } from "@/shared/helpers";
import { trpc } from "../../../trpc";
import { userState } from "../state";
import CodeInput from "./input";
import { useEffect } from "react";

export default function VerifyUser() {
	const intl = useIntl();
	const history = useHistory();
	const user = useRecoilValue(userState);
	const verifyUser = trpc.user.verifyUser.useMutation();
	const queryString = queryStringHelpers.formatQueryString(location.search);
	const requestNewVerificationCode = trpc.user.requestNewVerificationCode.useMutation();

	if (!user) {
		// Through login they can verify again if they need to
		history.push("/portal/login");
		return null;
	}

	if (user.verified) {
		history.push("/profile");
		return null;
	}

	// Prevent reload
	useEffect(() => {
		const handleBeforeUnload = (event) => {
			event.preventDefault();
			event.returnValue = null;

			return null;
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	const handleVerify = (token: string) => {
		if (token.length < 6) return;

		const body = { token, userId: user?._id };

		verifyUser.mutate(body, {
			onSuccess: () => {
				if (queryString?.organization_id) return history.push(`/portal/invite${location.search}`);
				history.push("/portal/sign_up/completed");
			},
			onError: () =>
				ShowFailedBanner({
					title: intl.formatMessage({
						id: "portal.verify_user.failed.not_found",
						defaultMessage: "Could not verify your account. Your token could be to expired.",
					}),
					intl,
				}),
		});
	};
	const handleResendVerificationCode = () => {
		requestNewVerificationCode.mutate(
			{ userId: user?._id },
			{
				onSuccess: () =>
					ShowSuccessBanner({
						title: intl.formatMessage(
							{
								id: "portal.verify_user.request_new_code.success",
								defaultMessage: "Verification code has been send to {email}",
							},
							{ email: user?.email }
						),
						intl,
					}),
				onError: () =>
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "portal.verify_user.request_new_code.failed",
							defaultMessage: "Could not request a new verification code. Please try again later.",
						}),
						intl,
					}),
			}
		);
	};

	return (
		<section className="portal_body">
			<article className="portal_body-article portal_verify">
				<header className="portal_body-header">
					<Typography.Title>
						<FormattedMessage id="portal.verify_user.title" defaultMessage="Verify your email" />
					</Typography.Title>
					<p>
						<FormattedMessage
							id="portal.verify_user.description"
							defaultMessage="We send a email to <bold>{email}</bold> with a code that is <bold>30 minutes</bold> valid to verify your account."
							values={{
								email: user?.email,
								bold: (content) => <Typography.Text strong>{content}</Typography.Text>,
							}}
						/>
					</p>
				</header>
				<CodeInput onChange={handleVerify} />
				<Button
					size="large"
					type="text"
					loading={requestNewVerificationCode.isLoading}
					onClick={handleResendVerificationCode}
				>
					<FormattedMessage id="portal.verify_user.resend_code" defaultMessage="Resend verification code" />
				</Button>
			</article>
		</section>
	);
}
