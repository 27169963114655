import "./style.scss";

import { Alert, Avatar, Button, Skeleton, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { queryStringHelpers } from "@/shared/helpers";
import ShowFailedBanner from "@/shared/components/banner/failed";
import { trpc } from "../../../trpc";

type IInviteLoadingState = "none" | "decline" | "accept";

export default function AcceptInvite() {
	const intl = useIntl();
	const history = useHistory();
	const location = useLocation();
	const [loadingState, setLoadingState] = useState<IInviteLoadingState>("none");
	const queryString: Record<string, string> = queryStringHelpers.formatQueryString(location.search);
	const { data: user, isError: isGetUserFailed, isLoading: isFetchingUser } = trpc.user.getUser.useQuery();
	const acceptMembership = trpc.member.acceptMembership.useMutation();
	const declineMembership = trpc.member.declineMembership.useMutation();
	const organizationId = queryString?.organization_id || queryString?.organizationId;
	const {
		isLoading,
		data: organization,
		isError,
	} = trpc.organization.getOrganizationById.useQuery({ organizationId }, { enabled: Boolean(organizationId) });

	const handleDeclineInvite = async () => {
		try {
			setLoadingState("decline");
			await declineMembership.mutateAsync({ membershipId: queryString?.member_id });

			history.push("/portal/login");
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "portal.accept_invite.decline_failed",
					defaultMessage: "Something went wrong. Please try again later",
				}),
				intl,
			});
		}

		setLoadingState("none");
	};
	const handleAcceptInvite = async () => {
		try {
			setLoadingState("accept");
			if (!organization) return;

			await acceptMembership.mutateAsync({ membershipId: queryString?.member_id });

			history.push(`/${organization.slug}`);
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "portal.accept_invite.accept_failed",
					defaultMessage: "You cannot accept the invite at the moment. Please try again later",
					description:
						"This can happen when user already accepted the invite, the invite has been deleted or the servers are down",
				}),
				intl,
			});
		}

		setLoadingState("none");
	};

	useEffect(() => {
		if (isFetchingUser) return;
		if (user?.initialized && !isGetUserFailed && queryString) return;

		history.push(`/portal/login${location.search}`);
	}, [user, isGetUserFailed, isFetchingUser]);

	return (
		<section className="portal_body">
			<article className="portal_body-article">
				<header className="portal_body-header">
					<Typography.Title>
						<FormattedMessage id="portal.accept_invite.title" defaultMessage="Accept your invite" />
					</Typography.Title>
					<p>
						<FormattedMessage
							id="portal.accept_invite.description"
							defaultMessage="You have been invited to a <bold>{organizationName}</bold>. Accept this invite if you want to join <bold>{organizationName}</bold>."
							values={{
								organizationName: organization?.name || "...",
								bold: (content) => <Typography.Text strong>{content}</Typography.Text>,
							}}
						/>
					</p>
				</header>
				{((!isLoading && !organization) || isError) && (
					<Alert
						message={intl.formatMessage({
							id: "portal.accept_invite.not_found.title",
							defaultMessage: "Could not find any invitations.",
						})}
						description={intl.formatMessage({
							id: "portal.accept_invite.not_found.description",
							defaultMessage:
								"We could not find any invitations for you, have you already accepted your invites or are you using a expired link?",
						})}
						className="portal_accept_invite-error"
						type="error"
					/>
				)}
				{(isLoading || organization) && !isError && (
					<div className="portal_accept_invite-card t-gap--top-lg">
						<Skeleton loading={isLoading} active avatar={{ shape: "square", size: 96 }}>
							<Avatar
								shape="square"
								className="portal_accept_invite-card-avatar"
								src={organization?.branding?.icon}
							/>
							<div className="portal_accept_invite-card-content">
								<h2 className="portal_accept_invite-card-content-title">{organization?.name}</h2>
								<footer className="portal_accept_invite-card-footer">
									<Button
										type="text"
										onClick={handleDeclineInvite}
										loading={loadingState === "decline"}
										disabled={loadingState !== "none"}
									>
										<FormattedMessage
											id="portal.accept_invite.decline_invite"
											defaultMessage="Decline"
										/>
									</Button>
									<Button
										type="primary"
										onClick={handleAcceptInvite}
										loading={loadingState === "accept"}
										disabled={loadingState !== "none"}
									>
										<FormattedMessage
											id="portal.accept_invite.accept_invite"
											defaultMessage="Accept invite"
										/>
									</Button>
								</footer>
							</div>
						</Skeleton>
					</div>
				)}
			</article>
		</section>
	);
}
