import "./style.scss";

import { Button, Form, Modal, Select, notification } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { FormattedMessage, useIntl } from "react-intl";

import { flowState } from "@/flows/common/state";
import { isReportUploadOpenState } from "./state";
import FileUpload from "../../../../shared/components/upload/fileUpload";
import { getFieldLabels, uploadToBucket } from "./utils";
import { ReportRouterOutput, trpc } from "../../../../trpc";

type ReportDetailUploadFilesForm = {
	label: string;
	files: File[];
};

export default function UploadFiles({ report }: { report?: ReportRouterOutput["getById"] }) {
	const intl = useIntl();
	const [isSaving, setIsSaving] = useState(false);
	const { flowId } = useParams<{ flowId: string }>();
	const [form] = Form.useForm<ReportDetailUploadFilesForm>();
	const flow = useRecoilValueLoadable(flowState({ id: flowId }));
	const language = useSelector((state) => state.dashboard.header.lang);
	const [isReportUploadOpen, setIsReportUploadOpen] = useRecoilState(isReportUploadOpenState);

	const trpcContext = trpc.useUtils();
	const getSignedUrl = trpc.files.getSignedUrl.useMutation();
	const saveUploadedFile = trpc.files.saveReportFile.useMutation();

	// Reset modal on open change
	useEffect(() => form.resetFields(), [isReportUploadOpen]);

	const fileLabels = useMemo(() => {
		if (!flow?.contents?.template?.fields?.length) return [];

		return getFieldLabels(flow?.contents?.template?.fields);
	}, [flow?.contents?.template?.fields]);

	const handleSubmit = async (value: ReportDetailUploadFilesForm) => {
		try {
			if (!report) return;

			setIsSaving(true);
			await Promise.all(
				value.files.map(async (file) => {
					const signedUrl = await getSignedUrl.mutateAsync({
						contentType: file.type,
						reportId: report._id,
						fileName: file.name,
					});

					if (!signedUrl) throw new Error("Upload failed");

					await uploadToBucket({ url: signedUrl, file, onProgress: () => {} });

					await saveUploadedFile.mutateAsync({
						organizationId: report.organizationId,
						reportId: report._id,
						fileName: file.name,
						mimeType: file.type,
						label: value.label,
						size: file.size,
					});
				})
			);
			trpcContext.report.getById.reset();
			setIsReportUploadOpen(false);
		} catch (_) {
			notification.error({
				message: intl.formatMessage({
					id: "flows.report_detail.files.upload_modal.failed",
					defaultMessage: "We temporarily cannot upload this file, try again later",
				}),
				placement: "bottomRight",
				duration: 8,
			});
		}

		setIsSaving(false);
	};

	return (
		<Modal
			mask={true}
			footer={null}
			destroyOnClose
			maskClosable={true}
			open={isReportUploadOpen}
			title={intl.formatMessage({
				id: "flows.report_detail.files.upload_modal.title",
				defaultMessage: "Upload files",
			})}
			onCancel={() => setIsReportUploadOpen(false)}
			className="report_detail-meta-upload_files"
		>
			<Form
				form={form}
				layout="vertical"
				disabled={isSaving}
				requiredMark={false}
				onFinish={handleSubmit}
				initialValues={{ label: fileLabels[0]?.key }}
			>
				<Form.Item
					name="label"
					label={intl.formatMessage({
						id: "flows.report_detail.files.upload_modal.label.label",
						defaultMessage: "File label",
					})}
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "flows.report_detail.files.upload_modal.label.required_error",
								defaultMessage: "Select which file you want to upload",
							}),
						},
					]}
				>
					<Select
						showSearch
						notFoundContent={null}
						popupMatchSelectWidth={false}
						filterOption={(input, option) =>
							(`${option?.label}` ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={fileLabels.map((label) => ({ label: label.name[language], value: label.key }))}
					/>
				</Form.Item>
				<Form.Item
					name="files"
					label={intl.formatMessage({
						id: "flows.report_detail.files.upload_modal.file.label",
						defaultMessage: "Files",
					})}
					rules={[
						{
							required: true,
							validator: (_, value) => {
								if (!value?.length) {
									const message = intl.formatMessage({
										id: "flows.report_detail.files.upload_modal.label.required_error",
										defaultMessage: "Select which file you want to upload",
									});

									return Promise.reject(new Error(message));
								}

								return Promise.resolve();
							},
						},
					]}
				>
					<FileUpload disabled={isSaving} />
				</Form.Item>
				<footer className="report_detail-meta-upload_files-footer">
					<Button type="text" onClick={() => setIsReportUploadOpen(false)}>
						<FormattedMessage
							id="flows.report_detail.files.upload_modal.footer.cancel"
							defaultMessage="Cancel"
						/>
					</Button>
					<Form.Item shouldUpdate className="report_detail-meta-upload_files-footer-submit">
						{() => {
							const isDisabled =
								!form.isFieldsTouched(true) ||
								!form.getFieldsError().filter(({ errors }) => errors.length).length;

							return (
								<Button type="primary" htmlType="submit" loading={isSaving} disabled={!isDisabled}>
									<FormattedMessage
										id="flows.report_detail.files.upload_modal.footer.submit"
										defaultMessage="Save file"
									/>
								</Button>
							);
						}}
					</Form.Item>
				</footer>
			</Form>
		</Modal>
	);
}
