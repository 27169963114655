import { defineMessages } from "react-intl";

export const importErrorMessages = defineMessages({
	noIdentifier: {
		id: "contact.import.analytics.error.no_identifier",
		defaultMessage:
			"Import contains no contacts with a valid identifier (email, phone, national registration number or unique identifier).",
	},
	invalidEmail: {
		id: "contact.import.analytics.error.invalid_email",
		defaultMessage: "Import contains one or more invalid email addresses.",
	},
	invalidPhone: {
		id: "contact.import.analytics.error.invalid_phone",
		defaultMessage: "Import contains one or more invalid phone number.",
	},
	invalidNationalRegistrationNumber: {
		id: "contact.import.analytics.error.invalid_nation_registration_number",
		defaultMessage: "Import contains one or more invalid national registration number.",
	},
	missingName: {
		id: "contact.import.analytics.error.missing_name",
		defaultMessage: "Import contains one or more contacts without a name.",
	},
	noLinkedContactFound: {
		id: "contact.import.analytics.error.no_linked_contact_found",
		defaultMessage: "Import contains no contacts that match with existing contacts.",
	},
	missingLinkedContacts: {
		id: "contact.import.analytics.error.missing_linked_contacts",
		defaultMessage: "Import contains some contacts that don't match with a existing contact.",
	},
	invalidLinkedContactIdentifier: {
		id: "contact.import.analytics.error.invalid_linked_contact_identifier",
		defaultMessage:
			"Import contains one or more contacts where the existing contact identifier (email, phone, national registration number) is invalid.",
	},
});
export const importWarningMessages = defineMessages({
	duplicateId: {
		id: "contact.import.analytics.warning.duplicate_id",
		defaultMessage: "Import contains two or more contacts with the same ids.",
	},
	duplicateEmail: {
		id: "contact.import.analytics.warning.duplicate_email",
		defaultMessage: "Import contains two or more contacts with the same email addresses.",
	},
	duplicatePhone: {
		id: "contact.import.analytics.warning.duplicate_phone",
		defaultMessage: "Import contains two or more contacts with the same phone number.",
	},
	duplicateNationalRegistrationNumber: {
		id: "contact.import.analytics.warning.duplicate_nation_registration_number",
		defaultMessage: "Import contains two or more contacts with the same national registration number.",
	},
	unknownAccountManagers: {
		id: "contact.import.analytics.warning.unknown_account_manager",
		defaultMessage:
			"Import contains one or more account manager emails that are not added to a member. Add these members first or they will be skipped in the import",
	},
});
