import { Alert, Button, Form, Modal, Select, Switch } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";

import { flowState, selectedFlowIdState } from "@/flows";
import { initReportBulkDownload } from "../../query";
import {
	allReportsSelectedState,
	isBulkDownloadingState,
	selectedReportIdsState,
	totalReportsSelectedState,
} from "@/flows/flow_detail/reports/bulk_actions/state";
import { searchFilterState, selectedContactLabelState, selectedProgressState, selectedStatusTypeState } from "@/flows/flow_detail/reports/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import ShowSuccessBanner from "@/shared/components/banner/success";
import FieldSideLabel from "@/shared/components/field/side_label";

import style from "../../style.module.scss";
import helpers from "./helpers";
import { useMemo } from "react";

export default function DownloadReportsModal({ showDownloadReportModal, setShowDownloadReportModal }) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const query = useRecoilValue(searchFilterState);
	const flowId = useRecoilValue(selectedFlowIdState);
	const progress = useRecoilValue(selectedProgressState);
	const statusType = useRecoilValue(selectedStatusTypeState);
	const flow = useRecoilValueLoadable(flowState({ id: flowId }));
	const selectedReportIds = useRecoilValue(selectedReportIdsState);
	const allReportsSelected = useRecoilValue(allReportsSelectedState);
	const totalReportsSelected = useRecoilValue(totalReportsSelectedState);
	const selectedContactLabel = useRecoilValue(selectedContactLabelState);
	const [isBulkDownloading, setIsBulkDownloading] = useRecoilState(isBulkDownloadingState);

	const defaultReportType = useMemo(
		() => helpers.selectDefaultReportType({ previewTypes: flow.contents?.template?.previewTypes }),
		[flow]
	);

	const handleConfirm = async (formValues) => {
		setIsBulkDownloading(true);

		try {
			const result = await initReportBulkDownload({
				flowId,
				downloadAttachments: formValues.withAttachments,
				reportType: formValues.reportType || defaultReportType,
				reportIds: allReportsSelected ? undefined : selectedReportIds,
				q: query,
				progress,
				statusId: statusType,
				contactLabel: selectedContactLabel,
			});

			if (result.status !== 202) throw new Error();

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.SUCCESS.TITLE",
					defaultMessage: "The report download has started.",
				}),
				description: intl.formatMessage({
					id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.SUCCESS.DESCRIPTION",
					defaultMessage:
						"You will receive a notification and email containing the download link the files have been processed.",
				}),
				duration: 6,
			});
		} catch (error) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.FAILED.TITLE",
					defaultMessage: "Bulk download error",
				}),
				description: intl.formatMessage({
					id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.BANNER.DOWNLOAD_REPORTS.FAILED.DESCRIPTION",
					defaultMessage: "The bulk download action failed to start.",
				}),
			});
		}

		setShowDownloadReportModal(false);
		setIsBulkDownloading(false);
	};

	return (
		<Modal
			centered
			title={intl.formatMessage({ id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.TITLE" })}
			open={showDownloadReportModal}
			onCancel={() => setShowDownloadReportModal(false)}
			mask={true}
			maskClosable={true}
			footer={null}
		>
			<Form
				form={form}
				layout="vertical"
				requiredMark={false}
				onFinish={handleConfirm}
				initialValues={{ reportType: defaultReportType, withAttachments: true }}
			>
				<FieldSideLabel
					label={intl.formatMessage({
						id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.REPORT_TYPE.LABEL",
					})}
					help={intl.formatMessage({
						id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.REPORT_TYPE.HELP",
					})}
				>
					<Form.Item name="reportType">
						<Select popupMatchSelectWidth={false}>
							{flow.contents?.template?.previewTypes?.map(({ type }) => (
								<Select.Option key={type} value={type}>
									<p>{type}</p>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</FieldSideLabel>
				<FieldSideLabel
					label={intl.formatMessage({
						id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.INCLUDE_ATTACHMENTS.LABEL",
					})}
					help={intl.formatMessage({
						id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.INCLUDE_ATTACHMENTS.HELP",
					})}
				>
					<Form.Item name="withAttachments" valuePropName="checked">
						<Switch />
					</Form.Item>
				</FieldSideLabel>

				<Form.Item className="t-gap--top">
					<Alert
						message={intl.formatMessage(
							{
								id: "FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.INFO_MESSAGE",
							},
							{
								totalReportsSelected,
								reportType: Form.useWatch("reportType", form),
								withAttachments: Number(Form.useWatch("withAttachments", form)),
							}
						)}
						type="info"
						showIcon
					/>
				</Form.Item>

				<Form.Item className={style.form_item_no_margin}>
					<div className={style.form_footer_wrapper}>
						<Button type="primary" htmlType="submit" loading={isBulkDownloading}>
							{" "}
							<FormattedMessage id="FLOWS.REPORT.OVERVIEW.BULK_ACTION.MODAL.DOWNLOAD_REPORTS.CONFIRM" />
						</Button>
					</div>
				</Form.Item>
			</Form>
		</Modal>
	);
}
