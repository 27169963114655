function deleteValueValidator({ value, intl }) {
	if (
		value ===
		intl.formatMessage({ id: "CONTACT.OVERVIEW.BULK_ACTION.ACTION.DELETE", defaultMessage: "DELETE" }).toUpperCase()
	) {
		return Promise.resolve();
	}

	const errorMessage = intl.formatMessage({ id: "FORM_ELEMENT.ERROR.VALUE_DELETE" });

	return Promise.reject(new Error(errorMessage));
}

export default { deleteValueValidator };
