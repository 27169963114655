import DashboardCard from "@/shared/components/card/dashboard";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { routesConfig } from "@/config/index.js";
import { Button } from "antd";
import "../style.scss";
import "../../style.scss";

export default function RelatedSettings({ slug }: { slug: string | undefined }) {
	const history = useHistory();
	const redirect = () => {
		history.push(routesConfig.dashboard.settings.organization.openingHours.replace(":team", slug));
	};

	return (
		<div className="settings_page-suggestions t-gap--top">
			<DashboardCard
				className="settings_page-suggestions-options"
				title="ORGANIZATION_SETTINGS.OPENING_HOURS.TITLE"
			>
				<div className="cardContent">
					<p>
						<FormattedMessage
							id="SETTINGS.SUGGESTIONS.OPENING_HOURS.DESCRIPTION"
							defaultMessage="Define your opening hours so you can help your customers even better"
						/>
					</p>
					<Button type="default" onClick={() => redirect()}>
						<FormattedMessage
							id="SETTINGS.SUGGESTIONS.OPENING_HOURS.BUTTON"
							defaultMessage="Manage your Opening hours"
						/>
					</Button>
				</div>
			</DashboardCard>
		</div>
	);
}
