import React from "react";

import { logo } from "../../../assets/images";

/**
 * @deprecated Use LogoLoader at shared instead
 */
export default function ALoader({ className }) {
	return (
		<div className={`a-component--main-loader ${className}`}>
			<div className="icon-loader loader-animation" />

			<img src={logo.logoSmall} alt="" className="loader-logo" />
		</div>
	);
}
