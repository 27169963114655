import React from "react";
import { FormattedMessage } from "react-intl";

export default function MEmpty({ title, description, values, img, className, children }) {
	return (
		<section className={`m-fallback ${className}`}>
			<div className="fallback--content">
				<article>
					{title && (
						<h1 className="title fallback--title">
							<FormattedMessage id={title} values={values} />
						</h1>
					)}
					{description && (
						<p>
							<FormattedMessage id={description} values={values} />
						</p>
					)}
				</article>
				{img && <img src={img} alt="" className="fallback--image" />}
				<div>{children}</div>
			</div>
		</section>
	);
}

MEmpty.defaultProps = {
	title: undefined,
	description: undefined,
	values: undefined,
	img: undefined,
	className: "",
};
