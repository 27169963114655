import "./style.scss";

import React from "react";

import WidgetPreview from "./preview";
import WidgetSettings from "./settings";

export default function WidgetCard({ isLoading, isDisabled }) {
	return (
		<section className="frsw-widget-section">
			<WidgetSettings isLoading={isLoading} isDisabled={isDisabled} />
			<WidgetPreview />
		</section>
	);
}
