import type { Spread } from "lexical";

import {
	DOMConversionMap,
	DOMConversionOutput,
	DOMExportOutput,
	EditorConfig,
	LexicalNode,
	NodeKey,
	SerializedTextNode,
	TextNode,
} from "lexical";

export type SerializedMentionNode = Spread<
	{
		id: string;
		name: string;
		type: "mention";
		version: 1;
	},
	SerializedTextNode
>;

function convertMentionElement(domNode: HTMLElement): DOMConversionOutput | null {
	const textContent = domNode.textContent;

	if (textContent === null) return null;

	const node = $createMentionNode({ id: textContent, name: textContent }); // TODO

	return { node };
}

export class MentionNode extends TextNode {
	__mentionId: string;
	__mentionName: string;

	static getType(): string {
		return "mention";
	}

	static clone(node: MentionNode): MentionNode {
		return new MentionNode({
			id: node.getMentionId(),
			name: node.getMentionName(),
			text: node.__text,
			key: node.__key,
		});
	}
	static importJSON(serializedNode: SerializedMentionNode): MentionNode {
		const node = $createMentionNode({
			id: serializedNode.id,
			name: serializedNode.name,
		});

		node.setTextContent(serializedNode.text);
		node.setFormat(serializedNode.format);
		node.setDetail(serializedNode.detail);
		node.setMode(serializedNode.mode);
		node.setStyle(serializedNode.style);

		return node;
	}

	constructor({ id, name, text, key }: { id: string; name: string; text?: string; key?: NodeKey }) {
		super(text ?? name, key);

		this.__mentionId = id;
		this.__mentionName = name;
	}

	getMentionId(): string {
		return this.__mentionId;
	}
	getMentionName(): string {
		return this.__mentionName;
	}

	exportJSON(): SerializedMentionNode {
		return {
			...super.exportJSON(),
			id: this.__mentionId,
			name: this.__mentionName,
			type: "mention",
			version: 1,
		};
	}

	createDOM(config: EditorConfig): HTMLElement {
		const dom = super.createDOM(config);

		dom.className = config.theme.mention;

		return dom;
	}

	exportDOM(): DOMExportOutput {
		const element = document.createElement("span");

		element.setAttribute("data-lexical-mention", "true");
		element.textContent = this.__text;

		return { element };
	}

	isSegmented(): false {
		return false;
	}

	static importDOM(): DOMConversionMap | null {
		return {
			span: (domNode: HTMLElement) => {
				if (!domNode.hasAttribute("data-lexical-mention")) return null;

				return {
					conversion: convertMentionElement,
					priority: 1,
				};
			},
		};
	}

	isTextEntity(): true {
		return true;
	}

	canInsertTextBefore(): boolean {
		return false;
	}

	canInsertTextAfter(): boolean {
		return false;
	}

	isToken(): true {
		return true;
	}
}

interface ICreateMentionNode {
	id: string;
	name: string;
}
export function $createMentionNode({ id, name }: ICreateMentionNode): MentionNode {
	const mentionNode = new MentionNode({ id, name });

	mentionNode.setMode("segmented").toggleDirectionless();

	return mentionNode;
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
	return node instanceof MentionNode;
}
