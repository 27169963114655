import { trpc } from "@/trpc";
import { Button, Divider, Empty, Form, Switch } from "antd";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import Group from "./components/Group";
import GroupSkeleton from "./components/GroupSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import "./style.scss";
import Loading from "./Loading";

export default function HistoryList() {
	const { flowId } = useParams<{ flowId: string }>();
	const intl = useIntl();
	const [onlyShowFailed, setOnlyShowFailed] = useState(false);

	const {
		data: historyByDay,
		isInitialLoading,
		isFetching,
		fetchNextPage,
	} = trpc.flowReminders.getFlowReminderHistoryByDayStats.useInfiniteQuery(
		{
			flowId,
			onlyFailed: onlyShowFailed,
		},
		{
			getNextPageParam: (data): Date | null => {
				if (!data || !data?.data?.length) return null;
				const latest = new Date(Math.min(...data?.data?.map((obj) => obj.lastExecuteAt.getTime())));
				const nextDay = latest;
				nextDay.setUTCDate(latest.getDate() - 1);
				nextDay.setUTCHours(23, 59, 59, 999);

				return nextDay;
			},
			keepPreviousData: true,
		}
	);

	const historyByDayFlat = useMemo(() => {
		const list = historyByDay?.pages.flatMap((page) => page.data) || [];

		if (!onlyShowFailed) return list;

		return list.filter((item) => item.failedCount);
	}, [historyByDay?.pages, onlyShowFailed]);

	const isEmpty = !isInitialLoading && historyByDayFlat.length === 0;
	const hasMore = (historyByDayFlat?.length || 0) < (historyByDay?.pages[0]?.total || 0);

	return (
		<>
			<div className="t-gap--top" style={{ padding: "0 2em" }}>
				<header style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
					<h2>
						<FormattedMessage id="FLOW.DETAIL.REMINDERS.HISTORY.TITLE" defaultMessage="Reminder history" />
					</h2>
					<Form.Item
						style={{ margin: 0 }}
						label={intl.formatMessage({
							id: "FLOW.DETAIL.REMINDERS.HISTORY.FILTERS.SHOW_FAILED.LABEL",
							defaultMessage: "Only show failed",
						})}
						name="onlyShowError"
						valuePropName="checked"
					>
						<Switch
							disabled={isEmpty && !onlyShowFailed}
							onChange={(checked) => setOnlyShowFailed(checked)}
						/>
					</Form.Item>
				</header>
				<Divider className="flow-reminder-history__header-divider" />
			</div>
			<div style={{ overflowY: "auto", height: "100%" }} id="reminderHistoryList">
				<div>
					{isEmpty && (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							className="t-gap--top-xl"
							style={{ margin: "auto" }}
							description={intl.formatMessage({
								id: "FLOW.DETAIL.REMINDERS.HISTORY.LIST.EMPTY",
								defaultMessage: "No reminder history",
							})}
						/>
					)}
					{isInitialLoading && <Loading />}
				</div>
				<InfiniteScroll
					next={fetchNextPage}
					scrollThreshold={0.6}
					dataLength={historyByDayFlat?.length || 0}
					hasMore={hasMore}
					loader={<GroupSkeleton />}
					endMessage={
						historyByDayFlat &&
						historyByDayFlat.length >= (historyByDay?.pages[0]?.total || 0) &&
						historyByDayFlat.length !== 0 && (
							<Divider className="t-gap--top" plain>
								<FormattedMessage
									id="FLOW.DETAIL.REMINDERS.HISTORY.LIST.FETCHED_ALL"
									defaultMessage="All history data is fetched"
								/>
							</Divider>
						)
					}
					scrollableTarget="reminderHistoryList"
					style={{ padding: "2em", display: "grid" }}
				>
					{!!historyByDayFlat?.length &&
						historyByDayFlat?.map((stat) => (
							<Group
								onlyShowFailed={onlyShowFailed}
								flowReminderSettingId={`${stat.settingId}`}
								key={stat._id}
								dateLabel={stat._id}
								stats={{
									total: stat?.totalCount,
									success: stat?.successCount,
									failed: stat?.failedCount,
								}}
							/>
						))}
					{hasMore && (
						<Button
							loading={isFetching}
							style={{ marginTop: "1em", justifySelf: "center" }}
							onClick={() => fetchNextPage()}
						>
							<FormattedMessage
								id="FLOW.DETAIL.REMINDERS.HISTORY.LIST.LOAD_MORE"
								defaultMessage="Load more days"
							/>
						</Button>
					)}
				</InfiniteScroll>
			</div>
		</>
	);
}
