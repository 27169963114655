import "./style.scss";

import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Empty, Table, Typography } from "antd";

import { actions as dashboardActions } from "../../../store/dashboard";
import config from "./config";
import { trpc } from "../../../trpc";
import SkeletonTable from "../../../shared/components/table/skeleton";
import Tag from "@/shared/components/antdWrappers/Tag";
import { EditOutlined } from "@ant-design/icons";
import CreateContactLabelModal from "@/settings/contact/labels/components/CreateContactLabelModal";
import UpdateContactLabelModal from "@/settings/contact/labels/components/UpdateContactLabelModal";

/**
 * TODO
 * move all components in to settings module with ant design components
 * ? should components be in each page? because they link to that other page
 */

interface ContactLabel {
	_id: string;
	name: string;
	description: string;
	color: string;
	isSystem: boolean;
}

export default function ContactLabels({ className }: { className?: string }) {
	const intl = useIntl();
	const dispatch = useDispatch();
	const routeParameters = useParams();

	const [sort, setSort] = useState<{ field: string; order: "ascend" | "descend" } | undefined>(undefined);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });

	const { data: contactLabels, isLoading } = trpc.contactLabel.all.useQuery({
		sort,
		limit: pagination.pageSize,
		offset: (pagination.current - 1) * pagination.pageSize,
	});

	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

	const [selectedLabel, setSelectedLabel] = useState<ContactLabel | undefined>(undefined);

	useEffect(() => {
		const breadcrumbs = config.header.breadcrumbs(routeParameters as { team: string });
		dispatch(dashboardActions.ui.changePageTitle({ config: { ...config.header, breadcrumbs }, intl }));
	}, []);

	const handleTableChange = ({ sorter, pagination }) => {
		if (sorter?.columnKey && sorter?.order) {
			setSort({ field: sorter.columnKey, order: sorter.order });
		}

		if (sorter?.order === undefined) {
			setSort(undefined);
		}

		setPagination({ current: pagination.current, pageSize: pagination.pageSize });
	};

	return (
		<section className={`page-settings--content page-settings-contact-labels ${className || ""}`}>
			<header className="page-settings-contact-labels--header t-gap--bottom">
				<Button type="primary" size="large" onClick={() => setShowCreateModal(true)}>
					<FormattedMessage id={config.table.addButton} />
				</Button>
			</header>
			<SkeletonTable
				rowKey="_id"
				dataSource={contactLabels?.data}
				isLoading={isLoading}
				onRow={(record: ContactLabel) => ({
					onClick: () => {
						setSelectedLabel(record);
						setShowUpdateModal(true);
					},
				})}
				pagination={{
					current: pagination.current,
					pageSize: pagination.pageSize,
					total: contactLabels?.total,
					showSizeChanger: true,
					defaultPageSize: 20,
					pageSizeOptions: ["20", "50", "100", "200"],
				}}
				onChange={(pagination: any, _: any, sorter: any) => handleTableChange({ pagination, sorter })}
				locale={{
					emptyText: (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={
								<p>
									<FormattedMessage
										id="SETTINGS.CONTACTS.LABELS.EMPTY"
										defaultMessage="No labels found"
									/>
								</p>
							}
						/>
					),
				}}
			>
				<Table.Column
					key="name"
					title={<FormattedMessage id="CONTACTS_LABEL_TABLE.NAME.TITLE" />}
					dataIndex="name"
					sorter={true}
					render={(_, record: ContactLabel) => (
						<div className="page-settings-contact-label-cell">
							<Tag
								key={record._id}
								color={record.color}
								style={{
									marginRight: 0,
									width: "fit-content",
								}}
							>
								{record.name}
							</Tag>
							<Typography.Text>{record?.description}</Typography.Text>
						</div>
					)}
				/>
				<Table.Column
					key="name"
					width="60px"
					dataIndex="name"
					render={() => (
						<Typography.Text>
							<EditOutlined />
						</Typography.Text>
					)}
				/>
			</SkeletonTable>
			<CreateContactLabelModal open={!!showCreateModal} setShowCreateModal={setShowCreateModal} />
			<UpdateContactLabelModal
				open={!!showUpdateModal}
				label={selectedLabel}
				setShowUpdateModal={setShowUpdateModal}
			/>
		</section>
	);
}
