import axios from "axios";

import { apiConfig } from "@/config";

async function fetchNotificationSettings() {
	const result = await axios.get(apiConfig.me.notification.settings.get);

	if (result.status !== 200 || !result.data.success) {
		throw new Error();
	}

	return result.data;
}

async function updateNotificationSettings(payload) {
	const result = await axios.put(apiConfig.me.notification.settings.update, payload);

	if (result.status !== 200 || !result.data.success) {
		throw new Error();
	}

	return result.data;
}

export default {
	fetchNotificationSettings,
	updateNotificationSettings,
};
