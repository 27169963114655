import "./style.scss";

import React from "react";
import { useIntl } from "react-intl";

import { Buttons, Image } from "@/components/atoms";
import { NavLink } from "react-router-dom";
import { routesConfig } from "@/config";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export default function PopupFullscreen({ children, onClose, className }) {
	const intl = useIntl();

	const team = useSelector((state) => state.content.team.team);

	return (
		<div role="dialog" aria-labelledby="dialog" className={`o-popup-fullscreen ${className}`}>
			<div className="popup-header">
				<Buttons.AIconButton
					icon="close"
					label={intl.formatMessage({ id: "POPUP.BUTTON.CLOSE" })}
					iconClass="small"
					onClick={onClose}
				/>
			</div>
			<div className="popup-content">
				<header className="o-header o-header--contact">
					<NavLink to={routesConfig.dashboard.root.replace(":team", team?.slug)} className="o-header--logo">
						<span style={{ maxWidth: "14rem", display: "grid", placeItems: "center" }}>
							<Image.ALogo />
						</span>
					</NavLink>
					<Button type="text" icon={<CloseOutlined />} onClick={onClose} />
				</header>
				{children}
			</div>
		</div>
	);
}
