import { HTMLAttributes, Ref, forwardRef, useMemo, useRef } from "react";
import clsx from "clsx";
import Message from "../Message";
import { MessageRouterOutput } from "@/trpc";
import ConversationEmpty from "../ConversationEmpty";
import { Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { AnimatePresence } from "framer-motion";
import MessagesLoading from "../MessagesLoading";
import { shallowEqual } from "lodash";
import { RouterOutputAuditMessage, RouterOutputEmailMessage, RouterOutputInternalMessage } from "../..";

import ConversationNotFoundImage from "../../../../../../assets/images/icon/inbox_conversation_not_found.svg";
import { useSelector } from "react-redux";
import type { IUser } from "@bothive_core/database";
import "./style.scss";

interface MessageListBaseProps extends HTMLAttributes<HTMLElement> {
	conversationId?: string;
	messages?: MessageRouterOutput["allByConversationId"];
	isMessagesRefetching: boolean;
	isMessagesLoading: boolean;
	showSubjects?: boolean;
	refetchMessages: () => void;
}

export interface MessageListWithActionsProps extends MessageListBaseProps {
	hasActions: true;
	onDeleteDraft: (messageId: string) => void;
}

export interface MessageListWithoutActionsProps extends MessageListBaseProps {
	hasActions: false;
}

type MessageListProps = MessageListWithActionsProps | MessageListWithoutActionsProps;

const MessageList = forwardRef((props: MessageListProps, lastMessageRef: Ref<HTMLElement> | null) => {
	const messageListRef = useRef<HTMLElement | null>(null);
	const user: IUser = useSelector((state: any) => state.profile.account.user, shallowEqual);

	const sortedMessages = useMemo(() => {
		if (!props.messages?.length) return [];
		return sortMessagesByInReplyTo(props.messages);
	}, [props.messages]);

	const lastEmailMessageIndex = sortedMessages
		? sortedMessages.reduceRight((prev, message, index) => {
				if (!message) return prev;
				if (message.type === "email" && prev === -1) return index;

				return prev;
		  }, -1)
		: 0;

	const emailMessages = useMemo(() => {
		if (!props.messages?.length) return [];

		return structuredClone(props.messages).reduce<
			(RouterOutputEmailMessage & { replyDraftMessage?: RouterOutputEmailMessage })[]
		>((prev, message) => {
			if (!message || message.type !== "email") return prev;

			if (message.isDraft && message.inReplyTo) {
				const indexOfInReplyToMessage = prev.findIndex(
					(_message) => _message.uniqueMessageId === message.inReplyTo
				);

				if (indexOfInReplyToMessage !== -1) {
					prev[indexOfInReplyToMessage].replyDraftMessage = message;
					return prev;
				}
			}

			prev.push(message);

			return prev;
		}, []);
	}, [props.messages]);

	const firstEmailMessage = emailMessages[0];

	return (
		<main {...props} className={clsx("ml-message-list", props.className)} ref={messageListRef}>
			{!!props.messages?.length ? (
				sortedMessages.map((message, index) => {
					if (!message) return null;
					if (message.type === "audit") {
						const auditMessage = message as RouterOutputAuditMessage;

						return (
							<Message.AuditMessage
								key={auditMessage._id}
								initial={{ opacity: 0, y: 5 }}
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: -5 }}
								transition={{ duration: 0.1, delay: index * 0.0125 }}
								{...auditMessage}
							/>
						);
					}

					const isLastMessage = index === sortedMessages.length - 1;
					const isLastEmailMessage = index === lastEmailMessageIndex;
					const previousMessage: MessageRouterOutput["allByConversationId"][number] | undefined =
						sortedMessages[index - 1];
					const nextMessage: MessageRouterOutput["allByConversationId"][number] | undefined =
						sortedMessages[index + 1];

					if (message.type === "internal_message") {
						const internalMessage = message as RouterOutputInternalMessage;

						return (
							<Message.InternalMessage
								key={internalMessage._id}
								hasEqualSenderAsPrevious={
									internalMessage.author?.id === previousMessage?.author?.id &&
									previousMessage?.type === "internal_message"
								}
								hasEqualSenderAsNext={
									internalMessage.author?.id === nextMessage?.author?.id &&
									nextMessage?.type === "internal_message"
								}
								ref={isLastMessage ? lastMessageRef : null}
								initial={{
									opacity: 0,
									x: user._id === internalMessage.author?.id ? 20 : -20,
									y: 5,
								}}
								animate={{ opacity: 1, x: 0, y: 0 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.1, delay: index * 0.0125 }}
								{...internalMessage}
							/>
						);
					}

					const emailMessage = message as RouterOutputEmailMessage;

					const isSubjectDifferent = Boolean(
						firstEmailMessage.subject &&
							emailMessage.subject &&
							!emailMessage.subject.endsWith(firstEmailMessage?.subject)
					);

					return (
						<Message.EmailMessage
							key={emailMessage._id}
							hasActions={props.hasActions}
							ref={isLastMessage ? lastMessageRef : null}
							// Props
							message={emailMessage}
							messages={sortedMessages}
							showSubject={props.showSubjects || isSubjectDifferent}
							messageListRef={messageListRef}
							isLastEmailMessage={isLastEmailMessage}
							isCollapsed={!isLastEmailMessage}
							onDeleteDraft={(messageId) => {
								if (!props.hasActions) return;

								props.onDeleteDraft(messageId);
							}}
						/>
					);
				})
			) : props.isMessagesLoading && props.conversationId ? (
				<MessagesLoading key={1} />
			) : (
				<ConversationEmpty
					image={ConversationNotFoundImage}
					description={
						<FormattedMessage
							id="INBOX.MESSAGES.NOT_FOUND_STATE.LABEL"
							defaultMessage="Oops, it seems like we could not find the messages of this conversation..."
							description="Fallback message when conversation messages could not be found / loaded"
						/>
					}
					action={
						<Button
							loading={props.isMessagesRefetching}
							type="primary"
							icon={<ReloadOutlined />}
							onClick={() => props.refetchMessages()}
						>
							{" "}
							<FormattedMessage
								id="INBOX.CONVERSATION.NOT_FOUND_STATE.BUTTON.RELOAD"
								defaultMessage="Search again"
							/>
						</Button>
					}
				/>
			)}
		</main>
	);
});

export default MessageList;

function sortMessagesByInReplyTo(messages: MessageRouterOutput["allByConversationId"][number][]) {
	const clonedMessages = structuredClone(messages);

	const messagesGroupedByInReplyTo = clonedMessages.reduce<
		(MessageRouterOutput["allByConversationId"][number] & {
			replyDraft?: MessageRouterOutput["allByConversationId"][number];
		})[]
	>((prev, message) => {
		if (message.type === "email" && message.isDraft && message.inReplyTo) {
			const indexOfInReplyToMessage = prev.findIndex(
				(_message) => _message.type === "email" && _message.uniqueMessageId === message.inReplyTo
			);

			if (indexOfInReplyToMessage !== -1) {
				prev[indexOfInReplyToMessage].replyDraft = message;
				return prev;
			}
		}

		prev.push(message);

		return prev;
	}, []);

	return messagesGroupedByInReplyTo.flatMap((message) => {
		if (message.replyDraft) {
			const replyDraft = structuredClone(message.replyDraft);
			delete message.replyDraft;
			return [message, replyDraft];
		}

		return [message];
	});
}
