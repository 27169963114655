export default {
	FILE_UPLOAD_FAILED: new Error("File upload failed"),
	GET_TEMPLATE_FAILED: new Error("Get template failed"),
	serverCodes: {
		authorizationFailed: "ERR11000",
		twoFactorFailed: "ERR11002",
		duplicate: "ERR22022",
		limitReached: "ERR22099",
		upgradeToCurrentPlan: "ERR88088",
		planNotFound: "ERR88404",
	},
	stripeErrorCodes: {
		authenticationFailed: "setup_intent_authentication_failure",
	},
};
