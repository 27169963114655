import "./style.scss";

import type { TContactType } from "@bothive_core/database";
import { Select, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import React from "react";

import { trpc } from "../../../../../../trpc";
import FieldLabel from "../../../../../../shared/components/field/default";
import ShowSuccessBanner from "../../../../../../shared/components/banner/success";
import ShowFailedBanner from "../../../../../../shared/components/banner/failed";

export default function ContactType({ type }: { type?: TContactType }) {
	const intl = useIntl();
	const params: { contactId: string } = useParams();
	const [contactType, setContactType] = React.useState(type);
	const updateContactMutation = trpc.contact.update.useMutation();
	const trpcUtils = trpc.useUtils();

	const handleChange = (value: TContactType) => {
		setContactType(value);

		updateContactMutation.mutate(
			{ id: params.contactId, type: value as any },
			{
				onSuccess: () => {
					ShowSuccessBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.TYPE.SUCCESS",
							defaultMessage: "The contact type has been successfully updated",
						}),
					});

					trpcUtils.contact.getContactById.invalidate();
				},
				onError: () => {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "CONTACTS.DETAIL.ATTRIBUTES.TYPE.ERROR",
							defaultMessage: "An error occurred while updating the contact type",
						}),
					});
				},
			}
		);
	};

	const options = [
		{
			value: "customer",
			label: intl.formatMessage({ id: "contact.type.customer.title", defaultMessage: "Customer" }),
			description: intl.formatMessage({
				id: "contacts.overview.select.type.customer.description",
				defaultMessage: "Contacts belonging to your customer base",
			}),
		},
		{
			value: "prospect",
			label: intl.formatMessage({ id: "contact.type.prospect.title", defaultMessage: "Prospect" }),
			description: intl.formatMessage({
				id: "contacts.overview.select.type.prospect.description",
				defaultMessage: "Contacts who already came into contact with your office",
			}),
		},
		{
			value: "linkedContact",
			label: intl.formatMessage({ id: "contact.type.linked_contact.title", defaultMessage: "Linked contact" }),
			description: intl.formatMessage({
				id: "contacts.overview.select.type.linked_contact.description",
				defaultMessage: "Contact that you can link to other contacts but does not belong to your customer base",
			}),
		},
		{
			value: "other",
			label: intl.formatMessage({ id: "contact.type.other.title", defaultMessage: "Other" }),
			description: intl.formatMessage({
				id: "contacts.overview.select.type.other.description",
				defaultMessage: "Contacts of a different type on which you want to be able to filter",
			}),
		},
	];

	if (type === "anonymous") {
		options.push({
			value: "anonymous",
			label: intl.formatMessage({ id: "contact.type.anonymous.title", defaultMessage: "Anonymous" }),
			description: intl.formatMessage({
				id: "contacts.overview.select.type.anonymous.description",
				defaultMessage: "Contact that anonymously has been in contact with you",
			}),
		});
	}

	const selectedOption = options.find(({ value }) => value === (contactType || type));

	return (
		<div style={{ width: "100%" }} className="contact_type_select">
			<FieldLabel style={{ width: "100%" }}>
				<Select
					allowClear={false}
					onChange={handleChange}
					defaultValue={type}
					popupMatchSelectWidth={false}
					style={{ width: "48%", textAlign: "left" }}
				>
					{options.map((option) => (
						<Select.Option value={option.value} key={option.value} className="contact_type_select-option">
							<p>{option.label}</p>
							<Typography.Text type="secondary" className="contact_type_select-option-description">
								{option.description}
							</Typography.Text>
						</Select.Option>
					))}
				</Select>
			</FieldLabel>
			{selectedOption && (
				<div style={{ textAlign: "left", marginTop: "1em" }}>
					<Typography.Text style={{ color: "grey" }}>
						<FormattedMessage id={selectedOption.description} />
					</Typography.Text>
				</div>
			)}
		</div>
	);
}
