import "./style.scss";
import { Button } from "antd";

import { getStatusState } from "./helper";
import StatusSuffix from "./status_suffix";

// tooltip is an object { success: "", failure: "" }
export default function StatusButton({ children, tooltip, isLoading, isSuccessful, ...props }) {
	return (
		<Button
			disabled={isLoading}
			data-loading={isLoading}
			className="report-status_button"
			data-status={getStatusState({ isSuccessful })}
			{...props}
		>
			<div className="report-status_button_content">
				<p>{children}</p>
				<StatusSuffix isSuccessful={isSuccessful} isLoading={isLoading} tooltip={tooltip} />
			</div>
		</Button>
	);
}
