import "./style.scss";

import { useIntl } from "react-intl";
import { IFlowTemplate } from "@bothive_core/database";

interface IAsidePreview {
	brandColor?: string;
	list: IFlowTemplate[];
}

export default function AsidePreview({ brandColor, list }: IAsidePreview) {
	const intl = useIntl();

	const totalEmptyItems = Math.max(0, 6 - list.length);
	const previewList = [...list, ...Array(totalEmptyItems).fill({ isPreview: true })];

	return (
		<div className="setup-aside_preview setup_flows-aside_preview">
			<div className="aside_preview-header" style={brandColor ? { background: brandColor } : {}} />
			<div className="aside_preview-content">
				{previewList.map((flow) => (
					<div className="aside_preview-flow_card" data-is-preview={flow.isPreview}>
						<img src={flow.logo} className="aside_preview-flow_card-logo" />
						<p className="aside_preview-flow_card-title">{!flow.isPreview && flow.name[intl.locale]}</p>
						<p className="aside_preview-flow_card-description">
							{!flow.isPreview && flow.shortDescription[intl.locale]}
						</p>
					</div>
				))}
			</div>
		</div>
	);
}
