import { Progress } from "antd";

export default function ProgressGraph({
	min = 0,
	max,
	value,
	label,
}: {
	min?: number;
	max: number;
	value: number;
	label: string;
}) {
	const displayText = () => (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				color: "white",
				zIndex: "1",
				mixBlendMode: "difference",
			}}
		>
			<span>{label}</span>
		</div>
	);

	return (
		<div style={{ width: "100%" }}>
			<Progress
				strokeLinecap="butt"
				percent={((value - min) / (max - min)) * 100}
				format={displayText}
				strokeWidth={45}
				strokeColor={"#002140"}
				style={{ position: "relative", width: "100%", borderRadius: "var(--rounded)" }}
			/>
			{max !== 0 && max < Number.MAX_SAFE_INTEGER && (
				<div style={{ display: "flex", justifyContent: "space-between", width: "98.5%" }}>
					<label>{min}</label>
					<label>{max}</label>
				</div>
			)}
		</div>
	);
}
