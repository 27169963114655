import { CloseCircleOutlined } from "@ant-design/icons";
import type { IAdminPulseConfig, IAdminPulseTaskLink } from "@bothive_core/database";
import { Button, Form, Modal, notification } from "antd";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { trpc } from "../../../../../../../trpc";
import TaskForm from "./taskForm";

interface IEditTaskLinkModal {
	isOpen: boolean;
	connectionId: string;
	taskLink?: IAdminPulseConfig["taskLink"][number];
	onClose: () => void;
}
export default function EditTaskLinkModal({ connectionId, taskLink, isOpen, onClose }: IEditTaskLinkModal) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const trpcContext = trpc.useContext();

	const updateTaskLink = trpc.integrations.crm.adminPulse.updateTaskLink.useMutation();
	const deleteTaskLink = trpc.integrations.crm.adminPulse.deleteTaskLink.useMutation();

	const handleUpdate = async (link: Omit<IAdminPulseTaskLink, "_id">) => {
		try {
			if (!taskLink) return;

			await updateTaskLink.mutateAsync({ connectionId, linkId: taskLink._id, link });

			onClose();
			trpcContext.integration.getConnection.invalidate({ id: connectionId });
			notification.success({
				message: intl.formatMessage({
					id: "settings.integrations.admin_pulse.tasks.edit_task_modal.succeeded",
					defaultMessage: "Link has been updated",
					description: "Notification about task link has been updated",
				}),
				placement: "bottomRight",
			});
		} catch (_) {
			notification.error({
				message: intl.formatMessage({
					id: "settings.integrations.admin_pulse.tasks.edit_task_modal.update.failed",
					defaultMessage:
						"It seems like the task link can't be updated right now. Please give it another shot later. We'll have this sorted out for you soon!",
					description:
						"Error notification about task link that can not be updated, user should try again later",
				}),
				placement: "bottomRight",
				duration: 8,
			});
		}
	};

	const handleDeleteLinking = () => {
		if (!taskLink?._id) return;

		Modal.confirm({
			width: 512,
			title: intl.formatMessage({
				id: "settings.integrations.admin_pulse.tasks.delete_linking_model.title",
				defaultMessage: "Delete task link",
				description:
					"Title of confirmation model that is shown to the user when they want to delete a tasks link",
			}),
			icon: <CloseCircleOutlined style={{ color: "#DE5050" }} />,
			content: intl.formatMessage({
				id: "settings.integrations.admin_pulse.tasks.delete_linking_model.description",
				defaultMessage:
					"Deleting this link is a one-way street! These tasks wont update automatically anymore. Ready to proceed with the deletion? 🗑️",
				description:
					"Description of confirmation model that is shown to the user when they want to delete a tasks link. Explain that this is irreversible and this task will not be updated automatically by us.",
			}),
			okText: intl.formatMessage({
				id: "settings.integrations.admin_pulse.tasks.delete_linking_model.button.delete",
				defaultMessage: "Delete",
			}),
			okButtonProps: { danger: true, loading: deleteTaskLink.isLoading },
			cancelText: intl.formatMessage({
				id: "settings.integrations.admin_pulse.tasks.delete_linking_model.button.cancel",
				defaultMessage: "Cancel",
			}),
			onOk() {
				deleteTaskLink
					.mutateAsync({ connectionId, linkId: taskLink._id })
					.then(() => {
						trpcContext.integration.getConnection.invalidate({ id: connectionId });
						notification.success({
							message: intl.formatMessage({
								id: "settings.integrations.admin_pulse.tasks.delete_linking_model.succeeded",
								defaultMessage: "The task link has been deleted.",
								description: "Notification about task link that has been successfully deleted",
							}),
							placement: "bottomRight",
						});
						onClose();
					})
					.catch(() => {
						notification.error({
							message: intl.formatMessage({
								id: "settings.integrations.admin_pulse.tasks.delete_linking_model.failed",
								defaultMessage:
									"It seems like the link can't be deleted right now. Please give it another shot later. We'll have this sorted out for you soon!",
								description: "Error task link can not be deleted, user should try again later",
							}),
							placement: "bottomRight",
							duration: 8,
						});
					});
			},
		});
	};

	useEffect(() => {
		form.setFieldsValue({
			...taskLink,
			taskId: taskLink?.task?.id,
			subTaskId: taskLink?.task?.subTaskId,
		});
	}, [taskLink]);

	return (
		<Modal
			width={720}
			open={isOpen}
			onCancel={onClose}
			title={intl.formatMessage({
				id: "settings.integrations.admin_pulse.tasks.edit_task_modal.title",
				defaultMessage: "Task link",
				description: "Title of modal that is shown to the user where they update the selected task link",
			})}
			className="admin_pulse-tasks-create_modal"
			destroyOnClose
			footer={
				<footer className="admin_pulse-webhook-create_modal-footer">
					<Button type="text" onClick={onClose}>
						<FormattedMessage
							id="settings.integrations.admin_pulse.tasks.edit_task_modal.button.cancel"
							defaultMessage="Cancel"
						/>
					</Button>
					<div className="admin_pulse-webhook-edit_filters-footer-wrapper">
						<Button
							danger
							type="primary"
							onClick={handleDeleteLinking}
							loading={deleteTaskLink.isLoading}
							disabled={updateTaskLink.isLoading}
						>
							<FormattedMessage
								id="settings.integrations.admin_pulse.tasks.edit_task_modal.button.delete"
								defaultMessage="Delete"
							/>
						</Button>
						<Button
							type="primary"
							htmlType="submit"
							form="adminPulseLinkForm"
							loading={updateTaskLink.isLoading}
							disabled={deleteTaskLink.isLoading}
						>
							<FormattedMessage
								id="settings.integrations.admin_pulse.tasks.edit_task_modal.button.submit"
								defaultMessage="Update task link"
							/>
						</Button>
					</div>
				</footer>
			}
		>
			<TaskForm connectionId={connectionId} form={form} onSubmit={handleUpdate} />
		</Modal>
	);
}
