import { IMember } from "@bothive_core/database";
import { Form, Select, Empty, Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { trpc } from "@/trpc";
import { Dispatch, SetStateAction, useState } from "react";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import ContactLabels from "../../../shared/components/ContactLabelsSelector";
import SelectLoading from "../../../shared/components/select/loading";

interface FiltersProps {
	labelId: string;
	setLabelId: Dispatch<SetStateAction<string | undefined>>;
	setContactType: Dispatch<SetStateAction<string | undefined>>;
	setAccountManagerId: Dispatch<SetStateAction<string | undefined>>;
}

export default function Filters({ setContactType, labelId, setLabelId, setAccountManagerId }: FiltersProps) {
	const intl = useIntl();
	const [searchLabel, setSearchLabel] = useState<string>();
	const [searchAccountManager, setSearchAccountManager] = useState<string>();

	const { data: contactLabels, isLoading: isLabelLoading } = trpc.contactLabel.all.useQuery({
		q: searchLabel,
		limit: 0,
	}); // allow unlimited labels as there is no pagination
	const { data: accountManagers, isLoading: isAccountManagerFetching } = trpc.member.getAll.useQuery({
		q: searchAccountManager,
		limit: 0,
	});

	return (
		<Form layout="vertical" className="contacts-filter">
			<Form.Item label={intl.formatMessage({ id: "CONTACTS.OVERVIEW.SELECT.TYPE.TITLE" })} name="contactType">
				<Select
					size="large"
					onChange={setContactType}
					placeholder={intl.formatMessage({ id: "CONTACTS.OVERVIEW.SELECT.TYPE.PLACEHOLDER" })}
					className="loading_select"
					allowClear={true}
				>
					<Select.Option value="customer" className="filter-dropdown-item">
						<FormattedMessage id="contact.overview.select.type.customer" defaultMessage="customer" />
					</Select.Option>
					<Select.Option value="prospect" className="filter-dropdown-item">
						<FormattedMessage id="contact.overview.select.type.prospect" defaultMessage="prospect" />
					</Select.Option>
					<Select.Option value="other" className="filter-dropdown-item">
						<FormattedMessage id="contact.overview.select.type.other" defaultMessage="other" />
					</Select.Option>
					<Select.OptGroup
						key="hidden"
						label={intl.formatMessage({
							id: "contact.overview.select.group.hidden",
							defaultMessage: "Hidden types",
							description: "Selection group title for hidden contact types",
						})}
					>
						<Select.Option value="linkedContact" className="filter-dropdown-item">
							<FormattedMessage
								id="contact.overview.select.type.linked_contact"
								defaultMessage="linked contacts"
							/>
							<Tooltip
								title={intl.formatMessage({
									id: "contact.overview.select.hidden_option.tooltip",
									defaultMessage: "These contacts are default hidden in overview",
									description:
										"Explain in tooltip that contacts with this type are default hidden from contact overview list",
								})}
							>
								<EyeInvisibleOutlined className="t-gap--left-xs" />
							</Tooltip>
						</Select.Option>
						<Select.Option value="anonymous" className="filter-dropdown-item">
							<FormattedMessage id="contact.overview.select.type.anonymous" defaultMessage="Anonymous" />
							<Tooltip
								title={intl.formatMessage({
									id: "contact.overview.select.hidden_option.tooltip",
									defaultMessage: "These contacts are default hidden in overview",
									description:
										"Explain in tooltip that contacts with this type are default hidden from contact overview list",
								})}
							>
								<EyeInvisibleOutlined className="t-gap--left-xs" />
							</Tooltip>
						</Select.Option>
					</Select.OptGroup>
				</Select>
			</Form.Item>
			<Form.Item
				label={intl.formatMessage({
					id: "CONTACTS.OVERVIEW.SELECT.LABELS.TITLE",
				})}
				name="labelId"
			>
				<ContactLabels
					placeholder={intl.formatMessage({
						id: "CONTACTS.OVERVIEW.SELECT.LABELS.PLACEHOLDER",
					})}
					contactLabels={contactLabels?.data}
					isLabelLoading={isLabelLoading}
					onChange={(labelId) => setLabelId(labelId)}
					onClear={() => setLabelId(undefined)}
					onSearch={(value) => setSearchLabel(value)}
					value={labelId}
				/>
			</Form.Item>
			<Form.Item
				label={intl.formatMessage({
					id: "CONTACTS.OVERVIEW.SELECT.ACCOUNT_MANAGER.TITLE",
				})}
				name="accountManagerId"
			>
				<SelectLoading
					showSearch
					isLoading={isAccountManagerFetching}
					placeholder={intl.formatMessage({
						id: "CONTACTS.OVERVIEW.SELECT.ACCOUNT_MANAGER.PLACEHOLDER",
					})}
					filterOption={(input, option) => option?.children.toLowerCase().includes(input.toLowerCase())}
					onChange={(value) => {
						setAccountManagerId(value);
					}}
					onClear={() => setAccountManagerId(undefined)}
					onSearch={(value) => setSearchAccountManager(value)}
					selectedValue={undefined}
					notFoundContent={
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={
								<p>
									<FormattedMessage id="CONTACTS.OVERVIEW.SELECT.ACCOUNT_MANAGER.NOT_FOUND" />
								</p>
							}
						/>
					}
				>
					{accountManagers?.data.map(({ userId, name }: IMember) => (
						<Select.Option key={`${userId}`} value={`${userId}`} className="filter-dropdown-item">
							{name}
						</Select.Option>
					))}
					selectedValue={undefined}
				</SelectLoading>
			</Form.Item>
		</Form>
	);
}
