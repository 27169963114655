import { Form, Empty, Input, Select } from "antd";
import qs from "qs";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";

import { queryParamsConfig } from "@/flows/common/config";
import config from "@/flows/flow_detail/config";
import {
	searchFilterState,
	selectedProgressState,
	selectedStatusTypeState,
	selectedContactLabelState,
	selectedAccountManagerState,
} from "@/flows/flow_detail/reports/state";
import { useStatusTypes } from "@/flows/flow_detail/status_types/hook";
import SelectLoading from "@/shared/components/select/loading";
import { debounceHelpers } from "@/shared/helpers";
import { trpc } from "../../../../../trpc";
import ContactLabels from "../../../../../shared/components/ContactLabelsSelector";

import style from "./style.module.scss";

export default function FilterModal() {
	const intl = useIntl();
	const history = useHistory();
	const [form] = Form.useForm();
	const [statusTypes] = useStatusTypes();
	const [searchLabel, setSearchLabel] = useState();
	const [searchFilter, setSearchFilter] = useRecoilState(searchFilterState);
	const [selectedStatus, setSelectedStatus] = useRecoilState(selectedStatusTypeState);
	const [selectedProgress, setSelectedProgress] = useRecoilState(selectedProgressState);
	const [selectedContactLabel, setSelectedContactLabel] = useRecoilState(selectedContactLabelState);
	const [selectedAccountManager, setSelectedAccountManager] = useRecoilState(selectedAccountManagerState);
	const { data: accountManagers, isLoading: isAccountManagerFetching } = trpc.member.getAll.useQuery({ limit: 0 });
	
	const { data: contactLabels, isLoading: isLabelLoading } = trpc.contactLabel.all.useQuery({
		q: searchLabel,
		limit: 0,
	}); // allow unlimited labels as there is no pagination

	const handleChange = () => {
		debounceHelpers(() => {
			const values = form.getFieldsValue();

			setSearchFilter(values.filter);
			setSelectedStatus(values.statusType);
			setSelectedProgress(values.progress);
			setSelectedContactLabel(values.label);
			setSelectedAccountManager(values.accountManager);
		});
	};

	const addNewFiltersToUrl = (filter) => {
		const search = qs.stringify(filter, { skipNulls: true });

		history.push({ search });
	};

	useEffect(() => {
		addNewFiltersToUrl({
			[queryParamsConfig.status]: selectedStatus,
			[queryParamsConfig.progress]: selectedProgress,
			[queryParamsConfig.search]: searchFilter,
			[queryParamsConfig.label]: selectedContactLabel,
			[queryParamsConfig.accountManager]: selectedAccountManager,
		});
	}, [selectedStatus, selectedProgress, searchFilter, selectedAccountManager, selectedContactLabel]);

	return (
		<div className={style.report_list_filter}>
			<Form
				form={form}
				layout="vertical"
				initialValues={{
					filter: searchFilter,
					statusType: selectedStatus,
					progress: selectedProgress,
					label: selectedContactLabel,
					accountManager: selectedAccountManager,
				}}
				onValuesChange={handleChange}
			>
				<Form.Item
					name="filter"
					label={intl.formatMessage({ id: "FLOWS.REPORT_DETAIL.LIST.FILTER.SEARCH.LABEL" })}
				>
					<Input
						placeholder={intl.formatMessage({ id: "FLOWS.REPORT_DETAIL.LIST.FILTER.SEARCH.PLACEHOLDER" })}
						size="large"
					/>
				</Form.Item>
				<Form.Item
					name="statusType"
					label={intl.formatMessage({ id: "FLOWS.REPORT_DETAIL.LIST.FILTER.STATUS.LABEL" })}
				>
					<SelectLoading
						isLoading={statusTypes.state === "loading"}
						value={selectedStatus}
						placeholder="FLOWS.REPORT_DETAIL.LIST.FILTER.STATUS.PLACEHOLDER"
						onChange={(value) => setSelectedStatus(value)}
						onClear={() => setSelectedStatus(undefined)}
						showSearch
					>
						{statusTypes?.contents?.map((item) => (
							<Select.Option
								key={item._id}
								value={item._id}
								data-is-done={item.isDone}
								className="filter-dropdown-item"
							>
								{item.name}
							</Select.Option>
						))}
					</SelectLoading>
				</Form.Item>
				<Form.Item
					name="progress"
					label={intl.formatMessage({ id: "FLOWS.REPORT_DETAIL.LIST.FILTER.PROGRESS.LABEL" })}
				>
					<SelectLoading
						value={selectedProgress}
						placeholder="FLOWS.REPORT_DETAIL.LIST.FILTER.PROGRESS.PLACEHOLDER"
						onChange={setSelectedProgress}
						onClear={() => setSelectedProgress(undefined)}
					>
						{config.reportProgress.progressTypesArray.map((progressType) => (
							<Select.Option key={progressType} value={progressType}>
								<FormattedMessage id={progressType} />
							</Select.Option>
						))}
					</SelectLoading>
				</Form.Item>
				<Form.Item
					label={intl.formatMessage({
						id: "flows.report_detail.list.filter.account_manager.label",
						defaultMessage: "Filter on account manager",
					})}
					name="accountManager"
				>
					<SelectLoading
						showSearch
						onChange={setSelectedAccountManager}
						isLoading={isAccountManagerFetching}
						onClear={() => setSelectedAccountManager(undefined)}
						placeholder={intl.formatMessage({
							id: "flows.report_detail.list.filter.account_manager.placeholder",
							defaultMessage: "Show all account manager",
						})}
						filterOption={(input, option) => option?.children.toLowerCase().includes(input.toLowerCase())}
						notFoundContent={
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description={
									<p>
										<FormattedMessage
											id="flows.report_detail.list.filter.account_manager.not_found"
											defaultMessage="Geen dossierbeheerders gevonden"
										/>
									</p>
								}
							/>
						}
					>
						{accountManagers?.data.map(({ userId, name }) => (
							<Select.Option key={`${userId}`} value={`${userId}`} className="filter-dropdown-item">
								{name}
							</Select.Option>
						))}
					</SelectLoading>
				</Form.Item>
				<Form.Item label={intl.formatMessage({ id: "flows.report_detail.list.filter.labels.title" })} name="label">
					<ContactLabels
						placeholder={intl.formatMessage({
							id: "flows.report_detail.list.filter.labels.placeholder",
						})}
						contactLabels={contactLabels?.data}
						isLabelLoading={isLabelLoading}
						onSearch={(value) => setSearchLabel(value)}
						onClear={() => setSelectedContactLabel(undefined)}
					/>
				</Form.Item>
			</Form>
		</div>
	);
}