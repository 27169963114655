import "./style.scss";

import ReminderHeader from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/components/Header";
import { useIntl } from "react-intl";
import { ReactNode } from "react-markdown/lib/react-markdown";
import ReminderFooter from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/components/Footer";
import { Card, Button, FormInstance } from "antd";
import WhenForm from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/components/Setup/components/WhenForm";
import StopForm from "@/flows/flow_detail/reminders/components/CreateReminderStrategy/components/Setup/components/StopForm";

export default function Setup({
	setCurrentStep,
	form,
}: {
	setCurrentStep: (step: number) => void;
	form: FormInstance;
}) {
	const intl = useIntl();

	return (
		<section style={{ width: "650px" }}>
			<ReminderHeader
				title={intl.formatMessage({
					id: "flow.detail.reminders.create_strategy.step_1.title",
					defaultMessage: "Setup",
				})}
				description={
					intl.formatMessage(
						{
							id: "flow.detail.reminders.create_strategy.step_1.description",
							defaultMessage: "Setup automatic reminder for your {flowName} flow",
						},
						{
							flowName: <span style={{ fontWeight: 600 }}>PB 2024</span>,
						}
					) as ReactNode
				}
			/>
			<div style={{ paddingBottom: "10em" }} className="reminder-setup-form">
				<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<Card
						title={
							<p style={{ fontWeight: 600, padding: "0.5em" }}>
								{intl.formatMessage({
									id: "flow.detail.reminders.create_strategy.step_1.when",
									defaultMessage: "When should reminder be sent",
								})}
							</p>
						}
						style={{ width: "100%", textAlign: "left" }}
						size="small"
					>
						<WhenForm />
					</Card>
					<span
						style={{ height: "40px", width: "3px", display: "block", backgroundColor: "lightgray" }}
					></span>
					<Card
						title={
							<p style={{ fontWeight: 600, padding: "0.5em" }}>
								{intl.formatMessage({
									id: "flow.detail.reminders.create_strategy.step_1.stop",
									defaultMessage: "Stop sending reminder",
								})}
							</p>
						}
						style={{ width: "100%", textAlign: "left" }}
						size="small"
					>
						<StopForm form={form} />
					</Card>
				</div>
			</div>
			<ReminderFooter
				submitButton={
					<Button
						key="submit"
						type="primary"
						style={{ margin: "1em" }}
						onClick={async () => {
							await form.validateFields([
								"sendFirstAfterDays",
								"interval",
								"total",
								"timeOfDay",
								"allowReEnroll",
								"cutoffDate",
							]);

							setCurrentStep(1);
						}}
					>
						{intl.formatMessage({
							id: "flow.detail.reminders.create_strategy.step_1.button.submit",
							defaultMessage: "Next",
						})}
					</Button>
				}
			/>
		</section>
	);
}
