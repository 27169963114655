import "./style.scss";

import { Alert, Button, Form, Input, Space, Typography } from "antd";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { setupArrow } from "../../../assets/images/icon";
import ShowFailedBanner from "@/shared/components/banner/failed";
import { stringHelpers } from "@/shared/helpers";
import SetupSteps from "../components/SetupSteps";
import { trpc } from "../../../trpc";
import AsidePreview from "./asidePreview";
import MemberList from "./memberList";

export default function InviteMembers() {
	const intl = useIntl();
	const history = useHistory();
	const routerParams = useParams<{ slug: string }>();
	const [inputValue, setInputValue] = useState<string>("");
	const [memberList, setMemberList] = useState<string[]>([]);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const { data: organization } = trpc.organization.getOrganization.useQuery();
	const { data: members } = trpc.member.getMembers.useQuery();
	const createMemberBatch = trpc.member.createMemberBatch.useMutation();

	const totalMembers = (members?.length || 0) + memberList.length;
	const memberLimitReached = totalMembers >= (organization?.permissions?.users || 0);
	const existingMembers = useMemo(() => members?.map((member) => member.name) || [], [members]);

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const isValid = stringHelpers.isEmail(event.target.value);

		setInputValue(event.target.value);

		if (isValid !== isEmailValid) setIsEmailValid(isValid);
	};
	const handleAddEmail = () => {
		if (memberLimitReached) return;

		setMemberList([...new Set([...memberList, inputValue])]);
		setIsEmailValid(false);
		setInputValue("");
	};

	const handleInviteMembers = () => {
		if (!memberList.length) return;

		createMemberBatch.mutate(
			{ emails: memberList },
			{
				onSuccess: () => {
					handleGoToNextStep();
				},
				onError: () => {
					ShowFailedBanner({
						title: intl.formatMessage({
							id: "setup.invite_members.invite.failed",
							defaultMessage: "Could invite members into your organization please try again later.",
						}),
						intl,
					});
				},
			}
		);
	};

	const handleGoToNextStep = () => {
		history.push(`/${routerParams.slug}/setup/branding`);
	};

	return (
		<main className="setup">
			<section className="setup-body">
				<SetupSteps currentStep={0} />
				<article className="setup_content">
					<header className="setup_content-header">
						<Typography.Title>
							<FormattedMessage id="setup.invite_members.title" defaultMessage="Invite members" />
						</Typography.Title>
						<p>
							<FormattedMessage
								id="setup.invite_members.description"
								defaultMessage="Invite your colleagues to your new organization."
							/>
						</p>
					</header>
					{memberLimitReached && (
						<Alert
							style={{ width: "100%" }}
							message={intl.formatMessage({
								id: "setup.invite_members.add_member.upgrade_banner",
								defaultMessage: "You will be able to invite more members when you upgrade your plan",
								description:
									"Banner with this message will be shown when user has invited all members they are allowed to invite in their current billing plan.",
							})}
							type="warning"
						/>
					)}
					<Form.Item
						className="setup_invite_members-invite_input_form"
						help={intl.formatMessage(
							{
								id: "setup.invite_members.add_member.input.help",
								defaultMessage: "{value} / {total} members you can add to your organization",
							},
							{
								value: totalMembers,
								total: organization?.permissions?.users,
							}
						)}
					>
						<Space.Compact className="setup_invite_members-invite_input">
							<Input
								type="email"
								size="large"
								value={inputValue}
								onChange={handleEmailChange}
								onPressEnter={handleAddEmail}
								placeholder="john.doe@bothive.be"
								aria-label={intl.formatMessage({
									id: "setup.invite_members.add_member.input.aria_label",
									defaultMessage: "Add email from colleagues you want to invite",
								})}
							/>
							<Button
								type="primary"
								size="large"
								onClick={handleAddEmail}
								disabled={!isEmailValid || memberLimitReached}
							>
								<FormattedMessage
									id="setup.invite_members.add_member.button"
									defaultMessage="Add member"
								/>
							</Button>
						</Space.Compact>
					</Form.Item>
					{memberList.length > 0 && (
						<div className="setup_invite_members-invite_list">
							<p>
								<Typography.Text type="secondary">
									<FormattedMessage
										id="setup.invite_members.list.title"
										defaultMessage="Invite list"
									/>
								</Typography.Text>
							</p>
							<MemberList value={memberList} onChange={setMemberList} />
						</div>
					)}
					<footer className="setup_content-footer">
						<Button
							size="large"
							type="primary"
							loading={createMemberBatch.isLoading}
							disabled={!memberList.length}
							onClick={handleInviteMembers}
							className="setup_content-footer-button"
						>
							<FormattedMessage id="setup.invite_members.button.invite" defaultMessage="Send invites" />
						</Button>
						<Button
							type="text"
							size="large"
							onClick={handleGoToNextStep}
							className="setup_content-footer-button"
						>
							<FormattedMessage id="setup.invite_members.button.skip" defaultMessage="Skip" />
						</Button>
					</footer>
				</article>
			</section>
			<section className="setup_aside">
				<header className="setup_aside-header">
					<p className="setup_aside-header-title">
						<FormattedMessage
							id="setup.invite_members.aside.title"
							defaultMessage="Bothive is build for you and your team"
						/>
					</p>
					<img src={setupArrow} alt="" className="setup_aside-header-icon" />
				</header>
				<AsidePreview list={[...existingMembers, ...memberList]} />
				<p className="setup_aside-help">
					<FormattedMessage
						id="setup.invite_members.aside.help"
						defaultMessage="This is a example from how you can assignee your colleagues to flow reports"
					/>
				</p>
			</section>
		</main>
	);
}
