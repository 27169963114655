import React from "react";
import { FormattedMessage } from "react-intl";

import { keyEvents } from "../../../helpers";
import { Component } from "../../atoms";

export default function MDefault({ id, expanded, label, className, onClick }) {
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			onClick(id);
		}
	};

	return (
		<header
			id={`accordion-${id}`}
			aria-controls={id}
			tabIndex={0}
			role="button"
			aria-expanded={expanded}
			className={`m-accordion-header ${expanded ? "active" : ""} ${className}`}
			onKeyUp={handleKeyUp}
			onClick={() => onClick(id)}
		>
			<h2>{label ? <FormattedMessage id={label} /> : undefined}</h2>
			<Component.AIcon type={`arrow--${expanded ? "up" : "down"}`} className="fill" />
		</header>
	);
}

MDefault.defaultProps = {
	expanded: false,
	className: "",
	onClick: () => undefined,
};
