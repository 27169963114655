import "../style.scss";

import type { IContact, IProperty } from "@bothive_core/database";
import { Divider, Form, Input, InputNumber, Select, Switch } from "antd";
import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import { useRef } from "react";

import FieldLabel from "../../../../../../../../shared/components/field/default";
import SideLabel from "../../../../../../../../shared/components/field/side_label";
import { propertyType, propertyBuildingType, propertyOwnershipType } from "../../config";
import { useSetFocus } from "@/shared/hooks";
import DateFormItem from "@/shared/components/input/DateFormItem";
import CoOwnershipForm from "./CoOwnerShipForm";
import RentForm from "./RentForm";
import AddressForm from "./AddressForm";

interface IMainForm {
	id?: string;
	isOpen: boolean;
	contact: IContact;
	isStrict: boolean; // define if strict input should be used
	initialValues?: { [key: string]: any };
	onSubmit: (value: IPropertyValues) => void;
}

export interface IPropertyValues extends Omit<IProperty, "address"> {
	streetName: string;
	streetNumber: string;
	bus?: string;
	city: string;
	postalCode: string;
	province?: string;
	country: string;
}

export default function MainForm({ initialValues, isOpen, isStrict, contact, onSubmit }: IMainForm) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const selectRef = useRef<any>(null);

	useSetFocus({ ref: selectRef, dependencies: [selectRef, isOpen] });

	// Form states
	const isCoOwnership = Form.useWatch<IProperty["isCoOwnership"]>("isCoOwnership", form);
	const isRentedOut = Form.useWatch<IProperty["isRentedOut"]>("isRentedOut", form);
	const isSold = Form.useWatch<IProperty["isSold"]>("isSold", form);

	const handleFieldChange = (changedFields: any[]) => {
		const field = changedFields[0];
		const fieldName = field.name[0];

		// Mixed rent type
		if (fieldName === "percentagePrivateRented") {
			form.setFieldValue("percentageProfessionallyRented", 100 - field.value);
		}

		if (fieldName === "percentageProfessionallyRented") {
			form.setFieldValue("percentagePrivateRented", 100 - field.value);
		}

		// Split on real estate vs movable property
		if (fieldName === "percentageRealEstate") {
			form.setFieldValue("percentageMovableProperty", 100 - field.value);
		}

		if (fieldName === "percentageMovableProperty") {
			form.setFieldValue("percentageRealEstate", 100 - field.value);
		}
	};

	const _isStrict = isStrict || (initialValues?.type && initialValues?.streetName);

	return (
		<Form
			id="PropertyForm"
			layout="vertical"
			form={form}
			requiredMark="optional"
			className="frsf-form foreign_property_modal-form"
			style={{ marginBottom: "1em" }}
			onFinish={onSubmit}
			onFieldsChange={handleFieldChange}
			initialValues={initialValues || {}}
		>
			<FieldLabel
				id="name"
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.name.label",
					defaultMessage: "Name",
				})}
				help={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.name.help",
					defaultMessage: "Give the property a unique name to help it identify",
				})}
				optional={true}
			>
				<Form.Item name="name" className="foreign_property_modal-form_item">
					<Input
						placeholder={intl.formatMessage({
							id: "contacts.detail.PROPERTIES_MODAL.form.name.placeholder",
							defaultMessage: "Vacation home Spain",
						})}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="type"
				optional={!_isStrict}
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.type.label",
					defaultMessage: "Property type",
				})}
			>
				<Form.Item
					name="type"
					className="foreign_property_modal-form_item"
					rules={[
						{
							required: _isStrict,
							message: intl.formatMessage({
								id: "contacts.detail.PROPERTIES_MODAL.form.type.error_required",
								defaultMessage: "Please select a property type",
							}),
						},
					]}
				>
					<Select autoFocus={true} ref={selectRef}>
						{Object.keys(propertyType).map((key) => (
							<Select.Option key={key} value={key}>
								<FormattedMessage id={propertyType[key]} />
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="buildingType"
				optional={!_isStrict}
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.building_type.label",
					defaultMessage: "Building type",
				})}
			>
				<Form.Item
					name="buildingType"
					className="foreign_property_modal-form_item"
					rules={[
						{
							required: _isStrict,
							message: intl.formatMessage({
								id: "contacts.detail.PROPERTIES_MODAL.form.building_type.error_required",
								defaultMessage: "Please select a building type",
							}),
						},
					]}
				>
					<Select>
						{Object.keys(propertyBuildingType).map((key) => (
							<Select.Option key={key} value={key}>
								<FormattedMessage id={propertyBuildingType[key]} />
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="ownershipType"
				optional={!_isStrict}
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.ownership_type.label",
					defaultMessage: "Ownership type",
				})}
			>
				<Form.Item
					name="ownershipType"
					className="foreign_property_modal-form_item"
					rules={[
						{
							required: _isStrict,
							message: intl.formatMessage({
								id: "contacts.detail.PROPERTIES_MODAL.form.ownership_type.error_required",
								defaultMessage: "Please select an ownership type",
							}),
						},
					]}
				>
					<Select autoFocus={true}>
						{Object.keys(propertyOwnershipType).map((key) => (
							<Select.Option key={key} value={key}>
								<FormattedMessage id={propertyOwnershipType[key]} />
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</FieldLabel>
			<Divider />
			<AddressForm isStrict={_isStrict} />
			<Divider />
			<FieldLabel
				id="purchaseDate"
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.purchase_date.label",
					defaultMessage: "Purchase date",
				})}
				optional={true}
			>
				<Form.Item name="purchaseDate" className="foreign_property_modal-form_item">
					<DateFormItem
						style={{ width: "100%" }}
						disabledDate={(current) => current && current > dayjs().endOf("day")}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="cadastralIncome"
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.cadastral_income.label",
					defaultMessage: "Cadastral income",
				})}
				optional={true}
			>
				<Form.Item name="cadastralIncome" className="foreign_property_modal-form_item">
					<InputNumber prefix="€" placeholder="750" />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="propertyValue"
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.property_value.label",
					defaultMessage: "Property value",
				})}
				help={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.property_value.help",
					defaultMessage: "Enter the value of this property.",
				})}
				optional={true}
			>
				<Form.Item name="propertyValue" className="foreign_property_modal-form_item">
					<InputNumber prefix="€" placeholder="72500" />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="originalValue"
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.original_value.label",
					defaultMessage: "Original value",
				})}
				help={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.original_value.help",
					defaultMessage: "Enter the original value of this property.",
				})}
				optional={true}
			>
				<Form.Item name="originalValue" className="foreign_property_modal-form_item">
					<InputNumber prefix="€" placeholder="64750" />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="renovationCosts"
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.renovation_costs.label",
					defaultMessage: "Renovation costs",
				})}
				optional={true}
			>
				<Form.Item name="renovationCosts" className="foreign_property_modal-form_item">
					<InputNumber prefix="€" placeholder="10950" />
				</Form.Item>
			</FieldLabel>
			<Divider />
			<SideLabel
				id="isCoOwnership"
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.is_co_ownership.label",
					defaultMessage: "Is co-ownership",
				})}
			>
				<Form.Item name="isCoOwnership" valuePropName="checked">
					<Switch style={{ marginLeft: "auto", display: "block" }} />
				</Form.Item>
			</SideLabel>
			{isCoOwnership && <CoOwnershipForm contact={contact} />}
			<Divider />
			<SideLabel
				id="isRentedOut"
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.is_rented_out.label",
					defaultMessage: "Is rented out",
				})}
			>
				<Form.Item name="isRentedOut" valuePropName="checked">
					<Switch style={{ marginLeft: "auto", display: "block" }} />
				</Form.Item>
			</SideLabel>
			{isRentedOut && <RentForm form={form} />}
			<Divider />
			<SideLabel
				id="isSold"
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.is_sold.label",
					defaultMessage: "Is sold",
				})}
			>
				<Form.Item name="isSold" valuePropName="checked">
					<Switch style={{ marginLeft: "auto", display: "block" }} />
				</Form.Item>
			</SideLabel>
			{isSold && (
				<FieldLabel
					id="soldDate"
					label={intl.formatMessage({
						id: "contacts.detail.PROPERTIES_MODAL.form.sold_date.label",
						defaultMessage: "Sold date",
					})}
					help={intl.formatMessage({
						id: "contacts.detail.PROPERTIES_MODAL.form.sold_date.help",
						defaultMessage: "Enter the sold date of this property.",
					})}
					optional={true}
				>
					<Form.Item
						name="soldDate"
						className="foreign_property_modal-form_item"
						rules={[
							({ getFieldValue }) => ({
								validator: (_, value) => {
									if (!value) return Promise.resolve();

									const startDate = getFieldValue("purchaseDate");

									if (
										Date.parse(new Date(value).toString()) <
										Date.parse(new Date(startDate).toString())
									) {
										const label = intl.formatMessage({
											id: "contacts.detail.PROPERTIES_MODAL.form.sold_date.error",
											defaultMessage: "Sold date can not be before purchase date",
										});

										return Promise.reject(new Error(label));
									}

									return Promise.resolve();
								},
							}),
						]}
					>
						<DateFormItem
							style={{ width: "100%" }}
							disabledDate={(current) => current && current > dayjs().endOf("day")}
						/>
					</Form.Item>
				</FieldLabel>
			)}
		</Form>
	);
}
