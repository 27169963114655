import { combineReducers } from "redux";

import { actions as bannerActions, reducers as bannerReducers } from "./banner";
import { actions as errorHandlingActions, reducers as errorHandlingReducers } from "./errorHandling";
import { actions as headerActions, reducers as headerReducers } from "./header";
import { actions as importActions, reducers as importReducers } from "./import";
import { actions as inboxActions, reducers as inboxReducers } from "./inbox";
import { actions as notificationActions, reducers as notificationReducers } from "./notification";
import { actions as permissionsActions, reducers as permissionsReducers } from "./permissions";
import { actions as uiActions, reducers as uiReducers } from "./ui";

export const reducers = combineReducers({
	banner: bannerReducers,
	errorHandling: errorHandlingReducers,
	header: headerReducers,
	import: importReducers,
	inbox: inboxReducers,
	notification: notificationReducers,
	permissions: permissionsReducers,
	ui: uiReducers,
});

export const actions = {
	banner: bannerActions,
	errorHandling: errorHandlingActions,
	header: headerActions,
	import: importActions,
	inbox: inboxActions,
	notification: notificationActions,
	permissions: permissionsActions,
	ui: uiActions,
};
