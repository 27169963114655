import "./style.scss";

import React from "react";
import { useIntl } from "react-intl";

import { trpc } from "@/trpc";
import { Card } from "../../../../components/molecules";
import config from "./config";

export default function WalkThroughConnectChannel({ onClick, className }) {
	const intl = useIntl();
	const { data: providerData, isFetching } = trpc.channel.getChannelProviders.useQuery({});

	const list = isFetching && !providerData?.data?.length ? config.loader.placeholders : providerData.data;

	function handleCardSelect(id) {
		const provider = providerData.data.find((item) => item._id === id);

		onClick(provider);
	}

	return (
		<div className={`walk_through-connect_channel ${className}`}>
			{list.map((item) => (
				<Card.MIntegrationCard
					{...item}
					id={item._id}
					key={item._id}
					loading={isFetching}
					language={intl.locale}
					className="walk_through-connect_channel--list-item"
					onClick={handleCardSelect}
				/>
			))}
		</div>
	);
}
