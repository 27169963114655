import { Button, List } from "antd";
import { IContact } from "@bothive_core/database";

export default function FlowSendingList({
	contactSelection,
	selectedContactId,
	setSelectedContactId,
}: {
	contactSelection: Partial<IContact>[];
	selectedContactId: string;
	setSelectedContactId: (value: string) => void;
}) {
	return (
		<List
			style={{
				height: "100%",
				minWidth: "300px",
				overflowY: "scroll",
				overflowX: "hidden",
			}}
			bordered={true}
			itemLayout="vertical"
			dataSource={contactSelection}
			renderItem={(item, index) => {
				const selectedIndex = contactSelection?.findIndex((contact) => contact._id === selectedContactId);

				if (index === selectedIndex) {
					return (
						<Button
							type="primary"
							style={{
								width: "100%",
								textAlign: "left",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							{item?.firstName} {item?.lastName}
						</Button>
					);
				}

				return (
					<Button
						type="text"
						onClick={() => {
							setSelectedContactId(item._id);
						}}
						style={{
							width: "100%",
							textAlign: "left",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						{item?.firstName} {item?.lastName}
					</Button>
				);
			}}
		/>
	);
}
