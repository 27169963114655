import FieldLabel from "../../../../../../../../shared/components/field/default";
import { Form, Input } from "antd";
import { useIntl } from "react-intl";
import CountryPicker from "@/shared/components/select/country_picker";
import { normalizeEmptyToUndefined } from "../../../../../../../../shared/helpers/string.helpers";

export default function AddressForm({ isStrict }: { isStrict: boolean }) {
	const intl = useIntl();

	return (
		<div className="foreign_property_modal-address">
			<FieldLabel
				id="streetName"
				label="CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NAME.LABEL"
				className="foreign_property_modal-address-street_name"
				optional={!isStrict}
			>
				<Form.Item
					name="streetName"
					className="foreign_property_modal-form_item"
					rules={[
						{
							required: isStrict,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NAME.ERROR_REQUIRED",
							}),
						},
					]}
				>
					<Input
						placeholder={intl.formatMessage({
							id: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NAME.PLACEHOLDER",
						})}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="streetNumber"
				label="CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NUMBER.LABEL"
				className="foreign_property_modal-address-street_number"
			>
				<Form.Item
					name="streetNumber"
					className="foreign_property_modal-form_item"
					rules={[
						{
							required: isStrict,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NUMBER.ERROR_REQUIRED",
							}),
						},
					]}
				>
					<Input
						placeholder={intl.formatMessage({
							id: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.STREET_NUMBER.PLACEHOLDER",
						})}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="bus"
				label="CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.BUS.LABEL"
				className="foreign_property_modal-address-bus"
				optional={true}
			>
				<Form.Item name="bus" className="foreign_property_modal-form_item">
					<Input />
				</Form.Item>
			</FieldLabel>

			<FieldLabel
				id="postalCode"
				label="CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.POSTAL_CODE.LABEL"
				className="foreign_property_modal-address-postal_code"
				optional={!isStrict}
			>
				<Form.Item
					name="postalCode"
					className="foreign_property_modal-form_item"
					rules={[
						{
							required: isStrict,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.POSTAL_CODE.ERROR_REQUIRED",
							}),
						},
					]}
				>
					<Input
						placeholder={intl.formatMessage({
							id: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.POSTAL_CODE.PLACEHOLDER",
						})}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="city"
				label="CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.CITY.LABEL"
				className="foreign_property_modal-address-city"
				optional={!isStrict}
			>
				<Form.Item
					name="city"
					className="foreign_property_modal-form_item"
					rules={[
						{
							required: isStrict,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.CITY.ERROR_REQUIRED",
							}),
						},
					]}
				>
					<Input
						placeholder={intl.formatMessage({
							id: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.CITY.PLACEHOLDER",
						})}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="province"
				label="CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.PROVINCE.LABEL"
				className="foreign_property_modal-address-province"
				optional={true}
			>
				<Form.Item
					normalize={normalizeEmptyToUndefined}
					name="province"
					className="foreign_property_modal-form_item"
				>
					<Input
						placeholder={intl.formatMessage({
							id: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.PROVINCE.PLACEHOLDER",
						})}
					/>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="country"
				label="CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.COUNTRY.LABEL"
				className="foreign_property_modal-address-country"
				optional={!isStrict}
			>
				<Form.Item
					name="country"
					className="foreign_property_modal-form_item"
					rules={[
						{
							required: isStrict,
							message: intl.formatMessage({
								id: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.COUNTRY.ERROR_REQUIRED",
							}),
						},
					]}
				>
					<CountryPicker
						placeholder={intl.formatMessage({
							id: "CONTACTS.DETAIL.PROPERTIES_MODAL.FORM.ADDRESS.COUNTRY.PLACEHOLDER",
						})}
					/>
				</Form.Item>
			</FieldLabel>
		</div>
	);
}
