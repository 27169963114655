import { percentageValidator } from "@/helpers/validators.helpers";
import FieldLabel from "../../../../../../../../shared/components/field/default";
import SideLabel from "../../../../../../../../shared/components/field/side_label";
import { Form, FormInstance, InputNumber, Select, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { propertyRentType } from "../../config";
import type { IProperty } from "@bothive_core/database";

export default function RentForm({ form }: { form: FormInstance }) {
	const intl = useIntl();

	const rentType = Form.useWatch<IProperty["rentType"]>("rentType", form);
	const rentedWithFurniture = Form.useWatch<IProperty["rentedWithFurniture"]>("rentedWithFurniture", form);
	const hasRealEstateMovablePropertySplit = Form.useWatch<IProperty["hasRealEstateMovablePropertySplit"]>(
		"hasRealEstateMovablePropertySplit",
		form
	);

	return (
		<>
			<FieldLabel
				id="rentType"
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.rent_type.label",
					defaultMessage: "Rent type",
				})}
				help={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.rent_type.help",
					defaultMessage: "Rent type of this property.",
				})}
			>
				<Form.Item
					name="rentType"
					className="foreign_property_modal-form_item"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "contacts.detail.PROPERTIES_MODAL.form.rent_type.error_required",
								defaultMessage: "Please select a rent type",
							}),
						},
					]}
				>
					<Select autoFocus={true}>
						{Object.keys(propertyRentType).map((key) => (
							<Select.Option key={key} value={key}>
								<FormattedMessage id={propertyRentType[key]} />
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.possible_rent_amount.label",
					defaultMessage: "Possible rent amount",
				})}
				help={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.possible_rent_amount.help",
					defaultMessage: "Enter the possible rent amount of this property.",
				})}
				id="possibleRentAmount"
				optional={true}
			>
				<Form.Item name="possibleRentAmount" className="foreign_property_modal-form_item">
					<InputNumber prefix="€" placeholder="1250" />
				</Form.Item>
			</FieldLabel>
			<FieldLabel
				id="rentAmount"
				label={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.rent_amount.label",
					defaultMessage: "Rent amount",
				})}
				help={intl.formatMessage({
					id: "contacts.detail.PROPERTIES_MODAL.form.rent_amount.help",
					defaultMessage: "Enter the rent amount of this property.",
				})}
				optional={true}
			>
				<Form.Item name="rentAmount" className="foreign_property_modal-form_item">
					<InputNumber prefix="€" placeholder="1250" />
				</Form.Item>
			</FieldLabel>
			{rentType === "mixed" && (
				<div className="foreign_property_modal-form_dual">
					<FieldLabel
						id="percentagePrivateRented"
						label={intl.formatMessage({
							id: "contacts.detail.PROPERTIES_MODAL.form.percentage_private_rented.label",
							defaultMessage: "Percentage private rented",
						})}
						optional={true}
					>
						<Form.Item
							name="percentagePrivateRented"
							className="foreign_property_modal-form_item"
							rules={[
								{
									validator: (_, value) => percentageValidator(value, intl),
								},
							]}
						>
							<InputNumber max={100} prefix="%" placeholder="20" />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						id="percentageProfessionallyRented"
						label={intl.formatMessage({
							id: "contacts.detail.PROPERTIES_MODAL.form.percentage_professionally_rented.label",
							defaultMessage: "Percentage professionally rented",
						})}
						optional={true}
					>
						<Form.Item
							name="percentageProfessionallyRented"
							className="foreign_property_modal-form_item"
							rules={[
								{
									validator: (_, value) => percentageValidator(value, intl),
								},
							]}
						>
							<InputNumber max={100} prefix="%" placeholder="80" />
						</Form.Item>
					</FieldLabel>
				</div>
			)}
			{(rentType === "mixed" || rentType === "private") && (
				<>
					<SideLabel
						id="rentedWithFurniture"
						label={intl.formatMessage({
							id: "contacts.detail.PROPERTIES_MODAL.form.rented_with_furniture.label",
							defaultMessage: "rented with furniture",
						})}
					>
						<Form.Item name="rentedWithFurniture" valuePropName="checked">
							<Switch style={{ marginLeft: "auto", display: "block" }} />
						</Form.Item>
					</SideLabel>
					{rentedWithFurniture && (
						<SideLabel
							id="hasRealEstateMovablePropertySplit"
							label={intl.formatMessage({
								id: "contacts.detail.PROPERTIES_MODAL.form.has_re_mvp_split.label",
								defaultMessage: "Division between real estate and movable rent in contract",
							})}
						>
							<Form.Item name="hasRealEstateMovablePropertySplit" valuePropName="checked">
								<Switch style={{ marginLeft: "auto", display: "block" }} />
							</Form.Item>
						</SideLabel>
					)}
					{hasRealEstateMovablePropertySplit && (
						<div className="foreign_property_modal-form_dual">
							<FieldLabel
								id="percentageMovableProperty"
								label={intl.formatMessage({
									id: "contacts.detail.PROPERTIES_MODAL.form.percentage_movable_property.label",
									defaultMessage: "Percentage movalbe property",
								})}
								optional={true}
							>
								<Form.Item
									name="percentageMovableProperty"
									className="foreign_property_modal-form_item"
									rules={[
										{
											validator: (_, value) => percentageValidator(value, intl),
										},
									]}
								>
									<InputNumber max={100} prefix="%" placeholder="20" />
								</Form.Item>
							</FieldLabel>
							<FieldLabel
								id="percentageRealEstate"
								label={intl.formatMessage({
									id: "contacts.detail.PROPERTIES_MODAL.form.percentage_real_estate.label",
									defaultMessage: "Percentage real estate",
								})}
								optional={true}
							>
								<Form.Item
									name="percentageRealEstate"
									className="foreign_property_modal-form_item"
									rules={[
										{
											validator: (_, value) => percentageValidator(value, intl),
										},
									]}
								>
									<InputNumber max={100} prefix="%" placeholder="80" />
								</Form.Item>
							</FieldLabel>
						</div>
					)}
				</>
			)}
		</>
	);
}
