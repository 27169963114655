import Collapse, { DestructivePanel, Panel } from "../../../../shared/components/collapse";
import { Section } from "../../../../components/organisms";
import DeleteModal from "../../../../shared/components/modal/delete_modal";
import React from "react";
import { trpc } from "../../../../trpc";
import { useHistory, useParams } from "react-router";
import ShowSuccessBanner from "../../../../shared/components/banner/success";
import { FormattedMessage, useIntl } from "react-intl";
import { routesConfig } from "../../../../config";
import { useSelector } from "react-redux";
import { Button, Typography } from "antd";
import ShowFailedBanner from "../../../../shared/components/banner/failed";
import { calculateDeleteDate } from "../../utils";

export default function ContactDetailSettings() {
	const [showDeleteModal, setShowDeleteModal] = React.useState(false);

	const intl = useIntl();
	const history = useHistory();
	const trpcUtils = trpc.useUtils();
	const params: { contactId: string } = useParams();
	const team = useSelector((state) => state.content.team.team);

	const restoreContactMutation = trpc.contact.restoreContact.useMutation();
	const deleteContactMutation = trpc.contact.deleteContact.useMutation();
	const archiveContactMutation = trpc.contact.archiveContact.useMutation();
	const dearchiveContactMutation = trpc.contact.dearchiveContact.useMutation();

	const { data: contact } = trpc.contact.getContactById.useQuery({ id: params?.contactId });
	const [isArchived, setIsArchived] = React.useState(!!contact?.archivedAt);

	const archivePanel = () => {
		return (
			<Panel
				key="archive"
				title={intl.formatMessage({ id: "CONTACTS.DETAIL.SETTINGS.ARCHIVE.TITLE" })}
				className={undefined}
				isLoading={undefined}
			>
				<div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
					<p style={{ maxWidth: "90ch" }}>
						<FormattedMessage id="CONTACTS.DETAIL.SETTINGS.ARCHIVE.DESCRIPTION" />
					</p>
					<Button
						type="primary"
						onClick={() => {
							archiveContactMutation.mutate(
								{ contactId: params?.contactId },
								{
									onSuccess: () => {
										trpcUtils.contact.getContactById.invalidate();
										trpcUtils.contact.getAllContacts.invalidate();
										setIsArchived(true);

										ShowSuccessBanner({
											title: intl.formatMessage({
												id: "CONTACTS.DETAIL.SETTINGS.ARCHIVE.SUCCESS",
												defaultMessage: "Contact successfully archived",
											}),
										});
									},
									onError: () => {
										ShowFailedBanner({
											title: intl.formatMessage({
												id: "CONTACTS.DETAIL.SETTINGS.ARCHIVE.FAILED",
												defaultMessage: "Failed to archive contact",
											}),
										});
									},
								}
							);
						}}
					>
						<FormattedMessage id="CONTACTS.DETAIL.SETTINGS.ARCHIVE.BUTTON" />
					</Button>
				</div>
			</Panel>
		);
	};

	const deArchivePanel = () => {
		return (
			<Panel
				key="archive"
				title={intl.formatMessage({ id: "CONTACTS.DETAIL.SETTINGS.ARCHIVED.TITLE" })}
				className={undefined}
				isLoading={undefined}
			>
				<div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
					<p style={{ maxWidth: "90ch" }}>
						<FormattedMessage id="CONTACTS.DETAIL.SETTINGS.ARCHIVED.DESCRIPTION" />
					</p>
					<Button
						type="primary"
						onClick={() => {
							dearchiveContactMutation.mutate(
								{ contactId: params?.contactId },
								{
									onSuccess: () => {
										trpcUtils.contact.getContactById.invalidate();
										trpcUtils.contact.getAllContacts.invalidate();
										setIsArchived(false);

										ShowSuccessBanner({
											title: intl.formatMessage({
												id: "CONTACTS.DETAIL.SETTINGS.DEARCHIVE.SUCCESS",
												defaultMessage: "Contact successfully dearchived",
											}),
										});
									},
									onError: () => {
										ShowFailedBanner({
											title: intl.formatMessage({
												id: "CONTACTS.DETAIL.SETTINGS.DEARCHIVE.FAILED",
												defaultMessage: "Failed to dearchive contact",
											}),
										});
									},
								}
							);
						}}
					>
						<FormattedMessage id="CONTACTS.DETAIL.SETTINGS.ARCHIVED.BUTTON" />
					</Button>
				</div>
			</Panel>
		);
	};

	const handleRestore = async () => {
		try {
			await restoreContactMutation.mutateAsync({ contactId: params?.contactId });

			trpcUtils.contact.getContactById.invalidate();
			trpcUtils.contact.getAllContacts.invalidate();
			ShowSuccessBanner({
				title: intl.formatMessage(
					{
						id: "contacts.detail.settings.restore.succeeded",
						defaultMessage: "Successfully restored {fullName}",
					},
					{ fullName: contact?.fullName }
				),
				intl,
			});
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage(
					{
						id: "contacts.detail.settings.restore.failed",
						defaultMessage: "Failed to restore {fullName}. Try again later",
					},
					{ fullName: contact?.fullName }
				),
				intl,
			});
		}
	};

	return (
		<main className="frs-overview">
			<Collapse expandIconPosition="end" defaultActiveKey={["archive"]}>
				{isArchived ? deArchivePanel() : archivePanel()}

				{contact && contact.deletedAt && (
					<Panel
						key="restore"
						title={intl.formatMessage({
							id: "contacts.detail.settings.restore.title",
							defaultMessage: "Restore contact",
						})}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
								alignItems: "center",
							}}
						>
							<div>
								<p style={{ maxWidth: "90ch" }}>
									<FormattedMessage
										id="contacts.detail.settings.restore.description"
										defaultMessage="This contact is deleted and will be definitely delete within <bold>{days, plural, =0 {Today} one {{days} day} other {{days} days}}</bold> if you don't restore this contact before it. If this contact is not deleted from external resources it's possible it will be re-added when it changes in that resource after it's been definitely deleted from Bothive"
										values={{
											days: calculateDeleteDate(contact.deletedAt),
											bold: (child) => <strong>{child}</strong>,
										}}
										description="Explain to users that a contact that has been hard deleted from our database can be re-added if it's changed and still active in external integrations like AdminPulse"
									/>
								</p>
							</div>
							<Button type="primary" onClick={handleRestore}>
								<FormattedMessage
									id="contacts.detail.settings.restore.button"
									defaultMessage="Restore contact"
								/>
							</Button>
						</div>
						<Typography.Paragraph style={{ marginBottom: 0 }} italic className="t-gap--top">
							<Typography.Text>
								<FormattedMessage
									id="contacts.detail.settings.restore.extra.title"
									defaultMessage="When a contact is deleted, all this data will be deleted as well:"
								/>
							</Typography.Text>
							<ul style={{ marginBottom: 0 }}>
								<li>
									<FormattedMessage
										id="contacts.detail.settings.restore.extra.reports"
										defaultMessage="All reports for this contact"
									/>
								</li>
								<li>
									<FormattedMessage
										id="contacts.detail.settings.restore.extra.files"
										defaultMessage="All files uploaded for this contact"
									/>
								</li>
								<li>
									<FormattedMessage
										id="contacts.detail.settings.restore.extra.linked_contacts"
										defaultMessage="All linked contacts"
									/>
								</li>
								{/* TODO: Add conversations when inbox is released */}
							</ul>
						</Typography.Paragraph>
					</Panel>
				)}
				{!contact?.deletedAt && (
					<DestructivePanel
						title="CONTACT_DETAILS.SETTINGS.DANGER_ZONE.ACCORDION_TITLE"
						panelKey={undefined}
						isActive={undefined}
						onItemClick={undefined}
					>
						<Section.ODestructive
							title="CONTACT_DETAILS.SETTINGS.DANGER_ZONE.TITLE"
							description="CONTACT_DETAILS.SETTINGS.DANGER_ZONE.DESCRIPTION"
							buttonLabel="CONTACT_DETAILS.SETTINGS.DANGER_ZONE.BUTTON"
							onClick={() => setShowDeleteModal(true)}
						/>
					</DestructivePanel>
				)}
			</Collapse>
			<DeleteModal
				title={intl.formatMessage({
					id: "CONTACT_DETAILS.SETTINGS.DANGER_ZONE.ACCORDION_TITLE",
					defaultMessage: "Remove Contact",
				})}
				deleteLabel={intl.formatMessage({
					id: "CONTACT_DETAILS.SETTINGS.DANGER_ZONE.BUTTON",
					defaultMessage: "Remove Contact",
				})}
				description={intl.formatMessage({
					id: "CONTACT_DETAILS.SETTINGS.DANGER_ZONE.DESCRIPTION",
					defaultMessage:
						"Are you sure you want to delete this contact? All documents, reports and data linked to this contact will be permanently deleted.",
				})}
				isVisible={showDeleteModal}
				onDelete={() => {
					deleteContactMutation.mutate(
						{ contactId: params?.contactId },
						{
							onSuccess: () => {
								ShowSuccessBanner({
									title: "BANNER.SUCCEEDED.DELETE_CONTACT",
									defaultMessage: "Contact is successfully deleted",
								});

								trpcUtils.contact.getAllContacts.invalidate();

								history.push(routesConfig.dashboard.contact.overview.replace(":team", team?.slug));
							},
							onError: () => {
								//TODO VERANDEREN NAAR SHOWFAILEDBANNER
								ShowSuccessBanner({
									title: "BANNER.FAILED.DELETE_CONTACT",
									intl,
								});
							},
						}
					);
				}}
				onClose={() => setShowDeleteModal(false)}
			/>
		</main>
	);
}
