import { Space } from "antd";
import { useParams } from "react-router-dom";

import { typesConfig } from "@/flows";
import { compareReportProgress } from "@/flows/common/helper";
import Actions from "@/flows/report_detail/meta_aside/actions";

import AssigneeSection from "./assignee";
import Automation from "./automation";
import ReportInfoSection from "./report_info";
import Status from "./status";
import FilesOverview from "./files";
import style from "./style.module.scss";
import ReminderStats from "./reminder_stats";
import { trpc } from "@/trpc";

export default function MetaAside() {
	const routerParams = useParams<{ reportId: string; flowId: string }>();
	const reportId = routerParams.reportId;

	const { data: report, isLoading } = trpc.report.getById.useQuery({ reportId }, { enabled: !!reportId });
	const isReportInProgress = compareReportProgress({
		report: report,
		progress: typesConfig.progressState.inviteSend,
	});

	return (
		<aside className={style.meta_aside}>
			<Space direction="vertical" className={style.meta_aside_space}>
				<div className={style.meta_aside_group}>
					<Status />
					<AssigneeSection />
				</div>
				<ReportInfoSection report={report} isLoading={isLoading} className="t-gap--top t-gap--bottom" />
				{!!report?.flowReminderQueueHistory?.length && <ReminderStats report={report} />}
				<Automation isReportInProgress={isReportInProgress} />
				<FilesOverview report={report} />
				<Actions reportId={reportId} isReportInProgress={isReportInProgress} />
			</Space>
		</aside>
	);
}
