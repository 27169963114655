import axios from "axios";

import { apiConfig } from "../../../../config";
import { queryStringHelpers, stringHelpers, timerHelpers } from "../../../../helpers";
import store from "../../../store";
import actionTypes from "./flow.actionTypes";

function startFetchWatcher(dispatch) {
	timerHelpers.startTimer();
	timerHelpers.watchTimeout(() => {
		dispatch(fetchingFailed());
		return;
	});

	dispatch(isFetching());
}

function startFetchWatcherDetail(dispatch) {
	timerHelpers.startTimer();
	timerHelpers.watchTimeout(() => {
		dispatch(fetchingFailed());
		return;
	});

	dispatch(isFetchingDetail());
}

export const isFetching = () => (dispatch) => {
	dispatch({
		type: actionTypes.FLOW_IS_FETCHING,
	});
};
export const isFetchingDetail = () => (dispatch) => {
	dispatch({
		type: actionTypes.FLOW_DETAIL_IS_FETCHING,
	});
};
export const fetchingFailed = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCH_FLOW_FAILED,
	});
};
export const fetchingSucceeded = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCH_FLOW_SUCCEEDED,
	});
};

export const hideFlowWarning = () => (dispatch) => {
	dispatch({
		type: actionTypes.HIDE_FLOW_WARNING,
	});
};

export const showFlowWarning = ({ title, description, values }) => (dispatch) => {
	dispatch({
		type: actionTypes.SHOW_FLOW_WARNING,
		data: {
			title,
			description,
			values,
		},
	});
};

export const fetchFlows = ({ limit, offset, search } = {}) => async (dispatch) => {
	startFetchWatcher(dispatch);

	try {
		const flows = store.getState().content.flow.flow;
		const searchValue = stringHelpers.isEmpty(search) ? search : flows.filter.search;
		const limitValue = limit !== undefined ? limit : flows.filter.limit;
		const offsetValue = offset !== undefined ? offset : flows.filter.offset;
		const query = queryStringHelpers.createQueryString({
			limit: limitValue,
			offset: offsetValue,
			q: searchValue,
		});
		const result = await axios.get(`${apiConfig.flow.getFlows}${query}`);

		if (result.status !== 200 || !result.data.success) {
			throw new Error();
		}

		dispatch({
			type: actionTypes.FETCH_FLOWS,
			data: {
				flows: result.data.data,
				filter: result.data.meta,
			},
		});

		dispatch(fetchingSucceeded());
		timerHelpers.clearTimer();
	} catch (error) {
		dispatch(fetchingFailed());
		timerHelpers.clearTimer();
	}
};

export const fetchFlowById = (id) => async (dispatch) => {
	const flowState = store.getState().content.flow.flow;

	if (flowState.selected?.id === id) {
		dispatch({ type: actionTypes.FLOW_ALREADY_FETCHED });
		return;
	}

	startFetchWatcherDetail(dispatch);

	try {
		const result = await axios.get(apiConfig.flow.getById.replace(":id", id));

		if (result.status !== 200 || !result.data.success) {
			throw new Error();
		}

		dispatch({
			type: actionTypes.FETCH_FLOW_DETAILS,
			data: result.data.data,
		});
		dispatch(fetchingSucceeded());

		timerHelpers.clearTimer();
	} catch (error) {
		dispatch(fetchingFailed());
		timerHelpers.clearTimer();
	}
};
