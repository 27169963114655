import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { $createParagraphNode, $getRoot, $insertNodes, LexicalEditor } from "lexical";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import { TOnImageUpload } from "../nodes/image_node/imageNode";
import { TableCellNodes } from "../nodes/table_node/tableCellNodes";
import {
	AutoLinkPlugin,
	CodeHighlightPlugin,
	DragDropPaste,
	ImagesPlugin,
	KeywordsPlugin,
	ListMaxIndentLevelPlugin,
	TablePlugin as NewTablePlugin,
	SignaturePlugin,
	TableActionMenuPlugin,
	TableCellResizerPlugin,
	ThreadPlugin,
} from "../plugin";
import { htmlTheme } from "../theme";
import ToolbarPlugin from "./toolbarPlugin";
import { OptionTools } from "./types";

function Placeholder({ text }: { text?: MessageDescriptor }) {
	if (!text) return null;

	return (
		<div className="html_editor-placeholder">
			<FormattedMessage {...text} />
		</div>
	);
}

interface I18nConfig {
	placeholder?: MessageDescriptor;
}

interface IEditor {
	value?: string;
	i18n?: I18nConfig;
	channelId?: string;
	className?: string;
	tools?: OptionTools[];
	onChange?: (value: string) => void;
	onImageUpload?: TOnImageUpload;
	onFileUpload?: (params: { file: File }) => void;
	onImageDelete?: (fileId: string) => void;
	editorRef?: MutableRefObject<LexicalEditor | undefined>;
}

export default function Editor({
	value,
	channelId,
	i18n,
	editorRef,
	onFileUpload,
	onImageUpload,
	onImageDelete,
	onChange,
	tools = [],
	className = "",
}: IEditor) {
	const [editor] = useLexicalComposerContext();
	const editorValue = useRef(value); // use this to keep current editor state without re-rendering editor
	const floatingAnchorElem = useRef<any>();
	const [isDragOver, setIsDragOver] = useState(false);

	const cellEditorConfig = {
		namespace: "htmlEditorCellEditor",
		nodes: TableCellNodes,
		onError: (error: Error) => {
			throw error;
		},
		htmlTheme,
	};

	const handleOnChange = () => {
		if (!onChange) return;

		editor.getEditorState().read(() => {
			const val = $generateHtmlFromNodes(editor, null);

			if (editorValue.current === val) return;

			editorValue.current = val;
			onChange(val);
		});
	};

	useEffect(() => {
		// if external value changes update editor
		if (editorValue.current === value) return;

		editor.update(() => {
			$getRoot().clear();

			const parser = new DOMParser();
			const dom = parser.parseFromString(value || "", "text/html");
			const nodes = $generateNodesFromDOM(editor, dom);

			try {
				$insertNodes(nodes);
			} catch (_) {
				try {
					const topNode = $createParagraphNode();

					$insertNodes([topNode, ...nodes]);
				} catch (error) {
					console.error("Insert table failed", error);
				}
			}
		});
	}, [value]);

	if (editorRef) editorRef.current = editor;

	return (
		<div className={`html_editor-container ${className}`}>
			<ToolbarPlugin channelId={channelId} tools={tools} />
			<div
				className="html_editor-inner"
				onDragEnter={() => setIsDragOver(true)}
				onDragOver={() => setIsDragOver(true)}
				onDrop={() => setIsDragOver(false)}
				onDragLeave={() => setIsDragOver(false)}
				onDragEnd={() => setIsDragOver(false)}
				onDragExit={() => setIsDragOver(false)}
				data-is-drag-over={isDragOver}
			>
				<RichTextPlugin
					contentEditable={
						<div ref={floatingAnchorElem} className="html_editor-input_wrapper">
							<ContentEditable className="html_editor-input" />
						</div>
					}
					placeholder={<Placeholder text={i18n?.placeholder} />}
					ErrorBoundary={LexicalErrorBoundary}
				/>
				{/* Plugin order is important */}
				{tools?.includes("signature") && <SignaturePlugin channelId={channelId} />}
				<ThreadPlugin />
				<LinkPlugin />
				<ListPlugin />
				<HistoryPlugin />
				<ImagesPlugin onImageUpload={onImageUpload} onImageDelete={onImageDelete} />
				<DragDropPaste onFileUpload={onFileUpload} />
				<AutoLinkPlugin />
				<KeywordsPlugin />
				<CodeHighlightPlugin />
				<HorizontalRulePlugin />
				{/* <TableCellResizerPlugin /> */}
				{floatingAnchorElem && (
					<TableActionMenuPlugin anchorElem={floatingAnchorElem.current} cellMerge={true} />
				)}
				<ListMaxIndentLevelPlugin maxDepth={10} />
				<OnChangePlugin onChange={handleOnChange} />
				<TablePlugin />
				<NewTablePlugin cellEditorConfig={cellEditorConfig}>
					<AutoFocusPlugin />
					<KeywordsPlugin />
					<RichTextPlugin
						placeholder={null}
						ErrorBoundary={LexicalErrorBoundary}
						contentEditable={<ContentEditable className="html_editor-input" />}
					/>
					<HistoryPlugin />
					<ImagesPlugin onImageUpload={onImageUpload} onImageDelete={onImageDelete} />
					<ListMaxIndentLevelPlugin maxDepth={10} />
					<HorizontalRulePlugin />
					<LinkPlugin />
					<ListPlugin />
				</NewTablePlugin>
			</div>
		</div>
	);
}
