import "../../style.scss";
import DashboardCard from "@/shared/components/card/dashboard";
import { PlusOutlined } from "@ant-design/icons";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Skeleton } from "antd";
import LocationCard from "@/modules/contacts/contact_detail/attributes/components/ContactAddresses/components/LocationCard";
import EditModal from "./EditModal";
import AddModal from "./AddModal";
import { IOrganization, IOrganizationAddress } from "@bothive_core/database";

export default function LocationSettings({
	allowEditing,
	organization,
	isLoading,
	className,
}: {
	allowEditing: boolean;
	isLoading: boolean;
	organization?: IOrganization;
	className?: string;
}) {
	const intl = useIntl();
	const [showAddModal, setShowAddModal] = useState(false);
	const [currentAddressId, setCurrentAddressId] = useState<string | undefined>(undefined);
	const selectedLocation = useMemo<IOrganizationAddress | undefined>(
		() => organization?.address?.find((address) => address._id === currentAddressId),
		[currentAddressId]
	);

	function generateForm() {
		if (organization && (!organization.address || organization.address.length === 0) && !allowEditing) {
			return (
				<p className="location-fallback">
					<FormattedMessage
						id="organization_settings.general.locations.missing_locations_permissions"
						defaultMessage="Your organization does not have any location(s) at this time. Please request someone with the necessary permissions to add them."
					/>
				</p>
			);
		}
		return (
			<Skeleton active loading={isLoading} className="t-gap--top">
				<div className="locations">
					{organization?.address?.map((address) => (
						<LocationCard
							key={`address${address._id}`}
							{...address}
							_id={address._id as string}
							primary={address.isPrimary}
							className="t-gap--top t-gap--right"
							onClick={() => setCurrentAddressId(address._id)}
							allowEditing={allowEditing}
						/>
					))}
					{allowEditing && (
						<Button
							className={
								organization?.address?.length === 0 || !organization?.address
									? "button spacingButton"
									: "button"
							}
							type="text"
							shape="default"
							icon={<PlusOutlined />}
							size="large"
							onClick={() => setShowAddModal(true)}
						>
							<span>
								<FormattedMessage
									id="organization_settings.general.locations.table.add_button"
									defaultMessage="Add location"
								/>
							</span>
						</Button>
					)}
					<EditModal
						organization={organization}
						allowEditing={allowEditing}
						currentAddressId={currentAddressId}
						setCurrentAddressId={setCurrentAddressId}
						selectedLocation={selectedLocation as IOrganizationAddress}
					/>

					<AddModal
						visible={showAddModal}
						setVisible={setShowAddModal}
						organization={organization}
						allowEditing={allowEditing}
					/>
				</div>
			</Skeleton>
		);
	}

	return (
		<DashboardCard
			className={className}
			title={intl.formatMessage({
				id: "organization_settings.general.locations.title",
				defaultMessage: "Location",
				description: "Section title for organizations addresses",
			})}
		>
			{generateForm()}
		</DashboardCard>
	);
}
