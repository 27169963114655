export default {
	SHOW_COPIED_TO_CLIPBOARD: "SHOW_COPIED_TO_CLIPBOARD",

	SHOW_SETTINGS_COPIED_TO_CLIPBOARD: "SHOW_SETTINGS_COPIED_TO_CLIPBOARD",

	SHOW_CREDIT_CARD_INVALID: "SHOW_CREDIT_CARD_INVALID",

	SHOW_KEY_BANNER: "SHOW_KEY_BANNER",
	HIDE_KEY_BANNER: "HIDE_KEY_BANNER",

	SHOW_FAILED_MESSAGE: "SHOW_FAILED_MESSAGE",
	SHOW_SUCCESS_MESSAGE: "SHOW_SUCCESS_MESSAGE",
	SHOW_INFO_MESSAGE: "SHOW_INFO_MESSAGE",
	HIDE_BANNER: "HIDE_BANNER",

	SHOW_PROCESSING_BANNER: "SHOW_PROCESSING_BANNER",
	HIDE_PROCESSING_BANNER: "HIDE_PROCESSING_BANNER",
};
