import "./style.scss";

import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isAtNodeEnd } from "@lexical/selection";
import { mergeRegister } from "@lexical/utils";
import { TableSelection } from "@lexical/table";
import { Button, Input, InputRef, Space } from "antd";
import {
	$getSelection,
	$isRangeSelection,
	BaseSelection,
	LexicalEditor,
	NodeSelection,
	RangeSelection,
	SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

function getSelectedNode(selection) {
	const anchor = selection.anchor;
	const focus = selection.focus;
	const anchorNode = selection.anchor.getNode();
	const focusNode = selection.focus.getNode();

	if (anchorNode === focusNode) return anchorNode;

	const isBackward = selection.isBackward();

	if (isBackward) return $isAtNodeEnd(focus) ? anchorNode : focusNode;

	return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
}

function positionEditorElement(editor, rect) {
	if (rect === null) {
		editor.style.opacity = "0";
		editor.style.top = "-1000px";
		editor.style.left = "-1000px";
		return;
	}

	editor.style.opacity = "1";
	editor.style.top = `${rect.top + rect.height + window.scrollY + 10}px`;
	editor.style.left = `${rect.left + window.scrollX - editor.offsetWidth / 2 + rect.width / 2}px`;
}

interface IFloatingLinkEditor {
	editor: LexicalEditor;
	className?: string;
}

export function FloatingLinkEditor({ editor, className = "" }: IFloatingLinkEditor) {
	const intl = useIntl();
	const mouseDownRef = useRef(false);
	const [linkUrl, setLinkUrl] = useState("");
	const editorRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<InputRef>(null);
	const [isEditMode, setEditMode] = useState(false);
	const [lastSelection, setLastSelection] = useState<
		BaseSelection | RangeSelection | NodeSelection | TableSelection | null
	>(null);

	const updateLinkEditor = useCallback(() => {
		const selection = $getSelection();

		if ($isRangeSelection(selection)) {
			const node = getSelectedNode(selection);
			const parent = node.getParent();

			if ($isLinkNode(parent)) {
				setLinkUrl(parent.getURL());
			} else if ($isLinkNode(node)) {
				setLinkUrl(node.getURL());
			} else {
				setLinkUrl("");
			}
		}

		const editorElem = editorRef.current;
		const nativeSelection = window.getSelection();
		const activeElement = document.activeElement;

		if (editorElem === null) return;

		const rootElement = editor.getRootElement();

		if (
			nativeSelection &&
			selection !== null &&
			rootElement !== null &&
			!nativeSelection.isCollapsed &&
			rootElement.contains(nativeSelection.anchorNode)
		) {
			let rect;
			const domRange = nativeSelection.getRangeAt(0);

			if (nativeSelection.anchorNode === rootElement) {
				let inner = rootElement;

				while (inner.firstElementChild != null) {
					// @ts-ignore
					inner = inner.firstElementChild;
				}

				rect = inner.getBoundingClientRect();
			} else {
				rect = domRange.getBoundingClientRect();
			}

			if (!mouseDownRef.current) positionEditorElement(editorElem, rect);

			setLastSelection(selection);
		} else if (!activeElement || activeElement.className !== "link-input") {
			positionEditorElement(editorElem, null);
			setLastSelection(null);
			setEditMode(false);
			setLinkUrl("");
		}

		return true;
	}, [editor]);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => editorState.read(() => updateLinkEditor())),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				() => {
					updateLinkEditor();
					return true;
				},
				1
			)
		);
	}, [editor, updateLinkEditor]);

	useEffect(() => {
		editor.getEditorState().read(() => updateLinkEditor());
	}, [editor, updateLinkEditor]);

	useEffect(() => {
		if (isEditMode && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isEditMode]);

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault();

			if (lastSelection !== null) {
				if (linkUrl !== "") editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);

				setEditMode(false);
			}

			return;
		}

		if (event.key === "Escape") {
			event.preventDefault();
			setEditMode(false);
			return;
		}
	};

	const handleSaveLink = () => {
		if (linkUrl !== "") editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);

		setEditMode(false);
	};

	if (isEditMode) {
		return (
			<div ref={editorRef} className={`editor-component-floating_link_editor ${className}`}>
				<Space.Compact>
					<Input
						ref={inputRef}
						value={linkUrl}
						onKeyDown={handleKeyDown}
						onChange={(event) => setLinkUrl(event.target.value)}
						className="floating_link_editor-input"
					/>
					<Button
						type="primary"
						icon={<SaveOutlined />}
						onClick={handleSaveLink}
						aria-label={intl.formatMessage({
							id: "editor.toolbar.link.editor_modal.save_button.aria_label",
							defaultMessage: "Save link",
						})}
					/>
				</Space.Compact>
			</div>
		);
	}

	return (
		<div ref={editorRef} className={`editor-component-floating_link_editor ${className}`}>
			<a href={linkUrl} target="_blank" rel="noopener noreferrer">
				{linkUrl}
			</a>
			<Button
				type="text"
				size="small"
				icon={<EditOutlined />}
				onClick={() => setEditMode(true)}
				aria-label={intl.formatMessage({
					id: "editor.toolbar.link.editor_modal.edit_button.aria_label",
					defaultMessage: "Edit link",
				})}
			/>
		</div>
	);
}
