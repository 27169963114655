import "./style.scss";

import { Drawer, Steps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, MessageDescriptor, defineMessages, useIntl } from "react-intl";

import { Image } from "../../../components/atoms";
import AdminPulseImport from "./AdminPulseImport";
import CsvImport from "./CsvImport";
import { StepsTypes } from "./types";

type ContactImportParams = {
	open: boolean;
	integrationId?: "csv" | string;
	onClose: () => void;
};

const adminPulseId = "5cdc7149e0f7a731c8b449ca";

const stepsConfig = {
	csv: defineMessages<StepsTypes>({
		GET_DATA: {
			id: "contact_import.steps.csv.get_data",
			defaultMessage: "File upload",
		},
		MAP_FILES: {
			id: "contact_import.steps.csv.map_files",
			defaultMessage: "Map files",
		},
		REVIEW_IMPORT: {
			id: "contact_import.steps.csv.import_preview",
			defaultMessage: "Import preview",
		},
	}),
	[adminPulseId]: defineMessages({
		GET_DATA: {
			id: "contact_import.steps.integration.preview_import",
			defaultMessage: "Import preview",
		},
		REVIEW_IMPORT: {
			id: "contact_import.steps.integration.analyze",
			defaultMessage: "Analyze preview",
		},
	}),
};

export default function ContactImport({ open, integrationId, onClose }: ContactImportParams) {
	const intl = useIntl();
	const [currentStep, setCurrentStep] = useState<StepsTypes>("GET_DATA");

	const steps = stepsConfig[integrationId || "csv"];

	const currentStepIndex = useMemo(() => Object.keys(steps).findIndex((key) => key === currentStep), [currentStep]);

	useEffect(() => setCurrentStep("GET_DATA"), [open]);

	const handleStepClick = (stepIndex: number) => {
		if (currentStepIndex < stepIndex) return;

		setCurrentStep(Object.keys(steps)[stepIndex] as StepsTypes);
	};

	return (
		<Drawer
			open={open}
			width="100vw"
			placement="left"
			onClose={onClose}
			className="contact_import"
			classNames={{ header: "contact_import-header" }}
			extra={
				<div className="contact_import-header-left_wrapper">
					<Image.ALogoRelative className="contact_import_logo" />
					<p>
						<FormattedMessage id="contact_import.title" defaultMessage="Contact import" />
					</p>
				</div>
			}
		>
			<div className="contact_import-body">
				<div className="contact_import-steps">
					<Steps
						size="small"
						direction="vertical"
						onChange={handleStepClick}
						current={currentStepIndex}
						items={Object.values(steps).map((title) => ({
							title: intl.formatMessage(title as MessageDescriptor),
						}))}
					/>
				</div>
				<div className="contact_import-content">
					{integrationId === "csv" && (
						<CsvImport step={currentStep} onChange={setCurrentStep} onClose={onClose} />
					)}
					{integrationId === adminPulseId && (
						<AdminPulseImport step={currentStep} onChange={setCurrentStep} onClose={onClose} />
					)}
				</div>
			</div>
		</Drawer>
	);
}
