import { defineMessages } from "react-intl";

export const modalTitle = defineMessages({
	create: {
		id: "settings.email_signature.modal.create.title",
		defaultMessage: "Create email signature",
	},
	update: {
		id: "settings.email_signature.modal.update.title",
		defaultMessage: "Update email signature",
	},
});

export const modalSubmitButton = defineMessages({
	create: {
		id: "settings.email_signature.modal.create.button.submit",
		defaultMessage: "Create",
	},
	update: {
		id: "settings.email_signature.modal.update.button.submit",
		defaultMessage: "Update",
	},
});
