import "./style.scss";

import { Button, Typography } from "antd";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { setupArrow } from "../../../assets/images/icon";
import ShowFailedBanner from "@/shared/components/banner/failed";
import { trpc } from "../../../trpc";
import FlowCard from "./card";
import { IFlowTemplate } from "@bothive_core/database";
import SetupSteps from "../components/SetupSteps";
import AsidePreview from "./asidePreview";

export default function Card() {
	const intl = useIntl();
	const history = useHistory();
	const routerParams = useParams<{ slug: string }>();
	const [selectedFlows, setSelectedFlows] = useState<string[]>([]);

	const { data: organization } = trpc.organization.getOrganization.useQuery();
	const { data: flows } = trpc.flow.getOrganizationFlows.useQuery({ limit: 100, compact: true });
	const { data } = trpc.flowTemplate.getTemplates.useQuery({ freemium: true });
	const createBatch = trpc.flow.createBatch.useMutation();
	const existingFlows = useMemo(() => flows?.data?.map((flow) => flow.template._id) || [], [flows?.data]);

	const handleSelectFlow = (id: string) => {
		setSelectedFlows((prevList) => {
			if (prevList.includes(id)) return prevList.filter((item) => item !== id);

			return [...prevList, id];
		});
	};

	const handleCreateFlows = async () => {
		try {
			await createBatch.mutateAsync({ templateIds: selectedFlows });

			history.push(`/${routerParams.slug}/setup/flow-setup`);
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "setup.flows.create_flows.failed",
					defaultMessage: "Could not create the flows, please try again later.",
				}),
				intl,
			});
		}
	};

	const handleGoToNext = () => {
		history.push(`/${routerParams.slug}`);
	};

	const previewList = [...selectedFlows, ...existingFlows].reduce<IFlowTemplate[]>((prev, flowId) => {
		const template = data?.data?.find((flow) => flow._id === flowId);

		return template ? [...prev, template] : prev;
	}, []);

	return (
		<main className="setup">
			<section className="setup-body">
				<SetupSteps currentStep={2} />
				<article className="setup_content">
					<header className="setup_content-header">
						<Typography.Title>
							<FormattedMessage id="setup.flows.title" defaultMessage="Enable your first flows" />
						</Typography.Title>
						<p>
							<FormattedMessage
								id="setup.flows.description"
								defaultMessage="At Bothive you get a range of free flows that you can send to your customers. click on which one you want to create."
							/>
						</p>
					</header>
					<div className="setup-flow_setup-grid">
						{data?.data?.map((item: IFlowTemplate) => (
							<FlowCard
								{...item}
								id={item._id}
								key={item._id}
								name={item.name?.[intl.locale]}
								shortDescription={item.shortDescription?.[intl.locale]}
								selected={selectedFlows.includes(item._id) || existingFlows.includes(item._id)}
								disabled={existingFlows.includes(item._id)}
								onClick={handleSelectFlow}
							/>
						))}
					</div>
					<footer className="setup_content-footer">
						<Button
							size="large"
							type="primary"
							disabled={!selectedFlows.length && !flows?.meta?.total}
							onClick={handleCreateFlows}
							className="setup_content-footer-button"
						>
							{!selectedFlows.length && !!flows?.meta?.total ? (
								<FormattedMessage
									id="setup.flows.button.next_step"
									defaultMessage="Next step"
									description="Organization has already some flows but hasn't selected any"
								/>
							) : (
								<FormattedMessage
									id="setup.flows.button.create"
									defaultMessage="{value, plural, =0 {Select a flow above} =1 {Create # flow} other {Create # flows}}"
									values={{ value: selectedFlows.length }}
								/>
							)}
						</Button>
						<Button
							type="text"
							size="large"
							onClick={handleGoToNext}
							className="setup_content-footer-button"
						>
							<FormattedMessage
								id="setup.flows.button.go_to_organization"
								defaultMessage="Skip and finish setup"
								description="Skip the last step (flow setup) and redirected the user to their organization"
							/>
						</Button>
					</footer>
				</article>
			</section>
			<section className="setup_aside">
				<header className="setup_aside-header">
					<p className="setup_aside-header-title">
						<FormattedMessage
							id="setup.flows.aside.title"
							defaultMessage="Put flows directly on your website"
						/>
					</p>
					<img src={setupArrow} alt="" className="setup_aside-header-icon" />
				</header>
				<AsidePreview list={previewList || []} brandColor={organization?.branding?.brandColor} />
				<p className="setup_aside-help">
					<FormattedMessage
						id="setup.flows.aside.help"
						defaultMessage="This is an example on how your web-developer could implement it on your website"
					/>
				</p>
			</section>
		</main>
	);
}
