export default {
	title: "SETTINGS.NOTIFICATIONS.BILLING.TITLE",
	fieldConfig: {
		email: {
			label: "SETTINGS.NOTIFICATIONS.BILLING.FORM.EMAIL.LABEL",
			placeholder: "SETTINGS.NOTIFICATIONS.BILLING.FORM.EMAIL.PLACEHOLDER",
			emailError: "SETTINGS.NOTIFICATIONS.BILLING.FORM.EMAIL.EMAIL_ERROR",
		},
	},
};
