import "./style.scss";

import { MessageRouterOutput } from "@/trpc";
import {
	SwapOutlined,
	TagOutlined,
	UserAddOutlined,
	UserDeleteOutlined,
	UserSwitchOutlined,
	UsergroupAddOutlined,
	UsergroupDeleteOutlined,
} from "@ant-design/icons";
import { IMessage, AuditMessage as AuditMessageType, AuditMessageAction } from "@bothive_core/database";
import { ReactNode } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { HTMLMotionProps, motion } from "framer-motion";

import { dateHelpers } from "@/shared/helpers";
import config from "../../../../../config/statusFilters.config";
import stringHelpers from "@/shared/helpers/string.helpers";
import { Typography } from "antd";

const auditMessagesIntlMapping: Record<AuditMessageAction, { id: string; defaultMessage: string; icon?: ReactNode }> =
	defineMessages({
		add_participant: {
			id: "inbox.conversation.message.audit.add_participant",
			defaultMessage:
				"{actor} <b>added</b> {mutatedEntities} as <b>{mutatedEntitiesCount, plural, =1 {participant} other {participants}}</b>",
			icon: <UsergroupAddOutlined />,
		},
		remove_participant: {
			id: "inbox.conversation.message.audit.remove_participant",
			defaultMessage:
				"{actor} <b>removed</b> {mutatedEntities} as <b>{mutatedEntitiesCount, plural, =1 {participant} other {participants}}</b>",
			icon: <UsergroupDeleteOutlined />,
		},
		assign: {
			id: "inbox.conversation.message.audit.assign",
			defaultMessage: "{actor} <b>assigned</b> {mutatedEntities}",
			icon: <UserAddOutlined />,
		},
		unassign: {
			id: "inbox.conversation.message.audit.unassign",
			defaultMessage: "{actor} <b>unassigned</b> {mutatedEntities}",
			icon: <UserDeleteOutlined />,
		},
		swap_assignment: {
			id: "inbox.conversation.message.audit.swap_assignment",
			defaultMessage: "{actor} <b>changed assigned</b> user <b>from</b> {from} <b>to</b> {to}",
			icon: <UserSwitchOutlined />,
		},
		mark_participant_label: {
			id: "inbox.conversation.message.audit.mark_participant_label",
			defaultMessage: "{actor} <b>moved</b> conversation <b>to</b> {mutatedEntities}",
			icon: <SwapOutlined />,
		},
		unmarked_participant_label: {
			id: "inbox.conversation.message.audit.unmarked_participant_label",
			defaultMessage: "{actor} <b>removed</b> conversation <b>from</b> {mutatedEntities}",
			icon: <SwapOutlined />,
		},
		add_inbox_label: {
			id: "inbox.conversation.message.audit.add_inbox_label",
			defaultMessage: "{actor} <b>added label</b> {mutatedEntities}",
			icon: <TagOutlined />,
		},
		remove_inbox_label: {
			id: "inbox.conversation.message.audit.remove_inbox_label",
			defaultMessage: "{actor} <b>removed label</b> {mutatedEntities}",
			icon: <TagOutlined />,
		},
	});

export default function AuditMessage({
	action,
	authorData,
	entity,
	mutatedEntitiesDetails,
	createdAt,
	...props
}: MessageRouterOutput["allByConversationId"][number] & IMessage<AuditMessageType> & HTMLMotionProps<"article">) {
	const intl = useIntl();
	const conjunctionFormatter = new Intl.ListFormat(intl.locale, { style: "long", type: "conjunction" });

	function formatMutatedEntities() {
		if (entity === "participant_label") {
			return conjunctionFormatter.format(
				// TODO: fix ts ignore
				// @ts-ignore
				mutatedEntitiesDetails.map((key) => intl.formatMessage(config[key.toLowerCase()].label))
			);
		}

		return conjunctionFormatter.format(
			mutatedEntitiesDetails.map((entity) => stringHelpers.truncateString({ string: entity, maxLength: 25 }))
		);
	}

	return (
		<motion.article className="aum-audit-message" {...props}>
			<p style={{ fontSize: "0.6em", color: "var(--grey)", textAlign: "center" }}>
				{dateHelpers.getRelativeDate({ date: createdAt, alwaysIncludeTime: true, locale: intl.locale })}
			</p>
			<span style={{ display: "flex", gap: "var(--spacer-xs)", alignItems: "center" }}>
				<Typography.Text style={{ textAlign: "center", maxWidth: "var(--width-prose)" }}>
					<span style={{ marginRight: "var(--spacer-xs)" }}>{auditMessagesIntlMapping[action].icon}</span>
					<FormattedMessage
						{...auditMessagesIntlMapping[action]}
						values={{
							actor: authorData.author.displayName,
							mutatedEntities: formatMutatedEntities(),
							...(action === "swap_assignment" && {
								from: String(
									stringHelpers.truncateString({ string: mutatedEntitiesDetails[0], maxLength: 25 })
								),
								to: String(
									stringHelpers.truncateString({ string: mutatedEntitiesDetails[1], maxLength: 25 })
								),
							}),
							mutatedEntitiesCount: mutatedEntitiesDetails.length,
							b: (content) => <strong style={{ fontWeight: 700 }}>{content}</strong>,
						}}
					/>
				</Typography.Text>
			</span>
		</motion.article>
	);
}
