import type { IApTaskUpdateAuditLogDocument, TFlowActions } from "@bothive_core/database";
import { defineMessages } from "react-intl";

export const ApTaskTypes = defineMessages<IApTaskUpdateAuditLogDocument["meta"]["type"]>({
	SUCCESS: { id: "audit_logs.success", defaultMessage: "Succeeded" },
	FAILED: { id: "audit_logs.failed", defaultMessage: "Failed" },
	RELATION_NOT_FOUND: { id: "audit_logs.relation_not_found", defaultMessage: "Relation not found" },
	NO_TASKS_FOUND: { id: "audit_logs.no_tasks_found", defaultMessage: "Task not found" },
	NO_SUB_TASKS_FOUND: { id: "audit_logs.no_sub_tasks_found", defaultMessage: "Sub task not found" },
});
