import React from "react";
import { Tag } from "antd";

import { Dropdown } from "../../atoms";

export default class MTagDropdownInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			field: "",
			value: props.value || [],
			error: props.error,
		};

		this.bindHandles();
	}

	bindHandles() {
		this.findItem = this.findItem.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		const error = state.error !== props.error ? props.error : state.error;
		const value = state.value !== props.value ? props.value || [] : state.value;

		return { value, error };
	}

	findItem(uuid) {
		const result = this.props.items.find((item) => item.uuid === uuid);

		return result ? result.value : "";
	}

	handleChange(value) {
		let valid = true;

		if (this.props.validator) {
			valid = this.props.validator(value);
		}

		if (this.state.value.find((valueUuid) => valueUuid === value) || !valid) {
			this.setState({ error: true, field: undefined });
			return;
		}

		if (value) {
			this.setState((prev) => {
				const result = [...prev.value, value];

				if (this.props.onChange) {
					this.props.onChange(result);
				}

				return { value: result, error: false, field: undefined };
			});
		}
	}

	handleRemove(name) {
		this.setState((prev) => {
			const value = [...prev.value.filter((item) => item !== name)];

			if (this.props.onChange) {
				this.props.onChange(value);
			}

			return { value };
		});
	}

	buildHeader({ searchable, ...props }) {
		if (searchable) {
			return <Dropdown.ASearchable {...props} />;
		}

		return <Dropdown.ADefault {...props} />;
	}

	render() {
		let chipList,
			props = { ...this.props };

		delete props.validator;

		if (this.state.value && this.state.value.length && this.props.items && this.props.items.length) {
			chipList = (
				<ul className="m-list m-list--horizontal m-input--tag-list m-list--wrap">
					{this.state.value.map((valueUuid) => (
						<li key={valueUuid} className="m-list--horizontal-item ">
							<Tag closeIcon={!this.props.disabled} onClose={() => this.handleRemove(valueUuid)}>
								{this.findItem(valueUuid)}
							</Tag>
						</li>
					))}
				</ul>
			);
		}

		return (
			<div className={`m-input--tag ${this.props.disabled ? "disabled" : ""} ${this.props.className}`}>
				{this.buildHeader({
					...props,
					persistance: false,
					value: this.state.field,
					exclude: this.state.value,
					searchable: props.searchable,
					onChange: this.handleChange,
				})}
				{chipList}
			</div>
		);
	}
}

MTagDropdownInput.defaultProps = {
	value: [],
	items: [],
	error: false,
	searchable: false,
	className: "",
	validator: undefined,

	onChange: undefined,
};
