import "./style.scss";

import { INotification } from "@bothive_core/database";
import { Button, Card, Tooltip, Typography } from "antd";
import { ClockCircleOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { MouseEventHandler } from "react";

import { dateHelpers } from "../../../../../helpers";
import { routesConfig } from "../../../../../../config";
import { MarkdownText } from "../../../../text/markdown";
import { chipConfig } from "./config";
import Tag from "@/shared/components/antdWrappers/Tag";
import clsx from "clsx";
import { stringHelpers } from "@/shared/helpers";
import { NavLink, useHistory } from "react-router-dom";

interface INotificationItem {
	notification: INotification;
	onClick: (id: string) => void;
	onMarkAsReadUnRead: (props: { id: string; isRead: boolean }) => void;
	organizationSlug: string;
}
export default function NotificationItem({
	notification,
	onClick,
	onMarkAsReadUnRead,
	organizationSlug,
}: INotificationItem) {
	const intl = useIntl();
	const history = useHistory();
	const isTitleBold = !!notification.content.description;
	const universalReadStyle = notification.isRead ? { color: "var(--grey)" } : undefined;

	function generateBody() {
		if (notification.type === "reportBulkDownload" && notification.content.description) {
			const notificationContent = notification.content.description.match(
				/(?:\<([^\<\|@]+)\|)(?:([^\<\|\@]+)\>)|(?:\<([^\<\|\@]+)>)/
			);

			const today = new Date().toString();
			const isDatePast = dateHelpers.getDifference(notification.createdAt?.toString() as string, today) > 1;

			if (notificationContent?.[1] && notificationContent?.[2]) {
				return (
					<div className="list_item-body">
						<div className="list_item-body-action">
							<Typography.Text
								style={universalReadStyle}
								strong={isTitleBold}
								className="list_item-paragraph"
							>
								<MarkdownText value={notificationContent[2]} />
							</Typography.Text>
							<Tooltip
								title={intl.formatMessage({
									id: "notifications.list_item.report_download.button.tooltip",
									defaultMessage: "Download is expired",
									description: "Explain download link is expired",
								})}
							>
								<Button
									type="primary"
									href={notificationContent[1]}
									target="_blank"
									disabled={isDatePast}
									rel="noopener noreferrer"
								>
									<FormattedMessage
										id="notifications.list_item.download_button"
										defaultMessage="Download"
										description="Button inside notification to download bulk file"
									/>
								</Button>
							</Tooltip>
						</div>
						<Typography.Text style={universalReadStyle} italic className="list_item-paragraph">
							<FormattedMessage
								id="notifications.list_item.report_download.info"
								defaultMessage="Download will expire after 1 day"
								description="Explain download will only work for 1 day"
							/>
						</Typography.Text>
					</div>
				);
			}
		}

		return (
			<div className="list_item-body">
				<Typography.Text style={universalReadStyle} strong={isTitleBold} className="list_item-paragraph">
					<MarkdownText value={notification.content.title} />
				</Typography.Text>
				<Typography.Text style={universalReadStyle} className="list_item-paragraph">
					<MarkdownText
						value={stringHelpers.truncateString({
							string: notification.content.description || "",
							maxLength: 125,
						})}
					/>
				</Typography.Text>
				{((notification.type === "contact.import.succeeded" && notification.meta?.hasConflicts) ||
					notification.type === "contact.webhook.sync.failed") && (
					<Button
						style={{ marginTop: "var(--spacer-sm)" }}
						type="primary"
						onClick={() => {
							history.push(
								routesConfig.dashboard.contact.import.report
									.replace(":team", organizationSlug)
									.replace(":contactImportId", notification.meta?.contactImportId)
							);
						}} 
					>
						<FormattedMessage
							id="contact.import.report.notification.cta.link"
							defaultMessage="Go to conflicts"
						/>
					</Button>
				)}
			</div>
		);
	}

	const handleMarkAsReadUnread: MouseEventHandler<HTMLElement> = (event) => {
		event.stopPropagation();
		onMarkAsReadUnRead({ id: notification._id, isRead: !notification.isRead });
	};

	return (
		<Card className="notification-list_item" hoverable onClick={() => onClick(notification._id)}>
			<header className="list_item-header">
				<time className={clsx("list_item-header-time", !notification.isRead && "is-unread")}>
					<ClockCircleOutlined />
					{dateHelpers.getRelativeDate({ date: notification.createdAt, locale: intl.locale })}
					{!notification.isRead && <span className="list_item-unread-indicator" />}
				</time>
				<div className="list_item-header-right">
					{!notification.isRead ? (
						<Tooltip
							title={intl.formatMessage({
								id: "notification.list_item.mark_as_read.tooltip",
								defaultMessage: "Mark as read",
							})}
						>
							<Button size="small" type="text" icon={<EyeOutlined />} onClick={handleMarkAsReadUnread} />
						</Tooltip>
					) : (
						<Tooltip
							title={intl.formatMessage({
								id: "notification.list_item.mark_as_unread.tooltip",
								defaultMessage: "Mark as unread",
							})}
						>
							<Button
								size="small"
								type="text"
								icon={<EyeInvisibleOutlined />}
								onClick={handleMarkAsReadUnread}
							/>
						</Tooltip>
					)}
					{chipConfig[notification.type] && (
						<Tag color={chipConfig[notification.type].color} className="list_item-header-tag">
							<FormattedMessage {...chipConfig[notification.type].label} />
						</Tag>
					)}
				</div>
			</header>
			{generateBody()}
		</Card>
	);
}
