import "./style.scss";
import type { FC, KeyboardEventHandler, MouseEventHandler, ReactNode } from "react";
import { Button as AntdButton } from "antd";

interface ButtonProps {
	icon?: ReactNode;
	className: string;
	type?: "link" | "text" | "primary" | "default" | "dashed" | undefined;
	children: ReactNode;
	shortcut?: string;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	onKeyDown?: KeyboardEventHandler;
}

const Button: FC<ButtonProps> = ({ icon, className, type, children, shortcut, onClick, onKeyDown }) => {
	return (
		<AntdButton
			data-has-shortcut={shortcut === undefined ? true : false}
			className={`c-button ${className}`}
			icon={icon}
			type={type}
			onClick={onClick}
			onKeyDown={onKeyDown}
		>
			{children} {shortcut && <kbd>{shortcut}</kbd>}
		</AntdButton>
	);
};
export default Button;
