import { detectorHelpers } from "../helpers";
import config from "./config.ts";

const renameKeys = (isMac) => ({
	arrowright: "→",
	arrowleft: "←",
	arrowup: "↑",
	arrowdown: "↓",
	backspace: "delete",
	cmd: isMac ? "⌘" : "ctrl",
	escape: "esc",
	shift: "⇧",
});

function renameKeyMapping(key) {
	const splitKey = key.split("+");
	const keyNameConfig = renameKeys(detectorHelpers.isMac());

	return splitKey.map((item) => keyNameConfig[item] || item);
}

function shortcutMappingToConfigGroups(mapping) {
	const reverseKeyMapping = Object.keys(mapping).reduce((prev, key) => {
		const renamedKey = renameKeyMapping(key);

		return {
			...prev,
			[mapping[key]]: [...(prev[mapping[key]] || []), renamedKey],
		};
	}, {});

	return config.groups.map((configGroup) => ({
		...configGroup,
		items: configGroup.items.map((item) => ({
			...item,
			keys: reverseKeyMapping[item.id],
		})),
	}));
}

export default {
	shortcutMappingToConfigGroups,
};
