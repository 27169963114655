import "./style.scss";

import { InboxRouterOutput, trpc } from "@/trpc";
import {
	BugOutlined,
	ExportOutlined,
	LoadingOutlined,
	PlusOutlined,
	SettingOutlined,
	SyncOutlined,
	TagFilled,
} from "@ant-design/icons";
import {
	ArrowUpOnSquareIcon,
	ArrowUturnLeftIcon,
	AtSymbolIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	DocumentTextIcon,
	InboxIcon,
	InboxStackIcon,
	PaperAirplaneIcon,
	PencilSquareIcon,
	PlusIcon,
	ShareIcon,
	UserIcon,
} from "@heroicons/react/24/outline";
import type { MenuProps } from "antd";
import { Alert, Avatar, Button, Menu, Skeleton, Space, Tooltip, Typography } from "antd";
import { Key, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

import settingRoutes from "../../settings/config";
import AdvancedButton from "../components/AdvancedButton";
import useInboxNavigation, { InboxFolder } from "../hooks/useInboxNavigation.hooks";
import { FormattedMenuItem, getItem } from "./helpers/antdMenu.helpers";
import SideNavItem from "./sideNavItem";
import SideNavSubItem from "./sideNavSubItem";
import { composeNewDraft } from "../Composer/state";
import folderCountConfig from "../config/folderCount.config.ts";
import { visibleLabelsState } from "../state";
import { storageHelpers } from "../../../shared/helpers";
import AvatarGroup from "@/shared/components/antdWrappers/AvatarGroup";

const defaultOpenKeys = ["personal", "teams", "inbox", "folders", "labels", "calendars"];

const defaultSideNavItems = {
	inbox: [
		getItem(
			<FormattedMessage
				id="inbox.side_navigation.personal.my_conversations.label"
				defaultMessage="My conversations"
			/>,
			"personal_conversations",
			<InboxIcon className="btn-icon" />
		),
		getItem(
			<FormattedMessage
				id="inbox.side_navigation.personal.assigned_to_me.label"
				defaultMessage="Assigned to me"
			/>,
			"assigned_to_me",
			<UserIcon className="btn-icon" />
		),
		getItem(
			<FormattedMessage id="inbox.side_navigation.personal.mentions.label" defaultMessage="Mentions" />,
			"mentioned",
			<AtSymbolIcon className="btn-icon" />
		),
		getItem(
			<FormattedMessage
				id="inbox.side_navigation.personal.shared_with_me.label"
				defaultMessage="Shared with me"
			/>,
			"shared_with_me",
			<ShareIcon className="btn-icon" />
		),
		getItem(
			<FormattedMessage
				id="inbox.side_navigation.personal.all_conversations.label"
				defaultMessage="All conversations"
			/>,
			"all_conversations",
			<InboxStackIcon className="btn-icon" />
		),
	],
	folders: [
		getItem(
			<SideNavItem
				title={<FormattedMessage id="inbox.side_navigation.folders.drafts.label" defaultMessage="Drafts" />}
			/>,
			"drafts",
			<DocumentTextIcon className="btn-icon" />
		),
		getItem(
			<SideNavItem
				title={
					<FormattedMessage id="inbox.side_navigation.personal.delegated.label" defaultMessage="Delegated" />
				}
			/>,
			"assigned_by_me",
			<ArrowUturnLeftIcon className="btn-icon" />
		),
		getItem(
			<SideNavItem
				title={<FormattedMessage id="inbox.side_navigation.personal.send.label" defaultMessage="Send" />}
			/>,
			"sent",
			<PaperAirplaneIcon className="btn-icon" />
		),
	],
};

enum EShowActions {
	show_more_labels = "show_more_labels",
	show_less_labels = "show_less_labels",
}

const generateInboxMenuItems = (
	items: { label: ReactNode; key: Key; icon: ReactNode }[],
	itemsCount: InboxRouterOutput["getInboxStatsFixedFolders"] | undefined
) => {
	if (!itemsCount) return items;

	return items.map((item) => ({
		...item,
		label: (
			<SideNavItem
				title={item.label}
				count={itemsCount[typeof item.key === "bigint" ? Number(item.key) : item.key] || 0}
			/>
		),
		children: undefined,
		type: undefined,
	}));
};

function SideNav() {
	const intl = useIntl();
	const history = useHistory();
	const labelKeyPrefix = "label:";
	const calendarKeyPrefix = "calendar:";
	const params: { team: string; conversationId?: string } = useParams();
	const menuRef = useRef<HTMLElement | null>(null);
	const startNewDraft = useSetRecoilState(composeNewDraft);
	const openKeys = useMemo(() => {
		const state = storageHelpers.getInboxState();

		return state?.navigation?.openKeys || defaultOpenKeys;
	}, []);

	// Own state
	const MIN_LABEL_COUNT = 5;
	const LABEL_PAGE_SIZE = 5;
	const [visibleLabels, setVisibleLabels] = useRecoilState(visibleLabelsState);
	const [labelCount, setLabelCount] = useState<number>(MIN_LABEL_COUNT);

	const [inboxMenuItems, setInboxMenuItems] = useState(defaultSideNavItems.inbox);
	const [folderItems, setFolderItems] = useState(defaultSideNavItems.folders);
	const [labelMenuItems, setLabelMenuItems] = useState<FormattedMenuItem[]>([]);
	const [calendarMenuItems, setCalendarMenuItems] = useState<FormattedMenuItem[]>([]);
	const { setFolder, setLabel, currentFolder, currentLabel, path, isSearching } = useInboxNavigation();

	const { data: inboxChannels } = trpc.channel.getInboxChannels.useQuery({ type: "email", limit: 0 });
	const syncingInboxChannels = useMemo(
		() =>
			inboxChannels?.data.filter(
				(channel) => channel.syncStatus && ["PENDING", "IN_PROGRESS"].includes(channel.syncStatus)
			),
		[inboxChannels?.data]
	);
	const disabledChannels = useMemo(
		() => inboxChannels?.data?.filter((channel) => channel.disabled) || [],
		[inboxChannels?.data]
	);
	const calendarsQuery = trpc.inbox.inbox.getChannelCalendars.useQuery();

	// External libs
	const inboxLabelsQuery = trpc.inbox.inboxLabel.getAll.useQuery({ limit: 0 });
	const inboxStatsFixedFoldersQuery = trpc.inbox.inbox.getInboxStatsFixedFolders.useQuery({
		folders: folderCountConfig.foldersWithCount,
	});
	const inboxStatsLabelsQuery = trpc.inbox.inbox.getInboxStatsLabels.useQuery(
		{
			labelIds: visibleLabels,
		},
		{
			keepPreviousData: true,
		}
	);

	const handleFolderClick: MenuProps["onClick"] = (event) => {
		if (event.key === EShowActions.show_more_labels) {
			setLabelCount((prev) => prev + LABEL_PAGE_SIZE);
			if (!menuRef) return;
			menuRef.current?.scrollTo({ behavior: "smooth", top: menuRef.current?.scrollHeight });
			return;
		}

		if (event.key === EShowActions.show_less_labels) {
			setLabelCount(LABEL_PAGE_SIZE);
			if (!menuRef) return;
			menuRef.current?.scrollTo({ behavior: "smooth", top: menuRef.current?.scrollHeight });
			return;
		}

		if (event.key.includes("add_label")) {
			history.push(settingRoutes.routes.inbox.labels.replace(":team", params.team));
			return;
		}

		if (event.key.includes(labelKeyPrefix)) {
			setLabel(event.key.replace(labelKeyPrefix, ""));
			return;
		}

		if (event.key.includes(calendarKeyPrefix)) {
			return;
		}

		setFolder({ folder: event.key as InboxFolder });
	};

	useEffect(() => {
		if (!currentFolder && !currentLabel && !isSearching) {
			setFolder({ folder: "personal_conversations", conversationId: params.conversationId });
		}
	}, [path]);

	const generateLabelItems = () => {
		// Show skeleton while loading user labels
		if (inboxLabelsQuery.isLoading) {
			setLabelMenuItems(() => [getItem(<Skeleton.Input active />, "labelSkeleton")]);
			return;
		}

		if (!inboxLabelsQuery.data) return;

		const labels = inboxLabelsQuery.data.data.slice(0, labelCount);

		setVisibleLabels(labels.map((label) => label._id));
		const labelItems = labels.map((label) => {
			return getItem(
				<SideNavItem title={label.name} count={inboxStatsLabelsQuery.data?.[label._id]} />,
				`${labelKeyPrefix}${label._id}`,
				<TagFilled style={{ color: label.color }} />
			);
		});

		if (inboxLabelsQuery.data?.data?.length > labelCount) {
			labelItems.push(
				getItem(
					<SideNavItem
						title={intl.formatMessage({
							id: "inbox.side_navigation.labels.show_more",
							defaultMessage: "Show more",
						})}
					/>,
					EShowActions.show_more_labels,
					<ChevronDownIcon className="btn-icon" />
				)
			);
		}

		if (
			inboxLabelsQuery.data?.data?.length &&
			inboxLabelsQuery.data?.data.length <= labelCount &&
			inboxLabelsQuery.data?.data.length > MIN_LABEL_COUNT
		) {
			labelItems.push(
				getItem(
					<SideNavItem
						title={intl.formatMessage({
							id: "inbox.side_navigation.labels.show_less",
							defaultMessage: "Show less",
						})}
					/>,
					EShowActions.show_less_labels,
					<ChevronUpIcon className="btn-icon" />
				)
			);
		}

		if (!inboxLabelsQuery.data?.data?.length) {
			labelItems.push(
				getItem(
					<SideNavItem
						title={intl.formatMessage({
							id: "inbox.side_navigation.labels.add_more",
							defaultMessage: "add label",
						})}
					/>,
					"add_label",
					<PlusOutlined />
				)
			);
		}

		setLabelMenuItems(() => labelItems);
	};

	const generateCalendarItems = () => {
		// Show skeleton while loading calendars
		if (calendarsQuery.isLoading) {
			setCalendarMenuItems(() => [getItem(<Skeleton.Input active />, "calendarSkeleton")]);
			return;
		}

		if (!calendarsQuery.data) return;

		const calendarItems = calendarsQuery.data.map((calendar) => {
			return getItem(
				<SideNavItem
					title={
						<a href={calendar.url} target="_blank">
							<Space align="center">
								<Typography.Text style={{ maxWidth: "20ch" }} ellipsis>
									{calendar.uniqueIdentifier}
								</Typography.Text>
								<ExportOutlined />
							</Space>
						</a>
					}
				/>,
				`${calendarKeyPrefix}${calendar.url}`,
				<img src={calendar.channelLogo} style={{ width: "1.5rem", height: "1.5rem" }} />
			);
		});

		if (!calendarsQuery.data?.length) {
			calendarItems.push(
				getItem(
					<Typography.Text type="secondary">
						<FormattedMessage
							id="inbox.side_navigation.calendar.not_found"
							defaultMessage="No calendars found"
						/>
					</Typography.Text>,
					`${calendarKeyPrefix}not_found`,
					null
				)
			);
		}

		setCalendarMenuItems(() => calendarItems);
	};

	const handleMenuChange = (openKeys: string[]) => {
		storageHelpers.updateInboxState({ navigation: { openKeys } });
	};

	useEffect(() => {
		generateLabelItems();
	}, [inboxLabelsQuery.data, inboxLabelsQuery.isLoading, labelCount, inboxStatsLabelsQuery.data]);

	useEffect(() => {
		generateCalendarItems();
	}, [calendarsQuery.data, calendarsQuery.isLoading]);

	useEffect(() => {
		if (!inboxStatsFixedFoldersQuery.data) return;

		const tempMenuItems = defaultSideNavItems.inbox;

		if (inboxStatsFixedFoldersQuery.data?.["outbox"] && !inboxMenuItems.find((item) => item.key === "outbox")) {
			tempMenuItems.push(
				getItem(
					<FormattedMessage id="inbox.side_navigation.personal.outbox.label" defaultMessage="Outbox" />,
					"outbox",
					<ArrowUpOnSquareIcon className="btn-icon" />
				)
			);
		}

		setInboxMenuItems(() => [...generateInboxMenuItems(tempMenuItems, inboxStatsFixedFoldersQuery.data)]);
		setFolderItems(() => [
			...generateInboxMenuItems(defaultSideNavItems.folders, inboxStatsFixedFoldersQuery.data),
		]);
	}, [inboxStatsFixedFoldersQuery.data]);

	return (
		<aside className="sn-left-side-nav">
			<AdvancedButton
				className="sn-compose-btn"
				icon={<PencilSquareIcon className="btn-icon mr-xs" />}
				onClick={() => startNewDraft(undefined)}
				type="primary"
				shortcut="N"
			>
				<FormattedMessage id="inbox.side_navigation.compose_button.label" defaultMessage="Compose" />
			</AdvancedButton>
			<section ref={menuRef} className="sn-scroll-section">
				<Menu
					className="sn-side-nav-menu"
					defaultSelectedKeys={isSearching ? [] : [currentFolder]}
					selectedKeys={isSearching ? [] : [currentFolder || `${labelKeyPrefix}${currentLabel}`]}
					defaultOpenKeys={openKeys}
					onOpenChange={handleMenuChange}
					inlineIndent={8}
					mode="inline"
					theme="light"
					items={[
						getItem(
							<SideNavSubItem
								title={intl.formatMessage({
									id: "inbox.side_navigation.personal.title",
									defaultMessage: "Personal",
								})}
							/>,
							"personal",
							null,
							[
								getItem(
									null,
									// <SideNavSubItem
									// 	title={<FormattedMessage id="ASIDE.INBOX.MAIN_INBOX.PERSONAL.INBOX.TITLE" />}
									// />,
									"inbox",
									null,
									inboxMenuItems,
									"group"
								),
								getItem(
									<SideNavSubItem
										title={intl.formatMessage({
											id: "inbox.side_navigation.folders.title",
											defaultMessage: "Folders",
										})}
									/>,
									"folders",
									null,
									folderItems
								),
								getItem(
									<SideNavSubItem
										title={intl.formatMessage({
											id: "inbox.side_navigation.labels.title",
											defaultMessage: "Labels",
										})}
										actionIcon={<PlusIcon className="btn-icon" />}
										onClickAction={() =>
											history.push(
												settingRoutes.routes.inbox.labels.replace(":team", params.team)
											)
										}
									/>,
									"labels",
									null,
									labelMenuItems
								),
								getItem(
									<SideNavSubItem
										title={intl.formatMessage({
											id: "inbox.side_navigation.calendars.title",
											defaultMessage: "Calendars",
										})}
									/>,
									"calendars",
									null,
									calendarMenuItems
								),
							],
							"group"
						),
						// todo: teams implementation of inbox. This will be a feature in the first external release. Not in the first internal release.
						// getItem(
						// 	<SideNavSubItem title={<FormattedMessage id="ASIDE.INBOX.TEAMS_INBOX.TEAMS.TITLE" />} />,
						// 	"teams",
						// 	null,
						// 	[
						// 		getItem(
						// 			<SideNavItem title="todo@mvp.be" />,
						// 			"sales",
						// 			<UserGroupIcon className="btn-icon" />
						// 		),
						// 		getItem(<Skeleton.Input active />, "teamSkeleton"),
						// 	]
						// ),
					]}
					onClick={handleFolderClick}
				/>
			</section>
			<footer className="sn-left-side-nav-footer">
				{disabledChannels.map((channel) => (
					<Alert
						className="channel-sync-alert"
						type="warning"
						message={
							<Typography.Text className="channel-sync-alert-text" ellipsis={{ tooltip: true }}>
								<FormattedMessage
									id="inbox.side_navigation.warning.channel_disabled.label"
									defaultMessage="Reconnect {channelName}"
									values={{ channelName: channel.name }}
								/>
							</Typography.Text>
						}
						showIcon
						action={[
							<Button
								type="text"
								size="small"
								href={`/${params.team}/settings/inbox/channels/${channel._id}`}
							>
								<SyncOutlined />
							</Button>,
						]}
					/>
				))}
				{!!syncingInboxChannels?.length && (
					<Alert
						type="success"
						className="channel-sync-alert"
						message={intl.formatMessage(
							{
								id: "inbox.side_navigation.channel_sync.status.in_progress",
								defaultMessage: "{count ,plural, =1 {Syncing channel} other {Syncing channels}}...",
							},
							{ count: syncingInboxChannels?.length }
						)}
						showIcon
						icon={<LoadingOutlined />}
						action={
							<AvatarGroup className="channel-sync-avatar-group" maxCount={2} size="small">
								{syncingInboxChannels.map((channel) => (
									<Tooltip key={channel._id} title={channel.address} placement="top">
										<Avatar style={{ border: "none" }} shape="square" src={channel.logo} />
									</Tooltip>
								))}
							</AvatarGroup>
						}
					/>
				)}
				<Button
					block
					type="text"
					className="sn-settings-btn"
					onClick={() =>
						history.push(`/${params.team}/support?requestType=bug&subject=Probleem met de inbox`)
					}
					icon={<BugOutlined />}
				>
					{" "}
					<FormattedMessage id="inbox.side_navigation.button.report_bug" defaultMessage="Probleem melden" />
				</Button>
				<Button
					block
					type="text"
					className="sn-settings-btn"
					onClick={() => history.push(`/${params.team}/settings/inbox/channels`)}
					icon={<SettingOutlined />}
				>
					{" "}
					<FormattedMessage
						id="inbox.side_navigation.settings_button.label"
						defaultMessage="Inbox settings"
					/>
				</Button>
			</footer>
		</aside>
	);
}

export default SideNav;
