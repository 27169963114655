import "./style.scss";

import { Alert, Button, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilValue } from "recoil";

import { userState } from "../state";
import { useState } from "react";

export default function VerifyUser() {
	const intl = useIntl();
	const history = useHistory();
	const user = useRecoilValue(userState);
	const [showJoin, setShowJoin] = useState(false);

	if (!user) {
		history.push("/portal/sign_up");
		return null;
	}

	return (
		<section className="portal_body">
			<article className="portal_body-article">
				<header className="portal_body-header">
					<Typography.Title>
						<FormattedMessage
							id="portal.sign_up_completed.title"
							defaultMessage="Get started with Bothive"
						/>
					</Typography.Title>
					<p>
						<FormattedMessage
							id="portal.sign_up_completed.description"
							defaultMessage="Bothive is a new, fast and structured way to communicate with your <bold>colleagues</bold> and <bold>customers</bold>."
							values={{ bold: (content) => <Typography.Text strong>{content}</Typography.Text> }}
						/>
					</p>
				</header>
				<div className="portal_sign_up_completed-body">
					<Button
						size="large"
						type="primary"
						className="portal_sign_up_completed-body-buttons"
						onClick={() => history.push("/portal/organization")}
					>
						<FormattedMessage
							id="portal.sign_up_completed.button.create_organization"
							defaultMessage="Create your organization"
						/>
					</Button>
					<p>
						<FormattedMessage id="portal.sign_up_completed.button_split.or" defaultMessage="Or" />
					</p>
					{!showJoin && (
						<Button
							size="large"
							type="text"
							className="portal_sign_up_completed-body-buttons"
							onClick={() => setShowJoin(true)}
						>
							<FormattedMessage
								id="portal.sign_up_completed.button.join_an_organization"
								defaultMessage="Join an existing organization"
							/>
						</Button>
					)}
					{showJoin && (
						<Alert
							type="info"
							message={intl.formatMessage({
								id: "portal.sign_up_completed.join_organization_instruction.title",
								defaultMessage: "Join an existing organization",
							})}
							description={intl.formatMessage(
								{
									id: "portal.sign_up_completed.join_organization_instruction.description",
									defaultMessage:
										"To join a organization ask the administrator of your company to invite you in the organization with your email: {email}",
								},
								{
									email: user.email,
								}
							)}
						/>
					)}
				</div>
			</article>
		</section>
	);
}
