import { atomFamily } from "recoil";

import { getTemplateConfig } from "@/flows/flow_detail/configuration/query";

export const flowConfiguration = atomFamily({
	key: "frcFlowConfiguration",
	default: async ({ templateId }) => {
		const result = await getTemplateConfig({ templateId });

		return result?.data;
	},
});
