import { routesConfig } from "@/config";
import Editor from "@/flows/send/components/Compose/components/Editor";
import NavigationBar from "@/flows/send/components/Navigation";
import { messageValues } from "@/flows/send/interface";
import FieldLabel from "@/shared/components/field/default";
import { trpc } from "@/trpc";
import { WarningOutlined } from "@ant-design/icons";
import type { IOrganization, IUser } from "@bothive_core/database";
import { Button, Empty, Form, Input, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import config from "./config";

export default function Compose({
	setCurrentStep,
	setMessageValues,
	templateId,
	messageValues,
	isReminding,
}: {
	setCurrentStep: (value: number) => void;
	setMessageValues: (value: messageValues) => void;
	templateId?: string;
	messageValues?: messageValues;
	isReminding?: boolean;
}) {
	const intl = useIntl();
	const history = useHistory();
	const [form] = Form.useForm();

	const [selectedChannel, setSelectedChannel] = useState<string | undefined>(undefined);
	const formChannelId = Form.useWatch("channelId", form);

	const { data: inbox, isLoading } = trpc.inbox.inbox.getUserInbox.useQuery();

	const state: { user: IUser; team: IOrganization } = useSelector((state) => ({
		user: state.profile.account.user,
		team: state.content.team.team,
	}));

	useEffect(() => {
		if (!inbox?.channels?.length) return;

		const channel = inbox.channels.find(({ disabled }) => !disabled);

		if (!channel) return;

		form.setFieldValue("channelId", channel._id);
		setSelectedChannel(channel._id);
	}, [inbox]);

	useEffect(() => {
		if (messageValues) {
			form.setFieldsValue({
				channelId: messageValues.channel.id,
				subject: messageValues.subject,
				message: messageValues.message,
			});
		}
	}, []);

	useEffect(() => {
		if (!inbox || selectedChannel === formChannelId) return;

		setSelectedChannel(formChannelId);
	}, [formChannelId]);

	let messageTemplate =
		config.templates.find(
			(template) => template.id === templateId && template.type === (isReminding ? "reminding" : "firstSend")
		) ||
		config.templates.find((template) => template.id === templateId) ||
		config.templates[0];

	const templateMessage = `${
		messageTemplate?.content || ""
	}</p><div data-lexical-signature-placeholder='true'></div>`;

	return (
		<div style={{ width: "100%", display: "flex", gap: "2em", flexDirection: "column", maxWidth: "722px" }}>
			<div style={{ display: "flex", flexDirection: "column", gap: "2em", paddingBottom: "12em" }}>
				<header className="m-header m-header--description">
					<h1>
						<FormattedMessage id="FLOWS.SEND.COMPOSE.TITLE" defaultMessage="Compose message" />
					</h1>
					<p>
						<FormattedMessage
							id="FLOWS.SEND.COMPOSE.DESCRIPTION"
							defaultMessage="Compose a message to send to your contacts"
						/>
					</p>
				</header>
				<Form
					form={form}
					initialValues={{ message: messageValues?.message || templateMessage }}
					id="flowSendingForm"
					requiredMark="optional"
					onFinish={(values) => {
						setMessageValues({
							...values,
							channel: {
								name: inbox?.channels.find((channel) => channel._id === values.channelId)?.name,
								id: values.channelId,
							},
						});
						setCurrentStep(2);
					}}
				>
					<FieldLabel
						label={intl.formatMessage({ id: "FLOWS.SEND.COMPOSE.CHANNEL", defaultMessage: "Channel" })}
					>
						<Form.Item
							name="channelId"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.REQUIRED_ERROR",
									}),
								},
							]}
							style={{ width: "100%" }}
						>
							<Select
								notFoundContent={
									<Empty
										image={Empty.PRESENTED_IMAGE_SIMPLE}
										description={
											<p>
												<FormattedMessage
													id="FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.EMPTY"
													defaultMessage="No channels added to your user"
												/>
											</p>
										}
									>
										<Button
											type="link"
											onClick={() =>
												history.push(
													routesConfig.dashboard.settings.inbox.channels.overview.replace(
														":team",
														state.team?.slug
													)
												)
											}
										>
											<FormattedMessage
												id="FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.EMPTY.ADD"
												defaultMessage="Add channel"
											/>
										</Button>
									</Empty>
								}
								loading={isLoading}
							>
								{inbox?.channels?.length &&
									inbox.channels.map((channel) => (
										<Select.Option
											value={channel._id}
											key={channel._id}
											disabled={channel.disabled}
										>
											{channel.name}
											{channel.disabled && (
												<Tooltip
													title={intl.formatMessage({
														id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.CHANNEL.RECONNECT.TOOLTIP",
													})}
												>
													<WarningOutlined className="fs-flow_sending-form_item-select-warning_icon" />
												</Tooltip>
											)}
										</Select.Option>
									))}
							</Select>
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						className="t-gap--top-sm"
						label={intl.formatMessage({ id: "FLOWS.SEND.COMPOSE.SUBJECT", defaultMessage: "Subject" })}
					>
						<Form.Item
							name="subject"
							className="fs-flow_sending-form_item"
							style={{ width: "100%" }}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "FLOWS.SEND.COMPOSE.SUBJECT.REQUIRED",
										defaultMessage: "Please fill in a subject",
									}),
								},
							]}
						>
							<Input />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						className="t-gap--top-sm"
						label={intl.formatMessage({ id: "FLOWS.SEND.COMPOSE.EMAIL", defaultMessage: "Email" })}
					>
						<Form.Item
							name="message"
							style={{ width: "100%" }}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.ERROR_EMPTY",
									}),
								},
								{
									validator: async (_, value) => {
										if (!value.includes("{{flow_link}}")) {
											const message = intl.formatMessage({
												id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.COMPOSE.ERROR_NO_LINK_VARIABLE",
											});

											return Promise.reject(new Error(message));
										}
									},
								},
							]}
						>
							<Editor channelId={selectedChannel} tools={["signature"]} />
						</Form.Item>
					</FieldLabel>
				</Form>
			</div>
			<NavigationBar>
				<Button type="text" style={{ margin: "1em" }} onClick={() => setCurrentStep(0)}>
					<FormattedMessage id="FLOWS.SEND.COMPOSE.PREVIOUS" defaultMessage="Previous" />
				</Button>
				<Button key="submit" form="flowSendingForm" type="primary" style={{ margin: "1em" }} htmlType="submit">
					<FormattedMessage id="FLOWS.SEND.COMPOSE.BUTTONS.NEXT" defaultMessage="Next" />
				</Button>
			</NavigationBar>
		</div>
	);
}
