import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { selectedFlowIdState } from "@/flows/common/state";

import config from "./config";
import { getStatusTypes } from "./query";
import { statusTypesList, statusTypesMeta } from "./state";

export function useStatusTypes() {
	const [hookState, setHookState] = useState("hasValue");
	const setStatusTypesMetaData = useSetRecoilState(statusTypesMeta);
	const [statusTypes, setStatusTypes] = useRecoilState(statusTypesList);
	const flowId = useRecoilValue(selectedFlowIdState);

	useEffect(() => {
		// Only run effect hook if flowId is changed or no status types exist
		if (statusTypes?.length && statusTypes[0]?.flowId === flowId) {
			return;
		}

		fetchStatusTypes({ limit: config.pagination.limit, offset: 0 });
	}, [flowId]);

	const fetchStatusTypes = async ({ limit, offset }) => {
		try {
			setHookState("loading");

			const result = await getStatusTypes({ flowId, limit, offset });

			setHookState("hasValue");
			setStatusTypesMetaData(result.meta);
			setStatusTypes(result.data);
		} catch (error) {
			setHookState("hasError");
		}
	};

	return [
		{
			state: hookState,
			contents: statusTypes,
		},
		fetchStatusTypes,
	];
}
