import { Result } from "antd";
import React from "react";

import DashboardCard from "@/shared/components/card/dashboard";

export default function NotFoundState({ title, style = {}, className = "" }) {
	return (
		<DashboardCard className={className} style={style}>
			<Result status="warning" title={title} />
		</DashboardCard>
	);
}
