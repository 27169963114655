import "./style.scss";

import { LoadingOutlined } from "@ant-design/icons";
import type { IContactSyncRule } from "@bothive_core/database";
import { Button, Card, Form, Progress, Result, Segmented, Space, Spin, Typography } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilValue } from "recoil";
import { useHistory, useParams } from "react-router-dom";

import ContactLabels from "../../../../shared/components/ContactLabelsSelector";
import { IntegrationsRouterOutput, trpc } from "../../../../trpc";
import Analytics, { emptyAnalytics } from "../components/Analytics";
import EditorTable from "../components/EditorTable";
import RulesTable from "../components/LabelRules";
import { StepParams, TableFilters } from "../types";
import { importConfigState } from "./state";
import { rulesFileKeys } from "./config";

const ANALYZE_LIMIT = 100;
const BATCH_TIMEOUT = 2_000; // 2 seconds;

export default function ReviewImport({ onBack, onClose }: StepParams) {
	const intl = useIntl();
	const history = useHistory();
	const params = useParams<{ team: string }>();
	const isImportStarted = useRef<boolean>(false);
	const batchTimeoutRef = useRef<NodeJS.Timeout>();
	const [form] = Form.useForm<{ label: string }>();
	const importConfig = useRecoilValue(importConfigState);
	const [tableFilters, setFilter] = useState<TableFilters>("ALL");
	const [rules, setRules] = useState<IContactSyncRule[]>([]);
	const [editedData, setEditedData] = useState<
		Record<string, IntegrationsRouterOutput["crm"]["analyzeContacts"]["contacts"]>
	>([]);

	const trpcContext = trpc.useUtils();
	const analyzeImport = trpc.integrations.crm.analyzeContacts.useInfiniteQuery(
		{
			integrationId: "admin_pulse",
			query: {
				limit: ANALYZE_LIMIT,
				q: importConfig.q,
				type: importConfig.type,
				crmType: importConfig.crmType,
			},
		},
		{
			getNextPageParam: (data) => (data?.meta ? { offset: (data.meta.offset || 0) + ANALYZE_LIMIT } : null),
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		}
	);
	const importContacts = trpc.integrations.crm.importContacts.useMutation();
	const { data: contactLabels, isLoading: isLabelLoading } = trpc.contactLabel.all.useQuery({ limit: 0 });

	type FlattenAnalyze = {
		contacts: IntegrationsRouterOutput["crm"]["analyzeContacts"]["contacts"];
		analytics: IntegrationsRouterOutput["crm"]["analyzeContacts"]["analytics"];
	};

	const flattenAnalyze = useMemo(
		() =>
			analyzeImport.data?.pages?.reduce<FlattenAnalyze>(
				(prev, page) => {
					let analytics = { ...prev.analytics };
					let contacts = [...prev.contacts, ...page.contacts];

					if (Object.keys(editedData)?.length) {
						// map edited data into the contacts
						contacts = contacts.map((item) => ({ ...item, ...(editedData[item.id] || {}) }));
					}

					// Combine Errors
					Object.entries(page.analytics.errors).forEach(([key, value]) => {
						switch (true) {
							case typeof value == "boolean":
								analytics.errors[key] = analytics.errors[key] || value;
								break;
							case value instanceof Set:
								analytics.errors[key] = new Set([...analytics.errors[key], ...value]);
								break;
							default:
								analytics.errors[key] = (analytics.errors[key] || 0) + (value || 0);
								break;
						}
					});

					// Combine Warnings
					Object.entries(page.analytics.warnings).forEach(([key, value]) => {
						switch (true) {
							case typeof value == "boolean":
								analytics.warnings[key] = analytics.warnings[key] || value;
								break;
							case value instanceof Set:
								analytics.warnings[key] = new Set([...analytics.warnings[key], ...value]);
								break;
							default:
								analytics.warnings[key] = (analytics.warnings[key] || 0) + (value || 0);
								break;
						}
					});

					analytics.totalUpdatedContacts =
						(analytics.totalUpdatedContacts || 0) + (page.analytics.totalUpdatedContacts || 0);

					return { contacts, analytics };
				},
				{
					analytics: structuredClone(emptyAnalytics),
					contacts: [],
				}
			),
		[analyzeImport, editedData]
	);

	const tableData = useMemo(() => {
		if (!flattenAnalyze) return [];

		// Map analytics on to table rows
		return flattenAnalyze.contacts.map((data) => {
			const errors = {
				hasError: false,
				invalidEmail: data.email && flattenAnalyze.analytics.errors.invalidEmail.has(data.email),
				invalidPhone: data.phone && flattenAnalyze.analytics.errors.invalidPhone.has(data.phone),
				invalidNationalRegistrationNumber:
					data.nationalRegistrationNumber &&
					flattenAnalyze.analytics.errors.invalidNationalRegistrationNumber.has(
						data.nationalRegistrationNumber
					),
				missingName:
					flattenAnalyze.analytics.errors.missingName && !data.firstName && !data.lastName && !data.fullName,
			};
			const warnings = {
				hasWarnings: false,
				duplicateEmail: data.email && flattenAnalyze.analytics.warnings.duplicateEmail.has(data.email),
				duplicatePhone: data.phone && flattenAnalyze.analytics.warnings.duplicatePhone.has(data.phone),
				duplicateId: data.phone && flattenAnalyze.analytics.warnings.duplicateId.has(data.uniqueIdentifier),
				duplicateNationalRegistrationNumber:
					data.nationalRegistrationNumber &&
					flattenAnalyze.analytics.warnings.duplicateNationalRegistrationNumber.has(
						data.nationalRegistrationNumber
					),
				unknownAccountManagers: false,
			};

			errors.hasError = !!Object.values(errors).find(Boolean);
			warnings.hasWarnings = !!Object.values(warnings).find(Boolean);

			return { ...data, errors, warnings, id: data.id as string };
		});
	}, [flattenAnalyze]);

	const filteredTableData = useMemo(() => {
		if (tableFilters === "ALL") return tableData;

		return tableData.filter((item) => {
			if (tableFilters === "ERRORS") return item.errors?.hasError;
			if (tableFilters === "WARNINGS") return item.warnings?.hasWarnings;

			return true;
		});
	}, [tableData, tableFilters]);

	const analyzeProgress = useMemo(() => {
		const totalContacts = analyzeImport.data?.pages?.[0]?.meta?.total;

		if (!totalContacts || !flattenAnalyze?.contacts?.length) return 0;

		return Math.floor((flattenAnalyze?.contacts?.length / totalContacts) * 100);
	}, [analyzeImport, flattenAnalyze]);

	useEffect(() => {
		const total = analyzeImport.data?.pages?.[0]?.meta?.total;

		if (!flattenAnalyze?.contacts?.length || !total) return;
		if (total <= flattenAnalyze?.contacts?.length) return;

		clearTimeout(batchTimeoutRef.current);

		batchTimeoutRef.current = setTimeout(() => analyzeImport.fetchNextPage(), BATCH_TIMEOUT);
	}, [flattenAnalyze]);

	const handleUpload = async () => {
		try {
			// mapping to support other components
			const formatRules = rules.map((rule) => ({
				conditions: rule.conditions.map((item) => ({
					field: item.field,
					type: item.type,
					action: item.value,
					value: item.input,
				})),
				label: rule.labelId,
			}));

			await importContacts.mutateAsync({
				query: {
					q: importConfig.q,
					type: importConfig.type,
					crmType: importConfig.crmType,
				},
				rules: formatRules,
				editData: editedData,
				integrationId: "admin_pulse",
				label: form.getFieldValue("label"),
				allowCreate: importConfig.allowCreate,
				allowUpdate: importConfig.allowUpdate,
				// exclude
			});

			trpcContext.contact.getAllContacts.invalidate();
		} catch (error) {
			console.error("Import failed", error);
		}
	};

	const handleClose = () => {
		history.push(`/${params.team}/contact`);
		trpcContext.contact.getAllContacts.invalidate();
		onClose();
	};

	if (analyzeImport.isError) {
		return (
			<Result
				status="error"
				className="contact_import-content-status"
				title={intl.formatMessage({
					id: "contact.import.admin_pulse.review_import.analyze_failed.title",
					defaultMessage: "Import could not be analyzed",
				})}
				subTitle={intl.formatMessage({
					id: "contact.import.admin_pulse.review_import.analyze_failed.description",
					defaultMessage: "We could not analyze your import. Please try again later with other settings.",
				})}
				extra={
					<Button type="primary" onClick={() => onBack("GET_DATA")}>
						<FormattedMessage
							id="contact.import.admin_pulse.review_import.analyze_failed.button"
							defaultMessage="Try again"
						/>
					</Button>
				}
			/>
		);
	}
	if (analyzeImport.isInitialLoading) {
		return (
			<div className="contact_import-content-loader">
				<Spin
					size="large"
					tip={intl.formatMessage({
						id: "contact.import.admin_pulse.review_import.loading",
						defaultMessage: "We are analyzing your import, this can take a moment",
					})}
				>
					<div />
				</Spin>
			</div>
		);
	}
	if (importContacts.isError) {
		return (
			<Result
				status="error"
				className="contact_import-content-status"
				title={intl.formatMessage({
					id: "contact.import.admin_pulse.review_import.import_failed.title",
					defaultMessage: "Import failed",
				})}
				subTitle={intl.formatMessage({
					id: "contact.import.admin_pulse.review_import.import_failed.description",
					defaultMessage:
						"The import failed. Please try again later with other settings. If the problem persists, please contact support.",
				})}
				extra={
					<Button type="primary" onClick={() => onBack("GET_DATA")}>
						<FormattedMessage
							id="contact.import.admin_pulse.review_import.import_failed.button"
							defaultMessage="Try again"
						/>
					</Button>
				}
			/>
		);
	}
	if (importContacts.isLoading || isImportStarted.current) {
		isImportStarted.current = true;
		return (
			<Result
				icon={<LoadingOutlined />}
				className="contact_import-content-status"
				title={intl.formatMessage({
					id: "contact.import.admin_pulse.review_import.is_importing.title",
					defaultMessage: "Import started",
				})}
				subTitle={intl.formatMessage({
					id: "contact.import.admin_pulse.review_import.is_importing.description",
					defaultMessage:
						"Import is running in the background. You can close this window and continue working.",
				})}
				extra={
					<Button type="primary" onClick={handleClose}>
						<FormattedMessage
							id="contact.import.admin_pulse.review_import.is_importing.button"
							defaultMessage="Close window"
						/>
					</Button>
				}
			/>
		);
	}

	const totalNewContacts =
		(flattenAnalyze?.contacts?.length || 0) -
		(flattenAnalyze?.analytics?.errors?.total || 0) -
		(flattenAnalyze?.analytics?.totalUpdatedContacts || 0);
	const totalValidContacts = totalNewContacts + (flattenAnalyze?.analytics?.totalUpdatedContacts || 0);

	return (
		<Form
			form={form}
			preserve={false}
			layout="vertical"
			onFinish={handleUpload}
			initialValues={importConfig}
			className="contact_import-content_wrapper contact_import-review_import"
		>
			<Card
				title={intl.formatMessage({
					id: "contact.import.admin_pulse.review_import.title",
					defaultMessage: "Review import",
				})}
				className="contact_import-content-card"
			>
				<Space direction="vertical" size="small" className="contact_import-content-spacer">
					<Analytics
						isLoading={analyzeImport.isLoading}
						analytics={flattenAnalyze?.analytics}
						allowCreate={importConfig.allowCreate}
						allowUpdate={importConfig.allowUpdate}
						total={flattenAnalyze?.contacts.length || 0}
					/>
					<Progress percent={analyzeProgress} size="small" />
					{analyzeProgress < 100 && (
						<div className="contact_import-review_import-progress">
							<Typography.Text>
								<FormattedMessage
									id="contact.import.admin_pulse.review_import.analyze.in_progress.label"
									defaultMessage="Contact analyze is busy"
								/>
							</Typography.Text>
							<Spin size="small" />
						</div>
					)}
					{analyzeProgress === 100 && (
						<Typography.Text>
							<FormattedMessage
								id="contact.import.admin_pulse.review_import.analyze.in_progress.complete"
								defaultMessage="Contact analyze is completed"
							/>
						</Typography.Text>
					)}
				</Space>
			</Card>
			<Card
				title={intl.formatMessage({
					id: "contact.import.admin_pulse.review_import.toolbar.title",
					defaultMessage: "Import preview",
				})}
				className="contact_import-content-card"
				extra={
					<Segmented
						value={tableFilters}
						onChange={(value) => setFilter(value as TableFilters)}
						options={[
							{
								label: intl.formatMessage({
									id: "contact.import.admin_pulse.review_import.filters.show_all",
									defaultMessage: "Show all",
								}),
								value: "ALL",
							},
							{
								label: intl.formatMessage({
									id: "contact.import.admin_pulse.review_import.filters.warnings",
									defaultMessage: "Only show warnings",
								}),
								value: "WARNINGS",
							},
							{
								label: intl.formatMessage({
									id: "contact.import.admin_pulse.review_import.filters.errors",
									defaultMessage: "Only show errors",
								}),
								value: "ERRORS",
							},
						]}
					/>
				}
			>
				<EditorTable
					dataSource={filteredTableData}
					columns={[
						{
							key: "firstName",
							dataIndex: "firstName",
							title: intl.formatMessage({
								id: "contact.import.admin_pulse.review_import.table.column.first_name",
								defaultMessage: "First name",
							}),
						},
						{
							key: "lastName",
							dataIndex: "lastName",
							title: intl.formatMessage({
								id: "contact.import.admin_pulse.review_import.table.column.last_name",
								defaultMessage: "Last name",
							}),
						},
						{
							key: "email",
							dataIndex: "email",
							title: intl.formatMessage({
								id: "contact.import.admin_pulse.review_import.table.column.email",
								defaultMessage: "Email",
							}),
						},
						{
							key: "phone",
							dataIndex: "phone",
							title: intl.formatMessage({
								id: "contact.import.admin_pulse.review_import.table.column.phone",
								defaultMessage: "Phone",
							}),
						},
					]}
					onChange={({ id, data }) => setEditedData((prev) => ({ ...prev, [id]: data }))}
				/>
			</Card>
			<Card
				title={intl.formatMessage({
					id: "contact.import.admin_pulse.review_import.labels.title",
					defaultMessage: "Add labels",
				})}
				className="contact_import-content-card"
			>
				<Space direction="vertical" size="large" className="contact_import-content-spacer">
					<Form.Item
						id="label"
						name="label"
						label={intl.formatMessage({
							id: "contact.import.admin_pulse.review_import.labels.general.label",
							defaultMessage: "Add label to contacts",
						})}
						help={intl.formatMessage({
							id: "contact.import.admin_pulse.review_import.labels.general.help",
							defaultMessage:
								"Hint: with labels you can find and edit all contacts from a specific import",
						})}
					>
						<ContactLabels
							allowClear
							showAddButton
							isLabelLoading={isLabelLoading}
							contactLabels={contactLabels?.data}
						/>
					</Form.Item>
					<RulesTable
						rules={rules}
						onChange={setRules}
						fileKeys={rulesFileKeys}
						isLoading={isLabelLoading}
						contactLabels={contactLabels?.data}
					/>
					<footer className="contact_import-content-footer">
						<Button type="text" onClick={() => onBack()}>
							<FormattedMessage
								id="contact.import.admin_pulse.review_import.footer.back"
								defaultMessage="Back"
							/>
						</Button>
						<Button
							type="primary"
							htmlType="submit"
							disabled={analyzeProgress !== 100 || !totalValidContacts}
							loading={importContacts.isLoading || analyzeImport.isLoading}
						>
							<FormattedMessage
								id="contact.import.admin_pulse.review_import.footer.import"
								defaultMessage="Import contacts"
							/>
						</Button>
					</footer>
				</Space>
			</Card>
		</Form>
	);
}
