import axios from "axios";
import { atom, selector } from "recoil";

export const templateUrlState = atom({
	key: "frdvpTemplateUrlState",
	default: undefined,
});

export const htmlTemplateState = selector({
	key: "frdvpHtmlTemplate",
	get: async ({ get }) => {
		const templateUrl = get(templateUrlState);

		const { data } = await axios.get(templateUrl);

		if (!data) {
			throw new Error();
		}

		return data;
	},
});
