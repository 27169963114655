import { IntlShape } from "react-intl";
import { ApiKeyManagerRouterOutput } from "@/trpc";
import { IApiPermission } from "@bothive_core/database";

export default {
	/**
	 * @description
	 * Call this function with the current API-key to check if this key has missing data depending on the permissions
	 **/
	checkForCredentialWarning({
		apiKey,
		permissions,
		intl,
	}: {
		apiKey: ApiKeyManagerRouterOutput["getById"] | null | undefined;
		permissions: IApiPermission[];
		intl: IntlShape;
	}) {
		if (!apiKey?.permissions || !permissions?.length) {
			return undefined;
		}

		// Loop through all the permissions in the API-key until warning is found or all permissions are checked
		for (let permission of apiKey.permissions) {
			const permissionDetail = permissions.find((item) => item.key === permission);

			// If permission has no requirements exist
			if (!permissionDetail?.options) {
				continue;
			}

			// Get list of all requirements that can throw a warning
			const permissionKey = Object.keys(permissionDetail.options).reduce<string[]>(
				(prev, key) => (["domainLock"].includes(key) && permissionDetail.options[key] ? [...prev, key] : prev),
				[]
			);

			// Check if API-key requires trusted domains and if no domains are set. exit loop and assign warning message
			if (permissionKey.includes(["domainLock"][0]) && !apiKey?.domains?.length) {
				return intl.formatMessage({
					id: "settings.api_key_detail.permissions.warning.domain_lock.description",
					defaultMessage: "The permission requires a domain to be added before this API key will work.",
				});
			}
		}

		return undefined;
	},
};
