import "./style.scss";

import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { routesConfig } from "@/config";
import { trpc } from "@/trpc";
import { Card } from "@/components/molecules";
import DashboardCard from "@/shared/components/card/dashboard";
import { PlusOutlined } from "@ant-design/icons";

export default function FirstVisit({
	setShowImportModal,
	setShowCreateModal,
}: {
	setShowImportModal: ({ show, integrationId }: { show: boolean; integrationId: string }) => void;
	setShowCreateModal?: (value: boolean) => void;
}) {
	const history = useHistory();
	const queryParameters: { team: string } = useParams();

	const { data: integrations, isLoading } = trpc.integration.getConnections.useQuery({
		query: { features: "contact.import", populate: true },
	});

	return (
		<DashboardCard style={{ maxWidth: "1200px" }} className="t-gap--top-xl">
			<section className="contacts-first_visit">
				<header className="contacts-first_visit-header">
					<h1>
						<FormattedMessage id="CONTACTS.OVERVIEW.INTRO.TITLE" />
					</h1>
					<p>
						<FormattedMessage id="CONTACTS.OVERVIEW.INTRO.DESCRIPTION" />
					</p>
				</header>
				{!isLoading && (
					<div className="t-list t-list--cards" style={{ height: "fit-content" }}>
						{integrations?.data?.map((integration: any) => (
							<Card.MIntegrationSmallCard
								label={integration?.integration?.name}
								key={integration?.integration?._id}
								id={integration?.integration?._id}
								{...integration?.integration}
								onClick={(id) => {
									setShowImportModal({
										show: true,
										integrationId: id,
									});
								}}
							/>
						))}
						<Card.MIntegrationSmallCard
							id="csv"
							label="IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.OPTIONS.CSV.LABEL"
							logo="https://ui-avatars.com/api/?name=csv&background=FFFFFF&color=002140&size=224&length=3"
							onClick={(id: string) => {
								setShowImportModal({
									show: true,
									integrationId: id,
								});
							}}
						/>
						{!!integrations?.data?.length && integrations?.data?.length < 2 && (
							<Card.MIntegrationSmallCard
								id="addIntegration"
								label="IMPORT_CONTACTS_MODAL.IMPORT_OPTIONS.OPTIONS.ADD_IMPORT_OPTIONS.LABEL"
								icon={PlusOutlined}
								onClick={() => {
									const url = routesConfig.dashboard.settings.integrations.overview.replace(
										":team",
										queryParameters.team
									);

									history.push(url);
								}}
							/>
						)}
					</div>
				)}
				<footer className="contacts-first_visit-footer">
					<Button onClick={() => setShowCreateModal && setShowCreateModal(true)} size="large">
						<FormattedMessage id="CONTACTS.OVERVIEW.INTRO.FOOTER_BUTTON" />
					</Button>
				</footer>
			</section>
		</DashboardCard>
	);
}
