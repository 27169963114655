import "./style.scss";

import { IEmailSignature } from "@bothive_core/database";
import { Button, Form, Modal, notification, Input, Select, Switch, Space } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useMemo } from "react";

import { modalTitle, modalSubmitButton } from "./config";
import { trpc } from "../../../../../trpc";
import FieldSideLabel from "@/shared/components/field/side_label";
import ContentEditor from "./contentEditor";
interface ISignatureModal {
	isOpen: boolean;
	signature?: IEmailSignature;
	isFirstSignature: boolean;
	onClose: () => void;
}
interface ISignatureForm {
	name: string;
	channelId?: string;
	isPrimary: boolean;
	content: string;
}
export default function SignatureModal({ signature, isOpen, onClose, isFirstSignature }: ISignatureModal) {
	const intl = useIntl();
	const [form] = Form.useForm<ISignatureForm>();

	const trpcUtils = trpc.useUtils();
	const createSignature = trpc.emailSignature.createSignature.useMutation();
	const updateSignature = trpc.emailSignature.updateSignature.useMutation();
	const deleteSignature = trpc.emailSignature.deleteSignature.useMutation();
	const channelsQuery = trpc.channel.getInboxChannels.useQuery(
		{ type: "email", limit: 0 },
		{ refetchOnWindowFocus: false, staleTime: Infinity }
	);

	useEffect(() => {
		if (!form) return;

		if (!signature) {
			form.resetFields();
			return;
		}

		form.setFieldsValue({
			name: signature.name,
			content: signature.content,
			isPrimary: signature.isPrimary || false,
			channelId: signature.channelId as string,
		});
	}, [signature]);

	const handleCreate = async () => {
		try {
			await createSignature.mutateAsync(form.getFieldsValue());

			notification.success({
				message: intl.formatMessage({
					id: "settings.email_signature.create.succeeded",
					defaultMessage: "Successfully saved your email signature",
				}),
				placement: "bottomRight",
			});
			trpcUtils.emailSignature.getSignatures.invalidate();
			onClose();
		} catch (_) {
			notification.error({
				message: intl.formatMessage({
					id: "settings.email_signature.create.failed",
					defaultMessage: "Something went wrong while saving your signature",
				}),
				placement: "bottomRight",
			});
		}
	};
	const handleUpdate = async () => {
		try {
			if (!signature) return;

			const values = form.getFieldsValue();

			await updateSignature.mutateAsync({
				...values,
				id: signature._id,
				channelId: values.channelId || undefined,
			});

			notification.success({
				message: intl.formatMessage({
					id: "settings.email_signature.update.succeeded",
					defaultMessage: "Successfully updated your email signature",
				}),
				placement: "bottomRight",
			});
			trpcUtils.emailSignature.getSignatures.invalidate();
			onClose();
		} catch (_) {
			notification.error({
				message: intl.formatMessage({
					id: "settings.email_signature.update.failed",
					defaultMessage: "Something went wrong while updating your signature",
				}),
				placement: "bottomRight",
			});
		}
	};
	const handleSubmit = async () => {
		if (signature) return handleUpdate();

		return handleCreate();
	};
	const handleDelete = async () => {
		if (!signature) return;

		try {
			await deleteSignature.mutateAsync({ id: signature._id });

			notification.success({
				message: intl.formatMessage({
					id: "settings.email_signature.delete.succeeded",
					defaultMessage: "Successfully deleted email signature",
				}),
				placement: "bottomRight",
			});
			trpcUtils.emailSignature.getSignatures.invalidate();
			onClose();
		} catch (error) {
			notification.error({
				message: intl.formatMessage({
					id: "settings.email_signature.delete.failed",
					defaultMessage: "Something went wrong while deleting your signature",
				}),
				placement: "bottomRight",
			});
		}
	};

	const isSubmitLoading = createSignature.isLoading || updateSignature.isLoading;
	const formatChannels = useMemo(
		() =>
			channelsQuery.data?.data?.map((channel) => {
				const label = channel.name === channel.address ? channel.name : `${channel.name} (${channel.address})`;

				return { value: channel._id, label };
			}),
		[channelsQuery.data?.data]
	);

	return (
		<Modal
			width={700}
			open={isOpen}
			destroyOnClose
			onCancel={onClose}
			title={intl.formatMessage(signature ? modalTitle.update : modalTitle.create)}
			className="signatures_modal"
			footer={
				<footer className="signatures_modal-footer">
					<Button type="text" onClick={onClose}>
						<FormattedMessage id="settings.email_signature.modal.button.cancel" defaultMessage="Cancel" />
					</Button>
					<div className="signatures_modal-footer-submit_wrapper">
						{signature && (
							<Button
								onClick={handleDelete}
								danger
								loading={deleteSignature.isLoading}
								disabled={isSubmitLoading}
							>
								<FormattedMessage
									id="settings.email_signature.modal.button.delete"
									defaultMessage="Delete"
								/>
							</Button>
						)}
						<Button
							type="primary"
							htmlType="submit"
							form="signatureForm"
							onClick={handleSubmit}
							loading={isSubmitLoading}
							disabled={deleteSignature.isLoading}
						>
							<FormattedMessage {...(signature ? modalSubmitButton.update : modalSubmitButton.create)} />
						</Button>
					</div>
				</footer>
			}
		>
			<Form
				form={form}
				preserve={false}
				layout="vertical"
				id="signatureForm"
				requiredMark="optional"
				initialValues={{ ...(isFirstSignature && { isPrimary: true }) }}
			>
				<Form.Item
					name="name"
					className="signatures_modal-form-name"
					label={intl.formatMessage({
						id: "settings.email_signature.modal.form.name.label",
						defaultMessage: "Name",
					})}
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "settings.email_signature.modal.form.name.error.required",
								defaultMessage: "Please add a name to your signature",
							}),
						},
						{
							max: 48,
							message: intl.formatMessage({
								id: "settings.email_signature.modal.form.name.error.max_length",
								defaultMessage: "name cannot be longer then 48 charachters",
							}),
						},
					]}
				>
					<Input maxLength={48} />
				</Form.Item>
				<FieldSideLabel
					name="isPrimary"
					label={intl.formatMessage({
						id: "settings.email_signature.modal.form.is_primary.label",
						defaultMessage: "Is primary",
					})}
					help={intl.formatMessage({
						id: "settings.email_signature.modal.form.is_primary.help",
						defaultMessage:
							"Primary signature will be set by default depending on your selected channel or not",
					})}
					className="signatures_modal-form-is_primary t-gap--bottom-sm"
				>
					<Form.Item name="isPrimary" valuePropName="checked">
						<Switch />
					</Form.Item>
				</FieldSideLabel>
				<Form.Item
					name="channelId"
					className="signatures_modal-form-channel-select"
					label={intl.formatMessage({
						id: "settings.email_signature.modal.form.channel.label",
						defaultMessage: "Channel",
					})}
				>
					<Select
						showSearch
						allowClear
						options={formatChannels}
						notFoundContent={null}
						placeholder={intl.formatMessage({
							id: "settings.email_signature.modal.form.channel.placeholder",
							defaultMessage: "Link signature to one of your channels",
						})}
					/>
				</Form.Item>
				<Form.Item
					name="content"
					label={intl.formatMessage({
						id: "settings.email_signature.modal.form.content.label",
						defaultMessage: "Content",
					})}
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: "settings.email_signature.modal.form.content.error.required",
								defaultMessage: "Please add signature content",
							}),
						},
					]}
				>
					<ContentEditor />
				</Form.Item>
			</Form>
		</Modal>
	);
}
