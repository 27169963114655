import "./style.scss";

import { Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { trpc } from "@/trpc";
import { useSelector } from "react-redux";
import { typesConfig } from "@/config";
import ContactSelect from "./components/ContactSelect";
import { selectedQuery } from "@/flows/send/components/ContactSelection/interface";
import { TProgress } from "@bothive_core/database";

const filterOptions = {
	label: {
		id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.LABEL",
		defaultValue: "Label",
	},
	contact: {
		id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.CONTACT",
		defaultValue: "Contact",
	},
	accountManager: {
		id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.ACCOUNT_MANAGER",
		defaultValue: "Account Manager",
	},
	progress: {
		id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.PROGRESS",
		defaultValue: "Progress",
	},
};

const progressFilters = [
	{
		id: typesConfig.sessionState.pendingSend,
		name: "PENDING_SEND",
	},
	{
		id: typesConfig.sessionState.created,
		name: "CREATED",
	},
	{
		id: typesConfig.sessionState.inviteSend,
		name: "INVITE_SEND",
	},
	{
		id: typesConfig.sessionState.inProgress,
		name: "IN_PROGRESS",
	},
	{
		id: typesConfig.sessionState.completed,
		name: "COMPLETED",
	},
];

export default function ContactFilter({
	setQuery,
	defaultProgress,
}: {
	setQuery: (query: selectedQuery) => void;
	defaultProgress?: TProgress[];
}) {
	const [selectedFilter, setSelectedFilter] = useState<string>(
		defaultProgress ? filterOptions["progress"].id : filterOptions["label"].id
	);
	const intl = useIntl();

	const { isLoading: isMembersFetching, data: members } = trpc.member.getAll.useQuery({ limit: 0 });
	const { data: contactLabels, isLoading: isLabelLoading } = trpc.contactLabel.all.useQuery({
		limit: 0,
		sort: {
			field: "name",
			order: "ascend",
		},
	});

	return (
		<div className="fs-flow_sending-contact_filter">
			<Select
				showArrow
				value={selectedFilter}
				notFoundContent={null}
				onChange={setSelectedFilter}
				className="filter_options"
			>
				{Object.values(filterOptions).map((filter) => (
					<Select.Option value={filter.id} key={filter.id}>
						<FormattedMessage id={filter.id} defaultMessage={filter.defaultValue} />
					</Select.Option>
				))}
			</Select>
			{selectedFilter === filterOptions["label"].id && (
				<Select
					showArrow
					showSearch
					allowClear
					mode="multiple"
					loading={isLabelLoading}
					notFoundContent={null}
					onChange={(values) =>
						setQuery({
							type: "label",
							value: values,
						})
					}
					className="filter_value"
					// value={value?.labels as string[]}
					placeholder={intl.formatMessage({ id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.LABEL.PLACEHOLDER" })}
					filterOption={(input, option: { key: string } | undefined) =>
						(option?.key ?? "").toLowerCase().includes(input.toLowerCase())
					}
				>
					{contactLabels?.data?.map((label) => (
						<Select.Option value={label._id} key={label.name}>
							<FormattedMessage id={label.name} />
						</Select.Option>
					))}
				</Select>
			)}
			{selectedFilter === filterOptions["contact"].id && (
				<ContactSelect
					onChange={(values) =>
						setQuery({
							type: "contact",
							value: values,
						})
					}
				/>
			)}
			{selectedFilter === filterOptions["accountManager"].id && (
				<Select
					showArrow
					showSearch
					allowClear
					mode="multiple"
					notFoundContent={null}
					onChange={(values) => setQuery({ type: "accountManager", value: values })}
					className="filter_value"
					// value={value?.accountManagers as string[]}
					loading={isMembersFetching}
					placeholder={intl.formatMessage({
						id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.ACCOUNT_MANAGER.PLACEHOLDER",
					})}
					filterOption={(input, option: { key: string } | undefined) =>
						(option?.key ?? "").toLowerCase().includes(input.toLowerCase())
					}
				>
					{members?.data
						?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
						// temp sort in frontend as members are still part of redux
						.map((item) => (
							<Select.Option value={item.userId} key={item.name}>
								{item.name}
							</Select.Option>
						))}
				</Select>
			)}
			{selectedFilter === filterOptions["progress"].id && (
				<Select
					showArrow
					allowClear
					mode="multiple"
					onChange={(values) => setQuery({ type: "progress", value: values })}
					notFoundContent={null}
					className="filter_value"
					placeholder={intl.formatMessage({
						id: "FLOWS_OVERVIEW.SEND_FLOW.MODAL.SELECT.PROGRESS.PLACEHOLDER",
					})}
					defaultValue={defaultProgress}
					filterOption={(input, option: { key: string } | undefined) =>
						(option?.key ?? "").toLowerCase().includes(input.toLowerCase())
					}
				>
					{progressFilters.map((progress) => (
						<Select.Option value={progress.id} key={progress.name}>
							<FormattedMessage id={progress.name} />
						</Select.Option>
					))}
				</Select>
			)}
		</div>
	);
}
