import Localbase from "localbase";

import { localDbConfig } from "../config";

const databases = {
	conversation: undefined,
	inbox: undefined,
};

export default {
	init() {
		Object.keys(localDbConfig.databaseName).forEach((key) => {
			if (databases[key]) {
				return;
			}

			databases[key] = new Localbase(localDbConfig.databaseName[key]);

			databases[key].config.debug = false;
		});
	},
	deleteAll() {
		return Promise.all(
			Object.keys(localDbConfig.databaseName).map((key) => {
				if (!databases[key]) {
					return;
				}

				return databases[key].delete();
			})
		);
	},
	getDatabase(key) {
		return databases[key];
	},
};
