import DashboardCard from "@/shared/components/card/dashboard";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { routesConfig } from "@/config/index.js";
import { Button } from "antd";

import "../../style.scss";

export default function RelatedSettings({ slug }: { slug: string }) {
	const history = useHistory();
	const redirect = () => {
		history.push(routesConfig.dashboard.settings.organization.general.replace(":team", slug));
	};

	return (
		<div className="settings_page-suggestions t-gap--top">
			<DashboardCard
				className="settings_page-suggestions-options"
				title="SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.TITLE"
			>
				<div className="cardContent">
					<p>
						<FormattedMessage
							id="SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.DESCRIPTION"
							defaultMessage="Manage your basic company settings"
						/>
					</p>
					<Button type="default" onClick={() => redirect()}>
						<FormattedMessage
							id="SETTINGS.SUGGESTIONS.COMPANY_SETTINGS.BUTTON"
							defaultMessage="Manage Company settings"
						/>
					</Button>
				</div>
			</DashboardCard>
		</div>
	);
}
