import { Progress, Skeleton, Tooltip } from "antd";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { routesConfig } from "@/config";
import helpers from "@/flows/common/helper";
import UserPreview from "@/shared/components/user/user_preview";

import style from "./style.module.scss";
import { ReportRouterOutput } from "@/trpc";

export default function ReportInfoSection({
	className,
	report,
	isLoading,
}: {
	className: string;
	isLoading: boolean;
	report?: ReportRouterOutput["getById"];
}) {
	const intl = useIntl();
	const routerParams = useParams<{ flowId: string; reportId: string; team: string }>();

	if (isLoading || !report) {
		return (
			<section className={`${style.report_info_section} ${className}`}>
				<Skeleton
					paragraph={{ rows: 2, width: "100%" }}
					title={false}
					active={isLoading}
					className="t-gap--bottom"
				/>
				<Skeleton
					paragraph={{ rows: 2, width: "100%" }}
					title={false}
					active={isLoading}
					className="t-gap--bottom"
				/>
				<Skeleton paragraph={{ rows: 2, width: "100%" }} title={false} active={isLoading} />
			</section>
		);
	}

	return (
		<section className={`${style.report_info_section} ${className}`}>
			{report.authorId && report.author?.fullName && report.authorId !== report.contactId && (
				<>
					<p>
						<FormattedMessage id="FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.AUTHOR.LABEL" />
					</p>
					<UserPreview
						id={report.authorId}
						src={report.author?.avatar}
						username={report.author?.fullName}
						isLoading={isLoading}
						className="t-gap--top-xs"
						contactUrl={
							report?.authorId &&
							routesConfig.dashboard.contact.detail.root
								.replace(":team", routerParams.team)
								.replace(":contactId", report?.authorId)
						}
					/>
				</>
			)}
			<p>
				<FormattedMessage id="FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.PROGRESS.LABEL" />
			</p>
			<Tooltip title={report.progress && intl.formatMessage({ id: report.progress })}>
				<Progress
					percent={helpers.calculateProgress(report.progress).percentage}
					strokeColor={helpers.calculateProgress(report.progress).color}
					showInfo={false}
					trailColor="rgba(#002140, 0.1)"
				/>
			</Tooltip>
			<p>
				<FormattedMessage id="FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.CREATED_AT.LABEL" />
			</p>
			<p>
				<FormattedDate value={report.createdAt} />
			</p>
			{report.lastFilledInAt && (
				<>
					<p>
						<FormattedMessage id="FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.LAST_FILLED_IN_AT.LABEL" />
					</p>
					<p>
						<FormattedDate
							value={report.lastFilledInAt}
							day="2-digit"
							month="2-digit"
							year="numeric"
							hour="2-digit"
							minute="2-digit"
							formatMatcher="basic"
						/>
					</p>
				</>
			)}
			<p>
				<FormattedMessage id="FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.UPDATED_AT.LABEL" />
			</p>
			<p>
				<FormattedDate
					value={report.updatedAt}
					day="2-digit"
					month="2-digit"
					year="numeric"
					hour="2-digit"
					minute="2-digit"
					formatMatcher="basic"
				/>
			</p>
			{report.lastSyncedToContactAt && (
				<>
					<p>
						<FormattedMessage id="FLOWS.REPORT_DETAIL.META_ASIDE.REPORT_INFO.LAST_SYNCED_TO_CONTACT_AT.LABEL" />
					</p>
					<p>
						<FormattedDate
							value={report.lastSyncedToContactAt}
							day="2-digit"
							month="2-digit"
							year="numeric"
							hour="2-digit"
							minute="2-digit"
							formatMatcher="basic"
						/>
					</p>
				</>
			)}
		</section>
	);
}

ReportInfoSection.defaultProps = {
	className: "",
};
