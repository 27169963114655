import { Button } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { FieldGroup } from "react-reactive-form";

import { formatHelper, keyEvents } from "../../../helpers";

export default function TFormTemplate({
	value,
	control,
	loading,
	disabled,
	hideSubmit,
	children,
	className,
	buttonLabel,
	buttonClassName,
	allowEnterSubmit,
	buttonChildren,
	onSubmit,
	onChange,
	onKeyDown,
}) {
	const [valuesChanged, setValuesChanged] = useState(false);
	const handleSubmit = (event) => {
		event.preventDefault();
		setValuesChanged(false);

		if (onSubmit) {
			onSubmit({ ...control.value });
		}
	};
	const handleChange = (event) => {
		setValuesChanged(true);
		if (onChange) {
			onChange({ event, value: control.value });
		}
	};
	const handleKeyPress = (event) => {
		onKeyDown && onKeyDown(event);

		if (keyEvents.enterPressed(event) && !keyEvents.shiftEnterPressed(event)) {
			event.preventDefault();

			if (allowEnterSubmit && control.status === "VALID") {
				handleSubmit(event);
				return;
			}
		}

		if (keyEvents.saveIsPressed(event) && control.status === "VALID") {
			event.preventDefault();

			handleSubmit(event);
			return;
		}
	};

	if (control && !valuesChanged) {
		control = formatHelper.mapValueToControl({ values: value, controls: control });
		control.setValue({ ...control.value });
	}

	return (
		<FieldGroup
			control={control}
			render={({ invalid }) => (
				<form // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
					className={`o-form ${className}`}
					onSubmit={handleSubmit}
					onKeyDown={handleKeyPress}
					onChange={handleChange}
					autoComplete="off"
					noValidate
				>
					{!children.length
						? React.cloneElement(children, { disabled })
						: children
								.flat()
								.map((child, index) => React.cloneElement(child, { disabled, key: `child-${index}` }))}
					<div className="o-form--button-wrapper">
						{buttonChildren({ invalid, disabled })}
						{!hideSubmit && (
							<Button
								type="primary"
								htmlType="submit"
								className={buttonClassName}
								loading={loading}
								disabled={disabled || invalid}
							>
								<FormattedMessage id={buttonLabel} />
							</Button>
						)}
					</div>
				</form>
			)}
		/>
	);
}

TFormTemplate.defaultProps = {
	value: undefined,
	control: undefined,
	loading: false,
	disabled: false,
	hideSubmit: false,
	allowEnterSubmit: true,
	children: undefined,
	onChange: undefined,
	onKeyDown: undefined,
	onSubmit: undefined,
	className: "",
	buttonLabel: "FORM_ELEMENT.BUTTON.DEFAULT",
	buttonClassName: "",
	buttonChildren: () => undefined,
};
