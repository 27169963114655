import "./style.scss";

import { Button, Form, Typography } from "antd";
import { FormattedMessage, defineMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { setupArrow } from "../../../assets/images/icon";
import ShowFailedBanner from "@/shared/components/banner/failed";
import FieldLabel from "@/shared/components/field/default";
import { ColorPicker } from "@/shared/components/input";
import { ImageUpload } from "@/shared/components/upload";
import { stringHelpers } from "@/shared/helpers";
import { trpc } from "../../../trpc";
import SetupSteps from "../components/SetupSteps";
import AsidePreview from "./asidePreview";

export default function InviteMembers() {
	const intl = useIntl();
	const history = useHistory();
	const [formRef] = Form.useForm();
	const routerParams = useParams<{ slug: string }>();
	const updateBranding = trpc.organization.updateBranding.useMutation();
	const { data } = trpc.organization.getOrganization.useQuery();

	const handleSaveBranding = async (branding) => {
		try {
			await updateBranding.mutateAsync(branding);

			handleGoToNextStep();
		} catch (_) {
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "setup.branding.update.failed",
					defaultMessage: "Could not update your organizations branding please try again later.",
				}),
				intl,
			});
		}
	};

	const handleGoToNextStep = () => {
		history.push(`/${routerParams.slug}/setup/flow`);
	};

	return (
		<main className="setup">
			<section className="setup-body">
				<SetupSteps currentStep={1} />

				<article className="setup_content">
					<header className="setup_content-header">
						<Typography.Title>
							<FormattedMessage id="setup.branding.title" defaultMessage="Branding" />
						</Typography.Title>
						<p>
							<FormattedMessage
								id="setup.branding.description"
								defaultMessage="Configure your branding to provide a unified experience for your customers"
							/>
						</p>
					</header>
					<Form
						id="setupBrandingForm"
						form={formRef}
						onFinish={handleSaveBranding}
						initialValues={data?.branding}
						className="setup_content-form setup-branding-form"
					>
						<div>
							<Typography.Title level={2}>
								<FormattedMessage
									id="setup.branding.icon_and_logo.title"
									defaultMessage="Icon and logo"
								/>
							</Typography.Title>
							<div className="setup-branding-form-icon_wrapper">
								<FieldLabel
									id="logo"
									label={intl.formatMessage({
										id: "setup.branding.icon_and_logo.logo.label",
										defaultMessage: "Logo",
									})}
								>
									<Form.Item
										name="logo"
										className="setup_content-form-field"
										extra={intl.formatMessage({
											id: "setup.branding.icon_and_logo.logo.help",
											defaultMessage: "Maximum logo size is 10MB",
										})}
										required
										rules={[
											{
												required: true,
												message: intl.formatMessage({
													id: "setup.branding.icon_and_logo.logo.is_required",
													defaultMessage: "Logo is required",
												}),
											},
										]}
									>
										<ImageUpload
											id="logo"
											crop={{ aspect: 16 / 9 }}
											config={{
												placeholder: defineMessage({
													id: "setup.branding.icon_and_logo.logo.placeholder",
													defaultMessage: "Pick your logo",
												}),
												overlay: defineMessage({
													id: "setup.branding.icon_and_logo.logo.overlay",
													defaultMessage: "Pick a other logo",
												}),
											}}
											className="setup-branding-form-logo_upload"
										/>
									</Form.Item>
								</FieldLabel>
								<FieldLabel
									id="icon"
									label={intl.formatMessage({
										id: "setup.branding.icon_and_logo.icon.label",
										defaultMessage: "Icon",
									})}
								>
									<Form.Item
										name="icon"
										className="setup_content-form-field"
										extra={intl.formatMessage({
											id: "setup.branding.icon_and_logo.icon.help",
											defaultMessage: "Maximum icon size is 10MB",
										})}
										required
										rules={[
											{
												required: true,
												message: intl.formatMessage({
													id: "setup.branding.icon_and_logo.icon.is_required",
													defaultMessage: "Icon is required",
												}),
											},
										]}
									>
										<ImageUpload
											id="icon"
											crop={{ aspect: 1 / 1 }}
											config={{
												placeholder: defineMessage({
													id: "setup.branding.icon_and_logo.icon.placeholder",
													defaultMessage: "Pick your icon",
												}),
												overlay: defineMessage({
													id: "setup.branding.icon_and_logo.icon.overlay",
													defaultMessage: "Pick a other icon",
												}),
											}}
										/>
									</Form.Item>
								</FieldLabel>
							</div>
						</div>
						<div className="setup-branding-form-colors">
							<Typography.Title level={2}>
								<FormattedMessage id="setup.branding.colors.title" defaultMessage="Colors" />
							</Typography.Title>
							<FieldLabel
								id="brandColor"
								label={intl.formatMessage({
									id: "setup.branding.colors.brand_color.label",
									defaultMessage: "Brand color",
								})}
							>
								<Form.Item
									name="brandColor"
									className="setup_content-form-field"
									required
									rules={[
										{
											validator: async (_, value) => {
												if (!value?.length || value === "#") {
													const message = intl.formatMessage({
														id: "setup.branding.colors.brand_color.is_required",
														defaultMessage: "Brand color is required",
													});

													return Promise.reject(new Error(message));
												}

												if (!stringHelpers.isValidHex(value)) {
													const message = intl.formatMessage({
														id: "setup.branding.colors.brand_color.invalid_color",
														defaultMessage: "Brand color is not a valid hex color",
													});

													return Promise.reject(new Error(message));
												}
											},
										},
									]}
								>
									<ColorPicker id="brandColor" />
								</Form.Item>
							</FieldLabel>
							<FieldLabel
								id="accentColor"
								label={intl.formatMessage({
									id: "setup.branding.colors.accent_color.label",
									defaultMessage: "Accent color",
								})}
							>
								<Form.Item
									name="accentColor"
									className="setup_content-form-field"
									required
									rules={[
										{
											validator: async (_, value) => {
												if (!value?.length || value === "#") {
													const message = intl.formatMessage({
														id: "setup.branding.colors.accent_color.is_required",
														defaultMessage: "Accent color is required",
													});

													return Promise.reject(new Error(message));
												}

												if (!stringHelpers.isValidHex(value)) {
													const message = intl.formatMessage({
														id: "setup.branding.colors.accent_color.invalid_color",
														defaultMessage: "Accent color is not a valid hex color",
													});

													return Promise.reject(new Error(message));
												}
											},
										},
									]}
								>
									<ColorPicker id="accentColor" />
								</Form.Item>
							</FieldLabel>
							<FieldLabel
								id="textColor"
								label={intl.formatMessage({
									id: "setup.branding.colors.text_color.label",
									defaultMessage: "Text color",
								})}
							>
								<Form.Item
									name="textColor"
									className="setup_content-form-field"
									required
									rules={[
										{
											validator: async (_, value) => {
												if (!value?.length || value === "#") {
													const message = intl.formatMessage({
														id: "setup.branding.colors.text_color.is_required",
														defaultMessage: "Text color is required",
													});

													return Promise.reject(new Error(message));
												}

												if (!stringHelpers.isValidHex(value)) {
													const message = intl.formatMessage({
														id: "setup.branding.colors.text_color.invalid_color",
														defaultMessage: "Text color is not a valid hex color",
													});

													return Promise.reject(new Error(message));
												}
											},
										},
									]}
								>
									<ColorPicker id="textColor" />
								</Form.Item>
							</FieldLabel>
						</div>
						<footer className="setup_content-footer">
							<Button
								size="large"
								type="primary"
								htmlType="submit"
								form="setupBrandingForm"
								loading={updateBranding.isLoading}
								className="setup_content-footer-button"
							>
								<FormattedMessage id="setup.branding.button.invite" defaultMessage="Save branding" />
							</Button>
							<Button
								type="text"
								size="large"
								htmlType="button"
								onClick={handleGoToNextStep}
								disabled={updateBranding.isLoading}
								className="setup_content-footer-button"
							>
								<FormattedMessage id="setup.branding.button.skip" defaultMessage="Skip" />
							</Button>
						</footer>
					</Form>
				</article>
			</section>
			<section className="setup_aside">
				<header className="setup_aside-header">
					<p className="setup_aside-header-title">
						<FormattedMessage
							id="setup.branding.aside.title"
							defaultMessage="Example how we will use your branding"
						/>
					</p>
					<img src={setupArrow} alt="" className="setup_aside-header-icon" />
				</header>
				<AsidePreview
					brandColor={Form.useWatch("brandColor", formRef)}
					textColor={Form.useWatch("textColor", formRef)}
					icon={Form.useWatch("icon", formRef)}
				/>
				<p className="setup_aside-help">
					<FormattedMessage id="setup.branding.aside.help" defaultMessage="Personal income tax 2024 flow" />
				</p>
			</section>
		</main>
	);
}
