import { Button, Divider, Empty, Select, SelectProps, Space, Spin } from "antd";
import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

import CreateContactLabelModal from "../../../settings/contact/labels/components/CreateContactLabelModal";
import Tag from "../antdWrappers/Tag";

interface IContactLabelsSelector extends SelectProps {
	isLabelLoading: boolean;
	contactLabels: any[] | undefined;
	initialValue?: string | undefined;
	onClear?: () => void;
	onSearch?: (value: string) => void;
	onChange?: (value: string) => void;
	size?: "large" | "middle" | "small";
	showAddButton?: boolean;
}

export default function ContactLabelsSelector({
	isLabelLoading,
	contactLabels,
	showAddButton = false,
	size = "large",
	onClear,
	onSearch,
	onChange,
	...antDProps
}: IContactLabelsSelector) {
	const [showCreateLabelModal, setShowCreateLabelModal] = React.useState<boolean>(false);
	const [createdLabel, setCreatedLabel] = React.useState<string | undefined>(undefined);
	const [labelSearchValue, setLabelSearchValue] = useState<string | undefined>(undefined);

	const handleLabelCreated = (labelId: string) => {
		setCreatedLabel(labelId);
		onChange && onChange(labelId);
	};

	return (
		<>
			<Select
				showSearch
				size={size}
				onClear={onClear}
				popupMatchSelectWidth={false}
				onSearch={(value) => {
					setLabelSearchValue(value);
					onSearch && onSearch(value);
				}}
				allowClear={!!onClear}
				className="loading_select"
				notFoundContent={
					isLabelLoading ? (
						<Spin size="small" />
					) : (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={
								<p>
									<FormattedMessage
										id="contact_label_selector.empty"
										defaultMessage="No labels found"
									/>
								</p>
							}
						/>
					)
				}
				dropdownRender={(menu) => (
					<>
						{menu}
						{showAddButton && (
							<>
								<Divider style={{ margin: "8px 0" }} />
								<Space style={{ padding: "0 8px 4px" }}>
									<Button
										type="text"
										icon={<PlusOutlined />}
										onClick={() => setShowCreateLabelModal(true)}
									>
										{" "}
										<FormattedMessage
											id="contact_label_selector.add_label"
											defaultMessage="Add label"
										/>
									</Button>
								</Space>
							</>
						)}
					</>
				)}
				value={createdLabel}
				filterOption={(input, option) =>
					// @ts-ignore
					option?.children?.props?.children.toLowerCase().includes(input.toLowerCase())
				}
				onChange={onChange}
				{...antDProps}
			>
				{contactLabels?.map((item: { _id?: string; name?: string; color: string }) => (
					<Select.Option
						key={item?._id}
						value={item?._id}
						style={{ lineHeight: 0, margin: "auto 0" }}
						className="filter-dropdown-item"
					>
						<Tag
							title={item.name}
							key={item._id}
							color={item.color}
							style={{
								maxWidth: "170px",
								textOverflow: "ellipsis",
								overflowX: "hidden",
								whiteSpace: "nowrap",
								margin: "auto 0 auto",
							}}
						>
							{item.name}
						</Tag>
					</Select.Option>
				))}
			</Select>
			{showAddButton && (
				<CreateContactLabelModal
					open={showCreateLabelModal}
					setShowCreateModal={setShowCreateLabelModal}
					setCreatedLabel={handleLabelCreated}
					labelSearchValue={labelSearchValue}
				/>
			)}
		</>
	);
}
