import "./style.scss";

import { Form, Input } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Component } from "@/components/atoms";
import { timingConfig } from "@/config";
import { debounce } from "@/helpers";
import { actions as contentActions } from "@/store/content";
import helpers from "../helpers";
import config from "./config";

export default function DeveloperSettings({ className }) {
	const intl = useIntl();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const state = useSelector((state) => ({
		billing: state.content.team.team.billing,
	}));

	const handleChange = (changedFields) => {
		debounce(() => {
			const formErrors = form.getFieldsError();

			if (formErrors.find((field) => field.errors?.length)) {
				return;
			}

			const fields = helpers.formatChangedFields(changedFields);

			dispatch(contentActions.team.updateBillingInformation({ ...state.billing, ...fields }));
		}, timingConfig.keyboardTimeout);
	};

	return (
		<Component.ADashboardCard
			title={config.title}
			className={`page-notification-settings-billing ${className || ""}`}
		>
			<Form
				form={form}
				autoComplete="off"
				layout="vertical"
				onFieldsChange={handleChange}
				initialValues={{ email: state.billing?.email }}
			>
				<Form.Item
					name="email"
					className="billing-notification--form-item"
					rules={[
						{ type: "email", message: intl.formatMessage({ id: config.fieldConfig.email.emailError }) },
					]}
					label={<FormattedMessage id={config.fieldConfig.email.label} />}
				>
					<Input
						type="email"
						className="billing-notification--input t-gap--right-sm"
						placeholder={intl.formatMessage({ id: config.fieldConfig.email.placeholder })}
					/>
				</Form.Item>
			</Form>
		</Component.ADashboardCard>
	);
}
