import { useIntl } from "react-intl";
import { TConversationWithContactResponse } from "../..";
import { Collapse, CollapseProps, Divider, Tag, Tooltip, Typography } from "antd";
import { CSSProperties } from "react";
import ContactConversationPanelHeader from "../ContactConversationPanelHeader";
import ContactConversationPanelChildren from "../ContactConversationPanelChildren";
import { CalendarFilled, SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import "./style.scss";

interface DayOverviewProps {
	conversations: TConversationWithContactResponse;
	groupKey: string;
}

export default function DayOverview({ groupKey, conversations }: DayOverviewProps) {
	const intl = useIntl();
	const groupKeyIsDate = !Number.isNaN(Date.parse(groupKey));
	const canReadAllContactConversations = useSelector(
		(state) => state.dashboard.permissions.userPermissions.readAllContactConversations
	);

	const formattedGroupKey = groupKeyIsDate
		? new Intl.DateTimeFormat(intl.locale, {
				year: "numeric",
				month: "short",
				day: "numeric",
		  }).format(new Date(groupKey))
		: groupKey;

	const panelStyle: CSSProperties = {
		background: "white",
		border: "1px solid var(--grey-500)",
		borderRadius: "var(--rounded)",
		marginBottom: "var(--spacer-sm)",
	};

	const conversationPanels: CollapseProps["items"] = conversations.map((conversation) => ({
		key: conversation._id,
		showArrow: false,
		style: panelStyle,
		label: (
			<ContactConversationPanelHeader
				showParticipantIndicator={canReadAllContactConversations}
				{...conversation}
			/>
		),
		children: <ContactConversationPanelChildren {...conversation} />,
	}));

	return (
		<li className="do-day-overview">
			<Tag color="var(--primary)" className="do-day-overview__date-tag">
				{groupKeyIsDate ? <CalendarFilled /> : <SearchOutlined />}
				<Typography.Text style={{ color: "inherit", fontSize: "inherit" }} strong>
					{formattedGroupKey}
				</Typography.Text>
			</Tag>
			<Tooltip title={formattedGroupKey} placement="right">
				<Divider className="do-day-overview__line" type="vertical" />
			</Tooltip>
			<Collapse ghost className="do-day-overview__conversation-list" items={conversationPanels} />
		</li>
	);
}
