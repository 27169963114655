import { FormBuilder, Validators } from "react-reactive-form";

import validator from "../helpers/validators.helpers";

function getValidator(key) {
	const cleanKey = key.replace(/\([0-9a-zA-Z]+\)/g, "");

	switch (cleanKey) {
		case "required":
			return Validators.required;
		case "minLength":
			return Validators.minLength(Number(key.replace(/minLength|\)|\(/g, "")));
		case "maxLength":
			return Validators.maxLength(Number(key.replace(/maxLength|\)|\(/g, "")));
		case "templateLength":
			return validator.templateLength(Number(key.replace(/templateLength|\)|\(/g, "")));
		case "email":
			return Validators.email;
		case "fidUsername":
			return validator.fidUsername;
		case "botNotificationTrigger":
			return validator.botNotificationTrigger;
		case "valueIsDELETE":
			return validator.valueIsDELETE;
		case "toggleIsTrue":
			return validator.toggleIsTrue;
		case "valueIsCancelSubscription":
			return validator.valueIsCancelSubscription;
		case "valueIsDowngrade":
			return validator.valueIsDowngrade;
		case "imageIsUploading":
			return validator.imageIsUploading;
		case "vatValidation":
			return validator.vatValidation;
		case "asyncNameValidation":
			return validator.asyncNameValidation(key.replace(/asyncNameValidation|\)|\(/g, ""));
		case "numberValidator":
			return validator.numberValidator;
		case "passwordValidator":
			return validator.passwordValidator;
		case "comparePasswords":
			return validator.comparePasswords;
		case "uniqueContactLabel":
			return validator.uniqueContactLabel;
		case "uniqueEmail":
			return validator.uniqueEmail;
		case "hasUppercase":
			return validator.hasUppercase;
		case "multiLanguage":
			return validator.multiLanguage;
		case "languageIteratorOneRequired":
			return validator.languageIteratorOneRequired;
		case "objectRequired":
			return validator.objectRequired;
		case "conflictedSystemLabels":
			return validator.conflictedSystemLabels;
		default:
			return "";
	}
}

function generateDefaultValue(item) {
	if (item.length === 1) {
		return item;
	}

	const validation = Array.isArray(item[1]) ? item[1].map((key) => getValidator(key)) : getValidator(item[1]);
	const asyncValidator = item.length === 3 ? getValidator(item[2]) : undefined;

	return [item[0], validation, asyncValidator];
}

export default {
	generateFormBuilder: (template) =>
		FormBuilder.group(
			template
				.filter((item) => item.key)
				.reduce((prev, curr) => ({ ...prev, [curr.id]: generateDefaultValue(curr.defaultValue) }), {})
		),
	questionSettings: {
		generateBuilder: (template) =>
			FormBuilder.group(template.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.defaultValue }), {})),
	},
	user: {
		invite: FormBuilder.group({
			authorizationLevel: ["", Validators.required],
			email: ["", Validators.required],
		}),
	},
};
