import type { IContact } from "@bothive_core/database";
import { Alert, Button, Form, Input, InputRef, Modal, notification, Select } from "antd";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { routesConfig } from "../../../../config";
import ShowFailedBanner from "../../../../shared/components/banner/failed";
import ShowSuccessBanner from "../../../../shared/components/banner/success";
import FieldLabel from "../../../../shared/components/field/default";
import { trpc } from "../../../../trpc";
import ContactLabels from "../../../../shared/components/ContactLabelsSelector";
import { ExistingContactDisplay } from "../../components/existingContactDisplay";
import { TConflictDetails, TFormContactFields } from "../../types";

const getConflictingFieldsIntl = defineMessages<keyof TFormContactFields>({
	email: { id: "CONTACTS.OVERVIEW.ADD.MODAL.EMAIL" },
	nationalRegistrationNumber: { id: "CONTACTS.OVERVIEW.ADD.MODAL.NATIONALREGISTRATIONNUMBER" },
});

export default function CreateModal({
	setShowModal,
	open,
	setCreatedContact,
	navigateToCreatedContact,
	contactType,
	initialName,
	isCreatingLinkedContact,
}: {
	setShowModal: (value: boolean) => void;
	open: boolean;
	setCreatedContact?: (value: any) => void;
	navigateToCreatedContact?: boolean;
	contactType?: string;
	initialName?: string;
	isCreatingLinkedContact?: boolean;
}) {
	const intl = useIntl();
	const history = useHistory();
	const [form] = Form.useForm();
	const trpcUtils = trpc.useUtils();
	const inputRef = useRef<InputRef>(null);
	const [labelSearch, setLabelSearch] = useState("");
	const team = useSelector((state) => state.content.team.team);
	const [conflict, setConflict] = useState<TConflictDetails | null>(null);
	const [isNationalRegistrationNumberRequired, setIsNationalRegistrationNumberRequired] = useState(true);

	const { data: contactLabels, isLoading: isLabelLoading } = trpc.contactLabel.all.useQuery({
		limit: 0, // allow unlimited labels as there is no pagination
		q: labelSearch,
	});

	const addContactMutation = trpc.contact.create.useMutation();

	useEffect(() => {
		if (inputRef?.current) {
			setTimeout(() => {
				inputRef?.current?.focus();
			}, 100);
		}
	}, [inputRef, open]);

	useEffect(() => {
		const spaceIndex = initialName?.indexOf(" ");

		// If the space is not found, return the full string
		if (spaceIndex === -1) {
			form.setFieldsValue({ firstName: initialName });
			return;
		}

		if (spaceIndex) {
			form.setFieldsValue({
				firstName: initialName?.substring(0, spaceIndex),
				lastName: initialName?.substring(spaceIndex + 1),
			});
		}
	}, [initialName]);

	const handleSubmit = async (values: any) => {
		let body = {
			...values,
			labels: values.labels ? [values.labels] : [],
			type: contactType || values?.type || "customer",
		};

		try {
			const result = await addContactMutation.mutateAsync(body);

			ShowSuccessBanner({
				title: intl.formatMessage({
					id: "CONTACT.OVERVIEW.ADD.MODAL.SUCCESS",
					defaultMessage: "Contact created",
				}),
			});

			setConflict(null);

			if (setCreatedContact) {
				setCreatedContact(result);
			}
			setShowModal(false);

			if (navigateToCreatedContact) {
				history.push(
					routesConfig.dashboard.contact.detail.root
						.replace(":contactId", result._id)
						.replace(":team", team.slug)
				);
			}

			trpcUtils.contact.getAllContacts.invalidate();

			// reset state form
			form.resetFields();
		} catch (err) {
			console.error("Failed to add contact", err);

			const error = err as any;

			if (error?.data?.code === "BAD_REQUEST") {
				const details = error?.shape?.message ? (JSON.parse(error?.shape?.message) as TConflictDetails) : null;

				setConflict(details);
				return;
			}

			if (error?.data?.code === "CONFLICT") {
				const details = error?.shape?.message ? (JSON.parse(error?.shape?.message) as TConflictDetails) : null;

				setConflict(details);
				return;
			}

			ShowFailedBanner({
				title: intl.formatMessage({
					id: "CONTACT.OVERVIEW.ADD.MODAL.FAILED",
					defaultMessage: "Failed to create contact",
				}),
				description: intl.formatMessage({
					id: "CONTACT.OVERVIEW.ADD.MODAL.FAILED.DESCRIPTION",
					defaultMessage: "Please check if a contact with this email already exists",
				}),
			});

			setConflict(null);
			setShowModal(false);
			form.resetFields();
		}
	};

	const _isCreatingLinkedContact = isCreatingLinkedContact || Form.useWatch("type", form) === "linkedContact";

	return (
		<Modal
			title={intl.formatMessage({ id: "CONTACTS.OVERVIEW.ADD.MODAL.TITLE" })}
			open={open}
			onCancel={() => setShowModal(false)}
			width={700}
			destroyOnClose
			transitionName="" // to disable animation
			footer={
				<Button key="submit" type="primary" form="addContactForm" htmlType="submit">
					<FormattedMessage id="FLOWS.REPORTS.STATUS_TYPES.MODAL.ADD" />
				</Button>
			}
		>
			{!!conflict?.conflictingFields?.length && (
				<ExistingContactConflictBanner
					contact={conflict.existingContact}
					{...conflict}
					onResolvedConflict={() => {
						form.resetFields();
						setConflict(null);
						setShowModal(false);
					}}
				/>
			)}
			<Form
				form={form}
				layout="vertical"
				className={clsx("frsf-form", !!conflict?.conflictingFields?.length && "t-gap--top")}
				id="addContactForm"
				onFinish={(values) => handleSubmit(values)}
			>
				{!contactType && (
					<FieldLabel label="CONTACTS.OVERVIEW.ADD.MODAL.TYPE">
						<Form.Item name="type" style={{ width: "100%", textAlign: "left", marginBottom: 8 }}>
							<Select
								className="loading_select"
								allowClear={false}
								onChange={(value) => {
									if (value === "customer") {
										setIsNationalRegistrationNumberRequired(true);

										return;
									}

									setIsNationalRegistrationNumberRequired(false);
								}}
								defaultValue="customer"
							>
								<Select.Option value="customer" className="filter-dropdown-item">
									<FormattedMessage id="contact.overview.select.type.customer" />
								</Select.Option>
								<Select.Option value="prospect" className="filter-dropdown-item">
									<FormattedMessage id="contact.overview.select.type.prospect" />
								</Select.Option>
								<Select.Option value="linkedContact" className="filter-dropdown-item">
									<FormattedMessage id="contact.overview.select.type.linked_contact" />
								</Select.Option>
								<Select.Option value="other" className="filter-dropdown-item">
									<FormattedMessage id="contact.overview.select.type.other" />
								</Select.Option>
							</Select>
						</Form.Item>
					</FieldLabel>
				)}
				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						marginBottom: 8,
					}}
				>
					<FieldLabel label="CONTACTS.OVERVIEW.ADD.MODAL.FIRSTNAME">
						<Form.Item
							name="firstName"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "CONTACTS.OVERVIEW.ADD.MODAL.FIRSTNAME.REQUIRED",
									}),
								},
							]}
							style={{ width: "96%", textAlign: "left" }}
						>
							<Input ref={inputRef} />
						</Form.Item>
					</FieldLabel>
					<FieldLabel label="CONTACTS.OVERVIEW.ADD.MODAL.LASTNAME">
						<Form.Item
							name="lastName"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "CONTACTS.OVERVIEW.ADD.MODAL.LASTNAME.REQUIRED",
									}),
								},
							]}
							style={{ width: "100%", textAlign: "left" }}
						>
							<Input />
						</Form.Item>
					</FieldLabel>
				</div>
				<FieldLabel label="CONTACTS.OVERVIEW.ADD.MODAL.EMAIL" optional={_isCreatingLinkedContact}>
					<Form.Item
						name="email"
						rules={[
							{
								required: _isCreatingLinkedContact ? false : true,
								type: "email",
								message: intl.formatMessage({
									id: "CONTACTS.OVERVIEW.ADD.MODAL.EMAIL.REQUIRED",
								}),
							},
						]}
						style={{ width: "100%", textAlign: "left", marginBottom: 8 }}
					>
						<Input />
					</Form.Item>
				</FieldLabel>
				<FieldLabel
					label="CONTACTS.OVERVIEW.ADD.MODAL.NATIONALREGISTRATIONNUMBER"
					optional={!(_isCreatingLinkedContact ? false : isNationalRegistrationNumberRequired)}
				>
					<Form.Item
						name="nationalRegistrationNumber"
						rules={[
							{
								required: _isCreatingLinkedContact ? false : isNationalRegistrationNumberRequired,
								message: intl.formatMessage({
									id: "CONTACTS.OVERVIEW.ADD.MODAL.NATIONALREGISTRATIONNUMBER.REQUIRED",
								}),
							},
						]}
						style={{ width: "100%", textAlign: "left", marginBottom: 8 }}
					>
						<Input placeholder="94.04.22-000.00" />
					</Form.Item>
				</FieldLabel>
				<FieldLabel label="CONTACTS.OVERVIEW.ADD.MODAL.LABELS" optional={true}>
					<Form.Item name="labels" style={{ width: "100%", textAlign: "left", marginBottom: 8 }}>
						<ContactLabels
							contactLabels={contactLabels?.data}
							isLabelLoading={isLabelLoading}
							style={{ width: "100%" }}
							onSearch={setLabelSearch}
							size="middle"
							showAddButton
							allowClear={true}
						/>
					</Form.Item>
				</FieldLabel>
			</Form>
		</Modal>
	);
}

export function ExistingContactConflictBanner({
	contact,
	archivedAt,
	deletedAt,
	conflictingFields,
	onResolvedConflict,
}: {
	contact: IContact;
	archivedAt?: Date | undefined;
	deletedAt?: Date | undefined;
	conflictingFields: string[];
	onResolvedConflict: () => void;
}) {
	const intl = useIntl();
	const trpcUtils = trpc.useUtils();
	const { team } = useParams<{ team: string }>();
	const VIEW_CONTACT_URL = `/${team}/contact/id/${contact._id}`;

	const CONFLICTING_FIELDS_STRING = new Intl.ListFormat(intl.locale, { style: "short", type: "conjunction" }).format(
		conflictingFields.map((field) => intl.formatMessage(getConflictingFieldsIntl[field]).toLowerCase())
	);

	const restoreContactMutation = trpc.contact.restoreContact.useMutation({
		onSuccess: () => {
			notification.success({
				message: intl.formatMessage({
					id: "CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_DELETED.RESTORE.SUCCESS",
					defaultMessage: "Contact restored successfully.",
				}),
				placement: "bottomRight",
			});

			trpcUtils.contact.getAllContacts.invalidate();
			onResolvedConflict();
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_DELETED.RESTORE.ERROR",
					defaultMessage: "Something went wrong while executing restore.",
				}),
				placement: "bottomRight",
			});
		},
	});

	const unarchiveContactMutation = trpc.contact.dearchiveContact.useMutation({
		onSuccess: () => {
			notification.success({
				message: intl.formatMessage({
					id: "CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_ARCHIVED.UNARCHIVE.SUCCESS",
					defaultMessage: "Contact unarchived successfully.",
				}),
				placement: "bottomRight",
			});
			onResolvedConflict();
		},
		onError: () => {
			notification.error({
				message: intl.formatMessage({
					id: "CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_ARCHIVED.UNARCHIVE.ERROR",
					defaultMessage: "Something went wrong while executing unarchive.",
				}),
				placement: "bottomRight",
			});
		},
	});

	if (!archivedAt && !deletedAt) {
		return (
			<>
				<Alert
					message={intl.formatMessage(
						{
							id: "CONTACT.OVERVIEW.ADD.MODAL.FAILED.DUPLICATE",
						},
						{ field: CONFLICTING_FIELDS_STRING }
					)}
					description={intl.formatMessage(
						{ id: "CONTACT.OVERVIEW.ADD.MODAL.FAILED.DUPLICATE.DESCRIPTION" },
						{ field: CONFLICTING_FIELDS_STRING }
					)}
					type="error"
					showIcon
					className="t-gap--bottom-sm"
				/>
				<ExistingContactDisplay contact={contact} />
			</>
		);
	}

	if (deletedAt) {
		return (
			<Alert
				message={intl.formatMessage(
					{
						id: "CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_DELETED",
						defaultMessage: "A contact with this {fields} is currently deleted",
					},
					{ fields: CONFLICTING_FIELDS_STRING }
				)}
				showIcon
				type="warning"
				action={[
					<Button key="view" type="link" href={VIEW_CONTACT_URL}>
						<FormattedMessage
							id="CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.VIEW_CONTACT"
							defaultMessage="View contact"
						/>
					</Button>,
					<Button
						type="text"
						key="restore"
						size="small"
						loading={restoreContactMutation.isLoading}
						onClick={() => restoreContactMutation.mutate({ contactId: contact._id, unarchive: true })}
					>
						<FormattedMessage
							id="CONTACTS.OVERVIEW.BULK_ACTION.RESTORE.BUTTON.TITLE"
							defaultMessage="Restore"
						/>
					</Button>,
				]}
			/>
		);
	}

	return (
		<Alert
			message={intl.formatMessage(
				{
					id: "CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.ALREADY_ARCHIVED",
					defaultMessage: "A contact with this {fields} is currently archived",
				},
				{ fields: CONFLICTING_FIELDS_STRING }
			)}
			showIcon
			type="warning"
			action={[
				<Button key="view" type="link" href={VIEW_CONTACT_URL}>
					<FormattedMessage
						id="CONTACTS.OVERVIEW.ADD.MODAL.CONFLICT.VIEW_CONTACT"
						defaultMessage="View contact"
					/>
				</Button>,
				<Button
					type="text"
					size="small"
					key="unarchive"
					loading={unarchiveContactMutation.isLoading}
					onClick={() => unarchiveContactMutation.mutate({ contactId: contact._id })}
				>
					<FormattedMessage
						id="CONTACTS.OVERVIEW.BULK_ACTION.UNARCHIVE.BUTTON.TITLE"
						defaultMessage="Unarchive"
					/>
				</Button>,
			]}
		/>
	);
}
