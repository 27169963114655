export function updateStatusTypeList({ updatedStatus, list }) {
	return list.map((prevStatus) => {
		// If current status type is converted to default, mark the current one as not default
		if (prevStatus.isDefault && updatedStatus.isDefault && prevStatus._id !== updatedStatus._id) {
			return { ...prevStatus, isDefault: false };
		}

		return prevStatus._id === updatedStatus._id ? updatedStatus : prevStatus;
	});
}

export default {
	updateStatusTypeList,
};
