import { Divider, Empty, List } from "antd";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { routesConfig } from "@/flows/common/config";
import { selectedFlowIdState } from "@/flows/common/state";
import { getFlowReports } from "@/flows/flow_detail/reports/query";
import {
	searchFilterState,
	selectedProgressState,
	selectedStatusTypeState,
	selectedContactLabelState,
	selectedAccountManagerState,
} from "@/flows/flow_detail/reports/state";
import { isEditingReportState, reportState, selectedReportIdState } from "@/flows/report_detail/state";
import ShowFailedBanner from "@/shared/components/banner/failed";
import { generatorHelpers } from "@/shared/helpers";

import config from "../config";
import ReportListItem from "./report_list_item";
import SkeletonListItem from "./skeleton_list_item";
import style from "./style.module.scss";

export default function ReportList() {
	const intl = useIntl();
	const history = useHistory();
	const routerParameters = useParams();
	const flowId = useRecoilValue(selectedFlowIdState);
	const skeletonList = useRef(generatorHelpers.skeletonList({ max: 10, min: 4 }));
	const searchFilter = useRecoilValue(searchFilterState);
	const setIsEditing = useSetRecoilState(isEditingReportState);
	const selectedProgress = useRecoilValue(selectedProgressState);
	const selectedStatus = useRecoilValue(selectedStatusTypeState);
	const selectedContactLabel = useRecoilValue(selectedContactLabelState);
	const [reportId, setReportId] = useRecoilState(selectedReportIdState);
	const selectedAccountManager = useRecoilValue(selectedAccountManagerState);
	const [reports, setReports] = useState([]);
	const [isFetching, setIsFetching] = useState(false);
	const [reportMetaData, setReportMetaData] = useState({
		limit: config.fetchLimit,
		offset: 0,
		total: 0,
	});

	useEffect(() => {
		handleFetchReports();
	}, [searchFilter, selectedProgress, selectedStatus, selectedContactLabel, selectedAccountManager]);

	const handleFetchReports = async () => {
		const result = await fetchReports({ offset: 0 });

		if (!result) {
			return;
		}

		setReports(result.data);
		setReportMetaData(result.meta);
	};

	const fetchNext = async () => {
		const offset = reportMetaData.offset + reportMetaData.limit;
		const result = await fetchReports({ offset });

		if (!result) {
			return;
		}

		setReports([...reports, ...result.data]);
		setReportMetaData(result.meta);
	};

	const fetchReports = async ({ offset } = { offset: undefined }) => {
		setIsFetching(true);

		try {
			// cannot use the reports hook because we use it for antD which re fetches the data on each pagination
			// TODO change this to use same state
			const result = await getFlowReports({
				...reportMetaData,
				flowId,
				q: searchFilter,
				status: selectedStatus,
				progress: selectedProgress,
				accountManager: selectedAccountManager,
				contactLabel: selectedContactLabel,
				limit: config.fetchLimit,
				offset,
			});

			setIsFetching(false);

			return result;
		} catch (_) {
			setIsFetching(false);
			ShowFailedBanner({
				title: intl.formatMessage({
					id: "FLOWS.REPORT_DETAIL.REPORT_LIST.FETCH_FAILED.TITLE",
					defaultMessage: "Reports could not be fetched",
				}),
			});
		}
	};

	const handleClick = useRecoilCallback(({ snapshot }) => (reportId) => {
		setIsEditing(false);

		const report = reports.find(({ _id }) => _id === reportId);
		const url = routesConfig.reportDetail
			.replace(":team", routerParameters.team)
			.replace(":flowId", report.flowId)
			.replace(":reportId", report._id);

		setReportId(reportId);
		snapshot.getLoadable(reportState({ flowId: report.flowId, reportId })); // pre-fetch report

		history.push(url);
	});

	if (!reports?.length && isFetching) {
		return <List dataSource={skeletonList.current} renderItem={() => <SkeletonListItem />} />;
	}

	if (!reports?.length) {
		return (
			<div id="reportList" className={style.report_list}>
				<Empty
					image={Empty.PRESENTED_IMAGE_SIMPLE}
					description={<FormattedMessage id="FLOWS.REPORT_DETAIL.REPORT_LIST.FALLBACK.DESCRIPTION" />}
					className={style.report_list_empty}
				/>
			</div>
		);
	}

	return (
		<div id="reportList" className={style.report_list}>
			<InfiniteScroll
				next={fetchNext}
				scrollThreshold={0.75}
				dataLength={reports.length}
				scrollableTarget="reportList"
				loader={<SkeletonListItem />}
				hasMore={reports.length < reportMetaData.total}
				endMessage={
					reports.length > config.fetchLimit && (
						<Divider plain>
							<FormattedMessage id="FLOWS.REPORT_DETAIL.REPORT_LIST.END_OF_LIST" />
						</Divider>
					)
				}
			>
				<List
					dataSource={reports}
					renderItem={(report) => (
						<ReportListItem
							{...report}
							id={report._id}
							contact={report.contact}
							onClick={handleClick}
							selected={report._id === reportId}
						/>
					)}
				/>
			</InfiniteScroll>
		</div>
	);
}
