import "./style.scss";

import {
	AutoComplete,
	Avatar,
	Badge,
	Button,
	DatePicker,
	Divider,
	Flex,
	Form,
	Input,
	Select,
	Space,
	Switch,
	Typography,
} from "antd";
import { ReactNode, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDebounce } from "use-debounce";
import { ControlOutlined, FileSearchOutlined, FormOutlined, UserOutlined } from "@ant-design/icons";

import FieldSideLabel from "@/shared/components/field/side_label";
import { keyEventsHelpers } from "@/shared/helpers";
import { trpc } from "../../../trpc";
import { searchableFolders } from "../config/search";
import useInboxNavigation, { IInboxSearch } from "../hooks/useInboxNavigation.hooks";
import RecipientSelect from "../Composer/components/recipientSelect";
import { debounce } from "../../../helpers";
import InboxLabelSelect from "../components/InboxLabelSelect";
import validatorsHelpers from "@/helpers/validators.helpers";

const MAX_TAG_COUNTS = { recipient: 10, location: 5 };

export default function AdvancedSearch() {
	const intl = useIntl();
	const [form] = Form.useForm();
	const searchRef = useRef<any | null>(null);
	const [menuOpened, setMenuOpened] = useState(false); // Prevent close advanced search when date picker / select is opened
	const modalRef = useRef<HTMLDialogElement | null>(null);
	const searchSuggestionsModalRef = useRef<HTMLDialogElement | null>(null);
	const advancedButtonRef = useRef<HTMLElement | null>(null);
	const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
	const [labelSearchQuery, setLabelSearchQuery] = useState<string>("");
	const { getSearchParameters, isSearching, setSearch } = useInboxNavigation();

	const [isFormSet, setIsFormSet] = useState(isSearching);
	const searchParameters = getSearchParameters();
	const untilDate = Form.useWatch("until", form);
	const fromDate = Form.useWatch("fromDate", form);

	const [debouncedLabelSearch] = useDebounce(labelSearchQuery, 500);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const inboxLabelsQuery = trpc.inbox.inboxLabel.getAll.useQuery({ limit: 0, q: debouncedLabelSearch });
	const inboxChannelsQuery = trpc.channel.getInboxChannels.useQuery({ type: "email", limit: 0 });

	const { data: recipientsSuggestions } = trpc.contact.getRecipientsByChannel.useQuery(
		{
			channelIds: inboxChannelsQuery.data?.data.map(({ _id }) => _id) || [],
			q: searchQuery || undefined,
			limit: 6,
		},
		{
			enabled: Boolean(searchQuery),
		}
	);

	// Close logic
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [open, searchRef.current]);

	const handleClickOutside = (event: MouseEvent) => {
		if (!open || menuOpened) return;
		if (
			!modalRef?.current?.contains(event.target as Node) &&
			!advancedButtonRef?.current?.contains(event.target as Node) &&
			!searchSuggestionsModalRef?.current?.contains(event.target as Node)
		) {
			setShowAdvancedSearch(false);
		}
	};

	//Keyboard shortcuts
	useEffect(() => {
		// Should only execute when state.selectedId is changed else selected id won't be updated in handleKeyUp
		window.addEventListener("keyup", handleKeyUp);

		return () => {
			window.removeEventListener("keyup", handleKeyUp);
		};
	}, []);

	useEffect(() => {
		if (searchParameters.q === searchQuery) return;

		setSearchQuery(searchParameters.q || "");
	}, [searchParameters?.q]);
	useEffect(() => {
		if (!showAdvancedSearch) return;

		// When advanced search is re opened reset fields
		form.setFieldsValue(
			Object.keys(form.getFieldsValue()).reduce((prev, key) => ({ ...prev, [key]: searchParameters[key] }), {})
		);
	}, [showAdvancedSearch]);

	function handleKeyUp(event) {
		const functionMap = {
			"global.close_modal": () => setShowAdvancedSearch(false),
			"inbox.search.focus": searchRef.current?.focus,
		};

		keyEventsHelpers.handleShortcutPress({ event, functionMap });
	}

	const handleReset = () => {
		setSearch({});
		form.setFieldsValue(
			Object.keys(form.getFieldsValue()).reduce((prev, key) => ({ ...prev, [key]: undefined }), {})
		);
		setSearchQuery("");
		setShowAdvancedSearch(false);
	};

	const handleFormChange = (_, formValues: Record<string, string | string[]>) => {
		setIsFormSet(Boolean(Object.values(formValues).find((value) => (value as string)?.length)));
	};

	const handleAdvancedChange = (values: IInboxSearch) => {
		setSearch(values);
		setShowAdvancedSearch(false);
	};

	const handleSearchSuggestionSelected = (selectedValue: string) => {
		const [key, value] = selectedValue.split(":");

		const newParams = {
			...getSearchParameters(),
			...(value && { [key]: value }),
		};

		if (!newParams.q) {
			setSearchQuery("");
		}

		handleAdvancedChange(newParams);

		searchRef.current?.blur();
	};

	const handleSearchSuggestionInputCleared = () => {
		const newParams = getSearchParameters();

		delete newParams.q;

		handleAdvancedChange(newParams);
	};

	const generateSearchSuggestionOptions = () => {
		if (!searchQuery) return [];

		const recipientSuggestionOptions = (recipientsSuggestions ?? [])?.map((recipient) => ({
			value: `from:${recipient.email}:${recipient._id}`,
			label: (
				<SearchSuggestion
					icon={<Avatar size="small" shape="square" src={recipient.avatar} />}
					label={intl.formatMessage({
						id: "inbox.search.suggestions.from",
						defaultMessage: "From",
					})}
					suggestion={recipient.fullName || recipient.email}
					suggestionDetail={!!recipient.fullName ? recipient.email : undefined}
				/>
			),
		}));

		// Only show from (non recipient) suggestion if input is valid email address
		const fromSuggestion =
			!!searchQuery && validatorsHelpers.emailValidator(searchQuery)
				? {
						value: `from:${searchQuery}`,
						label: (
							<SearchSuggestion
								icon={<UserOutlined style={{ fontSize: "1.5rem" }} />}
								label={intl.formatMessage({
									id: "inbox.search.suggestions.from",
									defaultMessage: "From",
								})}
								suggestion={searchQuery}
							/>
						),
				  }
				: undefined;

		return [
			...recipientSuggestionOptions,
			fromSuggestion,
			{
				value: `subject:${searchQuery}`,
				label: (
					<SearchSuggestion
						icon={<FormOutlined style={{ fontSize: "1.5rem" }} />}
						label={intl.formatMessage({
							id: "inbox.search.suggestions.subject",
							defaultMessage: "Subject",
						})}
						suggestion={searchQuery}
					/>
				),
			},
			{
				value: `q:${searchQuery}`,
				label: (
					<SearchSuggestion
						icon={<FileSearchOutlined style={{ fontSize: "1.5rem" }} />}
						label={intl.formatMessage({
							id: "inbox.search.suggestions.all",
							defaultMessage: "All search results for",
						})}
						suggestion={searchQuery}
					/>
				),
			},
		].filter(Boolean);
	};

	return (
		<>
			<Space.Compact className="inbox_layout-header-search_wrapper">
				<AutoComplete
					allowClear
					ref={searchRef}
					options={generateSearchSuggestionOptions()}
					style={{ width: "40ch", textAlign: "left" }}
					popupMatchSelectWidth={400}
					value={searchQuery}
					onClear={handleSearchSuggestionInputCleared}
					onKeyUp={(event) => event.key === "Enter" && handleSearchSuggestionSelected(`q:${searchQuery}`)}
					onSelect={handleSearchSuggestionSelected}
					onSearch={(value) => setSearchQuery(value)}
					placeholder={intl.formatMessage({
						id: "inbox.search.placeholder",
						defaultMessage: "Press S to search all conversations",
					})}
				/>
				<Badge
					// Filter input field from badge because it's always visible
					count={Object.keys(searchParameters).filter((key) => key !== "q").length}
					size="small"
					color="var(--secondary)"
					style={{ color: "var(--primary)", fontWeight: 600, fontSize: "10px" }}
				>
					<Button
						type={showAdvancedSearch ? "default" : "primary"}
						ref={advancedButtonRef}
						icon={<ControlOutlined />}
						onClick={() => {
							setShowAdvancedSearch((state) => !state);
						}}
					/>
				</Badge>
			</Space.Compact>
			<dialog open={showAdvancedSearch} className="inbox-advanced_search" ref={modalRef}>
				<Form
					form={form}
					preserve={false}
					layout="vertical"
					initialValues={searchParameters}
					onValuesChange={handleFormChange}
					onFinish={handleAdvancedChange}
				>
					<Form.Item
						name="from"
						label={intl.formatMessage({
							id: "inbox.search.advanced.form.from.label",
							defaultMessage: "From",
						})}
					>
						<RecipientSelect
							autoFocus
							size="small"
							maxTagCount={MAX_TAG_COUNTS.recipient}
							getPopupContainer={(trigger) => trigger.parentNode}
							fromChannelIds={inboxChannelsQuery.data?.data.map(({ _id }) => _id) || []}
						/>
					</Form.Item>
					<Form.Item
						name="to"
						label={intl.formatMessage({
							id: "inbox.search.advanced.form.to.label",
							defaultMessage: "To",
						})}
					>
						<RecipientSelect
							autoFocus
							size="small"
							maxTagCount={MAX_TAG_COUNTS.recipient}
							getPopupContainer={(trigger) => trigger.parentNode}
							fromChannelIds={inboxChannelsQuery.data?.data.map(({ _id }) => _id) || []}
						/>
					</Form.Item>
					<Form.Item
						name="subject"
						label={intl.formatMessage({
							id: "inbox.search.advanced.form.subject.label",
							defaultMessage: "Subject",
						})}
					>
						<Input size="small" />
					</Form.Item>
					<Divider />
					<Flex gap="var(--spacer-sm">
						<Form.Item
							style={{ width: "100%" }}
							name="searchWithinFolders"
							label={intl.formatMessage({
								id: "inbox.search.advanced.form.search_within_folders.label",
								defaultMessage: "Search within folder(s)",
							})}
						>
							<Select
								size="small"
								autoFocus
								mode="tags"
								maxTagCount={MAX_TAG_COUNTS.location}
								notFoundContent={null}
								tokenSeparators={[","]}
								getPopupContainer={(trigger) => trigger.parentNode}
								onSearch={setLabelSearchQuery}
								options={[
									{
										label: intl.formatMessage(searchableFolders.INBOX),
										value: "INBOX",
									},
									{
										label: intl.formatMessage(searchableFolders.ASSIGNED_TO_ME),
										value: "ASSIGNED_TO_ME",
									},
									{
										label: intl.formatMessage(searchableFolders.MENTIONED),
										value: "MENTIONED",
									},
									{
										label: intl.formatMessage(searchableFolders.SHARED),
										value: "SHARED",
									},
									{
										label: intl.formatMessage(searchableFolders.DRAFT),
										value: "DRAFT",
									},
									{
										label: intl.formatMessage(searchableFolders.DELEGATED),
										value: "DELEGATED",
									},
									{
										label: intl.formatMessage(searchableFolders.SENT),
										value: "SENT",
									},
									{
										label: intl.formatMessage(searchableFolders.OPEN),
										value: "OPEN",
									},
									{
										label: intl.formatMessage(searchableFolders.CLOSED),
										value: "CLOSED",
									},
									{
										label: intl.formatMessage(searchableFolders.SNOOZED),
										value: "SNOOZED",
									},
									{
										label: intl.formatMessage(searchableFolders.TRASH),
										value: "TRASH",
									},
									{
										label: intl.formatMessage(searchableFolders.SPAM),
										value: "SPAM",
									},
								]}
								filterOption={(inputValue, option) => (option?.label as string)?.includes(inputValue)}
							/>
						</Form.Item>
						<Form.Item
							style={{ width: "100%" }}
							name="searchWithinLabels"
							label={intl.formatMessage({
								id: "inbox.search.advanced.form.search_within_labels.label",
								defaultMessage: "Search within label(s)",
							})}
						>
							<InboxLabelSelect
								size="small"
								mode="multiple"
								maxTagCount={MAX_TAG_COUNTS.location}
								onSearch={setLabelSearchQuery}
								getPopupContainer={(trigger) => trigger.parentNode}
								inboxLabels={inboxLabelsQuery.data?.data || []}
								isLabelLoading={inboxLabelsQuery.isLoading}
							/>
						</Form.Item>
					</Flex>
					<FieldSideLabel
						name="hasAttachments"
						label={intl.formatMessage({
							id: "inbox.search.advanced.form.has_attachments.label",
							defaultMessage: "Has attachments",
						})}
					>
						<Form.Item name="hasAttachments" valuePropName="checked">
							<Switch size="small" />
						</Form.Item>
					</FieldSideLabel>
					<Divider />
					<div className="advanced_search-date_picker">
						<Form.Item
							name="fromDate"
							label={intl.formatMessage({
								id: "inbox.search.advanced.form.from_date.label",
								defaultMessage: "From date",
							})}
						>
							<DatePicker
								size="small"
								format="DD-MM-YYYY"
								onOpenChange={setMenuOpened}
								disabledDate={(currentDate) => (untilDate ? currentDate > untilDate : false)}
							/>
						</Form.Item>
						<Form.Item
							name="until"
							label={intl.formatMessage({
								id: "inbox.search.advanced.form.until.label",
								defaultMessage: "Until",
							})}
						>
							<DatePicker
								size="small"
								format="DD-MM-YYYY"
								onOpenChange={setMenuOpened}
								disabledDate={(currentDate) => (fromDate ? currentDate < fromDate : false)}
							/>
						</Form.Item>
					</div>
					<footer className="advanced_search-footer">
						{(isFormSet || isSearching) && (
							<Button
								size="small"
								type="text"
								onClick={handleReset}
								className="advanced_search-footer-button_focus"
							>
								<FormattedMessage
									id="inbox.search.advanced.footer.button.reset"
									defaultMessage="Reset"
								/>
							</Button>
						)}
						<Button
							type="primary"
							size="small"
							htmlType="submit"
							className="advanced_search-footer-button_focus"
						>
							<FormattedMessage id="inbox.search.advanced.footer.button.search" defaultMessage="Search" />
						</Button>
					</footer>
				</Form>
			</dialog>
		</>
	);
}

function SearchSuggestion({
	icon,
	label,
	suggestion,
	suggestionDetail,
}: {
	icon: ReactNode;
	label: string;
	suggestion: string;
	suggestionDetail?: string;
}) {
	return (
		<Flex gap="small" align="center">
			<span style={{ display: "grid", placeItems: "center", width: 24, height: 24 }}>{icon}</span>
			<Flex gap={4} style={{ maxWidth: "45ch" }}>
				<Typography.Text type="secondary">{label}</Typography.Text>
				<Typography.Text strong title={suggestion}>
					{suggestion}
				</Typography.Text>
				{suggestionDetail && (
					<Typography.Text type="secondary" ellipsis title={suggestionDetail}>
						{suggestionDetail}
					</Typography.Text>
				)}
			</Flex>
		</Flex>
	);
}
