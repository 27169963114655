import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";

import { Popup } from "..";

export default function OPopupDestructiveControl({
	addLabel,
	disabled,
	isLoading,
	formattedValues,
	onClick,
	children,
	onClose,
	...props
}) {
	return (
		<Popup.OPopupGeneral formattedValues={formattedValues} onClose={onClose} {...props}>
			{children}
			<div className="o-popup--button">
				<Button
					danger
					type="primary"
					htmlType="submit"
					loading={isLoading}
					onClick={onClick}
					disabled={disabled}
				>
					<FormattedMessage id={addLabel} values={formattedValues} />
				</Button>
				<Button type="text" onClick={onClose}>
					<FormattedMessage id="POPUP.BUTTON.CANCEL" defaultMessage="Cancel" />
				</Button>
			</div>
		</Popup.OPopupGeneral>
	);
}

OPopupDestructiveControl.defaultProps = {
	disabled: false,
	formattedValues: undefined,
	onClick: undefined,
	children: undefined,
	className: "",
};
