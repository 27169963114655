export default {
	copyToClipboard(value) {
		const dummy = document.createElement("textarea");

		document.body.appendChild(dummy);
		dummy.value = value;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
	},
};
