import axios from "axios";

import config from "../../config";

export const updateStatusType = async ({ flowId, statusTypeId, payload }) => {
	const url = config.api.update.replace(":flowId", flowId).replace(":statusTypeId", statusTypeId);
	const { data } = await axios.put(url, payload);
	// Don't check here for success because data can contain custom error

	return data;
};

export const deleteStatusType = async ({ flowId, statusTypeId }) => {
	const url = config.api.delete.replace(":flowId", flowId).replace(":statusTypeId", statusTypeId);
	const { status } = await axios.delete(url);

	if (status !== 204) {
		throw new Error("Failed to delete status type");
	}

	return true;
};
