import "./style.scss";

import { Table, Typography, Button } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import { Panel } from "@/shared/components/collapse";
import AddDomain from "./add_domain";
import helpers from "./helpers";
import { ApiKeyManagerRouterOutput, trpc } from "@/trpc";

export default function Domains({
	apiKey,
	onChange,
	...collapseProps
}: {
	apiKey: ApiKeyManagerRouterOutput["getById"] | null | undefined;
	onChange: (domains) => void;
}) {
	const intl = useIntl();
	const [isCreateVisible, toggleCreate] = useState<boolean>(false);
	const dataSource = helpers.formatDataSource({ apiKey });

	const handleAddDomain = ({ name }: { name: string }) => {
		onChange([...(apiKey?.domains || []), name]);
		toggleCreate(false);
	};
	const handleDelete = (domain) => {
		const domains = apiKey?.domains?.filter((item) => item !== domain);

		onChange(domains);
	};

	return (
		<>
			<Panel
				key="domains"
				{...collapseProps} // Get all functional props from parent collapse component
				title={intl.formatMessage({
					id: "settings.api_key_detail.domain.title",
					defaultMessage: "Trusted domains",
				})}
			>
				<Typography.Text>
					<FormattedMessage
						id={intl.formatMessage({
							id: "settings.api_key_detail.domain.description",
							defaultMessage:
								"Protect your API key from unwanted access by restricting the API key to a specific range of trusted domains. Some permissions require that you add trusted domains to the API key before it will work.",
						})}
					/>
				</Typography.Text>
				<Table
					className="page-api-key-permissions--table t-gap--top-sm"
					dataSource={dataSource}
					pagination={false}
				>
					<Table.Column
						key="domain"
						dataIndex="domain"
						title={
							<FormattedMessage
								id="settings.api_key_detail.domain.table.domain"
								defaultMessage="Domain name"
							/>
						}
					/>
					<Table.Column
						key="deleteKey"
						dataIndex="deleteKey"
						width="48px"
						render={(id) => (
							<Button type="text" onClick={() => handleDelete(id)}>
								<DeleteOutlined style={{ fontSize: "1.4em" }} />
							</Button>
						)}
					/>
				</Table>
				<Button icon={<PlusOutlined />} size="large" type="text" onClick={() => toggleCreate(true)}>
					<FormattedMessage
						id="settings.api_key_detail.domain.button.add_domain"
						defaultMessage="Add domain"
					/>
				</Button>
			</Panel>
			<AddDomain visible={isCreateVisible} onSubmit={handleAddDomain} onClose={() => toggleCreate(false)} />
		</>
	);
}
