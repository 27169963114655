import "./style.scss";

import { Button, Tooltip } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function ApiKeyHeader({
	isDisabled,
	onClick,
	className,
}: {
	isDisabled: boolean;
	onClick: () => void;
	className?: string;
}) {
	const intl = useIntl();

	return (
		<header className={`api-key-overview--header ${className}`}>
			<Tooltip
				title={
					isDisabled
						? intl.formatMessage({
								id: "filter.api_key.button.create_api_key.tooltip",
								defaultMessage: "You can not create API keys, please upgrade your plan",
						  })
						: null
				}
			>
				<Button type="primary" disabled={isDisabled} onClick={onClick}>
					<FormattedMessage id="filter.api_key.button.create_api_key" defaultMessage="Create API key" />
				</Button>
			</Tooltip>
		</header>
	);
}
