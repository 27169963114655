import { routesConfig } from "@/config";

export default {
	title: "SETTINGS.INTEGRATIONS.OVERVIEW.PAGE.TITLE",
	tabTitle: "SETTINGS.INTEGRATIONS.OVERVIEW.PAGE.TITLE",
	breadcrumbs: ({ team }) => [
		{
			to: routesConfig.root.replace(":team", team),
			name: "SETTINGS.INTEGRATIONS.OVERVIEW.HEADER.BREADCRUMB.SETTINGS",
		},
		{
			to: routesConfig.dashboard.settings.integrations.overview.replace(":team", team),
			name: "SETTINGS.INTEGRATIONS.OVERVIEW.HEADER.BREADCRUMB.PAGE_TITLE",
		},
		{
			to: routesConfig.dashboard.settings.integrations.twinntaxIntegration.replace(":team", team),
			name: "SETTINGS.INTEGRATIONS.TWINNTAX.BREADCRUMB.PAGE_TITLE",
		},
	],
};
