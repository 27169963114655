import "./style.scss";
import { FormattedMessage } from "react-intl";
import buttonConfig, { ReplyAction } from "./config";
import { HTMLMotionProps, motion } from "framer-motion";

interface ReplyFooterProps extends HTMLMotionProps<"footer"> {
	onClickReplyOption(replyAction: ReplyAction): void;
	className?: string;
}

export default function ReplyFooter({ onClickReplyOption, className, ...props }: ReplyFooterProps) {
	return (
		<motion.footer data-private className="rpf-inbox-conversation--reply-footer" {...props}>
			<div className={`rpf-footer-wrapper ${className}`}>
				{buttonConfig.map((button) => (
					<button key={button.id} className="rpf-reply-button" onClick={() => onClickReplyOption(button.id)}>
						<FormattedMessage id={button.label} />
					</button>
				))}
			</div>
		</motion.footer>
	);
}
