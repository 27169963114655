import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";

import NotFoundState from "@/flows/common/components/not_found";
import helpers from "@/flows/report_detail/viewer/pdf/helpers";
import { htmlTemplateState, templateUrlState } from "@/flows/report_detail/viewer/pdf/state";

import style from "./style.module.scss";

export default function PDFViewer({ data, templateUrl }) {
	const intl = useIntl();
	const [parsedHtml, setParsedHtml] = useState(undefined);
	const [isParsing, setIsParsing] = useState(false);
	const [parsingFailed, setParsingFailed] = useState(false);

	const setTemplateUrl = useSetRecoilState(templateUrlState);

	const template = useRecoilValueLoadable(htmlTemplateState);

	useEffect(() => {
		generateHtml();
	}, [data, template]);
	useEffect(() => setTemplateUrl(templateUrl), [templateUrl]);

	const generateErrorMessage = () => (
		<NotFoundState
			style={{ margin: "var(--spacer-md) auto", width: "auto" }}
			title={intl.formatMessage({
				id: "FLOWS.REPORT_DETAIL.PDF.FAILED.TITLE",
				description: "FLOWS.REPORT_DETAIL.PDF.FAILED.DESCRIPTION",
			})}
		/>
	);

	const generateHtml = async () => {
		try {
			setIsParsing(true);

			const parsedHtml = await helpers.parseHtml({
				templateContents: template?.contents,
				language: intl.locale,
				data,
			});

			setParsedHtml(parsedHtml);
			setIsParsing(false);
		} catch (error) {
			console.error("Failed to parse pdf", error);
			setIsParsing(false);
			setParsingFailed(true);
		}
	};

	if (!templateUrl || !data) {
		return generateErrorMessage();
	}

	if (template.state === "loading" || isParsing) {
		return <Skeleton active className={style.skeleton_loader} paragraph={{ rows: 16 }} />;
	}

	if (template.state === "hasError" || parsingFailed) {
		return generateErrorMessage();
	}

	return (
		<div className={style.data_preview_pdf}>
			<iframe title="pdf_preview" srcDoc={parsedHtml} className={style.data_preview_content} />
		</div>
	);
}
