import axios from "axios";

import { apiConfig } from "@/flows/common/config";

async function updateReportAssignee({ flowId, assignee, reportId }) {
	const url = apiConfig.report.assign.replace(":id", flowId).replace(":reportId", reportId);

	const { data } = await axios.put(url, { assignee });

	return data;
}

export default { updateReportAssignee };
