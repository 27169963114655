import { Alert, Button, Form, Input, Modal, Result, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import FieldLabel from "@/shared/components/field/default";
import { queryStringHelpers } from "@/shared/helpers";
import { useState } from "react";
import ShowFailedBanner from "../../../shared/components/banner/failed";
import { trpc } from "../../../trpc";
import { timeoutHelpers } from "@bothive/helpers";

const SECONDS_TO_WAIT_BEFORE_REDIRECT = 5;

export default function ResetPassword() {
	const intl = useIntl();
	const history = useHistory();
	const location = useLocation();
	const [form] = Form.useForm();
	const [showSuccess, setShowSuccess] = useState(false);
	const requestPasswordReset = trpc.user.resetPassword.useMutation();
	const params = queryStringHelpers.formatQueryString(location.search);
	const [showInvalidTokenBanner, setShowInvalidTokenBanner] = useState(false);
	const [redirectWithIn, setRedirectWithIn] = useState(SECONDS_TO_WAIT_BEFORE_REDIRECT);

	if (!params.user || !params.token) {
		history.push("/portal/login");
		return null;
	}

	const handleResetPassword = ({ password }: { password: string }) => {
		const body = {
			password,
			user: params.user,
			token: params.token,
		};

		requestPasswordReset.mutate(body, {
			onSuccess: async () => {
				setShowSuccess(true);

				let counter = redirectWithIn;

				// Wait and update redirect counter before redirect
				do {
					await timeoutHelpers.wait(1_000);
					setRedirectWithIn((prev) => {
						counter = prev - 1;
						return prev - 1;
					});
				} while (counter);

				history.push("/portal/login");
			},
			onError: (error) => {
				if (error && error?.data?.code === "NOT_FOUND") {
					setShowInvalidTokenBanner(true);
					return;
				}

				ShowFailedBanner({
					title: intl.formatMessage({
						id: "portal.reset_password.failed.error",
						defaultMessage: "Something went wrong, please try again later.",
						description: "Show error banner when reset failed",
					}),
					intl,
				});
				return;
			},
		});
	};

	return (
		<section className="portal_body">
			<article className="portal_body-article">
				<header className="portal_body-header">
					<Typography.Title>
						<FormattedMessage id="portal.reset_password.title" defaultMessage="Reset password" />
					</Typography.Title>
					<p>
						<FormattedMessage
							id="portal.reset_password.description"
							defaultMessage="Don't want to reset your password? <link>Login</link>"
							values={{
								link: (content) => (
									<NavLink to="/portal/login" className="portal_body-header-link">
										{content}
									</NavLink>
								),
							}}
						/>
					</p>
				</header>
				{showInvalidTokenBanner && (
					<Alert
						type="error"
						className="t-gap--bottom-sm"
						message={intl.formatMessage({
							id: "portal.reset_password.error_banner.invalid_token.title",
							defaultMessage: "Reset url is invalid",
							description: "Show error banner when the reset token isn't valid",
						})}
						description={intl.formatMessage({
							id: "portal.reset_password.error_banner.invalid_token.description",
							defaultMessage:
								"Your reset url is not valid anymore, this can happen because the url has already been used or the url was requested more then 30minutes ago.",
							description:
								"Show error banner when the reset token isn't valid anymore and explain how to solve",
						})}
					/>
				)}
				<Form id="resetPasswordForm" onFinish={handleResetPassword} form={form} className="portal_body-form">
					<FieldLabel
						id="password"
						label={intl.formatMessage({
							id: "portal.reset_password.new_password.label",
							defaultMessage: "New password",
						})}
					>
						<Form.Item
							name="password"
							className="portal_body-form-field"
							required
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "portal.reset_password.new_password.error_is_required",
										defaultMessage: "You must enter a password",
									}),
								},
								{
									min: 5,
									message: intl.formatMessage({
										id: "portal.reset_password.new_password.min_length",
										defaultMessage: "Your password need to have at least 5 characters",
									}),
								},
								{
									validator: async (_, value: string) => {
										if (/[A-Z]/.test(value)) return;

										const message = intl.formatMessage({
											id: "portal.reset_password.repeat_password.no_capital_case",
											defaultMessage: "Your password needs to contain at least 1 capital case",
										});

										return Promise.reject(new Error(message));
									},
								},
							]}
						>
							<Input.Password id="password" size="large" />
						</Form.Item>
					</FieldLabel>
					<FieldLabel
						id="repeatPassword"
						label={intl.formatMessage({
							id: "portal.reset_password.repeat_password.label",
							defaultMessage: "Repeat password",
						})}
					>
						<Form.Item
							name="repeatPassword"
							className="portal_body-form-field"
							required
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: "portal.reset_password.repeat_password.error_is_required",
										defaultMessage: "You must confirm your password",
									}),
								},
								{
									min: 5,
									message: intl.formatMessage({
										id: "portal.reset_password.repeat_password.min_length",
										defaultMessage: "Your password need to have at least 5 characters",
									}),
								},
								{
									validator: async (_, value: string) => {
										if (value === form.getFieldValue("password")) return;

										const message = intl.formatMessage({
											id: "portal.reset_password.repeat_password.not_equal",
											defaultMessage: "Your password are not equal",
										});

										return Promise.reject(new Error(message));
									},
								},
							]}
						>
							<Input.Password id="repeatPassword" size="large" />
						</Form.Item>
					</FieldLabel>
					<Form.Item shouldUpdate className="t-gap--top portal_body-form-field-submit_wrapper">
						{() => {
							const isDisabled =
								!form.isFieldsTouched(true) ||
								!!form.getFieldsError().filter(({ errors }) => errors.length).length;

							return (
								<Button
									size="large"
									type="primary"
									htmlType="submit"
									disabled={isDisabled}
									form="resetPasswordForm"
									loading={requestPasswordReset.isLoading}
									className="portal_body-form-field-submit_button"
								>
									<FormattedMessage
										id="portal.reset_password.button"
										defaultMessage="Reset password"
									/>
								</Button>
							);
						}}
					</Form.Item>
				</Form>
			</article>
			<Modal open={showSuccess} footer={null} closable={false}>
				<Result
					status="success"
					title={intl.formatMessage({
						id: "portal.reset_password.successful.title",
						defaultMessage: "Password successfully changed",
					})}
					subTitle={
						//@ts-ignore
						intl.formatMessage(
							{
								id: "portal.reset_password.successful.description",
								defaultMessage:
									"You will be redirected in <bold>{seconds, plural, =1 {# second} other {# seconds}}</bold> to the login screen",
								description:
									"Show modal to explain to the user they will be redirected to login within maxim 5 seconds",
							},
							{
								seconds: redirectWithIn,
								bold: (content) => <Typography.Text strong>{content}</Typography.Text>,
							}
						) as React.ReactNode
					}
				/>
			</Modal>
		</section>
	);
}
