import "./style.scss";

import { Alert, Button, Card, Descriptions, Form, Input, Result, Select, Skeleton, Space, Switch, Table } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useRecoilState } from "recoil";

import FieldSideLabel from "@/shared/components/field/side_label";
import { trpc } from "../../../../trpc";
import { StepParams } from "../types";
import { ImportConfig, importConfigState } from "./state";
import { ECrmType, EType } from "./types";

export default function FilterImport({ onNext, onClose }: StepParams & { onNext: () => void }) {
	const intl = useIntl();
	const [form] = Form.useForm<ImportConfig>();
	const [importConfig, setImportConfig] = useRecoilState(importConfigState);

	const {
		data: fetchedPreview,
		isLoading,
		isError,
	} = trpc.integrations.crm.fetchContacts.useQuery(
		{
			integrationId: "admin_pulse",
			query: {
				offset: 0,
				limit: 100,
				...importConfig,
			},
		},
		{ refetchOnWindowFocus: false }
	);

	return (
		<div className="contact_import-content_wrapper contact_import-filter_import">
			<Card
				title={intl.formatMessage({
					id: "contact.import.admin_pulse.filter_import.header.title",
					defaultMessage: "Filter contacts",
				})}
				className="contact_import-content-card"
			>
				<Form
					form={form}
					layout="vertical"
					initialValues={importConfig}
					className="filter_import-header"
					onValuesChange={(values) => setImportConfig((prev) => ({ ...prev, ...values }))}
				>
					<Form.Item
						name="crmType"
						label={intl.formatMessage({
							id: "contact.import.admin_pulse.filter_import.header.crm_type.label",
							defaultMessage: "Select CRM type",
						})}
					>
						<Select
							size="large"
							placeholder={intl.formatMessage({
								id: "contact.import.admin_pulse.filter_import.header.crm_type.placeholder",
								defaultMessage: "Select CRM type",
							})}
							className="loading_select"
							allowClear={true}
							options={[
								{
									value: ECrmType.customer,
									label: intl.formatMessage({
										id: "contact.import.admin_pulse.filter_import.header.crm_type.option.customer",
										defaultMessage: "Customer",
									}),
								},
								{
									value: ECrmType.prospect,
									label: intl.formatMessage({
										id: "contact.import.admin_pulse.filter_import.header.crm_type.option.prospect",
										defaultMessage: "Prospect",
									}),
								},
								{
									value: ECrmType.ownCompany,
									label: intl.formatMessage({
										id: "contact.import.admin_pulse.filter_import.header.crm_type.option.own_company",
										defaultMessage: "Own company",
									}),
								},
								{
									value: ECrmType.other,
									label: intl.formatMessage({
										id: "contact.import.admin_pulse.filter_import.header.crm_type.option.other",
										defaultMessage: "Other",
									}),
								},
							]}
						></Select>
					</Form.Item>
					<Form.Item
						name="type"
						label={intl.formatMessage({
							id: "contact.import.admin_pulse.filter_import.header.type.label",
							defaultMessage: "Filter on contact type",
						})}
					>
						<Select
							size="large"
							placeholder={intl.formatMessage({
								id: "contact.import.admin_pulse.filter_import.header.type.placeholder",
								defaultMessage: "Select contact type",
							})}
							className="loading_select"
							allowClear={true}
							options={[
								{
									value: EType.person,
									label: intl.formatMessage({
										id: "contact.import.admin_pulse.filter_import.header.type.option.person",
										defaultMessage: "Person",
									}),
								},
								{
									value: EType.soleProprietor,
									label: intl.formatMessage({
										id: "contact.import.admin_pulse.filter_import.header.type.option.sole_proprietor",
										defaultMessage: "Sole proprietor",
									}),
								},
								{
									value: EType.company,
									label: intl.formatMessage({
										id: "contact.import.admin_pulse.filter_import.header.type.option.company",
										defaultMessage: "Company",
									}),
								},
							]}
						></Select>
					</Form.Item>
					<Form.Item
						label={intl.formatMessage({
							id: "contact.import.admin_pulse.filter_import.header.search.label",
							defaultMessage: "Search on contact",
						})}
						name="q"
					>
						<Input
							size="large"
							placeholder={intl.formatMessage({
								id: "contact.import.admin_pulse.filter_import.header.search.placeholder",
								defaultMessage: "Search on name or e-mail",
							})}
						/>
					</Form.Item>
				</Form>
			</Card>

			<Card
				title={intl.formatMessage({
					id: "contact.import.admin_pulse.filter_import.preview.title",
					defaultMessage: "Import preview",
				})}
				className="contact_import-content-card"
			>
				{!isError && (
					<Skeleton
						active
						title={false}
						loading={isLoading}
						className="filter_import-skeleton"
						paragraph={{ rows: 10, width: "100%" }}
					>
						<Space direction="vertical" size="large" className="contact_import-content-spacer">
							{fetchedPreview?.meta.total > 100 && (
								<Alert
									message={intl.formatMessage({
										id: "contact.import.admin_pulse.filter_import.info",
										defaultMessage:
											"We only show a preview of the first 100 contacts that will be imported. In the next step you will be able to see all imported contacts and fine tune which will be imported",
										description:
											"Explain we only show a preview of the first 100 admin pulse contacts in this import step so the user knows their filters are correct",
									})}
									type="info"
								/>
							)}
							<div className="contact_import-content-spacer">
								<Descriptions
									items={[
										{
											key: "total",
											label: intl.formatMessage({
												id: "contact.import.admin_pulse.filter_import.stats.total",
												defaultMessage: "Found contacts",
											}),
											children: intl.formatNumber(fetchedPreview?.meta?.total),
										},
									]}
								/>
								<Table
									bordered
									rowKey="id"
									columns={[
										{
											key: "fullName",
											dataIndex: "fullName",
											title: intl.formatMessage({
												id: "contact.import.admin_pulse.filter_import.table.column.name",
												defaultMessage: "Name",
											}),
										},
										{
											key: "email",
											dataIndex: "email",
											title: intl.formatMessage({
												id: "contact.import.admin_pulse.filter_import.table.column.email",
												defaultMessage: "Email",
											}),
										},
										{
											key: "phone",
											dataIndex: "phone",
											title: intl.formatMessage({
												id: "contact.import.admin_pulse.filter_import.table.column.phone",
												defaultMessage: "Phone",
											}),
										},
									]}
									dataSource={fetchedPreview?.data}
									pagination={
										fetchedPreview?.meta?.total > 10 && {
											showSizeChanger: true,
											showQuickJumper: true,
											defaultPageSize: 10,
											pageSizeOptions: ["10", "20", "50", "100"],
										}
									}
									locale={{
										emptyText: intl.formatMessage({
											id: "contact.import.admin_pulse.filter_import.table.empty_text",
											defaultMessage: "No contacts found",
										}),
									}}
								/>
							</div>
						</Space>
					</Skeleton>
				)}
				{isError && (
					<Result
						status="error"
						title={intl.formatMessage({
							id: "contact.import.admin_pulse.filter_import.fetch_failed.title",
							defaultMessage: "Failed to retrieve your contacts",
						})}
						subTitle={intl.formatMessage({
							id: "contact.import.admin_pulse.filter_import.fetch_failed.description",
							defaultMessage:
								"We couldn't retrieve any contacts from Admin Pulse. Please try again later. If the problem persists, please contact support.",
						})}
					/>
				)}
			</Card>

			<Card
				title={intl.formatMessage({
					id: "contact.import.admin_pulse.filter_import.details.title",
					defaultMessage: "Import details",
				})}
				className="contact_import-content-card"
			>
				<Form
					layout="vertical"
					initialValues={importConfig}
					className="contact_import-content-card-body"
					onFinish={(values) => setImportConfig((prev) => ({ ...prev, ...values }))}
				>
					<FieldSideLabel
						id="allowCreate"
						label={intl.formatMessage({
							id: "contact.import.admin_pulse.filter_import.details.allow_create.label",
							defaultMessage: "Do you want to add new contacts?",
						})}
						className="filter_import-switch-form_item"
					>
						<Form.Item name="allowCreate" valuePropName="checked">
							<Switch
								checkedChildren={intl.formatMessage({
									id: "contact.import.admin_pulse.filter_import.details.toggle.yes",
									defaultMessage: "Yes",
								})}
								unCheckedChildren={intl.formatMessage({
									id: "contact.import.admin_pulse.filter_import.details.toggle.no",
									defaultMessage: "No",
								})}
							/>
						</Form.Item>
					</FieldSideLabel>
					<FieldSideLabel
						id="allowUpdate"
						label={intl.formatMessage({
							id: "contact.import.admin_pulse.filter_import.details.allow_update.label",
							defaultMessage: "Do you want to update existing contacts?",
						})}
						className="filter_import-switch-form_item"
					>
						<Form.Item name="allowUpdate" valuePropName="checked">
							<Switch
								checkedChildren={intl.formatMessage({
									id: "contact.import.admin_pulse.filter_import.details.toggle.yes",
									defaultMessage: "Yes",
								})}
								unCheckedChildren={intl.formatMessage({
									id: "contact.import.admin_pulse.filter_import.details.toggle.no",
									defaultMessage: "No",
								})}
							/>
						</Form.Item>
					</FieldSideLabel>
					<footer className="contact_import-content-footer">
						<Button type="text" danger onClick={() => onClose()}>
							<FormattedMessage
								id="contact.import.admin_pulse.filter_import.footer.cancel"
								defaultMessage="Cancel import"
							/>
						</Button>
						<Button
							type="primary"
							onClick={onNext}
							htmlType="submit"
							loading={isLoading}
							disabled={!fetchedPreview?.meta?.total || isError}
						>
							<FormattedMessage
								id="contact.import.admin_pulse.filter_import.footer.next"
								defaultMessage="Next"
							/>
						</Button>
					</footer>
				</Form>
			</Card>
		</div>
	);
}
